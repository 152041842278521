<template>
  <div
    class="drag-drop-choice__wrapper"
    :class="{
      'drag-drop-choice--2': choice.remaining > 1,
      'drag-drop-choice--3': choice.remaining > 2,
      'drag-drop-choice--error': error
    }"
    tabindex="0"
  >
    <component
      class="drag-drop-choice__choice"
      :is="type === 'latex' ? DragDropLatexContent : DragDropRichTextContent"
      :text="choice.text"
      :variableContext="variableContext"
      :view-as-student="viewAsStudent"
      @pointerdown="onPointerDown"
    />
    <div class="drag-drop-choice__fake" v-if="choice.remaining > 1" />
    <div class="drag-drop-choice__fake" v-if="choice.remaining > 2" />
  </div>
</template>

<script setup lang="ts">
import DragDropLatexContent from './DragDropLatexContent.vue'
import DragDropRichTextContent from './DragDropRichTextContent.vue'
import useDragging from '../useDragging'

interface Choice {
  id: string
  text: string
  remaining: number
}

interface Props {
  type: 'latex' | 'rich-text'
  choice: Choice
  variableContext: any
  error?: boolean
  viewAsStudent?: boolean
}

const emit = defineEmits<{
  move: [{ destTarget: string; choice: string }]
}>()
const props = defineProps<Props>()

const { onPointerDown } = useDragging({
  onDragEnd(e, targetId) {
    if (targetId) {
      emit('move', {
        choice: props.choice.id,
        destTarget: targetId
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.drag-drop-choice__wrapper {
  position: relative;

  &:focus {
    outline: none;
  }
}

.drag-drop-choice__fake,
.drag-drop-choice__choice {
  border: 1px solid #3d2c60;
  border-radius: 4px;
  background-color: #fff;
}

.drag-drop-choice__choice {
  padding: 4px 6px;
  display: block;
  cursor: grab;
  position: relative;
  z-index: 2;
}

.drag-drop-choice__fake {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.drag-drop-choice__fake:nth-child(2) {
  top: 2px;
  left: 2px;
  z-index: 1;
}
.drag-drop-choice__fake:nth-child(3) {
  top: 4px;
  left: 4px;
}

.drag-drop-choice--2 {
  transform: translate(-2px, -2px);
}

.drag-drop-choice--3 {
  transform: translate(-4px, -4px);
}

.drag-drop-choice--error {
  .drag-drop-choice__choice,
  .drag-drop-choice__fake {
    border: 1px solid $color-error;
    outline: 1px solid $color-error;
    animation: 0.5s ease-in shake;
  }

  @keyframes shake {
    0% {
      transform: rotate(-3deg);
    }
    20% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    60% {
      transform: rotate(5deg);
    }
    90% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(0);
    }
  }
}

:global(.drag-drop-choice__ghost) {
  border-radius: 4px;
  border: 1px solid #000;
  padding: 2px 10px;
  background-color: white;
  position: fixed;
  left: -100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  cursor: grabbing;
  z-index: 9999;
}
</style>
