<template>
  <form-button v-bind="$attrs" @click="onClick"><slot /></form-button>
  <input ref="fileInput" type="file" style="display: none" @change="onChange" />
</template>

<script setup>
import { ref } from 'vue'

const emit = defineEmits(['change'])

const fileInput = ref()

function onClick() {
  fileInput.value?.click()
}
function onChange(e) {
  emit('change', e)
}
</script>
