<template>
  <button
    type="button"
    :aria-label="label"
    :disabled="disabled || null"
    :title="toolTip ? label : ''"
    @click="onClick"
  >
    <icon :icon="icon" :class="color" class="icon" />
    <span class="sr-only">{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  emits: ['click'],
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: '',
      required: true
    },
    toolTip: {
      type: Boolean,
      default: true,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #333333;
  text-align: center;
  font-weight: 400;
  line-height: 1.6;
  white-space: nowrap;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus {
    box-shadow: $teal 0px 1px 2px, $teal 0px 0px 0px 2px;
    border-radius: 6px;
  }

  & :deep(.icon) {
    font-size: 15px;

    &.move-disabled {
      color: $light-grey;
    }

    &.move-active {
      color: $dark-grey;
    }

    &.copy-icon {
      color: $dark-grey;
    }

    &.delete-icon {
      color: $color-error;
    }

    &.add-icon {
      color: $teal;
    }
    &.help-icon {
      color: $teal;
    }
  }
}
</style>
