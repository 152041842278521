<template>
  <div role="group">
    <ul class="filter-list">
      <li class="filter-option">
        <checkbox
          class="filter-checkbox"
          :name="name"
          v-model="mutableValue"
          value="true"
        >
          {{ trueLabel }}
        </checkbox>
      </li>
      <li class="filter-option">
        <checkbox
          class="filter-checkbox"
          :name="name"
          v-model="mutableValue"
          value="false"
        >
          {{ falseLabel }}
        </checkbox>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'LibraryFilterBoolean',
  emits: ['update:modelValue'],
  props: {
    trueLabel: {
      type: String,
      required: true
    },
    falseLabel: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const name = `boolean-filter-${Math.round(Math.random() * 100000)}`
    const mutableValue = computed({
      get: () => {
        switch (props.modelValue) {
          case 'true':
          case 'false':
            return [props.modelValue]
          case 'both':
            return ['true', 'false']
          default:
            return []
        }
      },
      set: value => {
        if (value.includes('true')) {
          if (value.includes('false')) {
            emit('update:modelValue', 'both')
          } else {
            emit('update:modelValue', 'true')
          }
        } else if (value.includes('false')) {
          emit('update:modelValue', 'false')
        } else {
          emit('update:modelValue')
        }
      }
    })

    return { name, mutableValue }
  }
}
</script>

<style lang="scss" scoped>
.filter-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.filter-option {
  margin: 8px 16px 8px 8px;
  font-size: 16px;
}

.filter-checkbox {
  margin: 0;
  font-size: 16px;
  flex-grow: 1;
}
</style>
