<template>
  <div class="flex justify-center">
    <card class="max-w-[75%] shadow-md">
      <UILabel class="pb-4 block text-xl text-pivot-purple-700 bold">
        LTI Error
      </UILabel>
      <div
        class="bg-pivot-yellow-50 p-2 flex flex-col items-center justify-center"
      >
        <UILabel>
          An error has occurred opening Pivot from your LMS. Please reach out to
          customer support for assistance.
        </UILabel>
        <div class="flex py-6 justify-center space-x-8">
          <a v-bind:href="emailContent">
            <UIButton type="outline">Email Support</UIButton></a
          >
          <UIButton type="invisible" @click="copyToClipboard()"
            >Copy Error Message</UIButton
          >
          <UIButton v-if="intercomCheck" type="invisible" @click="openChat()"
            >Open Chat</UIButton
          >
        </div>
        <div class="w-full p-2 flex justify-center">
          <UITextInput
            class="w-full h-48"
            type="multiline"
            name="ErrorMessageBox"
            :modelValue="errorMessage"
          ></UITextInput>
        </div>
      </div>
    </card>
  </div>
</template>

<script setup>
import client from 'src/shared/api-client'
import { computed, onBeforeMount, ref } from 'vue'
import { UILabel, UIButton, UITextInput } from '@pi/design'
import { useFlash } from 'src/shared/hooks/flash'

let ltiSession
const errorMessage = ref('')
const flash = useFlash()

onBeforeMount(async () => {
  ltiSession = await client.lti.getSession()
  errorMessage.value = `${ltiSession?.context?.errorMessage}\n token: ${ltiSession?.context?.token}`
})

const emailContent = computed(() => {
  return `mailto:support@pivotInteractives.com?subject=Pivot LMS issue&body=I have an issue with opening Pivot from my LMS. { ${errorMessage.value} }`
})

async function copyToClipboard() {
  try {
    await navigator.clipboard.writeText(errorMessage.value)
    flash.success('Error has been copied to clipboard.')
  } catch (error) {
    flash.error('Something went wrong, please try again.')
  }
}

const intercomCheck = computed(() => {
  return !!window.Intercom
})
function openChat() {
  if (window.Intercom) {
    window.Intercom(
      'showNewMessage',
      `I'm having problems connecting LTI ${errorMessage.value}.`
    )
  }
}
</script>
