<template>
  <div class="line-item">
    <div class="full">
      <div class="flex space-between">
        <h2 class="sub-header">
          {{ item.description }}
        </h2>
      </div>
      <div class="sub-header flex space-between">
        <div class="text-bold">
          {{ formatInteger(item.quantity) }} x
          {{ $format.currency(item.unitPrice) }}
          ea.
        </div>
        <div class="flex-end">{{ $format.currency(item.totalPrice) }}</div>
      </div>
    </div>
  </div>
  <div class="divider" />
</template>

<script>
export default {
  name: 'LineItem',
  inject: ['$modal'],
  emits: ['refresh', 'change'],
  props: {
    quoteId: {
      type: String,
      required: false
    },
    quotePaid: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    formatInteger(number) {
      if (number) {
        return Number(number)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return number
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.full {
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 16px;
  margin-bottom: 16px;
  @media print {
    padding: 8px 16px;
    margin-bottom: 8px;
  }
}
h2 {
  margin: 0px 0px 8px 0px;
}
.banner {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 16px 64px;
  border: 1px solid #ccc;
  margin-bottom: 24px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgb(61 44 96 / 20%);
}
.flex {
  display: flex;
}
.ref-big {
  color: #3d2c60;
  font-weight: bold;
  font-size: 20px;
}
.text-timid {
  font-size: 20px;
  margin-bottom: 8px;
}
.text-bold {
  font-weight: 700;
}
.space-between {
  justify-content: space-between;
}
.sub-header {
  font-size: 20px;
  line-height: 30px;
  @media print {
    font-size: 16px;
    line-height: 24px;
  }
}
.sub--subheader {
  font-size: 14px;
  line-height: 1.6;
}

.edit-icon-btn,
.delete-icon-btn {
  display: inline-block;
  width: 20px;
  @media print {
    display: none;
  }
}
</style>
