<template>
  <UIForm @submit="onSubmit">
    <ClassSelector
      class="mx-auto mt-6 max-w-[720px]"
      :classes="classes"
      :className="className"
      :platformName="platformName"
      path="classId"
      required
      @refreshClassList="refreshList"
    >
      <template #actions>
        <div class="flex justify-end mt-5">
          <UIButton
            v-if="managing"
            class="mr-4"
            variant="destructive"
            type="outline"
            @click="cancel"
            >Cancel Changes</UIButton
          >
          <UIButton submit :disabled="!meta.valid">Connect Class</UIButton>
        </div>
      </template>
    </ClassSelector>
  </UIForm>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'

import { UIButton, UIForm } from '@pi/design'
import { Class } from '@pi/api-types'

import client from 'src/shared/api-client'
import { useFlash } from 'src/shared/hooks/flash'
import ClassSelector from '../components/ClassSelector.vue'
import { computed, watch } from 'vue'

const flash = useFlash()
const router = useRouter()
const emits = defineEmits(['refreshClassList'])

const props = defineProps<{
  platformName: string
  selectedClass?: string
  className?: string
  classes: Class[]
  managing: boolean
}>()

const { handleSubmit, meta, resetForm } = useForm<{ classId: string }>({
  initialValues: { classId: props.selectedClass },
  validateOnMount: true
})
watch(
  () => props.selectedClass,
  classId => {
    resetForm({ values: { classId } })
  }
)

const onSubmit = handleSubmit(async values => {
  await client.lti.connectClass({
    classId: values.classId
  })

  flash.success('Class connected successfully.')
  await router.push({ name: 'lti_connect_assignment' })
})

function refreshList() {
  emits('refreshClassList', true)
}

async function cancel() {
  router.back()
}
</script>
