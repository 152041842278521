<template>
  <p v-if="hasName">{{ fullName }}</p>
</template>

<script>
export default {
  name: 'ActivityStudentName',
  inject: ['inherited'],
  computed: {
    response() {
      return this.inherited.response
    },
    hasName() {
      return this.response.students && this.response.students[0].firstName
    },
    fullName() {
      const { firstName, lastName } = this.response.students[0]
      return `${firstName} ${lastName}`
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
</style>
