<template>
  <flash-provider>
    <error-boundary>
      <modal-provider class="fullscreen-provider" ref="modal">
        <div class="app">
          <message-bars />
          <main-navigation />
          <transition name="fade" mode="out-in">
            <pi-router-view
              class="view container"
              :class="{ 'view--lti': isLtiMode }"
            />
          </transition>
          <footer-navigation />
        </div>
      </modal-provider>
    </error-boundary>
  </flash-provider>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MainNavigation from 'src/shared/components/navigation/MainNavigation'
import FooterNavigation from 'src/shared/components/navigation/FooterNavigation'
import PiRouterView from 'src/setup/router/RouterView'
import ModalProvider from 'src/shared/components/modals/components/ModalProvider'
import MessageBars from 'src/shared/components/message-bar/MessageBars'
import ErrorBoundary from 'src/shared/components/ErrorBoundary'
import FlashProvider from 'src/shared/components/FlashProvider'
import MFASecretModal from 'src/shared/components/modals/MFASecretModal.vue'
import client from 'src/shared/api-client'
import ChooseLoginMethodModal from './modules/auth/modals/ChooseLoginMethodModal.vue'

export default {
  components: {
    MainNavigation,
    FooterNavigation,
    PiRouterView,
    ModalProvider,
    MessageBars,
    ErrorBoundary,
    FlashProvider
  },
  computed: {
    ...mapGetters(['isLtiMode']),
    ...mapGetters('features', ['isEnabled']),
    ...mapGetters(['loginMethods']),
    ...mapState({
      shouldUpdate: state => state.version.shouldUpdate,
      canUpdate: state => state.version.canUpdate,
      user: state => state.auth.user
    })
  },
  watch: {
    shouldUpdate() {
      if (this.shouldUpdate && this.canUpdate) {
        window.location.reload(true)
      }
    },
    canUpdate() {
      // can update may have changed when should update is true
      if (this.canUpdate && this.shouldUpdate) {
        window.location.reload(true)
      }
    },
    async user(newValue) {
      if (newValue && this.loginMethods.length > 1) {
        await this.$refs.modal.show(ChooseLoginMethodModal)
      }

      if (
        ['admin', 'sales_admin'].includes(newValue?.role) &&
        newValue?.authType === 'password' &&
        !newValue?.hasMFA &&
        PI_ENV === 'production'
      ) {
        await this.configureMFA()
      }
    }
  },
  methods: {
    async configureMFA() {
      const mfaResponse = await client.auth.generateMFA()
      const { status } = await this.$refs.modal.show(MFASecretModal, {
        secret: mfaResponse.secret,
        uri: mfaResponse.uri,
        qr: mfaResponse.qr,
        message:
          'As a platform admin you must setup MFA before continuing. You can also log in with Microsoft SSO and skip MFA.',
        enforce: true
      })

      if (status === 'ok') {
        this.setHasMFA(true)
        this.$success('Multi-factor authentication configured succssfully.')
      }
    }
  }
}
</script>
<style lang="scss">
.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.view {
  flex: 1 1 100%;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.view--lti {
  padding-top: 16px;
  padding-bottom: 16px;
}

.pi-btn {
  margin-right: 15px;
  background-color: $teal;
  color: #ffffff;
  border-radius: 8px 0 8px 0;
  font-weight: 700;
  padding: 14px 22px;
  border: none;
  vertical-align: middle;
  &:hover,
  &:focus {
    background-color: $teal;
  }
}

.pi-btn.shadow {
  box-shadow: -8px 8px 0px 0px $plum;
  margin-bottom: 8px;
  &:hover,
  &:focus {
    background-color: $plum;
    box-shadow: -8px 8px 0px 0px $teal;
  }
}

.pi-btn-small {
  background-color: $teal;
  border: $teal solid 1px;
  color: #ffffff;
  border-radius: 6px;
  font-weight: 700;
  padding: 7px 14px;
  vertical-align: middle;
  &:hover,
  &:focus {
    background-color: $darker-teal;
    border: $darker-teal solid 1px;
  }
  .pi-btn-icon {
    padding-right: 5px;
  }
}

.pi-btn-corners {
  border-radius: 8px 0 8px 0;
}

.pi-btn-light {
  background-color: #ffffff;
  color: $teal;
  border: $teal solid 1px;
  &:hover,
  &:focus {
    background-color: $darker-teal;
    color: #ffffff;
  }
}

.pi-cancel {
  margin-right: 15px;
}

.pi-header-actions {
  margin-top: 10px;
  > div {
    padding: 0;
  }
  .pi-header-action {
    margin: 0 0 0 16px;
  }
}

.router-link-active > a > span {
  color: $teal;
  border-bottom: 2px solid $plum;
  text-decoration: none;
}

.lead {
  font-size: ceil($font-size-base * 1.25);
}

.pi-form-intro {
  margin-top: 10px;
  margin-bottom: 20px;
}

.pi-form-control {
  &:focus {
    border-color: #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: $border-radius-base;
  }
}

.control-label {
  font-weight: 400;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.control-label-nested {
  font-size: 14px;
}

.pi-intro-text,
.pi-features {
  h1 {
    font-size: 52px;
    font-weight: normal;
    .underline {
      margin: 0;
    }
  }
  h2 {
    font-size: 25px;
    font-weight: 700;
  }
  .pi-btn.shadow {
    margin: 66px 0 20px 0;
  }
  .science {
    font-weight: 900;
    color: $pink;
  }
}

// bootstrap override
.close {
  float: right;
  height: 24px;
  width: 24px;
}

.panel-collapse {
  display: block !important;
  height: 0;
  overflow: hidden;
  transition: 300ms ease all;
  visibility: hidden;
  &.in {
    display: block !important;
    height: auto;
    overflow: visible;
    visibility: visible;
  }
}

// Alert Messages

.print {
  display: none;
  height: 0;
}

// Muted Colors
.text-muted {
  color: $color-muted;
}

// Danger/Error Colors
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label,
.text-danger {
  color: $color-error;
}

// Success Colors
.text-success {
  color: $color-success;
}

// Warning Colors
.text-warning {
  color: $color-warning;
}

.fade-in {
  opacity: 0;
  animation: fadeIn ease 1;
  animation-fill-mode: both;
  animation-duration: 1s;
}

// Dropdown menu
.dropdown-menu > li > a.pi-disabled {
  pointer-events: none;
  cursor: none;
  color: $silver;
}

.fullscreen-provider {
  height: 100vh;
  width: 100vw;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: $screen-xs-max) {
  .pi-intro-text,
  .pi-features {
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 18px;
    }
  }
}

/* Small devices (tablets, 768px) to medium devices (desktop) 1200px */
@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
  .pi-intro-text,
  .pi-features {
    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 26px;
    }
  }
}

/* styles for print preview page */
@media print {
  .collapse {
    display: block !important;
    height: auto !important;
  }

  .page-break {
    display: block;
    page-break-inside: avoid;
  }

  .edit-row-label {
    width: 32px;
  }

  .column-selector {
    height: 300px;
    margin-top: 25px;
    display: none !important;
  }

  #app,
  .container {
    // page size minus margins
    width: 7.5in !important;
  }

  #app {
    padding-bottom: 0;
  }

  .graph {
    display: flex;
    // because our html is backwards!
    flex-wrap: wrap-reverse;
  }

  .graph > .plot,
  .graph > .column-selector {
    // make these full width so they wrap
    width: 100%;
  }

  .modal-provider {
    height: auto !important;
  }

  @page {
    size: 8.5in 11in;
    margin-top: 0.5in;
    margin-left: 0.5in;
    margin-right: 0.5in;
    margin-bottom: 0.5in;
  }
}
</style>
