<template>
  <div class="line-item" v-if="quote.purchaseType">
    <div class="full">
      <div class="flex space-between">
        <h2 class="sub-header">
          {{ licenseType }}
        </h2>
      </div>
      <div v-if="showTimeframe" class="light-grey">
        {{ printDate(quote.startDate, 'startDate') }} -
        {{ printDate(quote.endDate, 'endDate') }}
        <div class="date-footnote">
          Licenses become active at 12AM UTC on the start date, and expire at
          11:59 PM UTC on the end date. In {{ timeZone() }}, this license
          becomes active at {{ localDate(quote.startDate) }} and inactive at
          {{ localDate(quote.endDate) }}.
        </div>
      </div>
      <p></p>
      <div v-if="showCost" class="sub-header flex space-between">
        <div class="text-bold">
          {{ formatInteger(quote.seats) }}
          {{ quote.purchaseType === 'access-codes' ? 'codes' : 'seats' }} x
          {{
            $format.currency(quote.proratedCostPerSeat || quote.pricePerSeat)
          }}
          ea.
          <span
            v-if="
              quote.proratedCostPerSeat &&
              quote.proratedCostPerSeat !== quote.pricePerSeat
            "
            >(Prorated)</span
          >
        </div>
        <div>{{ $format.currency(quote.productPrice) }}</div>
      </div>
      <div v-if="quote.proratedDays">
        (Prorated for {{ quote.proratedDays }}
        {{ quote.proratedDays === 1 ? 'day' : 'days' }} remaining on license)
      </div>
      <p
        class="order-banner__advanced-features"
        v-if="['renewal', 'upgrade', 'license'].includes(quote.purchaseType)"
      >
        For licenses over 500 seats and features such as LMS integration and
        rostering, standards alignment crosswalks, and customized professional
        development, please
        <hyper-link :to="{ name: 'de_sales_inquiry' }">
          connect with our sales team here</hyper-link
        >.
      </p>
    </div>
  </div>
  <div class="line-items">
    <line-item
      v-for="item in quote.lineItems"
      :key="item.id"
      :quoteId="quote.id"
      :item="item"
      :quotePaid="quote.paid"
      @refresh="refreshLineItem"
    />
  </div>

  <div v-if="!salesTax" class="charge">
    <div>
      <h4 class="section-header">Sales Tax</h4>
      <p class="charge-info wispy">If applicable, tax calculated at checkout</p>
    </div>
    <h4>--</h4>
  </div>
  <div class="divider" />
  <div class="charge">
    <div>
      <h4 class="section-header">Subtotal</h4>
    </div>
    <h4>
      {{ $format.currency(quote.subtotal) }}
    </h4>
  </div>
  <p class="intl-tax-message" v-if="quote.isInternational">
    The amounts shown are exclusive of local taxes, which the customer will be
    liable for at the relevant prevailing rate
  </p>
</template>

<script>
import { mapGetters } from 'vuex'
import LineItem from './LineItem'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export default {
  name: 'OrderBanner',
  components: { LineItem },
  emits: ['refresh', 'lineitemchange', 'lineitemadd'],
  props: {
    quote: {
      type: Object,
      required: true
    },
    printing: {
      type: Boolean,
      required: false,
      default: false
    },
    allowAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    salesTax: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    licenseType() {
      const licenseTypes = {
        'high-school': '6–12 Institution License',
        college: 'College Institution License',
        'bookstore-purchase': 'Course Codes',
        student: 'Student Paid Access'
      }

      if (this.quote.purchaseType === 'access-codes') {
        if (this.quote.licenseLevel === 'college') {
          return 'College Access Codes'
        } else {
          return 'High School Access Codes'
        }
      }

      if (this.quote.licenseType === 'institution') {
        let licenseLabel = licenseTypes[this.quote.licenseLevel]
        if (this.quote.purchaseType === 'renewal') {
          licenseLabel += ' Renewal'
        } else if (this.quote.purchaseType === 'upgrade') {
          licenseLabel += ' Upgrade'
        } else if (this.quote.purchaseType === 'added-seats') {
          licenseLabel += ' Added Seats'
        }
        return licenseLabel
      }

      return licenseTypes[this.quote.licenseType]
    },
    showCost() {
      return this.quote.licenseType && this.quote.licenseType !== 'student'
    },
    showTimeframe() {
      return (
        this.quote.licenseType &&
        this.quote.purchaseType !== 'added-seats' &&
        this.quote.licenseType === 'institution'
      )
    }
  },
  methods: {
    formatInteger(number) {
      if (number) {
        return Number(number)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return number
      }
    },
    printDate(date, type) {
      if (date) {
        return format(utcToZonedTime(new Date(date), 'Etc/GMT'), 'MM/dd/yyyy')
      } else {
        return ''
      }
    },
    localDate(date) {
      if (date) {
        return format(new Date(date), 'MM/dd/yyyy hh:mm a')
      } else {
        return ''
      }
    },
    timeZone() {
      return new Date()
        .toLocaleString('en-US', { timeZoneName: 'long' })
        .split(' ')
        .slice(3)
        .join(' ')
    },
    refreshLineItem() {
      this.$emit('refresh')
    }
  }
}
</script>
<style lang="scss" scoped>
.full {
  width: 100%;
}
h2 {
  margin: 0px 0px 8px 0px;
}
.banner {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 16px 64px;
  border: 1px solid #ccc;
  margin-bottom: 24px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgb(61 44 96 / 20%);
}
.pad-40 {
  margin: 0px 0px 40px 0px;
}
.light-grey {
  color: #716a6d;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin: 0px 0px 8px 0px;
  @media print {
    font-size: 14px;
    line-height: normal;
  }
}
.flex {
  display: flex;
}
.ref-big {
  color: #3d2c60;
  font-weight: bold;
  font-size: 20px;
}
.text-timid {
  font-size: 20px;
  margin-bottom: 8px;
}
.text-bold {
  font-weight: 700;
}
.space-between {
  justify-content: space-between;
}
.sub-header {
  font-size: 20px;
  line-height: 30px;
  @media print {
    font-size: 16px;
    line-height: 24px;
  }
}
.sub--subheader {
  font-size: 14px;
  line-height: 1.6;
}
.section-header {
  @media print {
    font-size: 16px;
    padding: 0px;
    margin: 0px;
  }
}
.line-items {
  padding-top: 16px;
  @media print {
    padding: 0px;
  }
}

.line-item {
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 16px;
  @media print {
    padding: 8px;
  }
}
.charge {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: baseline;

  @media print {
    margin: 0px;
  }
}
.charge-info {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.008em;
  text-align: left;
  @media print {
    font-size: 12px;
    line-height: 18px;
  }
}
.wispy {
  color: #716a6d;
}
.add-item-btn {
  margin-top: 10px;
  @media print {
    display: none;
  }
}

.order-banner__advanced-features {
  @media print {
    a[href]:after {
      display: none;
      visibility: hidden;
    }
  }
}
.date-footnote {
  border-top: 1px solid #c4c4c4;
  font-size: 12px;
  line-height: 18px;
  @media print {
    font-size: 10px;
    line-height: 14px;
  }
}

.intl-tax-message {
  font-style: italic;
}
</style>
