<template>
  <div v-if="isScorable || showComments" class="feedback">
    <p v-if="submissionsUsedText" class="submissions_message">
      {{ submissionsUsedText }}
    </p>

    <div class="feedback__row-1">
      <div class="feedback__score">
        <slot name="score" />
      </div>
      <div class="feedback__comments">
        <slot name="comments" />
      </div>
    </div>
  </div>
</template>

<script>
import { hasContent } from 'src/shared/components/editor/utils.js'

export default {
  name: 'ActivityQuestionFeedback',
  inject: ['inherited'],
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    isScorable() {
      if (!this.inherited.response?.assignment?.showScore) {
        return false
      }

      return this.component.pointValue && this.component.pointValue > 0
    },
    componentResponse() {
      return this.inherited.componentResponse
    },
    showComments() {
      return (
        this.component.componentType.includes('Question') &&
        (!this.readonly || hasContent(this.componentResponse.comments)) &&
        this.inherited.response?.assignment?.showFeedback
      )
    },
    canRespond() {
      return this.inherited.canRespond
    },
    isCorrect() {
      return this.componentResponse.correct ?? this.componentResponse.isCorrect
    },
    submissionsRemaining() {
      return this.component.autogradeLimit
        ? this.component.autogradeLimit -
            (this.componentResponse.submissions ?? 0)
        : Infinity
    },
    submissionsUsedText() {
      if (
        this.submissionsRemaining > 0 ||
        (this.submissionsRemaining === 0 && this.isCorrect)
      ) {
        return ''
      }
      if (!this.readonly) {
        return 'This student exhausted their submissions for this question, and can no longer receive credit from the autograder even if they get the answer correct.'
      }
      if (this.canRespond && !this.isCorrect) {
        return 'Because you have used all of your submissions, you can no longer get credit for this question, but you can continue to submit your work to get feedback.'
      }
      return 'Because you used more than your allowed number of submissions, you cannot get credit for this question'
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback {
  background-color: #eee;
  padding: 10px;
  margin: 8px 0;

  &__row-1 {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__score {
    flex-shrink: 0;
    min-width: 100px;
    margin: 0 16px 0 0;
  }

  &__comments {
    min-width: 0;
    flex-grow: 1;
  }

  &__autograde-limit {
    margin-top: 8px;
  }

  .submissions_message {
    font-size: small;
    font-style: italic;
  }
}
</style>
