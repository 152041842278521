import LtiPlatformRegistrationView from 'src/modules/lti/views/LtiPlatformRegistrationView'
import LtiPlatformRegistrationCanvasView from 'src/modules/lti/views/LtiPlatformRegistrationCanvasView'
import LtiPlatformRegistrationD2LView from 'src/modules/lti/views/LtiPlatformRegistrationD2LView'
import LtiPlatformRegistrationSchoologyView from 'src/modules/lti/views/LtiPlatformRegistrationSchoologyView'
import LtiPlatformRegistrationOtherView from 'src/modules/lti/views/LtiPlatformRegistrationOtherView'
import LtiPlatformsView from 'src/modules/lti/views/LtiPlatformsView'
import LtiEditPlatformView from 'src/modules/lti/views/LtiEditPlatformView'
import LtiConnectAccountView from 'src/modules/lti/views/LtiConnectAccountView'
import LtiLoginView from 'src/modules/lti/views/LtiLoginView'
import LtiConnectClassView from 'src/modules/lti/views/LtiConnectClassView'
import LtiConnectAssignmentView from 'src/modules/lti/views/LtiConnectAssignmentView'
import LtiConnectExistingClassView from 'src/modules/lti/views/LtiConnectExistingClassView'
import LtiClassPaymentView from 'src/modules/lti/views/LtiClassPaymentView'
import LtiAssignActivityView from 'src/modules/lti/views/LtiAssignActivity'
import LtiView from 'src/modules/lti/views/LtiView'
import LtiErrorView from 'src/modules/lti/views/LtiErrorView'

export default store => [
  {
    name: 'lti_platform_registration',
    path: '/lti/platforms/register',
    component: LtiPlatformRegistrationView,
    meta: { title: 'LTI Registration', requiresAuth: true }
  },
  {
    name: 'lti_canvas_registration',
    path: '/lti/platforms/register/canvas',
    component: LtiPlatformRegistrationCanvasView,
    meta: { title: 'LTI Registration - Canvas', requiresAuth: true }
  },
  {
    name: 'lti_schoology_registration',
    path: '/lti/platforms/register/schoology',
    component: LtiPlatformRegistrationSchoologyView,
    meta: { title: 'LTI Registration - Schoology', requiresAuth: true }
  },
  {
    name: 'lti_d2l_registration',
    path: '/lti/platforms/register/d2l',
    component: LtiPlatformRegistrationD2LView,
    meta: { title: 'LTI Registration - D2l Brightspace', requiresAuth: true }
  },
  {
    name: 'lti_other_registration',
    path: '/lti/platforms/register/other',
    component: LtiPlatformRegistrationOtherView,
    meta: { title: 'LTI Registration - Other', requiresAuth: true }
  },
  {
    name: 'lti_platforms',
    path: '/lti/platforms',
    component: LtiPlatformsView,
    meta: { title: 'LTI Platforms', requiresAuth: true }
  },
  {
    name: 'lti_edit_platform',
    path: '/lti/platforms/:id',
    component: LtiEditPlatformView,
    props: true,
    meta: { title: 'Edit LTI Platforms', requiresAuth: true }
  },
  {
    name: 'lti',
    path: '/lti',
    component: LtiView
  },
  {
    name: 'lti_login',
    path: '/lti/login/:step?',
    component: LtiLoginView,
    meta: {
      title: 'Log in',
      redirect: { name: 'lti_connect_account' }
    },
    props: route => ({
      redirect: route.query.redirect,
      step: route.params.step
    }),
    beforeEnter(to, from, next) {
      if (!to.params.step) {
        next({
          ...to,
          params: { ...to.params, step: 'account-select' }
        })
      } else {
        next()
      }
    }
  },
  {
    name: 'lti_class_payment',
    path: '/lti/payment',
    component: LtiClassPaymentView,
    meta: {
      title: 'Class Payment',
      requiresAuth: true,
      redirect: { name: 'lti_login' }
    }
  },
  {
    name: 'lti_error',
    path: '/lti/error',
    component: LtiErrorView,
    meta: {
      title: 'Error'
    }
  },
  {
    name: 'lti_connect_account',
    path: '/lti/account',
    component: LtiConnectAccountView,
    meta: {
      title: 'Connect Account',
      requiresAuth: true,
      redirect: { name: 'lti_login' }
    }
  },
  {
    name: 'lti_connect_class',
    path: '/lti/classes',
    component: LtiConnectClassView,
    meta: {
      title: 'Connect Class',
      requiresAuth: true,
      redirect: { name: 'lti_login' }
    }
  },
  {
    name: 'lti_connect_assignment',
    path: '/lti/assignments',
    component: LtiConnectAssignmentView,
    meta: {
      title: 'Connect Assignment',
      requiresAuth: true,
      redirect: { name: 'lti_login' }
    }
  },
  {
    name: 'lti_assign_activity',
    path: '/lti/assign-activity',
    component: LtiAssignActivityView,
    meta: {
      title: 'Assign Activity',
      requiresAuth: true,
      redirect: { name: 'lti_login' }
    },
    props: to => ({ query: to.query })
  },
  {
    name: 'lti_connect_existing_class',
    path: '/lti/connect-existing-class',
    component: LtiConnectExistingClassView,
    meta: {
      title: 'Connect Class',
      requiresAuth: true,
      redirect: { name: 'lti_login' }
    }
  }
]
