import {
  ResendInvitationRequest,
  GetInvitationRequest,
  GetInvitationResponse,
  SearchInvitationsRequest,
  SearchInvitationsResponse
} from '@pi/api-types'
import type Client from './client'

export default class Invitations {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async search(
    search: SearchInvitationsRequest
  ): Promise<SearchInvitationsResponse> {
    const params = new URLSearchParams({
      page: search.page ? search.page.toString() : '',
      sort: search.sort || '',
      q: search.q || '',
      limit: search.limit || ''
    })
    const { body } = await this.client.get<SearchInvitationsResponse>({
      path: `/api/v3/admin/invitations?${params}`
    })
    return body
  }

  async resend(token: ResendInvitationRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/admin/invitations/${token}/resend`
    })
  }

  async get(token: GetInvitationRequest): Promise<GetInvitationResponse> {
    const { body } = await this.client.get<GetInvitationResponse>({
      path: `/api/v3/invitations/${token}`
    })
    return body
  }
}
