<template>
  <span class="title"> Viewing {{ title }}</span>
</template>

<script>
export default {
  name: 'ProgressSummary',
  inject: ['progress'],
  computed: {
    title() {
      return this.progress.summary
    }
  }
}
</script>
