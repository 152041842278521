<template>
  <view-container narrow>
    <view-header>
      <view-title>Class Payment</view-title>
    </view-header>
    <class-payment-form
      :class-key="classKey"
      @invalidorder="onInvalidOrder"
      @submit="onSubmit"
    />
  </view-container>
</template>

<script>
import ClassPaymentForm from '../components/ClassPaymentForm'

export default {
  name: 'JoinClassPaymentView',
  components: {
    ClassPaymentForm
  },
  props: {
    classKey: {
      type: String,
      default: undefined
    },
    redirect: {
      type: String,
      default: undefined
    }
  },
  methods: {
    onInvalidOrder() {
      const { classKey, redirect } = this
      this.$router.push({ name: 'join_class', query: { classKey, redirect } })
    },
    onSubmit() {
      this.$router.push(this.redirect || { name: 'classes' })
    }
  }
}
</script>
