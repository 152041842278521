<template>
  <modal class="version-error-modal" @submit="copy">
    <modal-header>Change Conflict</modal-header>
    <modal-body>
      <p>
        Oops! Someone else saved over this activity while you were editing it.
      </p>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Save as New</modal-button-submit>
      <modal-button-action @click="discard">
        Discard My Changes
      </modal-button-action>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import client from 'src/shared/api-client'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonAction,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

export default {
  name: 'ActivityVersionErrorModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonAction,
    ModalButtonCancel
  },
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  methods: {
    async copy({ done }) {
      try {
        const { id: _, ...activityFields } = this.activity
        const activity = {
          ...activityFields,
          name: `Copy of ${this.activity.name}`
        }
        const { id } = await client.libraries.createActivity({
          libraryId: this.activity.library.id,
          ...activity
        })
        this.$router.push({ name: 'activity', params: { id } })
        this.$success('Activity copied.')
        done()
      } catch (error) {
        done(false)
        throw error
      }
    },
    discard() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.version-error-modal {
  width: 406px;
}
</style>
