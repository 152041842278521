<template>
  <li>
    <click-to-copy class="click-to-copy" :copy-text="value">
      <!-- @slot The content of the dropdown item. -->
      <slot />
    </click-to-copy>
  </li>
</template>

<script>
import ClickToCopy from 'src/shared/components/ClickToCopy'
export default {
  name: 'DropdownCopy',
  components: { ClickToCopy },
  props: {
    /** The content to copy. */
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.click-to-copy {
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #333333;
  display: block;
  width: 100%;
  text-align: left;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.6;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: #262626;
    background-color: #f5f5f5;
    outline: none;
  }
  &:focus {
    background-color: #f5f5f5;
    text-decoration: underline;
  }

  &:disabled {
    pointer-events: none;
    cursor: none;
    color: $silver;
  }
}
</style>
