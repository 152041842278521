<template>
  <NoAuthActivityView
    v-if="!isAuthenticated || !isLicenseActive"
    :id="this.id"
  />
  <ActivityPreviewView v-else :id="this.id" />
</template>

<script>
import { mapGetters } from 'vuex'
import NoAuthActivityView from 'src/modules/activities/views/NoAuthActivityView'
import ActivityPreviewView from 'src/modules/activities/views/ActivityPreviewView'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: { NoAuthActivityView, ActivityPreviewView },
  computed: {
    ...mapGetters(['isAuthenticated', 'isLicenseActive'])
  }
}
</script>
