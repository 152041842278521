<template>
  <div>
    <teacher-classes-view
      v-if="canManageClasses"
      v-bind="$props"
    ></teacher-classes-view>
    <student-classes-view v-if="canDoAssignments"></student-classes-view>
  </div>
</template>

<script>
import StudentClassesView from 'src/modules/classes/views/StudentClassesView'
import TeacherClassesView from 'src/modules/classes/views/TeacherClassesView'
import { mapGetters } from 'vuex'

export default {
  components: {
    StudentClassesView,
    TeacherClassesView
  },
  props: ['isArchived', 'page'],
  computed: {
    ...mapGetters(['canManageClasses', 'canDoAssignments'])
  }
}
</script>
