<template>
  <div class="row">
    <div class="col-sm-6">
      <number-input
        v-model="value"
        class="numerical-question-response"
        readonly
      />
      <span class="units">{{ units }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumericalResponse',
  props: {
    component: {
      type: Object
    },
    response: {
      type: Object
    }
  },
  data() {
    return {
      answerExpression: '',
      accuracy: '',
      units: ''
    }
  },
  mounted() {
    if (this.component && this.component.answer) {
      // this is a placeholder until the assignment response has the computed values
      this.answerExpression = this.component.answer
      this.accuracy = this.component.accuracy
      this.units = this.component.units
    }
  },
  computed: {
    value: {
      get() {
        return this.response ? this.response.value : ''
      }
    }
  }
}
</script>

<style lang="scss">
.numerical-question-response {
  .form-control {
    width: 150px;
  }
}
.units {
  padding-left: 15px;
}
</style>
