<template>
  <div v-bind="wrapperAttrs" :class="errorMessage ? 'has-error' : ''">
    <multiselect
      ref="input"
      v-bind="$attrs"
      v-model="inputVal"
      class="pi-select-control"
      :mode="multiple || override ? 'tags' : 'single'"
      :options="sanitizedOptions"
      :valueProp="valueKey"
      :label="labelKey"
      :trackBy="labelKey"
      :placeholder="placeholder"
      :searchable="true"
      :max="!multiple && override ? 1 : -1"
      :createTag="override"
      :aria="{
        'aria-label': ariaLabel,
        'aria-describedby': `${helpId} ${ariaDescribedby}`
      }"
      @focusout="handleBlur"
      @search-change="handleSearchChange"
    />
    <span v-if="errorMessage || helpText" :id="helpId" class="help-block">
      {{ errorMessage || helpText }}
    </span>
  </div>
</template>

<script>
import { toRef, computed, ref } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useField } from 'vee-validate'
import DOMPurify from 'dompurify'
import throttle from 'lodash/throttle'
let counter = 0

export default {
  name: 'Autocomplete',
  components: {
    Multiselect
  },
  inheritAttrs: false,
  emits: ['input', 'update:modelValue'],
  props: {
    modelValue: {
      type: [String, Array]
    },
    options: {
      type: [Array, Function],
      required: true
    },
    labelKey: {
      type: String,
      default: undefined
    },
    valueKey: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      default: 'field'
    },
    name: {
      type: String,
      // Names must be unique between different inputs.
      default: () => `autocomplete-${counter++}`
    },
    rules: {
      type: [String, Object],
      default: null
    },
    override: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: props.modelValue,
      validateOnValueUpdate: false,
      syncVModel: false
    })

    const dynamicOptions = ref([])
    const sanitizedOptions = computed(() => {
      const optionsToMap =
        typeof props.options === 'function'
          ? dynamicOptions.value
          : props.options
      return optionsToMap.map(option => {
        if (typeof option === 'string' || typeof option === 'number') {
          return {
            value: option,
            label: DOMPurify.sanitize(option)
          }
        } else {
          const labelKey = props.labelKey ?? 'label'
          return {
            ...option,
            [labelKey]: DOMPurify.sanitize(option[labelKey])
          }
        }
      })
    })
    const handleSearchChange = throttle(async query => {
      if (typeof props.options === 'function') {
        const response = await props.options(query)
        dynamicOptions.value = response
      }
    }, 500)

    return {
      handleSearchChange,
      sanitizedOptions,
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField
    }
  },
  computed: {
    helpId() {
      return `${this.name.replace(' ', '-')}-help`
    },
    inputVal: {
      get() {
        if (this.override && !this.multiple) return [this.modelValue]
        else return this.modelValue
      },
      set(val) {
        if (this.override && !this.multiple) {
          this.handleChange(val[0])
          this.$emit('update:modelValue', val[0])
          this.$emit('input', val[0])
        } else {
          this.handleChange(val)
          this.$emit('update:modelValue', val)
          this.$emit('input', val)
        }
      }
    },
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    inputAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    clear() {
      this.$refs.input.clear()
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue !== this.inputValue) {
        this.resetField({ value: this.modelValue })
      }
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss" scoped>
.error-border {
  border: 1px solid $color-error;
  border-radius: 8px;
}

:deep() {
  .multiselect {
    font-size: 14px;
    padding: 0;
    line-height: 1.6;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    min-height: 36px;

    &.is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.is-active {
      box-shadow: 0 0 5px rgb(0 0 0 / 50%);
    }
  }

  .multiselect-tags {
    padding: 6px 4px;
    margin: 0;
  }

  .multiselect-tag {
    font-size: 14px;
    background: $teal;
    color: white;
    padding: 6px;
    margin: 0 4px 4px 0;

    &-remove {
      width: 28px;
      height: 28px;
      margin: -6px -8px -6px 2px;

      &-icon {
        width: 14px;
        height: 14px;
      }
    }
  }

  .multiselect-search:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .multiselect-tags-search-wrapper {
    margin: 0;
    width: 100%;
  }

  .multiselect-tags-search {
    height: 22px;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  .multiselect-clear {
    width: 34px;
    height: 34px;
    padding: 0;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
  }

  .multiselect-clear-icon {
    width: 14px;
    height: 14px;
  }

  .multiselect-caret {
    width: 16px;
    height: 16px;
    margin: 9px;
    align-self: flex-end;
  }

  .multiselect-dropdown {
    border: 1px solid #ccc;
    max-height: 160px;
    z-index: 101;
  }

  .multiselect-options {
    max-height: 160px;
  }

  .multiselect-option {
    font-size: 14px;
  }
}
</style>
