<template>
  <modal @submit="submit" class="merge-license-modal">
    <modal-provider>
      <modal-header>Merge Licenses</modal-header>
      <modal-body>
        <div class="merge-license-modal__search-input">
          <form-label>Select a target license to merge into:</form-label>
          <autocomplete
            :modelValue="chosenId"
            @update:modelValue="getLicense"
            :options="loadLicenses"
            label-key="name"
            value-key="id"
            placeholder="Search licenses"
            aria-label="Search licenses"
          />
        </div>
        <div class="merge-license-modal__selected-license-info">
          <div v-if="targetLicense && !duplicateError">
            <hyper-link
              target="_blank"
              :to="{
                name: 'admin_manage_license',
                params: { id: targetLicense.id, tab: 'manage' }
              }"
              class="merge-license-modal__selected-license-title"
            >
              <p>{{ targetLicense.name }}</p>
            </hyper-link>

            <p>
              <span class="merge-license-modal__target-field"
                >Live Seat Count:</span
              >
              {{ targetLicense.liveSeatCount }}
            </p>
            <p>
              <span class="merge-license-modal__target-field">Salesforce:</span>
              {{ targetLicense.salesforce }}
            </p>
            <p>
              <span class="merge-license-modal__target-field">Domain:</span>
              {{ targetLicense.emailDomain }}
            </p>
            <p>
              <span class="merge-license-modal__target-field">Seat Limit:</span>
              {{ targetLicense.maxSeats }}
            </p>
            <p>
              <span class="merge-license-modal__target-field">Seats Used:</span>
              {{ targetLicense.seatsUsed }}
            </p>
          </div>
          <div v-if="targetLicense && !duplicateError">
            <p>
              <span class="merge-license-modal__target-field">Start Date:</span>
              {{ targetLicense.startDate }}
            </p>
            <p>
              <span class="merge-license-modal__target-field">End Date:</span>
              {{ targetLicense.endDate }}
            </p>
            <p>
              <span class="merge-license-modal__target-field">Status:</span>
              {{ targetLicense.status }}
            </p>
            <p>
              <span class="merge-license-modal__target-field">Type:</span>
              {{ targetLicense.type }}
            </p>
            <p>
              <span class="merge-license-modal__target-field">Level:</span>
              {{ targetLicense.level }}
            </p>
          </div>
        </div>
        <span v-if="duplicateError" class="merge-license-modal__duplicate-error"
          >You cannot merge a license into itself.</span
        >
      </modal-body>
    </modal-provider>
    <modal-actions>
      <modal-button-submit> Merge </modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonCancel,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import ModalProvider from 'src/shared/components/modals/components/ModalProvider'
import ConfirmModal from './ConfirmModal.vue'
import { ref, computed, inject } from 'vue'

import Autocomplete from 'src/shared/components/Autocomplete.vue'
import client from 'src/shared/api-client'

const props = defineProps({
  sourceLicense: {
    type: Object,
    required: true
  }
})

const chosenId = ref()
const targetLicense = ref()

const duplicateError = computed(() => chosenId.value === props.sourceLicense.id)
const getLicense = async licenseId => {
  if (licenseId) {
    chosenId.value = licenseId
    const response = await client.licenses.get({ id: licenseId })
    targetLicense.value = response
  } else {
    chosenId.value = null
    targetLicense.value = null
  }
}
const loadLicenses = async licenseQuery => {
  if (!licenseQuery) {
    return []
  } else {
    const response = await client.licenses.find({
      status: ['active', 'expiring', 'trial'],
      page: 1,
      limit: 10,
      q: licenseQuery
    })
    return response.page
  }
}

const submit = async e => {
  e.done({ targetLicense: targetLicense.value })
}
</script>

<style lang="scss" scoped>
.merge-license-modal {
  resize: both;
  overflow: hidden;
  min-width: 500px;
  min-height: 425px;
}
.merge-license-modal__selected-license-info {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  height: 250px;
}
.merge-license-modal__selected-license-info p {
  font-size: 16px;
}

.merge-license-modal__duplicate-error {
  color: $color-error;
}
.merge-license-modal__target-field {
  font-weight: bold;
}
</style>
