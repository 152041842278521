const VALUES_MAP = {
  GeneralNote: 'Announcement',
  GeneralInstruction: 'Numbered Instruction',
  GridGraphQuestion: 'Data Table & Graph',
  GridQuestion: 'Data Table',
  OpenEndedQuestion: 'Open Ended Question',
  EmbeddedInstance: 'Video Instance',
  StudentInstance: 'Student Instance Upload',
  InstructorInstance: 'Instructor Instance Upload',
  MultipleChoiceQuestion: 'Multiple Choice Question',
  IFrame: 'Embedded Web Content',
  PhetIOSim: 'Legacy PhET-iO Simulation',
  PhetIOSimulation: 'PhET-iO Simulation',
  NumericalQuestion: 'Numerical Question',
  DragDropQuestion: 'Drag & Drop Question'
}

export default function humanize(componentType) {
  return VALUES_MAP[componentType] || 'Default Component'
}
