<template>
  <pivot-instance
    v-if="isInstructorInstanceComponent"
    :settings="settings"
    :limit-functionality="limitFunctionality"
  />
</template>

<script>
import PivotInstance from 'src/shared/components/PivotInstance'
import generateInstanceSettings from 'src/shared/components/config/instanceSettings'

export default {
  name: 'ActivityInstructorInstance',
  inject: ['inherited'],
  components: {
    PivotInstance
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    limitFunctionality: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    componentResponse() {
      return this.inherited.componentResponse
    },
    isInstructorInstanceComponent() {
      return this.component.componentType === 'InstructorInstance'
    },
    settings() {
      try {
        const { playbackFrameRate, recordedFrameRate, src, calibration } =
          JSON.parse(this.component.instanceMetadata)

        return generateInstanceSettings(
          playbackFrameRate,
          recordedFrameRate,
          src,
          { ...calibration, editable: false }
        )
      } catch (error) {
        this.$error(error.message)
        throw error
      }
    }
  }
}
</script>
