<template>
  <editor-bubble-menu
    plugin-key="image"
    :editor="editor"
    :tippy-options="{ placement: 'bottom' }"
    :should-show="shouldShow"
  >
    <div>
      <editor-menu-button
        label="Edit"
        title="Edit Source"
        icon="pencil"
        @click="editor.chain().openImageModal().run()"
      />
    </div>
  </editor-bubble-menu>
</template>

<script setup>
import { ref, computed } from 'vue'
import { isActive } from '@tiptap/core'
import EditorBubbleMenu from '../components/EditorBubbleMenu'
import EditorMenuButton from '../components/EditorMenuButton'

const props = defineProps({
  editor: {
    type: Object,
    required: true
  }
})

const shouldShow = computed(
  () =>
    ({ state }) =>
      isActive(state, 'image')
)
</script>
