<template>
  <div
    ref="popoverAnchorRef"
    @mouseover="openPopover"
    @mouseleave="closePopover"
  >
    <router-link
      class="user-date-expired"
      :to="{
        name: 'existing_class_roster',
        params: { id: classId }
      }"
    >
      <span class="user-date-expired" t aria-hidden="true" />
      {{ endDateDisplay(endDate) }}
      <icon icon="triangle-exclamation" />
    </router-link>
    <base-popover
      :anchor-ref="popoverAnchorRef"
      :visible="popoverVisible"
      placement="bottom"
    >
      {{ endDateHelper(endDate) }}
    </base-popover>
  </div>
</template>

<script>
import { addDays, startOfDay, differenceInCalendarDays, format } from 'date-fns'
const OFFLOAD_THRESHOLD = 180
const DROP_THRESHOLD = 30
export default {
  name: 'DatePopover',
  props: {
    classId: {
      type: String,
      default: ''
    },

    endDate: {
      type: Date,
      default: ''
    }
  },
  data() {
    return {
      popoverVisible: false,
      popoverAnchorRef: null
    }
  },
  mounted() {
    this.popoverAnchorRef = this.$refs.popoverAnchorRef
  },
  methods: {
    openPopover() {
      this.popoverVisible = true
    },
    closePopover() {
      this.popoverVisible = false
    },
    endDateDisplay(endDate) {
      return endDate ? new Date(endDate).toLocaleDateString() : null
    },
    endDateHelper(endDate) {
      const dropDate = format(
        startOfDay(addDays(new Date(endDate), DROP_THRESHOLD)),
        'MM/dd/yyyy'
      )
      const offloadDate = format(
        startOfDay(addDays(new Date(endDate), OFFLOAD_THRESHOLD)),
        'MM/dd/yyyy'
      )

      if (this.daysFromToday(endDate) > OFFLOAD_THRESHOLD) {
        return `All student data was removed on ${offloadDate}, 180 days after the end date for this class`
      } else if (
        this.daysFromToday(endDate) > DROP_THRESHOLD &&
        this.daysFromToday(endDate) < OFFLOAD_THRESHOLD
      ) {
        return `All student data is scheduled to be removed on ${offloadDate}, and cannot be recovered after this date. If you wish to prevent this, change the end date to the future`
      } else if (this.daysFromToday(endDate) < DROP_THRESHOLD) {
        return `All students are scheduled to be dropped on ${dropDate}. If you wish prevent this, change the end date to the future`
      } else {
        return 'Click here to see Class Settings'
      }
    },
    daysFromToday(endDate) {
      const today = startOfDay(new Date())
      const diff = differenceInCalendarDays(
        today,
        startOfDay(new Date(endDate))
      )
      return diff
    }
  }
}
</script>
<style lang="scss" scoped>
.user-date-expired {
  padding: 0px;
  margin: 0px;
  text-transform: capitalize;
  color: $color-warning;
}
</style>
