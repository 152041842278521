<template>
  <div class="browse-sidebar">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BrowseSidebar'
}
</script>

<style lang="scss" scoped>
.browse-sidebar {
  grid-area: sidebar;
  padding: 0 32px 0 0;

  display: none;

  @media screen and (min-width: $screen-sm) {
    display: block;
  }
}
</style>
