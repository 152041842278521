<template>
  <nav aria-label="breadcrumb">
    <slot />
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumb'
}
</script>
