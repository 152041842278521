<template>
  <div class="message-bar">
    <div class="container message-bar__content">
      <hyper-link
        v-if="message.link"
        class="message-bar__message"
        :to="message.link"
        :data-testid="dataTestid"
        >{{ message.message }}</hyper-link
      >
      <p v-else class="message-bar__message">{{ message.message }}</p>
      <button
        type="button"
        :aria-label="`Hide ${messageName} Message Bar`"
        class="message-bar__close"
        @click="onClose"
      >
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageBar',
  emits: ['close'],
  props: {
    message: {
      type: Object,
      required: true
    },
    messageName: {
      type: String,
      required: true
    },
    dataTestid: String
  },
  methods: {
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  all: unset;
}
.message-bar {
  background-color: $color-notice;
  font-weight: bold;
  border-bottom: 1px solid #ffffff;
  padding: 15px 0;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;

  &__content {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }

  &__message {
    flex-grow: 1;
    margin: 0;
    color: #ffffff;
  }

  &__close {
    background-color: $color-notice;
    flex-shrink: 0;
    color: #ffffff;
    font-weight: normal;
    margin-left: auto;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}
</style>
