import Table from '@tiptap/extension-table'

const CustomTable = Table.extend({
  renderHTML({ HTMLAttributes, node }) {
    return [
      'div',
      { class: 'tableWrapper' },
      this.parent({ HTMLAttributes, node })
    ]
  }
})

export default CustomTable
