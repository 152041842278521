<template>
  <div class="flex">
    <div
      class="hidden md:block md:sticky flex-shrink-0 w-[200px] lg:w-[300px] h-fit top-16 mt-6 mr-12"
    >
      <SectionsList :sections="sections" :values="values" :classes="classes" />
    </div>
    <div
      class="flex-grow max-w-[720px] pb-12 mx-auto md:mx-0"
      id="class-selector-list"
    >
      <UIForm @submit="onSubmit">
        <ClassSelector
          v-for="section in sections"
          :key="section.id"
          class="mt-6 scroll-mt-16"
          :classes="classes"
          :id="`section-${section.id}`"
          :sectionName="section.name"
          :platformName="platformName"
          :path="`sections.${section.id}`"
          @refreshClassList="refreshList"
        >
        </ClassSelector>
        <div
          class="mt-4 flex items-start relative justify-end mx-auto mb-[40vh]"
        >
          <UIButton
            v-if="managing"
            class="mr-4"
            variant="destructive"
            type="outline"
            @click="cancel"
            >Cancel Changes</UIButton
          >
          <UIButton submit :disabled="!meta.valid || !atLeastOneClassSelected"
            >Save Changes</UIButton
          >
        </div>
      </UIForm>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'

import { UIButton, UIForm } from '@pi/design'
import { Class } from '@pi/api-types'

import client from 'src/shared/api-client'
import { useFlash } from 'src/shared/hooks/flash'
import ClassSelector from '../components/ClassSelector.vue'
import SectionsList from './SectionsList.vue'
import { computed, watch } from 'vue'

const emits = defineEmits(['refreshClassList'])
const flash = useFlash()
const router = useRouter()

interface LtiSection {
  id: string
  name: string
  class?: {
    id: string
    name: string
  }
}

const props = defineProps<{
  platformName: string
  classes: Class[]
  sections: LtiSection[]
  managing: boolean
}>()

const initialValues = computed(() => ({
  sections: Object.fromEntries(
    props.sections.map(section => [
      section.id,
      section.class?.id ?? (props.managing ? 'none' : undefined)
    ])
  )
}))
const { handleSubmit, meta, values, resetForm } = useForm<{
  sections: Record<string, string>
}>({
  initialValues: initialValues.value,
  validateOnMount: true
})
watch(initialValues, values => {
  resetForm({ values })
})

const onSubmit = handleSubmit(async values => {
  const sections = Object.fromEntries(
    Object.entries(values.sections).map(([sectionId, classId]) => {
      return [sectionId, classId === 'none' ? null : classId]
    })
  )
  await client.lti.connectClasses({
    sections
  })

  flash.success('Classes connected successfully.')
  await router.push({ name: 'lti_connect_assignment' })
})

const atLeastOneClassSelected = computed(() => {
  return Object.values(values.sections).some(id => id && id !== 'none')
})

function refreshList() {
  emits('refreshClassList', true)
}

async function cancel() {
  router.back()
}
</script>
