<template>
  <view-container>
    <tab-provider :tab="tab" @change="changeTab">
      <view-header bordered>
        <view-title class="dashboard-title" v-if="license">
          {{ license?.name ?? 'Manage License' }} Dashboard
        </view-title>
        <view-header-actions>
          <form-button secondary @click="addMembers"
            >Send Invitations</form-button
          >
          <button-link
            class="action-button"
            primary
            :to="{
              name: 'new_renewal_session',
              query: { licenseId: license?.id }
            }"
          >
            {{ license?.type === 'trial' ? 'Purchase' : 'Renew' }} License
          </button-link>
        </view-header-actions>
        <view-subtitle>
          <tab-header>
            <tab-header-button tab="overview">Overview</tab-header-button>
            <tab-header-button tab="onboarding">Onboarding</tab-header-button>
            <tab-header-button tab="classes">Classes</tab-header-button>
            <tab-header-button tab="usage">Usage</tab-header-button>
            <tab-header-button tab="manage">Manage</tab-header-button>
            <tab-header-button tab="lti">LTI</tab-header-button>
          </tab-header>
        </view-subtitle>
      </view-header>
      <tab-panel>
        <template #overview>
          <license-overview-tab :id="id" />
        </template>
        <template #onboarding>
          <license-onboarding-tab
            :licenseId="licenseId"
            @addMembers="addMembers"
          />
        </template>
        <template #classes>
          <license-classes-tab :licenseId="licenseId" />
        </template>
        <template #usage>
          <license-usage-tab :license="license" />
        </template>
        <template #manage>
          <license-manage-tab :id="id" />
        </template>
        <template #lti>
          <loading-container :loading="isLoading">
            <lti-manage-tab :licenseId="licenseId"></lti-manage-tab>
          </loading-container>
        </template>
      </tab-panel>
    </tab-provider>
  </view-container>
</template>

<script setup>
import { useRouter } from 'vue-router'

import LicenseAddMembersModal from 'src/shared/components/modals/LicenseAddMembersModal'
import LicenseOverviewTab from 'src/modules/licenses/components/OverviewTab.vue'
import LicenseOnboardingTab from 'src/modules/licenses/components/OnboardingTab.vue'
import LicenseManageTab from 'src/modules/licenses/components/ManageTab.vue'
import LicenseUsageTab from 'src/modules/licenses/components/UsageTab.vue'
import LtiManageTab from 'src/modules/licenses/components/LtiManageTab.vue'
import LicenseClassesTab from 'src/modules/licenses/components/ClassesTab.vue'
import client from 'src/shared/api-client'
import useTitle from 'src/shared/hooks/title'
import { useStore } from 'vuex'
import { useFlash } from 'src/shared/hooks/flash'
import { watch, ref, computed, inject, onBeforeMount } from 'vue'

const $router = useRouter()
const store = useStore()
const $modal = inject('$modal')
const flash = useFlash()
const props = defineProps({
  id: {
    type: String,
    required: false
  },
  tab: {
    type: String,
    required: false,
    default: 'manage'
  }
})

const license = ref()
const siteLicense = computed(() => store.state.auth.user.siteLicense)
const tabTitle = computed(() => {
  switch (props.tab) {
    case 'manage':
    default:
      return 'Manage License'
    case 'usage':
      return 'License Usage'
    case 'onboarding':
      return 'License Onboarding'
    case 'overview':
      return 'License Overview'
  }
})

useTitle(() =>
  license.value
    ? `${license.value?.name} | ${tabTitle.value}`
    : 'Manage License'
)

const isLicenseTrial = computed(() => {
  return siteLicense.value?.type === 'trial'
})

const licenseId = computed(() => {
  return props.id || (siteLicense.value && siteLicense.value.id)
})

const getLicense = async () => {
  const licenseRequest = await client.licenses.get({ id: licenseId.value })
  license.value = licenseRequest
}

const changeTab = tab => {
  //change route
  $router.push({
    name: $router.currentRoute.value.name,
    params: { id: props.id, tab }
  })
}

const isAdmin = computed(
  () => store.getters.isAdmin || store.getters.isSalesAdmin
)
const addMembers = async () => {
  try {
    const { status } = await $modal.show(LicenseAddMembersModal, {
      license: license,
      isAdmin: isAdmin.value
    })
    if (status === 'ok') {
      flash.success('Invitations sent successfully')
    }
  } finally {
    await getLicense()
  }
}
onBeforeMount(async () => {
  await getLicense()
})

watch(
  () => props.id,
  async () => {
    await getLicense()
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.action-button {
  margin-left: 10px;
}

.dashboard-title {
  margin-bottom: 10px;
}
</style>
