<template>
  <div class="section-component">
    <div v-if="isDependencyOfAnother && !viewAsStudent">
      <em
        >Students must answer this question correctly in order to answer
        subsequent questions</em
      >
    </div>
    <div v-if="canHide && overrideHiding">
      <em>
        This question will be hidden for the student until the previous question
        is correct.
      </em>
    </div>
    <div class="section-component__feedback">
      <button
        type="button"
        aria-label="Copy link to this component to clipboard"
        title="Copy link to this component to clipboard"
        @click="copyComponentLink"
        class="section-component__feedback-btn"
      >
        <icon icon="link" />
      </button>
    </div>
    <div
      class="section-component__feedback"
      v-if="canEditActivity && !viewAsStudent"
    >
      <button-link
        type="button"
        :aria-label="`Edit this component in a new tab`"
        :title="`Edit this component in a new tab`"
        class="section-component__feedback-btn"
        :to="{
          name: 'activity',
          params: {
            id: activity.id,
            tab: 'content'
          },
          hash: `#component-${this.component._id}`
        }"
        target="_blank"
        rel="noopener"
      >
        <icon icon="pencil" />
      </button-link>
    </div>
    <div
      class="section-component__feedback"
      v-if="canReportProblem && !viewAsStudent"
    >
      <button
        type="button"
        :aria-label="`Report a problem with this ${component.componentType} to Pivot Interactives`"
        :title="`Report a problem with this ${component.componentType} to Pivot Interactives`"
        @click="reportProblemToIntercom"
        class="section-component__feedback-btn"
      >
        <icon icon="bullhorn" />
      </button>
    </div>
    <div v-if="canHide && !overrideHiding && !previousQuestionCorrect">
      <div v-if="component.questionNumber" class="question__number">
        {{ component.questionNumber }}.
      </div>

      <p class="hide-question">
        This question is hidden until you answer the previous question<span
          v-if="gradeAfterSubmit"
        >
          correctly</span
        >.
      </p>
    </div>
    <div v-else>
      <slot
        :section="section"
        :canRespond="canRespond"
        :onSubmit="onSubmit"
        :onComplete="onComplete"
        :canSubmit="canSubmit && needsSubmission"
        @canSubmitChange="onCanSubmitChange"
      />
    </div>
  </div>
</template>

<script>
import * as Vue from 'vue'
import { mapGetters, useStore } from 'vuex'
import { getText } from 'src/shared/components/editor/utils'
import ReportProblemModal from 'src/shared/components/modals/ReportProblemModal'
import client from 'src/shared/api-client'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'

export default {
  name: 'ActivitySectionComponent',
  inject: ['inherited', '$modal'],
  provide() {
    return {
      inherited: Vue.computed(() => ({
        ...this.inherited,
        component: this.component,
        componentResponse: this.componentResponse,
        updateResponse: this.updateResponse,
        canRespond: this.canRespond,
        studentResponsePopoverMessage: this.studentResponsePopoverMessage,
        isGrading: this.isGrading,
        canSubmit: this.canSubmit,
        prevResponse: this.prevResponse
      }))
    }
  },
  emits: ['change', 'submit', 'needs-submission'],
  props: {
    component: {
      type: Object,
      required: true
    },
    preview: {
      type: String,
      default: null
    },
    variableContext: {
      type: Object,
      required: false
    },
    overrideHiding: {
      type: Boolean,
      default: false
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const isReopen = store.getters['features/isEnabled']('reopen')
    return { isReopen }
  },
  data() {
    return {
      isGrading: false,
      canSubmit: true,
      prevResponse: {}
    }
  },
  computed: {
    ...mapGetters(['isTeacher', 'isAdmin']),
    activity() {
      return this.inherited.activity
    },
    response() {
      return this.inherited.response
    },
    section() {
      return this.inherited.section
    },
    isOverdue() {
      return this.inherited.isOverdue
    },
    isSubmitted() {
      return this.inherited.isSubmitted
    },
    isReopened() {
      return this.inherited.isReopened
    },
    gradeAfterSubmit() {
      if (this.isGrading) return true
      return (
        this.inherited?.response?.assignment?.studentFeedbackTiming ===
          AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit ||
        this.inherited?.response?.assignment?.studentFeedbackTiming ===
          AssignmentStudentFeedbackTimingValue.Never
      )
    },
    dependenciesSatisfied() {
      return (
        this.component.componentType !== 'NumericalQuestion' ||
        this.component.dependenciesSatisfied ||
        this.componentResponse.dependenciesSatisfied
      )
    },
    isDependencyOfAnother() {
      const prevSubmittableDependents = Object.keys(
        this.inherited.prevSubmittableComponentMap
      ).filter(
        c =>
          this.inherited.prevSubmittableComponentMap[c] === this.component._id
      )
      const isDependent = this.inherited.activity.sections
        .map(s => s.components)
        .flat()
        .some(c => {
          return (
            c.dependencies && c.dependencies.some(d => d === this.component._id)
          )
        })
      const isNextHidden = this.inherited.activity.sections
        .map(s => s.components)
        .flat()
        .some(c => prevSubmittableDependents.includes(c._id) && c.hideContent)

      return isDependent || isNextHidden
    },
    previousSubmittableQuestion() {
      return this.inherited.prevSubmittableComponentMap[this.component._id]
    },
    isFirstQuestion() {
      return (
        this.inherited.section.index === 0 &&
        this.component.questionNumber === 1
      )
    },
    canHide() {
      return (
        (this.component.hideContent ||
          (this.response?.assignment?.progressiveDisclosure &&
            !this.isFirstQuestion)) &&
        !!this.previousSubmittableQuestion
      )
    },
    previousQuestionCorrect() {
      if (this.previousSubmittableQuestion) {
        const response = this.inherited.response.responses.find(
          r => r.component === this.previousSubmittableQuestion
        )
        if (
          this.gradeAfterSubmit &&
          response?.value !== undefined &&
          response?.value !== ''
        ) {
          return true
        } else {
          return response?.correct ?? false
        }
      } else {
        return false
      }
    },
    canRespond() {
      return (
        (!this.isSubmitted || this.isReopened) &&
        (!this.isOverdue || this.isReopened) &&
        !this.section.isLocked &&
        this.section.isAvailable &&
        this.dependenciesSatisfied
      )
    },
    needsSubmission() {
      const { value, correct } = this.componentResponse
      const { value: prevValue } = this.prevResponse
      const isGraded = typeof correct === 'boolean'
      const hasValue = value !== undefined || value !== null
      let hasChanged = false
      if (typeof value === 'string') {
        hasChanged =
          (value || '').split(',').sort().join(',') !==
          (prevValue || '').split(',').sort().join(',')
      } else {
        hasChanged = value !== prevValue
      }
      return hasChanged || (!isGraded && hasValue)
    },
    studentResponsePopoverMessage() {
      if (this.isReopen && this.isSubmitted && !this.isOverdue) {
        return 'Please reopen your assignment to continue working.'
      }

      if (this.isSubmitted) {
        return 'This assignment has been submitted and responses cannot be modified.'
      } else if (this.isOverdue) {
        return 'This assignment is overdue and responses cannot be modified.'
      } else if (this.section.isLocked) {
        return 'This section is locked: response cannot be modified.'
      } else if (!this.section.isAvailable) {
        return 'This section is disabled because you must complete and lock a previous section.'
      }
      return null
    },
    componentResponse() {
      return (
        this.response.responses.find(
          ({ component }) => component === this.component._id
        ) || { component: this.component._id }
      )
    },
    canReportProblem() {
      return this.isTeacher && window.Intercom
    },
    canEditActivity() {
      return (
        ['owner', 'admin', 'editor'].includes(
          this.activity.library?.permissions
        ) ||
        ['owner', 'admin', 'editor'].includes(this.activity.permissions) ||
        this.isAdmin
      )
    },
    componentName() {
      if (this.component.componentType === 'IFrame') {
        return this.component.url ? `${this.component.url}` : ''
      }

      return this.component.text
        ? getText(this.component.text, this.variableContext)
        : ''
    },
    numberAndPrompt() {
      const prompt =
        this.componentName.length < 30
          ? this.componentName
          : this.componentName.slice(0, 30)
      return this.component.questionNumber
        ? `${this.component.questionNumber}. ${prompt}:`
        : `${prompt}:`
    },
    componentAnswer() {
      const { answer, choices, conditions } = this.component
      if (!answer || !choices || !conditions) {
        return ''
      }
      let value =
        answer ||
        conditions
          .filter(c => c.isCorrect)
          .map(c => c.condition)
          .join(',') ||
        choices
          .filter(c => c.answer)
          .map(c => c.text)
          .join(',')
      // Convert JSON object strings to regular objects.
      if (typeof value === 'string') {
        try {
          const parsedValue = JSON.parse(value)
          if (typeof parsedValue !== 'number') {
            value = parsedValue
          }
        } catch {}
      }
      return value || ''
    }
  },
  methods: {
    updateResponse(data, isDirty = true) {
      this.$emit('change', {
        response: {
          ...this.componentResponse,
          ...data
        },
        isDirty
      })
    },
    async onComplete() {
      this.updateResponse(
        {
          isComplete: true
        },
        false
      )
      this.canSubmit = false
    },
    async onSubmit() {
      if (this.isGrading) return
      this.isGrading = true
      try {
        let response
        if (this.preview === 'activity') {
          response = await client.activities.submitPreviewComponentResponse({
            activityId: this.activity.id,
            componentId: this.component._id,
            responseValue: this.componentResponse.value,
            variables: this.response.variables,
            grade: true
          })

          response.submissions = (this.prevResponse.submissions ?? 0) + 1
          response.score =
            response.submissions > this.component.autogradeLimit
              ? 0
              : response.score
        } else if (this.preview === 'assignment') {
          response = await client.assignments.submitPreviewComponentResponse({
            assignmentId: this.response.assignmentId,
            componentId: this.component._id,
            responseValue: this.componentResponse.value,
            variables: this.response.variables,
            grade: true
          })
          response.submissions = (this.prevResponse.submissions ?? 0) + 1
          response.score =
            response.submissions > this.component.autogradeLimit
              ? 0
              : response.score
        } else {
          response = await client.assignments.submitComponentResponse({
            assignmentId: this.response.assignmentId,
            componentId: this.component._id,
            responseValue: this.componentResponse.value,
            grade: true
          })
        }
        this.updateResponse(response, false)
        if (this.preview === 'activity' || this.preview === 'assignment') {
          this.setPreviewResponseVariable()
        }
        this.$emit('submit')
      } finally {
        this.isGrading = false
      }
    },
    setPreviewResponseVariable() {
      if (!this.componentResponse.correct) return
      this.activity.variables.forEach(activityVariable => {
        if (
          /studentResponse/i.test(activityVariable.variableType) &&
          activityVariable.content === this.component._id
        ) {
          const responseVariable = this.response.variables.find(
            respVariable => respVariable.id === activityVariable.id
          )
          if (responseVariable) {
            responseVariable.value = this.componentResponse.value
          } else {
            this.response.variables.push({
              id: activityVariable.id,
              value: this.componentResponse.value
            })
          }
        }
      })
    },
    assignmentResponseLink() {
      return this.response.owner
        ? `Assignment Response Link: https://app.pivotinteractives.com/assignments/${this.response.assignmentId}/responses/${this.response.id}/#component-${this.component._id} \r\n`
        : ''
    },
    async reportProblemToIntercom() {
      const { status, data } = await this.$modal.show(ReportProblemModal)
      const problemDescription = data

      if (status === 'ok') {
        const newMessage = `I'm writing to report a problem with a ${
          this.component.componentType
        } \r\n
      ${problemDescription} \r\n
  Page type: ${this.response.owner ? 'Student Response' : 'Activity Preview'}
  ${this.assignmentResponseLink()}
  Activity URL: https://app.pivotinteractives.com/activities/${
    this.activity.id
  }\r\n
  Component Preview URL : http://app.pivotinteractives.com/activities/${
    this.activity.id
  }/preview/#component-${this.component._id}\r\n
  Component Edit URL: http://app.pivotinteractives.com/activities/${
    this.activity.id
  }/content/#component-${this.component._id}\r\n

  Activity ID: ${this.activity.id}\r\n
  Section: ${this.section.name}\r\n
  Component name: ${this.component.componentType}\r\n
  Question Number and truncated prompt: ${this.numberAndPrompt}\r\n
  Student response (if applicable): ${this.componentResponse.value || ''}\r\n
  Correct Answer: ${this.componentAnswer}\r\n`

        if (window.Intercom) {
          window.Intercom('showNewMessage', newMessage)
        }
      }
    },
    onCanSubmitChange(submitEnabled) {
      this.canSubmit = submitEnabled
    },
    copyComponentLink() {
      let url = `${window.location.origin}`
      if (this.response.owner) {
        url =
          url +
          `/assignments/${this.response.assignmentId}/responses/${this.response.id}/#component-${this.component._id}`
      } else {
        url =
          url +
          `/activities/${this.inherited.activity.id}/preview/#component-${this.component._id}`
      }

      navigator.clipboard.writeText(url)
      this.$success('Link copied to clipboard')
    }
  },
  watch: {
    componentResponse: {
      handler(response) {
        if (this.prevResponse.submissions !== response.submissions) {
          this.prevResponse = { ...response }
        }
      },
      immediate: true
    },
    needsSubmission(newValue, previousValue) {
      if (previousValue !== newValue && this.component.autograde) {
        this.$emit('needs-submission', newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-component {
  margin: 40px 0 24px 0;
  border-top: 1px solid $light-grey;
}

.section-component__feedback {
  float: right;

  .section-component__feedback-btn {
    margin-top: 16px;
    margin-left: 4px;
    background-color: transparent;
    border: $color-success solid 1px;
    border-radius: 3px;
    color: $color-success;
    border-radius: 6px;
    font-weight: 700;
    padding: 2px 12px;
    opacity: (1);
    transition: opacity 0.25s ease-out;
    white-space: nowrap;

    &:focus {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }
}

.question__number {
  margin: 0 8px 0 0;
  line-height: $line-height-base;
}
.hide-question {
  padding: 16px;
  background-color: #eee;
}
</style>
