<template>
  <div
    class="view-sub-header"
    :class="{ 'view--sub-header--centered': centered }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ViewSubHeader',
  props: {
    centered: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.view-sub-header {
  display: flex;
  width: calc(100% + 16px);
  margin: 0 -8px 16px -8px;
  min-height: 24px;

  & > * {
    margin: 0 8px;
  }
}

.view-sub-header--centered {
  justify-content: center;
  text-align: center;
}
</style>
