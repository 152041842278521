import seedrandom from 'seedrandom'

const scramble = (seed, choicesArray) => {
  let newArrayOrder = choicesArray.slice()
  let arrayLength = choicesArray.length

  const rng = new seedrandom(seed)

  for (let i = 0; i < arrayLength; i++) {
    let randomElementIndex = Math.floor(rng() * i)

    let temporaryIndex = newArrayOrder[i]
    newArrayOrder[i] = newArrayOrder[randomElementIndex]
    newArrayOrder[randomElementIndex] = temporaryIndex
  }

  return newArrayOrder
}

export { scramble }
