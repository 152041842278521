<template>
  <form-button class="modal-button-ok" @click="onClick">
    <slot>Ok</slot>
  </form-button>
</template>

<script>
export default {
  name: 'ModalButtonOk',
  inject: ['$_modal'],
  methods: {
    onClick() {
      this.$_modal.ok()
    }
  }
}
</script>

<style lang="scss">
.modal-button-ok {
  margin: 0 0 0 16px;
}
</style>
