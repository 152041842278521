<template>
  <view-container wide>
    <sticky-header>
      <template #primary-navigation>
        <breadcrumb v-if="!isLoading">
          <breadcrumb-item
            :to="{
              name: 'existing_class',
              params: {
                id: gradableAssignment.class
              }
            }"
            >{{ className }}
          </breadcrumb-item>
        </breadcrumb>
      </template>
      <template #title>
        <sticky-header-title>
          Select Questions: {{ assignmentActivity.name }}
        </sticky-header-title>
      </template>
      <template #sub-title>
        <sticky-header-sub-title>
          Selected: {{ selectedQuestionText }}
        </sticky-header-sub-title>
      </template>
      <template #actions>
        <checkbox v-model="gradeAnonymously">Grade anonymously</checkbox>
        <form-button :disabled="!canSubmit" @click="doGradeQuestions()">
          Grade Questions
        </form-button>
      </template>
    </sticky-header>
    <loading-container class="question-sections" :loading="isLoading">
      <variable-provider
        v-slot="variableContext"
        :variables="assignmentActivity.variables"
        :activity="assignmentActivity"
      >
        <div>
          <div class="gradable-questions-section__header">
            Select the questions you would like to grade for this activity.
            <div class="gradable-questions-section__filters">
              <form-button
                link
                style="margin-right: 15px"
                value=""
                @click="toggleAll"
                >{{ areAllOpen ? 'Hide' : 'Show' }} All Sections</form-button
              >
              <select-field
                :modelValue="showComponentsFilter"
                aria-label="Show options"
                @update:modelValue="changeShowComponentsFilter"
                class="gradable-questions-section__filters--select"
              >
                <option value="only_ungraded">
                  Show Only Ungraded Questions
                </option>
                <option value="all_gradable">
                  Show all Gradable Questions
                </option>
                <option value="all">Show All Components</option>
              </select-field>
            </div>
          </div>
          <div class="gradable-questions-section">
            <gradable-section
              v-for="(section, sectionIndex) in assignmentActivity.sections"
              :key="sectionIndex"
              :section="section"
              :is-section-collapsed="collapsedSections[sectionIndex]"
              :components-filter="showComponentsFilter"
              :variable-context="variableContext"
              v-model:gradableComponents="gradableComponents"
              @update:is-section-collapsed="
                collapsed => toggleSection(sectionIndex, collapsed)
              "
            />
          </div>
        </div>
      </variable-provider>
    </loading-container>
  </view-container>
</template>

<script setup>
import GradableSection from '../components/GradableSection'
import PreviewActivityModal from 'src/shared/components/modals/PreviewActivityModal'
import UnsavedChangesModal from 'src/shared/components/modals/UnsavedChangesModal'
import VariableProvider from 'src/shared/components/VariableProvider'
import client from 'src/shared/api-client'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { ref, computed, onMounted, inject, watch, nextTick } from 'vue'

const $modal = inject('$modal')
const $router = useRouter()

const props = defineProps({
  assignmentId: {
    type: String,
    required: true
  }
})

const activity = ref({})
const gradableComponents = ref([])
const isLoading = ref(true)
const gradableAssignment = ref({})
const assignmentActivity = ref({})

const showComponentsFilter = ref('only_ungraded')

const gradeAnonymously = ref(false)
const className = ref('')
const canSubmit = computed(() => gradableComponents.value.length > 0)

const selectedQuestionText = computed(() => gradableComponents.value.length)

const changeShowComponentsFilter = newValue => {
  showComponentsFilter.value = newValue
  gradableComponents.value = []
}

const load = async () => {
  await getAssignment(props.assignmentId)
}
const getAssignment = async assignmentId => {
  isLoading.value = true
  await loadGradableAssignment(assignmentId)

  isLoading.value = false
}
const showActivityPreview = () => {
  $modal.show(PreviewActivityModal, {
    id: gradableAssignment.value.activityId
  })
}
const doGradeQuestions = () => {
  $router.push({
    name: 'grade_assignment',
    params: {
      assignmentId: props.assignmentId
    },
    query: {
      question: gradableComponents.value.reduce(
        (result, { id, defaultScore = null }) => {
          result[id] = defaultScore
          return result
        },
        {}
      ),
      gradeAnonymously: gradeAnonymously.value
    }
  })
}
const collapsedSections = ref({})
const areAllCollapsed = computed(() =>
  assignmentActivity.value.sections.every(
    (section, index) => collapsedSections.value[index]
  )
)
const areAllOpen = computed(() =>
  assignmentActivity.value.sections.every(
    (section, index) => !collapsedSections.value[index]
  )
)

function toggleSection(id, collapsed) {
  collapsedSections.value[id] = collapsed
}

function toggleAll() {
  if (!areAllOpen.value && !areAllCollapsed.value) {
    collapsedSections.value = {}
  } else if (!areAllCollapsed.value) {
    const collapsed = {}
    for (let i = 0; i < assignmentActivity.value.sections.length; i++) {
      collapsed[i] = true
    }
    collapsedSections.value = collapsed
  } else {
    collapsedSections.value = {}
  }
}

const flattenComponents = section => {
  return section.components
    .map(component => {
      if (component.componentType === 'SplitView') {
        return [...component.leftContent, ...component.rightContent]
      }
      return component
    })
    .flat()
}

const loadGradableAssignment = async assignmentId => {
  const requests = [
    client.assignments.get({ assignmentId: assignmentId }),
    client.assignments.getActivity({ assignmentId })
  ]
  const [assignment, activityResponse] = await Promise.all(requests)

  const activity = activityResponse
  const classId = assignment.class
  const body = await client.classes.get({ classId })
  className.value = body.name

  const components = activity.sections
    .map(section =>
      section.components
        .map(c =>
          c.componentType === 'SplitView'
            ? [...c.leftContent, ...c.rightContent].map(cc => cc._id)
            : [c._id]
        )
        .flat()
    )
    .flat()
  const responsesResult = await client.assignments.getAllResponses({
    assignmentId,
    hasResponse: true,
    components
  })
  const totalResponsesPossible =
    assignment.assignedTo === 'everyone'
      ? body.roster.length
      : responsesResult.total
  const sectionWithResponses = activity.sections.map(section => {
    const components = flattenComponents(section).map(component => {
      const gradedResponses = responsesResult.page.filter(r =>
        r.responses.some(
          rc => rc.component === component._id && typeof rc.score === 'number'
        )
      ).length

      const gradingPct = gradedResponses / totalResponsesPossible
      return {
        ...component,
        gradingPct
      }
    })
    section.components = components
    return section
  })

  activity.sections = sectionWithResponses
  gradableAssignment.value = assignment
  assignmentActivity.value = activity
}

watch(props.assignmentId, () => {
  nextTick(async () => {
    await load()
  })
})

onMounted(async () => {
  await load()
  window.addEventListener('beforeunload', beforeUnload)
})

const beforeUnload = e => {
  const text = 'You have unsaved changes.'
  if (gradableComponents.value.length) {
    e.returnValue = text
    return text
  }
}

onBeforeRouteLeave(async (to, from, next) => {
  if (gradableComponents.value.length) {
    if (to.name === 'grade_assignment') {
      window.removeEventListener('beforeunload', beforeUnload)
      next()
    } else {
      const { status } = await $modal.show(UnsavedChangesModal)
      if (status === 'ok') {
        window.removeEventListener('beforeunload', beforeUnload)
        next()
      } else {
        next(false)
      }
    }
  } else {
    window.removeEventListener('beforeunload', beforeUnload)
    next()
  }
})
</script>

<style lang="scss" scoped>
$light-grey: #ccc;
$solid-gray: #c3c3c3;

$dashed-bottom-border: 1px dashed $light-grey;
$solid-bottom-border: 1px solid $solid-gray;

.question-sections {
  margin-top: 10px;
}

.select-question-section {
  border-bottom: 4px solid $light-teal;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 4px solid $light-teal;
  }
  &:first-child {
    border-top: 4px solid $light-teal;
    padding-top: 10px;
  }
}

.gradable-questions-section__header {
  padding: 15px 0 20px 0;
  display: flex;
  justify-content: space-between;
}

.gradable-questions-section__filters {
  width: 350px;
  text-align: right;
  &--select {
    margin: 0 10px;
    display: inline-block;
    width: 180px;
  }
  & > button {
    display: inline-block;
  }
}

.table {
  border-collapse: collapse;

  & thead > tr > th {
    vertical-align: middle;
    border-bottom: $dashed-bottom-border;
  }
}

.collapse-icon {
  width: 30px;
}
</style>
