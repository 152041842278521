<template>
  <view-container>
    <VariableProvider
      v-slot="variableContext"
      :variables="activity?.variables || []"
      :values="assignmentPreview?.variables || []"
      :activity
    >
      <tab-provider :tab="tab" @change="changeTab">
        <view-header bordered>
          <template #primary-navigation>
            <breadcrumb v-if="assignment">
              <breadcrumb-item
                :to="{
                  name: 'classes'
                }"
                >My Classes
              </breadcrumb-item>
              <breadcrumb-item
                :to="{
                  name: 'existing_class',
                  params: { id: assignment.class }
                }"
              >
                {{ klass?.name ?? 'Class' }}
              </breadcrumb-item>
            </breadcrumb>
          </template>
          <view-title>
            Scores:
            <router-link
              v-if="assignmentId"
              :to="{
                name: 'preview_assignment',
                params: { id: assignmentId }
              }"
            >
              {{ assignment?.activity.name ?? 'Assignment' }}
            </router-link>
          </view-title>
          <view-header-actions>
            <button-link
              class="assignment-scores__grade-by-question"
              :to="{
                name: 'select_questions_to_grade',
                params: { assignmentId }
              }"
            >
              Grade By Question
            </button-link>

            <button-dropdown secondary right>
              <template #button>
                <icon icon="ellipsis-h" />
                <span class="sr-only">Assignment Menu</span>
              </template>
              <dropdown-action @click="downloadGrades">
                Download Grades
              </dropdown-action>
              <dropdown-link
                :to="{
                  name: 'preview_assignment',
                  params: { id: assignmentId }
                }"
              >
                Preview Activity
              </dropdown-link>
            </button-dropdown>
          </view-header-actions>
          <view-subtitle>
            <tab-header>
              <tab-header-button tab="scores">Scores</tab-header-button>
              <tab-header-button tab="settings">Settings</tab-header-button>
              <tab-header-button tab="insights">Insights</tab-header-button>
            </tab-header>
          </view-subtitle>
        </view-header>
        <loading-container :loading="isLoading">
          <tab-panel>
            <template #scores>
              <assignment-scores-tab
                v-bind="{ assignment, activity, responses, klass }"
                @refresh="reload"
              />
            </template>
            <template #settings>
              <assignment-settings-tab
                v-bind="{ assignment, activity, responses, klass }"
                @refresh="reload"
              />
            </template>
            <template #insights>
              <AssignmentInsightsTab
                v-bind="{
                  assignment,
                  activity,
                  responses,
                  klass,
                  variableContext
                }"
                @refresh="reload"
              />
            </template>
          </tab-panel>
        </loading-container> </tab-provider
    ></VariableProvider>
  </view-container>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue'
import client from 'src/shared/api-client'
import AssignmentScoresTab from '../components/AssignmentScoresTab'
import AssignmentSettingsTab from '../components/AssignmentSettingsTab'
import AssignmentInsightsTab from '../components/AssignmentInsightsTab'
import { format as formatDate } from 'date-fns'
import useTitle from 'src/shared/hooks/title'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import VariableProvider from 'src/shared/components/VariableProvider'
const props = defineProps({
  assignmentId: {
    type: String,
    required: true
  },
  tab: {
    type: String,
    required: false,
    default: 'scores'
  }
})

const $router = useRouter()
const isLoading = ref(true)
const assignment = ref()
const activity = ref()
const assignmentPreview = ref()
const responses = ref([])
const klass = ref()
useTitle(() =>
  assignment.value
    ? `${assignment.value.activity.name} | ${
        props.tab === 'scores' ? 'Scores' : 'Settings'
      }`
    : `${props.tab === 'scores' ? 'Scores' : 'Settings'}`
)

const changeTab = tab => {
  //change route
  $router.push({
    name: $router.currentRoute.value.name,
    params: { id: props.id, tab }
  })
}

async function loadResponses() {
  const { page } = await client.assignments.getAllResponses({
    assignmentId: props.assignmentId,
    hasResponse: true
  })
  responses.value = page
}

async function loadAssignment() {
  const assignmentResponse = await client.assignments.get({
    assignmentId: props.assignmentId
  })
  assignment.value = assignmentResponse
}
async function loadAssignmentPreview() {
  const assignmentPreviewResponse = await client.assignments.getPreviewResponse(
    {
      assignmentId: props.assignmentId
    }
  )
  assignmentPreview.value = assignmentPreviewResponse
}

async function reload() {
  await Promise.all([
    loadAssignment(),
    loadResponses(),
    loadAssignmentPreview()
  ])
}

onBeforeMount(async () => {
  const assignmentRequest = loadAssignment()
  const [activityResponse, klassResponse] = await Promise.all([
    client.assignments.getActivity({ assignmentId: props.assignmentId }),
    assignmentRequest.then(() =>
      client.classes.get({ classId: assignment.value.class })
    ),
    assignmentRequest
  ])

  klass.value = klassResponse
  activity.value = activityResponse

  await loadResponses()
  await loadAssignmentPreview()
  isLoading.value = false
})

async function downloadGrades() {
  const response = await client.assignments.downloadGradesAsCSV({
    assignmentId: props.assignmentId
  })
  const blob = new Blob([response], {
    type: 'text/csv;charset=utf-8;'
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `${assignment.value.activity.name} - ${formatDate(
    new Date(),
    'M-d-yyyy'
  )}.csv`
  if (window.Cypress) {
    document.body.appendChild(link)
  } else {
    link.click()
  }
}
</script>

<style lang="scss">
.assignment-scores__grade-by-question {
  margin-right: 10px;
}
</style>
