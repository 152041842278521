import BaseLink from '@tiptap/extension-link'
import { mergeAttributes } from '@tiptap/core'

const Link = BaseLink.extend({
  addStorage() {
    return {}
  },

  addCommands() {
    return {
      ...this.parent(),
      openLinkModal: () => () => {
        this.storage.onEditLink?.()
      }
    }
  },

  parseHTML() {
    return [
      { tag: 'a[href][data-type=file]:not([href *= "javascript:" i])' },
      { tag: 'a[href]:not([data-type]):not([href *= "javascript:" i])' }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': 'link'
      }),
      0
    ]
  }
})

export default Link
