<template>
  <li v-bind="wrapperAttrs">
    <button
      v-bind="buttonAttrs"
      type="button"
      :disabled="disabled || null"
      @click="onClick"
    >
      <!-- @slot The content of the dropdown item. -->
      <slot />
    </button>
  </li>
</template>

<script>
export default {
  name: 'DropdownAction',
  emits: ['click'],
  inject: ['buttonDropdown'],
  props: {
    /** Disables the dropdown action. */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    buttonAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    onClick(e) {
      if (this.buttonDropdown) this.buttonDropdown.close()
      /** Raised when the dropdown item is clicked. */
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #333333;
  display: block;
  width: 100%;
  text-align: left;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    color: #262626;
    background-color: #f5f5f5;
    outline: none;
  }
  &:focus {
    background-color: #f5f5f5;
    text-decoration: underline;
  }

  &:disabled {
    pointer-events: none;
    cursor: none;
    color: $silver;
  }
}
</style>
