<template>
  <div class="drag-drop__conditions">
    <div>
      <slot
        v-for="(condition, index) in nonDefaultConditions"
        :component-name="name"
        name="condition"
        :key="condition.id"
        :condition="condition"
        :first="index === 0"
        :last="index === nonDefaultConditions.length - 1"
        @edit="condition => updateCondition(condition, index)"
        @delete="removeCondition(index)"
        @insert="addCondition(index + 1)"
        @duplicate="duplicateCondition(index)"
        @moveup="moveConditionUp(index)"
        @movedown="moveConditionDown(index)"
      />
    </div>
    <div class="drag-drop__add-condition" @click="() => addCondition()">
      <form-button link>
        <icon icon="plus" />
        Add Condition
      </form-button>
    </div>

    <div class="drag-drop__default-feedback">
      <p
        class="drag-drop__default-feedback-label"
        :id="`default-condition-${defaultCondition.id}-label`"
      >
        Default Feedback
      </p>
      <p class="drag-drop__default-feedback-explain">
        Show if no condition is true
      </p>
      <div class="drag-drop__incorrect-indicator">
        <checkbox
          :modelValue="showIncorrectIndicators"
          @update:modelValue="toggleIncorrectIndicators"
          :disabled="multipleCorrect"
          >Indicate incorrect drop targets
        </checkbox>
        <p
          v-if="multipleCorrect"
          class="drag-drop__incorrect-indicator-explain"
        >
          Because you have more than one correct condition, we cannot indicate
          incorrect drop targets for this question
        </p>
      </div>
      <editor
        :aria-labelledby="`default-condition-${defaultCondition.id}-label`"
        :text="defaultCondition.response"
        :label="defaultCondition.response"
        :variable-context="variableContext"
        :rules="{
          undefinedVariables: true
        }"
        :name="`${name}.defaultResponse`"
        data-testid="text-input"
        @update:text="response => setDefaultResponse(response)"
        allow-response-variable
      />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, watch } from 'vue'
const emit = defineEmits(['update'])
const props = defineProps({
  conditions: {
    required: true
  },
  showIncorrectIndicators: {
    type: Boolean
  },
  variableContext: {
    type: Object,
    required: true
  },
  dropTargets: {
    type: Array,
    required: true
  },
  name: {
    type: String,
    required: true
  }
})

const toggleIncorrectIndicators = val => {
  emit('update', {
    showIncorrectIndicators: val
  })
}

const multipleCorrect = computed(() => {
  return props.conditions.filter(c => c.isCorrect).length > 1
})

watch(multipleCorrect, newValue => {
  if (newValue) {
    toggleIncorrectIndicators(false)
  }
})

const defaultCondition = computed(() => {
  const defaultCondition = props.conditions.find(condition => condition.default)
  return (
    defaultCondition || {
      response: '',
      default: true,
      id: Math.floor(Math.random() * 100000)
    }
  )
})

const nonDefaultConditions = computed(() => {
  return props.conditions.filter(c => !c.default)
})

const addCondition = index => {
  index = index ?? nonDefaultConditions.value.length

  const newCondition = {
    isCorrect: false,
    default: false,
    response: '',
    targets: (props.dropTargets || []).map(dropTarget => ({
      targetId: dropTarget.id,
      conditionType: 'is',
      choices: []
    })),
    id: Math.floor(Math.random() * 100000)
  }

  emit('update', {
    conditions: [
      ...props.conditions.slice(0, index),
      newCondition,
      ...props.conditions.slice(index)
    ]
  })
}

const duplicateCondition = index => {
  const condition = props.conditions[index]
  emit('update', {
    conditions: [
      ...props.conditions.slice(0, index),
      { ...condition, id: Math.floor(Math.random() * 100000) },
      ...props.conditions.slice(index)
    ]
  })
}

const removeCondition = index => {
  emit('update', {
    conditions: props.conditions.filter((_, i) => i !== index)
  })
}

const updateCondition = (fields, index) => {
  emit('update', {
    conditions: [
      ...props.conditions.slice(0, index),
      {
        ...props.conditions[index],
        ...fields
      },
      ...props.conditions.slice(index + 1)
    ]
  })
}

const moveConditionUp = index => {
  const condition = props.conditions[index]
  const mutableCondition = [...props.conditions]
  mutableCondition.splice(index, 1)
  mutableCondition.splice(index - 1, 0, condition)
  emit('update', {
    conditions: mutableCondition
  })
  const focusEl = document.activeElement

  nextTick(() => {
    if (index === 1) {
      focusEl.nextElementSibling.focus()
    } else {
      focusEl.focus()
    }
  })
}
const moveConditionDown = index => {
  const condition = props.conditions[index]
  const mutableCondition = [...props.conditions]
  mutableCondition.splice(index, 1)
  mutableCondition.splice(index + 1, 0, condition)
  emit('update', {
    conditions: mutableCondition
  })
  const focusEl = document.activeElement

  nextTick(() => {
    if (index === nonDefaultConditions.value.length - 2) {
      focusEl.previousElementSibling.focus()
    } else {
      focusEl.focus()
    }
  })
}

const setDefaultResponse = response => {
  const defaultIndex = props.conditions.findIndex(c => c.default)
  if (response) {
    if (defaultIndex !== -1) {
      updateCondition({ response }, defaultIndex)
    } else {
      emit('update', {
        conditions: [
          ...props.conditions,
          {
            response,
            default: true,
            id: Math.floor(Math.random() * 100000)
          }
        ]
      })
    }
  } else {
    // remove default condition if it exists
    if (defaultIndex !== -1) {
      removeCondition(defaultIndex)
    }
  }
}
</script>
<style lang="scss" scoped>
$border: 1px solid $teal;

.drag-drop__conditions {
  margin: 10px;

  & .drag-drop__no-conditions-message {
    color: $color-error;
  }

  & .drag-drop__add-condition {
    padding-top: 15px;
  }

  & .drag-drop__default-feedback-editor {
    margin: 0;
  }
}

.drag-drop__default-feedback {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
}

.drag-drop__default-feedback-label {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
}
.drag-drop__default-feedback-explain {
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 0px 10px 0px;
}

.drag-drop__incorrect-indicator {
  position: absolute;
  right: 0;
  width: 40%;
  top: -40px;
}
</style>
