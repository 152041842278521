<template>
  <div>
    <slot :items="items" />
    <nav v-if="hasItems" class="text-center" aria-label="Pagination">
      <ul class="pagination__list">
        <li>
          <button
            :disabled="onFirstPage || null"
            @click="loadPage(page - 1)"
            class="pagination__previous"
          >
            <icon icon="chevron-left" />
            <span class="sr-only">Previous Page</span>
          </button>
        </li>
        <li
          v-for="(_, i) in Array.from({
            length: truncateLeft ? truncateLength : page - 1
          })"
          :key="i"
        >
          <button @click="loadPage(i + 1)" class="pagination__page">
            <span class="sr-only">Page </span>{{ i + 1 }}
          </button>
        </li>
        <template v-if="truncateLeft">
          <li class="pagination__spacer" aria-hidden="true">
            <icon icon="ellipsis-h" />
          </li>
          <li
            v-for="(_, i) in Array.from({ length: truncateLength })"
            :key="page - (truncateLength - i)"
          >
            <button
              @click="loadPage(page - (truncateLength - i))"
              class="pagination__page"
            >
              <span class="sr-only">Page </span
              >{{ page - (truncateLength - i) }}
            </button>
          </li>
        </template>
        <li class="pagination__page pagination__page--selected">
          <span class="sr-only">Page </span>{{ page }}
        </li>
        <li
          v-for="(_, i) in Array.from({
            length: truncateRight ? truncateLength : totalPages - page
          })"
          :key="page + 1 + i"
        >
          <button @click="loadPage(page + 1 + i)" class="pagination__page">
            <span class="sr-only">Page </span>{{ page + i + 1 }}
          </button>
        </li>
        <template v-if="truncateRight">
          <li class="pagination__spacer" aria-hidden="true">
            <icon icon="ellipsis-h" />
          </li>
          <li
            v-for="(_, i) in Array.from({ length: truncateLength })"
            :key="totalPages - (truncateLength - i)"
          >
            <button
              @click="loadPage(totalPages - (truncateLength - i))"
              class="pagination__page"
            >
              <span class="sr-only">Page </span
              >{{ totalPages - (truncateLength - i) }}
            </button>
          </li>
        </template>
        <li>
          <button
            :disabled="onLastPage || null"
            @click="loadPage(page + 1)"
            class="pagination__next"
          >
            <icon icon="chevron-right" />
            <span class="sr-only">Next Page</span>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'PaginationContainer',
  emits: ['loadpage'],
  props: {
    limit: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    count: {
      type: Number,
      default(props) {
        return props.items.length
      }
    }
  },
  computed: {
    truncateLength() {
      return 2
    },
    hasItems() {
      return this.count > 1
    },
    totalPages() {
      return Math.ceil(this.count / this.limit) || 1
    },
    onFirstPage() {
      return this.page === 1
    },
    onLastPage() {
      return this.page === this.totalPages
    },
    truncateLeft() {
      return this.page > this.truncateLength * 2 + 2
    },
    truncateRight() {
      return this.page < this.totalPages - (this.truncateLength * 2 + 1)
    }
  },
  methods: {
    loadPage(page) {
      this.$emit('loadpage', {
        page,
        limit: this.limit
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination__list {
  list-style: none;
  padding: 0;
  display: inline-flex;
}

.pagination__previous {
  border-radius: 6px 0 0 6px;
}

.pagination__next {
  border-radius: 0 6px 6px 0;
}

.pagination__previous,
.pagination__next {
  padding: 7px 0;
  text-align: center;
  width: 40px;
  font-weight: bold;
  border: $teal solid;
  border-width: 1px 0;
  background: $teal;
  color: white;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: $darker-teal;
    background: $darker-teal;
  }

  &:disabled {
    border-color: lightgrey;
    background: lightgrey;
    color: black;
  }
}

.pagination__spacer {
  padding: 7px 0;
  text-align: center;
  width: 40px;
  color: $teal;
  font-weight: bold;
  background: white;
  border: $teal solid;
  border-width: 1px 0;
}

.pagination__page {
  padding: 7px 0;
  text-align: center;
  width: 40px;
  color: $teal;
  font-weight: bold;
  background: white;
  border: $teal solid;
  border-width: 1px 0;

  &:hover,
  &:focus {
    background: $teal;
    color: white;
  }

  &--selected {
    background: $teal;
    color: white;
  }
}
</style>
