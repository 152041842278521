import { ref, watchEffect } from 'vue'

/**
 * Like Vue.computed, but memoized. Equal primitive values will not trigger a rerender of the component.
 * This is most useful for values that are checked often, but change infrequently.
 */
export default function memo(fn) {
  const cache = ref(fn())

  watchEffect(() => {
    const newValue = fn()
    if (newValue !== cache.value) {
      cache.value = newValue
    }
  })

  return cache
}
