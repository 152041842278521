<template>
  <div ref="sentinel">
    <slot :state="state" />
  </div>
</template>

<script>
import { markRaw, ref, watchEffect } from 'vue'

export default {
  name: 'InfiniteScroll',
  emits: ['load'],
  setup(props, { emit }) {
    const sentinel = ref(null)
    const state = ref('idle')

    const observer = markRaw(
      new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            if (state.value !== 'loading') {
              state.value = 'loading'
              emit('load', {
                loaded: () => (state.value = 'idle'),
                complete: () => {
                  if (state.value === 'idle') {
                    state.value = 'complete'
                  } else {
                    state.value = 'empty'
                  }
                }
              })
            }
          }
        },
        {
          rootMargin: '0px 0px 100px 0px',
          root: document.querySelector('#scroll-container') ?? window
        }
      )
    )

    watchEffect(() => {
      observer.disconnect()
      if (sentinel.value && ['idle', 'loading'].includes(state.value)) {
        observer.observe(sentinel.value)
      }
    })

    const reset = () => {
      state.value = 'idle'
    }

    return {
      sentinel,
      state,
      observer,
      reset
    }
  }
}
</script>

<style></style>
