<template>
  <loading-container :loading="isLoading" class="activity-list">
    <div v-if="isEmpty" class="activity-list__empty">
      <p>This library does not have any activities.</p>
      <div v-if="canCreateActivities">
        <form-button @click="addActivity"> Add Existing Activity </form-button>
        <pendo-button secondary class="activity-list_help-button"
          >Show Me How</pendo-button
        >
        <button-link :to="newActivityRoute"> Create New Activity </button-link>
      </div>
    </div>
    <pagination-container v-else v-bind="pagination" @loadpage="loadPage">
      <ul
        :class="
          browseActivities.viewSelected === 'card'
            ? 'activity-list__card'
            : 'activity-list__row'
        "
      >
        <li v-for="activity in activities" :key="activity.id">
          <slot
            v-if="browseActivities.viewSelected === 'card'"
            name="card"
            :activity="activity"
          />
          <slot v-else name="row" :activity="activity" />
        </li>
      </ul>
    </pagination-container>
  </loading-container>
</template>

<script>
import SelectLibraryToBrowseModal from 'src/shared/components/modals/SelectLibraryToBrowseModal'
import PendoButton from 'src/shared/components/PendoButton'

export default {
  name: 'ActivityList',
  components: { PendoButton },
  inject: ['browseActivities', '$modal'],
  computed: {
    isEmpty() {
      return this.activities.length === 0
    },
    canCreateActivities() {
      return (
        this.browseActivities.canManageLibraryActivities &&
        this.browseActivities.library.status !== 'archived'
      )
    },
    activities() {
      return this.browseActivities.activities
    },
    pagination() {
      return {
        page: this.browseActivities.query.page,
        count: this.browseActivities.activityCount,
        limit: this.browseActivities.activityLimit
      }
    },
    isLoading() {
      return this.browseActivities.isLoadingActivities
    },
    newActivityRoute() {
      return {
        name: 'new_library_activity',
        params: {
          libraryId: this.browseActivities.library.id
        }
      }
    }
  },
  methods: {
    loadPage({ page }) {
      this.browseActivities.updateQuery({ page: page.toString() })
    },
    addActivity() {
      this.$modal.show(SelectLibraryToBrowseModal)
    }
  }
}
</script>

<style lang="scss">
.activity-list {
  grid-area: results;

  &__card {
    list-style: none;
    display: grid;
    gap: 32px;
    grid-auto-flow: row;
    padding: 0;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    padding-inline-start: 0px;
  }
  &__row {
    list-style: none;
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
    margin: 0px;
    padding-inline-start: 0px;
  }

  &__empty {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }

    & button {
      margin: 0 8px;
    }
  }
}
.activity-list_help-button {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
