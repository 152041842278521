// Returns the passed function in a wrapper that prevents simultaneous execution (race condition)
export function notConcurrent(fn) {
  let complete = false

  // "Conch Shell" prevents race condition
  return async function (...args) {
    // eslint-disable-next-line no-unmodified-loop-condition
    while (complete) {
      await complete
    }
    try {
      complete = fn.apply(this, args)
      return await complete
    } finally {
      complete = false
    }
  }
}
