<template>
  <view-container>
    <progress-provider>
      <async-form class="form" style="margin-top: 0" @submit="saveAll" persist>
        <sticky-header>
          <template #primary-navigation>
            <breadcrumb v-if="isAssignmentLoaded">
              <breadcrumb-item
                :to="{
                  name: 'classes'
                }"
                >My Classes
              </breadcrumb-item>
              <breadcrumb-item
                :to="{ name: 'existing_class', params: { id: classId } }"
                >{{ className }}
              </breadcrumb-item>
              <breadcrumb-item
                :to="{
                  name: 'select_questions_to_grade',
                  params: { assignmentId: assignmentId }
                }"
                >Select Questions
              </breadcrumb-item>
            </breadcrumb>
          </template>
          <template #page-action>
            <toggle
              link
              :toggle="showAnswer"
              class="toggle-answer-key"
              @toggle="toggleAnswerKey"
            >
              <template #on>Hide Answer Key</template>
              <template #off>View Answer Key</template>
            </toggle>
            <form-button link @click="showQuestions">
              Show Questions & Answers
            </form-button>
          </template>
          <template #title>
            <sticky-header-title>
              By Question: {{ activity.name || '' }}
            </sticky-header-title>
          </template>
          <template #sub-title>
            <sticky-header-sub-title>
              <progress-summary />
              <span class="total-possible-points">
                Total Possible Points:
                <span class="total-possible-points-number"
                  >{{ totalPossiblePoints }}
                </span>
                <form-button
                  class="change-button"
                  link
                  @click="changeTotalPossiblePoints"
                >
                  change
                </form-button>
              </span>
            </sticky-header-sub-title>
          </template>
          <template #actions>
            <button-dropdown secondary right class="hidden-lg hidden-md">
              <template #button>
                <icon icon="ellipsis-v" />
                <span class="sr-only">Actions</span>
              </template>
              <dropdown-action @click="showQuestions">
                Show Questions & Answers
              </dropdown-action>
              <dropdown-action @click="toggleAnswerKey">
                <span v-if="showAnswer">Hide Answer Key</span>
                <span v-else>View Answer Key</span>
              </dropdown-action>
            </button-dropdown>
            <select-field
              :modelValue="responseGradingProgress"
              aria-label="Status for All Responses"
              @update:modelValue="changeAllResponseStatuses"
            >
              <option value="per_student">
                Set Grading Status Per
                {{ isGroupAssignment ? 'Group' : 'Student' }}
              </option>
              <option value="pending">Set All to Pending Grade</option>
              <option value="graded">Set All to Final Grade</option>
            </select-field>
            <submit-button>
              <template #default>Save Progress</template>
              <template #submitting>Saving</template>
              <template #submitted>Saved</template>
            </submit-button>
          </template>
        </sticky-header>
        <div class="filter-bar">
          <div>
            <h4 class="inline-flex pr-5">
              {{ isGroupAssignment ? 'Group' : 'Student' }} Responses
            </h4>
          </div>
          <div>
            <select-field
              class="inline-flex"
              :modelValue="showingFilter"
              aria-label="Show"
              @update:modelValue="filterChanged"
            >
              <option value="gradeable">Show All Gradeable Responses</option>
              <option value="ungraded">Show Only Ungraded Responses</option>
            </select-field>
          </div>
        </div>
        <progress-element
          v-for="(response, index) in gradableStudentResponses"
          :key="index"
          :elementId="index"
          :title="progressDisplayName(response, index)"
        >
          <grade-student-panel
            :response="response"
            @update:progress="setNewProgress"
            @save:progress="() => saveUpToIndex(index + 1)"
            @save:grade="
              response =>
                saveStudentGrade(response, `#student-${index + 1}-heading`)
            "
            @update:response="setComponentResponse"
            :isGroupAssignment="isGroupAssignment"
            :gradeAnonymously="gradeAnonymously"
            :index="index"
            :show-answer="showAnswer"
            :variables="activity.variables"
            :gradableComponents="gradableComponents"
            :is-dirty="isResponseDirty(response)"
            :is-saved="isResponseSaved(response)"
            :responseGradingProgress="responseGradingProgress"
          />
        </progress-element>

        <infinite-scroll
          v-if="isAssignmentLoaded"
          @load="infiniteHandler"
          ref="scroller"
        >
          <template #default="{ state }">
            <div class="infinite-results">
              <loading-spinner v-if="state === 'loading'" />
              <p v-else-if="state === 'complete'">
                Congratulations! You finished grading all responses for this
                assignment.
              </p>
              <p v-else-if="state === 'empty'">
                No responses to grade yet. Have you turned on "Accept Responses"
                for this assignment?
              </p>
            </div>
          </template>
        </infinite-scroll>
      </async-form>
    </progress-provider>
  </view-container>
</template>

<script setup>
import LoadingSpinner from 'src/shared/components/LoadingSpinner'
import InfiniteScroll from 'src/shared/components/InfiniteScroll'
import GradeStudentPanel from '../components/GradeStudentPanel'
import UnsavedChangesModal from 'src/shared/components/modals/UnsavedChangesModal'
import UnsavedChangesFilterSortModal from '../components/UnsavedChangesFilterSortModal'
import UnlockSectionsConfirmationModal from 'src/shared/components/modals/UnlockSectionsConfirmationModal'
import ShowQuestionsModal from '../components/ShowQuestionsModal'
import TotalPossiblePointsModal from 'src/shared/components/modals/TotalPossiblePointsModal'
import client from 'src/shared/api-client'
import { hasContent } from 'src/shared/components/editor/utils.js'
import { computed, ref, inject, onMounted } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useFlash } from 'src/shared/hooks/flash'

const { success, error, clear } = useFlash()
const PAGE_SIZE = 3

const $modal = inject('$modal')

const props = defineProps({
  assignmentId: {
    type: String,
    required: true
  },
  questions: {
    default: {}
  },
  gradeAnonymously: {
    type: Boolean
  }
})
const scroller = ref(null)
const showingFilter = ref('gradeable')

const filterChanged = async newFilter => {
  if (hasUnsavedChanges.value) {
    const { status } = await $modal.show(UnsavedChangesFilterSortModal)
    if (status !== 'ok') {
      return false
    }
  }
  showingFilter.value = newFilter
  gradableStudentResponses.value = []
  pageNumber.value = 1
  scroller.value?.reset()

  await loadGradableStudentResponses(pageNumber.value, showingFilter.value)
}

const responseGradingProgress = ref('per_student')
const dirtyResponses = ref([])
const savedResponses = ref([])

const isAssignmentLoaded = ref(false)
onMounted(async () => {
  window.addEventListener('beforeunload', beforeUnload)
  await loadGradableAssignment()
  isAssignmentLoaded.value = true
})

const isGroupAssignment = computed(() => {
  return (
    gradableAssignment.value && gradableAssignment.value.assignedTo === 'groups'
  )
})
const loadedPageCount = computed(() => {
  return Math.ceil(gradableStudentResponses.value.length / PAGE_SIZE)
})
const totalResponseCount = ref(undefined)
const totalPageCount = computed(() => {
  return Math.ceil(totalResponseCount.value / PAGE_SIZE)
})

const focusedStudent = ref(0)
const showStudent = computed(() => {
  if (!gradableStudentResponses.value[focusedStudent.value]) {
    return ''
  }
  const response = gradableStudentResponses.value[focusedStudent.value]
  if (isGroupAssignment.value) {
    if (!props.gradeAnonymously) {
      return response.groupName
    }
    return `Group ${focusedStudent.value + 1} of ${totalResponseCount.value}`
  }
  if (!props.gradeAnonymously) {
    return `${response.owner.firstName} ${response.owner.lastName}`
  }
  return `Student ${focusedStudent.value + 1} of ${totalResponseCount.value}`
})
const progressDisplayName = (response, index) => {
  if (isGroupAssignment.value) {
    if (props.gradeAnonymously) {
      return `Group ${index + 1} of ${totalResponseCount.value}`
    }
    return response.groupName
  }
  if (props.gradeAnonymously) {
    return `Student ${index + 1} of ${totalResponseCount.value}`
  } else {
    return `${response.owner.firstName} ${response.owner.lastName}`
  }
}
const hasUnsavedChanges = computed(() => {
  return (
    dirtyResponses.value.length > 0 ||
    responseGradingProgress.value !== 'per_student'
  )
})
const assignmentHasLockedSections = computed(() => {
  return !!(activity.value?.sections ?? []).filter(s => s.completeFirst).length
})
const gradableComponents = computed(() => {
  // Creates an array of the activity sections with "section" as an added field
  // for sort order
  const activityComponents = (activity.value?.sections ?? []).reduce(
    (list, section, i) =>
      list.concat(
        section.components.map(component => ({
          ...component,
          section: section.sectionNumber
        }))
      ),
    []
  )
  // Sets the display order for questions
  return props.questions
    .map(({ id, defaultScore }) => ({
      ...(activityComponents.find(({ _id }) => _id === id) || {}),
      defaultScore
    }))
    .sort(
      (
        { section: s1, questionNumber: q1 },
        { section: s2, questionNumber: q2 }
      ) => {
        if (s1 > s2) {
          return 1
        } else if (s1 < s2) {
          return -1
        } else if (q1 > q2) {
          return 1
        } else if (q1 < q2) {
          return -1
        } else {
          return 0
        }
      }
    )
})
const autoGradedPoints = computed(() => {
  return (activity.value.sections ?? [])
    .flatMap(section => section.components)
    .reduce((total, component) => {
      const componentPoints =
        (component.autograde ? component.pointValue : 0) ?? 0
      return total + componentPoints
    }, 0)
})
const instructorGradedPoints = computed(() => {
  return (activity.value.sections ?? [])
    .flatMap(section => section.components)
    .reduce((total, component) => {
      const componentPoints =
        (!component.autograde ? component.pointValue : 0) ?? 0
      return total + componentPoints
    }, 0)
})
const totalPossiblePoints = computed(() => {
  return gradableAssignment.value.totalPointValue
    ? gradableAssignment.value.totalPointValue
    : gradableAssignment.value.activity?.totalPointValue
})

const showAnswer = ref(false)
const toggleAnswerKey = () => {
  showAnswer.value = !showAnswer.value
}
const isResponseDirty = gradableResponse => {
  if (!dirtyResponses.value) return false
  return dirtyResponses.value.indexOf(gradableResponse.id) >= 0
}
const isResponseSaved = gradableResponse => {
  if (!savedResponses.value) return false
  return savedResponses.value.indexOf(gradableResponse.id) >= 0
}

const hasValidResponse = gradableComponent => {
  if (typeof gradableComponent.value === 'undefined') {
    return false
  }
  switch (gradableComponent.componentType) {
    case 'OpenEndedQuestion': {
      return hasContent(gradableComponent.value)
    }
    case 'MultipleChoiceQuestion':
    case 'GridQuestion':
    case 'GridGraphQuestion':
      return typeof gradableComponent.value !== 'undefined'
  }
  return true
}

const saveStudentGrade = async (gradableResponse, element) => {
  if (gradableResponse.newProgress) {
    gradableResponse.gradingProgress = gradableResponse.newProgress
  }
  await client.assignments.gradeResponse({
    assignmentId: props.assignmentId,
    id: gradableResponse.id,
    ...gradableResponse
  })
  dirtyResponses.value.splice(
    dirtyResponses.value.indexOf(gradableResponse.id),
    1
  )
  success('Student grade Saved successfully')
  // $scrollTo(element, 500, { easing: 'linear', offset: -50 })
}
const shouldUnlock = ref(false)
const saveAll = async e => {
  clear()
  const unlock = shouldUnlock
  try {
    await saveStudentResponses({ unlock })
    e.done()
  } catch (error) {
    e.done(false)
    throw error
  }
}
const saveUpToIndex = async upToIndex => {
  clear()

  await saveStudentResponses({
    upToIndex,
    unlock: false
  })
}
const saveStudentResponses = async ({ upToIndex }) => {
  let responsesToSave = upToIndex
    ? gradableStudentResponses.value.slice(0, upToIndex)
    : gradableStudentResponses.value

  responsesToSave = responsesToSave.map(response => {
    response.gradingProgress =
      responseGradingProgress.value === 'per_student'
        ? response.newProgress || response.gradingProgress
        : responseGradingProgress

    return response
  })

  try {
    await client.assignments.saveResponsesBulk({
      assignmentId: props.assignmentId,
      assignmentResponses: responsesToSave
    })
    savedResponses.value = responsesToSave.map(r => r.id)
    dirtyResponses.value = dirtyResponses.value.filter(
      id => !savedResponses.value.includes(id)
    )
    success('Student grades saved successfully!')
  } catch (error) {
    if (!error.body?.operations) {
      throw error
    }
    const failedResponseIds = error.body?.operations.map(failure => failure.id)
    dirtyResponses.value.forEach((dirtyResponse, index) => {
      if (!failedResponseIds.includes(dirtyResponse)) {
        dirtyResponses.value.splice(index, 1)
        savedResponses.value.push(dirtyResponse)
      }
    })
    error(
      "Some students' grades failed to save. Please try again or contact support if the issue persists."
    )
  }
}
const pageNumber = ref(0)
const infiniteHandler = async ({ loaded, complete }) => {
  pageNumber.value++
  await loadGradableStudentResponses(pageNumber.value, showingFilter.value)
  if (totalPageCount.value > 0) {
    loaded()
  }
  if (
    totalPageCount.value === 0 ||
    totalPageCount.value === loadedPageCount.value
  ) {
    complete()
  }
}
const gradableAssignment = ref({})
const activity = ref({})
const className = ref('')
const classId = ref('')
const loadGradableAssignment = async () => {
  gradableAssignment.value = await client.assignments.get({
    assignmentId: props.assignmentId
  })
  classId.value = gradableAssignment.value.class
  const body = await client.classes.get({ classId: classId.value })
  className.value = body.name
  const unflattenedActivity = await client.assignments.getActivity({
    assignmentId: props.assignmentId
  })
  activity.value = {
    ...unflattenedActivity,
    sections: unflattenedActivity.sections.map(section => {
      return {
        ...section,
        components: section.components.flatMap(component => {
          if (component.componentType === 'SplitView') {
            return [...component.leftContent, ...component.rightContent]
          }
          return component
        })
      }
    })
  }
  activity.value.sections.forEach(section => {
    let qNum = 1
    for (let i = 0; i < section.components.length; i++) {
      const component = section.components[i]
      if (
        component.componentType.indexOf('Question') >= 0 ||
        component.componentType === 'GeneralInstruction'
      ) {
        component.questionNumber = qNum++
        component.defaultScore = component.pointValue
      }
    }
  })
}
const gradableStudentResponses = ref([])
const loadGradableStudentResponses = async (pageNumber, filter) => {
  const { page, total } = await client.assignments.getAllResponses({
    assignmentId: props.assignmentId,
    hasResponse: true,
    components: gradableComponents.value.map(({ _id }) => _id),
    page: pageNumber,
    limit: PAGE_SIZE,
    filter,
    sort: 'owner.lastname',
    dir: 'asc'
  })

  // Set default scores for responses.
  page.forEach(studentResponse => {
    studentResponse.responses = gradableComponents.value.map(
      gradableComponent => {
        const componentResponse = (studentResponse.responses || []).find(
          r => r.component === gradableComponent._id
        ) || { component: gradableComponent._id }
        // If a student has responded to a component and does not already have
        // any score, give that component the default score
        if (
          hasValidResponse({
            ...componentResponse,
            componentType: gradableComponent.componentType
          }) &&
          typeof componentResponse.score !== 'number' &&
          typeof gradableComponent.defaultScore === 'number'
        ) {
          componentResponse.score = gradableComponent.defaultScore
          if (!dirtyResponses.value.includes(studentResponse.id)) {
            dirtyResponses.value.push(studentResponse.id)
          }
        }
        return componentResponse
      }
    )
  })
  gradableStudentResponses.value = gradableStudentResponses.value.concat(page)
  totalResponseCount.value = total
}
// setters
const setComponentResponse = (
  studentResponse,
  component,
  { newScore, newComments }
) => {
  const componentResponse = studentResponse.responses.find(
    componentResponse => componentResponse.component === component._id
  )
  if (!componentResponse) {
    const newResponse = {
      component: component._id,
      score: newScore,
      comments: newComments
    }
    studentResponse.responses.push(newResponse)
  } else {
    componentResponse.score = newScore
    componentResponse.comments = newComments
  }
  if (!dirtyResponses.value.includes(studentResponse.id)) {
    dirtyResponses.value.push(studentResponse.id)
  }
}
const setNewProgress = (studentResponse, newProgress) => {
  studentResponse.newProgress = newProgress
  if (!dirtyResponses.value.includes(studentResponse.id)) {
    dirtyResponses.value.push(studentResponse.id)
  }
}
const showQuestions = async () => {
  await $modal.show(ShowQuestionsModal, {
    gradableComponents: gradableComponents,
    variables: activity.value.variables
  })
}
const changeAllResponseStatuses = async newProgress => {
  responseGradingProgress.value = newProgress
  clear()
  shouldUnlock.value = false
}
const changeTotalPossiblePoints = async () => {
  const { status, data } = await $modal.show(TotalPossiblePointsModal, {
    autoGradedPoints: autoGradedPoints,
    instructorGradedPoints: instructorGradedPoints,
    totalPossiblePoints: gradableAssignment.value.totalPointValue
      ? gradableAssignment.value.totalPointValue
      : activity.value.totalPointValue
  })
  if (status === 'ok') {
    gradableAssignment.value.totalPointValue = data
    const newTotalPointValue = gradableAssignment.value.totalPointValue
      ? gradableAssignment.value.totalPointValue
      : activity.value.totalPointValue
    await client.assignments.update({
      assignmentId: gradableAssignment.value.id,
      totalPointValueOverride: newTotalPointValue
    })
    success(
      `Total possible points successfully updated to ${newTotalPointValue}.`
    )
  }
}

const beforeUnload = e => {
  const text = 'You have unsaved changes.'
  if (hasUnsavedChanges.value) {
    e.returnValue = text
    return text
  }
}

onBeforeRouteLeave(async (to, from, next) => {
  if (to.redirectedFrom && to.redirectedFrom.path === to.path) {
    //this is a hack to prevent firing twice when there is a query string in the url, as in the case with a feature flag.
    next()
    return
  }
  if (hasUnsavedChanges.value) {
    const { status } = await $modal.show(UnsavedChangesModal)
    if (status === 'ok') {
      gradableStudentResponses.value = []
      window.removeEventListener('beforeunload', beforeUnload)
      next()
    } else {
      next(false)
    }
  } else {
    gradableStudentResponses.value = []
    window.removeEventListener('beforeunload', beforeUnload)
    next()
  }
  return
})
</script>

<style lang="scss" scoped>
.infinite-results {
  display: flex;
  justify-content: center;
}

.split-dropdown {
  margin: 10px 0 15px 0;
}
.toggle-answer-key {
  margin-right: 10px;
}

.total-possible-points {
  padding-left: 15px;
}

.total-possible-points-number {
  font-weight: bold;
}

.change-button {
  margin-left: 5px;
}

.inline-flex {
  display: inline-flex;
}

.pr-5 {
  padding-right: 1rem;
}

:deep(.sticky-header--stuck) {
  .sticky-header__row-3 {
    font-size: 12px;
    select {
      font-size: inherit;
      padding: 10px 14px;
      height: auto;
    }

    h4 {
      font-size: 14px;
    }
  }
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}
</style>
