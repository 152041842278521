import './setup/polyfill'
import './setup/font-awesome'
import 'src/styles/index.scss'
import { h, createApp } from 'vue'
import initVue from 'src/setup/vue'
import App from 'src/App'
import 'src/setup/math'
import 'katex/contrib/mhchem/mhchem'
import 'src/styles/utils.scss'

const app = createApp({
  setup() {
    return () => h(App)
  },
  watch: {
    $route() {
      if (window.Intercom) window.Intercom('update')
    }
  }
})

initVue(app)

app.mount('#app')
