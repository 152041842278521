<template>
  <collapse-panel :title="'Tags & Categories'" :collapsed="false" show-on-print>
    <div class="activity-inner-panel">
      <div class="left-panel-side">
        <div class="activity-input-group">
          <label>System Tags</label>
          <autocomplete
            v-model="activity.tags"
            :options="tags"
            :override="isAdmin || isContentDeveloper"
            multiple
          />
        </div>
        <div class="activity-input-group">
          <label>Author Tags</label>
          <autocomplete
            v-model="activity.publicTags"
            :options="publicTags"
            override
            multiple
          />
        </div>
      </div>
      <div class="right-panel-side">
        <label>Categories</label>
        <ul class="category-list">
          <template
            v-for="[key, tree] in Object.entries(categories)"
            :key="key"
          >
            <li>
              <collapse-provider :collapsed="true">
                <collapse-toggle class="category-list__heading">
                  <collapse-icon icon="caret" />

                  {{ tree.name }} ({{ categoryCounts[key] }})
                </collapse-toggle>

                <collapse-content>
                  <tree-input
                    :tree="tree.children"
                    v-model="activity.categories"
                    :select-children="false"
                  />
                </collapse-content>
              </collapse-provider>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </collapse-panel>
</template>

<script>
import { onBeforeMount, inject, ref, computed } from 'vue'
import { mapGetters } from 'vuex'
import TreeInput from '../../libraries/components/filters/TreeInput.vue'
import Autocomplete from 'src/shared/components/Autocomplete'
import client from 'src/shared/api-client.ts'

function buildSlugList(tree) {
  function buildSlugListRec(tree, slugs) {
    if (tree.children?.length > 0) {
      let count = 0
      for (const child of tree.children) {
        count = count + buildSlugListRec(child, slugs)
      }
      slugs.push({ slug: tree.slug, count: count + 1 })
      return count
    } else {
      slugs.push({ slug: tree.slug, count: 1 })
      return 1
    }
  }

  const slugs = []
  buildSlugListRec(tree, slugs)

  return slugs
}

export default {
  name: 'ActivityTagsSection',
  inject: ['inherited'],
  components: { Autocomplete, TreeInput },
  setup() {
    const categories = ref({})

    const slugsByCategory = computed(() =>
      Object.fromEntries(
        Object.entries(categories.value).map(([key, tree]) => [
          key,
          buildSlugList(tree)
        ])
      )
    )

    onBeforeMount(async () => {
      categories.value = await client.libraries.getFilters()
    })

    return { categories, slugsByCategory }
  },

  computed: {
    ...mapGetters(['isAdmin', 'isContentDeveloper']),

    activity() {
      return this.inherited.activity
    },

    tags() {
      return this.inherited.tags
    },

    publicTags() {
      return this.inherited.publicTags
    },

    categoryCounts() {
      const slugs = this.inherited.activity.categories

      return Object.fromEntries(
        Object.entries(this.slugsByCategory).map(([key, slugList]) => [
          key,
          slugList.filter(slug => slugs.includes(slug.slug)).length
        ])
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.category-list__heading {
  font-size: 18px;
}
.category-list {
  padding: 0;
  list-style-type: none;
}
</style>
