<template>
  <tr
    v-for="rowIndex in maxCells"
    :key="`data-grid__lastRow-${rowIndex}`"
    class="data-grid__calculation-row"
  >
    <th scope="row" class="data-grid__calculation-th" />
    <data-grid-calculation-column
      v-for="(column, columnIndex) in columnsArray"
      :key="columnIndex"
      :column="column"
      :column-index="columnIndex"
      :row-index="rowIndex - 1"
      :nav-row-index="numRows + rowIndex - 1"
      :readonly="readonly"
      :last-column="columnIndex === columnsArray.length - 1"
      :variable-context="variableContext"
    />
  </tr>
</template>

<script setup>
import { computed, onBeforeUnmount, watch, inject, markRaw, ref } from 'vue'
import * as Y from 'yjs'
import DataGridCalculationColumn from './DataGridCalculationColumn'

const props = defineProps({
  numRows: {
    type: Number,
    required: true
  },
  columns: {
    type: Y.Array,
    required: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  variableContext: {
    type: Object,
    default: undefined,
    required: false
  }
})

let columnsArray = ref([])
let unsubscribe = () => {}

onBeforeUnmount(() => {
  unsubscribe?.()
})
watch(
  props.columns,
  () => {
    unsubscribe?.()
    const columns = markRaw(props.columns)
    const onChange = () => {
      columnsArray.value = columns.toArray()
    }
    onChange()
    columns.observe(onChange)
    unsubscribe = () => {
      columns.unobserve(onChange)
    }
  },
  { immediate: true }
)

const maxCells = computed(() => {
  let max = 0
  for (const column of columnsArray.value) {
    const calculations = column.get('calculations')
    if (calculations) {
      max = Math.max(max, calculations.toArray().length)
    }
  }
  return max + 1
})
</script>

<style lang="scss" scoped>
.data-grid__calculation-row {
  vertical-align: top;

  td,
  th {
    height: 30px;
  }
}

th {
  font-weight: bold;

  & > div {
    position: relative;
    display: flex;
    height: $grid-row-small-height;
    border-left: 1px solid $grid-border-color;
    border-right: 2px solid $grid-border-color;
    background-color: $grid-header-background-color;

    &:not(:first-child) {
      background: none;
      border-bottom: none;
      border-left: none;
    }
  }
}
</style>
