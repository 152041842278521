<template>
  <div :class="settings.hideTable && !editMode ? 'hidden' : ''">
    <data-grid-actions-provider
      ref="actions"
      :grid="ymap"
      v-model:focused-cell="focusedCell"
      :variable-context="variableContext"
      @reset="$emit('reset')"
      @bluetooth-data-collected="onBluetoothDataCollected"
    >
      <data-grid-formula-bar
        v-if="gridSettings.includeFormulaBar"
        :focused-cell="focusedCell"
        :readonly="readonly"
        :edit-mode="editMode"
        :variable-context="variableContext"
        :style="{ width: `${tableWidth}px` }"
      />
      <table-wrapper
        ref="table"
        v-bind="$attrs"
        class="pi-data-grid"
        sticky="both"
        :width="tableWidth"
        :aria-label="ariaLabel"
        :aria-describedby="ariaDescribedby"
        @keydown="onKeyDown"
        @isEditing="onEditingChange($event)"
      >
        <template #header>
          <data-grid-header
            :columns="columns"
            :show-grid-actions-menu="showGridActionsMenu"
            :allow-download-csv="gridSettings.includeCsvDownload"
            :allow-bluetooth="gridSettings.includeBluetooth"
            :allow-reset="allowReset"
            :readonly="readonly"
            :focused-cell="focusedCell"
            @focus:cell="setFocusedCell"
          />
        </template>
        <template #body>
          <data-grid-body
            :num-rows="numRows"
            :columns="columns"
            :readonly="readonly"
            :focused-cell="focusedCell"
            @focus:cell="setFocusedCell"
            :variable-context="variableContext"
            :edit-mode="editMode"
          />
        </template>
        <template #footer>
          <data-grid-footer
            v-if="gridSettings.includeColumnStatistics"
            :columns="columns"
            :readonly="readonly"
            :num-rows="numRows"
            :variable-context="variableContext"
          />
        </template>
      </table-wrapper>
    </data-grid-actions-provider>
  </div>
</template>

<script>
import * as Y from 'yjs'
import DataGridActionsProvider from 'src/shared/components/grid-graph/DataGridActionsProvider'
import DataGridHeader from 'src/shared/components/grid-graph/DataGridHeader'
import DataGridBody from 'src/shared/components/grid-graph/DataGridBody'
import DataGridFooter from 'src/shared/components/grid-graph/DataGridFooter'
import DataGridFormulaBar from 'src/shared/components/grid-graph/DataGridFormulaBar'
import { columnDisplayWidth } from './utilities'
export default {
  name: 'DataGrid',
  components: {
    DataGridActionsProvider,
    DataGridHeader,
    DataGridBody,
    DataGridFooter,
    DataGridFormulaBar
  },
  inheritAttrs: false,
  emits: ['reset'],
  props: {
    ymap: {
      type: Y.Map,
      required: true
    },
    readonly: Boolean,
    ariaLabel: {
      type: String,
      default: ''
    },
    ariaDescribedby: {
      type: String,
      default: ''
    },
    showGridActionsMenu: {
      type: Boolean,
      default: true
    },
    allowDownloadCsv: {
      type: Boolean,
      default: true
    },
    allowReset: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      required: true
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tableWidth() {
      return this.columns
        ? this.columns
            .toArray()
            .reduce((acc, column) => acc + columnDisplayWidth(column), 0) + 50
        : 195
    },
    gridSettings() {
      const defaultSettings = {
        includeColumnStatistics: false,
        includeFormulaBar: false,
        includeBluetooth: true,
        includeCsvDownload: true
      }
      const gridSettings = { ...this.settings }

      for (const key in defaultSettings) {
        if (!Object.prototype.hasOwnProperty.call(gridSettings, key)) {
          gridSettings[key] = defaultSettings[key]
        }
      }

      return gridSettings
    }
  },
  data() {
    return {
      numRows: this.ymap.get('rows'),
      columns: this.ymap.get('columns'),
      focusedCell: { column: null, row: null, value: null, allowText: false },
      isEditing: false
    }
  },
  methods: {
    async onKeyDown(e) {
      if (
        ((e.key === 'Enter' && this.isEditing && !e.shiftKey) ||
          e.key === 'ArrowDown') &&
        !this.gridSettings.includeColumnStatistics &&
        !this.readonly
      ) {
        if (this.focusedCell.row === this.numRows - 1) {
          this.$refs.actions.insertRowBelowRow(this.focusedCell.row)
        }
      }
      if (e.ctrlKey) {
        switch (e.key) {
          case 'ArrowUp':
          case 'ArrowRight': {
            e.stopPropagation()
            e.preventDefault()
            const cell = ['TD', 'TH'].includes(e.target.tagName)
              ? e.target
              : e.target.closest('td, th')
            if (cell.cellIndex > 0) {
              const styles = window.getComputedStyle(cell)
              const width = parseInt(styles.width, 10)
              this.$refs.actions.setColumnWidth(cell.cellIndex - 1, width + 10)
            }
            break
          }
          case 'ArrowLeft':
          case 'ArrowDown': {
            e.stopPropagation()
            e.preventDefault()
            const cell = ['TD', 'TH'].includes(e.target.tagName)
              ? e.target
              : e.target.closest('td, th')
            if (cell.cellIndex > 0) {
              const styles = window.getComputedStyle(cell)
              const width = parseInt(styles.width, 10)
              this.$refs.actions.setColumnWidth(cell.cellIndex - 1, width - 10)
            }
            break
          }
        }
      }
    },
    onBluetoothDataCollected({ totalSamples }) {
      const row = this.$refs.table.$el.querySelector('tbody').rows[totalSamples]
      this.$nextTick(() => {
        row?.scrollIntoView({ block: 'nearest' })
      })
    },
    setFocusedCell(props) {
      this.focusedCell = props
    },
    onEditingChange(value) {
      this.isEditing = value
    }
  },

  beforeUnmount() {
    this.unsubscribe?.()
  },
  watch: {
    ymap: {
      handler() {
        this.unsubscribe?.()
        const ymap = this.ymap
        const onChange = () => {
          this.numRows = ymap.get('rows')
          this.columns = ymap.get('columns')
        }
        onChange()
        ymap.observe(onChange)
        this.unsubscribe = () => ymap.unobserve(onChange)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.pi-data-grid {
  width: 100%;
  max-width: 100%;
  padding-bottom: 15px;
  overflow: auto;
  position: relative;
  max-height: 600px; /* for sticky table */
  font-size: 14px;
  margin-top: -1px;
}
</style>

<style lang="scss">
.pi-data-grid div.dropdown + div {
  input {
    padding-right: $grid-input-h-padding + $grid-row-default-height;
  }
}

th.row-header {
  width: $grid-first-column-default-width;
}
</style>
