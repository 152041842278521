import {
  GetVideoInstanceRequest,
  ListInstancesResponse,
  VideoInstanceResponse
} from '@pi/api-types'
import Client from './client'

export default class Instances {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async list(): Promise<ListInstancesResponse[]> {
    const { body } = await this.client.get<ListInstancesResponse[]>({
      path: `/api/v2/instances`
    })
    return body
  }

  async get(request: GetVideoInstanceRequest): Promise<VideoInstanceResponse> {
    const { body } = await this.client.get<VideoInstanceResponse>({
      path: `/api/v2/instances/${request.id}`
    })
    return body
  }
}
