<template>
  <view-container>
    <view-header>
      <view-title v-if="instructor">
        <template v-if="instructor.firstName">
          {{ instructor.firstName }} {{ instructor.lastName }}
        </template>
        <template v-else> Instructor </template>
        <template v-if="instructor.email">
          -
          <a :href="`mailto:${instructor.email}`">{{ instructor.email }}</a>
        </template>
      </view-title>

      <view-title v-else>Instructor</view-title>
    </view-header>
    <loading-container :loading="isLoading">
      <div class="form-group row">
        <h2 class="col-md-6 col-sm-10">Classes</h2>
      </div>
      <list v-if="instructor && classes" :items="classes">
        <list-column title="Name" property="name" />
        <list-column title="Class Key" property="classKey" />
        <list-column title="# Students" property="studentCount" />
        <list-column v-slot="{ id }" title="Actions">
          <router-link
            :to="{
              name: 'instructor_class',
              params: { classId: id }
            }"
          >
            View
          </router-link>
        </list-column>
      </list>
    </loading-container>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client.ts'

export default {
  name: 'InstructorView',
  props: {
    instructorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      instructor: {},
      classes: [],
      isLoading: true
    }
  },
  methods: {
    async load() {
      await Promise.all([this.loadInstructor(), this.loadClasses()])
      this.isLoading = false
    },
    async loadInstructor() {
      this.instructor = await client.users.getUser(this.instructorId)
    },
    async loadClasses() {
      this.classes = await client.classes.getClassesForUser({
        id: this.instructorId,
        roles: ['instructor']
      })
    }
  },
  watch: {
    instructorId: {
      handler() {
        return this.load()
      },
      immediate: true
    }
  }
}
</script>
