<template>
  <div
    v-if="hasSummary"
    class="activity-objectives bg-pivot-teal-50 color-ui-gray-800"
  >
    <template v-if="!viewAsStudent">
      <h3 class="changelog-entry__heading" v-if="changelogEntry">
        Last updated
        {{
          format(new Date(changelogEntry.createdAt), 'MMM dd, yyyy h:mm aaa')
        }}
        by
        {{ changelogEntry.createdBy.firstName }}
        {{ changelogEntry.createdBy.lastName }}
        <form-button
          class="changelog-entry__view-button"
          v-if="changelogEntry"
          link
          @click="openChangelogModal"
        >
          View Changelog
        </form-button>
      </h3>
      <h3 v-else class="changelog-entry__heading">
        Last updated
        {{ format(new Date(updatedAt), 'MMM dd, yyyy h:mm aaa') }}
      </h3>
    </template>
    <h2 class="activity-objectives__header">Learning Objectives</h2>
    <editor-content
      :text="text"
      :variable-context="variableContext"
      :view-as-student="viewAsStudent"
    />
  </div>
</template>

<script setup>
import { hasContent } from 'src/shared/components/editor/utils'
import { computed, inject, ref } from 'vue'
import ActivityChangelogModal from './ActivityChangelogModal'
import { format } from 'date-fns'

const props = defineProps({
  variableContext: {
    type: Object,
    required: true
  },
  changelog: {
    type: Array,
    default: undefined
  },
  viewAsStudent: {
    type: Boolean,
    default: false
  }
})

const inherited = inject('inherited')
const modal = inject('$modal')

const text = computed(() => inherited.value.activity.objectives?.summary || '')
const updatedAt = computed(() => inherited.value.activity.updatedAt)
const hasSummary = computed(() => hasContent(text.value))

const changelogEntry = computed(() => props.changelog?.[0])

const openChangelogModal = () => {
  modal.show(ActivityChangelogModal, {
    changelogEntries: props.changelog || []
  })
}
</script>

<style lang="scss" scoped>
.activity-objectives {
  margin: 0 -9999rem 24px;
  padding: 28px 9999rem;
}

.activity-object-summary {
  margin-bottom: 24px;
}
.changelog-entry__heading {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}
.changelog-entry__view-button {
  margin-left: 10px;
  font-style: normal;
  text-decoration: underline;
  color: white;
  font-weight: 400;
}
</style>
