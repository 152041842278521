<template>
  <browse-licenses ref="browseLicenses" :query="computedQuery">
    <view-container>
      <view-header>
        <view-title>Licenses</view-title>
        <view-header-actions>
          <button-link :to="{ name: 'admin_create_license' }">
            <icon icon="plus" />
            New License
          </button-link>
        </view-header-actions>
      </view-header>
      <browse-licenses-layout>
        <template #sidebar>
          <browse-sidebar>
            <sidebar-filter>
              <type-filter />
              <status-filter />
              <level-filter />
            </sidebar-filter>
          </browse-sidebar>
        </template>
        <template #search>
          <license-search />
        </template>
        <template #summary>
          <browse-summary />
        </template>
        <template #licenses>
          <license-list />
        </template>
      </browse-licenses-layout>
    </view-container>
  </browse-licenses>
</template>

<script>
import BrowseLicenses from 'src/modules/licenses/components/BrowseLicenses'
import BrowseLicensesLayout from 'src/modules/licenses/components/BrowseLicensesLayout'
import LicenseSearch from 'src/modules/licenses/components/LicenseSearch'
import BrowseSidebar from 'src/shared/components/BrowseSidebar'
import BrowseSummary from 'src/modules/licenses/components/BrowseSummary'
import LicenseList from 'src/modules/licenses/components/LicenseList'
import TypeFilter from 'src/modules/licenses/components/TypeFilter'
import LevelFilter from 'src/modules/licenses/components/LevelFilter'
import StatusFilter from 'src/modules/licenses/components/StatusFilter'
import SidebarFilter from 'src/modules/licenses/components/SidebarFilter'

export default {
  name: 'LicensesView',
  components: {
    BrowseLicenses,
    BrowseLicensesLayout,
    BrowseSidebar,
    BrowseSummary,
    LicenseSearch,
    LicenseList,
    TypeFilter,
    LevelFilter,
    StatusFilter,
    SidebarFilter
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  computed: {
    computedQuery() {
      return {
        page: parseInt(this.query.page) || 1,
        sort: this.query.sort || 'updatedAt',
        dir: this.query.dir || 'desc',
        q: this.query.q || '',
        types: Array.isArray(this.query.types || [])
          ? this.query.types || []
          : [this.query.types],
        status: Array.isArray(this.query.status || [])
          ? this.query.status || ['active', 'expiring', 'inactive']
          : [this.query.status],
        level: this.query.level || 'all'
      }
    }
  }
}
</script>
