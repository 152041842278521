<template>
  <div class="drag-drop__template">
    <selector-input
      v-model="renderType"
      aria-labelledby="Render type"
      label="render-type"
      required
      v-show="!renderTypeChosen"
    >
      <selector-option value="latex" title="LaTeX" />
      <selector-option value="rich-text" title="Rich Text" />
    </selector-input>
    <div v-if="renderTypeChosen">
      <form-label
        >Drag & Drop Area
        <help-popover
          v-if="renderType === 'latex'"
          :to="{ name: 'latex_dnd_help' }"
        >
          Help for LaTeX Drag and Drop questions
        </help-popover>
        <help-popover v-else :to="{ name: 'rich_text_dnd_help' }"
          >Help for Rich Text Drag and Drop questions</help-popover
        >
      </form-label>
      <drag-drop-area
        :type="renderType"
        :variable-context="variableContext"
        :choices="component.choices ?? []"
        :targets="component.dropTargets ?? []"
        :text="component.dropAreaText"
        @update="onDropAreaChange"
      />

      <tab-provider tab="choices" disable-scroll>
        <collapse-provider show-on-print class="drag-drop__collapse-panel">
          <div class="drag-drop__tab-spacer">
            <collapse-toggle>
              <collapse-icon class="collapse-icon" />
              <span class="sr-only">Collapse</span>
            </collapse-toggle>
            <tab-header>
              <tab-header-button tab="choices">
                <span :class="{ 'error-tab': hasChoiceErrors }">
                  <icon
                    v-if="hasChoiceErrors"
                    icon="triangle-exclamation"
                    class="error-icon"
                  />
                  Choices ({{ component.choices.length }})
                </span>
              </tab-header-button>
              <tab-header-button tab="conditions">
                <span :class="{ 'error-tab text-warning-orange-600': hasConditionErrors }">
                  <icon
                    v-if="hasConditionErrors"
                    icon="triangle-exclamation"
                    class="error-icon"
                  />
                  Conditions & Feedback ({{ totalConditions }})
                </span>
              </tab-header-button>
            </tab-header>
          </div>

          <collapse-content>
            <tab-panel class="drag-drop__background" :lazy="false">
              <template #choices>
                <span class="drag-drop__header--row">
                  <checkbox
                    @click.stop
                    value="randomized-order"
                    :modelValue="component.randomizedChoices"
                    @update:modelValue="
                      randomizedChoices =>
                        $emit('change', { randomizedChoices })
                    "
                    >Randomize Choice Order</checkbox
                  >
                </span>
                <drag-drop-choice
                  v-for="(choice, index) in choices"
                  :type="renderType"
                  :key="choice.id"
                  :choice="choice"
                  :index="index"
                  :variable-context="variableContext"
                  :targets="dropTargets"
                  :canMoveDown="index === choices.length - 1"
                  @update="choice => updateChoice(index, choice)"
                  @move="dir => move(index, dir)"
                  @duplicate="duplicate(index)"
                  @remove="remove(index)"
                  @insert="addAtIndex(index)"
                />
                <div class="drag-drop__add-choice-btn">
                  <form-button link @click="addChoice">
                    <icon icon="plus" />
                    Add Choice
                  </form-button>
                </div>
              </template>
              <template #conditions>
                <drag-and-drop-conditions
                  :conditions="component.conditions"
                  :variable-context="variableContext"
                  :name="name"
                  :dropTargets="dropTargets"
                  :showIncorrectIndicators="component.showIncorrectIndicators"
                  @update="changes => emit('change', changes)"
                >
                  <template #condition="{ condition, first, last, ...events }">
                    <drag-and-drop-condition
                      v-bind="events"
                      :dropTargets="dropTargets"
                      :choices="choices"
                      :condition="condition"
                      :renderType="renderType"
                      :allowEmptyTargets="component.allowEmptyTargets"
                      :first="first"
                      :last="last"
                      :variable-context="variableContext"
                      :name="`${name}.conditions.c${condition.id}`"
                    />
                  </template>
                </drag-and-drop-conditions>
              </template>
            </tab-panel>
          </collapse-content>
        </collapse-provider>
      </tab-provider>
    </div>
    <div v-else>Render choice is required</div>
  </div>
</template>

<script setup>
import { nextTick, computed, inject } from 'vue'
import memo from 'src/shared/hooks/memo'
import DragAndDropConditions from './DragAndDropConditions.vue'
import DragAndDropCondition from './DragAndDropCondition.vue'
import DragDropArea from './DragDropArea.vue'
import DragDropChoice from './DragDropChoice.vue'
import { indexToLetter } from 'src/shared/utils/index-to-letter.js'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import { useFormErrors } from 'vee-validate'

const emit = defineEmits(['change', 'choiceActions'])

const choices = computed(() => props.component.choices?.slice() || [])

const props = defineProps({
  component: {
    type: Object,
    required: true
  },
  variableContext: {
    type: Object,
    required: true
  },
  name: {
    type: String,
    required: true
  }
})

const formErrors = useFormErrors()

const hasConditionErrors = memo(() => {
  return Object.keys(formErrors.value).some(key =>
    key.startsWith(`${props.name}.condition`)
  )
})

const hasChoiceErrors = memo(() => {
  return Object.keys(formErrors.value).some(key =>
    key.startsWith(`${props.name}.choices`)
  )
})

const modal = inject('$modal')

const renderType = computed({
  get() {
    return props.component.renderType
  },
  async set(renderType) {
    const { status } = await modal.show(ConfirmModal, {
      prompt: `This will lock your rendering type to ${renderType}.  This cannot be undone.  Are you sure you want to proceed?`
    })
    if (status === 'ok') {
      emit('change', {
        renderType
      })
    }
  }
})

const renderTypeChosen = computed(
  () => renderType.value === 'latex' || renderType.value === 'rich-text'
)
const filteredConditions = computed(() => {
  return props.component.conditions.filter(c => !c.default)
})
const totalConditions = computed(() => {
  return (filteredConditions.value || []).length
})

// When a drop target is removed, we have to remove it from all valid target lists in choices and from each condition.
// When a drop target is added, we have to add it to each condition with a default value.
function onDropAreaChange({ text, targets }) {
  if (targets) {
    emit('change', {
      dropAreaText: text,
      dropTargets: targets,
      choices: props.component.choices.map(choice => ({
        ...choice,
        validTargets: choice.validTargets.filter(target =>
          targets.some(t => t.id === target)
        )
      })),
      conditions: props.component.conditions.map(condition =>
        condition.default
          ? condition
          : {
              ...condition,
              targets: targets.map(
                target =>
                  condition.targets.find(c => c.targetId === target.id) ?? {
                    targetId: target.id,
                    conditionType: 'is',
                    choices: []
                  }
              )
            }
      )
    })
  } else {
    emit('change', {
      dropAreaText: text
    })
  }
}

// We need to figure out the label for each target to present in the UI.
const dropTargets = computed(
  () =>
    props.component.dropTargets?.map((target, index) => ({
      ...target,
      label: target.label ? `${target.label}` : indexToLetter(index)
    })) ?? []
)

const getId = () => Math.floor(Math.random() * 100000)

const move = (index, dir) => {
  const choice = choices.value[index]
  const mutableChoices = choices.value.slice()
  mutableChoices.splice(index, 1)
  if (dir < 0) {
    mutableChoices.splice(index - 1, 0, choice)
  } else {
    mutableChoices.splice(index + 1, 0, choice)
  }
  emit('change', { choices: mutableChoices })
  const focusEl = document.activeElement
  nextTick(() => {
    if (dir < 0 && index - 1 === 0) {
      focusEl.nextElementSibling.focus()
    } else if (dir > 0 && index + 1 === mutableChoices.length - 1) {
      focusEl.previousElementSibling.focus()
    } else {
      focusEl.focus()
    }
  })
}
const addAtIndex = index => {
  const mutableChoices = choices.value.slice()
  mutableChoices.splice(index + 1, 0, {
    id: getId(),
    text: '',
    validTargets: [],
    count: 1
  })
  emit('change', { choices: mutableChoices })
}

// When we remove a choice, we have to remove it from all conditions that expect it.
const remove = index => {
  const mutableChoices = choices.value.slice()
  const choice = mutableChoices[index]
  mutableChoices.splice(index, 1)
  emit('change', {
    choices: mutableChoices,
    conditions: props.component.conditions.map(condition => ({
      ...condition,
      targets: condition.targets.map(target => ({
        ...target,
        choices:
          target.choices === 'all'
            ? 'all'
            : target.choices.filter(
                ch => ch.toString() !== choice.id.toString()
              )
      }))
    }))
  })
}

const duplicate = index => {
  const mutableChoices = choices.value.slice()
  const choice = choices.value[index]

  mutableChoices.splice(index + 1, 0, {
    id: getId(),
    text: choice.text,
    validTargets: choice.validTargets,
    count: choice.count
  })
  emit('change', { choices: mutableChoices })
}

const addChoice = () => {
  emit('change', {
    choices: [
      ...choices.value,
      {
        id: getId(),
        text: '',
        validTargets: [],
        count: 1
      }
    ]
  })
}

const updateChoice = (index, choice) => {
  emit('change', {
    choices: [
      ...choices.value.slice(0, index),
      {
        ...choices.value[index],
        ...choice
      },
      ...choices.value.slice(index + 1)
    ]
  })
}
</script>
<style lang="scss" scoped>
.drag-drop__add-choice-btn {
  padding: 16px 16px 8px 16px;
}

.drag-drop__tab-spacer {
  margin-left: 10px;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  width: 100%;
}
.drag-drop__background {
  border-radius: 6px 6px 0px 0px;
  padding: 10px 0;
  background-color: $extra-light-teal;
}
.drag-drop__collapse-panel {
  border-radius: 6px;
}
.collapse-icon {
  margin-right: 5px;
  font-size: 20px;
  color: $plum;
}
:deep(.tab-header .tab-header__tab--active) {
  border-radius: 6px 6px 0px 0px;
  background-color: $extra-light-teal;
  border: none;
  vertical-align: baseline;
}

.drag-drop__drop-area {
  margin-bottom: 20px;
}
.drag-drop__header--row {
  display: flex;
  justify-content: flex-end;
  margin: 0 16px;
  border-bottom: 1px solid $teal;
}
</style>
