<template>
  <view-container>
    <StickyHeader>
      <template #title>
        <StickyHeaderTitle>
          {{ ltiSession?.context.platform.name }}:
          {{ ltiSession?.context.context.name }}</StickyHeaderTitle
        >
      </template>
      <template #actions>
        <UIButton
          type="outline"
          variant="destructive"
          size="md"
          @click="cancelLTIFlow(ltiSession)"
          >Cancel Connection
        </UIButton>
      </template>
      <template #steps="{ isStuck }">
        <div
          class="bg-ui-gray-50 relative"
          style="padding: 12px 9999% 6px 9999%; margin: 0 -9999% 0 -9999%"
          :class="{
            'sticky top-12': isStuck
          }"
        >
          <UIStepList :steps="steps" currentStep="signin" />
        </div>
      </template>
    </StickyHeader>

    <async-form @submit="connect">
      <UICard
        class="tw-reset mt-6 max-w-[760px] mx-auto relative"
        header="Connect Pivot Account"
      >
        <UICardHeading>Connect Pivot to {{ platform }}?</UICardHeading>
        <UICardContent>
          <form-group>
            <p>
              <strong>{{ platform }}</strong> would like to connect your Pivot
              Interactives account with the email
              <strong>{{ user.email }}</strong
              >. If this is not you, please log out.
            </p>
          </form-group>
          <form-group variant="right-aligned">
            <submit-button>
              <template #default>Connect</template>
              <template #submitting>Connecting</template>
              <template #submitted>Success</template>
            </submit-button>
            <form-button secondary @click="logOut"> Log Out </form-button>
          </form-group>
        </UICardContent>
      </UICard>
    </async-form>
  </view-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import client from 'src/shared/api-client'
import {
  UIButton,
  UIStepList,
  UICard,
  UICardContent,
  UICardHeading
} from '@pi/design'
import { cancelLTIFlow, getLtiSteps } from '../lti-utils'

export default {
  name: 'LtiConnectAccountView',
  components: {
    UIButton,
    UIStepList,
    UICard,
    UICardHeading,
    UICardContent
  },
  data() {
    return {
      platform: null,
      ltiSession: undefined,
      steps: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user || {}
    })
  },
  async mounted() {
    const ltiSession = await client.lti.getSession()
    this.ltiSession = ltiSession
    if (ltiSession.state !== 'CONNECT_ACCOUNT') {
      this.$router.push({ name: 'home' })
    } else {
      this.platform = ltiSession.context.platform.name
    }
    this.steps = getLtiSteps(ltiSession)
  },
  methods: {
    ...mapActions(['refreshAuth']),
    async connect(e) {
      try {
        await client.lti.connectAccount({
          request: this.ltiSession
        })
        this.$success(`Your account has been connected to ${this.platform}.`)
        this.$router.push({ name: 'lti' })
        e.done()

        await this.refreshAuth()
      } catch (error) {
        e.done(false)
        if (error.status === 401) {
          this.$error('Incorrect email or password.')
        }
        throw error
      }
    },
    async logOut() {
      await client.auth.logout()
      window.location.assign(this.$router.resolve({ name: 'lti_login' }).href)
    }
  }
}
</script>

<style lang="scss" scoped>
.platform-name {
  font-style: italic;
}

.account-email {
  font-size: 16px;
  margin: 0 0 32px 0;
}
</style>
