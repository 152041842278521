<script>
import * as Vue from 'vue'
export default {
  name: 'EditorModalProvider',
  emits: ['modalchange'],
  render() {
    return this.$slots.default({
      name: this.modalName,
      isShown: this.isModalShown
    })
  },
  setup(props, { emit }) {
    const modalName = Vue.ref(null)

    const isModalShown = Vue.computed(() => !!modalName.value)

    const register = name => {
      modalName.value = name
      emit('modalchange', name)
    }
    const unregister = () => {
      modalName.value = null
      emit('modalchange', null)
    }

    const provided = Vue.computed(() => ({
      register,
      unregister,
      isModalShown
    }))
    Vue.provide('editorModal', provided)

    return { isModalShown, modalName }
  }
}
</script>
