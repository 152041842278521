<template>
  <collection-page
    v-if="isEditingCollection"
    class="variables-modal"
    :selected-variable="selectedVariable"
    @change="handleCollectionsSave"
    @close="stopEditingCollection"
  />
  <variable-list-page
    v-else
    class="variables-modal"
    v-model:selectedVariableId="selectedVariableId"
    :new-variable="newVariable"
    :variables="currentVariables"
    :activity="activity"
    :allow-insert="allowInsert"
    :insert-text="insertText"
    @edit="editCollection"
    @save="saveVariable"
    @change="updateSelectedVariable"
    @delete="deleteVariable"
  />
</template>

<script>
import CollectionPage from './ActivityVariablesModal/CollectionPage'
import VariableListPage from './ActivityVariablesModal/VariableListPage'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import AlertModal from 'src/shared/components/modals/AlertModal'
import { isUsingVariable, getText } from 'src/shared/components/editor/utils'
import humanizeComponentType from 'src/setup/mixins/humanizeComponentType'
import { getVariablesFromLatex } from 'src/shared/utils/latex-methods'

export default {
  name: 'ActivityVariablesModal',
  components: {
    VariableListPage,
    CollectionPage
  },
  inject: ['$modal'],
  mixins: [humanizeComponentType],
  props: {
    variableContext: {
      type: Object,
      required: true
    },
    allowInsert: {
      type: Boolean,
      default: false
    },
    allowResponseVariable: {
      type: Boolean,
      default: false
    },
    currentVariable: {
      type: String,
      default: ''
    },
    insertText: {
      type: String,
      default: 'Insert'
    }
  },
  data() {
    return {
      newVariable: {},
      selectedVariableId: this.currentVariable,
      currentVariables: [],
      isEditingCollection: false
    }
  },
  created() {
    if (this.allowResponseVariable) {
      this.currentVariables = [
        { id: 'response', name: 'response' },
        ...this.variables
      ]
    } else {
      this.currentVariables = [...this.variables]
    }
  },
  computed: {
    activity() {
      return this.variableContext.activity
    },
    variables() {
      return this.variableContext.variables ?? []
    },
    selectedVariable() {
      const id = (this.selectedVariableId ?? '').split('.')[0]
      return this.currentVariables.find(v => v.id === id) ?? this.newVariable
    }
  },
  methods: {
    updateSelectedVariable(updates) {
      const index = this.currentVariables.findIndex(
        v => v === this.selectedVariable
      )
      if (index >= 0) {
        this.currentVariables[index] = updates
      } else {
        this.newVariable = updates
      }
    },
    editCollection() {
      this.isEditingCollection = true
    },
    stopEditingCollection() {
      this.isEditingCollection = false
    },
    getRandomId() {
      let id = Math.random().toString(36).replace('0.', '')
      // probably wont ever get here, butjust in case lets make sure no other variable has this id.
      if (this.currentVariables.find(v => v.id === id)) {
        id = this.getRandomId()
      }
      return id
    },
    async saveVariable() {
      const idx = this.currentVariables.findIndex(
        v => v.id === this.selectedVariable.id
      )

      if (!this.selectedVariable.id) {
        // generate an id for the variable
        this.selectedVariable.id = this.getRandomId()
        this.selectedVariableId = this.selectedVariable.id
      }

      if (idx === -1) {
        this.currentVariables.push(this.selectedVariable)
      } else {
        this.currentVariables.splice(idx, 1, this.selectedVariable)
      }

      await this.variableContext.update?.(
        this.currentVariables.filter(
          v => v.id !== 'response' && v.name !== 'response'
        )
      )

      if (this.newVariable === this.selectedVariable) {
        this.newVariable = {}
      }
    },
    handleCollectionsSave(collection) {
      this.updateSelectedVariable(collection)
      this.isEditingCollection = false
      this.saveVariable()
    },
    isVariableUsed(variableId) {
      const sections = {}
      const getComponentName = component => {
        const componentTitle =
          getText(component.text, this.variableContext) || ''
        return `${this.humanizeComponentType(
          component.componentType
        )}:${componentTitle}`
      }

      const addComponentUsedForSection = (section, component) => {
        if (!sections[section.name]) {
          sections[section.name] = []
        }
        sections[section.name].push(getComponentName(component))
      }

      const flattenComponents = section => {
        return section.components
          .map(component => {
            if (component.componentType === 'SplitView') {
              return [...component.leftContent, ...component.rightContent]
            }
            return component
          })
          .flat()
      }

      if (isUsingVariable(this.activity.objectives.summary, variableId)) {
        sections['Activity Objectives'] = []
      }

      this.activity.sections.forEach(section => {
        if (isUsingVariable(section.summary, variableId)) {
          sections[section.name] = ['Section Introduction']
        }
        const usedComponents = flattenComponents(section).filter(component => {
          //need a way to test the component text and any other text that can have a variable in it.
          if (
            isUsingVariable(component.text, variableId) ||
            isUsingVariable(component.answer, variableId)
          ) {
            return true
          }

          if (component.url) {
            if (isUsingVariable(component.url, variableId, false)) {
              return true
            }
          }
          if (
            component.componentType === 'DragDropQuestion' &&
            component.renderType === 'latex'
          ) {
            if (
              getVariablesFromLatex({
                formula: component.dropAreaText,
                variables: [{ id: variableId }]
              }).length > 0
            ) {
              return true
            }
            if (
              component.choices.some(
                choice =>
                  getVariablesFromLatex({
                    formula: choice.text,
                    variables: [{ id: variableId }]
                  }).length > 0
              )
            ) {
              return true
            }
          }

          if (Array.isArray(component.choices)) {
            //differentiate between latex/rich text drag and drop and multiple choice choices
            if (
              component.choices.some(
                choice =>
                  isUsingVariable(choice.text, variableId) ||
                  isUsingVariable(choice.response, variableId)
              )
            ) {
              return true
            }
          }
          if (component.config) {
            if (component.config.includes(variableId)) {
              return true
            }
          }
          if (Array.isArray(component.conditions)) {
            if (
              component.conditions.some(
                condition =>
                  isUsingVariable(condition.response, variableId) ||
                  isUsingVariable(condition.condition, variableId, false)
              )
            ) {
              return true
            }
          }
        })
        usedComponents.forEach(component => {
          addComponentUsedForSection(section, component)
        })
      })
      return Object.keys(sections).map(sectionName => {
        return {
          sectionName,
          components: sections[sectionName]
        }
      })
    },
    async deleteVariable() {
      const isUsed = this.isVariableUsed(this.selectedVariable.id)
      if (isUsed && isUsed.length) {
        let sectionHtml = '<ul class="list-unstyled">'
        isUsed.forEach(section => {
          sectionHtml += `<li><b>${section.sectionName}</b><ul>`
          section.components.forEach(component => {
            sectionHtml += `<li>${component}</li>`
          })
          sectionHtml += '</ul></li>'
        })
        sectionHtml += '</ul>'
        await this.$modal.show(AlertModal, {
          title: 'This component is being used',
          html: `This variable is being used in the following components:${sectionHtml}`,
          body: 'You must remove the variable from each component in order to delete it.'
        })
        return
      }
      const { status } = await this.$modal.show(ConfirmModal, {
        text: `Are you sure you want to delete this variable? This action cannot be undone`,
        prompt: 'Yes Delete Variable'
      })
      if (status === 'ok') {
        const idx = this.currentVariables.findIndex(
          v => v.id === this.selectedVariable.id
        )
        this.currentVariables.splice(idx, 1)
        await this.variableContext.update?.(
          this.currentVariables.filter(
            v => v.id !== 'response' && v.name !== 'response'
          )
        )
      }
    }
  }
}
</script>

<style lang="scss">
.variables-modal {
  width: 790px;
}
</style>
