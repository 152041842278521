<template>
  <icon-button
    v-bind="$attrs"
    ref="button"
    icon="question-circle"
    color="help-icon"
    label="Help"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
    @click="onClick"
  />
  <base-popover
    :placement="placement"
    :anchor-ref="button"
    :visible="showPopover"
  >
    <slot
  /></base-popover>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue'
const button = ref()
import { linksList } from 'src/setup/router/externalLinkUtils'

const props = defineProps({
  placement: {
    type: String,
    default: 'bottom'
  },
  to: {
    type: Object,
    default: undefined
  }
})

const hasClicked = ref(false)
const isHovering = ref(false)
const showPopover = computed(() => hasClicked.value || isHovering.value)

function onClick() {
  if (props.to) {
    window.open(linksList[props.to.name], '_blank')
    return
  }
  hasClicked.value = true

  window.addEventListener(
    'click',
    e => {
      e.stopPropagation()
      hasClicked.value = false
    },
    { capture: true, once: true }
  )
}
</script>
