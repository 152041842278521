<template>
  <span class="tab-header">
    <span v-if="stuck" class="switch-span">Switch To:</span>
    <ul>
      <li v-for="{ tab, template } in tabs" :key="tab">
        <tab-button
          :class="{
            'tab-header__stuck-tab': stuck,
            'tab-header__tab': !stuck,
            'tab-header__tab--active': !stuck && tab === tabsInfo.currentTab
          }"
          :tabindex="!stuck && tab === tabsInfo.currentTab ? -1 : tab"
          link
          :tab="tab"
        >
          <vnode :template="template" />
        </tab-button>
      </li>
    </ul>
  </span>
</template>

<script setup>
import { inject, computed, useSlots } from 'vue'
import TabButton from './TabButton'
import TabHeaderButton from './TabHeaderButton'

const Vnode = props => {
  return props.template()
}

const props = defineProps({
  stuck: {
    type: Boolean,
    default: false
  }
})

const tabsInfo = inject('tabsInfo')

const slots = useSlots()
const tabs = computed(() =>
  slots
    .default()
    .filter(component => component.type === TabHeaderButton)
    .map(component => ({
      tab: component.props.tab,
      template: component.children.default
    }))
)
</script>
<style lang="scss" scoped>
.switch-span {
  margin-right: 5px;
}

ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  margin-bottom: 0;
}

:global(.sticky-header-sub-title .tab-header) {
  margin-bottom: -16px;
}

.tab-header {
  display: flex;

  & .tab-header__tab {
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px 7px 10px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  & .tab-header__tab--active {
    font-weight: 400;
    color: #000;

    text-decoration-line: none;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid white;
    margin-bottom: -2px;
    padding-bottom: 8px;
    background: white;
  }

  & .tab-header__tab--active:hover {
    cursor: auto;
  }

  & .tab-header__stuck-tab {
    margin-right: 10px;
  }
}
</style>
