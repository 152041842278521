import ActivitiesView from './views/ActivitiesView'
import AssignActivitiesView from './views/AssignActivitiesView'
import ManageLibraryView from './views/ManageLibraryView'
import PublicActivitiesView from './views/PublicActivitiesView'
import JoinLibraryView from './views/JoinLibraryView'

export default store => {
  return [
    {
      name: 'activities',
      path: '/activities',
      components: {
        authenticated: ActivitiesView,
        unauthenticated: PublicActivitiesView
      },
      props: {
        authenticated: to => ({ query: to.query }),
        unauthenticated: to => ({ query: to.query })
      },
      meta: { title: 'Activities' }
    },
    {
      name: 'manage_library',
      path: '/libraries/:libraryId',
      component: ManageLibraryView,
      props: true,
      meta: { title: 'Manage Library', requiresAuth: true }
    },
    {
      name: 'class_assign_activity',
      path: '/classes/:id/activities',
      component: AssignActivitiesView,
      props: to => ({ classId: to.params.id, query: to.query }),
      meta: { title: 'Assign Activity', requiresAuth: true }
    },
    {
      name: 'join_library',
      path: '/join-library',
      component: JoinLibraryView,
      props: route => ({
        libraryId: route.query.library
      }),
      meta: { title: 'Join Library', requiresAuth: true }
    }
  ]
}
