<template>
  <editor-modal
    v-if="isModalShown"
    class="fileUpload-menu__modal"
    name="file"
    title="File Upload Modal"
  >
    <async-form class="fileUpload-menu__form" @submit="updateContent">
      <div>
        <form-label for="editor-file">PDF upload</form-label>
        <input
          type="file"
          id="editor-file"
          accept="application/pdf"
          @change="onFileChange"
        />
        <div v-if="previewError" class="fileUpload-menu__error">
          {{ previewError }}
        </div>
      </div>

      <div class="editor-modal__actions">
        <form-button type="submit">Update</form-button>
        <form-button tertiary @click="cancel">Cancel</form-button>
      </div>
    </async-form>
  </editor-modal>
</template>

<script>
import * as Vue from 'vue'
import { isActive } from '@tiptap/core'
import client from 'src/shared/api-client'
import EditorModal from '../components/EditorModal'

export default {
  name: 'FileUploadModal',
  components: { EditorModal },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const shouldShow = ({ state }) => {
      return isActive(state, 'file')
    }
    const file = Vue.ref()

    const previewError = Vue.ref(null)
    const isModalShown = Vue.ref(false)

    const openModal = () => {
      previewError.value = undefined
      isModalShown.value = true
    }

    const updateContent = async e => {
      previewError.value = undefined

      try {
        if (!file.value) {
          throw new Error('You must select a file to insert')
        }

        const ext = file.value.name.split('.').pop()
        const name = `${new Date().getTime()}.${ext}`
        const url = await client.assets.upload({
          file: file.value,
          name
        })

        const startPosition = props.editor.view.state.selection.anchor
        const endPosition = startPosition + file.value.name.length
        props.editor
          .chain()
          .focus()
          .insertContent(file.value.name)
          .setTextSelection({
            from: startPosition,
            to: endPosition
          })
          .setFile({ href: url })
          .run()

        isModalShown.value = false
        e.done()
      } catch (error) {
        previewError.value = error.message
        e.done(false)
      } finally {
        file.value = undefined
      }
    }

    const cancel = e => {
      isModalShown.value = false
      props.editor.commands.focus()
    }
    // eslint-disable-next-line vue/no-mutating-props
    props.editor.storage.file.onEditLink = openModal

    const onFileChange = async e => {
      previewError.value = undefined
      file.value = e.target.files[0]
    }

    return {
      isModalShown,
      previewError,
      shouldShow,
      onFileChange,
      updateContent,
      cancel,
      openModal
    }
  }
}
</script>

<style lang="scss" scoped>
.fileUpload-menu {
  &__modal {
    width: 300px;
  }

  &__form {
    margin: 0 !important;
  }

  &__error {
    margin: 16px 0 0 0;
    color: $color-error;
  }
}
</style>
