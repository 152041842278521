<template>
  <h4>{{ component.title }}</h4>
  <pivot-instance
    v-if="isInstanceComponent"
    :settings="settings"
    :limit-functionality="limitFunctionality"
    :is-randomized="component.isRandomized && !isStudent"
  />
</template>

<script>
import PivotInstance from 'src/shared/components/PivotInstance'
import { findVariableById } from '@pi/shared/variables'

export default {
  name: 'ActivityInstance',
  inject: ['inherited'],
  components: {
    PivotInstance
  },
  props: {
    limitFunctionality: {
      type: Boolean,
      default: false
    },
    variableContext: {
      type: Object,
      required: true
    },
    isStudent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    isInstanceComponent() {
      return this.component.componentType === 'EmbeddedInstance'
    },
    settings() {
      try {
        const settings = JSON.parse(
          JSON.stringify(
            this.component.instance ? this.component.instance.settings : {}
          )
        )
        if (settings.selection) {
          const config = { ...settings.selection.parameters }
          try {
            if (this.component.config) {
              const metadata = JSON.parse(this.component.config)
              Object.keys(metadata.parameters).forEach(key => {
                const param = metadata.parameters[key]
                const type = param.type
                config[key].visible = param.visible
                config[key].selectable = param.selectable
                if (type === 'variable') {
                  const variable = findVariableById(
                    param.initial,
                    this.variableContext.variables
                  )
                  if (variable) {
                    config[key].initial = variable.value
                  }
                } else if (type === 'preselected') {
                  config[key].initial = param.initial
                }
              })
            }
          } catch (e) {}
          settings.selection.parameters = config
        }
        return settings
      } catch (error) {
        this.$error(error.message)
        throw error
      }
    }
  }
}
</script>
