export interface UseDraggingOptions {
  renderChoiceGhost?(root: HTMLElement): void
  onDragStart?(e: PointerEvent): boolean
  onDragEnd?(e: PointerEvent, targetId?: string): void
}

const MOVE_THRESHOLD = 10
let choiceGhost: HTMLDivElement
const EDGE_SIZE = 100
const SCROLL_SPEED = 10

function createChoiceGhost(html: string): HTMLElement {
  if (!choiceGhost) {
    choiceGhost = document.createElement('div')
    choiceGhost.id = 'pivot-choice-ghost'
    choiceGhost.className = 'drag-drop-choice__ghost'
  }
  document.body.appendChild(choiceGhost)
  choiceGhost.innerHTML = html
  return choiceGhost
}

export default function useDragging(options: UseDraggingOptions) {
  let hoveredTargetId: string | undefined
  let previousHoveredElement: HTMLElement | undefined

  let isScrolling = false
  const container = document.getElementById('scroll-container')

  function startScrolling(dir: 'up' | 'down') {
    isScrolling = true

    function loop() {
      if (isScrolling) {
        if (!container) return
        const viewportHeight = container ? container.clientHeight : 0
        const currentScrollY = container.scrollTop
        const documentHeight = container.scrollHeight
        const maxScrollY = documentHeight - viewportHeight

        let nextScrollY = currentScrollY

        if (dir === 'up') {
          nextScrollY = nextScrollY - SCROLL_SPEED
        } else if (dir === 'down') {
          nextScrollY = nextScrollY + SCROLL_SPEED
        }

        nextScrollY = Math.max(0, Math.min(maxScrollY, nextScrollY))

        if (nextScrollY !== currentScrollY) {
          container.scrollTop = nextScrollY
        }
        requestAnimationFrame(loop)
      }
    }
    requestAnimationFrame(loop)
  }

  function stopScrolling() {
    isScrolling = false
  }

  function onPointerDown(e: PointerEvent) {
    // We want the primary touch pointer or the right mouse button.
    if (
      !e.isPrimary ||
      (typeof e.button === 'number' && e.button !== 0) ||
      !(e.currentTarget instanceof HTMLElement)
    )
      return

    if (
      e.target &&
      e.target instanceof HTMLElement &&
      e.target.attributes.getNamedItem('data-tip-id')
    ) {
      e.target.click() // this is to trigger the vocab tooltip link if used within a drop choice
      return
    }
    const startPosition = { x: e.pageX, y: e.pageY }

    const originalEvent = e
    const target = e.currentTarget

    target.setPointerCapture(e.pointerId)
    function onTempPointerMove(e: PointerEvent) {
      e.preventDefault()
      const dist = Math.sqrt(
        (e.pageX - startPosition.x) ** 2 + (e.pageY - startPosition.y) ** 2
      )
      if (dist > MOVE_THRESHOLD) {
        target.removeEventListener('pointermove', onTempPointerMove)
        target.removeEventListener('pointerup', onPointerCancel)
        target.removeEventListener('pointercancel', onPointerCancel)

        if (!(options.onDragStart?.(originalEvent) ?? true)) return

        const choiceGhostElement = createChoiceGhost('')
        if (options.renderChoiceGhost) {
          options.renderChoiceGhost(choiceGhostElement)
        } else {
          choiceGhostElement.innerHTML = target.innerHTML
        }
        choiceGhostElement.setPointerCapture(e.pointerId)
        choiceGhostElement.style.top = `${e.pageY - 20}px`
        choiceGhostElement.style.left = `${e.pageX - 20}px`
        choiceGhostElement.addEventListener('pointerup', onPointerUp)
        choiceGhostElement.addEventListener('pointercancel', onPointerCancel)
        choiceGhostElement.addEventListener('pointermove', onPointerMove)
      }
    }
    function onTempPointerCancel() {
      target.removeEventListener('pointermove', onTempPointerMove)
      target.removeEventListener('pointerup', onTempPointerCancel)
      target.removeEventListener('pointercancel', onTempPointerCancel)
    }
    target.addEventListener('pointermove', onTempPointerMove)
    target.addEventListener('pointerup', onTempPointerCancel)
    target.addEventListener('pointercancel', onTempPointerCancel)
  }

  function onPointerMove(e: PointerEvent) {
    e.preventDefault()
    if (
      !e.isPrimary ||
      !(e.currentTarget instanceof HTMLElement) ||
      e.target instanceof HTMLSelectElement
    )
      return

    e.currentTarget.style.top = `${e.pageY - 20}px`
    e.currentTarget.style.left = `${e.pageX - 20}px`

    const newHoveredElement = document
      .elementsFromPoint(e.clientX, e.clientY)
      .find((el): el is HTMLElement => {
        return (
          el instanceof HTMLElement && !!(el.dataset.ref || el.dataset.target)
        )
      })

    if (
      newHoveredElement !== previousHoveredElement &&
      !newHoveredElement?.classList.contains('inline-drop-target--dropdown') &&
      !newHoveredElement?.classList.contains('drop-target--dropdown')
    ) {
      previousHoveredElement?.classList.remove('dropping')
      newHoveredElement?.classList.add('dropping')

      e.currentTarget.style.cursor = newHoveredElement ? 'copy' : 'grabbing'

      previousHoveredElement = newHoveredElement
      hoveredTargetId =
        newHoveredElement?.dataset.ref || newHoveredElement?.dataset.target
    }

    const viewportHeight = container ? container.clientHeight : 0
    const edgeBottom = viewportHeight - EDGE_SIZE
    const viewportY = e.clientY
    const isInTopEdge = viewportY < EDGE_SIZE
    const isInBottomEdge = viewportY > edgeBottom

    if (!(isInTopEdge || isInBottomEdge)) {
      stopScrolling()
      return
    }

    if (!isScrolling && isInTopEdge) {
      startScrolling('up')
    } else if (!isScrolling && isInBottomEdge) {
      startScrolling('down')
    }
  }

  function onPointerUp(e: PointerEvent) {
    if (
      !e.isPrimary ||
      !(e.currentTarget instanceof HTMLElement) ||
      e.target instanceof HTMLSelectElement
    )
      return

    stopScrolling()

    previousHoveredElement?.classList.remove('dropping')
    options.onDragEnd?.(e, hoveredTargetId)
    previousHoveredElement = undefined
    hoveredTargetId = undefined
    e.currentTarget.remove()
    e.currentTarget.removeEventListener('pointerup', onPointerUp)
    e.currentTarget.removeEventListener('pointercancel', onPointerCancel)
    e.currentTarget.removeEventListener('pointermove', onPointerMove)
  }

  function onPointerCancel(e: PointerEvent) {
    if (!e.isPrimary || !(e.currentTarget instanceof HTMLElement)) return

    stopScrolling()

    previousHoveredElement?.classList.remove('dropping')
    previousHoveredElement = undefined
    hoveredTargetId = undefined
    e.currentTarget.remove()
    e.currentTarget.removeEventListener('pointerup', onPointerUp)
    e.currentTarget.removeEventListener('pointercancel', onPointerCancel)
    e.currentTarget.removeEventListener('pointermove', onPointerMove)
  }
  return {
    onPointerDown
  }
}
