export function fillTargets({ choices, condition }) {
  const targets = condition.targets
    // We ignore targets with no correct choices since we can't set a choice for them.
    .filter(target => target.choices.length > 0)
    // We also want to convert all remaining targets to a list of correct choices.
    .map(target => ({
      id: target.targetId,
      choices: (target.choices === 'all'
        ? choices.map(choice => choice.id)
        : target.choices
      ).map(choice => parseInt(choice))
    }))

  const answer = {}

  // Now we can set the answers for targets with only one correct choice.
  targets
    .filter(target => target.choices.length === 1)
    .forEach(target => (answer[target.id] = target.choices[0]))

  // These targets are the ones that are left to fill.
  const complexTargets = targets.filter(target => target.choices.length > 1)

  // We can split choices into those that are infinite and those that are limited.
  const infiniteChoices = choices
    .filter(choice => typeof choice.count === 'undefined')
    .map(choice => choice.id)
  const singleChoices = choices
    .filter(choice => typeof choice.count === 'number')
    .flatMap(choice => Array.from({ length: choice.count }, () => choice.id))

  // And now we remove already used choices from the list of finite choices.
  Object.values(answer).map(choice => {
    const index = singleChoices.indexOf(choice)
    if (index >= 0) {
      singleChoices.splice(index, 1)
    }
  })

  const individualChoices = [...singleChoices, ...infiniteChoices]

  // This function uses recursion to find a set of choices that fulfills each remainin target's condition.
  // We go through each choice for the target's condition, and if it is still available, we see if that works with the remaining target.
  function fillChoice(index, choiceList, answer) {
    if (index >= complexTargets.length) return answer

    const target = complexTargets[index]

    for (const choice of target.choices) {
      const newChoices = choiceList.slice()
      const i = choiceList.indexOf(choice)
      if (i >= 0) {
        if (!infiniteChoices.includes(choice)) {
          newChoices.splice(i, 1)
        }
        const newAnswer = fillChoice(index + 1, newChoices, {
          ...answer,
          [target.id]: choice
        })
        if (newAnswer) {
          return newAnswer
        }
      }
    }

    return false
  }

  return fillChoice(0, individualChoices, answer) || {}
}

function getComponentAnswers(component) {
  let value
  switch (component.componentType) {
    case 'GridQuestion':
    case 'GridGraphQuestion':
    case 'OpenEndedQuestion': {
      try {
        value = JSON.parse(component.answer)
      } catch {
        value = component.answer
      }
      break
    }
    case 'MultipleChoiceQuestion': {
      value = component.choices
        .filter(c => c.answer)
        .map(c => c.id)
        .join(',')
      break
    }
    case 'DragDropQuestion': {
      const condition = component.conditions?.find(
        condition => condition.isCorrect
      )
      if (condition) {
        value = fillTargets({
          condition,
          choices: component.choices
        })
      }
      break
    }
  }
  if (value) {
    return {
      component: component._id,
      value
    }
  }
  return
}

export function getActivityAnswers(activity) {
  return activity.sections
    .flatMap(section => section.components)
    .flatMap(component => {
      if (component.componentType === 'SplitView') {
        return [
          ...component.leftContent.flatMap(getComponentAnswers),
          ...component.rightContent.flatMap(getComponentAnswers)
        ]
      }
      return getComponentAnswers(component)
    })
    .filter(response => response)
}
