<template>
  <div
    ref="root"
    data-testid="flash-messages"
    class="flash-messages"
    :class="{
      'flash-messages--stuck': isStuck
    }"
  >
    <div v-for="messageLevel in messageLevels" :key="messageLevel">
      <div
        v-if="
          messagesByLevel[messageLevel] && messagesByLevel[messageLevel].length
        "
        class="fade-in alert alert-dismissible"
        role="alert"
        aria-label="Flash Message"
        :class="'alert-' + messageLevel"
        data-testid="flash-message"
      >
        <div class="container alert-content">
          <div class="alert-messages">
            <p
              v-for="message in messagesByLevel[messageLevel]"
              :key="message.text + message.level + message.timeout"
              @click="message.onClick"
              :class="message.onClick ? 'alert-clickable' : ''"
            >
              <span class="sr-only">Message:</span>
              <icon class="flash-icon" :icon="iconHelper(message.level)" />
              <hyper-link
                v-if="message.route"
                class="alert-link"
                :to="message.route"
              >
                {{ message.text }}
              </hyper-link>
              <span v-else>{{ message.text }}</span>
            </p>
          </div>
          <button
            class="close"
            type="button"
            data-dismiss="alert"
            aria-label="Close"
            @click="() => flash.clear(messageLevel)"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject, onMounted, onBeforeUnmount } from 'vue'

const flash = inject('_flash')
const props = defineProps({
  inline: {
    type: Boolean,
    default: false
  }
})
const isStuck = ref(false)
const observer = ref()

const messageLevels = computed(() =>
  Array.from(new Set(flash.messages.map(m => m.level)))
)
const messagesByLevel = computed(() => {
  const mapped = {}
  flash.messages.forEach(message => {
    if (!mapped[message.level]) {
      mapped[message.level] = []
    }
    mapped[message.level].push(message)
  })
  return mapped
})

function iconHelper(level) {
  if (['error', 'warning'].includes(level)) {
    return 'triangle-exclamation'
  } else {
    return 'circle-check'
  }
}

onMounted(() => {
  if (!props.inline) {
    if (!window.IntersectionObserver) return

    // This is a transparent element 100px tall at the top of the view container.
    // See the ViewContainer for where this sentinel is defined.
    const scrollSentinel = document.querySelector('#scroll-sentinel')

    observer.value = new IntersectionObserver(
      ([e]) => {
        isStuck.value = e.intersectionRatio < 0.6
      },
      {
        // The #scroll-container element should be the ancestry of this component,
        // but in storybook it may not be, so we use the body.
        root:
          document.querySelector('#scroll-container') ||
          document.querySelector('body'),
        threshold: [0, 0.6]
      }
    )
    if (scrollSentinel) {
      observer.value.observe(scrollSentinel)
    }
  }
})

onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect()
    observer.value = null
  }
})
</script>

<style lang="scss" scoped>
.flash-messages {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1001;

  &--stuck {
    position: fixed;
    top: 0;
    margin-top: 0 !important;
  }
}

.alert {
  animation-delay: 0.3s;
  font-size: $font-size-base;
  line-height: 1.4;
  padding: 15px 10px;
  font-weight: bold;
  margin-bottom: 0;
  border-radius: 0;
  border: none;

  .close {
    right: 0;
    top: 0;
    opacity: 1;
    width: auto;
    text-shadow: none;
    font-weight: normal;
    font-size: 14px;
    height: 14px;

    &:hover,
    &:focus {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
}

.alert-success {
  background-color: #1c8484;
  color: white;
}

.alert-error {
  background-color: #cd6113;
  color: white;
}

.alert-warning {
  background-color: #cd6113;
  color: white;
}

.alert-notice {
  background-color: $color-notice;
  color: white;
}

.alert-link {
  color: white;
}

.alert-clickable {
  cursor: pointer;
}

.alert-content {
  display: flex;
  align-items: flex-start;

  p {
    margin-bottom: 0;
  }
  .flash-icon {
    padding-right: 10px;
  }
}

.alert-messages {
  flex-grow: 1;
}
</style>
