<template>
  <div class="grading-progress">
    <button-dropdown unstyled center class="progress_dropdown">
      <template #button="{ isOpen }">
        <slot name="button">Progress: {{ progress }}&nbsp;</slot>
        <icon
          class="grading-progress-dropdown__icon"
          :icon="isOpen ? 'caret-up' : 'caret-down'"
        />
      </template>
      <ul class="progress-dropdown-menu">
        <template v-if="showCorrectQuestions">
          <li>
            <activity-grading-progress-list
              :label="'Correct: ' + correctQuestions.length"
              :questions="correctQuestions"
            />
          </li>
          <li>
            <activity-grading-progress-list
              :label="'Incorrect: ' + incorrectQuestions.length"
              :questions="incorrectQuestions"
            />
          </li>
          <li>
            <hr class="divider" /></li
        ></template>
        <li>
          <activity-grading-progress-list
            :label="'Pending Grade: ' + pendingGrade.length"
            :questions="pendingGrade"
          />
        </li>
        <li>
          <activity-grading-progress-list
            :label="'Unanswered: ' + unansweredComponents.length"
            :questions="unansweredComponents"
          />
        </li>
      </ul>
    </button-dropdown>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue'
import {
  GradeableComponentResponse,
  InheritedGradableActivity,
  SectionComponent
} from '../types'
import { Component } from '@pi/api-types'
import ActivityGradingProgressList from './ActivityGradingProgressList.vue'

const UNGRADABLE = [
  'GeneralNote',
  'GeneralInstruction',
  'EmbeddedInstance',
  'IFrame',
  'SplitView',
  'PhetIOSim',
  'PhetIOSimulation',
  'InstructorInstance',
  'StudentInstance'
]

const inherited = inject<Ref<InheritedGradableActivity>>('inherited')

const showCorrectQuestions = computed(
  () => inherited?.value?.response?.assignment?.showIndicators
)

const allComponents = computed<SectionComponent[]>(
  () =>
    inherited?.value?.activity?.sections
      .flatMap(section => {
        return section.components
          .filter(
            c =>
              c.componentType.includes('Question') ||
              c.componentType === 'GeneralInstruction' ||
              c.componentType === 'SplitView'
          )
          .map(c => {
            if (c.componentType === 'SplitView') {
              return [
                ...(c.leftContent as Component[]),
                ...(c.rightContent as Component[])
              ]
                .filter(
                  c =>
                    c.componentType.includes('Question') ||
                    c.componentType === 'GeneralInstruction' ||
                    c.componentType === 'SplitView'
                )
                .map(component => {
                  return {
                    ...component,
                    name: `Section ${section.sectionNumber} Question ${component.questionNumber}`
                  } as SectionComponent
                })
            }

            return {
              ...c,
              name: `Section ${section.sectionNumber} Question ${c.questionNumber}`
            } as SectionComponent
          })
      })
      .flatMap(component => {
        return component
      }) ?? []
)

const gradableComponents = computed<GradeableComponentResponse[]>(() =>
  (
    allComponents.value.filter(
      component => !UNGRADABLE.includes(component.componentType)
    ) ?? []
  ).map<GradeableComponentResponse>(component => ({
    component,
    response:
      inherited?.value?.response?.responses.find(
        response => response.component === component._id
      ) ?? undefined
  }))
)

const correctQuestions = computed<GradeableComponentResponse[]>(() => {
  return gradableComponents.value.filter(gcr => gcr.response?.isCorrect)
})
const incorrectQuestions = computed<GradeableComponentResponse[]>(() => {
  return gradableComponents.value.filter(
    gcr =>
      gcr.response?.submissions &&
      gcr.response?.submissions > 0 &&
      gcr.response?.isCorrect === false
  )
})

const pendingGrade = computed<GradeableComponentResponse[]>(() => {
  return gradableComponents.value.filter(
    gcr =>
      gcr.response?.isCorrect === null || gcr.response?.isCorrect === undefined
  )
})

const progress = computed<string>(() => {
  const total = gradableComponents.value.length
  const answered = gradableComponents.value.filter(
    gcr => gcr.response?.value
  ).length
  return `${answered}/${total} Answered`
})

const unansweredComponents = computed<GradeableComponentResponse[]>(() => {
  return gradableComponents.value.filter(gcr => {
    if (gcr.response?.value) return false
    return !gcr.response?.submissions || gcr.response?.submissions === 0
  })
})
</script>

<style lang="scss" scoped>
.grading-progress {
  position: relative;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;

  .dropdown-toggle {
    color: $dark-grey;
  }

  .dropdown-content {
    position: relative;
  }

  .progress-dropdown-menu {
    width: 245px;
    max-height: calc(100vh - 400px);
    overflow: hidden auto;
    list-style-type: none;
    padding-left: 15px;
    padding-right: 15px;
  }

  .progress-dropdown-menu li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

:deep(.progress_dropdown) {
  margin-left: 10px;
  font-weight: 400;
}
</style>
