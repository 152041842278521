<template>
  <loading-container :loading="gettingLicense">
    <view-container normal>
      <flash />
      <help-banner />
      <div class="card summary-card">
        <h2 class="summary-header">{{ planType }}</h2>
        <p class="dates">
          {{ printDate(license.startDate) }} -
          {{ printDate(license.endDate) }}
        </p>
        <p class="plain-text no-margin">{{ license.maxSeats }} seats</p>
      </div>
      <div class="card">
        <async-form class="form" @submit="onSubmit">
          <h1 class="header">Add Seats</h1>
          <p class="plain-text">
            You are adding more seats to your existing license. The cost of the
            seats is prorated for the
            <strong
              >{{ proratedDays }}
              {{ proratedDays === 1 ? 'day' : 'days' }}</strong
            >
            remaining on your license.
            <i>
              These added seats will expire on
              {{ printDate(license.endDate) }}, the end date of your current
              license.</i
            >
          </p>
          <p class="plain-text">
            Do you want to purchase a
            {{ license.level === 'college' ? 'full-term' : 'full-year' }}
            license renewal instead?
            <button-link
              tertiary
              :to="{
                name: 'new_renewal_session',
                query: { licenseId: license.id }
              }"
            >
              Renew License
            </button-link>
          </p>

          <h3 id="addSeatsLabel">How many seats would you like to add?</h3>
          <form-group class="input-45">
            <number-input
              id="addedSeats"
              v-model="addedSeats"
              aria-labelledby="addSeatsLabel"
              rules="required|integer"
              label="Projected Number of Seats"
              type="number"
              min="1"
              step="1"
              :disabled="!canEdit"
              oninput="validity.valid||(value='')"
            />
          </form-group>
          <table class="data-table">
            <tr class="data-cell plain-text">
              <td>Cost Per Seat</td>
              <td>
                {{ $format.currency(costPerSeat, '--') }}
              </td>
            </tr>
            <tr class="data-cell plain-text">
              <td>Total Cost</td>
              <td>
                {{ $format.currency(totalCost, '--') }}
              </td>
            </tr>
          </table>
          <form-group v-if="license.salesforceId" class="button-group">
            <submit-button
              :disabled="isDisabled"
              action="submit"
              class="form-button--beta"
            >
              Continue to Purchase
            </submit-button>
            <submit-button
              :disabled="isDisabled"
              action="quote"
              class="form-button--alpha"
            >
              {{
                isUpdatingQuote ? 'Send me Updated Quote' : 'Send me a quote'
              }}
            </submit-button>
          </form-group>
          <form-group v-else>
            <h3>
              Your account requires an update prior to renewing. Please email
              Pivot Interactives support at
              <hyper-link :to="{ name: 'support' }">
                support@pivotinteractives.com
              </hyper-link>
              or
              <form-button link @click="OpenChat">open a chat.</form-button>
            </h3>
          </form-group>
          <form-group v-if="isAdmin" class="admin list">
            <form-label for="admin"
              >Select license admin to receive quote</form-label
            >
            <select-field id="admin" v-model="licenseAdmin" rules="required">
              <option
                v-for="admin in adminList"
                :key="admin.email"
                :value="admin"
              >
                {{ admin.lastName }}, {{ admin.firstName }} {{ admin.email }}
              </option>
            </select-field>
          </form-group>
        </async-form>
      </div>
    </view-container>
  </loading-container>
</template>

<script>
import HelpBanner from 'src/modules/purchases/components/HelpBanner'
import { mapState, mapGetters } from 'vuex'
import { getPurchaseData, mergePurchaseData } from 'src/shared/utils/purchases'
import Flash from 'src/shared/components//Flash'
import { isBefore, differenceInDays, format, add } from 'date-fns'
import client from 'src/shared/api-client.ts'
import utils from '../pricing.ts'

export default {
  name: 'ConfigureAddedSeats',
  components: { HelpBanner, Flash },
  props: {
    id: {
      type: String,
      default: undefined
    },
    licenseId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      gettingLicense: true,
      license: {},
      addedSeats: getPurchaseData().configureAddedSeatsForm || 0,
      proratedDays: 0,
      adminList: [],
      licenseAdmin: undefined
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    ...mapGetters(['isAdmin']),
    canEdit() {
      return this.isAdmin || !this.quote?.customPricing
    },
    siteLicenseId() {
      return this.user.siteLicense && this.user.siteLicense.id
    },
    costPerSeat: function () {
      if (this.quote && this.quote.seats === this.addedSeats) {
        return this.quote.pricePerSeat
      }
      if (this.addedSeats) {
        return this.totalCost / this.addedSeats
      } else {
        return 0
      }
    },
    totalCost: function () {
      // only run if seats changed
      if (this.quote && this.quote.seats === this.addedSeats) {
        return this.quote.subtotal
      }
      return utils.determineAddedSeatsPrice(
        new Date(this.license.startDate),
        new Date(this.license.endDate),
        this.license.level,
        this.addedSeats
      )
    },
    planType() {
      return this.license.level === 'college'
        ? 'College Institution License'
        : 'High School Institution License'
    },
    isUpdatingQuote() {
      return this.$route.query && this.$route.query.id
    },
    isDisabled() {
      if (this.admin) return false
      return this.percentLicenseRemaining <= 0
    }
  },
  methods: {
    printDate(date) {
      return date ? format(new Date(date), 'MM/dd/yyyy') : ''
    },
    openChat() {
      if (window.Intercom) {
        window.Intercom(
          'showNewMessage',
          `My ${
            this.license.name || ''
          } account requires an update before I can renew.`
        )
      }
    },
    async onSubmit({ action, done }) {
      try {
        mergePurchaseData({
          purchaseType: 'added-seats',
          configureAddedSeatsForm: this.addedSeats
        })
        let currentQuoteId = this.id
        if (this.isUpdatingQuote) {
          if (
            this.isAdmin &&
            isBefore(new Date(this.quoteExpires, new Date()))
          ) {
            this.quote.quoteExpires = add(new Date(), { months: 1 })
          }
          await client.purchases.updateAddedSeatsQuote({
            id: this.id,
            ...this.quote,
            licenseId: this.license.id,
            seats: this.addedSeats
          })
          mergePurchaseData({ id: this.id })
        } else {
          const { id } = await client.purchases.createAddedSeatsQuote({
            licenseId: this.license.id,
            seats: this.addedSeats,
            contactFirstName: this.licenseAdmin
              ? this.licenseAdmin.firstName
              : this.user.firstName,
            contactLastName: this.licenseAdmin
              ? this.licenseAdmin.lastName
              : this.user.lastName,
            contactEmail: this.licenseAdmin
              ? this.licenseAdmin.email
              : this.user.email,
            institution: this.license.institutionName
          })

          currentQuoteId = id
          mergePurchaseData({ id })
        }

        if (action === 'quote') {
          try {
            await client.purchases.sendQuoteEmail({ id: currentQuoteId })
          } catch (e) {
            this.$error('Could not send email')
          } finally {
            this.$router.push({
              name: 'purchase_quote',
              params: { id: currentQuoteId },
              query: { quoteSent: true }
            })
          }
        } else {
          this.$router.push({ name: 'purchase_billing_information' })
        }

        done()
      } catch (error) {
        done(false)
        throw error
      }
    }
  },
  async mounted() {
    this.gettingLicense = true
    if (this.isUpdatingQuote) {
      const { quote } = await client.purchases.getQuote({ id: this.id })
      this.quote = quote || {}
      this.pricePerSeat = quote.pricePerSeat
      this.addedSeats = quote.seats
      this.startDate = new Date(quote.startDate)
      this.endDate = new Date(quote.endDate)
    }

    const license = await client.licenses.get({
      id: this.quote
        ? this.quote.licenseId
        : this.isAdmin
        ? this.licenseId
        : this.siteLicenseId
    })

    this.license = license

    const startDate = new Date(license.startDate)
    const endDate = new Date(license.endDate)
    const percentLicenseRemaining = utils.determinePercentLicenseRemaining(
      startDate,
      endDate
    )
    this.proratedDays = Math.ceil(
      percentLicenseRemaining * differenceInDays(endDate, startDate)
    )

    if (isBefore(endDate, new Date())) {
      this.$error(
        'Sorry, this license is no longer active. You must renew it to continue.'
      )
      this.$router.push({
        name: 'new_renewal_session',
        params: { licenseId: license._id }
      })
    }
    this.gettingLicense = false

    this.adminList = license.members.filter(member => member.role === 'admin')
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 60px 64px;
  margin-bottom: 24px;
}
.summary-card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 16px 64px;
}
.header {
  margin-top: 0;
}
.summary-header {
  margin-top: 0;
  font-size: 24px;
}
.dates {
  color: #716a6d;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.008em;
  text-align: left;
}
.plain-text {
  font-size: 20px;
  line-height: 150%;
  font-weight: normal;
}
.input-45 {
  width: 45%;
}
.data-table {
  border-collapse: separate !important;
  width: 45%;
  border: 1px solid #eeeded;
  border-radius: 3px;
  tr {
    height: 54px;
    td {
      padding: 12px 16px;
      &:last-child {
        text-align: right;
      }
    }
    &:first-child {
      td {
        border-bottom: 1px solid #eeeded;
      }
    }
  }
}
.button-group {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
}
.no-margin {
  margin: 0;
}
</style>
