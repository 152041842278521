<template>
  <resizable
    :as="NodeViewWrapper"
    class="target-context"
    :class="{
      'target-context--editing': !isReadonly
    }"
    v-model:size="size"
    :aspect-ratio="aspectRatio"
    :focused="!isReadonly && selected"
    draggable="false"
    data-node-view-wrapper="dropTargetContext"
    @click="() => editor.commands.setNodeSelection(getPos())"
  >
    <node-view-content
      as="div"
      class="target-context__content"
      :style="{
        backgroundImage: `url(${src})`
      }"
    />
  </resizable>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import Resizable from '../components/Resizable.vue'

const props = defineProps(nodeViewProps)

const src = computed(() =>
  props.node.attrs.src?.startsWith('data:') ||
  props.node.attrs.src?.startsWith('http')
    ? (props.node.attrs.src as string)
    : `//${window.PI_API_HOST}${props.node.attrs.src}`
)
const isReadonly = computed(() => !props.editor.view.editable)

const aspectRatio = ref<number | undefined>(undefined)
watch(
  src,
  src => {
    if (src) {
      const image = new Image()
      image.src = src
      image.onload = function () {
        aspectRatio.value = image.naturalWidth / image.naturalHeight
      }
    }
  },
  { immediate: true }
)

const size = computed({
  get() {
    return {
      width: props.node.attrs.width ?? 32,
      height: props.node.attrs.height ?? 32
    }
  },
  set(size) {
    props.updateAttributes(size)
  }
})
</script>

<style lang="scss" scoped>
.target-context--editing {
  border: 1px dashed;
}

.target-context__content {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
</style>
