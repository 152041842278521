<template>
  <div class="view-subtitle">
    <p v-if="!editing">
      <!-- @slot The content of the view's subtitle. Unlike `view-title` this can have anything in it. -->
      <slot>{{ value }}</slot>
    </p>
    <form-button
      v-if="editable && !editing"
      tertiary
      class="view-subtitle__action"
      @click="toggleEdit"
    >
      <icon icon="pencil" />
      <span class="sr-only">Edit {{ ariaLabel }}</span>
    </form-button>
    <async-form
      v-if="editing"
      class="view-subtitle__form"
      @submit="saveValue"
      persist
    >
      <text-input
        v-model="mutableValue"
        :aria-label="ariaLabel"
        rules="required"
        class="view-subtitle__input"
        :placeholder="placeholder"
      />
      <form-button
        v-if="editable"
        tertiary
        class="view-subtitle__action"
        type="submit"
      >
        <icon icon="check" />
        <span class="sr-only">Save</span>
      </form-button>
      <form-button
        v-if="editable"
        tertiary
        class="view-subtitle__action"
        @click="toggleEdit"
      >
        <icon icon="times" />
        <span class="sr-only">Cancel</span>
      </form-button>
    </async-form>
  </div>
</template>

<script>
export default {
  name: 'ViewSubtitle',
  emits: ['submit'],
  props: {
    /**
     * Allows the subtitle to be modified.
     */
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * The editable subtitle value.
     * Raises the `submit` event when changed.
     * Required when `editable` is `true`.
     */
    value: {
      type: String,
      required: false
    },
    /**
     * Provides the accessible label to the editable subtitle.
     * Required when `editable` is `true`.
     */
    ariaLabel: {
      type: String,
      required: false
    },
    /** Placeholder text for the textbox in an editable subtitle */
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editing: false,
      mutableValue: ''
    }
  },
  methods: {
    toggleEdit() {
      if (this.value) this.mutableValue = this.value
      this.editing = !this.editing
    },
    saveValue() {
      /**
       * Raised with the new subtitle when it is changed.
       * @type {String}
       */
      this.$emit('submit', this.mutableValue)
      this.editing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.view-subtitle {
  width: 100%;
  font-weight: normal;
  font-size: 16px;
}
.view-subtitle p {
  display: inline-block;
  margin: 0;
}
.view-subtitle__form {
  margin: 0;
  min-height: 44px;
}
.view-subtitle__input {
  display: inline-block;
  width: 300px;
}
.view-subtitle__action {
  font-size: 16px;
  line-height: 25px;
  display: inline-block;
  width: 41px;
  height: 41px;
  padding: 0;
}
</style>
