import * as types from '../mutation-types'
import client from 'src/shared/api-client'
const state = {
  instances: []
}

const getters = {}

const actions = {
  loadInstances({ commit }) {
    return client.instances.list().then(response => {
      commit(types.SET_INSTANCES, response)
    })
  }
}

const mutations = {
  [types.SET_INSTANCES](state, instances = []) {
    state.instances = instances.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
