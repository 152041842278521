<template>
  <li
    class="library-dropdown-item"
    :class="{ 'library-dropdown-item--active': isActive }"
  >
    <button class="library-dropdown-item__button" @click="changeLibrary">
      <slot />
    </button>
    <button-dropdown
      v-if="isShared && !hideMenu && canManage"
      class="library-dropdown-item__menu"
      link
    >
      <template #button>
        <icon icon="ellipsis-h" />
        <span class="sr-only"><slot /> Menu</span>
      </template>
      <dropdown-link
        v-if="!isArchived"
        :to="{ name: 'manage_library', params: { libraryId: library } }"
      >
        Edit & Share
      </dropdown-link>
      <dropdown-action v-if="!isArchived" @click="archiveLibrary">
        Archive
        <span class="sr-only"><slot /> Archive</span>
      </dropdown-action>
      <dropdown-action v-if="isArchived" @click="unArchiveLibrary">
        Unarchive
        <span class="sr-only"><slot /> Unarchive</span>
      </dropdown-action>
    </button-dropdown>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import client from 'src/shared/api-client.ts'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'

export default {
  name: 'SidebarLibrary',
  inject: ['browseActivities', '$modal', 'buttonDropdown'],
  props: {
    library: {
      type: String,
      required: true
    },
    hideMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    selectedLibrary() {
      return this.browseActivities.query.library
    },
    isActive() {
      return this.selectedLibrary === this.library
    },
    isShared() {
      return !['pivot', 'community'].includes(this.library)
    },
    canManage() {
      const library = this.browseActivities.getLibrary(this.library)
      return this.canManageLibrary(library)
    },
    isArchived() {
      const library = this.browseActivities.getLibrary(this.library)
      return library.status && library.status === 'archived'
    },
    libraryName() {
      const textEl = this.$slots.default().find(el => !!el.text)
      return textEl ? textEl.text.trim() : ''
    }
  },
  methods: {
    changeLibrary() {
      if (this.selectedLibrary !== this.library) {
        this.browseActivities.updateQuery({
          page: 1,
          library: this.library,
          isArchived: undefined
        })
      }
      this.buttonDropdown.close()
    },
    canManageLibrary(library) {
      const currentMember = (library.members || []).find(
        member => member.id === this.user.id
      ) || {
        permissions: 'none'
      }
      return ['owner', 'admin'].includes(currentMember.permissions)
    },
    async archiveLibrary() {
      const libraryName = this.libraryName || 'this library'
      const { status } = await this.$modal.show(ConfirmModal, {
        text: `Archiving ${libraryName} will make it unavailable to be used and all activities within it to be unavailable.`,
        prompt: `Are you sure you want to archive ${libraryName}?`
      })
      if (status === 'ok') {
        await client.libraries.setIsArchived(this.library)
        this.$success('The library has been archived')
        await this.browseActivities.loadLibraries()
        await this.browseActivities.loadSelectedLibrary()
      }
    },
    async unArchiveLibrary() {
      await client.libraries.setIsArchived(this.library, false)
      this.$success('The library has been unarchived')
      await this.browseActivities.loadLibraries()
      await this.browseActivities.loadSelectedLibrary()
    }
  }
}
</script>

<style lang="scss" scoped>
.library-dropdown-item {
  position: relative;

  &__button {
    font-weight: normal;
    display: block;
    margin: 0;
    cursor: pointer;
    padding: 0;
    flex-grow: 1;
    background-color: transparent;
    border-radius: 0;
    border: none;
    color: #333333;
    text-align: left;
    padding: 3px 44px 3px 20px;
    width: 100%;
    font-weight: 400;
    line-height: 1.6;
    white-space: nowrap;

    &:hover {
      color: #262626;
      background-color: #f5f5f5;
      outline: none;
      text-decoration: none;
    }
    &:focus {
      background-color: #f5f5f5;
      text-decoration: underline;
    }

    .library-dropdown-item--active & {
      font-weight: bold;
    }
  }

  :deep(.library-dropdown-item__menu) {
    position: absolute;
    right: 15px;
    top: 0;
    height: 28px;
    padding: 0 6px;
  }
}
</style>
