<template>
  <hyper-link
    class="button-link"
    :class="{
      'button-link--secondary': secondary,
      'button-link--tertiary': tertiary,
      'button-link--alpha': alpha,
      'button-link--beta': beta
    }"
    :to="to"
  >
    <slot />
  </hyper-link>
</template>

<script>
export default {
  name: 'ButtonLink',
  props: {
    to: {
      type: [String, Object],
      required: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    },
    alpha: {
      type: Boolean,
      default: false
    },
    beta: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.button-link {
  text-decoration: none;
  display: inline-block;
  background-color: $teal;
  border: $teal solid 1px;
  color: #ffffff;
  border-radius: 6px;
  font-weight: 700;
  padding: 7px 14px;
  opacity: (1);
  transition: opacity 0.25s ease-out;

  &:hover,
  &:focus {
    color: white;
    background-color: $darker-teal;
    border: $darker-teal solid 1px;
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.button-link--secondary {
  background-color: white;
  border-color: $teal;
  color: $teal;

  &:hover,
  &:focus {
    color: white;
    background-color: $darker-teal;
    border: $darker-teal solid 1px;
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

.button-link--tertiary {
  border-color: transparent;
  color: $teal;
  background-color: inherit;

  &:hover,
  &:focus {
    color: $darker-teal;
    background-color: inherit;
  }
}

.button-link--alpha {
  background-color: #ffffff;
  border-radius: 3px;
  color: #3c393a;
  border: 1px solid #3c393a;

  &:hover,
  &:focus {
    background-color: #ffffff;
    border-radius: 3px;
    color: #3c393a;
    border: 1px solid #3c393a;
  }
}

.button-link--beta {
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #3d2c60;
  background-color: #3d2c60;

  &:hover,
  &:focus {
    color: #ffffff;
    border-radius: 3px;
    border: 1px solid #3d2c60;
    background-color: #3d2c60;
  }
}
</style>
