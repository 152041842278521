<template>
  <button-dropdown
    class="library-filter-dropdown__button"
    menu-class="library-filter-dropdown__menu"
    v-model:open="dropdownOpen"
    :menu-tag="tag"
    :open="dropdownOpen"
  >
    <template #button="{ isOpen }">
      <slot name="button">{{ name }}</slot>
      {{ ' ' }}
      <span v-if="count" aria-hidden="true">({{ count }})</span>
      <span class="sr-only">{{ count }} filters selected</span>
      <icon
        class="library-filter-dropdown__icon"
        :icon="isOpen ? 'caret-up' : 'caret-down'"
      />
    </template>
    <template #default>
      <button
        class="dropdown-close"
        type="button"
        aria-label="close"
        @click="close"
      >
        <icon icon="times" />
      </button>
      <slot />
    </template>
  </button-dropdown>
</template>

<script>
export default {
  name: 'LibraryFilterDropdown',
  props: {
    name: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  data() {
    return {
      dropdownOpen: false
    }
  },
  methods: {
    close() {
      this.dropdownOpen = false
    }
  }
}
</script>

<style lang="scss">
.library-filter-dropdown__button {
  position: relative;
  background: inherit;
  color: inherit;
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  text-align: left;
  border: 1px solid #ccc;
  line-height: 1;
  padding-right: 24px;

  &:hover,
  &:focus {
    background: inherit;
    border-color: #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

.dropdown-close {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  right: 0;
  padding: 0;
  color: $color-error;
  font-size: 14px;
  font-weight: 900;
  line-height: 32px;
  background-color: transparent;
  border: none;
  justify-content: center;
  display: flex;
  align-items: center;

  &:focus {
    margin: 6px 6px 0 0;
    outline: $teal solid 2px;
    width: 20px;
    height: 20px;
    border-radius: $border-radius-base;
  }
}

.library-filter-dropdown__icon {
  position: absolute;
  right: 8px;
}

.library-filter-dropdown__menu {
  max-height: calc(100vh - 400px);
  overflow: hidden auto;
  padding-right: 16px !important;
}
</style>
