<template>
  <div class="activity-changelog-tab">
    <form-button
      secondary
      class="activity-changelog-tab__button"
      @click="addEntry"
    >
      Add New
    </form-button>
    <changelog-entry
      v-for="entry in changelogEntries"
      :key="entry.id"
      :changelog-entry="entry"
      class="changelog-entry"
      :collapsed="!!collapsedEntries[entry.id]"
      @update:collapsed="collapsed => toggleEntry(entry.id, collapsed)"
    />
  </div>
</template>

<script setup>
import { watchEffect, computed, inject, ref, onBeforeMount } from 'vue'
import ChangelogEntry from './ChangelogEntry.vue'
import ActivityChangelogEntryModal from './ActivityChangelogEntryModal.vue'
import client from 'src/shared/api-client'

const emit = defineEmits('update:collapsed', 'saved')
const props = defineProps({
  collapsed: {
    type: Boolean,
    default: true
  }
})

const changelogEntries = ref([])
const modal = inject('$modal')
const inherited = inject('inherited')

const addEntry = async () => {
  const { status, data } = await modal.show(ActivityChangelogEntryModal)

  if (status === 'ok') {
    await client.activities.createChangelogEntry({
      ...data,
      activityId: inherited.value.activity.id
    })
    emit('saved')
    await getEntries()
  }
}

const getEntries = async () => {
  const entries = await await client.activities.getChangelog({
    activityId: inherited.value.activity.id
  })
  changelogEntries.value = entries
}

onBeforeMount(async () => {
  await getEntries()
})

const collapsedEntries = ref({})
const areAllCollapsed = computed(() =>
  changelogEntries.value.every(entry => collapsedEntries.value[entry.id])
)
function toggleEntry(id, collapsed) {
  collapsedEntries.value[id] = collapsed

  if (areAllCollapsed.value && !props.collapsed) {
    emit('update:collapsed', true)
  } else if (!areAllCollapsed.value && props.collapsed) {
    emit('update:collapsed', false)
  }
}

watchEffect(() => {
  if (props.collapsed && !areAllCollapsed.value) {
    collapsedEntries.value = Object.fromEntries(
      changelogEntries.value.map(entry => [entry.id, true])
    )
  } else if (!props.collapsed && areAllCollapsed.value) {
    collapsedEntries.value = {}
  }
})
</script>

<style lang="scss" scoped>
.activity-changelog-tab {
  display: flex;
  flex-direction: column;
}
.activity-changelog-tab__button {
  width: 100px;
  align-self: flex-end;
  margin-top: 15px;
}
.changelog-entry {
  margin-bottom: 25px;
}
</style>
