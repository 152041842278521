<template>
  <div>
    <slot />
  </div>
</template>

<script>
import * as Vue from 'vue'

export default {
  name: 'ProgressProvider',
  provide() {
    return {
      progress: Vue.computed(() => {
        return {
          registerElement: this.registerElement,
          unregisterElement: this.unregisterElement,
          updateElement: this.updateElement,
          summary: this.summary
        }
      })
    }
  },
  data() {
    return {
      elements: [],
      summary: ''
    }
  },
  created() {
    window.addEventListener('load', this.getElementIntoView)
    window.addEventListener('scroll', this.getElementIntoView, true)
  },
  methods: {
    registerElement(el) {
      const index = this.elements.findIndex(el2 => el2.id === el.id)
      if (index >= 0) {
        this.elements.splice(index, 1, el)
      } else {
        this.elements.push(el)
      }
      // When the first element is added, see if it is visible
      // Otherwise the progress won't update until a scroll event
      if (this.elements.length === 1) {
        this.getElementIntoView()
      }
    },

    unregisterElement(id) {
      const index = this.elements.findIndex(el2 => el2.id === id)
      if (index >= 0) {
        this.elements.splice(index, 1)
      }
    },

    updateElement(id, el) {
      const index = this.elements.findIndex(el2 => el2.id === id)
      if (index >= 0) {
        this.elements.splice(id, 1, el)
      }
    },

    getElementIntoView() {
      const elementsArr = []

      this.elements.forEach(
        function (element, index) {
          elementsArr.splice(index, 0, this.panelViewPercent(element.el))
        }.bind(this)
      )
      this.setSubHeaderText(elementsArr)
    },

    setSubHeaderText(elementsArr) {
      const indexInView = this.maxValueIndex(elementsArr)
      this.summary = this.elements[indexInView]?.title
    },
    maxValueIndex(elementsArr) {
      let maxIndex = 0
      let maxValue = elementsArr[0]

      for (let i = 1; i < elementsArr.length; ++i) {
        if (elementsArr[i] > maxValue) {
          maxValue = elementsArr[i]
          maxIndex = i
        }
      }

      return maxIndex
    },
    panelViewPercent(element) {
      const bounding = element.getBoundingClientRect()

      const navBarBounding = document
        .querySelector('.navbar')
        .getBoundingClientRect()
      const stickyHeaderBounding = document
        .querySelector('.sticky-header')
        ?.getBoundingClientRect()

      const offsetTop =
        navBarBounding.height + (stickyHeaderBounding?.height ?? 0)
      const offsetBottom = 0

      const viewportTop =
        (window.scrollTop || document.documentElement.scrollTop) + offsetTop
      const viewportHeight =
        (window.innerHeight || document.documentElement.clientHeight) -
        offsetTop -
        offsetBottom
      const viewportBottom = viewportTop + viewportHeight

      const eleTop = bounding.top
      const eleHeight = bounding.height
      const eleBottom = eleTop + eleHeight

      const totalHeight =
        Math.max(eleBottom, viewportBottom) - Math.min(eleTop, viewportTop)
      const heightDiff = totalHeight - viewportHeight
      const elementInside = eleHeight - heightDiff

      return parseInt(
        elementInside <= 0 ? 0 : (elementInside / eleHeight) * 100
      )
    }
  },
  unmounted() {
    window.removeEventListener('load', this.getElementIntoView)
    window.removeEventListener('scroll', this.getElementIntoView, true)
  }
}
</script>
