<template>
  <modal class="settings-modal" @submit="onSubmit">
    <modal-header>{{ variable.name }} Settings</modal-header>
    <modal-body>
      <form-group v-if="variable.variableType === 'number'">
        <form-label>Formatting</form-label>
        <variable-formatting
          v-model:precision="localVariable.precision"
          v-model:precisionType="localVariable.precisionType"
          v-model:sciNotation="localVariable.useScientificNotation"
        />
      </form-group>
      <form-group>
        <form-label for="variableDescription">Description</form-label>
        <multiline-text-input
          id="variableDescription"
          v-model="localVariable.description"
          label="description"
          aria-placeholder="Description"
        />
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Save</modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import { ref } from 'vue'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import VariableFormatting from './VariableFormatting.vue'

const emit = defineEmits(['update:variable', 'close'])
const props = defineProps({
  variable: {
    type: Object,
    required: true
  }
})

const localVariable = ref({ ...props.variable })

function onSubmit(e) {
  e.done(localVariable.value)
}
</script>
<style scoped lang="scss">
.settings-modal {
  max-width: 380px;
}
</style>
