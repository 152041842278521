import * as Y from 'yjs'

const defaultNumColumns = 2
const defaultNumRows = 5

export const DEFAULT_TEXT_WIDTH = 160
export const DEFAULT_NUM_WIDTH = 95
const FALLBACK_WIDTH = 120

const NUMBER_REGEX = /^\s*(\+|-)?(\d+(\.\d+)?|\.\d+)((E|e)(\+|-)?\d+)?\s*$/

export function parseCellNumber(str) {
  return NUMBER_REGEX.test(str) ? parseFloat(str) : NaN
}

export const defaultColumnValue = {
  name: '',
  units: '',
  variable: '',
  formula: '',
  format: 'auto',
  decimalPlaces: 3,
  significantFigures: 3,
  allowText: false,
  width: DEFAULT_NUM_WIDTH,
  data: Array(defaultNumRows).fill(null),
  // v3
  deviceId: '',
  dataStreamId: '',
  calculations: []
}

export const newColumn = rows => ({
  ...defaultColumnValue,
  data: Array(rows).fill(''),
  calculations: []
})

export const graphDefinitionToYMap = (ymap, definition) => {
  ymap.set('title', definition.title)
  ymap.set('type', definition.type)
  ymap.set('curveFitUncertainties', definition.curveFitUncertainties)
  ymap.set('includeOrigin', definition.includeOrigin)

  const hCols = new Y.Map()
  hCols.set('data', -1)
  hCols.set('uncertainty', -1)

  ymap.set('hColumns', hCols)

  const defaultVColumn = new Y.Map()
  defaultVColumn.set('data', -1)
  defaultVColumn.set('uncertainty', -1)
  defaultVColumn.set('curve', 'none')

  ymap.set('vColumns', Y.Array.from([defaultVColumn]))
}

export const columnDefinitionToYMap = (ymap, definition) => {
  ymap.set('name', definition.name)
  ymap.set('units', definition.units)
  ymap.set('variable', definition.variable)
  ymap.set('formula', definition.formula)
  ymap.set('format', definition.format)
  ymap.set('decimalPlaces', definition.decimalPlaces)
  ymap.set('significantFigures', definition.significantFigures)
  ymap.set('allowText', definition.allowText)
  ymap.set('width', definition.width)
  ymap.set('deviceId', definition.deviceId)
  ymap.set('dataStreamId', definition.dataStreamId)
  ymap.set('width', definition.width)

  const calculations = new Y.Array()
  ymap.set('calculations', calculations)
  calculations.push([...(definition.calculations || [])].filter(val => val))

  const data = new Y.Array()
  ymap.set('data', data)
  data.push(definition.data.map(val => val ?? ''))
}

export const defaultColumnName = 'Name'
export const defaultColumnUnits = 'units'
export const defaultColumnVariable = 'var'

export const columnDisplayName = (column, fallback = defaultColumnName) =>
  column?.get?.('name') || column?.name || fallback

export const columnDisplayUnits = (column, fallback = defaultColumnUnits) =>
  column?.get?.('units') || column?.units || fallback

export const columnDisplayVariable = (
  column,
  fallback = defaultColumnVariable
) => column?.get?.('variable') || column?.variable || fallback

export const columnDisplayWidth = column => {
  return column?.get?.('width') ?? column?.width ?? FALLBACK_WIDTH
}

export const columnDisplayValue = (column, rowIndex) => {
  const value =
    typeof column.get === 'function'
      ? column.get('data').get(rowIndex)
      : column.data?.[rowIndex]

  if (
    value === null ||
    (column.get?.('allowText') ?? column.allowText) ||
    (column.get?.('format') ?? column.format) === 'auto'
  )
    return value

  const float = parseFloat(value)

  if (isNaN(float)) {
    return value
  }

  switch (column.get?.('format') ?? column.format) {
    case 'scientific':
      return float.toExponential()
    case 'decimals':
      return float.toFixed(
        column.get?.('decimalPlaces') ?? column.decimalPlaces
      )
    case 'sigfigs':
      return float.toPrecision(
        column.get?.('significantFigures') ?? column.significantFigures
      )
    case 'auto':
    default:
      return value
  }
}

export const toPlotlyString = val => {
  if (typeof val === 'object') {
    return val.formula
  } else {
    return val
  }
}

export const columnHasData = column =>
  column
    .get('data')
    .toArray()
    .some(value => value !== null && value !== '')

export const rowHasData = (columns, rowIndex) =>
  columns.toArray().some(column => {
    const value = column.get('data').get(rowIndex)
    return value !== null && value !== ''
  })

export const columnIsUntouched = column =>
  !columnHasData(column) &&
  !column.get('formula') &&
  column.get('name') === defaultColumnValue.name &&
  column.get('units') === defaultColumnValue.units &&
  column.get('variable') === defaultColumnValue.variable &&
  column.get('dataStreamId') === defaultColumnValue.dataStreamId &&
  column.get('calculations').toArray().length === 0

export const columnsAreUntouched = columns =>
  columns.toArray().every(columnIsUntouched)

export const lastDataRowForColumn = column => {
  let row = -1
  for (let i = column.get('data').length - 1; i >= 0; i--) {
    const value = column.get('data').get(i)
    if (value !== null && value !== '') {
      row = i
      break
    }
  }
  return row
}

export const cropRowsForGridData = ymap => {
  const columns = ymap.get('columns')

  let lastRowWithData = defaultNumRows - 1
  for (let r = ymap.get('rows') - 1; r >= defaultNumRows; r--) {
    let allColumnsEmpty = true
    for (let c = 0; c < columns.length; c++) {
      const value = columns.get(c).get('data').get(r)

      if (value !== null && value !== '') {
        allColumnsEmpty = false
        break
      }
    }
    if (!allColumnsEmpty) {
      lastRowWithData = r
      break
    }
  }

  if (lastRowWithData !== ymap.get('rows') - 1) {
    const numRowsRemoved = ymap.get('rows') - 1 - lastRowWithData
    for (const column of columns) {
      column.get('data').delete(lastRowWithData, numRowsRemoved)
    }
    ymap.set('rows', ymap.get('rows') - numRowsRemoved)
  }
}

export const clearColumnWidths = ymap => {
  const columns = ymap.get('columns')
  for (let c = 0; c < columns.length; c++) {
    const column = columns.get(c)
    const isText = column?.get?.('allowText') || column?.allowText
    column.set('width', isText ? DEFAULT_TEXT_WIDTH : DEFAULT_NUM_WIDTH)
  }
}

export const clearColumnData = column => {
  if (!column) {
    return
  }

  column.set('data', Y.Array.from(Array(column.get('data').length).fill('')))
}

const defaultNumGraphVAxisColumns = 1

const defaultGraphAxisColumns = {
  data: -1,
  uncertainty: -1
}

export const defaultGraphVAxisColumns = {
  ...defaultGraphAxisColumns,
  curve: 'none'
}

const defaultBluetoothSettings = {
  sampleRate: 1,
  maxSamples: 25
}

export const defaultGridValue = (
  numColumns = defaultNumColumns,
  numRows = defaultNumRows
) => ({
  format: 4,
  columns: Array(numColumns)
    .fill()
    .map(() => newColumn(numRows)),
  // v3
  rows: numRows,
  bluetooth: { ...defaultBluetoothSettings }
})

export const defaultGraphValue = (
  numVAxisColumns = defaultNumGraphVAxisColumns
) => ({
  hColumns: { ...defaultGraphAxisColumns },
  vColumns: Array(numVAxisColumns)
    .fill()
    .map(() => ({ ...defaultGraphVAxisColumns })),
  includeOrigin: false,
  curveFitUncertainties: false,
  type: 'scatter',
  // v3
  title: ''
})

export const attachValueToYMap = (map, value) => {
  map.set(
    'rows',
    typeof value.rows === 'number' ? value.rows : value.rows.length
  )
  map.set('format', value.format)

  // TO DO: finish adding graph data
  map.set(
    'graphs',
    Y.Array.from(
      (value.graphs ?? [defaultGraphValue()]).map(graph => {
        const g = new Y.Map()
        g.set('title', graph.title)
        g.set('type', graph.type)
        g.set('includeOrigin', graph.includeOrigin)
        g.set('hColumns', new Y.Map())
        g.set('vColumns', new Y.Array())
        return g
      })
    )
  )

  map.set(
    'columns',
    Y.Array.from(
      (value.columns ?? []).map(column => {
        const c = new Y.Map()
        c.set('name', column.name)
        c.set('units', column.units)
        c.set('variable', column.variable)
        c.set('formula', column.formula)
        c.set('format', column.format)
        c.set('decimalPlaces', column.decimalPlaces)
        c.set('significantFigures', column.significantFigures)
        c.set('allowText', column.allowText)
        c.set('width', column.width)
        c.set('deviceId', column.deviceId)
        c.set('dataStreamId', column.dataStreamId)
        c.set(
          'data',
          Y.Array.from(column.data.map(value => (value === null ? '' : value)))
        )
        c.set(
          'calculations',
          Y.Array.from([...(column.calculations || [])].filter(val => val))
        )
        return c
      })
    )
  )

  const bluetooth = new Y.Map()
  bluetooth.set('sampleRate', value.bluetooth?.sampleRate ?? 1)
  bluetooth.set('maxSamples', value.bluetooth?.maxSamples ?? 25)
  map.set('bluetooth', bluetooth)

  const hColumns = new Y.Map()
  hColumns.set('data', value.hColumns?.data ?? -1)
  hColumns.set('uncertainty', value.hColumns?.uncertainty ?? -1)
  map.set('hColumns', hColumns)

  map.set(
    'vColumns',
    Y.Array.from(
      (value.vColumns ?? []).map(vColumn => {
        const c = new Y.Map()
        c.set('data', vColumn.data)
        c.set('uncertainty', vColumn.uncertainty)
        c.set('curve', vColumn.curve)
        return c
      })
    )
  )
}

export const gridHasData = value => {
  return (
    value &&
    value.columns &&
    value.columns.some(c => c.data.some(d => d !== ''))
  )
}

export const convertDataFormat = value => {
  if (!value) throw new Error('Table/Graph value is null')
  if (typeof value === 'string') {
    throw new Error('Table/Graph value is not parsed')
  }

  const version = parseInt(value.format)

  if (version === 5) {
    return value
  } else if (version === 4) {
    return convertV4Format(value)
  } else if (version === 3) {
    return convertV3Format(value)
  } else if (version === 2) {
    return convertV2Format(value)
  } else {
    return convertV1Format(value)
  }
}

export const addMultipleGraphSupport = value => {
  if (!value.graphs) {
    const {
      title,
      type,
      includeOrigin,
      curveFitUncertainties,
      hColumns,
      vColumns,
      ...remainingProps
    } = value
    const graphData = {
      title,
      type,
      includeOrigin,
      curveFitUncertainties,
      hColumns,
      vColumns
    }
    const multipleGraphResponseValue = {
      ...remainingProps,
      graphs: [graphData]
    }
    return multipleGraphResponseValue
  }
  return value
}

export const convertV4Format = value => {
  value.format = 5
  return addMultipleGraphSupport(value)
}

export const convertV3Format = value => {
  if (Array.isArray(value.rows)) {
    value.rows = value.rows.length
  } else if (typeof value.rows === 'string') {
    value.rows = Math.max(...value.columns.map(column => column.data.length))
  }

  value.columns = value.columns.map(column => ({
    ...column,
    data: column.data.concat(
      new Array(value.rows - column.data.length).fill(null)
    ),
    deviceId: column.deviceId || '',
    dataStreamId: column.dataStreamId || ''
  }))

  value.bluetooth = { ...defaultBluetoothSettings, ...value.bluetooth }
  value.format = 4

  return addMultipleGraphSupport(value)
}

export const convertV2Format = value => {
  value.columns = value.columns.map(column => ({
    ...column,
    deviceId: '',
    dataStreamId: ''
  }))
  value.bluetooth = { ...defaultBluetoothSettings }
  value.format = 4
  value.rows = value.rows.length

  if (typeof value.title === 'undefined') {
    value.title = ''
  }

  return addMultipleGraphSupport(value)
}

export const convertV1Format = value => {
  if (!Array.isArray(value.data))
    throw new Error('Table/Graph value missing `data` property')

  const columns = value.data.length
  const rows = Math.max(...value.data.map(c => c.length))

  const migrated = {
    ...defaultGridValue(columns, rows),
    graphs: [{ ...defaultGraphValue(1) }]
  }

  for (const columnIndex in migrated.columns) {
    if (
      Array.isArray(value.headers) &&
      value.headers[columnIndex] &&
      value.headers[columnIndex] !== 'New Column'
    ) {
      migrated.columns[columnIndex].name = value.headers[columnIndex]
    }
    if (
      Array.isArray(value.units) &&
      value.units[columnIndex] &&
      value.units[columnIndex] !== 'units'
    ) {
      migrated.columns[columnIndex].units = value.units[columnIndex]
    }
    if (Array.isArray(value.formulas) && value.formulas[columnIndex]) {
      migrated.columns[columnIndex].formula = value.formulas[columnIndex]
    }

    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
      const data = value.data[columnIndex][rowIndex]
      if (typeof data === 'number' && !isNaN(data) && isFinite(data)) {
        migrated.columns[columnIndex].data[rowIndex] = data
      }
    }
  }

  let hasRowLabels = false
  const rowLabels = Array(migrated.rows).fill('')
  for (let rowIndex = 0; rowIndex < migrated.rows; rowIndex++) {
    if (Array.isArray(value.rowLabels) && value.rowLabels[rowIndex]) {
      hasRowLabels = true
      rowLabels[rowIndex] = value.rowLabels[rowIndex]
    }
  }

  const graph = migrated.graphs[0]

  if (typeof value.xIndex === 'number' && value.xIndex > -1) {
    graph.hColumns.data = value.xIndex

    if (typeof value.xErrorIndex === 'number') {
      graph.hColumns.uncertainty = value.xErrorIndex
    }
  }

  if (typeof value.y1Index === 'number') {
    graph.vColumns[0].data = value.y1Index

    if (typeof value.y1ErrorIndex === 'number') {
      graph.vColumns[0].uncertainty = value.y1ErrorIndex
    }
  }

  if (typeof value.y2Index === 'number') {
    graph.vColumns.push({
      data: value.y2Index,
      uncertainty: -1,
      curve: 'none'
    })

    if (typeof value.y2ErrorIndex === 'number') {
      graph.vColumns[1].uncertainty = value.y2ErrorIndex
    }
  }

  if (value.regressionEnabled) {
    graph.vColumns[0].curve = 'linear'

    if (graph.vColumns[1]) {
      graph.vColumns[1].curve = 'linear'
    }
  }

  if (graph.showOrigin) migrated.includeOrigin = true

  // if any row labels are filled in, add a text column at the start
  // with this label in it. then shift all referenced indexes up by 1.
  if (hasRowLabels) {
    // shift indexes in column formulas
    for (let i = 0; i < migrated.columns.length; i++) {
      migrated.columns[i].formula = migrated.columns[i].formula.replace(
        /\(col(\d+)\)/g,
        (match, column) => {
          return `(col${+column + 1})`
        }
      )
    }

    // shift graph column indexs
    if (graph.hColumns.data >= 0) graph.hColumns.data++
    if (graph.hColumns.uncertainty >= 0) graph.hColumns.uncertainty++

    for (let i = 0; i < graph.vColumns.length; i++) {
      if (graph.vColumns[i].data >= 0) graph.vColumns[i].data++
      if (graph.vColumns[i].uncertainty >= 0) graph.vColumns[i].uncertainty++
    }

    // insert new text column at beginning with row names
    migrated.columns.unshift({
      name: 'Row Name',
      units: '',
      variable: '',
      formula: '',
      format: 'auto',
      decimalPlaces: 1,
      significantFigures: 1,
      allowText: true,
      width: null,
      deviceId: '',
      dataStreamId: '',
      data: [...rowLabels]
    })
  }

  return migrated
}
