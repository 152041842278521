<template>
  <div class="embedded-iFrame">
    <form-group class="left-panel">
      <div class="inner-left-panel">
        <div class="input-box">
          <label class="control-label" :for="`${id}-url`">URL</label>
          <text-input
            :id="`${id}-url`"
            v-model="url"
            help-text="Paste the URL from the web resource you wish to embed"
            placeholder="Example: https://www.youtube.com/embed/0qx9y38MGfY"
            :rules="{
              required: true,
              undefinedVariables: true,
              url: { require_protocol: true, protocols: ['https'] }
            }"
            label="URL"
            :name="`${name}.url`"
            @change="emitChange"
            @blur="extractEmbedURL"
          />
        </div>
        <form-button link class="addVariablesBtn" @click="addVariables">
          [+var]
        </form-button>
      </div>
    </form-group>
    <form-group class="right-panel">
      <label class="control-label" :for="`${id}-poster`">Poster Image</label>
      <div>
        <div class="thumbnail-image-container">
          <div>
            <div
              class="thumbnail-image"
              :style="{ backgroundImage: `url(${posterSrc})` }"
            />
            <loading-container
              class="uploading-container"
              :class="{ uploading }"
              :loading="uploading"
            />
          </div>
        </div>
        <input
          :id="`${id}-poster`"
          ref="fileInput"
          type="file"
          accept="image/*"
          :aria-describedby="`${id}-image-help`"
          @change="fileChange($event)"
        />
        <span :id="`${id}-image-help`" class="help-block"
          >This image will be displayed before the embedded content is
          launched.</span
        >
      </div>
    </form-group>
  </div>
</template>

<script>
import client from 'src/shared/api-client'
import ActivityVariablesModal from 'src/shared/components/modals/ActivityVariablesModal'
import { findVariableByName, findVariableById } from '@pi/shared/variables'

let idCounter = 0
const VAR_REGEX = /\[\$([^[\]]+)\]/g

export default {
  name: 'EditIFrame',
  inject: ['$modal', 'inherited'],
  emits: ['change'],
  props: {
    component: {
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      url: '',
      poster: '',
      uploading: false,
      thumbnailCacheBuster: +new Date()
    }
  },
  computed: {
    id() {
      return `edit-iframe-${idCounter++}`
    },
    isPhetEmbed() {
      return (
        this.component.url && this.component.url.includes('phet.colorado.edu')
      )
    },
    posterSrc() {
      if (this.poster) {
        return `${this.poster}?rnd=${this.thumbnailCacheBuster}`
      }

      return this.isPhetEmbed
        ? `https://assets.pivotinteractives.com/public/pivot%2Bphet-launcher.png`
        : `https://assets.pivotinteractives.com/public/pivot-launcher.png`
    },
    activity() {
      return this.inherited.activity
    }
  },
  methods: {
    emitChange() {
      this.$emit('change', {
        url: this.url.replaceAll(VAR_REGEX, (_, name) => {
          const id =
            findVariableByName(name, this.variableContext.variables)?.id ?? name
          return `[$${id}]`
        }),
        config: JSON.stringify({ poster: this.poster })
      })
    },
    extractEmbedURL: function (e) {
      const input = e.target.value
      let url = input
      const iframeRegexMatch = input.match(/<iframe.+src\s*=\s*"([^"]+)"/)
      const youtubeLinkRegexMatch = input.match(
        /(?:youtube.com\/watch\?v=|youtu.be\/)([\w-]+)(?:(?:\?|&)t=(\d+))?/
      )
      if (iframeRegexMatch) {
        url = iframeRegexMatch[1]
      } else if (youtubeLinkRegexMatch) {
        url = `https://www.youtube.com/embed/${youtubeLinkRegexMatch[1]}${
          youtubeLinkRegexMatch[2] ? `&start=${youtubeLinkRegexMatch[2]}` : ''
        }`
      }
      this.url = url
      this.emitChange()
    },
    async fileChange(event) {
      this.uploading = true

      try {
        const file = event.target.files[0]
        const { name } = file
        const extension = name.split('.').slice(-1)[0].toLowerCase()
        const fileName = this.component.id
          ? `component-${this.component.id}-thumbnail.${extension}`
          : `${Date.now()}.${extension}`

        const src = await client.assets.upload({
          file,
          name: fileName,
          type: 'image'
        })

        this.poster = `//${PI_API_HOST}${src}`

        this.emitChange()
      } finally {
        this.uploading = false
        if (this.$refs.fileInput) this.$refs.fileInput.value = null
        this.thumbnailCacheBuster = +new Date()
      }
    },
    async addVariables(e) {
      e.preventDefault()
      const { status, data } = await this.$modal.show(ActivityVariablesModal, {
        variableContext: this.variableContext,
        allowInsert: true
      })
      if (status === 'ok') {
        if (!this.url) {
          this.url = ''
        }
        this.url += `[$${data.name}]`
        this.emitChange()
      }
    }
  },
  watch: {
    component: {
      handler(value) {
        this.url = value.url?.replaceAll(VAR_REGEX, (_, id) => {
          console.log(id, this.variableContext.variables)
          const name =
            findVariableById(id, this.variableContext.variables)?.name ?? id
          return `[$${name}]`
        })

        try {
          const config = JSON.parse(value.config)
          this.poster = config.poster
        } catch {
          this.poster = ''
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail-image-container {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid #ccc;
  overflow: hidden;
}

.thumbnail-image-container > div {
  position: relative;
  height: 0px;
  padding-bottom: 56.25%;
}

.uploading-container,
.thumbnail-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.uploading-container.uploading {
  background-color: rgba(255, 255, 255, 0.5);
}

.embedded-iFrame {
  display: flex;
  justify-content: space-between;

  & .left-panel {
    width: 50%;
    margin: 0;
    margin-right: 20px;
  }

  & .right-panel {
    width: 50%;
    margin: 0;
    margin-left: 20px;
    margin-right: 20px;

    & input {
      margin-top: 20px;
    }
  }

  & .inner-left-panel {
    display: flex;
    justify-content: space-between;

    & .input-box {
      width: 90%;
    }
  }
}
</style>
