<template>
  <modal ref="modalElement" @submit="insert">
    <modal-header>Variables</modal-header>
    <modal-body class="variables-page__body">
      <div class="variables-page__details">
        <form-group>
          <form-label for="variable-name">Variable Name</form-label>
          <div>
            <text-input
              :disabled="name === 'response'"
              id="variable-name"
              v-model="name"
              label="name"
              :rules="{
                required: true,
                regex: /^[A-Za-z0-9_ℹ]+$/
              }"
              data-focus
            />
          </div>
        </form-group>

        <form-group v-if="name !== 'response'">
          <form-label for="variable-type">Variable Type</form-label>
          <select-field
            id="variable-type"
            v-model="variableType"
            label="type"
            rules="required"
            :disabled="isExistingVariable"
          >
            <option value="" disabled>Select Variable Type</option>
            <option value="random">Random</option>
            <option value="studentResponse">Previous Student Response</option>
            <option value="collection">Collection</option>
          </select-field>
        </form-group>

        <form-group
          v-if="selectedVariable.variableType === 'random'"
          class="variables-page__horizontal-group"
        >
          <div>
            <form-label for="variable-min">Min</form-label>
            <number-input id="variable-min" v-model="min" label="min" />
          </div>
          <div>
            <form-label for="variable-max">Max</form-label>
            <number-input id="variable-max" v-model="max" label="max" />
          </div>
          <div>
            <form-label for="variable-step">Step</form-label>
            <number-input
              id="variable-step"
              v-model="step"
              label="step"
              step="any"
            />
          </div>
        </form-group>

        <form-group v-if="selectedVariable.variableType === 'studentResponse'">
          <form-label for="variable-question">Question</form-label>
          <select-field
            id="variable-question"
            v-model="content"
            label="Question"
            rules="required"
          >
            <option value="" disabled>Select Question</option>

            <template
              v-for="component in numericalComponents"
              :key="component.id"
            >
              <option :value="component.id">
                {{
                  `Part ${component.sectionNumber}, Q${component.questionNumber}—${component.text}`
                }}
              </option>
            </template>
          </select-field>
        </form-group>

        <div v-if="selectedVariable.variableType === 'collection'">
          <template v-if="selectedVariable.variables">
            <form-group>
              <div>
                <form-label for="variables-in-collection"
                  >Variables in Collection</form-label
                >
                <form-button
                  class="variables-page__edit-collection-button"
                  link
                  @click="emit('edit')"
                  >Edit
                </form-button>
              </div>
              <list-box
                id="variables-in-collection"
                :size="Math.max(2, selectedVariable.variables.length)"
                v-model="selectedCollectionVariableId"
              >
                <template
                  v-for="item in selectedVariable.variables"
                  :key="item.id"
                >
                  <option :value="item.id">
                    {{ item.name }} <span>{{ item.variableType }}</span>
                  </option>
                </template>
              </list-box>
            </form-group>
          </template>
          <template v-else>
            <form-group class="variables-page__horizontal-group">
              <div>
                <form-label for="variable-count"
                  >Variables (Columns)</form-label
                >
                <number-input
                  id="variable-count"
                  v-model="columns"
                  rules="required|integer|min:1"
                />
              </div>
              <div>
                <form-label for="dataset-count">Data Sets (Rows)</form-label>
                <number-input
                  id="dataset-count"
                  v-model="rows"
                  rules="required|integer|min:1"
                />
              </div>
            </form-group>
          </template>
        </div>

        <form-group v-if="selectedVariable.variableType === 'random'">
          <div>
            <form-label for="precision">Formatting</form-label>
            <variable-formatting
              v-model:precision="precision"
              v-model:precisionType="precisionType"
              v-model:sciNotation="useScientificNotation"
            />
          </div>
        </form-group>

        <form-group v-if="name !== 'response'">
          <form-label for="variable-description">Description</form-label>
          <multiline-text-input
            id="variable-description"
            v-model="description"
            label="description"
          />
        </form-group>
      </div>

      <div class="variables-page__save-actions">
        <template v-if="name !== 'response'">
          <div class="variables-page__delete-action" v-if="selectedVariable.id">
            <form-button link destructive @click="emit('delete')"
              >Delete
              {{
                selectedVariable.variableType === 'collection'
                  ? 'Collection '
                  : ''
              }}Variable</form-button
            >
          </div>
          <form-button link @click="closeModal">Cancel</form-button>
          <form-button
            v-if="
              selectedVariable.variableType === 'collection' &&
              !selectedVariable.variables
            "
            :disabled="!isValid"
            @click="emit('edit')"
          >
            Create
          </form-button>
          <form-button
            v-else
            primary
            type="button"
            :disabled="!isValid"
            @click="emit('save')"
          >
            Save
          </form-button>
        </template>
      </div>

      <div class="variables-page__list">
        <form-label for="selected-variable">Existing Variables</form-label>
        <list-box
          id="selected-variable"
          v-model="selectedVariableId"
          aria-placeholder="Selected Variables"
          :size="2"
          class="variables-page__variable-list"
        >
          <template v-for="(item, index) in variables" :key="index">
            <option :value="item.id">${{ item.name }}</option>
          </template>
          <option value="">+ Add New Variable</option>
        </list-box>
      </div>

      <div v-if="allowInsert" class="variables-page__insert-actions">
        <form-button secondary v-if="selectedVariableId" @click="copyVariable"
          >Copy</form-button
        >
        <form-button secondary @click="pasteVariable">Paste</form-button>
        <modal-button-submit :disabled="!hasSelectedVariable" primary>
          {{ insertText }}
        </modal-button-submit>
      </div>
    </modal-body>
  </modal>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick } from 'vue'
import { getText } from 'src/shared/components/editor/utils.js'
import { useFlash } from 'src/shared/hooks/flash.js'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import VariableFormatting from './VariableFormatting'

const emit = defineEmits([
  'update:selectedVariableId',
  'change',
  'edit',
  'save',
  'delete'
])
const flash = useFlash()
const props = defineProps({
  selectedVariableId: {
    type: String,
    default: undefined
  },
  variables: {
    type: Array,
    required: true
  },
  newVariable: {
    type: Object,
    required: true
  },
  activity: {
    type: Object,
    required: true
  },
  allowInsert: {
    type: Boolean,
    default: false
  },
  insertText: {
    type: String,
    default: 'Insert'
  }
})

const selectedVariableId = computed({
  get: () => (props.selectedVariableId ?? '').split('.')[0],
  set: value => emit('update:selectedVariableId', value)
})
const selectedCollectionVariableId = computed({
  get: () => (props.selectedVariableId ?? '').split('.')[1],
  set: value =>
    emit('update:selectedVariableId', `${selectedVariableId.value}.${value}`)
})
const selectedVariable = computed(() => {
  return (
    props.variables.find(v => v.id === selectedVariableId.value) ??
    props.newVariable
  )
})
const selectedCollectionVariable = computed(() => {
  return selectedVariable.value.variables?.find(
    v => v.id === selectedCollectionVariableId.value
  )
})
const hasSelectedVariable = computed(
  () =>
    !!selectedVariable.value.id &&
    (selectedVariable.value.variableType !== 'collection' ||
      selectedCollectionVariable.value)
)
const isExistingVariable = computed(() => !!selectedVariable.value.id)

async function insert(e) {
  let id = selectedVariable.value.id
  let name = selectedVariable.value.name
  if (selectedVariable.value.variableType === 'collection') {
    id += `.${selectedCollectionVariable.value.id}`
    name += `.${selectedCollectionVariable.value.name}`
  }
  e.done({ id, name })
}

const copyVariable = async () => {
  try {
    const variable = {
      ...selectedVariable.value
    }
    if (variable.id) {
      delete variable.id
    }
    await navigator.clipboard.writeText(
      JSON.stringify({ type: 'variable', data: variable })
    )
    flash.success(`Variable has been copied to clipboard`)
  } catch (error) {
    flash.error('Something went wrong, please try again.')
  }
}

const getClipboardData = async () => {
  if (document.hasFocus()) {
    let jsonString
    try {
      jsonString = await navigator.clipboard.readText()
      const clipboardData = JSON.parse(jsonString)
      if (clipboardData?.type === 'variable') {
        const variable = clipboardData.data
        if (variable.name) {
          return variable
        }
      }
    } catch {
      return undefined
    }
  }
}

const duplicateVariableName = variableName => {
  const duplicateName = props.variables.some(v => v.name === variableName)
  if (duplicateName) {
    return duplicateVariableName(`${variableName}_copy`)
  }
  return variableName
}

const pasteVariable = async () => {
  const variableInClipboard = await getClipboardData()
  if (variableInClipboard) {
    const newVariable = {
      ...variableInClipboard,
      name: duplicateVariableName(variableInClipboard.name)
    }
    emit('update:selectedVariableId', '')
    emit('change', newVariable)

    await nextTick()
    emit('save')
  } else {
    flash.error('No variable in clipboard')
  }
}

function computedField(key, defaultValue) {
  return computed({
    get: () => selectedVariable.value[key] ?? defaultValue,
    set: value => emit('change', { ...selectedVariable.value, [key]: value })
  })
}
const name = computedField('name')
const variableType = computedField('variableType', '')
const min = computedField('min')
const max = computedField('max')
const step = computedField('step')
const content = computedField('content', '')
const columns = computedField('columns')
const rows = computedField('rows')
const precision = computedField('precision')
const precisionType = computedField('precisionType', 'decimal')
const useScientificNotation = computedField('useScientificNotation')
const description = computedField('description')

const isValid = computed(() => {
  if (!selectedVariable.value.name || selectedVariable.value.name === '') {
    return false
  }

  switch (selectedVariable.value.variableType) {
    case 'random': {
      if (
        typeof selectedVariable.value.min !== 'number' ||
        typeof selectedVariable.value.max !== 'number' ||
        typeof selectedVariable.value.step !== 'number'
      ) {
        return false
      }
      return true
    }
    case 'expression':
      if (!selectedVariable.value.content) {
        return false
      }
      return true
    case 'studentResponse':
      if (!selectedVariable.value.content) {
        return false
      }
      return true
    case 'collection':
      if (
        !selectedVariable.value.variables &&
        (typeof selectedVariable.value.rows !== 'number' ||
          typeof selectedVariable.value.columns !== 'number')
      ) {
        return false
      }
      return true
    default:
      return false
  }
})

const modalElement = ref()
function closeModal(e) {
  modalElement.value?.close()
}

const numericalComponents = computed(() => {
  return (props.activity?.sections ?? []).flatMap((section, sectionIndex) => {
    let questionNumber = 0

    const flattenedComponents = section.components.flatMap(component => {
      if (component.componentType === 'SplitView') {
        return [...component.leftContent, ...component.rightContent]
      }
      return component
    })

    return flattenedComponents
      .map((component, index) => {
        return [index, component]
      })
      .filter(
        ([, component]) => component.componentType === 'NumericalQuestion'
      )
      .map(([index, component]) => {
        const text = getText(component.text, {
          variables: props.variables
        })
        return {
          id: component._id,
          sectionNumber: section.sectionNumber,
          questionNumber: component.questionNumber,
          text: text ?? `Component ${index + 1}`
        }
      })
  })
})
</script>

<style lang="scss" scoped>
.variables-page__body {
  display: grid;
  grid:
    'details list' 1fr
    'save insert' auto
    / 1fr 1fr;
  overflow: hidden;
  height: 100%;
  column-gap: 16px;

  & > * {
    padding: 8px;
    margin: -8px;
  }
}

.variables-page__details {
  grid-area: details;
  overflow: auto;
}

.variables-page__horizontal-group {
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
}

.variables-page__list {
  grid-area: list;
  display: flex;
  flex-direction: column;
}

.variables-page__variable-list {
  flex-grow: 1;
  margin-bottom: 15px;
  min-height: 280px;
}

.variables-page__insert-actions,
.variables-page__save-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin-left: 16px;
  }
}

.variables-page__insert-actions {
  .form-button:not(.modal-button-submit):not(.form-button--link)
    + .form-button {
    margin-left: 16px;
    margin-right: 0;
  }
}

.variables-page__delete-action {
  display: flex;
  justify-self: flex-start;
  flex-grow: 1;
  margin-left: 0;
}

.variables-page__save-actions {
  grid-area: save;
}

.variables-page__save-actions {
  grid-area: save;
}

.variables-page__edit-collection-button {
  margin-left: 16px;
}
</style>
