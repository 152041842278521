<template>
  <div class="drag-drop-choice">
    <div class="drag-drop-choice__header">
      <form-label class="drag-drop-choice__label" :id="`choice-${choice.id}`">
        Choice {{ index + 1 }}
      </form-label>
      <div class="drag-drop-choice__actions">
        <icon-button
          label="Move Up"
          icon="chevron-up"
          :color="index === 0 ? 'move-disabled' : 'move-active'"
          @click="emit('move', -1)"
        />
        <icon-button
          label="Move Down"
          icon="chevron-down"
          :color="canMoveDown ? 'move-disabled' : 'move-active'"
          @click="emit('move', 1)"
        />
        <icon-button
          label="Duplicate Choice"
          icon="copy"
          color="copy-icon"
          @click="emit('duplicate')"
        />
        <icon-button
          label="Delete Choice"
          icon="trash"
          color="delete-icon"
          @click="emit('remove')"
        />
        <icon-button
          label="Add Choice Below"
          icon="plus"
          color="add-icon"
          @click="emit('insert')"
        />
      </div>
    </div>
    <div class="drag-drop-choice__content">
      <editor
        v-if="type === 'rich-text'"
        class="drag-drop-choice__text"
        v-model:text="text"
        :id="`${choice.id}-choice-text`"
        :variable-context="variableContext"
        :aria-labelledby="`choice-${choice.id}`"
        inline
      />
      <div v-else-if="type === 'latex'" class="drag-drop-choice__text">
        <div class="drag-drop-choice__latex-preview">
          <latex-block :latex="latexForPreview" />
        </div>
        <multiline-text-input
          :modelValue="text"
          @change="(newText: string) => (text = newText)"
          :id="`${choice.id}-choice-text`"
          :aria-labelledby="`choice-${choice.id}`"
          :rows="3"
          resizable-vertical
        />
      </div>
      <div class="drag-drop-choice__uses">
        <form-label :for="`choice-${choice.id}-uses`">Uses:</form-label>
        <number-input
          :id="`choice-${choice.id}-uses`"
          class="drag-drop-choice__uses-input"
          v-model="uses"
          stepper
          placeholder="Unlimited"
          min="0"
          step="1"
        />
      </div>
      <div class="drag-drop-choice__targets">
        <form-label>Valid Drop Targets</form-label>
        <autocomplete
          v-model="targets"
          :options="props.targets"
          label-key="label"
          value-key="id"
          multiple
          placeholder="Valid in all drop areas"
          aria-label="valid drop targets"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  processLatex,
  expressionToBoxedLatex
} from '../../../shared/utils/latex-methods'
import Autocomplete from '../../../shared/components/Autocomplete.vue'

interface Choice {
  id: number
  text?: string
  count?: number
  validTargets: string[]
}

interface Props {
  type: 'latex' | 'rich-text'
  index: number
  variableContext: any
  choice: Choice
  targets: { id: string; label: string }[]
  canMoveDown: boolean
}

const emit = defineEmits<{
  update: [Choice]
  move: [number]
  duplicate: []
  remove: []
  insert: []
}>()
const props = defineProps<Props>()

const text = computed({
  get: () =>
    props.type === 'latex'
      ? (processLatex(
          props.choice.text ?? '',
          props.variableContext.variables,
          'id'
        ) as string)
      : props.choice.text ?? '',
  set: text =>
    emit('update', {
      ...props.choice,
      text:
        props.type === 'latex'
          ? processLatex(text, props.variableContext.variables, 'name')
          : text
    })
})
const uses = computed({
  get: () => props.choice.count,
  set: value => emit('update', { ...props.choice, count: value || undefined })
})
const targets = computed({
  get: () => props.choice.validTargets,
  set: validTargets => emit('update', { ...props.choice, validTargets })
})

const latexForPreview = computed<string | undefined>(() => {
  if (props.type === 'latex') {
    return props.choice.text ? expressionToBoxedLatex(props.choice.text) : ''
  } else {
    return undefined
  }
})
</script>

<style lang="scss" scoped>
.drag-drop-choice {
  padding: 0 16px;

  &:focus-within {
    background-color: darken($extra-light-teal, 10%);
  }
}

.drag-drop-choice__header {
  display: flex;
}

.drag-drop-choice__label {
  flex: 1;
  text-align: left;
}

.drag-drop-choice__actions {
  display: flex;
}

.drag-drop-choice__content {
  display: grid;
  grid:
    'text uses' auto
    'text targets' 1fr / 1fr 360px;
  gap: 8px 16px;
  padding: 0 0 16px 0;
  border-bottom: 1px solid $teal;

  @media screen and (max-width: 992px) {
    grid:
      'text uses' auto
      'text targets' 1fr / 1fr 240px;
  }

  @media screen and (max-width: 700px) {
    grid:
      'text uses' auto
      'text targets' 1fr / 1fr 200px;
  }
}

.drag-drop-choice__text {
  grid-area: text;
}

.drag-drop-choice__latex-preview {
  margin: 0 0 8px 0;
  padding: 0 0 0 16px;
}

.drag-drop-choice__uses {
  grid-area: uses;
}

.drag-drop-choice__uses-input {
  display: inline-block;
  width: 120px;
  margin: 0 0 0 8px;
}

.drag-drop-choice__targets {
  grid-area: targets;
}
</style>
