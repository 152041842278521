<template>
  <div>
    <data-grid
      ref="table"
      :ymap="ymap"
      :readonly="readonly"
      :autosave="autosave"
      :settings="settings"
      :show-grid-actions-menu="showGridActionsMenu"
      :allow-download-csv="allowDownloadCsv"
      :variable-context="variableContext"
      :allow-reset="allowReset"
      :edit-mode="editMode"
      @reset="$emit('reset')"
    />
    <section class="graphs">
      <div class="graph-handler">
        <nav>
          <span v-if="graphArray.length > 1" class="graph-jump-links">
            <a
              v-for="(graph, graphIndex) in graphArray"
              :key="`graph-${graphIndex}`"
              class="graph-jump-link"
              @click="moveToGraph(graphIndex, $event)"
              >Jump To {{ graph.get('title') || `Graph ${graphIndex + 1}` }}</a
            >
          </span>
        </nav>
      </div>
      <div class="graphs">
        <editor-content
          v-if="hasGraphInstructions"
          :id="`component-${componentId}-graphInstructions`"
          :text="graphInstructions"
          :variable-context="variableContext"
          :view-as-student="viewAsStudent"
        />
        <div v-if="graphHint" class="hint-content">
          <form-button
            class="hint-toggle__btn"
            v-if="hideGraphHint && !showHint"
            link
            @click="toggleHint"
          >
            {{ graphHintPrompt || 'I need a hint' }}
          </form-button>
          <form-button
            class="hint-toggle__btn"
            v-if="hideGraphHint && showHint"
            link
            @click="toggleHint"
          >
            Hide Hint
          </form-button>
          <div v-show="showHint" class="prompt__container">
            <playback :element="hint" class="playback_btn" />
            <div ref="hint">
              <span class="question__hint-label">Hint:</span>

              <editor-content
                class="question__hint-body"
                :text="graphHint"
                :variable-context="variableContext"
                :view-as-student="viewAsStudent"
              />
            </div>
          </div>
        </div>
        <div
          v-for="(graph, graphIndex) in graphArray"
          :key="graphIndex"
          :class="['graph-container']"
        >
          <data-graph
            :ref="el => (graphRefs[graphIndex] = el)"
            :graph-index="graphIndex"
            :can-delete="graphArray.length > 1"
            :ymap="ymap"
            :readonly="readonly"
            :settings="settings"
            :variable-context="variableContext"
            @collapsedToggled="graphCollapseToggle($event)"
            @deleteGraph="deleteGraph(graphIndex)"
            @resetGraph="resetGraph(graphIndex)"
          >
            <template v-slot:back-to-table>
              <form-button class="back-to-table-btn" link @click="moveToTable">
                Go Back to the Table
              </form-button>
            </template>
          </data-graph>
        </div>
      </div>
      <footer class="graph-footer">
        <form-button
          :disabled="!canAddGraphs"
          class="add-graph"
          @click.prevent="addGraph"
          >Add Another Graph</form-button
        >
      </footer>
    </section>
  </div>
</template>

<script>
import * as Y from 'yjs'
import DataGrid from 'src/shared/components/grid-graph/DataGrid'
import DataGraph from 'src/shared/components/grid-graph/DataGraph'

import {
  defaultGraphValue,
  graphDefinitionToYMap
} from 'src/shared/components/grid-graph/utilities'
import { hasContent } from 'src/shared/components/editor/utils.js'
import { ref } from 'vue'
export default {
  name: 'DataGridWithGraph',
  components: {
    DataGrid,
    DataGraph
  },
  data() {
    return {
      graphArray: null,
      columns: null
    }
  },
  emits: ['reset'],
  props: {
    ymap: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    },
    autosave: {
      type: Boolean,
      default: false
    },
    componentId: {
      type: String
    },
    showGridActionsMenu: {
      type: Boolean,
      default: true
    },
    allowDownloadCsv: {
      type: Boolean,
      default: true
    },
    allowReset: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      required: true
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    },
    graphInstructions: {
      type: Object,
      default: undefined
    },
    graphHint: {
      type: String,
      default: undefined
    },
    graphHintPrompt: {
      type: String,
      default: undefined
    },
    hideGraphHint: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const graphRefs = []
    const hint = ref(null)
    const showHint = ref(!props.hideGraphHint)
    return {
      graphRefs,
      hint,
      showHint
    }
  },
  computed: {
    tableId() {
      return `table-${this.componentId}`
    },
    canAddGraphs() {
      return this.graphArray.length < 5
    },
    hasGraphInstructions() {
      return this.graphInstructions && hasContent(this.graphInstructions)
    }
  },
  methods: {
    addGraph() {
      this.ymap.doc.transact(() => {
        const newGraph = new Y.Map()
        graphDefinitionToYMap(newGraph, { ...defaultGraphValue() })
        const graphs = this.ymap.get('graphs')
        graphs.push([newGraph])
      })

      this.$nextTick(() => {
        this.moveToGraph(this.graphArray.length - 1)
      })
    },
    deleteGraph(graphIndex) {
      this.ymap.get('graphs').delete(graphIndex, 1)
    },
    resetGraph(graphIndex) {
      this.ymap.doc.transact(() => {
        const newGraph = new Y.Map()
        graphDefinitionToYMap(newGraph, { ...defaultGraphValue() })
        this.ymap.get('graphs').insert(graphIndex, [newGraph])
        this.ymap.get('graphs').delete(graphIndex + 1, 1)
      })
    },
    moveToGraph(index, e) {
      const graph = this.graphRefs[index].$el
      if (graph && typeof graph.scrollIntoView !== 'undefined') {
        // only prevent default jump to element ID if the graph exists and the native scrollIntoView method is defined
        e?.preventDefault()
        graph.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    },
    moveToTable() {
      const table = this.$refs.table.$el
      if (table && typeof table.scrollIntoView !== 'undefined') {
        table.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      } else if (table && table.nextElementSibling) {
        table.nextElementSibling.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    },
    graphCollapseToggle(data) {
      const { collapsed, index } = data
      const container = this.graphRefs[index].$el.parentElement
      container.classList[collapsed ? 'add' : 'remove']('collapsed')
    },
    toggleHint() {
      this.showHint = !this.showHint
    }
  },
  watch: {
    ymap: {
      immediate: true,
      handler() {
        const ymap = this.ymap
        const onChange = () => {
          this.graphArray = ymap.get('graphs').toArray()
          this.columns = ymap.get('columns')
        }
        ymap.get('graphs').observeDeep(onChange)
        onChange()
        this.unsubscribe = () => ymap.get('graphs').unobserveDeep(onChange)
      }
    }
  },
  beforeUnmount() {
    this.unsubscribe?.()
  }
}
</script>

<style lang="scss" scoped>
section.graphs {
  min-width: 100%;
}
.graph-handler {
  margin-top: 4rem;
}
.graph-jump-link {
  margin-right: 2rem;
}
.graph-container {
  margin: 4rem 0;
  border: solid 1px #eee;
  border-radius: 7px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.05);
  background: #fff;
  &.collapsed {
    .back-to-table-btn {
      visibility: hidden;
      opacity: 0;
    }
  }
}
.add-graph {
  &:before {
    content: '\002B';
    margin-right: 0.5rem;
  }
}
.back-to-table-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  &:after {
    content: '\2191';
    margin-left: 0.75rem;
    font-size: 1.6rem;
  }
}
.graphs {
  margin: 4rem 0;
}
.graph-footer {
  margin-bottom: 4rem;
}
.hint-content {
  padding-bottom: 10px;
  width: 100%;
  clear: both;
}

.hint-toggle__btn {
  display: inline-block;
}
.playback_btn {
  margin: 2px 8px 8px 0;
  flex-shrink: 0;
}
.prompt__container {
  display: flex;
  align-items: flex-start;
}
.question__hint-label {
  color: $pink;
  font-weight: bold;
  padding: 0;
  margin: 0 8px 0 0;
  float: left;
}
</style>
