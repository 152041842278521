import Client, { ApiRequestError } from './client'
import { UploadOptionsRequest } from '@pi/api-types'

export default class Assets {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async upload(
    request: UploadOptionsRequest,
    options: { signal?: AbortSignal } = {}
  ): Promise<string | undefined> {
    const fileName = request.name || request.file.name
    const response = await this.client.post<any>({
      path: `api/users/assets/${request.type}`,
      body: JSON.stringify({ fileName: fileName })
    })

    const fields = response.body.fields
    const uploadFormData = new FormData()
    for (const k of Object.getOwnPropertyNames(fields)) {
      uploadFormData.append(k, fields[k])
    }
    uploadFormData.append('Content-Type', request.file.type)
    uploadFormData.append('file', request.file)

    const awsRequest = new Request(response.body.url, {
      method: 'POST',
      body: uploadFormData,
      signal: options.signal
    })

    const awsResponse = await fetch(awsRequest)

    if (awsResponse.status === 204) {
      const regex = /.+\/users\/([^/]+)\//

      const matches = regex.exec(response.body.fields.key)

      if (matches) {
        const userId = matches[1]
        return `/api/users/${userId}/assets/${fileName}`
      } else {
        throw new Error('Could not create asset URL')
      }
    } else {
      throw new ApiRequestError({
        status: awsResponse.status,
        body: await awsResponse.json(),
        request: awsRequest
      })
    }
  }
}
