<template>
  <view-container>
    <flash />
    <div class="thank-you">
      <div class="thank-you-card">
        <div class="text-box">
          <h1 v-if="purchaseType === 'license'" class="heading">
            Welcome! Just one more step...
          </h1>
          <h1 v-else-if="purchaseType === 'renewal'" class="heading">
            Renewal complete!
          </h1>
          <h1 v-else-if="purchaseType === 'upgrade'" class="heading">
            Upgrade complete!
          </h1>
          <h1 v-else-if="purchaseType === 'added-seats'" class="heading">
            {{ seats }} seats added!
          </h1>
          <h1 v-else class="heading">Thank You!</h1>

          <p v-if="purchaseType === 'access-codes'" class="text">
            Your codes have been emailed to {{ email }}
          </p>
          <p v-else-if="purchaseType === 'license'" class="text">
            <template
              v-if="licenseType === 'trial' || licenseType === 'student'"
            >
              Pivot Interactives accounts are for instructors at accredited
              institutions only. Once we've verified your status as an
              instructor, we will send an email to {{ email }} with instructions
              on how to access your account. This process usually takes less
              than 24 hours.
            </template>
            <template v-else>
              To set up your account, check the email sent to
              {{ email }}
            </template>
          </p>
          <p v-if="purchaseType === 'upgrade'" class="text">
            Your trial license has been upgraded! A confirmation email has been
            sent to {{ email }}
          </p>
          <p
            v-else-if="['renewal', 'added-seats'].includes(purchaseType)"
            class="text"
          >
            A confirmation email has been sent to all license admins.
          </p>
        </div>

        <div
          v-if="['renewal', 'added-seats', 'upgrade'].includes(purchaseType)"
          class="button-row thank-you-cta"
        >
          <form-group class="button-group">
            <button-link alpha :to="{ name: 'teacher_dashboard' }">
              Go to Dashboard
            </button-link>
          </form-group>
        </div>
      </div>

      <div v-if="licenseType === 'student'" class="extra-card">
        <h2 class="heading">Need to purchase course codes?</h2>
        <p>
          Course codes aren’t required -- students can pay directly when they
          enroll for classes. However, some institutions offer course codes in a
          school bookstore
        </p>
        <div class="button-row">
          <form-group class="button-group">
            <button-link alpha :to="{ name: 'access_codes_configuration' }">
              Purchase Codes
            </button-link>
          </form-group>
        </div>
      </div>

      <div v-if="purchaseType === 'access-codes'" class="extra-card">
        <h2 class="heading">
          Need to set up a new license for instructor access?
        </h2>
        <p>
          Course codes are used to access classes created under an active Pivot
          Interactives account. If your institution or department needs an
          account, you can set one up now.
        </p>
        <div class="button-row">
          <form-group class="button-group">
            <button-link
              alpha
              :to="{
                name: 'new_license_session',
                params: { licenseType: 'student' }
              }"
            >
              Start a new license
            </button-link>
          </form-group>
        </div>
      </div>
    </div>
  </view-container>
</template>

<script>
import { getPurchaseData, clearPurchaseData } from 'src/shared/utils/purchases'
import { mapActions, mapGetters } from 'vuex'
import Flash from 'src/shared/components//Flash'

export default {
  name: 'ThankYouView',
  components: { Flash },
  data() {
    return {
      purchaseType: '',
      email: '',
      seats: '',
      licenseType: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    const data = getPurchaseData()
    const license =
      data.configureLicenseForm ??
      data.configureRenewalForm ??
      data.createCodesForm

    if (!data || !data.purchaseType) {
      return next({
        name: 'home'
      })
    }

    return next($vm => {
      $vm.purchaseType = data.purchaseType
      $vm.email = data.educatorContactForm?.contactEmail ?? data.billing?.email
      $vm.licenseType = license?.licenseType
      $vm.seats = data.configureAddedSeatsForm
    })
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['refreshAuth'])
  },
  async mounted() {
    if (this.isAuthenticated) {
      await this.refreshAuth()
    }

    clearPurchaseData()
    await this.$router.replace({
      query: {
        purchaseType: this.purchaseType
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.thank-you-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url(../../../assets/img/paper-airplanes.svg);
  background-repeat: no-repeat;
  background-position: 60px -140px;
  padding: 264px 62px 40px 62px;
  margin: auto;
  margin-bottom: 40px;
  width: 926px;
  height: 505px;
  box-shadow:
    0px 1px 1px rgba(61, 44, 96, 0.14),
    0px 2px 1px rgba(61, 44, 96, 0.12),
    0px 1px 3px rgba(61, 44, 96, 0.2);
  border-radius: 6px;
  .heading {
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0;
  }
}
.extra-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  margin-bottom: 40px;
  width: 926px;
  padding: 48px 64px;
  box-shadow:
    0px 1px 1px rgba(61, 44, 96, 0.14),
    0px 2px 1px rgba(61, 44, 96, 0.12),
    0px 1px 3px rgba(61, 44, 96, 0.2);
  border-radius: 6px;
  .heading {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    margin-bottom: 32px;
  }
  p {
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.008em;
    text-align: left;
    margin-bottom: 32px;
  }
  .button-row {
    display: flex;
    justify-content: flex-end;
    align-self: stretch;
  }
}
.button-group {
  flex-direction: row-reverse;
}
.text-box {
  text-align: center;
  width: 802px;
  .text {
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.008em;
    text-align: center;
    width: 496px;
    margin: auto;
  }
  .resend-button {
    border: none;
    background: none;
    margin-top: 47px;
  }
}

.thank-you-cta {
  margin-top: 16px;
  width: 100%;

  .button-group {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
