export default function generateInstanceSettings(
  playbackFrameRate,
  recordedFrameRate,
  src,
  calibration
) {
  return {
    calibration: {
      editable: true,
      color: 'white',
      ...calibration,
      left: calibration && (calibration.left || calibration.leftPoint),
      right: calibration && (calibration.right || calibration.rightPoint)
    },
    stopwatch: true,
    videos: [
      {
        playbackFrameRate,
        recordedFrameRate,
        src: !src || src.startsWith('data') ? src : `//${PI_API_HOST}${src}`
      }
    ],
    tools: [
      {
        type: 'ruler',
        limit: 1,
        x: 0.5,
        y: 0.5,
        width: 0.95,
        height: 0.2
      },
      {
        type: 'grid',
        limit: 1,
        x: 0.5,
        y: 0.5,
        width: 0.5,
        height: 0.5
      },
      {
        type: 'protractor',
        limit: 1,
        width: 0.2,
        x: 0.5,
        y: 0.5,
        r: 0.2,
        p: Math.PI * 2
      },
      {
        type: 'color',
        r: 10,
        x: 0.5,
        y: 0.5,
        label: 'Iris RGB',
        resizable: true
      }
    ]
  }
}
