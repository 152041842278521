<template>
  <div class="container">
    <div class="sizer">
      <div v-if="!launched" class="launcher">
        <div>
          <form-button type="button" @click="launch"
            >Launch Simulation</form-button
          >
        </div>
      </div>

      <div v-if="loading">
        <div class="loader" />
        <span class="sr-only">Loading Simulation</span>
      </div>

      <div v-if="error">
        <h4>Error loading PhET-iO simulation</h4>
      </div>

      <iframe ref="iframe" src="about:blank" allowfullscreen></iframe>
    </div>
    <form-button v-if="launched" type="button" @click="relaunch"
      >Relaunch Simulation</form-button
    >
  </div>
</template>

<script>
import { evaluateExpression } from '@pi/shared/variables'
import * as Sentry from '@sentry/vue'

export default {
  name: 'PhetIoSimulation',
  props: {
    config: {
      type: String,
      required: true
    },
    variables: {
      type: Array,
      required: false
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      launched: false,
      loading: false,
      error: false,
      parsedConfig: {}
    }
  },
  computed: {
    simulationValues() {
      const values = this.parsedConfig.values || []

      // If any errors at all occur while trying to evaluate
      // the simulation values, just return `null` which will
      // display the error screen unless in edit mode. This is instead
      // of trying to gracefully show the simulation using the defaults
      // because that may not be accurate in the context of the activity.
      try {
        const evaluatedValues = values.reduce((acc, { key, value }) => {
          // If value begins with `{`, ensure it is valid JSON
          // and evaluate any nested mathjs expressions. Nested mathjs
          // expressions are notated with `mathjs(expression)`.
          if (value.startsWith('{')) {
            const parsedValue = JSON.parse(value, (_, val) => {
              if (typeof val === 'string' && val.startsWith('mathjs(')) {
                const expression = val.substring(7, val.length - 1)
                const evaluated = evaluateExpression(expression, this.variables)
                if (evaluated !== null) {
                  return evaluated
                } else {
                  throw new Error('mathjs')
                }
              }

              return val
            })

            acc[key] = parsedValue
          } else {
            // Assume value is just a mathjs expression
            const evaluated = evaluateExpression(value, this.variables)
            if (evaluated !== null) {
              acc[key] = evaluated
            } else {
              throw new Error('mathjs')
            }
          }

          return acc
        }, {})

        return evaluatedValues
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            config: values
          }
        })
        return null
      }
    }
  },
  methods: {
    launch() {
      try {
        this.launched = true
        this.loading = true

        if (!this.editMode && this.simulationValues === null) {
          this.error = true

          return
        }

        // Listen for iframe load to stop showing loading indicator
        // and set any custom values
        this.$refs.iframe.onload = () => {
          this.onIFrameLoaded()
        }

        // Create a blob from the decoded base64 wrapper HTML
        const blob = new Blob([atob(this.parsedConfig.wrapper)], {
          type: 'text/html'
        })

        // Set the source of the iframe to the wrapper HTML
        this.$refs.iframe.src = URL.createObjectURL(blob)
      } catch (error) {
        Sentry.captureException(error)
        this.error = true
      }
    },
    relaunch() {
      this.reset()
      this.launch()
    },
    onIFrameLoaded() {
      this.loading = false

      try {
        const phetioClient = this.$refs.iframe.contentWindow.phetioClient

        if (
          this.simulationValues !== null &&
          Object.values(this.simulationValues).length > 0
        ) {
          const init = () => {
            phetioClient.invokeSequence(
              Object.keys(this.simulationValues).map(key => ({
                phetioID: key,
                method: 'setValue',
                args: [this.simulationValues[key]]
              }))
            )
          }

          try {
            init()
          } catch (e) {}

          // Add listener for when sim has been initialized
          // and set all of our custom values.
          phetioClient.addSimInitializedListener(init)
        }
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            config: this.simulationValues
          }
        })
        this.error = true
      }
    },
    reset() {
      this.launched = false
      this.loading = false
      this.error = false
      this.parsedConfig = {}

      if (this.$refs.iframe) {
        this.$refs.iframe.onload = null
        this.$refs.iframe.src = 'about:blank'
      }

      try {
        this.parsedConfig = JSON.parse(this.config)
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            config: this.config
          }
        })
        this.error = true
      }
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

<style scoped>
div.container {
  width: 100%;
}

div.container > button {
  display: block;
  margin: 10px auto 0 auto;
}

div.sizer {
  background: white;
  color: black;
  position: relative;
  width: 100%;
  height: 0px;
  padding-top: 56.25%;
  overflow: hidden;
}

div.sizer > div,
div.sizer iframe {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: none;
  background: black;
  color: white;
  z-index: 1;
}

div.sizer > div {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

div.sizer div.launcher {
  padding: 3%;
  background-color: white;
  color: black;
}

div.sizer div.launcher img {
  width: 67.7312775%;
  height: auto;
  transform: translateX(-3.4146341%);
}

div.sizer div.launcher > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  background-image: url('https://assets.pivotinteractives.com/public/pivot%2Bphet-launcher.png');
  background-size: cover;
}

div.sizer div.launcher > div button {
  position: relative;
  top: 66%;
}

.loader {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid white;
  animation: load 1.1s infinite linear;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
