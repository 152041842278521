<template>
  <modal style="width: 600px">
    <modal-header>Need Help?</modal-header>
    <modal-body>
      <p>
        Your license for {{ name }} is rostered with {{ connectionType }}, but
        we cannot locate a teacher on the rostering service with the email
        address {{ email }}.
      </p>
      <p>Possible reasons for this might include:</p>
      <ul>
        <li>Your classes have not yet been added to the rostering service</li>
        <li>
          The email associated with your rostering account on
          {{ connectionType }} is different from {{ email }}
        </li>
      </ul>
      <hr />
      <p>
        Please open a chat with Pivot Interactives Support so that we can
        assist.
      </p>
      <p>
        <form-button link @click="openChat">Open Chat Support</form-button>
      </p></modal-body
    >
    <modal-actions><modal-button-ok /></modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonOk
} from 'src/shared/components/modals/components'
export default {
  name: 'RosteringHelpModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonOk
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    connectionType: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    }
  },
  methods: {
    openChat() {
      if (window.Intercom) {
        window.Intercom(
          'showNewMessage',
          `I'm unable to roster my classes from ${this.name}.`
        )
      }
    }
  }
}
</script>

<style scoped>
p.prompt {
  font-weight: bold;
  margin-bottom: 0;
}
</style>
