<template>
  <select-library-modal
    ref="selectLibraryModal"
    title="Select a destination"
    :submit-label="bulkMode ? 'Copy Activities' : 'Copy Activity'"
    hide-archived
    @submit="submit"
  />
</template>

<script>
import client from 'src/shared/api-client.ts'
import SelectLibraryModal from './SelectLibraryModal'
export default {
  components: {
    SelectLibraryModal
  },
  props: {
    activity: {
      type: Object,
      required: false
    },
    activities: {
      type: Object,
      required: false
    }
  },
  computed: {
    bulkMode() {
      return !this.activity && this.activities && this.activities.length > 0
    }
  },
  methods: {
    async submit({ libraryId, done }) {
      let newActivity
      if (this.bulkMode) {
        try {
          newActivity = await client.activities.copyBulk({
            activities: this.activities,
            library: libraryId
          })
        } catch (error) {
          done(false)
          throw error
        }
      } else {
        try {
          newActivity = await client.activities.copy({
            activityId: this.activity.id,
            destinationLibraryId: libraryId
          })
        } catch (error) {
          done(false)
          throw error
        }
      }
      const library = await client.libraries.get(libraryId).catch(() => null)
      const successMessage = this.bulkMode
        ? library
          ? `Activities copied to library ${library.name}.`
          : 'Activities copied'
        : library
          ? `Activity ${this.activity.name} copied to library ${library.name}. Click this banner to begin editing.`
          : `Activity ${this.activity.name} copied.`

      if (newActivity) {
        this.$refs.selectLibraryModal.$success(successMessage, {
          route: { name: 'activity', params: { id: newActivity.activityId } }
        })
      } else {
        this.$refs.selectLibraryModal.$success(successMessage)
      }
      done({ libraryId, activityId: newActivity.activityId })
    }
  }
}
</script>
