import { onBeforeUpdate, customRef, Ref } from 'vue'

export type SetRef = (index: number, el: any) => void
// Array refs don't need to be reactive
// but we do need to reset them when they render without losing the reference.
function simpleRef<T>(value: T) {
  return customRef(() => ({
    get() {
      return value
    },
    set(newValue) {
      value = newValue
    }
  }))
}

export default function useArrayRef<T>(): [Ref<T[]>, SetRef] {
  const refs = simpleRef<T[]>([])
  const setRef: SetRef = (index: number, el: T) => {
    if (el) {
      refs.value[index] = el
    }
  }

  onBeforeUpdate(() => {
    refs.value = []
  })

  return [refs, setRef]
}
