<template>
  <modal @submit="submit">
    <modal-header>External Rostering</modal-header>
    <modal-body>
      <div>
        Configure rostering from an external roster service to sync the classes
        and students from that service into Pivot Interactives for all the
        teachers of this license.
      </div>
      <form-group>
        <form-label for="rostering-connection-type"
          >External Roster Service</form-label
        >
        <select-field
          id="rostering-connection-type"
          v-model="connectionType"
          :disabled="update"
          rules="required"
          label="external roster service"
        >
          <option value="ClassLink">ClassLink</option>
          <option value="Clever">Clever</option>
        </select-field>
      </form-group>
      <form-group v-if="showApplicationId">
        <form-label for="rostering-connection-application-id">
          Application ID
        </form-label>
        <text-input
          id="rostering-connection-application-id"
          v-model="applicationId"
          rules="required"
          label="application id"
        />
      </form-group>
      <form-group v-if="showDistrictId">
        <form-label for="rostering-connection-district-id">
          District ID
        </form-label>
        <text-input
          id="rostering-connection-district-id"
          v-model="districtId"
          rules="required"
          label="district id"
        />
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Save</modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonCancel,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'

export default {
  name: 'ConfirmModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  props: {
    update: {
      type: Boolean,
      default: false
    },
    initialConnectionType: {
      type: String,
      default: 'ClassLink'
    },
    initialApplicationId: {
      type: String,
      default: ''
    },
    initialDistrictId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      connectionType: this.initialConnectionType,
      applicationId: this.initialApplicationId,
      districtId: this.initialDistrictId
    }
  },
  computed: {
    showApplicationId() {
      return ['ClassLink'].includes(this.connectionType)
    },
    showDistrictId() {
      return ['Clever'].includes(this.connectionType)
    }
  },
  methods: {
    async submit({ done }) {
      const { connectionType, applicationId, districtId } = this
      done({ connectionType, applicationId, districtId })
    }
  }
}
</script>
