<template>
  <div class="modal-header">
    <h1 id="modal-title" class="modal-header__title"><slot /></h1>
    <div class="modal-header__secondary">
      <slot name="secondary" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalHeader',
  inject: ['$_modal']
}
</script>

<style lang="scss">
.modal-header {
  display: flex;
  margin: 0 0 8px 0;
}

.modal-header__title {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.modal-header__secondary {
  flex-grow: 1;
}
</style>
