<template>
  <loading-container :loading="isLoading" class="instructors-list">
    <div class="header">
      <div class="header-right">
        <label for="bulkActionSelect">Bulk Actions</label>
        <select-field
          id="bulkActionSelect"
          v-model="bulkActionSelect"
          class="header-right--select"
        >
          <option value="">Select Action</option>
          <option value="approve">Approve</option>
          <option value="reject">Reject</option>
        </select-field>
        <form-button secondary @click="doBulkActions(bulkActionSelect)"
          >Apply</form-button
        >
      </div>
    </div>
    <paginated-list
      v-bind="pagination"
      data-testid="instructors-list"
      @loadpage="loadPage"
    >
      <list-column title="bulkActions">
        <template v-slot:title>
          <checkbox
            v-model="allInstructorsSelected"
            :indeterminate="someInstructorsSelected"
          />
        </template>
        <template v-slot="item">
          <checkbox v-model="selectedInstructors" :value="item.id" />
        </template>
      </list-column>
      <list-column
        v-slot="{ firstName, lastName }"
        title="Name"
        property="lastName"
      >
        <span v-if="lastName || firstName">
          {{ `${lastName}, ${firstName}` }}
        </span>
      </list-column>
      <list-column title="Email" property="email" />
      <list-column v-slot="item" title="License">
        <router-link
          v-if="item.siteLicense"
          :to="{
            name: 'admin_manage_license',
            params: {
              id: item.siteLicense.id
            }
          }"
        >
          {{ item.siteLicense.name }}
        </router-link>
        <div v-else-if="item.pendingInvites">
          <div v-for="(invitation, index) in item.pendingInvites" :key="index">
            <router-link
              :to="{
                name: 'admin_manage_license',
                params: {
                  id: invitation.id
                }
              }"
            >
              {{ invitation.name }} (Pending)
            </router-link>
            <span v-if="index < item.pendingInvites.length - 1">, </span>
          </div>
        </div>
      </list-column>

      <list-column title="License Role" v-slot="item">
        {{ item.siteLicense?.role ?? item.invitedLicenseRole ?? '' }}
      </list-column>
      <list-column title="Status" property="vetStatus" />
      <list-column v-slot="item" title="Actions">
        <form-button link @click="researchInstructor(item)">
          Research
        </form-button>
        &nbsp;|&nbsp;
        <template v-if="item.vetStatus === 'unvetted'">
          <form-button link @click="updateStatus(item.id, true)">
            Approve
          </form-button>
          &nbsp;|&nbsp;
          <form-button link @click="updateStatus(item.id, false)">
            Deny
          </form-button>
        </template>
        <template v-if="item.vetStatus === 'prevetted'">
          <form-button link @click="updateStatus(item.id, true)">
            Confirm
          </form-button>
          &nbsp;|&nbsp;
          <form-button link @click="updateStatus(item.id, false)">
            Revoke
          </form-button>
        </template>
        <template v-if="item.vetStatus === 'rejected'">
          <form-button link @click="updateStatus(item.id, true)">
            Approve
          </form-button>
        </template>
        <template v-if="item.vetStatus === 'vetted'">
          <form-button link @click="updateStatus(item.id, false)">
            Revoke
          </form-button>
        </template>
      </list-column>
    </paginated-list>
  </loading-container>
</template>

<script>
import client from 'src/shared/api-client.ts'

export default {
  name: 'InstructorsList',
  inject: ['browseInstructors', '$modal'],
  data() {
    return {
      selectedInstructors: [],
      bulkActionSelect: ''
    }
  },
  computed: {
    sort() {
      return this.browseInstructors.query.sort
        ? {
            [this.browseInstructors.query.sort]:
              this.browseInstructors.query.dir
          }
        : {}
    },
    empty() {
      return this.instructors.length === 0
    },
    instructors() {
      return this.browseInstructors.instructors
    },
    pagination() {
      return {
        page: this.browseInstructors.query.page,
        count: this.browseInstructors.instructorCount,
        limit: this.browseInstructors.instructorLimit,
        items: this.browseInstructors.instructors
      }
    },
    isLoading() {
      return this.browseInstructors.isLoadingInstructors
    },
    allInstructorsSelected: {
      get() {
        return (
          this.selectedInstructors.length ===
          this.browseInstructors.instructors.length
        )
      },
      set(isSelected) {
        if (isSelected) {
          this.selectedInstructors = this.browseInstructors.instructors.map(
            instructor => instructor.id
          )
        } else {
          this.selectedInstructors = []
        }
      }
    },
    someInstructorsSelected() {
      return this.selectedInstructors.length > 0 && !this.allInstructorsSelected
    }
  },
  methods: {
    loadPage({ page }) {
      this.browseInstructors.updateQuery({ page: page.toString() })
    },
    changeSort(property, dir) {
      if (dir) {
        this.browseInstructors.updateQuery({
          sort: property,
          dir
        })
      } else {
        this.browseInstructors.updateQuery({
          sort: undefined,
          dir: undefined
        })
      }
    },
    async doBulkActions(action) {
      if (action === 'approve') {
        try {
          await client.users.vetInstructors({
            users: this.selectedInstructors,
            approved: true
          })
          this.selectedInstructors.forEach(id => {
            this.browseInstructors.updateInstructor(id, true)
          })
          this.$success(
            `${this.selectedInstructors.length} instructors have been approved`
          )
        } catch (e) {
          this.$error(
            `There was an error approving ${this.selectedInstructors.length} instructors ${e.message}`
          )
        }
      } else if (action === 'reject') {
        try {
          await client.users.vetInstructors({
            users: this.selectedInstructors,
            approved: false
          })
          this.selectedInstructors.forEach(id => {
            this.browseInstructors.updateInstructor(id, false)
          })

          this.$success(
            `${this.selectedInstructors.length} instructors have been rejected`
          )
        } catch (e) {
          this.$error(
            `There was an error rejecting ${this.selectedInstructors.length} instructors ${e.message}`
          )
        }
      }

      this.selectedInstructors = []
    },

    async updateStatus(id, isApproved) {
      await client.users.vetInstructors({ users: [id], approved: isApproved })
      this.$success('Instructor vetting status updated successfully')
      this.browseInstructors.updateInstructor(id, isApproved)
    },
    researchInstructor(instructor) {
      const institutionName =
        instructor.siteLicense &&
        instructor.siteLicense.institutionName &&
        instructor.siteLicense.institutionName !== 'undefined'
          ? `${instructor.siteLicense?.institutionName} `
          : ''
      const q = encodeURI(
        `${institutionName}${instructor.firstName} ${instructor.lastName} ${instructor.email}`
      )
      const link = `https://www.google.com/search?q=${q}`
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" sco`ped>
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-right {
  width: 350px;
  flex-shrink: 0;
  margin-left: 16px;
  label {
    font-weight: bold;
  }
  &--select {
    margin: 0 10px;
    display: inline-flex;
    width: 180px;
  }
}

.instructors-list {
  grid-area: results;

  &__list {
    list-style: none;
    display: grid;
    gap: 32px;
    grid-auto-flow: row;
    padding: 0;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }

  &__empty {
    margin-top: 1em;
    text-align: center;

    & > button {
      margin: 0 8px;
    }
  }
}
</style>
