import pasco from './pasco'
import BluetoothDevice, { BluetoothDataStream } from './BluetoothDevice'

class PascoBluetoothDataStream extends BluetoothDataStream {
  constructor(pascoSensor, pascoMeasurement) {
    super(
      `${pascoSensor.id} - ${pascoMeasurement.ID}`,
      pascoMeasurement.NameTag,
      pascoMeasurement.UnitType
    )

    this._pascoSensor = pascoSensor
    this._pascoMeasurement = pascoMeasurement

    this._pascoSensor.on('value-changed', this._onSensorValueChanged.bind(this))
  }

  get measurementPeriod() {
    return this._measurementPeriod
  }

  set measurementPeriod(measurementPeriod) {
    const { minPeriod, maxPeriod } = { minPeriod: 0, maxPeriod: 1000000 }
    this._measurementPeriod = Math.min(
      Math.max(measurementPeriod, minPeriod),
      maxPeriod
    )
  }

  _onSensorValueChanged() {
    if (this._buffering) {
      this._sampleBuffer.push(this._pascoMeasurement.value - this.tareValue)
    } else {
      this.liveReading = this._pascoMeasurement.value - this.tareValue
    }
  }
}

class PascoBluetoothDevice extends BluetoothDevice {
  get name() {
    return this._nativeDevice?.name
  }

  async init() {
    this._pascoDevice = await pasco.createDevice(this._nativeDevice, {
      startMeasurements: false
    })

    // One difference from Vernier here is that each sensor of a
    // PASCO device can have multiple measurements. We're adding
    // each measurement to the list of dataStreams here instead
    // of each device. Passing the sensor to the DataStream constructor
    // as that is the object that emits `value-change` event.
    this._pascoDevice.sensors.forEach(sensor => {
      Object.values(sensor.measurements).forEach(measurement => {
        if (measurement.Visible === '1') {
          this.dataStreams[`${sensor.id} - ${measurement.ID}`] =
            new PascoBluetoothDataStream(sensor, measurement)
        }
      })
    })

    await this.reset()
  }

  async reset() {
    try {
      await super.reset()

      await this._pascoDevice.start(
        BluetoothDevice.IDLE_MEASUREMENT_PERIOD * 1000
      )
    } catch (error) {
      this.error = true
      this.onError(error, this)
    }
  }

  async startCollectingSamples(measurementPeriod) {
    try {
      await super.startCollectingSamples(measurementPeriod)

      await this._pascoDevice.start(measurementPeriod * 1000)
    } catch (error) {
      this.error = true
      this.onError(error, this)
    }
  }

  async stopCollectingSamples() {
    try {
      await this._pascoDevice.stop()
    } catch (error) {
      this.error = true
      this.onError(error, this)
    }
  }

  async disconnect() {
    await super.disconnect()
    await this._pascoDevice.close()
  }
}

PascoBluetoothDevice.namePrefixes = pasco.namePrefixes
PascoBluetoothDevice.optionalServices = pasco.optionalServices

export default PascoBluetoothDevice
