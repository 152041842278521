<template>
  <view-container>
    <view-header>
      <view-title>Assignment</view-title>
    </view-header>

    <div class="form-two-column">
      <form-group>
        <form-label for="name">Activity Name</form-label>
        <static-value id="name">
          {{ (assignment.activity || {}).name }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="startDate">Start Date</form-label>
        <static-value id="startDate">
          {{ $format.date(assignment.startDate, 'MM/dd/yyyy') }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="endDate">End Date</form-label>
        <static-value id="endDate">
          {{ $format.date(assignment.endDate, 'MM/dd/yyyy') }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="responses"> Responses </form-label>
        <list
          v-if="(responses || []).length > 0"
          id="responses"
          :items="responses"
        >
          <list-column
            v-slot="{ groupName }"
            title="Group Name"
            v-if="responses[0].groupName"
          >
            {{ groupName }}
          </list-column>
          <list-column v-slot="{ owner }" title="Student Name" v-else>
            {{ owner.lastName }}, {{ owner.firstName }}
          </list-column>
          <list-column v-slot="{ studentUpdatedAt }" title="Student Updated At">
            {{ $format.date(studentUpdatedAt, 'MM/dd/yyyy') }}
          </list-column>
          <list-column v-slot="{ totalScore }" title="Score">
            {{ formatPercent(grade(totalScore)) }}
          </list-column>
          <list-column v-slot="{ id }" title="Actions">
            <router-link
              :to="{
                name: 'grade_response',
                params: { assignmentId: assignment.id, responseId: id }
              }"
              >View</router-link
            >
          </list-column>
        </list>
        <static-value v-else id="responses">
          This assignment has no responses yet.
        </static-value>
      </form-group>
    </div>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'

export default {
  name: 'AdminAssignmentView',
  props: ['id'],
  async created() {
    await this.getAssignment()
  },
  data() {
    return {
      assignment: {},
      responses: []
    }
  },
  methods: {
    grade(score) {
      const total = this.assignment.activity.totalPointValue
      return total > 0 ? score / total : 0
    },
    async getAssignment() {
      const [assignmentResponse, responses] = await Promise.all([
        client.assignments.get({ assignmentId: this.id }),
        client.assignments.getAllResponses({ assignmentId: this.id })
      ])
      this.assignment = assignmentResponse
      this.responses = responses.page
    },
    formatPercent(value) {
      if (typeof value !== 'number') return value
      return `${(value * 100).toPrecision(4)} %`
    }
  },
  watch: {
    async id() {
      await this.getAssignment()
    }
  }
}
</script>

<style scoped>
.form-label {
  width: 140px;
}
</style>
