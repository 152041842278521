<template>
  <loading-container :loading="isLoading">
    <dashboard-panel v-slot="{ collapsed }">
      <div class="panel-header">
        <dashboard-collapse-toggle
          >Onboarding Summary</dashboard-collapse-toggle
        >
        <template v-if="!collapsed">
          <form-button
            class="dashboard-invitations-button"
            @click="emit('add-members')"
            >Invite Teachers</form-button
          >
          <div class="header-right">
            <button-dropdown tertiary right>
              <template #button> Bulk Actions <span class="caret" /></template>
              <dropdown-action @click="doBulkActions('resend')"
                >Resend Invitations</dropdown-action
              >
              <dropdown-action @click="doBulkActions('help')"
                >Send Help Link</dropdown-action
              >
            </button-dropdown>
          </div>
        </template>
      </div>
      <collapse-content>
        <div class="onboarding">
          <list :items="sortedItems">
            <list-column property="id">
              <template #title>
                <checkbox
                  :modelValue="allSelected"
                  class="bulk-select-checkbox"
                  @update:modelValue="isSelected => selectAllItems(isSelected)"
                />
              </template>
              <template v-slot="{ id }">
                <checkbox
                  v-model="selectedBulkItems"
                  :value="id"
                  class="bulk-select-checkbox"
                />
              </template>
            </list-column>
            <sortable-list-column
              title="Teacher"
              :sort="sort['name']"
              @change="sort => changeSort('name', sort)"
              v-slot="{ name, id }"
            >
              <router-link
                v-if="id"
                :to="{
                  name: 'manage_instructor',
                  params: { instructorId: id }
                }"
              >
                {{ name }}
              </router-link>
              <template v-if="!id">{{ name }}</template>
            </sortable-list-column>
            <sortable-list-column
              title="Onboarding Progress"
              :sort="sort['statusMsg']"
              v-slot="{ statusMsg, statusClass, statusPct, isComplete }"
              @change="sort => changeSort('statusMsg', sort)"
            >
              <div
                :class="
                  isComplete ? 'status-complete status-label' : 'status-label'
                "
              >
                <span
                  :class="statusClass"
                  v-if="!isComplete"
                  :data-value="statusPct"
                  aria-hidden="true"
                />
                <icon v-else icon="check" />
                {{ statusMsg }}
              </div>
            </sortable-list-column>
            <sortable-list-column
              title="Last Onboarding Date"
              property="lastOnboardingDate"
              :sort="sort['lastOnboardingDate']"
              @change="sort => changeSort('lastOnboardingDate', sort)"
              v-slot="{ lastOnboardingDate }"
            >
              {{ $format.date(lastOnboardingDate, 'MM/dd/yyyy hh:mm a') }}
            </sortable-list-column>
            <list-column
              title="Actions"
              v-slot="{ onboardingStatus, email, isComplete, id }"
            >
              <form-button
                v-if="onboardingStatus === 'invited'"
                link
                @click="() => resendLicenseInvite(email)"
              >
                Resend Invitation
              </form-button>
              <form-button
                v-if="!isComplete && onboardingStatus !== 'invited'"
                link
                @click="() => sendHelpEmail(id)"
              >
                Send Help Link
              </form-button>
            </list-column>
          </list>
        </div>
      </collapse-content>
    </dashboard-panel>
  </loading-container>
</template>

<script setup>
import { ref, computed, inject, onBeforeMount, watch } from 'vue'

import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import DashboardPanel from 'src/shared/components//DashboardPanel.vue'
import DashboardCollapseToggle from 'src/shared/components//DashboardCollapseToggle.vue'
import CollapseContent from 'src/shared/components/global/CollapseContent.vue'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client.ts'

const props = defineProps({
  licenseId: {
    type: String,
    required: true
  }
})
const $modal = inject('$modal')
const flash = useFlash()

const emit = defineEmits(['add-members'])

const teachers = ref()
const isLoading = computed(() => !teachers.value)

const getUsageData = async () => {
  if (!props.licenseId) return

  const usage = await client.licenses.getUsage({ id: props.licenseId })

  teachers.value = usage.teachers
}

const selectedBulkItems = ref([])
const selectAllItems = isSelected => {
  if (isSelected) {
    selectedBulkItems.value = sortedItems.value.map(item => item.id)
  } else {
    selectedBulkItems.value = []
  }
}

const canSendInvite = member => {
  return member.onboardingStatus === 'invited'
}
const canSendHelp = member => {
  return member.onboardingStatus !== 'invited' && !member.isComplete
}

const resendLicenseInvite = async (email, showFlash = true) => {
  await client.licenses.inviteMembers({ id: props.licenseId, emails: [email] })
  if (showFlash) flash.success('License Invitation Resent')
}

const sendHelpEmail = async (memberId, showFlash = true) => {
  await client.licenses.sendHelpEmail({ licenseId: props.licenseId, memberId })
  if (showFlash) flash.success('Help Email Sent')
}

const doBulkActions = async (action, force = false) => {
  if (!selectedBulkItems.value.length) {
    flash.error('No teachers selected')
    return
  }
  let sentEmails = 0
  let unsentEmails = []
  if (action === 'help') {
    const completedMembers = selectedBulkItems.value.filter(
      m => !canSendHelp(m)
    )
    if (completedMembers.length) {
      const { status } = await $modal.show(
        ConfirmModal,
        `The following users ${completedMembers
          .map(m => m.name)
          .join(
            ', '
          )} are already fully onboarded, do you wish to resend a help article about assigning activities to them?`
      )
      if (status === 'ok') {
        doBulkActions(action, true)
      }
      return
    }
  }
  selectedBulkItems.value.forEach(id => {
    const teacher = sortedItems.value.find(item => item.id === id)
    if (action === 'resend') {
      if (canSendInvite(teacher)) {
        resendLicenseInvite(teacher.email, false)
        sentEmails++
      } else {
        unsentEmails.push(teacher)
      }
    } else if (action === 'help') {
      if (canSendHelp(teacher) && !force) {
        sendHelpEmail(teacher.id, false)
        sentEmails++
      } else {
        unsentEmails.push(teacher)
      }
    }
  })

  if (action === 'resend' && unsentEmails.length) {
    flash.error(
      `The following users have already accepted their invitations and were not sent another message. ${unsentEmails
        .map(m => m.name)
        .join('; ')}`
    )
  }
  flash.success(
    `${sentEmails} users were ${
      action === 'resend' ? 'resent invitations' : 'sent the help link'
    }`
  )
}

const getStatusFriendlyName = status => {
  const statusMap = {
    default: '4. Onboarding Complete',
    invited: '0. Not Yet Active',
    'accepted-invitation': '1. Accepted Invite',
    'created-class': '2. Created First Class',
    'enrolled-students': '3. Enrolled Students',
    'assigned-activity': '4. Onboarding Complete',
    reset: '1. Reset'
  }
  const statusPct = [0, 0, 25, 50, 75, 100]
  const index = Object.keys(statusMap).indexOf(status)
  const friendlyStatus = statusMap[status] || statusMap.default
  const statusClass = `status-ind status-${index}`

  return {
    onboardingStatus: status,
    statusMsg: friendlyStatus,
    statusClass,
    statusPct: statusPct[index],
    isComplete: index < 1 || index > 4
  }
}
const formatItems = teachers => {
  const formattedItems = teachers.map(teacher => ({
    ...teacher,
    lastOnboardingDate: teacher.lastOnboardingDate || teacher.invitedDate,
    name: teacher.name || teacher.email,
    ...getStatusFriendlyName(teacher.status)
  }))
  return formattedItems
}

const sortItems = (items, sort) => {
  const sortField = Object.keys(sort)[0]
  const sortDir = sort[sortField]

  return items.sort((a, b) => {
    if (!a[sortField] && !b[sortField]) return 0
    const isString =
      typeof a[sortField] === 'string' || typeof b[sortField] === 'string'

    if (sortDir === 'asc') {
      if (!a[sortField]) return -1
      if (!b[sortField]) return 1
      return isString
        ? a[sortField].localeCompare(b[sortField])
        : a[sortField] > b[sortField]
        ? 1
        : -1
    } else {
      if (!a[sortField]) return 1
      if (!b[sortField]) return -1
      return isString
        ? b[sortField].localeCompare(a[sortField])
        : a[sortField] < b[sortField]
        ? 1
        : -1
    }
  })
}
const sort = ref({ name: 'asc' })

const formattedItems = computed(() =>
  teachers.value ? formatItems(teachers.value) : []
)
const sortedItems = computed(() =>
  props.licenseId ? sortItems(formattedItems.value.slice(), sort.value) : []
)
const changeSort = newSort => {
  const dir = sort.value[newSort]
    ? sort.value[newSort] === 'asc'
      ? 'desc'
      : 'asc'
    : 'asc'
  sort.value = { [newSort]: dir }
}

onBeforeMount(async () => {
  await getUsageData()
})
</script>

<style lang="scss" scoped>
.status-label {
  position: relative;
}

.status-complete {
  color: $color-success;
}

.status-ind {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #eee;
  position: relative;
  display: inline-block;
  top: 4px;
  margin-right: 5px;
}

.status-ind::before {
  content: '';
  display: block;
  margin-left: 50%;
  height: 100%;
  border-radius: 0 100% 100% 0 / 50%;
  background-color: inherit;
  transform-origin: left;
}

.status-ind::after {
  content: '';
  position: absolute;
  width: 70%;
  height: 70%;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font: 900 20px/41px Tahoma;
}

.status-2 {
  background-image: linear-gradient(to right, transparent 50%, #e30e62 0);
}

.status-3 {
  background-image: linear-gradient(to right, transparent 50%, #f1af51 0);
}
.status-4 {
  background-image: linear-gradient(to right, transparent 50%, #5a3f85 0);
  &:before {
    background-color: #5a3f85;
  }
}
.status-5 {
  background-image: linear-gradient(to right, transparent 50%, #16b8b7 0);
  &:before {
    background-color: #16b8b7;
  }
}

.status-6 {
  background-image: linear-gradient(to right, transparent 50%, #b7b7b7 0);
  &:before {
    background-color: #b7b7b7;
  }
}

@for $i from 0 through 50 {
  .status-ind[data-value='#{$i}']:before {
    transform: rotate(calc((#{$i}turn/ 100)));
  }
}

@for $i from 51 through 100 {
  .status-ind[data-value='#{$i}']:before {
    transform: rotate(calc(-#{$i}turn/ 100));
  }
}

.panel-header {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.dashboard-invitations-button {
  height: 35px;
  padding: 5px 14px;
}
.header-right {
  width: 120px;
  justify-self: flex-end;
  margin-left: auto;
}
</style>
