import { Mark, mergeAttributes } from '@tiptap/core'

const FileUpload = new Mark({
  name: 'file',
  priority: 1000,
  keepOnSplit: false,
  excludes: 'link',

  addOptions() {
    return {
      openOnClick: true,
      HTMLAttributes: {
        target: '_blank',
        rel: 'noopener nofollow',
        class: null
      }
    }
  },

  addStorage() {
    return {}
  },

  addCommands() {
    return {
      setFile:
        attributes =>
        ({ chain }) => {
          return chain()
            .setMark(this.name, attributes)
            .setMeta('preventAutolink', true)
            .run()
        },

      toggleFile:
        attributes =>
        ({ chain }) => {
          return chain()
            .toggleMark(this.name, attributes, { extendEmptyMarkRange: true })
            .setMeta('preventAutolink', true)
            .run()
        },

      unsetFile:
        () =>
        ({ chain }) => {
          return chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .setMeta('preventAutolink', true)
            .run()
        },
      openFileModal: () => () => {
        this.storage.onEditLink?.()
      }
    }
  },

  addAttributes() {
    return {
      href: {
        default: null,
        parseHTML: element =>
          element.getAttribute('href').replace(`//${PI_API_HOST}`, ''),
        renderHTML: ({ href }) => ({
          href: href ? `//${PI_API_HOST}${href}` : ''
        })
      }
    }
  },

  parseHTML() {
    return [{ tag: 'a[href][data-type=file]:not([href *= "javascript:" i])' }]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(HTMLAttributes, this.options.HTMLAttributes, {
        'data-type': 'file'
      }),
      0
    ]
  }
})

export default FileUpload
