<template>
  <async-form
    v-if="useForm"
    ref="form"
    class="form modal"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-title"
    data-testid="modal"
    @submit="submit"
    v-slot="form"
  >
    <button
      id="modal-close"
      ref="close"
      class="modal-header__close"
      type="button"
      aria-label="close"
      @click="onClick"
      v-if="closable"
    >
      <div class="modal-header__border" aria-hidden="true">&times;</div>
    </button>
    <slot v-bind="form" />
  </async-form>
  <div
    v-else
    class="modal"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-title"
    data-testid="modal"
  >
    <button
      id="modal-div-close"
      ref="close"
      class="modal-header__close"
      type="button"
      aria-label="close"
      @click="onClick"
      v-if="closable"
    >
      <div class="modal-header__border" aria-hidden="true">&times;</div>
    </button>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Modal',
  inject: ['$_modal'],
  emits: ['ok', 'status', 'submit'],
  props: {
    closable: {
      type: Boolean,
      default: true
    },
    useForm: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      $_modal: {
        close: this.close,
        ok: this.ok,
        status: this.status
      }
    }
  },
  methods: {
    keydown(e) {
      // If there is a nested modal, only that modal should be closed.
      if (this.$el.querySelector('[role="dialog"]')) {
        return
      }
      if (e.key === 'Escape' && this.closable === true) {
        e.preventDefault()
        this.close()
      }
    },
    focusin(e) {
      if (!this.$el.contains(e.target)) {
        const close = this.$el.querySelector('#modal-close')
        if (close) close.focus()
      }
    },
    close() {
      if (this.$attrs.onClose) {
        this.$attrs.onClose()
      } else {
        this.$_modal.close()
      }
    },
    ok() {
      this.$emit('ok')
      this.$_modal.ok()
    },
    status(status) {
      this.$emit(status)
      this.$_modal.status(status)
    },
    submit(e) {
      this.$emit('submit', {
        done: (data = true) => {
          e.done(!!data)
          if (data) {
            this.$_modal.ok(data)
          }
        }
      })
    },
    reset() {
      if (this.useForm) {
        this.$refs.form?.reset()
      }
    },
    onClick() {
      this.close()
    }
  },
  created() {
    window.addEventListener('keydown', this.keydown)
    window.addEventListener('focusin', this.focusin)
  },
  unmounted() {
    window.removeEventListener('keydown', this.keydown)
    window.removeEventListener('focusin', this.focusin)
  },
  mounted() {
    const firstElement = this.$el.querySelector('[data-focus]')
    if (firstElement) firstElement.focus()
    else this.$refs.close?.focus()
  }
}
</script>

<style lang="scss">
.modal {
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.5);
  padding: 30px;
  margin: 16px;
  max-height: calc(100% - 32px);
  max-width: calc(100% - 32px);
  width: 400px;
  display: flex;
  flex-direction: column;
}

.modal-header__close {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 0;
  right: 0;
  padding: 0;
  margin: -5px -5px 0px 0px;
  color: $color-error;
  font-size: 20px;
  font-weight: 900;
  line-height: 32px;
  background-color: transparent;
  border: none;

  &:focus .modal-header__border {
    outline: $teal solid 2px;
  }
}
.modal-header__border {
  margin: -8px 0px 0px 10px;
  position: absolute;
  width: 18px;
  height: 18px;
  box-shadow: none;
  font-size: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: $border-radius-base;
}
</style>
