import VueResource from 'vue-resource'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
import loadGlobalComponents from 'src/shared/components/global/global'
import loadMixins from 'src/setup/mixins'
import loadInterceptors from 'src/setup/interceptors'
import loadSentry from 'src/setup/sentry'
import loadValidationRules from 'src/setup/validation'
import { initVueGtag } from 'src/setup/analytics'
import { createAppStore } from 'src/setup/store'
import { createAppRouter } from 'src/setup/router'

export default app => {
  app.use(Donut)
  // VueResource isn't compatible with vue 3
  // but it still "works" since all we really use is the global http object it adds to the instance.
  // So we add it and ignore any errors,
  // and then monkey patch app.http to window so that services still work.
  try {
    VueResource(app)
  } catch (error) {}
  window.http = app.http

  // We use require instead of import because the order of import is important.
  const store = createAppStore()
  app.use(store)

  loadGlobalComponents(app)

  const router = createAppRouter(store)
  app.use(router)

  loadMixins(app)
  loadInterceptors(app, store)
  loadSentry(app)
  initVueGtag(app, router)
  loadValidationRules()

  return { store, router }
}
