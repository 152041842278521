<template>
  <div
    ref="wrapper"
    class="table__wrapper"
    :class="{
      'table--editing': isEditing,
      'table--sticky-header': ['both', 'header'].includes(sticky),
      'table--sticky-column': ['both', 'column'].includes(sticky)
    }"
    v-bind="wrapperAttrs"
  >
    <table
      v-bind="tableAttrs"
      ref="table"
      role="grid"
      class="table__table"
      :width="width"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @keydown="onKeyDown"
      @dblclick="onDoubleClick"
    >
      <thead>
        <slot name="header" />
      </thead>
      <tbody>
        <slot name="body" />
      </tbody>
      <tfoot>
        <slot name="footer" />
      </tfoot>
    </table>
  </div>
</template>

<script setup>
// This component makes a couple of assumptions:
// 1. Components that are navigable within the table need the data-tablenav attribute and a tabindex of -1
// 2. If sticky headers are turned on, all rows in the thead will be sticky
// 3. If sticky column is turned on, only the first column will be sticky

import {
  ref,
  computed,
  reactive,
  provide,
  onMounted,
  useAttrs,
  onUpdated
} from 'vue'

const emit = defineEmits(['isEditing'])
const props = defineProps({
  sticky: {
    type: String,
    default: 'none'
  },
  width: {
    type: Number
  }
})

const attrs = useAttrs()
const wrapperAttrs = computed(() => ({
  class: attrs.class,
  id: attrs.id
}))
const tableAttrs = computed(() => {
  const { class: _, id, ...rest } = attrs
  return rest
})

const wrapper = ref()
const table = ref()
const headerRowCount = ref(0)
const stickyOffset = ref({ top: 0, left: 0 })

// We need to determine the sticky offsets for each header row.
function manageHeaderRows() {
  const thead = table.value.querySelector('thead')
  let top = 0
  let left = 0
  if (thead.rows.length !== headerRowCount.value) {
    Array.from(thead.rows).forEach((row, i) => {
      if (i === 0) {
        left = row.cells[0].offsetWidth
      } else {
        Array.from(row.cells).forEach(cell => (cell.style.top = `${top}px`))
      }
      top += row.offsetHeight
    })
    stickyOffset.value = { top, left }
    headerRowCount.value = thead.rows.count
  }
}
onMounted(manageHeaderRows)
onUpdated(manageHeaderRows)

// We need to ensure that only one element at a time can be focused within the table by using tab controls.
// This allows the tab key to return to where it was when you tab in and out of the table.
function onFocusIn(e) {
  if (e.target.hasAttribute('data-tablenav')) {
    for (const el of table.value.querySelectorAll('[data-tablenav]')) {
      el.tabIndex = -1
    }
    e.target.tabIndex = 0
  }
}
// By default no elements are focusable, so we need to turn one on.
onMounted(() => {
  const firstEl = table.value.querySelector('[data-tablenav]')
  if (firstEl) {
    firstEl.tabIndex = 0
  }
})

// This mimics scrollIntoView with the settings "nearest"
// while taking into account any sticky headers.
function scrollCellIntoView(cell) {
  if (wrapper.value) {
    const cellX = [cell.offsetLeft, cell.offsetLeft + cell.offsetWidth]
    const cellY = [cell.offsetTop, cell.offsetTop + cell.offsetHeight]

    const wrapperWidth = wrapper.value.clientWidth
    const wrapperHeight = wrapper.value.clientHeight
    const wrapperX = [
      wrapper.value.scrollLeft + stickyOffset.value.left,
      wrapper.value.scrollLeft + wrapperWidth
    ]
    const wrapperY = [
      wrapper.value.scrollTop + stickyOffset.value.top,
      wrapper.value.scrollTop + wrapperHeight
    ]

    let newLeft = wrapper.value.scrollLeft
    let newTop = wrapper.value.scrollTop
    if (wrapperX[0] > cellX[0]) {
      newLeft = cellX[0] - stickyOffset.value.left
    } else if (wrapperX[1] < cellX[1]) {
      newLeft = cellX[1] - wrapperWidth
    }
    if (wrapperY[0] > cellY[0]) {
      newTop = cellY[0] - stickyOffset.value.top
    } else if (wrapperY[1] < cellY[1]) {
      newTop = cellY[1] - wrapperHeight
    }
    wrapper.value.scroll(newLeft, newTop)
  }
}

// These two helpers are used in keyboard controls to move focus around.
function focusHorizontalCell(el, delta) {
  const cell = ['TD', 'TH'].includes(el.tagName) ? el : el.closest('th, td')
  // First we see if we should focus another element within the cell.
  const focusableElements = Array.from(cell.querySelectorAll('[data-tablenav]'))
  const currentIndex = focusableElements.indexOf(el)
  const newIndex = currentIndex + delta
  if (0 <= newIndex && newIndex < focusableElements.length) {
    focusableElements[newIndex]?.focus({ preventScroll: true })
    scrollCellIntoView(cell)
  }
  // If that isn't needed, then we focus an element in another cell in the same row.
  else {
    const row = cell.parentElement
    const newIndex = cell.cellIndex + delta
    if (0 <= newIndex && newIndex < row.cells.length) {
      const newCell = row.cells[newIndex]
      const focusableElements = Array.from(
        newCell.querySelectorAll('[data-tablenav]')
      )
      // If the next cell has nothing focusable, we skip to the next cell.
      if (focusableElements.length === 0) {
        if (Math.sign(delta) > 0 && newIndex < row.cells.length - 1) {
          focusHorizontalCell(el, delta + 1)
        } else if (Math.sign(delta) < 0 && newIndex > 0) {
          focusHorizontalCell(el, delta - 1)
        }
      }
      // We consider the direction when focusing within the cell so that the selection
      // moves linearly from left to right and vice versa.
      else if (delta > 0) {
        focusableElements[0]?.focus({ preventScroll: true })
      } else {
        focusableElements.slice(-1)[0]?.focus({ preventScroll: true })
      }
      scrollCellIntoView(newCell)
    }
  }
}

function focusVerticalCell(el, delta) {
  const cell = ['TD', 'TH'].includes(el.tagName) ? el : el.closest('th, td')
  const row = cell.parentElement
  const table = row.closest('table')

  const newIndex = Math.min(
    table.rows.length - 1,
    Math.max(0, row.rowIndex + delta)
  )
  const newRow = table.rows[newIndex]
  let newCell
  if (newRow.cells.length > row.cells.length) {
    newCell = newRow.cells[cell.cellIndex + 1]
  } else if (newRow.cells.length < row.cells.length) {
    newCell = newRow.cells[cell.cellIndex - 1]
  } else {
    newCell = newRow.cells[cell.cellIndex]
  }
  const focusableElements = newCell?.querySelectorAll('[data-tablenav]') ?? []
  if (focusableElements.length > 0) {
    focusableElements[0].focus({ preventScroll: true })
    scrollCellIntoView(newCell)
  }
  // If the cell has nothing focusable, we skip to the next cell in the column.
  else if (Math.sign(delta) > 0 && newIndex < table.rows.length - 1) {
    focusVerticalCell(el, delta + 1)
  } else if (Math.sign(delta) < 0 && newIndex > 0) {
    if (newIndex > 0) focusVerticalCell(el, delta - 1)
  }
}

const editingCanceled = ref(false)
const isEditing = ref(false)
provide('table', reactive({ isEditing, editingCanceled }))

function onDoubleClick(e) {
  const target = e.target
  if (target.tagName === 'INPUT' && !target.readOnly) {
    target.select()
    isEditing.value = true
    editingCanceled.value = false
    emit('isEditing', true)
  }
}

function onFocusOut(e) {
  if (e.relatedTarget?.closest('table') !== e.target.closest('table')) {
    isEditing.value = false
    emit('isEditing', false)
  }
}

function onKeyDown(e) {
  // We return if the control or alt keys are pressed so that other handlers can add their own controls with these keys.
  if (e.ctrlKey || e.altKey) return

  const target = e.target
  const cell = ['TD', 'TH'].includes(target.tagName)
    ? target
    : target.closest('th, td')

  if (target.tagName === 'INPUT' && isEditing.value) {
    // We scroll the cell into view on any keypress while editing an input so that you can see what you are typing.
    scrollCellIntoView(cell)
    // While editing an input, you need to be able to exit editing mode in various ways.
    switch (e.key) {
      case 'Escape': {
        isEditing.value = false
        editingCanceled.value = true
        emit('isEditing', false)
        break
      }
      case 'Tab': {
        focusHorizontalCell(e.target, e.shiftKey ? -1 : 1)
        isEditing.value = false
        emit('isEditing', false)
        break
      }
      case 'Enter': {
        isEditing.value = false
        emit('isEditing', false)
        setTimeout(() => {
          focusVerticalCell(e.target, e.shiftKey ? -1 : 1)
        })
        break
      }
      // All other keys should be passed to the input as keystrokes.
      default:
        return
    }
  } else {
    switch (e.key) {
      // This allows users to start editing a text input.
      case 'Enter': {
        if (e.target.tagName === 'INPUT' && !e.target.readOnly) {
          e.target.select()
          isEditing.value = true
          emit('isEditing', true)
          break
        }
        return
      }
      // When not editing an input, the arrow keys move you around the table.
      case 'ArrowRight': {
        focusHorizontalCell(e.target, 1)
        break
      }
      case 'ArrowLeft': {
        focusHorizontalCell(e.target, -1)
        break
      }
      case 'ArrowUp': {
        focusVerticalCell(e.target, -1)
        break
      }
      case 'ArrowDown': {
        setTimeout(() => {
          focusVerticalCell(e.target, 1)
        })
        break
      }
      default: {
        // On inputs, starting to type will put you in edit mode after clearing the cell
        if (
          e.target.tagName === 'INPUT' &&
          e.key?.length === 1 &&
          !e.target.readOnly
        ) {
          switch (e.target.type) {
            case 'number':
              e.target.value = undefined
              break
            case 'text':
              e.target.value = ''
              break
          }
          isEditing.value = true
          emit('isEditing', true)
        }
        return
      }
    }
  }
  e.stopPropagation()
  e.preventDefault()
}
</script>

<script>
export default {
  inheritAttrs: false
}
</script>

<style lang="scss" scoped>
.table__wrapper {
  position: relative;
}

.table__table {
  table-layout: fixed;
  border-collapse: separate;

  :deep() {
    th,
    td {
      border-bottom: 1px solid #979797;
      border-right: 1px solid #979797;
      background-color: #ffffff;
      height: 36px;

      &:first-child {
        border-left: 1px solid #979797;
      }
    }

    tr:has([rowspan='2']:first-child) + tr {
      td,
      th {
        border-left: none !important;
      }
    }

    thead {
      th,
      td {
        background-color: #f8f8f8;
      }
    }

    thead tr:first-child {
      th,
      td {
        border-top: 1px solid #979797;
      }
    }

    thead,
    tbody {
      :not(li) > button {
        color: $teal;
        background: transparent;
        border: none;

        &:focus {
          color: white;
          background-color: $teal;
          box-shadow: none;
        }
      }

      select,
      input {
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        border: none;

        &:focus {
          background: lighten($teal, 50%);
        }
      }

      input {
        border: 2px solid transparent;

        .table--editing &:focus {
          border-color: $teal;
          background: transparent;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    tfoot {
      th,
      td {
        border: none;
        background-color: inherit;

        &:first-child {
          border: none;
        }
      }
    }

    input {
      caret-color: transparent;
    }
  }
}
.table--editing {
  :deep(input) {
    caret-color: initial;
  }
}

.table--sticky-header {
  overflow: auto;
  &:not(.table--sticky-column) {
    overflow-x: hidden;
  }

  :deep(thead) {
    td,
    th {
      z-index: 5;
      position: sticky;
      top: 0;
    }
  }
}

.table--sticky-column {
  overflow: auto;
  &:not(.table--sticky-header) {
    overflow-y: hidden;
  }

  :deep(tr:not(:has([rowspan='2']:first-child)) + tr),
  :deep(tr:first-child) {
    td:first-child,
    th:first-child {
      left: 0;
      position: sticky;
      z-index: 1;
    }
  }
}

.table--sticky-column.table--sticky-header {
  :deep(thead) {
    tr:not(:has([rowspan='2']:first-child)) + tr,
    tr:first-child {
      td:first-child,
      th:first-child {
        position: sticky;
        z-index: 10;
      }
    }
  }
}
</style>
