<template>
  <h3 class="sidebar-header">
    <slot />
  </h3>
</template>

<script>
export default {
  name: 'SidebarHeader'
}
</script>

<style lang="scss" scoped>
.sidebar-header {
  margin: 0 0 8px 0;
}
</style>
