<template>
  <div v-if="canAutosave" class="autosave-indicator">
    <div v-if="saved">
      <span class="autosave-indicator__circle">●</span>
      <span class="autosave-indicator__status">Saved</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityAutosaveIndicator',
  inject: ['inherited'],
  props: {
    autosavedComponents: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    saved() {
      return this.autosavedComponents.includes(this.inherited.component._id)
    },
    canAutosave() {
      return this.inherited.component.componentType.includes('Question')
    }
  }
}
</script>

<style lang="scss" scoped>
.autosave-indicator {
  float: right;
  margin-right: 10px;
  height: 10px;
  padding-bottom: 20px;

  &__circle {
    padding: 2px;
    color: $color-success;
  }

  &__status {
    color: $dark-grey;
    padding: 2px;
    font-weight: bold;
    font-size: 14px;
  }
}
</style>
