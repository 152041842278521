<template>
  <div
    class="data-grid-input"
    :class="{
      [`data-grid-input--${type}`]: true,
      [$attrs.class]: true
    }"
  >
    <latex-block
      v-if="type === 'latex'"
      class="data-grid-input__display"
      :latex="value"
    />
    <expression-block
      v-else-if="type === 'expression'"
      class="data-grid-input__display data-grid-input__display-expr"
      :expression="value"
      :variable-context="variableContext"
      :read-only="readonly"
      :format="format"
    />
    <div
      v-else
      class="data-grid-input__display"
      :class="{
        'data-grid-input__display--placeholder': type === 'text' && !value
      }"
      aria-hidden="true"
    >
      {{ type === 'number' ? formattedNumber : value || placeholder }}
    </div>
    <input
      v-if="!hideInput"
      v-bind="inputAttrs"
      :placeholder="placeholder"
      class="data-grid-input__input"
      ref="input"
      tabindex="-1"
      v-model="inputValue"
      data-tablenav
      @change="onChange"
      @focus="onFocus"
      @blur="isFocused = false"
    />
  </div>
</template>

<script>
import { parseCellNumber } from './utilities'
import { inject, toRefs } from 'vue'
import ExpressionBlock from 'src/shared/components/grid-graph/ExpressionBlock'

export default {
  name: 'DataGridInputField',
  emits: ['input', 'inputfocus'],
  inheritAttrs: false,
  components: { ExpressionBlock },
  props: {
    value: [String, Number],
    type: {
      type: String,
      default: 'number'
    },
    small: {
      type: Boolean,
      default: false
    },
    format: {
      type: Object
    },
    placeholder: {
      type: String
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const table = inject('table')
    const { isEditing, editingCanceled } = toRefs(table)
    return { isEditing, editingCanceled }
  },

  data() {
    return {
      isFocused: false,
      inputValue: ''
    }
  },
  computed: {
    inputAttrs() {
      const { class: _, ...attrs } = this.$attrs
      return attrs
    },
    hideInput() {
      return this.readonly && this.type === 'expression'
    },
    formattedNumber() {
      if (this.format) {
        const float = parseCellNumber(this.value)
        if (isNaN(float)) {
          return this.value
        }

        switch (this.format.type) {
          case 'scientific':
            return float.toExponential()
          case 'decimals':
            return float.toFixed(this.format.decimalPlaces)
          case 'sigfigs':
            return float.toPrecision(this.format.significantFigures)
          case 'auto':
          default:
            return this.value
        }
      } else {
        return this.value
      }
    }
  },

  methods: {
    focusInput() {
      this.$refs.input && this.$refs.input.focus()
    },
    onChange(event) {
      if (this.isEditing === true) {
        this.$emit('input', event.target.value)
      }
    },
    onFocus() {
      if (this.$refs.input) {
        // put the cursor always at the end of the input value
        if (this.$refs.input.type === 'text') {
          this.$refs.input.setSelectionRange(
            this.$refs.input.value.length,
            this.$refs.input.value.length
          )
        }
      }

      this.isFocused = true

      this.$emit('inputfocus')
    }
  },
  watch: {
    isEditing(newValue, oldValue) {
      if (
        this.isFocused &&
        newValue === false &&
        this.editingCanceled === false
      ) {
        this.$emit('input', this.$refs.input.value)
      }
    },
    value: {
      handler(newValue) {
        if (!this.isFocused || !this.isEditing) {
          this.inputValue = newValue
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.data-grid-input {
  height: 100%;
  width: 100%;
  position: relative;

  &.invalid {
    box-shadow: inset 0 0 0 2px $color-error;
  }

  &:focus-within {
    background-color: lighten($teal, 50%);

    .table--editing & {
      background-color: inherit;

      .data-grid-input__input {
        opacity: 100%;
      }

      :deep(.data-grid-input__display) {
        opacity: 0;
      }
    }
  }
}

.data-grid-input__input {
  width: 100%;
  height: 100%;
  line-height: 22px;
  opacity: 0;
  display: flex;
  align-items: center;

  .invalid & {
    border-color: $color-error !important;
  }
}

:deep(.data-grid-input__display) {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 3px 4px;
  line-height: 22px;
  background-color: transparent;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;

  &.data-grid-input__display-expr {
    overflow: visible;
  }
}

.data-grid-input__display--placeholder {
  color: #666666;
}

.data-grid__formula-bar:focus-within + * {
  .data-grid-input:has(.data-grid-input__input[tabindex='0']) {
    background-color: lighten($teal, 50%);
  }
}

.data-grid-input--text {
  .data-grid-input__input {
    position: absolute;
    top: 0;
  }

  .data-grid-input__display {
    position: unset;
    top: unset;
    padding: 6px 4px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: unset;
  }
}
</style>
