<template>
  <p class="card__secondary" data-testid="card-secondary">
    <slot />
    <span class="clear-both" />
  </p>
</template>

<script>
export default {
  name: 'CardSecondary'
}
</script>

<style lang="scss">
.card__secondary {
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}
</style>
