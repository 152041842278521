<template>
  <legacy-phet-io-simulation
    v-if="isLegacyPhetIOSimulation"
    :config="component.config"
    :variables="variableContext.variables"
  />
</template>

<script>
import LegacyPhetIoSimulation from 'src/shared/components/LegacyPhetIoSimulation'

export default {
  name: 'ActivityLegacyPhetIoSimulation',
  inject: ['inherited'],
  components: {
    LegacyPhetIoSimulation
  },
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    isLegacyPhetIOSimulation() {
      return this.component.componentType === 'PhetIOSim'
    }
  }
}
</script>
