var dc = Object.defineProperty;
var fc = (e, a, t) => a in e ? dc(e, a, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[a] = t;
var ve = (e, a, t) => (fc(e, typeof a != "symbol" ? a + "" : a, t), t);
import { defineComponent as be, openBlock as P, createElementBlock as Y, renderSlot as se, ref as z, computed as A, watch as Ge, h as At, toRef as Qe, useAttrs as Zo, mergeProps as Ue, withDirectives as ka, createElementVNode as ie, isRef as ra, unref as m, vModelDynamic as vc, createBlock as Me, createCommentVNode as G, vModelText as es, normalizeClass as ye, normalizeStyle as Ze, toDisplayString as Le, vModelRadio as mc, shallowRef as pc, triggerRef as ji, onScopeDispose as ts, inject as It, nextTick as Ne, watchEffect as Dt, onMounted as He, cloneVNode as as, Fragment as Se, provide as Nt, reactive as qa, onUnmounted as Ht, toRaw as pt, withCtx as me, createVNode as $e, withModifiers as Ba, watchPostEffect as ns, renderList as Fe, createTextVNode as vt, Teleport as Gl, Transition as Da, useSlots as Ka, createSlots as kt, normalizeProps as bt, guardReactiveProps as Pt, resolveDynamicComponent as Dr, render as Ui, onBeforeUpdate as hc, vShow as mr, withKeys as pr, getCurrentScope as yc, resolveComponent as Jl, toHandlers as rs } from "vue";
import { useIsSubmitting as gc, useField as Ia, ErrorMessage as bc, useForm as wc } from "vee-validate";
const xc = { class: "tw-reset font-bold" }, W3 = /* @__PURE__ */ be({
  name: "UILabel",
  __name: "UILabel",
  setup(e) {
    return (a, t) => (P(), Y("label", xc, [
      se(a.$slots, "default")
    ]));
  }
}), q3 = /* @__PURE__ */ be({
  __name: "UIForm",
  setup(e, { expose: a }) {
    const t = gc(), n = (i) => {
      t.value && (i.preventDefault(), i.stopImmediatePropagation());
    }, r = z();
    return a({
      requestSubmit() {
        var i;
        (i = r.value) == null || i.requestSubmit();
      }
    }), (i, l) => (P(), Y("form", {
      ref_key: "root",
      ref: r,
      class: "tw-reset",
      novalidate: "",
      onSubmit: n
    }, [
      se(i.$slots, "default")
    ], 544));
  }
});
function Wi(e, a) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    a && (n = n.filter(function(r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, n);
  }
  return t;
}
function pe(e) {
  for (var a = 1; a < arguments.length; a++) {
    var t = arguments[a] != null ? arguments[a] : {};
    a % 2 ? Wi(Object(t), !0).forEach(function(n) {
      yt(e, n, t[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : Wi(Object(t)).forEach(function(n) {
      Object.defineProperty(e, n, Object.getOwnPropertyDescriptor(t, n));
    });
  }
  return e;
}
function gr(e) {
  "@babel/helpers - typeof";
  return gr = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(a) {
    return typeof a;
  } : function(a) {
    return a && typeof Symbol == "function" && a.constructor === Symbol && a !== Symbol.prototype ? "symbol" : typeof a;
  }, gr(e);
}
function kc(e, a) {
  if (!(e instanceof a))
    throw new TypeError("Cannot call a class as a function");
}
function qi(e, a) {
  for (var t = 0; t < a.length; t++) {
    var n = a[t];
    n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
  }
}
function _c(e, a, t) {
  return a && qi(e.prototype, a), t && qi(e, t), Object.defineProperty(e, "prototype", {
    writable: !1
  }), e;
}
function yt(e, a, t) {
  return a in e ? Object.defineProperty(e, a, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[a] = t, e;
}
function Zl(e, a) {
  return Oc(e) || Tc(e, a) || ls(e, a) || Cc();
}
function Yn(e) {
  return Mc(e) || Sc(e) || ls(e) || Pc();
}
function Mc(e) {
  if (Array.isArray(e))
    return Tl(e);
}
function Oc(e) {
  if (Array.isArray(e))
    return e;
}
function Sc(e) {
  if (typeof Symbol < "u" && e[Symbol.iterator] != null || e["@@iterator"] != null)
    return Array.from(e);
}
function Tc(e, a) {
  var t = e == null ? null : typeof Symbol < "u" && e[Symbol.iterator] || e["@@iterator"];
  if (t != null) {
    var n = [], r = !0, i = !1, l, o;
    try {
      for (t = t.call(e); !(r = (l = t.next()).done) && (n.push(l.value), !(a && n.length === a)); r = !0)
        ;
    } catch (s) {
      i = !0, o = s;
    } finally {
      try {
        !r && t.return != null && t.return();
      } finally {
        if (i)
          throw o;
      }
    }
    return n;
  }
}
function ls(e, a) {
  if (e) {
    if (typeof e == "string")
      return Tl(e, a);
    var t = Object.prototype.toString.call(e).slice(8, -1);
    if (t === "Object" && e.constructor && (t = e.constructor.name), t === "Map" || t === "Set")
      return Array.from(e);
    if (t === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t))
      return Tl(e, a);
  }
}
function Tl(e, a) {
  (a == null || a > e.length) && (a = e.length);
  for (var t = 0, n = new Array(a); t < a; t++)
    n[t] = e[t];
  return n;
}
function Pc() {
  throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function Cc() {
  throw new TypeError(`Invalid attempt to destructure non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
var Ki = function() {
}, ei = {}, is = {}, os = null, ss = {
  mark: Ki,
  measure: Ki
};
try {
  typeof window < "u" && (ei = window), typeof document < "u" && (is = document), typeof MutationObserver < "u" && (os = MutationObserver), typeof performance < "u" && (ss = performance);
} catch {
}
var Ac = ei.navigator || {}, Xi = Ac.userAgent, Qi = Xi === void 0 ? "" : Xi, Oa = ei, Xe = is, Gi = os, Zn = ss;
Oa.document;
var ma = !!Xe.documentElement && !!Xe.head && typeof Xe.addEventListener == "function" && typeof Xe.createElement == "function", us = ~Qi.indexOf("MSIE") || ~Qi.indexOf("Trident/"), er, tr, ar, nr, rr, ua = "___FONT_AWESOME___", Pl = 16, cs = "fa", ds = "svg-inline--fa", ja = "data-fa-i2svg", Cl = "data-fa-pseudo-element", Dc = "data-fa-pseudo-element-pending", ti = "data-prefix", ai = "data-icon", Ji = "fontawesome-i2svg", Ic = "async", Ec = ["HTML", "HEAD", "STYLE", "SCRIPT"], fs = function() {
  try {
    return process.env.NODE_ENV === "production";
  } catch {
    return !1;
  }
}(), Ke = "classic", at = "sharp", ni = [Ke, at];
function Bn(e) {
  return new Proxy(e, {
    get: function(t, n) {
      return n in t ? t[n] : t[Ke];
    }
  });
}
var Dn = Bn((er = {}, yt(er, Ke, {
  fa: "solid",
  fas: "solid",
  "fa-solid": "solid",
  far: "regular",
  "fa-regular": "regular",
  fal: "light",
  "fa-light": "light",
  fat: "thin",
  "fa-thin": "thin",
  fad: "duotone",
  "fa-duotone": "duotone",
  fab: "brands",
  "fa-brands": "brands",
  fak: "kit",
  fakd: "kit",
  "fa-kit": "kit",
  "fa-kit-duotone": "kit"
}), yt(er, at, {
  fa: "solid",
  fass: "solid",
  "fa-solid": "solid",
  fasr: "regular",
  "fa-regular": "regular",
  fasl: "light",
  "fa-light": "light",
  fast: "thin",
  "fa-thin": "thin"
}), er)), In = Bn((tr = {}, yt(tr, Ke, {
  solid: "fas",
  regular: "far",
  light: "fal",
  thin: "fat",
  duotone: "fad",
  brands: "fab",
  kit: "fak"
}), yt(tr, at, {
  solid: "fass",
  regular: "fasr",
  light: "fasl",
  thin: "fast"
}), tr)), En = Bn((ar = {}, yt(ar, Ke, {
  fab: "fa-brands",
  fad: "fa-duotone",
  fak: "fa-kit",
  fal: "fa-light",
  far: "fa-regular",
  fas: "fa-solid",
  fat: "fa-thin"
}), yt(ar, at, {
  fass: "fa-solid",
  fasr: "fa-regular",
  fasl: "fa-light",
  fast: "fa-thin"
}), ar)), $c = Bn((nr = {}, yt(nr, Ke, {
  "fa-brands": "fab",
  "fa-duotone": "fad",
  "fa-kit": "fak",
  "fa-light": "fal",
  "fa-regular": "far",
  "fa-solid": "fas",
  "fa-thin": "fat"
}), yt(nr, at, {
  "fa-solid": "fass",
  "fa-regular": "fasr",
  "fa-light": "fasl",
  "fa-thin": "fast"
}), nr)), Rc = /fa(s|r|l|t|d|b|k|ss|sr|sl|st)?[\-\ ]/, vs = "fa-layers-text", Nc = /Font ?Awesome ?([56 ]*)(Solid|Regular|Light|Thin|Duotone|Brands|Free|Pro|Sharp|Kit)?.*/i, Lc = Bn((rr = {}, yt(rr, Ke, {
  900: "fas",
  400: "far",
  normal: "far",
  300: "fal",
  100: "fat"
}), yt(rr, at, {
  900: "fass",
  400: "fasr",
  300: "fasl",
  100: "fast"
}), rr)), ms = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], Hc = ms.concat([11, 12, 13, 14, 15, 16, 17, 18, 19, 20]), zc = ["class", "data-prefix", "data-icon", "data-fa-transform", "data-fa-mask"], Ha = {
  GROUP: "duotone-group",
  SWAP_OPACITY: "swap-opacity",
  PRIMARY: "primary",
  SECONDARY: "secondary"
}, $n = /* @__PURE__ */ new Set();
Object.keys(In[Ke]).map($n.add.bind($n));
Object.keys(In[at]).map($n.add.bind($n));
var Fc = [].concat(ni, Yn($n), ["2xs", "xs", "sm", "lg", "xl", "2xl", "beat", "border", "fade", "beat-fade", "bounce", "flip-both", "flip-horizontal", "flip-vertical", "flip", "fw", "inverse", "layers-counter", "layers-text", "layers", "li", "pull-left", "pull-right", "pulse", "rotate-180", "rotate-270", "rotate-90", "rotate-by", "shake", "spin-pulse", "spin-reverse", "spin", "stack-1x", "stack-2x", "stack", "ul", Ha.GROUP, Ha.SWAP_OPACITY, Ha.PRIMARY, Ha.SECONDARY]).concat(ms.map(function(e) {
  return "".concat(e, "x");
})).concat(Hc.map(function(e) {
  return "w-".concat(e);
})), Sn = Oa.FontAwesomeConfig || {};
function Vc(e) {
  var a = Xe.querySelector("script[" + e + "]");
  if (a)
    return a.getAttribute(e);
}
function Yc(e) {
  return e === "" ? !0 : e === "false" ? !1 : e === "true" ? !0 : e;
}
if (Xe && typeof Xe.querySelector == "function") {
  var Bc = [["data-family-prefix", "familyPrefix"], ["data-css-prefix", "cssPrefix"], ["data-family-default", "familyDefault"], ["data-style-default", "styleDefault"], ["data-replacement-class", "replacementClass"], ["data-auto-replace-svg", "autoReplaceSvg"], ["data-auto-add-css", "autoAddCss"], ["data-auto-a11y", "autoA11y"], ["data-search-pseudo-elements", "searchPseudoElements"], ["data-observe-mutations", "observeMutations"], ["data-mutate-approach", "mutateApproach"], ["data-keep-original-source", "keepOriginalSource"], ["data-measure-performance", "measurePerformance"], ["data-show-missing-icons", "showMissingIcons"]];
  Bc.forEach(function(e) {
    var a = Zl(e, 2), t = a[0], n = a[1], r = Yc(Vc(t));
    r != null && (Sn[n] = r);
  });
}
var ps = {
  styleDefault: "solid",
  familyDefault: "classic",
  cssPrefix: cs,
  replacementClass: ds,
  autoReplaceSvg: !0,
  autoAddCss: !0,
  autoA11y: !0,
  searchPseudoElements: !1,
  observeMutations: !0,
  mutateApproach: "async",
  keepOriginalSource: !0,
  measurePerformance: !1,
  showMissingIcons: !0
};
Sn.familyPrefix && (Sn.cssPrefix = Sn.familyPrefix);
var dn = pe(pe({}, ps), Sn);
dn.autoReplaceSvg || (dn.observeMutations = !1);
var ge = {};
Object.keys(ps).forEach(function(e) {
  Object.defineProperty(ge, e, {
    enumerable: !0,
    set: function(t) {
      dn[e] = t, Tn.forEach(function(n) {
        return n(ge);
      });
    },
    get: function() {
      return dn[e];
    }
  });
});
Object.defineProperty(ge, "familyPrefix", {
  enumerable: !0,
  set: function(a) {
    dn.cssPrefix = a, Tn.forEach(function(t) {
      return t(ge);
    });
  },
  get: function() {
    return dn.cssPrefix;
  }
});
Oa.FontAwesomeConfig = ge;
var Tn = [];
function jc(e) {
  return Tn.push(e), function() {
    Tn.splice(Tn.indexOf(e), 1);
  };
}
var ya = Pl, ta = {
  size: 16,
  x: 0,
  y: 0,
  rotate: 0,
  flipX: !1,
  flipY: !1
};
function Uc(e) {
  if (!(!e || !ma)) {
    var a = Xe.createElement("style");
    a.setAttribute("type", "text/css"), a.innerHTML = e;
    for (var t = Xe.head.childNodes, n = null, r = t.length - 1; r > -1; r--) {
      var i = t[r], l = (i.tagName || "").toUpperCase();
      ["STYLE", "LINK"].indexOf(l) > -1 && (n = i);
    }
    return Xe.head.insertBefore(a, n), e;
  }
}
var Wc = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function Rn() {
  for (var e = 12, a = ""; e-- > 0; )
    a += Wc[Math.random() * 62 | 0];
  return a;
}
function bn(e) {
  for (var a = [], t = (e || []).length >>> 0; t--; )
    a[t] = e[t];
  return a;
}
function ri(e) {
  return e.classList ? bn(e.classList) : (e.getAttribute("class") || "").split(" ").filter(function(a) {
    return a;
  });
}
function hs(e) {
  return "".concat(e).replace(/&/g, "&amp;").replace(/"/g, "&quot;").replace(/'/g, "&#39;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
}
function qc(e) {
  return Object.keys(e || {}).reduce(function(a, t) {
    return a + "".concat(t, '="').concat(hs(e[t]), '" ');
  }, "").trim();
}
function Ir(e) {
  return Object.keys(e || {}).reduce(function(a, t) {
    return a + "".concat(t, ": ").concat(e[t].trim(), ";");
  }, "");
}
function li(e) {
  return e.size !== ta.size || e.x !== ta.x || e.y !== ta.y || e.rotate !== ta.rotate || e.flipX || e.flipY;
}
function Kc(e) {
  var a = e.transform, t = e.containerWidth, n = e.iconWidth, r = {
    transform: "translate(".concat(t / 2, " 256)")
  }, i = "translate(".concat(a.x * 32, ", ").concat(a.y * 32, ") "), l = "scale(".concat(a.size / 16 * (a.flipX ? -1 : 1), ", ").concat(a.size / 16 * (a.flipY ? -1 : 1), ") "), o = "rotate(".concat(a.rotate, " 0 0)"), s = {
    transform: "".concat(i, " ").concat(l, " ").concat(o)
  }, d = {
    transform: "translate(".concat(n / 2 * -1, " -256)")
  };
  return {
    outer: r,
    inner: s,
    path: d
  };
}
function Xc(e) {
  var a = e.transform, t = e.width, n = t === void 0 ? Pl : t, r = e.height, i = r === void 0 ? Pl : r, l = e.startCentered, o = l === void 0 ? !1 : l, s = "";
  return o && us ? s += "translate(".concat(a.x / ya - n / 2, "em, ").concat(a.y / ya - i / 2, "em) ") : o ? s += "translate(calc(-50% + ".concat(a.x / ya, "em), calc(-50% + ").concat(a.y / ya, "em)) ") : s += "translate(".concat(a.x / ya, "em, ").concat(a.y / ya, "em) "), s += "scale(".concat(a.size / ya * (a.flipX ? -1 : 1), ", ").concat(a.size / ya * (a.flipY ? -1 : 1), ") "), s += "rotate(".concat(a.rotate, "deg) "), s;
}
var Qc = `:root, :host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
  --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-regular: normal 400 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-light: normal 300 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-thin: normal 100 1em/1 "Font Awesome 6 Sharp";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-2xs {
  vertical-align: 0.1em;
}
.svg-inline--fa.fa-xs {
  vertical-align: 0em;
}
.svg-inline--fa.fa-sm {
  vertical-align: -0.0714285705em;
}
.svg-inline--fa.fa-lg {
  vertical-align: -0.2em;
}
.svg-inline--fa.fa-xl {
  vertical-align: -0.25em;
}
.svg-inline--fa.fa-2xl {
  vertical-align: -0.3125em;
}
.svg-inline--fa.fa-pull-left {
  margin-right: var(--fa-pull-margin, 0.3em);
  width: auto;
}
.svg-inline--fa.fa-pull-right {
  margin-left: var(--fa-pull-margin, 0.3em);
  width: auto;
}
.svg-inline--fa.fa-li {
  width: var(--fa-li-width, 2em);
  top: 0.25em;
}
.svg-inline--fa.fa-fw {
  width: var(--fa-fw-width, 1.25em);
}

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.fa-layers-counter, .fa-layers-text {
  display: inline-block;
  position: absolute;
  text-align: center;
}

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -0.125em;
  width: 1em;
}
.fa-layers svg.svg-inline--fa {
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-counter-scale, 0.25));
          transform: scale(var(--fa-counter-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  -webkit-animation-name: fa-beat;
          animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  -webkit-animation-name: fa-bounce;
          animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  -webkit-animation-name: fa-fade;
          animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
          animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  -webkit-animation-name: fa-flip;
          animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  -webkit-animation-name: fa-shake;
          animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
  }
}
@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}
@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
          transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto);
}

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em;
}
.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black;
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}`;
function ys() {
  var e = cs, a = ds, t = ge.cssPrefix, n = ge.replacementClass, r = Qc;
  if (t !== e || n !== a) {
    var i = new RegExp("\\.".concat(e, "\\-"), "g"), l = new RegExp("\\--".concat(e, "\\-"), "g"), o = new RegExp("\\.".concat(a), "g");
    r = r.replace(i, ".".concat(t, "-")).replace(l, "--".concat(t, "-")).replace(o, ".".concat(n));
  }
  return r;
}
var Zi = !1;
function ol() {
  ge.autoAddCss && !Zi && (Uc(ys()), Zi = !0);
}
var Gc = {
  mixout: function() {
    return {
      dom: {
        css: ys,
        insertCss: ol
      }
    };
  },
  hooks: function() {
    return {
      beforeDOMElementCreation: function() {
        ol();
      },
      beforeI2svg: function() {
        ol();
      }
    };
  }
}, ca = Oa || {};
ca[ua] || (ca[ua] = {});
ca[ua].styles || (ca[ua].styles = {});
ca[ua].hooks || (ca[ua].hooks = {});
ca[ua].shims || (ca[ua].shims = []);
var Ut = ca[ua], gs = [], Jc = function e() {
  Xe.removeEventListener("DOMContentLoaded", e), br = 1, gs.map(function(a) {
    return a();
  });
}, br = !1;
ma && (br = (Xe.documentElement.doScroll ? /^loaded|^c/ : /^loaded|^i|^c/).test(Xe.readyState), br || Xe.addEventListener("DOMContentLoaded", Jc));
function Zc(e) {
  ma && (br ? setTimeout(e, 0) : gs.push(e));
}
function jn(e) {
  var a = e.tag, t = e.attributes, n = t === void 0 ? {} : t, r = e.children, i = r === void 0 ? [] : r;
  return typeof e == "string" ? hs(e) : "<".concat(a, " ").concat(qc(n), ">").concat(i.map(jn).join(""), "</").concat(a, ">");
}
function eo(e, a, t) {
  if (e && e[a] && e[a][t])
    return {
      prefix: a,
      iconName: t,
      icon: e[a][t]
    };
}
var ed = function(a, t) {
  return function(n, r, i, l) {
    return a.call(t, n, r, i, l);
  };
}, sl = function(a, t, n, r) {
  var i = Object.keys(a), l = i.length, o = r !== void 0 ? ed(t, r) : t, s, d, u;
  for (n === void 0 ? (s = 1, u = a[i[0]]) : (s = 0, u = n); s < l; s++)
    d = i[s], u = o(u, a[d], d, a);
  return u;
};
function td(e) {
  for (var a = [], t = 0, n = e.length; t < n; ) {
    var r = e.charCodeAt(t++);
    if (r >= 55296 && r <= 56319 && t < n) {
      var i = e.charCodeAt(t++);
      (i & 64512) == 56320 ? a.push(((r & 1023) << 10) + (i & 1023) + 65536) : (a.push(r), t--);
    } else
      a.push(r);
  }
  return a;
}
function Al(e) {
  var a = td(e);
  return a.length === 1 ? a[0].toString(16) : null;
}
function ad(e, a) {
  var t = e.length, n = e.charCodeAt(a), r;
  return n >= 55296 && n <= 56319 && t > a + 1 && (r = e.charCodeAt(a + 1), r >= 56320 && r <= 57343) ? (n - 55296) * 1024 + r - 56320 + 65536 : n;
}
function to(e) {
  return Object.keys(e).reduce(function(a, t) {
    var n = e[t], r = !!n.icon;
    return r ? a[n.iconName] = n.icon : a[t] = n, a;
  }, {});
}
function Dl(e, a) {
  var t = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, n = t.skipHooks, r = n === void 0 ? !1 : n, i = to(a);
  typeof Ut.hooks.addPack == "function" && !r ? Ut.hooks.addPack(e, to(a)) : Ut.styles[e] = pe(pe({}, Ut.styles[e] || {}), i), e === "fas" && Dl("fa", a);
}
var lr, ir, or, nn = Ut.styles, nd = Ut.shims, rd = (lr = {}, yt(lr, Ke, Object.values(En[Ke])), yt(lr, at, Object.values(En[at])), lr), ii = null, bs = {}, ws = {}, xs = {}, ks = {}, _s = {}, ld = (ir = {}, yt(ir, Ke, Object.keys(Dn[Ke])), yt(ir, at, Object.keys(Dn[at])), ir);
function id(e) {
  return ~Fc.indexOf(e);
}
function od(e, a) {
  var t = a.split("-"), n = t[0], r = t.slice(1).join("-");
  return n === e && r !== "" && !id(r) ? r : null;
}
var Ms = function() {
  var a = function(i) {
    return sl(nn, function(l, o, s) {
      return l[s] = sl(o, i, {}), l;
    }, {});
  };
  bs = a(function(r, i, l) {
    if (i[3] && (r[i[3]] = l), i[2]) {
      var o = i[2].filter(function(s) {
        return typeof s == "number";
      });
      o.forEach(function(s) {
        r[s.toString(16)] = l;
      });
    }
    return r;
  }), ws = a(function(r, i, l) {
    if (r[l] = l, i[2]) {
      var o = i[2].filter(function(s) {
        return typeof s == "string";
      });
      o.forEach(function(s) {
        r[s] = l;
      });
    }
    return r;
  }), _s = a(function(r, i, l) {
    var o = i[2];
    return r[l] = l, o.forEach(function(s) {
      r[s] = l;
    }), r;
  });
  var t = "far" in nn || ge.autoFetchSvg, n = sl(nd, function(r, i) {
    var l = i[0], o = i[1], s = i[2];
    return o === "far" && !t && (o = "fas"), typeof l == "string" && (r.names[l] = {
      prefix: o,
      iconName: s
    }), typeof l == "number" && (r.unicodes[l.toString(16)] = {
      prefix: o,
      iconName: s
    }), r;
  }, {
    names: {},
    unicodes: {}
  });
  xs = n.names, ks = n.unicodes, ii = Er(ge.styleDefault, {
    family: ge.familyDefault
  });
};
jc(function(e) {
  ii = Er(e.styleDefault, {
    family: ge.familyDefault
  });
});
Ms();
function oi(e, a) {
  return (bs[e] || {})[a];
}
function sd(e, a) {
  return (ws[e] || {})[a];
}
function za(e, a) {
  return (_s[e] || {})[a];
}
function Os(e) {
  return xs[e] || {
    prefix: null,
    iconName: null
  };
}
function ud(e) {
  var a = ks[e], t = oi("fas", e);
  return a || (t ? {
    prefix: "fas",
    iconName: t
  } : null) || {
    prefix: null,
    iconName: null
  };
}
function Sa() {
  return ii;
}
var si = function() {
  return {
    prefix: null,
    iconName: null,
    rest: []
  };
};
function Er(e) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, t = a.family, n = t === void 0 ? Ke : t, r = Dn[n][e], i = In[n][e] || In[n][r], l = e in Ut.styles ? e : null;
  return i || l || null;
}
var ao = (or = {}, yt(or, Ke, Object.keys(En[Ke])), yt(or, at, Object.keys(En[at])), or);
function $r(e) {
  var a, t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = t.skipLookups, r = n === void 0 ? !1 : n, i = (a = {}, yt(a, Ke, "".concat(ge.cssPrefix, "-").concat(Ke)), yt(a, at, "".concat(ge.cssPrefix, "-").concat(at)), a), l = null, o = Ke;
  (e.includes(i[Ke]) || e.some(function(d) {
    return ao[Ke].includes(d);
  })) && (o = Ke), (e.includes(i[at]) || e.some(function(d) {
    return ao[at].includes(d);
  })) && (o = at);
  var s = e.reduce(function(d, u) {
    var c = od(ge.cssPrefix, u);
    if (nn[u] ? (u = rd[o].includes(u) ? $c[o][u] : u, l = u, d.prefix = u) : ld[o].indexOf(u) > -1 ? (l = u, d.prefix = Er(u, {
      family: o
    })) : c ? d.iconName = c : u !== ge.replacementClass && u !== i[Ke] && u !== i[at] && d.rest.push(u), !r && d.prefix && d.iconName) {
      var f = l === "fa" ? Os(d.iconName) : {}, v = za(d.prefix, d.iconName);
      f.prefix && (l = null), d.iconName = f.iconName || v || d.iconName, d.prefix = f.prefix || d.prefix, d.prefix === "far" && !nn.far && nn.fas && !ge.autoFetchSvg && (d.prefix = "fas");
    }
    return d;
  }, si());
  return (e.includes("fa-brands") || e.includes("fab")) && (s.prefix = "fab"), (e.includes("fa-duotone") || e.includes("fad")) && (s.prefix = "fad"), !s.prefix && o === at && (nn.fass || ge.autoFetchSvg) && (s.prefix = "fass", s.iconName = za(s.prefix, s.iconName) || s.iconName), (s.prefix === "fa" || l === "fa") && (s.prefix = Sa() || "fas"), s;
}
var cd = /* @__PURE__ */ function() {
  function e() {
    kc(this, e), this.definitions = {};
  }
  return _c(e, [{
    key: "add",
    value: function() {
      for (var t = this, n = arguments.length, r = new Array(n), i = 0; i < n; i++)
        r[i] = arguments[i];
      var l = r.reduce(this._pullDefinitions, {});
      Object.keys(l).forEach(function(o) {
        t.definitions[o] = pe(pe({}, t.definitions[o] || {}), l[o]), Dl(o, l[o]);
        var s = En[Ke][o];
        s && Dl(s, l[o]), Ms();
      });
    }
  }, {
    key: "reset",
    value: function() {
      this.definitions = {};
    }
  }, {
    key: "_pullDefinitions",
    value: function(t, n) {
      var r = n.prefix && n.iconName && n.icon ? {
        0: n
      } : n;
      return Object.keys(r).map(function(i) {
        var l = r[i], o = l.prefix, s = l.iconName, d = l.icon, u = d[2];
        t[o] || (t[o] = {}), u.length > 0 && u.forEach(function(c) {
          typeof c == "string" && (t[o][c] = d);
        }), t[o][s] = d;
      }), t;
    }
  }]), e;
}(), no = [], rn = {}, sn = {}, dd = Object.keys(sn);
function fd(e, a) {
  var t = a.mixoutsTo;
  return no = e, rn = {}, Object.keys(sn).forEach(function(n) {
    dd.indexOf(n) === -1 && delete sn[n];
  }), no.forEach(function(n) {
    var r = n.mixout ? n.mixout() : {};
    if (Object.keys(r).forEach(function(l) {
      typeof r[l] == "function" && (t[l] = r[l]), gr(r[l]) === "object" && Object.keys(r[l]).forEach(function(o) {
        t[l] || (t[l] = {}), t[l][o] = r[l][o];
      });
    }), n.hooks) {
      var i = n.hooks();
      Object.keys(i).forEach(function(l) {
        rn[l] || (rn[l] = []), rn[l].push(i[l]);
      });
    }
    n.provides && n.provides(sn);
  }), t;
}
function Il(e, a) {
  for (var t = arguments.length, n = new Array(t > 2 ? t - 2 : 0), r = 2; r < t; r++)
    n[r - 2] = arguments[r];
  var i = rn[e] || [];
  return i.forEach(function(l) {
    a = l.apply(null, [a].concat(n));
  }), a;
}
function Ua(e) {
  for (var a = arguments.length, t = new Array(a > 1 ? a - 1 : 0), n = 1; n < a; n++)
    t[n - 1] = arguments[n];
  var r = rn[e] || [];
  r.forEach(function(i) {
    i.apply(null, t);
  });
}
function da() {
  var e = arguments[0], a = Array.prototype.slice.call(arguments, 1);
  return sn[e] ? sn[e].apply(null, a) : void 0;
}
function El(e) {
  e.prefix === "fa" && (e.prefix = "fas");
  var a = e.iconName, t = e.prefix || Sa();
  if (a)
    return a = za(t, a) || a, eo(Ss.definitions, t, a) || eo(Ut.styles, t, a);
}
var Ss = new cd(), vd = function() {
  ge.autoReplaceSvg = !1, ge.observeMutations = !1, Ua("noAuto");
}, md = {
  i2svg: function() {
    var a = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return ma ? (Ua("beforeI2svg", a), da("pseudoElements2svg", a), da("i2svg", a)) : Promise.reject("Operation requires a DOM of some kind.");
  },
  watch: function() {
    var a = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, t = a.autoReplaceSvgRoot;
    ge.autoReplaceSvg === !1 && (ge.autoReplaceSvg = !0), ge.observeMutations = !0, Zc(function() {
      hd({
        autoReplaceSvgRoot: t
      }), Ua("watch", a);
    });
  }
}, pd = {
  icon: function(a) {
    if (a === null)
      return null;
    if (gr(a) === "object" && a.prefix && a.iconName)
      return {
        prefix: a.prefix,
        iconName: za(a.prefix, a.iconName) || a.iconName
      };
    if (Array.isArray(a) && a.length === 2) {
      var t = a[1].indexOf("fa-") === 0 ? a[1].slice(3) : a[1], n = Er(a[0]);
      return {
        prefix: n,
        iconName: za(n, t) || t
      };
    }
    if (typeof a == "string" && (a.indexOf("".concat(ge.cssPrefix, "-")) > -1 || a.match(Rc))) {
      var r = $r(a.split(" "), {
        skipLookups: !0
      });
      return {
        prefix: r.prefix || Sa(),
        iconName: za(r.prefix, r.iconName) || r.iconName
      };
    }
    if (typeof a == "string") {
      var i = Sa();
      return {
        prefix: i,
        iconName: za(i, a) || a
      };
    }
  }
}, Rt = {
  noAuto: vd,
  config: ge,
  dom: md,
  parse: pd,
  library: Ss,
  findIconDefinition: El,
  toHtml: jn
}, hd = function() {
  var a = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, t = a.autoReplaceSvgRoot, n = t === void 0 ? Xe : t;
  (Object.keys(Ut.styles).length > 0 || ge.autoFetchSvg) && ma && ge.autoReplaceSvg && Rt.dom.i2svg({
    node: n
  });
};
function Rr(e, a) {
  return Object.defineProperty(e, "abstract", {
    get: a
  }), Object.defineProperty(e, "html", {
    get: function() {
      return e.abstract.map(function(n) {
        return jn(n);
      });
    }
  }), Object.defineProperty(e, "node", {
    get: function() {
      if (ma) {
        var n = Xe.createElement("div");
        return n.innerHTML = e.html, n.children;
      }
    }
  }), e;
}
function yd(e) {
  var a = e.children, t = e.main, n = e.mask, r = e.attributes, i = e.styles, l = e.transform;
  if (li(l) && t.found && !n.found) {
    var o = t.width, s = t.height, d = {
      x: o / s / 2,
      y: 0.5
    };
    r.style = Ir(pe(pe({}, i), {}, {
      "transform-origin": "".concat(d.x + l.x / 16, "em ").concat(d.y + l.y / 16, "em")
    }));
  }
  return [{
    tag: "svg",
    attributes: r,
    children: a
  }];
}
function gd(e) {
  var a = e.prefix, t = e.iconName, n = e.children, r = e.attributes, i = e.symbol, l = i === !0 ? "".concat(a, "-").concat(ge.cssPrefix, "-").concat(t) : i;
  return [{
    tag: "svg",
    attributes: {
      style: "display: none;"
    },
    children: [{
      tag: "symbol",
      attributes: pe(pe({}, r), {}, {
        id: l
      }),
      children: n
    }]
  }];
}
function ui(e) {
  var a = e.icons, t = a.main, n = a.mask, r = e.prefix, i = e.iconName, l = e.transform, o = e.symbol, s = e.title, d = e.maskId, u = e.titleId, c = e.extra, f = e.watchable, v = f === void 0 ? !1 : f, g = n.found ? n : t, b = g.width, C = g.height, p = r === "fak", w = [ge.replacementClass, i ? "".concat(ge.cssPrefix, "-").concat(i) : ""].filter(function(N) {
    return c.classes.indexOf(N) === -1;
  }).filter(function(N) {
    return N !== "" || !!N;
  }).concat(c.classes).join(" "), y = {
    children: [],
    attributes: pe(pe({}, c.attributes), {}, {
      "data-prefix": r,
      "data-icon": i,
      class: w,
      role: c.attributes.role || "img",
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 ".concat(b, " ").concat(C)
    })
  }, x = p && !~c.classes.indexOf("fa-fw") ? {
    width: "".concat(b / C * 16 * 0.0625, "em")
  } : {};
  v && (y.attributes[ja] = ""), s && (y.children.push({
    tag: "title",
    attributes: {
      id: y.attributes["aria-labelledby"] || "title-".concat(u || Rn())
    },
    children: [s]
  }), delete y.attributes.title);
  var D = pe(pe({}, y), {}, {
    prefix: r,
    iconName: i,
    main: t,
    mask: n,
    maskId: d,
    transform: l,
    symbol: o,
    styles: pe(pe({}, x), c.styles)
  }), S = n.found && t.found ? da("generateAbstractMask", D) || {
    children: [],
    attributes: {}
  } : da("generateAbstractIcon", D) || {
    children: [],
    attributes: {}
  }, $ = S.children, O = S.attributes;
  return D.children = $, D.attributes = O, o ? gd(D) : yd(D);
}
function ro(e) {
  var a = e.content, t = e.width, n = e.height, r = e.transform, i = e.title, l = e.extra, o = e.watchable, s = o === void 0 ? !1 : o, d = pe(pe(pe({}, l.attributes), i ? {
    title: i
  } : {}), {}, {
    class: l.classes.join(" ")
  });
  s && (d[ja] = "");
  var u = pe({}, l.styles);
  li(r) && (u.transform = Xc({
    transform: r,
    startCentered: !0,
    width: t,
    height: n
  }), u["-webkit-transform"] = u.transform);
  var c = Ir(u);
  c.length > 0 && (d.style = c);
  var f = [];
  return f.push({
    tag: "span",
    attributes: d,
    children: [a]
  }), i && f.push({
    tag: "span",
    attributes: {
      class: "sr-only"
    },
    children: [i]
  }), f;
}
function bd(e) {
  var a = e.content, t = e.title, n = e.extra, r = pe(pe(pe({}, n.attributes), t ? {
    title: t
  } : {}), {}, {
    class: n.classes.join(" ")
  }), i = Ir(n.styles);
  i.length > 0 && (r.style = i);
  var l = [];
  return l.push({
    tag: "span",
    attributes: r,
    children: [a]
  }), t && l.push({
    tag: "span",
    attributes: {
      class: "sr-only"
    },
    children: [t]
  }), l;
}
var ul = Ut.styles;
function $l(e) {
  var a = e[0], t = e[1], n = e.slice(4), r = Zl(n, 1), i = r[0], l = null;
  return Array.isArray(i) ? l = {
    tag: "g",
    attributes: {
      class: "".concat(ge.cssPrefix, "-").concat(Ha.GROUP)
    },
    children: [{
      tag: "path",
      attributes: {
        class: "".concat(ge.cssPrefix, "-").concat(Ha.SECONDARY),
        fill: "currentColor",
        d: i[0]
      }
    }, {
      tag: "path",
      attributes: {
        class: "".concat(ge.cssPrefix, "-").concat(Ha.PRIMARY),
        fill: "currentColor",
        d: i[1]
      }
    }]
  } : l = {
    tag: "path",
    attributes: {
      fill: "currentColor",
      d: i
    }
  }, {
    found: !0,
    width: a,
    height: t,
    icon: l
  };
}
var wd = {
  found: !1,
  width: 512,
  height: 512
};
function xd(e, a) {
  !fs && !ge.showMissingIcons && e && console.error('Icon with name "'.concat(e, '" and prefix "').concat(a, '" is missing.'));
}
function Rl(e, a) {
  var t = a;
  return a === "fa" && ge.styleDefault !== null && (a = Sa()), new Promise(function(n, r) {
    if (da("missingIconAbstract"), t === "fa") {
      var i = Os(e) || {};
      e = i.iconName || e, a = i.prefix || a;
    }
    if (e && a && ul[a] && ul[a][e]) {
      var l = ul[a][e];
      return n($l(l));
    }
    xd(e, a), n(pe(pe({}, wd), {}, {
      icon: ge.showMissingIcons && e ? da("missingIconAbstract") || {} : {}
    }));
  });
}
var lo = function() {
}, Nl = ge.measurePerformance && Zn && Zn.mark && Zn.measure ? Zn : {
  mark: lo,
  measure: lo
}, On = 'FA "6.5.1"', kd = function(a) {
  return Nl.mark("".concat(On, " ").concat(a, " begins")), function() {
    return Ts(a);
  };
}, Ts = function(a) {
  Nl.mark("".concat(On, " ").concat(a, " ends")), Nl.measure("".concat(On, " ").concat(a), "".concat(On, " ").concat(a, " begins"), "".concat(On, " ").concat(a, " ends"));
}, ci = {
  begin: kd,
  end: Ts
}, hr = function() {
};
function io(e) {
  var a = e.getAttribute ? e.getAttribute(ja) : null;
  return typeof a == "string";
}
function _d(e) {
  var a = e.getAttribute ? e.getAttribute(ti) : null, t = e.getAttribute ? e.getAttribute(ai) : null;
  return a && t;
}
function Md(e) {
  return e && e.classList && e.classList.contains && e.classList.contains(ge.replacementClass);
}
function Od() {
  if (ge.autoReplaceSvg === !0)
    return yr.replace;
  var e = yr[ge.autoReplaceSvg];
  return e || yr.replace;
}
function Sd(e) {
  return Xe.createElementNS("http://www.w3.org/2000/svg", e);
}
function Td(e) {
  return Xe.createElement(e);
}
function Ps(e) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, t = a.ceFn, n = t === void 0 ? e.tag === "svg" ? Sd : Td : t;
  if (typeof e == "string")
    return Xe.createTextNode(e);
  var r = n(e.tag);
  Object.keys(e.attributes || []).forEach(function(l) {
    r.setAttribute(l, e.attributes[l]);
  });
  var i = e.children || [];
  return i.forEach(function(l) {
    r.appendChild(Ps(l, {
      ceFn: n
    }));
  }), r;
}
function Pd(e) {
  var a = " ".concat(e.outerHTML, " ");
  return a = "".concat(a, "Font Awesome fontawesome.com "), a;
}
var yr = {
  replace: function(a) {
    var t = a[0];
    if (t.parentNode)
      if (a[1].forEach(function(r) {
        t.parentNode.insertBefore(Ps(r), t);
      }), t.getAttribute(ja) === null && ge.keepOriginalSource) {
        var n = Xe.createComment(Pd(t));
        t.parentNode.replaceChild(n, t);
      } else
        t.remove();
  },
  nest: function(a) {
    var t = a[0], n = a[1];
    if (~ri(t).indexOf(ge.replacementClass))
      return yr.replace(a);
    var r = new RegExp("".concat(ge.cssPrefix, "-.*"));
    if (delete n[0].attributes.id, n[0].attributes.class) {
      var i = n[0].attributes.class.split(" ").reduce(function(o, s) {
        return s === ge.replacementClass || s.match(r) ? o.toSvg.push(s) : o.toNode.push(s), o;
      }, {
        toNode: [],
        toSvg: []
      });
      n[0].attributes.class = i.toSvg.join(" "), i.toNode.length === 0 ? t.removeAttribute("class") : t.setAttribute("class", i.toNode.join(" "));
    }
    var l = n.map(function(o) {
      return jn(o);
    }).join(`
`);
    t.setAttribute(ja, ""), t.innerHTML = l;
  }
};
function oo(e) {
  e();
}
function Cs(e, a) {
  var t = typeof a == "function" ? a : hr;
  if (e.length === 0)
    t();
  else {
    var n = oo;
    ge.mutateApproach === Ic && (n = Oa.requestAnimationFrame || oo), n(function() {
      var r = Od(), i = ci.begin("mutate");
      e.map(r), i(), t();
    });
  }
}
var di = !1;
function As() {
  di = !0;
}
function Ll() {
  di = !1;
}
var wr = null;
function so(e) {
  if (Gi && ge.observeMutations) {
    var a = e.treeCallback, t = a === void 0 ? hr : a, n = e.nodeCallback, r = n === void 0 ? hr : n, i = e.pseudoElementsCallback, l = i === void 0 ? hr : i, o = e.observeMutationsRoot, s = o === void 0 ? Xe : o;
    wr = new Gi(function(d) {
      if (!di) {
        var u = Sa();
        bn(d).forEach(function(c) {
          if (c.type === "childList" && c.addedNodes.length > 0 && !io(c.addedNodes[0]) && (ge.searchPseudoElements && l(c.target), t(c.target)), c.type === "attributes" && c.target.parentNode && ge.searchPseudoElements && l(c.target.parentNode), c.type === "attributes" && io(c.target) && ~zc.indexOf(c.attributeName))
            if (c.attributeName === "class" && _d(c.target)) {
              var f = $r(ri(c.target)), v = f.prefix, g = f.iconName;
              c.target.setAttribute(ti, v || u), g && c.target.setAttribute(ai, g);
            } else
              Md(c.target) && r(c.target);
        });
      }
    }), ma && wr.observe(s, {
      childList: !0,
      attributes: !0,
      characterData: !0,
      subtree: !0
    });
  }
}
function Cd() {
  wr && wr.disconnect();
}
function Ad(e) {
  var a = e.getAttribute("style"), t = [];
  return a && (t = a.split(";").reduce(function(n, r) {
    var i = r.split(":"), l = i[0], o = i.slice(1);
    return l && o.length > 0 && (n[l] = o.join(":").trim()), n;
  }, {})), t;
}
function Dd(e) {
  var a = e.getAttribute("data-prefix"), t = e.getAttribute("data-icon"), n = e.innerText !== void 0 ? e.innerText.trim() : "", r = $r(ri(e));
  return r.prefix || (r.prefix = Sa()), a && t && (r.prefix = a, r.iconName = t), r.iconName && r.prefix || (r.prefix && n.length > 0 && (r.iconName = sd(r.prefix, e.innerText) || oi(r.prefix, Al(e.innerText))), !r.iconName && ge.autoFetchSvg && e.firstChild && e.firstChild.nodeType === Node.TEXT_NODE && (r.iconName = e.firstChild.data)), r;
}
function Id(e) {
  var a = bn(e.attributes).reduce(function(r, i) {
    return r.name !== "class" && r.name !== "style" && (r[i.name] = i.value), r;
  }, {}), t = e.getAttribute("title"), n = e.getAttribute("data-fa-title-id");
  return ge.autoA11y && (t ? a["aria-labelledby"] = "".concat(ge.replacementClass, "-title-").concat(n || Rn()) : (a["aria-hidden"] = "true", a.focusable = "false")), a;
}
function Ed() {
  return {
    iconName: null,
    title: null,
    titleId: null,
    prefix: null,
    transform: ta,
    symbol: !1,
    mask: {
      iconName: null,
      prefix: null,
      rest: []
    },
    maskId: null,
    extra: {
      classes: [],
      styles: {},
      attributes: {}
    }
  };
}
function uo(e) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
    styleParser: !0
  }, t = Dd(e), n = t.iconName, r = t.prefix, i = t.rest, l = Id(e), o = Il("parseNodeAttributes", {}, e), s = a.styleParser ? Ad(e) : [];
  return pe({
    iconName: n,
    title: e.getAttribute("title"),
    titleId: e.getAttribute("data-fa-title-id"),
    prefix: r,
    transform: ta,
    mask: {
      iconName: null,
      prefix: null,
      rest: []
    },
    maskId: null,
    symbol: !1,
    extra: {
      classes: i,
      styles: s,
      attributes: l
    }
  }, o);
}
var $d = Ut.styles;
function Ds(e) {
  var a = ge.autoReplaceSvg === "nest" ? uo(e, {
    styleParser: !1
  }) : uo(e);
  return ~a.extra.classes.indexOf(vs) ? da("generateLayersText", e, a) : da("generateSvgReplacementMutation", e, a);
}
var Ta = /* @__PURE__ */ new Set();
ni.map(function(e) {
  Ta.add("fa-".concat(e));
});
Object.keys(Dn[Ke]).map(Ta.add.bind(Ta));
Object.keys(Dn[at]).map(Ta.add.bind(Ta));
Ta = Yn(Ta);
function co(e) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
  if (!ma)
    return Promise.resolve();
  var t = Xe.documentElement.classList, n = function(c) {
    return t.add("".concat(Ji, "-").concat(c));
  }, r = function(c) {
    return t.remove("".concat(Ji, "-").concat(c));
  }, i = ge.autoFetchSvg ? Ta : ni.map(function(u) {
    return "fa-".concat(u);
  }).concat(Object.keys($d));
  i.includes("fa") || i.push("fa");
  var l = [".".concat(vs, ":not([").concat(ja, "])")].concat(i.map(function(u) {
    return ".".concat(u, ":not([").concat(ja, "])");
  })).join(", ");
  if (l.length === 0)
    return Promise.resolve();
  var o = [];
  try {
    o = bn(e.querySelectorAll(l));
  } catch {
  }
  if (o.length > 0)
    n("pending"), r("complete");
  else
    return Promise.resolve();
  var s = ci.begin("onTree"), d = o.reduce(function(u, c) {
    try {
      var f = Ds(c);
      f && u.push(f);
    } catch (v) {
      fs || v.name === "MissingIcon" && console.error(v);
    }
    return u;
  }, []);
  return new Promise(function(u, c) {
    Promise.all(d).then(function(f) {
      Cs(f, function() {
        n("active"), n("complete"), r("pending"), typeof a == "function" && a(), s(), u();
      });
    }).catch(function(f) {
      s(), c(f);
    });
  });
}
function Rd(e) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
  Ds(e).then(function(t) {
    t && Cs([t], a);
  });
}
function Nd(e) {
  return function(a) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = (a || {}).icon ? a : El(a || {}), r = t.mask;
    return r && (r = (r || {}).icon ? r : El(r || {})), e(n, pe(pe({}, t), {}, {
      mask: r
    }));
  };
}
var Ld = function(a) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = t.transform, r = n === void 0 ? ta : n, i = t.symbol, l = i === void 0 ? !1 : i, o = t.mask, s = o === void 0 ? null : o, d = t.maskId, u = d === void 0 ? null : d, c = t.title, f = c === void 0 ? null : c, v = t.titleId, g = v === void 0 ? null : v, b = t.classes, C = b === void 0 ? [] : b, p = t.attributes, w = p === void 0 ? {} : p, y = t.styles, x = y === void 0 ? {} : y;
  if (a) {
    var D = a.prefix, S = a.iconName, $ = a.icon;
    return Rr(pe({
      type: "icon"
    }, a), function() {
      return Ua("beforeDOMElementCreation", {
        iconDefinition: a,
        params: t
      }), ge.autoA11y && (f ? w["aria-labelledby"] = "".concat(ge.replacementClass, "-title-").concat(g || Rn()) : (w["aria-hidden"] = "true", w.focusable = "false")), ui({
        icons: {
          main: $l($),
          mask: s ? $l(s.icon) : {
            found: !1,
            width: null,
            height: null,
            icon: {}
          }
        },
        prefix: D,
        iconName: S,
        transform: pe(pe({}, ta), r),
        symbol: l,
        title: f,
        maskId: u,
        titleId: g,
        extra: {
          attributes: w,
          styles: x,
          classes: C
        }
      });
    });
  }
}, Hd = {
  mixout: function() {
    return {
      icon: Nd(Ld)
    };
  },
  hooks: function() {
    return {
      mutationObserverCallbacks: function(t) {
        return t.treeCallback = co, t.nodeCallback = Rd, t;
      }
    };
  },
  provides: function(a) {
    a.i2svg = function(t) {
      var n = t.node, r = n === void 0 ? Xe : n, i = t.callback, l = i === void 0 ? function() {
      } : i;
      return co(r, l);
    }, a.generateSvgReplacementMutation = function(t, n) {
      var r = n.iconName, i = n.title, l = n.titleId, o = n.prefix, s = n.transform, d = n.symbol, u = n.mask, c = n.maskId, f = n.extra;
      return new Promise(function(v, g) {
        Promise.all([Rl(r, o), u.iconName ? Rl(u.iconName, u.prefix) : Promise.resolve({
          found: !1,
          width: 512,
          height: 512,
          icon: {}
        })]).then(function(b) {
          var C = Zl(b, 2), p = C[0], w = C[1];
          v([t, ui({
            icons: {
              main: p,
              mask: w
            },
            prefix: o,
            iconName: r,
            transform: s,
            symbol: d,
            maskId: c,
            title: i,
            titleId: l,
            extra: f,
            watchable: !0
          })]);
        }).catch(g);
      });
    }, a.generateAbstractIcon = function(t) {
      var n = t.children, r = t.attributes, i = t.main, l = t.transform, o = t.styles, s = Ir(o);
      s.length > 0 && (r.style = s);
      var d;
      return li(l) && (d = da("generateAbstractTransformGrouping", {
        main: i,
        transform: l,
        containerWidth: i.width,
        iconWidth: i.width
      })), n.push(d || i.icon), {
        children: n,
        attributes: r
      };
    };
  }
}, zd = {
  mixout: function() {
    return {
      layer: function(t) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.classes, i = r === void 0 ? [] : r;
        return Rr({
          type: "layer"
        }, function() {
          Ua("beforeDOMElementCreation", {
            assembler: t,
            params: n
          });
          var l = [];
          return t(function(o) {
            Array.isArray(o) ? o.map(function(s) {
              l = l.concat(s.abstract);
            }) : l = l.concat(o.abstract);
          }), [{
            tag: "span",
            attributes: {
              class: ["".concat(ge.cssPrefix, "-layers")].concat(Yn(i)).join(" ")
            },
            children: l
          }];
        });
      }
    };
  }
}, Fd = {
  mixout: function() {
    return {
      counter: function(t) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.title, i = r === void 0 ? null : r, l = n.classes, o = l === void 0 ? [] : l, s = n.attributes, d = s === void 0 ? {} : s, u = n.styles, c = u === void 0 ? {} : u;
        return Rr({
          type: "counter",
          content: t
        }, function() {
          return Ua("beforeDOMElementCreation", {
            content: t,
            params: n
          }), bd({
            content: t.toString(),
            title: i,
            extra: {
              attributes: d,
              styles: c,
              classes: ["".concat(ge.cssPrefix, "-layers-counter")].concat(Yn(o))
            }
          });
        });
      }
    };
  }
}, Vd = {
  mixout: function() {
    return {
      text: function(t) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.transform, i = r === void 0 ? ta : r, l = n.title, o = l === void 0 ? null : l, s = n.classes, d = s === void 0 ? [] : s, u = n.attributes, c = u === void 0 ? {} : u, f = n.styles, v = f === void 0 ? {} : f;
        return Rr({
          type: "text",
          content: t
        }, function() {
          return Ua("beforeDOMElementCreation", {
            content: t,
            params: n
          }), ro({
            content: t,
            transform: pe(pe({}, ta), i),
            title: o,
            extra: {
              attributes: c,
              styles: v,
              classes: ["".concat(ge.cssPrefix, "-layers-text")].concat(Yn(d))
            }
          });
        });
      }
    };
  },
  provides: function(a) {
    a.generateLayersText = function(t, n) {
      var r = n.title, i = n.transform, l = n.extra, o = null, s = null;
      if (us) {
        var d = parseInt(getComputedStyle(t).fontSize, 10), u = t.getBoundingClientRect();
        o = u.width / d, s = u.height / d;
      }
      return ge.autoA11y && !r && (l.attributes["aria-hidden"] = "true"), Promise.resolve([t, ro({
        content: t.innerHTML,
        width: o,
        height: s,
        transform: i,
        title: r,
        extra: l,
        watchable: !0
      })]);
    };
  }
}, Yd = new RegExp('"', "ug"), fo = [1105920, 1112319];
function Bd(e) {
  var a = e.replace(Yd, ""), t = ad(a, 0), n = t >= fo[0] && t <= fo[1], r = a.length === 2 ? a[0] === a[1] : !1;
  return {
    value: Al(r ? a[0] : a),
    isSecondary: n || r
  };
}
function vo(e, a) {
  var t = "".concat(Dc).concat(a.replace(":", "-"));
  return new Promise(function(n, r) {
    if (e.getAttribute(t) !== null)
      return n();
    var i = bn(e.children), l = i.filter(function($) {
      return $.getAttribute(Cl) === a;
    })[0], o = Oa.getComputedStyle(e, a), s = o.getPropertyValue("font-family").match(Nc), d = o.getPropertyValue("font-weight"), u = o.getPropertyValue("content");
    if (l && !s)
      return e.removeChild(l), n();
    if (s && u !== "none" && u !== "") {
      var c = o.getPropertyValue("content"), f = ~["Sharp"].indexOf(s[2]) ? at : Ke, v = ~["Solid", "Regular", "Light", "Thin", "Duotone", "Brands", "Kit"].indexOf(s[2]) ? In[f][s[2].toLowerCase()] : Lc[f][d], g = Bd(c), b = g.value, C = g.isSecondary, p = s[0].startsWith("FontAwesome"), w = oi(v, b), y = w;
      if (p) {
        var x = ud(b);
        x.iconName && x.prefix && (w = x.iconName, v = x.prefix);
      }
      if (w && !C && (!l || l.getAttribute(ti) !== v || l.getAttribute(ai) !== y)) {
        e.setAttribute(t, y), l && e.removeChild(l);
        var D = Ed(), S = D.extra;
        S.attributes[Cl] = a, Rl(w, v).then(function($) {
          var O = ui(pe(pe({}, D), {}, {
            icons: {
              main: $,
              mask: si()
            },
            prefix: v,
            iconName: y,
            extra: S,
            watchable: !0
          })), N = Xe.createElementNS("http://www.w3.org/2000/svg", "svg");
          a === "::before" ? e.insertBefore(N, e.firstChild) : e.appendChild(N), N.outerHTML = O.map(function(M) {
            return jn(M);
          }).join(`
`), e.removeAttribute(t), n();
        }).catch(r);
      } else
        n();
    } else
      n();
  });
}
function jd(e) {
  return Promise.all([vo(e, "::before"), vo(e, "::after")]);
}
function Ud(e) {
  return e.parentNode !== document.head && !~Ec.indexOf(e.tagName.toUpperCase()) && !e.getAttribute(Cl) && (!e.parentNode || e.parentNode.tagName !== "svg");
}
function mo(e) {
  if (ma)
    return new Promise(function(a, t) {
      var n = bn(e.querySelectorAll("*")).filter(Ud).map(jd), r = ci.begin("searchPseudoElements");
      As(), Promise.all(n).then(function() {
        r(), Ll(), a();
      }).catch(function() {
        r(), Ll(), t();
      });
    });
}
var Wd = {
  hooks: function() {
    return {
      mutationObserverCallbacks: function(t) {
        return t.pseudoElementsCallback = mo, t;
      }
    };
  },
  provides: function(a) {
    a.pseudoElements2svg = function(t) {
      var n = t.node, r = n === void 0 ? Xe : n;
      ge.searchPseudoElements && mo(r);
    };
  }
}, po = !1, qd = {
  mixout: function() {
    return {
      dom: {
        unwatch: function() {
          As(), po = !0;
        }
      }
    };
  },
  hooks: function() {
    return {
      bootstrap: function() {
        so(Il("mutationObserverCallbacks", {}));
      },
      noAuto: function() {
        Cd();
      },
      watch: function(t) {
        var n = t.observeMutationsRoot;
        po ? Ll() : so(Il("mutationObserverCallbacks", {
          observeMutationsRoot: n
        }));
      }
    };
  }
}, ho = function(a) {
  var t = {
    size: 16,
    x: 0,
    y: 0,
    flipX: !1,
    flipY: !1,
    rotate: 0
  };
  return a.toLowerCase().split(" ").reduce(function(n, r) {
    var i = r.toLowerCase().split("-"), l = i[0], o = i.slice(1).join("-");
    if (l && o === "h")
      return n.flipX = !0, n;
    if (l && o === "v")
      return n.flipY = !0, n;
    if (o = parseFloat(o), isNaN(o))
      return n;
    switch (l) {
      case "grow":
        n.size = n.size + o;
        break;
      case "shrink":
        n.size = n.size - o;
        break;
      case "left":
        n.x = n.x - o;
        break;
      case "right":
        n.x = n.x + o;
        break;
      case "up":
        n.y = n.y - o;
        break;
      case "down":
        n.y = n.y + o;
        break;
      case "rotate":
        n.rotate = n.rotate + o;
        break;
    }
    return n;
  }, t);
}, Kd = {
  mixout: function() {
    return {
      parse: {
        transform: function(t) {
          return ho(t);
        }
      }
    };
  },
  hooks: function() {
    return {
      parseNodeAttributes: function(t, n) {
        var r = n.getAttribute("data-fa-transform");
        return r && (t.transform = ho(r)), t;
      }
    };
  },
  provides: function(a) {
    a.generateAbstractTransformGrouping = function(t) {
      var n = t.main, r = t.transform, i = t.containerWidth, l = t.iconWidth, o = {
        transform: "translate(".concat(i / 2, " 256)")
      }, s = "translate(".concat(r.x * 32, ", ").concat(r.y * 32, ") "), d = "scale(".concat(r.size / 16 * (r.flipX ? -1 : 1), ", ").concat(r.size / 16 * (r.flipY ? -1 : 1), ") "), u = "rotate(".concat(r.rotate, " 0 0)"), c = {
        transform: "".concat(s, " ").concat(d, " ").concat(u)
      }, f = {
        transform: "translate(".concat(l / 2 * -1, " -256)")
      }, v = {
        outer: o,
        inner: c,
        path: f
      };
      return {
        tag: "g",
        attributes: pe({}, v.outer),
        children: [{
          tag: "g",
          attributes: pe({}, v.inner),
          children: [{
            tag: n.icon.tag,
            children: n.icon.children,
            attributes: pe(pe({}, n.icon.attributes), v.path)
          }]
        }]
      };
    };
  }
}, cl = {
  x: 0,
  y: 0,
  width: "100%",
  height: "100%"
};
function yo(e) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !0;
  return e.attributes && (e.attributes.fill || a) && (e.attributes.fill = "black"), e;
}
function Xd(e) {
  return e.tag === "g" ? e.children : [e];
}
var Qd = {
  hooks: function() {
    return {
      parseNodeAttributes: function(t, n) {
        var r = n.getAttribute("data-fa-mask"), i = r ? $r(r.split(" ").map(function(l) {
          return l.trim();
        })) : si();
        return i.prefix || (i.prefix = Sa()), t.mask = i, t.maskId = n.getAttribute("data-fa-mask-id"), t;
      }
    };
  },
  provides: function(a) {
    a.generateAbstractMask = function(t) {
      var n = t.children, r = t.attributes, i = t.main, l = t.mask, o = t.maskId, s = t.transform, d = i.width, u = i.icon, c = l.width, f = l.icon, v = Kc({
        transform: s,
        containerWidth: c,
        iconWidth: d
      }), g = {
        tag: "rect",
        attributes: pe(pe({}, cl), {}, {
          fill: "white"
        })
      }, b = u.children ? {
        children: u.children.map(yo)
      } : {}, C = {
        tag: "g",
        attributes: pe({}, v.inner),
        children: [yo(pe({
          tag: u.tag,
          attributes: pe(pe({}, u.attributes), v.path)
        }, b))]
      }, p = {
        tag: "g",
        attributes: pe({}, v.outer),
        children: [C]
      }, w = "mask-".concat(o || Rn()), y = "clip-".concat(o || Rn()), x = {
        tag: "mask",
        attributes: pe(pe({}, cl), {}, {
          id: w,
          maskUnits: "userSpaceOnUse",
          maskContentUnits: "userSpaceOnUse"
        }),
        children: [g, p]
      }, D = {
        tag: "defs",
        children: [{
          tag: "clipPath",
          attributes: {
            id: y
          },
          children: Xd(f)
        }, x]
      };
      return n.push(D, {
        tag: "rect",
        attributes: pe({
          fill: "currentColor",
          "clip-path": "url(#".concat(y, ")"),
          mask: "url(#".concat(w, ")")
        }, cl)
      }), {
        children: n,
        attributes: r
      };
    };
  }
}, Gd = {
  provides: function(a) {
    var t = !1;
    Oa.matchMedia && (t = Oa.matchMedia("(prefers-reduced-motion: reduce)").matches), a.missingIconAbstract = function() {
      var n = [], r = {
        fill: "currentColor"
      }, i = {
        attributeType: "XML",
        repeatCount: "indefinite",
        dur: "2s"
      };
      n.push({
        tag: "path",
        attributes: pe(pe({}, r), {}, {
          d: "M156.5,447.7l-12.6,29.5c-18.7-9.5-35.9-21.2-51.5-34.9l22.7-22.7C127.6,430.5,141.5,440,156.5,447.7z M40.6,272H8.5 c1.4,21.2,5.4,41.7,11.7,61.1L50,321.2C45.1,305.5,41.8,289,40.6,272z M40.6,240c1.4-18.8,5.2-37,11.1-54.1l-29.5-12.6 C14.7,194.3,10,216.7,8.5,240H40.6z M64.3,156.5c7.8-14.9,17.2-28.8,28.1-41.5L69.7,92.3c-13.7,15.6-25.5,32.8-34.9,51.5 L64.3,156.5z M397,419.6c-13.9,12-29.4,22.3-46.1,30.4l11.9,29.8c20.7-9.9,39.8-22.6,56.9-37.6L397,419.6z M115,92.4 c13.9-12,29.4-22.3,46.1-30.4l-11.9-29.8c-20.7,9.9-39.8,22.6-56.8,37.6L115,92.4z M447.7,355.5c-7.8,14.9-17.2,28.8-28.1,41.5 l22.7,22.7c13.7-15.6,25.5-32.9,34.9-51.5L447.7,355.5z M471.4,272c-1.4,18.8-5.2,37-11.1,54.1l29.5,12.6 c7.5-21.1,12.2-43.5,13.6-66.8H471.4z M321.2,462c-15.7,5-32.2,8.2-49.2,9.4v32.1c21.2-1.4,41.7-5.4,61.1-11.7L321.2,462z M240,471.4c-18.8-1.4-37-5.2-54.1-11.1l-12.6,29.5c21.1,7.5,43.5,12.2,66.8,13.6V471.4z M462,190.8c5,15.7,8.2,32.2,9.4,49.2h32.1 c-1.4-21.2-5.4-41.7-11.7-61.1L462,190.8z M92.4,397c-12-13.9-22.3-29.4-30.4-46.1l-29.8,11.9c9.9,20.7,22.6,39.8,37.6,56.9 L92.4,397z M272,40.6c18.8,1.4,36.9,5.2,54.1,11.1l12.6-29.5C317.7,14.7,295.3,10,272,8.5V40.6z M190.8,50 c15.7-5,32.2-8.2,49.2-9.4V8.5c-21.2,1.4-41.7,5.4-61.1,11.7L190.8,50z M442.3,92.3L419.6,115c12,13.9,22.3,29.4,30.5,46.1 l29.8-11.9C470,128.5,457.3,109.4,442.3,92.3z M397,92.4l22.7-22.7c-15.6-13.7-32.8-25.5-51.5-34.9l-12.6,29.5 C370.4,72.1,384.4,81.5,397,92.4z"
        })
      });
      var l = pe(pe({}, i), {}, {
        attributeName: "opacity"
      }), o = {
        tag: "circle",
        attributes: pe(pe({}, r), {}, {
          cx: "256",
          cy: "364",
          r: "28"
        }),
        children: []
      };
      return t || o.children.push({
        tag: "animate",
        attributes: pe(pe({}, i), {}, {
          attributeName: "r",
          values: "28;14;28;28;14;28;"
        })
      }, {
        tag: "animate",
        attributes: pe(pe({}, l), {}, {
          values: "1;0;1;1;0;1;"
        })
      }), n.push(o), n.push({
        tag: "path",
        attributes: pe(pe({}, r), {}, {
          opacity: "1",
          d: "M263.7,312h-16c-6.6,0-12-5.4-12-12c0-71,77.4-63.9,77.4-107.8c0-20-17.8-40.2-57.4-40.2c-29.1,0-44.3,9.6-59.2,28.7 c-3.9,5-11.1,6-16.2,2.4l-13.1-9.2c-5.6-3.9-6.9-11.8-2.6-17.2c21.2-27.2,46.4-44.7,91.2-44.7c52.3,0,97.4,29.8,97.4,80.2 c0,67.6-77.4,63.5-77.4,107.8C275.7,306.6,270.3,312,263.7,312z"
        }),
        children: t ? [] : [{
          tag: "animate",
          attributes: pe(pe({}, l), {}, {
            values: "1;0;0;0;0;1;"
          })
        }]
      }), t || n.push({
        tag: "path",
        attributes: pe(pe({}, r), {}, {
          opacity: "0",
          d: "M232.5,134.5l7,168c0.3,6.4,5.6,11.5,12,11.5h9c6.4,0,11.7-5.1,12-11.5l7-168c0.3-6.8-5.2-12.5-12-12.5h-23 C237.7,122,232.2,127.7,232.5,134.5z"
        }),
        children: [{
          tag: "animate",
          attributes: pe(pe({}, l), {}, {
            values: "0;0;1;1;0;0;"
          })
        }]
      }), {
        tag: "g",
        attributes: {
          class: "missing"
        },
        children: n
      };
    };
  }
}, Jd = {
  hooks: function() {
    return {
      parseNodeAttributes: function(t, n) {
        var r = n.getAttribute("data-fa-symbol"), i = r === null ? !1 : r === "" ? !0 : r;
        return t.symbol = i, t;
      }
    };
  }
}, Zd = [Gc, Hd, zd, Fd, Vd, Wd, qd, Kd, Qd, Gd, Jd];
fd(Zd, {
  mixoutsTo: Rt
});
Rt.noAuto;
var Is = Rt.config, ef = Rt.library;
Rt.dom;
var xr = Rt.parse;
Rt.findIconDefinition;
Rt.toHtml;
var tf = Rt.icon;
Rt.layer;
var af = Rt.text;
Rt.counter;
var nf = {
  prefix: "fas",
  iconName: "sort-down",
  icon: [320, 512, ["sort-desc"], "f0dd", "M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"]
}, rf = {
  prefix: "fas",
  iconName: "pencil",
  icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"]
}, lf = {
  prefix: "fas",
  iconName: "caret-right",
  icon: [256, 512, [], "f0da", "M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"]
}, of = {
  prefix: "fas",
  iconName: "table",
  icon: [512, 512, [], "f0ce", "M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
}, sf = {
  prefix: "fas",
  iconName: "bars",
  icon: [448, 512, ["navicon"], "f0c9", "M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, uf = {
  prefix: "fas",
  iconName: "list",
  icon: [512, 512, ["list-squares"], "f03a", "M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"]
}, cf = {
  prefix: "fas",
  iconName: "eye-slash",
  icon: [640, 512, [], "f070", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"]
}, df = {
  prefix: "fas",
  iconName: "chevron-up",
  icon: [512, 512, [], "f077", "M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"]
}, ff = {
  prefix: "fas",
  iconName: "angle-right",
  icon: [320, 512, [8250], "f105", "M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"]
}, vf = {
  prefix: "fas",
  iconName: "bullseye",
  icon: [512, 512, [], "f140", "M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
}, mf = {
  prefix: "fas",
  iconName: "bullhorn",
  icon: [512, 512, [128226, 128363], "f0a1", "M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"]
}, pf = {
  prefix: "fas",
  iconName: "ban",
  icon: [512, 512, [128683, "cancel"], "f05e", "M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"]
}, hf = {
  prefix: "fas",
  iconName: "shuffle",
  icon: [512, 512, [128256, "random"], "f074", "M403.8 34.4c12-5 25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160H352c-10.1 0-19.6 4.7-25.6 12.8L284 229.3 244 176l31.2-41.6C293.3 110.2 321.8 96 352 96h32V64c0-12.9 7.8-24.6 19.8-29.6zM164 282.7L204 336l-31.2 41.6C154.7 401.8 126.2 416 96 416H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c10.1 0 19.6-4.7 25.6-12.8L164 282.7zm274.6 188c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V416H352c-30.2 0-58.7-14.2-76.8-38.4L121.6 172.8c-6-8.1-15.5-12.8-25.6-12.8H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c30.2 0 58.7 14.2 76.8 38.4L326.4 339.2c6 8.1 15.5 12.8 25.6 12.8h32V320c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64z"]
}, yf = {
  prefix: "fas",
  iconName: "arrow-right-to-bracket",
  icon: [512, 512, ["sign-in"], "f090", "M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"]
}, gf = {
  prefix: "fas",
  iconName: "hourglass-start",
  icon: [384, 512, ["hourglass-1"], "f251", "M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM288 437v11H96V437c0-25.5 10.1-49.9 28.1-67.9L192 301.3l67.9 67.9c18 18 28.1 42.4 28.1 67.9z"]
}, bf = {
  prefix: "fas",
  iconName: "right-to-bracket",
  icon: [512, 512, ["sign-in-alt"], "f2f6", "M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"]
}, wf = {
  prefix: "fas",
  iconName: "clipboard",
  icon: [384, 512, [128203], "f328", "M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"]
}, xf = {
  prefix: "fas",
  iconName: "underline",
  icon: [448, 512, [], "f0cd", "M16 64c0-17.7 14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H128V224c0 53 43 96 96 96s96-43 96-96V96H304c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H384V224c0 88.4-71.6 160-160 160s-160-71.6-160-160V96H48C30.3 96 16 81.7 16 64zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32z"]
}, kf = {
  prefix: "fas",
  iconName: "bold",
  icon: [384, 512, [], "f032", "M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z"]
}, _f = {
  prefix: "fas",
  iconName: "image",
  icon: [512, 512, [], "f03e", "M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"]
}, Mf = {
  prefix: "fas",
  iconName: "caret-up",
  icon: [320, 512, [], "f0d8", "M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"]
}, Of = {
  prefix: "fas",
  iconName: "rotate-left",
  icon: [512, 512, ["rotate-back", "rotate-backward", "undo-alt"], "f2ea", "M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"]
}, Sf = {
  prefix: "fas",
  iconName: "circle-check",
  icon: [512, 512, [61533, "check-circle"], "f058", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"]
}, Tf = {
  prefix: "fas",
  iconName: "pause",
  icon: [320, 512, [9208], "f04c", "M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"]
}, Pf = {
  prefix: "fas",
  iconName: "subscript",
  icon: [512, 512, [], "f12c", "M32 64C14.3 64 0 78.3 0 96s14.3 32 32 32H47.3l89.6 128L47.3 384H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H64c10.4 0 20.2-5.1 26.2-13.6L176 311.8l85.8 122.6c6 8.6 15.8 13.6 26.2 13.6h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H304.7L215.1 256l89.6-128H320c17.7 0 32-14.3 32-32s-14.3-32-32-32H288c-10.4 0-20.2 5.1-26.2 13.6L176 200.2 90.2 77.6C84.2 69.1 74.4 64 64 64H32zM480 320c0-11.1-5.7-21.4-15.2-27.2s-21.2-6.4-31.1-1.4l-32 16c-15.8 7.9-22.2 27.1-14.3 42.9C393 361.5 404.3 368 416 368v80c-17.7 0-32 14.3-32 32s14.3 32 32 32h32 32c17.7 0 32-14.3 32-32s-14.3-32-32-32V320z"]
}, Cf = {
  prefix: "fas",
  iconName: "arrows-rotate",
  icon: [512, 512, [128472, "refresh", "sync"], "f021", "M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"]
}, Af = {
  prefix: "fas",
  iconName: "italic",
  icon: [384, 512, [], "f033", "M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"]
}, Df = {
  prefix: "fas",
  iconName: "list-ol",
  icon: [512, 512, ["list-1-2", "list-numeric"], "f0cb", "M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"]
}, If = {
  prefix: "fas",
  iconName: "vector-square",
  icon: [448, 512, [], "f5cb", "M368 80h32v32H368V80zM352 32c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v64c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V384c0-17.7-14.3-32-32-32V160c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H352zM96 160c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32V160zM48 400H80v32H48V400zm320 32V400h32v32H368zM48 112V80H80v32H48z"]
}, Ef = {
  prefix: "fas",
  iconName: "filter",
  icon: [512, 512, [], "f0b0", "M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"]
}, $f = {
  prefix: "fas",
  iconName: "question",
  icon: [320, 512, [10067, 10068, 61736], "3f", "M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"]
}, Rf = {
  prefix: "fas",
  iconName: "arrow-up-right-from-square",
  icon: [512, 512, ["external-link"], "f08e", "M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"]
}, Nf = Rf, Lf = {
  prefix: "fas",
  iconName: "arrow-right",
  icon: [448, 512, [8594], "f061", "M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"]
}, Hf = {
  prefix: "fas",
  iconName: "volume-high",
  icon: [640, 512, [128266, "volume-up"], "f028", "M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"]
}, zf = {
  prefix: "fas",
  iconName: "circle-question",
  icon: [512, 512, [62108, "question-circle"], "f059", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
}, Ff = zf, Vf = {
  prefix: "fas",
  iconName: "eye",
  icon: [576, 512, [128065], "f06e", "M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"]
}, Yf = {
  prefix: "fas",
  iconName: "sort-up",
  icon: [320, 512, ["sort-asc"], "f0de", "M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"]
}, Bf = {
  prefix: "fas",
  iconName: "trash",
  icon: [448, 512, [], "f1f8", "M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"]
}, jf = {
  prefix: "fas",
  iconName: "arrow-left",
  icon: [448, 512, [8592], "f060", "M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"]
}, Uf = {
  prefix: "fas",
  iconName: "align-left",
  icon: [448, 512, [], "f036", "M288 64c0 17.7-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32H256c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, Wf = {
  prefix: "fas",
  iconName: "file-pdf",
  icon: [512, 512, [], "f1c1", "M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"]
}, qf = {
  prefix: "fas",
  iconName: "table-cells-large",
  icon: [512, 512, ["th-large"], "f009", "M448 96V224H288V96H448zm0 192V416H288V288H448zM224 224H64V96H224V224zM64 288H224V416H64V288zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
}, Kf = {
  prefix: "fas",
  iconName: "indent",
  icon: [448, 512, [], "f03c", "M0 64C0 46.3 14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64zM192 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zm32 96H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM127.8 268.6L25.8 347.9C15.3 356.1 0 348.6 0 335.3V176.7c0-13.3 15.3-20.8 25.8-12.6l101.9 79.3c8.2 6.4 8.2 18.9 0 25.3z"]
}, Xf = {
  prefix: "fas",
  iconName: "circle-info",
  icon: [512, 512, ["info-circle"], "f05a", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"]
}, Qf = {
  prefix: "fas",
  iconName: "gear",
  icon: [512, 512, [9881, "cog"], "f013", "M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"]
}, Gf = {
  prefix: "fas",
  iconName: "grip-vertical",
  icon: [320, 512, [], "f58e", "M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z"]
}, Jf = {
  prefix: "fas",
  iconName: "square-root-variable",
  icon: [576, 512, ["square-root-alt"], "f698", "M282.6 78.1c8-27.3 33-46.1 61.4-46.1H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H344L238.7 457c-3.6 12.3-14.1 21.2-26.8 22.8s-25.1-4.6-31.5-15.6L77.6 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H77.6c22.8 0 43.8 12.1 55.3 31.8l65.2 111.8L282.6 78.1zM393.4 233.4c12.5-12.5 32.8-12.5 45.3 0L480 274.7l41.4-41.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L525.3 320l41.4 41.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L480 365.3l-41.4 41.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L434.7 320l-41.4-41.4c-12.5-12.5-12.5-32.8 0-45.3z"]
}, Zf = {
  prefix: "fas",
  iconName: "caret-down",
  icon: [320, 512, [], "f0d7", "M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"]
}, ev = {
  prefix: "fas",
  iconName: "ellipsis-vertical",
  icon: [128, 512, ["ellipsis-v"], "f142", "M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"]
}, tv = ev, av = {
  prefix: "fas",
  iconName: "align-center",
  icon: [448, 512, [], "f037", "M352 64c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32zm96 128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 448c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM352 320c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32z"]
}, nv = {
  prefix: "fas",
  iconName: "outdent",
  icon: [448, 512, ["dedent"], "f03b", "M0 64C0 46.3 14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64zM192 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zm32 96H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM.2 268.6c-8.2-6.4-8.2-18.9 0-25.3l101.9-79.3c10.5-8.2 25.8-.7 25.8 12.6V335.3c0 13.3-15.3 20.8-25.8 12.6L.2 268.6z"]
}, rv = {
  prefix: "fas",
  iconName: "angle-down",
  icon: [448, 512, [8964], "f107", "M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"]
}, lv = {
  prefix: "fas",
  iconName: "ellipsis",
  icon: [448, 512, ["ellipsis-h"], "f141", "M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"]
}, iv = lv, ov = {
  prefix: "fas",
  iconName: "superscript",
  icon: [512, 512, [], "f12b", "M480 32c0-11.1-5.7-21.4-15.2-27.2s-21.2-6.4-31.1-1.4l-32 16c-15.8 7.9-22.2 27.1-14.3 42.9C393 73.5 404.3 80 416 80v80c-17.7 0-32 14.3-32 32s14.3 32 32 32h32 32c17.7 0 32-14.3 32-32s-14.3-32-32-32V32zM32 64C14.3 64 0 78.3 0 96s14.3 32 32 32H47.3l89.6 128L47.3 384H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H64c10.4 0 20.2-5.1 26.2-13.6L176 311.8l85.8 122.6c6 8.6 15.8 13.6 26.2 13.6h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H304.7L215.1 256l89.6-128H320c17.7 0 32-14.3 32-32s-14.3-32-32-32H288c-10.4 0-20.2 5.1-26.2 13.6L176 200.2 90.2 77.6C84.2 69.1 74.4 64 64 64H32z"]
}, sv = {
  prefix: "fas",
  iconName: "link",
  icon: [640, 512, [128279, "chain"], "f0c1", "M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"]
}, uv = {
  prefix: "fas",
  iconName: "play",
  icon: [384, 512, [9654], "f04b", "M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"]
}, Es = {
  prefix: "fas",
  iconName: "magnifying-glass",
  icon: [512, 512, [128269, "search"], "f002", "M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"]
}, cv = Es, dv = {
  prefix: "fas",
  iconName: "chevron-down",
  icon: [512, 512, [], "f078", "M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"]
}, fv = {
  prefix: "fas",
  iconName: "list-ul",
  icon: [512, 512, ["list-dots"], "f0ca", "M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"]
}, vv = {
  prefix: "fas",
  iconName: "copy",
  icon: [448, 512, [], "f0c5", "M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"]
}, mv = {
  prefix: "fas",
  iconName: "grip",
  icon: [448, 512, ["grip-horizontal"], "f58d", "M128 136c0-22.1-17.9-40-40-40L40 96C17.9 96 0 113.9 0 136l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40l0-48zm0 192c0-22.1-17.9-40-40-40H40c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM288 328c0-22.1-17.9-40-40-40H200c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM448 328c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328z"]
}, pv = {
  prefix: "fas",
  iconName: "plus",
  icon: [448, 512, [10133, 61543, "add"], "2b", "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"]
}, $s = {
  prefix: "fas",
  iconName: "xmark",
  icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"]
}, hv = $s, yv = {
  prefix: "fas",
  iconName: "arrows-up-down-left-right",
  icon: [512, 512, ["arrows"], "f047", "M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l9.4-9.4V224H109.3l9.4-9.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4H224V402.7l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-9.4 9.4V288H402.7l-9.4 9.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l9.4 9.4H288V109.3l9.4 9.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64z"]
}, gv = {
  prefix: "fas",
  iconName: "chevron-left",
  icon: [320, 512, [9001], "f053", "M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"]
}, bv = {
  prefix: "fas",
  iconName: "chevron-right",
  icon: [320, 512, [9002], "f054", "M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"]
}, wv = {
  prefix: "fas",
  iconName: "calendar",
  icon: [448, 512, [128197, 128198], "f133", "M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z"]
}, xv = {
  prefix: "fas",
  iconName: "link-slash",
  icon: [640, 512, ["chain-broken", "chain-slash", "unlink"], "f127", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L489.3 358.2l90.5-90.5c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114l-96 96-31.9-25C430.9 239.6 420.1 175.1 377 132c-52.2-52.3-134.5-56.2-191.3-11.7L38.8 5.1zM239 162c30.1-14.9 67.7-9.9 92.8 15.3c20 20 27.5 48.3 21.7 74.5L239 162zM116.6 187.9L60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5l61.8-61.8-50.6-39.9zM220.9 270c-2.1 39.8 12.2 80.1 42.2 110c38.9 38.9 94.4 51 143.6 36.3L220.9 270z"]
}, kv = xv, _v = {
  prefix: "fas",
  iconName: "user-plus",
  icon: [640, 512, [], "f234", "M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"]
}, Mv = {
  prefix: "fas",
  iconName: "check",
  icon: [448, 512, [10003, 10004], "f00c", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]
}, Ov = {
  prefix: "fas",
  iconName: "triangle-exclamation",
  icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"]
}, Sv = {
  prefix: "fas",
  iconName: "align-right",
  icon: [448, 512, [], "f038", "M448 64c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, Tv = {
  prefix: "fas",
  iconName: "exclamation",
  icon: [64, 512, [10069, 10071, 61738], "21", "M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM32 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"]
}, Pv = {
  prefix: "fas",
  iconName: "dollar-sign",
  icon: [320, 512, [128178, 61781, "dollar", "usd"], "24", "M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"]
}, Cv = Pv, Av = {
  prefix: "far",
  iconName: "lightbulb",
  icon: [384, 512, [128161], "f0eb", "M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"]
};
function go(e, a) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    a && (n = n.filter(function(r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, n);
  }
  return t;
}
function Yt(e) {
  for (var a = 1; a < arguments.length; a++) {
    var t = arguments[a] != null ? arguments[a] : {};
    a % 2 ? go(Object(t), !0).forEach(function(n) {
      Tt(e, n, t[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : go(Object(t)).forEach(function(n) {
      Object.defineProperty(e, n, Object.getOwnPropertyDescriptor(t, n));
    });
  }
  return e;
}
function kr(e) {
  "@babel/helpers - typeof";
  return kr = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(a) {
    return typeof a;
  } : function(a) {
    return a && typeof Symbol == "function" && a.constructor === Symbol && a !== Symbol.prototype ? "symbol" : typeof a;
  }, kr(e);
}
function Tt(e, a, t) {
  return a = Hv(a), a in e ? Object.defineProperty(e, a, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[a] = t, e;
}
function Dv(e, a) {
  if (e == null)
    return {};
  var t = {}, n = Object.keys(e), r, i;
  for (i = 0; i < n.length; i++)
    r = n[i], !(a.indexOf(r) >= 0) && (t[r] = e[r]);
  return t;
}
function Iv(e, a) {
  if (e == null)
    return {};
  var t = Dv(e, a), n, r;
  if (Object.getOwnPropertySymbols) {
    var i = Object.getOwnPropertySymbols(e);
    for (r = 0; r < i.length; r++)
      n = i[r], !(a.indexOf(n) >= 0) && Object.prototype.propertyIsEnumerable.call(e, n) && (t[n] = e[n]);
  }
  return t;
}
function Hl(e) {
  return Ev(e) || $v(e) || Rv(e) || Nv();
}
function Ev(e) {
  if (Array.isArray(e))
    return zl(e);
}
function $v(e) {
  if (typeof Symbol < "u" && e[Symbol.iterator] != null || e["@@iterator"] != null)
    return Array.from(e);
}
function Rv(e, a) {
  if (e) {
    if (typeof e == "string")
      return zl(e, a);
    var t = Object.prototype.toString.call(e).slice(8, -1);
    if (t === "Object" && e.constructor && (t = e.constructor.name), t === "Map" || t === "Set")
      return Array.from(e);
    if (t === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t))
      return zl(e, a);
  }
}
function zl(e, a) {
  (a == null || a > e.length) && (a = e.length);
  for (var t = 0, n = new Array(a); t < a; t++)
    n[t] = e[t];
  return n;
}
function Nv() {
  throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function Lv(e, a) {
  if (typeof e != "object" || e === null)
    return e;
  var t = e[Symbol.toPrimitive];
  if (t !== void 0) {
    var n = t.call(e, a || "default");
    if (typeof n != "object")
      return n;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (a === "string" ? String : Number)(e);
}
function Hv(e) {
  var a = Lv(e, "string");
  return typeof a == "symbol" ? a : String(a);
}
var zv = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, Rs = { exports: {} };
(function(e) {
  (function(a) {
    var t = function(p, w, y) {
      if (!d(w) || c(w) || f(w) || v(w) || s(w))
        return w;
      var x, D = 0, S = 0;
      if (u(w))
        for (x = [], S = w.length; D < S; D++)
          x.push(t(p, w[D], y));
      else {
        x = {};
        for (var $ in w)
          Object.prototype.hasOwnProperty.call(w, $) && (x[p($, y)] = t(p, w[$], y));
      }
      return x;
    }, n = function(p, w) {
      w = w || {};
      var y = w.separator || "_", x = w.split || /(?=[A-Z])/;
      return p.split(x).join(y);
    }, r = function(p) {
      return g(p) ? p : (p = p.replace(/[\-_\s]+(.)?/g, function(w, y) {
        return y ? y.toUpperCase() : "";
      }), p.substr(0, 1).toLowerCase() + p.substr(1));
    }, i = function(p) {
      var w = r(p);
      return w.substr(0, 1).toUpperCase() + w.substr(1);
    }, l = function(p, w) {
      return n(p, w).toLowerCase();
    }, o = Object.prototype.toString, s = function(p) {
      return typeof p == "function";
    }, d = function(p) {
      return p === Object(p);
    }, u = function(p) {
      return o.call(p) == "[object Array]";
    }, c = function(p) {
      return o.call(p) == "[object Date]";
    }, f = function(p) {
      return o.call(p) == "[object RegExp]";
    }, v = function(p) {
      return o.call(p) == "[object Boolean]";
    }, g = function(p) {
      return p = p - 0, p === p;
    }, b = function(p, w) {
      var y = w && "process" in w ? w.process : w;
      return typeof y != "function" ? p : function(x, D) {
        return y(x, p, D);
      };
    }, C = {
      camelize: r,
      decamelize: l,
      pascalize: i,
      depascalize: l,
      camelizeKeys: function(p, w) {
        return t(b(r, w), p);
      },
      decamelizeKeys: function(p, w) {
        return t(b(l, w), p, w);
      },
      pascalizeKeys: function(p, w) {
        return t(b(i, w), p);
      },
      depascalizeKeys: function() {
        return this.decamelizeKeys.apply(this, arguments);
      }
    };
    e.exports ? e.exports = C : a.humps = C;
  })(zv);
})(Rs);
var Fv = Rs.exports, Vv = ["class", "style"];
function Yv(e) {
  return e.split(";").map(function(a) {
    return a.trim();
  }).filter(function(a) {
    return a;
  }).reduce(function(a, t) {
    var n = t.indexOf(":"), r = Fv.camelize(t.slice(0, n)), i = t.slice(n + 1).trim();
    return a[r] = i, a;
  }, {});
}
function Bv(e) {
  return e.split(/\s+/).reduce(function(a, t) {
    return a[t] = !0, a;
  }, {});
}
function fi(e) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, t = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  if (typeof e == "string")
    return e;
  var n = (e.children || []).map(function(s) {
    return fi(s);
  }), r = Object.keys(e.attributes || {}).reduce(function(s, d) {
    var u = e.attributes[d];
    switch (d) {
      case "class":
        s.class = Bv(u);
        break;
      case "style":
        s.style = Yv(u);
        break;
      default:
        s.attrs[d] = u;
    }
    return s;
  }, {
    attrs: {},
    class: {},
    style: {}
  });
  t.class;
  var i = t.style, l = i === void 0 ? {} : i, o = Iv(t, Vv);
  return At(e.tag, Yt(Yt(Yt({}, a), {}, {
    class: r.class,
    style: Yt(Yt({}, r.style), l)
  }, r.attrs), o), n);
}
var Ns = !1;
try {
  Ns = process.env.NODE_ENV === "production";
} catch {
}
function jv() {
  if (!Ns && console && typeof console.error == "function") {
    var e;
    (e = console).error.apply(e, arguments);
  }
}
function Pn(e, a) {
  return Array.isArray(a) && a.length > 0 || !Array.isArray(a) && a ? Tt({}, e, a) : {};
}
function Uv(e) {
  var a, t = (a = {
    "fa-spin": e.spin,
    "fa-pulse": e.pulse,
    "fa-fw": e.fixedWidth,
    "fa-border": e.border,
    "fa-li": e.listItem,
    "fa-inverse": e.inverse,
    "fa-flip": e.flip === !0,
    "fa-flip-horizontal": e.flip === "horizontal" || e.flip === "both",
    "fa-flip-vertical": e.flip === "vertical" || e.flip === "both"
  }, Tt(a, "fa-".concat(e.size), e.size !== null), Tt(a, "fa-rotate-".concat(e.rotation), e.rotation !== null), Tt(a, "fa-pull-".concat(e.pull), e.pull !== null), Tt(a, "fa-swap-opacity", e.swapOpacity), Tt(a, "fa-bounce", e.bounce), Tt(a, "fa-shake", e.shake), Tt(a, "fa-beat", e.beat), Tt(a, "fa-fade", e.fade), Tt(a, "fa-beat-fade", e.beatFade), Tt(a, "fa-flash", e.flash), Tt(a, "fa-spin-pulse", e.spinPulse), Tt(a, "fa-spin-reverse", e.spinReverse), a);
  return Object.keys(t).map(function(n) {
    return t[n] ? n : null;
  }).filter(function(n) {
    return n;
  });
}
function bo(e) {
  if (e && kr(e) === "object" && e.prefix && e.iconName && e.icon)
    return e;
  if (xr.icon)
    return xr.icon(e);
  if (e === null)
    return null;
  if (kr(e) === "object" && e.prefix && e.iconName)
    return e;
  if (Array.isArray(e) && e.length === 2)
    return {
      prefix: e[0],
      iconName: e[1]
    };
  if (typeof e == "string")
    return {
      prefix: "fas",
      iconName: e
    };
}
var qt = be({
  name: "FontAwesomeIcon",
  props: {
    border: {
      type: Boolean,
      default: !1
    },
    fixedWidth: {
      type: Boolean,
      default: !1
    },
    flip: {
      type: [Boolean, String],
      default: !1,
      validator: function(a) {
        return [!0, !1, "horizontal", "vertical", "both"].indexOf(a) > -1;
      }
    },
    icon: {
      type: [Object, Array, String],
      required: !0
    },
    mask: {
      type: [Object, Array, String],
      default: null
    },
    maskId: {
      type: String,
      default: null
    },
    listItem: {
      type: Boolean,
      default: !1
    },
    pull: {
      type: String,
      default: null,
      validator: function(a) {
        return ["right", "left"].indexOf(a) > -1;
      }
    },
    pulse: {
      type: Boolean,
      default: !1
    },
    rotation: {
      type: [String, Number],
      default: null,
      validator: function(a) {
        return [90, 180, 270].indexOf(Number.parseInt(a, 10)) > -1;
      }
    },
    swapOpacity: {
      type: Boolean,
      default: !1
    },
    size: {
      type: String,
      default: null,
      validator: function(a) {
        return ["2xs", "xs", "sm", "lg", "xl", "2xl", "1x", "2x", "3x", "4x", "5x", "6x", "7x", "8x", "9x", "10x"].indexOf(a) > -1;
      }
    },
    spin: {
      type: Boolean,
      default: !1
    },
    transform: {
      type: [String, Object],
      default: null
    },
    symbol: {
      type: [Boolean, String],
      default: !1
    },
    title: {
      type: String,
      default: null
    },
    titleId: {
      type: String,
      default: null
    },
    inverse: {
      type: Boolean,
      default: !1
    },
    bounce: {
      type: Boolean,
      default: !1
    },
    shake: {
      type: Boolean,
      default: !1
    },
    beat: {
      type: Boolean,
      default: !1
    },
    fade: {
      type: Boolean,
      default: !1
    },
    beatFade: {
      type: Boolean,
      default: !1
    },
    flash: {
      type: Boolean,
      default: !1
    },
    spinPulse: {
      type: Boolean,
      default: !1
    },
    spinReverse: {
      type: Boolean,
      default: !1
    }
  },
  setup: function(a, t) {
    var n = t.attrs, r = A(function() {
      return bo(a.icon);
    }), i = A(function() {
      return Pn("classes", Uv(a));
    }), l = A(function() {
      return Pn("transform", typeof a.transform == "string" ? xr.transform(a.transform) : a.transform);
    }), o = A(function() {
      return Pn("mask", bo(a.mask));
    }), s = A(function() {
      return tf(r.value, Yt(Yt(Yt(Yt({}, i.value), l.value), o.value), {}, {
        symbol: a.symbol,
        title: a.title,
        titleId: a.titleId,
        maskId: a.maskId
      }));
    });
    Ge(s, function(u) {
      if (!u)
        return jv("Could not find one or more icon(s)", r.value, o.value);
    }, {
      immediate: !0
    });
    var d = A(function() {
      return s.value ? fi(s.value.abstract[0], {}, n) : null;
    });
    return function() {
      return d.value;
    };
  }
}), K3 = be({
  name: "FontAwesomeLayers",
  props: {
    fixedWidth: {
      type: Boolean,
      default: !1
    }
  },
  setup: function(a, t) {
    var n = t.slots, r = Is.familyPrefix, i = A(function() {
      return ["".concat(r, "-layers")].concat(Hl(a.fixedWidth ? ["".concat(r, "-fw")] : []));
    });
    return function() {
      return At("div", {
        class: i.value
      }, n.default ? n.default() : []);
    };
  }
});
be({
  name: "FontAwesomeLayersText",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    transform: {
      type: [String, Object],
      default: null
    },
    counter: {
      type: Boolean,
      default: !1
    },
    position: {
      type: String,
      default: null,
      validator: function(a) {
        return ["bottom-left", "bottom-right", "top-left", "top-right"].indexOf(a) > -1;
      }
    }
  },
  setup: function(a, t) {
    var n = t.attrs, r = Is.familyPrefix, i = A(function() {
      return Pn("classes", [].concat(Hl(a.counter ? ["".concat(r, "-layers-counter")] : []), Hl(a.position ? ["".concat(r, "-layers-").concat(a.position)] : [])));
    }), l = A(function() {
      return Pn("transform", typeof a.transform == "string" ? xr.transform(a.transform) : a.transform);
    }), o = A(function() {
      var d = af(a.value.toString(), Yt(Yt({}, l.value), i.value)), u = d.abstract;
      return a.counter && (u[0].attributes.class = u[0].attributes.class.replace("fa-layers-text", "")), u[0];
    }), s = A(function() {
      return fi(o.value, {}, n);
    });
    return function() {
      return s.value;
    };
  }
});
ef.add(
  Av,
  av,
  Uf,
  Sv,
  rv,
  ff,
  jf,
  Lf,
  Cf,
  yv,
  pf,
  sf,
  kf,
  mf,
  vf,
  wv,
  Zf,
  lf,
  Mf,
  Mv,
  dv,
  gv,
  bv,
  df,
  Sf,
  Xf,
  wf,
  vv,
  Cv,
  iv,
  tv,
  Nf,
  Tv,
  Vf,
  cf,
  Wf,
  Ef,
  Qf,
  mv,
  Gf,
  gf,
  _f,
  Kf,
  Af,
  sv,
  uf,
  Df,
  fv,
  Es,
  nv,
  Tf,
  rf,
  uv,
  pv,
  Ff,
  $f,
  cv,
  nf,
  Yf,
  Jf,
  Pf,
  ov,
  of,
  qf,
  hv,
  Bf,
  Ov,
  xf,
  kv,
  Hf,
  _v,
  $s,
  bf,
  Of,
  yf,
  If,
  hf
);
const Wv = ["type"], X3 = /* @__PURE__ */ be({
  inheritAttrs: !1,
  __name: "UITextInput",
  props: {
    type: { default: "text" },
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    icon: {},
    controlled: { type: Boolean, default: !0 }
  },
  setup(e) {
    const a = e, { value: t, errorMessage: n } = Ia(
      Qe(a, "name"),
      Qe(a, "rules"),
      {
        label: Qe(a, "label"),
        syncVModel: !0,
        controlled: a.controlled
      }
    ), r = Zo(), i = A(() => {
      const { class: d, style: u } = r;
      return { class: d, style: u };
    }), l = A(() => {
      const { class: d, style: u, ...c } = r;
      return c;
    }), o = z(!1), s = (d) => {
      o.value = d.getModifierState && d.getModifierState("CapsLock");
    };
    return (d, u) => d.type !== "multiline" ? (P(), Y("div", Ue({
      key: 0,
      class: "relative"
    }, i.value), [
      ka(ie("input", Ue(l.value, {
        onKeydown: s,
        "onUpdate:modelValue": u[0] || (u[0] = (c) => ra(t) ? t.value = c : null),
        type: d.type,
        class: [
          "tw-reset w-full px-2 h-8 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
          { "pl-8": d.icon },
          {
            "border-warning-orange-600 text-warning-orange-600 italic": m(n)
          },
          {
            "pl-6": o.value && d.type === "password"
          }
        ]
      }), null, 16, Wv), [
        [vc, m(t)]
      ]),
      d.icon ? (P(), Me(m(qt), {
        key: 0,
        icon: d.icon,
        class: "absolute left-2 top-1/2 transform -translate-y-1/2 text-ui-gray-800 disabled:text-ui-gray-300"
      }, null, 8, ["icon"])) : G("", !0),
      o.value && d.type === "password" ? (P(), Me(m(qt), {
        key: 1,
        title: "Caps Lock is on",
        icon: "arrow-up-from-bracket",
        class: "absolute left-2 top-1/2 transform -translate-y-1/2 border-warning-orange-600 disabled:text-ui-gray-300"
      })) : G("", !0)
    ], 16)) : ka((P(), Y("textarea", Ue({ key: 1 }, m(r), {
      "onUpdate:modelValue": u[1] || (u[1] = (c) => ra(t) ? t.value = c : null),
      class: [
        "tw-reset px-2 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
        {
          "border-warning-orange-600 text-warning-orange-600 italic": m(n)
        }
      ]
    }), null, 16)), [
      [es, m(t)]
    ]);
  }
}), Q3 = /* @__PURE__ */ be({
  __name: "UIFieldError",
  props: {
    name: {}
  },
  setup(e) {
    return (a, t) => (P(), Me(m(bc), {
      name: a.name,
      class: "tw-reset text-sm text-warning-orange-600"
    }, null, 8, ["name"]));
  }
}), qv = { class: "flex h-6 items-center" }, Kv = ["id", "name", "disabled", "aria-describedby"], Xv = {
  key: 1,
  class: "w-3.5 h-0.5 bg-pivot-teal-700 rounded-xl"
}, Qv = { className: "ml-3 leading-6" }, Gv = ["htmlFor"], G3 = /* @__PURE__ */ be({
  __name: "UICheckbox",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    value: { default: "true" },
    indeterminate: { type: Boolean },
    class: {},
    style: { type: [Boolean, null, String, Object, Array] },
    disabled: { type: Boolean, default: !1 }
  },
  setup(e) {
    const a = e, { checked: t, handleChange: n } = Ia(() => a.name, a.rules, {
      type: "checkbox",
      checkedValue: a.value,
      label: a.label,
      syncVModel: !0
    });
    return (r, i) => (P(), Y("div", {
      class: ye(["relative flex items-start", a.class]),
      style: Ze(r.style)
    }, [
      ie("div", qv, [
        ie("input", Ue(r.$attrs, {
          id: r.name,
          name: r.name,
          disabled: r.disabled,
          type: "checkbox",
          "aria-describedby": r.label,
          class: "absolute opacity-0 w-6 h-6",
          onClick: i[0] || (i[0] = (l) => m(n)(r.value))
        }), null, 16, Kv),
        ie("span", {
          class: ye(["w-6 h-6 rounded border border-ui-gray-400 bg-white flex items-center justify-center hidden-print", {
            "bg-ui-gray-100": r.disabled
          }])
        }, [
          m(t) ? (P(), Me(m(qt), {
            key: 0,
            icon: "check",
            class: "text-pivot-teal-700 block",
            size: "lg"
          })) : r.indeterminate ? (P(), Y("div", Xv)) : G("", !0)
        ], 2)
      ]),
      ie("div", Qv, [
        ie("label", {
          htmlFor: r.name,
          class: ye(["text-base", {
            "text-ui-gray-800": !m(t),
            "text-pivot-teal-700 font-bold": m(t),
            "text-ui-gray-300": r.disabled
          }])
        }, Le(r.label), 11, Gv)
      ])
    ], 6));
  }
}), Jv = ["htmlFor"], Zv = { class: "flex h-6 items-center" }, e0 = ["id", "name", "disabled", "aria-describedby", "value"], t0 = { className: "ml-3" }, J3 = /* @__PURE__ */ be({
  __name: "UIRadioButton",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    value: { default: "true" },
    class: {},
    style: { type: [Boolean, null, String, Object, Array] },
    disabled: { type: Boolean, default: !1 }
  },
  emits: ["update:modelValue"],
  setup(e, { emit: a }) {
    const t = e, n = a, {
      checked: r,
      handleChange: i,
      value: l
    } = Ia(() => t.name, t.rules, {
      type: "radio",
      checkedValue: t.value,
      uncheckedValue: "",
      label: t.label,
      syncVModel: !0
    }), o = () => {
      let s = t.value;
      l.value === s && (s = ""), i(s), n("update:modelValue", s);
    };
    return (s, d) => (P(), Y("div", {
      class: ye(["relative flex items-start", t.class]),
      style: Ze(s.style)
    }, [
      ie("label", {
        htmlFor: s.value,
        class: "flex"
      }, [
        ie("div", Zv, [
          ka(ie("input", Ue(s.$attrs, {
            id: s.value,
            name: s.name,
            disabled: s.disabled,
            type: "radio",
            "aria-describedby": s.label,
            value: s.value,
            "onUpdate:modelValue": d[0] || (d[0] = (u) => ra(l) ? l.value = u : null),
            class: "absolute opacity-0",
            onClick: o
          }), null, 16, e0), [
            [mc, m(l)]
          ]),
          ie("span", {
            class: ye(["w-6 h-6 rounded-full border border-ui-gray-400 flex items-center justify-center hidden-print", {
              "bg-ui-gray-100": s.disabled,
              "bg-white": !s.disabled
            }])
          }, [
            m(r) ? (P(), Me(m(qt), {
              key: 0,
              icon: "circle",
              class: "text-pivot-teal-700 block",
              size: "xs"
            })) : G("", !0)
          ], 2)
        ]),
        ie("div", t0, [
          ie("span", {
            class: ye(["text-base leading-6", {
              "text-ui-gray-800": !m(r) && !s.disabled,
              "text-pivot-teal-700 font-bold": m(r),
              "text-ui-gray-300": s.disabled
            }])
          }, Le(s.label), 3)
        ])
      ], 8, Jv)
    ], 6));
  }
});
function Za(e, a, t) {
  let n = t.initialDeps ?? [], r;
  return () => {
    var i, l, o, s;
    let d;
    t.key && ((i = t.debug) != null && i.call(t)) && (d = Date.now());
    const u = e();
    if (!(u.length !== n.length || u.some((v, g) => n[g] !== v)))
      return r;
    n = u;
    let f;
    if (t.key && ((l = t.debug) != null && l.call(t)) && (f = Date.now()), r = a(...u), t.key && ((o = t.debug) != null && o.call(t))) {
      const v = Math.round((Date.now() - d) * 100) / 100, g = Math.round((Date.now() - f) * 100) / 100, b = g / 16, C = (p, w) => {
        for (p = String(p); p.length < w; )
          p = " " + p;
        return p;
      };
      console.info(
        `%c⏱ ${C(g, 5)} /${C(v, 5)} ms`,
        `
            font-size: .6rem;
            font-weight: bold;
            color: hsl(${Math.max(
          0,
          Math.min(120 - 120 * b, 120)
        )}deg 100% 31%);`,
        t == null ? void 0 : t.key
      );
    }
    return (s = t == null ? void 0 : t.onChange) == null || s.call(t, r), r;
  };
}
function dl(e, a) {
  if (e === void 0)
    throw new Error(`Unexpected undefined${a ? `: ${a}` : ""}`);
  return e;
}
const a0 = (e, a) => Math.abs(e - a) < 1, n0 = (e) => e, r0 = (e) => {
  const a = Math.max(e.startIndex - e.overscan, 0), t = Math.min(e.endIndex + e.overscan, e.count - 1), n = [];
  for (let r = a; r <= t; r++)
    n.push(r);
  return n;
}, l0 = (e, a) => {
  const t = e.scrollElement;
  if (!t)
    return;
  const n = (i) => {
    const { width: l, height: o } = i;
    a({ width: Math.round(l), height: Math.round(o) });
  };
  if (n(t.getBoundingClientRect()), typeof ResizeObserver > "u")
    return () => {
    };
  const r = new ResizeObserver((i) => {
    const l = i[0];
    if (l != null && l.borderBoxSize) {
      const o = l.borderBoxSize[0];
      if (o) {
        n({ width: o.inlineSize, height: o.blockSize });
        return;
      }
    }
    n(t.getBoundingClientRect());
  });
  return r.observe(t, { box: "border-box" }), () => {
    r.unobserve(t);
  };
}, i0 = (e, a) => {
  const t = e.scrollElement;
  if (!t)
    return;
  const n = () => {
    a(t[e.options.horizontal ? "scrollLeft" : "scrollTop"]);
  };
  return n(), t.addEventListener("scroll", n, {
    passive: !0
  }), () => {
    t.removeEventListener("scroll", n);
  };
}, o0 = (e, a, t) => {
  if (a != null && a.borderBoxSize) {
    const n = a.borderBoxSize[0];
    if (n)
      return Math.round(
        n[t.options.horizontal ? "inlineSize" : "blockSize"]
      );
  }
  return Math.round(
    e.getBoundingClientRect()[t.options.horizontal ? "width" : "height"]
  );
}, s0 = (e, {
  adjustments: a = 0,
  behavior: t
}, n) => {
  var r, i;
  const l = e + a;
  (i = (r = n.scrollElement) == null ? void 0 : r.scrollTo) == null || i.call(r, {
    [n.options.horizontal ? "left" : "top"]: l,
    behavior: t
  });
};
class u0 {
  constructor(a) {
    this.unsubs = [], this.scrollElement = null, this.isScrolling = !1, this.isScrollingTimeoutId = null, this.scrollToIndexTimeoutId = null, this.measurementsCache = [], this.itemSizeCache = /* @__PURE__ */ new Map(), this.pendingMeasuredCacheIndexes = [], this.scrollDirection = null, this.scrollAdjustments = 0, this.measureElementCache = /* @__PURE__ */ new Map(), this.observer = /* @__PURE__ */ (() => {
      let t = null;
      const n = () => t || (typeof ResizeObserver < "u" ? t = new ResizeObserver((r) => {
        r.forEach((i) => {
          this._measureElement(i.target, i);
        });
      }) : null);
      return {
        disconnect: () => {
          var r;
          return (r = n()) == null ? void 0 : r.disconnect();
        },
        observe: (r) => {
          var i;
          return (i = n()) == null ? void 0 : i.observe(r, { box: "border-box" });
        },
        unobserve: (r) => {
          var i;
          return (i = n()) == null ? void 0 : i.unobserve(r);
        }
      };
    })(), this.range = null, this.setOptions = (t) => {
      Object.entries(t).forEach(([n, r]) => {
        typeof r > "u" && delete t[n];
      }), this.options = {
        debug: !1,
        initialOffset: 0,
        overscan: 1,
        paddingStart: 0,
        paddingEnd: 0,
        scrollPaddingStart: 0,
        scrollPaddingEnd: 0,
        horizontal: !1,
        getItemKey: n0,
        rangeExtractor: r0,
        onChange: () => {
        },
        measureElement: o0,
        initialRect: { width: 0, height: 0 },
        scrollMargin: 0,
        gap: 0,
        scrollingDelay: 150,
        indexAttribute: "data-index",
        initialMeasurementsCache: [],
        lanes: 1,
        ...t
      };
    }, this.notify = (t) => {
      var n, r;
      (r = (n = this.options).onChange) == null || r.call(n, this, t);
    }, this.maybeNotify = Za(
      () => (this.calculateRange(), [
        this.isScrolling,
        this.range ? this.range.startIndex : null,
        this.range ? this.range.endIndex : null
      ]),
      (t) => {
        this.notify(t);
      },
      {
        key: process.env.NODE_ENV !== "production" && "maybeNotify",
        debug: () => this.options.debug,
        initialDeps: [
          this.isScrolling,
          this.range ? this.range.startIndex : null,
          this.range ? this.range.endIndex : null
        ]
      }
    ), this.cleanup = () => {
      this.unsubs.filter(Boolean).forEach((t) => t()), this.unsubs = [], this.scrollElement = null;
    }, this._didMount = () => (this.measureElementCache.forEach(this.observer.observe), () => {
      this.observer.disconnect(), this.cleanup();
    }), this._willUpdate = () => {
      const t = this.options.getScrollElement();
      this.scrollElement !== t && (this.cleanup(), this.scrollElement = t, this._scrollToOffset(this.scrollOffset, {
        adjustments: void 0,
        behavior: void 0
      }), this.unsubs.push(
        this.options.observeElementRect(this, (n) => {
          this.scrollRect = n, this.maybeNotify();
        })
      ), this.unsubs.push(
        this.options.observeElementOffset(this, (n) => {
          this.scrollAdjustments = 0, this.scrollOffset !== n && (this.isScrollingTimeoutId !== null && (clearTimeout(this.isScrollingTimeoutId), this.isScrollingTimeoutId = null), this.isScrolling = !0, this.scrollDirection = this.scrollOffset < n ? "forward" : "backward", this.scrollOffset = n, this.maybeNotify(), this.isScrollingTimeoutId = setTimeout(() => {
            this.isScrollingTimeoutId = null, this.isScrolling = !1, this.scrollDirection = null, this.maybeNotify();
          }, this.options.scrollingDelay));
        })
      ));
    }, this.getSize = () => this.scrollRect[this.options.horizontal ? "width" : "height"], this.memoOptions = Za(
      () => [
        this.options.count,
        this.options.paddingStart,
        this.options.scrollMargin,
        this.options.getItemKey
      ],
      (t, n, r, i) => (this.pendingMeasuredCacheIndexes = [], {
        count: t,
        paddingStart: n,
        scrollMargin: r,
        getItemKey: i
      }),
      {
        key: !1
      }
    ), this.getFurthestMeasurement = (t, n) => {
      const r = /* @__PURE__ */ new Map(), i = /* @__PURE__ */ new Map();
      for (let l = n - 1; l >= 0; l--) {
        const o = t[l];
        if (r.has(o.lane))
          continue;
        const s = i.get(
          o.lane
        );
        if (s == null || o.end > s.end ? i.set(o.lane, o) : o.end < s.end && r.set(o.lane, !0), r.size === this.options.lanes)
          break;
      }
      return i.size === this.options.lanes ? Array.from(i.values()).sort((l, o) => l.end === o.end ? l.index - o.index : l.end - o.end)[0] : void 0;
    }, this.getMeasurements = Za(
      () => [this.memoOptions(), this.itemSizeCache],
      ({ count: t, paddingStart: n, scrollMargin: r, getItemKey: i }, l) => {
        const o = this.pendingMeasuredCacheIndexes.length > 0 ? Math.min(...this.pendingMeasuredCacheIndexes) : 0;
        this.pendingMeasuredCacheIndexes = [];
        const s = this.measurementsCache.slice(0, o);
        for (let d = o; d < t; d++) {
          const u = i(d), c = this.options.lanes === 1 ? s[d - 1] : this.getFurthestMeasurement(s, d), f = c ? c.end + this.options.gap : n + r, v = l.get(u), g = typeof v == "number" ? v : this.options.estimateSize(d), b = f + g, C = c ? c.lane : d % this.options.lanes;
          s[d] = {
            index: d,
            start: f,
            size: g,
            end: b,
            key: u,
            lane: C
          };
        }
        return this.measurementsCache = s, s;
      },
      {
        key: process.env.NODE_ENV !== "production" && "getMeasurements",
        debug: () => this.options.debug
      }
    ), this.calculateRange = Za(
      () => [this.getMeasurements(), this.getSize(), this.scrollOffset],
      (t, n, r) => this.range = t.length > 0 && n > 0 ? c0({
        measurements: t,
        outerSize: n,
        scrollOffset: r
      }) : null,
      {
        key: process.env.NODE_ENV !== "production" && "calculateRange",
        debug: () => this.options.debug
      }
    ), this.getIndexes = Za(
      () => [
        this.options.rangeExtractor,
        this.calculateRange(),
        this.options.overscan,
        this.options.count
      ],
      (t, n, r, i) => n === null ? [] : t({
        ...n,
        overscan: r,
        count: i
      }),
      {
        key: process.env.NODE_ENV !== "production" && "getIndexes",
        debug: () => this.options.debug
      }
    ), this.indexFromElement = (t) => {
      const n = this.options.indexAttribute, r = t.getAttribute(n);
      return r ? parseInt(r, 10) : (console.warn(
        `Missing attribute name '${n}={index}' on measured element.`
      ), -1);
    }, this._measureElement = (t, n) => {
      const r = this.measurementsCache[this.indexFromElement(t)];
      if (!r || !t.isConnected) {
        this.measureElementCache.forEach((o, s) => {
          o === t && (this.observer.unobserve(t), this.measureElementCache.delete(s));
        });
        return;
      }
      const i = this.measureElementCache.get(r.key);
      i !== t && (i && this.observer.unobserve(i), this.observer.observe(t), this.measureElementCache.set(r.key, t));
      const l = this.options.measureElement(t, n, this);
      this.resizeItem(r, l);
    }, this.resizeItem = (t, n) => {
      const r = this.itemSizeCache.get(t.key) ?? t.size, i = n - r;
      i !== 0 && (t.start < this.scrollOffset + this.scrollAdjustments && (process.env.NODE_ENV !== "production" && this.options.debug && console.info("correction", i), this._scrollToOffset(this.scrollOffset, {
        adjustments: this.scrollAdjustments += i,
        behavior: void 0
      })), this.pendingMeasuredCacheIndexes.push(t.index), this.itemSizeCache = new Map(this.itemSizeCache.set(t.key, n)), this.notify(!1));
    }, this.measureElement = (t) => {
      t && this._measureElement(t, void 0);
    }, this.getVirtualItems = Za(
      () => [this.getIndexes(), this.getMeasurements()],
      (t, n) => {
        const r = [];
        for (let i = 0, l = t.length; i < l; i++) {
          const o = t[i], s = n[o];
          r.push(s);
        }
        return r;
      },
      {
        key: process.env.NODE_ENV !== "production" && "getIndexes",
        debug: () => this.options.debug
      }
    ), this.getVirtualItemForOffset = (t) => {
      const n = this.getMeasurements();
      return dl(
        n[Ls(
          0,
          n.length - 1,
          (r) => dl(n[r]).start,
          t
        )]
      );
    }, this.getOffsetForAlignment = (t, n) => {
      const r = this.getSize();
      n === "auto" && (t <= this.scrollOffset ? n = "start" : t >= this.scrollOffset + r ? n = "end" : n = "start"), n === "start" ? t = t : n === "end" ? t = t - r : n === "center" && (t = t - r / 2);
      const i = this.options.horizontal ? "scrollWidth" : "scrollHeight", o = (this.scrollElement ? "document" in this.scrollElement ? this.scrollElement.document.documentElement[i] : this.scrollElement[i] : 0) - this.getSize();
      return Math.max(Math.min(o, t), 0);
    }, this.getOffsetForIndex = (t, n = "auto") => {
      t = Math.max(0, Math.min(t, this.options.count - 1));
      const r = dl(this.getMeasurements()[t]);
      if (n === "auto")
        if (r.end >= this.scrollOffset + this.getSize() - this.options.scrollPaddingEnd)
          n = "end";
        else if (r.start <= this.scrollOffset + this.options.scrollPaddingStart)
          n = "start";
        else
          return [this.scrollOffset, n];
      const i = n === "end" ? r.end + this.options.scrollPaddingEnd : r.start - this.options.scrollPaddingStart;
      return [this.getOffsetForAlignment(i, n), n];
    }, this.isDynamicMode = () => this.measureElementCache.size > 0, this.cancelScrollToIndex = () => {
      this.scrollToIndexTimeoutId !== null && (clearTimeout(this.scrollToIndexTimeoutId), this.scrollToIndexTimeoutId = null);
    }, this.scrollToOffset = (t, { align: n = "start", behavior: r } = {}) => {
      this.cancelScrollToIndex(), r === "smooth" && this.isDynamicMode() && console.warn(
        "The `smooth` scroll behavior is not fully supported with dynamic size."
      ), this._scrollToOffset(this.getOffsetForAlignment(t, n), {
        adjustments: void 0,
        behavior: r
      });
    }, this.scrollToIndex = (t, { align: n = "auto", behavior: r } = {}) => {
      t = Math.max(0, Math.min(t, this.options.count - 1)), this.cancelScrollToIndex(), r === "smooth" && this.isDynamicMode() && console.warn(
        "The `smooth` scroll behavior is not fully supported with dynamic size."
      );
      const [i, l] = this.getOffsetForIndex(t, n);
      this._scrollToOffset(i, { adjustments: void 0, behavior: r }), r !== "smooth" && this.isDynamicMode() && (this.scrollToIndexTimeoutId = setTimeout(() => {
        if (this.scrollToIndexTimeoutId = null, this.measureElementCache.has(
          this.options.getItemKey(t)
        )) {
          const [s] = this.getOffsetForIndex(t, l);
          a0(s, this.scrollOffset) || this.scrollToIndex(t, { align: l, behavior: r });
        } else
          this.scrollToIndex(t, { align: l, behavior: r });
      }));
    }, this.scrollBy = (t, { behavior: n } = {}) => {
      this.cancelScrollToIndex(), n === "smooth" && this.isDynamicMode() && console.warn(
        "The `smooth` scroll behavior is not fully supported with dynamic size."
      ), this._scrollToOffset(this.scrollOffset + t, {
        adjustments: void 0,
        behavior: n
      });
    }, this.getTotalSize = () => {
      var t;
      const n = this.getMeasurements();
      let r;
      return n.length === 0 ? r = this.options.paddingStart : r = this.options.lanes === 1 ? ((t = n[n.length - 1]) == null ? void 0 : t.end) ?? 0 : Math.max(
        ...n.slice(-this.options.lanes).map((i) => i.end)
      ), r - this.options.scrollMargin + this.options.paddingEnd;
    }, this._scrollToOffset = (t, {
      adjustments: n,
      behavior: r
    }) => {
      this.options.scrollToFn(t, { behavior: r, adjustments: n }, this);
    }, this.measure = () => {
      this.itemSizeCache = /* @__PURE__ */ new Map(), this.notify(!1);
    }, this.setOptions(a), this.scrollRect = this.options.initialRect, this.scrollOffset = this.options.initialOffset, this.measurementsCache = this.options.initialMeasurementsCache, this.measurementsCache.forEach((t) => {
      this.itemSizeCache.set(t.key, t.size);
    }), this.maybeNotify();
  }
}
const Ls = (e, a, t, n) => {
  for (; e <= a; ) {
    const r = (e + a) / 2 | 0, i = t(r);
    if (i < n)
      e = r + 1;
    else if (i > n)
      a = r - 1;
    else
      return r;
  }
  return e > 0 ? e - 1 : 0;
};
function c0({
  measurements: e,
  outerSize: a,
  scrollOffset: t
}) {
  const n = e.length - 1, i = Ls(0, n, (o) => e[o].start, t);
  let l = i;
  for (; l < n && e[l].end < t + a; )
    l++;
  return { startIndex: i, endIndex: l };
}
function d0(e) {
  const a = new u0(m(e)), t = pc(a), n = a._didMount();
  return Ge(
    () => m(e).getScrollElement(),
    (r) => {
      r && a._willUpdate();
    },
    {
      immediate: !0
    }
  ), Ge(
    () => m(e),
    (r) => {
      a.setOptions({
        ...r,
        onChange: (i, l) => {
          var o;
          ji(t), (o = r.onChange) == null || o.call(r, i, l);
        }
      }), a._willUpdate(), ji(t);
    },
    {
      immediate: !0
    }
  ), ts(n), t;
}
function f0(e) {
  return d0(
    A(() => ({
      observeElementRect: l0,
      observeElementOffset: i0,
      scrollToFn: s0,
      ...m(e)
    }))
  );
}
function vi(e, a, t) {
  let n = z(t == null ? void 0 : t.value), r = A(() => e.value !== void 0);
  return [A(() => r.value ? e.value : n.value), function(i) {
    return r.value || (n.value = i), a == null ? void 0 : a(i);
  }];
}
let v0 = Symbol("headlessui.useid"), m0 = 0;
function _t() {
  return It(v0, () => `${++m0}`)();
}
function he(e) {
  var a;
  if (e == null || e.value == null)
    return null;
  let t = (a = e.value.$el) != null ? a : e.value;
  return t instanceof Node ? t : null;
}
function ut(e, a, ...t) {
  if (e in a) {
    let r = a[e];
    return typeof r == "function" ? r(...t) : r;
  }
  let n = new Error(`Tried to handle "${e}" but there is no handler defined. Only defined handlers are: ${Object.keys(a).map((r) => `"${r}"`).join(", ")}.`);
  throw Error.captureStackTrace && Error.captureStackTrace(n, ut), n;
}
var p0 = Object.defineProperty, h0 = (e, a, t) => a in e ? p0(e, a, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[a] = t, wo = (e, a, t) => (h0(e, typeof a != "symbol" ? a + "" : a, t), t);
let y0 = class {
  constructor() {
    wo(this, "current", this.detect()), wo(this, "currentId", 0);
  }
  set(a) {
    this.current !== a && (this.currentId = 0, this.current = a);
  }
  reset() {
    this.set(this.detect());
  }
  nextId() {
    return ++this.currentId;
  }
  get isServer() {
    return this.current === "server";
  }
  get isClient() {
    return this.current === "client";
  }
  detect() {
    return typeof window > "u" || typeof document > "u" ? "server" : "client";
  }
}, Nr = new y0();
function Lr(e) {
  if (Nr.isServer)
    return null;
  if (e instanceof Node)
    return e.ownerDocument;
  if (e != null && e.hasOwnProperty("value")) {
    let a = he(e);
    if (a)
      return a.ownerDocument;
  }
  return document;
}
let Fl = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e) => `${e}:not([tabindex='-1'])`).join(",");
var Vl = ((e) => (e[e.First = 1] = "First", e[e.Previous = 2] = "Previous", e[e.Next = 4] = "Next", e[e.Last = 8] = "Last", e[e.WrapAround = 16] = "WrapAround", e[e.NoScroll = 32] = "NoScroll", e))(Vl || {}), g0 = ((e) => (e[e.Error = 0] = "Error", e[e.Overflow = 1] = "Overflow", e[e.Success = 2] = "Success", e[e.Underflow = 3] = "Underflow", e))(g0 || {}), b0 = ((e) => (e[e.Previous = -1] = "Previous", e[e.Next = 1] = "Next", e))(b0 || {});
function Hs(e = document.body) {
  return e == null ? [] : Array.from(e.querySelectorAll(Fl)).sort((a, t) => Math.sign((a.tabIndex || Number.MAX_SAFE_INTEGER) - (t.tabIndex || Number.MAX_SAFE_INTEGER)));
}
var Hr = ((e) => (e[e.Strict = 0] = "Strict", e[e.Loose = 1] = "Loose", e))(Hr || {});
function zr(e, a = 0) {
  var t;
  return e === ((t = Lr(e)) == null ? void 0 : t.body) ? !1 : ut(a, { 0() {
    return e.matches(Fl);
  }, 1() {
    let n = e;
    for (; n !== null; ) {
      if (n.matches(Fl))
        return !0;
      n = n.parentElement;
    }
    return !1;
  } });
}
function zs(e) {
  let a = Lr(e);
  Ne(() => {
    a && !zr(a.activeElement, 0) && x0(e);
  });
}
var w0 = ((e) => (e[e.Keyboard = 0] = "Keyboard", e[e.Mouse = 1] = "Mouse", e))(w0 || {});
typeof window < "u" && typeof document < "u" && (document.addEventListener("keydown", (e) => {
  e.metaKey || e.altKey || e.ctrlKey || (document.documentElement.dataset.headlessuiFocusVisible = "");
}, !0), document.addEventListener("click", (e) => {
  e.detail === 1 ? delete document.documentElement.dataset.headlessuiFocusVisible : e.detail === 0 && (document.documentElement.dataset.headlessuiFocusVisible = "");
}, !0));
function x0(e) {
  e == null || e.focus({ preventScroll: !0 });
}
let k0 = ["textarea", "input"].join(",");
function _0(e) {
  var a, t;
  return (t = (a = e == null ? void 0 : e.matches) == null ? void 0 : a.call(e, k0)) != null ? t : !1;
}
function Fr(e, a = (t) => t) {
  return e.slice().sort((t, n) => {
    let r = a(t), i = a(n);
    if (r === null || i === null)
      return 0;
    let l = r.compareDocumentPosition(i);
    return l & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : l & Node.DOCUMENT_POSITION_PRECEDING ? 1 : 0;
  });
}
function M0(e, a) {
  return O0(Hs(), a, { relativeTo: e });
}
function O0(e, a, { sorted: t = !0, relativeTo: n = null, skipElements: r = [] } = {}) {
  var i;
  let l = (i = Array.isArray(e) ? e.length > 0 ? e[0].ownerDocument : document : e == null ? void 0 : e.ownerDocument) != null ? i : document, o = Array.isArray(e) ? t ? Fr(e) : e : Hs(e);
  r.length > 0 && o.length > 1 && (o = o.filter((g) => !r.includes(g))), n = n ?? l.activeElement;
  let s = (() => {
    if (a & 5)
      return 1;
    if (a & 10)
      return -1;
    throw new Error("Missing Focus.First, Focus.Previous, Focus.Next or Focus.Last");
  })(), d = (() => {
    if (a & 1)
      return 0;
    if (a & 2)
      return Math.max(0, o.indexOf(n)) - 1;
    if (a & 4)
      return Math.max(0, o.indexOf(n)) + 1;
    if (a & 8)
      return o.length - 1;
    throw new Error("Missing Focus.First, Focus.Previous, Focus.Next or Focus.Last");
  })(), u = a & 32 ? { preventScroll: !0 } : {}, c = 0, f = o.length, v;
  do {
    if (c >= f || c + f <= 0)
      return 0;
    let g = d + c;
    if (a & 16)
      g = (g + f) % f;
    else {
      if (g < 0)
        return 3;
      if (g >= f)
        return 1;
    }
    v = o[g], v == null || v.focus(u), c += s;
  } while (v !== l.activeElement);
  return a & 6 && _0(v) && v.select(), 2;
}
function S0() {
  return /iPhone/gi.test(window.navigator.platform) || /Mac/gi.test(window.navigator.platform) && window.navigator.maxTouchPoints > 0;
}
function T0() {
  return /Android/gi.test(window.navigator.userAgent);
}
function Fs() {
  return S0() || T0();
}
function sr(e, a, t) {
  Nr.isServer || Dt((n) => {
    document.addEventListener(e, a, t), n(() => document.removeEventListener(e, a, t));
  });
}
function P0(e, a, t) {
  Nr.isServer || Dt((n) => {
    window.addEventListener(e, a, t), n(() => window.removeEventListener(e, a, t));
  });
}
function mi(e, a, t = A(() => !0)) {
  function n(i, l) {
    if (!t.value || i.defaultPrevented)
      return;
    let o = l(i);
    if (o === null || !o.getRootNode().contains(o))
      return;
    let s = function d(u) {
      return typeof u == "function" ? d(u()) : Array.isArray(u) || u instanceof Set ? u : [u];
    }(e);
    for (let d of s) {
      if (d === null)
        continue;
      let u = d instanceof HTMLElement ? d : he(d);
      if (u != null && u.contains(o) || i.composed && i.composedPath().includes(u))
        return;
    }
    return !zr(o, Hr.Loose) && o.tabIndex !== -1 && i.preventDefault(), a(i, o);
  }
  let r = z(null);
  sr("pointerdown", (i) => {
    var l, o;
    t.value && (r.value = ((o = (l = i.composedPath) == null ? void 0 : l.call(i)) == null ? void 0 : o[0]) || i.target);
  }, !0), sr("mousedown", (i) => {
    var l, o;
    t.value && (r.value = ((o = (l = i.composedPath) == null ? void 0 : l.call(i)) == null ? void 0 : o[0]) || i.target);
  }, !0), sr("click", (i) => {
    Fs() || r.value && (n(i, () => r.value), r.value = null);
  }, !0), sr("touchend", (i) => n(i, () => i.target instanceof HTMLElement ? i.target : null), !0), P0("blur", (i) => n(i, () => window.document.activeElement instanceof HTMLIFrameElement ? window.document.activeElement : null), !0);
}
function xo(e, a) {
  if (e)
    return e;
  let t = a ?? "button";
  if (typeof t == "string" && t.toLowerCase() === "button")
    return "button";
}
function Vr(e, a) {
  let t = z(xo(e.value.type, e.value.as));
  return He(() => {
    t.value = xo(e.value.type, e.value.as);
  }), Dt(() => {
    var n;
    t.value || he(a) && he(a) instanceof HTMLButtonElement && !((n = he(a)) != null && n.hasAttribute("type")) && (t.value = "button");
  }), t;
}
function ko(e) {
  return [e.screenX, e.screenY];
}
function pi() {
  let e = z([-1, -1]);
  return { wasMoved(a) {
    let t = ko(a);
    return e.value[0] === t[0] && e.value[1] === t[1] ? !1 : (e.value = t, !0);
  }, update(a) {
    e.value = ko(a);
  } };
}
function Vs({ container: e, accept: a, walk: t, enabled: n }) {
  Dt(() => {
    let r = e.value;
    if (!r || n !== void 0 && !n.value)
      return;
    let i = Lr(e);
    if (!i)
      return;
    let l = Object.assign((s) => a(s), { acceptNode: a }), o = i.createTreeWalker(r, NodeFilter.SHOW_ELEMENT, l, !1);
    for (; o.nextNode(); )
      t(o.currentNode);
  });
}
var la = ((e) => (e[e.None = 0] = "None", e[e.RenderStrategy = 1] = "RenderStrategy", e[e.Static = 2] = "Static", e))(la || {}), wa = ((e) => (e[e.Unmount = 0] = "Unmount", e[e.Hidden = 1] = "Hidden", e))(wa || {});
function rt({ visible: e = !0, features: a = 0, ourProps: t, theirProps: n, ...r }) {
  var i;
  let l = Bs(n, t), o = Object.assign(r, { props: l });
  if (e || a & 2 && l.static)
    return fl(o);
  if (a & 1) {
    let s = (i = l.unmount) == null || i ? 0 : 1;
    return ut(s, { 0() {
      return null;
    }, 1() {
      return fl({ ...r, props: { ...l, hidden: !0, style: { display: "none" } } });
    } });
  }
  return fl(o);
}
function fl({ props: e, attrs: a, slots: t, slot: n, name: r }) {
  var i, l;
  let { as: o, ...s } = Xa(e, ["unmount", "static"]), d = (i = t.default) == null ? void 0 : i.call(t, n), u = {};
  if (n) {
    let c = !1, f = [];
    for (let [v, g] of Object.entries(n))
      typeof g == "boolean" && (c = !0), g === !0 && f.push(v);
    c && (u["data-headlessui-state"] = f.join(" "));
  }
  if (o === "template") {
    if (d = Ys(d ?? []), Object.keys(s).length > 0 || Object.keys(a).length > 0) {
      let [c, ...f] = d ?? [];
      if (!C0(c) || f.length > 0)
        throw new Error(['Passing props on "template"!', "", `The current component <${r} /> is rendering a "template".`, "However we need to passthrough the following props:", Object.keys(s).concat(Object.keys(a)).map((b) => b.trim()).filter((b, C, p) => p.indexOf(b) === C).sort((b, C) => b.localeCompare(C)).map((b) => `  - ${b}`).join(`
`), "", "You can apply a few solutions:", ['Add an `as="..."` prop, to ensure that we render an actual element instead of a "template".', "Render a single element as the child so that we can forward the props onto that element."].map((b) => `  - ${b}`).join(`
`)].join(`
`));
      let v = Bs((l = c.props) != null ? l : {}, s, u), g = as(c, v, !0);
      for (let b in v)
        b.startsWith("on") && (g.props || (g.props = {}), g.props[b] = v[b]);
      return g;
    }
    return Array.isArray(d) && d.length === 1 ? d[0] : d;
  }
  return At(o, Object.assign({}, s, u), { default: () => d });
}
function Ys(e) {
  return e.flatMap((a) => a.type === Se ? Ys(a.children) : [a]);
}
function Bs(...e) {
  if (e.length === 0)
    return {};
  if (e.length === 1)
    return e[0];
  let a = {}, t = {};
  for (let n of e)
    for (let r in n)
      r.startsWith("on") && typeof n[r] == "function" ? (t[r] != null || (t[r] = []), t[r].push(n[r])) : a[r] = n[r];
  if (a.disabled || a["aria-disabled"])
    return Object.assign(a, Object.fromEntries(Object.keys(t).map((n) => [n, void 0])));
  for (let n in t)
    Object.assign(a, { [n](r, ...i) {
      let l = t[n];
      for (let o of l) {
        if (r instanceof Event && r.defaultPrevented)
          return;
        o(r, ...i);
      }
    } });
  return a;
}
function hi(e) {
  let a = Object.assign({}, e);
  for (let t in a)
    a[t] === void 0 && delete a[t];
  return a;
}
function Xa(e, a = []) {
  let t = Object.assign({}, e);
  for (let n of a)
    n in t && delete t[n];
  return t;
}
function C0(e) {
  return e == null ? !1 : typeof e.type == "string" || typeof e.type == "object" || typeof e.type == "function";
}
var Yr = ((e) => (e[e.None = 1] = "None", e[e.Focusable = 2] = "Focusable", e[e.Hidden = 4] = "Hidden", e))(Yr || {});
let yi = be({ name: "Hidden", props: { as: { type: [Object, String], default: "div" }, features: { type: Number, default: 1 } }, setup(e, { slots: a, attrs: t }) {
  return () => {
    var n;
    let { features: r, ...i } = e, l = { "aria-hidden": (r & 2) === 2 ? !0 : (n = i["aria-hidden"]) != null ? n : void 0, style: { position: "fixed", top: 1, left: 1, width: 1, height: 0, padding: 0, margin: -1, overflow: "hidden", clip: "rect(0, 0, 0, 0)", whiteSpace: "nowrap", borderWidth: "0", ...(r & 4) === 4 && (r & 2) !== 2 && { display: "none" } } };
    return rt({ ourProps: l, theirProps: i, slot: {}, attrs: t, slots: a, name: "Hidden" });
  };
} }), js = Symbol("Context");
var st = ((e) => (e[e.Open = 1] = "Open", e[e.Closed = 2] = "Closed", e[e.Closing = 4] = "Closing", e[e.Opening = 8] = "Opening", e))(st || {});
function A0() {
  return Un() !== null;
}
function Un() {
  return It(js, null);
}
function Br(e) {
  Nt(js, e);
}
var Ce = ((e) => (e.Space = " ", e.Enter = "Enter", e.Escape = "Escape", e.Backspace = "Backspace", e.Delete = "Delete", e.ArrowLeft = "ArrowLeft", e.ArrowUp = "ArrowUp", e.ArrowRight = "ArrowRight", e.ArrowDown = "ArrowDown", e.Home = "Home", e.End = "End", e.PageUp = "PageUp", e.PageDown = "PageDown", e.Tab = "Tab", e))(Ce || {});
function D0(e) {
  function a() {
    document.readyState !== "loading" && (e(), document.removeEventListener("DOMContentLoaded", a));
  }
  typeof window < "u" && typeof document < "u" && (document.addEventListener("DOMContentLoaded", a), a());
}
let La = [];
D0(() => {
  function e(a) {
    a.target instanceof HTMLElement && a.target !== document.body && La[0] !== a.target && (La.unshift(a.target), La = La.filter((t) => t != null && t.isConnected), La.splice(10));
  }
  window.addEventListener("click", e, { capture: !0 }), window.addEventListener("mousedown", e, { capture: !0 }), window.addEventListener("focus", e, { capture: !0 }), document.body.addEventListener("click", e, { capture: !0 }), document.body.addEventListener("mousedown", e, { capture: !0 }), document.body.addEventListener("focus", e, { capture: !0 });
});
function I0(e) {
  throw new Error("Unexpected object: " + e);
}
var De = ((e) => (e[e.First = 0] = "First", e[e.Previous = 1] = "Previous", e[e.Next = 2] = "Next", e[e.Last = 3] = "Last", e[e.Specific = 4] = "Specific", e[e.Nothing = 5] = "Nothing", e))(De || {});
function _r(e, a) {
  let t = a.resolveItems();
  if (t.length <= 0)
    return null;
  let n = a.resolveActiveIndex(), r = n ?? -1;
  switch (e.focus) {
    case 0: {
      for (let i = 0; i < t.length; ++i)
        if (!a.resolveDisabled(t[i], i, t))
          return i;
      return n;
    }
    case 1: {
      r === -1 && (r = t.length);
      for (let i = r - 1; i >= 0; --i)
        if (!a.resolveDisabled(t[i], i, t))
          return i;
      return n;
    }
    case 2: {
      for (let i = r + 1; i < t.length; ++i)
        if (!a.resolveDisabled(t[i], i, t))
          return i;
      return n;
    }
    case 3: {
      for (let i = t.length - 1; i >= 0; --i)
        if (!a.resolveDisabled(t[i], i, t))
          return i;
      return n;
    }
    case 4: {
      for (let i = 0; i < t.length; ++i)
        if (a.resolveId(t[i], i, t) === e.id)
          return i;
      return n;
    }
    case 5:
      return null;
    default:
      I0(e);
  }
}
function E0(e) {
  typeof queueMicrotask == "function" ? queueMicrotask(e) : Promise.resolve().then(e).catch((a) => setTimeout(() => {
    throw a;
  }));
}
function Nn() {
  let e = [], a = { addEventListener(t, n, r, i) {
    return t.addEventListener(n, r, i), a.add(() => t.removeEventListener(n, r, i));
  }, requestAnimationFrame(...t) {
    let n = requestAnimationFrame(...t);
    a.add(() => cancelAnimationFrame(n));
  }, nextFrame(...t) {
    a.requestAnimationFrame(() => {
      a.requestAnimationFrame(...t);
    });
  }, setTimeout(...t) {
    let n = setTimeout(...t);
    a.add(() => clearTimeout(n));
  }, microTask(...t) {
    let n = { current: !0 };
    return E0(() => {
      n.current && t[0]();
    }), a.add(() => {
      n.current = !1;
    });
  }, style(t, n, r) {
    let i = t.style.getPropertyValue(n);
    return Object.assign(t.style, { [n]: r }), this.add(() => {
      Object.assign(t.style, { [n]: i });
    });
  }, group(t) {
    let n = Nn();
    return t(n), this.add(() => n.dispose());
  }, add(t) {
    return e.push(t), () => {
      let n = e.indexOf(t);
      if (n >= 0)
        for (let r of e.splice(n, 1))
          r();
    };
  }, dispose() {
    for (let t of e.splice(0))
      t();
  } };
  return a;
}
function gi(e = {}, a = null, t = []) {
  for (let [n, r] of Object.entries(e))
    Ws(t, Us(a, n), r);
  return t;
}
function Us(e, a) {
  return e ? e + "[" + a + "]" : a;
}
function Ws(e, a, t) {
  if (Array.isArray(t))
    for (let [n, r] of t.entries())
      Ws(e, Us(a, n.toString()), r);
  else
    t instanceof Date ? e.push([a, t.toISOString()]) : typeof t == "boolean" ? e.push([a, t ? "1" : "0"]) : typeof t == "string" ? e.push([a, t]) : typeof t == "number" ? e.push([a, `${t}`]) : t == null ? e.push([a, ""]) : gi(t, a, e);
}
function $0(e) {
  var a, t;
  let n = (a = e == null ? void 0 : e.form) != null ? a : e.closest("form");
  if (n) {
    for (let r of n.elements)
      if (r !== e && (r.tagName === "INPUT" && r.type === "submit" || r.tagName === "BUTTON" && r.type === "submit" || r.nodeName === "INPUT" && r.type === "image")) {
        r.click();
        return;
      }
    (t = n.requestSubmit) == null || t.call(n);
  }
}
function R0(e, a) {
  return e === a;
}
var N0 = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(N0 || {}), L0 = ((e) => (e[e.Single = 0] = "Single", e[e.Multi = 1] = "Multi", e))(L0 || {}), H0 = ((e) => (e[e.Pointer = 0] = "Pointer", e[e.Focus = 1] = "Focus", e[e.Other = 2] = "Other", e))(H0 || {});
let qs = Symbol("ComboboxContext");
function Qa(e) {
  let a = It(qs, null);
  if (a === null) {
    let t = new Error(`<${e} /> is missing a parent <Combobox /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(t, Qa), t;
  }
  return a;
}
let Ks = Symbol("VirtualContext"), z0 = be({ name: "VirtualProvider", setup(e, { slots: a }) {
  let t = Qa("VirtualProvider"), n = A(() => {
    let o = he(t.optionsRef);
    if (!o)
      return { start: 0, end: 0 };
    let s = window.getComputedStyle(o);
    return { start: parseFloat(s.paddingBlockStart || s.paddingTop), end: parseFloat(s.paddingBlockEnd || s.paddingBottom) };
  }), r = f0(A(() => ({ scrollPaddingStart: n.value.start, scrollPaddingEnd: n.value.end, count: t.virtual.value.options.length, estimateSize() {
    return 40;
  }, getScrollElement() {
    return he(t.optionsRef);
  }, overscan: 12 }))), i = A(() => {
    var o;
    return (o = t.virtual.value) == null ? void 0 : o.options;
  }), l = z(0);
  return Ge([i], () => {
    l.value += 1;
  }), Nt(Ks, t.virtual.value ? r : null), () => [At("div", { style: { position: "relative", width: "100%", height: `${r.value.getTotalSize()}px` }, ref: (o) => {
    if (o) {
      if (typeof process < "u" && process.env.JEST_WORKER_ID !== void 0 || t.activationTrigger.value === 0)
        return;
      t.activeOptionIndex.value !== null && t.virtual.value.options.length > t.activeOptionIndex.value && r.value.scrollToIndex(t.activeOptionIndex.value);
    }
  } }, r.value.getVirtualItems().map((o) => as(a.default({ option: t.virtual.value.options[o.index], open: t.comboboxState.value === 0 })[0], { key: `${l.value}-${o.index}`, "data-index": o.index, "aria-setsize": t.virtual.value.options.length, "aria-posinset": o.index + 1, style: { position: "absolute", top: 0, left: 0, transform: `translateY(${o.start}px)`, overflowAnchor: "none" } })))];
} }), F0 = be({ name: "Combobox", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "template" }, disabled: { type: [Boolean], default: !1 }, by: { type: [String, Function], nullable: !0, default: null }, modelValue: { type: [Object, String, Number, Boolean], default: void 0 }, defaultValue: { type: [Object, String, Number, Boolean], default: void 0 }, form: { type: String, optional: !0 }, name: { type: String, optional: !0 }, nullable: { type: Boolean, default: !1 }, multiple: { type: [Boolean], default: !1 }, immediate: { type: [Boolean], default: !1 }, virtual: { type: Object, default: null } }, inheritAttrs: !1, setup(e, { slots: a, attrs: t, emit: n }) {
  let r = z(1), i = z(null), l = z(null), o = z(null), s = z(null), d = z({ static: !1, hold: !1 }), u = z([]), c = z(null), f = z(2), v = z(!1);
  function g(M = (B) => B) {
    let B = c.value !== null ? u.value[c.value] : null, X = M(u.value.slice()), te = X.length > 0 && X[0].dataRef.order.value !== null ? X.sort((E, I) => E.dataRef.order.value - I.dataRef.order.value) : Fr(X, (E) => he(E.dataRef.domRef)), T = B ? te.indexOf(B) : null;
    return T === -1 && (T = null), { options: te, activeOptionIndex: T };
  }
  let b = A(() => e.multiple ? 1 : 0), C = A(() => e.nullable), [p, w] = vi(A(() => e.modelValue), (M) => n("update:modelValue", M), A(() => e.defaultValue)), y = A(() => p.value === void 0 ? ut(b.value, { 1: [], 0: void 0 }) : p.value), x = null, D = null;
  function S(M) {
    return ut(b.value, { 0() {
      return w == null ? void 0 : w(M);
    }, 1: () => {
      let B = pt(O.value.value).slice(), X = pt(M), te = B.findIndex((T) => O.compare(X, pt(T)));
      return te === -1 ? B.push(X) : B.splice(te, 1), w == null ? void 0 : w(B);
    } });
  }
  let $ = A(() => {
  });
  Ge([$], ([M], [B]) => {
    if (O.virtual.value && M && B && c.value !== null) {
      let X = M.indexOf(B[c.value]);
      X !== -1 ? c.value = X : c.value = null;
    }
  });
  let O = { comboboxState: r, value: y, mode: b, compare(M, B) {
    if (typeof e.by == "string") {
      let X = e.by;
      return (M == null ? void 0 : M[X]) === (B == null ? void 0 : B[X]);
    }
    return e.by === null ? R0(M, B) : e.by(M, B);
  }, calculateIndex(M) {
    return O.virtual.value ? e.by === null ? O.virtual.value.options.indexOf(M) : O.virtual.value.options.findIndex((B) => O.compare(B, M)) : u.value.findIndex((B) => O.compare(B.dataRef.value, M));
  }, defaultValue: A(() => e.defaultValue), nullable: C, immediate: A(() => !1), virtual: A(() => null), inputRef: l, labelRef: i, buttonRef: o, optionsRef: s, disabled: A(() => e.disabled), options: u, change(M) {
    w(M);
  }, activeOptionIndex: A(() => {
    if (v.value && c.value === null && (O.virtual.value ? O.virtual.value.options.length > 0 : u.value.length > 0)) {
      if (O.virtual.value) {
        let B = O.virtual.value.options.findIndex((X) => {
          var te;
          return !((te = O.virtual.value) != null && te.disabled(X));
        });
        if (B !== -1)
          return B;
      }
      let M = u.value.findIndex((B) => !B.dataRef.disabled);
      if (M !== -1)
        return M;
    }
    return c.value;
  }), activationTrigger: f, optionsPropsRef: d, closeCombobox() {
    v.value = !1, !e.disabled && r.value !== 1 && (r.value = 1, c.value = null);
  }, openCombobox() {
    if (v.value = !0, !e.disabled && r.value !== 0) {
      if (O.value.value) {
        let M = O.calculateIndex(O.value.value);
        M !== -1 && (c.value = M);
      }
      r.value = 0;
    }
  }, setActivationTrigger(M) {
    f.value = M;
  }, goToOption(M, B, X) {
    v.value = !1, x !== null && cancelAnimationFrame(x), x = requestAnimationFrame(() => {
      if (e.disabled || s.value && !d.value.static && r.value === 1)
        return;
      if (O.virtual.value) {
        c.value = M === De.Specific ? B : _r({ focus: M }, { resolveItems: () => O.virtual.value.options, resolveActiveIndex: () => {
          var E, I;
          return (I = (E = O.activeOptionIndex.value) != null ? E : O.virtual.value.options.findIndex((q) => {
            var ne;
            return !((ne = O.virtual.value) != null && ne.disabled(q));
          })) != null ? I : null;
        }, resolveDisabled: (E) => O.virtual.value.disabled(E), resolveId() {
          throw new Error("Function not implemented.");
        } }), f.value = X ?? 2;
        return;
      }
      let te = g();
      if (te.activeOptionIndex === null) {
        let E = te.options.findIndex((I) => !I.dataRef.disabled);
        E !== -1 && (te.activeOptionIndex = E);
      }
      let T = M === De.Specific ? B : _r({ focus: M }, { resolveItems: () => te.options, resolveActiveIndex: () => te.activeOptionIndex, resolveId: (E) => E.id, resolveDisabled: (E) => E.dataRef.disabled });
      c.value = T, f.value = X ?? 2, u.value = te.options;
    });
  }, selectOption(M) {
    let B = u.value.find((te) => te.id === M);
    if (!B)
      return;
    let { dataRef: X } = B;
    S(X.value);
  }, selectActiveOption() {
    if (O.activeOptionIndex.value !== null) {
      if (O.virtual.value)
        S(O.virtual.value.options[O.activeOptionIndex.value]);
      else {
        let { dataRef: M } = u.value[O.activeOptionIndex.value];
        S(M.value);
      }
      O.goToOption(De.Specific, O.activeOptionIndex.value);
    }
  }, registerOption(M, B) {
    let X = qa({ id: M, dataRef: B });
    if (O.virtual.value) {
      u.value.push(X);
      return;
    }
    D && cancelAnimationFrame(D);
    let te = g((T) => (T.push(X), T));
    c.value === null && O.isSelected(B.value.value) && (te.activeOptionIndex = te.options.indexOf(X)), u.value = te.options, c.value = te.activeOptionIndex, f.value = 2, te.options.some((T) => !he(T.dataRef.domRef)) && (D = requestAnimationFrame(() => {
      let T = g();
      u.value = T.options, c.value = T.activeOptionIndex;
    }));
  }, unregisterOption(M, B) {
    if (x !== null && cancelAnimationFrame(x), B && (v.value = !0), O.virtual.value) {
      u.value = u.value.filter((te) => te.id !== M);
      return;
    }
    let X = g((te) => {
      let T = te.findIndex((E) => E.id === M);
      return T !== -1 && te.splice(T, 1), te;
    });
    u.value = X.options, c.value = X.activeOptionIndex, f.value = 2;
  }, isSelected(M) {
    return ut(b.value, { 0: () => O.compare(pt(O.value.value), pt(M)), 1: () => pt(O.value.value).some((B) => O.compare(pt(B), pt(M))) });
  }, isActive(M) {
    return c.value === O.calculateIndex(M);
  } };
  mi([l, o, s], () => O.closeCombobox(), A(() => r.value === 0)), Nt(qs, O), Br(A(() => ut(r.value, { 0: st.Open, 1: st.Closed })));
  let N = A(() => {
    var M;
    return (M = he(l)) == null ? void 0 : M.closest("form");
  });
  return He(() => {
    Ge([N], () => {
      if (!N.value || e.defaultValue === void 0)
        return;
      function M() {
        O.change(e.defaultValue);
      }
      return N.value.addEventListener("reset", M), () => {
        var B;
        (B = N.value) == null || B.removeEventListener("reset", M);
      };
    }, { immediate: !0 });
  }), () => {
    var M, B, X;
    let { name: te, disabled: T, form: E, ...I } = e, q = { open: r.value === 0, disabled: T, activeIndex: O.activeOptionIndex.value, activeOption: O.activeOptionIndex.value === null ? null : O.virtual.value ? O.virtual.value.options[(M = O.activeOptionIndex.value) != null ? M : 0] : (X = (B = O.options.value[O.activeOptionIndex.value]) == null ? void 0 : B.dataRef.value) != null ? X : null, value: y.value };
    return At(Se, [...te != null && y.value != null ? gi({ [te]: y.value }).map(([ne, U]) => At(yi, hi({ features: Yr.Hidden, key: ne, as: "input", type: "hidden", hidden: !0, readOnly: !0, form: E, name: ne, value: U }))) : [], rt({ theirProps: { ...t, ...Xa(I, ["by", "defaultValue", "immediate", "modelValue", "multiple", "nullable", "onUpdate:modelValue", "virtual"]) }, ourProps: {}, slot: q, slots: a, attrs: t, name: "Combobox" })]);
  };
} });
be({ name: "ComboboxLabel", props: { as: { type: [Object, String], default: "label" }, id: { type: String, default: null } }, setup(e, { attrs: a, slots: t }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-combobox-label-${_t()}`, i = Qa("ComboboxLabel");
  function l() {
    var o;
    (o = he(i.inputRef)) == null || o.focus({ preventScroll: !0 });
  }
  return () => {
    let o = { open: i.comboboxState.value === 0, disabled: i.disabled.value }, { ...s } = e, d = { id: r, ref: i.labelRef, onClick: l };
    return rt({ ourProps: d, theirProps: s, slot: o, attrs: a, slots: t, name: "ComboboxLabel" });
  };
} });
let V0 = be({ name: "ComboboxButton", props: { as: { type: [Object, String], default: "button" }, id: { type: String, default: null } }, setup(e, { attrs: a, slots: t, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-combobox-button-${_t()}`, l = Qa("ComboboxButton");
  n({ el: l.buttonRef, $el: l.buttonRef });
  function o(u) {
    l.disabled.value || (l.comboboxState.value === 0 ? l.closeCombobox() : (u.preventDefault(), l.openCombobox()), Ne(() => {
      var c;
      return (c = he(l.inputRef)) == null ? void 0 : c.focus({ preventScroll: !0 });
    }));
  }
  function s(u) {
    switch (u.key) {
      case Ce.ArrowDown:
        u.preventDefault(), u.stopPropagation(), l.comboboxState.value === 1 && l.openCombobox(), Ne(() => {
          var c;
          return (c = l.inputRef.value) == null ? void 0 : c.focus({ preventScroll: !0 });
        });
        return;
      case Ce.ArrowUp:
        u.preventDefault(), u.stopPropagation(), l.comboboxState.value === 1 && (l.openCombobox(), Ne(() => {
          l.value.value || l.goToOption(De.Last);
        })), Ne(() => {
          var c;
          return (c = l.inputRef.value) == null ? void 0 : c.focus({ preventScroll: !0 });
        });
        return;
      case Ce.Escape:
        if (l.comboboxState.value !== 0)
          return;
        u.preventDefault(), l.optionsRef.value && !l.optionsPropsRef.value.static && u.stopPropagation(), l.closeCombobox(), Ne(() => {
          var c;
          return (c = l.inputRef.value) == null ? void 0 : c.focus({ preventScroll: !0 });
        });
        return;
    }
  }
  let d = Vr(A(() => ({ as: e.as, type: a.type })), l.buttonRef);
  return () => {
    var u, c;
    let f = { open: l.comboboxState.value === 0, disabled: l.disabled.value, value: l.value.value }, { ...v } = e, g = { ref: l.buttonRef, id: i, type: d.value, tabindex: "-1", "aria-haspopup": "listbox", "aria-controls": (u = he(l.optionsRef)) == null ? void 0 : u.id, "aria-expanded": l.comboboxState.value === 0, "aria-labelledby": l.labelRef.value ? [(c = he(l.labelRef)) == null ? void 0 : c.id, i].join(" ") : void 0, disabled: l.disabled.value === !0 ? !0 : void 0, onKeydown: s, onClick: o };
    return rt({ ourProps: g, theirProps: v, slot: f, attrs: a, slots: t, name: "ComboboxButton" });
  };
} }), Y0 = be({ name: "ComboboxInput", props: { as: { type: [Object, String], default: "input" }, static: { type: Boolean, default: !1 }, unmount: { type: Boolean, default: !0 }, displayValue: { type: Function }, defaultValue: { type: String, default: void 0 }, id: { type: String, default: null } }, emits: { change: (e) => !0 }, setup(e, { emit: a, attrs: t, slots: n, expose: r }) {
  var i;
  let l = (i = e.id) != null ? i : `headlessui-combobox-input-${_t()}`, o = Qa("ComboboxInput"), s = A(() => Lr(he(o.inputRef))), d = { value: !1 };
  r({ el: o.inputRef, $el: o.inputRef });
  function u() {
    o.change(null);
    let x = he(o.optionsRef);
    x && (x.scrollTop = 0), o.goToOption(De.Nothing);
  }
  let c = A(() => {
    var x;
    let D = o.value.value;
    return he(o.inputRef) ? typeof e.displayValue < "u" && D !== void 0 ? (x = e.displayValue(D)) != null ? x : "" : typeof D == "string" ? D : "" : "";
  });
  He(() => {
    Ge([c, o.comboboxState, s], ([x, D], [S, $]) => {
      if (d.value)
        return;
      let O = he(o.inputRef);
      O && (($ === 0 && D === 1 || x !== S) && (O.value = x), requestAnimationFrame(() => {
        var N;
        if (d.value || !O || ((N = s.value) == null ? void 0 : N.activeElement) !== O)
          return;
        let { selectionStart: M, selectionEnd: B } = O;
        Math.abs((B ?? 0) - (M ?? 0)) === 0 && M === 0 && O.setSelectionRange(O.value.length, O.value.length);
      }));
    }, { immediate: !0 }), Ge([o.comboboxState], ([x], [D]) => {
      if (x === 0 && D === 1) {
        if (d.value)
          return;
        let S = he(o.inputRef);
        if (!S)
          return;
        let $ = S.value, { selectionStart: O, selectionEnd: N, selectionDirection: M } = S;
        S.value = "", S.value = $, M !== null ? S.setSelectionRange(O, N, M) : S.setSelectionRange(O, N);
      }
    });
  });
  let f = z(!1);
  function v() {
    f.value = !0;
  }
  function g() {
    Nn().nextFrame(() => {
      f.value = !1;
    });
  }
  function b(x) {
    switch (d.value = !0, x.key) {
      case Ce.Enter:
        if (d.value = !1, o.comboboxState.value !== 0 || f.value)
          return;
        if (x.preventDefault(), x.stopPropagation(), o.activeOptionIndex.value === null) {
          o.closeCombobox();
          return;
        }
        o.selectActiveOption(), o.mode.value === 0 && o.closeCombobox();
        break;
      case Ce.ArrowDown:
        return d.value = !1, x.preventDefault(), x.stopPropagation(), ut(o.comboboxState.value, { 0: () => o.goToOption(De.Next), 1: () => o.openCombobox() });
      case Ce.ArrowUp:
        return d.value = !1, x.preventDefault(), x.stopPropagation(), ut(o.comboboxState.value, { 0: () => o.goToOption(De.Previous), 1: () => {
          o.openCombobox(), Ne(() => {
            o.value.value || o.goToOption(De.Last);
          });
        } });
      case Ce.Home:
        if (x.shiftKey)
          break;
        return d.value = !1, x.preventDefault(), x.stopPropagation(), o.goToOption(De.First);
      case Ce.PageUp:
        return d.value = !1, x.preventDefault(), x.stopPropagation(), o.goToOption(De.First);
      case Ce.End:
        if (x.shiftKey)
          break;
        return d.value = !1, x.preventDefault(), x.stopPropagation(), o.goToOption(De.Last);
      case Ce.PageDown:
        return d.value = !1, x.preventDefault(), x.stopPropagation(), o.goToOption(De.Last);
      case Ce.Escape:
        if (d.value = !1, o.comboboxState.value !== 0)
          return;
        x.preventDefault(), o.optionsRef.value && !o.optionsPropsRef.value.static && x.stopPropagation(), o.nullable.value && o.mode.value === 0 && o.value.value === null && u(), o.closeCombobox();
        break;
      case Ce.Tab:
        if (d.value = !1, o.comboboxState.value !== 0)
          return;
        o.mode.value === 0 && o.activationTrigger.value !== 1 && o.selectActiveOption(), o.closeCombobox();
        break;
    }
  }
  function C(x) {
    a("change", x), o.nullable.value && o.mode.value === 0 && x.target.value === "" && u(), o.openCombobox();
  }
  function p(x) {
    var D, S, $;
    let O = (D = x.relatedTarget) != null ? D : La.find((N) => N !== x.currentTarget);
    if (d.value = !1, !((S = he(o.optionsRef)) != null && S.contains(O)) && !(($ = he(o.buttonRef)) != null && $.contains(O)) && o.comboboxState.value === 0)
      return x.preventDefault(), o.mode.value === 0 && (o.nullable.value && o.value.value === null ? u() : o.activationTrigger.value !== 1 && o.selectActiveOption()), o.closeCombobox();
  }
  function w(x) {
    var D, S, $;
    let O = (D = x.relatedTarget) != null ? D : La.find((N) => N !== x.currentTarget);
    (S = he(o.buttonRef)) != null && S.contains(O) || ($ = he(o.optionsRef)) != null && $.contains(O) || o.disabled.value || o.immediate.value && o.comboboxState.value !== 0 && (o.openCombobox(), Nn().nextFrame(() => {
      o.setActivationTrigger(1);
    }));
  }
  let y = A(() => {
    var x, D, S, $;
    return ($ = (S = (D = e.defaultValue) != null ? D : o.defaultValue.value !== void 0 ? (x = e.displayValue) == null ? void 0 : x.call(e, o.defaultValue.value) : null) != null ? S : o.defaultValue.value) != null ? $ : "";
  });
  return () => {
    var x, D, S, $, O, N, M;
    let B = { open: o.comboboxState.value === 0 }, { displayValue: X, onChange: te, ...T } = e, E = { "aria-controls": (x = o.optionsRef.value) == null ? void 0 : x.id, "aria-expanded": o.comboboxState.value === 0, "aria-activedescendant": o.activeOptionIndex.value === null ? void 0 : o.virtual.value ? (D = o.options.value.find((I) => !o.virtual.value.disabled(I.dataRef.value) && o.compare(I.dataRef.value, o.virtual.value.options[o.activeOptionIndex.value]))) == null ? void 0 : D.id : (S = o.options.value[o.activeOptionIndex.value]) == null ? void 0 : S.id, "aria-labelledby": (N = ($ = he(o.labelRef)) == null ? void 0 : $.id) != null ? N : (O = he(o.buttonRef)) == null ? void 0 : O.id, "aria-autocomplete": "list", id: l, onCompositionstart: v, onCompositionend: g, onKeydown: b, onInput: C, onFocus: w, onBlur: p, role: "combobox", type: (M = t.type) != null ? M : "text", tabIndex: 0, ref: o.inputRef, defaultValue: y.value, disabled: o.disabled.value === !0 ? !0 : void 0 };
    return rt({ ourProps: E, theirProps: T, slot: B, attrs: t, slots: n, features: la.RenderStrategy | la.Static, name: "ComboboxInput" });
  };
} }), B0 = be({ name: "ComboboxOptions", props: { as: { type: [Object, String], default: "ul" }, static: { type: Boolean, default: !1 }, unmount: { type: Boolean, default: !0 }, hold: { type: [Boolean], default: !1 } }, setup(e, { attrs: a, slots: t, expose: n }) {
  let r = Qa("ComboboxOptions"), i = `headlessui-combobox-options-${_t()}`;
  n({ el: r.optionsRef, $el: r.optionsRef }), Dt(() => {
    r.optionsPropsRef.value.static = e.static;
  }), Dt(() => {
    r.optionsPropsRef.value.hold = e.hold;
  });
  let l = Un(), o = A(() => l !== null ? (l.value & st.Open) === st.Open : r.comboboxState.value === 0);
  return Vs({ container: A(() => he(r.optionsRef)), enabled: A(() => r.comboboxState.value === 0), accept(s) {
    return s.getAttribute("role") === "option" ? NodeFilter.FILTER_REJECT : s.hasAttribute("role") ? NodeFilter.FILTER_SKIP : NodeFilter.FILTER_ACCEPT;
  }, walk(s) {
    s.setAttribute("role", "none");
  } }), () => {
    var s, d, u;
    let c = { open: r.comboboxState.value === 0 }, f = { "aria-labelledby": (u = (s = he(r.labelRef)) == null ? void 0 : s.id) != null ? u : (d = he(r.buttonRef)) == null ? void 0 : d.id, id: i, ref: r.optionsRef, role: "listbox", "aria-multiselectable": r.mode.value === 1 ? !0 : void 0 }, v = Xa(e, ["hold"]);
    return rt({ ourProps: f, theirProps: v, slot: c, attrs: a, slots: r.virtual.value && r.comboboxState.value === 0 ? { ...t, default: () => [At(z0, {}, t.default)] } : t, features: la.RenderStrategy | la.Static, visible: o.value, name: "ComboboxOptions" });
  };
} }), j0 = be({ name: "ComboboxOption", props: { as: { type: [Object, String], default: "li" }, value: { type: [Object, String, Number, Boolean] }, disabled: { type: Boolean, default: !1 }, order: { type: [Number], default: null } }, setup(e, { slots: a, attrs: t, expose: n }) {
  let r = Qa("ComboboxOption"), i = `headlessui-combobox-option-${_t()}`, l = z(null);
  n({ el: l, $el: l });
  let o = A(() => {
    var p;
    return r.virtual.value ? r.activeOptionIndex.value === r.calculateIndex(e.value) : r.activeOptionIndex.value === null ? !1 : ((p = r.options.value[r.activeOptionIndex.value]) == null ? void 0 : p.id) === i;
  }), s = A(() => r.isSelected(e.value)), d = It(Ks, null), u = A(() => ({ disabled: e.disabled, value: e.value, domRef: l, order: A(() => e.order) }));
  He(() => r.registerOption(i, u)), Ht(() => r.unregisterOption(i, o.value)), Dt(() => {
    let p = he(l);
    p && (d == null || d.value.measureElement(p));
  }), Dt(() => {
    r.comboboxState.value === 0 && o.value && (r.virtual.value || r.activationTrigger.value !== 0 && Ne(() => {
      var p, w;
      return (w = (p = he(l)) == null ? void 0 : p.scrollIntoView) == null ? void 0 : w.call(p, { block: "nearest" });
    }));
  });
  function c(p) {
    var w;
    if (e.disabled || (w = r.virtual.value) != null && w.disabled(e.value))
      return p.preventDefault();
    r.selectOption(i), Fs() || requestAnimationFrame(() => {
      var y;
      return (y = he(r.inputRef)) == null ? void 0 : y.focus({ preventScroll: !0 });
    }), r.mode.value === 0 && requestAnimationFrame(() => r.closeCombobox());
  }
  function f() {
    var p;
    if (e.disabled || (p = r.virtual.value) != null && p.disabled(e.value))
      return r.goToOption(De.Nothing);
    let w = r.calculateIndex(e.value);
    r.goToOption(De.Specific, w);
  }
  let v = pi();
  function g(p) {
    v.update(p);
  }
  function b(p) {
    var w;
    if (!v.wasMoved(p) || e.disabled || (w = r.virtual.value) != null && w.disabled(e.value) || o.value)
      return;
    let y = r.calculateIndex(e.value);
    r.goToOption(De.Specific, y, 0);
  }
  function C(p) {
    var w;
    v.wasMoved(p) && (e.disabled || (w = r.virtual.value) != null && w.disabled(e.value) || o.value && (r.optionsPropsRef.value.hold || r.goToOption(De.Nothing)));
  }
  return () => {
    let { disabled: p } = e, w = { active: o.value, selected: s.value, disabled: p }, y = { id: i, ref: l, role: "option", tabIndex: p === !0 ? void 0 : -1, "aria-disabled": p === !0 ? !0 : void 0, "aria-selected": s.value, disabled: void 0, onClick: c, onFocus: f, onPointerenter: g, onMouseenter: g, onPointermove: b, onMousemove: b, onPointerleave: C, onMouseleave: C }, x = Xa(e, ["order", "value"]);
    return rt({ ourProps: y, theirProps: x, slot: w, attrs: t, slots: a, name: "ComboboxOption" });
  };
} }), Xs = Symbol("DescriptionContext");
function U0() {
  let e = It(Xs, null);
  if (e === null)
    throw new Error("Missing parent");
  return e;
}
function W0({ slot: e = z({}), name: a = "Description", props: t = {} } = {}) {
  let n = z([]);
  function r(i) {
    return n.value.push(i), () => {
      let l = n.value.indexOf(i);
      l !== -1 && n.value.splice(l, 1);
    };
  }
  return Nt(Xs, { register: r, slot: e, name: a, props: t }), A(() => n.value.length > 0 ? n.value.join(" ") : void 0);
}
be({ name: "Description", props: { as: { type: [Object, String], default: "p" }, id: { type: String, default: null } }, setup(e, { attrs: a, slots: t }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-description-${_t()}`, i = U0();
  return He(() => Ht(i.register(r))), () => {
    let { name: l = "Description", slot: o = z({}), props: s = {} } = i, { ...d } = e, u = { ...Object.entries(s).reduce((c, [f, v]) => Object.assign(c, { [f]: m(v) }), {}), id: r };
    return rt({ ourProps: u, theirProps: d, slot: o.value, attrs: a, slots: t, name: l });
  };
} });
let _o = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
function Mo(e) {
  var a, t;
  let n = (a = e.innerText) != null ? a : "", r = e.cloneNode(!0);
  if (!(r instanceof HTMLElement))
    return n;
  let i = !1;
  for (let o of r.querySelectorAll('[hidden],[aria-hidden],[role="img"]'))
    o.remove(), i = !0;
  let l = i ? (t = r.innerText) != null ? t : "" : n;
  return _o.test(l) && (l = l.replace(_o, "")), l;
}
function q0(e) {
  let a = e.getAttribute("aria-label");
  if (typeof a == "string")
    return a.trim();
  let t = e.getAttribute("aria-labelledby");
  if (t) {
    let n = t.split(" ").map((r) => {
      let i = document.getElementById(r);
      if (i) {
        let l = i.getAttribute("aria-label");
        return typeof l == "string" ? l.trim() : Mo(i).trim();
      }
      return null;
    }).filter(Boolean);
    if (n.length > 0)
      return n.join(", ");
  }
  return Mo(e).trim();
}
function Qs(e) {
  let a = z(""), t = z("");
  return () => {
    let n = he(e);
    if (!n)
      return "";
    let r = n.innerText;
    if (a.value === r)
      return t.value;
    let i = q0(n).trim().toLowerCase();
    return a.value = r, t.value = i, i;
  };
}
function K0(e, a) {
  return e === a;
}
var X0 = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(X0 || {}), Q0 = ((e) => (e[e.Single = 0] = "Single", e[e.Multi = 1] = "Multi", e))(Q0 || {}), G0 = ((e) => (e[e.Pointer = 0] = "Pointer", e[e.Other = 1] = "Other", e))(G0 || {});
function J0(e) {
  requestAnimationFrame(() => requestAnimationFrame(e));
}
let Gs = Symbol("ListboxContext");
function Wn(e) {
  let a = It(Gs, null);
  if (a === null) {
    let t = new Error(`<${e} /> is missing a parent <Listbox /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(t, Wn), t;
  }
  return a;
}
let Z0 = be({ name: "Listbox", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "template" }, disabled: { type: [Boolean], default: !1 }, by: { type: [String, Function], default: () => K0 }, horizontal: { type: [Boolean], default: !1 }, modelValue: { type: [Object, String, Number, Boolean], default: void 0 }, defaultValue: { type: [Object, String, Number, Boolean], default: void 0 }, form: { type: String, optional: !0 }, name: { type: String, optional: !0 }, multiple: { type: [Boolean], default: !1 } }, inheritAttrs: !1, setup(e, { slots: a, attrs: t, emit: n }) {
  let r = z(1), i = z(null), l = z(null), o = z(null), s = z([]), d = z(""), u = z(null), c = z(1);
  function f(y = (x) => x) {
    let x = u.value !== null ? s.value[u.value] : null, D = Fr(y(s.value.slice()), ($) => he($.dataRef.domRef)), S = x ? D.indexOf(x) : null;
    return S === -1 && (S = null), { options: D, activeOptionIndex: S };
  }
  let v = A(() => e.multiple ? 1 : 0), [g, b] = vi(A(() => e.modelValue), (y) => n("update:modelValue", y), A(() => e.defaultValue)), C = A(() => g.value === void 0 ? ut(v.value, { 1: [], 0: void 0 }) : g.value), p = { listboxState: r, value: C, mode: v, compare(y, x) {
    if (typeof e.by == "string") {
      let D = e.by;
      return (y == null ? void 0 : y[D]) === (x == null ? void 0 : x[D]);
    }
    return e.by(y, x);
  }, orientation: A(() => e.horizontal ? "horizontal" : "vertical"), labelRef: i, buttonRef: l, optionsRef: o, disabled: A(() => e.disabled), options: s, searchQuery: d, activeOptionIndex: u, activationTrigger: c, closeListbox() {
    e.disabled || r.value !== 1 && (r.value = 1, u.value = null);
  }, openListbox() {
    e.disabled || r.value !== 0 && (r.value = 0);
  }, goToOption(y, x, D) {
    if (e.disabled || r.value === 1)
      return;
    let S = f(), $ = _r(y === De.Specific ? { focus: De.Specific, id: x } : { focus: y }, { resolveItems: () => S.options, resolveActiveIndex: () => S.activeOptionIndex, resolveId: (O) => O.id, resolveDisabled: (O) => O.dataRef.disabled });
    d.value = "", u.value = $, c.value = D ?? 1, s.value = S.options;
  }, search(y) {
    if (e.disabled || r.value === 1)
      return;
    let x = d.value !== "" ? 0 : 1;
    d.value += y.toLowerCase();
    let D = (u.value !== null ? s.value.slice(u.value + x).concat(s.value.slice(0, u.value + x)) : s.value).find(($) => $.dataRef.textValue.startsWith(d.value) && !$.dataRef.disabled), S = D ? s.value.indexOf(D) : -1;
    S === -1 || S === u.value || (u.value = S, c.value = 1);
  }, clearSearch() {
    e.disabled || r.value !== 1 && d.value !== "" && (d.value = "");
  }, registerOption(y, x) {
    let D = f((S) => [...S, { id: y, dataRef: x }]);
    s.value = D.options, u.value = D.activeOptionIndex;
  }, unregisterOption(y) {
    let x = f((D) => {
      let S = D.findIndex(($) => $.id === y);
      return S !== -1 && D.splice(S, 1), D;
    });
    s.value = x.options, u.value = x.activeOptionIndex, c.value = 1;
  }, theirOnChange(y) {
    e.disabled || b(y);
  }, select(y) {
    e.disabled || b(ut(v.value, { 0: () => y, 1: () => {
      let x = pt(p.value.value).slice(), D = pt(y), S = x.findIndex(($) => p.compare(D, pt($)));
      return S === -1 ? x.push(D) : x.splice(S, 1), x;
    } }));
  } };
  mi([l, o], (y, x) => {
    var D;
    p.closeListbox(), zr(x, Hr.Loose) || (y.preventDefault(), (D = he(l)) == null || D.focus());
  }, A(() => r.value === 0)), Nt(Gs, p), Br(A(() => ut(r.value, { 0: st.Open, 1: st.Closed })));
  let w = A(() => {
    var y;
    return (y = he(l)) == null ? void 0 : y.closest("form");
  });
  return He(() => {
    Ge([w], () => {
      if (!w.value || e.defaultValue === void 0)
        return;
      function y() {
        p.theirOnChange(e.defaultValue);
      }
      return w.value.addEventListener("reset", y), () => {
        var x;
        (x = w.value) == null || x.removeEventListener("reset", y);
      };
    }, { immediate: !0 });
  }), () => {
    let { name: y, modelValue: x, disabled: D, form: S, ...$ } = e, O = { open: r.value === 0, disabled: D, value: C.value };
    return At(Se, [...y != null && C.value != null ? gi({ [y]: C.value }).map(([N, M]) => At(yi, hi({ features: Yr.Hidden, key: N, as: "input", type: "hidden", hidden: !0, readOnly: !0, form: S, name: N, value: M }))) : [], rt({ ourProps: {}, theirProps: { ...t, ...Xa($, ["defaultValue", "onUpdate:modelValue", "horizontal", "multiple", "by"]) }, slot: O, slots: a, attrs: t, name: "Listbox" })]);
  };
} });
be({ name: "ListboxLabel", props: { as: { type: [Object, String], default: "label" }, id: { type: String, default: null } }, setup(e, { attrs: a, slots: t }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-listbox-label-${_t()}`, i = Wn("ListboxLabel");
  function l() {
    var o;
    (o = he(i.buttonRef)) == null || o.focus({ preventScroll: !0 });
  }
  return () => {
    let o = { open: i.listboxState.value === 0, disabled: i.disabled.value }, { ...s } = e, d = { id: r, ref: i.labelRef, onClick: l };
    return rt({ ourProps: d, theirProps: s, slot: o, attrs: a, slots: t, name: "ListboxLabel" });
  };
} });
let e1 = be({ name: "ListboxButton", props: { as: { type: [Object, String], default: "button" }, id: { type: String, default: null } }, setup(e, { attrs: a, slots: t, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-listbox-button-${_t()}`, l = Wn("ListboxButton");
  n({ el: l.buttonRef, $el: l.buttonRef });
  function o(c) {
    switch (c.key) {
      case Ce.Space:
      case Ce.Enter:
      case Ce.ArrowDown:
        c.preventDefault(), l.openListbox(), Ne(() => {
          var f;
          (f = he(l.optionsRef)) == null || f.focus({ preventScroll: !0 }), l.value.value || l.goToOption(De.First);
        });
        break;
      case Ce.ArrowUp:
        c.preventDefault(), l.openListbox(), Ne(() => {
          var f;
          (f = he(l.optionsRef)) == null || f.focus({ preventScroll: !0 }), l.value.value || l.goToOption(De.Last);
        });
        break;
    }
  }
  function s(c) {
    switch (c.key) {
      case Ce.Space:
        c.preventDefault();
        break;
    }
  }
  function d(c) {
    l.disabled.value || (l.listboxState.value === 0 ? (l.closeListbox(), Ne(() => {
      var f;
      return (f = he(l.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })) : (c.preventDefault(), l.openListbox(), J0(() => {
      var f;
      return (f = he(l.optionsRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })));
  }
  let u = Vr(A(() => ({ as: e.as, type: a.type })), l.buttonRef);
  return () => {
    var c, f;
    let v = { open: l.listboxState.value === 0, disabled: l.disabled.value, value: l.value.value }, { ...g } = e, b = { ref: l.buttonRef, id: i, type: u.value, "aria-haspopup": "listbox", "aria-controls": (c = he(l.optionsRef)) == null ? void 0 : c.id, "aria-expanded": l.listboxState.value === 0, "aria-labelledby": l.labelRef.value ? [(f = he(l.labelRef)) == null ? void 0 : f.id, i].join(" ") : void 0, disabled: l.disabled.value === !0 ? !0 : void 0, onKeydown: o, onKeyup: s, onClick: d };
    return rt({ ourProps: b, theirProps: g, slot: v, attrs: a, slots: t, name: "ListboxButton" });
  };
} }), t1 = be({ name: "ListboxOptions", props: { as: { type: [Object, String], default: "ul" }, static: { type: Boolean, default: !1 }, unmount: { type: Boolean, default: !0 }, id: { type: String, default: null } }, setup(e, { attrs: a, slots: t, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-listbox-options-${_t()}`, l = Wn("ListboxOptions"), o = z(null);
  n({ el: l.optionsRef, $el: l.optionsRef });
  function s(c) {
    switch (o.value && clearTimeout(o.value), c.key) {
      case Ce.Space:
        if (l.searchQuery.value !== "")
          return c.preventDefault(), c.stopPropagation(), l.search(c.key);
      case Ce.Enter:
        if (c.preventDefault(), c.stopPropagation(), l.activeOptionIndex.value !== null) {
          let f = l.options.value[l.activeOptionIndex.value];
          l.select(f.dataRef.value);
        }
        l.mode.value === 0 && (l.closeListbox(), Ne(() => {
          var f;
          return (f = he(l.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
        }));
        break;
      case ut(l.orientation.value, { vertical: Ce.ArrowDown, horizontal: Ce.ArrowRight }):
        return c.preventDefault(), c.stopPropagation(), l.goToOption(De.Next);
      case ut(l.orientation.value, { vertical: Ce.ArrowUp, horizontal: Ce.ArrowLeft }):
        return c.preventDefault(), c.stopPropagation(), l.goToOption(De.Previous);
      case Ce.Home:
      case Ce.PageUp:
        return c.preventDefault(), c.stopPropagation(), l.goToOption(De.First);
      case Ce.End:
      case Ce.PageDown:
        return c.preventDefault(), c.stopPropagation(), l.goToOption(De.Last);
      case Ce.Escape:
        c.preventDefault(), c.stopPropagation(), l.closeListbox(), Ne(() => {
          var f;
          return (f = he(l.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
        });
        break;
      case Ce.Tab:
        c.preventDefault(), c.stopPropagation();
        break;
      default:
        c.key.length === 1 && (l.search(c.key), o.value = setTimeout(() => l.clearSearch(), 350));
        break;
    }
  }
  let d = Un(), u = A(() => d !== null ? (d.value & st.Open) === st.Open : l.listboxState.value === 0);
  return () => {
    var c, f;
    let v = { open: l.listboxState.value === 0 }, { ...g } = e, b = { "aria-activedescendant": l.activeOptionIndex.value === null || (c = l.options.value[l.activeOptionIndex.value]) == null ? void 0 : c.id, "aria-multiselectable": l.mode.value === 1 ? !0 : void 0, "aria-labelledby": (f = he(l.buttonRef)) == null ? void 0 : f.id, "aria-orientation": l.orientation.value, id: i, onKeydown: s, role: "listbox", tabIndex: 0, ref: l.optionsRef };
    return rt({ ourProps: b, theirProps: g, slot: v, attrs: a, slots: t, features: la.RenderStrategy | la.Static, visible: u.value, name: "ListboxOptions" });
  };
} }), a1 = be({ name: "ListboxOption", props: { as: { type: [Object, String], default: "li" }, value: { type: [Object, String, Number, Boolean] }, disabled: { type: Boolean, default: !1 }, id: { type: String, default: null } }, setup(e, { slots: a, attrs: t, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-listbox-option-${_t()}`, l = Wn("ListboxOption"), o = z(null);
  n({ el: o, $el: o });
  let s = A(() => l.activeOptionIndex.value !== null ? l.options.value[l.activeOptionIndex.value].id === i : !1), d = A(() => ut(l.mode.value, { 0: () => l.compare(pt(l.value.value), pt(e.value)), 1: () => pt(l.value.value).some((y) => l.compare(pt(y), pt(e.value))) })), u = A(() => ut(l.mode.value, { 1: () => {
    var y;
    let x = pt(l.value.value);
    return ((y = l.options.value.find((D) => x.some((S) => l.compare(pt(S), pt(D.dataRef.value))))) == null ? void 0 : y.id) === i;
  }, 0: () => d.value })), c = Qs(o), f = A(() => ({ disabled: e.disabled, value: e.value, get textValue() {
    return c();
  }, domRef: o }));
  He(() => l.registerOption(i, f)), Ht(() => l.unregisterOption(i)), He(() => {
    Ge([l.listboxState, d], () => {
      l.listboxState.value === 0 && d.value && ut(l.mode.value, { 1: () => {
        u.value && l.goToOption(De.Specific, i);
      }, 0: () => {
        l.goToOption(De.Specific, i);
      } });
    }, { immediate: !0 });
  }), Dt(() => {
    l.listboxState.value === 0 && s.value && l.activationTrigger.value !== 0 && Ne(() => {
      var y, x;
      return (x = (y = he(o)) == null ? void 0 : y.scrollIntoView) == null ? void 0 : x.call(y, { block: "nearest" });
    });
  });
  function v(y) {
    if (e.disabled)
      return y.preventDefault();
    l.select(e.value), l.mode.value === 0 && (l.closeListbox(), Ne(() => {
      var x;
      return (x = he(l.buttonRef)) == null ? void 0 : x.focus({ preventScroll: !0 });
    }));
  }
  function g() {
    if (e.disabled)
      return l.goToOption(De.Nothing);
    l.goToOption(De.Specific, i);
  }
  let b = pi();
  function C(y) {
    b.update(y);
  }
  function p(y) {
    b.wasMoved(y) && (e.disabled || s.value || l.goToOption(De.Specific, i, 0));
  }
  function w(y) {
    b.wasMoved(y) && (e.disabled || s.value && l.goToOption(De.Nothing));
  }
  return () => {
    let { disabled: y } = e, x = { active: s.value, selected: d.value, disabled: y }, { value: D, disabled: S, ...$ } = e, O = { id: i, ref: o, role: "option", tabIndex: y === !0 ? void 0 : -1, "aria-disabled": y === !0 ? !0 : void 0, "aria-selected": d.value, disabled: void 0, onClick: v, onFocus: g, onPointerenter: C, onMouseenter: C, onPointermove: p, onMousemove: p, onPointerleave: w, onMouseleave: w };
    return rt({ ourProps: O, theirProps: $, slot: x, attrs: t, slots: a, name: "ListboxOption" });
  };
} });
var n1 = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(n1 || {}), r1 = ((e) => (e[e.Pointer = 0] = "Pointer", e[e.Other = 1] = "Other", e))(r1 || {});
function l1(e) {
  requestAnimationFrame(() => requestAnimationFrame(e));
}
let Js = Symbol("MenuContext");
function jr(e) {
  let a = It(Js, null);
  if (a === null) {
    let t = new Error(`<${e} /> is missing a parent <Menu /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(t, jr), t;
  }
  return a;
}
let i1 = be({ name: "Menu", props: { as: { type: [Object, String], default: "template" } }, setup(e, { slots: a, attrs: t }) {
  let n = z(1), r = z(null), i = z(null), l = z([]), o = z(""), s = z(null), d = z(1);
  function u(f = (v) => v) {
    let v = s.value !== null ? l.value[s.value] : null, g = Fr(f(l.value.slice()), (C) => he(C.dataRef.domRef)), b = v ? g.indexOf(v) : null;
    return b === -1 && (b = null), { items: g, activeItemIndex: b };
  }
  let c = { menuState: n, buttonRef: r, itemsRef: i, items: l, searchQuery: o, activeItemIndex: s, activationTrigger: d, closeMenu: () => {
    n.value = 1, s.value = null;
  }, openMenu: () => n.value = 0, goToItem(f, v, g) {
    let b = u(), C = _r(f === De.Specific ? { focus: De.Specific, id: v } : { focus: f }, { resolveItems: () => b.items, resolveActiveIndex: () => b.activeItemIndex, resolveId: (p) => p.id, resolveDisabled: (p) => p.dataRef.disabled });
    o.value = "", s.value = C, d.value = g ?? 1, l.value = b.items;
  }, search(f) {
    let v = o.value !== "" ? 0 : 1;
    o.value += f.toLowerCase();
    let g = (s.value !== null ? l.value.slice(s.value + v).concat(l.value.slice(0, s.value + v)) : l.value).find((C) => C.dataRef.textValue.startsWith(o.value) && !C.dataRef.disabled), b = g ? l.value.indexOf(g) : -1;
    b === -1 || b === s.value || (s.value = b, d.value = 1);
  }, clearSearch() {
    o.value = "";
  }, registerItem(f, v) {
    let g = u((b) => [...b, { id: f, dataRef: v }]);
    l.value = g.items, s.value = g.activeItemIndex, d.value = 1;
  }, unregisterItem(f) {
    let v = u((g) => {
      let b = g.findIndex((C) => C.id === f);
      return b !== -1 && g.splice(b, 1), g;
    });
    l.value = v.items, s.value = v.activeItemIndex, d.value = 1;
  } };
  return mi([r, i], (f, v) => {
    var g;
    c.closeMenu(), zr(v, Hr.Loose) || (f.preventDefault(), (g = he(r)) == null || g.focus());
  }, A(() => n.value === 0)), Nt(Js, c), Br(A(() => ut(n.value, { 0: st.Open, 1: st.Closed }))), () => {
    let f = { open: n.value === 0, close: c.closeMenu };
    return rt({ ourProps: {}, theirProps: e, slot: f, slots: a, attrs: t, name: "Menu" });
  };
} }), o1 = be({ name: "MenuButton", props: { disabled: { type: Boolean, default: !1 }, as: { type: [Object, String], default: "button" }, id: { type: String, default: null } }, setup(e, { attrs: a, slots: t, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-menu-button-${_t()}`, l = jr("MenuButton");
  n({ el: l.buttonRef, $el: l.buttonRef });
  function o(c) {
    switch (c.key) {
      case Ce.Space:
      case Ce.Enter:
      case Ce.ArrowDown:
        c.preventDefault(), c.stopPropagation(), l.openMenu(), Ne(() => {
          var f;
          (f = he(l.itemsRef)) == null || f.focus({ preventScroll: !0 }), l.goToItem(De.First);
        });
        break;
      case Ce.ArrowUp:
        c.preventDefault(), c.stopPropagation(), l.openMenu(), Ne(() => {
          var f;
          (f = he(l.itemsRef)) == null || f.focus({ preventScroll: !0 }), l.goToItem(De.Last);
        });
        break;
    }
  }
  function s(c) {
    switch (c.key) {
      case Ce.Space:
        c.preventDefault();
        break;
    }
  }
  function d(c) {
    e.disabled || (l.menuState.value === 0 ? (l.closeMenu(), Ne(() => {
      var f;
      return (f = he(l.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })) : (c.preventDefault(), l.openMenu(), l1(() => {
      var f;
      return (f = he(l.itemsRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })));
  }
  let u = Vr(A(() => ({ as: e.as, type: a.type })), l.buttonRef);
  return () => {
    var c;
    let f = { open: l.menuState.value === 0 }, { ...v } = e, g = { ref: l.buttonRef, id: i, type: u.value, "aria-haspopup": "menu", "aria-controls": (c = he(l.itemsRef)) == null ? void 0 : c.id, "aria-expanded": l.menuState.value === 0, onKeydown: o, onKeyup: s, onClick: d };
    return rt({ ourProps: g, theirProps: v, slot: f, attrs: a, slots: t, name: "MenuButton" });
  };
} }), s1 = be({ name: "MenuItems", props: { as: { type: [Object, String], default: "div" }, static: { type: Boolean, default: !1 }, unmount: { type: Boolean, default: !0 }, id: { type: String, default: null } }, setup(e, { attrs: a, slots: t, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-menu-items-${_t()}`, l = jr("MenuItems"), o = z(null);
  n({ el: l.itemsRef, $el: l.itemsRef }), Vs({ container: A(() => he(l.itemsRef)), enabled: A(() => l.menuState.value === 0), accept(f) {
    return f.getAttribute("role") === "menuitem" ? NodeFilter.FILTER_REJECT : f.hasAttribute("role") ? NodeFilter.FILTER_SKIP : NodeFilter.FILTER_ACCEPT;
  }, walk(f) {
    f.setAttribute("role", "none");
  } });
  function s(f) {
    var v;
    switch (o.value && clearTimeout(o.value), f.key) {
      case Ce.Space:
        if (l.searchQuery.value !== "")
          return f.preventDefault(), f.stopPropagation(), l.search(f.key);
      case Ce.Enter:
        if (f.preventDefault(), f.stopPropagation(), l.activeItemIndex.value !== null) {
          let g = l.items.value[l.activeItemIndex.value];
          (v = he(g.dataRef.domRef)) == null || v.click();
        }
        l.closeMenu(), zs(he(l.buttonRef));
        break;
      case Ce.ArrowDown:
        return f.preventDefault(), f.stopPropagation(), l.goToItem(De.Next);
      case Ce.ArrowUp:
        return f.preventDefault(), f.stopPropagation(), l.goToItem(De.Previous);
      case Ce.Home:
      case Ce.PageUp:
        return f.preventDefault(), f.stopPropagation(), l.goToItem(De.First);
      case Ce.End:
      case Ce.PageDown:
        return f.preventDefault(), f.stopPropagation(), l.goToItem(De.Last);
      case Ce.Escape:
        f.preventDefault(), f.stopPropagation(), l.closeMenu(), Ne(() => {
          var g;
          return (g = he(l.buttonRef)) == null ? void 0 : g.focus({ preventScroll: !0 });
        });
        break;
      case Ce.Tab:
        f.preventDefault(), f.stopPropagation(), l.closeMenu(), Ne(() => M0(he(l.buttonRef), f.shiftKey ? Vl.Previous : Vl.Next));
        break;
      default:
        f.key.length === 1 && (l.search(f.key), o.value = setTimeout(() => l.clearSearch(), 350));
        break;
    }
  }
  function d(f) {
    switch (f.key) {
      case Ce.Space:
        f.preventDefault();
        break;
    }
  }
  let u = Un(), c = A(() => u !== null ? (u.value & st.Open) === st.Open : l.menuState.value === 0);
  return () => {
    var f, v;
    let g = { open: l.menuState.value === 0 }, { ...b } = e, C = { "aria-activedescendant": l.activeItemIndex.value === null || (f = l.items.value[l.activeItemIndex.value]) == null ? void 0 : f.id, "aria-labelledby": (v = he(l.buttonRef)) == null ? void 0 : v.id, id: i, onKeydown: s, onKeyup: d, role: "menu", tabIndex: 0, ref: l.itemsRef };
    return rt({ ourProps: C, theirProps: b, slot: g, attrs: a, slots: t, features: la.RenderStrategy | la.Static, visible: c.value, name: "MenuItems" });
  };
} }), u1 = be({ name: "MenuItem", inheritAttrs: !1, props: { as: { type: [Object, String], default: "template" }, disabled: { type: Boolean, default: !1 }, id: { type: String, default: null } }, setup(e, { slots: a, attrs: t, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-menu-item-${_t()}`, l = jr("MenuItem"), o = z(null);
  n({ el: o, $el: o });
  let s = A(() => l.activeItemIndex.value !== null ? l.items.value[l.activeItemIndex.value].id === i : !1), d = Qs(o), u = A(() => ({ disabled: e.disabled, get textValue() {
    return d();
  }, domRef: o }));
  He(() => l.registerItem(i, u)), Ht(() => l.unregisterItem(i)), Dt(() => {
    l.menuState.value === 0 && s.value && l.activationTrigger.value !== 0 && Ne(() => {
      var p, w;
      return (w = (p = he(o)) == null ? void 0 : p.scrollIntoView) == null ? void 0 : w.call(p, { block: "nearest" });
    });
  });
  function c(p) {
    if (e.disabled)
      return p.preventDefault();
    l.closeMenu(), zs(he(l.buttonRef));
  }
  function f() {
    if (e.disabled)
      return l.goToItem(De.Nothing);
    l.goToItem(De.Specific, i);
  }
  let v = pi();
  function g(p) {
    v.update(p);
  }
  function b(p) {
    v.wasMoved(p) && (e.disabled || s.value || l.goToItem(De.Specific, i, 0));
  }
  function C(p) {
    v.wasMoved(p) && (e.disabled || s.value && l.goToItem(De.Nothing));
  }
  return () => {
    let { disabled: p } = e, w = { active: s.value, disabled: p, close: l.closeMenu }, { ...y } = e;
    return rt({ ourProps: { id: i, ref: o, role: "menuitem", tabIndex: p === !0 ? void 0 : -1, "aria-disabled": p === !0 ? !0 : void 0, disabled: void 0, onClick: c, onFocus: f, onPointerenter: g, onMouseenter: g, onPointermove: b, onMousemove: b, onPointerleave: C, onMouseleave: C }, theirProps: { ...t, ...y }, slot: w, attrs: t, slots: a, name: "MenuItem" });
  };
} }), Zs = Symbol("LabelContext");
function eu() {
  let e = It(Zs, null);
  if (e === null) {
    let a = new Error("You used a <Label /> component, but it is not inside a parent.");
    throw Error.captureStackTrace && Error.captureStackTrace(a, eu), a;
  }
  return e;
}
function c1({ slot: e = {}, name: a = "Label", props: t = {} } = {}) {
  let n = z([]);
  function r(i) {
    return n.value.push(i), () => {
      let l = n.value.indexOf(i);
      l !== -1 && n.value.splice(l, 1);
    };
  }
  return Nt(Zs, { register: r, slot: e, name: a, props: t }), A(() => n.value.length > 0 ? n.value.join(" ") : void 0);
}
let d1 = be({ name: "Label", props: { as: { type: [Object, String], default: "label" }, passive: { type: [Boolean], default: !1 }, id: { type: String, default: null } }, setup(e, { slots: a, attrs: t }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-label-${_t()}`, i = eu();
  return He(() => Ht(i.register(r))), () => {
    let { name: l = "Label", slot: o = {}, props: s = {} } = i, { passive: d, ...u } = e, c = { ...Object.entries(s).reduce((f, [v, g]) => Object.assign(f, { [v]: m(g) }), {}), id: r };
    return d && (delete c.onClick, delete c.htmlFor, delete u.onClick), rt({ ourProps: c, theirProps: u, slot: o, attrs: t, slots: a, name: l });
  };
} }), tu = Symbol("GroupContext"), f1 = be({ name: "SwitchGroup", props: { as: { type: [Object, String], default: "template" } }, setup(e, { slots: a, attrs: t }) {
  let n = z(null), r = c1({ name: "SwitchLabel", props: { htmlFor: A(() => {
    var l;
    return (l = n.value) == null ? void 0 : l.id;
  }), onClick(l) {
    n.value && (l.currentTarget.tagName === "LABEL" && l.preventDefault(), n.value.click(), n.value.focus({ preventScroll: !0 }));
  } } }), i = W0({ name: "SwitchDescription" });
  return Nt(tu, { switchRef: n, labelledby: r, describedby: i }), () => rt({ theirProps: e, ourProps: {}, slot: {}, slots: a, attrs: t, name: "SwitchGroup" });
} }), v1 = be({ name: "Switch", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "button" }, modelValue: { type: Boolean, default: void 0 }, defaultChecked: { type: Boolean, optional: !0 }, form: { type: String, optional: !0 }, name: { type: String, optional: !0 }, value: { type: String, optional: !0 }, id: { type: String, default: null } }, inheritAttrs: !1, setup(e, { emit: a, attrs: t, slots: n, expose: r }) {
  var i;
  let l = (i = e.id) != null ? i : `headlessui-switch-${_t()}`, o = It(tu, null), [s, d] = vi(A(() => e.modelValue), (w) => a("update:modelValue", w), A(() => e.defaultChecked));
  function u() {
    d(!s.value);
  }
  let c = z(null), f = o === null ? c : o.switchRef, v = Vr(A(() => ({ as: e.as, type: t.type })), f);
  r({ el: f, $el: f });
  function g(w) {
    w.preventDefault(), u();
  }
  function b(w) {
    w.key === Ce.Space ? (w.preventDefault(), u()) : w.key === Ce.Enter && $0(w.currentTarget);
  }
  function C(w) {
    w.preventDefault();
  }
  let p = A(() => {
    var w, y;
    return (y = (w = he(f)) == null ? void 0 : w.closest) == null ? void 0 : y.call(w, "form");
  });
  return He(() => {
    Ge([p], () => {
      if (!p.value || e.defaultChecked === void 0)
        return;
      function w() {
        d(e.defaultChecked);
      }
      return p.value.addEventListener("reset", w), () => {
        var y;
        (y = p.value) == null || y.removeEventListener("reset", w);
      };
    }, { immediate: !0 });
  }), () => {
    let { name: w, value: y, form: x, ...D } = e, S = { checked: s.value }, $ = { id: l, ref: f, role: "switch", type: v.value, tabIndex: 0, "aria-checked": s.value, "aria-labelledby": o == null ? void 0 : o.labelledby.value, "aria-describedby": o == null ? void 0 : o.describedby.value, onClick: g, onKeyup: b, onKeypress: C };
    return At(Se, [w != null && s.value != null ? At(yi, hi({ features: Yr.Hidden, as: "input", type: "checkbox", hidden: !0, readOnly: !0, checked: s.value, form: x, name: w, value: y })) : null, rt({ ourProps: $, theirProps: { ...t, ...Xa(D, ["modelValue", "defaultChecked"]) }, slot: S, attrs: t, slots: n, name: "Switch" })]);
  };
} }), m1 = d1;
function p1(e) {
  let a = { called: !1 };
  return (...t) => {
    if (!a.called)
      return a.called = !0, e(...t);
  };
}
function vl(e, ...a) {
  e && a.length > 0 && e.classList.add(...a);
}
function ur(e, ...a) {
  e && a.length > 0 && e.classList.remove(...a);
}
var Yl = ((e) => (e.Finished = "finished", e.Cancelled = "cancelled", e))(Yl || {});
function h1(e, a) {
  let t = Nn();
  if (!e)
    return t.dispose;
  let { transitionDuration: n, transitionDelay: r } = getComputedStyle(e), [i, l] = [n, r].map((o) => {
    let [s = 0] = o.split(",").filter(Boolean).map((d) => d.includes("ms") ? parseFloat(d) : parseFloat(d) * 1e3).sort((d, u) => u - d);
    return s;
  });
  return i !== 0 ? t.setTimeout(() => a("finished"), i + l) : a("finished"), t.add(() => a("cancelled")), t.dispose;
}
function Oo(e, a, t, n, r, i) {
  let l = Nn(), o = i !== void 0 ? p1(i) : () => {
  };
  return ur(e, ...r), vl(e, ...a, ...t), l.nextFrame(() => {
    ur(e, ...t), vl(e, ...n), l.add(h1(e, (s) => (ur(e, ...n, ...a), vl(e, ...r), o(s))));
  }), l.add(() => ur(e, ...a, ...t, ...n, ...r)), l.add(() => o("cancelled")), l.dispose;
}
function Ra(e = "") {
  return e.split(/\s+/).filter((a) => a.length > 1);
}
let bi = Symbol("TransitionContext");
var y1 = ((e) => (e.Visible = "visible", e.Hidden = "hidden", e))(y1 || {});
function g1() {
  return It(bi, null) !== null;
}
function b1() {
  let e = It(bi, null);
  if (e === null)
    throw new Error("A <TransitionChild /> is used but it is missing a parent <TransitionRoot />.");
  return e;
}
function w1() {
  let e = It(wi, null);
  if (e === null)
    throw new Error("A <TransitionChild /> is used but it is missing a parent <TransitionRoot />.");
  return e;
}
let wi = Symbol("NestingContext");
function Ur(e) {
  return "children" in e ? Ur(e.children) : e.value.filter(({ state: a }) => a === "visible").length > 0;
}
function au(e) {
  let a = z([]), t = z(!1);
  He(() => t.value = !0), Ht(() => t.value = !1);
  function n(i, l = wa.Hidden) {
    let o = a.value.findIndex(({ id: s }) => s === i);
    o !== -1 && (ut(l, { [wa.Unmount]() {
      a.value.splice(o, 1);
    }, [wa.Hidden]() {
      a.value[o].state = "hidden";
    } }), !Ur(a) && t.value && (e == null || e()));
  }
  function r(i) {
    let l = a.value.find(({ id: o }) => o === i);
    return l ? l.state !== "visible" && (l.state = "visible") : a.value.push({ id: i, state: "visible" }), () => n(i, wa.Unmount);
  }
  return { children: a, register: r, unregister: n };
}
let nu = la.RenderStrategy, x1 = be({ props: { as: { type: [Object, String], default: "div" }, show: { type: [Boolean], default: null }, unmount: { type: [Boolean], default: !0 }, appear: { type: [Boolean], default: !1 }, enter: { type: [String], default: "" }, enterFrom: { type: [String], default: "" }, enterTo: { type: [String], default: "" }, entered: { type: [String], default: "" }, leave: { type: [String], default: "" }, leaveFrom: { type: [String], default: "" }, leaveTo: { type: [String], default: "" } }, emits: { beforeEnter: () => !0, afterEnter: () => !0, beforeLeave: () => !0, afterLeave: () => !0 }, setup(e, { emit: a, attrs: t, slots: n, expose: r }) {
  let i = z(0);
  function l() {
    i.value |= st.Opening, a("beforeEnter");
  }
  function o() {
    i.value &= ~st.Opening, a("afterEnter");
  }
  function s() {
    i.value |= st.Closing, a("beforeLeave");
  }
  function d() {
    i.value &= ~st.Closing, a("afterLeave");
  }
  if (!g1() && A0())
    return () => At(ru, { ...e, onBeforeEnter: l, onAfterEnter: o, onBeforeLeave: s, onAfterLeave: d }, n);
  let u = z(null), c = A(() => e.unmount ? wa.Unmount : wa.Hidden);
  r({ el: u, $el: u });
  let { show: f, appear: v } = b1(), { register: g, unregister: b } = w1(), C = z(f.value ? "visible" : "hidden"), p = { value: !0 }, w = _t(), y = { value: !1 }, x = au(() => {
    !y.value && C.value !== "hidden" && (C.value = "hidden", b(w), d());
  });
  He(() => {
    let te = g(w);
    Ht(te);
  }), Dt(() => {
    if (c.value === wa.Hidden && w) {
      if (f.value && C.value !== "visible") {
        C.value = "visible";
        return;
      }
      ut(C.value, { hidden: () => b(w), visible: () => g(w) });
    }
  });
  let D = Ra(e.enter), S = Ra(e.enterFrom), $ = Ra(e.enterTo), O = Ra(e.entered), N = Ra(e.leave), M = Ra(e.leaveFrom), B = Ra(e.leaveTo);
  He(() => {
    Dt(() => {
      if (C.value === "visible") {
        let te = he(u);
        if (te instanceof Comment && te.data === "")
          throw new Error("Did you forget to passthrough the `ref` to the actual DOM node?");
      }
    });
  });
  function X(te) {
    let T = p.value && !v.value, E = he(u);
    !E || !(E instanceof HTMLElement) || T || (y.value = !0, f.value && l(), f.value || s(), te(f.value ? Oo(E, D, S, $, O, (I) => {
      y.value = !1, I === Yl.Finished && o();
    }) : Oo(E, N, M, B, O, (I) => {
      y.value = !1, I === Yl.Finished && (Ur(x) || (C.value = "hidden", b(w), d()));
    })));
  }
  return He(() => {
    Ge([f], (te, T, E) => {
      X(E), p.value = !1;
    }, { immediate: !0 });
  }), Nt(wi, x), Br(A(() => ut(C.value, { visible: st.Open, hidden: st.Closed }) | i.value)), () => {
    let { appear: te, show: T, enter: E, enterFrom: I, enterTo: q, entered: ne, leave: U, leaveFrom: L, leaveTo: ue, ...K } = e, V = { ref: u }, h = { ...K, ...v.value && f.value && Nr.isServer ? { class: ye([t.class, K.class, ...D, ...S]) } : {} };
    return rt({ theirProps: h, ourProps: V, slot: {}, slots: n, attrs: t, features: nu, visible: C.value === "visible", name: "TransitionChild" });
  };
} }), k1 = x1, ru = be({ inheritAttrs: !1, props: { as: { type: [Object, String], default: "div" }, show: { type: [Boolean], default: null }, unmount: { type: [Boolean], default: !0 }, appear: { type: [Boolean], default: !1 }, enter: { type: [String], default: "" }, enterFrom: { type: [String], default: "" }, enterTo: { type: [String], default: "" }, entered: { type: [String], default: "" }, leave: { type: [String], default: "" }, leaveFrom: { type: [String], default: "" }, leaveTo: { type: [String], default: "" } }, emits: { beforeEnter: () => !0, afterEnter: () => !0, beforeLeave: () => !0, afterLeave: () => !0 }, setup(e, { emit: a, attrs: t, slots: n }) {
  let r = Un(), i = A(() => e.show === null && r !== null ? (r.value & st.Open) === st.Open : e.show);
  Dt(() => {
    if (![!0, !1].includes(i.value))
      throw new Error('A <Transition /> is used but it is missing a `:show="true | false"` prop.');
  });
  let l = z(i.value ? "visible" : "hidden"), o = au(() => {
    l.value = "hidden";
  }), s = z(!0), d = { show: i, appear: A(() => e.appear || !s.value) };
  return He(() => {
    Dt(() => {
      s.value = !1, i.value ? l.value = "visible" : Ur(o) || (l.value = "hidden");
    });
  }), Nt(wi, o), Nt(bi, d), () => {
    let u = Xa(e, ["show", "appear", "unmount", "onBeforeEnter", "onBeforeLeave", "onAfterEnter", "onAfterLeave"]), c = { unmount: e.unmount };
    return rt({ ourProps: { ...c, as: "template" }, theirProps: {}, slot: {}, slots: { ...n, default: () => [At(k1, { onBeforeEnter: () => a("beforeEnter"), onAfterEnter: () => a("afterEnter"), onBeforeLeave: () => a("beforeLeave"), onAfterLeave: () => a("afterLeave"), ...t, ...c, ...u }, n.default)] }, attrs: {}, features: nu, visible: l.value === "visible", name: "Transition" });
  };
} });
const _1 = /* @__PURE__ */ ie("div", {
  "aria-hidden": "true",
  class: "relative w-8 px-1 pointer-events-none h-5 rounded-full border-2 border-solid border-ui-gray-800 bg-white transition-colors duration-200 ease-in-out group-focus/toggle:shadow-glow ui-checked:bg-pivot-teal-600 ui-checked:border-pivot-teal-600 group-disabled/toggle:border-ui-gray-300 group-disabled/toggle:bg-ui-gray-300"
}, [
  /* @__PURE__ */ ie("div", {
    "aria-hidden": "true",
    class: "absolute top-[3px] pointer-events-none h-[10px] w-[10px] rounded-full shadow bg-ui-gray-800 transition duration-200 ease-in-out group-disabled/toggle:bg-white left-1 ui-checked:translate-x-full ui-checked:bg-white"
  })
], -1), ey = /* @__PURE__ */ be({
  __name: "UISwitch",
  props: {
    name: {},
    disabled: { type: Boolean, default: !1 },
    modelValue: { type: Boolean },
    controlled: { type: Boolean, default: !0 },
    class: {},
    style: {}
  },
  setup(e) {
    const a = e, { value: t } = Ia(Qe(a, "name"), void 0, {
      syncVModel: !a.controlled,
      controlled: a.controlled
    });
    return (n, r) => (P(), Me(m(f1), {
      as: "div",
      class: ye([a.class, "tw-reset flex items-center"]),
      style: Ze(n.style)
    }, {
      default: me(() => [
        $e(m(v1), Ue(n.$attrs, {
          modelValue: m(t),
          "onUpdate:modelValue": r[0] || (r[0] = (i) => ra(t) ? t.value = i : null),
          disabled: n.disabled,
          class: "group/toggle p-0 py-1 border-none flex-shrink-0 rounded-full focus:outline-none"
        }), {
          default: me(() => [
            _1
          ]),
          _: 1
        }, 16, ["modelValue", "disabled"]),
        $e(m(m1), { class: "ml-2 cursor-pointer font-bold" }, {
          default: me(() => [
            se(n.$slots, "default")
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["class", "style"]));
  }
}), ty = /* @__PURE__ */ be({
  __name: "UINumberInput",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {}
  },
  setup(e) {
    const a = e, { value: t, errorMessage: n, handleBlur: r } = Ia(
      Qe(a, "name"),
      Qe(a, "rules"),
      {
        label: Qe(a, "label"),
        syncVModel: !0
      }
    );
    return (i, l) => ka((P(), Y("input", {
      ref: "input",
      "onUpdate:modelValue": l[0] || (l[0] = (o) => ra(t) ? t.value = o : null),
      type: "number",
      onWheel: l[1] || (l[1] = Ba(() => {
      }, ["prevent"])),
      onBlur: l[2] || (l[2] = //@ts-ignore
      (...o) => m(r) && m(r)(...o)),
      class: ye([
        "hide-stepper tw-reset px-2 h-8 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
        {
          "border-warning-orange-600 text-warning-orange-600 italic": m(n)
        }
      ])
    }, null, 34)), [
      [es, m(t)]
    ]);
  }
}), M1 = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, O1 = {
  start: "end",
  end: "start"
};
function lu(e, a) {
  return typeof e == "function" ? e(a) : e;
}
function Ln(e) {
  return e.split("-")[0];
}
function xi(e) {
  return e.split("-")[1];
}
function S1(e) {
  return e === "x" ? "y" : "x";
}
function iu(e) {
  return e === "y" ? "height" : "width";
}
function ou(e) {
  return ["top", "bottom"].includes(Ln(e)) ? "y" : "x";
}
function su(e) {
  return S1(ou(e));
}
function T1(e, a, t) {
  t === void 0 && (t = !1);
  const n = xi(e), r = su(e), i = iu(r);
  let l = r === "x" ? n === (t ? "end" : "start") ? "right" : "left" : n === "start" ? "bottom" : "top";
  return a.reference[i] > a.floating[i] && (l = Mr(l)), [l, Mr(l)];
}
function P1(e) {
  const a = Mr(e);
  return [Bl(e), a, Bl(a)];
}
function Bl(e) {
  return e.replace(/start|end/g, (a) => O1[a]);
}
function C1(e, a, t) {
  const n = ["left", "right"], r = ["right", "left"], i = ["top", "bottom"], l = ["bottom", "top"];
  switch (e) {
    case "top":
    case "bottom":
      return t ? a ? r : n : a ? n : r;
    case "left":
    case "right":
      return a ? i : l;
    default:
      return [];
  }
}
function A1(e, a, t, n) {
  const r = xi(e);
  let i = C1(Ln(e), t === "start", n);
  return r && (i = i.map((l) => l + "-" + r), a && (i = i.concat(i.map(Bl)))), i;
}
function Mr(e) {
  return e.replace(/left|right|bottom|top/g, (a) => M1[a]);
}
function D1(e) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...e
  };
}
function I1(e) {
  return typeof e != "number" ? D1(e) : {
    top: e,
    right: e,
    bottom: e,
    left: e
  };
}
function Or(e) {
  return {
    ...e,
    top: e.y,
    left: e.x,
    right: e.x + e.width,
    bottom: e.y + e.height
  };
}
function So(e, a, t) {
  let {
    reference: n,
    floating: r
  } = e;
  const i = ou(a), l = su(a), o = iu(l), s = Ln(a), d = i === "y", u = n.x + n.width / 2 - r.width / 2, c = n.y + n.height / 2 - r.height / 2, f = n[o] / 2 - r[o] / 2;
  let v;
  switch (s) {
    case "top":
      v = {
        x: u,
        y: n.y - r.height
      };
      break;
    case "bottom":
      v = {
        x: u,
        y: n.y + n.height
      };
      break;
    case "right":
      v = {
        x: n.x + n.width,
        y: c
      };
      break;
    case "left":
      v = {
        x: n.x - r.width,
        y: c
      };
      break;
    default:
      v = {
        x: n.x,
        y: n.y
      };
  }
  switch (xi(a)) {
    case "start":
      v[l] -= f * (t && d ? -1 : 1);
      break;
    case "end":
      v[l] += f * (t && d ? -1 : 1);
      break;
  }
  return v;
}
const E1 = async (e, a, t) => {
  const {
    placement: n = "bottom",
    strategy: r = "absolute",
    middleware: i = [],
    platform: l
  } = t, o = i.filter(Boolean), s = await (l.isRTL == null ? void 0 : l.isRTL(a));
  let d = await l.getElementRects({
    reference: e,
    floating: a,
    strategy: r
  }), {
    x: u,
    y: c
  } = So(d, n, s), f = n, v = {}, g = 0;
  for (let b = 0; b < o.length; b++) {
    const {
      name: C,
      fn: p
    } = o[b], {
      x: w,
      y,
      data: x,
      reset: D
    } = await p({
      x: u,
      y: c,
      initialPlacement: n,
      placement: f,
      strategy: r,
      middlewareData: v,
      rects: d,
      platform: l,
      elements: {
        reference: e,
        floating: a
      }
    });
    if (u = w ?? u, c = y ?? c, v = {
      ...v,
      [C]: {
        ...v[C],
        ...x
      }
    }, D && g <= 50) {
      g++, typeof D == "object" && (D.placement && (f = D.placement), D.rects && (d = D.rects === !0 ? await l.getElementRects({
        reference: e,
        floating: a,
        strategy: r
      }) : D.rects), {
        x: u,
        y: c
      } = So(d, f, s)), b = -1;
      continue;
    }
  }
  return {
    x: u,
    y: c,
    placement: f,
    strategy: r,
    middlewareData: v
  };
};
async function $1(e, a) {
  var t;
  a === void 0 && (a = {});
  const {
    x: n,
    y: r,
    platform: i,
    rects: l,
    elements: o,
    strategy: s
  } = e, {
    boundary: d = "clippingAncestors",
    rootBoundary: u = "viewport",
    elementContext: c = "floating",
    altBoundary: f = !1,
    padding: v = 0
  } = lu(a, e), g = I1(v), C = o[f ? c === "floating" ? "reference" : "floating" : c], p = Or(await i.getClippingRect({
    element: (t = await (i.isElement == null ? void 0 : i.isElement(C))) == null || t ? C : C.contextElement || await (i.getDocumentElement == null ? void 0 : i.getDocumentElement(o.floating)),
    boundary: d,
    rootBoundary: u,
    strategy: s
  })), w = c === "floating" ? {
    ...l.floating,
    x: n,
    y: r
  } : l.reference, y = await (i.getOffsetParent == null ? void 0 : i.getOffsetParent(o.floating)), x = await (i.isElement == null ? void 0 : i.isElement(y)) ? await (i.getScale == null ? void 0 : i.getScale(y)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, D = Or(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
    rect: w,
    offsetParent: y,
    strategy: s
  }) : w);
  return {
    top: (p.top - D.top + g.top) / x.y,
    bottom: (D.bottom - p.bottom + g.bottom) / x.y,
    left: (p.left - D.left + g.left) / x.x,
    right: (D.right - p.right + g.right) / x.x
  };
}
const R1 = function(e) {
  return e === void 0 && (e = {}), {
    name: "flip",
    options: e,
    async fn(a) {
      var t, n;
      const {
        placement: r,
        middlewareData: i,
        rects: l,
        initialPlacement: o,
        platform: s,
        elements: d
      } = a, {
        mainAxis: u = !0,
        crossAxis: c = !0,
        fallbackPlacements: f,
        fallbackStrategy: v = "bestFit",
        fallbackAxisSideDirection: g = "none",
        flipAlignment: b = !0,
        ...C
      } = lu(e, a);
      if ((t = i.arrow) != null && t.alignmentOffset)
        return {};
      const p = Ln(r), w = Ln(o) === o, y = await (s.isRTL == null ? void 0 : s.isRTL(d.floating)), x = f || (w || !b ? [Mr(o)] : P1(o));
      !f && g !== "none" && x.push(...A1(o, b, g, y));
      const D = [o, ...x], S = await $1(a, C), $ = [];
      let O = ((n = i.flip) == null ? void 0 : n.overflows) || [];
      if (u && $.push(S[p]), c) {
        const X = T1(r, l, y);
        $.push(S[X[0]], S[X[1]]);
      }
      if (O = [...O, {
        placement: r,
        overflows: $
      }], !$.every((X) => X <= 0)) {
        var N, M;
        const X = (((N = i.flip) == null ? void 0 : N.index) || 0) + 1, te = D[X];
        if (te)
          return {
            data: {
              index: X,
              overflows: O
            },
            reset: {
              placement: te
            }
          };
        let T = (M = O.filter((E) => E.overflows[0] <= 0).sort((E, I) => E.overflows[1] - I.overflows[1])[0]) == null ? void 0 : M.placement;
        if (!T)
          switch (v) {
            case "bestFit": {
              var B;
              const E = (B = O.map((I) => [I.placement, I.overflows.filter((q) => q > 0).reduce((q, ne) => q + ne, 0)]).sort((I, q) => I[1] - q[1])[0]) == null ? void 0 : B[0];
              E && (T = E);
              break;
            }
            case "initialPlacement":
              T = o;
              break;
          }
        if (r !== T)
          return {
            reset: {
              placement: T
            }
          };
      }
      return {};
    }
  };
}, jl = Math.min, un = Math.max, Sr = Math.round, cr = Math.floor, Pa = (e) => ({
  x: e,
  y: e
});
function Ca(e) {
  return uu(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function $t(e) {
  var a;
  return (e == null || (a = e.ownerDocument) == null ? void 0 : a.defaultView) || window;
}
function pa(e) {
  var a;
  return (a = (uu(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : a.documentElement;
}
function uu(e) {
  return e instanceof Node || e instanceof $t(e).Node;
}
function fa(e) {
  return e instanceof Element || e instanceof $t(e).Element;
}
function ia(e) {
  return e instanceof HTMLElement || e instanceof $t(e).HTMLElement;
}
function To(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof $t(e).ShadowRoot;
}
function qn(e) {
  const {
    overflow: a,
    overflowX: t,
    overflowY: n,
    display: r
  } = Lt(e);
  return /auto|scroll|overlay|hidden|clip/.test(a + n + t) && !["inline", "contents"].includes(r);
}
function N1(e) {
  return ["table", "td", "th"].includes(Ca(e));
}
function ki(e) {
  const a = _i(), t = Lt(e);
  return t.transform !== "none" || t.perspective !== "none" || (t.containerType ? t.containerType !== "normal" : !1) || !a && (t.backdropFilter ? t.backdropFilter !== "none" : !1) || !a && (t.filter ? t.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((n) => (t.willChange || "").includes(n)) || ["paint", "layout", "strict", "content"].some((n) => (t.contain || "").includes(n));
}
function L1(e) {
  let a = fn(e);
  for (; ia(a) && !Wr(a); ) {
    if (ki(a))
      return a;
    a = fn(a);
  }
  return null;
}
function _i() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function Wr(e) {
  return ["html", "body", "#document"].includes(Ca(e));
}
function Lt(e) {
  return $t(e).getComputedStyle(e);
}
function qr(e) {
  return fa(e) ? {
    scrollLeft: e.scrollLeft,
    scrollTop: e.scrollTop
  } : {
    scrollLeft: e.pageXOffset,
    scrollTop: e.pageYOffset
  };
}
function fn(e) {
  if (Ca(e) === "html")
    return e;
  const a = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    To(e) && e.host || // Fallback.
    pa(e)
  );
  return To(a) ? a.host : a;
}
function cu(e) {
  const a = fn(e);
  return Wr(a) ? e.ownerDocument ? e.ownerDocument.body : e.body : ia(a) && qn(a) ? a : cu(a);
}
function Hn(e, a, t) {
  var n;
  a === void 0 && (a = []), t === void 0 && (t = !0);
  const r = cu(e), i = r === ((n = e.ownerDocument) == null ? void 0 : n.body), l = $t(r);
  return i ? a.concat(l, l.visualViewport || [], qn(r) ? r : [], l.frameElement && t ? Hn(l.frameElement) : []) : a.concat(r, Hn(r, [], t));
}
function du(e) {
  const a = Lt(e);
  let t = parseFloat(a.width) || 0, n = parseFloat(a.height) || 0;
  const r = ia(e), i = r ? e.offsetWidth : t, l = r ? e.offsetHeight : n, o = Sr(t) !== i || Sr(n) !== l;
  return o && (t = i, n = l), {
    width: t,
    height: n,
    $: o
  };
}
function Mi(e) {
  return fa(e) ? e : e.contextElement;
}
function cn(e) {
  const a = Mi(e);
  if (!ia(a))
    return Pa(1);
  const t = a.getBoundingClientRect(), {
    width: n,
    height: r,
    $: i
  } = du(a);
  let l = (i ? Sr(t.width) : t.width) / n, o = (i ? Sr(t.height) : t.height) / r;
  return (!l || !Number.isFinite(l)) && (l = 1), (!o || !Number.isFinite(o)) && (o = 1), {
    x: l,
    y: o
  };
}
const H1 = /* @__PURE__ */ Pa(0);
function fu(e) {
  const a = $t(e);
  return !_i() || !a.visualViewport ? H1 : {
    x: a.visualViewport.offsetLeft,
    y: a.visualViewport.offsetTop
  };
}
function z1(e, a, t) {
  return a === void 0 && (a = !1), !t || a && t !== $t(e) ? !1 : a;
}
function Wa(e, a, t, n) {
  a === void 0 && (a = !1), t === void 0 && (t = !1);
  const r = e.getBoundingClientRect(), i = Mi(e);
  let l = Pa(1);
  a && (n ? fa(n) && (l = cn(n)) : l = cn(e));
  const o = z1(i, t, n) ? fu(i) : Pa(0);
  let s = (r.left + o.x) / l.x, d = (r.top + o.y) / l.y, u = r.width / l.x, c = r.height / l.y;
  if (i) {
    const f = $t(i), v = n && fa(n) ? $t(n) : n;
    let g = f, b = g.frameElement;
    for (; b && n && v !== g; ) {
      const C = cn(b), p = b.getBoundingClientRect(), w = Lt(b), y = p.left + (b.clientLeft + parseFloat(w.paddingLeft)) * C.x, x = p.top + (b.clientTop + parseFloat(w.paddingTop)) * C.y;
      s *= C.x, d *= C.y, u *= C.x, c *= C.y, s += y, d += x, g = $t(b), b = g.frameElement;
    }
  }
  return Or({
    width: u,
    height: c,
    x: s,
    y: d
  });
}
const F1 = [":popover-open", ":modal"];
function vu(e) {
  return F1.some((a) => {
    try {
      return e.matches(a);
    } catch {
      return !1;
    }
  });
}
function V1(e) {
  let {
    elements: a,
    rect: t,
    offsetParent: n,
    strategy: r
  } = e;
  const i = r === "fixed", l = pa(n), o = a ? vu(a.floating) : !1;
  if (n === l || o && i)
    return t;
  let s = {
    scrollLeft: 0,
    scrollTop: 0
  }, d = Pa(1);
  const u = Pa(0), c = ia(n);
  if ((c || !c && !i) && ((Ca(n) !== "body" || qn(l)) && (s = qr(n)), ia(n))) {
    const f = Wa(n);
    d = cn(n), u.x = f.x + n.clientLeft, u.y = f.y + n.clientTop;
  }
  return {
    width: t.width * d.x,
    height: t.height * d.y,
    x: t.x * d.x - s.scrollLeft * d.x + u.x,
    y: t.y * d.y - s.scrollTop * d.y + u.y
  };
}
function Y1(e) {
  return Array.from(e.getClientRects());
}
function mu(e) {
  return Wa(pa(e)).left + qr(e).scrollLeft;
}
function B1(e) {
  const a = pa(e), t = qr(e), n = e.ownerDocument.body, r = un(a.scrollWidth, a.clientWidth, n.scrollWidth, n.clientWidth), i = un(a.scrollHeight, a.clientHeight, n.scrollHeight, n.clientHeight);
  let l = -t.scrollLeft + mu(e);
  const o = -t.scrollTop;
  return Lt(n).direction === "rtl" && (l += un(a.clientWidth, n.clientWidth) - r), {
    width: r,
    height: i,
    x: l,
    y: o
  };
}
function j1(e, a) {
  const t = $t(e), n = pa(e), r = t.visualViewport;
  let i = n.clientWidth, l = n.clientHeight, o = 0, s = 0;
  if (r) {
    i = r.width, l = r.height;
    const d = _i();
    (!d || d && a === "fixed") && (o = r.offsetLeft, s = r.offsetTop);
  }
  return {
    width: i,
    height: l,
    x: o,
    y: s
  };
}
function U1(e, a) {
  const t = Wa(e, !0, a === "fixed"), n = t.top + e.clientTop, r = t.left + e.clientLeft, i = ia(e) ? cn(e) : Pa(1), l = e.clientWidth * i.x, o = e.clientHeight * i.y, s = r * i.x, d = n * i.y;
  return {
    width: l,
    height: o,
    x: s,
    y: d
  };
}
function Po(e, a, t) {
  let n;
  if (a === "viewport")
    n = j1(e, t);
  else if (a === "document")
    n = B1(pa(e));
  else if (fa(a))
    n = U1(a, t);
  else {
    const r = fu(e);
    n = {
      ...a,
      x: a.x - r.x,
      y: a.y - r.y
    };
  }
  return Or(n);
}
function pu(e, a) {
  const t = fn(e);
  return t === a || !fa(t) || Wr(t) ? !1 : Lt(t).position === "fixed" || pu(t, a);
}
function W1(e, a) {
  const t = a.get(e);
  if (t)
    return t;
  let n = Hn(e, [], !1).filter((o) => fa(o) && Ca(o) !== "body"), r = null;
  const i = Lt(e).position === "fixed";
  let l = i ? fn(e) : e;
  for (; fa(l) && !Wr(l); ) {
    const o = Lt(l), s = ki(l);
    !s && o.position === "fixed" && (r = null), (i ? !s && !r : !s && o.position === "static" && !!r && ["absolute", "fixed"].includes(r.position) || qn(l) && !s && pu(e, l)) ? n = n.filter((u) => u !== l) : r = o, l = fn(l);
  }
  return a.set(e, n), n;
}
function q1(e) {
  let {
    element: a,
    boundary: t,
    rootBoundary: n,
    strategy: r
  } = e;
  const l = [...t === "clippingAncestors" ? W1(a, this._c) : [].concat(t), n], o = l[0], s = l.reduce((d, u) => {
    const c = Po(a, u, r);
    return d.top = un(c.top, d.top), d.right = jl(c.right, d.right), d.bottom = jl(c.bottom, d.bottom), d.left = un(c.left, d.left), d;
  }, Po(a, o, r));
  return {
    width: s.right - s.left,
    height: s.bottom - s.top,
    x: s.left,
    y: s.top
  };
}
function K1(e) {
  const {
    width: a,
    height: t
  } = du(e);
  return {
    width: a,
    height: t
  };
}
function X1(e, a, t) {
  const n = ia(a), r = pa(a), i = t === "fixed", l = Wa(e, !0, i, a);
  let o = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const s = Pa(0);
  if (n || !n && !i)
    if ((Ca(a) !== "body" || qn(r)) && (o = qr(a)), n) {
      const c = Wa(a, !0, i, a);
      s.x = c.x + a.clientLeft, s.y = c.y + a.clientTop;
    } else
      r && (s.x = mu(r));
  const d = l.left + o.scrollLeft - s.x, u = l.top + o.scrollTop - s.y;
  return {
    x: d,
    y: u,
    width: l.width,
    height: l.height
  };
}
function Co(e, a) {
  return !ia(e) || Lt(e).position === "fixed" ? null : a ? a(e) : e.offsetParent;
}
function hu(e, a) {
  const t = $t(e);
  if (!ia(e) || vu(e))
    return t;
  let n = Co(e, a);
  for (; n && N1(n) && Lt(n).position === "static"; )
    n = Co(n, a);
  return n && (Ca(n) === "html" || Ca(n) === "body" && Lt(n).position === "static" && !ki(n)) ? t : n || L1(e) || t;
}
const Q1 = async function(e) {
  const a = this.getOffsetParent || hu, t = this.getDimensions;
  return {
    reference: X1(e.reference, await a(e.floating), e.strategy),
    floating: {
      x: 0,
      y: 0,
      ...await t(e.floating)
    }
  };
};
function G1(e) {
  return Lt(e).direction === "rtl";
}
const J1 = {
  convertOffsetParentRelativeRectToViewportRelativeRect: V1,
  getDocumentElement: pa,
  getClippingRect: q1,
  getOffsetParent: hu,
  getElementRects: Q1,
  getClientRects: Y1,
  getDimensions: K1,
  getScale: cn,
  isElement: fa,
  isRTL: G1
};
function Z1(e, a) {
  let t = null, n;
  const r = pa(e);
  function i() {
    var o;
    clearTimeout(n), (o = t) == null || o.disconnect(), t = null;
  }
  function l(o, s) {
    o === void 0 && (o = !1), s === void 0 && (s = 1), i();
    const {
      left: d,
      top: u,
      width: c,
      height: f
    } = e.getBoundingClientRect();
    if (o || a(), !c || !f)
      return;
    const v = cr(u), g = cr(r.clientWidth - (d + c)), b = cr(r.clientHeight - (u + f)), C = cr(d), w = {
      rootMargin: -v + "px " + -g + "px " + -b + "px " + -C + "px",
      threshold: un(0, jl(1, s)) || 1
    };
    let y = !0;
    function x(D) {
      const S = D[0].intersectionRatio;
      if (S !== s) {
        if (!y)
          return l();
        S ? l(!1, S) : n = setTimeout(() => {
          l(!1, 1e-7);
        }, 100);
      }
      y = !1;
    }
    try {
      t = new IntersectionObserver(x, {
        ...w,
        // Handle <iframe>s
        root: r.ownerDocument
      });
    } catch {
      t = new IntersectionObserver(x, w);
    }
    t.observe(e);
  }
  return l(!0), i;
}
function yu(e, a, t, n) {
  n === void 0 && (n = {});
  const {
    ancestorScroll: r = !0,
    ancestorResize: i = !0,
    elementResize: l = typeof ResizeObserver == "function",
    layoutShift: o = typeof IntersectionObserver == "function",
    animationFrame: s = !1
  } = n, d = Mi(e), u = r || i ? [...d ? Hn(d) : [], ...Hn(a)] : [];
  u.forEach((p) => {
    r && p.addEventListener("scroll", t, {
      passive: !0
    }), i && p.addEventListener("resize", t);
  });
  const c = d && o ? Z1(d, t) : null;
  let f = -1, v = null;
  l && (v = new ResizeObserver((p) => {
    let [w] = p;
    w && w.target === d && v && (v.unobserve(a), cancelAnimationFrame(f), f = requestAnimationFrame(() => {
      var y;
      (y = v) == null || y.observe(a);
    })), t();
  }), d && !s && v.observe(d), v.observe(a));
  let g, b = s ? Wa(e) : null;
  s && C();
  function C() {
    const p = Wa(e);
    b && (p.x !== b.x || p.y !== b.y || p.width !== b.width || p.height !== b.height) && t(), b = p, g = requestAnimationFrame(C);
  }
  return t(), () => {
    var p;
    u.forEach((w) => {
      r && w.removeEventListener("scroll", t), i && w.removeEventListener("resize", t);
    }), c == null || c(), (p = v) == null || p.disconnect(), v = null, s && cancelAnimationFrame(g);
  };
}
const gu = R1, bu = (e, a, t) => {
  const n = /* @__PURE__ */ new Map(), r = {
    platform: J1,
    ...t
  }, i = {
    ...r.platform,
    _c: n
  };
  return E1(e, a, {
    ...r,
    platform: i
  });
}, em = { class: "flex flex-wrap" }, tm = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }, ay = /* @__PURE__ */ be({
  __name: "UISelectInput",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    options: {},
    placeholder: { default: "Choose One" },
    multiple: { type: Boolean, default: !1 },
    disabled: { type: Boolean }
  },
  setup(e) {
    const a = e, { value: t, errorMessage: n, handleBlur: r } = Ia(
      Qe(a, "name"),
      Qe(a, "rules"),
      {
        label: Qe(a, "label"),
        syncVModel: !0
      }
    ), i = A(() => {
      const s = Array.isArray(t.value) ? t.value : [t.value];
      return a.options.filter((d) => s.includes(d.value));
    }), l = z(), o = z();
    return ns((s) => {
      const d = l.value, u = o.value;
      !d || !u || s(
        yu(d, u, async () => {
          const { x: c, y: f } = await bu(d, u, {
            placement: "bottom-start",
            middleware: [gu()]
          });
          u.style.left = `${c}px`, u.style.top = `${f}px`, u.style.width = `${d.clientWidth}px`, d.closest("[role=dialog]") && (u.style.zIndex = "10000");
        })
      );
    }), (s, d) => (P(), Y("div", {
      ref_key: "anchorRef",
      ref: l
    }, [
      $e(m(Z0), {
        as: "div",
        modelValue: m(t),
        "onUpdate:modelValue": d[0] || (d[0] = (u) => ra(t) ? t.value = u : null),
        multiple: s.multiple,
        disabled: s.disabled
      }, {
        default: me(() => [
          $e(m(e1), {
            class: ye([
              "tw-reset relative text-left cursor-default w-full px-2 border rounded-sm border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
              {
                "border-warning-orange-600 text-warning-orange-600 italic": m(n)
              }
            ]),
            onBlur: m(r)
          }, {
            default: me(({ open: u }) => [
              ie("div", em, [
                i.value.length > 0 ? (P(!0), Y(Se, { key: 0 }, Fe(i.value, (c, f) => (P(), Y("span", { key: f }, Le(c.label) + Le(f < i.value.length - 1 ? ", " : ""), 1))), 128)) : (P(), Y(Se, { key: 1 }, [
                  vt(Le(s.placeholder), 1)
                ], 64))
              ]),
              ie("span", tm, [
                $e(m(qt), {
                  class: "h-4 w-4 text-gray-800",
                  "aria-hidden": "true",
                  icon: u ? "caret-up" : "caret-down"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          }, 8, ["class", "onBlur"]),
          (P(), Me(Gl, { to: "body" }, [
            ie("div", {
              ref_key: "menuRef",
              ref: o,
              class: "absolute z-10 [[role=dialog]_&]:z-[10000]"
            }, [
              $e(Da, {
                "leave-active-class": "transition ease-in duration-100",
                "leave-from-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }, {
                default: me(() => [
                  $e(m(t1), { class: "max-h-60 overflow-auto rounded-sm bg-white shadow-lg border border-ui-gray-400 border-t-0 focus:outline-none" }, {
                    default: me(() => [
                      (P(!0), Y(Se, null, Fe(s.options, (u) => (P(), Me(m(a1), {
                        key: u.value,
                        value: u.value,
                        as: "li",
                        class: "ui-active:bg-pivot-teal-600 ui-active:text-white text-ui-gray-800 relative cursor-default select-none py-1 pl-2 pr-9 ui-selected:font-semibold font-normal"
                      }, {
                        default: me(() => [
                          vt(Le(u.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 512)
          ]))
        ]),
        _: 1
      }, 8, ["modelValue", "multiple", "disabled"])
    ], 512));
  }
}), wu = 6048e5, am = 864e5, nm = 6e4, xu = 36e5, rm = 1e3, Ao = Symbol.for("constructDateFrom");
function Be(e, a) {
  return typeof e == "function" ? e(a) : e && typeof e == "object" && Ao in e ? e[Ao](a) : e instanceof Date ? new e.constructor(a) : new Date(a);
}
function Te(e, a) {
  return Be(a || e, e);
}
function Bt(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in);
  return isNaN(a) ? Be((t == null ? void 0 : t.in) || e, NaN) : (a && n.setDate(n.getDate() + a), n);
}
function Wt(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in);
  if (isNaN(a))
    return Be((t == null ? void 0 : t.in) || e, NaN);
  if (!a)
    return n;
  const r = n.getDate(), i = Be((t == null ? void 0 : t.in) || e, n.getTime());
  i.setMonth(n.getMonth() + a + 1, 0);
  const l = i.getDate();
  return r >= l ? i : (n.setFullYear(
    i.getFullYear(),
    i.getMonth(),
    r
  ), n);
}
function ku(e, a, t) {
  const {
    years: n = 0,
    months: r = 0,
    weeks: i = 0,
    days: l = 0,
    hours: o = 0,
    minutes: s = 0,
    seconds: d = 0
  } = a, u = Te(e, t == null ? void 0 : t.in), c = r || n ? Wt(u, r + n * 12) : u, f = l || i ? Bt(c, l + i * 7) : c, v = s + o * 60, b = (d + v * 60) * 1e3;
  return Be((t == null ? void 0 : t.in) || e, +f + b);
}
function lm(e, a, t) {
  return Be((t == null ? void 0 : t.in) || e, +Te(e) + a);
}
function im(e, a, t) {
  return lm(e, a * xu, t);
}
let om = {};
function Ga() {
  return om;
}
function Kt(e, a) {
  var o, s, d, u;
  const t = Ga(), n = (a == null ? void 0 : a.weekStartsOn) ?? ((s = (o = a == null ? void 0 : a.locale) == null ? void 0 : o.options) == null ? void 0 : s.weekStartsOn) ?? t.weekStartsOn ?? ((u = (d = t.locale) == null ? void 0 : d.options) == null ? void 0 : u.weekStartsOn) ?? 0, r = Te(e, a == null ? void 0 : a.in), i = r.getDay(), l = (i < n ? 7 : 0) + i - n;
  return r.setDate(r.getDate() - l), r.setHours(0, 0, 0, 0), r;
}
function vn(e, a) {
  return Kt(e, { ...a, weekStartsOn: 1 });
}
function _u(e, a) {
  const t = Te(e, a == null ? void 0 : a.in), n = t.getFullYear(), r = Be(t, 0);
  r.setFullYear(n + 1, 0, 4), r.setHours(0, 0, 0, 0);
  const i = vn(r), l = Be(t, 0);
  l.setFullYear(n, 0, 4), l.setHours(0, 0, 0, 0);
  const o = vn(l);
  return t.getTime() >= i.getTime() ? n + 1 : t.getTime() >= o.getTime() ? n : n - 1;
}
function Tr(e) {
  const a = Te(e), t = new Date(
    Date.UTC(
      a.getFullYear(),
      a.getMonth(),
      a.getDate(),
      a.getHours(),
      a.getMinutes(),
      a.getSeconds(),
      a.getMilliseconds()
    )
  );
  return t.setUTCFullYear(a.getFullYear()), +e - +t;
}
function Kn(e, ...a) {
  const t = Be.bind(
    null,
    e || a.find((n) => typeof n == "object")
  );
  return a.map(t);
}
function Do(e, a) {
  const t = Te(e, a == null ? void 0 : a.in);
  return t.setHours(0, 0, 0, 0), t;
}
function Mu(e, a, t) {
  const [n, r] = Kn(
    t == null ? void 0 : t.in,
    e,
    a
  ), i = Do(n), l = Do(r), o = +i - Tr(i), s = +l - Tr(l);
  return Math.round((o - s) / am);
}
function sm(e, a) {
  const t = _u(e, a), n = Be((a == null ? void 0 : a.in) || e, 0);
  return n.setFullYear(t, 0, 4), n.setHours(0, 0, 0, 0), vn(n);
}
function um(e, a, t) {
  return Wt(e, a * 3, t);
}
function Oi(e, a, t) {
  return Wt(e, a * 12, t);
}
function Io(e, a) {
  const t = +Te(e) - +Te(a);
  return t < 0 ? -1 : t > 0 ? 1 : t;
}
function Ou(e) {
  return e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function Cn(e) {
  return !(!Ou(e) && typeof e != "number" || isNaN(+Te(e)));
}
function Eo(e, a) {
  const t = Te(e, a == null ? void 0 : a.in);
  return Math.trunc(t.getMonth() / 3) + 1;
}
function cm(e, a, t) {
  const [n, r] = Kn(
    t == null ? void 0 : t.in,
    e,
    a
  );
  return n.getFullYear() - r.getFullYear();
}
function dm(e, a, t) {
  const [n, r] = Kn(
    t == null ? void 0 : t.in,
    e,
    a
  ), i = Io(n, r), l = Math.abs(cm(n, r));
  n.setFullYear(1584), r.setFullYear(1584);
  const o = Io(n, r) === -i, s = i * (l - +o);
  return s === 0 ? 0 : s;
}
function Su(e, a) {
  const [t, n] = Kn(e, a.start, a.end);
  return { start: t, end: n };
}
function Tu(e, a) {
  const { start: t, end: n } = Su(a == null ? void 0 : a.in, e);
  let r = +t > +n;
  const i = r ? +t : +n, l = r ? n : t;
  l.setHours(0, 0, 0, 0);
  let o = (a == null ? void 0 : a.step) ?? 1;
  if (!o)
    return [];
  o < 0 && (o = -o, r = !r);
  const s = [];
  for (; +l <= i; )
    s.push(Be(t, l)), l.setDate(l.getDate() + o), l.setHours(0, 0, 0, 0);
  return r ? s.reverse() : s;
}
function Fa(e, a) {
  const t = Te(e, a == null ? void 0 : a.in), n = t.getMonth(), r = n - n % 3;
  return t.setMonth(r, 1), t.setHours(0, 0, 0, 0), t;
}
function fm(e, a) {
  const { start: t, end: n } = Su(a == null ? void 0 : a.in, e);
  let r = +t > +n;
  const i = r ? +Fa(t) : +Fa(n);
  let l = Fa(r ? n : t), o = (a == null ? void 0 : a.step) ?? 1;
  if (!o)
    return [];
  o < 0 && (o = -o, r = !r);
  const s = [];
  for (; +l <= i; )
    s.push(Be(t, l)), l = um(l, o);
  return r ? s.reverse() : s;
}
function vm(e, a) {
  const t = Te(e, a == null ? void 0 : a.in);
  return t.setDate(1), t.setHours(0, 0, 0, 0), t;
}
function Pu(e, a) {
  const t = Te(e, a == null ? void 0 : a.in), n = t.getFullYear();
  return t.setFullYear(n + 1, 0, 0), t.setHours(23, 59, 59, 999), t;
}
function zn(e, a) {
  const t = Te(e, a == null ? void 0 : a.in);
  return t.setFullYear(t.getFullYear(), 0, 1), t.setHours(0, 0, 0, 0), t;
}
function Cu(e, a) {
  var o, s, d, u;
  const t = Ga(), n = (a == null ? void 0 : a.weekStartsOn) ?? ((s = (o = a == null ? void 0 : a.locale) == null ? void 0 : o.options) == null ? void 0 : s.weekStartsOn) ?? t.weekStartsOn ?? ((u = (d = t.locale) == null ? void 0 : d.options) == null ? void 0 : u.weekStartsOn) ?? 0, r = Te(e, a == null ? void 0 : a.in), i = r.getDay(), l = (i < n ? -7 : 0) + 6 - (i - n);
  return r.setDate(r.getDate() + l), r.setHours(23, 59, 59, 999), r;
}
function $o(e, a) {
  const t = Te(e, a == null ? void 0 : a.in), n = t.getMonth(), r = n - n % 3 + 3;
  return t.setMonth(r, 0), t.setHours(23, 59, 59, 999), t;
}
const mm = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, pm = (e, a, t) => {
  let n;
  const r = mm[e];
  return typeof r == "string" ? n = r : a === 1 ? n = r.one : n = r.other.replace("{{count}}", a.toString()), t != null && t.addSuffix ? t.comparison && t.comparison > 0 ? "in " + n : n + " ago" : n;
};
function ml(e) {
  return (a = {}) => {
    const t = a.width ? String(a.width) : e.defaultWidth;
    return e.formats[t] || e.formats[e.defaultWidth];
  };
}
const hm = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, ym = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, gm = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, bm = {
  date: ml({
    formats: hm,
    defaultWidth: "full"
  }),
  time: ml({
    formats: ym,
    defaultWidth: "full"
  }),
  dateTime: ml({
    formats: gm,
    defaultWidth: "full"
  })
}, wm = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, xm = (e, a, t, n) => wm[e];
function kn(e) {
  return (a, t) => {
    const n = t != null && t.context ? String(t.context) : "standalone";
    let r;
    if (n === "formatting" && e.formattingValues) {
      const l = e.defaultFormattingWidth || e.defaultWidth, o = t != null && t.width ? String(t.width) : l;
      r = e.formattingValues[o] || e.formattingValues[l];
    } else {
      const l = e.defaultWidth, o = t != null && t.width ? String(t.width) : e.defaultWidth;
      r = e.values[o] || e.values[l];
    }
    const i = e.argumentCallback ? e.argumentCallback(a) : a;
    return r[i];
  };
}
const km = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, _m = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, Mm = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}, Om = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
}, Sm = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, Tm = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, Pm = (e, a) => {
  const t = Number(e), n = t % 100;
  if (n > 20 || n < 10)
    switch (n % 10) {
      case 1:
        return t + "st";
      case 2:
        return t + "nd";
      case 3:
        return t + "rd";
    }
  return t + "th";
}, Cm = {
  ordinalNumber: Pm,
  era: kn({
    values: km,
    defaultWidth: "wide"
  }),
  quarter: kn({
    values: _m,
    defaultWidth: "wide",
    argumentCallback: (e) => e - 1
  }),
  month: kn({
    values: Mm,
    defaultWidth: "wide"
  }),
  day: kn({
    values: Om,
    defaultWidth: "wide"
  }),
  dayPeriod: kn({
    values: Sm,
    defaultWidth: "wide",
    formattingValues: Tm,
    defaultFormattingWidth: "wide"
  })
};
function _n(e) {
  return (a, t = {}) => {
    const n = t.width, r = n && e.matchPatterns[n] || e.matchPatterns[e.defaultMatchWidth], i = a.match(r);
    if (!i)
      return null;
    const l = i[0], o = n && e.parsePatterns[n] || e.parsePatterns[e.defaultParseWidth], s = Array.isArray(o) ? Dm(o, (c) => c.test(l)) : (
      // [TODO] -- I challenge you to fix the type
      Am(o, (c) => c.test(l))
    );
    let d;
    d = e.valueCallback ? e.valueCallback(s) : s, d = t.valueCallback ? (
      // [TODO] -- I challenge you to fix the type
      t.valueCallback(d)
    ) : d;
    const u = a.slice(l.length);
    return { value: d, rest: u };
  };
}
function Am(e, a) {
  for (const t in e)
    if (Object.prototype.hasOwnProperty.call(e, t) && a(e[t]))
      return t;
}
function Dm(e, a) {
  for (let t = 0; t < e.length; t++)
    if (a(e[t]))
      return t;
}
function Im(e) {
  return (a, t = {}) => {
    const n = a.match(e.matchPattern);
    if (!n)
      return null;
    const r = n[0], i = a.match(e.parsePattern);
    if (!i)
      return null;
    let l = e.valueCallback ? e.valueCallback(i[0]) : i[0];
    l = t.valueCallback ? t.valueCallback(l) : l;
    const o = a.slice(r.length);
    return { value: l, rest: o };
  };
}
const Em = /^(\d+)(th|st|nd|rd)?/i, $m = /\d+/i, Rm = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, Nm = {
  any: [/^b/i, /^(a|c)/i]
}, Lm = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, Hm = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, zm = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, Fm = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
}, Vm = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, Ym = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, Bm = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, jm = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, Um = {
  ordinalNumber: Im({
    matchPattern: Em,
    parsePattern: $m,
    valueCallback: (e) => parseInt(e, 10)
  }),
  era: _n({
    matchPatterns: Rm,
    defaultMatchWidth: "wide",
    parsePatterns: Nm,
    defaultParseWidth: "any"
  }),
  quarter: _n({
    matchPatterns: Lm,
    defaultMatchWidth: "wide",
    parsePatterns: Hm,
    defaultParseWidth: "any",
    valueCallback: (e) => e + 1
  }),
  month: _n({
    matchPatterns: zm,
    defaultMatchWidth: "wide",
    parsePatterns: Fm,
    defaultParseWidth: "any"
  }),
  day: _n({
    matchPatterns: Vm,
    defaultMatchWidth: "wide",
    parsePatterns: Ym,
    defaultParseWidth: "any"
  }),
  dayPeriod: _n({
    matchPatterns: Bm,
    defaultMatchWidth: "any",
    parsePatterns: jm,
    defaultParseWidth: "any"
  })
}, Au = {
  code: "en-US",
  formatDistance: pm,
  formatLong: bm,
  formatRelative: xm,
  localize: Cm,
  match: Um,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
function Wm(e, a) {
  const t = Te(e, a == null ? void 0 : a.in);
  return Mu(t, zn(t)) + 1;
}
function Si(e, a) {
  const t = Te(e, a == null ? void 0 : a.in), n = +vn(t) - +sm(t);
  return Math.round(n / wu) + 1;
}
function Ti(e, a) {
  var u, c, f, v;
  const t = Te(e, a == null ? void 0 : a.in), n = t.getFullYear(), r = Ga(), i = (a == null ? void 0 : a.firstWeekContainsDate) ?? ((c = (u = a == null ? void 0 : a.locale) == null ? void 0 : u.options) == null ? void 0 : c.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((v = (f = r.locale) == null ? void 0 : f.options) == null ? void 0 : v.firstWeekContainsDate) ?? 1, l = Be((a == null ? void 0 : a.in) || e, 0);
  l.setFullYear(n + 1, 0, i), l.setHours(0, 0, 0, 0);
  const o = Kt(l, a), s = Be((a == null ? void 0 : a.in) || e, 0);
  s.setFullYear(n, 0, i), s.setHours(0, 0, 0, 0);
  const d = Kt(s, a);
  return +t >= +o ? n + 1 : +t >= +d ? n : n - 1;
}
function qm(e, a) {
  var o, s, d, u;
  const t = Ga(), n = (a == null ? void 0 : a.firstWeekContainsDate) ?? ((s = (o = a == null ? void 0 : a.locale) == null ? void 0 : o.options) == null ? void 0 : s.firstWeekContainsDate) ?? t.firstWeekContainsDate ?? ((u = (d = t.locale) == null ? void 0 : d.options) == null ? void 0 : u.firstWeekContainsDate) ?? 1, r = Ti(e, a), i = Be((a == null ? void 0 : a.in) || e, 0);
  return i.setFullYear(r, 0, n), i.setHours(0, 0, 0, 0), Kt(i, a);
}
function Pi(e, a) {
  const t = Te(e, a == null ? void 0 : a.in), n = +Kt(t, a) - +qm(t, a);
  return Math.round(n / wu) + 1;
}
function Ye(e, a) {
  const t = e < 0 ? "-" : "", n = Math.abs(e).toString().padStart(a, "0");
  return t + n;
}
const ga = {
  // Year
  y(e, a) {
    const t = e.getFullYear(), n = t > 0 ? t : 1 - t;
    return Ye(a === "yy" ? n % 100 : n, a.length);
  },
  // Month
  M(e, a) {
    const t = e.getMonth();
    return a === "M" ? String(t + 1) : Ye(t + 1, 2);
  },
  // Day of the month
  d(e, a) {
    return Ye(e.getDate(), a.length);
  },
  // AM or PM
  a(e, a) {
    const t = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (a) {
      case "a":
      case "aa":
        return t.toUpperCase();
      case "aaa":
        return t;
      case "aaaaa":
        return t[0];
      case "aaaa":
      default:
        return t === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(e, a) {
    return Ye(e.getHours() % 12 || 12, a.length);
  },
  // Hour [0-23]
  H(e, a) {
    return Ye(e.getHours(), a.length);
  },
  // Minute
  m(e, a) {
    return Ye(e.getMinutes(), a.length);
  },
  // Second
  s(e, a) {
    return Ye(e.getSeconds(), a.length);
  },
  // Fraction of second
  S(e, a) {
    const t = a.length, n = e.getMilliseconds(), r = Math.trunc(
      n * Math.pow(10, t - 3)
    );
    return Ye(r, a.length);
  }
}, en = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, Ro = {
  // Era
  G: function(e, a, t) {
    const n = e.getFullYear() > 0 ? 1 : 0;
    switch (a) {
      case "G":
      case "GG":
      case "GGG":
        return t.era(n, { width: "abbreviated" });
      case "GGGGG":
        return t.era(n, { width: "narrow" });
      case "GGGG":
      default:
        return t.era(n, { width: "wide" });
    }
  },
  // Year
  y: function(e, a, t) {
    if (a === "yo") {
      const n = e.getFullYear(), r = n > 0 ? n : 1 - n;
      return t.ordinalNumber(r, { unit: "year" });
    }
    return ga.y(e, a);
  },
  // Local week-numbering year
  Y: function(e, a, t, n) {
    const r = Ti(e, n), i = r > 0 ? r : 1 - r;
    if (a === "YY") {
      const l = i % 100;
      return Ye(l, 2);
    }
    return a === "Yo" ? t.ordinalNumber(i, { unit: "year" }) : Ye(i, a.length);
  },
  // ISO week-numbering year
  R: function(e, a) {
    const t = _u(e);
    return Ye(t, a.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(e, a) {
    const t = e.getFullYear();
    return Ye(t, a.length);
  },
  // Quarter
  Q: function(e, a, t) {
    const n = Math.ceil((e.getMonth() + 1) / 3);
    switch (a) {
      case "Q":
        return String(n);
      case "QQ":
        return Ye(n, 2);
      case "Qo":
        return t.ordinalNumber(n, { unit: "quarter" });
      case "QQQ":
        return t.quarter(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return t.quarter(n, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return t.quarter(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(e, a, t) {
    const n = Math.ceil((e.getMonth() + 1) / 3);
    switch (a) {
      case "q":
        return String(n);
      case "qq":
        return Ye(n, 2);
      case "qo":
        return t.ordinalNumber(n, { unit: "quarter" });
      case "qqq":
        return t.quarter(n, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return t.quarter(n, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return t.quarter(n, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(e, a, t) {
    const n = e.getMonth();
    switch (a) {
      case "M":
      case "MM":
        return ga.M(e, a);
      case "Mo":
        return t.ordinalNumber(n + 1, { unit: "month" });
      case "MMM":
        return t.month(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return t.month(n, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return t.month(n, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(e, a, t) {
    const n = e.getMonth();
    switch (a) {
      case "L":
        return String(n + 1);
      case "LL":
        return Ye(n + 1, 2);
      case "Lo":
        return t.ordinalNumber(n + 1, { unit: "month" });
      case "LLL":
        return t.month(n, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return t.month(n, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return t.month(n, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(e, a, t, n) {
    const r = Pi(e, n);
    return a === "wo" ? t.ordinalNumber(r, { unit: "week" }) : Ye(r, a.length);
  },
  // ISO week of year
  I: function(e, a, t) {
    const n = Si(e);
    return a === "Io" ? t.ordinalNumber(n, { unit: "week" }) : Ye(n, a.length);
  },
  // Day of the month
  d: function(e, a, t) {
    return a === "do" ? t.ordinalNumber(e.getDate(), { unit: "date" }) : ga.d(e, a);
  },
  // Day of year
  D: function(e, a, t) {
    const n = Wm(e);
    return a === "Do" ? t.ordinalNumber(n, { unit: "dayOfYear" }) : Ye(n, a.length);
  },
  // Day of week
  E: function(e, a, t) {
    const n = e.getDay();
    switch (a) {
      case "E":
      case "EE":
      case "EEE":
        return t.day(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return t.day(n, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return t.day(n, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return t.day(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(e, a, t, n) {
    const r = e.getDay(), i = (r - n.weekStartsOn + 8) % 7 || 7;
    switch (a) {
      case "e":
        return String(i);
      case "ee":
        return Ye(i, 2);
      case "eo":
        return t.ordinalNumber(i, { unit: "day" });
      case "eee":
        return t.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return t.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return t.day(r, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return t.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(e, a, t, n) {
    const r = e.getDay(), i = (r - n.weekStartsOn + 8) % 7 || 7;
    switch (a) {
      case "c":
        return String(i);
      case "cc":
        return Ye(i, a.length);
      case "co":
        return t.ordinalNumber(i, { unit: "day" });
      case "ccc":
        return t.day(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return t.day(r, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return t.day(r, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return t.day(r, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(e, a, t) {
    const n = e.getDay(), r = n === 0 ? 7 : n;
    switch (a) {
      case "i":
        return String(r);
      case "ii":
        return Ye(r, a.length);
      case "io":
        return t.ordinalNumber(r, { unit: "day" });
      case "iii":
        return t.day(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return t.day(n, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return t.day(n, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return t.day(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(e, a, t) {
    const r = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (a) {
      case "a":
      case "aa":
        return t.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return t.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return t.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return t.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(e, a, t) {
    const n = e.getHours();
    let r;
    switch (n === 12 ? r = en.noon : n === 0 ? r = en.midnight : r = n / 12 >= 1 ? "pm" : "am", a) {
      case "b":
      case "bb":
        return t.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return t.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return t.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return t.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(e, a, t) {
    const n = e.getHours();
    let r;
    switch (n >= 17 ? r = en.evening : n >= 12 ? r = en.afternoon : n >= 4 ? r = en.morning : r = en.night, a) {
      case "B":
      case "BB":
      case "BBB":
        return t.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return t.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return t.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(e, a, t) {
    if (a === "ho") {
      let n = e.getHours() % 12;
      return n === 0 && (n = 12), t.ordinalNumber(n, { unit: "hour" });
    }
    return ga.h(e, a);
  },
  // Hour [0-23]
  H: function(e, a, t) {
    return a === "Ho" ? t.ordinalNumber(e.getHours(), { unit: "hour" }) : ga.H(e, a);
  },
  // Hour [0-11]
  K: function(e, a, t) {
    const n = e.getHours() % 12;
    return a === "Ko" ? t.ordinalNumber(n, { unit: "hour" }) : Ye(n, a.length);
  },
  // Hour [1-24]
  k: function(e, a, t) {
    let n = e.getHours();
    return n === 0 && (n = 24), a === "ko" ? t.ordinalNumber(n, { unit: "hour" }) : Ye(n, a.length);
  },
  // Minute
  m: function(e, a, t) {
    return a === "mo" ? t.ordinalNumber(e.getMinutes(), { unit: "minute" }) : ga.m(e, a);
  },
  // Second
  s: function(e, a, t) {
    return a === "so" ? t.ordinalNumber(e.getSeconds(), { unit: "second" }) : ga.s(e, a);
  },
  // Fraction of second
  S: function(e, a) {
    return ga.S(e, a);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(e, a, t) {
    const n = e.getTimezoneOffset();
    if (n === 0)
      return "Z";
    switch (a) {
      case "X":
        return Lo(n);
      case "XXXX":
      case "XX":
        return Na(n);
      case "XXXXX":
      case "XXX":
      default:
        return Na(n, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(e, a, t) {
    const n = e.getTimezoneOffset();
    switch (a) {
      case "x":
        return Lo(n);
      case "xxxx":
      case "xx":
        return Na(n);
      case "xxxxx":
      case "xxx":
      default:
        return Na(n, ":");
    }
  },
  // Timezone (GMT)
  O: function(e, a, t) {
    const n = e.getTimezoneOffset();
    switch (a) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + No(n, ":");
      case "OOOO":
      default:
        return "GMT" + Na(n, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(e, a, t) {
    const n = e.getTimezoneOffset();
    switch (a) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + No(n, ":");
      case "zzzz":
      default:
        return "GMT" + Na(n, ":");
    }
  },
  // Seconds timestamp
  t: function(e, a, t) {
    const n = Math.trunc(+e / 1e3);
    return Ye(n, a.length);
  },
  // Milliseconds timestamp
  T: function(e, a, t) {
    return Ye(+e, a.length);
  }
};
function No(e, a = "") {
  const t = e > 0 ? "-" : "+", n = Math.abs(e), r = Math.trunc(n / 60), i = n % 60;
  return i === 0 ? t + String(r) : t + String(r) + a + Ye(i, 2);
}
function Lo(e, a) {
  return e % 60 === 0 ? (e > 0 ? "-" : "+") + Ye(Math.abs(e) / 60, 2) : Na(e, a);
}
function Na(e, a = "") {
  const t = e > 0 ? "-" : "+", n = Math.abs(e), r = Ye(Math.trunc(n / 60), 2), i = Ye(n % 60, 2);
  return t + r + a + i;
}
const Ho = (e, a) => {
  switch (e) {
    case "P":
      return a.date({ width: "short" });
    case "PP":
      return a.date({ width: "medium" });
    case "PPP":
      return a.date({ width: "long" });
    case "PPPP":
    default:
      return a.date({ width: "full" });
  }
}, Du = (e, a) => {
  switch (e) {
    case "p":
      return a.time({ width: "short" });
    case "pp":
      return a.time({ width: "medium" });
    case "ppp":
      return a.time({ width: "long" });
    case "pppp":
    default:
      return a.time({ width: "full" });
  }
}, Km = (e, a) => {
  const t = e.match(/(P+)(p+)?/) || [], n = t[1], r = t[2];
  if (!r)
    return Ho(e, a);
  let i;
  switch (n) {
    case "P":
      i = a.dateTime({ width: "short" });
      break;
    case "PP":
      i = a.dateTime({ width: "medium" });
      break;
    case "PPP":
      i = a.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      i = a.dateTime({ width: "full" });
      break;
  }
  return i.replace("{{date}}", Ho(n, a)).replace("{{time}}", Du(r, a));
}, Ul = {
  p: Du,
  P: Km
}, Xm = /^D+$/, Qm = /^Y+$/, Gm = ["D", "DD", "YY", "YYYY"];
function Iu(e) {
  return Xm.test(e);
}
function Eu(e) {
  return Qm.test(e);
}
function Wl(e, a, t) {
  const n = Jm(e, a, t);
  if (console.warn(n), Gm.includes(e))
    throw new RangeError(n);
}
function Jm(e, a, t) {
  const n = e[0] === "Y" ? "years" : "days of the month";
  return `Use \`${e.toLowerCase()}\` instead of \`${e}\` (in \`${a}\`) for formatting ${n} to the input \`${t}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
const Zm = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, ep = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, tp = /^'([^]*?)'?$/, ap = /''/g, np = /[a-zA-Z]/;
function na(e, a, t) {
  var u, c, f, v, g, b, C, p;
  const n = Ga(), r = (t == null ? void 0 : t.locale) ?? n.locale ?? Au, i = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((c = (u = t == null ? void 0 : t.locale) == null ? void 0 : u.options) == null ? void 0 : c.firstWeekContainsDate) ?? n.firstWeekContainsDate ?? ((v = (f = n.locale) == null ? void 0 : f.options) == null ? void 0 : v.firstWeekContainsDate) ?? 1, l = (t == null ? void 0 : t.weekStartsOn) ?? ((b = (g = t == null ? void 0 : t.locale) == null ? void 0 : g.options) == null ? void 0 : b.weekStartsOn) ?? n.weekStartsOn ?? ((p = (C = n.locale) == null ? void 0 : C.options) == null ? void 0 : p.weekStartsOn) ?? 0, o = Te(e, t == null ? void 0 : t.in);
  if (!Cn(o))
    throw new RangeError("Invalid time value");
  let s = a.match(ep).map((w) => {
    const y = w[0];
    if (y === "p" || y === "P") {
      const x = Ul[y];
      return x(w, r.formatLong);
    }
    return w;
  }).join("").match(Zm).map((w) => {
    if (w === "''")
      return { isToken: !1, value: "'" };
    const y = w[0];
    if (y === "'")
      return { isToken: !1, value: rp(w) };
    if (Ro[y])
      return { isToken: !0, value: w };
    if (y.match(np))
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + y + "`"
      );
    return { isToken: !1, value: w };
  });
  r.localize.preprocessor && (s = r.localize.preprocessor(o, s));
  const d = {
    firstWeekContainsDate: i,
    weekStartsOn: l,
    locale: r
  };
  return s.map((w) => {
    if (!w.isToken)
      return w.value;
    const y = w.value;
    (!(t != null && t.useAdditionalWeekYearTokens) && Eu(y) || !(t != null && t.useAdditionalDayOfYearTokens) && Iu(y)) && Wl(y, a, String(e));
    const x = Ro[y[0]];
    return x(o, y, r.localize, d);
  }).join("");
}
function rp(e) {
  const a = e.match(tp);
  return a ? a[1].replace(ap, "'") : e;
}
function lp(e, a) {
  return Te(e, a == null ? void 0 : a.in).getDay();
}
function ip(e, a) {
  const t = Te(e, a == null ? void 0 : a.in), n = t.getFullYear(), r = t.getMonth(), i = Be(t, 0);
  return i.setFullYear(n, r + 1, 0), i.setHours(0, 0, 0, 0), i.getDate();
}
function op() {
  return Object.assign({}, Ga());
}
function va(e, a) {
  return Te(e, a == null ? void 0 : a.in).getHours();
}
function sp(e, a) {
  const t = Te(e, a == null ? void 0 : a.in).getDay();
  return t === 0 ? 7 : t;
}
function Aa(e, a) {
  return Te(e, a == null ? void 0 : a.in).getMinutes();
}
function Ee(e, a) {
  return Te(e, a == null ? void 0 : a.in).getMonth();
}
function mn(e) {
  return Te(e).getSeconds();
}
function Pe(e, a) {
  return Te(e, a == null ? void 0 : a.in).getFullYear();
}
function pn(e, a) {
  return +Te(e) > +Te(a);
}
function Fn(e, a) {
  return +Te(e) < +Te(a);
}
function ln(e, a) {
  return +Te(e) == +Te(a);
}
function up(e, a) {
  const t = cp(a) ? new a(0) : Be(a, 0);
  return t.setFullYear(e.getFullYear(), e.getMonth(), e.getDate()), t.setHours(
    e.getHours(),
    e.getMinutes(),
    e.getSeconds(),
    e.getMilliseconds()
  ), t;
}
function cp(e) {
  var a;
  return typeof e == "function" && ((a = e.prototype) == null ? void 0 : a.constructor) === e;
}
const dp = 10;
class $u {
  constructor() {
    ve(this, "subPriority", 0);
  }
  validate(a, t) {
    return !0;
  }
}
class fp extends $u {
  constructor(a, t, n, r, i) {
    super(), this.value = a, this.validateValue = t, this.setValue = n, this.priority = r, i && (this.subPriority = i);
  }
  validate(a, t) {
    return this.validateValue(a, this.value, t);
  }
  set(a, t, n) {
    return this.setValue(a, t, this.value, n);
  }
}
class vp extends $u {
  constructor(t, n) {
    super();
    ve(this, "priority", dp);
    ve(this, "subPriority", -1);
    this.context = t || ((r) => Be(n, r));
  }
  set(t, n) {
    return n.timestampIsSet ? t : Be(t, up(t, this.context));
  }
}
class Ve {
  run(a, t, n, r) {
    const i = this.parse(a, t, n, r);
    return i ? {
      setter: new fp(
        i.value,
        this.validate,
        this.set,
        this.priority,
        this.subPriority
      ),
      rest: i.rest
    } : null;
  }
  validate(a, t, n) {
    return !0;
  }
}
class mp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 140);
    ve(this, "incompatibleTokens", ["R", "u", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "G":
      case "GG":
      case "GGG":
        return r.era(t, { width: "abbreviated" }) || r.era(t, { width: "narrow" });
      case "GGGGG":
        return r.era(t, { width: "narrow" });
      case "GGGG":
      default:
        return r.era(t, { width: "wide" }) || r.era(t, { width: "abbreviated" }) || r.era(t, { width: "narrow" });
    }
  }
  set(t, n, r) {
    return n.era = r, t.setFullYear(r, 0, 1), t.setHours(0, 0, 0, 0), t;
  }
}
const ct = {
  month: /^(1[0-2]|0?\d)/,
  // 0 to 12
  date: /^(3[0-1]|[0-2]?\d)/,
  // 0 to 31
  dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
  // 0 to 366
  week: /^(5[0-3]|[0-4]?\d)/,
  // 0 to 53
  hour23h: /^(2[0-3]|[0-1]?\d)/,
  // 0 to 23
  hour24h: /^(2[0-4]|[0-1]?\d)/,
  // 0 to 24
  hour11h: /^(1[0-1]|0?\d)/,
  // 0 to 11
  hour12h: /^(1[0-2]|0?\d)/,
  // 0 to 12
  minute: /^[0-5]?\d/,
  // 0 to 59
  second: /^[0-5]?\d/,
  // 0 to 59
  singleDigit: /^\d/,
  // 0 to 9
  twoDigits: /^\d{1,2}/,
  // 0 to 99
  threeDigits: /^\d{1,3}/,
  // 0 to 999
  fourDigits: /^\d{1,4}/,
  // 0 to 9999
  anyDigitsSigned: /^-?\d+/,
  singleDigitSigned: /^-?\d/,
  // 0 to 9, -0 to -9
  twoDigitsSigned: /^-?\d{1,2}/,
  // 0 to 99, -0 to -99
  threeDigitsSigned: /^-?\d{1,3}/,
  // 0 to 999, -0 to -999
  fourDigitsSigned: /^-?\d{1,4}/
  // 0 to 9999, -0 to -9999
}, Zt = {
  basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
  basic: /^([+-])(\d{2})(\d{2})|Z/,
  basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
  extended: /^([+-])(\d{2}):(\d{2})|Z/,
  extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
};
function dt(e, a) {
  return e && {
    value: a(e.value),
    rest: e.rest
  };
}
function et(e, a) {
  const t = a.match(e);
  return t ? {
    value: parseInt(t[0], 10),
    rest: a.slice(t[0].length)
  } : null;
}
function ea(e, a) {
  const t = a.match(e);
  if (!t)
    return null;
  if (t[0] === "Z")
    return {
      value: 0,
      rest: a.slice(1)
    };
  const n = t[1] === "+" ? 1 : -1, r = t[2] ? parseInt(t[2], 10) : 0, i = t[3] ? parseInt(t[3], 10) : 0, l = t[5] ? parseInt(t[5], 10) : 0;
  return {
    value: n * (r * xu + i * nm + l * rm),
    rest: a.slice(t[0].length)
  };
}
function Ru(e) {
  return et(ct.anyDigitsSigned, e);
}
function nt(e, a) {
  switch (e) {
    case 1:
      return et(ct.singleDigit, a);
    case 2:
      return et(ct.twoDigits, a);
    case 3:
      return et(ct.threeDigits, a);
    case 4:
      return et(ct.fourDigits, a);
    default:
      return et(new RegExp("^\\d{1," + e + "}"), a);
  }
}
function Pr(e, a) {
  switch (e) {
    case 1:
      return et(ct.singleDigitSigned, a);
    case 2:
      return et(ct.twoDigitsSigned, a);
    case 3:
      return et(ct.threeDigitsSigned, a);
    case 4:
      return et(ct.fourDigitsSigned, a);
    default:
      return et(new RegExp("^-?\\d{1," + e + "}"), a);
  }
}
function Ci(e) {
  switch (e) {
    case "morning":
      return 4;
    case "evening":
      return 17;
    case "pm":
    case "noon":
    case "afternoon":
      return 12;
    case "am":
    case "midnight":
    case "night":
    default:
      return 0;
  }
}
function Nu(e, a) {
  const t = a > 0, n = t ? a : 1 - a;
  let r;
  if (n <= 50)
    r = e || 100;
  else {
    const i = n + 50, l = Math.trunc(i / 100) * 100, o = e >= i % 100;
    r = e + l - (o ? 100 : 0);
  }
  return t ? r : 1 - r;
}
function Lu(e) {
  return e % 400 === 0 || e % 4 === 0 && e % 100 !== 0;
}
class pp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 130);
    ve(this, "incompatibleTokens", ["Y", "R", "u", "w", "I", "i", "e", "c", "t", "T"]);
  }
  parse(t, n, r) {
    const i = (l) => ({
      year: l,
      isTwoDigitYear: n === "yy"
    });
    switch (n) {
      case "y":
        return dt(nt(4, t), i);
      case "yo":
        return dt(
          r.ordinalNumber(t, {
            unit: "year"
          }),
          i
        );
      default:
        return dt(nt(n.length, t), i);
    }
  }
  validate(t, n) {
    return n.isTwoDigitYear || n.year > 0;
  }
  set(t, n, r) {
    const i = t.getFullYear();
    if (r.isTwoDigitYear) {
      const o = Nu(
        r.year,
        i
      );
      return t.setFullYear(o, 0, 1), t.setHours(0, 0, 0, 0), t;
    }
    const l = !("era" in n) || n.era === 1 ? r.year : 1 - r.year;
    return t.setFullYear(l, 0, 1), t.setHours(0, 0, 0, 0), t;
  }
}
class hp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 130);
    ve(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "Q",
      "q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "i",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    const i = (l) => ({
      year: l,
      isTwoDigitYear: n === "YY"
    });
    switch (n) {
      case "Y":
        return dt(nt(4, t), i);
      case "Yo":
        return dt(
          r.ordinalNumber(t, {
            unit: "year"
          }),
          i
        );
      default:
        return dt(nt(n.length, t), i);
    }
  }
  validate(t, n) {
    return n.isTwoDigitYear || n.year > 0;
  }
  set(t, n, r, i) {
    const l = Ti(t, i);
    if (r.isTwoDigitYear) {
      const s = Nu(
        r.year,
        l
      );
      return t.setFullYear(
        s,
        0,
        i.firstWeekContainsDate
      ), t.setHours(0, 0, 0, 0), Kt(t, i);
    }
    const o = !("era" in n) || n.era === 1 ? r.year : 1 - r.year;
    return t.setFullYear(o, 0, i.firstWeekContainsDate), t.setHours(0, 0, 0, 0), Kt(t, i);
  }
}
class yp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 130);
    ve(this, "incompatibleTokens", [
      "G",
      "y",
      "Y",
      "u",
      "Q",
      "q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n) {
    return Pr(n === "R" ? 4 : n.length, t);
  }
  set(t, n, r) {
    const i = Be(t, 0);
    return i.setFullYear(r, 0, 4), i.setHours(0, 0, 0, 0), vn(i);
  }
}
class gp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 130);
    ve(this, "incompatibleTokens", ["G", "y", "Y", "R", "w", "I", "i", "e", "c", "t", "T"]);
  }
  parse(t, n) {
    return Pr(n === "u" ? 4 : n.length, t);
  }
  set(t, n, r) {
    return t.setFullYear(r, 0, 1), t.setHours(0, 0, 0, 0), t;
  }
}
class bp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 120);
    ve(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    switch (n) {
      case "Q":
      case "QQ":
        return nt(n.length, t);
      case "Qo":
        return r.ordinalNumber(t, { unit: "quarter" });
      case "QQQ":
        return r.quarter(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.quarter(t, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQQ":
        return r.quarter(t, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return r.quarter(t, {
          width: "wide",
          context: "formatting"
        }) || r.quarter(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.quarter(t, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  validate(t, n) {
    return n >= 1 && n <= 4;
  }
  set(t, n, r) {
    return t.setMonth((r - 1) * 3, 1), t.setHours(0, 0, 0, 0), t;
  }
}
class wp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 120);
    ve(this, "incompatibleTokens", [
      "Y",
      "R",
      "Q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    switch (n) {
      case "q":
      case "qq":
        return nt(n.length, t);
      case "qo":
        return r.ordinalNumber(t, { unit: "quarter" });
      case "qqq":
        return r.quarter(t, {
          width: "abbreviated",
          context: "standalone"
        }) || r.quarter(t, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqqq":
        return r.quarter(t, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return r.quarter(t, {
          width: "wide",
          context: "standalone"
        }) || r.quarter(t, {
          width: "abbreviated",
          context: "standalone"
        }) || r.quarter(t, {
          width: "narrow",
          context: "standalone"
        });
    }
  }
  validate(t, n) {
    return n >= 1 && n <= 4;
  }
  set(t, n, r) {
    return t.setMonth((r - 1) * 3, 1), t.setHours(0, 0, 0, 0), t;
  }
}
class xp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "L",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
    ve(this, "priority", 110);
  }
  parse(t, n, r) {
    const i = (l) => l - 1;
    switch (n) {
      case "M":
        return dt(
          et(ct.month, t),
          i
        );
      case "MM":
        return dt(nt(2, t), i);
      case "Mo":
        return dt(
          r.ordinalNumber(t, {
            unit: "month"
          }),
          i
        );
      case "MMM":
        return r.month(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.month(t, { width: "narrow", context: "formatting" });
      case "MMMMM":
        return r.month(t, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return r.month(t, { width: "wide", context: "formatting" }) || r.month(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.month(t, { width: "narrow", context: "formatting" });
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 11;
  }
  set(t, n, r) {
    return t.setMonth(r, 1), t.setHours(0, 0, 0, 0), t;
  }
}
class kp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 110);
    ve(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "M",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    const i = (l) => l - 1;
    switch (n) {
      case "L":
        return dt(
          et(ct.month, t),
          i
        );
      case "LL":
        return dt(nt(2, t), i);
      case "Lo":
        return dt(
          r.ordinalNumber(t, {
            unit: "month"
          }),
          i
        );
      case "LLL":
        return r.month(t, {
          width: "abbreviated",
          context: "standalone"
        }) || r.month(t, { width: "narrow", context: "standalone" });
      case "LLLLL":
        return r.month(t, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return r.month(t, { width: "wide", context: "standalone" }) || r.month(t, {
          width: "abbreviated",
          context: "standalone"
        }) || r.month(t, { width: "narrow", context: "standalone" });
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 11;
  }
  set(t, n, r) {
    return t.setMonth(r, 1), t.setHours(0, 0, 0, 0), t;
  }
}
function _p(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in), r = Pi(n, t) - a;
  return n.setDate(n.getDate() - r * 7), Te(n, t == null ? void 0 : t.in);
}
class Mp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 100);
    ve(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "i",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    switch (n) {
      case "w":
        return et(ct.week, t);
      case "wo":
        return r.ordinalNumber(t, { unit: "week" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    return n >= 1 && n <= 53;
  }
  set(t, n, r, i) {
    return Kt(_p(t, r, i), i);
  }
}
function Op(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in), r = Si(n, t) - a;
  return n.setDate(n.getDate() - r * 7), n;
}
class Sp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 100);
    ve(this, "incompatibleTokens", [
      "y",
      "Y",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    switch (n) {
      case "I":
        return et(ct.week, t);
      case "Io":
        return r.ordinalNumber(t, { unit: "week" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    return n >= 1 && n <= 53;
  }
  set(t, n, r) {
    return vn(Op(t, r));
  }
}
const Tp = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], Pp = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
];
class Cp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 90);
    ve(this, "subPriority", 1);
    ve(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    switch (n) {
      case "d":
        return et(ct.date, t);
      case "do":
        return r.ordinalNumber(t, { unit: "date" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    const r = t.getFullYear(), i = Lu(r), l = t.getMonth();
    return i ? n >= 1 && n <= Pp[l] : n >= 1 && n <= Tp[l];
  }
  set(t, n, r) {
    return t.setDate(r), t.setHours(0, 0, 0, 0), t;
  }
}
class Ap extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 90);
    ve(this, "subpriority", 1);
    ve(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "E",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    switch (n) {
      case "D":
      case "DD":
        return et(ct.dayOfYear, t);
      case "Do":
        return r.ordinalNumber(t, { unit: "date" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    const r = t.getFullYear();
    return Lu(r) ? n >= 1 && n <= 366 : n >= 1 && n <= 365;
  }
  set(t, n, r) {
    return t.setMonth(0, r), t.setHours(0, 0, 0, 0), t;
  }
}
function Ai(e, a, t) {
  var c, f, v, g;
  const n = Ga(), r = (t == null ? void 0 : t.weekStartsOn) ?? ((f = (c = t == null ? void 0 : t.locale) == null ? void 0 : c.options) == null ? void 0 : f.weekStartsOn) ?? n.weekStartsOn ?? ((g = (v = n.locale) == null ? void 0 : v.options) == null ? void 0 : g.weekStartsOn) ?? 0, i = Te(e, t == null ? void 0 : t.in), l = i.getDay(), s = (a % 7 + 7) % 7, d = 7 - r, u = a < 0 || a > 6 ? a - (l + d) % 7 : (s + d) % 7 - (l + d) % 7;
  return Bt(i, u, t);
}
class Dp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 90);
    ve(this, "incompatibleTokens", ["D", "i", "e", "c", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "E":
      case "EE":
      case "EEE":
        return r.day(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(t, { width: "short", context: "formatting" }) || r.day(t, { width: "narrow", context: "formatting" });
      case "EEEEE":
        return r.day(t, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return r.day(t, { width: "short", context: "formatting" }) || r.day(t, { width: "narrow", context: "formatting" });
      case "EEEE":
      default:
        return r.day(t, { width: "wide", context: "formatting" }) || r.day(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(t, { width: "short", context: "formatting" }) || r.day(t, { width: "narrow", context: "formatting" });
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 6;
  }
  set(t, n, r, i) {
    return t = Ai(t, r, i), t.setHours(0, 0, 0, 0), t;
  }
}
class Ip extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 90);
    ve(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "E",
      "i",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n, r, i) {
    const l = (o) => {
      const s = Math.floor((o - 1) / 7) * 7;
      return (o + i.weekStartsOn + 6) % 7 + s;
    };
    switch (n) {
      case "e":
      case "ee":
        return dt(nt(n.length, t), l);
      case "eo":
        return dt(
          r.ordinalNumber(t, {
            unit: "day"
          }),
          l
        );
      case "eee":
        return r.day(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(t, { width: "short", context: "formatting" }) || r.day(t, { width: "narrow", context: "formatting" });
      case "eeeee":
        return r.day(t, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return r.day(t, { width: "short", context: "formatting" }) || r.day(t, { width: "narrow", context: "formatting" });
      case "eeee":
      default:
        return r.day(t, { width: "wide", context: "formatting" }) || r.day(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(t, { width: "short", context: "formatting" }) || r.day(t, { width: "narrow", context: "formatting" });
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 6;
  }
  set(t, n, r, i) {
    return t = Ai(t, r, i), t.setHours(0, 0, 0, 0), t;
  }
}
class Ep extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 90);
    ve(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "E",
      "i",
      "e",
      "t",
      "T"
    ]);
  }
  parse(t, n, r, i) {
    const l = (o) => {
      const s = Math.floor((o - 1) / 7) * 7;
      return (o + i.weekStartsOn + 6) % 7 + s;
    };
    switch (n) {
      case "c":
      case "cc":
        return dt(nt(n.length, t), l);
      case "co":
        return dt(
          r.ordinalNumber(t, {
            unit: "day"
          }),
          l
        );
      case "ccc":
        return r.day(t, {
          width: "abbreviated",
          context: "standalone"
        }) || r.day(t, { width: "short", context: "standalone" }) || r.day(t, { width: "narrow", context: "standalone" });
      case "ccccc":
        return r.day(t, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return r.day(t, { width: "short", context: "standalone" }) || r.day(t, { width: "narrow", context: "standalone" });
      case "cccc":
      default:
        return r.day(t, { width: "wide", context: "standalone" }) || r.day(t, {
          width: "abbreviated",
          context: "standalone"
        }) || r.day(t, { width: "short", context: "standalone" }) || r.day(t, { width: "narrow", context: "standalone" });
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 6;
  }
  set(t, n, r, i) {
    return t = Ai(t, r, i), t.setHours(0, 0, 0, 0), t;
  }
}
function $p(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in), r = sp(n, t), i = a - r;
  return Bt(n, i, t);
}
class Rp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 90);
    ve(this, "incompatibleTokens", [
      "y",
      "Y",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "E",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(t, n, r) {
    const i = (l) => l === 0 ? 7 : l;
    switch (n) {
      case "i":
      case "ii":
        return nt(n.length, t);
      case "io":
        return r.ordinalNumber(t, { unit: "day" });
      case "iii":
        return dt(
          r.day(t, {
            width: "abbreviated",
            context: "formatting"
          }) || r.day(t, {
            width: "short",
            context: "formatting"
          }) || r.day(t, {
            width: "narrow",
            context: "formatting"
          }),
          i
        );
      case "iiiii":
        return dt(
          r.day(t, {
            width: "narrow",
            context: "formatting"
          }),
          i
        );
      case "iiiiii":
        return dt(
          r.day(t, {
            width: "short",
            context: "formatting"
          }) || r.day(t, {
            width: "narrow",
            context: "formatting"
          }),
          i
        );
      case "iiii":
      default:
        return dt(
          r.day(t, {
            width: "wide",
            context: "formatting"
          }) || r.day(t, {
            width: "abbreviated",
            context: "formatting"
          }) || r.day(t, {
            width: "short",
            context: "formatting"
          }) || r.day(t, {
            width: "narrow",
            context: "formatting"
          }),
          i
        );
    }
  }
  validate(t, n) {
    return n >= 1 && n <= 7;
  }
  set(t, n, r) {
    return t = $p(t, r), t.setHours(0, 0, 0, 0), t;
  }
}
class Np extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 80);
    ve(this, "incompatibleTokens", ["b", "B", "H", "k", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "a":
      case "aa":
      case "aaa":
        return r.dayPeriod(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaaa":
        return r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return r.dayPeriod(t, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(t, n, r) {
    return t.setHours(Ci(r), 0, 0, 0), t;
  }
}
class Lp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 80);
    ve(this, "incompatibleTokens", ["a", "B", "H", "k", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "b":
      case "bb":
      case "bbb":
        return r.dayPeriod(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbbb":
        return r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return r.dayPeriod(t, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(t, n, r) {
    return t.setHours(Ci(r), 0, 0, 0), t;
  }
}
class Hp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 80);
    ve(this, "incompatibleTokens", ["a", "b", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "B":
      case "BB":
      case "BBB":
        return r.dayPeriod(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBBB":
        return r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return r.dayPeriod(t, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(t, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(t, n, r) {
    return t.setHours(Ci(r), 0, 0, 0), t;
  }
}
class zp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 70);
    ve(this, "incompatibleTokens", ["H", "K", "k", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "h":
        return et(ct.hour12h, t);
      case "ho":
        return r.ordinalNumber(t, { unit: "hour" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    return n >= 1 && n <= 12;
  }
  set(t, n, r) {
    const i = t.getHours() >= 12;
    return i && r < 12 ? t.setHours(r + 12, 0, 0, 0) : !i && r === 12 ? t.setHours(0, 0, 0, 0) : t.setHours(r, 0, 0, 0), t;
  }
}
class Fp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 70);
    ve(this, "incompatibleTokens", ["a", "b", "h", "K", "k", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "H":
        return et(ct.hour23h, t);
      case "Ho":
        return r.ordinalNumber(t, { unit: "hour" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 23;
  }
  set(t, n, r) {
    return t.setHours(r, 0, 0, 0), t;
  }
}
class Vp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 70);
    ve(this, "incompatibleTokens", ["h", "H", "k", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "K":
        return et(ct.hour11h, t);
      case "Ko":
        return r.ordinalNumber(t, { unit: "hour" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 11;
  }
  set(t, n, r) {
    return t.getHours() >= 12 && r < 12 ? t.setHours(r + 12, 0, 0, 0) : t.setHours(r, 0, 0, 0), t;
  }
}
class Yp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 70);
    ve(this, "incompatibleTokens", ["a", "b", "h", "H", "K", "t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "k":
        return et(ct.hour24h, t);
      case "ko":
        return r.ordinalNumber(t, { unit: "hour" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    return n >= 1 && n <= 24;
  }
  set(t, n, r) {
    const i = r <= 24 ? r % 24 : r;
    return t.setHours(i, 0, 0, 0), t;
  }
}
class Bp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 60);
    ve(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "m":
        return et(ct.minute, t);
      case "mo":
        return r.ordinalNumber(t, { unit: "minute" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 59;
  }
  set(t, n, r) {
    return t.setMinutes(r, 0, 0), t;
  }
}
class jp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 50);
    ve(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(t, n, r) {
    switch (n) {
      case "s":
        return et(ct.second, t);
      case "so":
        return r.ordinalNumber(t, { unit: "second" });
      default:
        return nt(n.length, t);
    }
  }
  validate(t, n) {
    return n >= 0 && n <= 59;
  }
  set(t, n, r) {
    return t.setSeconds(r, 0), t;
  }
}
class Up extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 30);
    ve(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(t, n) {
    const r = (i) => Math.trunc(i * Math.pow(10, -n.length + 3));
    return dt(nt(n.length, t), r);
  }
  set(t, n, r) {
    return t.setMilliseconds(r), t;
  }
}
class Wp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 10);
    ve(this, "incompatibleTokens", ["t", "T", "x"]);
  }
  parse(t, n) {
    switch (n) {
      case "X":
        return ea(
          Zt.basicOptionalMinutes,
          t
        );
      case "XX":
        return ea(Zt.basic, t);
      case "XXXX":
        return ea(
          Zt.basicOptionalSeconds,
          t
        );
      case "XXXXX":
        return ea(
          Zt.extendedOptionalSeconds,
          t
        );
      case "XXX":
      default:
        return ea(Zt.extended, t);
    }
  }
  set(t, n, r) {
    return n.timestampIsSet ? t : Be(
      t,
      t.getTime() - Tr(t) - r
    );
  }
}
class qp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 10);
    ve(this, "incompatibleTokens", ["t", "T", "X"]);
  }
  parse(t, n) {
    switch (n) {
      case "x":
        return ea(
          Zt.basicOptionalMinutes,
          t
        );
      case "xx":
        return ea(Zt.basic, t);
      case "xxxx":
        return ea(
          Zt.basicOptionalSeconds,
          t
        );
      case "xxxxx":
        return ea(
          Zt.extendedOptionalSeconds,
          t
        );
      case "xxx":
      default:
        return ea(Zt.extended, t);
    }
  }
  set(t, n, r) {
    return n.timestampIsSet ? t : Be(
      t,
      t.getTime() - Tr(t) - r
    );
  }
}
class Kp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 40);
    ve(this, "incompatibleTokens", "*");
  }
  parse(t) {
    return Ru(t);
  }
  set(t, n, r) {
    return [Be(t, r * 1e3), { timestampIsSet: !0 }];
  }
}
class Xp extends Ve {
  constructor() {
    super(...arguments);
    ve(this, "priority", 20);
    ve(this, "incompatibleTokens", "*");
  }
  parse(t) {
    return Ru(t);
  }
  set(t, n, r) {
    return [Be(t, r), { timestampIsSet: !0 }];
  }
}
const Qp = {
  G: new mp(),
  y: new pp(),
  Y: new hp(),
  R: new yp(),
  u: new gp(),
  Q: new bp(),
  q: new wp(),
  M: new xp(),
  L: new kp(),
  w: new Mp(),
  I: new Sp(),
  d: new Cp(),
  D: new Ap(),
  E: new Dp(),
  e: new Ip(),
  c: new Ep(),
  i: new Rp(),
  a: new Np(),
  b: new Lp(),
  B: new Hp(),
  h: new zp(),
  H: new Fp(),
  K: new Vp(),
  k: new Yp(),
  m: new Bp(),
  s: new jp(),
  S: new Up(),
  X: new Wp(),
  x: new qp(),
  t: new Kp(),
  T: new Xp()
}, Gp = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, Jp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, Zp = /^'([^]*?)'?$/, e2 = /''/g, t2 = /\S/, a2 = /[a-zA-Z]/;
function ql(e, a, t, n) {
  var C, p, w, y, x, D, S, $;
  const r = () => Be((n == null ? void 0 : n.in) || t, NaN), i = op(), l = (n == null ? void 0 : n.locale) ?? i.locale ?? Au, o = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((p = (C = n == null ? void 0 : n.locale) == null ? void 0 : C.options) == null ? void 0 : p.firstWeekContainsDate) ?? i.firstWeekContainsDate ?? ((y = (w = i.locale) == null ? void 0 : w.options) == null ? void 0 : y.firstWeekContainsDate) ?? 1, s = (n == null ? void 0 : n.weekStartsOn) ?? ((D = (x = n == null ? void 0 : n.locale) == null ? void 0 : x.options) == null ? void 0 : D.weekStartsOn) ?? i.weekStartsOn ?? (($ = (S = i.locale) == null ? void 0 : S.options) == null ? void 0 : $.weekStartsOn) ?? 0;
  if (!a)
    return e ? r() : Te(t, n == null ? void 0 : n.in);
  const d = {
    firstWeekContainsDate: o,
    weekStartsOn: s,
    locale: l
  }, u = [new vp(n == null ? void 0 : n.in, t)], c = a.match(Jp).map((O) => {
    const N = O[0];
    if (N in Ul) {
      const M = Ul[N];
      return M(O, l.formatLong);
    }
    return O;
  }).join("").match(Gp), f = [];
  for (let O of c) {
    !(n != null && n.useAdditionalWeekYearTokens) && Eu(O) && Wl(O, a, e), !(n != null && n.useAdditionalDayOfYearTokens) && Iu(O) && Wl(O, a, e);
    const N = O[0], M = Qp[N];
    if (M) {
      const { incompatibleTokens: B } = M;
      if (Array.isArray(B)) {
        const te = f.find(
          (T) => B.includes(T.token) || T.token === N
        );
        if (te)
          throw new RangeError(
            `The format string mustn't contain \`${te.fullToken}\` and \`${O}\` at the same time`
          );
      } else if (M.incompatibleTokens === "*" && f.length > 0)
        throw new RangeError(
          `The format string mustn't contain \`${O}\` and any other token at the same time`
        );
      f.push({ token: N, fullToken: O });
      const X = M.run(
        e,
        O,
        l.match,
        d
      );
      if (!X)
        return r();
      u.push(X.setter), e = X.rest;
    } else {
      if (N.match(a2))
        throw new RangeError(
          "Format string contains an unescaped latin alphabet character `" + N + "`"
        );
      if (O === "''" ? O = "'" : N === "'" && (O = n2(O)), e.indexOf(O) === 0)
        e = e.slice(O.length);
      else
        return r();
    }
  }
  if (e.length > 0 && t2.test(e))
    return r();
  const v = u.map((O) => O.priority).sort((O, N) => N - O).filter((O, N, M) => M.indexOf(O) === N).map(
    (O) => u.filter((N) => N.priority === O).sort((N, M) => M.subPriority - N.subPriority)
  ).map((O) => O[0]);
  let g = Te(t, n == null ? void 0 : n.in);
  if (isNaN(+g))
    return r();
  const b = {};
  for (const O of v) {
    if (!O.validate(g, d))
      return r();
    const N = O.set(g, b, d);
    Array.isArray(N) ? (g = N[0], Object.assign(b, N[1])) : g = N;
  }
  return g;
}
function n2(e) {
  return e.match(Zp)[1].replace(e2, "'");
}
function zo(e, a, t) {
  const [n, r] = Kn(
    t == null ? void 0 : t.in,
    e,
    a
  );
  return +Fa(n) == +Fa(r);
}
function r2(e, a, t) {
  return Bt(e, -a, t);
}
function Hu(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in), r = n.getFullYear(), i = n.getDate(), l = Be((t == null ? void 0 : t.in) || e, 0);
  l.setFullYear(r, a, 15), l.setHours(0, 0, 0, 0);
  const o = ip(l);
  return n.setMonth(a, Math.min(i, o)), n;
}
function je(e, a, t) {
  let n = Te(e, t == null ? void 0 : t.in);
  return isNaN(+n) ? Be((t == null ? void 0 : t.in) || e, NaN) : (a.year != null && n.setFullYear(a.year), a.month != null && (n = Hu(n, a.month)), a.date != null && n.setDate(a.date), a.hours != null && n.setHours(a.hours), a.minutes != null && n.setMinutes(a.minutes), a.seconds != null && n.setSeconds(a.seconds), a.milliseconds != null && n.setMilliseconds(a.milliseconds), n);
}
function l2(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in);
  return n.setHours(a), n;
}
function zu(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in);
  return n.setMilliseconds(a), n;
}
function i2(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in);
  return n.setMinutes(a), n;
}
function Fu(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in);
  return n.setSeconds(a), n;
}
function aa(e, a, t) {
  const n = Te(e, t == null ? void 0 : t.in);
  return isNaN(+n) ? Be((t == null ? void 0 : t.in) || e, NaN) : (n.setFullYear(a), n);
}
function hn(e, a, t) {
  return Wt(e, -a, t);
}
function o2(e, a, t) {
  const {
    years: n = 0,
    months: r = 0,
    weeks: i = 0,
    days: l = 0,
    hours: o = 0,
    minutes: s = 0,
    seconds: d = 0
  } = a, u = hn(e, r + n * 12, t), c = r2(u, l + i * 7, t), f = s + o * 60, g = (d + f * 60) * 1e3;
  return Be((t == null ? void 0 : t.in) || e, +c - g);
}
function Vu(e, a, t) {
  return Oi(e, -a, t);
}
function wn() {
  const e = Zo();
  return P(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img",
      ...e
    },
    [
      ie("path", {
        d: "M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z"
      }),
      ie("path", {
        d: "M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      }),
      ie("path", {
        d: "M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      }),
      ie("path", {
        d: "M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"
      })
    ]
  );
}
wn.compatConfig = {
  MODE: 3
};
function Yu() {
  return P(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ie("path", {
        d: "M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
      }),
      ie("path", {
        d: "M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
      })
    ]
  );
}
Yu.compatConfig = {
  MODE: 3
};
function Di() {
  return P(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ie("path", {
        d: "M20.943 23.057l-7.057-7.057c0 0 7.057-7.057 7.057-7.057 0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-8 8c-0.521 0.521-0.521 1.365 0 1.885l8 8c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z"
      })
    ]
  );
}
Di.compatConfig = {
  MODE: 3
};
function Ii() {
  return P(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ie("path", {
        d: "M12.943 24.943l8-8c0.521-0.521 0.521-1.365 0-1.885l-8-8c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885l7.057 7.057c0 0-7.057 7.057-7.057 7.057-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0z"
      })
    ]
  );
}
Ii.compatConfig = {
  MODE: 3
};
function Ei() {
  return P(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ie("path", {
        d: "M16 1.333c-8.095 0-14.667 6.572-14.667 14.667s6.572 14.667 14.667 14.667c8.095 0 14.667-6.572 14.667-14.667s-6.572-14.667-14.667-14.667zM16 4c6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.623 0-12-5.377-12-12s5.377-12 12-12z"
      }),
      ie("path", {
        d: "M14.667 8v8c0 0.505 0.285 0.967 0.737 1.193l5.333 2.667c0.658 0.329 1.46 0.062 1.789-0.596s0.062-1.46-0.596-1.789l-4.596-2.298c0 0 0-7.176 0-7.176 0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      })
    ]
  );
}
Ei.compatConfig = {
  MODE: 3
};
function $i() {
  return P(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ie("path", {
        d: "M24.943 19.057l-8-8c-0.521-0.521-1.365-0.521-1.885 0l-8 8c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l7.057-7.057c0 0 7.057 7.057 7.057 7.057 0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z"
      })
    ]
  );
}
$i.compatConfig = {
  MODE: 3
};
function Ri() {
  return P(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ie("path", {
        d: "M7.057 12.943l8 8c0.521 0.521 1.365 0.521 1.885 0l8-8c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-7.057 7.057c0 0-7.057-7.057-7.057-7.057-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
      })
    ]
  );
}
Ri.compatConfig = {
  MODE: 3
};
const Ct = (e, a) => a ? new Date(e.toLocaleString("en-US", { timeZone: a })) : new Date(e), Ni = (e, a, t) => Kl(e, a, t) || ee(), s2 = (e, a, t) => {
  const n = a.dateInTz ? Ct(new Date(e), a.dateInTz) : ee(e);
  return t ? St(n, !0) : n;
}, Kl = (e, a, t) => {
  if (!e)
    return null;
  const n = t ? St(ee(e), !0) : ee(e);
  return a ? a.exactMatch ? s2(e, a, t) : Ct(n, a.timezone) : n;
}, u2 = (e, a) => {
  if (!e)
    return 0;
  const t = /* @__PURE__ */ new Date(), n = new Date(t.toLocaleString("en-US", { timeZone: "UTC" })), r = new Date(t.toLocaleString("en-US", { timeZone: e })), i = (a ?? r).getTimezoneOffset() / 60;
  return (+n - +r) / (1e3 * 60 * 60) - i;
};
var Ft = /* @__PURE__ */ ((e) => (e.month = "month", e.year = "year", e))(Ft || {}), Vt = /* @__PURE__ */ ((e) => (e.top = "top", e.bottom = "bottom", e))(Vt || {}), Va = /* @__PURE__ */ ((e) => (e.header = "header", e.calendar = "calendar", e.timePicker = "timePicker", e))(Va || {}), xt = /* @__PURE__ */ ((e) => (e.month = "month", e.year = "year", e.calendar = "calendar", e.time = "time", e.minutes = "minutes", e.hours = "hours", e.seconds = "seconds", e))(xt || {});
const c2 = ["timestamp", "date", "iso"];
var Mt = /* @__PURE__ */ ((e) => (e.up = "up", e.down = "down", e.left = "left", e.right = "right", e))(Mt || {}), qe = /* @__PURE__ */ ((e) => (e.arrowUp = "ArrowUp", e.arrowDown = "ArrowDown", e.arrowLeft = "ArrowLeft", e.arrowRight = "ArrowRight", e.enter = "Enter", e.space = " ", e.esc = "Escape", e.tab = "Tab", e.home = "Home", e.end = "End", e.pageUp = "PageUp", e.pageDown = "PageDown", e))(qe || {});
function Fo(e) {
  return (a) => new Intl.DateTimeFormat(e, { weekday: "short", timeZone: "UTC" }).format(/* @__PURE__ */ new Date(`2017-01-0${a}T00:00:00+00:00`)).slice(0, 2);
}
function d2(e) {
  return (a) => na(Ct(/* @__PURE__ */ new Date(`2017-01-0${a}T00:00:00+00:00`), "UTC"), "EEEEEE", { locale: e });
}
const f2 = (e, a, t) => {
  const n = [1, 2, 3, 4, 5, 6, 7];
  let r;
  if (e !== null)
    try {
      r = n.map(d2(e));
    } catch {
      r = n.map(Fo(a));
    }
  else
    r = n.map(Fo(a));
  const i = r.slice(0, t), l = r.slice(t + 1, r.length);
  return [r[t]].concat(...l).concat(...i);
}, Li = (e, a, t) => {
  const n = [];
  for (let r = +e[0]; r <= +e[1]; r++)
    n.push({ value: +r, text: Wu(r, a) });
  return t ? n.reverse() : n;
}, Bu = (e, a, t) => {
  const n = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
    const l = i < 10 ? `0${i}` : i;
    return /* @__PURE__ */ new Date(`2017-${l}-01T00:00:00+00:00`);
  });
  if (e !== null)
    try {
      const i = t === "long" ? "LLLL" : "LLL";
      return n.map((l, o) => {
        const s = na(Ct(l, "UTC"), i, { locale: e });
        return {
          text: s.charAt(0).toUpperCase() + s.substring(1),
          value: o
        };
      });
    } catch {
    }
  const r = new Intl.DateTimeFormat(a, { month: t, timeZone: "UTC" });
  return n.map((i, l) => {
    const o = r.format(i);
    return {
      text: o.charAt(0).toUpperCase() + o.substring(1),
      value: l
    };
  });
}, v2 = (e) => [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11][e], ht = (e) => {
  const a = m(e);
  return a != null && a.$el ? a == null ? void 0 : a.$el : a;
}, m2 = (e) => ({ type: "dot", ...e ?? {} }), ju = (e) => Array.isArray(e) ? !!e[0] && !!e[1] : !1, Hi = {
  prop: (e) => `"${e}" prop must be enabled!`,
  dateArr: (e) => `You need to use array as "model-value" binding in order to support "${e}"`
}, gt = (e) => e, Vo = (e) => e === 0 ? e : !e || isNaN(+e) ? null : +e, Yo = (e) => e === null, Uu = (e) => {
  if (e)
    return [...e.querySelectorAll("input, button, select, textarea, a[href]")][0];
}, p2 = (e) => {
  const a = [], t = (n) => n.filter((r) => r);
  for (let n = 0; n < e.length; n += 3) {
    const r = [e[n], e[n + 1], e[n + 2]];
    a.push(t(r));
  }
  return a;
}, Vn = (e, a, t) => {
  const n = t != null, r = a != null;
  if (!n && !r)
    return !1;
  const i = +t, l = +a;
  return n && r ? +e > i || +e < l : n ? +e > i : r ? +e < l : !1;
}, yn = (e, a) => p2(e).map((t) => t.map((n) => {
  const { active: r, disabled: i, isBetween: l, highlighted: o } = a(n);
  return {
    ...n,
    active: r,
    disabled: i,
    className: {
      dp__overlay_cell_active: r,
      dp__overlay_cell: !r,
      dp__overlay_cell_disabled: i,
      dp__overlay_cell_pad: !0,
      dp__overlay_cell_active_disabled: i && r,
      dp__cell_in_between: l,
      "dp--highlighted": o
    }
  };
})), _a = (e, a, t = !1) => {
  e && a.allowStopPropagation && (t && e.stopImmediatePropagation(), e.stopPropagation());
}, h2 = () => [
  "a[href]",
  "area[href]",
  "input:not([disabled]):not([type='hidden'])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "[tabindex]:not([tabindex='-1'])",
  "[data-datepicker-instance]"
].join(", ");
function y2(e, a) {
  let t = [...document.querySelectorAll(h2())];
  t = t.filter((r) => !e.contains(r) || r.hasAttribute("data-datepicker-instance"));
  const n = t.indexOf(e);
  if (n >= 0 && (a ? n - 1 >= 0 : n + 1 <= t.length))
    return t[n + (a ? -1 : 1)];
}
const Xl = (e, a) => e == null ? void 0 : e.querySelector(`[data-dp-element="${a}"]`), Wu = (e, a) => new Intl.NumberFormat(a, { useGrouping: !1, style: "decimal" }).format(e), zi = (e) => na(e, "dd-MM-yyyy"), pl = (e) => Array.isArray(e), Cr = (e, a) => a.get(zi(e)), g2 = (e, a) => e ? a ? a instanceof Map ? !!Cr(e, a) : a(ee(e)) : !1 : !0, Ot = (e, a, t = !1, n) => {
  if (e.key === qe.enter || e.key === qe.space)
    return t && e.preventDefault(), a();
  if (n)
    return n(e);
}, Bo = () => ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].some(
  (e) => navigator.userAgent.includes(e)
) || navigator.userAgent.includes("Mac") && "ontouchend" in document, jo = (e, a, t, n, r, i) => {
  const l = ql(e, a.slice(0, e.length), /* @__PURE__ */ new Date(), { locale: i });
  return Cn(l) && Ou(l) ? n || r ? l : je(l, {
    hours: +t.hours,
    minutes: +(t == null ? void 0 : t.minutes),
    seconds: +(t == null ? void 0 : t.seconds),
    milliseconds: 0
  }) : null;
}, b2 = (e, a, t, n, r, i) => {
  const l = Array.isArray(t) ? t[0] : t;
  if (typeof a == "string")
    return jo(e, a, l, n, r, i);
  if (Array.isArray(a)) {
    let o = null;
    for (const s of a)
      if (o = jo(e, s, l, n, r, i), o)
        break;
    return o;
  }
  return typeof a == "function" ? a(e) : null;
}, ee = (e) => e ? new Date(e) : /* @__PURE__ */ new Date(), w2 = (e, a, t) => {
  if (a) {
    const r = (e.getMonth() + 1).toString().padStart(2, "0"), i = e.getDate().toString().padStart(2, "0"), l = e.getHours().toString().padStart(2, "0"), o = e.getMinutes().toString().padStart(2, "0"), s = t ? e.getSeconds().toString().padStart(2, "0") : "00";
    return `${e.getFullYear()}-${r}-${i}T${l}:${o}:${s}.000Z`;
  }
  const n = Date.UTC(
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate(),
    e.getUTCHours(),
    e.getUTCMinutes(),
    e.getUTCSeconds()
  );
  return new Date(n).toISOString();
}, St = (e, a) => {
  const t = ee(JSON.parse(JSON.stringify(e))), n = je(t, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  return a ? vm(n) : n;
}, Ma = (e, a, t, n) => {
  let r = e ? ee(e) : ee();
  return (a || a === 0) && (r = l2(r, +a)), (t || t === 0) && (r = i2(r, +t)), (n || n === 0) && (r = Fu(r, +n)), zu(r, 0);
}, tt = (e, a) => !e || !a ? !1 : Fn(St(e), St(a)), Re = (e, a) => !e || !a ? !1 : ln(St(e), St(a)), ot = (e, a) => !e || !a ? !1 : pn(St(e), St(a)), Kr = (e, a, t) => e != null && e[0] && e != null && e[1] ? ot(t, e[0]) && tt(t, e[1]) : e != null && e[0] && a ? ot(t, e[0]) && tt(t, a) || tt(t, e[0]) && ot(t, a) : !1, jt = (e) => {
  const a = je(new Date(e), { date: 1 });
  return St(a);
}, hl = (e, a, t) => a && (t || t === 0) ? Object.fromEntries(
  ["hours", "minutes", "seconds"].map((n) => n === a ? [n, t] : [n, isNaN(+e[n]) ? void 0 : +e[n]])
) : {
  hours: isNaN(+e.hours) ? void 0 : +e.hours,
  minutes: isNaN(+e.minutes) ? void 0 : +e.minutes,
  seconds: isNaN(+e.seconds) ? void 0 : +e.seconds
}, Ya = (e) => ({
  hours: va(e),
  minutes: Aa(e),
  seconds: mn(e)
}), qu = (e, a) => {
  if (a) {
    const t = Pe(ee(a));
    if (t > e)
      return 12;
    if (t === e)
      return Ee(ee(a));
  }
}, Ku = (e, a) => {
  if (a) {
    const t = Pe(ee(a));
    return t < e ? -1 : t === e ? Ee(ee(a)) : void 0;
  }
}, gn = (e) => {
  if (e)
    return Pe(ee(e));
}, Xu = (e, a) => {
  const t = ot(e, a) ? a : e, n = ot(a, e) ? a : e;
  return Tu({ start: t, end: n });
}, x2 = (e) => {
  const a = Wt(e, 1);
  return { month: Ee(a), year: Pe(a) };
}, oa = (e, a) => {
  const t = Kt(e, { weekStartsOn: +a }), n = Cu(e, { weekStartsOn: +a });
  return [t, n];
}, Qu = (e, a) => {
  const t = {
    hours: va(ee()),
    minutes: Aa(ee()),
    seconds: a ? mn(ee()) : 0
  };
  return Object.assign(t, e);
}, xa = (e, a, t) => [je(ee(e), { date: 1 }), je(ee(), { month: a, year: t, date: 1 })], sa = (e, a, t) => {
  let n = e ? ee(e) : ee();
  return (a || a === 0) && (n = Hu(n, a)), t && (n = aa(n, t)), n;
}, Gu = (e, a, t, n, r) => {
  if (!n || r && !a || !r && !t)
    return !1;
  const i = r ? Wt(e, 1) : hn(e, 1), l = [Ee(i), Pe(i)];
  return r ? !_2(...l, a) : !k2(...l, t);
}, k2 = (e, a, t) => tt(...xa(t, e, a)) || Re(...xa(t, e, a)), _2 = (e, a, t) => ot(...xa(t, e, a)) || Re(...xa(t, e, a)), Ju = (e, a, t, n, r, i, l) => {
  if (typeof a == "function" && !l)
    return a(e);
  const o = t ? { locale: t } : void 0;
  return Array.isArray(e) ? `${na(e[0], i, o)}${r && !e[1] ? "" : n}${e[1] ? na(e[1], i, o) : ""}` : na(e, i, o);
}, tn = (e) => {
  if (e)
    return null;
  throw new Error(Hi.prop("partial-range"));
}, dr = (e, a) => {
  if (a)
    return e();
  throw new Error(Hi.prop("range"));
}, Ql = (e) => Array.isArray(e) ? Cn(e[0]) && (e[1] ? Cn(e[1]) : !0) : e ? Cn(e) : !1, M2 = (e, a) => je(a ?? ee(), {
  hours: +e.hours || 0,
  minutes: +e.minutes || 0,
  seconds: +e.seconds || 0
}), yl = (e, a, t, n) => {
  if (!e)
    return !0;
  if (n) {
    const r = t === "max" ? Fn(e, a) : pn(e, a), i = { seconds: 0, milliseconds: 0 };
    return r || ln(je(e, i), je(a, i));
  }
  return t === "max" ? e.getTime() <= a.getTime() : e.getTime() >= a.getTime();
}, gl = (e, a, t) => e ? M2(e, a) : ee(t ?? a), Uo = (e, a, t, n, r) => {
  if (Array.isArray(n)) {
    const l = gl(e, n[0], a), o = gl(e, n[1], a);
    return yl(n[0], l, t, !!a) && yl(n[1], o, t, !!a) && r;
  }
  const i = gl(e, n, a);
  return yl(n, i, t, !!a) && r;
}, bl = (e) => je(ee(), Ya(e)), O2 = (e, a) => e instanceof Map ? Array.from(e.values()).filter((t) => Pe(ee(t)) === a).map((t) => Ee(t)) : [], S2 = (e, a, t) => {
  if (e instanceof Map) {
    const n = Array.from(e.values()).filter((r) => Pe(ee(r)) === a).map((r) => Ee(r));
    return n.length ? n.includes(t) : !0;
  }
  return !0;
}, Zu = (e, a, t) => typeof e == "function" ? e({ month: a, year: t }) : !!e.months.find((n) => n.month === a && n.year === t), Fi = (e, a) => typeof e == "function" ? e(a) : e.years.includes(a), ec = (e) => na(e, "yyyy-MM-dd"), Mn = qa({
  menuFocused: !1,
  shiftKeyInMenu: !1
}), tc = () => {
  const e = (t) => {
    Mn.menuFocused = t;
  }, a = (t) => {
    Mn.shiftKeyInMenu !== t && (Mn.shiftKeyInMenu = t);
  };
  return {
    control: A(() => ({ shiftKeyInMenu: Mn.shiftKeyInMenu, menuFocused: Mn.menuFocused })),
    setMenuFocused: e,
    setShiftKey: a
  };
}, We = qa({
  monthYear: [],
  calendar: [],
  time: [],
  actionRow: [],
  selectionGrid: [],
  timePicker: {
    0: [],
    1: []
  },
  monthPicker: []
}), wl = z(null), fr = z(!1), xl = z(!1), kl = z(!1), _l = z(!1), wt = z(0), it = z(0), Ea = () => {
  const e = A(() => fr.value ? [...We.selectionGrid, We.actionRow].filter((c) => c.length) : xl.value ? [
    ...We.timePicker[0],
    ...We.timePicker[1],
    _l.value ? [] : [wl.value],
    We.actionRow
  ].filter((c) => c.length) : kl.value ? [...We.monthPicker, We.actionRow] : [We.monthYear, ...We.calendar, We.time, We.actionRow].filter((c) => c.length)), a = (c) => {
    wt.value = c ? wt.value + 1 : wt.value - 1;
    let f = null;
    e.value[it.value] && (f = e.value[it.value][wt.value]), !f && e.value[it.value + (c ? 1 : -1)] ? (it.value = it.value + (c ? 1 : -1), wt.value = c ? 0 : e.value[it.value].length - 1) : f || (wt.value = c ? wt.value - 1 : wt.value + 1);
  }, t = (c) => {
    it.value === 0 && !c || it.value === e.value.length && c || (it.value = c ? it.value + 1 : it.value - 1, e.value[it.value] ? e.value[it.value] && !e.value[it.value][wt.value] && wt.value !== 0 && (wt.value = e.value[it.value].length - 1) : it.value = c ? it.value - 1 : it.value + 1);
  }, n = (c) => {
    let f = null;
    e.value[it.value] && (f = e.value[it.value][wt.value]), f ? f.focus({ preventScroll: !fr.value }) : wt.value = c ? wt.value - 1 : wt.value + 1;
  }, r = () => {
    a(!0), n(!0);
  }, i = () => {
    a(!1), n(!1);
  }, l = () => {
    t(!1), n(!0);
  }, o = () => {
    t(!0), n(!0);
  }, s = (c, f) => {
    We[f] = c;
  }, d = (c, f) => {
    We[f] = c;
  }, u = () => {
    wt.value = 0, it.value = 0;
  };
  return {
    buildMatrix: s,
    buildMultiLevelMatrix: d,
    setTimePickerBackRef: (c) => {
      wl.value = c;
    },
    setSelectionGrid: (c) => {
      fr.value = c, u(), c || (We.selectionGrid = []);
    },
    setTimePicker: (c, f = !1) => {
      xl.value = c, _l.value = f, u(), c || (We.timePicker[0] = [], We.timePicker[1] = []);
    },
    setTimePickerElements: (c, f = 0) => {
      We.timePicker[f] = c;
    },
    arrowRight: r,
    arrowLeft: i,
    arrowUp: l,
    arrowDown: o,
    clearArrowNav: () => {
      We.monthYear = [], We.calendar = [], We.time = [], We.actionRow = [], We.selectionGrid = [], We.timePicker[0] = [], We.timePicker[1] = [], fr.value = !1, xl.value = !1, _l.value = !1, kl.value = !1, u(), wl.value = null;
    },
    setMonthPicker: (c) => {
      kl.value = c, u();
    },
    refSets: We
    // exposed for testing
  };
}, Wo = (e) => ({
  menuAppearTop: "dp-menu-appear-top",
  menuAppearBottom: "dp-menu-appear-bottom",
  open: "dp-slide-down",
  close: "dp-slide-up",
  next: "calendar-next",
  previous: "calendar-prev",
  vNext: "dp-slide-up",
  vPrevious: "dp-slide-down",
  ...e ?? {}
}), T2 = (e) => ({
  toggleOverlay: "Toggle overlay",
  menu: "Datepicker menu",
  input: "Datepicker input",
  openTimePicker: "Open time picker",
  closeTimePicker: "Close time Picker",
  incrementValue: (a) => `Increment ${a}`,
  decrementValue: (a) => `Decrement ${a}`,
  openTpOverlay: (a) => `Open ${a} overlay`,
  amPmButton: "Switch AM/PM mode",
  openYearsOverlay: "Open years overlay",
  openMonthsOverlay: "Open months overlay",
  nextMonth: "Next month",
  prevMonth: "Previous month",
  nextYear: "Next year",
  prevYear: "Previous year",
  day: void 0,
  weekDay: void 0,
  clearInput: "Clear value",
  calendarIcon: "Calendar icon",
  timePicker: "Time picker",
  monthPicker: (a) => `Month picker${a ? " overlay" : ""}`,
  yearPicker: (a) => `Year picker${a ? " overlay" : ""}`,
  timeOverlay: (a) => `${a} overlay`,
  ...e ?? {}
}), qo = (e) => e ? typeof e == "boolean" ? e ? 2 : 0 : +e >= 2 ? +e : 2 : 0, P2 = (e) => {
  const a = typeof e == "object" && e, t = {
    static: !0,
    solo: !1
  };
  if (!e)
    return { ...t, count: qo(!1) };
  const n = a ? e : {}, r = a ? n.count ?? !0 : e, i = qo(r);
  return Object.assign(t, n, { count: i });
}, C2 = (e, a, t) => e || (typeof t == "string" ? t : a), A2 = (e) => typeof e == "boolean" ? e ? Wo({}) : !1 : Wo(e), D2 = (e) => {
  const a = {
    enterSubmit: !0,
    tabSubmit: !0,
    openMenu: "open",
    selectOnFocus: !1,
    rangeSeparator: " - "
  };
  return typeof e == "object" ? { ...a, ...e ?? {}, enabled: !0 } : { ...a, enabled: e };
}, I2 = (e) => ({
  months: [],
  years: [],
  times: { hours: [], minutes: [], seconds: [] },
  ...e ?? {}
}), E2 = (e) => ({
  showSelect: !0,
  showCancel: !0,
  showNow: !1,
  showPreview: !0,
  ...e ?? {}
}), $2 = (e) => {
  const a = { input: !1 };
  return typeof e == "object" ? { ...a, ...e ?? {}, enabled: !0 } : {
    enabled: e,
    ...a
  };
}, R2 = (e) => ({ allowStopPropagation: !0, closeOnScroll: !1, modeHeight: 255, allowPreventDefault: !1, closeOnClearValue: !0, closeOnAutoApply: !0, noSwipe: !1, keepActionRow: !1, onClickOutside: void 0, tabOutClosesMenu: !0, arrowLeft: void 0, keepViewOnOffsetClick: !1, timeArrowHoldThreshold: 0, shadowDom: !1, mobileBreakpoint: 600, setDateOnMenuClose: !1, ...e ?? {} }), N2 = (e) => {
  const a = {
    dates: Array.isArray(e) ? e.map((t) => ee(t)) : [],
    years: [],
    months: [],
    quarters: [],
    weeks: [],
    weekdays: [],
    options: { highlightDisabled: !1 }
  };
  return typeof e == "function" ? e : { ...a, ...e ?? {} };
}, L2 = (e) => typeof e == "object" ? {
  type: (e == null ? void 0 : e.type) ?? "local",
  hideOnOffsetDates: (e == null ? void 0 : e.hideOnOffsetDates) ?? !1
} : {
  type: e,
  hideOnOffsetDates: !1
}, H2 = (e) => {
  const a = {
    noDisabledRange: !1,
    showLastInRange: !0,
    minMaxRawRange: !1,
    partialRange: !0,
    disableTimeRangeValidation: !1,
    maxRange: void 0,
    minRange: void 0,
    autoRange: void 0,
    fixedStart: !1,
    fixedEnd: !1
  };
  return typeof e == "object" ? { enabled: !0, ...a, ...e } : {
    enabled: e,
    ...a
  };
}, z2 = (e) => e ? typeof e == "string" ? {
  timezone: e,
  exactMatch: !1,
  dateInTz: void 0,
  emitTimezone: void 0,
  convertModel: !0
} : {
  timezone: e.timezone,
  exactMatch: e.exactMatch ?? !1,
  dateInTz: e.dateInTz ?? void 0,
  emitTimezone: e.emitTimezone ?? void 0,
  convertModel: e.convertModel ?? !0
} : { timezone: void 0, exactMatch: !1, emitTimezone: void 0 }, Ml = (e, a, t) => new Map(
  e.map((n) => {
    const r = Ni(n, a, t);
    return [zi(r), r];
  })
), F2 = (e, a) => e.length ? new Map(
  e.map((t) => {
    const n = Ni(t.date, a);
    return [zi(n), t];
  })
) : null, V2 = (e) => {
  var a;
  return {
    minDate: Kl(e.minDate, e.timezone, e.isSpecific),
    maxDate: Kl(e.maxDate, e.timezone, e.isSpecific),
    disabledDates: pl(e.disabledDates) ? Ml(e.disabledDates, e.timezone, e.isSpecific) : e.disabledDates,
    allowedDates: pl(e.allowedDates) ? Ml(e.allowedDates, e.timezone, e.isSpecific) : null,
    highlight: typeof e.highlight == "object" && pl((a = e.highlight) == null ? void 0 : a.dates) ? Ml(e.highlight.dates, e.timezone) : e.highlight,
    markers: F2(e.markers, e.timezone)
  };
}, Y2 = (e) => typeof e == "boolean" ? { enabled: e, dragSelect: !0, limit: null } : {
  enabled: !!e,
  limit: e.limit ? +e.limit : null,
  dragSelect: e.dragSelect ?? !0
}, B2 = (e) => ({
  ...Object.fromEntries(
    Object.keys(e).map((a) => {
      const t = a, n = e[t], r = typeof e[t] == "string" ? { [n]: !0 } : Object.fromEntries(n.map((i) => [i, !0]));
      return [a, r];
    })
  )
}), Je = (e) => {
  const a = () => {
    const S = e.enableSeconds ? ":ss" : "", $ = e.enableMinutes ? ":mm" : "";
    return e.is24 ? `HH${$}${S}` : `hh${$}${S} aa`;
  }, t = () => {
    var S;
    return e.format ? e.format : e.monthPicker ? "MM/yyyy" : e.timePicker ? a() : e.weekPicker ? `${((S = C.value) == null ? void 0 : S.type) === "iso" ? "RR" : "ww"}-yyyy` : e.yearPicker ? "yyyy" : e.quarterPicker ? "QQQ/yyyy" : e.enableTimePicker ? `MM/dd/yyyy, ${a()}` : "MM/dd/yyyy";
  }, n = (S) => Qu(S, e.enableSeconds), r = () => x.value.enabled ? e.startTime && Array.isArray(e.startTime) ? [n(e.startTime[0]), n(e.startTime[1])] : null : e.startTime && !Array.isArray(e.startTime) ? n(e.startTime) : null, i = A(() => P2(e.multiCalendars)), l = A(() => r()), o = A(() => T2(e.ariaLabels)), s = A(() => I2(e.filters)), d = A(() => A2(e.transitions)), u = A(() => E2(e.actionRow)), c = A(
    () => C2(e.previewFormat, e.format, t())
  ), f = A(() => D2(e.textInput)), v = A(() => $2(e.inline)), g = A(() => R2(e.config)), b = A(() => N2(e.highlight)), C = A(() => L2(e.weekNumbers)), p = A(() => z2(e.timezone)), w = A(() => Y2(e.multiDates)), y = A(
    () => V2({
      minDate: e.minDate,
      maxDate: e.maxDate,
      disabledDates: e.disabledDates,
      allowedDates: e.allowedDates,
      highlight: b.value,
      markers: e.markers,
      timezone: p.value,
      isSpecific: e.monthPicker || e.yearPicker || e.quarterPicker
    })
  ), x = A(() => H2(e.range)), D = A(() => B2(e.ui));
  return {
    defaultedTransitions: d,
    defaultedMultiCalendars: i,
    defaultedStartTime: l,
    defaultedAriaLabels: o,
    defaultedFilters: s,
    defaultedActionRow: u,
    defaultedPreviewFormat: c,
    defaultedTextInput: f,
    defaultedInline: v,
    defaultedConfig: g,
    defaultedHighlight: b,
    defaultedWeekNumbers: C,
    defaultedRange: x,
    propDates: y,
    defaultedTz: p,
    defaultedMultiDates: w,
    defaultedUI: D,
    getDefaultPattern: t,
    getDefaultStartTime: r
  };
}, j2 = (e, a, t) => {
  const n = z(), { defaultedTextInput: r, defaultedRange: i, defaultedTz: l, defaultedMultiDates: o, getDefaultPattern: s } = Je(a), d = z(""), u = Qe(a, "format"), c = Qe(a, "formatLocale");
  Ge(
    n,
    () => {
      typeof a.onInternalModelChange == "function" && e("internal-model-change", n.value, Z(!0));
    },
    { deep: !0 }
  ), Ge(i, (_, ce) => {
    _.enabled !== ce.enabled && (n.value = null);
  }), Ge(u, () => {
    ne();
  });
  const f = (_) => l.value.timezone && l.value.convertModel ? Ct(_, l.value.timezone) : _, v = (_) => {
    if (l.value.timezone && l.value.convertModel) {
      const ce = u2(l.value.timezone, _);
      return im(_, ce);
    }
    return _;
  }, g = (_, ce, ke = !1) => Ju(
    _,
    a.format,
    a.formatLocale,
    r.value.rangeSeparator,
    a.modelAuto,
    ce ?? s(),
    ke
  ), b = (_) => _ ? a.modelType ? L(_) : {
    hours: va(_),
    minutes: Aa(_),
    seconds: a.enableSeconds ? mn(_) : 0
  } : null, C = (_) => a.modelType ? L(_) : { month: Ee(_), year: Pe(_) }, p = (_) => Array.isArray(_) ? o.value.enabled ? _.map((ce) => w(ce, aa(ee(), ce))) : dr(
    () => [
      aa(ee(), _[0]),
      _[1] ? aa(ee(), _[1]) : tn(i.value.partialRange)
    ],
    i.value.enabled
  ) : aa(ee(), +_), w = (_, ce) => (typeof _ == "string" || typeof _ == "number") && a.modelType ? U(_) : ce, y = (_) => Array.isArray(_) ? [
    w(
      _[0],
      Ma(null, +_[0].hours, +_[0].minutes, _[0].seconds)
    ),
    w(
      _[1],
      Ma(null, +_[1].hours, +_[1].minutes, _[1].seconds)
    )
  ] : w(_, Ma(null, _.hours, _.minutes, _.seconds)), x = (_) => {
    const ce = je(ee(), { date: 1 });
    return Array.isArray(_) ? o.value.enabled ? _.map((ke) => w(ke, sa(ce, +ke.month, +ke.year))) : dr(
      () => [
        w(_[0], sa(ce, +_[0].month, +_[0].year)),
        w(
          _[1],
          _[1] ? sa(ce, +_[1].month, +_[1].year) : tn(i.value.partialRange)
        )
      ],
      i.value.enabled
    ) : w(_, sa(ce, +_.month, +_.year));
  }, D = (_) => {
    if (Array.isArray(_))
      return _.map((ce) => U(ce));
    throw new Error(Hi.dateArr("multi-dates"));
  }, S = (_) => {
    if (Array.isArray(_) && i.value.enabled) {
      const ce = _[0], ke = _[1];
      return [
        ee(Array.isArray(ce) ? ce[0] : null),
        Array.isArray(ke) && ke.length ? ee(ke[0]) : null
      ];
    }
    return ee(_[0]);
  }, $ = (_) => a.modelAuto ? Array.isArray(_) ? [U(_[0]), U(_[1])] : a.autoApply ? [U(_)] : [U(_), null] : Array.isArray(_) ? dr(
    () => _[1] ? [
      U(_[0]),
      _[1] ? U(_[1]) : tn(i.value.partialRange)
    ] : [U(_[0])],
    i.value.enabled
  ) : U(_), O = () => {
    Array.isArray(n.value) && i.value.enabled && n.value.length === 1 && n.value.push(tn(i.value.partialRange));
  }, N = () => {
    const _ = n.value;
    return [
      L(_[0]),
      _[1] ? L(_[1]) : tn(i.value.partialRange)
    ];
  }, M = () => n.value[1] ? N() : L(gt(n.value[0])), B = () => (n.value || []).map((_) => L(_)), X = (_ = !1) => (_ || O(), a.modelAuto ? M() : o.value.enabled ? B() : Array.isArray(n.value) ? dr(() => N(), i.value.enabled) : L(gt(n.value))), te = (_) => !_ || Array.isArray(_) && !_.length ? null : a.timePicker ? y(gt(_)) : a.monthPicker ? x(gt(_)) : a.yearPicker ? p(gt(_)) : o.value.enabled ? D(gt(_)) : a.weekPicker ? S(gt(_)) : $(gt(_)), T = (_) => {
    const ce = te(_);
    Ql(gt(ce)) ? (n.value = gt(ce), ne()) : (n.value = null, d.value = "");
  }, E = () => {
    const _ = (ce) => na(ce, r.value.format);
    return `${_(n.value[0])} ${r.value.rangeSeparator} ${n.value[1] ? _(n.value[1]) : ""}`;
  }, I = () => t.value && n.value ? Array.isArray(n.value) ? E() : na(n.value, r.value.format) : g(n.value), q = () => n.value ? o.value.enabled ? n.value.map((_) => g(_)).join("; ") : r.value.enabled && typeof r.value.format == "string" ? I() : g(n.value) : "", ne = () => {
    !a.format || typeof a.format == "string" || r.value.enabled && typeof r.value.format == "string" ? d.value = q() : d.value = a.format(n.value);
  }, U = (_) => {
    if (a.utc) {
      const ce = new Date(_);
      return a.utc === "preserve" ? new Date(ce.getTime() + ce.getTimezoneOffset() * 6e4) : ce;
    }
    return a.modelType ? c2.includes(a.modelType) ? f(new Date(_)) : a.modelType === "format" && (typeof a.format == "string" || !a.format) ? f(
      ql(_, s(), /* @__PURE__ */ new Date(), { locale: c.value })
    ) : f(
      ql(_, a.modelType, /* @__PURE__ */ new Date(), { locale: c.value })
    ) : f(new Date(_));
  }, L = (_) => _ ? a.utc ? w2(_, a.utc === "preserve", a.enableSeconds) : a.modelType ? a.modelType === "timestamp" ? +v(_) : a.modelType === "iso" ? v(_).toISOString() : a.modelType === "format" && (typeof a.format == "string" || !a.format) ? g(v(_)) : g(v(_), a.modelType, !0) : v(_) : "", ue = (_, ce = !1, ke = !1) => {
    if (ke)
      return _;
    if (e("update:model-value", _), l.value.emitTimezone && ce) {
      const ft = Array.isArray(_) ? _.map((j) => Ct(gt(j), l.value.emitTimezone)) : Ct(gt(_), l.value.emitTimezone);
      e("update:model-timezone-value", ft);
    }
  }, K = (_) => Array.isArray(n.value) ? o.value.enabled ? n.value.map((ce) => _(ce)) : [
    _(n.value[0]),
    n.value[1] ? _(n.value[1]) : tn(i.value.partialRange)
  ] : _(gt(n.value)), V = () => {
    if (Array.isArray(n.value)) {
      const _ = oa(n.value[0], a.weekStart), ce = n.value[1] ? oa(n.value[1], a.weekStart) : [];
      return [_.map((ke) => ee(ke)), ce.map((ke) => ee(ke))];
    }
    return oa(n.value, a.weekStart).map((_) => ee(_));
  }, h = (_, ce) => ue(gt(K(_)), !1, ce), R = (_) => {
    const ce = V();
    return _ ? ce : e("update:model-value", V());
  }, Z = (_ = !1) => (_ || ne(), a.monthPicker ? h(C, _) : a.timePicker ? h(b, _) : a.yearPicker ? h(Pe, _) : a.weekPicker ? R(_) : ue(X(_), !0, _));
  return {
    inputValue: d,
    internalModelValue: n,
    checkBeforeEmit: () => n.value ? i.value.enabled ? i.value.partialRange ? n.value.length >= 1 : n.value.length === 2 : !!n.value : !1,
    parseExternalModelValue: T,
    formatInputValue: ne,
    emitModelValue: Z
  };
}, U2 = (e, a) => {
  const { defaultedFilters: t, propDates: n } = Je(e), { validateMonthYearInRange: r } = $a(e), i = (u, c) => {
    let f = u;
    return t.value.months.includes(Ee(f)) ? (f = c ? Wt(u, 1) : hn(u, 1), i(f, c)) : f;
  }, l = (u, c) => {
    let f = u;
    return t.value.years.includes(Pe(f)) ? (f = c ? Oi(u, 1) : Vu(u, 1), l(f, c)) : f;
  }, o = (u, c = !1) => {
    const f = je(ee(), { month: e.month, year: e.year });
    let v = u ? Wt(f, 1) : hn(f, 1);
    e.disableYearSelect && (v = aa(v, e.year));
    let g = Ee(v), b = Pe(v);
    t.value.months.includes(g) && (v = i(v, u), g = Ee(v), b = Pe(v)), t.value.years.includes(b) && (v = l(v, u), b = Pe(v)), r(g, b, u, e.preventMinMaxNavigation) && s(g, b, c);
  }, s = (u, c, f) => {
    a("update-month-year", { month: u, year: c, fromNav: f });
  }, d = A(() => (u) => Gu(
    je(ee(), { month: e.month, year: e.year }),
    n.value.maxDate,
    n.value.minDate,
    e.preventMinMaxNavigation,
    u
  ));
  return { handleMonthYearChange: o, isDisabled: d, updateMonthYear: s };
}, Xr = {
  multiCalendars: { type: [Boolean, Number, String, Object], default: void 0 },
  modelValue: { type: [String, Date, Array, Object, Number], default: null },
  modelType: { type: String, default: null },
  position: { type: String, default: "center" },
  dark: { type: Boolean, default: !1 },
  format: {
    type: [String, Function],
    default: () => null
  },
  autoPosition: { type: [Boolean, String], default: !0 },
  altPosition: { type: Function, default: null },
  transitions: { type: [Boolean, Object], default: !0 },
  formatLocale: { type: Object, default: null },
  utc: { type: [Boolean, String], default: !1 },
  ariaLabels: { type: Object, default: () => ({}) },
  offset: { type: [Number, String], default: 10 },
  hideNavigation: { type: Array, default: () => [] },
  timezone: { type: [String, Object], default: null },
  vertical: { type: Boolean, default: !1 },
  disableMonthYearSelect: { type: Boolean, default: !1 },
  disableYearSelect: { type: Boolean, default: !1 },
  dayClass: {
    type: Function,
    default: null
  },
  yearRange: { type: Array, default: () => [1900, 2100] },
  enableTimePicker: { type: Boolean, default: !0 },
  autoApply: { type: Boolean, default: !1 },
  disabledDates: { type: [Array, Function], default: () => [] },
  monthNameFormat: { type: String, default: "short" },
  startDate: { type: [Date, String], default: null },
  startTime: { type: [Object, Array], default: null },
  hideOffsetDates: { type: Boolean, default: !1 },
  noToday: { type: Boolean, default: !1 },
  disabledWeekDays: { type: Array, default: () => [] },
  allowedDates: { type: Array, default: null },
  nowButtonLabel: { type: String, default: "Now" },
  markers: { type: Array, default: () => [] },
  escClose: { type: Boolean, default: !0 },
  spaceConfirm: { type: Boolean, default: !0 },
  monthChangeOnArrows: { type: Boolean, default: !0 },
  presetDates: { type: Array, default: () => [] },
  flow: { type: Array, default: () => [] },
  partialFlow: { type: Boolean, default: !1 },
  preventMinMaxNavigation: { type: Boolean, default: !1 },
  reverseYears: { type: Boolean, default: !1 },
  weekPicker: { type: Boolean, default: !1 },
  filters: { type: Object, default: () => ({}) },
  arrowNavigation: { type: Boolean, default: !1 },
  highlight: {
    type: [Function, Object],
    default: null
  },
  teleport: { type: [Boolean, String, Object], default: null },
  teleportCenter: { type: Boolean, default: !1 },
  locale: { type: String, default: "en-Us" },
  weekNumName: { type: String, default: "W" },
  weekStart: { type: [Number, String], default: 1 },
  weekNumbers: {
    type: [String, Function, Object],
    default: null
  },
  monthChangeOnScroll: { type: [Boolean, String], default: !0 },
  dayNames: {
    type: [Function, Array],
    default: null
  },
  monthPicker: { type: Boolean, default: !1 },
  customProps: { type: Object, default: null },
  yearPicker: { type: Boolean, default: !1 },
  modelAuto: { type: Boolean, default: !1 },
  selectText: { type: String, default: "Select" },
  cancelText: { type: String, default: "Cancel" },
  previewFormat: {
    type: [String, Function],
    default: () => ""
  },
  multiDates: { type: [Object, Boolean], default: !1 },
  ignoreTimeValidation: { type: Boolean, default: !1 },
  minDate: { type: [Date, String], default: null },
  maxDate: { type: [Date, String], default: null },
  minTime: { type: Object, default: null },
  maxTime: { type: Object, default: null },
  name: { type: String, default: null },
  placeholder: { type: String, default: "" },
  hideInputIcon: { type: Boolean, default: !1 },
  clearable: { type: Boolean, default: !0 },
  state: { type: Boolean, default: null },
  required: { type: Boolean, default: !1 },
  autocomplete: { type: String, default: "off" },
  timePicker: { type: Boolean, default: !1 },
  enableSeconds: { type: Boolean, default: !1 },
  is24: { type: Boolean, default: !0 },
  noHoursOverlay: { type: Boolean, default: !1 },
  noMinutesOverlay: { type: Boolean, default: !1 },
  noSecondsOverlay: { type: Boolean, default: !1 },
  hoursGridIncrement: { type: [String, Number], default: 1 },
  minutesGridIncrement: { type: [String, Number], default: 5 },
  secondsGridIncrement: { type: [String, Number], default: 5 },
  hoursIncrement: { type: [Number, String], default: 1 },
  minutesIncrement: { type: [Number, String], default: 1 },
  secondsIncrement: { type: [Number, String], default: 1 },
  range: { type: [Boolean, Object], default: !1 },
  uid: { type: String, default: null },
  disabled: { type: Boolean, default: !1 },
  readonly: { type: Boolean, default: !1 },
  inline: { type: [Boolean, Object], default: !1 },
  textInput: { type: [Boolean, Object], default: !1 },
  sixWeeks: { type: [Boolean, String], default: !1 },
  actionRow: { type: Object, default: () => ({}) },
  focusStartDate: { type: Boolean, default: !1 },
  disabledTimes: { type: [Function, Array], default: void 0 },
  timePickerInline: { type: Boolean, default: !1 },
  calendar: { type: Function, default: null },
  config: { type: Object, default: void 0 },
  quarterPicker: { type: Boolean, default: !1 },
  yearFirst: { type: Boolean, default: !1 },
  loading: { type: Boolean, default: !1 },
  onInternalModelChange: { type: [Function, Object], default: null },
  enableMinutes: { type: Boolean, default: !0 },
  ui: { type: Object, default: () => ({}) }
}, Xt = {
  ...Xr,
  shadow: { type: Boolean, default: !1 },
  flowStep: { type: Number, default: 0 },
  internalModelValue: { type: [Date, Array], default: null },
  noOverlayFocus: { type: Boolean, default: !1 },
  collapse: { type: Boolean, default: !1 },
  menuWrapRef: { type: Object, default: null },
  getInputRect: { type: Function, default: () => ({}) },
  isTextInputDate: { type: Boolean, default: !1 },
  isMobile: { type: Boolean, default: void 0 }
}, W2 = ["title"], q2 = ["disabled"], K2 = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "ActionRow",
  props: {
    menuMount: { type: Boolean, default: !1 },
    calendarWidth: { type: Number, default: 0 },
    ...Xt
  },
  emits: ["close-picker", "select-date", "select-now", "invalid-select"],
  setup(e, { emit: a }) {
    const t = a, n = e, {
      defaultedActionRow: r,
      defaultedPreviewFormat: i,
      defaultedMultiCalendars: l,
      defaultedTextInput: o,
      defaultedInline: s,
      defaultedRange: d,
      defaultedMultiDates: u
    } = Je(n), { isTimeValid: c, isMonthValid: f } = $a(n), { buildMatrix: v } = Ea(), g = z(null), b = z(null), C = z(!1), p = z({}), w = z(null), y = z(null);
    He(() => {
      n.arrowNavigation && v([ht(g), ht(b)], "actionRow"), x(), window.addEventListener("resize", x);
    }), Ht(() => {
      window.removeEventListener("resize", x);
    });
    const x = () => {
      C.value = !1, setTimeout(() => {
        var T, E;
        const I = (T = w.value) == null ? void 0 : T.getBoundingClientRect(), q = (E = y.value) == null ? void 0 : E.getBoundingClientRect();
        I && q && (p.value.maxWidth = `${q.width - I.width - 20}px`), C.value = !0;
      }, 0);
    }, D = A(() => d.value.enabled && !d.value.partialRange && n.internalModelValue ? n.internalModelValue.length === 2 : !0), S = A(
      () => !c.value(n.internalModelValue) || !f.value(n.internalModelValue) || !D.value
    ), $ = () => {
      const T = i.value;
      return n.timePicker || n.monthPicker, T(gt(n.internalModelValue));
    }, O = () => {
      const T = n.internalModelValue;
      return l.value.count > 0 ? `${N(T[0])} - ${N(T[1])}` : [N(T[0]), N(T[1])];
    }, N = (T) => Ju(
      T,
      i.value,
      n.formatLocale,
      o.value.rangeSeparator,
      n.modelAuto,
      i.value
    ), M = A(() => !n.internalModelValue || !n.menuMount ? "" : typeof i.value == "string" ? Array.isArray(n.internalModelValue) ? n.internalModelValue.length === 2 && n.internalModelValue[1] ? O() : u.value.enabled ? n.internalModelValue.map((T) => `${N(T)}`) : n.modelAuto ? `${N(n.internalModelValue[0])}` : `${N(n.internalModelValue[0])} -` : N(n.internalModelValue) : $()), B = () => u.value.enabled ? "; " : " - ", X = A(
      () => Array.isArray(M.value) ? M.value.join(B()) : M.value
    ), te = () => {
      c.value(n.internalModelValue) && f.value(n.internalModelValue) && D.value ? t("select-date") : t("invalid-select");
    };
    return (T, E) => (P(), Y("div", {
      ref_key: "actionRowRef",
      ref: y,
      class: "dp__action_row"
    }, [
      T.$slots["action-row"] ? se(T.$slots, "action-row", bt(Ue({ key: 0 }, {
        internalModelValue: T.internalModelValue,
        disabled: S.value,
        selectDate: () => T.$emit("select-date"),
        closePicker: () => T.$emit("close-picker")
      }))) : (P(), Y(Se, { key: 1 }, [
        m(r).showPreview ? (P(), Y("div", {
          key: 0,
          class: "dp__selection_preview",
          title: X.value,
          style: Ze(p.value)
        }, [
          T.$slots["action-preview"] && C.value ? se(T.$slots, "action-preview", {
            key: 0,
            value: T.internalModelValue
          }) : G("", !0),
          !T.$slots["action-preview"] && C.value ? (P(), Y(Se, { key: 1 }, [
            vt(Le(X.value), 1)
          ], 64)) : G("", !0)
        ], 12, W2)) : G("", !0),
        ie("div", {
          ref_key: "actionBtnContainer",
          ref: w,
          class: "dp__action_buttons",
          "data-dp-element": "action-row"
        }, [
          T.$slots["action-buttons"] ? se(T.$slots, "action-buttons", {
            key: 0,
            value: T.internalModelValue
          }) : G("", !0),
          T.$slots["action-buttons"] ? G("", !0) : (P(), Y(Se, { key: 1 }, [
            !m(s).enabled && m(r).showCancel ? (P(), Y("button", {
              key: 0,
              ref_key: "cancelButtonRef",
              ref: g,
              type: "button",
              class: "dp__action_button dp__action_cancel",
              onClick: E[0] || (E[0] = (I) => T.$emit("close-picker")),
              onKeydown: E[1] || (E[1] = (I) => m(Ot)(I, () => T.$emit("close-picker")))
            }, Le(T.cancelText), 545)) : G("", !0),
            m(r).showNow ? (P(), Y("button", {
              key: 1,
              type: "button",
              class: "dp__action_button dp__action_cancel",
              onClick: E[2] || (E[2] = (I) => T.$emit("select-now")),
              onKeydown: E[3] || (E[3] = (I) => m(Ot)(I, () => T.$emit("select-now")))
            }, Le(T.nowButtonLabel), 33)) : G("", !0),
            m(r).showSelect ? (P(), Y("button", {
              key: 2,
              ref_key: "selectButtonRef",
              ref: b,
              type: "button",
              class: "dp__action_button dp__action_select",
              disabled: S.value,
              "data-test-id": "select-button",
              onKeydown: E[4] || (E[4] = (I) => m(Ot)(I, () => te())),
              onClick: te
            }, Le(T.selectText), 41, q2)) : G("", !0)
          ], 64))
        ], 512)
      ], 64))
    ], 512));
  }
}), X2 = ["role", "aria-label", "tabindex"], Q2 = { class: "dp__selection_grid_header" }, G2 = ["aria-selected", "aria-disabled", "data-test-id", "onClick", "onKeydown", "onMouseover"], J2 = ["aria-label"], Xn = /* @__PURE__ */ be({
  __name: "SelectionOverlay",
  props: {
    items: {},
    type: {},
    isLast: { type: Boolean },
    arrowNavigation: { type: Boolean },
    skipButtonRef: { type: Boolean },
    headerRefs: {},
    hideNavigation: {},
    escClose: { type: Boolean },
    useRelative: { type: Boolean },
    height: {},
    textInput: { type: [Boolean, Object] },
    config: {},
    noOverlayFocus: { type: Boolean },
    focusValue: {},
    menuWrapRef: {},
    ariaLabels: {},
    overlayLabel: {}
  },
  emits: ["selected", "toggle", "reset-flow", "hover-value"],
  setup(e, { expose: a, emit: t }) {
    const { setSelectionGrid: n, buildMultiLevelMatrix: r, setMonthPicker: i } = Ea(), l = t, o = e, { defaultedAriaLabels: s, defaultedTextInput: d, defaultedConfig: u } = Je(
      o
    ), { hideNavigationButtons: c } = Jr(), f = z(!1), v = z(null), g = z(null), b = z([]), C = z(), p = z(null), w = z(0), y = z(null);
    hc(() => {
      v.value = null;
    }), He(() => {
      Ne().then(() => B()), o.noOverlayFocus || D(), x(!0);
    }), Ht(() => x(!1));
    const x = (K) => {
      var V;
      o.arrowNavigation && ((V = o.headerRefs) != null && V.length ? i(K) : n(K));
    }, D = () => {
      var K;
      const V = ht(g);
      V && (d.value.enabled || (v.value ? (K = v.value) == null || K.focus({ preventScroll: !0 }) : V.focus({ preventScroll: !0 })), f.value = V.clientHeight < V.scrollHeight);
    }, S = A(
      () => ({
        dp__overlay: !0,
        "dp--overlay-absolute": !o.useRelative,
        "dp--overlay-relative": o.useRelative
      })
    ), $ = A(
      () => o.useRelative ? { height: `${o.height}px`, width: "var(--dp-menu-min-width)" } : void 0
    ), O = A(() => ({
      dp__overlay_col: !0
    })), N = A(
      () => ({
        dp__btn: !0,
        dp__button: !0,
        dp__overlay_action: !0,
        dp__over_action_scroll: f.value,
        dp__button_bottom: o.isLast
      })
    ), M = A(() => {
      var K, V;
      return {
        dp__overlay_container: !0,
        dp__container_flex: ((K = o.items) == null ? void 0 : K.length) <= 6,
        dp__container_block: ((V = o.items) == null ? void 0 : V.length) > 6
      };
    });
    Ge(
      () => o.items,
      () => B(!1),
      { deep: !0 }
    );
    const B = (K = !0) => {
      Ne().then(() => {
        const V = ht(v), h = ht(g), R = ht(p), Z = ht(y), _ = R ? R.getBoundingClientRect().height : 0;
        h && (h.getBoundingClientRect().height ? w.value = h.getBoundingClientRect().height - _ : w.value = u.value.modeHeight - _), V && Z && K && (Z.scrollTop = V.offsetTop - Z.offsetTop - (w.value / 2 - V.getBoundingClientRect().height) - _);
      });
    }, X = (K) => {
      K.disabled || l("selected", K.value);
    }, te = () => {
      l("toggle"), l("reset-flow");
    }, T = () => {
      o.escClose && te();
    }, E = (K, V, h, R) => {
      K && ((V.active || V.value === o.focusValue) && (v.value = K), o.arrowNavigation && (Array.isArray(b.value[h]) ? b.value[h][R] = K : b.value[h] = [K], I()));
    }, I = () => {
      var K, V;
      const h = (K = o.headerRefs) != null && K.length ? [o.headerRefs].concat(b.value) : b.value.concat([o.skipButtonRef ? [] : [p.value]]);
      r(gt(h), (V = o.headerRefs) != null && V.length ? "monthPicker" : "selectionGrid");
    }, q = (K) => {
      o.arrowNavigation || _a(K, u.value, !0);
    }, ne = (K) => {
      C.value = K, l("hover-value", K);
    }, U = () => {
      if (te(), !o.isLast) {
        const K = Xl(o.menuWrapRef ?? null, "action-row");
        if (K) {
          const V = Uu(K);
          V == null || V.focus();
        }
      }
    }, L = (K) => {
      switch (K.key) {
        case qe.esc:
          return T();
        case qe.arrowLeft:
          return q(K);
        case qe.arrowRight:
          return q(K);
        case qe.arrowUp:
          return q(K);
        case qe.arrowDown:
          return q(K);
        default:
          return;
      }
    }, ue = (K) => {
      if (K.key === qe.enter)
        return te();
      if (K.key === qe.tab)
        return U();
    };
    return a({ focusGrid: D }), (K, V) => {
      var h;
      return P(), Y("div", {
        ref_key: "gridWrapRef",
        ref: g,
        class: ye(S.value),
        style: Ze($.value),
        role: K.useRelative ? void 0 : "dialog",
        "aria-label": K.overlayLabel,
        tabindex: K.useRelative ? void 0 : "0",
        onKeydown: L,
        onClick: V[0] || (V[0] = Ba(() => {
        }, ["prevent"]))
      }, [
        ie("div", {
          ref_key: "containerRef",
          ref: y,
          class: ye(M.value),
          style: Ze({ "--dp-overlay-height": `${w.value}px` }),
          role: "grid"
        }, [
          ie("div", Q2, [
            se(K.$slots, "header")
          ]),
          K.$slots.overlay ? se(K.$slots, "overlay", { key: 0 }) : (P(!0), Y(Se, { key: 1 }, Fe(K.items, (R, Z) => (P(), Y("div", {
            key: Z,
            class: ye(["dp__overlay_row", { dp__flex_row: K.items.length >= 3 }]),
            role: "row"
          }, [
            (P(!0), Y(Se, null, Fe(R, (_, ce) => (P(), Y("div", {
              key: _.value,
              ref_for: !0,
              ref: (ke) => E(ke, _, Z, ce),
              role: "gridcell",
              class: ye(O.value),
              "aria-selected": _.active || void 0,
              "aria-disabled": _.disabled || void 0,
              tabindex: "0",
              "data-test-id": _.text,
              onClick: Ba((ke) => X(_), ["prevent"]),
              onKeydown: (ke) => m(Ot)(ke, () => X(_), !0),
              onMouseover: (ke) => ne(_.value)
            }, [
              ie("div", {
                class: ye(_.className)
              }, [
                K.$slots.item ? se(K.$slots, "item", {
                  key: 0,
                  item: _
                }) : G("", !0),
                K.$slots.item ? G("", !0) : (P(), Y(Se, { key: 1 }, [
                  vt(Le(_.text), 1)
                ], 64))
              ], 2)
            ], 42, G2))), 128))
          ], 2))), 128))
        ], 6),
        K.$slots["button-icon"] ? ka((P(), Y("button", {
          key: 0,
          ref_key: "toggleButton",
          ref: p,
          type: "button",
          "aria-label": (h = m(s)) == null ? void 0 : h.toggleOverlay,
          class: ye(N.value),
          tabindex: "0",
          onClick: te,
          onKeydown: ue
        }, [
          se(K.$slots, "button-icon")
        ], 42, J2)), [
          [mr, !m(c)(K.hideNavigation, K.type)]
        ]) : G("", !0)
      ], 46, X2);
    };
  }
}), Z2 = ["data-dp-mobile"], Qr = /* @__PURE__ */ be({
  __name: "InstanceWrap",
  props: {
    multiCalendars: {},
    stretch: { type: Boolean },
    collapse: { type: Boolean },
    isMobile: { type: Boolean }
  },
  setup(e) {
    const a = e, t = A(
      () => a.multiCalendars > 0 ? [...Array(a.multiCalendars).keys()] : [0]
    ), n = A(() => ({
      dp__instance_calendar: a.multiCalendars > 0
    }));
    return (r, i) => (P(), Y("div", {
      class: ye({
        dp__menu_inner: !r.stretch,
        "dp--menu--inner-stretched": r.stretch,
        dp__flex_display: r.multiCalendars > 0,
        "dp--flex-display-collapsed": r.collapse
      }),
      "data-dp-mobile": r.isMobile
    }, [
      (P(!0), Y(Se, null, Fe(t.value, (l, o) => (P(), Y("div", {
        key: l,
        class: ye(n.value)
      }, [
        se(r.$slots, "default", {
          instance: l,
          index: o
        })
      ], 2))), 128))
    ], 10, Z2));
  }
}), eh = ["data-dp-element", "aria-label", "aria-disabled"], An = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "ArrowBtn",
  props: {
    ariaLabel: {},
    elName: {},
    disabled: { type: Boolean }
  },
  emits: ["activate", "set-ref"],
  setup(e, { emit: a }) {
    const t = a, n = z(null);
    return He(() => t("set-ref", n)), (r, i) => (P(), Y("button", {
      ref_key: "elRef",
      ref: n,
      type: "button",
      "data-dp-element": r.elName,
      class: "dp__btn dp--arrow-btn-nav",
      tabindex: "0",
      "aria-label": r.ariaLabel,
      "aria-disabled": r.disabled || void 0,
      onClick: i[0] || (i[0] = (l) => r.$emit("activate")),
      onKeydown: i[1] || (i[1] = (l) => m(Ot)(l, () => r.$emit("activate"), !0))
    }, [
      ie("span", {
        class: ye(["dp__inner_nav", { dp__inner_nav_disabled: r.disabled }])
      }, [
        se(r.$slots, "default")
      ], 2)
    ], 40, eh));
  }
}), th = ["aria-label", "data-test-id"], ac = /* @__PURE__ */ be({
  __name: "YearModePicker",
  props: {
    ...Xt,
    showYearPicker: { type: Boolean, default: !1 },
    items: { type: Array, default: () => [] },
    instance: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    isDisabled: { type: Function, default: () => !1 }
  },
  emits: ["toggle-year-picker", "year-select", "handle-year"],
  setup(e, { emit: a }) {
    const t = a, n = e, { showRightIcon: r, showLeftIcon: i } = Jr(), { defaultedConfig: l, defaultedMultiCalendars: o, defaultedAriaLabels: s, defaultedTransitions: d, defaultedUI: u } = Je(n), { showTransition: c, transitionName: f } = Qn(d), v = z(!1), g = (p = !1, w) => {
      v.value = !v.value, t("toggle-year-picker", { flow: p, show: w });
    }, b = (p) => {
      v.value = !1, t("year-select", p);
    }, C = (p = !1) => {
      t("handle-year", p);
    };
    return (p, w) => {
      var y, x, D, S, $;
      return P(), Y(Se, null, [
        ie("div", {
          class: ye(["dp--year-mode-picker", { "dp--hidden-el": v.value }])
        }, [
          m(i)(m(o), e.instance) ? (P(), Me(An, {
            key: 0,
            ref: "mpPrevIconRef",
            "aria-label": (y = m(s)) == null ? void 0 : y.prevYear,
            disabled: e.isDisabled(!1),
            class: ye((x = m(u)) == null ? void 0 : x.navBtnPrev),
            onActivate: w[0] || (w[0] = (O) => C(!1))
          }, {
            default: me(() => [
              p.$slots["arrow-left"] ? se(p.$slots, "arrow-left", { key: 0 }) : G("", !0),
              p.$slots["arrow-left"] ? G("", !0) : (P(), Me(m(Di), { key: 1 }))
            ]),
            _: 3
          }, 8, ["aria-label", "disabled", "class"])) : G("", !0),
          ie("button", {
            ref: "mpYearButtonRef",
            class: "dp__btn dp--year-select",
            type: "button",
            "aria-label": `${e.year}-${(D = m(s)) == null ? void 0 : D.openYearsOverlay}`,
            "data-test-id": `year-mode-btn-${e.instance}`,
            onClick: w[1] || (w[1] = () => g(!1)),
            onKeydown: w[2] || (w[2] = pr(() => g(!1), ["enter"]))
          }, [
            p.$slots.year ? se(p.$slots, "year", {
              key: 0,
              year: e.year
            }) : G("", !0),
            p.$slots.year ? G("", !0) : (P(), Y(Se, { key: 1 }, [
              vt(Le(e.year), 1)
            ], 64))
          ], 40, th),
          m(r)(m(o), e.instance) ? (P(), Me(An, {
            key: 1,
            ref: "mpNextIconRef",
            "aria-label": (S = m(s)) == null ? void 0 : S.nextYear,
            disabled: e.isDisabled(!0),
            class: ye(($ = m(u)) == null ? void 0 : $.navBtnNext),
            onActivate: w[3] || (w[3] = (O) => C(!0))
          }, {
            default: me(() => [
              p.$slots["arrow-right"] ? se(p.$slots, "arrow-right", { key: 0 }) : G("", !0),
              p.$slots["arrow-right"] ? G("", !0) : (P(), Me(m(Ii), { key: 1 }))
            ]),
            _: 3
          }, 8, ["aria-label", "disabled", "class"])) : G("", !0)
        ], 2),
        $e(Da, {
          name: m(f)(e.showYearPicker),
          css: m(c)
        }, {
          default: me(() => {
            var O, N;
            return [
              e.showYearPicker ? (P(), Me(Xn, {
                key: 0,
                items: e.items,
                "text-input": p.textInput,
                "esc-close": p.escClose,
                config: p.config,
                "is-last": p.autoApply && !m(l).keepActionRow,
                "hide-navigation": p.hideNavigation,
                "aria-labels": p.ariaLabels,
                "overlay-label": (N = (O = m(s)) == null ? void 0 : O.yearPicker) == null ? void 0 : N.call(O, !0),
                type: "year",
                onToggle: g,
                onSelected: w[4] || (w[4] = (M) => b(M))
              }, kt({
                "button-icon": me(() => [
                  p.$slots["calendar-icon"] ? se(p.$slots, "calendar-icon", { key: 0 }) : G("", !0),
                  p.$slots["calendar-icon"] ? G("", !0) : (P(), Me(m(wn), { key: 1 }))
                ]),
                _: 2
              }, [
                p.$slots["year-overlay-value"] ? {
                  name: "item",
                  fn: me(({ item: M }) => [
                    se(p.$slots, "year-overlay-value", {
                      text: M.text,
                      value: M.value
                    })
                  ]),
                  key: "0"
                } : void 0
              ]), 1032, ["items", "text-input", "esc-close", "config", "is-last", "hide-navigation", "aria-labels", "overlay-label"])) : G("", !0)
            ];
          }),
          _: 3
        }, 8, ["name", "css"])
      ], 64);
    };
  }
}), Vi = (e, a, t) => {
  if (a.value && Array.isArray(a.value))
    if (a.value.some((n) => Re(e, n))) {
      const n = a.value.filter((r) => !Re(r, e));
      a.value = n.length ? n : null;
    } else
      (t && +t > a.value.length || !t) && a.value.push(e);
  else
    a.value = [e];
}, Yi = (e, a, t) => {
  let n = e.value ? e.value.slice() : [];
  return n.length === 2 && n[1] !== null && (n = []), n.length ? (tt(a, n[0]) ? n.unshift(a) : n[1] = a, t("range-end", a)) : (n = [a], t("range-start", a)), n;
}, Gr = (e, a, t, n) => {
  e && (e[0] && e[1] && t && a("auto-apply"), e[0] && !e[1] && n && t && a("auto-apply"));
}, nc = (e) => {
  Array.isArray(e.value) && e.value.length <= 2 && e.range ? e.modelValue.value = e.value.map((a) => Ct(ee(a), e.timezone)) : Array.isArray(e.value) || (e.modelValue.value = Ct(ee(e.value), e.timezone));
}, rc = (e, a, t, n) => Array.isArray(a.value) && (a.value.length === 2 || a.value.length === 1 && n.value.partialRange) ? n.value.fixedStart && (ot(e, a.value[0]) || Re(e, a.value[0])) ? [a.value[0], e] : n.value.fixedEnd && (tt(e, a.value[1]) || Re(e, a.value[1])) ? [e, a.value[1]] : (t("invalid-fixed-range", e), a.value) : [], lc = ({
  multiCalendars: e,
  range: a,
  highlight: t,
  propDates: n,
  calendars: r,
  modelValue: i,
  props: l,
  filters: o,
  year: s,
  month: d,
  emit: u
}) => {
  const c = A(() => Li(l.yearRange, l.locale, l.reverseYears)), f = z([!1]), v = A(() => (M, B) => {
    const X = je(jt(/* @__PURE__ */ new Date()), {
      month: d.value(M),
      year: s.value(M)
    }), te = B ? Pu(X) : zn(X);
    return Gu(
      te,
      n.value.maxDate,
      n.value.minDate,
      l.preventMinMaxNavigation,
      B
    );
  }), g = () => Array.isArray(i.value) && e.value.solo && i.value[1], b = () => {
    for (let M = 0; M < e.value.count; M++)
      if (M === 0)
        r.value[M] = r.value[0];
      else if (M === e.value.count - 1 && g())
        r.value[M] = {
          month: Ee(i.value[1]),
          year: Pe(i.value[1])
        };
      else {
        const B = je(ee(), r.value[M - 1]);
        r.value[M] = { month: Ee(B), year: Pe(Oi(B, 1)) };
      }
  }, C = (M) => {
    if (!M)
      return b();
    const B = je(ee(), r.value[M]);
    return r.value[0].year = Pe(Vu(B, e.value.count - 1)), b();
  }, p = (M, B) => {
    const X = dm(B, M);
    return a.value.showLastInRange && X > 1 ? B : M;
  }, w = (M) => l.focusStartDate || e.value.solo ? M[0] : M[1] ? p(M[0], M[1]) : M[0], y = () => {
    if (i.value) {
      const M = Array.isArray(i.value) ? w(i.value) : i.value;
      r.value[0] = { month: Ee(M), year: Pe(M) };
    }
  }, x = () => {
    y(), e.value.count && b();
  };
  Ge(i, (M, B) => {
    l.isTextInputDate && JSON.stringify(M ?? {}) !== JSON.stringify(B ?? {}) && x();
  }), He(() => {
    x();
  });
  const D = (M, B) => {
    r.value[B].year = M, u("update-month-year", { instance: B, year: M, month: r.value[B].month }), e.value.count && !e.value.solo && C(B);
  }, S = A(() => (M) => yn(c.value, (B) => {
    var X;
    const te = s.value(M) === B.value, T = Vn(
      B.value,
      gn(n.value.minDate),
      gn(n.value.maxDate)
    ) || ((X = o.value.years) == null ? void 0 : X.includes(s.value(M))), E = Fi(t.value, B.value);
    return { active: te, disabled: T, highlighted: E };
  })), $ = (M, B) => {
    D(M, B), N(B);
  }, O = (M, B = !1) => {
    if (!v.value(M, B)) {
      const X = B ? s.value(M) + 1 : s.value(M) - 1;
      D(X, M);
    }
  }, N = (M, B = !1, X) => {
    B || u("reset-flow"), X !== void 0 ? f.value[M] = X : f.value[M] = !f.value[M], f.value[M] ? u("overlay-toggle", { open: !0, overlay: xt.year }) : (u("overlay-closed"), u("overlay-toggle", { open: !1, overlay: xt.year }));
  };
  return {
    isDisabled: v,
    groupedYears: S,
    showYearPicker: f,
    selectYear: D,
    toggleYearPicker: N,
    handleYearSelect: $,
    handleYear: O
  };
}, ah = (e, a) => {
  const {
    defaultedMultiCalendars: t,
    defaultedAriaLabels: n,
    defaultedTransitions: r,
    defaultedConfig: i,
    defaultedRange: l,
    defaultedHighlight: o,
    propDates: s,
    defaultedTz: d,
    defaultedFilters: u,
    defaultedMultiDates: c
  } = Je(e), f = () => {
    e.isTextInputDate && x(Pe(ee(e.startDate)), 0);
  }, { modelValue: v, year: g, month: b, calendars: C } = Gn(e, a, f), p = A(() => Bu(e.formatLocale, e.locale, e.monthNameFormat)), w = z(null), { checkMinMaxRange: y } = $a(e), {
    selectYear: x,
    groupedYears: D,
    showYearPicker: S,
    toggleYearPicker: $,
    handleYearSelect: O,
    handleYear: N,
    isDisabled: M
  } = lc({
    modelValue: v,
    multiCalendars: t,
    range: l,
    highlight: o,
    calendars: C,
    year: g,
    propDates: s,
    month: b,
    filters: u,
    props: e,
    emit: a
  });
  He(() => {
    e.startDate && (v.value && e.focusStartDate || !v.value) && x(Pe(ee(e.startDate)), 0);
  });
  const B = (h) => h ? { month: Ee(h), year: Pe(h) } : { month: null, year: null }, X = () => v.value ? Array.isArray(v.value) ? v.value.map((h) => B(h)) : B(v.value) : B(), te = (h, R) => {
    const Z = C.value[h], _ = X();
    return Array.isArray(_) ? _.some((ce) => ce.year === (Z == null ? void 0 : Z.year) && ce.month === R) : (Z == null ? void 0 : Z.year) === _.year && R === _.month;
  }, T = (h, R, Z) => {
    var _, ce;
    const ke = X();
    return Array.isArray(ke) ? g.value(R) === ((_ = ke[Z]) == null ? void 0 : _.year) && h === ((ce = ke[Z]) == null ? void 0 : ce.month) : !1;
  }, E = (h, R) => {
    if (l.value.enabled) {
      const Z = X();
      if (Array.isArray(v.value) && Array.isArray(Z)) {
        const _ = T(h, R, 0) || T(h, R, 1), ce = sa(jt(ee()), h, g.value(R));
        return Kr(v.value, w.value, ce) && !_;
      }
      return !1;
    }
    return !1;
  }, I = A(() => (h) => yn(p.value, (R) => {
    var Z;
    const _ = te(h, R.value), ce = Vn(
      R.value,
      qu(g.value(h), s.value.minDate),
      Ku(g.value(h), s.value.maxDate)
    ) || O2(s.value.disabledDates, g.value(h)).includes(R.value) || ((Z = u.value.months) == null ? void 0 : Z.includes(R.value)) || !S2(s.value.allowedDates, g.value(h), R.value), ke = E(R.value, h), ft = Zu(o.value, R.value, g.value(h));
    return { active: _, disabled: ce, isBetween: ke, highlighted: ft };
  })), q = (h, R) => sa(jt(ee()), h, g.value(R)), ne = (h, R) => {
    const Z = v.value ? v.value : jt(/* @__PURE__ */ new Date());
    v.value = sa(Z, h, g.value(R)), a("auto-apply"), a("update-flow-step");
  }, U = (h, R) => {
    const Z = q(h, R);
    l.value.fixedEnd || l.value.fixedStart ? v.value = rc(Z, v, a, l) : v.value ? y(Z, v.value) && (v.value = Yi(v, q(h, R), a)) : v.value = [q(h, R)], Ne().then(() => {
      Gr(v.value, a, e.autoApply, e.modelAuto);
    });
  }, L = (h, R) => {
    Vi(q(h, R), v, c.value.limit), a("auto-apply", !0);
  }, ue = (h, R) => (C.value[R].month = h, V(R, C.value[R].year, h), c.value.enabled ? L(h, R) : l.value.enabled ? U(h, R) : ne(h, R)), K = (h, R) => {
    x(h, R), V(R, h, null);
  }, V = (h, R, Z) => {
    let _ = Z;
    if (!_ && _ !== 0) {
      const ce = X();
      _ = Array.isArray(ce) ? ce[h].month : ce.month;
    }
    a("update-month-year", { instance: h, year: R, month: _ });
  };
  return {
    groupedMonths: I,
    groupedYears: D,
    year: g,
    isDisabled: M,
    defaultedMultiCalendars: t,
    defaultedAriaLabels: n,
    defaultedTransitions: r,
    defaultedConfig: i,
    showYearPicker: S,
    modelValue: v,
    presetDate: (h, R) => {
      nc({
        value: h,
        modelValue: v,
        range: l.value.enabled,
        timezone: R ? void 0 : d.value.timezone
      }), a("auto-apply");
    },
    setHoverDate: (h, R) => {
      w.value = q(h, R);
    },
    selectMonth: ue,
    selectYear: K,
    toggleYearPicker: $,
    handleYearSelect: O,
    handleYear: N,
    getModelMonthYear: X
  };
}, nh = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "MonthPicker",
  props: {
    ...Xt
  },
  emits: [
    "update:internal-model-value",
    "overlay-closed",
    "reset-flow",
    "range-start",
    "range-end",
    "auto-apply",
    "update-month-year",
    "update-flow-step",
    "mount",
    "invalid-fixed-range",
    "overlay-toggle"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = Ka(), i = Et(r, "yearMode"), l = e;
    He(() => {
      l.shadow || n("mount", null);
    });
    const {
      groupedMonths: o,
      groupedYears: s,
      year: d,
      isDisabled: u,
      defaultedMultiCalendars: c,
      defaultedConfig: f,
      showYearPicker: v,
      modelValue: g,
      presetDate: b,
      setHoverDate: C,
      selectMonth: p,
      selectYear: w,
      toggleYearPicker: y,
      handleYearSelect: x,
      handleYear: D,
      getModelMonthYear: S
    } = ah(l, n);
    return a({ getSidebarProps: () => ({
      modelValue: g,
      year: d,
      getModelMonthYear: S,
      selectMonth: p,
      selectYear: w,
      handleYear: D
    }), presetDate: b, toggleYearPicker: ($) => y(0, $) }), ($, O) => (P(), Me(Qr, {
      "multi-calendars": m(c).count,
      collapse: $.collapse,
      stretch: "",
      "is-mobile": $.isMobile
    }, {
      default: me(({ instance: N }) => [
        $.$slots["top-extra"] ? se($.$slots, "top-extra", {
          key: 0,
          value: $.internalModelValue
        }) : G("", !0),
        $.$slots["month-year"] ? se($.$slots, "month-year", bt(Ue({ key: 1 }, {
          year: m(d),
          months: m(o)(N),
          years: m(s)(N),
          selectMonth: m(p),
          selectYear: m(w),
          instance: N
        }))) : (P(), Me(Xn, {
          key: 2,
          items: m(o)(N),
          "arrow-navigation": $.arrowNavigation,
          "is-last": $.autoApply && !m(f).keepActionRow,
          "esc-close": $.escClose,
          height: m(f).modeHeight,
          config: $.config,
          "no-overlay-focus": !!($.noOverlayFocus || $.textInput),
          "use-relative": "",
          type: "month",
          onSelected: (M) => m(p)(M, N),
          onHoverValue: (M) => m(C)(M, N)
        }, kt({
          header: me(() => [
            $e(ac, Ue($.$props, {
              items: m(s)(N),
              instance: N,
              "show-year-picker": m(v)[N],
              year: m(d)(N),
              "is-disabled": (M) => m(u)(N, M),
              onHandleYear: (M) => m(D)(N, M),
              onYearSelect: (M) => m(x)(M, N),
              onToggleYearPicker: (M) => m(y)(N, M == null ? void 0 : M.flow, M == null ? void 0 : M.show)
            }), kt({ _: 2 }, [
              Fe(m(i), (M, B) => ({
                name: M,
                fn: me((X) => [
                  se($.$slots, M, bt(Pt(X)))
                ])
              }))
            ]), 1040, ["items", "instance", "show-year-picker", "year", "is-disabled", "onHandleYear", "onYearSelect", "onToggleYearPicker"])
          ]),
          _: 2
        }, [
          $.$slots["month-overlay-value"] ? {
            name: "item",
            fn: me(({ item: M }) => [
              se($.$slots, "month-overlay-value", {
                text: M.text,
                value: M.value
              })
            ]),
            key: "0"
          } : void 0
        ]), 1032, ["items", "arrow-navigation", "is-last", "esc-close", "height", "config", "no-overlay-focus", "onSelected", "onHoverValue"]))
      ]),
      _: 3
    }, 8, ["multi-calendars", "collapse", "is-mobile"]));
  }
}), rh = (e, a) => {
  const t = () => {
    e.isTextInputDate && (u.value = Pe(ee(e.startDate)));
  }, { modelValue: n } = Gn(e, a, t), r = z(null), { defaultedHighlight: i, defaultedMultiDates: l, defaultedFilters: o, defaultedRange: s, propDates: d } = Je(e), u = z();
  He(() => {
    e.startDate && (n.value && e.focusStartDate || !n.value) && (u.value = Pe(ee(e.startDate)));
  });
  const c = (b) => Array.isArray(n.value) ? n.value.some((C) => Pe(C) === b) : n.value ? Pe(n.value) === b : !1, f = (b) => s.value.enabled && Array.isArray(n.value) ? Kr(n.value, r.value, g(b)) : !1, v = A(() => yn(Li(e.yearRange, e.locale, e.reverseYears), (b) => {
    const C = c(b.value), p = Vn(
      b.value,
      gn(d.value.minDate),
      gn(d.value.maxDate)
    ) || o.value.years.includes(b.value), w = f(b.value) && !C, y = Fi(i.value, b.value);
    return { active: C, disabled: p, isBetween: w, highlighted: y };
  })), g = (b) => aa(jt(zn(/* @__PURE__ */ new Date())), b);
  return {
    groupedYears: v,
    modelValue: n,
    focusYear: u,
    setHoverValue: (b) => {
      r.value = aa(jt(/* @__PURE__ */ new Date()), b);
    },
    selectYear: (b) => {
      var C;
      if (a("update-month-year", { instance: 0, year: b }), l.value.enabled)
        return n.value ? Array.isArray(n.value) && (((C = n.value) == null ? void 0 : C.map((p) => Pe(p))).includes(b) ? n.value = n.value.filter((p) => Pe(p) !== b) : n.value.push(aa(St(ee()), b))) : n.value = [aa(St(zn(ee())), b)], a("auto-apply", !0);
      s.value.enabled ? (n.value = Yi(n, g(b), a), Ne().then(() => {
        Gr(n.value, a, e.autoApply, e.modelAuto);
      })) : (n.value = g(b), a("auto-apply"));
    }
  };
}, lh = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "YearPicker",
  props: {
    ...Xt
  },
  emits: [
    "update:internal-model-value",
    "reset-flow",
    "range-start",
    "range-end",
    "auto-apply",
    "update-month-year"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, { groupedYears: i, modelValue: l, focusYear: o, selectYear: s, setHoverValue: d } = rh(r, n), { defaultedConfig: u } = Je(r);
    return a({ getSidebarProps: () => ({
      modelValue: l,
      selectYear: s
    }) }), (c, f) => (P(), Y("div", null, [
      c.$slots["top-extra"] ? se(c.$slots, "top-extra", {
        key: 0,
        value: c.internalModelValue
      }) : G("", !0),
      c.$slots["month-year"] ? se(c.$slots, "month-year", bt(Ue({ key: 1 }, {
        years: m(i),
        selectYear: m(s)
      }))) : (P(), Me(Xn, {
        key: 2,
        items: m(i),
        "is-last": c.autoApply && !m(u).keepActionRow,
        height: m(u).modeHeight,
        config: c.config,
        "no-overlay-focus": !!(c.noOverlayFocus || c.textInput),
        "focus-value": m(o),
        type: "year",
        "use-relative": "",
        onSelected: m(s),
        onHoverValue: m(d)
      }, kt({ _: 2 }, [
        c.$slots["year-overlay-value"] ? {
          name: "item",
          fn: me(({ item: v }) => [
            se(c.$slots, "year-overlay-value", {
              text: v.text,
              value: v.value
            })
          ]),
          key: "0"
        } : void 0
      ]), 1032, ["items", "is-last", "height", "config", "no-overlay-focus", "focus-value", "onSelected", "onHoverValue"]))
    ]));
  }
}), ih = {
  key: 0,
  class: "dp__time_input"
}, oh = ["data-compact", "data-collapsed"], sh = ["data-test-id", "aria-label", "onKeydown", "onClick", "onMousedown"], uh = ["aria-label", "disabled", "data-test-id", "onKeydown", "onClick"], ch = ["data-test-id", "aria-label", "onKeydown", "onClick", "onMousedown"], dh = { key: 0 }, fh = ["aria-label", "data-compact"], vh = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "TimeInput",
  props: {
    hours: { type: Number, default: 0 },
    minutes: { type: Number, default: 0 },
    seconds: { type: Number, default: 0 },
    closeTimePickerBtn: { type: Object, default: null },
    order: { type: Number, default: 0 },
    disabledTimesConfig: { type: Function, default: null },
    validateTime: { type: Function, default: () => !1 },
    ...Xt
  },
  emits: [
    "set-hours",
    "set-minutes",
    "update:hours",
    "update:minutes",
    "update:seconds",
    "reset-flow",
    "mounted",
    "overlay-closed",
    "overlay-opened",
    "am-pm-change"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, { setTimePickerElements: i, setTimePickerBackRef: l } = Ea(), {
      defaultedAriaLabels: o,
      defaultedTransitions: s,
      defaultedFilters: d,
      defaultedConfig: u,
      defaultedRange: c,
      defaultedMultiCalendars: f
    } = Je(r), { transitionName: v, showTransition: g } = Qn(s), b = qa({
      hours: !1,
      minutes: !1,
      seconds: !1
    }), C = z("AM"), p = z(null), w = z([]), y = z(), x = z(!1);
    He(() => {
      n("mounted");
    });
    const D = (k) => je(/* @__PURE__ */ new Date(), {
      hours: k.hours,
      minutes: k.minutes,
      seconds: r.enableSeconds ? k.seconds : 0,
      milliseconds: 0
    }), S = A(
      () => (k) => U(k, r[k]) || O(k, r[k])
    ), $ = A(() => ({ hours: r.hours, minutes: r.minutes, seconds: r.seconds })), O = (k, Q) => c.value.enabled && !c.value.disableTimeRangeValidation ? !r.validateTime(k, Q) : !1, N = (k, Q) => {
      if (c.value.enabled && !c.value.disableTimeRangeValidation) {
        const Ae = Q ? +r[`${k}Increment`] : -+r[`${k}Increment`], oe = r[k] + Ae;
        return !r.validateTime(k, oe);
      }
      return !1;
    }, M = A(() => (k) => !h(+r[k] + +r[`${k}Increment`], k) || N(k, !0)), B = A(() => (k) => !h(+r[k] - +r[`${k}Increment`], k) || N(k, !1)), X = (k, Q) => ku(je(ee(), k), Q), te = (k, Q) => o2(je(ee(), k), Q), T = A(
      () => ({
        dp__time_col: !0,
        dp__time_col_block: !r.timePickerInline,
        dp__time_col_reg_block: !r.enableSeconds && r.is24 && !r.timePickerInline,
        dp__time_col_reg_inline: !r.enableSeconds && r.is24 && r.timePickerInline,
        dp__time_col_reg_with_button: !r.enableSeconds && !r.is24,
        dp__time_col_sec: r.enableSeconds && r.is24,
        dp__time_col_sec_with_button: r.enableSeconds && !r.is24
      })
    ), E = A(
      () => r.timePickerInline && c.value.enabled && !f.value.count
    ), I = A(() => {
      const k = [{ type: "hours" }];
      return r.enableMinutes && k.push({ type: "", separator: !0 }, {
        type: "minutes"
      }), r.enableSeconds && k.push({ type: "", separator: !0 }, {
        type: "seconds"
      }), k;
    }), q = A(() => I.value.filter((k) => !k.separator)), ne = A(() => (k) => {
      if (k === "hours") {
        const Q = ft(+r.hours);
        return { text: Q < 10 ? `0${Q}` : `${Q}`, value: Q };
      }
      return { text: r[k] < 10 ? `0${r[k]}` : `${r[k]}`, value: r[k] };
    }), U = (k, Q) => {
      var Ae;
      if (!r.disabledTimesConfig)
        return !1;
      const oe = r.disabledTimesConfig(r.order, k === "hours" ? Q : void 0);
      return oe[k] ? !!((Ae = oe[k]) != null && Ae.includes(Q)) : !0;
    }, L = (k, Q) => Q !== "hours" || C.value === "AM" ? k : k + 12, ue = (k) => {
      const Q = r.is24 ? 24 : 12, Ae = k === "hours" ? Q : 60, oe = +r[`${k}GridIncrement`], ze = k === "hours" && !r.is24 ? oe : 0, Ie = [];
      for (let F = ze; F < Ae; F += oe)
        Ie.push({ value: r.is24 ? F : L(F, k), text: F < 10 ? `0${F}` : `${F}` });
      return k === "hours" && !r.is24 && Ie.unshift({ value: C.value === "PM" ? 12 : 0, text: "12" }), yn(Ie, (F) => ({ active: !1, disabled: d.value.times[k].includes(F.value) || !h(F.value, k) || U(k, F.value) || O(k, F.value) }));
    }, K = (k) => k >= 0 ? k : 59, V = (k) => k >= 0 ? k : 23, h = (k, Q) => {
      const Ae = r.minTime ? D(hl(r.minTime)) : null, oe = r.maxTime ? D(hl(r.maxTime)) : null, ze = D(
        hl(
          $.value,
          Q,
          Q === "minutes" || Q === "seconds" ? K(k) : V(k)
        )
      );
      return Ae && oe ? (Fn(ze, oe) || ln(ze, oe)) && (pn(ze, Ae) || ln(ze, Ae)) : Ae ? pn(ze, Ae) || ln(ze, Ae) : oe ? Fn(ze, oe) || ln(ze, oe) : !0;
    }, R = (k) => r[`no${k[0].toUpperCase() + k.slice(1)}Overlay`], Z = (k) => {
      R(k) || (b[k] = !b[k], b[k] ? (x.value = !0, n("overlay-opened", k)) : (x.value = !1, n("overlay-closed", k)));
    }, _ = (k) => k === "hours" ? va : k === "minutes" ? Aa : mn, ce = () => {
      y.value && clearTimeout(y.value);
    }, ke = (k, Q = !0, Ae) => {
      const oe = Q ? X : te, ze = Q ? +r[`${k}Increment`] : -+r[`${k}Increment`];
      h(+r[k] + ze, k) && n(
        `update:${k}`,
        _(k)(oe({ [k]: +r[k] }, { [k]: +r[`${k}Increment`] }))
      ), !(Ae != null && Ae.keyboard) && u.value.timeArrowHoldThreshold && (y.value = setTimeout(() => {
        ke(k, Q);
      }, u.value.timeArrowHoldThreshold));
    }, ft = (k) => r.is24 ? k : (k >= 12 ? C.value = "PM" : C.value = "AM", v2(k)), j = () => {
      C.value === "PM" ? (C.value = "AM", n("update:hours", r.hours - 12)) : (C.value = "PM", n("update:hours", r.hours + 12)), n("am-pm-change", C.value);
    }, ae = (k) => {
      b[k] = !0;
    }, J = (k, Q, Ae) => {
      if (k && r.arrowNavigation) {
        Array.isArray(w.value[Q]) ? w.value[Q][Ae] = k : w.value[Q] = [k];
        const oe = w.value.reduce(
          (ze, Ie) => Ie.map((F, re) => [...ze[re] || [], Ie[re]]),
          []
        );
        l(r.closeTimePickerBtn), p.value && (oe[1] = oe[1].concat(p.value)), i(oe, r.order);
      }
    }, fe = (k, Q) => (Z(k), n(`update:${k}`, Q));
    return a({ openChildCmp: ae }), (k, Q) => {
      var Ae;
      return k.disabled ? G("", !0) : (P(), Y("div", ih, [
        (P(!0), Y(Se, null, Fe(I.value, (oe, ze) => {
          var Ie, F, re;
          return P(), Y("div", {
            key: ze,
            class: ye(T.value),
            "data-compact": E.value && !k.enableSeconds,
            "data-collapsed": E.value && k.enableSeconds
          }, [
            oe.separator ? (P(), Y(Se, { key: 0 }, [
              x.value ? G("", !0) : (P(), Y(Se, { key: 0 }, [
                vt(":")
              ], 64))
            ], 64)) : (P(), Y(Se, { key: 1 }, [
              ie("button", {
                ref_for: !0,
                ref: (xe) => J(xe, ze, 0),
                type: "button",
                class: ye({
                  dp__btn: !0,
                  dp__inc_dec_button: !k.timePickerInline,
                  dp__inc_dec_button_inline: k.timePickerInline,
                  dp__tp_inline_btn_top: k.timePickerInline,
                  dp__inc_dec_button_disabled: M.value(oe.type),
                  "dp--hidden-el": x.value
                }),
                "data-test-id": `${oe.type}-time-inc-btn-${r.order}`,
                "aria-label": (Ie = m(o)) == null ? void 0 : Ie.incrementValue(oe.type),
                tabindex: "0",
                onKeydown: (xe) => m(Ot)(xe, () => ke(oe.type, !0, { keyboard: !0 }), !0),
                onClick: (xe) => m(u).timeArrowHoldThreshold ? void 0 : ke(oe.type, !0),
                onMousedown: (xe) => m(u).timeArrowHoldThreshold ? ke(oe.type, !0) : void 0,
                onMouseup: ce
              }, [
                r.timePickerInline ? (P(), Y(Se, { key: 1 }, [
                  k.$slots["tp-inline-arrow-up"] ? se(k.$slots, "tp-inline-arrow-up", { key: 0 }) : (P(), Y(Se, { key: 1 }, [
                    Q[2] || (Q[2] = ie("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_l" }, null, -1)),
                    Q[3] || (Q[3] = ie("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_r" }, null, -1))
                  ], 64))
                ], 64)) : (P(), Y(Se, { key: 0 }, [
                  k.$slots["arrow-up"] ? se(k.$slots, "arrow-up", { key: 0 }) : G("", !0),
                  k.$slots["arrow-up"] ? G("", !0) : (P(), Me(m($i), { key: 1 }))
                ], 64))
              ], 42, sh),
              ie("button", {
                ref_for: !0,
                ref: (xe) => J(xe, ze, 1),
                type: "button",
                "aria-label": `${ne.value(oe.type).text}-${(F = m(o)) == null ? void 0 : F.openTpOverlay(oe.type)}`,
                class: ye({
                  dp__time_display: !0,
                  dp__time_display_block: !k.timePickerInline,
                  dp__time_display_inline: k.timePickerInline,
                  "dp--time-invalid": S.value(oe.type),
                  "dp--time-overlay-btn": !S.value(oe.type),
                  "dp--hidden-el": x.value
                }),
                disabled: R(oe.type),
                tabindex: "0",
                "data-test-id": `${oe.type}-toggle-overlay-btn-${r.order}`,
                onKeydown: (xe) => m(Ot)(xe, () => Z(oe.type), !0),
                onClick: (xe) => Z(oe.type)
              }, [
                k.$slots[oe.type] ? se(k.$slots, oe.type, {
                  key: 0,
                  text: ne.value(oe.type).text,
                  value: ne.value(oe.type).value
                }) : G("", !0),
                k.$slots[oe.type] ? G("", !0) : (P(), Y(Se, { key: 1 }, [
                  vt(Le(ne.value(oe.type).text), 1)
                ], 64))
              ], 42, uh),
              ie("button", {
                ref_for: !0,
                ref: (xe) => J(xe, ze, 2),
                type: "button",
                class: ye({
                  dp__btn: !0,
                  dp__inc_dec_button: !k.timePickerInline,
                  dp__inc_dec_button_inline: k.timePickerInline,
                  dp__tp_inline_btn_bottom: k.timePickerInline,
                  dp__inc_dec_button_disabled: B.value(oe.type),
                  "dp--hidden-el": x.value
                }),
                "data-test-id": `${oe.type}-time-dec-btn-${r.order}`,
                "aria-label": (re = m(o)) == null ? void 0 : re.decrementValue(oe.type),
                tabindex: "0",
                onKeydown: (xe) => m(Ot)(xe, () => ke(oe.type, !1, { keyboard: !0 }), !0),
                onClick: (xe) => m(u).timeArrowHoldThreshold ? void 0 : ke(oe.type, !1),
                onMousedown: (xe) => m(u).timeArrowHoldThreshold ? ke(oe.type, !1) : void 0,
                onMouseup: ce
              }, [
                r.timePickerInline ? (P(), Y(Se, { key: 1 }, [
                  k.$slots["tp-inline-arrow-down"] ? se(k.$slots, "tp-inline-arrow-down", { key: 0 }) : (P(), Y(Se, { key: 1 }, [
                    Q[4] || (Q[4] = ie("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_l" }, null, -1)),
                    Q[5] || (Q[5] = ie("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_r" }, null, -1))
                  ], 64))
                ], 64)) : (P(), Y(Se, { key: 0 }, [
                  k.$slots["arrow-down"] ? se(k.$slots, "arrow-down", { key: 0 }) : G("", !0),
                  k.$slots["arrow-down"] ? G("", !0) : (P(), Me(m(Ri), { key: 1 }))
                ], 64))
              ], 42, ch)
            ], 64))
          ], 10, oh);
        }), 128)),
        k.is24 ? G("", !0) : (P(), Y("div", dh, [
          k.$slots["am-pm-button"] ? se(k.$slots, "am-pm-button", {
            key: 0,
            toggle: j,
            value: C.value
          }) : G("", !0),
          k.$slots["am-pm-button"] ? G("", !0) : (P(), Y("button", {
            key: 1,
            ref_key: "amPmButton",
            ref: p,
            type: "button",
            class: "dp__pm_am_button",
            role: "button",
            "aria-label": (Ae = m(o)) == null ? void 0 : Ae.amPmButton,
            tabindex: "0",
            "data-compact": E.value,
            onClick: j,
            onKeydown: Q[0] || (Q[0] = (oe) => m(Ot)(oe, () => j(), !0))
          }, Le(C.value), 41, fh))
        ])),
        (P(!0), Y(Se, null, Fe(q.value, (oe, ze) => (P(), Me(Da, {
          key: ze,
          name: m(v)(b[oe.type]),
          css: m(g)
        }, {
          default: me(() => {
            var Ie, F;
            return [
              b[oe.type] ? (P(), Me(Xn, {
                key: 0,
                items: ue(oe.type),
                "is-last": k.autoApply && !m(u).keepActionRow,
                "esc-close": k.escClose,
                type: oe.type,
                "text-input": k.textInput,
                config: k.config,
                "arrow-navigation": k.arrowNavigation,
                "aria-labels": k.ariaLabels,
                "overlay-label": (F = (Ie = m(o)).timeOverlay) == null ? void 0 : F.call(Ie, oe.type),
                onSelected: (re) => fe(oe.type, re),
                onToggle: (re) => Z(oe.type),
                onResetFlow: Q[1] || (Q[1] = (re) => k.$emit("reset-flow"))
              }, kt({
                "button-icon": me(() => [
                  k.$slots["clock-icon"] ? se(k.$slots, "clock-icon", { key: 0 }) : G("", !0),
                  k.$slots["clock-icon"] ? G("", !0) : (P(), Me(Dr(k.timePickerInline ? m(wn) : m(Ei)), { key: 1 }))
                ]),
                _: 2
              }, [
                k.$slots[`${oe.type}-overlay-value`] ? {
                  name: "item",
                  fn: me(({ item: re }) => [
                    se(k.$slots, `${oe.type}-overlay-value`, {
                      text: re.text,
                      value: re.value
                    })
                  ]),
                  key: "0"
                } : void 0,
                k.$slots[`${oe.type}-overlay-header`] ? {
                  name: "header",
                  fn: me(() => [
                    se(k.$slots, `${oe.type}-overlay-header`, {
                      toggle: () => Z(oe.type)
                    })
                  ]),
                  key: "1"
                } : void 0
              ]), 1032, ["items", "is-last", "esc-close", "type", "text-input", "config", "arrow-navigation", "aria-labels", "overlay-label", "onSelected", "onToggle"])) : G("", !0)
            ];
          }),
          _: 2
        }, 1032, ["name", "css"]))), 128))
      ]));
    };
  }
}), mh = ["data-dp-mobile"], ph = ["aria-label", "tabindex"], hh = ["role", "aria-label", "tabindex"], yh = ["aria-label"], ic = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "TimePicker",
  props: {
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    seconds: { type: [Number, Array], default: 0 },
    disabledTimesConfig: { type: Function, default: null },
    validateTime: {
      type: Function,
      default: () => !1
    },
    ...Xt
  },
  emits: [
    "update:hours",
    "update:minutes",
    "update:seconds",
    "mount",
    "reset-flow",
    "overlay-opened",
    "overlay-closed",
    "am-pm-change"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, { buildMatrix: i, setTimePicker: l } = Ea(), o = Ka(), { defaultedTransitions: s, defaultedAriaLabels: d, defaultedTextInput: u, defaultedConfig: c, defaultedRange: f } = Je(r), { transitionName: v, showTransition: g } = Qn(s), { hideNavigationButtons: b } = Jr(), C = z(null), p = z(null), w = z([]), y = z(null), x = z(!1);
    He(() => {
      n("mount"), !r.timePicker && r.arrowNavigation ? i([ht(C.value)], "time") : l(!0, r.timePicker);
    });
    const D = A(() => f.value.enabled && r.modelAuto ? ju(r.internalModelValue) : !0), S = z(!1), $ = (U) => ({
      hours: Array.isArray(r.hours) ? r.hours[U] : r.hours,
      minutes: Array.isArray(r.minutes) ? r.minutes[U] : r.minutes,
      seconds: Array.isArray(r.seconds) ? r.seconds[U] : r.seconds
    }), O = A(() => {
      const U = [];
      if (f.value.enabled)
        for (let L = 0; L < 2; L++)
          U.push($(L));
      else
        U.push($(0));
      return U;
    }), N = (U, L = !1, ue = "") => {
      L || n("reset-flow"), S.value = U, n(U ? "overlay-opened" : "overlay-closed", xt.time), r.arrowNavigation && l(U), Ne(() => {
        ue !== "" && w.value[0] && w.value[0].openChildCmp(ue);
      });
    }, M = A(() => ({
      dp__btn: !0,
      dp__button: !0,
      dp__button_bottom: r.autoApply && !c.value.keepActionRow
    })), B = Et(o, "timePicker"), X = (U, L, ue) => f.value.enabled ? L === 0 ? [U, O.value[1][ue]] : [O.value[0][ue], U] : U, te = (U) => {
      n("update:hours", U);
    }, T = (U) => {
      n("update:minutes", U);
    }, E = (U) => {
      n("update:seconds", U);
    }, I = () => {
      if (y.value && !u.value.enabled && !r.noOverlayFocus) {
        const U = Uu(y.value);
        U && U.focus({ preventScroll: !0 });
      }
    }, q = (U) => {
      x.value = !1, n("overlay-closed", U);
    }, ne = (U) => {
      x.value = !0, n("overlay-opened", U);
    };
    return a({ toggleTimePicker: N }), (U, L) => {
      var ue;
      return P(), Y("div", {
        class: "dp--tp-wrap",
        "data-dp-mobile": U.isMobile
      }, [
        !U.timePicker && !U.timePickerInline ? ka((P(), Y("button", {
          key: 0,
          ref_key: "openTimePickerBtn",
          ref: C,
          type: "button",
          class: ye({ ...M.value, "dp--hidden-el": S.value }),
          "aria-label": (ue = m(d)) == null ? void 0 : ue.openTimePicker,
          tabindex: U.noOverlayFocus ? void 0 : 0,
          "data-test-id": "open-time-picker-btn",
          onKeydown: L[0] || (L[0] = (K) => m(Ot)(K, () => N(!0))),
          onClick: L[1] || (L[1] = (K) => N(!0))
        }, [
          U.$slots["clock-icon"] ? se(U.$slots, "clock-icon", { key: 0 }) : G("", !0),
          U.$slots["clock-icon"] ? G("", !0) : (P(), Me(m(Ei), { key: 1 }))
        ], 42, ph)), [
          [mr, !m(b)(U.hideNavigation, "time")]
        ]) : G("", !0),
        $e(Da, {
          name: m(v)(S.value),
          css: m(g) && !U.timePickerInline
        }, {
          default: me(() => {
            var K, V;
            return [
              S.value || U.timePicker || U.timePickerInline ? (P(), Y("div", {
                key: 0,
                ref_key: "overlayRef",
                ref: y,
                role: U.timePickerInline ? void 0 : "dialog",
                class: ye({
                  dp__overlay: !U.timePickerInline,
                  "dp--overlay-absolute": !r.timePicker && !U.timePickerInline,
                  "dp--overlay-relative": r.timePicker
                }),
                style: Ze(U.timePicker ? { height: `${m(c).modeHeight}px` } : void 0),
                "aria-label": (K = m(d)) == null ? void 0 : K.timePicker,
                tabindex: U.timePickerInline ? void 0 : 0
              }, [
                ie("div", {
                  class: ye(
                    U.timePickerInline ? "dp__time_picker_inline_container" : "dp__overlay_container dp__container_flex dp__time_picker_overlay_container"
                  ),
                  style: { display: "flex" }
                }, [
                  U.$slots["time-picker-overlay"] ? se(U.$slots, "time-picker-overlay", {
                    key: 0,
                    hours: e.hours,
                    minutes: e.minutes,
                    seconds: e.seconds,
                    setHours: te,
                    setMinutes: T,
                    setSeconds: E
                  }) : G("", !0),
                  U.$slots["time-picker-overlay"] ? G("", !0) : (P(), Y("div", {
                    key: 1,
                    class: ye(U.timePickerInline ? "dp__flex" : "dp__overlay_row dp__flex_row")
                  }, [
                    (P(!0), Y(Se, null, Fe(O.value, (h, R) => ka((P(), Me(vh, Ue({
                      key: R,
                      ref_for: !0
                    }, {
                      ...U.$props,
                      order: R,
                      hours: h.hours,
                      minutes: h.minutes,
                      seconds: h.seconds,
                      closeTimePickerBtn: p.value,
                      disabledTimesConfig: e.disabledTimesConfig,
                      disabled: R === 0 ? m(f).fixedStart : m(f).fixedEnd
                    }, {
                      ref_for: !0,
                      ref_key: "timeInputRefs",
                      ref: w,
                      "validate-time": (Z, _) => e.validateTime(Z, X(_, R, Z)),
                      "onUpdate:hours": (Z) => te(X(Z, R, "hours")),
                      "onUpdate:minutes": (Z) => T(X(Z, R, "minutes")),
                      "onUpdate:seconds": (Z) => E(X(Z, R, "seconds")),
                      onMounted: I,
                      onOverlayClosed: q,
                      onOverlayOpened: ne,
                      onAmPmChange: L[2] || (L[2] = (Z) => U.$emit("am-pm-change", Z))
                    }), kt({ _: 2 }, [
                      Fe(m(B), (Z, _) => ({
                        name: Z,
                        fn: me((ce) => [
                          se(U.$slots, Z, Ue({ ref_for: !0 }, ce))
                        ])
                      }))
                    ]), 1040, ["validate-time", "onUpdate:hours", "onUpdate:minutes", "onUpdate:seconds"])), [
                      [mr, R === 0 ? !0 : D.value]
                    ])), 128))
                  ], 2)),
                  !U.timePicker && !U.timePickerInline ? ka((P(), Y("button", {
                    key: 2,
                    ref_key: "closeTimePickerBtn",
                    ref: p,
                    type: "button",
                    class: ye({ ...M.value, "dp--hidden-el": x.value }),
                    "aria-label": (V = m(d)) == null ? void 0 : V.closeTimePicker,
                    tabindex: "0",
                    onKeydown: L[3] || (L[3] = (h) => m(Ot)(h, () => N(!1))),
                    onClick: L[4] || (L[4] = (h) => N(!1))
                  }, [
                    U.$slots["calendar-icon"] ? se(U.$slots, "calendar-icon", { key: 0 }) : G("", !0),
                    U.$slots["calendar-icon"] ? G("", !0) : (P(), Me(m(wn), { key: 1 }))
                  ], 42, yh)), [
                    [mr, !m(b)(U.hideNavigation, "time")]
                  ]) : G("", !0)
                ], 2)
              ], 14, hh)) : G("", !0)
            ];
          }),
          _: 3
        }, 8, ["name", "css"])
      ], 8, mh);
    };
  }
}), oc = (e, a, t, n) => {
  const { defaultedRange: r } = Je(e), i = (y, x) => Array.isArray(a[y]) ? a[y][x] : a[y], l = (y) => e.enableSeconds ? Array.isArray(a.seconds) ? a.seconds[y] : a.seconds : 0, o = (y, x) => y ? x !== void 0 ? Ma(y, i("hours", x), i("minutes", x), l(x)) : Ma(y, a.hours, a.minutes, l()) : Fu(ee(), l(x)), s = (y, x) => {
    a[y] = x;
  }, d = A(() => e.modelAuto && r.value.enabled ? Array.isArray(t.value) ? t.value.length > 1 : !1 : r.value.enabled), u = (y, x) => {
    const D = Object.fromEntries(
      Object.keys(a).map((S) => S === y ? [S, x] : [S, a[S]].slice())
    );
    if (d.value && !r.value.disableTimeRangeValidation) {
      const S = (O) => t.value ? Ma(
        t.value[O],
        D.hours[O],
        D.minutes[O],
        D.seconds[O]
      ) : null, $ = (O) => zu(t.value[O], 0);
      return !(Re(S(0), S(1)) && (pn(S(0), $(1)) || Fn(S(1), $(0))));
    }
    return !0;
  }, c = (y, x) => {
    u(y, x) && (s(y, x), n && n());
  }, f = (y) => {
    c("hours", y);
  }, v = (y) => {
    c("minutes", y);
  }, g = (y) => {
    c("seconds", y);
  }, b = (y, x, D, S) => {
    x && f(y), !x && !D && v(y), D && g(y), t.value && S(t.value);
  }, C = (y) => {
    if (y) {
      const x = Array.isArray(y), D = x ? [+y[0].hours, +y[1].hours] : +y.hours, S = x ? [+y[0].minutes, +y[1].minutes] : +y.minutes, $ = x ? [+y[0].seconds, +y[1].seconds] : +y.seconds;
      s("hours", D), s("minutes", S), e.enableSeconds && s("seconds", $);
    }
  }, p = (y, x) => {
    const D = {
      hours: Array.isArray(a.hours) ? a.hours[y] : a.hours,
      disabledArr: []
    };
    return (x || x === 0) && (D.hours = x), Array.isArray(e.disabledTimes) && (D.disabledArr = r.value.enabled && Array.isArray(e.disabledTimes[y]) ? e.disabledTimes[y] : e.disabledTimes), D;
  }, w = A(() => (y, x) => {
    var D;
    if (Array.isArray(e.disabledTimes)) {
      const { disabledArr: S, hours: $ } = p(y, x), O = S.filter((N) => +N.hours === $);
      return ((D = O[0]) == null ? void 0 : D.minutes) === "*" ? { hours: [$], minutes: void 0, seconds: void 0 } : {
        hours: [],
        minutes: (O == null ? void 0 : O.map((N) => +N.minutes)) ?? [],
        seconds: (O == null ? void 0 : O.map((N) => N.seconds ? +N.seconds : void 0)) ?? []
      };
    }
    return { hours: [], minutes: [], seconds: [] };
  });
  return {
    setTime: s,
    updateHours: f,
    updateMinutes: v,
    updateSeconds: g,
    getSetDateTime: o,
    updateTimeValues: b,
    getSecondsValue: l,
    assignStartTime: C,
    validateTime: u,
    disabledTimesConfig: w
  };
}, gh = (e, a) => {
  const t = () => {
    e.isTextInputDate && x();
  }, { modelValue: n, time: r } = Gn(e, a, t), { defaultedStartTime: i, defaultedRange: l, defaultedTz: o } = Je(e), { updateTimeValues: s, getSetDateTime: d, setTime: u, assignStartTime: c, disabledTimesConfig: f, validateTime: v } = oc(e, r, n, g);
  function g() {
    a("update-flow-step");
  }
  const b = (S) => {
    const { hours: $, minutes: O, seconds: N } = S;
    return { hours: +$, minutes: +O, seconds: N ? +N : 0 };
  }, C = () => {
    if (e.startTime) {
      if (Array.isArray(e.startTime)) {
        const $ = b(e.startTime[0]), O = b(e.startTime[1]);
        return [je(ee(), $), je(ee(), O)];
      }
      const S = b(e.startTime);
      return je(ee(), S);
    }
    return l.value.enabled ? [null, null] : null;
  }, p = () => {
    if (l.value.enabled) {
      const [S, $] = C();
      n.value = [
        Ct(d(S, 0), o.value.timezone),
        Ct(d($, 1), o.value.timezone)
      ];
    } else
      n.value = Ct(d(C()), o.value.timezone);
  }, w = (S) => Array.isArray(S) ? [Ya(ee(S[0])), Ya(ee(S[1]))] : [Ya(S ?? ee())], y = (S, $, O) => {
    u("hours", S), u("minutes", $), u("seconds", e.enableSeconds ? O : 0);
  }, x = () => {
    const [S, $] = w(n.value);
    return l.value.enabled ? y(
      [S.hours, $.hours],
      [S.minutes, $.minutes],
      [S.seconds, $.seconds]
    ) : y(S.hours, S.minutes, S.seconds);
  };
  He(() => {
    if (!e.shadow)
      return c(i.value), n.value ? x() : p();
  });
  const D = () => {
    Array.isArray(n.value) ? n.value = n.value.map((S, $) => S && d(S, $)) : n.value = d(n.value), a("time-update");
  };
  return {
    modelValue: n,
    time: r,
    disabledTimesConfig: f,
    updateTime: (S, $ = !0, O = !1) => {
      s(S, $, O, D);
    },
    validateTime: v
  };
}, bh = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "TimePickerSolo",
  props: {
    ...Xt
  },
  emits: [
    "update:internal-model-value",
    "time-update",
    "am-pm-change",
    "mount",
    "reset-flow",
    "update-flow-step",
    "overlay-toggle"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, i = Ka(), l = Et(i, "timePicker"), o = z(null), { time: s, modelValue: d, disabledTimesConfig: u, updateTime: c, validateTime: f } = gh(r, n);
    return He(() => {
      r.shadow || n("mount", null);
    }), a({ getSidebarProps: () => ({
      modelValue: d,
      time: s,
      updateTime: c
    }), toggleTimePicker: (v, g = !1, b = "") => {
      var C;
      (C = o.value) == null || C.toggleTimePicker(v, g, b);
    } }), (v, g) => (P(), Me(Qr, {
      "multi-calendars": 0,
      stretch: "",
      "is-mobile": v.isMobile
    }, {
      default: me(() => [
        $e(ic, Ue({
          ref_key: "tpRef",
          ref: o
        }, v.$props, {
          hours: m(s).hours,
          minutes: m(s).minutes,
          seconds: m(s).seconds,
          "internal-model-value": v.internalModelValue,
          "disabled-times-config": m(u),
          "validate-time": m(f),
          "onUpdate:hours": g[0] || (g[0] = (b) => m(c)(b)),
          "onUpdate:minutes": g[1] || (g[1] = (b) => m(c)(b, !1)),
          "onUpdate:seconds": g[2] || (g[2] = (b) => m(c)(b, !1, !0)),
          onAmPmChange: g[3] || (g[3] = (b) => v.$emit("am-pm-change", b)),
          onResetFlow: g[4] || (g[4] = (b) => v.$emit("reset-flow")),
          onOverlayClosed: g[5] || (g[5] = (b) => v.$emit("overlay-toggle", { open: !1, overlay: b })),
          onOverlayOpened: g[6] || (g[6] = (b) => v.$emit("overlay-toggle", { open: !0, overlay: b }))
        }), kt({ _: 2 }, [
          Fe(m(l), (b, C) => ({
            name: b,
            fn: me((p) => [
              se(v.$slots, b, bt(Pt(p)))
            ])
          }))
        ]), 1040, ["hours", "minutes", "seconds", "internal-model-value", "disabled-times-config", "validate-time"])
      ]),
      _: 3
    }, 8, ["is-mobile"]));
  }
}), wh = { class: "dp--header-wrap" }, xh = {
  key: 0,
  class: "dp__month_year_wrap"
}, kh = { key: 0 }, _h = { class: "dp__month_year_wrap" }, Mh = ["data-dp-element", "aria-label", "data-test-id", "onClick", "onKeydown"], Oh = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "DpHeader",
  props: {
    month: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    instance: { type: Number, default: 0 },
    years: { type: Array, default: () => [] },
    months: { type: Array, default: () => [] },
    ...Xt
  },
  emits: ["update-month-year", "mount", "reset-flow", "overlay-closed", "overlay-opened"],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, {
      defaultedTransitions: i,
      defaultedAriaLabels: l,
      defaultedMultiCalendars: o,
      defaultedFilters: s,
      defaultedConfig: d,
      defaultedHighlight: u,
      propDates: c,
      defaultedUI: f
    } = Je(r), { transitionName: v, showTransition: g } = Qn(i), { buildMatrix: b } = Ea(), { handleMonthYearChange: C, isDisabled: p, updateMonthYear: w } = U2(r, n), { showLeftIcon: y, showRightIcon: x } = Jr(), D = z(!1), S = z(!1), $ = z(!1), O = z([null, null, null, null]);
    He(() => {
      n("mount");
    });
    const N = (V) => ({
      get: () => r[V],
      set: (h) => {
        const R = V === Ft.month ? Ft.year : Ft.month;
        n("update-month-year", { [V]: h, [R]: r[R] }), V === Ft.month ? q(!0) : ne(!0);
      }
    }), M = A(N(Ft.month)), B = A(N(Ft.year)), X = A(() => (V) => ({
      month: r.month,
      year: r.year,
      items: V === Ft.month ? r.months : r.years,
      instance: r.instance,
      updateMonthYear: w,
      toggle: V === Ft.month ? q : ne
    })), te = A(() => r.months.find((h) => h.value === r.month) || { text: "", value: 0 }), T = A(() => yn(r.months, (V) => {
      const h = r.month === V.value, R = Vn(
        V.value,
        qu(r.year, c.value.minDate),
        Ku(r.year, c.value.maxDate)
      ) || s.value.months.includes(V.value), Z = Zu(u.value, V.value, r.year);
      return { active: h, disabled: R, highlighted: Z };
    })), E = A(() => yn(r.years, (V) => {
      const h = r.year === V.value, R = Vn(
        V.value,
        gn(c.value.minDate),
        gn(c.value.maxDate)
      ) || s.value.years.includes(V.value), Z = Fi(u.value, V.value);
      return { active: h, disabled: R, highlighted: Z };
    })), I = (V, h, R) => {
      R !== void 0 ? V.value = R : V.value = !V.value, V.value ? ($.value = !0, n("overlay-opened", h)) : ($.value = !1, n("overlay-closed", h));
    }, q = (V = !1, h) => {
      U(V), I(D, xt.month, h);
    }, ne = (V = !1, h) => {
      U(V), I(S, xt.year, h);
    }, U = (V) => {
      V || n("reset-flow");
    }, L = (V, h) => {
      r.arrowNavigation && (O.value[h] = ht(V), b(O.value, "monthYear"));
    }, ue = A(() => {
      var V, h, R, Z, _, ce;
      return [
        {
          type: Ft.month,
          index: 1,
          toggle: q,
          modelValue: M.value,
          updateModelValue: (ke) => M.value = ke,
          text: te.value.text,
          showSelectionGrid: D.value,
          items: T.value,
          ariaLabel: (V = l.value) == null ? void 0 : V.openMonthsOverlay,
          overlayLabel: ((R = (h = l.value).monthPicker) == null ? void 0 : R.call(h, !0)) ?? void 0
        },
        {
          type: Ft.year,
          index: 2,
          toggle: ne,
          modelValue: B.value,
          updateModelValue: (ke) => B.value = ke,
          text: Wu(r.year, r.locale),
          showSelectionGrid: S.value,
          items: E.value,
          ariaLabel: (Z = l.value) == null ? void 0 : Z.openYearsOverlay,
          overlayLabel: ((ce = (_ = l.value).yearPicker) == null ? void 0 : ce.call(_, !0)) ?? void 0
        }
      ];
    }), K = A(() => r.disableYearSelect ? [ue.value[0]] : r.yearFirst ? [...ue.value].reverse() : ue.value);
    return a({
      toggleMonthPicker: q,
      toggleYearPicker: ne,
      handleMonthYearChange: C
    }), (V, h) => {
      var R, Z, _, ce, ke, ft;
      return P(), Y("div", wh, [
        V.$slots["month-year"] ? (P(), Y("div", xh, [
          se(V.$slots, "month-year", bt(Pt({ month: e.month, year: e.year, months: e.months, years: e.years, updateMonthYear: m(w), handleMonthYearChange: m(C), instance: e.instance })))
        ])) : (P(), Y(Se, { key: 1 }, [
          V.$slots["top-extra"] ? (P(), Y("div", kh, [
            se(V.$slots, "top-extra", { value: V.internalModelValue })
          ])) : G("", !0),
          ie("div", _h, [
            m(y)(m(o), e.instance) && !V.vertical ? (P(), Me(An, {
              key: 0,
              "aria-label": (R = m(l)) == null ? void 0 : R.prevMonth,
              disabled: m(p)(!1),
              class: ye((Z = m(f)) == null ? void 0 : Z.navBtnPrev),
              "el-name": "action-prev",
              onActivate: h[0] || (h[0] = (j) => m(C)(!1, !0)),
              onSetRef: h[1] || (h[1] = (j) => L(j, 0))
            }, {
              default: me(() => [
                V.$slots["arrow-left"] ? se(V.$slots, "arrow-left", { key: 0 }) : G("", !0),
                V.$slots["arrow-left"] ? G("", !0) : (P(), Me(m(Di), { key: 1 }))
              ]),
              _: 3
            }, 8, ["aria-label", "disabled", "class"])) : G("", !0),
            ie("div", {
              class: ye(["dp__month_year_wrap", {
                dp__year_disable_select: V.disableYearSelect
              }])
            }, [
              (P(!0), Y(Se, null, Fe(K.value, (j, ae) => (P(), Y(Se, {
                key: j.type
              }, [
                ie("button", {
                  ref_for: !0,
                  ref: (J) => L(J, ae + 1),
                  type: "button",
                  "data-dp-element": `overlay-${j.type}`,
                  class: ye(["dp__btn dp__month_year_select", { "dp--hidden-el": $.value }]),
                  "aria-label": `${j.text}-${j.ariaLabel}`,
                  "data-test-id": `${j.type}-toggle-overlay-${e.instance}`,
                  onClick: j.toggle,
                  onKeydown: (J) => m(Ot)(J, () => j.toggle(), !0)
                }, [
                  V.$slots[j.type] ? se(V.$slots, j.type, {
                    key: 0,
                    text: j.text,
                    value: r[j.type]
                  }) : G("", !0),
                  V.$slots[j.type] ? G("", !0) : (P(), Y(Se, { key: 1 }, [
                    vt(Le(j.text), 1)
                  ], 64))
                ], 42, Mh),
                $e(Da, {
                  name: m(v)(j.showSelectionGrid),
                  css: m(g)
                }, {
                  default: me(() => [
                    j.showSelectionGrid ? (P(), Me(Xn, {
                      key: 0,
                      items: j.items,
                      "arrow-navigation": V.arrowNavigation,
                      "hide-navigation": V.hideNavigation,
                      "is-last": V.autoApply && !m(d).keepActionRow,
                      "skip-button-ref": !1,
                      config: V.config,
                      type: j.type,
                      "header-refs": [],
                      "esc-close": V.escClose,
                      "menu-wrap-ref": V.menuWrapRef,
                      "text-input": V.textInput,
                      "aria-labels": V.ariaLabels,
                      "overlay-label": j.overlayLabel,
                      onSelected: j.updateModelValue,
                      onToggle: j.toggle
                    }, kt({
                      "button-icon": me(() => [
                        V.$slots["calendar-icon"] ? se(V.$slots, "calendar-icon", { key: 0 }) : G("", !0),
                        V.$slots["calendar-icon"] ? G("", !0) : (P(), Me(m(wn), { key: 1 }))
                      ]),
                      _: 2
                    }, [
                      V.$slots[`${j.type}-overlay-value`] ? {
                        name: "item",
                        fn: me(({ item: J }) => [
                          se(V.$slots, `${j.type}-overlay-value`, {
                            text: J.text,
                            value: J.value
                          })
                        ]),
                        key: "0"
                      } : void 0,
                      V.$slots[`${j.type}-overlay`] ? {
                        name: "overlay",
                        fn: me(() => [
                          se(V.$slots, `${j.type}-overlay`, Ue({ ref_for: !0 }, X.value(j.type)))
                        ]),
                        key: "1"
                      } : void 0,
                      V.$slots[`${j.type}-overlay-header`] ? {
                        name: "header",
                        fn: me(() => [
                          se(V.$slots, `${j.type}-overlay-header`, {
                            toggle: j.toggle
                          })
                        ]),
                        key: "2"
                      } : void 0
                    ]), 1032, ["items", "arrow-navigation", "hide-navigation", "is-last", "config", "type", "esc-close", "menu-wrap-ref", "text-input", "aria-labels", "overlay-label", "onSelected", "onToggle"])) : G("", !0)
                  ]),
                  _: 2
                }, 1032, ["name", "css"])
              ], 64))), 128))
            ], 2),
            m(y)(m(o), e.instance) && V.vertical ? (P(), Me(An, {
              key: 1,
              "aria-label": (_ = m(l)) == null ? void 0 : _.prevMonth,
              "el-name": "action-prev",
              disabled: m(p)(!1),
              class: ye((ce = m(f)) == null ? void 0 : ce.navBtnPrev),
              onActivate: h[2] || (h[2] = (j) => m(C)(!1, !0))
            }, {
              default: me(() => [
                V.$slots["arrow-up"] ? se(V.$slots, "arrow-up", { key: 0 }) : G("", !0),
                V.$slots["arrow-up"] ? G("", !0) : (P(), Me(m($i), { key: 1 }))
              ]),
              _: 3
            }, 8, ["aria-label", "disabled", "class"])) : G("", !0),
            m(x)(m(o), e.instance) ? (P(), Me(An, {
              key: 2,
              ref: "rightIcon",
              "el-name": "action-next",
              disabled: m(p)(!0),
              "aria-label": (ke = m(l)) == null ? void 0 : ke.nextMonth,
              class: ye((ft = m(f)) == null ? void 0 : ft.navBtnNext),
              onActivate: h[3] || (h[3] = (j) => m(C)(!0, !0)),
              onSetRef: h[4] || (h[4] = (j) => L(j, V.disableYearSelect ? 2 : 3))
            }, {
              default: me(() => [
                V.$slots[V.vertical ? "arrow-down" : "arrow-right"] ? se(V.$slots, V.vertical ? "arrow-down" : "arrow-right", { key: 0 }) : G("", !0),
                V.$slots[V.vertical ? "arrow-down" : "arrow-right"] ? G("", !0) : (P(), Me(Dr(V.vertical ? m(Ri) : m(Ii)), { key: 1 }))
              ]),
              _: 3
            }, 8, ["disabled", "aria-label", "class"])) : G("", !0)
          ])
        ], 64))
      ]);
    };
  }
}), Sh = {
  class: "dp__calendar_header",
  role: "row"
}, Th = {
  key: 0,
  class: "dp__calendar_header_item",
  role: "gridcell"
}, Ph = ["aria-label"], Ch = {
  key: 0,
  class: "dp__calendar_item dp__week_num",
  role: "gridcell"
}, Ah = { class: "dp__cell_inner" }, Dh = ["id", "aria-pressed", "aria-disabled", "aria-label", "tabindex", "data-test-id", "onClick", "onTouchend", "onKeydown", "onMouseenter", "onMouseleave", "onMousedown"], Ih = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "DpCalendar",
  props: {
    mappedDates: { type: Array, default: () => [] },
    instance: { type: Number, default: 0 },
    month: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    ...Xt
  },
  emits: [
    "select-date",
    "set-hover-date",
    "handle-scroll",
    "mount",
    "handle-swipe",
    "handle-space",
    "tooltip-open",
    "tooltip-close"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, { buildMultiLevelMatrix: i } = Ea(), {
      defaultedTransitions: l,
      defaultedConfig: o,
      defaultedAriaLabels: s,
      defaultedMultiCalendars: d,
      defaultedWeekNumbers: u,
      defaultedMultiDates: c,
      defaultedUI: f
    } = Je(r), v = z(null), g = z({
      bottom: "",
      left: "",
      transform: ""
    }), b = z([]), C = z(null), p = z(!0), w = z(""), y = z({ startX: 0, endX: 0, startY: 0, endY: 0 }), x = z([]), D = z({ left: "50%" }), S = z(!1), $ = A(() => r.calendar ? r.calendar(r.mappedDates) : r.mappedDates), O = A(() => r.dayNames ? Array.isArray(r.dayNames) ? r.dayNames : r.dayNames(r.locale, +r.weekStart) : f2(r.formatLocale, r.locale, +r.weekStart));
    He(() => {
      n("mount", { cmp: "calendar", refs: b }), o.value.noSwipe || C.value && (C.value.addEventListener("touchstart", L, { passive: !1 }), C.value.addEventListener("touchend", ue, { passive: !1 }), C.value.addEventListener("touchmove", K, { passive: !1 })), r.monthChangeOnScroll && C.value && C.value.addEventListener("wheel", R, { passive: !1 });
    });
    const N = (j) => j ? r.vertical ? "vNext" : "next" : r.vertical ? "vPrevious" : "previous", M = (j, ae) => {
      if (r.transitions) {
        const J = St(sa(ee(), r.month, r.year));
        w.value = ot(St(sa(ee(), j, ae)), J) ? l.value[N(!0)] : l.value[N(!1)], p.value = !1, Ne(() => {
          p.value = !0;
        });
      }
    }, B = A(
      () => ({
        ...f.value.calendar ?? {}
      })
    ), X = A(() => (j) => {
      const ae = m2(j);
      return {
        dp__marker_dot: ae.type === "dot",
        dp__marker_line: ae.type === "line"
      };
    }), te = A(() => (j) => Re(j, v.value)), T = A(() => ({
      dp__calendar: !0,
      dp__calendar_next: d.value.count > 0 && r.instance !== 0
    })), E = A(() => (j) => r.hideOffsetDates ? j.current : !0), I = async (j, ae) => {
      const { width: J, height: fe } = j.getBoundingClientRect();
      v.value = ae.value;
      let k = { left: `${J / 2}px` }, Q = -50;
      if (await Ne(), x.value[0]) {
        const { left: Ae, width: oe } = x.value[0].getBoundingClientRect();
        Ae < 0 && (k = { left: "0" }, Q = 0, D.value.left = `${J / 2}px`), window.innerWidth < Ae + oe && (k = { right: "0" }, Q = 0, D.value.left = `${oe - J / 2}px`);
      }
      g.value = {
        bottom: `${fe}px`,
        ...k,
        transform: `translateX(${Q}%)`
      };
    }, q = async (j, ae, J) => {
      var fe, k, Q;
      const Ae = ht(b.value[ae][J]);
      Ae && ((fe = j.marker) != null && fe.customPosition && (Q = (k = j.marker) == null ? void 0 : k.tooltip) != null && Q.length ? g.value = j.marker.customPosition(Ae) : await I(Ae, j), n("tooltip-open", j.marker));
    }, ne = async (j, ae, J) => {
      var fe, k;
      if (S.value && c.value.enabled && c.value.dragSelect)
        return n("select-date", j);
      if (n("set-hover-date", j), (k = (fe = j.marker) == null ? void 0 : fe.tooltip) != null && k.length) {
        if (r.hideOffsetDates && !j.current)
          return;
        await q(j, ae, J);
      }
    }, U = (j) => {
      v.value && (v.value = null, g.value = JSON.parse(JSON.stringify({ bottom: "", left: "", transform: "" })), n("tooltip-close", j.marker));
    }, L = (j) => {
      y.value.startX = j.changedTouches[0].screenX, y.value.startY = j.changedTouches[0].screenY;
    }, ue = (j) => {
      y.value.endX = j.changedTouches[0].screenX, y.value.endY = j.changedTouches[0].screenY, V();
    }, K = (j) => {
      r.vertical && !r.inline && j.preventDefault();
    }, V = () => {
      const j = r.vertical ? "Y" : "X";
      Math.abs(y.value[`start${j}`] - y.value[`end${j}`]) > 10 && n("handle-swipe", y.value[`start${j}`] > y.value[`end${j}`] ? "right" : "left");
    }, h = (j, ae, J) => {
      j && (Array.isArray(b.value[ae]) ? b.value[ae][J] = j : b.value[ae] = [j]), r.arrowNavigation && i(b.value, "calendar");
    }, R = (j) => {
      r.monthChangeOnScroll && (j.preventDefault(), n("handle-scroll", j));
    }, Z = (j) => u.value.type === "local" ? Pi(j.value, { weekStartsOn: +r.weekStart }) : u.value.type === "iso" ? Si(j.value) : typeof u.value.type == "function" ? u.value.type(j.value) : "", _ = (j) => {
      const ae = j[0];
      return u.value.hideOnOffsetDates ? j.some((J) => J.current) ? Z(ae) : "" : Z(ae);
    }, ce = (j, ae, J = !0) => {
      J && Bo() || !J && !Bo() || (!c.value.enabled || o.value.allowPreventDefault) && (_a(j, o.value), n("select-date", ae));
    }, ke = (j) => {
      _a(j, o.value);
    }, ft = (j) => {
      c.value.enabled && c.value.dragSelect ? (S.value = !0, n("select-date", j)) : c.value.enabled && n("select-date", j);
    };
    return a({ triggerTransition: M }), (j, ae) => (P(), Y("div", {
      class: ye(T.value)
    }, [
      ie("div", {
        ref_key: "calendarWrapRef",
        ref: C,
        class: ye(B.value),
        role: "grid"
      }, [
        ie("div", Sh, [
          j.weekNumbers ? (P(), Y("div", Th, Le(j.weekNumName), 1)) : G("", !0),
          (P(!0), Y(Se, null, Fe(O.value, (J, fe) => {
            var k, Q;
            return P(), Y("div", {
              key: fe,
              class: "dp__calendar_header_item",
              role: "gridcell",
              "data-test-id": "calendar-header",
              "aria-label": (Q = (k = m(s)) == null ? void 0 : k.weekDay) == null ? void 0 : Q.call(k, fe)
            }, [
              j.$slots["calendar-header"] ? se(j.$slots, "calendar-header", {
                key: 0,
                day: J,
                index: fe
              }) : G("", !0),
              j.$slots["calendar-header"] ? G("", !0) : (P(), Y(Se, { key: 1 }, [
                vt(Le(J), 1)
              ], 64))
            ], 8, Ph);
          }), 128))
        ]),
        ae[2] || (ae[2] = ie("div", { class: "dp__calendar_header_separator" }, null, -1)),
        $e(Da, {
          name: w.value,
          css: !!j.transitions
        }, {
          default: me(() => [
            p.value ? (P(), Y("div", {
              key: 0,
              class: "dp__calendar",
              role: "rowgroup",
              onMouseleave: ae[1] || (ae[1] = (J) => S.value = !1)
            }, [
              (P(!0), Y(Se, null, Fe($.value, (J, fe) => (P(), Y("div", {
                key: fe,
                class: "dp__calendar_row",
                role: "row"
              }, [
                j.weekNumbers ? (P(), Y("div", Ch, [
                  ie("div", Ah, Le(_(J.days)), 1)
                ])) : G("", !0),
                (P(!0), Y(Se, null, Fe(J.days, (k, Q) => {
                  var Ae, oe, ze;
                  return P(), Y("div", {
                    id: m(ec)(k.value),
                    ref_for: !0,
                    ref: (Ie) => h(Ie, fe, Q),
                    key: Q + fe,
                    role: "gridcell",
                    class: "dp__calendar_item",
                    "aria-pressed": (k.classData.dp__active_date || k.classData.dp__range_start || k.classData.dp__range_start) ?? void 0,
                    "aria-disabled": k.classData.dp__cell_disabled || void 0,
                    "aria-label": (oe = (Ae = m(s)) == null ? void 0 : Ae.day) == null ? void 0 : oe.call(Ae, k),
                    tabindex: !k.current && j.hideOffsetDates ? void 0 : 0,
                    "data-test-id": k.value,
                    onClick: Ba((Ie) => ce(Ie, k), ["prevent"]),
                    onTouchend: (Ie) => ce(Ie, k, !1),
                    onKeydown: (Ie) => m(Ot)(Ie, () => j.$emit("select-date", k)),
                    onMouseenter: (Ie) => ne(k, fe, Q),
                    onMouseleave: (Ie) => U(k),
                    onMousedown: (Ie) => ft(k),
                    onMouseup: ae[0] || (ae[0] = (Ie) => S.value = !1)
                  }, [
                    ie("div", {
                      class: ye(["dp__cell_inner", k.classData])
                    }, [
                      j.$slots.day && E.value(k) ? se(j.$slots, "day", {
                        key: 0,
                        day: +k.text,
                        date: k.value
                      }) : G("", !0),
                      j.$slots.day ? G("", !0) : (P(), Y(Se, { key: 1 }, [
                        vt(Le(k.text), 1)
                      ], 64)),
                      k.marker && E.value(k) ? (P(), Y(Se, { key: 2 }, [
                        j.$slots.marker ? se(j.$slots, "marker", {
                          key: 0,
                          marker: k.marker,
                          day: +k.text,
                          date: k.value
                        }) : (P(), Y("div", {
                          key: 1,
                          class: ye(X.value(k.marker)),
                          style: Ze(k.marker.color ? { backgroundColor: k.marker.color } : {})
                        }, null, 6))
                      ], 64)) : G("", !0),
                      te.value(k.value) ? (P(), Y("div", {
                        key: 3,
                        ref_for: !0,
                        ref_key: "activeTooltip",
                        ref: x,
                        class: "dp__marker_tooltip",
                        style: Ze(g.value)
                      }, [
                        (ze = k.marker) != null && ze.tooltip ? (P(), Y("div", {
                          key: 0,
                          class: "dp__tooltip_content",
                          onClick: ke
                        }, [
                          (P(!0), Y(Se, null, Fe(k.marker.tooltip, (Ie, F) => (P(), Y("div", {
                            key: F,
                            class: "dp__tooltip_text"
                          }, [
                            j.$slots["marker-tooltip"] ? se(j.$slots, "marker-tooltip", {
                              key: 0,
                              tooltip: Ie,
                              day: k.value
                            }) : G("", !0),
                            j.$slots["marker-tooltip"] ? G("", !0) : (P(), Y(Se, { key: 1 }, [
                              ie("div", {
                                class: "dp__tooltip_mark",
                                style: Ze(Ie.color ? { backgroundColor: Ie.color } : {})
                              }, null, 4),
                              ie("div", null, Le(Ie.text), 1)
                            ], 64))
                          ]))), 128)),
                          ie("div", {
                            class: "dp__arrow_bottom_tp",
                            style: Ze(D.value)
                          }, null, 4)
                        ])) : G("", !0)
                      ], 4)) : G("", !0)
                    ], 2)
                  ], 40, Dh);
                }), 128))
              ]))), 128))
            ], 32)) : G("", !0)
          ]),
          _: 3
        }, 8, ["name", "css"])
      ], 2)
    ], 2));
  }
}), Ko = (e) => Array.isArray(e), Eh = (e, a, t, n) => {
  const r = z([]), i = z(/* @__PURE__ */ new Date()), l = z(), o = () => L(e.isTextInputDate), { modelValue: s, calendars: d, time: u, today: c } = Gn(e, a, o), {
    defaultedMultiCalendars: f,
    defaultedStartTime: v,
    defaultedRange: g,
    defaultedConfig: b,
    defaultedTz: C,
    propDates: p,
    defaultedMultiDates: w
  } = Je(e), { validateMonthYearInRange: y, isDisabled: x, isDateRangeAllowed: D, checkMinMaxRange: S } = $a(e), { updateTimeValues: $, getSetDateTime: O, setTime: N, assignStartTime: M, validateTime: B, disabledTimesConfig: X } = oc(e, u, s, n), te = A(
    () => (H) => d.value[H] ? d.value[H].month : 0
  ), T = A(
    () => (H) => d.value[H] ? d.value[H].year : 0
  ), E = (H) => !b.value.keepViewOnOffsetClick || H ? !0 : !l.value, I = (H, le, Oe, W = !1) => {
    var de, we;
    E(W) && (d.value[H] || (d.value[H] = { month: 0, year: 0 }), d.value[H].month = Yo(le) ? (de = d.value[H]) == null ? void 0 : de.month : le, d.value[H].year = Yo(Oe) ? (we = d.value[H]) == null ? void 0 : we.year : Oe);
  }, q = () => {
    e.autoApply && a("select-date");
  };
  He(() => {
    e.shadow || (s.value || (j(), v.value && M(v.value)), L(!0), e.focusStartDate && e.startDate && j());
  });
  const ne = A(() => {
    var H;
    return (H = e.flow) != null && H.length && !e.partialFlow ? e.flowStep === e.flow.length : !0;
  }), U = () => {
    e.autoApply && ne.value && a("auto-apply", e.partialFlow ? e.flowStep !== e.flow.length : !1);
  }, L = (H = !1) => {
    if (s.value)
      return Array.isArray(s.value) ? (r.value = s.value, _(H)) : V(s.value, H);
    if (f.value.count && H && !e.startDate)
      return K(ee(), H);
  }, ue = () => Array.isArray(s.value) && g.value.enabled ? Ee(s.value[0]) === Ee(s.value[1] ?? s.value[0]) : !1, K = (H = /* @__PURE__ */ new Date(), le = !1) => {
    if ((!f.value.count || !f.value.static || le) && I(0, Ee(H), Pe(H)), f.value.count && (!s.value || ue() || !f.value.solo) && (!f.value.solo || le))
      for (let Oe = 1; Oe < f.value.count; Oe++) {
        const W = je(ee(), { month: te.value(Oe - 1), year: T.value(Oe - 1) }), de = ku(W, { months: 1 });
        d.value[Oe] = { month: Ee(de), year: Pe(de) };
      }
  }, V = (H, le) => {
    K(H), N("hours", va(H)), N("minutes", Aa(H)), N("seconds", mn(H)), f.value.count && le && ft();
  }, h = (H) => {
    if (f.value.count) {
      if (f.value.solo)
        return 0;
      const le = Ee(H[0]), Oe = Ee(H[1]);
      return Math.abs(Oe - le) < f.value.count ? 0 : 1;
    }
    return 1;
  }, R = (H, le) => {
    H[1] && g.value.showLastInRange ? K(H[h(H)], le) : K(H[0], le);
    const Oe = (W, de) => [
      W(H[0]),
      H[1] ? W(H[1]) : u[de][1]
    ];
    N("hours", Oe(va, "hours")), N("minutes", Oe(Aa, "minutes")), N("seconds", Oe(mn, "seconds"));
  }, Z = (H, le) => {
    if ((g.value.enabled || e.weekPicker) && !w.value.enabled)
      return R(H, le);
    if (w.value.enabled && le) {
      const Oe = H[H.length - 1];
      return V(Oe, le);
    }
  }, _ = (H) => {
    const le = s.value;
    Z(le, H), f.value.count && f.value.solo && ft();
  }, ce = (H, le) => {
    const Oe = je(ee(), { month: te.value(le), year: T.value(le) }), W = H < 0 ? Wt(Oe, 1) : hn(Oe, 1);
    y(Ee(W), Pe(W), H < 0, e.preventMinMaxNavigation) && (I(le, Ee(W), Pe(W)), a("update-month-year", { instance: le, month: Ee(W), year: Pe(W) }), f.value.count && !f.value.solo && ke(le), t());
  }, ke = (H) => {
    for (let le = H - 1; le >= 0; le--) {
      const Oe = hn(je(ee(), { month: te.value(le + 1), year: T.value(le + 1) }), 1);
      I(le, Ee(Oe), Pe(Oe));
    }
    for (let le = H + 1; le <= f.value.count - 1; le++) {
      const Oe = Wt(je(ee(), { month: te.value(le - 1), year: T.value(le - 1) }), 1);
      I(le, Ee(Oe), Pe(Oe));
    }
  }, ft = () => {
    if (Array.isArray(s.value) && s.value.length === 2) {
      const H = ee(
        ee(s.value[1] ? s.value[1] : Wt(s.value[0], 1))
      ), [le, Oe] = [Ee(s.value[0]), Pe(s.value[0])], [W, de] = [Ee(s.value[1]), Pe(s.value[1])];
      (le !== W || le === W && Oe !== de) && f.value.solo && I(1, Ee(H), Pe(H));
    } else
      s.value && !Array.isArray(s.value) && (I(0, Ee(s.value), Pe(s.value)), K(ee()));
  }, j = () => {
    e.startDate && (I(0, Ee(ee(e.startDate)), Pe(ee(e.startDate))), f.value.count && ke(0));
  }, ae = (H, le) => {
    if (e.monthChangeOnScroll) {
      const Oe = (/* @__PURE__ */ new Date()).getTime() - i.value.getTime(), W = Math.abs(H.deltaY);
      let de = 500;
      W > 1 && (de = 100), W > 100 && (de = 0), Oe > de && (i.value = /* @__PURE__ */ new Date(), ce(e.monthChangeOnScroll !== "inverse" ? -H.deltaY : H.deltaY, le));
    }
  }, J = (H, le, Oe = !1) => {
    e.monthChangeOnArrows && e.vertical === Oe && fe(H, le);
  }, fe = (H, le) => {
    ce(H === "right" ? -1 : 1, le);
  }, k = (H) => {
    if (p.value.markers)
      return Cr(H.value, p.value.markers);
  }, Q = (H, le) => {
    switch (e.sixWeeks === !0 ? "append" : e.sixWeeks) {
      case "prepend":
        return [!0, !1];
      case "center":
        return [H == 0, !0];
      case "fair":
        return [H == 0 || le > H, !0];
      case "append":
        return [!1, !1];
      default:
        return [!1, !1];
    }
  }, Ae = (H, le, Oe, W) => {
    if (e.sixWeeks && H.length < 6) {
      const de = 6 - H.length, we = (le.getDay() + 7 - W) % 7, Jt = 6 - (Oe.getDay() + 7 - W) % 7, [zt, Ja] = Q(we, Jt);
      for (let xn = 1; xn <= de; xn++)
        if (Ja ? !!(xn % 2) == zt : zt) {
          const ha = H[0].days[0], il = oe(Bt(ha.value, -7), Ee(le));
          H.unshift({ days: il });
        } else {
          const ha = H[H.length - 1], il = ha.days[ha.days.length - 1], cc = oe(Bt(il.value, 1), Ee(le));
          H.push({ days: cc });
        }
    }
    return H;
  }, oe = (H, le) => {
    const Oe = ee(H), W = [];
    for (let de = 0; de < 7; de++) {
      const we = Bt(Oe, de), Jt = Ee(we) !== le;
      W.push({
        text: e.hideOffsetDates && Jt ? "" : we.getDate(),
        value: we,
        current: !Jt,
        classData: {}
      });
    }
    return W;
  }, ze = (H, le) => {
    const Oe = [], W = new Date(le, H), de = new Date(le, H + 1, 0), we = e.weekStart, Jt = Kt(W, { weekStartsOn: we }), zt = (Ja) => {
      const xn = oe(Ja, H);
      if (Oe.push({ days: xn }), !Oe[Oe.length - 1].days.some(
        (ha) => Re(St(ha.value), St(de))
      )) {
        const ha = Bt(Ja, 7);
        zt(ha);
      }
    };
    return zt(Jt), Ae(Oe, W, de, we);
  }, Ie = (H) => {
    const le = Ma(ee(H.value), u.hours, u.minutes, Gt());
    a("date-update", le), w.value.enabled ? Vi(le, s, w.value.limit) : s.value = le, n(), Ne().then(() => {
      U();
    });
  }, F = (H) => g.value.noDisabledRange ? Xu(r.value[0], H).some((le) => x(le)) : !1, re = () => {
    r.value = s.value ? s.value.slice() : [], r.value.length === 2 && !(g.value.fixedStart || g.value.fixedEnd) && (r.value = []);
  }, xe = (H, le) => {
    const Oe = [
      ee(H.value),
      Bt(ee(H.value), +g.value.autoRange)
    ];
    D(Oe) ? (le && mt(H.value), r.value = Oe) : a("invalid-date", H.value);
  }, mt = (H) => {
    const le = Ee(ee(H)), Oe = Pe(ee(H));
    if (I(0, le, Oe), f.value.count > 0)
      for (let W = 1; W < f.value.count; W++) {
        const de = x2(
          je(ee(H), { year: T.value(W - 1), month: te.value(W - 1) })
        );
        I(W, de.month, de.year);
      }
  }, Qt = (H) => {
    if (F(H.value) || !S(H.value, s.value, g.value.fixedStart ? 0 : 1))
      return a("invalid-date", H.value);
    r.value = rc(ee(H.value), s, a, g);
  }, _e = (H, le) => {
    if (re(), g.value.autoRange)
      return xe(H, le);
    if (g.value.fixedStart || g.value.fixedEnd)
      return Qt(H);
    r.value[0] ? S(ee(H.value), s.value) && !F(H.value) ? tt(ee(H.value), ee(r.value[0])) ? (r.value.unshift(ee(H.value)), a("range-end", r.value[0])) : (r.value[1] = ee(H.value), a("range-end", r.value[1])) : (e.autoApply && a("auto-apply-invalid", H.value), a("invalid-date", H.value)) : (r.value[0] = ee(H.value), a("range-start", r.value[0]));
  }, Gt = (H = !0) => e.enableSeconds ? Array.isArray(u.seconds) ? H ? u.seconds[0] : u.seconds[1] : u.seconds : 0, lt = (H) => {
    r.value[H] = Ma(
      r.value[H],
      u.hours[H],
      u.minutes[H],
      Gt(H !== 1)
    );
  }, Zr = () => {
    var H, le;
    r.value[0] && r.value[1] && +((H = r.value) == null ? void 0 : H[0]) > +((le = r.value) == null ? void 0 : le[1]) && (r.value.reverse(), a("range-start", r.value[0]), a("range-end", r.value[1]));
  }, el = () => {
    r.value.length && (r.value[0] && !r.value[1] ? lt(0) : (lt(0), lt(1), n()), Zr(), s.value = r.value.slice(), Gr(r.value, a, e.autoApply, e.modelAuto));
  }, Jn = (H, le = !1) => {
    if (x(H.value) || !H.current && e.hideOffsetDates)
      return a("invalid-date", H.value);
    if (l.value = JSON.parse(JSON.stringify(H)), !g.value.enabled)
      return Ie(H);
    Ko(u.hours) && Ko(u.minutes) && !w.value.enabled && (_e(H, le), el());
  }, tl = (H, le) => {
    var Oe;
    I(H, le.month, le.year, !0), f.value.count && !f.value.solo && ke(H), a("update-month-year", { instance: H, month: le.month, year: le.year }), t(f.value.solo ? H : void 0);
    const W = (Oe = e.flow) != null && Oe.length ? e.flow[e.flowStep] : void 0;
    !le.fromNav && (W === xt.month || W === xt.year) && n();
  }, al = (H, le) => {
    nc({
      value: H,
      modelValue: s,
      range: g.value.enabled,
      timezone: le ? void 0 : C.value.timezone
    }), q(), e.multiCalendars && Ne().then(() => L(!0));
  }, nl = () => {
    const H = Ni(ee(), C.value);
    !g.value.enabled && !w.value.enabled ? s.value = H : s.value && Array.isArray(s.value) && s.value[0] ? w.value.enabled ? s.value = [...s.value, H] : s.value = tt(H, s.value[0]) ? [H, s.value[0]] : [s.value[0], H] : s.value = [H], q();
  }, rl = () => {
    if (Array.isArray(s.value))
      if (w.value.enabled) {
        const H = ll();
        s.value[s.value.length - 1] = O(H);
      } else
        s.value = s.value.map((H, le) => H && O(H, le));
    else
      s.value = O(s.value);
    a("time-update");
  }, ll = () => Array.isArray(s.value) && s.value.length ? s.value[s.value.length - 1] : null;
  return {
    calendars: d,
    modelValue: s,
    month: te,
    year: T,
    time: u,
    disabledTimesConfig: X,
    today: c,
    validateTime: B,
    getCalendarDays: ze,
    getMarker: k,
    handleScroll: ae,
    handleSwipe: fe,
    handleArrow: J,
    selectDate: Jn,
    updateMonthYear: tl,
    presetDate: al,
    selectCurrentDate: nl,
    updateTime: (H, le = !0, Oe = !1) => {
      $(H, le, Oe, rl);
    },
    assignMonthAndYear: K
  };
}, $h = { key: 0 }, Rh = /* @__PURE__ */ be({
  __name: "DatePicker",
  props: {
    ...Xt
  },
  emits: [
    "tooltip-open",
    "tooltip-close",
    "mount",
    "update:internal-model-value",
    "update-flow-step",
    "reset-flow",
    "auto-apply",
    "focus-menu",
    "select-date",
    "range-start",
    "range-end",
    "invalid-fixed-range",
    "time-update",
    "am-pm-change",
    "time-picker-open",
    "time-picker-close",
    "recalculate-position",
    "update-month-year",
    "auto-apply-invalid",
    "date-update",
    "invalid-date",
    "overlay-toggle"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, {
      calendars: i,
      month: l,
      year: o,
      modelValue: s,
      time: d,
      disabledTimesConfig: u,
      today: c,
      validateTime: f,
      getCalendarDays: v,
      getMarker: g,
      handleArrow: b,
      handleScroll: C,
      handleSwipe: p,
      selectDate: w,
      updateMonthYear: y,
      presetDate: x,
      selectCurrentDate: D,
      updateTime: S,
      assignMonthAndYear: $
    } = Eh(r, n, ue, K), O = Ka(), { setHoverDate: N, getDayClassData: M, clearHoverDate: B } = Jh(s, r), { defaultedMultiCalendars: X } = Je(r), te = z([]), T = z([]), E = z(null), I = Et(O, "calendar"), q = Et(O, "monthYear"), ne = Et(O, "timePicker"), U = (ae) => {
      r.shadow || n("mount", ae);
    };
    Ge(
      i,
      () => {
        r.shadow || setTimeout(() => {
          n("recalculate-position");
        }, 0);
      },
      { deep: !0 }
    ), Ge(
      X,
      (ae, J) => {
        ae.count - J.count > 0 && $();
      },
      { deep: !0 }
    );
    const L = A(() => (ae) => v(l.value(ae), o.value(ae)).map((J) => ({
      ...J,
      days: J.days.map((fe) => (fe.marker = g(fe), fe.classData = M(fe), fe))
    })));
    function ue(ae) {
      var J;
      ae || ae === 0 ? (J = T.value[ae]) == null || J.triggerTransition(l.value(ae), o.value(ae)) : T.value.forEach((fe, k) => fe.triggerTransition(l.value(k), o.value(k)));
    }
    function K() {
      n("update-flow-step");
    }
    const V = (ae, J = !1) => {
      w(ae, J), r.spaceConfirm && n("select-date");
    }, h = (ae, J, fe = 0) => {
      var k;
      (k = te.value[fe]) == null || k.toggleMonthPicker(ae, J);
    }, R = (ae, J, fe = 0) => {
      var k;
      (k = te.value[fe]) == null || k.toggleYearPicker(ae, J);
    }, Z = (ae, J, fe) => {
      var k;
      (k = E.value) == null || k.toggleTimePicker(ae, J, fe);
    }, _ = (ae, J) => {
      var fe;
      if (!r.range) {
        const k = s.value ? s.value : c, Q = J ? new Date(J) : k, Ae = ae ? Kt(Q, { weekStartsOn: 1 }) : Cu(Q, { weekStartsOn: 1 });
        w({
          value: Ae,
          current: Ee(Q) === l.value(0),
          text: "",
          classData: {}
        }), (fe = document.getElementById(ec(Ae))) == null || fe.focus();
      }
    }, ce = (ae) => {
      var J;
      (J = te.value[0]) == null || J.handleMonthYearChange(ae, !0);
    }, ke = (ae) => {
      y(0, { month: l.value(0), year: o.value(0) + (ae ? 1 : -1), fromNav: !0 });
    }, ft = (ae, J) => {
      ae === xt.time && n(`time-picker-${J ? "open" : "close"}`), n("overlay-toggle", { open: J, overlay: ae });
    }, j = (ae) => {
      n("overlay-toggle", { open: !1, overlay: ae }), n("focus-menu");
    };
    return a({
      clearHoverDate: B,
      presetDate: x,
      selectCurrentDate: D,
      toggleMonthPicker: h,
      toggleYearPicker: R,
      toggleTimePicker: Z,
      handleArrow: b,
      updateMonthYear: y,
      getSidebarProps: () => ({
        modelValue: s,
        month: l,
        year: o,
        time: d,
        updateTime: S,
        updateMonthYear: y,
        selectDate: w,
        presetDate: x
      }),
      changeMonth: ce,
      changeYear: ke,
      selectWeekDate: _
    }), (ae, J) => (P(), Y(Se, null, [
      $e(Qr, {
        "multi-calendars": m(X).count,
        collapse: ae.collapse,
        "is-mobile": ae.isMobile
      }, {
        default: me(({ instance: fe, index: k }) => [
          ae.disableMonthYearSelect ? G("", !0) : (P(), Me(Oh, Ue({
            key: 0,
            ref: (Q) => {
              Q && (te.value[k] = Q);
            },
            months: m(Bu)(ae.formatLocale, ae.locale, ae.monthNameFormat),
            years: m(Li)(ae.yearRange, ae.locale, ae.reverseYears),
            month: m(l)(fe),
            year: m(o)(fe),
            instance: fe
          }, ae.$props, {
            onMount: J[0] || (J[0] = (Q) => U(m(Va).header)),
            onResetFlow: J[1] || (J[1] = (Q) => ae.$emit("reset-flow")),
            onUpdateMonthYear: (Q) => m(y)(fe, Q),
            onOverlayClosed: j,
            onOverlayOpened: J[2] || (J[2] = (Q) => ae.$emit("overlay-toggle", { open: !0, overlay: Q }))
          }), kt({ _: 2 }, [
            Fe(m(q), (Q, Ae) => ({
              name: Q,
              fn: me((oe) => [
                se(ae.$slots, Q, bt(Pt(oe)))
              ])
            }))
          ]), 1040, ["months", "years", "month", "year", "instance", "onUpdateMonthYear"])),
          $e(Ih, Ue({
            ref: (Q) => {
              Q && (T.value[k] = Q);
            },
            "mapped-dates": L.value(fe),
            month: m(l)(fe),
            year: m(o)(fe),
            instance: fe
          }, ae.$props, {
            onSelectDate: (Q) => m(w)(Q, fe !== 1),
            onHandleSpace: (Q) => V(Q, fe !== 1),
            onSetHoverDate: J[3] || (J[3] = (Q) => m(N)(Q)),
            onHandleScroll: (Q) => m(C)(Q, fe),
            onHandleSwipe: (Q) => m(p)(Q, fe),
            onMount: J[4] || (J[4] = (Q) => U(m(Va).calendar)),
            onResetFlow: J[5] || (J[5] = (Q) => ae.$emit("reset-flow")),
            onTooltipOpen: J[6] || (J[6] = (Q) => ae.$emit("tooltip-open", Q)),
            onTooltipClose: J[7] || (J[7] = (Q) => ae.$emit("tooltip-close", Q))
          }), kt({ _: 2 }, [
            Fe(m(I), (Q, Ae) => ({
              name: Q,
              fn: me((oe) => [
                se(ae.$slots, Q, bt(Pt({ ...oe })))
              ])
            }))
          ]), 1040, ["mapped-dates", "month", "year", "instance", "onSelectDate", "onHandleSpace", "onHandleScroll", "onHandleSwipe"])
        ]),
        _: 3
      }, 8, ["multi-calendars", "collapse", "is-mobile"]),
      ae.enableTimePicker ? (P(), Y("div", $h, [
        ae.$slots["time-picker"] ? se(ae.$slots, "time-picker", bt(Ue({ key: 0 }, { time: m(d), updateTime: m(S) }))) : (P(), Me(ic, Ue({
          key: 1,
          ref_key: "timePickerRef",
          ref: E
        }, ae.$props, {
          hours: m(d).hours,
          minutes: m(d).minutes,
          seconds: m(d).seconds,
          "internal-model-value": ae.internalModelValue,
          "disabled-times-config": m(u),
          "validate-time": m(f),
          onMount: J[8] || (J[8] = (fe) => U(m(Va).timePicker)),
          "onUpdate:hours": J[9] || (J[9] = (fe) => m(S)(fe)),
          "onUpdate:minutes": J[10] || (J[10] = (fe) => m(S)(fe, !1)),
          "onUpdate:seconds": J[11] || (J[11] = (fe) => m(S)(fe, !1, !0)),
          onResetFlow: J[12] || (J[12] = (fe) => ae.$emit("reset-flow")),
          onOverlayClosed: J[13] || (J[13] = (fe) => ft(fe, !1)),
          onOverlayOpened: J[14] || (J[14] = (fe) => ft(fe, !0)),
          onAmPmChange: J[15] || (J[15] = (fe) => ae.$emit("am-pm-change", fe))
        }), kt({ _: 2 }, [
          Fe(m(ne), (fe, k) => ({
            name: fe,
            fn: me((Q) => [
              se(ae.$slots, fe, bt(Pt(Q)))
            ])
          }))
        ]), 1040, ["hours", "minutes", "seconds", "internal-model-value", "disabled-times-config", "validate-time"]))
      ])) : G("", !0)
    ], 64));
  }
}), Nh = (e, a) => {
  const t = z(), {
    defaultedMultiCalendars: n,
    defaultedConfig: r,
    defaultedHighlight: i,
    defaultedRange: l,
    propDates: o,
    defaultedFilters: s,
    defaultedMultiDates: d
  } = Je(e), { modelValue: u, year: c, month: f, calendars: v } = Gn(e, a), { isDisabled: g } = $a(e), { selectYear: b, groupedYears: C, showYearPicker: p, isDisabled: w, toggleYearPicker: y, handleYearSelect: x, handleYear: D } = lc({
    modelValue: u,
    multiCalendars: n,
    range: l,
    highlight: i,
    calendars: v,
    propDates: o,
    month: f,
    year: c,
    filters: s,
    props: e,
    emit: a
  }), S = (E, I) => [E, I].map((q) => na(q, "MMMM", { locale: e.formatLocale })).join("-"), $ = A(() => (E) => u.value ? Array.isArray(u.value) ? u.value.some((I) => zo(E, I)) : zo(u.value, E) : !1), O = (E) => {
    if (l.value.enabled) {
      if (Array.isArray(u.value)) {
        const I = Re(E, u.value[0]) || Re(E, u.value[1]);
        return Kr(u.value, t.value, E) && !I;
      }
      return !1;
    }
    return !1;
  }, N = (E, I) => E.quarter === Eo(I) && E.year === Pe(I), M = (E) => typeof i.value == "function" ? i.value({ quarter: Eo(E), year: Pe(E) }) : !!i.value.quarters.find((I) => N(I, E)), B = A(() => (E) => {
    const I = je(/* @__PURE__ */ new Date(), { year: c.value(E) });
    return fm({
      start: zn(I),
      end: Pu(I)
    }).map((q) => {
      const ne = Fa(q), U = $o(q), L = g(q), ue = O(ne), K = M(ne);
      return {
        text: S(ne, U),
        value: ne,
        active: $.value(ne),
        highlighted: K,
        disabled: L,
        isBetween: ue
      };
    });
  }), X = (E) => {
    Vi(E, u, d.value.limit), a("auto-apply", !0);
  }, te = (E) => {
    u.value = Yi(u, E, a), Gr(u.value, a, e.autoApply, e.modelAuto);
  }, T = (E) => {
    u.value = E, a("auto-apply");
  };
  return {
    defaultedConfig: r,
    defaultedMultiCalendars: n,
    groupedYears: C,
    year: c,
    isDisabled: w,
    quarters: B,
    showYearPicker: p,
    modelValue: u,
    setHoverDate: (E) => {
      t.value = E;
    },
    selectYear: b,
    selectQuarter: (E, I, q) => {
      if (!q)
        return v.value[I].month = Ee($o(E)), d.value.enabled ? X(E) : l.value.enabled ? te(E) : T(E);
    },
    toggleYearPicker: y,
    handleYearSelect: x,
    handleYear: D
  };
}, Lh = { class: "dp--quarter-items" }, Hh = ["data-test-id", "disabled", "onClick", "onMouseover"], zh = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "QuarterPicker",
  props: {
    ...Xt
  },
  emits: [
    "update:internal-model-value",
    "reset-flow",
    "overlay-closed",
    "auto-apply",
    "range-start",
    "range-end",
    "overlay-toggle",
    "update-month-year"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, i = Ka(), l = Et(i, "yearMode"), {
      defaultedMultiCalendars: o,
      defaultedConfig: s,
      groupedYears: d,
      year: u,
      isDisabled: c,
      quarters: f,
      modelValue: v,
      showYearPicker: g,
      setHoverDate: b,
      selectQuarter: C,
      toggleYearPicker: p,
      handleYearSelect: w,
      handleYear: y
    } = Nh(r, n);
    return a({ getSidebarProps: () => ({
      modelValue: v,
      year: u,
      selectQuarter: C,
      handleYearSelect: w,
      handleYear: y
    }) }), (x, D) => (P(), Me(Qr, {
      "multi-calendars": m(o).count,
      collapse: x.collapse,
      stretch: "",
      "is-mobile": x.isMobile
    }, {
      default: me(({ instance: S }) => [
        ie("div", {
          class: "dp-quarter-picker-wrap",
          style: Ze({ minHeight: `${m(s).modeHeight}px` })
        }, [
          x.$slots["top-extra"] ? se(x.$slots, "top-extra", {
            key: 0,
            value: x.internalModelValue
          }) : G("", !0),
          ie("div", null, [
            $e(ac, Ue(x.$props, {
              items: m(d)(S),
              instance: S,
              "show-year-picker": m(g)[S],
              year: m(u)(S),
              "is-disabled": ($) => m(c)(S, $),
              onHandleYear: ($) => m(y)(S, $),
              onYearSelect: ($) => m(w)($, S),
              onToggleYearPicker: ($) => m(p)(S, $ == null ? void 0 : $.flow, $ == null ? void 0 : $.show)
            }), kt({ _: 2 }, [
              Fe(m(l), ($, O) => ({
                name: $,
                fn: me((N) => [
                  se(x.$slots, $, bt(Pt(N)))
                ])
              }))
            ]), 1040, ["items", "instance", "show-year-picker", "year", "is-disabled", "onHandleYear", "onYearSelect", "onToggleYearPicker"])
          ]),
          ie("div", Lh, [
            (P(!0), Y(Se, null, Fe(m(f)(S), ($, O) => (P(), Y("div", { key: O }, [
              ie("button", {
                type: "button",
                class: ye(["dp--qr-btn", {
                  "dp--qr-btn-active": $.active,
                  "dp--qr-btn-between": $.isBetween,
                  "dp--qr-btn-disabled": $.disabled,
                  "dp--highlighted": $.highlighted
                }]),
                "data-test-id": $.value,
                disabled: $.disabled,
                onClick: (N) => m(C)($.value, S, $.disabled),
                onMouseover: (N) => m(b)($.value)
              }, [
                x.$slots.quarter ? se(x.$slots, "quarter", {
                  key: 0,
                  value: $.value,
                  text: $.text
                }) : (P(), Y(Se, { key: 1 }, [
                  vt(Le($.text), 1)
                ], 64))
              ], 42, Hh)
            ]))), 128))
          ])
        ], 4)
      ]),
      _: 3
    }, 8, ["multi-calendars", "collapse", "is-mobile"]));
  }
}), sc = (e, a) => {
  const t = z(0);
  He(() => {
    n(), window.addEventListener("resize", n, { passive: !0 });
  }), Ht(() => {
    window.removeEventListener("resize", n);
  });
  const n = () => {
    t.value = window.document.documentElement.clientWidth;
  };
  return {
    isMobile: A(
      () => t.value <= e.value.mobileBreakpoint && !a ? !0 : void 0
    )
  };
}, Fh = ["id", "tabindex", "role", "aria-label"], Vh = {
  key: 0,
  class: "dp--menu-load-container"
}, Yh = {
  key: 1,
  class: "dp--menu-header"
}, Bh = ["data-dp-mobile"], jh = {
  key: 0,
  class: "dp__sidebar_left"
}, Uh = ["data-dp-mobile"], Wh = ["data-test-id", "data-dp-mobile", "onClick", "onKeydown"], qh = {
  key: 2,
  class: "dp__sidebar_right"
}, Kh = {
  key: 3,
  class: "dp__action_extra"
}, Xo = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "DatepickerMenu",
  props: {
    ...Xr,
    shadow: { type: Boolean, default: !1 },
    openOnTop: { type: Boolean, default: !1 },
    internalModelValue: { type: [Date, Array], default: null },
    noOverlayFocus: { type: Boolean, default: !1 },
    collapse: { type: Boolean, default: !1 },
    getInputRect: { type: Function, default: () => ({}) },
    isTextInputDate: { type: Boolean, default: !1 }
  },
  emits: [
    "close-picker",
    "select-date",
    "auto-apply",
    "time-update",
    "flow-step",
    "update-month-year",
    "invalid-select",
    "update:internal-model-value",
    "recalculate-position",
    "invalid-fixed-range",
    "tooltip-open",
    "tooltip-close",
    "time-picker-open",
    "time-picker-close",
    "am-pm-change",
    "range-start",
    "range-end",
    "auto-apply-invalid",
    "date-update",
    "invalid-date",
    "overlay-toggle",
    "menu-blur"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, i = z(null), l = A(() => {
      const { openOnTop: F, ...re } = r;
      return {
        ...re,
        isMobile: b.value,
        flowStep: B.value,
        menuWrapRef: i.value
      };
    }), { setMenuFocused: o, setShiftKey: s, control: d } = tc(), u = Ka(), { defaultedTextInput: c, defaultedInline: f, defaultedConfig: v, defaultedUI: g } = Je(r), { isMobile: b } = sc(v, r.shadow), C = z(null), p = z(0), w = z(null), y = z(!1), x = z(null), D = z(!1);
    He(() => {
      if (!r.shadow) {
        y.value = !0, S(), window.addEventListener("resize", S);
        const F = ht(i);
        if (F && !c.value.enabled && !f.value.enabled && (o(!0), ne()), F) {
          const re = (xe) => {
            D.value = !0, v.value.allowPreventDefault && xe.preventDefault(), _a(xe, v.value, !0);
          };
          F.addEventListener("pointerdown", re), F.addEventListener("mousedown", re);
        }
      }
      document.addEventListener("mousedown", Ie);
    }), Ht(() => {
      window.removeEventListener("resize", S), document.addEventListener("mousedown", Ie);
    });
    const S = () => {
      const F = ht(w);
      F && (p.value = F.getBoundingClientRect().width);
    }, { arrowRight: $, arrowLeft: O, arrowDown: N, arrowUp: M } = Ea(), { flowStep: B, updateFlowStep: X, childMount: te, resetFlow: T, handleFlow: E } = Zh(r, n, x), I = A(() => r.monthPicker ? nh : r.yearPicker ? lh : r.timePicker ? bh : r.quarterPicker ? zh : Rh), q = A(() => {
      var F;
      if (v.value.arrowLeft)
        return v.value.arrowLeft;
      const re = (F = i.value) == null ? void 0 : F.getBoundingClientRect(), xe = r.getInputRect();
      return (xe == null ? void 0 : xe.width) < (p == null ? void 0 : p.value) && (xe == null ? void 0 : xe.left) <= ((re == null ? void 0 : re.left) ?? 0) ? `${(xe == null ? void 0 : xe.width) / 2}px` : (xe == null ? void 0 : xe.right) >= ((re == null ? void 0 : re.right) ?? 0) && (xe == null ? void 0 : xe.width) < (p == null ? void 0 : p.value) ? `${(p == null ? void 0 : p.value) - (xe == null ? void 0 : xe.width) / 2}px` : "50%";
    }), ne = () => {
      const F = ht(i);
      F && F.focus({ preventScroll: !0 });
    }, U = A(() => {
      var F;
      return ((F = x.value) == null ? void 0 : F.getSidebarProps()) || {};
    }), L = () => {
      r.openOnTop && n("recalculate-position");
    }, ue = Et(u, "action"), K = A(() => r.monthPicker || r.yearPicker ? Et(u, "monthYear") : r.timePicker ? Et(u, "timePicker") : Et(u, "shared")), V = A(() => r.openOnTop ? "dp__arrow_bottom" : "dp__arrow_top"), h = A(() => ({
      dp__menu_disabled: r.disabled,
      dp__menu_readonly: r.readonly,
      "dp-menu-loading": r.loading
    })), R = A(
      () => ({
        dp__menu: !0,
        dp__menu_index: !f.value.enabled,
        dp__relative: f.value.enabled,
        ...g.value.menu ?? {}
      })
    ), Z = (F) => {
      _a(F, v.value, !0);
    }, _ = () => {
      r.escClose && n("close-picker");
    }, ce = (F) => {
      if (r.arrowNavigation) {
        if (F === Mt.up)
          return M();
        if (F === Mt.down)
          return N();
        if (F === Mt.left)
          return O();
        if (F === Mt.right)
          return $();
      } else
        F === Mt.left || F === Mt.up ? J("handleArrow", Mt.left, 0, F === Mt.up) : J("handleArrow", Mt.right, 0, F === Mt.down);
    }, ke = (F) => {
      s(F.shiftKey), !r.disableMonthYearSelect && F.code === qe.tab && F.target.classList.contains("dp__menu") && d.value.shiftKeyInMenu && (F.preventDefault(), _a(F, v.value, !0), n("close-picker"));
    }, ft = () => {
      ne(), n("time-picker-close");
    }, j = (F) => {
      var re, xe, mt;
      (re = x.value) == null || re.toggleTimePicker(!1, !1), (xe = x.value) == null || xe.toggleMonthPicker(!1, !1, F), (mt = x.value) == null || mt.toggleYearPicker(!1, !1, F);
    }, ae = (F, re = 0) => {
      var xe, mt, Qt;
      return F === "month" ? (xe = x.value) == null ? void 0 : xe.toggleMonthPicker(!1, !0, re) : F === "year" ? (mt = x.value) == null ? void 0 : mt.toggleYearPicker(!1, !0, re) : F === "time" ? (Qt = x.value) == null ? void 0 : Qt.toggleTimePicker(!0, !1) : j(re);
    }, J = (F, ...re) => {
      var xe, mt;
      (xe = x.value) != null && xe[F] && ((mt = x.value) == null || mt[F](...re));
    }, fe = () => {
      J("selectCurrentDate");
    }, k = (F, re) => {
      J("presetDate", F, re);
    }, Q = () => {
      J("clearHoverDate");
    }, Ae = (F, re) => {
      J("updateMonthYear", F, re);
    }, oe = (F, re) => {
      F.preventDefault(), ce(re);
    }, ze = (F) => {
      var re, xe, mt;
      if (ke(F), F.key === qe.home || F.key === qe.end)
        return J(
          "selectWeekDate",
          F.key === qe.home,
          F.target.getAttribute("id")
        );
      switch ((F.key === qe.pageUp || F.key === qe.pageDown) && (F.shiftKey ? (J("changeYear", F.key === qe.pageUp), (re = Xl(i.value, "overlay-year")) == null || re.focus()) : (J("changeMonth", F.key === qe.pageUp), (xe = Xl(i.value, F.key === qe.pageUp ? "action-prev" : "action-next")) == null || xe.focus()), F.target.getAttribute("id") && ((mt = i.value) == null || mt.focus({ preventScroll: !0 }))), F.key) {
        case qe.esc:
          return _();
        case qe.arrowLeft:
          return oe(F, Mt.left);
        case qe.arrowRight:
          return oe(F, Mt.right);
        case qe.arrowUp:
          return oe(F, Mt.up);
        case qe.arrowDown:
          return oe(F, Mt.down);
        default:
          return;
      }
    }, Ie = (F) => {
      var re;
      f.value.enabled && !f.value.input && !((re = i.value) != null && re.contains(F.target)) && D.value && (D.value = !1, n("menu-blur"));
    };
    return a({
      updateMonthYear: Ae,
      switchView: ae,
      handleFlow: E
    }), (F, re) => {
      var xe, mt, Qt;
      return P(), Y("div", {
        id: F.uid ? `dp-menu-${F.uid}` : void 0,
        ref_key: "dpMenuRef",
        ref: i,
        tabindex: m(f).enabled ? void 0 : "0",
        role: m(f).enabled ? void 0 : "dialog",
        "aria-label": (xe = F.ariaLabels) == null ? void 0 : xe.menu,
        class: ye(R.value),
        style: Ze({ "--dp-arrow-left": q.value }),
        onMouseleave: Q,
        onClick: Z,
        onKeydown: ze
      }, [
        (F.disabled || F.readonly) && m(f).enabled || F.loading ? (P(), Y("div", {
          key: 0,
          class: ye(h.value)
        }, [
          F.loading ? (P(), Y("div", Vh, re[19] || (re[19] = [
            ie("span", { class: "dp--menu-loader" }, null, -1)
          ]))) : G("", !0)
        ], 2)) : G("", !0),
        F.$slots["menu-header"] ? (P(), Y("div", Yh, [
          se(F.$slots, "menu-header")
        ])) : G("", !0),
        !m(f).enabled && !F.teleportCenter ? (P(), Y("div", {
          key: 2,
          class: ye(V.value)
        }, null, 2)) : G("", !0),
        ie("div", {
          ref_key: "innerMenuRef",
          ref: w,
          class: ye({
            dp__menu_content_wrapper: ((mt = F.presetDates) == null ? void 0 : mt.length) || !!F.$slots["left-sidebar"] || !!F.$slots["right-sidebar"],
            "dp--menu-content-wrapper-collapsed": e.collapse && (((Qt = F.presetDates) == null ? void 0 : Qt.length) || !!F.$slots["left-sidebar"] || !!F.$slots["right-sidebar"])
          }),
          "data-dp-mobile": m(b),
          style: Ze({ "--dp-menu-width": `${p.value}px` })
        }, [
          F.$slots["left-sidebar"] ? (P(), Y("div", jh, [
            se(F.$slots, "left-sidebar", bt(Pt(U.value)))
          ])) : G("", !0),
          F.presetDates.length ? (P(), Y("div", {
            key: 1,
            class: ye({ "dp--preset-dates-collapsed": e.collapse, "dp--preset-dates": !0 }),
            "data-dp-mobile": m(b)
          }, [
            (P(!0), Y(Se, null, Fe(F.presetDates, (_e, Gt) => (P(), Y(Se, { key: Gt }, [
              _e.slot ? se(F.$slots, _e.slot, {
                key: 0,
                presetDate: k,
                label: _e.label,
                value: _e.value
              }) : (P(), Y("button", {
                key: 1,
                type: "button",
                style: Ze(_e.style || {}),
                class: ye(["dp__btn dp--preset-range", { "dp--preset-range-collapsed": e.collapse }]),
                "data-test-id": _e.testId ?? void 0,
                "data-dp-mobile": m(b),
                onClick: Ba((lt) => k(_e.value, _e.noTz), ["prevent"]),
                onKeydown: (lt) => m(Ot)(lt, () => k(_e.value, _e.noTz), !0)
              }, Le(_e.label), 47, Wh))
            ], 64))), 128))
          ], 10, Uh)) : G("", !0),
          ie("div", {
            ref_key: "calendarWrapperRef",
            ref: C,
            class: "dp__instance_calendar",
            role: "document"
          }, [
            (P(), Me(Dr(I.value), Ue({
              ref_key: "dynCmpRef",
              ref: x
            }, l.value, {
              "flow-step": m(B),
              onMount: m(te),
              onUpdateFlowStep: m(X),
              onResetFlow: m(T),
              onFocusMenu: ne,
              onSelectDate: re[0] || (re[0] = (_e) => F.$emit("select-date")),
              onDateUpdate: re[1] || (re[1] = (_e) => F.$emit("date-update", _e)),
              onTooltipOpen: re[2] || (re[2] = (_e) => F.$emit("tooltip-open", _e)),
              onTooltipClose: re[3] || (re[3] = (_e) => F.$emit("tooltip-close", _e)),
              onAutoApply: re[4] || (re[4] = (_e) => F.$emit("auto-apply", _e)),
              onRangeStart: re[5] || (re[5] = (_e) => F.$emit("range-start", _e)),
              onRangeEnd: re[6] || (re[6] = (_e) => F.$emit("range-end", _e)),
              onInvalidFixedRange: re[7] || (re[7] = (_e) => F.$emit("invalid-fixed-range", _e)),
              onTimeUpdate: re[8] || (re[8] = (_e) => F.$emit("time-update")),
              onAmPmChange: re[9] || (re[9] = (_e) => F.$emit("am-pm-change", _e)),
              onTimePickerOpen: re[10] || (re[10] = (_e) => F.$emit("time-picker-open", _e)),
              onTimePickerClose: ft,
              onRecalculatePosition: L,
              onUpdateMonthYear: re[11] || (re[11] = (_e) => F.$emit("update-month-year", _e)),
              onAutoApplyInvalid: re[12] || (re[12] = (_e) => F.$emit("auto-apply-invalid", _e)),
              onInvalidDate: re[13] || (re[13] = (_e) => F.$emit("invalid-date", _e)),
              onOverlayToggle: re[14] || (re[14] = (_e) => F.$emit("overlay-toggle", _e)),
              "onUpdate:internalModelValue": re[15] || (re[15] = (_e) => F.$emit("update:internal-model-value", _e))
            }), kt({ _: 2 }, [
              Fe(K.value, (_e, Gt) => ({
                name: _e,
                fn: me((lt) => [
                  se(F.$slots, _e, bt(Pt({ ...lt })))
                ])
              }))
            ]), 1040, ["flow-step", "onMount", "onUpdateFlowStep", "onResetFlow"]))
          ], 512),
          F.$slots["right-sidebar"] ? (P(), Y("div", qh, [
            se(F.$slots, "right-sidebar", bt(Pt(U.value)))
          ])) : G("", !0),
          F.$slots["action-extra"] ? (P(), Y("div", Kh, [
            F.$slots["action-extra"] ? se(F.$slots, "action-extra", {
              key: 0,
              selectCurrentDate: fe
            }) : G("", !0)
          ])) : G("", !0)
        ], 14, Bh),
        !F.autoApply || m(v).keepActionRow ? (P(), Me(K2, Ue({
          key: 3,
          "menu-mount": y.value
        }, l.value, {
          "calendar-width": p.value,
          onClosePicker: re[16] || (re[16] = (_e) => F.$emit("close-picker")),
          onSelectDate: re[17] || (re[17] = (_e) => F.$emit("select-date")),
          onInvalidSelect: re[18] || (re[18] = (_e) => F.$emit("invalid-select")),
          onSelectNow: fe
        }), kt({ _: 2 }, [
          Fe(m(ue), (_e, Gt) => ({
            name: _e,
            fn: me((lt) => [
              se(F.$slots, _e, bt(Pt({ ...lt })))
            ])
          }))
        ]), 1040, ["menu-mount", "calendar-width"])) : G("", !0)
      ], 46, Fh);
    };
  }
});
var an = /* @__PURE__ */ ((e) => (e.center = "center", e.left = "left", e.right = "right", e))(an || {});
const Xh = ({
  menuRef: e,
  menuRefInner: a,
  inputRef: t,
  pickerWrapperRef: n,
  inline: r,
  emit: i,
  props: l,
  slots: o
}) => {
  const { defaultedConfig: s } = Je(l), d = z({}), u = z(!1), c = z({
    top: "0",
    left: "0"
  }), f = z(!1), v = Qe(l, "teleportCenter");
  Ge(v, () => {
    c.value = JSON.parse(JSON.stringify({})), D();
  });
  const g = (I) => {
    if (l.teleport) {
      const q = I.getBoundingClientRect();
      return {
        left: q.left + window.scrollX,
        top: q.top + window.scrollY
      };
    }
    return { top: 0, left: 0 };
  }, b = (I, q) => {
    c.value.left = `${I + q - d.value.width}px`;
  }, C = (I) => {
    c.value.left = `${I}px`;
  }, p = (I, q) => {
    l.position === an.left && C(I), l.position === an.right && b(I, q), l.position === an.center && (c.value.left = `${I + q / 2 - d.value.width / 2}px`);
  }, w = (I) => {
    const { width: q, height: ne } = I.getBoundingClientRect(), { top: U, left: L } = g(I);
    return { top: +U, left: +L, width: q, height: ne };
  }, y = () => {
    c.value.left = "50%", c.value.top = "50%", c.value.transform = "translate(-50%, -50%)", c.value.position = "fixed", delete c.value.opacity;
  }, x = () => {
    const I = ht(t);
    c.value = l.altPosition(I);
  }, D = (I = !0) => {
    var q;
    if (!r.value.enabled) {
      if (v.value)
        return y();
      if (l.altPosition !== null)
        return x();
      if (I) {
        const ne = l.teleport ? (q = a.value) == null ? void 0 : q.$el : e.value;
        ne && (d.value = ne.getBoundingClientRect()), i("recalculate-position");
      }
      return X();
    }
  }, S = ({ inputEl: I, left: q, width: ne }) => {
    window.screen.width > 768 && !u.value && p(q, ne), N(I);
  }, $ = (I) => {
    const { top: q, left: ne, height: U, width: L } = w(I);
    c.value.top = `${U + q + +l.offset}px`, f.value = !1, u.value || (c.value.left = `${ne + L / 2 - d.value.width / 2}px`), S({ inputEl: I, left: ne, width: L });
  }, O = (I) => {
    const { top: q, left: ne, width: U } = w(I);
    c.value.top = `${q - +l.offset - d.value.height}px`, f.value = !0, S({ inputEl: I, left: ne, width: U });
  }, N = (I) => {
    if (l.autoPosition) {
      const { left: q, width: ne } = w(I), { left: U, right: L } = d.value;
      if (!u.value) {
        if (Math.abs(U) !== Math.abs(L)) {
          if (U <= 0)
            return u.value = !0, C(q);
          if (L >= document.documentElement.clientWidth)
            return u.value = !0, b(q, ne);
        }
        return p(q, ne);
      }
    }
  }, M = () => {
    const I = ht(t);
    if (I) {
      if (l.autoPosition === Vt.top)
        return Vt.top;
      if (l.autoPosition === Vt.bottom)
        return Vt.bottom;
      const { height: q } = d.value, { top: ne, height: U } = I.getBoundingClientRect(), L = window.innerHeight - ne - U, ue = ne;
      return q <= L ? Vt.bottom : q > L && q <= ue ? Vt.top : L >= ue ? Vt.bottom : Vt.top;
    }
    return Vt.bottom;
  }, B = (I) => M() === Vt.bottom ? $(I) : O(I), X = () => {
    const I = ht(t);
    if (I)
      return l.autoPosition ? B(I) : $(I);
  }, te = function(I) {
    if (I) {
      const q = I.scrollHeight > I.clientHeight, ne = window.getComputedStyle(I).overflowY.indexOf("hidden") !== -1;
      return q && !ne;
    }
    return !0;
  }, T = function(I) {
    return !I || I === document.body || I.nodeType === Node.DOCUMENT_FRAGMENT_NODE ? window : te(I) ? I : T(
      I.assignedSlot && s.value.shadowDom ? I.assignedSlot.parentNode : I.parentNode
    );
  }, E = (I) => {
    if (I)
      switch (l.position) {
        case an.left:
          return { left: 0, transform: "translateX(0)" };
        case an.right:
          return { left: `${I.width}px`, transform: "translateX(-100%)" };
        default:
          return { left: `${I.width / 2}px`, transform: "translateX(-50%)" };
      }
    return {};
  };
  return {
    openOnTop: f,
    menuStyle: c,
    xCorrect: u,
    setMenuPosition: D,
    getScrollableParent: T,
    shadowRender: (I, q) => {
      var ne, U, L;
      const ue = document.createElement("div"), K = (ne = ht(t)) == null ? void 0 : ne.getBoundingClientRect();
      ue.setAttribute("id", "dp--temp-container");
      const V = (U = n.value) != null && U.clientWidth ? n.value : document.body;
      V.append(ue);
      const h = E(K), R = s.value.shadowDom ? Object.keys(o).filter(
        (_) => ["right-sidebar", "left-sidebar", "top-extra", "action-extra"].includes(_)
      ) : Object.keys(o), Z = At(
        I,
        {
          ...q,
          shadow: !0,
          style: { opacity: 0, position: "absolute", ...h }
        },
        Object.fromEntries(R.map((_) => [_, o[_]]))
      );
      Ui(Z, ue), d.value = (L = Z.el) == null ? void 0 : L.getBoundingClientRect(), Ui(null, ue), V.removeChild(ue);
    }
  };
}, ba = [
  { name: "clock-icon", use: ["time", "calendar", "shared"] },
  { name: "arrow-left", use: ["month-year", "calendar", "shared", "year-mode"] },
  { name: "arrow-right", use: ["month-year", "calendar", "shared", "year-mode"] },
  { name: "arrow-up", use: ["time", "calendar", "month-year", "shared"] },
  { name: "arrow-down", use: ["time", "calendar", "month-year", "shared"] },
  { name: "calendar-icon", use: ["month-year", "time", "calendar", "shared", "year-mode"] },
  { name: "day", use: ["calendar", "shared"] },
  { name: "month-overlay-value", use: ["calendar", "month-year", "shared"] },
  { name: "year-overlay-value", use: ["calendar", "month-year", "shared", "year-mode"] },
  { name: "year-overlay", use: ["month-year", "shared"] },
  { name: "month-overlay", use: ["month-year", "shared"] },
  { name: "month-overlay-header", use: ["month-year", "shared"] },
  { name: "year-overlay-header", use: ["month-year", "shared"] },
  { name: "hours-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "hours-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "minutes-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "minutes-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "seconds-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "seconds-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "hours", use: ["calendar", "time", "shared"] },
  { name: "minutes", use: ["calendar", "time", "shared"] },
  { name: "month", use: ["calendar", "month-year", "shared"] },
  { name: "year", use: ["calendar", "month-year", "shared", "year-mode"] },
  { name: "action-buttons", use: ["action"] },
  { name: "action-preview", use: ["action"] },
  { name: "calendar-header", use: ["calendar", "shared"] },
  { name: "marker-tooltip", use: ["calendar", "shared"] },
  { name: "action-extra", use: ["menu"] },
  { name: "time-picker-overlay", use: ["calendar", "time", "shared"] },
  { name: "am-pm-button", use: ["calendar", "time", "shared"] },
  { name: "left-sidebar", use: ["menu"] },
  { name: "right-sidebar", use: ["menu"] },
  { name: "month-year", use: ["month-year", "shared"] },
  { name: "time-picker", use: ["menu", "shared"] },
  { name: "action-row", use: ["action"] },
  { name: "marker", use: ["calendar", "shared"] },
  { name: "quarter", use: ["shared"] },
  { name: "top-extra", use: ["shared", "month-year"] },
  { name: "tp-inline-arrow-up", use: ["shared", "time"] },
  { name: "tp-inline-arrow-down", use: ["shared", "time"] },
  { name: "menu-header", use: ["menu"] }
], Qh = [{ name: "trigger" }, { name: "input-icon" }, { name: "clear-icon" }, { name: "dp-input" }], Gh = {
  all: () => ba,
  monthYear: () => ba.filter((e) => e.use.includes("month-year")),
  input: () => Qh,
  timePicker: () => ba.filter((e) => e.use.includes("time")),
  action: () => ba.filter((e) => e.use.includes("action")),
  calendar: () => ba.filter((e) => e.use.includes("calendar")),
  menu: () => ba.filter((e) => e.use.includes("menu")),
  shared: () => ba.filter((e) => e.use.includes("shared")),
  yearMode: () => ba.filter((e) => e.use.includes("year-mode"))
}, Et = (e, a, t) => {
  const n = [];
  return Gh[a]().forEach((r) => {
    e[r.name] && n.push(r.name);
  }), t != null && t.length && t.forEach((r) => {
    r.slot && n.push(r.slot);
  }), n;
}, Qn = (e) => {
  const a = A(() => (n) => e.value ? n ? e.value.open : e.value.close : ""), t = A(() => (n) => e.value ? n ? e.value.menuAppearTop : e.value.menuAppearBottom : "");
  return { transitionName: a, showTransition: !!e.value, menuTransition: t };
}, Gn = (e, a, t) => {
  const { defaultedRange: n, defaultedTz: r } = Je(e), i = ee(Ct(ee(), r.value.timezone)), l = z([{ month: Ee(i), year: Pe(i) }]), o = (f) => {
    const v = {
      hours: va(i),
      minutes: Aa(i),
      seconds: 0
    };
    return n.value.enabled ? [v[f], v[f]] : v[f];
  }, s = qa({
    hours: o("hours"),
    minutes: o("minutes"),
    seconds: o("seconds")
  });
  Ge(
    n,
    (f, v) => {
      f.enabled !== v.enabled && (s.hours = o("hours"), s.minutes = o("minutes"), s.seconds = o("seconds"));
    },
    { deep: !0 }
  );
  const d = A({
    get: () => e.internalModelValue,
    set: (f) => {
      !e.readonly && !e.disabled && a("update:internal-model-value", f);
    }
  }), u = A(
    () => (f) => l.value[f] ? l.value[f].month : 0
  ), c = A(
    () => (f) => l.value[f] ? l.value[f].year : 0
  );
  return Ge(
    d,
    (f, v) => {
      t && JSON.stringify(f ?? {}) !== JSON.stringify(v ?? {}) && t();
    },
    { deep: !0 }
  ), {
    calendars: l,
    time: s,
    modelValue: d,
    month: u,
    year: c,
    today: i
  };
}, Jh = (e, a) => {
  const {
    defaultedMultiCalendars: t,
    defaultedMultiDates: n,
    defaultedUI: r,
    defaultedHighlight: i,
    defaultedTz: l,
    propDates: o,
    defaultedRange: s
  } = Je(a), { isDisabled: d } = $a(a), u = z(null), c = z(Ct(/* @__PURE__ */ new Date(), l.value.timezone)), f = (h) => {
    !h.current && a.hideOffsetDates || (u.value = h.value);
  }, v = () => {
    u.value = null;
  }, g = (h) => Array.isArray(e.value) && s.value.enabled && e.value[0] && u.value ? h ? ot(u.value, e.value[0]) : tt(u.value, e.value[0]) : !0, b = (h, R) => {
    const Z = () => e.value ? R ? e.value[0] || null : e.value[1] : null, _ = e.value && Array.isArray(e.value) ? Z() : null;
    return Re(ee(h.value), _);
  }, C = (h) => {
    const R = Array.isArray(e.value) ? e.value[0] : null;
    return h ? !tt(u.value ?? null, R) : !0;
  }, p = (h, R = !0) => (s.value.enabled || a.weekPicker) && Array.isArray(e.value) && e.value.length === 2 ? a.hideOffsetDates && !h.current ? !1 : Re(ee(h.value), e.value[R ? 0 : 1]) : s.value.enabled ? b(h, R) && C(R) || Re(h.value, Array.isArray(e.value) ? e.value[0] : null) && g(R) : !1, w = (h, R) => {
    if (Array.isArray(e.value) && e.value[0] && e.value.length === 1) {
      const Z = Re(h.value, u.value);
      return R ? ot(e.value[0], h.value) && Z : tt(e.value[0], h.value) && Z;
    }
    return !1;
  }, y = (h) => !e.value || a.hideOffsetDates && !h.current ? !1 : s.value.enabled ? a.modelAuto && Array.isArray(e.value) ? Re(h.value, e.value[0] ? e.value[0] : c.value) : !1 : n.value.enabled && Array.isArray(e.value) ? e.value.some((R) => Re(R, h.value)) : Re(h.value, e.value ? e.value : c.value), x = (h) => {
    if (s.value.autoRange || a.weekPicker) {
      if (u.value) {
        if (a.hideOffsetDates && !h.current)
          return !1;
        const R = Bt(u.value, +s.value.autoRange), Z = oa(ee(u.value), a.weekStart);
        return a.weekPicker ? Re(Z[1], ee(h.value)) : Re(R, ee(h.value));
      }
      return !1;
    }
    return !1;
  }, D = (h) => {
    if (s.value.autoRange || a.weekPicker) {
      if (u.value) {
        const R = Bt(u.value, +s.value.autoRange);
        if (a.hideOffsetDates && !h.current)
          return !1;
        const Z = oa(ee(u.value), a.weekStart);
        return a.weekPicker ? ot(h.value, Z[0]) && tt(h.value, Z[1]) : ot(h.value, u.value) && tt(h.value, R);
      }
      return !1;
    }
    return !1;
  }, S = (h) => {
    if (s.value.autoRange || a.weekPicker) {
      if (u.value) {
        if (a.hideOffsetDates && !h.current)
          return !1;
        const R = oa(ee(u.value), a.weekStart);
        return a.weekPicker ? Re(R[0], h.value) : Re(u.value, h.value);
      }
      return !1;
    }
    return !1;
  }, $ = (h) => Kr(e.value, u.value, h.value), O = () => a.modelAuto && Array.isArray(a.internalModelValue) ? !!a.internalModelValue[0] : !1, N = () => a.modelAuto ? ju(a.internalModelValue) : !0, M = (h) => {
    if (a.weekPicker)
      return !1;
    const R = s.value.enabled ? !p(h) && !p(h, !1) : !0;
    return !d(h.value) && !y(h) && !(!h.current && a.hideOffsetDates) && R;
  }, B = (h) => s.value.enabled ? a.modelAuto ? O() && y(h) : !1 : y(h), X = (h) => i.value ? g2(h.value, o.value.highlight) : !1, te = (h) => {
    const R = d(h.value);
    return R && (typeof i.value == "function" ? !i.value(h.value, R) : !i.value.options.highlightDisabled);
  }, T = (h) => {
    var R;
    return typeof i.value == "function" ? i.value(h.value) : (R = i.value.weekdays) == null ? void 0 : R.includes(h.value.getDay());
  }, E = (h) => (s.value.enabled || a.weekPicker) && (!(t.value.count > 0) || h.current) && N() && !(!h.current && a.hideOffsetDates) && !y(h) ? $(h) : !1, I = (h) => {
    const { isRangeStart: R, isRangeEnd: Z } = L(h), _ = s.value.enabled ? R || Z : !1;
    return {
      dp__cell_offset: !h.current,
      dp__pointer: !a.disabled && !(!h.current && a.hideOffsetDates) && !d(h.value),
      dp__cell_disabled: d(h.value),
      dp__cell_highlight: !te(h) && (X(h) || T(h)) && !B(h) && !_ && !S(h) && !(E(h) && a.weekPicker) && !Z,
      dp__cell_highlight_active: !te(h) && (X(h) || T(h)) && B(h),
      dp__today: !a.noToday && Re(h.value, c.value) && h.current,
      "dp--past": tt(h.value, c.value),
      "dp--future": ot(h.value, c.value)
    };
  }, q = (h) => ({
    dp__active_date: B(h),
    dp__date_hover: M(h)
  }), ne = (h) => {
    if (e.value && !Array.isArray(e.value)) {
      const R = oa(e.value, a.weekStart);
      return {
        ...K(h),
        dp__range_start: Re(R[0], h.value),
        dp__range_end: Re(R[1], h.value),
        dp__range_between_week: ot(h.value, R[0]) && tt(h.value, R[1])
      };
    }
    return {
      ...K(h)
    };
  }, U = (h) => {
    if (e.value && Array.isArray(e.value)) {
      const R = oa(e.value[0], a.weekStart), Z = e.value[1] ? oa(e.value[1], a.weekStart) : [];
      return {
        ...K(h),
        dp__range_start: Re(R[0], h.value) || Re(Z[0], h.value),
        dp__range_end: Re(R[1], h.value) || Re(Z[1], h.value),
        dp__range_between_week: ot(h.value, R[0]) && tt(h.value, R[1]) || ot(h.value, Z[0]) && tt(h.value, Z[1]),
        dp__range_between: ot(h.value, R[1]) && tt(h.value, Z[0])
      };
    }
    return {
      ...K(h)
    };
  }, L = (h) => {
    const R = t.value.count > 0 ? h.current && p(h) && N() : p(h) && N(), Z = t.value.count > 0 ? h.current && p(h, !1) && N() : p(h, !1) && N();
    return { isRangeStart: R, isRangeEnd: Z };
  }, ue = (h) => {
    const { isRangeStart: R, isRangeEnd: Z } = L(h);
    return {
      dp__range_start: R,
      dp__range_end: Z,
      dp__range_between: E(h),
      dp__date_hover: Re(h.value, u.value) && !R && !Z && !a.weekPicker,
      dp__date_hover_start: w(h, !0),
      dp__date_hover_end: w(h, !1)
    };
  }, K = (h) => ({
    ...ue(h),
    dp__cell_auto_range: D(h),
    dp__cell_auto_range_start: S(h),
    dp__cell_auto_range_end: x(h)
  }), V = (h) => s.value.enabled ? s.value.autoRange ? K(h) : a.modelAuto ? { ...q(h), ...ue(h) } : a.weekPicker ? U(h) : ue(h) : a.weekPicker ? ne(h) : q(h);
  return {
    setHoverDate: f,
    clearHoverDate: v,
    getDayClassData: (h) => a.hideOffsetDates && !h.current ? {} : {
      ...I(h),
      ...V(h),
      [a.dayClass ? a.dayClass(h.value, a.internalModelValue) : ""]: !0,
      ...r.value.calendarCell ?? {}
    }
  };
}, $a = (e) => {
  const { defaultedFilters: a, defaultedRange: t, propDates: n, defaultedMultiDates: r } = Je(e), i = (T) => n.value.disabledDates ? typeof n.value.disabledDates == "function" ? n.value.disabledDates(ee(T)) : !!Cr(T, n.value.disabledDates) : !1, l = (T) => n.value.maxDate ? e.yearPicker ? Pe(T) > Pe(n.value.maxDate) : ot(T, n.value.maxDate) : !1, o = (T) => n.value.minDate ? e.yearPicker ? Pe(T) < Pe(n.value.minDate) : tt(T, n.value.minDate) : !1, s = (T) => {
    const E = l(T), I = o(T), q = i(T), ne = a.value.months.map((V) => +V).includes(Ee(T)), U = e.disabledWeekDays.length ? e.disabledWeekDays.some((V) => +V === lp(T)) : !1, L = v(T), ue = Pe(T), K = ue < +e.yearRange[0] || ue > +e.yearRange[1];
    return !(E || I || q || ne || K || U || L);
  }, d = (T, E) => tt(...xa(n.value.minDate, T, E)) || Re(...xa(n.value.minDate, T, E)), u = (T, E) => ot(...xa(n.value.maxDate, T, E)) || Re(...xa(n.value.maxDate, T, E)), c = (T, E, I) => {
    let q = !1;
    return n.value.maxDate && I && u(T, E) && (q = !0), n.value.minDate && !I && d(T, E) && (q = !0), q;
  }, f = (T, E, I, q) => {
    let ne = !1;
    return q && (n.value.minDate || n.value.maxDate) ? n.value.minDate && n.value.maxDate ? ne = c(T, E, I) : (n.value.minDate && d(T, E) || n.value.maxDate && u(T, E)) && (ne = !0) : ne = !0, ne;
  }, v = (T) => Array.isArray(n.value.allowedDates) && !n.value.allowedDates.length ? !0 : n.value.allowedDates ? !Cr(T, n.value.allowedDates) : !1, g = (T) => !s(T), b = (T) => t.value.noDisabledRange ? !Tu({ start: T[0], end: T[1] }).some((E) => g(E)) : !0, C = (T) => {
    if (T) {
      const E = Pe(T);
      return E >= +e.yearRange[0] && E <= e.yearRange[1];
    }
    return !0;
  }, p = (T, E) => !!(Array.isArray(T) && T[E] && (t.value.maxRange || t.value.minRange) && C(T[E])), w = (T, E, I = 0) => {
    if (p(E, I) && C(T)) {
      const q = Mu(T, E[I]), ne = Xu(E[I], T), U = ne.length === 1 ? 0 : ne.filter((ue) => g(ue)).length, L = Math.abs(q) - (t.value.minMaxRawRange ? 0 : U);
      if (t.value.minRange && t.value.maxRange)
        return L >= +t.value.minRange && L <= +t.value.maxRange;
      if (t.value.minRange)
        return L >= +t.value.minRange;
      if (t.value.maxRange)
        return L <= +t.value.maxRange;
    }
    return !0;
  }, y = () => !e.enableTimePicker || e.monthPicker || e.yearPicker || e.ignoreTimeValidation, x = (T) => Array.isArray(T) ? [T[0] ? bl(T[0]) : null, T[1] ? bl(T[1]) : null] : bl(T), D = (T, E, I) => T.find(
    (q) => +q.hours === va(E) && q.minutes === "*" ? !0 : +q.minutes === Aa(E) && +q.hours === va(E)
  ) && I, S = (T, E, I) => {
    const [q, ne] = T, [U, L] = E;
    return !D(q, U, I) && !D(ne, L, I) && I;
  }, $ = (T, E) => {
    const I = Array.isArray(E) ? E : [E];
    return Array.isArray(e.disabledTimes) ? Array.isArray(e.disabledTimes[0]) ? S(e.disabledTimes, I, T) : !I.some((q) => D(e.disabledTimes, q, T)) : T;
  }, O = (T, E) => {
    const I = Array.isArray(E) ? [Ya(E[0]), E[1] ? Ya(E[1]) : void 0] : Ya(E), q = !e.disabledTimes(I);
    return T && q;
  }, N = (T, E) => e.disabledTimes ? Array.isArray(e.disabledTimes) ? $(E, T) : O(E, T) : E, M = (T) => {
    let E = !0;
    if (!T || y())
      return !0;
    const I = !n.value.minDate && !n.value.maxDate ? x(T) : T;
    return (e.maxTime || n.value.maxDate) && (E = Uo(
      e.maxTime,
      n.value.maxDate,
      "max",
      gt(I),
      E
    )), (e.minTime || n.value.minDate) && (E = Uo(
      e.minTime,
      n.value.minDate,
      "min",
      gt(I),
      E
    )), N(T, E);
  }, B = (T) => {
    if (!e.monthPicker)
      return !0;
    let E = !0;
    const I = ee(jt(T));
    if (n.value.minDate && n.value.maxDate) {
      const q = ee(jt(n.value.minDate)), ne = ee(jt(n.value.maxDate));
      return ot(I, q) && tt(I, ne) || Re(I, q) || Re(I, ne);
    }
    if (n.value.minDate) {
      const q = ee(jt(n.value.minDate));
      E = ot(I, q) || Re(I, q);
    }
    if (n.value.maxDate) {
      const q = ee(jt(n.value.maxDate));
      E = tt(I, q) || Re(I, q);
    }
    return E;
  }, X = A(() => (T) => !e.enableTimePicker || e.ignoreTimeValidation ? !0 : M(T)), te = A(() => (T) => e.monthPicker ? Array.isArray(T) && (t.value.enabled || r.value.enabled) ? !T.filter((E) => !B(E)).length : B(T) : !0);
  return {
    isDisabled: g,
    validateDate: s,
    validateMonthYearInRange: f,
    isDateRangeAllowed: b,
    checkMinMaxRange: w,
    isValidTime: M,
    isTimeValid: X,
    isMonthValid: te
  };
}, Jr = () => {
  const e = A(() => (n, r) => n == null ? void 0 : n.includes(r)), a = A(() => (n, r) => n.count ? n.solo ? !0 : r === 0 : !0), t = A(() => (n, r) => n.count ? n.solo ? !0 : r === n.count - 1 : !0);
  return { hideNavigationButtons: e, showLeftIcon: a, showRightIcon: t };
}, Zh = (e, a, t) => {
  const n = z(0), r = qa({
    [Va.timePicker]: !e.enableTimePicker || e.timePicker || e.monthPicker,
    [Va.calendar]: !1,
    [Va.header]: !1
  }), i = A(() => e.monthPicker || e.timePicker), l = (c) => {
    var f;
    if ((f = e.flow) != null && f.length) {
      if (!c && i.value)
        return u();
      r[c] = !0, Object.keys(r).filter((v) => !r[v]).length || u();
    }
  }, o = () => {
    var c, f;
    (c = e.flow) != null && c.length && n.value !== -1 && (n.value += 1, a("flow-step", n.value), u()), ((f = e.flow) == null ? void 0 : f.length) === n.value && Ne().then(() => s());
  }, s = () => {
    n.value = -1;
  }, d = (c, f, ...v) => {
    var g, b;
    e.flow[n.value] === c && t.value && ((b = (g = t.value)[f]) == null || b.call(g, ...v));
  }, u = (c = 0) => {
    c && (n.value += c), d(xt.month, "toggleMonthPicker", !0), d(xt.year, "toggleYearPicker", !0), d(xt.calendar, "toggleTimePicker", !1, !0), d(xt.time, "toggleTimePicker", !0, !0);
    const f = e.flow[n.value];
    (f === xt.hours || f === xt.minutes || f === xt.seconds) && d(f, "toggleTimePicker", !0, !0, f);
  };
  return { childMount: l, updateFlowStep: o, resetFlow: s, handleFlow: u, flowStep: n };
}, e3 = {
  key: 1,
  class: "dp__input_wrap"
}, t3 = ["id", "name", "inputmode", "placeholder", "disabled", "readonly", "required", "value", "autocomplete", "aria-label", "aria-disabled", "aria-invalid"], a3 = {
  key: 2,
  class: "dp--clear-btn"
}, n3 = ["aria-label"], r3 = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "DatepickerInput",
  props: {
    isMenuOpen: { type: Boolean, default: !1 },
    inputValue: { type: String, default: "" },
    ...Xr
  },
  emits: [
    "clear",
    "open",
    "update:input-value",
    "set-input-date",
    "close",
    "select-date",
    "set-empty-date",
    "toggle",
    "focus-prev",
    "focus",
    "blur",
    "real-blur",
    "text-input"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, {
      defaultedTextInput: i,
      defaultedAriaLabels: l,
      defaultedInline: o,
      defaultedConfig: s,
      defaultedRange: d,
      defaultedMultiDates: u,
      defaultedUI: c,
      getDefaultPattern: f,
      getDefaultStartTime: v
    } = Je(r), { checkMinMaxRange: g } = $a(r), b = z(), C = z(null), p = z(!1), w = z(!1), y = A(
      () => ({
        dp__pointer: !r.disabled && !r.readonly && !i.value.enabled,
        dp__disabled: r.disabled,
        dp__input_readonly: !i.value.enabled,
        dp__input: !0,
        dp__input_icon_pad: !r.hideInputIcon,
        dp__input_valid: typeof r.state == "boolean" ? r.state : !1,
        dp__input_invalid: typeof r.state == "boolean" ? !r.state : !1,
        dp__input_focus: p.value || r.isMenuOpen,
        dp__input_reg: !i.value.enabled,
        ...c.value.input ?? {}
      })
    ), x = () => {
      n("set-input-date", null), r.clearable && r.autoApply && (n("set-empty-date"), b.value = null);
    }, D = (L) => {
      const ue = v();
      return b2(
        L,
        i.value.format ?? f(),
        ue ?? Qu({}, r.enableSeconds),
        r.inputValue,
        w.value,
        r.formatLocale
      );
    }, S = (L) => {
      const { rangeSeparator: ue } = i.value, [K, V] = L.split(`${ue}`);
      if (K) {
        const h = D(K.trim()), R = V ? D(V.trim()) : null;
        if (pn(h, R))
          return;
        const Z = h && R ? [h, R] : [h];
        g(R, Z, 0) && (b.value = h ? Z : null);
      }
    }, $ = () => {
      w.value = !0;
    }, O = (L) => {
      if (d.value.enabled)
        S(L);
      else if (u.value.enabled) {
        const ue = L.split(";");
        b.value = ue.map((K) => D(K.trim())).filter((K) => K);
      } else
        b.value = D(L);
    }, N = (L) => {
      var ue;
      const K = typeof L == "string" ? L : (ue = L.target) == null ? void 0 : ue.value;
      K !== "" ? (i.value.openMenu && !r.isMenuOpen && n("open"), O(K), n("set-input-date", b.value)) : x(), w.value = !1, n("update:input-value", K), n("text-input", L, b.value);
    }, M = (L) => {
      i.value.enabled ? (O(L.target.value), i.value.enterSubmit && Ql(b.value) && r.inputValue !== "" ? (n("set-input-date", b.value, !0), b.value = null) : i.value.enterSubmit && r.inputValue === "" && (b.value = null, n("clear"))) : te(L);
    }, B = (L, ue) => {
      i.value.enabled && i.value.tabSubmit && !ue && O(L.target.value), i.value.tabSubmit && Ql(b.value) && r.inputValue !== "" ? (n("set-input-date", b.value, !0, !0), b.value = null) : i.value.tabSubmit && r.inputValue === "" && (b.value = null, n("clear", !0));
    }, X = () => {
      p.value = !0, n("focus"), Ne().then(() => {
        var L;
        i.value.enabled && i.value.selectOnFocus && ((L = C.value) == null || L.select());
      });
    }, te = (L) => {
      if (_a(L, s.value, !0), i.value.enabled && i.value.openMenu && !o.value.input) {
        if (i.value.openMenu === "open" && !r.isMenuOpen)
          return n("open");
        if (i.value.openMenu === "toggle")
          return n("toggle");
      } else
        i.value.enabled || n("toggle");
    }, T = () => {
      n("real-blur"), p.value = !1, (!r.isMenuOpen || o.value.enabled && o.value.input) && n("blur"), r.autoApply && i.value.enabled && b.value && !r.isMenuOpen && (n("set-input-date", b.value), n("select-date"), b.value = null);
    }, E = (L) => {
      _a(L, s.value, !0), n("clear");
    }, I = (L) => {
      if (L.key === "Tab" && B(L), L.key === "Enter" && M(L), !i.value.enabled) {
        if (L.code === "Tab")
          return;
        L.preventDefault();
      }
    }, q = () => {
      var L;
      (L = C.value) == null || L.focus({ preventScroll: !0 });
    }, ne = (L) => {
      b.value = L;
    }, U = (L) => {
      L.key === qe.tab && B(L, !0);
    };
    return a({
      focusInput: q,
      setParsedDate: ne
    }), (L, ue) => {
      var K, V, h;
      return P(), Y("div", { onClick: te }, [
        L.$slots.trigger && !L.$slots["dp-input"] && !m(o).enabled ? se(L.$slots, "trigger", { key: 0 }) : G("", !0),
        !L.$slots.trigger && (!m(o).enabled || m(o).input) ? (P(), Y("div", e3, [
          L.$slots["dp-input"] && !L.$slots.trigger && (!m(o).enabled || m(o).enabled && m(o).input) ? se(L.$slots, "dp-input", {
            key: 0,
            value: e.inputValue,
            isMenuOpen: e.isMenuOpen,
            onInput: N,
            onEnter: M,
            onTab: B,
            onClear: E,
            onBlur: T,
            onKeypress: I,
            onPaste: $,
            onFocus: X,
            openMenu: () => L.$emit("open"),
            closeMenu: () => L.$emit("close"),
            toggleMenu: () => L.$emit("toggle")
          }) : G("", !0),
          L.$slots["dp-input"] ? G("", !0) : (P(), Y("input", {
            key: 1,
            id: L.uid ? `dp-input-${L.uid}` : void 0,
            ref_key: "inputRef",
            ref: C,
            "data-test-id": "dp-input",
            name: L.name,
            class: ye(y.value),
            inputmode: m(i).enabled ? "text" : "none",
            placeholder: L.placeholder,
            disabled: L.disabled,
            readonly: L.readonly,
            required: L.required,
            value: e.inputValue,
            autocomplete: L.autocomplete,
            "aria-label": (K = m(l)) == null ? void 0 : K.input,
            "aria-disabled": L.disabled || void 0,
            "aria-invalid": L.state === !1 ? !0 : void 0,
            onInput: N,
            onBlur: T,
            onFocus: X,
            onKeypress: I,
            onKeydown: ue[0] || (ue[0] = (R) => I(R)),
            onPaste: $
          }, null, 42, t3)),
          ie("div", {
            onClick: ue[3] || (ue[3] = (R) => n("toggle"))
          }, [
            L.$slots["input-icon"] && !L.hideInputIcon ? (P(), Y("span", {
              key: 0,
              class: "dp__input_icon",
              onClick: ue[1] || (ue[1] = (R) => n("toggle"))
            }, [
              se(L.$slots, "input-icon")
            ])) : G("", !0),
            !L.$slots["input-icon"] && !L.hideInputIcon && !L.$slots["dp-input"] ? (P(), Me(m(wn), {
              key: 1,
              "aria-label": (V = m(l)) == null ? void 0 : V.calendarIcon,
              class: "dp__input_icon dp__input_icons",
              onClick: ue[2] || (ue[2] = (R) => n("toggle"))
            }, null, 8, ["aria-label"])) : G("", !0)
          ]),
          L.$slots["clear-icon"] && e.inputValue && L.clearable && !L.disabled && !L.readonly ? (P(), Y("span", a3, [
            se(L.$slots, "clear-icon", { clear: E })
          ])) : G("", !0),
          L.clearable && !L.$slots["clear-icon"] && e.inputValue && !L.disabled && !L.readonly ? (P(), Y("button", {
            key: 3,
            "aria-label": (h = m(l)) == null ? void 0 : h.clearInput,
            class: "dp--clear-btn",
            type: "button",
            onKeydown: ue[4] || (ue[4] = (R) => m(Ot)(R, () => E(R), !0, U)),
            onClick: ue[5] || (ue[5] = Ba((R) => E(R), ["prevent"]))
          }, [
            $e(m(Yu), {
              class: "dp__input_icons",
              "data-test-id": "clear-icon"
            })
          ], 40, n3)) : G("", !0)
        ])) : G("", !0)
      ]);
    };
  }
}), l3 = typeof window < "u" ? window : void 0, Ol = () => {
}, i3 = (e) => yc() ? (ts(e), !0) : !1, o3 = (e, a, t, n) => {
  if (!e)
    return Ol;
  let r = Ol;
  const i = Ge(
    () => m(e),
    (o) => {
      r(), o && (o.addEventListener(a, t, n), r = () => {
        o.removeEventListener(a, t, n), r = Ol;
      });
    },
    { immediate: !0, flush: "post" }
  ), l = () => {
    i(), r();
  };
  return i3(l), l;
}, s3 = (e, a, t, n = {}) => {
  const { window: r = l3, event: i = "pointerdown" } = n;
  return r ? o3(r, i, (l) => {
    const o = ht(e), s = ht(a);
    !o || !s || o === l.target || l.composedPath().includes(o) || l.composedPath().includes(s) || t(l);
  }, { passive: !0 }) : void 0;
}, u3 = ["data-dp-mobile"], c3 = /* @__PURE__ */ be({
  compatConfig: {
    MODE: 3
  },
  __name: "VueDatePicker",
  props: {
    ...Xr
  },
  emits: [
    "update:model-value",
    "update:model-timezone-value",
    "text-submit",
    "closed",
    "cleared",
    "open",
    "focus",
    "blur",
    "internal-model-change",
    "recalculate-position",
    "flow-step",
    "update-month-year",
    "invalid-select",
    "invalid-fixed-range",
    "tooltip-open",
    "tooltip-close",
    "time-picker-open",
    "time-picker-close",
    "am-pm-change",
    "range-start",
    "range-end",
    "date-update",
    "invalid-date",
    "overlay-toggle",
    "text-input"
  ],
  setup(e, { expose: a, emit: t }) {
    const n = t, r = e, i = Ka(), l = z(!1), o = Qe(r, "modelValue"), s = Qe(r, "timezone"), d = z(null), u = z(null), c = z(null), f = z(!1), v = z(null), g = z(!1), b = z(!1), C = z(!1), p = z(!1), { setMenuFocused: w, setShiftKey: y } = tc(), { clearArrowNav: x } = Ea(), { validateDate: D, isValidTime: S } = $a(r), {
      defaultedTransitions: $,
      defaultedTextInput: O,
      defaultedInline: N,
      defaultedConfig: M,
      defaultedRange: B,
      defaultedMultiDates: X
    } = Je(r), { menuTransition: te, showTransition: T } = Qn($), { isMobile: E } = sc(M);
    He(() => {
      Z(r.modelValue), Ne().then(() => {
        if (!N.value.enabled) {
          const W = K(v.value);
          W == null || W.addEventListener("scroll", Q), window == null || window.addEventListener("resize", Ae);
        }
      }), N.value.enabled && (l.value = !0), window == null || window.addEventListener("keyup", oe), window == null || window.addEventListener("keydown", ze);
    }), Ht(() => {
      if (!N.value.enabled) {
        const W = K(v.value);
        W == null || W.removeEventListener("scroll", Q), window == null || window.removeEventListener("resize", Ae);
      }
      window == null || window.removeEventListener("keyup", oe), window == null || window.removeEventListener("keydown", ze);
    });
    const I = Et(i, "all", r.presetDates), q = Et(i, "input");
    Ge(
      [o, s],
      () => {
        Z(o.value);
      },
      { deep: !0 }
    );
    const { openOnTop: ne, menuStyle: U, xCorrect: L, setMenuPosition: ue, getScrollableParent: K, shadowRender: V } = Xh({
      menuRef: d,
      menuRefInner: u,
      inputRef: c,
      pickerWrapperRef: v,
      inline: N,
      emit: n,
      props: r,
      slots: i
    }), {
      inputValue: h,
      internalModelValue: R,
      parseExternalModelValue: Z,
      emitModelValue: _,
      formatInputValue: ce,
      checkBeforeEmit: ke
    } = j2(n, r, f), ft = A(
      () => ({
        dp__main: !0,
        dp__theme_dark: r.dark,
        dp__theme_light: !r.dark,
        dp__flex_display: N.value.enabled,
        "dp--flex-display-collapsed": C.value,
        dp__flex_display_with_input: N.value.input
      })
    ), j = A(() => r.dark ? "dp__theme_dark" : "dp__theme_light"), ae = A(() => r.teleport ? {
      to: typeof r.teleport == "boolean" ? "body" : r.teleport,
      disabled: !r.teleport || N.value.enabled
    } : {}), J = A(() => ({ class: "dp__outer_menu_wrap" })), fe = A(() => N.value.enabled && (r.timePicker || r.monthPicker || r.yearPicker || r.quarterPicker)), k = () => {
      var W, de;
      return ((de = (W = c.value) == null ? void 0 : W.$el) == null ? void 0 : de.getBoundingClientRect()) ?? { width: 0, left: 0, right: 0 };
    }, Q = () => {
      l.value && (M.value.closeOnScroll ? lt() : ue());
    }, Ae = () => {
      var W;
      l.value && ue();
      const de = ((W = u.value) == null ? void 0 : W.$el.getBoundingClientRect().width) ?? 0;
      C.value = document.body.offsetWidth <= de;
    }, oe = (W) => {
      W.key === "Tab" && !N.value.enabled && !r.teleport && M.value.tabOutClosesMenu && (v.value.contains(document.activeElement) || lt()), b.value = W.shiftKey;
    }, ze = (W) => {
      b.value = W.shiftKey;
    }, Ie = () => {
      !r.disabled && !r.readonly && (V(Xo, r), ue(!1), l.value = !0, l.value && n("open"), l.value || Gt(), Z(r.modelValue));
    }, F = () => {
      var W;
      h.value = "", Gt(), (W = c.value) == null || W.setParsedDate(null), n("update:model-value", null), n("update:model-timezone-value", null), n("cleared"), M.value.closeOnClearValue && lt();
    }, re = () => {
      const W = R.value;
      return !W || !Array.isArray(W) && D(W) ? !0 : Array.isArray(W) ? X.value.enabled || W.length === 2 && D(W[0]) && D(W[1]) ? !0 : B.value.partialRange && !r.timePicker ? D(W[0]) : !1 : !1;
    }, xe = () => {
      ke() && re() ? (_(), lt()) : n("invalid-select", R.value);
    }, mt = (W) => {
      Qt(), _(), M.value.closeOnAutoApply && !W && lt();
    }, Qt = () => {
      c.value && O.value.enabled && c.value.setParsedDate(R.value);
    }, _e = (W = !1) => {
      r.autoApply && S(R.value) && re() && (B.value.enabled && Array.isArray(R.value) ? (B.value.partialRange || R.value.length === 2) && mt(W) : mt(W));
    }, Gt = () => {
      O.value.enabled || (R.value = null);
    }, lt = (W = !1) => {
      W && R.value && M.value.setDateOnMenuClose && xe(), N.value.enabled || (l.value && (l.value = !1, L.value = !1, w(!1), y(!1), x(), n("closed"), h.value && Z(o.value)), Gt(), n("blur"));
    }, Zr = (W, de, we = !1) => {
      if (!W) {
        R.value = null;
        return;
      }
      const Jt = Array.isArray(W) ? !W.some((Ja) => !D(Ja)) : D(W), zt = S(W);
      Jt && zt ? (p.value = !0, R.value = W, de && (g.value = we, xe(), n("text-submit")), Ne().then(() => {
        p.value = !1;
      })) : n("invalid-date", W);
    }, el = () => {
      r.autoApply && S(R.value) && _(), Qt();
    }, Jn = () => l.value ? lt() : Ie(), tl = (W) => {
      R.value = W;
    }, al = () => {
      O.value.enabled && (f.value = !0, ce()), n("focus");
    }, nl = () => {
      if (O.value.enabled && (f.value = !1, Z(r.modelValue), g.value)) {
        const W = y2(v.value, b.value);
        W == null || W.focus();
      }
      n("blur");
    }, rl = (W) => {
      u.value && u.value.updateMonthYear(0, {
        month: Vo(W.month),
        year: Vo(W.year)
      });
    }, ll = (W) => {
      Z(W ?? r.modelValue);
    }, H = (W, de) => {
      var we;
      (we = u.value) == null || we.switchView(W, de);
    }, le = (W) => M.value.onClickOutside ? M.value.onClickOutside(W) : lt(!0), Oe = (W = 0) => {
      var de;
      (de = u.value) == null || de.handleFlow(W);
    };
    return s3(d, c, () => le(re)), a({
      closeMenu: lt,
      selectDate: xe,
      clearValue: F,
      openMenu: Ie,
      onScroll: Q,
      formatInputValue: ce,
      // exposed for testing purposes
      updateInternalModelValue: tl,
      // modify internal modelValue
      setMonthYear: rl,
      parseModel: ll,
      switchView: H,
      toggleMenu: Jn,
      handleFlow: Oe,
      dpWrapMenuRef: d
    }), (W, de) => (P(), Y("div", {
      ref_key: "pickerWrapperRef",
      ref: v,
      class: ye(ft.value),
      "data-datepicker-instance": "",
      "data-dp-mobile": m(E)
    }, [
      $e(r3, Ue({
        ref_key: "inputRef",
        ref: c,
        "input-value": m(h),
        "onUpdate:inputValue": de[0] || (de[0] = (we) => ra(h) ? h.value = we : null),
        "is-menu-open": l.value
      }, W.$props, {
        onClear: F,
        onOpen: Ie,
        onSetInputDate: Zr,
        onSetEmptyDate: m(_),
        onSelectDate: xe,
        onToggle: Jn,
        onClose: lt,
        onFocus: al,
        onBlur: nl,
        onRealBlur: de[1] || (de[1] = (we) => f.value = !1),
        onTextInput: de[2] || (de[2] = (we) => W.$emit("text-input", we))
      }), kt({ _: 2 }, [
        Fe(m(q), (we, Jt) => ({
          name: we,
          fn: me((zt) => [
            se(W.$slots, we, bt(Pt(zt)))
          ])
        }))
      ]), 1040, ["input-value", "is-menu-open", "onSetEmptyDate"]),
      (P(), Me(Dr(W.teleport ? Gl : "div"), bt(Pt(ae.value)), {
        default: me(() => [
          $e(Da, {
            name: m(te)(m(ne)),
            css: m(T) && !m(N).enabled
          }, {
            default: me(() => [
              l.value ? (P(), Y("div", Ue({
                key: 0,
                ref_key: "dpWrapMenuRef",
                ref: d
              }, J.value, {
                class: { "dp--menu-wrapper": !m(N).enabled },
                style: m(N).enabled ? void 0 : m(U)
              }), [
                $e(Xo, Ue({
                  ref_key: "dpMenuRef",
                  ref: u
                }, W.$props, {
                  "internal-model-value": m(R),
                  "onUpdate:internalModelValue": de[3] || (de[3] = (we) => ra(R) ? R.value = we : null),
                  class: { [j.value]: !0, "dp--menu-wrapper": W.teleport },
                  "open-on-top": m(ne),
                  "no-overlay-focus": fe.value,
                  collapse: C.value,
                  "get-input-rect": k,
                  "is-text-input-date": p.value,
                  onClosePicker: lt,
                  onSelectDate: xe,
                  onAutoApply: _e,
                  onTimeUpdate: el,
                  onFlowStep: de[4] || (de[4] = (we) => W.$emit("flow-step", we)),
                  onUpdateMonthYear: de[5] || (de[5] = (we) => W.$emit("update-month-year", we)),
                  onInvalidSelect: de[6] || (de[6] = (we) => W.$emit("invalid-select", m(R))),
                  onAutoApplyInvalid: de[7] || (de[7] = (we) => W.$emit("invalid-select", we)),
                  onInvalidFixedRange: de[8] || (de[8] = (we) => W.$emit("invalid-fixed-range", we)),
                  onRecalculatePosition: m(ue),
                  onTooltipOpen: de[9] || (de[9] = (we) => W.$emit("tooltip-open", we)),
                  onTooltipClose: de[10] || (de[10] = (we) => W.$emit("tooltip-close", we)),
                  onTimePickerOpen: de[11] || (de[11] = (we) => W.$emit("time-picker-open", we)),
                  onTimePickerClose: de[12] || (de[12] = (we) => W.$emit("time-picker-close", we)),
                  onAmPmChange: de[13] || (de[13] = (we) => W.$emit("am-pm-change", we)),
                  onRangeStart: de[14] || (de[14] = (we) => W.$emit("range-start", we)),
                  onRangeEnd: de[15] || (de[15] = (we) => W.$emit("range-end", we)),
                  onDateUpdate: de[16] || (de[16] = (we) => W.$emit("date-update", we)),
                  onInvalidDate: de[17] || (de[17] = (we) => W.$emit("invalid-date", we)),
                  onOverlayToggle: de[18] || (de[18] = (we) => W.$emit("overlay-toggle", we)),
                  onMenuBlur: de[19] || (de[19] = (we) => W.$emit("blur"))
                }), kt({ _: 2 }, [
                  Fe(m(I), (we, Jt) => ({
                    name: we,
                    fn: me((zt) => [
                      se(W.$slots, we, bt(Pt({ ...zt })))
                    ])
                  }))
                ]), 1040, ["internal-model-value", "class", "open-on-top", "no-overlay-focus", "collapse", "is-text-input-date", "onRecalculatePosition"])
              ], 16)) : G("", !0)
            ]),
            _: 3
          }, 8, ["name", "css"])
        ]),
        _: 3
      }, 16))
    ], 10, u3));
  }
}), Bi = /* @__PURE__ */ (() => {
  const e = c3;
  return e.install = (a) => {
    a.component("Vue3DatePicker", e);
  }, e;
})(), d3 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: Bi
}, Symbol.toStringTag, { value: "Module" }));
Object.entries(d3).forEach(([e, a]) => {
  e !== "default" && (Bi[e] = a);
});
const f3 = ["type", "disabled"], Qo = /* @__PURE__ */ be({
  __name: "UIButton",
  props: {
    size: { default: "md" },
    type: { default: "default" },
    variant: { default: "default" },
    submit: { type: Boolean },
    disabled: { type: Boolean },
    to: {},
    openInNewTab: { type: Boolean }
  },
  setup(e) {
    const a = e, { isSubmitting: t } = wc(), n = A(() => a.submit && t.value);
    return (r, i) => {
      const l = Jl("router-link");
      return r.to ? (P(), Me(l, {
        key: 1,
        to: r.to,
        disabled: r.disabled,
        class: ye(["tw-reset focus:outline-none", {
          "text-center text-base font-bold rounded-md border focus:shadow-glow disabled:bg-ui-gray-300 disabled:border-ui-gray-300 disabled:text-white": r.type !== "link",
          "hover:underline disabled:text-ui-gray-300 cursor-pointer font-bold focus:underline": r.type === "link",
          "border-pivot-teal-700 bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 hover:border-pivot-teal-800": r.variant === "default" && r.type === "default",
          "border-pivot-purple-600 bg-pivot-purple-600 text-white hover:bg-pivot-purple-700 hover:border-pivot-purple-700": r.variant === "alt" && r.type === "default",
          "border-error-red-600 bg-error-red-600 text-white hover:bg-error-red-700 hover:border-error-red-700": r.variant === "destructive" && r.type === "default",
          "border-transparent bg-transparent text-pivot-teal-600 hover:bg-pivot-teal-50 disabled:text-white": r.variant === "reverse" && r.type === "default",
          "border-pivot-teal-700 bg-white text-pivot-teal-700 hover:border-pivot-teal-800 hover:text-pivot-teal-800 disabled:bg-transparent": r.variant === "default" && r.type === "outline",
          "border-pivot-purple-600 bg-white text-pivot-purple-600 hover:border-pivot-purple-700 hover:text-pivot-purple-700 disabled:bg-transparent": r.variant === "alt" && r.type === "outline",
          "border-error-red-600 bg-white text-error-red-600 hover:border-error-red-700 hover:text-error-red-700 disabled:bg-transparent": r.variant === "destructive" && r.type === "outline",
          "border-white bg-white text-white hover:bg-pivot-teal-50 disabled:bg-transparent": r.variant === "reverse" && r.type === "outline",
          "border-transparent bg-white text-pivot-teal-700 hover:border-pivot-teal-700 disabled:bg-transparent": r.variant === "default" && r.type === "invisible",
          "border-transparent bg-white text-pivot-purple-600 hover:border-pivot-purple-600 disabled:bg-transparent": r.variant === "alt" && r.type === "invisible",
          "border-transparent bg-white text-error-red-600 hover:border-error-red-600 disabled:bg-transparent": r.variant === "destructive" && r.type === "invisible",
          "text-error-red-600": r.variant === "destructive" && r.type === "link",
          "text-pivot-teal-700": r.variant !== "destructive" && r.type === "link",
          "py-1.5 px-5": r.size === "md" && r.type !== "link",
          "py-0.5 px-2": r.size === "sm" && r.type !== "link"
        }]),
        target: r.openInNewTab ? "_blank" : "_self"
      }, {
        default: me(() => [
          se(r.$slots, "default")
        ]),
        _: 3
      }, 8, ["to", "disabled", "target", "class"])) : (P(), Y("button", {
        key: 0,
        type: r.submit ? "submit" : "button",
        disabled: r.disabled,
        class: ye(["tw-reset focus:outline-none", {
          "text-center text-base font-bold rounded-md border focus:shadow-glow disabled:bg-ui-gray-300 disabled:border-ui-gray-300 disabled:text-white": r.type !== "link",
          "hover:underline disabled:text-ui-gray-300 cursor-pointer font-bold focus:underline": r.type === "link",
          "border-pivot-teal-700 bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 hover:border-pivot-teal-800": r.variant === "default" && r.type === "default",
          "border-pivot-purple-600 bg-pivot-purple-600 text-white hover:bg-pivot-purple-700 hover:border-pivot-purple-700": r.variant === "alt" && r.type === "default",
          "border-error-red-600 bg-error-red-600 text-white hover:bg-error-red-700 hover:border-error-red-700": r.variant === "destructive" && r.type === "default",
          "border-transparent bg-transparent text-pivot-teal-600 hover:bg-pivot-teal-50 disabled:text-white": r.variant === "reverse" && r.type === "default",
          "border-pivot-teal-700 text-pivot-teal-700 hover:border-pivot-teal-800 hover:text-pivot-teal-800 disabled:bg-transparent": r.variant === "default" && r.type === "outline",
          "border-pivot-purple-600 text-pivot-purple-600 hover:border-pivot-purple-700 hover:text-pivot-purple-700 disabled:bg-transparent": r.variant === "alt" && r.type === "outline",
          "border-error-red-600 text-error-red-600 hover:border-error-red-700 hover:text-error-red-700 disabled:bg-transparent": r.variant === "destructive" && r.type === "outline",
          "border-white bg-white text-white hover:bg-pivot-teal-50 disabled:bg-transparent": r.variant === "reverse" && r.type === "outline",
          "border-transparent text-pivot-teal-700 hover:border-pivot-teal-700 disabled:bg-transparent": r.variant === "default" && r.type === "invisible",
          "border-transparent text-pivot-purple-600 hover:border-pivot-purple-600 disabled:bg-transparent": r.variant === "alt" && r.type === "invisible",
          "border-transparent text-error-red-600 hover:border-error-red-600 disabled:bg-transparent": r.variant === "destructive" && r.type === "invisible",
          "text-error-red-600": r.variant === "destructive" && r.type === "link",
          "text-pivot-teal-700": r.variant !== "destructive" && r.type === "link",
          "py-1.5 px-5": r.size === "md" && r.type !== "link",
          "py-0.5 px-2": r.size === "sm" && r.type !== "link"
        }])
      }, [
        n.value ? (P(), Y(Se, { key: 1 }, [
          ie("div", {
            class: ye(`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] ${!r.variant || r.variant == "default" ? "text-white" : "text-pivot-teal-800"}`),
            role: "status",
            "aria-hidden": "true"
          }, null, 2),
          vt(" Submitting ")
        ], 64)) : se(r.$slots, "default", { key: 0 })
      ], 10, f3));
    };
  }
}), v3 = ["disabled", "onClick"], m3 = /* @__PURE__ */ ie("span", { class: "sr-only" }, "Open Calendar", -1), p3 = ["value", "disabled", "onInput", "onBlur", "onKeydown", "onPaste"], h3 = { class: "flex gap-3 items-center w-full mt-2" }, y3 = /* @__PURE__ */ ie("div", { class: "flex-grow" }, null, -1), ny = /* @__PURE__ */ be({
  __name: "UIDateInput",
  props: {
    modelValue: {},
    name: {},
    rules: {},
    label: { default: "date" },
    disabled: { type: Boolean },
    clearable: { type: Boolean },
    time: { type: Boolean },
    max: {},
    min: {}
  },
  setup(e) {
    const a = e, { value: t, errorMessage: n, handleBlur: r } = Ia(
      Qe(a, "name"),
      Qe(a, "rules"),
      {
        label: Qe(a, "label"),
        syncVModel: !0
      }
    ), i = z(null);
    function l() {
      var d;
      (d = i.value) == null || d.clearValue();
    }
    const o = /^\s*(\d+)\s*([/\-.])\s*(\d+)(?:\s*\2\s*(\d+))?(?:(?:\s*,\s*|\s+)(\d+)\s*:\s*(\d+)(?:\s*([APap][mM]))?)?\s*$/;
    function s(d) {
      var f;
      const u = /* @__PURE__ */ new Date();
      u.setSeconds(0), u.setMilliseconds(0);
      const c = o.exec(d);
      if (!c)
        return null;
      if (c[4]) {
        const v = parseInt(c[4]);
        u.setFullYear(v < 100 ? v + 2e3 : v);
      }
      if (u.setMonth(parseInt(c[1]) - 1), u.setDate(parseInt(c[3])), c[5] && c[6]) {
        const v = ((f = c[7]) == null ? void 0 : f.toLowerCase()) == "pm";
        let g = parseInt(c[5]);
        u.setHours(g < 12 && v ? g + 12 : g), u.setMinutes(parseInt(c[6]));
      }
      return u;
    }
    return (d, u) => (P(), Y("div", null, [
      $e(m(Bi), {
        ref_key: "picker",
        ref: i,
        modelValue: m(t),
        "onUpdate:modelValue": u[0] || (u[0] = (c) => ra(t) ? t.value = c : null),
        class: "date-picker",
        disabled: d.disabled,
        clearable: d.clearable,
        "enable-time-picker": d.time,
        "text-input": { openMenu: !1, format: s },
        "min-date": d.min,
        "max-date": d.max,
        "auto-apply": !d.time,
        "six-weeks": "center",
        teleport: "",
        "time-picker-inline": "",
        "is-24": !1,
        onKeydown: u[1] || (u[1] = pr(Ba(() => {
        }, ["prevent"]), ["enter"])),
        onBlur: m(r)
      }, {
        "dp-input": me(({
          value: c,
          onInput: f,
          onEnter: v,
          onTab: g,
          onBlur: b,
          onKeypress: C,
          onPaste: p,
          toggleMenu: w
        }) => [
          ie("div", {
            class: ye(["tw-reset flex w-full h-8 border rounded-sm border-ui-gray-400 focus-within:shadow-glow", {
              "border-warning-orange-600": m(n)
            }])
          }, [
            ie("button", {
              type: "button",
              class: ye(["bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 focus:outline-none w-8 h-full disabled:bg-ui-gray-300", {
                "text-warning-orange-600 italic": m(n)
              }]),
              disabled: d.disabled,
              tabindex: "-1",
              onClick: w
            }, [
              $e(m(qt), { icon: "calendar" }),
              m3
            ], 10, v3),
            ie("input", {
              class: ye(["inline-block px-2 h-full w-full rounded-sm focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100", {
                "pr-8": d.clearable
              }]),
              value: c,
              disabled: d.disabled,
              onInput: f,
              onBlur: b,
              onKeydown: [
                C,
                pr(v, ["enter"]),
                pr(g, ["tab"])
              ],
              onPaste: p
            }, null, 42, p3)
          ], 2)
        ]),
        "action-row": me(({ selectDate: c }) => [
          ie("div", h3, [
            d.clearable ? (P(), Me(Qo, {
              key: 0,
              size: "sm",
              type: "link",
              variant: "destructive",
              onClick: l
            }, {
              default: me(() => [
                vt(" Clear ")
              ]),
              _: 1
            })) : G("", !0),
            y3,
            $e(Qo, {
              size: "sm",
              onClick: c
            }, {
              default: me(() => [
                vt(" Select ")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "disabled", "clearable", "enable-time-picker", "text-input", "min-date", "max-date", "auto-apply", "onBlur"])
    ]));
  }
}), g3 = { class: "relative" }, b3 = {
  key: 0,
  class: "py-2 px-4 text-ui-gray-600"
}, ry = /* @__PURE__ */ be({
  __name: "UICombobox",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    options: {},
    placeholder: { default: "Search and select..." },
    multiple: { type: Boolean, default: !1 }
  },
  setup(e) {
    const a = e, t = z(""), { value: n, errorMessage: r, handleBlur: i } = Ia(Qe(a, "name"), Qe(a, "rules"), {
      label: Qe(a, "label"),
      syncVModel: !0
    }), l = A(() => t.value === "" ? a.options : a.options.filter((d) => d.label.toLowerCase().includes(t.value.toLowerCase()))), o = z(), s = z();
    return ns((d) => {
      const u = o.value, c = s.value;
      !u || !c || d(
        yu(u, c, async () => {
          const { x: f, y: v } = await bu(u, c, {
            placement: "bottom-start",
            middleware: [gu()]
          });
          c.style.left = `${f}px`, c.style.top = `${v}px`, c.style.width = `${u.clientWidth}px`, u.closest("[role=dialog]") && (c.style.zIndex = "10000");
        })
      );
    }), (d, u) => (P(), Y("div", {
      ref_key: "anchorRef",
      ref: o
    }, [
      $e(m(F0), {
        as: "div",
        modelValue: m(n),
        "onUpdate:modelValue": u[1] || (u[1] = (c) => ra(n) ? n.value = c : null),
        multiple: d.multiple,
        by: "value"
      }, {
        default: me(() => [
          ie("div", g3, [
            $e(m(Y0), {
              class: ye(["tw-reset w-full px-2 h-8 border rounded-sm border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100", {
                "border-warning-orange-600 text-warning-orange-600 italic": m(r),
                "pr-8": d.multiple
              }]),
              displayValue: () => t.value,
              onChange: u[0] || (u[0] = (c) => t.value = c.target.value),
              placeholder: d.placeholder,
              onBlur: m(i)
            }, null, 8, ["class", "displayValue", "placeholder", "onBlur"]),
            d.multiple ? (P(), Me(m(V0), {
              key: 0,
              class: "absolute inset-y-0 right-0 flex items-center pr-2"
            }, {
              default: me(() => [
                $e(m(qt), {
                  class: "h-4 w-4 text-gray-800",
                  "aria-hidden": "true",
                  icon: "caret-down"
                })
              ]),
              _: 1
            })) : G("", !0)
          ]),
          (P(), Me(Gl, { to: "body" }, [
            ie("div", {
              ref_key: "menuRef",
              ref: s,
              class: "absolute z-10 [[role=dialog]_&]:z-[10000]"
            }, [
              $e(m(B0), { class: "max-h-60 w-full overflow-auto rounded-sm bg-white shadow-lg border border-ui-gray-400 focus:outline-none mt-1" }, {
                default: me(() => [
                  l.value.length === 0 ? (P(), Y("div", b3, " No matches found ")) : G("", !0),
                  (P(!0), Y(Se, null, Fe(l.value, (c) => (P(), Me(m(j0), {
                    key: c.value,
                    value: c,
                    as: "template"
                  }, {
                    default: me(({ active: f, selected: v }) => [
                      ie("li", {
                        class: ye(["relative py-2 px-4 cursor-default select-none", {
                          "bg-pivot-teal-600 text-white": f,
                          "text-ui-gray-800": !f,
                          "font-semibold": v
                        }])
                      }, [
                        vt(Le(c.label) + " ", 1),
                        v ? (P(), Y("span", {
                          key: 0,
                          class: ye(["absolute inset-y-0 right-0 flex items-center pr-3", f ? "text-white" : "text-pivot-teal-600"])
                        }, [
                          $e(m(qt), {
                            icon: "check",
                            size: "sm"
                          })
                        ], 2)) : G("", !0)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))), 128))
                ]),
                _: 1
              })
            ], 512)
          ]))
        ]),
        _: 1
      }, 8, ["modelValue", "multiple"])
    ], 512));
  }
}), w3 = { class: "tw-reset overflow-hidden rounded-lg bg-white shadow-lg border border-ui-gray-300 p-4" }, ly = /* @__PURE__ */ be({
  __name: "UICard",
  props: {
    collapsible: { type: Boolean },
    modelValue: { type: Boolean },
    "onUpdate:modelValue": { type: Function }
  },
  setup(e) {
    const a = e, t = z(!1), n = A(() => !!a["onUpdate:modelValue"]), r = A({
      get() {
        return a.collapsible ? n.value ? !!a.modelValue : t.value : !1;
      },
      set(i) {
        var l;
        n.value ? (l = a["onUpdate:modelValue"]) == null || l.call(a, i) : t.value = i;
      }
    });
    return Nt(
      "card",
      qa({ collapsed: r, collapsible: Qe(a, "collapsible") })
    ), (i, l) => (P(), Y("div", w3, [
      se(i.$slots, "default")
    ]));
  }
}), x3 = { class: "group-focus:underline group-hover:underline" }, iy = /* @__PURE__ */ be({
  __name: "UICardHeading",
  setup(e) {
    const a = It("card");
    if (!a)
      throw new Error("UICardHeading must be used inside UICard");
    const t = Qe(a, "collapsible"), n = Qe(a, "collapsed");
    function r() {
      t.value && (n.value = !n.value);
    }
    return (i, l) => (P(), Y("button", {
      type: "button",
      class: ye(["group tw-reset text-pivot-purple-700 font-bold text-lg focus:outline-none", {
        "cursor-pointer": t.value
      }]),
      onClick: r
    }, [
      t.value ? (P(), Me(m(qt), {
        key: 0,
        icon: n.value ? "chevron-up" : "chevron-down",
        class: "text-pivot-purple-700 font-base mr-1",
        size: "lg"
      }, null, 8, ["icon"])) : G("", !0),
      ie("span", x3, [
        se(i.$slots, "default")
      ])
    ], 2));
  }
}), oy = /* @__PURE__ */ be({
  __name: "UICardContent",
  props: {
    unmount: { type: Boolean, default: !0 }
  },
  setup(e) {
    const a = It("card");
    if (!a)
      throw new Error("UICardContent must be used inside UICard");
    const t = Qe(a, "collapsed");
    return (n, r) => (P(), Me(m(ru), {
      show: !t.value,
      unmount: n.unmount,
      class: "tw-reset",
      enter: "transition duration-100 ease-out",
      "enter-from": "transform opacity-0",
      "enter-to": "transform opacity-100",
      leave: "transition duration-75 ease-out",
      "leave-from": "transform opacity-100",
      "leave-to": "transform opacity-0"
    }, {
      default: me(() => [
        se(n.$slots, "default")
      ]),
      _: 3
    }, 8, ["show", "unmount"]));
  }
}), k3 = { key: 0 }, vr = /* @__PURE__ */ be({
  __name: "UIStep",
  props: {
    state: { default: "future" },
    index: {}
  },
  setup(e) {
    return (a, t) => (P(), Y("div", {
      class: ye(["group flex text-base font-bold items-center", {
        "text-ui-gray-800": a.state !== "future",
        "text-ui-gray-400 hover:text-ui-gray-600": a.state === "future"
      }])
    }, [
      ie("span", {
        class: ye(["border rounded-full w-6 h-6 mr-2 flex items-center justify-center flex-shrink-0", {
          "border-ui-gray-400 group-hover:border-ui-gray-600 group-hover:text-ui-gray-800": a.state === "future",
          "bg-pivot-teal-700 border-pivot-teal-700": a.state === "complete",
          "bg-warning-orange-600 border-warning-orange-600": a.state === "warn",
          "bg-pivot-purple-700 border-pivot-purple-700": a.state === "current",
          "text-white": a.state !== "future"
        }])
      }, [
        a.state !== "complete" && a.state !== "warn" ? (P(), Y("span", k3, Le(a.index), 1)) : G("", !0),
        a.state === "complete" || a.state === "warn" ? (P(), Me(m(qt), {
          key: 1,
          icon: a.state === "complete" ? "check" : "exclamation",
          class: "w-4 h-4 inline-flex"
        }, null, 8, ["icon"])) : G("", !0)
      ], 2),
      se(a.$slots, "default")
    ], 2));
  }
}), Ar = [
  "click",
  "mouseenter",
  "mouseleave",
  "mouseover",
  "mouseout",
  "mousemove"
].map((e) => ({
  nativeEventName: e,
  sectionEventName: `section-${e}`
})), Go = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#F58231",
  "#46F0F0",
  "#D2F53C",
  "#911EB4",
  "#F032E6",
  "#3CB44B",
  "#FFE119",
  "#E6194B",
  "#FABEBE",
  "#008080",
  "#E6BEFF",
  "#0082C8",
  "#AA6E28",
  "#FFFAC8",
  "#800000",
  "#AAFFC3",
  "#808000",
  "#FFD8B1",
  "#000080",
  "#808080",
  "#000000"
], Sl = "1em", _3 = "dodgerblue", on = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left"
}, M3 = {
  [on.TOP]: {
    container: { flexDirection: "column" },
    legend: { order: -1, margin: 0, marginBottom: Sl }
  },
  [on.RIGHT]: {
    container: {},
    legend: {
      flexDirection: "column",
      margin: 0,
      marginLeft: Sl
    }
  },
  [on.BOTTOM]: {
    container: { flexDirection: "column" },
    legend: {}
  },
  [on.LEFT]: {
    container: {},
    legend: {
      flexDirection: "column",
      order: -1,
      margin: 0,
      marginRight: Sl
    }
  }
}, O3 = (e) => !!e && e.constructor === Object, S3 = [
  { type: "number", key: "value", required: !0 },
  { key: "label" },
  { key: "color" }
];
function T3(e) {
  return O3(e) ? S3.reduce((a, t) => {
    if (!a)
      return !1;
    let n = !0;
    return t.required && (n = n && Object.hasOwnProperty.call(e, t.key)), t.type && (n = n && typeof e[t.key] === t.type), a && n;
  }, !0) : !1;
}
const uc = (e, a) => {
  const t = e.__vccOpts || e;
  for (const [n, r] of a)
    t[n] = r;
  return t;
}, Jo = {
  LEFT: "cdc-section-left",
  RIGHT: "cdc-section-right"
}, P3 = {
  props: {
    startAngle: { type: Number, default: 0 },
    sections: { type: Array, default: () => [] }
  },
  emits: Ar.map((e) => e.sectionEventName),
  computed: {
    containerStyles() {
      return {
        transform: `rotate(${this.startAngle}deg)`
      };
    },
    donutSections() {
      let a = 0, t = 0;
      return this.sections.map((r) => {
        let [i, l] = [Jo.RIGHT, -180];
        a >= 180 && ([i, l] = [Jo.LEFT, 180]);
        const o = l + r.degree, s = {
          backgroundColor: r.color || _3,
          transform: `rotate(${o}deg)`
        }, d = { transform: `rotate(${t}deg)` };
        a += r.degree, a === 180 ? t = 0 : t += r.degree;
        const u = Ar.reduce((c, { nativeEventName: f, sectionEventName: v }) => ({
          ...c,
          [f]: (g) => this.emitEvent(v, r, g)
        }), {});
        return {
          label: r.label,
          className: i,
          fillerStyles: s,
          sectionStyles: d,
          listeners: u
        };
      });
    }
  },
  methods: {
    emitEvent(e, a, t) {
      a.value !== 0 && this.$emit(e, a.$section, t);
    }
  }
}, C3 = ["title"];
function A3(e, a, t, n, r, i) {
  return P(), Y("div", {
    class: "cdc-sections",
    style: Ze(i.containerStyles)
  }, [
    (P(!0), Y(Se, null, Fe(i.donutSections, (l, o) => (P(), Y("div", Ue(rs(l.listeners, !0), {
      key: o,
      class: ["cdc-section", l.className],
      style: l.sectionStyles
    }), [
      ie("div", {
        class: "cdc-filler",
        style: Ze(l.fillerStyles),
        title: l.label
      }, null, 12, C3)
    ], 16))), 128))
  ], 4);
}
const D3 = /* @__PURE__ */ uc(P3, [["render", A3]]), I3 = {
  name: "vc-donut",
  props: {
    // diameter of the donut
    size: { type: Number, default: 250, validator: (e) => e > 0 },
    // unit to use for `size`
    unit: { type: String, default: "px" },
    // percentage of donut ring's thickness
    thickness: {
      type: Number,
      default: 20,
      validator: (e) => e >= 0 && e <= 100
    },
    // text in the middle of the donut, this can also be passed using the default slot
    text: { type: String, default: null },
    autoAdjustTextSize: { type: Boolean, default: !0 },
    // color to use for the middle of the donut
    // set this to `transparent` or `thickness` to 100 to make a pie chart instead
    background: { type: String, default: "#ffffff" },
    // color to use for the empty ring areas
    foreground: { type: String, default: "#eeeeee" },
    // sections of the donut, must have a `value` property
    // other valid properties are `label` and `color` (default is `dodgerblue`)
    sections: {
      type: Array,
      default: () => [],
      validator(e) {
        for (let a = 0; a < e.length; ++a)
          if (!T3(e[a]))
            return !1;
        return !0;
      }
    },
    total: { type: Number, default: 100, validator: (e) => e > 0 },
    hasLegend: { type: Boolean, default: !1 },
    legendPlacement: {
      type: String,
      default: on.BOTTOM,
      validator: (e) => !!on[e.toUpperCase()]
    },
    // degree angle at which the first section begins
    startAngle: { type: Number, default: 0 }
  },
  emits: Ar.map((e) => e.sectionEventName),
  watch: {
    autoAdjustTextSize(e) {
      e ? window.addEventListener("resize", this.resizeListener) : window.removeEventListener("resize", this.resizeListener), this.recalcFontSize();
    },
    size() {
      this.recalcFontSize();
    },
    unit() {
      this.recalcFontSize();
    }
  },
  data() {
    return {
      donutEl: null,
      fontSize: "1em",
      resizeListener: null
    };
  },
  computed: {
    donutSections() {
      let e = this.sections.reduce((l, o) => l + o.value, 0);
      if (typeof e != "number")
        return [];
      if (e = Number(e.toFixed(2)), e > this.total) {
        const l = `Sum of all the sections' values (${e}) should not exceed \`total\` (${this.total})`;
        throw new Error(l);
      }
      const a = 360, t = 180;
      let n = 0, r = 0;
      const i = [];
      return this.sections.forEach((l) => {
        const o = a * (l.value / this.total);
        let s = [o];
        o > t && (s = [t, o - t]);
        const d = l.color || Go[r++];
        s.forEach((u) => {
          if (Number((n + u).toFixed(2)) > t) {
            const f = t - n;
            i.push(
              { ...l, degree: f, color: d, $section: l },
              { ...l, degree: u - f, color: d, $section: l }
            );
          } else
            i.push({ ...l, degree: u, color: d, $section: l });
          n += u, n >= t && (n -= t);
        });
      }), i;
    },
    legend() {
      if (!this.hasLegend)
        return null;
      let e = 0;
      return this.sections.map((a, t) => ({
        label: a.label || `Section ${t + 1}`,
        percent: `${a.value} (${a.value / this.total * 100}%)`,
        styles: {
          backgroundColor: a.color || Go[e++]
        }
      }));
    },
    placementStyles() {
      return this.hasLegend ? M3[this.legendPlacement] : {};
    },
    donutStyles() {
      const e = `${this.size}${this.unit}`;
      return {
        width: e,
        paddingBottom: e,
        backgroundColor: this.foreground
      };
    },
    overlayStyles() {
      const a = 100 - this.thickness, t = `${a}%`, n = `calc(50% - ${a / 2}%)`;
      return {
        height: t,
        width: t,
        top: n,
        left: n,
        backgroundColor: this.background
      };
    },
    donutTextStyles() {
      const { fontSize: e } = this;
      return { fontSize: e };
    },
    sectionListeners() {
      return Ar.reduce((e, { sectionEventName: a }) => ({
        ...e,
        [a]: (...t) => this.emitSectionEvent(a, ...t)
      }), {});
    }
  },
  methods: {
    recalcFontSize() {
      if (!this.autoAdjustTextSize) {
        this.fontSize = "1em";
        return;
      }
      const e = 0.08;
      let a = this.size;
      this.$nextTick(() => {
        this.unit !== "px" && (this.donutEl ? a = this.donutEl.clientWidth : a = null), this.fontSize = a ? `${(a * e).toFixed(2)}px` : "1em";
      });
    },
    emitSectionEvent(e, ...a) {
      this.$emit(e, ...a);
    }
  },
  created() {
    this.resizeListener = this.recalcFontSize.bind(this);
  },
  mounted() {
    this.donutEl = this.$refs.donut, this.autoAdjustTextSize && (this.recalcFontSize(), window.addEventListener("resize", this.resizeListener));
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  },
  components: { DonutSections: D3 }
}, E3 = ["title"];
function $3(e, a, t, n, r, i) {
  const l = Jl("donut-sections");
  return P(), Y("div", {
    class: "cdc-container",
    style: Ze(i.placementStyles.container)
  }, [
    ie("div", {
      class: "cdc",
      ref: "donut",
      style: Ze(i.donutStyles)
    }, [
      $e(l, Ue(rs(i.sectionListeners), {
        sections: i.donutSections,
        "start-angle": t.startAngle
      }), null, 16, ["sections", "start-angle"]),
      ie("div", {
        class: "cdc-overlay",
        style: Ze(i.overlayStyles)
      }, [
        ie("div", {
          class: "cdc-text",
          style: Ze(i.donutTextStyles)
        }, [
          se(e.$slots, "default", {}, () => [
            vt(Le(t.text), 1)
          ])
        ], 4)
      ], 4)
    ], 4),
    se(e.$slots, "legend", {}, () => [
      t.hasLegend ? (P(), Y("div", {
        key: 0,
        class: "cdc-legend",
        style: Ze(i.placementStyles.legend)
      }, [
        (P(!0), Y(Se, null, Fe(i.legend, (o, s) => (P(), Y("span", {
          class: "cdc-legend-item",
          key: s,
          title: o.percent
        }, [
          ie("span", {
            class: "cdc-legend-item-color",
            style: Ze(o.styles)
          }, null, 4),
          ie("span", null, Le(o.label), 1)
        ], 8, E3))), 128))
      ], 4)) : G("", !0)
    ])
  ], 4);
}
const R3 = /* @__PURE__ */ uc(I3, [["render", $3]]), N3 = { "aria-label": "Progress" }, L3 = {
  role: "list",
  class: "hidden md:flex items-center"
}, H3 = { class: "flex md:hidden justify-between" }, z3 = { class: "w-full flex" }, F3 = { class: "pl-4" }, V3 = { class: "px-2 py-2" }, Y3 = { class: "w-20 py-2" }, sy = /* @__PURE__ */ be({
  __name: "UIStepList",
  props: {
    steps: {},
    currentStep: {},
    erroredSteps: { default: () => [] }
  },
  setup(e) {
    const a = e, t = A(
      () => a.steps.find((o) => o.key === a.currentStep)
    ), n = A(() => a.steps.length ?? 0), r = A(
      () => a.steps.findIndex((o) => o.key === a.currentStep)
    ), i = A(() => a.currentStep ? a.steps.slice(0, r.value).map((o) => o.key) : []), l = A(() => a.steps.map((o) => {
      var s;
      return {
        label: o.name,
        value: 100 / a.steps.length,
        color: o.key === a.currentStep ? "#433069" : (s = i.value) != null && s.includes(o.key) ? "#1da7a7" : "#979797"
      };
    }));
    return (o, s) => {
      const d = Jl("router-link");
      return P(), Y("nav", N3, [
        ie("ol", L3, [
          (P(!0), Y(Se, null, Fe(o.steps, (u, c) => (P(), Y("li", {
            key: u.key,
            class: "pr-8 last:pr-0 relative"
          }, [
            u.href ? (P(), Me(d, {
              key: 0,
              to: u.href,
              class: "cursor-pointer"
            }, {
              default: me(() => [
                $e(vr, {
                  index: c + 1,
                  state: o.erroredSteps.includes(u.key) ? "warn" : u.key === o.currentStep ? "current" : i.value.includes(u.key) ? "complete" : "future"
                }, {
                  default: me(() => [
                    vt(Le(u.name), 1)
                  ]),
                  _: 2
                }, 1032, ["index", "state"])
              ]),
              _: 2
            }, 1032, ["to"])) : (P(), Me(vr, {
              key: 1,
              index: c + 1,
              state: o.erroredSteps.includes(u.key) ? "warn" : u.key === o.currentStep ? "current" : i.value.includes(u.key) ? "complete" : "future"
            }, {
              default: me(() => [
                vt(Le(u.name), 1)
              ]),
              _: 2
            }, 1032, ["index", "state"]))
          ]))), 128))
        ]),
        ie("div", H3, [
          $e(m(i1), {
            as: "div",
            class: "relative inline-block text-left w-full"
          }, {
            default: me(() => [
              $e(m(o1), { class: "inline-flex w-full gap-x-1.5 px-3 py-2 text-base justify-between" }, {
                default: me(({ open: u }) => {
                  var c;
                  return [
                    ie("div", z3, [
                      $e(R3, {
                        size: 25,
                        background: "white",
                        foreground: "gainsboro",
                        sections: l.value,
                        thickness: 20
                      }, null, 8, ["sections"]),
                      ie("span", F3, Le((c = t.value) == null ? void 0 : c.name), 1)
                    ]),
                    $e(m(qt), {
                      icon: u ? "caret-up" : "caret-down",
                      class: "block pt-1",
                      size: "sm"
                    }, null, 8, ["icon"])
                  ];
                }),
                _: 1
              }),
              $e(Da, {
                "enter-active-class": "transition ease-out duration-100",
                "enter-from-class": "transform opacity-0 scale-95",
                "enter-to-class": "transform opacity-100 scale-100",
                "leave-active-class": "transition ease-in duration-75",
                "leave-from-class": "transform opacity-100 scale-100",
                "leave-to-class": "transform opacity-0 scale-95"
              }, {
                default: me(() => [
                  $e(m(s1), { class: "absolute left-0 z-[9999] mt-2 w-56 origin-top-left focus:outline-none rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" }, {
                    default: me(() => [
                      ie("div", V3, [
                        (P(!0), Y(Se, null, Fe(o.steps, (u, c) => (P(), Me(m(u1), {
                          key: u.key,
                          class: "py-2 relative"
                        }, {
                          default: me(() => [
                            u.href ? (P(), Me(d, {
                              key: 0,
                              to: u.href,
                              class: "cursor-pointer flex"
                            }, {
                              default: me(() => [
                                $e(vr, {
                                  index: c + 1,
                                  state: o.erroredSteps.includes(u.key) ? "warn" : u.key === o.currentStep ? "current" : i.value.includes(u.key) ? "complete" : "future"
                                }, {
                                  default: me(() => [
                                    vt(Le(u.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["index", "state"])
                              ]),
                              _: 2
                            }, 1032, ["to"])) : (P(), Me(vr, {
                              key: 1,
                              index: c + 1,
                              state: o.erroredSteps.includes(u.key) ? "warn" : u.key === o.currentStep ? "current" : i.value.includes(u.key) ? "complete" : "future"
                            }, {
                              default: me(() => [
                                vt(Le(u.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["index", "state"]))
                          ]),
                          _: 2
                        }, 1024))), 128))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          ie("div", Y3, Le(r.value + 1) + " of " + Le(n.value), 1)
        ])
      ]);
    };
  }
});
export {
  Qo as UIButton,
  ly as UICard,
  oy as UICardContent,
  iy as UICardHeading,
  G3 as UICheckbox,
  ry as UICombobox,
  ny as UIDateInput,
  Q3 as UIFieldError,
  q3 as UIForm,
  qt as UIIcon,
  K3 as UIIconLayers,
  W3 as UILabel,
  ty as UINumberInput,
  J3 as UIRadioButton,
  ay as UISelectInput,
  sy as UIStepList,
  ey as UISwitch,
  X3 as UITextInput
};
