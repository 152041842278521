<template>
  <component
    :is="tag"
    :type="tag === 'button' ? 'button' : null"
    :title="label"
    class="editor-menu-button"
    :class="{
      'editor-menu-button--active': active
    }"
    tabindex="-1"
  >
    <Icon :icon="icon" />
  </component>
</template>

<script>
export default {
  name: 'EditorMenuButton',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-menu-button {
  border: 0px;
  background: transparent;
  width: 34px;
  height: 34px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: inherit;

  &:hover,
  &:focus {
    background: #dadada;
    color: inherit;
  }

  &:disabled {
    background: #e8e8e8;
    color: #777777;
  }

  &--active {
    background: $light-teal;
  }
}
</style>
