import ManageUsersView from './views/ManageUsersView'
import ManageInvitationsView from './views/ManageInvitationsView'
import ManageInstructorView from './views/ManageInstructorView'
import ManageStudentView from './views/ManageStudentView'
import AssignmentView from './views/AssignmentView'
import SendBookstoreCodesView from './views/SendBookstoreCodesView'
import BookstorePurchasesView from './views/BookstorePurchasesView'
import VetInstructorsView from './views/VetInstructorsView'
import InstructorView from './views/InstructorView'
import AdminAssignmentView from './views/AdminAssignmentView'

export default store => [
  {
    name: 'manage_instructor',
    path: '/instructors/:instructorId',
    props: true,
    component: InstructorView,
    meta: { title: 'Manage Instructor', requiresAuth: true }
  },
  {
    name: 'instructor_assignment',
    path: '/instructors/assignments/:id',
    props: true,
    component: AdminAssignmentView,
    meta: { title: 'Assignment', requiresAuth: true }
  },
  {
    name: 'admin_manage_invitations',
    path: '/admin/manage-invitations',
    component: ManageInvitationsView,
    meta: { title: 'Manage Invitations', requiresAuth: true },
    props: route => {
      const { search, page, sortBy, sort } = route.query

      let initialSort = {}
      if (Array.isArray(sortBy)) {
        initialSort = sortBy.reduce((acc, prop, index) => {
          if (Array.isArray(sort) && sort[index]) {
            acc[prop] = sort[index]
          } else {
            acc[prop] = 'asc'
          }

          return acc
        }, {})
      } else if (sortBy) {
        initialSort[sortBy] = typeof sort === 'string' ? sort : 'asc'
      }

      return {
        initialSearchText: search,
        initialPage: page && parseInt(page),
        initialSort
      }
    }
  },
  {
    name: 'admin_manage_users',
    path: '/admin/manage-users',
    component: ManageUsersView,
    meta: { title: 'Manage Users', requiresAuth: true },
    props: to => ({ query: to.query })
  },
  {
    name: 'admin_manage_instructor',
    path: '/admin/manage-instructor/:id',
    props: true,
    component: ManageInstructorView,
    meta: { title: 'Manage Instructor', requiresAuth: true }
  },
  {
    name: 'admin_manage_student',
    path: '/admin/manage-student/:id',
    props: true,
    component: ManageStudentView,
    meta: { title: 'Manage Student', requiresAuth: true }
  },
  {
    name: 'admin_assignment',
    path: '/admin/assignments/:id',
    component: AssignmentView,
    props: true,
    meta: { title: 'Assignment', requiresAuth: true }
  },
  {
    name: 'admin_purchases',
    path: '/admin/purchases',
    component: SendBookstoreCodesView,
    meta: { title: 'Codes', requiresAuth: true }
  },
  {
    name: 'admin_bookstore_purchases',
    path: '/admin/bookstore',
    component: BookstorePurchasesView,
    meta: { title: 'Bookstore Purchases', requiresAuth: true }
  },
  {
    name: 'admin_vet_instructors',
    path: '/admin/vet-instructors',
    component: VetInstructorsView,
    props: to => ({ query: to.query }),
    meta: { title: 'Vet Instructors', requiresAuth: true }
  }
]
