<template>
  <UICard collapsible>
    <UICardHeading class="pb-4">Assign Classes</UICardHeading>
    <UICardContent class="mb-4">
      <form-group>
        <div class="class-name-subgroup">
          <UISelectInput
            label="Classes"
            name="selectedClasses"
            aria-label="Classes"
            required
            :options="mappedClasses"
            v-model="selectedClasses"
            multiple
          />
        </div>
      </form-group>
    </UICardContent>
  </UICard>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { UICard, UICardContent, UICardHeading, UISelectInput } from '@pi/design'

const props = withDefaults(
  defineProps<{
    teacherClasses: { name: string; id: string }[]
    inLti?: boolean
  }>(),
  {
    inLti: false
  }
)

const selectedClasses = defineModel<string[]>()

const mappedClasses = computed(() =>
  props.teacherClasses.map(classItem => ({
    label: classItem.name,
    value: classItem.id
  }))
)
</script>

<style scoped>
.class-name-subgroup {
  width: 370px;
}
</style>
