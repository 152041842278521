<template>
  <collapse-provider renderless v-model:collapsed="isCollapsed">
    <collapse-toggle
      class="grading-progress-collapse-toggle"
      tabindex="-1"
      :class="{ 'grading-progress-collapse-toggle__open': !isCollapsed }"
    >
      <template #default>
        <div class="grading-progress-collapse-toggle-div">
          <span>{{ label }}</span>
          <collapse-icon
            class="grading-progress-collapse-button"
            icon="caret"
          />
        </div>
      </template>
    </collapse-toggle>
    <collapse-content class="grading-progress-option-collapse">
      <ul class="component-list">
        <li v-for="question in questions" :key="question.component._id">
          <a :href="`#component-${question.component._id}`">{{
            question.component.name
          }}</a>
        </li>
      </ul>
    </collapse-content>
  </collapse-provider>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { GradeableComponentResponse } from '../types'

type GradingProgressListProps = {
  label: string
  questions: GradeableComponentResponse[]
}
const props = defineProps<GradingProgressListProps>()

const isCollapsed = ref<boolean>(true)
</script>

<style type="text/scss" scoped>
.grading-progress-collapse-toggle {
  width: 100%;
  font-size: 16px;
  .grading-progress-collapse-toggle-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.grading-progress-collapse-toggle__open {
  font-weight: 700;
}

.component-list {
  list-style-type: none;
  padding-left: 0;

  a {
    color: #3d3d3d;
    font-weight: 400;
  }
}
</style>
