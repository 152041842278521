<template>
  <loading-container :loading="isLoading"> </loading-container>
</template>
<script setup lang="ts">
import { GetLibraryResponse } from '@pi/api-types'
import client from 'src/shared/api-client'
import { useFlash } from 'src/shared/hooks/flash'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
const props = defineProps({
  libraryId: {
    type: String,
    required: true
  }
})
const isLoading = ref(true)
const flash = useFlash()
const router = useRouter()

const getLibary = async () => {
  try {
    const response = await client.libraries.validateJoinLink(props.libraryId)
    if (response) {
      flash.success('You have been added as a member to this library')
      router.push({ name: 'activities', query: { libraryId: props.libraryId } })
    }
    isLoading.value = false
  } catch (error) {
    isLoading.value = false
    router.push({ name: 'home' })
  }
}

onMounted(() => {
  getLibary()
})
</script>

<style type="text/scss" scoped>
.mt-4 {
  margin-top: 1rem;
}
</style>
