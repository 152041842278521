<template>
  <modal
    class="collections-modal"
    @submit="onSubmit"
    @close="onClose"
    aria-label="Collections Page"
  >
    <modal-provider
      class="collections-modal__scroll-container"
      ref="modalProviderComponent"
    >
      <modal-header>Collection: {{ selectedVariable.name }}</modal-header>
      <modal-body class="collections-modal__body">
        <base-popover
          v-if="focusedImage?.image.url"
          :anchor-ref="focusedImage.anchor"
          visible
          strategy="fixed"
        >
          <img
            class="collections-modal__image-preview"
            :src="previewUrl(focusedImage.image.url)"
            :alt="focusedImage.image.alt"
          />
        </base-popover>
        <table-wrapper sticky="both">
          <template #header>
            <tr>
              <td class="collections-modal__header-column" rowspan="2" />
              <th
                v-for="(variable, variableIndex) in localVariable.variables"
                :key="variableIndex"
                class="collections-modal__data-column"
                :aria-label="variable.name ?? `Variable ${variableIndex + 1}`"
                scope="col"
              >
                <div class="collections-modal__cell-group">
                  <text-input
                    v-model="variable.name"
                    class="collections-modal__input collections-modal__variable-name"
                    label="name"
                    :rules="{
                      required: true,
                      regex: /^[A-Za-z0-9_ℹ]+$/
                    }"
                    tabindex="-1"
                    data-tablenav
                  />
                  <button-dropdown
                    ref="dropdown"
                    aria-label="column menu"
                    class="collections-modal__button"
                    right
                    tabindex="-1"
                    :offset="40"
                    data-tablenav
                  >
                    <template #button>
                      <icon icon="ellipsis-h" />
                    </template>
                    <template #default>
                      <dropdown-action
                        @click="insertColumn(variableIndex, true)"
                      >
                        Insert Column After
                      </dropdown-action>
                      <dropdown-action
                        @click="insertColumn(variableIndex, false)"
                      >
                        Insert Column Before
                      </dropdown-action>
                      <dropdown-action
                        @click="deleteColumn(variableIndex, false)"
                      >
                        Delete this column
                      </dropdown-action>
                      <dropdown-action
                        @click="duplicateColumn(variableIndex, false)"
                      >
                        Duplicate this column
                      </dropdown-action>
                    </template>
                  </button-dropdown>
                </div>
              </th>
            </tr>
            <tr>
              <td
                v-for="(variable, variableIndex) in localVariable.variables"
                :key="variableIndex"
                class="collections-modal__data-column"
                :aria-label="variable.variableType"
              >
                <div class="collections-modal__cell-group">
                  <select-field
                    v-model="variable.variableType"
                    label="variableType"
                    rules="required"
                    aria-label="variable type"
                    class="collections-modal__select collections-modal__variable-type"
                    tabindex="-1"
                    @update:modelValue="() => onVariableTypeChange(variable)"
                    data-tablenav
                  >
                    <option value="image">image</option>
                    <option value="number">number</option>
                    <option value="text">text</option>
                  </select-field>
                  <form-button
                    tabindex="-1"
                    @click="openSettingsModal(variableIndex)"
                    class="collections-modal__variable-settings-button collections-modal__button"
                    :aria-label="`${variable.variableType} settings`"
                    data-tablenav
                  >
                    <icon icon="gear" />
                  </form-button>
                </div>
              </td>
            </tr>
          </template>
          <template #body>
            <tr
              v-for="(dataSet, dataSetIndex) in localVariable.dataSets"
              :key="dataSet.id"
            >
              <th
                class="collections-modal__header-column"
                :aria-label="`data set ${dataSetIndex + 1}`"
                scope="row"
              >
                <div class="collections-modal__cell-group">
                  <div class="collections-modal__row-index">
                    {{ dataSetIndex + 1 }}
                  </div>
                  <button-dropdown
                    ref="dropdown"
                    aria-label="row menu"
                    side
                    right
                    tabindex="-1"
                    class="collections-modal__button"
                    data-tablenav
                  >
                    <template #button>
                      <icon icon="ellipsis-h" />
                    </template>
                    <template #default>
                      <dropdown-action @click="insertRow(dataSetIndex, false)">
                        Insert Row Above
                      </dropdown-action>
                      <dropdown-action @click="insertRow(dataSetIndex, true)">
                        Insert Row Below
                      </dropdown-action>
                      <dropdown-action @click="duplicateRow(dataSetIndex)">
                        Duplicate Row
                      </dropdown-action>
                      <dropdown-action @click="deleteRow(dataSetIndex)">
                        Delete Row
                      </dropdown-action>
                    </template>
                  </button-dropdown>
                </div>
              </th>
              <td
                class="collections-modal__data-column"
                v-for="(variable, variableIndex) in localVariable.variables"
                :key="variableIndex"
              >
                <div class="collections-modal__cell-group">
                  <number-input
                    class="collections-modal__input"
                    v-if="variable.variableType === 'number'"
                    v-model="dataSet[variable.id]"
                    tabindex="-1"
                    @paste="e => onPaste(dataSetIndex, variableIndex, e)"
                    @keydown="e => onKeydown(e)"
                    data-tablenav
                  />
                  <text-input
                    class="collections-modal__input"
                    v-if="variable.variableType === 'text'"
                    v-model="dataSet[variable.id]"
                    tabindex="-1"
                    @paste="e => onPaste(dataSetIndex, variableIndex, e)"
                    data-tablenav
                  />
                  <template v-if="variable.variableType === 'image'">
                    <text-input
                      class="collections-modal__input"
                      tabindex="-1"
                      :modelValue="
                        (dataSet[variable.id].url ?? '').split('/').slice(-1)[0]
                      "
                      readonly
                      :title="dataSet[variable.id].url"
                      @focus="e => showImagePreview(e, dataSet[variable.id])"
                      @blur="hideImagePreview"
                      @mouseover="
                        e => showImagePreview(e, dataSet[variable.id])
                      "
                      @mouseout="hideImagePreview"
                      data-tablenav
                    />
                    <form-button
                      class="collections-modal__button"
                      tabindex="-1"
                      @click="() => openImageModal(dataSet, variable.id)"
                      data-tablenav
                    >
                      <icon icon="ellipsis-h" />
                    </form-button>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </table-wrapper>
      </modal-body>
      <modal-actions>
        <submit-button>Save</submit-button>
      </modal-actions>
    </modal-provider>
  </modal>
</template>
<script setup>
import { reactive, ref, markRaw } from 'vue'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions
} from 'src/shared/components/modals/components'
import ModalProvider from 'src/shared/components/modals/components/ModalProvider'
import SettingsModal from './SettingsModal'
import ImageModal from './ImageModal'
import ConfirmModal from '../ConfirmModal'

const modalProviderComponent = ref()

const emit = defineEmits(['change', 'close'])
const props = defineProps({
  selectedVariable: {
    type: Object,
    required: true
  }
})

function getRandomId() {
  return Math.random().toString(36).replace('0.', '')
}
function resetVariableValue(dataSet, variable) {
  const value = dataSet[variable.id]
  switch (variable.variableType) {
    case 'number':
      dataSet[variable.id] = typeof value === 'number' ? value : 0
      break
    case 'text':
      dataSet[variable.id] = typeof value === 'string' ? value : ''
      break
    case 'image':
      dataSet[variable.id] = value && typeof value === 'object' ? value : {}
      break
    default:
      throw new Error(`unsupported variable type: ${variable.variableType}.`)
  }
}

function previewUrl(path) {
  return path ? `//${PI_API_HOST}${path}` : ''
}

const localVariable = reactive({
  ...props.selectedVariable,
  dataSets: props.selectedVariable.dataSets
    ? props.selectedVariable.dataSets.map(set => ({ ...set }))
    : Array.from({ length: props.selectedVariable.rows }).map(row => ({
        id: getRandomId()
      })),
  variables: props.selectedVariable.variables
    ? props.selectedVariable.variables.map(variable => ({ ...variable }))
    : Array.from({ length: props.selectedVariable.columns }).map(column => ({
        id: getRandomId(),
        variableType: 'number'
      }))
})
localVariable.variables.forEach(variable => {
  localVariable.dataSets.forEach(dataSet =>
    resetVariableValue(dataSet, variable)
  )
})

function insertRow(index, isBelow) {
  const dataSet = { id: getRandomId() }
  if (isBelow) {
    localVariable.dataSets.splice(index + 1, 0, dataSet)
  } else {
    localVariable.dataSets.splice(index, 0, dataSet)
  }

  localVariable.variables.forEach(variable =>
    resetVariableValue(dataSet, variable)
  )
}
function duplicateRow(index) {
  const duplicatedRow = localVariable.dataSets[index]
  localVariable.dataSets.splice(index, 0, {
    ...duplicatedRow,
    id: getRandomId()
  })
}
async function deleteRow(index) {
  const { status } = await modalProviderComponent.value.show(ConfirmModal, {
    text: `To undo, you can close this modal without saving, losing any other changes.`,
    prompt: 'Are you sure you want to delete this row?'
  })
  if (status === 'ok') {
    localVariable.dataSets.splice(index, 1)
  }
}

function insertColumn(index, isAfter) {
  const variable = {
    id: getRandomId(),
    variableType: 'number'
  }
  if (isAfter) {
    localVariable.variables.splice(index + 1, 0, variable)
  } else {
    localVariable.variables.splice(index, 0, variable)
  }

  localVariable.dataSets.forEach(dataSet =>
    resetVariableValue(dataSet, variable)
  )
}
function duplicateColumn(index) {
  const duplicatedColumn = localVariable.variables[index]
  const newColumnId = getRandomId()

  localVariable.variables.splice(index, 0, {
    ...duplicatedColumn,
    id: newColumnId,
    variableType: 'number'
  })
  localVariable.dataSets.forEach(
    dataSet => (dataSet[newColumnId] = dataSet[duplicatedColumn.id])
  )
}
async function deleteColumn(index) {
  const { status } = await modalProviderComponent.value.show(ConfirmModal, {
    text: `To undo, you can close this modal without saving, losing any other changes.`,
    prompt: 'Are you sure you want to delete this column?'
  })
  if (status === 'ok') {
    const deletedColumnId = localVariable.variables[index].id

    localVariable.dataSets.forEach(dataSet => delete dataSet[deletedColumnId])

    localVariable.variables.splice(index, 1)
  }
}

function onVariableTypeChange(variable) {
  localVariable.dataSets.forEach(dataSet =>
    resetVariableValue(dataSet, variable)
  )
}
function onKeydown(event) {
  if (event.keyCode === 38 || event.keyCode === 40) {
    event.preventDefault()
  }
}

function onPaste(rowIndex, colIndex, event) {
  const clipboardData = event.clipboardData
  const pastedText =
    clipboardData.getData('Text') || clipboardData.getData('text/plain')
  if (!pastedText && pastedText.length) {
    return
  }
  event.preventDefault()
  // Parse the pasted text from Excel into rows.
  // Pasted text is usually separated by a new line for each row,
  // but a single cell can contain multiple lines, which is what
  // we pars out in the first `replace`.
  //
  // We find all text within double-quotes ('"') which has new
  // lines, and put the text within the quotes into capture
  // groups. For each match, we replace its contents again, by
  // removing the new lines with spaces.
  //
  // Then lastly, once we've joined all the multi line cells, we
  // split the entire pasted content on new lines, which gives
  // us an array of each row.
  //
  // Since Windows usually uses weird line-endings, we need to
  // ensure we check for each of the different possible
  // line-endings in every regexp.
  //
  // It also handles cells which contains quotes. There appears
  // to be two ways this is handled. In Google Docs, quotes within
  // cells are always doubled up when pasting, so " becomes "".
  // In Libre Office, the quotes are not normal quotes, some
  // other character is used, so we don't need to handle it any
  // differently.

  const rows = pastedText
    .replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm, function (match, p1) {
      // This function runs for each cell with multi lined text.
      return (
        p1
          // Replace any double double-quotes with a single
          // double-quote
          .replace(/""/g, '"')
          // Replace all new lines with spaces.
          .replace(/\r\n|\n\r|\n|\r/g, ' ')
      )
    })
    // Split each line into rows
    .split(/\r\n|\n\r|\n|\r/g)

  rows.forEach((row, i) => {
    const cells = row.split('\t')
    cells.forEach((cell, c) => {
      const dataset = localVariable.dataSets[rowIndex + i]
      const variable = localVariable.variables[colIndex + c]
      if (!dataset || !variable) return
      //if variableType is a number and the value is not a number don't set it.
      if (variable.variableType === 'number') {
        if (isNaN(cell)) {
          return
        }
        localVariable.dataSets[rowIndex + i][variable.id] = parseFloat(cell)
      } else {
        localVariable.dataSets[rowIndex + i][variable.id] = cell
      }
    })
  })
}

function onClose() {
  emit('close')
}
function onSubmit() {
  emit('change', localVariable)
}

async function openSettingsModal(variableIndex) {
  const { status, data } = await modalProviderComponent.value.show(
    SettingsModal,
    {
      variable: localVariable.variables[variableIndex]
    }
  )
  if (status === 'ok') {
    localVariable.variables[variableIndex] = data
  }
}
async function openImageModal(dataSet, variableId) {
  const { status, data } = await modalProviderComponent.value.show(ImageModal, {
    value: dataSet[variableId]
  })
  if (status === 'ok') {
    dataSet[variableId] = data
  }
}

const focusedImage = ref()
function showImagePreview(e, image) {
  focusedImage.value = {
    anchor: markRaw(e.currentTarget),
    image
  }
}
function hideImagePreview() {
  focusedImage.value = undefined
}
</script>

<style lang="scss" scoped>
.collections-modal {
  resize: both;
  overflow: hidden;
  min-width: 790px;
  min-height: 462px;
}

.collections-modal__body {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.collections-modal__save {
  margin-left: 520px;
}

:deep(.collections-modal__scroll-container) {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

:deep(.collections-modal__input input),
:deep(.collections-modal__button),
:deep(.collections-modal__select select) {
  height: 36px;
  &,
  &:focus,
  &:hover {
    border-radius: 0;
  }
}

:deep(.collections-modal__input),
:deep(.collections-modal__select) {
  width: 100%;
}

.collections-modal__header-column {
  width: 70px;
  min-width: 70px;
}

.collections-modal__data-column {
  width: 180px;
  min-width: 180px;
}

.collections-modal__cell-group {
  display: inline-flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.collections-modal__variable-settings-button {
  border-left: 1px solid #979797 !important;
}

.collections-modal__row-index {
  padding-left: 5px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.collections-modal__variable-type,
.collections-modal__variable-name {
  flex-grow: 1;
}

.collections-modal__image-preview {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  display: block;
}

.collections-modal__input :deep(.help-block) {
  position: absolute;
  top: 32px;
  background: $color-error;
  padding: 0 4px;
  color: white;
}
</style>
