<template>
  <div v-if="section.completeFirst">
    <div v-if="section.isLocked" class="lock-unlock-div">
      <p class="lock-unlock-text">
        Pressing this will allow the student to modify their response to this
        section, and prevent them from viewing the next section.
      </p>
      <form-button class="lock-unlock-button" @click="unlock">
        Unlock Section
      </form-button>
    </div>
    <div v-else class="lock-unlock-div">
      <p class="lock-unlock-text">
        Pressing this will prevent the student from modifying their response to
        this section, and allow them to view the next section.
      </p>
      <form-button class="lock-unlock-button" @click="lock">
        Lock Section
      </form-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivitySectionLockOverride',
  inject: ['inherited'],
  emits: ['lock', 'unlock'],
  computed: {
    section() {
      return this.inherited.section
    }
  },
  methods: {
    lock() {
      this.$emit('lock', this.section.index)
    },
    unlock() {
      this.$emit('unlock', this.section.index)
    }
  }
}
</script>

<style scoped scss>
div.lock-unlock-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.lock-unlock-button {
  width: 140px;
}

p.lock-unlock-text {
  max-width: 470px;
  margin-right: 15px;
  margin-top: 8px;
  text-align: right;
}
</style>
