<template>
  <span class="activity-dragged-section" ref="root">
    <div class="activity-section">
      <div class="left-panel">
        <form-group class="section-insight">
          <label class="control-label" :for="`section-${sectionIndex}-name`"
            >Section Name</label
          >
          <text-input
            :id="`section-${sectionIndex}-name`"
            ref="sectionNameInput"
            :modelValue="section.name"
            rules="required"
            :name="`${name}.name`"
            label="section name"
            @input="name => sectionChanged(sectionIndex, { name })"
          />
        </form-group>
        <div class="section-insight" v-if="!last">
          <checkbox
            :modelValue="section.completeFirst"
            @update:modelValue="
              completeFirst => sectionChanged(sectionIndex, { completeFirst })
            "
          >
            Require this section to be completed before the following section
          </checkbox>
        </div>
      </div>
      <div class="right-panel">
        <div class="section-insight">
          <label>Introduction</label>
          <editor
            :text="section.summary"
            :variable-context="variableContext"
            @input="summary => sectionChanged(sectionIndex, { summary })"
            video
          />
        </div>
      </div>
    </div>
    <div class="component-info right-align">
      <span class="left-align">
        Section {{ sectionIndex + 1 }} Components ({{ componentsCount }})</span
      >
      <form-button link class="toggle-components" @click="toggleComponents"
        >{{ allOpen ? 'Hide' : 'Show' }} All Components</form-button
      >
    </div>
    <div
      class="components panel-group inner-panel"
      role="tablist"
      aria-multiselectable="true"
    >
      <draggable
        v-model="components"
        ghost-class="moving-section"
        :animation="200"
        class="component-list-group"
        :class="
          componentDragging && section.components.length === 0
            ? 'empty-dropzone'
            : ''
        "
        group="components"
        :handle="`.${componentDragHandle}`"
        :empty-insert-threshold="500"
        :scroll-sensitivity="200"
        :force-fallback="true"
        @end="handleEnd"
        @unchoose="() => (isChosen = false)"
        @start="$emit('componentDragging', true)"
        @choose="() => (isChosen = true)"
        item-key="_id"
        @add="handleAdd"
      >
        <template #item="{ element: component, index }">
          <div>
            <activity-component
              :class="{
                'component-sortable': componentDragging
              }"
              :component="component"
              :first="sectionIndex === 0 && index === 0"
              :last="sectionIndex == sections.length - 1 && index === lastIndex"
              :questionNumber="componentQuestionNumber"
              :sectionName="name"
              :sectionIndex="sectionIndex"
              :variableContext="variableContext"
              @change="arg => updateComponent(arg, index)"
              @move-panel="moveComponent"
              @panel-action="arg => panelAction(index, arg)"
            />
            <div v-if="componentsCount > 0 && isChosen === false">
              <div
                v-show="showAddComponentBox(index, lastIndex)"
                class="add-component looped-components"
              >
                <add-activity-bar
                  :ref="el => setAddComponentRef(index, el)"
                  v-model="newComponentType"
                  humanized-component-name="Paste from Clipboard"
                  :isLastComponent="index === lastIndex"
                  @addComponent="addComponent(index)"
                  @pasteFromClipboard="pasteFromClipboard(index)"
                  @removeAddComponentBar="this.componentIndex = undefined"
                  @addSplitView="addSplitView(index)"
                  :can-add-split="splitViewEnabled"
                />
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div v-if="componentsCount === 0" class="add-component">
      <add-activity-bar
        v-model="newComponentType"
        humanized-component-name="Paste from Clipboard"
        @addComponent="addComponent(-1)"
        @pasteFromClipboard="pasteFromClipboard(-1)"
        @addSplitView="addSplitView(-1)"
        :can-add-split="splitViewEnabled"
      />
    </div>
  </span>
</template>

<script>
import draggable from 'vuedraggable'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import humanizeComponentType from 'src/setup/mixins/humanizeComponentType'
import AddActivityBar from './AddActivityBar.vue'
import useArrayRef from 'src/shared/hooks/array-ref'
import ActivityComponent from './ActivityComponent'
import { generateObjectId } from '../../../shared/utils/objectId'
import { getNumberedSection } from 'src/shared/utils/activity-numbers'

export default {
  name: 'ActivityDraggedSection',
  components: {
    ActivityComponent,
    draggable,
    AddActivityBar
  },
  inject: ['inherited', '$modal', '_flash'],
  mixins: [humanizeComponentType],
  emits: ['componentDragging', 'copyInClipboard'],
  props: {
    section: {
      type: Object,
      required: true
    },
    sectionId: {
      type: String,
      required: true
    },
    sectionIndex: {
      type: Number,
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    },
    componentDragging: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    last: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const [addComponentRefs, setAddComponentRef] = useArrayRef()

    return {
      addComponentRefs,
      setAddComponentRef
    }
  },
  data: function () {
    return {
      newComponentType: '',
      componentIndex: undefined,
      isChosen: false,
      componentDragHandle: 'component-drag-handle',
      sourceActivity: undefined
    }
  },
  computed: {
    splitViewEnabled() {
      return this.inherited.activity.splitViewComponentEnabled
    },
    sourceVariables() {
      return this.sourceActivity?.variables
    },
    components: {
      get() {
        if (this.section.components.length > 0) {
          return this.section.components
        }
        // we need to send empty array like this, as vue-draggable library required this in order to shift component from a section into empty section
        return []
      },
      set(newValue) {
        this.sectionChanged(this.sectionIndex, {
          components: newValue
        })
      }
    },
    flattenedComponents() {
      return this.components.flatMap(component => {
        if (component.componentType === 'SplitView') {
          // TODO: BB clean up this spaghetti code
          // We have to keep the component in the "flattened" components array
          // so that my spaghetti enumeration inside each split view side works
          return [
            component,
            ...component.leftContent,
            ...component.rightContent
          ]
        } else {
          return component
        }
      })
    },

    componentsCount() {
      const count = (this.section.components || []).reduce((acc, component) => {
        if (component.componentType === 'SplitView') {
          return (
            acc + component.rightContent.length + component.leftContent.length
          )
        }
        return acc + 1
      }, 0)
      return count
    },

    lastIndex() {
      return this.components.length > 0 ? this.components.length - 1 : 0
    },

    sections() {
      return this.inherited.activity.sections
    },
    allOpen() {
      return this.components.every(component => !component.collapsed)
    }
  },
  methods: {
    componentQuestionNumber(componentId) {
      const componentOb = getNumberedSection(this.section)
        .components.flatMap(c => {
          if (c.componentType === 'SplitView') {
            return [...c.leftContent, ...c.rightContent]
          } else {
            return c
          }
        })
        .find(c => c._id === componentId)
      return componentOb?.questionNumber
    },
    sectionChanged(sectionIndex, fields) {
      // Prop mutation is not ideal, but passing a new object up as an event has poor performance.
      const section = this.sections[sectionIndex]
      for (const [key, value] of Object.entries(fields)) {
        section[key] = value
      }
      section.collapsed = false
    },

    panelAction(index, args) {
      if (args.action === 'delete') this.deleteComponent(index)
      if (args.action === 'add') this.focusOnAddComponentBox(index)
      if (args.action === 'duplicate') this.duplicateComponent(index)
      if (args.action === 'clipboard') this.saveToClipBoard(index)
      if (args.action === 'copyLinkToClipboard') this.copyLinkToClipboard(index)
      if (args.action === 'previewComponent') this.previewComponent(index)
      if (args.action === 'toggle-collapse')
        this.updateComponent(
          {
            collapsed: !args.panel.collapsed
          },
          index
        )
    },

    async duplicateComponent(index) {
      const insertionIndex = index + 1
      const componentsCopy = this.components.slice()

      let toBeDuplicate = {}
      if (this.components[index].componentType === 'SplitView') {
        toBeDuplicate = {
          ...this.components[index],
          _id: generateObjectId(),
          keyId: Math.floor(Math.random() * 100000),
          collapsed: false,
          leftContent: this.components[index].leftContent.map(leftComponent => {
            return {
              ...leftComponent,
              _id: generateObjectId(),
              keyId: Math.floor(Math.random() * 100000),
              collapsed: false
            }
          }),
          rightContent: this.components[index].rightContent.map(
            rightComponent => {
              return {
                ...rightComponent,
                _id: generateObjectId(),
                keyId: Math.floor(Math.random() * 100000),
                collapsed: false
              }
            }
          )
        }
      } else {
        toBeDuplicate = {
          ...this.components[index],
          _id: generateObjectId(),
          keyId: Math.floor(Math.random() * 100000),
          collapsed: false
        }
      }

      componentsCopy.splice(insertionIndex, 0, toBeDuplicate)

      await this.sectionChanged(this.sectionIndex, {
        components: componentsCopy
      })
      this.$nextTick(() => {
        this.scrollToComponent(insertionIndex)
      })
    },

    async deleteComponent(componentIndex) {
      const component = this.components[componentIndex]
      // determine if the component being removed is being referenced as a student response variable anywhere
      const isComponentResponseReferenced = this.variableContext.variables.some(
        variable =>
          variable.variableType === 'studentResponse' &&
          variable.content === component._id
      )

      const { status } = await this.$modal.show(ConfirmModal, {
        text: isComponentResponseReferenced
          ? 'The student response value for this component is being referenced in other components as a variable. Deleting this component will break other components that rely on this variable.'
          : '',
        prompt: `Are you sure you want to delete this component? This action cannot be undone.`
      })
      if (status === 'ok') {
        this.sectionChanged(this.sectionIndex, {
          components: this.components.filter((_, i) => i !== componentIndex)
        })
      }
    },

    addSplitView(index) {
      const insertionIndex = index + 1
      const newComponent = {
        _id: generateObjectId(),
        componentType: 'SplitView',
        choices: [],
        answer: '',
        pointValue: 0,
        collapsed: false,
        conditions: [],
        hideHint: true,
        leftContent: [],
        rightContent: []
      }
      this.componentIndex = undefined
      this.components.splice(insertionIndex, 0, newComponent)
      this.sectionChanged(this.sectionIndex, {
        components: this.components
      })
      this.$nextTick(() => {
        this.scrollToComponent(insertionIndex)
      })
      this.newComponentType = ''
    },

    addComponent(index) {
      const insertionIndex = index + 1
      const newComponent = {
        _id: generateObjectId(),
        componentType: this.newComponentType,
        choices: [],
        answer: '',
        pointValue: [
          'MultipleChoiceQuestion',
          'DragDropQuestion',
          'NumericalQuestion'
        ].includes(this.newComponentType)
          ? 1
          : 0,
        collapsed: false,
        conditions: [],
        hideHint: true
      }
      this.componentIndex = undefined
      if (newComponent.componentType) {
        if (newComponent.componentType === 'PhetIOSim') {
          newComponent.config = '{"version":"2","urls":"","state":"{}"}'
        }
        if (newComponent.componentType === 'PhetIOSimulation') {
          newComponent.config =
            '{"simName": "", "simVersion": "", "wrapper": "", "values": []}'
        }
        if (
          ['MultipleChoiceQuestion', 'DragDropQuestion'].includes(
            newComponent.componentType
          )
        ) {
          newComponent.randomizedChoices = true
        }
        if (
          [
            'MultipleChoiceQuestion',
            'DragDropQuestion',
            'NumericalQuestion'
          ].includes(newComponent.componentType)
        ) {
          newComponent.autograde = true
        }
        if (newComponent.componentType === 'GridGraphQuestion') {
          newComponent.settings = {
            includeColumnStatistics: false,
            includeFormulaBar: false,
            allowUncertainty: false,
            legacyInterface: false,
            includeBluetooth: true,
            includeCsvDownload: true,
            curveFitTypes: {
              proportional: true,
              linear: true,
              squareLaw: true,
              squareRoot: true,
              quadratic: true,
              exponential: true,
              logarithmic: true,
              inverse: true,
              inverseSquare: true
            },
            graphTypes: {
              scatterGraphs: true,
              lineGraphs: true,
              barGraphs: true
            }
          }
        }
        if (newComponent.componentType === 'GridQuestion') {
          newComponent.settings = {
            includeColumnStatistics: false,
            includeFormulaBar: false,
            includeBluetooth: true,
            includeCsvDownload: true
          }
        }
        newComponent.keyId = Math.floor(Math.random() * 100000)

        this.components.splice(insertionIndex, 0, newComponent)
        this.sectionChanged(this.sectionIndex, {
          components: this.components
        })
      }
      this.$nextTick(() => {
        this.scrollToComponent(insertionIndex)
      })
      this.newComponentType = ''
    },

    updateComponent(fields, index) {
      // Prop mutation is not ideal, but passing a new object up as an event has poor performance.
      let component = this.components[index]

      // If the component is moved out of and above the SplitView from the left side
      // this changes the value of the index of the SplitView component itself.
      // updateComponent is called with the old index, but the component accessed
      // is based on the new one.  I think this might be caused by all of our prop
      // mutation - Vue is maybe not updating the index reactively because of it?
      // The best solution I could think of without a big refactor was to
      // simply "fix" the index if it's not pointing to a SplitView component.
      if (fields.leftContent && component.componentType !== 'SplitView') {
        component = this.components[index + 1]
      }

      for (const [key, value] of Object.entries(fields)) {
        if (value === undefined || value === null) {
          delete component[key]
        } else {
          component[key] = value
        }
      }
    },

    findComponent(componentId) {
      for (let si = 0; si < this.sections.length; si++) {
        const section = this.sections[si]
        for (let ci = 0; ci < section.components.length; ci++) {
          const component = section.components[ci]
          if (component._id === componentId) {
            return {
              component,
              sectionIndex: si,
              componentIndex: ci
            }
          } else if (component.componentType === 'SplitView') {
            for (let li = 0; li < component.leftContent.length; li++) {
              const childComponent = component.leftContent[li]
              if (childComponent._id === componentId) {
                return {
                  component: childComponent,
                  sectionIndex: si,
                  componentIndex: ci,
                  split: {
                    component,
                    side: 'left',
                    index: li
                  }
                }
              }
            }
            for (let ri = 0; ri < component.rightContent.length; ri++) {
              const childComponent = component.rightContent[ri]
              if (childComponent._id === componentId) {
                return {
                  component: childComponent,
                  sectionIndex: si,
                  componentIndex: ci,
                  split: {
                    component,
                    side: 'right',
                    index: ri
                  }
                }
              }
            }
          }
        }
      }
    },

    moveComponent(componentId, direction) {
      const { component, sectionIndex, componentIndex, split } =
        this.findComponent(componentId)

      const dirModifier = direction === 'up' ? -1 : 1

      if (split) {
        if (direction === 'up' && split.index === 0) {
          if (split.side === 'right') {
            split.component.rightContent.shift()
            split.component.leftContent.push(component)
          } else {
            split.component.leftContent.shift()
            this.sections[sectionIndex].components.splice(
              componentIndex,
              0,
              component
            )
          }
        } else if (
          direction === 'down' &&
          split.side === 'right' &&
          split.index === split.component.rightContent.length - 1
        ) {
          split.component.rightContent.pop()
          this.sections[sectionIndex].components.splice(
            componentIndex + 1,
            0,
            component
          )
        } else if (
          direction === 'down' &&
          split.side === 'left' &&
          split.index === split.component.leftContent.length - 1
        ) {
          split.component.leftContent.pop()
          split.component.rightContent.unshift(component)
        } else {
          split.component[`${split.side}Content`].splice(split.index, 1)
          split.component[`${split.side}Content`].splice(
            split.index + dirModifier,
            0,
            component
          )
        }
      } else if (direction === 'up' && componentIndex === 0) {
        if (sectionIndex === 0) return
        this.sections[sectionIndex].components.shift()
        this.sections[sectionIndex - 1].components.push(component)
        this.sections[sectionIndex - 1].collapsed = false
      } else if (
        direction === 'down' &&
        componentIndex === this.sections[sectionIndex].components.length - 1
      ) {
        if (sectionIndex === this.sections.length - 1) return
        this.sections[sectionIndex].components.pop()
        this.sections[sectionIndex + 1].components.unshift(component)
        this.sections[sectionIndex + 1].collapsed = false
      } else {
        const components = this.sections[sectionIndex].components
        const nextComponent = components[componentIndex + dirModifier]
        components.splice(componentIndex, 1)
        if (
          nextComponent.componentType === 'SplitView' &&
          component.componentType !== 'SplitView'
        ) {
          if (direction === 'up') {
            nextComponent.rightContent.push(component)
          } else if (direction === 'down') {
            nextComponent.leftContent.unshift(component)
          }
        } else {
          components.splice(componentIndex + dirModifier, 0, component)
        }
      }

      // We have to wait for section opening animations to complete before we focus, otherwise the scroll into view won't work.
      setTimeout(() => {
        const componentDom = document.querySelector(
          `#component-${component._id}`
        )
        const buttonDom = componentDom?.querySelector(
          `[data-queryid=${direction === 'down' ? 'move-down' : 'move-up'}]`
        )
        if (buttonDom.disabled) {
          componentDom
            ?.querySelector(
              `[data-queryid=${direction === 'down' ? 'move-up' : 'move-down'}]`
            )
            ?.focus()
        } else {
          buttonDom?.focus()
        }
      }, 500)
    },

    humanizedComponentName(componentType) {
      return this.humanizeComponentType(componentType)
    },

    hasText(component) {
      return !['EmbeddedInstance', 'IFrame', 'PhetIOSim'].includes(
        component.componentType
      )
    },
    isQuestion(component) {
      return component.componentType.indexOf('Question') !== -1
    },
    isEmbeddedInstance(component) {
      return ['EmbeddedInstance'].includes(component.componentType)
    },

    showAddComponentBox(index, lastComponent) {
      return this.componentIndex === index || index === lastComponent
    },

    focusOnAddComponentBox(index) {
      this.componentIndex = index

      this.$nextTick(() => {
        const focusedElement = this.addComponentRefs[index].$el.querySelector(
          '[data-queryid="select-component"]'
        )
        focusedElement?.focus()
      })
    },

    scrollToComponent(index) {
      const components = this.$refs.root.querySelectorAll(
        '[data-queryid="activity-component"]'
      )
      components[index]?.focus()
    },
    isValidComponent(componentType) {
      return this.humanizeComponentType(componentType)
    },
    copyLinkToClipboard(index) {
      const url = `${window.location.origin}/activities/${this.inherited.activity.id}/content/#component-${this.components[index]._id}`
      navigator.clipboard.writeText(url)
      this.$success('Link copied to clipboard')
    },
    previewComponent(index) {
      const url = `${window.location.origin}/activities/${this.inherited.activity.id}/preview/#component-${this.components[index]._id}`
      window.open(url, '_blank', 'noopener')
    },
    async saveToClipBoard(index) {
      this.$emit('copyInClipboard', this.components[index])
    },
    async getClipboardData() {
      if (document.hasFocus()) {
        try {
          const payload = JSON.parse(await navigator.clipboard.readText())

          if (
            payload.fromPivot === true &&
            payload.type === 'activity-component'
          ) {
            const { component, activityId, variables } = payload.data

            if (this.isValidComponent(component.componentType).length) {
              if (activityId === this.inherited.activity.id) {
                delete this.sourceActivity
              } else {
                this.sourceActivity = { variables }
              }

              return component
            }
          }
        } catch {
          delete this.sourceActivity
          return
        }
      }
    },

    async pasteFromClipboard(index) {
      const clipboardComponent = await this.getClipboardData()
      if (!clipboardComponent) {
        //show message
        this.$error('Clipboard is empty')
        return
      }
      const insertionIndex = index + 1
      const componentsCopy = this.components.slice()
      let toBeCopy = {}
      if (clipboardComponent.componentType === 'SplitView') {
        toBeCopy = {
          ...clipboardComponent,
          _id: generateObjectId(),
          keyId: Math.floor(Math.random() * 100000),
          collapsed: false,
          leftContent: clipboardComponent.leftContent.map(leftComponent => {
            return {
              ...leftComponent,
              _id: generateObjectId(),
              keyId: Math.floor(Math.random() * 100000),
              collapsed: false
            }
          }),
          rightContent: clipboardComponent.rightContent.map(rightComponent => {
            return {
              ...rightComponent,
              _id: generateObjectId(),
              keyId: Math.floor(Math.random() * 100000),
              collapsed: false
            }
          })
        }
        toBeCopy.leftContent.forEach(leftComponent => {
          this.parseComponent(leftComponent)
        })
        toBeCopy.rightContent.forEach(rightComponent => {
          this.parseComponent(rightComponent)
        })
      } else {
        toBeCopy = {
          ...clipboardComponent,
          _id: generateObjectId(),
          keyId: Math.floor(Math.random() * 100000),
          collapsed: false
        }
        this.parseComponent(toBeCopy)
      }

      this.componentIndex = undefined

      componentsCopy.splice(insertionIndex, 0, toBeCopy)
      this.sectionChanged(this.sectionIndex, {
        components: componentsCopy
      })
      this.$nextTick(() => {
        this.scrollToComponent(insertionIndex)
      })
    },

    parseComponent(component) {
      if (component.componentType === 'IFrame') {
        component.url = this.parseVariables(component.url)
      }
      if (component.componentType === 'NumericalQuestion') {
        // parse conditions
        component.conditions.map(condition => {
          condition.condition = this.parseVariables(condition?.condition)
          condition.response = this.parseVariables(condition.response, true)
          return condition
        })
      }

      if (component.componentType === 'MultipleChoiceQuestion') {
        // parse conditions
        component.choices.map(choice => {
          choice.text = this.parseVariables(choice.text, true)
          choice.response = this.parseVariables(choice?.response, true)
          return choice
        })
      }

      if (this.hasText(component)) {
        // parse text
        component.text = this.parseVariables(component.text, true)
      }
      if (this.isQuestion(component)) {
        // parse hint
        component.hint = this.parseVariables(component.hint, true)
      }
    },
    parseVariables(str, enCloseBrackets = false) {
      if (this.sourceVariables === undefined || str === undefined) {
        return str
      }
      this.sourceVariables.forEach(variable => {
        const indexOfVariable = str.indexOf(`${variable.id}`)
        if (indexOfVariable !== -1) {
          str = str.replace(
            new RegExp(`${variable.id}`, 'g'),
            `${variable.name}_UND`
          )
        }
      })
      return str
    },
    setFocus() {
      this.$refs.sectionNameInput.setFocus()
    },
    handleEnd(event) {
      this.$emit('componentDragging', false)
      event.item.firstElementChild.focus()
    },
    handleAdd(event) {
      this.$nextTick(() => this.scrollToComponent(event.newIndex))
    },
    toggleComponents() {
      const newCollapsed = this.allOpen
      this.components.forEach(component => {
        component.collapsed = newCollapsed
        if (component.componentType === 'SplitView') {
          component.rightContent.forEach(rightComponent => {
            rightComponent.collapsed = newCollapsed
          })
          component.leftContent.forEach(leftComponent => {
            leftComponent.collapsed = newCollapsed
          })
        }
      })
    }
  },
  async mounted() {
    let hashParams = window.location.hash
    hashParams = hashParams.replace('#component-', '')
    if (hashParams) {
      const index = this.components.findIndex(c => {
        if (c._id === hashParams) return true
        if (c.componentType === 'SplitView') {
          if (c.leftContent.find(lc => lc._id === hashParams)) return true
          if (c.rightContent.find(lc => lc._id === hashParams)) return true
        }
        return false
      })

      if (index >= 0) {
        const component = this.components[index]
        component.collapsed = false
        this.$nextTick(() => {
          if (component.componentType !== 'SplitView') {
            this.scrollToComponent(index)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-dragged-section :deep(.ql-editor) {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.left-align {
  text-align: left;
  margin-left: 24px;
}

.right-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 24px;
}

.activity-section {
  display: flex;
  width: 99%;
  margin-right: 10px;

  & .section-insight {
    margin: 0;
    margin-top: 10px;

    & .control-label {
      padding: 0;
    }
  }

  & .left-panel {
    width: 50%;
    margin-right: 10px;
  }

  & .right-panel {
    width: 50%;
  }
}
.component-info {
  margin: 10px;

  & span {
    font-size: 16px;
    font-weight: 600;
  }
}

.add-component {
  margin: 10px;
  border: 1px solid $light-teal;
  padding: 10px;
  border-style: dotted;
  justify-content: space-between;

  & .add-component-btn {
    color: $teal;
    padding: 2px;
  }
}

.inner-panel {
  margin: 10px;
}

.activity-panel :deep(.dragable-panel) {
  border-top: none;
  margin-bottom: 0;
}

.looped-components {
  margin: 0;
  background-color: #fff;
  &:focus-within {
    border: 2px solid #158484;
    box-sizing: border-box;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  &:hover {
    box-sizing: border-box;
    box-shadow:
      0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 0px 10px rgba(52, 189, 189, 0.5);
    border-radius: 5px;
  }
}

.add-component-box-btn {
  display: flex;
}

.clipboard-btn {
  & span {
    margin-right: 10px;
  }
}

[draggable='true'] {
  border: 2px solid $teal !important;
  box-shadow: 10px 10px 5px -1px rgba($color: #000000, $alpha: 0.14);
  border-radius: 4px;
  padding: 10px;
  cursor: move;

  & .drag-handle {
    color: #ccc !important;
  }

  & .component-sortable {
    border: none !important;
  }
}

.sections .sortable-drag {
  cursor: move;
}

.component-sortable {
  border: 2px dashed $teal;
  cursor: move;
}

.empty-dropzone {
  min-height: 85px;
  border: 2px dashed $teal;
}
</style>
