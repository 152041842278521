<template>
  <div v-bind="wrapperAttrs" :class="errorMessage ? 'has-error' : ''">
    <input
      v-bind="inputAttrs"
      ref="input"
      :value="inputValue"
      class="pi-form-control form-control number-input"
      :class="{ 'number-input--stepper': stepper }"
      type="number"
      :aria-describedby="`${helpId} ${ariaDescribedby}`"
      @input="onInput"
      @change="onChange"
      @blur="handleBlur"
      @keydown="onKeyDown"
      @wheel.prevent
    />
    <span v-if="errorMessage || helpText" :id="helpId" class="help-block">
      {{ errorMessage || helpText }}
    </span>
  </div>
</template>

<script>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
let counter = 0

export default {
  name: 'NumberInput',
  inheritAttrs: false,
  emits: ['change', 'input', 'update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: 'field'
    },
    name: {
      type: String,
      // Names must be unique between different inputs.
      default: () => `number-input-${counter++}`
    },
    helpText: {
      type: String,
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: ''
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    stepper: {
      type: Boolean,
      default: false
    },
    responseInput: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField,
      meta,
      setValidationState
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: props.modelValue,
      validateOnValueUpdate: false,
      syncVModel: false,
      keepValueOnUnmount: true
    })

    return {
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField,
      meta,
      setValidationState
    }
  },
  computed: {
    helpId() {
      return `${this.name}-help`
    },
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    inputAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    processValue(value) {
      const parsedValue = parseFloat(value)
      if (this.responseInput) {
        return isNaN(parsedValue) ? null : value
      } else {
        return isNaN(parsedValue) ? null : parsedValue
      }
    },
    onInput(e) {
      const value = this.processValue(e.target.value)
      this.handleChange(e.target.value, !!this.errorMessage)
      this.$emit('update:modelValue', value)
      this.$emit('input', value)
    },
    onChange(e) {
      const value = this.processValue(e.target.value)
      this.handleChange(value)
      this.$emit('change', value)
    },
    focus() {
      this.$refs.input.focus()
    },
    onKeyDown(e) {
      if (
        // !this.stepper &&
        e.key === 'ArrowUp' ||
        e.key === 'ArrowDown'
      ) {
        e.preventDefault()
      }
    }
  },
  watch: {
    modelValue() {
      const value = this.processValue(this.inputValue)
      if (this.modelValue !== value) {
        this.resetField({ value: this.modelValue })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.number-input {
  display: inline-block;
}

// Remove stepper for most number inputs
.number-input:not(.number-input--stepper) {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & {
    -moz-appearance: textfield;
  }
}
</style>
