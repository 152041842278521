<template>
  <auth-card header="Login">
    <loading-container :loading="oauthLoading">
      <div class="card-content">
        <button-link secondary class="mr-4 button-content" :to="loginRoute">
          Login with Email and Password
        </button-link>
        <oauth-selection
          @success="onOauthLogin"
          prefix="Login with"
        ></oauth-selection>
        <div class="forgot_password">
          <router-link :to="{ name: 'forgot_password' }">
            Forgot login details?
          </router-link>
        </div>
      </div>
    </loading-container>
  </auth-card>
</template>

<script setup>
import OauthSelection from './OauthSelection.vue'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useFlash } from 'src/shared/hooks/flash'
import AuthCard from './AuthCard.vue'
import client from 'src/shared/api-client'

const router = useRouter()
const flash = useFlash()

const oauthLoading = ref(false)

const loginRoute = computed(() => ({
  ...router.currentRoute.value,
  params: { ...router.currentRoute.value.params, step: 'email' }
}))

async function onOauthLogin() {
  try {
    oauthLoading.value = true
    const response = await client.auth.loginOAuth()
    if (response?.newTeacher) {
      router.push({
        name: 'instructor_rostering_signup'
      })
    } else if (response?.connect) {
      router.push({
        ...router.currentRoute.value,
        params: { ...router.currentRoute.value.params, step: 'connect' }
      })
    } else {
      router.push({
        ...router.currentRoute.value,
        params: { ...router.currentRoute.value.params, step: 'redirect' }
      })
    }
  } catch (error) {
    if (error.status === 401) {
      error.body.errors.map(error => flash.error(error.message))
    } else {
      throw error
    }
  } finally {
    oauthLoading.value = false
  }
}
</script>
<style lang="scss" scoped>
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.button-content {
  width: 300px;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
}
.title {
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}
.forgot_password {
  margin-top: 8px;
}
</style>
