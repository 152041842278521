<template>
  <form-group class="no-margin">
    <editor
      :text="activity.objectives.summary"
      label="summary"
      data-testid="activity-summary"
      aria-label="Objectives"
      :variable-context="variableContext"
      @input="arg => (activity.objectives.summary = arg)"
      video
    />
  </form-group>
</template>

<script>
export default {
  name: 'ActivityObjectiveSummarySection',
  inject: ['inherited'],
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    activity() {
      return this.inherited.activity
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.no-margin {
  margin: 0;
}
</style>
