<template>
  <div>
    <div
      class="table-responsive"
      :class="[
        {
          'max-h-[700px]': hasSummary
        },
        tableClass
      ]"
    >
      <table
        class="table list__table"
        :class="{ 'table-hover': hasDetailsTemplate }"
        :aria-label="ariaLabel"
      >
        <thead class="sticky top-0 !bg-white z-10">
          <tr>
            <!-- @slot Contains the columns used in the list. -->
            <slot />
          </tr>
          <tr v-if="hasSummary">
            <list-cell
              v-for="(column, colIndex) in columns"
              :key="colIndex"
              :template="column.summary"
              class="summary-row"
              sticky
            />
          </tr>
        </thead>
        <tbody>
          <tr v-if="hasFirstRow">
            <td :colspan="columns.length">
              <!-- @slot Custom row that spans all columns. Useful for providing an action to add an item. -->
              <slot name="firstRow" />
            </td>
          </tr>

          <template
            v-for="(item, rowIndex) in items"
            :key="item[itemKey] ?? rowIndex"
          >
            <tr
              :class="[
                {
                  'details-open': rowIndex === rowDetailsIndex
                },
                computeRowClass(item)
              ]"
              @click="toggleDetails(rowIndex)"
            >
              <list-cell
                v-for="(column, colIndex) in columns"
                :key="colIndex"
                :property="column.property"
                :template="column.template"
                :item="item"
                :sticky="column.sticky"
                :details-open="rowIndex === rowDetailsIndex"
              />
            </tr>
            <tr
              v-if="hasDetailsTemplate && rowDetailsIndex === rowIndex"
              class="details-row"
            >
              <td :colspan="columns.length">
                <!--
                @slot Custom row that spans all columns to provide more details on a specific item.
                Recieves the `item` as a slot prop.
              -->
                <slot name="details" :item="item" />
              </td>
            </tr>
          </template>
          <tr v-if="hasLastRow">
            <td :colspan="columns.length">
              <!-- @slot Custom row that spans all columns. Useful for providing an action to add an item. -->
              <slot name="lastRow" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { h, Fragment } from 'vue'
import ListColumn from 'src/shared/components/global/ListColumn'
import SortableListColumn from 'src/shared/components/global/SortableListColumn'
const ListCell = props => {
  // Render using the template if specified.
  if (props.template) {
    return h(
      'td',
      { class: props.sticky ? 'list-cell--sticky' : '' },
      props.template({ detailsOpen: props.detailsOpen, ...props.item })
    )
  }
  // Otherwise just display the value of the given property.
  else {
    return h(
      'td',
      { class: props.sticky ? 'list-cell--sticky' : '' },
      props.item?.[props.property]
    )
  }
}
ListCell.props = {
  property: {
    type: String,
    default: null
  },
  item: {
    type: null
  },
  template: {
    type: Function,
    default: null
  },
  sticky: {
    type: Boolean,
    default: false
  },
  detailsOpen: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'List',
  components: { ListCell },
  props: {
    /**
     * The collection of items to display.
     */
    items: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The CSS class(es) to apply to each row.
     */
    rowClass: {
      type: null,
      default: null
    },
    /**
     * The accessible label for the table.
     * Required for all tables.
     */
    ariaLabel: {
      type: String,
      default: null
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    tableClass: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      columns: [],
      rowDetailsIndex: null
    }
  },
  computed: {
    hasDetailsTemplate() {
      return !!this.$slots.details
    },
    hasFirstRow() {
      return !!this.$slots.firstRow
    },
    hasLastRow() {
      return !!this.$slots.lastRow
    },
    hasSummary() {
      return this.columns.some(column => typeof column.summary !== 'undefined')
    }
  },
  mounted() {
    this.buildColumns()
  },
  updated() {
    this.buildColumns()
  },
  methods: {
    toggleDetails(rowIndex) {
      this.rowDetailsIndex = this.rowDetailsIndex === rowIndex ? null : rowIndex
    },
    computeRowClass(item) {
      if (typeof this.rowClass === 'function') {
        return this.rowClass(item)
      } else {
        return this.rowClass || ''
      }
    },
    buildColumns() {
      let nestedChildren = this.$slots.default?.() || []
      // PaginatedList passes down its slot as a fragment,
      // so we have to unwrap it here.

      let children = []

      nestedChildren.forEach(child => {
        if (Array.isArray(child.children)) {
          let unNested = child.children
          children.push(...unNested)
        } else {
          children.push(child)
        }
      })
      // Get the props and slot from each ListColumn component.
      let newColumns = []
      newColumns = children.reduce((cols, vm) => {
        if (
          vm.type &&
          (vm.type.name === ListColumn.name ||
            vm.type.name === SortableListColumn.name)
        ) {
          cols.push({
            title: vm.props?.title,
            property: vm.props?.property,
            template: vm.children?.default,
            summary: vm.children?.summary,
            sticky: vm.props?.sticky
          })
        }
        return cols
      }, [])
      if (newColumns.length !== this.columns.length) {
        this.columns = newColumns
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table > tbody > tr.details-open > td {
  border-bottom: 0;
  overflow: auto;
  margin: 0px;
}

.table > tbody > tr.details-row > td {
  border-top: 0;
  overflow: auto;
}

.table td {
  vertical-align: middle;
}

.list-cell--sticky {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}

.table-hover > tbody > tr.details-row:hover {
  background-color: inherit;
}

.table > tbody > tr.middle > td {
  vertical-align: middle;
}
.table-wrap {
  max-height: 75vh;
  overflow: auto;
}
.summary-row {
  background-color: lavender !important;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  border-top: 3px;
  border-color: gray;
}
</style>
