<template>
  <div>
    <sticky-header-list>
      <slot />
    </sticky-header-list>
  </div>
</template>

<script>
import StickyHeaderList from 'src/shared/components/StickyHeaderList.vue'

export default {
  name: 'StickyHeaderLibraries',
  components: {
    StickyHeaderList
  }
}
</script>
