<template>
  <loading-spinner v-if="!loaded" />
  <student-response-realtime-view
    v-else-if="concurrentEditingEnabled || isGroupAssignment"
    v-bind="$props"
  />
  <student-response-view v-else v-bind="$props" />
</template>
<script>
import LoadingSpinner from 'src/shared/components/LoadingSpinner'
import StudentResponseRealtimeView from './StudentResponseRealtimeView'
import StudentResponseView from './StudentResponseView'
import client from 'src/shared/api-client'
import { mapState } from 'vuex'

export default {
  name: 'StudentResponseFeatureFlaggedView',
  components: {
    StudentResponseRealtimeView,
    StudentResponseView,
    LoadingSpinner
  },
  data() {
    return {
      loaded: false,
      isGroupAssignment: null
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    concurrentEditingEnabled() {
      return this.$featureEnabled('concurrent-editing')
    }
  },
  async created() {
    if (this.user.role === 'teacher') {
      return this.$router.push({
        name: 'preview_assignment',
        params: { id: this.id }
      })
    }
    if (typeof this.query?.groups === 'boolean') {
      this.isGroupAssignment = this.query.groups
      this.loaded = true
      return
    }
    try {
      const response = await client.assignments.getStudentResponse({
        assignmentId: this.id
      })
      this.isGroupAssignment = response.assignment.assignedTo === 'groups'
      this.loaded = true
    } catch (error) {
      if (error.status === 404) {
        this.isGroupAssignment = false
        this.loaded = true
      } else {
        throw error
      }
    }
  }
}
</script>
