<template>
  <div class="view-header-actions">
    <div class="view-header-actions__spacer" />
    <!-- @slot The actions for the view's header. -->
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ViewHeaderActions'
}
</script>

<style lang="scss" scoped>
.view-header-actions {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  margin: 0 0 0 8px;
  flex-wrap: wrap;
}

.view-header-actions__spacer {
  flex: 1 1;
  width: 100%;
}
</style>
