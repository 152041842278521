<template>
  <auth-card header="Create New Account">
    <async-form
      class="profile-details__form"
      data-testid="signup-form"
      @submit="onSubmit"
    >
      <p v-if="completingProfile" class="profile-details__sso-notice">
        You will use the third party account you signed into to authenticate
        with Pivot Interactives. We just need a few more details to finish
        creating your account.
      </p>

      <div class="profile-details__email">
        <form-label id="email-address-label">Email Address</form-label>
        <static-value
          v-if="completingProfile"
          aria-labelledby="email-address-label"
          >{{ fieldValues.email }}</static-value
        >
        <text-input
          v-else
          v-model="fieldValues.email"
          rules="required|email"
          label="email"
          name="email"
          aria-labelledby="email-address-label"
        />
      </div>

      <div v-if="!completingProfile" class="profile-details__email-confirm">
        <form-label for="confirm-email">Confirm Email</form-label>
        <text-input
          id="confirm-email"
          v-model="fieldValues.confirmEmail"
          rules="required|confirmed:@email"
          label="email"
        />
      </div>

      <div class="profile-details__first-name">
        <form-label for="first-name">First Name</form-label>
        <text-input
          id="first-name"
          v-model="fieldValues.firstName"
          rules="required"
          label="first name"
          autocomplete="given-name"
        />
      </div>

      <div class="profile-details__last-name">
        <form-label for="last-name">Last Name</form-label>
        <text-input
          id="last-name"
          v-model="fieldValues.lastName"
          rules="required"
          label="last name"
          autocomplete="family-name"
        />
      </div>
      <div v-if="!student" class="profile-details__roles">
        <form-label id="institution-roles">Institution Roles</form-label>
        <p>Check all roles that apply</p>
        <institution-roles-choices
          v-model="fieldValues.institutionRoles"
          aria-labelledby="institution-roles"
        />
      </div>

      <div v-if="!student" class="profile-details__subjects">
        <form-label id="primary-subjects">Primary Subjects</form-label>
        <p>Check all subjects that apply</p>
        <primary-subjects-choices
          v-model="fieldValues.primarySubjects"
          aria-labelledby="primary-subjects"
        />
      </div>

      <div v-if="!completingProfile" class="profile-details__password">
        <form-label for="password">Password</form-label>
        <text-input
          id="password"
          v-model="fieldValues.password"
          :rules="{
            required: true,
            password: { isStudent: student }
          }"
          label="password"
          type="password"
          name="password"
          autocomplete="new-password"
        />
      </div>

      <div v-if="!completingProfile" class="profile-details__password-confirm">
        <form-label for="confirm-password">Confirm Password</form-label>
        <text-input
          id="confirm-password"
          v-model="fieldValues.confirmPassword"
          rules="required|confirmed:@password"
          label="password"
          type="password"
          autocomplete="new-password"
        />
      </div>

      <div class="profile-details__terms" v-if="!student">
        <label for="termsOfService">
          <input
            class="terms-checkbox"
            id="termsOfService"
            v-model="acceptTerms"
            type="checkbox"
            name="acceptTerms"
          />
          I have read and accepted the Pivot Interactives
          <hyper-link :to="{ name: 'terms' }">Terms of Service</hyper-link>
          and
          <hyper-link :to="{ name: 'privacy' }">Privacy Policy </hyper-link>
        </label>
      </div>
      <div class="profile-details__actions">
        <submit-button
          :disabled="!canSubmit"
          data-testid="signup-submit"
          name="login-submit"
        >
          Create Account
        </submit-button>
        <span>
          <slot />
        </span>
      </div>
    </async-form>
  </auth-card>
</template>

<script setup>
import AuthCard from './AuthCard.vue'
import InstitutionRolesChoices from 'src/shared/components/InstitutionRolesChoices'
import PrimarySubjectsChoices from 'src/shared/components/PrimarySubjectsChoices'
import { useFlash } from 'src/shared/hooks/flash'
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import client from 'src/shared/api-client'

const flash = useFlash()
const router = useRouter()
const props = defineProps({
  student: {
    type: Boolean,
    default: false
  },
  initialFieldValues: {
    type: Object,
    default: () => {}
  },
  invitationToken: {
    type: String
  }
})

const acceptTerms = ref(false)
const fieldValues = ref({
  email: '',
  confirmEmail: '',
  firstName: '',
  lastName: '',
  institutionRoles: [],
  primarySubjects: [],
  password: '',
  confirmPassword: '',
  ...props.initialFieldValues
})

const completingProfile = ref(false)
const canSubmit = computed(() => (props.student ? true : acceptTerms.value))

onMounted(async () => {
  try {
    const profile = await client.auth.getOAuthProfile()
    fieldValues.value = {
      ...fieldValues.value,
      ...profile
    }
    completingProfile.value = true
  } catch (error) {
    if (error.status !== 404) {
      throw error
    }
  }
})

async function onSubmit(e) {
  try {
    const shared = {
      firstName: fieldValues.value.firstName,
      lastName: fieldValues.value.lastName,
      password: fieldValues.value.password
    }
    if (props.student) {
      await client.auth.studentSignup({
        ...shared,
        email: fieldValues.value.email
      })
    } else if (!completingProfile.value || props.invitationToken) {
      await client.auth.teacherSignup({
        ...shared,
        institutionRoles: fieldValues.value.institutionRoles,
        primarySubjects: fieldValues.value.primarySubjects,
        email: fieldValues.value.email,
        token: props.invitationToken
      })
    } else {
      await client.auth.signUpOauth({
        ...shared,
        institutionRoles: fieldValues.value.institutionRoles,
        primarySubjects: fieldValues.value.primarySubjects
      })
    }
    flash.success(`Your account was created successfully.`)
    router.push({
      ...router.currentRoute.value,
      params: { ...router.currentRoute.value.params, step: 'redirect' }
    })
    e.done()
  } catch (error) {
    e.done(false)
    throw error
  }
}
</script>

<style scoped>
.terms-checkbox {
  display: inline;
}

.profile-details__form {
  display: grid;
  grid:
    'sso-notice sso-notice' auto
    'email email-confirm' auto
    'first-name last-name' auto
    'roles subjects' auto
    'password password-confirm' auto
    'terms terms' auto
    'actions actions' auto
    / 1fr 1fr;
}

.profile-details__sso-notice {
  grid-area: sso-notice;
  margin-bottom: 8px;
}
.profile-details__email {
  grid-area: email;
  margin-bottom: 8px;
  margin-right: 8px;
}
.profile-details__email-confirm {
  grid-area: email-confirm;
  margin-bottom: 8px;
}
.profile-details__first-name {
  grid-area: first-name;
  margin-bottom: 8px;
  margin-right: 8px;
}
.profile-details__last-name {
  grid-area: last-name;
  margin-bottom: 8px;
}
.profile-details__roles {
  grid-area: roles;
  margin-bottom: 8px;
  margin-right: 8px;
}
.profile-details__subjects {
  grid-area: subjects;
  margin-bottom: 8px;
}
.profile-details__password {
  grid-area: password;
  margin-bottom: 8px;
  margin-right: 8px;
}
.profile-details__password-confirm {
  grid-area: password-confirm;
  margin-bottom: 8px;
}
.profile-details__terms {
  grid-area: terms;
  margin-bottom: 8px;
}
.profile-details__actions {
  grid-area: actions;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
</style>
