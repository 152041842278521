<template>
  <button-dropdown unstyled center class="group-name">
    <template #button="{ isOpen }">
      Group Assignment
      <icon
        class="grading-progress-dropdown__icon"
        :icon="isOpen ? 'caret-up' : 'caret-down'"
      />
    </template>
    <ul class="group-dropdown-menu list-none pl-0">
      <li v-for="(student, i) in studentsList" :key="i" class="student">
        {{ student }}
      </li>
    </ul>
  </button-dropdown>
</template>

<script>
export default {
  name: 'ActivityGroupName',
  inject: ['inherited'],
  components: {},
  computed: {
    response() {
      return this.inherited.response
    },
    studentsList() {
      return this.response.students.map(s => `${s.firstName} ${s.lastName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.group-name {
  position: relative;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  .dropdown-toggle {
    color: $dark-grey;
  }
}

.student {
  padding: 5px 20px;
}
</style>
