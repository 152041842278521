<template>
  <view-container narrow>
    <view-header>
      <view-title>{{ isCreate ? 'Create' : 'Update' }} License</view-title>
    </view-header>

    <async-form ref="form" class="form-two-column" @submit="submit" persist>
      <!-- Name Field -->
      <form-group>
        <form-label for="name">Name</form-label>
        <text-input
          id="name"
          v-model="license.name"
          rules="required"
          label="name"
          type="text"
        />
      </form-group>
      <form-group>
        <form-label for="institutionName">Institution Name</form-label>
        <text-input
          id="institutionName"
          v-model="license.institutionName"
          rules="required"
          label="institutionName"
          type="text"
        />
      </form-group>
      <form-group class="input-30" v-if="isCreate">
        <form-label for="emailDomain">Institution Domain</form-label>
        <text-input
          id="emailDomain"
          v-model="license.emailDomain"
          label="institution domain"
          rules="schoolDomain|required"
        />
      </form-group>
      <form-group>
        <form-label for="DiscoveryAccount">DEK12 Account</form-label>
        <checkbox id="DiscoveryAccount" v-model="license.isDiscoveryManaged">
        </checkbox>
      </form-group>
      <form-group class="input-30">
        <form-label for="salesforceId">DE Salesforce ID</form-label>
        <text-input
          id="salesforceId"
          v-model="license.salesforceId"
          label="salesforceId"
          rules="required"
        />
      </form-group>
      <form-group>
        <form-label id="type-label">Type</form-label>
        <form-label v-if="isSalesAdmin">Trial License</form-label>
        <selector-input
          v-else
          v-model="license.type"
          class="selector-input--horizontal"
          aria-labelledby="type-label"
          label="type"
        >
          <selector-option value="institution" title="Institution" />
          <selector-option value="student" title="Student" />
          <selector-option value="trial" title="Trial" />
        </selector-input>
      </form-group>
      <form-group>
        <form-label id="level-label">Level</form-label>
        <selector-input
          v-model="license.level"
          class="selector-input--horizontal"
          aria-labelledby="level-label"
          label="level"
        >
          <selector-option value="high-school" title="6–12" />
          <selector-option value="college" title="College" />
        </selector-input>
      </form-group>
      <form-group v-if="license.type && license.type === 'trial'">
        <form-label for="autoVet">Automatically pre-vet users</form-label>
        <checkbox id="autoVet" v-model="license.autoVet"> </checkbox>
      </form-group>
      <template v-if="license.type">
        <template v-if="requireMaxSeats">
          <!-- Max Seat Count Field -->
          <form-group>
            <form-label for="maxSeats">Max Seat Count</form-label>
            <number-input
              id="maxSeats"
              v-model="license.maxSeats"
              rules="required|min_value:1|integer"
              label="Max Seats"
              step="1"
            />
          </form-group>
        </template>

        <form-group>
          <form-label for="start-date"> Start Date </form-label>
          <div>
            <date-input
              id="start-date"
              v-model="startDate"
              rules="required"
              label="start date"
              :clearable="false"
            />
          </div>
        </form-group>
        <form-group v-if="requireEndDate">
          <form-label for="end-date"> End Date </form-label>
          <div>
            <date-input
              id="end-date"
              v-model="endDate"
              :rules="{ required: true, after: startDate }"
              label="end date"
              :min-date="startDate"
              :clearable="false"
            />
          </div>
        </form-group>
      </template>

      <form-group variant="right-aligned">
        <submit-button class="pull-right">
          <template #default>{{ isCreate ? 'Create' : 'Update' }}</template>
          <template #submitting>Submitting</template>
          <template #submitted>Submitted</template>
        </submit-button>
      </form-group>
    </async-form>
  </view-container>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { getUTCMidnightOfDay } from 'src/shared/utils/dates'
import { sub, endOfDay } from 'date-fns'
import client from 'src/shared/api-client'
import { computed, onMounted, ref } from 'vue'
import { CreateLicenseRequest } from '@pi/api-types'
import { useFlash } from 'src/shared/hooks/flash'

type LicenseProps = Omit<CreateLicenseRequest, 'startDate' | 'endDate'> & {
  startDate?: Date
  endDate?: Date
}
const props = defineProps({
  id: {
    type: String,
    required: false
  },
  isCreate: {
    type: Boolean,
    default: false
  }
})

const form = ref()
const flash = useFlash()

const license = ref<LicenseProps>({
  name: '',
  institutionName: '',
  maxSeats: 0,
  startDate: undefined,
  endDate: undefined,
  type: '',
  level: '',
  emailDomain: '',
  isDiscoveryManaged: false,
  salesforceId: '',
  autoVet: true
})
const store = useStore()
const isSalesAdmin = computed(() => store.getters.isSalesAdmin)

const requireMaxSeats = computed(() => license.value.type === 'institution')
const requireEndDate = computed(() => license.value.type !== 'student')
const startDate = computed({
  get() {
    return license.value.startDate
  },
  set(date?: Date) {
    if (date) {
      date = getUTCMidnightOfDay(date)
      license.value.startDate = date
    } else {
      license.value.startDate = undefined
    }
  }
})
const endDate = computed({
  get() {
    return license.value.endDate
  },
  set(date) {
    if (date) {
      date = endOfDay(date)
      date = sub(date, { minutes: date.getTimezoneOffset() })

      if (new Date().getTimezoneOffset() >= 0) {
        license.value.endDate = date
      } else {
        license.value.endDate = sub(date, { days: 1 })
      }
    } else {
      license.value.endDate = undefined
    }
  }
})

const getLicense = async () => {
  const body = await client.licenses.get({ id: props.id! })
  license.value = {
    name: body.name,
    institutionName: body.institutionName,
    maxSeats: body.maxSeats,
    startDate: new Date(body.startDate),
    endDate: body.endDate ? new Date(body.endDate) : undefined,
    type: body.type,
    level: body.level,
    emailDomain: body.emailDomain,
    isDiscoveryManaged: body.isDiscoveryManaged,
    salesforceId: body.salesforceId,
    autoVet: body.autoVet
  }
}
const submit = async (e: any) => {
  try {
    if (props.isCreate) {
      const licenseId = await createLicense()

      flash.success(
        'License created successfully. Click this banner to manage license',
        {
          route: { name: 'admin_manage_license', params: { id: licenseId } }
        }
      )
      clear()
    } else {
      await updateLicense()

      flash.success('License updated successfully')
    }

    e.done()
  } catch (error) {
    e.done(false)
    throw error
  }
}
const createLicense = async () => {
  const {
    name,
    institutionName,
    maxSeats,
    type,
    level,
    salesforceId,
    isDiscoveryManaged,
    emailDomain,
    autoVet
  } = license.value

  const licenseId = await client.licenses.create({
    name,
    institutionName,
    maxSeats,
    startDate: startDate.value ? startDate.value : new Date(),
    endDate: requireEndDate.value ? endDate.value : undefined,
    type,
    level,
    emailDomain,
    salesforceId,
    isDiscoveryManaged,
    autoVet
  })

  return licenseId
}
const updateLicense = async () => {
  if (!props.id) return
  const {
    name,
    institutionName,
    maxSeats,
    startDate,
    endDate,
    type,
    level,
    salesforceId,
    isDiscoveryManaged,
    autoVet
  } = license.value
  await client.licenses.update({
    id: props.id,
    name,
    institutionName,
    startDate,
    salesforceId,
    isDiscoveryManaged,
    type,
    level,
    autoVet,
    ...(requireMaxSeats.value && { maxSeats }),
    ...(requireEndDate.value && { endDate })
  })
}
const clear = () => {
  license.value = {
    name: '',
    institutionName: '',
    maxSeats: 0,
    startDate: undefined,
    endDate: undefined,
    type: '',
    level: '',
    emailDomain: '',
    isDiscoveryManaged: false,
    salesforceId: '',
    autoVet: true
  }

  form.value.reset()
}
onMounted(async () => {
  if (isSalesAdmin.value) {
    license.value.type = 'trial'
  }
  if (!props.isCreate) {
    await getLicense()
  }
})
</script>

<style scoped>
.form-label {
  width: 120px;
}
</style>
