<template>
  <view-container narrow>
    <loading-container loading />
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'

export default {
  name: 'OAuthRedirectView',
  props: {
    code: {
      type: String,
      default: undefined
    },
    provider: {
      type: String,
      default: undefined
    },
    state: {
      type: Object,
      default: () => {}
    }
  },
  async created() {
    await this.exchangeOAuthCode()
  },
  computed: {
    providerLaunched() {
      try {
        return (
          !window.opener ||
          window.opener.location.origin !== window.location.origin
        )
      } catch {
        return true
      }
    }
  },
  methods: {
    async exchangeOAuthCode() {
      try {
        await client.auth.exchangeOAuthCode({
          provider: this.provider,
          code: this.code
        })

        if (!this.providerLaunched) {
          await this.onAfterPivotInitiatedLogin(null, this.provider)
        } else {
          await this.onAfterProviderInitiatedLogin(null, this.provider)
        }
      } catch (error) {
        if (!this.providerLaunched) {
          await this.onAfterPivotInitiatedLogin(error, this.provider)
        } else {
          await this.onAfterProviderInitiatedLogin(error, this.provider)
        }
      }
    },
    async onAfterPivotInitiatedLogin(error, provider) {
      if (error) {
        window.opener.postMessage(
          {
            type: 'pivot-oauth',
            status: 'error',
            error: JSON.parse(JSON.stringify(error))
          },
          window.location.origin
        )

        return window.close()
      }

      window.opener.postMessage(
        {
          type: 'pivot-oauth',
          status: 'ok',
          provider
        },
        window.location.origin
      )

      return window.close()
    },
    async onAfterProviderInitiatedLogin(error) {
      try {
        if (error) {
          throw error
        }
        const body = await client.auth.loginOAuth()

        if (body?.newTeacher) {
          this.$router.push({
            name: 'instructor_rostering_signup'
          })
        } else if (body?.connect) {
          this.$router.push({
            name: 'login',
            params: { step: 'connect' }
          })
        } else {
          window.location.assign('/')
        }
      } catch (error) {
        this.$router.replace({ name: 'login' })
        throw error
      }
    }
  }
}
</script>
