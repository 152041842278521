<template>
  <div class="drag-drop-area">
    <editor
      v-if="type === 'rich-text'"
      :variable-context="variableContext"
      v-model:text="text"
      drop-targets
      :drop-choices="choices"
    />
    <template v-else-if="type === 'latex'">
      <div class="drag-drop-area__latex-preview">
        <latex-block
          :latex="latexForPreview"
          html
          @click="onLatexClick"
          @render="onLatexRender"
        />
      </div>
      <multiline-text-input v-model="text" :rows="6" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { Component, computed, inject } from 'vue'
import { findTextDropTargets } from '../../../shared/components/editor/drop-target/DropTarget'
import {
  processLatex,
  expressionToBoxedLatex
} from '../../../shared/utils/latex-methods'
import { indexToLetter } from '../../../shared/utils/index-to-letter'
import LatexDropTargetModal from './LatexDropTargetModal.vue'

interface Target {
  id: string
  label?: string
  invalidResponse?: string
  targetType?: 'drag-drop' | 'dropdown'
}

interface Props {
  type: 'latex' | 'rich-text'
  text?: string
  choices: any[]
  targets: Target[]
  variableContext: any
}

const emit = defineEmits<{
  update: [{ text: string; targets?: Target[] }]
}>()
const props = defineProps<Props>()

const text = computed<string>({
  get() {
    if (props.type === 'rich-text') {
      return props.text ?? ''
    } else if (props.type === 'latex') {
      return processLatex(
        props.text ?? '',
        props.variableContext.variables,
        'id'
      ) as string
    } else {
      return ''
    }
  },
  set(text) {
    let targets: Target[] = []
    if (props.type === 'rich-text') {
      targets = findTextDropTargets(JSON.parse(text)).map(target => ({
        id: target.id,
        label: target.label ?? undefined,
        invalidResponse: target.invalidResponse ?? undefined,
        targetType: target.targetType ?? 'drag-drop'
      }))
    } else if (props.type === 'latex') {
      text = processLatex(text, props.variableContext.variables, 'name')
      targets = Array.from(
        text.matchAll(/\\droptarget\{(\d+)\}(?:\{(.+?)\})?/g)
      ).map(([_, id, label]) => {
        const prevTarget = props.targets.find(target => target.id === id)
        return {
          id,
          label,
          invalidResponse: prevTarget?.invalidResponse,
          targetType: prevTarget?.targetType ?? 'drag-drop'
        }
      })
    }
    // We want to determine if any of the targets have changed
    let noneChanged = targets.length === props.targets?.length
    targets = targets.map(target => {
      const prevTarget = props.targets?.find(t => t.id === target.id)
      if (
        !prevTarget ||
        prevTarget.label !== target.label ||
        prevTarget.invalidResponse !== target.invalidResponse ||
        prevTarget.targetType !== target.targetType
      ) {
        noneChanged = false
        return target
      } else {
        return prevTarget
      }
    })
    emit('update', {
      text,
      targets: noneChanged ? undefined : targets
    })
  }
})

const latexForPreview = computed<string | undefined>(() => {
  if (props.type === 'latex') {
    let counter = 0

    let displayText = text.value.replaceAll(
      /\\droptarget\{(\d+)\}(?:\{(.+?)\})?/g,
      (match, id, label = '') => {
        const indexLetter = indexToLetter(counter++)
        const labelText = label ? label : indexLetter

        return `\\ref{${id}}{${labelText}}`
      }
    )

    return expressionToBoxedLatex(displayText)
  } else {
    return undefined
  }
})

function onLatexRender(root: HTMLElement) {
  Array.from(root.querySelectorAll<HTMLElement>('.katex-ref')).forEach(
    target => {
      target.tabIndex = 0
      const id = target.dataset.ref
      const targetData = props.targets.find(t => t.id === id)
      target.ariaLabel = targetData?.label
        ? `${targetData.label}, drop target`
        : 'drop target'
    }
  )
}

const modal = inject('$modal') as {
  show<Props>(
    modal: Component<Props>,
    props: Props
  ): Promise<{ status: 'cancel' } | { status: 'ok'; data: Target }>
}
async function onLatexClick(e: MouseEvent) {
  const target = document
    .elementsFromPoint(e.clientX, e.clientY)
    .find(el => el.getAttribute('data-ref'))
  if (target) {
    const id = target.getAttribute('data-ref')
    const index = props.targets.findIndex(t => t.id === id)
    const currentTarget = props.targets[index]
    if (!currentTarget) return

    const modalResponse = await modal.show(LatexDropTargetModal, {
      target: currentTarget
    })
    if (modalResponse.status === 'ok') {
      const mutableTargets = props.targets ?? []
      mutableTargets.splice(index, 1, {
        ...currentTarget,
        ...modalResponse.data
      })
      emit('update', {
        text: text.value,
        targets: mutableTargets
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.drag-drop-area {
  margin: 0 0 16px 0;
}

.drag-drop-area__latex-preview {
  margin: 0 0 8px 0;
  padding: 0 0 0 16px;
}

:deep(.katex-ref) {
  border: 1px dashed #3d2c60;
  border-radius: 4px;
  cursor: pointer;
}
</style>
