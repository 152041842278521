<template>
  <modal class="data-graph-curve-fits-modal" @submit="submit">
    <modal-header>Curve Fits</modal-header>
    <modal-body>
      <div>
        <p v-if="!disabled">
          Select a curve fit for to apply to a plotted column.
        </p>
        <p v-else>
          Curve fits are disabled when plotting a text column on the horizontal
          axis.
        </p>
        <form-group
          v-for="(selection, selectionIndex) in selections"
          :key="selectionIndex"
        >
          <form-label>
            <latex-block
              v-if="columns[selection.data].name.parser === 'latex'"
              :latex="columns[selection.data].name.formula"
            />
            <template v-else>{{ columns[selection.data].name }}</template>
          </form-label>
          <select-field
            v-model="selection.curve"
            :aria-describedby="`select curve fit for plotted column ${columnDisplayName(
              columns[selection.data]
            )}`"
            :disabled="disabled"
            :data-focus="disabled ? null : true"
          >
            <option value="none">No Curve Fit</option>
            <option value="proportional" v-if="settings.proportional">
              Proportional: y = Ax
            </option>
            <option value="linear" v-if="settings.linear">
              Linear: y = Ax + B
            </option>
            <option value="square" v-if="settings.squareLaw">
              Square Law: y = Ax&sup2;
            </option>
            <option value="squareroot" v-if="settings.squareRoot">
              Square Root: y = A&radic;x
            </option>
            <option value="quadratic" v-if="settings.quadratic">
              Quadratic: y = Ax&sup2; + Bx + C
            </option>
            <option value="exponential" v-if="settings.exponential">
              Exponential: y = A e^(Bx);
            </option>
            <option value="logarithmic" v-if="settings.logarithmic">
              Logarithmic: y = A + Bln(x)
            </option>
            <option value="inverse" v-if="settings.inverse">
              Inverse: y = A / x
            </option>
            <option value="inverse-square" v-if="settings.inverseSquare">
              Inverse Square: y = A / x&sup2;
            </option>
          </select-field>
        </form-group>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Done</modal-button-submit>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'

import { columnDisplayName } from 'src/shared/components/grid-graph/utilities'

export default {
  name: 'DataGraphCurveFitsModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    initialSelections: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selections: this.initialSelections
    }
  },
  methods: {
    async submit(e) {
      e.done(this.selections)
    },
    columnDisplayName
  }
}
</script>
