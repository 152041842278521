<template>
  <ul
    class="library-filters full-width"
    ref="container"
    role="search"
    aria-label="activity filters"
    v-if="!isLoading"
  >
    <li class="library-filters__filter">
      <library-filter-dropdown
        name="Subjects & Topics"
        :count="getFilterCount('topics')"
        tag="ul"
      >
        <tree-input
          aria-label="Subjects & Topics"
          :tree="categoryFilters['topics']"
          :modelValue="modelValue.categories['topics']"
          @update:modelValue="value => onCategoryFilterChange('topics', value)"
        />
      </library-filter-dropdown>
    </li>
    <li
      v-if="visibleFilters.includes('standards')"
      class="library-filters__filter"
    >
      <library-filter-dropdown
        name="Standards"
        right
        :count="getFilterCount('standards')"
      >
        <library-filter-section
          v-for="node in categoryFilters['standards'].children"
          :key="node.id"
          :title="node.name"
        >
          <tree-input
            :aria-label="node.name"
            :tree="node.children"
            :modelValue="modelValue.categories['standards']"
            @update:modelValue="
              value => onCategoryFilterChange('standards', value)
            "
          />
        </library-filter-section>
      </library-filter-dropdown>
    </li>
    <li
      v-if="visibleFilters.includes('dimensions-of-science')"
      class="library-filters__filter"
    >
      <library-filter-dropdown
        name="Dimensions of Science"
        :count="getFilterCount('dimensions-of-science')"
        tag="ul"
      >
        <library-filter-section
          v-for="node in categoryFilters['dimensions-of-science'].children"
          :key="node.id"
          :title="node.name"
        >
          <tree-input
            :aria-label="node.name"
            :tree="node.children"
            :modelValue="modelValue.categories['dimensions-of-science']"
            @update:modelValue="
              value => onCategoryFilterChange('dimensions-of-science', value)
            "
          />
        </library-filter-section>
      </library-filter-dropdown>
    </li>
    <li
      v-if="visibleFilters.includes('pedagogy')"
      class="library-filters__filter"
    >
      <library-filter-dropdown
        name="Pedagogy"
        :count="getFilterCount('pedagogy')"
        tag="ul"
      >
        <library-filter-section
          v-for="node in categoryFilters['pedagogy'].children"
          :key="node.id"
          :title="node.name"
        >
          <tree-input
            :aria-label="node.name"
            :tree="node.children"
            :modelValue="modelValue.categories['pedagogy']"
            @update:modelValue="
              value => onCategoryFilterChange('pedagogy', value)
            "
          />
        </library-filter-section>
      </library-filter-dropdown>
    </li>
    <li
      v-if="visibleFilters.includes('grading')"
      class="library-filters__filter"
    >
      <library-filter-dropdown
        name="Grading & Feedback"
        :count="getFilterCount('grading')"
      >
        <library-filter-autograde
          :modelValue="modelValue.autograde"
          @update:modelValue="value => onFilterChange('autograde', value)"
        />
      </library-filter-dropdown>
    </li>

    <li
      class="library-filters__filter library-filters__more-filters"
      v-if="visibleFilters.length < 5 || showArchivedFilter || showFreeFilter"
    >
      <library-filter-dropdown :count="miscFilterCount" right tag="ul">
        <template #button>
          <icon icon="filter" class="library-filters__more-filters-icon" />
          <span class="library-filters__more-filters-text" :aria-hidden="true">
            More...
          </span>
          <span class="sr-only">More Filters</span>
        </template>

        <template v-if="!visibleFilters.includes('standards')">
          <library-filter-section
            v-for="node in categoryFilters['standards'].children"
            :key="node.id"
            :title="node.name"
          >
            <tree-input
              :aria-label="node.name"
              :tree="node.children"
              :modelValue="modelValue.categories['standards']"
              @update:modelValue="
                value => onCategoryFilterChange('standards', value)
              "
            />
          </library-filter-section>
        </template>
        <template v-if="!visibleFilters.includes('dimensions-of-science')">
          <library-filter-section
            v-for="node in categoryFilters['dimensions-of-science'].children"
            :key="node.id"
            :title="node.name"
          >
            <tree-input
              :aria-label="node.name"
              :tree="node.children"
              :modelValue="modelValue.categories['dimensions-of-science']"
              @update:modelValue="
                value => onCategoryFilterChange('dimensions-of-science', value)
              "
            />
          </library-filter-section>
        </template>
        <template v-if="!visibleFilters.includes('pedagogy')">
          <library-filter-section
            v-for="node in categoryFilters['pedagogy'].children"
            :key="node.id"
            :title="node.name"
          >
            <tree-input
              :aria-label="node.name"
              :tree="node.children"
              :modelValue="modelValue.categories['pedagogy']"
              @update:modelValue="
                value => onCategoryFilterChange('pedagogy', value)
              "
            />
          </library-filter-section>
        </template>

        <library-filter-section
          v-if="!visibleFilters.includes('grading')"
          name="Grading & Feedback"
          :count="getFilterCount('grading')"
        >
          <library-filter-autograde
            :modelValue="modelValue.autograde"
            @update:modelValue="value => onFilterChange('autograde', value)"
          />
        </library-filter-section>

        <library-filter-section v-if="showFreeFilter" title="Free/Paid">
          <library-filter-boolean
            aria-label="Free/Paid"
            true-label="Free"
            false-label="Paid"
            :modelValue="modelValue.isFree"
            @update:modelValue="value => onFilterChange('isFree', value)"
          />
        </library-filter-section>

        <library-filter-section v-if="showArchivedFilter" title="Archived">
          <library-filter-boolean
            aria-label="Archived"
            true-label="Archived"
            false-label="Unarchived"
            :modelValue="modelValue.isArchived"
            @update:modelValue="value => onFilterChange('isArchived', value)"
          />
        </library-filter-section>
      </library-filter-dropdown>
    </li>
    <li v-if="includeSearch">
      <activity-search-action :query="modelValue" size="sm" />
    </li>
  </ul>
</template>

<script>
import { ref, computed } from 'vue'
import LibraryFilterDropdown from './LibraryFilterDropdown'
import TreeInput from './TreeInput'
import LibraryFilterBoolean from './LibraryFilterBoolean'
import LibraryFilterSection from './LibraryFilterSection'
import LibraryFilterAutograde from './LibraryFilterAutograde'
import useElementSize from 'src/shared/hooks/element-size'
import useCategoryFilters from './use-category-filters'
import ActivitySearchAction from 'src/modules/libraries/components/ActivitySearchAction'

export default {
  name: 'LibraryFilters',
  components: {
    LibraryFilterDropdown,
    TreeInput,
    LibraryFilterBoolean,
    LibraryFilterSection,
    LibraryFilterAutograde,
    ActivitySearchAction
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    showFreeFilter: {
      type: Boolean,
      default: false
    },
    showArchivedFilter: {
      type: Boolean,
      default: false
    },
    includeSearch: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const container = ref(null)
    const containerSize = useElementSize(container)

    const {
      filters: categoryFilters,
      isLoading,
      keys: categoryKeys
    } = useCategoryFilters()

    const topicFilters = computed(() =>
      props.modelValue.categories.subjects?.length > 0
        ? categoryFilters.value.topics?.filter(topic =>
            props.modelValue.categories.subjects.includes(topic.slug)
          )
        : categoryFilters.value.topics
    )

    const pedagogyFilters = computed(() => {
      const test =
        props.modelValue.categories.pedagogy?.length > 0
          ? categoryFilters.value.pedagogy?.filter(pedagogy =>
              props.modelValue.categories.pedagogy.includes(pedagogy.slug)
            )
          : categoryFilters.value.pedagogy
      return test
    })

    const newTopicSubjects = computed(() => {
      return props.modelValue.categories.subjects
    })

    const miscFilters = computed(() => {
      const knownKeys = [
        'topics',
        'standards',
        'pedagogy',
        'grading',
        'dimensions-of-science'
      ]
      return categoryKeys.value.filter(key => !knownKeys.includes(key))
    })

    const visibleFilters = computed(() => {
      const width = containerSize.value.width
      const filters = ['topics', 'standards']
      if (width > 563) filters.push('dimensions-of-science')
      if (width > 713) filters.push('pedagogy')
      if (width > 939) filters.push('grading')
      return filters
    })
    const hiddenFilters = computed(() => {
      const keys = []
      if (props.showFreeFilter) {
        keys.push('free')
      }
      if (props.showArchivedFilter) {
        keys.push('archived')
      }
      keys.push(
        ...categoryKeys.value.filter(key => !visibleFilters.value.includes(key))
      )
      return keys
    })

    const getFilterCount = key => {
      switch (key) {
        case 'grading':
          return props.modelValue.autograde ? 1 : 0
        case 'free':
          return props.modelValue.isFree
            ? props.modelValue.isFree === 'both'
              ? 2
              : 1
            : 0
        case 'archived':
          return props.modelValue.isArchived
            ? props.modelValue.isArchived === 'both'
              ? 2
              : 1
            : 0
        default:
          return props.modelValue.categories[key]?.length ?? 0
      }
    }

    const miscFilterCount = computed(() =>
      hiddenFilters.value.reduce((total, key) => total + getFilterCount(key), 0)
    )

    const onFilterChange = (name, value) => {
      emit('update:modelValue', { [name]: value, page: 1 })
    }
    const onCategoryFilterChange = (name, value) => {
      emit('update:modelValue', { categories: { [name]: value }, page: 1 })
    }

    return {
      isLoading,
      categoryFilters,
      pedagogyFilters,
      miscFilters,
      topicFilters,
      getFilterCount,
      onFilterChange,
      onCategoryFilterChange,
      container,
      containerSize,
      visibleFilters,
      miscFilterCount
    }
  }
}
</script>

<style lang="scss" scoped>
.library-filters {
  list-style: none;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #ccc;
  display: flex;
  gap: 12px;
}

.library-filters_none {
  display: none;
  padding: none;
  margin: none;
}

.library-filters__filter {
  max-width: 200px;
  min-width: 0px;
}
.library-filters__more-filters {
  flex-shrink: 1;
  flex-grow: 0;
  @media screen and (max-width: 456px) {
    min-width: 60px;
  }
}
.library-filters__more-filters-icon {
  @media screen and (min-width: 457px) {
    display: none;
  }
}
.library-filters__more-filters-text {
  @media screen and (max-width: 456px) {
    display: none;
  }
}
</style>
