<template>
  <div
    class="view-header"
    :class="{
      'view-header--centered': centered,
      'view-header--bordered': bordered
    }"
  >
    <div class="view-header__primary-navigation">
      <slot name="primary-navigation" />
    </div>
    <div class="view-header__content">
      <!-- @slot Content of the view's header. -->
      <slot />
    </div>
    <flash v-if="showFlash" class="view-header__flash" />
  </div>
</template>

<script>
import Flash from 'src/shared/components/Flash.vue'

export default {
  name: 'ViewHeader',
  components: {
    Flash
  },
  props: {
    /**
     * Centers the header content in the view.
     */
    centered: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    showFlash: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.view-header--bordered {
  border-bottom: 1px solid #ccc;
  padding: 8px 9999% 0px 9999%;
  margin: 0 -9999% 24px -9999%;
}

.view-header {
  font-size: 16px;

  &__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 38px;
    align-items: center;
  }
}

.view-header--centered {
  justify-content: center;
  text-align: center;
}

.view-header__flash {
  margin-top: 8px;
}
</style>
