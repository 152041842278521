<template>
  <div
    v-if="editor"
    v-bind="wrapperAttrs"
    class="editor"
    :class="{ 'editor--has-error': errorMessage }"
  >
    <editor-modal-provider v-slot="modal" @modalchange="onModalChange">
      <div class="editor__control">
        <editor-menu
          :editor="editor"
          :aria-hidden="modal.isShown"
          :noupload="noupload"
          :inline="inline"
          :disable-vocab="disableVocab"
        />
        <editor-content
          v-bind="eventAttrs"
          class="editor__input"
          :editor="editor"
          :aria-hidden="modal.isShown"
        />
        <link-menu v-if="!inline" :editor="editor" />
        <file-upload-modal v-if="!inline" :editor="editor" />
        <latex-menu :editor="editor" />
        <image-menu :editor="editor" />
        <drop-target-context-menu
          v-if="editor.commands.openDropTargetContextModal"
          :editor="editor"
        />
        <file-menu v-if="!inline" :editor="editor" />
        <image-modal :editor="editor" :noupload="noupload" />
        <variable-menu
          v-if="editor.commands.openVariableModal"
          :editor="editor"
          :variable-context="variableContext"
          :allow-response-variable="allowResponseVariable"
        />
        <expression-menu
          :editor="editor"
          v-if="editor.commands.openExpressionModal"
        />
        <drop-target-modal
          v-if="editor.commands.openDropTargetModal"
          :editor="editor"
        />
        <drop-target-context-modal
          v-if="editor.commands.openDropTargetContextModal"
          :editor="editor"
        />
        <vocab-tooltip-menu
          :editor="editor"
          :variable-context="variableContext"
        />
      </div>
      <span
        v-if="errorMessage || helpText"
        :id="helpId"
        class="help-block editor__help-text"
      >
        {{ errorMessage || helpText }}
      </span>
    </editor-modal-provider>
  </div>
</template>

<script>
import * as Vue from 'vue'
import { useField } from 'vee-validate'
import { isActive } from '@tiptap/core'
import { EditorContent } from '@tiptap/vue-3'
import useEditor from './editor-hook'
import { LinkMenu } from './link'
import { VocabTooltipMenu } from './vocab-tooltip'
import { FileUploadModal, FileMenu } from './fileUpload'
import { LatexMenu } from './latex'
import { VariableMenu } from './variables'
import { ExpressionMenu } from './expression'
import { ImageModal } from './image'
import ImageMenu from './image/ImageMenu'
import EditorModalProvider from './components/EditorModalProvider'
import { EditorMenu } from './menu'
import { getText } from './utils.js'
import DropTargetModal from './drop-target/DropTargetModal'
import DropTargetContextModal from './drop-target/DropTargetContextModal'
import DropTargetContextMenu from './drop-target/DropTargetContextMenu'

let counter = 0

export default {
  name: 'Editor',
  inject: ['$modal'],
  inheritAttrs: false,
  emits: ['input', 'blur', 'update:text'],
  components: {
    EditorContent,
    LinkMenu,
    VocabTooltipMenu,
    LatexMenu,
    FileUploadModal,
    EditorModalProvider,
    EditorMenu,
    ImageModal,
    VariableMenu,
    ExpressionMenu,
    ImageMenu,
    FileMenu,
    DropTargetModal,
    DropTargetContextModal,
    DropTargetContextMenu
  },
  props: {
    text: {
      type: [String, Object],
      default: null
    },
    ytext: {
      type: null,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: null
    },
    helpText: {
      type: String,
      default: null
    },
    name: {
      type: String,
      // Names must be unique between different inputs.
      default: () => `rich-text-input-${counter++}`
    },
    label: {
      type: String,
      default: 'field'
    },
    // Prevent stringifying quill contents to JSON string
    preventStringify: {
      type: Boolean,
      default: false
    },
    variableContext: {
      type: Object,
      default: undefined
    },
    noupload: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    dropTargets: {
      type: Boolean,
      default: false
    },
    dropChoices: {
      type: Array,
      default: () => []
    },
    allowResponseVariable: {
      type: Boolean,
      default: false
    },
    video: {
      type: Boolean,
      default: false
    },
    disableVocab: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, attrs }) {
    const wrapperAttrs = Vue.computed(() => {
      const { style, class: klass } = attrs
      return { style, class: klass }
    })
    const eventAttrs = Vue.computed(() =>
      Object.fromEntries(
        Object.entries(attrs).filter(([key]) => key.match(/^on[A-Z]/))
      )
    )
    const editor = useEditor({
      text: Vue.toRef(props, 'text'),
      readonly: Vue.toRef(props, 'readonly'),
      editorAttributes: Vue.computed(() => {
        const { style, class: _, ..._attrs } = attrs
        const filteredAttrs = Object.fromEntries(
          Object.entries(_attrs).filter(([key]) => !key.match(/^on[A-Z]/))
        )
        filteredAttrs.role = 'textbox'
        return filteredAttrs
      }),
      variableContext: Vue.toRef(props, 'variableContext'),
      fragment: Vue.toRef(props, 'ytext'),
      dropTargets: props.dropTargets,
      dropChoices: Vue.toRef(props, 'dropChoices'),
      inline: Vue.toRef(props, 'inline'),
      disableVocab: Vue.toRef(props, 'disableVocab'),
      noupload: Vue.toRef(props, 'noupload'),
      video: Vue.toRef(props, 'video'),
      onUpdate({ editor }) {
        const json = editor.getJSON()
        const jsonStr = JSON.stringify(json)
        handleChange(editor.getText())
        const value = props.preventStringify ? json : jsonStr

        if (editor.isEmpty) {
          //if the editor is empty, sending an empty string helps to be able to check downstream if the value is empty
          emit('update:text', '')
        } else {
          emit('update:text', value)
        }
        emit('input', value)
      },
      onReset({ editor }) {
        const text = editor.getText()
        resetField({
          value: text,
          initialValue: text
        })
      }
    })

    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField,
      meta
    } = useField(Vue.toRef(props, 'name'), Vue.toRef(props, 'rules'), {
      initialValue: getText(props.text, props.variableContext),
      label: Vue.toRef(props, 'label'),
      validateOnValueUpdate: false,
      syncVModel: false
    })

    const onModalChange = name =>
      editor.value.view.dom.setAttribute('tabindex', name ? -1 : 0)

    const helpId = Vue.computed(() => `${props.name.replace(' ', '-')}-help`)

    return {
      editor,
      wrapperAttrs,
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField,
      meta,
      isActive,
      onModalChange,
      helpId,
      eventAttrs
    }
  }
}
</script>

<style lang="scss" scoped>
.editor {
  &__control {
    background: #fff;
    border-radius: $border-radius-base;
    border: 1px solid #ccc;
    position: relative;
    display: flex;
    flex-direction: column;

    &:focus-within {
      box-shadow: 0 0 5px rgb(0 0 0 / 50%);
    }

    .editor--has-error & {
      border-color: $color-error;

      &:focus-within {
        box-shadow:
          inset 0 1px 1px rgb(0 0 0 / 8%),
          0 0 6px #ce8483;
      }
    }
  }

  &__input {
    flex-grow: 1;
    font-family: $font-family-sans-serif;
    line-height: $line-height-base;
    font-size: $student-response-font-size-base;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

    & > :deep([contenteditable]) {
      padding: 10px 12px;
      height: 124px;
      min-height: 124px;
      resize: vertical;
      overflow: auto;

      &:focus {
        outline: none;
      }

      p {
        margin: 0;
      }

      ul,
      ol {
        padding-left: 32px;
      }

      ul {
        list-style-type: disc;
      }

      ol {
        list-style-type: decimal;
      }
    }
  }

  &__help-text {
    .editor--has-error & {
      color: $color-error;
    }
  }
}
</style>
