<template>
  <view-container normal>
    <view-header>
      <view-title>Schoology LTI Registration</view-title>
    </view-header>
    <loading-container :loading="loadingConfig">
      <section>
        <collapse-panel
          title="Step 1: Configure Pivot Interactives in Schoology"
        >
          <form-group>
            <strong>
              You must be an admin in Schoology to perform this step.
            </strong>
            See our detailed instructions
            <hyper-link :to="{ name: 'lti_schoology_registration_article' }"
              >here</hyper-link
            >, then return to this page when you've completed them.
          </form-group>
        </collapse-panel>
      </section>
      <section>
        <collapse-panel
          title="Step 2: Configure Schoology in Pivot Interactives"
        >
          <async-form @submit="register" persist>
            <ol>
              <li>
                <form-group>
                  Enter the Deployment ID you copied from Schoology in the field
                  below:
                </form-group>
                <form-group>
                  <text-input
                    v-model="platformData.clientId"
                    aria-label="Schoology Deployment ID"
                    placeholder="Example: 5844286125-5844049856"
                    label="client ID"
                    rules="required"
                  />
                </form-group>
              </li>
              <li>
                <form-group>
                  Enter the domain name you use to access Schoology in the field
                  below. Most often, this will be something like
                  <strong>your-institution.schoology.com</strong>.
                </form-group>
                <form-group>
                  <text-input
                    v-model="platformData.domain"
                    aria-label="Schoology Domain Name"
                    placeholder="Example: your-school.schoology.com"
                    label="domain name"
                    :rules="{
                      required: true,
                      regex: /^((\w|\d|-)+\.)+(\w|\d|-)+(:\d+)?$/
                    }"
                  />
                </form-group>
              </li>
              <li>
                <form-group>
                  When ready, click the <strong>Register</strong> button to
                  finish registering Schoology.
                </form-group>
                <form-group>
                  <submit-button>
                    <template #default>Register</template>
                    <template #submitting>Configuring</template>
                    <template #submitted>Success</template>
                  </submit-button>
                </form-group>
              </li>
            </ol>
          </async-form>
        </collapse-panel>
      </section>
    </loading-container>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'

export default {
  name: 'LtiPlatformRegistrationSchoologyView',
  data() {
    return {
      loadingConfig: true,
      config: {},
      platformData: {
        clientId: '',
        domain: ''
      }
    }
  },
  computed: {
    configString() {
      return JSON.stringify(this.config)
    }
  },
  methods: {
    async register(e) {
      try {
        const protocol = PI_IS_LOCAL ? 'http://' : 'https://'
        const { clientId, domain } = this.platformData
        await client.lti.createPlatform({
          name: 'Schoology',
          issuer: `https://${domain}`,
          clientId,
          oidcUrl: `${protocol}lti-service.svc.schoology.com/lti-service/authorize-redirect`,
          oauthUrl: `${protocol}lti-service.svc.schoology.com/lti-service/access-token`,
          oauthAud: `${protocol}lti-service.svc.schoology.com/lti-service/access-token`,
          jwkUrl: `${protocol}lti-service.svc.schoology.com/lti-service/.well-known/jwks`
        })
        this.$success('Schoology successfully configured in Pivot.')
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    async loadConfig() {
      this.loadingConfig = true
      try {
        const config = await client.lti.getConfig({ platform: 'schoology' })
        this.config = config
      } finally {
        this.loadingConfig = false
      }
    }
  },
  async created() {
    await this.loadConfig()
  }
}
</script>
