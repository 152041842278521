<template>
  <view-container>
    <view-header>
      <view-title>Manage Invitations</view-title>
      <view-header-actions>
        <form class="form search-form" @submit.prevent="() => searchUsers()">
          <text-input v-model="searchText" placeholder="Name or email" />
          <form-button type="submit"> Search </form-button>
        </form>
      </view-header-actions>
    </view-header>
    <loading-container :loading="loading">
      <paginated-list
        class="row"
        v-bind="{ page, items, limit, count }"
        @loadpage="loadPage"
      >
        <sortable-list-column
          title="Email"
          property="email"
          :sort="sort['email']"
          @change="sort => changeSort('email', sort)"
        />
        <sortable-list-column
          title="Invitation Type"
          property="inviteType"
          :sort="sort['inviteType']"
          @change="sort => changeSort('inviteType', sort)"
        />
        <sortable-list-column
          v-slot="{ createdAt }"
          title="Created At"
          :sort="sort['createdAt']"
          @change="sort => changeSort('createdAt', sort)"
        >
          {{ $format.date(createdAt, 'MM/dd/yyyy') }}
        </sortable-list-column>
        <list-column v-slot="{ token }" title="Actions">
          <form-button link @click="copyInvitationUrl(token)">
            Copy URL
          </form-button>
          |
          <form-button link @click="resendInvitation(token)">
            Resend
          </form-button>
        </list-column>
      </paginated-list>
    </loading-container>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client.ts'
export default {
  name: 'ManageInvitationsView',
  inject: ['$modal'],
  props: {
    initialSearchText: {
      type: String,
      required: false
    },
    initialSort: {
      type: Object,
      required: false
    },
    initialPage: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      sort: this.initialSort || {},
      searchText: this.initialSearchText || '',
      items: [],
      loading: true,
      page: this.initialPage || 1,
      count: 0,
      limit: 20
    }
  },
  methods: {
    async searchUsers(page = 1) {
      await this.loadPage({ page })
    },
    async changeSort(property, sort) {
      if (!sort) {
        delete this.sort[property]
      } else {
        this.sort[property] = sort
      }

      await this.searchUsers()
    },
    async loadPage({ page }) {
      this.loading = true
      const response = await client.invitations.search({
        sort: this.sort,
        limit: this.limit,
        page,
        q: this.searchText
      })

      this.items = response.items
      this.page = page
      this.count = response.totalCount

      this.updateURL()
      this.loading = false
    },
    updateURL() {
      const params = []

      if (Object.keys(this.sort).length > 0) {
        for (const prop in this.sort) {
          params.push(`sortBy=${prop}`)
          params.push(`sort=${this.sort[prop]}`)
        }
      }

      if (this.searchText) {
        params.push(`search=${this.searchText}`)
      }

      params.push(`page=${this.page}`)

      const { protocol, host, pathname } = window.location
      const newUrl = `${protocol}//${host}${pathname}?${params.join('&')}`
      window.history.replaceState({ path: newUrl }, '', newUrl)
    },
    copyInvitationUrl(token) {
      const { protocol, host } = window.location
      const signupUrl = `${protocol}//${host}/signup/${token}`

      window.navigator.clipboard.writeText(signupUrl)

      this.$success('Copied signup URL to clipboard')
    },
    async resendInvitation(token) {
      await client.invitations.resend(token)
      this.$success('Successfully resent invitation')
    }
  },
  async created() {
    await this.searchUsers(this.page)
  }
}
</script>

<style lang="scss" scoped>
.search-form {
  display: flex;
  align-items: center;
  .form-button {
    margin-left: 15px;
  }
}
</style>
