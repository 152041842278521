<template>
  <modal class="data-grid-edit" @submit="onSubmit">
    <modal-header>Calculation Settings</modal-header>
    <modal-body>
      <div class="data-grid-edit__row">
        <form-group>
          <form-label>Calculation Type</form-label>
          <select-field
            v-model="localCalculation"
            aria-describedby="select column data formatting option"
          >
            <option value="total">Total</option>
            <option value="median">Median</option>
            <option value="stdev">Standard Deviation</option>
            <option value="mean">Mean</option>
          </select-field>
        </form-group>
        <form-group>
          <form-label>Number Display Format</form-label>
          <select-field
            v-model="localFormat"
            aria-describedby="select column data formatting option"
          >
            <option value="scientific">Scientific Notation</option>
            <option value="decimals">Decimal Places</option>
            <option value="sigfigs">Significant Figures</option>
          </select-field>
        </form-group>

        <form-group v-if="localFormat === 'decimals'">
          <form-label>Number of Decimal Places</form-label>
          <select-field
            v-model="localPrecision"
            aria-describedby="select number of decimals places to format column data"
          >
            <option v-for="n in 11" :key="n" :value="n - 1">
              {{ n - 1 }}
            </option>
          </select-field>
        </form-group>
        <form-group v-if="localFormat === 'sigfigs'">
          <form-label>Number of Significant Figures</form-label>
          <select-field
            v-model="localPrecision"
            aria-describedby="select number of significant figures to format column data"
          >
            <option v-for="n in 10" :key="n" :value="n">
              {{ n }}
            </option>
          </select-field>
        </form-group>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Confirm</modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import cloneDeep from 'lodash/cloneDeep'
import * as Y from 'yjs'
import { computed, ref, inject, watch, onBeforeMount } from 'vue'
import math from 'src/setup/math'
import { processExpression } from '@vue/compiler-core'
const changingType = ref(false)
const dropdown = ref(null)
const dropdownOpen = ref(false)
const props = defineProps({
  calculation: {
    type: Y.Map,
    required: true
  }
})
const localPrecision = ref()
const localCalculation = ref()
const localFormat = ref()
const displayFormat = computed(() => props.calculation.get('format'))
const calculationType = computed(() => props.calculation.get('type'))
const precision = computed(() => props.calculation.get('precision'))

function onSubmit(e) {
  e.done({
    type: localCalculation.value,
    precision: localPrecision.value,
    format: localFormat.value
  })
}
onBeforeMount(async () => {
  localCalculation.value = calculationType.value
  localPrecision.value = precision.value
  localFormat.value = displayFormat.value
})
</script>
<style scoped lang="scss">
.data-grid-edit {
  max-width: 380px;
}
</style>
