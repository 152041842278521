<template>
  <div class="activity-card-actions">
    <button-link
      :to="{ name: 'preview_activity', params: { id: activity.id } }"
    >
      Preview
    </button-link>
    <form-button tertiary @click="$emit('copy', activity)">
      Copy to Library
    </form-button>
  </div>
</template>

<script>
export default {
  name: 'ActivityCardEditAction',
  inject: ['activityCard', '$modal'],
  emits: ['copy'],

  computed: {
    activity() {
      return this.activityCard.activity
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-card-actions {
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 8px;
  }

  &__edit {
    padding: 4px 0 0 0;
  }

  &__spacer {
    flex-grow: 1;
  }
}
</style>
