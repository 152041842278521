<template>
  <view-container>
    <view-header>
      <view-title>Whoops!</view-title>
    </view-header>
    <h4>We couldn't find what you were looking for...</h4>
  </view-container>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
