<template>
  <tbody class="gradable-component">
    <collapse-provider
      renderless
      :collapsed="isCollapsed"
      @update:collapsed="updateCollapsed"
    >
      <tr
        :class="`list-item-row__grading-status ${
          gradingPercentage === 100
            ? 'complete'
            : gradingPercentage > 0
            ? 'incomplete'
            : 'none'
        }`"
      >
        <td>
          <form-group v-if="isGradable">
            <checkbox
              v-model="isSelected"
              :name="'question-' + component._id"
              :disabled="disabled"
              aria-label="Select Question"
            />
          </form-group>
        </td>
        <td class="question-cell">
          <div class="question-text">
            <collapse-toggle class="question-toggle">
              <collapse-icon icon="caret" class="collapse-icon" />
              <span v-if="component.questionNumber"
                >{{ component.questionNumber }}.</span
              >
              <strong
                >{{ humanizeComponentType(component.componentType) }}:</strong
              >&nbsp;
              <span v-if="isCollapsed" class="truncated-prompt">{{
                questionText
              }}</span>
            </collapse-toggle>
          </div>
        </td>
        <td
          :class="`text-center grading-status ${
            gradingPercentage === 100 ? 'complete' : ''
          }`"
        >
          <template v-if="isGradable">
            <span v-if="component.autograde"><em>Autograded</em></span>
            <span v-if="!component.autograde" class="grading-pct"
              >{{ gradingPercentage }} %</span
            >
            <progress-circle
              v-if="!component.autograde"
              class="grading-progress"
              :progress="gradingPercentage / 100"
              :completed="gradingPercentage === 100"
              aria-hidden="true"
            >
            </progress-circle>
          </template>
        </td>
        <td class="text-center">
          <form-group v-if="hasPoints">
            <number-input
              v-if="!component.autograde"
              v-model="defaultScore"
              label="default score"
              class="score-field default-score"
              :rules="`min_value:0|max_value:${component.pointValue}`"
              aria-label="Default Score"
              :disabled="disabled || !isSelected"
            >
            </number-input>
            {{
              `${component.autograde ? component.pointValue : ''} / ${
                component.pointValue
              }`
            }}
          </form-group>
        </td>
      </tr>

      <tr :id="'component-' + component._id" :class="isCollapsed ? 'hide' : ''">
        <td
          colspan="4"
          :class="
            component.componentType === 'EmbeddedInstance' ? 'pivot-td' : ''
          "
        >
          <collapse-content class="question-content">
            <template v-if="component.componentType === 'EmbeddedInstance'">
              <pivot-instance class="col-sm-6" :settings="instanceSettings">
              </pivot-instance>
            </template>
            <template v-else-if="component.componentType === 'IFrame'">
              <embedded-iframe
                class="col-sm-6"
                :src="component.url"
                :config="component.config"
              >
              </embedded-iframe>
            </template>
            <template v-else-if="component.componentType === 'PhetIOSim'">
              <legacy-phet-io-simulation
                class="col-sm-6"
                :config="component.config"
                :variables="variableContext.variables"
              >
              </legacy-phet-io-simulation>
            </template>
            <template
              v-else-if="component.componentType === 'PhetIOSimulation'"
            >
              <phet-io-simulation
                class="col-sm-6"
                :config="component.config"
                :variables="variableContext.variables"
              >
              </phet-io-simulation>
            </template>
            <div v-else>
              <editor-content
                :text="component.text"
                :variable-context="variableContext"
                variable-names
              />
            </div>
          </collapse-content>
        </td>
      </tr>
    </collapse-provider>
  </tbody>
</template>

<script setup>
import PivotInstance from 'src/shared/components/PivotInstance'
import LegacyPhetIoSimulation from 'src/shared/components/LegacyPhetIoSimulation'
import PhetIoSimulation from 'src/shared/components/PhetIoSimulation'
import { humanizeComponentType } from 'src/setup/mixins/humanizeComponentType'
import Collapse from 'src/shared/components/Collapse'
import { computed } from 'vue'
import { getText } from 'src/shared/components/editor/utils'

const emit = defineEmits([
  'update:gradableComponents',
  'update:is-component-collapsed'
])
const props = defineProps({
  component: {
    required: true
  },
  isCollapsed: {
    type: Boolean,
    default: false
  },
  gradableComponents: {
    default: []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  variableContext: {
    type: Object,
    required: true
  }
})

const gradableComponent = computed(
  () =>
    props.gradableComponents.find(({ id }) => id === props.component._id) || {}
)
const gradingPercentage = computed(() => {
  if (props.component.autograde) return 100
  return Math.floor(props.component.gradingPct * 100)
})

const questionText = computed(() => {
  if (props.component.componentType === 'IFrame') {
    return props.component.url ? `${props.component.url}` : ''
  } else if (props.component.componentType === 'EmbeddedInstance') {
    return props.component.title ?? ''
  }
  return props.component.text
    ? getText(props.component.text, props.variableContext)
    : ''
})

const isSelected = computed({
  get: () => {
    return !!gradableComponent.value.id
  },
  set: isSelected => {
    if (!props.disabled) {
      const newComponents = props.gradableComponents.filter(
        ({ id }) => id !== props.component._id
      )
      if (isSelected) {
        newComponents.push({
          id: props.component._id,
          defaultScore: props.component.pointValue
        })
      }
      emit('update:gradableComponents', newComponents)
    }
  }
})
const defaultScore = computed({
  get: () => {
    return gradableComponent.value.defaultScore
  },
  set: defaultScore => {
    if (!props.disabled && isSelected.value) {
      const parsedScore = parseInt(defaultScore)
      const newComponents = props.gradableComponents.filter(
        ({ id }) => id !== props.component._id
      )
      newComponents.push({
        id: props.component._id,
        defaultScore: isNaN(parsedScore) ? undefined : parsedScore
      })
      emit('update:gradableComponents', newComponents)
    }
  }
})
const hasPoints = computed(
  () => props.component.pointValue && props.component.pointValue > 0
)

const isGradable = computed(() => {
  return ![
    'GeneralNote',
    'GeneralInstruction',
    'EmbeddedInstance',
    'IFrame',
    'PhetIOSim',
    'PhetIOSimulation'
  ].includes(props.component.componentType)
})
const instanceSettings = computed(() => {
  return JSON.parse(
    JSON.stringify(
      this.component.instance ? this.component.instance.settings : {}
    )
  )
})

const updateCollapsed = collapsed => {
  emit('update:is-component-collapsed', collapsed)
}
</script>

<style lang="scss" scoped>
$light-grey: #ccc;
$solid-gray: #c3c3c3;

$dashed-bottom-border: 1px dashed $light-grey;
$solid-bottom-border: 1px solid $solid-gray;

.default-score {
  width: 70px;
  display: inline-block;
}

.list-item-row__grading-status {
  &.complete {
    background-color: rgba(52, 189, 189, 0.2);
    font-weight: bold;
  }
  &.incomplete {
    background-color: rgba(239, 204, 79, 0.2);
    font-weight: bold;
  }
  &.none {
    background: transparent;
    font-weight: bold;
  }
}

.grading-status {
  display: flex;
  justify-content: center;
  align-items: center;
  & .grading-pct {
    padding-right: 15px;
  }
  &.complete {
    color: #158484;
    font-weight: 800;
  }
}

tbody + tbody {
  border-top: $dashed-bottom-border;
  border-bottom: $dashed-bottom-border;
}

.hide {
  visibility: hidden;
  height: 0;
}

tbody {
  & tr {
    & td {
      vertical-align: middle;
      height: 50px;
      border: none;

      .form-group {
        margin-bottom: 0;
      }
    }

    & td.pivot-td {
      padding-top: 0;
    }

    &.spacing-row {
      & td {
        height: 1px;
        padding: 1px;
      }
    }
  }
}

.collapse-icon {
  padding-right: 5px;
}

.question-cell {
  max-width: 650px;
  .question-text {
    width: 100%;
  }

  .question-toggle {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.question-content {
  padding-left: 60px;
}
</style>
