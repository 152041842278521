<template>
  <slot />
</template>

<script setup>
import { provide, reactive, ref, markRaw } from 'vue'
import { useRouter } from 'vue-router'

const DEFAULT_TIMEOUT = 8000

const messages = ref([])

function remove(message) {
  messages.value = messages.value.filter(m => m !== message)
}

function add({ level, route, text, timeout = true, onClick }) {
  const message = markRaw({
    level,
    text,
    route,
    onClick
  })
  messages.value.push(message)
  if (level !== 'error' && timeout) {
    setTimeout(
      () => remove(message),
      typeof timeout === 'number' ? timeout : DEFAULT_TIMEOUT
    )
  }
}

const router = useRouter()
router.isReady().then(() => {
  const route = router.currentRoute.value
  if (route.query.flash) {
    const { success, error } = route.query.flash
    if (success) {
      add({ level: 'success', text: success })
    }
    if (error) {
      add({ level: 'error', text: error })
    }
    const { flash, ...newQuery } = route.query
    router.replace({
      ...route,
      query: newQuery
    })
  }
})

provide(
  '_flash',
  reactive({
    messages,
    add,
    remove,
    clear(level) {
      if (level) {
        messages.value = messages.value.filter(m => m.level !== level)
      } else {
        messages.value = []
      }
    }
  })
)
</script>
