import InstructorDashboardView from './views/InstructorDashboardView'

export default store => [
  {
    name: 'teacher_dashboard',
    path: '/dashboard',
    component: InstructorDashboardView,
    meta: { title: 'Dashboard', requiresAuth: true, roles: ['teacher'] }
  }
]
