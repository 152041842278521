<template>
  <span>
    <span ref="root" />
    <span class="sr-only">{{ description }}</span>
  </span>
</template>

<script setup lang="ts">
import { watchEffect, ref, nextTick, computed } from 'vue'
import katex from 'katex'
import useLatexToText from '../../hooks/mathjax'

interface Props {
  latex: string
  html?: boolean
}

const emit = defineEmits<{
  render: [HTMLSpanElement]
}>()
const props = withDefaults(defineProps<Props>(), {
  html: false
})

const description = ref('')
const { toText } = useLatexToText()
toText(props.latex).then((text: string) => {
  description.value = text
})

const root = ref<HTMLSpanElement>()
function render() {
  try {
    if (root.value) {
      katex.render(props.latex, root.value, {
        throwOnError: false,
        trust: props.html,
        strict: false
      })
      emit('render', root.value)
    }
  } catch (error) {}
}

watchEffect(() => {
  if (props.latex) {
    if (root.value) {
      render()
    } else {
      nextTick(render)
    }
  } else {
    if (root.value) {
      root.value.innerHTML = ''
    }
  }
})
</script>
