<template>
  <div v-bind="wrapperAttrs">
    <label
      class="check-box response-style"
    >
      <input
        v-if="trueValue || falseValue"
        v-bind="inputAttrs"
        v-model="model"
        type="checkbox"
        :value="value"
        :disabled="disabled || null"
        :true-value="trueValue"
        :false-value="falseValue"
        :name="name"
        @blur="this.choiceInput?.value?.onBlur"
      />
      <input
        v-else
        v-bind="inputAttrs"
        v-model="model"
        type="checkbox"
        :value="value"
        :indeterminate="indeterminate"
        :disabled="disabled || null"
        :name="name"
      />
      <span class="checkbox-indicator hidden-print">
        <icon icon="check" class="checkbox-icon-check" size="lg" />
        <div class="checkbox-icon-dash" />
      </span>
      <div class="checkbox-value">
        <slot />
      </div>
    </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Checkbox',
  inject: {
    choiceInput: { default: null }
  },
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    value: {},
    modelValue: {},
    indeterminate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autosave: {
      type: Boolean,
      default: false
    },
    componentId: {
      type: String
    },
    trueValue: {},
    falseValue: {}
  },
  computed: {
    name() {
      return this.choiceInput ? this.choiceInput.name : undefined
    },
    model: {
      get() {
        return this.choiceInput ? this.choiceInput.value : this.modelValue
      },
      set(value) {
        if (this.choiceInput) {
          this.choiceInput.onChange(value)
        } else {
          this.$emit('update:modelValue', value)
        }
        if (this.autosave)
          this.autosaveStudentResponse({ componentId: this.componentId })
      }
    },
    wrapperAttrs() {
      const { style, class: klass, onClick } = this.$attrs
      return { style, class: klass, onClick }
    },
    inputAttrs() {
      const { style, class: _, onClick, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    ...mapActions(['autosaveStudentResponse'])
  }
}
</script>

<style lang="scss" scoped>
.check-box {
  display: flex;
  align-items: flex-start;
  margin: 8px 0;
}

.inline {
  display: inline-flex;
}

.checkbox-icon-check,
.checkbox-icon-dash {
  display: none;
}

.checkbox-icon-dash {
  background-color: currentColor;
  height: 3px;
  border-radius: 4px;
  width: 14px;
}

input {
  opacity: -1;
  position: absolute;

  &:checked + .checkbox-indicator {
    .checkbox-icon-check {
      display: block;
    }
  }

  &:indeterminate + .checkbox-indicator {
    .checkbox-icon-dash {
      display: block;
    }
  }

  &:focus + .checkbox-indicator {
    box-shadow: 0 0 5px $silver;
  }

  &:disabled + .checkbox-indicator {
    background-color: #eeeeee;

    &:after {
      color: #666666;
    }
  }
}

.checkbox-indicator {
  width: 24px;
  height: 24px;
  border-radius: $border-radius-base;
  background-color: #ffffff;
  border: $input-border solid 1px;
  display: inline-block;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $plum;
}

.checkbox-value {
  font-weight: 400;
  margin: 0 0 0 8px;
  flex-grow: 1;
  min-width: 0px;
}


@media print {
  input[type='checkbox'] {
    opacity: initial;
    position: initial;
    margin: 0;
  }
}
</style>
