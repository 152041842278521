<template>
  <score-field
    v-if="isScorable && showScore"
    v-model="score"
    class="question-score"
    :max="component.pointValue"
    :disabled="readonly"
  />
</template>

<script>
import ScoreField from 'src/shared/components/ScoreField'

export default {
  name: 'ActivityQuestionScore',
  inject: ['inherited'],
  components: {
    ScoreField
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    isScorable() {
      return this.component.pointValue && this.component.pointValue > 0
    },
    showScore() {
      return this.inherited?.response?.assignment?.showScore
    },
    score: {
      get() {
        return this.inherited.componentResponse.score
      },
      set(score) {
        this.inherited.updateResponse({ score })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.question-score {
  margin-top: 0;
  margin-bottom: 8px;
}
</style>
