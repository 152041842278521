<template>
  <div
    class="card"
    :class="{
      'card--selected': selected
    }"
    data-testid="card"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    scrollIntoView() {
      this.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    },
    focus() {
      this.$el.focus()
    }
  }
}
</script>

<style lang="scss">
.card {
  width: 100%;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  margin: 8px 0;

  &--selected {
    background-color: $yellow;
  }
}
</style>
