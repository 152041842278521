<template>
  <div class="add-activity-bar">
    <div class="add-component-box-btn">
      <label> Add Component </label>
      <component-type
        ref="componentTypeInput"
        v-model="componentType"
        class="component-dropdown"
        data-queryid="select-component"
      />
      <form-button
        tertiary
        class="add-component-btn"
        aria-label="Add Component"
        @click="emit('addComponent')"
      >
        <icon icon="plus" />
      </form-button>
    </div>
    <div v-if="humanizedComponentName !== null" class="clipboard-btn">
      <span>or</span>
      <form-button
        secondary
        aria-label="Paste from clipboard"
        @click="emit('pasteFromClipboard')"
      >
        {{ humanizedComponentName }}
      </form-button>
    </div>
    <template v-if="canAddSplit">
      <span class="px-5">or</span>
      <div class="add-component-box-btn">
        <form-button
          secondary
          aria-label="Add Split View"
          @click="emit('addSplitView')"
        >
          Add Split View
        </form-button>
      </div>
    </template>
    <form-button
      tertiary
      v-if="!isLastComponent"
      class="remove-addComponentBar-btn"
      aria-label="Remove Add Component Bar"
      @click="emit('removeAddComponentBar')"
    >
      <icon icon="close" />
    </form-button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ComponentType from './ComponentType.vue'

const emit = defineEmits([
  'addComponent',
  'removeAddComponentBar',
  'pasteFromClipboard',
  'update:modelValue',
  'addSplitView'
])
const props = defineProps({
  modelValue: {
    type: String
  },
  humanizedComponentName: {
    type: String
  },
  isLastComponent: {
    type: Boolean,
    default: true
  },
  canAddSplit: {
    type: Boolean,
    default: false
  }
})
const componentType = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  }
})
</script>

<style lang="scss" scoped>
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.add-activity-bar {
  display: flex;
  align-items: center;

  & .add-component-box-btn {
    display: flex;
    align-items: center;
    flex-grow: 1;

    & label {
      margin-right: 10px;
      font-weight: 600;
      color: $teal;
      margin-bottom: 0;
    }

    & .component-dropdown {
      margin-right: 10px;
      width: 220px;
    }
  }
}
.remove-addComponentBar-btn {
  color: $color-error !important;
  border: none !important;
}

.clipboard-btn {
  & span {
    margin-right: 10px;
  }
}
</style>
