<template>
  <label class="control-label form-label">
    <slot />
  </label>
</template>

<script>
export default {
  name: 'FormLabel'
}
</script>

<style lang="scss">
.form-label {
  padding-top: 5px !important; // !important only necessary because of bootstrap
}
</style>
