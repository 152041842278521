<template>
  <modal @submit="submit">
    <modal-header>Add Grader</modal-header>
    <modal-body>
      <div>
        Graders will be able to review and grade assignments, but they cannot
        join classes as a student
      </div>
      <form-group>
        <form-label for="email"> Email Address </form-label>
        <div>
          <text-input
            id="email"
            v-model="email"
            label="email address"
            rules="required|email"
            data-focus
          />
        </div>
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Add</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import client from 'src/shared/api-client'
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

export default {
  name: 'AddGraderModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  props: {
    classId: {
      type: String,
      requried: true
    }
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    async submit(e) {
      try {
        await client.classes.addGrader({
          classId: this.classId,
          email: this.email
        })
        this.$success('Grader added successfully!')
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    }
  }
}
</script>
