<template>
  <modal @submit="submit">
    <modal-header>New Library</modal-header>
    <modal-body>
      <form-group>
        <form-label for="library-name"> Name </form-label>
        <text-input
          id="library-name"
          v-model="name"
          rules="required"
          label="name"
          data-focus
        />
      </form-group>
      <form-group>
        <form-label for="library-description"> Description </form-label>
        <multiline-text-input
          id="library-description"
          v-model="description"
          label="description"
        />
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Create</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import client from 'src/shared/api-client.ts'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

export default {
  name: 'NewLibraryModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  data() {
    return {
      name: '',
      description: ''
    }
  },
  methods: {
    async submit(e) {
      try {
        const libraryId = await client.libraries.create({
          name: this.name,
          description: this.description
        })
        e.done({ libraryId })
      } catch (error) {
        e.done(false)
        throw error
      }
    }
  }
}
</script>
