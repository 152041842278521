<template>
  <editor-bubble-menu
    plugin-key="file-menu"
    :editor="editor"
    :tippy-options="{ placement: 'bottom' }"
    :should-show="shouldShow"
  >
    <editor-menu-button
      tag="a"
      :href="url"
      target="_blank"
      label="Open in New Window"
      icon="external-link"
    />
    <editor-menu-button label="Unlink" icon="unlink" @click="unlink" />
  </editor-bubble-menu>
</template>

<script>
import * as Vue from 'vue'
import { isActive } from '@tiptap/core'
import EditorBubbleMenu from '../components/EditorBubbleMenu'
import EditorMenuButton from '../components/EditorMenuButton'

export default {
  name: 'FileMenu',
  components: { EditorBubbleMenu, EditorMenuButton },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const shouldShow = ({ state }) => {
      return isActive(state, 'file')
    }

    const unlink = () => {
      props.editor.chain().focus().unsetFile().run()
    }
    // Sync the focused node's href with the menu.
    const url = Vue.ref(null)
    const onSelectionUpdate = () => {
      const href = props.editor.getAttributes('file').href
      url.value = href ? `//${PI_API_HOST}${href}` : ''
    }
    props.editor.on('selectionUpdate', onSelectionUpdate)
    Vue.onUnmounted(() => {
      props.editor.off('selectionUpdate', onSelectionUpdate)
    })

    return {
      url,
      shouldShow,
      unlink
    }
  }
}
</script>

<style lang="scss" scoped>
.link-menu {
  &__modal {
    width: 300px;
  }

  &__form {
    margin: 0 !important;
  }

  &__error {
    margin: 16px 0 0 0;
    color: $color-error;
  }
}
</style>
