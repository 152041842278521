<template>
  <div class="section-component">
    <div v-if="canHide && !previousQuestionCorrect">
      <div class="question__number">{{ component.questionNumber }}.</div>

      <p class="hide-question">
        This question is hidden until you answer the previous question<span
          v-if="gradeAfterSubmit"
        >
          correctly</span
        >
      </p>
    </div>
    <div v-else>
      <slot
        :section="section"
        :canRespond="canRespond"
        :onSubmit="onSubmit"
        :onComplete="onComplete"
        :canSubmit="canSubmit && needsSubmission"
        @canSubmitChange="onCanSubmitChange"
      />
    </div>
  </div>
</template>

<script>
import * as Vue from 'vue'
import * as Y from 'yjs'
import { useStore } from 'vuex'
export default {
  name: 'ActivitySectionComponentRealtime',
  inject: ['inherited'],
  provide() {
    return {
      inherited: Vue.computed(() => ({
        ...this.inherited,
        component: this.component,
        componentResponse: this.componentResponse,
        updateResponse: this.updateResponse,
        canRespond: this.canRespond,
        studentResponsePopoverMessage: this.studentResponsePopoverMessage,
        isGrading: this.isGrading,
        canSubmit: this.canSubmit,
        prevResponse: this.prevResponse
      }))
    }
  },
  emits: ['change', 'submit'],
  props: {
    component: {
      type: Object,
      required: true
    },
    componentResponseMap: {
      type: Y.Map,
      required: true
    }
  },
  setup() {
    const store = useStore()
    const isReopen = store.getters['features/isEnabled']('reopen')
    return { isReopen }
  },
  data() {
    return {
      isGrading: false,
      canSubmit: true,
      prevResponse: {},
      needsSubmission: false
    }
  },
  created() {
    this.prevResponse = { ...this.componentResponse }
  },
  computed: {
    response() {
      return this.inherited.response
    },
    section() {
      return this.inherited.section
    },
    isOverdue() {
      return this.inherited.isOverdue
    },
    isSubmitted() {
      return this.inherited.isSubmitted
    },
    isReopened() {
      return this.inherited.isReopened
    },
    dependenciesSatisfied() {
      return (
        this.component.componentType !== 'NumericalQuestion' ||
        this.component.dependenciesSatisfied ||
        this.componentResponse.dependenciesSatisfied
      )
    },
    gradeAfterSubmit() {
      if (this.isGrading) return true
      return (
        this.inherited?.response?.assignment?.studentFeedbackTiming ===
        'after-question-submit'
      )
    },
    previousSubmittableQuestion() {
      return this.inherited.prevSubmittableComponentMap[this.component._id]
    },
    isFirstQuestion() {
      return (
        this.inherited.section.index === 0 &&
        this.component.questionNumber === 1
      )
    },
    canHide() {
      return (
        (this.component.hideContent ||
          (this.inherited.response?.assignment?.progressiveDisclosure &&
            !this.isFirstQuestion)) &&
        !!this.previousSubmittableQuestion
      )
    },
    previousQuestionCorrect() {
      if (this.previousSubmittableQuestion) {
        const response = this.inherited.response.responses.find(
          r => r.component === this.previousSubmittableQuestion
        )
        return response?.isCorrect ?? false
      } else {
        return false
      }
    },

    canRespond() {
      return (
        (!this.isSubmitted || this.isReopened) &&
        (!this.isOverdue || this.isReopened) &&
        !this.section.isLocked &&
        this.section.isAvailable &&
        this.dependenciesSatisfied
      )
    },
    studentResponsePopoverMessage() {
      if (this.isReopen && this.isSubmitted && !this.isOverdue) {
        return 'Please reopen your assignment to continue working.'
      }
      if (this.isSubmitted) {
        return 'This assignment has been submitted and responses cannot be modified.'
      } else if (this.isOverdue) {
        return 'This assignment is overdue and responses cannot be modified.'
      } else if (this.section.isLocked) {
        return 'This section is locked: response cannot be modified.'
      } else if (!this.section.isAvailable) {
        return 'This section is disabled because you must complete and lock a previous section.'
      }
      return null
    },
    componentResponse() {
      return (
        this.response.responses.find(
          ({ component }) => component === this.component._id
        ) || { component: this.component._id }
      )
    }
  },
  methods: {
    updateResponse(data, isDirty = true) {
      this.$emit('change', {
        response: {
          ...this.componentResponse,
          ...data
        },
        isDirty
      })
    },
    async onComplete() {
      this.updateResponse(
        {
          isComplete: true
        },
        false
      )
      this.canSubmit = false
    },
    async onSubmit() {
      this.componentResponseMap.set(
        'submittedAnswer',
        this.hashValue(this.componentResponseMap.get('value'))
      )
      this.prevResponse = { ...this.componentResponse }
    },
    hashValue(value) {
      // convert content to utf8 before base64
      return btoa(
        encodeURIComponent(JSON.stringify(value)).replace(
          /%([0-9A-F]{2})/g,
          (match, p1) => String.fromCharCode('0x' + p1)
        )
      )
    },
    onCanSubmitChange(submitEnabled) {
      this.canSubmit = submitEnabled
    }
  },
  watch: {
    componentResponseMap: {
      immediate: true,
      handler(val) {
        this.unsubscribe?.()

        const changeHandler = event => {
          this.isCorrect = val.get('isCorrect')
          this.submissions = val.get('submissions') ?? 0

          const newValue = val.get('value')
          const jsonValue = newValue?.toJSON?.() ?? newValue
          const previousSubmittedAnswer = val.get('submittedAnswer')
          this.needsSubmission =
            jsonValue !== undefined &&
            jsonValue !== null &&
            this.hashValue(jsonValue) !== previousSubmittedAnswer

          this.isGrading = val.get('autogradeInProgress')
        }
        val.observe(changeHandler)
        changeHandler()

        this.unsubscribe = () => val.unobserve(changeHandler)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-component {
  margin: 40px 0 24px 0;
}

.question__number {
  margin: 0 8px 0 0;
  line-height: $line-height-base;
}
.hide-question {
  padding: 16px;
  background-color: #eee;
}
</style>
