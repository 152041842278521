<template>
  <div class="autosave-timestamp">
    <template v-if="lastSaved">
      Last saved at {{ $format.date(lastSaved, 'MM/dd/yyyy hh:mm a') }}
    </template>
  </div>
</template>

<script>
import * as Y from 'yjs'

export default {
  name: 'ActivityAutosaveTimestamp',
  props: {
    autosaveState: {
      type: Y.Map
    }
  },
  data() {
    return {
      lastSaved: null
    }
  },
  beforeUnmount() {
    this.unsubscribe?.()
  },
  watch: {
    autosaveState: {
      immediate: true,
      handler(val) {
        this.unsubscribe?.()

        if (!val) {
          return
        }

        const changeHandler = () => {
          const timestamp = val.get('lastSaved')

          if (timestamp) {
            this.lastSaved = new Date(val.get('lastSaved'))
          }
        }
        val.observe(changeHandler)
        changeHandler()

        this.unsubscribe = () => val.unobserve(changeHandler)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.autosave-timestamp {
  margin: 0 1em;
}
</style>
