<template>
  <editor-bubble-menu
    plugin-key="link"
    :editor="editor"
    :tippy-options="{ placement: 'bottom' }"
    :should-show="shouldShow"
  >
    <editor-menu-button
      tag="a"
      :href="url"
      target="_blank"
      label="Open in New Window"
      icon="external-link"
    />
    <editor-menu-button label="Edit" icon="pencil" @click="openModal" />
    <editor-menu-button label="Unlink" icon="unlink" @click="unlink" />
  </editor-bubble-menu>
  <editor-modal
    v-if="isModalShown"
    class="link-menu__modal"
    name="link"
    title="Edit Link"
  >
    <async-form class="link-menu__form" @submit="updateLink">
      <form-label for="editor-link-url">URL</form-label>
      <text-input
        id="editor-link-url"
        label="url"
        v-model="url"
        data-focus
        rules="required"
      />
      <div class="editor-modal__actions">
        <form-button type="submit">Update</form-button>
        <form-button tertiary @click="cancel">Cancel</form-button>
      </div>
    </async-form>
  </editor-modal>
</template>

<script>
import * as Vue from 'vue'
import { isActive } from '@tiptap/core'
import EditorModal from '../components/EditorModal'
import EditorBubbleMenu from '../components/EditorBubbleMenu'
import EditorMenuButton from '../components/EditorMenuButton'

export default {
  name: 'LinkMenu',
  components: { EditorBubbleMenu, EditorModal, EditorMenuButton },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const shouldShow = ({ state }) => {
      return isActive(state, 'link')
    }

    const unlink = () => {
      props.editor.chain().focus().unsetLink().run()
    }
    // Sync the focused node's href with the menu.
    const url = Vue.ref(null)
    const onSelectionUpdate = () => {
      url.value = props.editor.getAttributes('link').href
    }
    props.editor.on('selectionUpdate', onSelectionUpdate)
    Vue.onUnmounted(() => {
      props.editor.off('selectionUpdate', onSelectionUpdate)
    })

    const isModalShown = Vue.ref(false)
    const openModal = () => (isModalShown.value = true)

    const updateLink = e => {
      props.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url.value })
        .run()
      isModalShown.value = false
      e.done()
    }
    const cancel = e => {
      isModalShown.value = false
      props.editor.commands.focus()
    }

    // eslint-disable-next-line vue/no-mutating-props
    props.editor.storage.link.onEditLink = openModal

    return {
      url,
      isModalShown,
      shouldShow,
      unlink,
      cancel,
      openModal,
      updateLink
    }
  }
}
</script>

<style lang="scss" scoped>
.link-menu {
  &__modal {
    width: 300px;
  }

  &__form {
    margin: 0 !important;
  }

  &__error {
    margin: 16px 0 0 0;
    color: $color-error;
  }
}
</style>
