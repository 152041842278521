<template>
  <div />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import client from 'src/shared/api-client'
import { LTI_MODE } from 'src/setup/store/mutation-types'

export default {
  name: 'LtiView',
  computed: {
    ...mapGetters(['isAuthenticated', 'canManageResponses'])
  },
  methods: {
    ...mapMutations({
      ltiMode: LTI_MODE
    }),
    async selectView() {
      let ltiSession
      ltiSession = await client.lti.getSession()

      switch (ltiSession.state) {
        case 'CONNECT_ACCOUNT':
          if (this.isAuthenticated) {
            await this.$router.replace({ name: 'lti_connect_account' })
          } else {
            await this.$router.replace({ name: 'lti_login' })
          }
          break
        case 'CONNECT_CLASS':
          await this.$router.replace({ name: 'lti_connect_class' })
          break
        case 'CONNECT_ASSIGNMENT':
          await this.$router.replace({ name: 'lti_connect_assignment' })
          break
        case 'CONNECT_TEMPLATE':
          await this.$router.replace({ name: 'lti_assign_activity' })
          break
        case 'CONNECT_EXISTING_CLASS':
          await this.$router.replace({ name: 'lti_connect_existing_class' })
          break
        case 'CLASS_PAYMENT':
          await this.$router.replace({ name: 'lti_class_payment' })
          break
        case 'ERROR':
          await this.$router.replace({
            name: 'lti_error'
          })
          break
        case 'CONNECTED':
          if (this.canManageResponses) {
            await this.$router.replace({
              name: 'assignment_scores',
              params: { assignmentId: ltiSession.context.assignment }
            })
          } else {
            await this.$router.replace({
              name: 'student_response',
              params: { id: ltiSession.context.assignment }
            })
          }
      }
    }
  },
  async created() {
    //this clears our saved steps on initial launch of lti to prevent past steps leaking over.
    sessionStorage.removeItem('lti-steps')
    this.ltiMode()
    await this.selectView()
  }
}
</script>
