<template>
  <div class="container">
    <div v-if="!launched" class="launcher">
      <div :style="{ backgroundImage: `url('${poster}'` }">
        <form-button
          class="embedded-iframe__launch-button"
          type="button"
          @click="launch"
        >
          {{ launchText }}
        </form-button>
        <div class="embedded-iframe__terms">
          This content is from another website and can set cookies when
          launched. By clicking {{ launchText }}, you agree to the terms and
          conditions of
          <a :href="termsUrl" target="_blank">
            {{ domainName }}
            <icon icon="external-link" /></a
          >.
        </div>
      </div>
    </div>

    <iframe
      v-else
      :src="src"
      data-testid="embedded-iframe"
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      allowfullscreen="allowfullscreen"
    ></iframe>
  </div>
</template>

<script scoped>
import { mapGetters } from 'vuex'
const DOMAIN_REGEX = /(https?:\/\/((?:\w|\.|-)+(?::\d+)?))(?:\/|#|\?|$)/

export default {
  inject: ['inherited'],
  props: {
    src: {
      type: String
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      launched: false
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),

    isPhetEmbed() {
      return this.src && this.src.includes('phet.colorado.edu')
    },
    poster() {
      try {
        const config = JSON.parse(this.config)

        if (config.poster) return config.poster

        return this.isPhetEmbed
          ? `https://assets.pivotinteractives.com/public/pivot%2Bphet-launcher.png`
          : `https://assets.pivotinteractives.com/public/pivot-launcher.png`
      } catch {
        return this.isPhetEmbed
          ? `https://assets.pivotinteractives.com/public/pivot%2Bphet-launcher.png`
          : `https://assets.pivotinteractives.com/public/pivot-launcher.png`
      }
    },
    launchText() {
      return this.isPhetEmbed ? `Launch Simulation` : `Launch Embedded Content`
    },
    termsUrl() {
      const match = DOMAIN_REGEX.exec(this.src)
      return match?.[1] ?? '???'
    },
    domainName() {
      const match = DOMAIN_REGEX.exec(this.src)
      return match?.[2] ?? '???'
    }
  },
  methods: {
    launch() {
      this.launched = true
    }
  }
}
</script>

<style scoped lang="scss">
div.container {
  background: white;
  color: black;
  position: relative;
  width: 100%;
  height: 0px;
  padding-top: 56.25%;
  overflow: hidden;
}

div.container div.launcher,
div.container iframe {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 1;
}

div.container div.launcher {
  padding: 3%;
  background-color: white;
  z-index: 2;
}

div.container div.launcher > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  background-size: cover;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
}

.embedded-iframe {
  &__launch-button {
  }

  &__terms {
    text-align: center;
    margin: 16px 0 0 0;
    padding: 24px 16px 5% 32px;
    font-size: 14px;
    flex-shrink: 1;
  }
}
.pivot-instance__overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.pivot-instance__overlay-text {
  text-align: center;
}
.pivot-instance {
  position: relative;
  border-style: hidden;
  border-width: 1px;
  border-color: black;
}
</style>
