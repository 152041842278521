<template>
  <form-button
    v-if="useFormButton"
    v-bind="{ ...$attrs, primary, secondary, tertiary, link }"
    :aria-expanded="!collapse.state"
    :aria-controls="collapse.contentId"
    @click="toggle"
  >
    <!-- @slot The text of the button. Passes `collapsed` as a slot prop.  -->
    <slot :collapsed="collapse.state" />
  </form-button>
  <button
    v-else
    v-bind="$attrs"
    class="collapse-toggle"
    type="button"
    :aria-expanded="!collapse.state"
    :aria-controls="collapse.contentId"
    @click="toggle"
  >
    <slot :collapsed="collapse.state" />
  </button>
</template>

<script>
/**
 * Renders a button that toggles the collapse state of the nearest CollapseProvider.
 * By default uses an unstyled button,
 * but when using one of the form-button variants, uses a FormButton component.
 */
export default {
  name: 'CollapseToggle',
  inject: ['collapse'],
  props: {
    /** When true, renders a primary style button. */
    primary: {
      type: Boolean,
      default: false
    },
    /** When true, renders a secondary style button. */
    secondary: {
      type: Boolean,
      default: false
    },
    /** When true, renders a tertiary style button. */
    tertiary: {
      type: Boolean,
      default: false
    },
    /** When true, renders a link style button. */
    link: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    useFormButton() {
      return this.primary || this.secondary || this.tertiary || this.link
    }
  },
  methods: {
    toggle() {
      this.collapse.setState(!this.collapse.state)
    }
  }
}
</script>

<style lang="scss" scoped>
// Reset button styles
// See https://unpkg.com/tailwindcss@2.2.12/dist/base.css
.collapse-toggle {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  cursor: pointer;
  padding: 0;
  line-height: inherit;
  color: inherit;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  text-align: inherit;
  &:focus {
    text-decoration: underline;
  }
}
</style>
