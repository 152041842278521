<template>
  <div v-bind="attrs">
    <slot />
  </div>
</template>

<script>
import * as Vue from 'vue'

export default {
  name: 'TabProvider',
  inheritAttrs: false,
  props: {
    tab: {
      type: String,
      required: true
    },
    disableScroll: {
      type: Boolean,
      default: false
    }
  },
  data(props) {
    return {
      localCurrentTab: props.tab
    }
  },
  provide() {
    return {
      tabsInfo: Vue.computed(() => ({
        currentTab: this.currentTab,
        changeTab: this.changeTab
      }))
    }
  },
  computed: {
    attrs() {
      const { onChange, ...attrs } = this.$attrs
      return attrs
    },
    isControlled() {
      return !!this.$attrs.onChange
    },
    currentTab() {
      return this.isControlled ? this.tab : this.localCurrentTab
    }
  },
  methods: {
    changeTab(newTab) {
      if (this.isControlled) {
        this.$attrs.onChange(newTab)
      } else {
        this.localCurrentTab = newTab
      }
    },
    scrollUp() {
      const scroller = document.querySelector('#scroll-container') || window
      scroller.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  },
  watch: {
    currentTab(newTab, oldTab) {
      // TODO: move this scroll behavior out of this component since it isn't being used in full page tabs
      if (!this.disableScroll && newTab !== oldTab) {
        this.scrollUp()
      }
    }
  }
}
</script>
