export const DEFAULT_GRAPH_SETTINGS = {
  allowUncertainty: false,
  legacyInterface: true,
  curveFitTypes: {
    proportional: true,
    linear: true,
    squareLaw: true,
    squareRoot: true,
    quadratic: true,
    exponential: true,
    logarithmic: true,
    inverse: true,
    inverseSquare: true
  },
  graphTypes: {
    scatterGraphs: true,
    lineGraphs: true,
    barGraphs: true
  }
}

export const DEFAULT_GRID_SETTINGS = {
  includeColumnStatistics: false,
  includeFormulaBar: false,
  includeBluetooth: true,
  includeCsvDownload: true
}

export const DEFAULT_GRID_GRAPH_SETTINGS = {
  ...DEFAULT_GRAPH_SETTINGS,
  ...DEFAULT_GRID_SETTINGS
}
