<template>
  <editor-modal
    class="expression-menu__modal"
    v-if="isModalShown"
    name="expression"
    title="Edit Expression"
  >
    <async-form class="expression-menu__form" @submit="updateExpression">
      <multiline-text-input
        rows="4"
        v-model="currentExpression"
        resizableBoth
      />
      <div class="editor-modal__actions">
        <form-button type="submit">Update</form-button>
        <form-button tertiary @click="cancel">Cancel</form-button>
      </div>
    </async-form>
  </editor-modal>
</template>

<script>
import * as Vue from 'vue'
import EditorModal from '../components/EditorModal.vue'
import { replaceVariablesWithNames } from '@pi/shared/variables'

export default {
  name: 'ExpressionMenu',
  components: { EditorModal },
  props: {
    editor: {
      type: Object,
      required: true
    },
    variableContext: {
      type: Object,
      default: undefined
    }
  },
  setup(props) {
    // Sync the focused node's href with the menu.
    const currentExpression = Vue.ref(null)
    const isModalShown = Vue.ref(false)
    const onSelectionUpdate = () => {
      const expr = props.editor.getAttributes('expression').expr
      if (
        !props.editor.storage.expression.variableContext?.value ||
        typeof expr !== 'string'
      ) {
        currentExpression.value = expr
        return
      }
      const name = replaceVariablesWithNames(
        expr,
        props.editor.storage.expression.variableContext.value.variables
      )
      currentExpression.value = name
    }
    props.editor.on('selectionUpdate', onSelectionUpdate)
    Vue.onUnmounted(() => {
      props.editor.off('selectionUpdate', onSelectionUpdate)
    })

    const openModal = () => {
      isModalShown.value = true
    }
    const updateExpression = e => {
      props.editor.chain().focus().setExpression(currentExpression.value).run()
      isModalShown.value = false
      e.done()
    }
    const cancel = e => {
      isModalShown.value = false
      props.editor.commands.focus()
    }

    // eslint-disable-next-line vue/no-mutating-props
    props.editor.storage.expression.onEditExpression = openModal

    return {
      openModal,
      isModalShown,
      currentExpression,
      cancel,
      updateExpression
    }
  }
}
</script>
