<template>
  <div class="container">
    <div v-if="!launched" class="launcher">
      <div>
        <form-button type="button" @click="launch"
          >Launch Simulation</form-button
        >
      </div>
    </div>

    <div v-else-if="loading">
      <div class="loader" />
      <span class="sr-only">Loading Simulation</span>
    </div>

    <div v-else-if="error">
      <h4>Error loading PhET-iO simulation</h4>
    </div>

    <iframe ref="iframe" allowfullscreen></iframe>
  </div>
</template>

<script>
import math from 'src/setup/math'

// All the properties any sim may try to set on `window`
const windowProps = {
  assert: undefined,
  SimIFrameClient: undefined,
  WrapperUtils: undefined,
  QueryStringMachine: undefined,
  phetio: undefined
}

// Map of script URLs to objects holding references to
// what they might have tried to set on `window`
const urlToPhetMap = new Map()

// Set of script URLs that have loaded successfully to
// avoid loading again
const loadedURLs = new Set()

// Match a script URL in the map to an error stack trace
function phetUrlForError(e = new Error()) {
  try {
    throw e
  } catch (error) {
    for (const url of urlToPhetMap.keys()) {
      if (url !== '' && error.stack.includes(url)) {
        return url
      }
    }
  }
}

// Define getter and setter for all window props to get or set value in map
for (const prop in windowProps) {
  Object.defineProperty(window, prop, {
    get() {
      return urlToPhetMap.get(phetUrlForError(new Error()))[prop]
    },
    set(value) {
      urlToPhetMap.get(phetUrlForError(new Error()))[prop] = value
    }
  })
}

// Parse a phet URL into needed data
function phetURL(url) {
  const parts = url.split('/')

  return {
    id: parts.slice(0, -2).join('/'),
    fullURL: url,
    name: parts[parts.length - 4],
    version: parts[parts.length - 3]
  }
}

export default {
  name: 'LegacyPhetIoSimulation',
  props: {
    config: {
      type: String,
      required: true
    },
    variables: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      launched: false,
      loading: false,
      error: false,
      phetioClient: null
    }
  },
  created() {
    for (const url of this.urls) {
      const { id } = phetURL(url)
      if (!urlToPhetMap.has(id)) {
        urlToPhetMap.set(id, { ...windowProps })
      }
    }
  },
  mounted() {
    this.load()
  },
  unmounted() {
    if (this.phetioClient) {
      this.phetioClient.dispose()
    }
  },
  computed: {
    settings() {
      try {
        return JSON.parse(this.config)
      } catch (error) {
        this.$error(error.message)
        throw error
      }
    },
    version() {
      return this.settings.version
    },
    urls() {
      return this.settings.urls?.split('\n') || []
    },
    simulationState() {
      try {
        return JSON.parse(this.settings.state)
      } catch (error) {
        this.$error(error.message)
        throw error
      }
    },
    simulationValues() {
      const values = this.settings.values || []
      const variables = this.variables || []
      const scope = variables.reduce(
        (scope, variable) => ({
          ...scope,
          [`$${variable.id}`]: variable.value
        }),
        {}
      )

      const evaluatedValues = values.reduce((acc, { key, value }) => {
        let evaluatedExpr = math.evaluate(value, scope)
        if (math.typeOf(evaluatedExpr) === 'BigNumber') {
          evaluatedExpr = math.number(evaluatedExpr)
        }

        if (
          typeof evaluatedExpr !== 'number' &&
          typeof evaluatedExpr !== 'boolean'
        )
          return acc

        if (key.includes('.value.')) {
          const parts = key.split('.value.')
          if (!acc[parts[0]]) acc[parts[0]] = {}

          acc[parts[0]][parts[1]] = evaluatedExpr
        } else {
          acc[key] = evaluatedExpr
        }

        return acc
      }, {})

      return evaluatedValues
    }
  },
  methods: {
    launch() {
      this.loading = true
      this.launched = true
      this.load()
    },
    async load() {
      if (!this.launched) return

      try {
        this.loading = true
        this.error = false

        if (this.phetioClient) {
          this.phetioClient.dispose()
        }
        await this.loadScripts()
        this.loadSimulation()

        this.loading = false
      } catch (error) {
        this.error = true
        throw error
      } finally {
        this.loading = false
      }
    },
    async loadScripts() {
      for (const url of this.urls) {
        await this.loadScript(url)
      }
    },
    loadScript(url) {
      return new Promise((resolve, reject) => {
        if (loadedURLs.has(url)) {
          return resolve()
        }

        const el = document.createElement('script')
        el.src = url
        el.onload = () => {
          loadedURLs.add(url)
          document.head.removeChild(el)
          resolve()
        }
        el.onerror = reject
        document.head.appendChild(el)
      })
    },
    loadSimulation() {
      const { id, name, version } = phetURL(this.urls[0])
      if (this.version === '1') {
        const scripts = urlToPhetMap.get(id)
        this.phetioClient = new scripts.SimIFrameClient(this.$refs.iframe)
        this.phetioClient.launchSim(
          scripts.WrapperUtils.getSim(name, version).URL,
          {
            onSimInitialized: this.setSimulationState,
            phetioEventsListener: message => {
              const data = JSON.parse(message)
              if (data.phetioID.includes('resetAll')) {
                this.setSimulationState()
              }
            }
          }
        )
      } else if (this.version === '2') {
        const scripts = urlToPhetMap.get(id)
        this.phetioClient = new scripts.phetio.Client(this.$refs.iframe)
        this.phetioClient.launchSim({
          onSimInitialized: this.setSimulationState,
          onEvent: event => {
            if (
              event.phetioID.includes(
                'resetAllButton.pressListener.releaseAction'
              )
            ) {
              this.setSimulationState()
            }
          }
        })
      }
    },
    setSimulationState() {
      if (this.phetioClient) {
        if (this.version === '1') {
          this.phetioClient.invokeSequence(
            this.simulationState,
            this.setSimulationValues
          )
        } else if (this.version === '2') {
          this.phetioClient.invoke(
            'phetioEngine',
            'setState',
            [this.simulationState],
            this.setSimulationValues
          )
        }
      }
    },
    setSimulationValues() {
      if (this.phetioClient) {
        for (const key in this.simulationValues) {
          this.phetioClient.invoke(key, 'setValue', [
            this.simulationValues[key]
          ])
        }
      }
    }
  },
  watch: {
    urls: {
      handler() {
        for (const url of this.urls) {
          const { id } = phetURL(url)
          if (!urlToPhetMap.has(id)) {
            urlToPhetMap.set(id, { ...windowProps })
          }
        }
        this.load()
      },
      deep: true
    },
    simulationState() {
      this.setSimulationState()
    },
    simulationValues() {
      this.setSimulationValues()
    }
  }
}
</script>

<style scoped>
div.container {
  background: white;
  color: black;
  position: relative;
  width: 100%;
  height: 0px;
  padding-top: 56.25%;
  overflow: hidden;
}

div.container > div,
div.container iframe {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: none;
  background: black;
  color: white;
  z-index: 1;
}

div.container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

div.container div.launcher {
  padding: 3%;
  background-color: white;
  color: black;
}

div.container div.launcher img {
  width: 67.7312775%;
  height: auto;
  transform: translateX(-3.4146341%);
}

div.container div.launcher > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  background-image: url('https://assets.pivotinteractives.com/public/pivot%2Bphet-launcher.png');
  background-size: cover;
}

div.container div.launcher > div button {
  position: relative;
  top: 66%;
}

.loader {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid white;
  animation: load 1.1s infinite linear;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
