<template>
  <button
    ref="button"
    class="form-button"
    :class="{
      'form-button--secondary': secondary,
      'form-button--tertiary': tertiary,
      'form-button--link': link,
      'form-button--submit': submit,
      'form-button--alpha': alpha,
      'form-button--beta': beta,
      'form-button--destructive': destructive,
      'form-button--warning': warning,
      'form-button--unstyled': unstyled
    }"
    :type="submit ? 'submit' : type"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    alpha: {
      type: Boolean,
      default: false
    },
    beta: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    },
    destructive: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    unstyled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    focus() {
      this.$refs.button.focus()
    },
    contains(...args) {
      return this.$refs.button.contains(...args)
    }
  }
}
</script>

<style lang="scss">
.form-button {
  background-color: $teal;
  border: $teal solid 1px;
  color: #ffffff;
  border-radius: 6px;
  font-weight: 700;
  padding: 7px 14px;
  opacity: (1);
  transition: opacity 0.25s ease-out;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $darker-teal;
    border: $darker-teal solid 1px;
  }

  &:focus {
    box-shadow: $focus-shadow;
  }

  &:not(.modal-button-submit):not(.form-button--link) + .form-button {
    margin-right: 16px;
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.form-button--secondary {
  background-color: white;
  border-color: $teal;
  color: $teal;

  &:hover,
  &:focus {
    background-color: $darker-teal;
    color: white;
  }
  &:focus {
    box-shadow: $focus-shadow;
  }
}

.form-button--alpha {
  background-color: #ffffff;
  border-radius: 3px;
  color: #3c393a;
  border: 1px solid #3c393a;

  &:hover,
  &:focus {
    background-color: #ffffff;
    border-radius: 3px;
    color: #3c393a;
    border: 1px solid #3c393a;
  }
  &:focus {
    box-shadow: $focus-shadow;
  }
}

.form-button--beta {
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #3d2c60;
  background-color: #3d2c60;

  &:hover,
  &:focus {
    color: #ffffff;
    border-radius: 3px;
    border: 1px solid #3d2c60;
    background-color: #3d2c60;
  }
  &:focus {
    box-shadow: $focus-shadow;
  }
}

.form-button--link {
  border: none;
  background-color: inherit;
  padding: 0;
  border-radius: 0;

  outline: none;
  font-size: inherit;
  color: $teal;

  &.text-danger {
    color: $color-error;
  }

  &:hover,
  &:focus {
    background-color: inherit;
    border: none;
    color: $darker-teal;
    box-shadow: none;
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
}

.form-button--submit {
  float: right;
}

.form-button--tertiary {
  border-color: transparent;
  color: $teal;
  background-color: inherit;

  &:hover,
  &:focus {
    color: $darker-teal;
    background-color: inherit;
  }
  &:focus {
    box-shadow: $focus-shadow;
  }
}

.form-button--warning {
  border-color: $color-warning;
  color: $color-warning;
  background-color: inherit;

  &:hover,
  &:focus {
    color: white;
    background-color: $color-warning;
    border-color: $color-warning;
  }
  &:focus {
    box-shadow: $focus-shadow;
  }
}

.form-button--destructive {
  border-color: $color-error;
  color: $color-error;
  background-color: inherit;
  border-radius: 0;

  &:hover,
  &:focus {
    color: white;
    background-color: $color-error;
    border-color: $color-error;
  }
  &:focus {
    box-shadow: $focus-shadow;
  }
}

.form-button--link.form-button--destructive {
  border: none;
  background-color: inherit;
  color: $color-error;
  padding: 0;
  &:hover,
  &:focus {
    color: darken($color-error, 20%);
    background-color: inherit;
    border: none;
    text-decoration: underline;
  }
}

.form-button--unstyled {
  border: none;
  background-color: inherit;
  padding: 0;
  border-radius: 0;
  color: rgb(61, 61, 61);

  outline: none;
  font-size: inherit;

  &:hover,
  &:focus {
    background-color: inherit;
    border: none;
    box-shadow: none;
  }

  &:disabled {
    opacity: unset;
  }
}
</style>
