import {
  GetUserResponse,
  SearchUsersResponse,
  SearchUsersRequest,
  SetUsersDisabledReasonRequest,
  UpdateProfileRequest,
  VetUsersRequest,
  StartImpersonatingRequest,
  RemoveSSORequest
} from '@pi/api-types'
import type Client from './client'

export default class Users {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async search(request: SearchUsersRequest): Promise<SearchUsersResponse> {
    const requestBody = JSON.stringify(request)
    const { body } = await this.client.post<SearchUsersResponse>({
      path: `/api/v3/users`,
      body: requestBody
    })
    return body
  }
  async getMyself(): Promise<GetUserResponse> {
    const { body } = await this.client.get<GetUserResponse>({
      path: `/api/v3/user`
    })
    return body
  }
  async getUser(id: string): Promise<GetUserResponse> {
    const { body } = await this.client.get<GetUserResponse>({
      path: `/api/v3/users/${id}`
    })
    return body
  }

  async updateUserProfile(
    request: UpdateProfileRequest & { id: string }
  ): Promise<void> {
    await this.client.patch({
      path: `/api/v3/users/${request.id}`,
      body: JSON.stringify(request)
    })
    return
  }

  async updateMyProfile(request: UpdateProfileRequest): Promise<void> {
    await this.client.patch({
      path: `/api/v3/user`,
      body: JSON.stringify(request)
    })
    return
  }

  async vetInstructors(body: VetUsersRequest): Promise<void> {
    await this.client.post({
      path: '/api/v3/users/vet',
      body: JSON.stringify(body)
    })
    return
  }

  async setUsersDisableStatus(
    request: SetUsersDisabledReasonRequest
  ): Promise<void> {
    await this.client.post({
      path: `/api/v3/users/disable`,
      body: JSON.stringify(request)
    })
    return
  }

  async removeSSO({ id, ...body }: RemoveSSORequest): Promise<void> {
    await this.client.patch({
      path: `/api/v3/users/${id}/remove-sso`,
      body: JSON.stringify(body)
    })
  }

  async startImpersonating(id: string): Promise<void> {
    const body: StartImpersonatingRequest = { userId: id }
    await this.client.post({
      path: `/api/v3/admin/impersonate/start`,
      body: JSON.stringify(body)
    })
  }

  async stopImpersonating(): Promise<void> {
    await this.client.post({
      path: `/api/v3/admin/impersonate/stop`
    })
  }
}
