<template>
  <modal class="min-w-max" :useForm="false">
    <modal-header class="-mt-4 -ml-4 text-pivot-purple-700"
      >Create Pivot Class</modal-header
    >
    <modal-body>
      <UIForm class="mx-auto w-[700px] pt-6 px-10" @submit="onSubmit">
        <div class="pb-4">
          <UILabel for="name"> Name </UILabel>
          <UITextInput
            id="name"
            rules="required"
            type="name"
            autocomplete="name"
            label="Name"
            name="name"
          />
          <UIFieldError name="'name'" />
        </div>
        <div class="pb-4 w-48">
          <UILabel for="date"> End Date </UILabel>
          <UIDateInput
            id="date"
            rules="required"
            autocomplete="date"
            label="date"
            name="date"
          />
          <UIFieldError name="'date'" />
        </div>
        <div class="pb-4">
          <UILabel for="subject">Subject</UILabel>
          <UISelectInput
            id="subject"
            label="Subject"
            name="subject"
            :options="subjectOptions"
            :multiple="true"
          />
          <UIFieldError name="'subject'" />
        </div>
        <div class="pb-4">
          <UILabel for="gradeLevel">Grade Level</UILabel>
          <UISelectInput
            id="gradeLevel"
            label="Grade Level"
            name="gradeLevel"
            :options="gradeOptions"
            :multiple="true"
          />
          <UIFieldError name="'date'" />
        </div>

        <div class="pb-4">
          <UILabel for="instructionalLevel">Instructional Level</UILabel>
          <UISelectInput
            id="instructionalLevel"
            label="Instructional Level"
            name="instructionalLevel"
            :options="instructionalLevelOptions"
            :multiple="true"
          />
          <UIFieldError name="'instructionalLevel'" />
        </div>
        <modal-actions>
          <UIButton submit class="mx-4"> Create Class </UIButton>
          <modal-button-cancel>Cancel</modal-button-cancel>
        </modal-actions>
      </UIForm>
    </modal-body>
  </modal>
</template>
<script setup>
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import { ref, inject } from 'vue'
import { useForm } from 'vee-validate'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client.ts'

import {
  UILabel,
  UITextInput,
  UIDateInput,
  UIForm,
  UIButton,
  UIFieldError,
  UISelectInput
} from '@pi/design'
const props = defineProps({
  html: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  }
})
const $modal = inject('$_modal')
const flash = useFlash()

const { handleSubmit, values } = useForm({
  initialValues: {
    name: '',
    date: '',
    subject: [],
    gradeLevel: [],
    instructionalLevel: []
  }
})

const gradeOptions = [
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: 'college', value: 'college' }
]
const instructionalLevelOptions = [
  { label: 'Remedial', value: 'Remedial' },
  { label: 'On-Level', value: 'On-Level' },
  { label: 'Honors', value: 'Honors' },
  { label: 'Pre-AP', value: 'Pre-AP' },
  { label: 'AP', value: 'AP' },
  { label: 'IB', value: 'IB' }
]
const subjectOptions = [
  { label: 'Astronomy', value: 'Astronomy' },
  { label: 'Biology', value: 'Biology' },
  { label: 'Chemistry', value: 'Chemistry' },
  { label: 'Earth and Space', value: 'Earth and Space' },
  { label: 'Environmental Science', value: 'Environmental Science' },
  { label: 'Physics', value: 'Physics' }
]
const onSubmit = handleSubmit(async values => {
  await client.classes.create({
    name: values.name,
    endDate: values.date,
    subject: values.subject,
    grade: values.grade,
    instructionalLevel: values.instructionalLevel
  })
  flash.success(`Class ${values.name} created successfully!`)
  $modal.ok()
})
</script>

<style scoped>
p.prompt {
  font-weight: bold;
  margin-bottom: 0;
}
</style>
