<template>
  <div class="parent-cell">
    <div class="conditon-wrapper">
      <div class="answer-cell">
        <div class="formula-and-feedback-cell">
          <div class="answer_input">
            <label class="answer-label" for="answerExpression">
              Answer Formula
            </label>
            <hyper-link :to="{ name: 'help_NAG' }">
              <icon icon="question-circle" />
              <span class="sr-only">Expression Help</span>
            </hyper-link>
            <form-button link class="add-variable" @click="addVariables">
              [+var]
            </form-button>
            <text-input
              id="answerExpression"
              v-model="answerExpression"
              label="answerExpression"
              :name="`${name}.expression`"
              :rules="{
                required: true,
                undefinedVariables: true,
                mathjs: { variables: expressionVariables }
              }"
            />
          </div>
          <div class="tolerance-cell">
            <span class="plus-minus">&plusmn;</span>
            <number-input
              id="accuracy"
              class="tolerance-input"
              :modelValue="String(condition.accuracy)"
              label="accuracy"
              :rules="{
                min: 0
              }"
              @input="accuracy => emitChange({ accuracy })"
            />
            <span
              v-if="condition.accuracyType == 'percent'"
              class="tolerance-unit"
              >%</span
            >
            <select-field
              id="accuracyType"
              :modelValue="condition.accuracyType"
              label="accuracyType"
              class="tolerance-type"
              @update:modelValue="accuracyType => emitChange({ accuracyType })"
              aria-label="Accuracy Type"
            >
              <option value="percent">Percent</option>
              <option value="absolute">Absolute</option>
            </select-field>
          </div>

          <div class="feedback">
            <label for="feedback">Feedback</label>
            <editor
              :text="condition.response"
              :label="condition.response"
              :variable-context="variableContext"
              :rules="{
                undefinedVariables: true
              }"
              :name="`${name}.response`"
              data-testid="text-input"
              @input="response => emitChange({ response })"
              allow-response-variable
            />
          </div>
          <checkbox
            class="correct-checkbox"
            :modelValue="condition.isCorrect"
            data-testid="condition-isCorrect-input"
            @update:modelValue="isCorrect => emitChange({ isCorrect })"
          >
            Correct Answer
          </checkbox>
        </div>
      </div>
      <div class="actions-cell">
        <icon-button
          label="Move Up"
          icon="chevron-up"
          :color="index === 0 ? 'move-disabled' : 'move-avtive'"
          @click="moveUp"
        />
        <icon-button
          label="Move Down"
          icon="chevron-down"
          :color="index === lastIndex ? 'move-disabled' : 'move-avtive'"
          @click="moveDown"
        />
        <icon-button
          label="Duplicate Condition"
          icon="copy"
          color="copy-icon"
          @click="copyCondition"
        />
        <icon-button
          label="Delete Condition"
          icon="trash"
          color="delete-icon"
          @click="removeCondition"
        />
        <icon-button
          label="Add Condition Below"
          icon="plus"
          color="add-icon"
          @click="insertCondition"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ActivityVariablesModal from 'src/shared/components/modals/ActivityVariablesModal'
import {
  replaceVariablesWithIds,
  replaceVariablesWithNames
} from '@pi/shared/variables'

export default {
  name: 'ResponseCondition',

  inject: ['inherited', '$modal'],
  emits: ['edit', 'insert', 'delete', 'moveUp', 'moveDown'],
  props: {
    condition: {
      required: true
    },
    index: {
      required: true
    },
    lastIndex: {
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      displayExpression: null,
      replacedExpression: null
    }
  },
  computed: {
    expressionVariables() {
      return [
        { name: 'response', id: 'response' },
        ...this.variableContext.variables
      ]
    },
    answerExpression: {
      get() {
        return this.displayExpression
      },
      set(expression) {
        this.displayExpression = expression
        const newText = replaceVariablesWithIds(
          expression,
          this.expressionVariables
        )
        this.replacedExpression = newText
        if (newText !== this.condition.condition) {
          this.emitChange({ condition: newText })
        }
      }
    }
  },
  methods: {
    emitChange(fields) {
      this.$emit(
        'edit',
        {
          ...this.condition,
          ...fields
        },
        this.index
      )
    },
    insertCondition() {
      this.$emit('insert', this.index + 1, {
        condition: '',
        isCorrect: false,
        response: '',
        pointValue: '',
        accuracy: 2,
        accuracyType: 'percent'
      })
    },
    removeCondition() {
      this.$emit('delete', this.index)
    },
    copyCondition() {
      const { id, ...condition } = this.condition
      this.$emit('insert', this.index + 1, condition)
    },
    moveUp() {
      this.$emit('moveUp', this.index, { ...this.condition })
    },
    moveDown() {
      this.$emit('moveDown', this.index, { ...this.condition })
    },
    // This protects the display value from being parsed and modified as you type.
    // If the source of the change is this component,
    // then prop changes won't cause the internal state to change.
    convertExpression() {
      if (this.condition.condition !== this.replacedExpression) {
        this.displayExpression = replaceVariablesWithNames(
          this.condition.condition ?? '',
          this.expressionVariables
        )
      } else {
        //this ensures that the variable text is replaced by the id if the variable was added after the expression was set
        const newText = replaceVariablesWithIds(
          this.displayExpression,
          this.expressionVariables
        )
        if (newText !== this.replacedExpression) {
          this.replacedExpression = newText
          if (newText !== this.condition.condition) {
            this.emitChange({ condition: newText })
          }
        }
      }
    },
    async addVariables(e) {
      e.preventDefault()
      const { status, data } = await this.$modal.show(ActivityVariablesModal, {
        variableContext: this.variableContext,
        allowInsert: true,
        allowResponseVariable: true
      })
      if (status === 'ok') {
        this.answerExpression = `${this.answerExpression ?? ''}$${data.name}`
      }
    }
  },
  watch: {
    expressionVariables: {
      handler: 'convertExpression'
    },
    text: {
      handler: 'convertExpression',
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
$border: 1px solid $teal;
.parent-cell {
  padding: 0 16px 16px 16px;
  margin: 0 -15px;

  & .conditon-wrapper {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    border-top: $border;
  }

  &:focus-within {
    background-color: darken($extra-light-teal, 10%);
  }
  & label {
    font-weight: 400;
  }
  .answer_input {
    grid-area: answer;
  }
  .tolerance-cell {
    grid-area: tolerance;
    align-self: end;
    flex-grow: 0;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-right: 8px;
  }
  .tolerance-input {
    display: inline-block;
  }

  .feedback {
    grid-area: feedback;
  }
  & .correct-answer-cell {
    min-width: 20%;
    margin-top: 20px;
  }
}
.actions-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.numerical-parent {
  label {
    font-weight: 400;
  }
}

.add-variable {
  float: right;
}

.form-group.reset-group-margin {
  margin-left: 0;
  margin-right: 0;
  align-items: center;
  display: flex;
}

.input-append {
  margin-left: 8px;
}

.tolerance-input {
  display: inline-block;
  width: 60px;
  margin-right: 8px;
}
.tolerance-type {
  margin-left: 8px;
}
.answer-label {
  margin-right: 5px;
}
.formula-and-feedback-cell {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  row-gap: 8px;
  grid-template-areas:
    'answer tolerance'
    'feedback checkbox';
}
.feedback {
  grid-column: 2;
  grid-row: 2;
}
.answer-cell {
  flex-grow: 1;
}
.plus-minus {
  margin: 0 8px;
}
.correct-checkbox {
  grid-area: checkbox;
  margin-left: 10px;
  margin-top: 20px;
}
</style>
