<template>
  <dashboard-panel class="quicklinks-widget">
    <dashboard-panel-header>
      Explore Pivot Interactives
    </dashboard-panel-header>
    <p class="quicklinks-widget__link">
      <router-link :to="{ name: 'activities' }">
        Search our library of activities
      </router-link>
    </p>
    <p class="quicklinks-widget__link">
      <router-link :to="{ name: 'activities' }">
        Create your own activity
      </router-link>
    </p>
    <p class="quicklinks-widget__link">
      <router-link :to="{ name: 'classes' }"> Create a new class </router-link>
    </p>
    <p class="quicklinks-widget__link">
      Feeling stuck? Check out our
      <hyper-link :to="{ name: 'instructor_quick_start' }">
        help documentation
      </hyper-link>
    </p>
  </dashboard-panel>
</template>
<script setup>
import DashboardPanel from 'src/shared/components/DashboardPanel.vue'
import DashboardPanelHeader from 'src/shared/components/DashboardPanelHeader.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const siteLicense = computed(() => store.state.auth.user.siteLicense)
</script>
<style lang="scss" scoped>
.quicklinks-widget {
  padding: 0 15px;
  height: 100%;
  width: 100%;
}

.quicklinks-widget__link {
  font-size: 16px;
  margin: 4px 0;
}
</style>
