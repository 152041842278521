<template>
  <view-container>
    <loading-container :loading="loading">
      <view-header>
        <view-title>
          {{ student.firstName }} {{ student.lastName }} -
          <a :href="`mailto:${student.email}`">{{ student.email }}</a>
          <span v-if="isDisabled" class="disable-status-txt">
            Disabled ({{ disableReason }})
          </span>
        </view-title>
        <view-header-actions>
          <form-button
            v-if="!isDisabled"
            class="disable-button"
            destructive
            @click="disable(student.id)"
            >Disable Account</form-button
          >
          <form-button
            v-if="isDisabled && allowReenable"
            class="disable-button"
            @click="reEnable(student.id)"
            >Re-Enable Account</form-button
          >
          <form-button
            class="reset-password-button"
            @click="resetPassword(student.email)"
            >Reset Password</form-button
          >
          <form-button
            class="impersonate-button"
            @click="impersonate(student.id)"
            >Impersonate</form-button
          >
        </view-header-actions>
      </view-header>
      <div class="col-sm-6">
        Last login:
        {{
          student.lastLogin
            ? $format.date(student.lastLogin, 'MM/dd/yyyy  hh:mm a')
            : ''
        }}
      </div>
      <div v-if="student.ssoProviders.length > 0" class="form-group row">
        <h4 class="fcol-md-6 col-sm-10">
          SSO Providers:
          <ul class="list-unstyled" style="padding-left: 20px">
            <li v-for="sso in student.ssoProviders" :key="sso">
              <span>{{ sso }}</span>
              <icon-button
                style="padding-left: 10px"
                color="delete-icon"
                icon="trash"
                @click="removeSsoOption(sso)"
                label="Remove"
              />
            </li>
          </ul>
        </h4>
      </div>
      <div v-if="student.lti" class="form-group row">
        <div>
          <h4 class="fcol-md-6 col-sm-10">LTI: {{ student.lti.name }}</h4>
          <click-to-copy-field
            aria-label="Platform ID"
            :value="student.lti.id"
            message="The platform ID has been copied."
            name="platform-id"
          />
        </div>
      </div>
      <div class="form-group row">
        <h2 class="col-md-6 col-sm-10">Rostered Classes</h2>
      </div>
      <list
        :items="rosteredClasses"
        :row-class="({ isArchived }) => (isArchived ? 'danger' : '')"
      >
        <list-column title="Name">
          <template #default="{ name, isArchived }">
            {{ name + (isArchived ? ' (archived)' : '') }}
          </template>
        </list-column>
        <list-column title="Instructor(s)">
          <template #default="{ instructors }">
            <template
              v-for="(instructor, index) in instructors.slice(0, 3)"
              :key="index"
            >
              <router-link
                :to="{
                  name: 'admin_manage_instructor',
                  params: { id: instructor.id }
                }"
              >
                {{ instructor.email }}
              </router-link>
              <template v-if="instructors.slice(0, 3).length > 1 && index < 2"
                >,
              </template>
            </template>
          </template>
        </list-column>
        <list-column title="Payment" property="paymentSource" />
        <list-column title="Level" property="level" />
        <list-column v-slot="{ id }" title="Actions">
          <router-link :to="{ name: 'admin_class', params: { id: id } }">
            View
          </router-link>
        </list-column>
      </list>

      <div class="form-group row">
        <h2 class="col-md-6 col-sm-10">Dropped Classes</h2>
      </div>
      <list
        :items="droppedClasses"
        :row-class="({ isArchived }) => (isArchived ? 'danger' : '')"
      >
        <list-column title="Name">
          <template #default="{ name, isArchived }">
            {{ name + (isArchived ? ' (archived)' : '') }}
          </template>
        </list-column>
        <list-column title="Instructors">
          <template #default="{ instructors }">
            <router-link
              v-if="instructors?.length"
              :to="{
                name: 'admin_manage_instructor',
                params: { id: instructors[0].id }
              }"
            >
              {{ instructors[0].email }}
            </router-link>
          </template>
        </list-column>
        <list-column title="Payment" property="paymentSource" />
        <list-column title="Level" property="level" />
        <list-column v-slot="{ id }" title="Actions">
          <router-link :to="{ name: 'admin_class', params: { id } }">
            View
          </router-link>
        </list-column>
      </list>
    </loading-container>
  </view-container>
</template>

<script>
import { mapActions } from 'vuex'
import DisableReasonModal from 'src/shared/components/modals/DisableReasonModal'
import ClickToCopyField from 'src/shared/components/ClickToCopyField'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import client from 'src/shared/api-client.ts'

export default {
  name: 'ManageStudentView',
  components: {
    ClickToCopyField
  },
  props: ['id'],
  inject: ['$modal'],
  data() {
    return {
      loading: true,
      student: {},
      classes: { rostered: [], dropped: [] }
    }
  },
  computed: {
    isDisabled() {
      return !this.loading && this.student
        ? typeof this.student.disabledReason !== 'undefined'
        : false
    },
    allowReenable() {
      return !this.loading && this.student
        ? this.student.disabledReason !== 'invalid'
        : false
    },
    disableReason() {
      if (!this.student) return ''
      switch (this.student.disabledReason) {
        case 'invalid':
          return 'Invalid'
        case 'abuse':
          return 'Abuse'
        case 'vetting-rejected':
          return 'Vetting Rejected'
        default:
          return ''
      }
    },
    platformId() {
      return this.student.lti.id
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    async resetPassword(email) {
      await client.auth.requestPasswordReset(email)
      this.$success(`Password reset sent to ${email}`)
    },
    async loadStudent() {
      const student = await client.users.getUser(this.id)
      this.student = student
    },
    async loadClasses() {
      this.rosteredClasses = await client.classes.getClassesForUser({
        id: this.id,
        roles: ['student']
      })
      this.droppedClasses = await client.classes.getClassesForUser({
        id: this.id,
        roles: ['dropped']
      })
    },
    async load() {
      this.loading = true
      await Promise.all([this.loadStudent(), this.loadClasses()])
      this.loading = false
    },
    async impersonate(userId) {
      window.sessionStorage.setItem('previous_url', window.location.href)
      await client.users.startImpersonating(userId)
      await this.refreshAuth()
      this.$router.push({ name: 'home' })
    },
    async disable(id) {
      const { status: modalStatus } = await this.$modal.show(DisableReasonModal)
      if (modalStatus !== 'cancel') {
        await client.users.setUsersDisableStatus({
          users: [{ id, reason: modalStatus }]
        })
        this.$success('User has been disabled')
        this.student.disabledReason = modalStatus
      }
    },
    async reEnable(id) {
      await client.users.setUsersDisableStatus({ users: [{ id }] })
      this.$success('User has been re-enabled')
      this.student.disabledReason = undefined
    },
    async removeSsoOption(ssoOpt) {
      //confirm
      const { status: modalStatus } = await this.$modal.show(ConfirmModal, {
        text: `This will remove the saved ${ssoOpt} login method for the user to log in and will send a password reset email to the user.`,
        prompt: `Do you wish to remove ${ssoOpt}`
      })
      if (modalStatus === 'ok') {
        await client.users.removeSSO({ id: this.id, ssoOption: ssoOpt })
        this.$success(`${ssoOpt} as been removed`)
        await this.loadStudent()
      }
    }
  },
  async created() {
    await this.load()
  }
}
</script>
<style scoped>
.reset-password-button {
  margin-right: 10px;
}
.disable-button {
  margin-right: 16px;
}
.disable-status-txt {
  color: #d9534f;
  margin-left: 16px;
}
.click-copy-field {
  width: 30%;
}
</style>
