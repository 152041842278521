<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as Sentry from '@sentry/browser'
import SessionExpiredModal from 'src/modules/auth/modals/SessionExpiredModal'
import modal from 'src/shared/components/modals/handler'

export default {
  name: 'ErrorBoundary',
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    onUnhandledRejection(event) {
      this.handleError(event.reason)
    },
    handleError(error) {
      if (error?.request) {
        const response = error
        const request = response.request
        if (request.url?.includes(PI_API_URL)) {
          this.handlePivotApiError(request, response)
          return true
        } else if (request.url?.includes(PI_S3_ENDPOINT)) {
          this.handleAwsApiError(request, response)
          return true
        }
      }
      return false
    },
    handlePivotApiError(request, response) {
      let errors = response.body?.errors
      if (!Array.isArray(errors)) {
        errors = []
      }

      switch (response.status) {
        case 400:
        case 422:
          errors.forEach(error => this.$error(error.message ?? error))
          break

        case 401: {
          // redirect GETs to login and show login modal for all others so user can continue working
          // The one exception is the refresh route.
          // We don't want to redirect then because some pages are accessible without logging in.
          if (request.url.endsWith('/user')) {
            break
          } else if (request.method === 'GET' || !this.isAuthenticated) {
            if (this.isAuthenticated) {
              window.location.assign(
                this.$router.resolve({
                  name: 'login',
                  query: {
                    redirect: this.$router.currentRoute.path,
                    flash: {
                      error: 'Your session has expired. Please log in again.'
                    }
                  }
                }).href
              )
            } else {
              this.$error('Your session has expired. Please log in again.')
              this.$router.replace({
                name: 'login',
                query: {
                  redirect: this.$router.currentRoute.path
                }
              })
            }
          } else {
            modal.show(SessionExpiredModal)
          }
          break
        }

        case 403:
          this.$router.replace({ name: 'home' })
          this.$error('You are not authorized to view that page.', {
            timeout: true
          })
          break

        case 404:
          if (request.method === 'GET') {
            this.$router.push({ name: 'not_found' })
          } else {
            this.$error("We couldn't find what you were looking for.")
          }
          break

        case 500:
        case 503:
          if (errors.length > 0) {
            errors.forEach(error => {
              Sentry.withScope(scope => {
                scope.setExtra('response', response)
                Sentry.captureException(new Error(error.message ?? error))
              })
              this.$error(error)
            })
          } else {
            Sentry.withScope(scope => {
              scope.setExtra('response', response)
              Sentry.captureException(new Error(response.statusText))
            })
            this.$error(
              'An unknown error occurred. Please try again or contact support if the issue persists.'
            )
          }
          break

        case 0:
          // this indicates there is no internet connection or the request to the api timed out w/o receiving a response
          this.$error(
            'We were unable to process your request. Please check your Internet connection and try again or contact support if the issue persists.'
          )
          break
        default:
          // just send to sentry for now and skip messaging user until we have more insight into what these are
          Sentry.withScope(scope => {
            scope.setExtra('response', response)
            Sentry.captureException(new Error(response.statusText))
          })
      }
    },
    handleAwsApiError(request, response) {
      switch (response.status) {
        case 400:
          this.$error(
            'There was an error uploading your file. Please check the file size and try again.'
          )
          break
        case 403:
          this.$error(
            'There was an error uploading your file. Please check the file size and try again.'
          )
          break
        case 404:
          this.$error(
            'There was an error uploading your file. Please try again later.'
          )
          break

        case 0:
          // this indicates there is no internet connection or the request to the api timed out w/o receiving a response
          if (request.method !== 'GET') {
            this.$error(
              'We were unable to process your request. Please check your Internet connection and try again or contact support if the issue persists.'
            )
          }
          break
        default:
          // just send to sentry for now and skip messaging user until we have more insight into what these are
          Sentry.withScope(scope => {
            scope.setExtra('response', response)
            Sentry.captureException(new Error(response.statusText))
          })
      }
    }
  },
  errorCaptured(error) {
    const handled = this.handleError(error)
    return !handled
  },
  created() {
    window.addEventListener(
      'unhandledrejection',
      this.onUnhandledRejection,
      true
    )
  },
  unmounted() {
    window.removeEventListener('unhandledrejection', this.onUnhandledRejection)
  }
}
</script>
