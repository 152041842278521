<template>
  <modal class="vocab-display-modal">
    <modal-body class="replace-activity-modal__body">
      <playback :element="tipRef" class="playback_btn !absolute top-2 left-2" />
      <div class="editor-wrapper" ref="tipRef">
        <editor-content :text="tip" :variable-context="variableContext" />
      </div>
    </modal-body>
    <modal-actions class="updated-changes__modal-actions">
      <form-button destructive class="flex-start" @click="closeModal">
        Close
      </form-button>
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalBody,
  ModalActions
} from 'src/shared/components/modals/components'

import { inject, ref } from 'vue'

interface VocabDisplayModalProps {
  variableContext: any
  tip: any
}
const $modal = inject<any>('$_modal')

const tipRef = ref<any>(null)

const props = defineProps<VocabDisplayModalProps>()

const closeModal = () => {
  $modal.close()
}
</script>

<style lang="scss" scoped></style>
