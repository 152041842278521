const determineLicensePriceCostPerSeat = (level: string, seatCount: number) => {
  return level === 'high-school' ? 5.5 : 10
}

const determineLicensePrice = (level: string, seatCount: number) => {
  const costPerSeat = determineLicensePriceCostPerSeat(level, seatCount)
  return costPerSeat * seatCount
}
const determineCodePurchasePriceCostPerSeat = (level: string) => {
  if (level === 'high-school') {
    return 5.5
  } else {
    return 10
  }
}
const determineCodePurchasePrice = (level: string, seatCount: number) => {
  const costPerSeat = determineCodePurchasePriceCostPerSeat(level)
  return costPerSeat * seatCount
}
const determinePercentLicenseRemaining = (startDate: Date, endDate: Date) => {
  const licenseDuration = endDate.getTime() - startDate.getTime()
  const now = new Date().getTime()
  const licenseRemaining =
    now > startDate.getTime() ? endDate.getTime() - now : licenseDuration
  const percentLicenseRemaining = licenseRemaining / licenseDuration
  return percentLicenseRemaining
}
const determineAddedSeatsPriceCostPerSeat = (
  startDate: Date,
  endDate: Date,
  level: string,
  seatCount: number
) => {
  const percentLicenseRemaining = determinePercentLicenseRemaining(
    startDate,
    endDate
  )
  return (
    Math.round(
      determineLicensePriceCostPerSeat(level, seatCount) *
        percentLicenseRemaining *
        100 +
        Number.EPSILON
    ) / 100
  )
}
const determineAddedSeatsPrice = (
  startDate: Date,
  endDate: Date,
  level: string,
  seatCount: number
) => {
  const costPerSeat = determineAddedSeatsPriceCostPerSeat(
    startDate,
    endDate,
    level,
    seatCount
  )

  return Math.round(costPerSeat * seatCount * 100 + Number.EPSILON) / 100
}

export default {
  determineLicensePrice,
  determineCodePurchasePrice,
  determineAddedSeatsPrice,
  determineLicensePriceCostPerSeat,
  determineCodePurchasePriceCostPerSeat,
  determineAddedSeatsPriceCostPerSeat,
  determinePercentLicenseRemaining
}
