<template>
  <ul class="sidebar-list">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'SidebarList'
}
</script>

<style lang="scss" scoped>
.sidebar-list {
  list-style: none;
  padding: 0;
  font-size: 16px;

  .sidebar-list {
    padding: 0 0 0 20px;
  }
}
</style>
