<template>
  <component
    :is="as"
    ref="root"
    class="resizable"
    :class="{ 'resizable--active': focused || isDragging }"
    :style="{
      ...(['horizontal', 'both'].includes(direction) && {
        width: `${size.width}${units === 'percent' ? '%' : 'px'}`
      }),
      ...(['vertical', 'both'].includes(direction) &&
        !preserveAspectRatio && {
          height: `${size.height}${units === 'percent' ? '%' : 'px'}`
        })
    }"
  >
    <div
      v-if="preserveAspectRatio"
      class="resizable__aspect-box"
      :style="
        (size.width &&
          size.height && {
            paddingBottom: `${(100 * size.height) / size.width}%`
          }) ||
        ''
      "
    >
      <div class="resizable__aspect-box-child">
        <slot />
        <div v-if="displaySize" class="resizable__readout">
          {{ displaySize }}
        </div>
        <div
          v-if="focused || isDragging"
          class="resizable__handle-target"
          :class="{
            [`resizable__handle-target--${direction}`]: true
          }"
          @pointerdown="onPointerDown"
        >
          <div class="resizable__handle"></div>
        </div>
      </div>
    </div>
    <template v-else>
      <slot />
      <div v-if="displaySize" class="resizable__readout">
        {{ displaySize }}
      </div>
      <div
        v-if="(focused || isDragging) && direction !== 'none'"
        class="resizable__handle-target"
        :class="{
          [`resizable__handle-target--${direction}`]: true
        }"
        @pointerdown="onPointerDown"
      >
        <div class="resizable__handle"></div>
      </div>
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed, type Component, ref } from 'vue'

type ResizeDirection = 'horizontal' | 'vertical' | 'both' | 'none'
interface Size {
  width: number
  height: number
}

interface ResizableProps {
  /** The element or component to render as the root element. */
  as: Component | string
  /** The aspect ratio to enforce while resizing. */
  aspectRatio?: number
  /** The current size of the element in the units specified by `units`. */
  size: Partial<Size>
  /** Whether the element being resized is focused, and the resizer should be made visible. */
  focused?: boolean
  /** The directions that can be resized. Defaults to `'both'` */
  direction?: ResizeDirection
  /** The units to use for dimensions. Defaults to `'pixels'`. */
  units?: 'pixels' | 'percent'
  /**
   * Whether the element being sized is block or inline.
   * Block elements can be resized in both directions, while inline only horizontal.
   */
  display?: 'block' | 'inline'
  /**
   * Whether the element being sized is fullwidth.
   * This removes horizontal sizing completely.
   */
  fullWidth?: boolean
  /** Whether to preserve the aspect ratio of the element when it must be downsized to fit container. */
  preserveAspectRatio?: boolean
}

const emit = defineEmits<{
  'update:size': [size: Partial<Size>]
  resizestart: []
  resizeend: []
}>()
const props = withDefaults(defineProps<ResizableProps>(), {
  focused: false,
  units: 'pixels',
  preserveAspectRatio: true,
  display: 'block',
  fullWidth: false
})

const isDragging = ref(false)
const displaySize = ref<string>()
const root = ref<Component | HTMLElement>()
const rootEl = computed(() =>
  root.value && '$el' in root.value ? root.value.$el : root.value
)
const parentSize = ref<Size>()

const direction = computed(() => {
  if (props.display === 'block') {
    return props.fullWidth ? 'vertical' : 'both'
  } else {
    return props.fullWidth ? 'none' : 'horizontal'
  }
})

// The direction vector is a unit vector along the resize direction.
// This is proportional to the aspect ratio.
const directionVector = computed(() => {
  if (direction.value !== 'both') return
  let vector: [number, number] | undefined
  if (props.aspectRatio) {
    vector = [1, 1 / props.aspectRatio]
    const mag = Math.sqrt(vector[0] ** 2 + vector[1] ** 2)
    vector[0] /= mag
    vector[1] /= mag
  }
  return vector
})

function onPointerDown(e: PointerEvent) {
  if (!e.isPrimary) return

  e.stopPropagation()
  e.preventDefault()

  isDragging.value = true

  // If we are sizing relative to a parent element,
  // we get the bounding box of the parent when dragging starts.
  // The parent size shouldn't change while we are resizing.
  if (props.units === 'percent') {
    const parent = rootEl.value?.parentElement
    if (!parent) return

    const bounds = parent.getBoundingClientRect()
    parentSize.value = { width: bounds.width, height: bounds.height }
  }

  const handle = e.currentTarget as HTMLElement
  handle.setPointerCapture(e.pointerId)
  handle.addEventListener('pointermove', onPointerMove)
  handle.addEventListener('pointerup', onPointerEnd)
  handle.addEventListener('pointercancel', onPointerEnd)

  emit('resizestart')
}
function onPointerMove(e: PointerEvent) {
  if (!e.isPrimary || !isDragging.value) return

  e.stopPropagation()
  e.preventDefault()
  if (!root.value) return

  // We need the bounding box of the resizing element
  // so that we can calculate the dimensions between the far corner and the handle
  const boundingBox = rootEl.value.getBoundingClientRect()
  let width = Math.max(16, e.clientX - boundingBox.left)
  let height = Math.max(16, e.clientY - boundingBox.top)

  // If we are sizing relative to a parent element, we have to constrain to the parent size
  if (parentSize.value) {
    width = Math.min(parentSize.value.width, width)
    height = Math.min(parentSize.value.height, height)
  }

  // If we are sizing according to an aspect ratio,
  // we project the width/height vector onto the vector from corner to corner.
  if (directionVector.value) {
    const dot =
      directionVector.value[0] * width + directionVector.value[1] * height
    width = dot * directionVector.value[0]
    height = dot * directionVector.value[1]
  }

  // We have to save these for the readout before we convert to percentages.
  const displayWidth = Math.round(width)
  const displayHeight = Math.round(height)

  // If we are sizing relative to a parent element, we have to convert to a percentage
  if (parentSize.value) {
    width = (100 * width) / parentSize.value.width
    height = (100 * height) / parentSize.value.height
  }

  // Depending on the directions we are allowed to resize,
  // we only report changes to the relevant dimensions.
  switch (direction.value) {
    case 'both':
      emit('update:size', { width, height })
      displaySize.value = `${displayWidth}x${displayHeight}`
      break
    case 'horizontal':
      emit('update:size', { width })
      displaySize.value = displayWidth.toString()
      break
    case 'vertical':
      emit('update:size', { height })
      displaySize.value = displayHeight.toString()
      break
  }
}
function onPointerEnd(e: PointerEvent) {
  if (!e.isPrimary || !isDragging.value) return

  e.stopPropagation()
  e.preventDefault()

  isDragging.value = false
  displaySize.value = undefined

  const handle = e.currentTarget as HTMLElement
  handle.removeEventListener('pointermove', onPointerMove)
  handle.removeEventListener('pointerup', onPointerEnd)
  handle.removeEventListener('pointercancel', onPointerEnd)

  emit('resizeend')
}
</script>

<style lang="scss" scoped>
.resizable {
  max-width: 100%;
  position: relative;
  width: fit-content;

  &--active {
    box-shadow: 0px 0px 0px 3px dodgerblue;
  }
}

.resizable__aspect-box {
  position: relative;
  max-width: 100%;
}

.resizable__aspect-box-child {
  position: absolute;
  width: 100%;
  height: 100%;
}

.resizable__handle-target {
  width: 32px;
  height: 32px;
  background: transparent;
  position: absolute;
  z-index: 2;

  &--both {
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
  }

  &--horizontal {
    bottom: 50%;
    right: 0;
    cursor: ew-resize;
  }

  &--vertical {
    bottom: 0;
    right: 50%;
    cursor: ns-resize;
  }

  .resizable__handle {
    width: 16px;
    height: 16px;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
    right: -8px;
    background: white;
    position: absolute;
    z-index: 1;
    box-shadow: 0px 0px 0px 3px dodgerblue;
  }
}

.resizable__readout {
  position: absolute;
  left: 0;
  bottom: 0;
  background: white;
  padding: 2px;
  font-size: 10px;
}
</style>
