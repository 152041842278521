<template>
  <div class="selector-option">
    <input
      :id="inputId"
      v-model="modelValue"
      :disabled="disabled || null"
      class="selector-option__input"
      :name="name"
      type="radio"
      :value="value"
      @blur="this.selectorInput.onBlur"
    />
    <label
      class="selector-option__label"
      :class="{ 'disabled-input': disabled }"
      :for="inputId"
    >
      {{ title }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'SelectorOption',
  inject: ['selectorInput'],
  props: {
    value: {
      type: null,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    name() {
      return this.selectorInput.name
    },
    inputId() {
      return `${this.name}-option-${this.value}`
    },
    modelValue: {
      get() {
        return this.selectorInput.value
      },
      set(value) {
        this.selectorInput.onChange(value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
label.disabled-input {
  color: $light-grey;
}
label.disabled-input:hover {
  cursor: not-allowed;
}
</style>
