<template>
  <collapse-provider
    class="panel panel-default"
    v-slot="{ collapsed }"
    data-testid="panel"
    :collapsed="collapsed"
    :aria-expanded="!collapsed"
    role="region"
    :aria-label="title"
  >
    <div class="collapse-panel__header">
      <collapse-toggle data-testid="collapse-panel-header">
        <h3 class="collapse-panel__title">
          <icon :icon="collapsed ? iconRight : iconDown" class="icon" />
          <span class="collapse-panel__title-text">{{ title }}</span>
        </h3>
      </collapse-toggle>
      <slot name="header" :collapsed="collapsed" />
    </div>
    <collapse-content print>
      <div class="panel-body">
        <slot />
      </div>
    </collapse-content>
  </collapse-provider>
</template>

<script>
export default {
  name: 'CollapsePanel',
  props: {
    title: {
      type: String,
      required: true
    },
    collapsed: {
      type: Boolean,
      default: true
    },
    iconRight: {
      type: String,
      default: 'chevron-right',
      required: false
    },
    iconDown: {
      type: String,
      default: 'chevron-down',
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 4px;
}

.collapse-panel__header {
  display: inline-flex;
  text-align: inherit;
  width: 100%;
  align-items: baseline;
  padding: 20px 15px;
  font-size: 20px;
}

.collapse-panel__title {
  margin: 0;
  color: $plum;
  font-weight: bold;
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
  font-size: inherit;

  &:hover,
  &:focus {
    .collapse-panel__title-text {
      text-decoration: underline;
    }
  }
}
</style>
