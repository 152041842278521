<template>
  <node-view-wrapper
    :as="isReadonly ? 'span' : 'button'"
    type="button"
    :tabindex="isReadonly ? null : -1"
    class="inline-latex"
    :class="{
      'bg-transparent': !isRandom && !(isStudentResponse && isReadonly),
      'inline-latex--selected': selected,
      'inline-latex--readonly': isReadonly,
      'bg-pivot-purple-50': isRandom,
      'bg-pivot-yellow-50': isStudentResponse,
      'text-ui-gray-800': toolTip && (isRandom || isStudentResponse)
    }"
    @click="() => !isReadonly && onClick()"
    ref="popoverAnchorRef"
  >
    <template
      v-if="isReadonly && !viewAsStudent && (isRandom || isStudentResponse)"
    >
      <base-popover
        placement="top"
        :anchor-ref="popoverAnchorRef"
        :visible="popoverVisible"
        class="inline-variable--popover"
      >
        {{ variablePopText }}
      </base-popover>
      <icon
        v-if="isStudentResponse"
        class="text-pivot-yellow-700 mr-2"
        icon="circle-info"
        @mouseover="openPopover"
        @mouseout="closePopover"
      />
      <icon
        v-else-if="isRandom"
        class="text-pivot-purple-600 mr-2"
        icon="shuffle"
        @mouseover="openPopover"
        @mouseout="closePopover"
      />
    </template>

    <span ref="katexRoot" aria-hidden="true" />
    <span class="sr-only">{{ ariaText }}</span>
  </node-view-wrapper>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import katex from 'katex'
import useLatexToText from 'src/shared/hooks/mathjax'
import {
  getVariablesFromLatex,
  expressionToBoxedLatex
} from 'src/shared/utils/latex-methods'

const props = defineProps(nodeViewProps)
const popoverAnchorRef = ref(null)
const popoverVisible = ref(false)

const closePopover = () => {
  popoverVisible.value = false
}
const openPopover = () => {
  popoverVisible.value = true
}

const katexRoot = ref(null)
const ariaText = ref('')
const { toText } = useLatexToText()
toText(props.node.attrs.formula).then(text => {
  ariaText.value = text
})

const editorOptions = ref({ ...props.editor.options })
const isReadonly = computed(() => !props.editor.isEditable)
const viewAsStudent = computed(() => props.extension.storage.viewAsStudent)
const toolTip = computed(() => props.extension.storage.toolTip)

const usedVariables = computed(() =>
  isReadonly.value
    ? getVariablesFromLatex({
        formula: props.node.attrs.formula,
        variables: props.extension.storage.variableContext?.variables
      })
    : []
)
const isRandom = computed(() =>
  isReadonly.value && !viewAsStudent.value
    ? usedVariables.value.some(
        v => v.variableType === 'random' || v.variableType === 'collection'
      )
    : false
)
const isStudentResponse = computed(() =>
  isReadonly.value && !viewAsStudent.value
    ? usedVariables.value.some(v => v.variableType === 'studentResponse')
    : false
)

const variablePopText = computed(() => {
  if (isStudentResponse.value) {
    const usedVariablesWithSections = usedVariables.value.filter(
      variable => 'section' in variable && variable.question !== undefined
    )
    if (
      usedVariablesWithSections.length > 0 &&
      usedVariablesWithSections.some(item => item.section)
    ) {
      const dependentQuestionList = usedVariablesWithSections
        .map(item => `S${item.section}.Q${item.question}`)
        .join(',\n')
      return `This value is a calculation from responses to the following questions: ${dependentQuestionList}`
    } else {
      return `Complete responses to previous questions to see this value.`
    }
  } else if (isRandom.value) {
    return 'This value is random for each student.'
  } else {
    return ''
  }
})
watchEffect(() => {
  editorOptions.value = { ...props.editor.options }
  if (katexRoot.value) {
    let toRenderIfReadonly = ''
    if (isReadonly.value) {
      try {
        const latexForDisplay = props.extension.options.getLatexForDisplay({
          variables: props.extension.storage.variableContext?.variables,
          node: props.node
        })
        if (latexForDisplay) {
          toRenderIfReadonly = latexForDisplay
        }
      } catch (_e) {}
    }

    const toRender = isReadonly.value
      ? toRenderIfReadonly
      : expressionToBoxedLatex(props.node.attrs.formula)
    katex.render(toRender, katexRoot.value, {
      throwOnError: false
    })
  }
})

const onClick = () => {
  props.editor.commands.openLatexModal()
}
</script>

<style lang="scss" scoped>
.inline-latex {
  display: inline-block;
  margin: 0px -2px;
  padding: 4px;
  border-radius: 4px;
  border: 0px;

  &:hover:not(.inline-latex--readonly),
  &:focus:not(.inline-latex--readonly),
  &--selected:not(.inline-latex--readonly) {
    background: $light-teal;
  }
}
</style>
