<template>
  <UIButton type="outline" @click="showSearchModal" :size="size">
    <icon icon="search" />
    <span class="sr-only">Search Activities</span>
  </UIButton>
</template>

<script setup lang="ts">
import ActivitySearchModal from './ActivitySearchModal'
import { UIButton } from '@pi/design'
import { ref, computed, inject } from 'vue'

interface ActivitySearchActionProps {
  size?: string
}

const browseActivities = inject('browseActivities')
const $modal = inject('$modal')
const emit = defineEmits(['click'])
const props = defineProps<ActivitySearchActionProps>()

const showSearchModal = async () => {
  emit('click')
  const { status, data } = await $modal.show(ActivitySearchModal, {
    query: browseActivities.value.query
  })

  if (status === 'ok' && data) {
    browseActivities.value.updateQuery(data.updatedQuery)
  }
}
</script>
