<template>
  <div class="browse-licenses-layout">
    <div class="browse-licenses-layout__content">
      <div class="browse-licenses-layout__sidebar">
        <slot name="sidebar" />
      </div>
      <div class="browse-licenses-layout__results">
        <div class="browse-licenses-layout__search">
          <slot name="search" />
        </div>
        <div class="browse-licenses-layout__summary">
          <slot name="summary" />
        </div>
        <div class="browse-licenses-layout__licenses">
          <slot name="licenses" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrowseLicensesLayout'
}
</script>

<style lang="scss" scoped>
.browse-licenses-layout {
  display: flex;
  flex-direction: column;
  margin-top: 6px;

  &__sidebar {
    width: 200px;
  }

  &__content {
    display: flex;
    flex-direction: row;
  }

  &__results {
    flex: 1;
  }

  &__summary {
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
  }
}
</style>
