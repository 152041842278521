<template>
  <div class="footer-image" aria-hidden="true">
    <img class="footer-image__img hidden-print" src="~assets/img/footer.png" />
  </div>
</template>

<script>
export default {
  name: 'FooterImage'
}
</script>

<style lang="scss">
.footer-image {
  margin-bottom: 350px;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 175px;
  }

  @media (max-width: $screen-md-max) {
    margin-bottom: 250px;
  }
}

.footer-image__img {
  width: 100%;
  height: 350px;
  position: absolute;
  bottom: 60px;
  left: 0;
  z-index: -1;

  @media (max-width: $screen-md-max) {
    height: 250px;
  }

  @media (max-width: $screen-xs-max) {
    height: 175px;
    bottom: 78px;
  }
}
</style>
