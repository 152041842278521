<template>
  <view-container normal>
    <view-header>
      <view-title>Canvas LTI Registration</view-title>
    </view-header>
    <loading-container :loading="loadingConfig">
      <section>
        <collapse-panel title="Step 1: Configure Pivot Interactives in Canvas">
          <form-group>
            <strong>
              You must be an admin in Canvas to perform this step.
            </strong>
          </form-group>
          <ol>
            <li>
              <form-group>
                In Canvas, find the <strong>Developer Keys</strong> menu under
                the <strong>Admin</strong> menu.
              </form-group>
            </li>
            <li>
              <form-group>
                In the top right corner, click the
                <strong>+ Developer Key</strong> button and add an
                <strong>LTI Key</strong>.
              </form-group>
            </li>
            <li>
              <form-group>
                Enter Pivot Interactives into the
                <strong>Key Name</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Pivot Interactives"
                  value="Pivot Interactives"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the text below into the
                <strong>Redirect URIs</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Redirect URIs"
                  :value="config.target_link_uri || ''"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Click the <strong>Method</strong> field, and select
                <strong>Paste JSON</strong>.
              </form-group>
            </li>
            <li>
              <form-group>
                Copy the text below and replace the contents of the
                <strong>LTI 1.3 Configuration</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="LTI Configuration"
                  :value="configString"
                  message="Copied LTI configuration to clipboard."
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Click the <strong>Save and Customize</strong> button.
              </form-group>
            </li>
            <li>
              <form-group>
                If there is an option to, please set the
                <strong>Privacy Level</strong> to Public. This allows teachers
                to identify students in Pivot when they grade assignments. It
                may not be configurable in your Canvas installation.
              </form-group>
            </li>
            <li>
              <form-group>
                Review the configuration for Pivot Interactives within Canvas
                and then click the <strong>Save</strong> button when you are
                done.
              </form-group>
            </li>
            <li>
              <form-group>
                After you are redirected, find the key that was just created,
                and change its state to <strong>On</strong>.
              </form-group>
            </li>
            <li>
              <form-group>
                Copy the shorter number in the <strong>Details</strong> column.
                You will need that later. Do not copy the longer number that
                appears when clicking the View Key button.
              </form-group>
            </li>
          </ol>
        </collapse-panel>
      </section>
      <section>
        <collapse-panel title="Step 2: Configure Canvas in Pivot Interactives">
          <async-form @submit="register" persist>
            <ol>
              <li>
                <form-group>
                  Enter the number you copied from Canvas in the field below:
                </form-group>
                <form-group>
                  <text-input
                    v-model="platformData.clientId"
                    aria-label="Canvas Client ID"
                    placeholder="Example: 10000000000014"
                    label="client ID"
                    rules="required"
                  />
                </form-group>
              </li>
              <li>
                <form-group>
                  Enter the domain name you use to access Canvas in the field
                  below. Most often, this will be something like
                  <strong>your-institution.instructure.com</strong>.
                </form-group>
                <form-group>
                  <text-input
                    v-model="platformData.domain"
                    aria-label="Canvas Domain Name"
                    placeholder="Example: canvas.instructure.com"
                    label="domain name"
                    :rules="{
                      required: true,
                      regex: /^((\w|\d|-)+\.)+(\w|\d|-)+(:\d+)?$/
                    }"
                  />
                </form-group>
              </li>
              <li>
                <form-group>
                  When ready, click the button to finish registering Canvas.
                </form-group>
                <form-group>
                  <submit-button>
                    <template #default>Register</template>
                    <template #submitting>Configuring</template>
                    <template #submitted>Success</template>
                  </submit-button>
                </form-group>
              </li>
            </ol>
          </async-form>
        </collapse-panel>
      </section>
      <section>
        <collapse-panel title="Step 3: Install Pivot Interactives in Canvas">
          <ol>
            <li>
              <form-group>
                To install Pivot Interactives for the entire institution, find
                the
                <strong>Settings</strong> menu under the
                <strong>Admin</strong> menu.
                <strong>
                  You must be an admin in Canvas to perform this step.
                </strong>
                <div><em>or</em></div>
                To install Pivot Interactives for a specific class, find the
                <strong>Settings</strong>
                menu under the course menu.
              </form-group>
            </li>
            <li>
              <form-group>
                In the <strong>Apps</strong> tab, click the
                <strong>+ App</strong> button.
              </form-group>
            </li>
            <li>
              <form-group>
                Select <strong>By Client ID</strong> in the
                <strong>Configuration Type</strong> field.
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the number from Step 2 into the
                <strong>Client ID</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Canvas Client ID"
                  :value="platformData.clientId"
                />
              </form-group>
            </li>
            <li>
              <form-group
                >Click the <strong>Sumbit</strong> button and, when prompted,
                the <strong>Install</strong> button.</form-group
              >
            </li>
            <li>
              <form-group>
                Congratulations! Pivot is now connected and installed to Canvas.
              </form-group>
            </li>
          </ol>
        </collapse-panel>
      </section>
    </loading-container>
  </view-container>
</template>

<script>
import ClickToCopyField from 'src/shared/components/ClickToCopyField'
import client from 'src/shared/api-client'

export default {
  name: 'LtiPlatformRegistrationCanvasView',
  components: { ClickToCopyField },
  data() {
    return {
      loadingConfig: true,
      config: {},
      platformData: {
        clientId: '',
        domain: ''
      }
    }
  },
  computed: {
    configString() {
      return JSON.stringify(this.config)
    }
  },
  methods: {
    async register(e) {
      try {
        const protocol = PI_IS_LOCAL ? 'http://' : 'https://'
        const { clientId, domain } = this.platformData
        await client.lti.createPlatform({
          name: 'Canvas',
          issuer: 'https://canvas.instructure.com',
          clientId,
          oidcUrl: `${protocol}${domain}/api/lti/authorize_redirect`,
          oauthUrl: `${protocol}${domain}/login/oauth2/token`,
          oauthAud: `${protocol}${domain}/login/oauth2/token`,
          jwkUrl: `${protocol}${domain}/api/lti/security/jwks`
        })
        this.$success('Canvas successfully configured in Pivot.')
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    async loadConfig() {
      this.loadingConfig = true
      try {
        const config = await client.lti.getConfig({ platform: 'canvas' })
        this.config = config
      } finally {
        this.loadingConfig = false
      }
    }
  },
  async created() {
    await this.loadConfig()
  }
}
</script>
