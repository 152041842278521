<template>
  <div
    v-bind:class="{
      'library-description--wrapper full-width': isEmpty,
      'library-description--wrapper wrapper-padding full-width': !isEmpty
    }"
  >
    <p v-if="!licenseActive" class="library-description--inactive full-width">
      <strong>
        You aren't able to edit activities because you don't have an active
        license.
      </strong>
      Purchase a license renewal or join an active license to get access to your
      activities.
    </p>
    <p v-if="isPersonalLibrary" class="library-description--owner full-width">
      <strong> You are the owner of this library </strong>
    </p>
    <p v-else-if="owner" class="library-description--owner">
      <strong>
        Library Owner: <a :href="`mailto:${owner.email}`">{{ owner.name }}</a>
      </strong>
    </p>
    <p v-if="library?.description" class="library-description full-width">
      {{ library.description }}
    </p>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'LibraryDescription',
  props: {
    library: {
      type: Object
    }
  },
  setup(props) {
    const store = useStore()
    const userId = store.getters.userId
    const licenseActive = store.getters.isLicenseActive

    const owner = computed(() => {
      const ownerLookup = props.library?.members?.find(
        member => member.permissions === 'owner'
      )

      return ownerLookup && ownerLookup.id !== userId
        ? {
            name: `${ownerLookup.firstName} ${ownerLookup.lastName}`,
            email: ownerLookup.email
          }
        : undefined
    })

    const isPersonalLibrary = computed(() => {
      const ownerLookup = props.library?.members?.find(
        member => member.permissions === 'owner'
      )
      return ownerLookup && ownerLookup.id === userId
    })
    return {
      licenseActive,
      isPersonalLibrary,
      isEmpty: computed(() => {
        return (
          licenseActive &&
          !isPersonalLibrary.value &&
          !owner.value &&
          !props.library?.description
        )
      }),
      owner
    }
  }
}
</script>

<style lang="scss" scoped>
.library-description--wrapper {
  background-color: #f8f8f8;
}
.wrapper-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}
.library-description--inactive {
  padding-top: 15px;
  background-color: #f8f8f8;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
}
.library-description--owner {
  background-color: #f8f8f8;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
}
.library-description {
  padding-bottom: 0px;
}
</style>
