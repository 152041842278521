<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ProgressElement',
  inject: ['progress'],
  props: {
    elementId: {
      type: [String, Number]
    },
    title: {
      type: String
    }
  },
  mounted() {
    this.progress.registerElement({
      id: this.elementId,
      title: this.title,
      el: this.$el
    })
  },
  updated() {
    this.progress.updateElement(this.elementId, {
      id: this.elementId,
      title: this.title,
      el: this.$el
    })
  },
  unmounted() {
    this.progress.unregisterElement(this.elementId)
  }
}
</script>
