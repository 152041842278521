import * as Sentry from '@sentry/vue'
import * as Integrations from '@sentry/integrations'

export default app => {
  if (window.PI_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: window.PI_SENTRY_DSN,
      integrations: [new Integrations.ExtraErrorData()],
      environment: PI_ENV,
      release: `web@v${PI_VERSION}`,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      beforeSend(event, hint) {
        // Filter out HTTP requests.
        if (hint.originalException?.request) {
          return null
        }

        // Filter out this unhandled exception caused by browser extensions.
        try {
          if (
            event.extra.__serialized__.detail.reason.message ===
            'Extension context invalidated.'
          )
            return null
        } catch {}

        // This error comes from Microsoft servers crawling our site.
        // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/20
        try {
          if (hint.originalException.includes('Object Not Found Matching Id:'))
            return null
        } catch {}

        // Pretty confident this error is from a browser extension, but not 100% sure.
        try {
          if (hint.originalException.message.includes('no instance for')) {
            return null
          }
        } catch {}

        // This appears to be an error within AppleMail.
        // Nothing in our app uses the class name mce-visual-caret-hidden.
        try {
          if (
            hint.originalException.message.includes(
              `document.getElementsByClassName('hide-icon hidden mce-visual-caret-hidden')[0].remove`
            )
          ) {
            return null
          }
        } catch {}

        try {
          if (
            hint.originalException.stack.includes('postMessage') &&
            hint.originalException.stack.includes('phetio.js')
          ) {
            return null
          }
        } catch {}

        return event
      }
    })
  }
}
