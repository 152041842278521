<template>
  <div
    ref="popoverAnchorRef"
    @mouseover="openPopover"
    @mouseout="closePopover"
    @focusin="openPopover"
    @focusout="closePopover"
  >
    <base-popover
      :anchor-ref="popoverAnchorRef"
      :visible="popoverVisible"
      placement="top"
      >This cell is pre-populated and cannot be edited
    </base-popover>
    <span>{{ valueForDisplay }}</span>
  </div>
</template>
<script setup lang="ts">
import {
  replaceVariablesWithNames,
  evaluateExpression,
  type VariableConfig
} from '@pi/shared/variables'
import { computed, ref } from 'vue'
import { parseCellNumber } from './utilities'

interface VariableContextConfig {
  variables: VariableConfig[]
  hasStudentResponse?: boolean
}

interface Props {
  expression: string
  readOnly: boolean
  variableContext?: VariableContextConfig
  format?: {
    type: string
    decimalPlaces?: number
    significantFigures?: number
  }
}
const popoverAnchorRef = ref()
const popoverVisible = ref<boolean>(false)
const props = defineProps<Props>()

const closePopover = () => {
  popoverVisible.value = false
}
const openPopover = () => {
  popoverVisible.value = true
}

const formatExpressionValue = (value: number) => {
  if (props.format) {
    const float = parseCellNumber(value)
    if (isNaN(float)) {
      return value
    }

    switch (props.format.type) {
      case 'scientific':
        return float.toExponential()
      case 'decimals':
        return float.toFixed(props.format.decimalPlaces)
      case 'sigfigs':
        return float.toPrecision(props.format.significantFigures)
      case 'auto':
      default:
        return value
    }
  } else {
    return value
  }
}

const valueForDisplay = computed<string>(() => {
  return props.readOnly
    ? formatExpressionValue(
        evaluateExpression(
          props.expression,
          props.variableContext?.variables ?? []
        )
      )
    : replaceVariablesWithNames(
        props.expression,
        props.variableContext?.variables ?? []
      )
})
</script>
