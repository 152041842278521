<template>
  <variable-provider
    v-slot="variableContext"
    :variables="activity.variables"
    :values="response.variables"
    :activity
  >
    <view-container wide class="activity">
      <slot
        :variableContext="variableContext.variableContext"
        :view-mode="computedViewMode"
        :toggle-split-view="toggleSplitView"
        :split-view-available="splitViewAvailable"
      />
      <footer-image />
    </view-container>
  </variable-provider>
</template>

<script>
import * as Vue from 'vue'
import { computed, ref } from 'vue'
import FooterImage from 'src/shared/components/FooterImage'
import VariableProvider from 'src/shared/components/VariableProvider'
import useElementSize from 'src/shared/hooks/element-size'

export default {
  name: 'Activity',
  components: { FooterImage, VariableProvider },
  provide() {
    return {
      inherited: Vue.computed(() => ({
        activity: this.activity,
        response: this.response,
        isOverdue: this.isOverdue,
        isSubmitted: this.isSubmitted,
        isReopened: this.isReopened,
        checkComponentDependencies: this.checkComponentDependencies,
        dependenciesSatisfied: this.dependenciesSatisfied,
        prevSubmittableComponentMap: this.prevSubmittableComponentMap
      }))
    }
  },
  props: {
    activity: {
      type: Object,
      required: true
    },
    response: {
      type: Object,
      default: () => ({ responses: [], lockedResponses: [] })
    },
    isOverdue: {
      type: Boolean,
      default: false
    },
    isSubmitted: {
      type: Boolean,
      default: false
    },
    isReopened: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const windowSize = useElementSize(document.body)
    const viewMode = ref(props.activity.isSplitViewDefault ? 'split' : 'inline')
    const splittableComponents = [
      'EmbeddedInstance',
      'StudentInstance',
      'InstructorInstance',
      'IFrame',
      'PhetIOSim',
      'PhetIOSimulation'
    ]

    const isSmallScreen = computed(() => windowSize.value.width < 993)
    const splitViewComponentEnabled = computed(
      () => props.activity.splitViewComponentEnabled
    )
    const splitViewAvailable = computed(() => {
      const components = (props.activity?.sections || [])
        .map(section => section?.components)
        .flat()

      return (
        (!splitViewComponentEnabled.value &&
          components.some(component =>
            splittableComponents.includes(component.componentType)
          )) ||
        components.some(component => component.componentType === 'SplitView')
      )
    })

    return {
      computedViewMode: computed(() =>
        isSmallScreen.value || !splitViewAvailable.value
          ? 'inline'
          : viewMode.value
      ),
      toggleSplitView() {
        if (!isSmallScreen.value) {
          if (viewMode.value === 'inline') {
            viewMode.value = 'split'
          } else {
            viewMode.value = 'inline'
          }
        }
      },
      splitViewAvailable
    }
  },
  computed: {
    activityComponents() {
      return this.activity.sections.flatMap(section => {
        const flattenedComponents = section.components.flatMap(component => {
          if (component.componentType === 'SplitView') {
            return [...component.leftContent, ...component.rightContent]
          } else {
            return component
          }
        })
        return flattenedComponents.map(c => ({
          sectionName: section.name,
          sectionNumber: section.sectionNumber,
          ...c
        }))
      })
    },
    prevSubmittableComponentMap() {
      const componentMap = {}
      let lastSubmittable

      for (const component of this.activityComponents) {
        componentMap[component._id] = lastSubmittable
        const isQuestion = component.componentType.includes('Question')
        if (component.autograde) {
          lastSubmittable = component._id
        } else if (isQuestion) {
          lastSubmittable = undefined
        }
      }
      return componentMap
    },
    allowIncorrectDependencies() {
      return (
        !this.response.assignment.showFeedback &&
        !this.response.assignment.showIndicators
      )
    }
  },

  methods: {
    checkComponentDependencies() {
      const activityComponents = this.activity.sections
        .map(section => section.components)
        .flat()
        .map(c =>
          c.componentType === 'SplitView'
            ? [...c.leftContent, ...c.rightContent]
            : c
        )
        .flat()
      activityComponents.forEach(component => {
        if (this.componentDependenciesSatisfied(component)) {
          component.dependenciesSatisfied = true
          component.componentDependenciesRemaining = []
        } else {
          component.dependenciesSatisfied = false
          component.componentDependenciesRemaining =
            this.getUnsatisifiedComponentDependencies(component)
        }
      })
    },
    getUnsatisifiedComponentDependencies(component) {
      return (component?.dependencies ?? [])
        .filter(dependency => {
          const resp = this.response.responses.find(
            resp => resp.component === dependency
          )
          return (
            !resp ||
            this.allowIncorrectDependencies ||
            (typeof resp.correct !== 'undefined'
              ? !resp.correct
              : !resp.isCorrect)
          )
        })
        .map(dependency =>
          this.activityComponents.find(
            component => component._id === dependency
          )
        )
    },

    componentDependenciesSatisfied(component) {
      return (component?.dependencies ?? []).every(dep => {
        return (
          this.activityComponents.some(component => component._id === dep) &&
          this.response.responses.some(resp => {
            return (
              resp.component === dep &&
              ((typeof resp.correct !== 'undefined'
                ? resp.correct
                : resp.isCorrect) ||
                this.allowIncorrectDependencies)
            )
          })
        )
      })
    }
  }
}
</script>

<style lang="scss">
.activity {
  font-size: $student-response-font-size-base;

  @media print {
    font-size: $print-font-size-base;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    h1 {
      font-size: ceil($print-font-size-base * 1.5);
    }

    h2 {
      font-size: ceil($print-font-size-base * 1.25);
    }

    h3 {
      font-size: ceil($print-font-size-base * 1.15);
    }

    .lead {
      font-size: $print-font-size-base;
    }
  }
}
</style>
