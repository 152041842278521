<template>
  <collapse collapsed>
    <template #header="{ collapsed }">
      <div class="sidebar-collapse__header">
        <icon
          class="sidebar-collapse__icon"
          :icon="`${collapsed ? 'caret-right' : 'caret-down'}`"
        />
        <span><slot name="header" /></span>
      </div>
    </template>
    <template #body><slot name="body" /></template>
  </collapse>
</template>

<script>
import Collapse from 'src/shared/components/Collapse'

export default {
  name: 'SidebarCollapse',
  components: { Collapse }
}
</script>

<style lang="scss" scoped>
.sidebar-collapse {
  &__header {
    min-height: 44px;
    display: block;
    padding: 8px 0 8px 0;
    position: relative;
    font-weight: normal;

    @media (min-width: $screen-md) {
      margin-left: 18px;
    }
  }

  &__icon {
    margin-right: 8px;
  }
}
</style>
