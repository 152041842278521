<template>
  <ul
    class="tree-list"
    role="tree"
    aria-multiselectable="true"
    @focus="onFocus"
  >
    <tree-input-item
      v-for="(child, i) in tree"
      v-model="mutableValue"
      :start-focus="i === 0"
      :ref="el => (childElements[i] = el)"
      :key="child.id"
      :node="child"
      :name="name"
      @movefocus="arg => onFocusMove(i, arg)"
      :select-children="selectChildren"
    />
  </ul>
</template>

<script>
import { ref, computed } from 'vue'
import TreeInputItem from './TreeInputItem.vue'

export default {
  name: 'TreeInput',
  components: { TreeInputItem },
  emits: ['update:modelValue'],
  props: {
    tree: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    selectChildren: {
      type: Boolean,
      default: true
    }
  },

  setup(props, { emit }) {
    const name = `tree-input-${Math.round(Math.random() * 100000)}`
    const mutableValue = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    })
    const childElements = ref([])
    const onFocusMove = (index, { direction }) => {
      switch (direction) {
        case 'next': {
          childElements.value[index + 1]?.focus()
          break
        }
        case 'prev': {
          childElements.value[index - 1]?.focus('prev')
          break
        }
      }
    }

    return { mutableValue, childElements, name, onFocusMove }
  }
}
</script>

<style lang="scss" scoped>
.tree-list {
  padding: 0;
  list-style: none;
}
</style>
