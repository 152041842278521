import type Client from './client'
import {
  CreateLibraryRequest,
  GetLibraryResponse,
  GetLibrariesResponse,
  UpdateLibraryRequest,
  AddMemberToLibraryRequest,
  UpdateLibraryMemberPermissionsRequest,
  SearchActivitiesRequest,
  SearchActivitiesResponse,
  GetFiltersResponse,
  GetLibraryAutocompleteFiltersResponse,
  GetLibraryAutocompleteFiltersRequest,
  CreateActivityRequest,
  SaveActivityResponse
} from '@pi/api-types'

export default class Libraries {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }
  async create(body: CreateLibraryRequest): Promise<string | undefined> {
    const { headers } = await this.client.post({
      path: '/api/v3/libraries',
      body: JSON.stringify(body)
    })
    const location = headers.get('location')
    return location?.split('/').slice(-1)[0]
  }
  async list(): Promise<GetLibrariesResponse> {
    const { body } = await this.client.get<GetLibrariesResponse>({
      path: `/api/v3/libraries`
    })
    return body
  }
  async get(id: string): Promise<GetLibraryResponse> {
    const { body } = await this.client.get<GetLibraryResponse>({
      path: `/api/v3/libraries/${id}`
    })
    return body
  }
  async update(request: UpdateLibraryRequest): Promise<void> {
    await this.client.patch({
      path: `/api/v3/libraries/${request.id}`,
      body: JSON.stringify(request)
    })
  }
  async setIsArchived(id: string, isArchived = true): Promise<void> {
    await this.client.put({
      path: `/api/v3/libraries/${id}/archive`,
      body: JSON.stringify({
        isArchived
      })
    })
  }
  async validateJoinLink(id: string): Promise<string> {
    const { body } = await this.client.get<string>({
      path: `/api/v3/libraries/${id}/join-link`
    })
    return body
  }
  async addMember(request: AddMemberToLibraryRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/libraries/${request.id}/members`,
      body: JSON.stringify(request)
    })
  }
  async updateMember(
    request: UpdateLibraryMemberPermissionsRequest
  ): Promise<void> {
    await this.client.patch({
      path: `/api/v3/libraries/${request.libraryId}/members/${request.memberId}`,
      body: JSON.stringify({
        permissions: request.permissions
      })
    })
  }
  async removeMember(libraryId: string, memberId: string): Promise<void> {
    await this.client.delete({
      path: `/api/v3/libraries/${libraryId}/members/${memberId}`
    })
  }
  async searchActivities(
    request: SearchActivitiesRequest
  ): Promise<SearchActivitiesResponse> {
    const { libraryId, ...query } = request
    const params = new URLSearchParams()

    Object.entries(query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(value => params.append(`${key}[]`, value.toString()))
      } else {
        params.append(key, value as string)
      }
    })
    const response = await this.client.get<SearchActivitiesResponse>({
      path: `/api/v3/libraries/${libraryId}/activities?${params}`
    })
    return response.body
  }
  async searchAutocompleteFilters(
    request: GetLibraryAutocompleteFiltersRequest
  ): Promise<GetLibraryAutocompleteFiltersResponse> {
    const params = new URLSearchParams({
      text: request.text,
      limit: request.limit?.toString() || ''
    })
    const response =
      await this.client.get<GetLibraryAutocompleteFiltersResponse>({
        path: `/api/v3/activities/filters/autocomplete?${params}`
      })
    return response.body
  }
  async getFilters(): Promise<GetFiltersResponse> {
    const response = await this.client.get<GetFiltersResponse>({
      path: `/api/v3/activities/filters`
    })
    return response.body
  }

  async createActivity({ libraryId, ...request }: CreateActivityRequest) {
    const { headers } = await this.client.post<SaveActivityResponse>({
      path: `/api/v3/libraries/${libraryId}/activities`,
      body: JSON.stringify(request)
    })
    const location = headers.get('location')
    if (!location) {
      throw new Error('Location header not found')
    }
    return location?.split('/').slice(-1)[0]
  }
}
