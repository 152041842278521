<template>
  <router-link
    :to="{ name: 'preview_activity', params: { id: activity.id } }"
    @click="onClick"
  >
    <div class="activity-card">
      <div class="activity-card__thumbnail">
        <img role="presentation" :src="thumbnail" />
      </div>
      <span v-if="activity.isArchived" class="activity-card__archived-label">
        Archived
      </span>
      <p class="activity-card__name">{{ activity.name }}</p>
    </div>
  </router-link>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import defaultThumbnail from 'src/assets/img/activity-card/pivot-interactives-logo-color.png'

const props = defineProps({
  activity: {
    type: Object,
    required: true
  }
})

const { activity } = toRefs(props)

const emit = defineEmits(['click'])
const thumbnail = computed(() => {
  return activity.value.thumbnail ?? defaultThumbnail
})

const onClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
.activity-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.activity-card__thumbnail {
  width: 170px;
  height: 95px;
  padding-bottom: 56.25%;
  margin: 0 0 16px 0;
  position: relative;
  background: #efefef;
  border-radius: 8px;
  overflow: hidden;

  img {
    position: absolute;
    height: 100%;
    margin: auto;
  }
}

.activity-card__name {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 0 10px;
  color: #333333;
  height: 75px;
  text-overflow: ellipsis;
}

.activity-card__free-label {
  position: absolute;
  margin: -12px 0 0 16px;
  display: block;
  padding: 4px 12px;
  line-height: 100%;
  color: white;
  background-color: $plum;
  border-radius: 4px;
}

.activity-card__archived-label {
  position: absolute;
  margin: -12px 0 0 16px;
  display: block;
  padding: 4px 12px;
  line-height: 100%;
  color: white;
  background-color: $dark-grey;
  border-radius: 4px;
}
</style>
