<template>
  <div>
    <template v-if="lazy">
      <slot :name="tabsInfo.currentTab" />
    </template>
    <template v-else>
      <div
        v-for="slot in Object.keys($slots)"
        :key="slot"
        v-show="tabsInfo.currentTab === slot"
      >
        <slot :name="slot" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TabPanel',
  inject: ['tabsInfo'],
  props: {
    lazy: {
      type: Boolean,
      default: true
    }
  }
}
</script>
