<template>
  <li>
    <hyper-link
      class="nav-link"
      :class="{ disabled: disabled }"
      :to="disabled ? '' : to"
    >
      <slot />
    </hyper-link>
  </li>
</template>

<script>
export default {
  name: 'NavLink',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: null,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
  font-size: ceil($font-size-base * 1.14);
  line-height: 33px;
  color: $plum;
  padding-top: 5px;

  &:hover,
  &:focus {
    // Have to use important because of bootstrap.
    text-decoration: underline;
    color: inherit !important;
    background-color: inherit !important;
  }

  &.disabled {
    color: grey !important;
  }
}
</style>
