<template>
  <tr v-for="rowIndex in numRows" :key="rowIndex - 1">
    <data-grid-row-header
      :num-rows="numRows"
      :row-index="rowIndex - 1"
      :edit-mode="editMode"
      @focus:cell="
        () => $emit('focus:cell', { column: null, row: null, value: null })
      "
    />

    <data-grid-cell
      v-for="(column, columnIndex) in columnsArray"
      :key="columnIndex"
      :column="column"
      :column-index="columnIndex"
      :row-index="rowIndex - 1"
      :readonly="readonly"
      :variable-context="variableContext"
      :is-focused="getIsFocused(columnIndex, rowIndex - 1)"
      :edit-mode="editMode"
      @focus:cell="props => $emit('focus:cell', props)"
    />
  </tr>
</template>

<script>
import * as Y from 'yjs'
import DataGridRowHeader from 'src/shared/components/grid-graph/DataGridRowHeader'
import DataGridCell from 'src/shared/components/grid-graph/DataGridCell'

export default {
  name: 'DataGridBody',
  components: {
    DataGridRowHeader,
    DataGridCell
  },
  emits: ['focus:cell'],
  props: {
    numRows: {
      type: Number,
      required: true
    },
    columns: {
      type: Y.Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    focusedCell: {
      type: Object,
      default: null
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnsArray: this.columns.toArray()
    }
  },
  methods: {
    getIsFocused(columnIndex, rowIndex) {
      const focusedArray = this.focusedCell
      if (!focusedArray) return false
      return (
        focusedArray.column === columnIndex && focusedArray.row === rowIndex
      )
    }
  },
  watch: {
    columns: {
      handler() {
        this.unsubscribe?.()
        const columns = this.columns
        const onChange = () => {
          this.columnsArray = columns.toArray()
        }
        onChange()
        columns.observe(onChange)
        this.unsubscribe = () => columns.unobserve(onChange)
      },
      immediate: true
    }
  },
  beforeUnmount() {
    this.unsubscribe?.()
  }
}
</script>
