import {
  CreateLtiPlatformRequest,
  DeleteLtiPlatformRequest,
  GetLtiPlatformRequest,
  GetLtiPlatformResponse,
  ConnectLtiAssignmentRequestV3,
  ConnectLtiClassRequestV3,
  ConnectLtiClassesRequestV3,
  GetLtiSessionResponse,
  UpdateLtiPlatformRequest,
  LtiConfigRequest,
  LtiConfig,
  SwitchLtiSectionRequest,
  ConnectLtiTemplateRequest,
  ConnectLtiTemplateResponse,
  CreateLtiAssignmentBulkRequest,
  CreateLtiPlatformForLicenseRequest
} from '@pi/api-types'
import type Client from './client'

export default class Lti {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async getConfig(request: LtiConfigRequest): Promise<LtiConfig> {
    const { body } = await this.client.get<LtiConfig>({
      path: `/api/v3/lti/config/${request.platform}`
    })
    return body
  }
  async getPlatforms(): Promise<GetLtiPlatformResponse[]> {
    const { body } = await this.client.get<GetLtiPlatformResponse[]>({
      path: `/api/v3/lti/platforms/`
    })
    return body
  }
  async getPlatform(
    request: GetLtiPlatformRequest
  ): Promise<GetLtiPlatformResponse> {
    const { body } = await this.client.get<GetLtiPlatformResponse>({
      path: `/api/v3/lti/platforms/${request.id}`
    })
    return body
  }
  async getLtiForLicense(
    request: GetLtiPlatformRequest
  ): Promise<GetLtiPlatformResponse | undefined> {
    const { body } = await this.client.get<GetLtiPlatformResponse>({
      path: `/api/v3/licenses/${request.licenseId}/lti`
    })
    return body
  }

  async connectLtiToLicense({
    licenseId,
    ...request
  }: CreateLtiPlatformForLicenseRequest): Promise<string | void> {
    const { headers } = await this.client.post({
      path: `/api/v3/licenses/${licenseId}/lti`,
      body: JSON.stringify(request)
    })
    const location = headers.get('location')
    return location?.split('/').slice(-1)[0]
  }
  async createPlatform(
    request: CreateLtiPlatformRequest
  ): Promise<string | void> {
    const { headers } = await this.client.post({
      path: `/api/v3/lti/platforms/`,
      body: JSON.stringify(request)
    })
    const location = headers.get('location')
    return location?.split('/').slice(-1)[0]
  }
  async updatePlatform(request: UpdateLtiPlatformRequest): Promise<void> {
    await this.client.put({
      path: `/api/v3/lti/platforms/${request.id}`,
      body: JSON.stringify(request)
    })
    return
  }

  async deletePlatform(request: DeleteLtiPlatformRequest): Promise<void> {
    await this.client.delete({
      path: `/api/v3/lti/platforms/${request.id}`
    })
  }

  async connectAccount(): Promise<void> {
    const { body } = await this.client.post<void>({
      path: `/api/v3/lti/account/connect`
    })
    return body
  }

  async cancelConnection(): Promise<any> {
    const { body } = await this.client.post<{
      token: string
      openedInNewTab: boolean
    }>({
      path: `/api/v3/lti/cancel`
    })
    return body
  }

  async connectAssignment(
    request: ConnectLtiAssignmentRequestV3
  ): Promise<any> {
    const params = JSON.stringify(request)
    const { body } = await this.client.post<{
      token: string
      openedInNewTab: boolean
    }>({
      path: `/api/v3/lti/assignments/connect`,
      body: params
    })
    return body
  }

  async addAssignments(request: CreateLtiAssignmentBulkRequest): Promise<void> {
    const params = JSON.stringify(request)
    const { body } = await this.client.post({
      path: `/api/v3/lti/classes/assign/bulk/`,
      body: params
    })
    return body
  }

  async connectClass(request: ConnectLtiClassRequestV3): Promise<void> {
    const params = JSON.stringify(request)
    const { body } = await this.client.post<void>({
      path: `/api/v3/lti/classes/connect`,
      body: params
    })
    return body
  }

  async connectClasses(request: ConnectLtiClassesRequestV3): Promise<void> {
    const params = JSON.stringify(request)
    const { body } = await this.client.post<void>({
      path: `/api/v3/lti/sections/connect`,
      body: params
    })
    return body
  }

  async switchSection(sectionId: string): Promise<void> {
    const body: SwitchLtiSectionRequest = {
      sectionId: sectionId
    }
    await this.client.post<void>({
      path: `/api/v3/lti/classes/switch`,
      body: JSON.stringify(body)
    })
  }

  async disconnectClass(): Promise<void> {
    const response = await this.client.post<void>({
      path: `/api/v3/lti/classes/disconnect`
    })
    return response.body
  }

  async getSession(): Promise<GetLtiSessionResponse> {
    const response = await this.client.get<GetLtiSessionResponse>({
      path: `/api/v3/lti/session`
    })

    return response.body
  }

  async createTemplate(
    request: ConnectLtiTemplateRequest
  ): Promise<ConnectLtiTemplateResponse> {
    const params = JSON.stringify(request)
    const response = await this.client.post<ConnectLtiTemplateResponse>({
      path: `/api/v3/lti/assignments/createTemplate`,
      body: params
    })
    return response.body
  }
}
