<template>
  <div
    :class="{
      'view-container__child--narrow': narrow,
      'view-container__child--normal': normal,
      'view-container__child--wide': wide,
      'view-container__child--full': full
    }"
  >
    <!-- This sentinel is used by the sticky header to determine when the header has scrolled out of view. -->
    <div id="scroll-sentinel" class="view-container__scroll-sentinel" />
    <!-- @slot Content of the view. -->
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ViewContainer',
  props: {
    /**
     * Constrains the max width to 480px.
     */
    narrow: {
      type: Boolean,
      default: false
    },
    /**
     * Constrains the max width to 768px. This is not the default width, but much narrower.
     */
    normal: {
      type: Boolean,
      default: false
    },
    /**
     * Constrains the max width to 970px. This is still narrower than the default view.
     */
    wide: {
      type: Boolean,
      default: false
    },
    /**
     * Expands the view to the full screen width.
     */
    full: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.view-container__scroll-sentinel {
  height: 100px;
  margin-bottom: -100px;
}

.view-container__child--full {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 32px;
}

.view-container__child--narrow {
  max-width: 480px;
  width: 100%;
  margin: auto;
}

.view-container__child--normal {
  max-width: 768px;
  width: 100%;
  margin: auto;
}

.view-container__child--wide {
  max-width: 970px;
  width: 100%;
  margin: auto;
}
</style>
