<template>
  <div
    class="graph-data"
    ref="popoverAnchorRef"
    @mouseover="openPopover"
    @mouseout="closePopover"
    @focusin="openPopover"
    @focusout="closePopover"
  >
    <component
      :is="gridGraphComponent"
      v-if="!resetInProgress"
      :component-id="component._id"
      :settings="component.settings"
      :ymap="value"
      :readonly="(!canRespond || readonly) && !grading"
      :show-grid-actions-menu="showGridActionsMenu"
      :allow-download-csv="allowDownloadCsv"
      :variable-context="variableContext"
      :allow-reset="allowReset"
      @reset="onReset"
      :graphInstructions="component.graphInstructions"
    />
  </div>
  <base-popover
    :anchor-ref="popoverAnchorRef"
    :visible="studentResponsePopoverMessage && popoverVisible"
    placement="bottom"
  >
    {{ studentResponsePopoverMessage }}
  </base-popover>

  <dependency-popover
    v-if="dependencyVisible"
    :anchor-ref="popoverAnchorRef"
    :grading="grading"
  >
  </dependency-popover>
</template>

<script>
import { ref } from 'vue'
import { mapGetters } from 'vuex'
import * as Y from 'yjs'
import DataGrid from 'src/shared/components/grid-graph/DataGrid'
import DependencyPopover from './DependencyPopover'
import DataGridWithGraph from 'src/shared/components/grid-graph/DataGridWithGraph'
import {
  defaultGridValue,
  defaultGraphValue,
  attachValueToYMap,
  gridHasData
} from 'src/shared/components/grid-graph/utilities'

export default {
  name: 'ActivityResponseGridGraphRealtime',
  inject: ['inherited'],
  components: {
    DataGrid,
    DataGridWithGraph,
    DependencyPopover
  },
  props: {
    /* DATA */
    componentResponse: {
      type: Y.Map,
      required: true
    },
    component: {
      type: Object,
      required: true
    },

    /* CONFIGURATION */
    readonly: {
      type: Boolean,
      default: false
    },
    grading: {
      type: Boolean,
      default: false
    },
    showGridActionsMenu: {
      type: Boolean,
      default: true
    },
    allowDownloadCsv: {
      type: Boolean,
      default: true
    },
    allowReset: {
      type: Boolean,
      default: false
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      popoverVisible: false,
      resetInProgress: false,
      value: null,
      dependencyVisible: false
    }
  },
  setup() {
    const popoverAnchorRef = ref()
    return { popoverAnchorRef }
  },
  beforeUnmount() {
    this.unsubscribe?.()
  },
  computed: {
    ...mapGetters(['isStudent']),

    componentId() {
      return this.component._id
    },
    canRespond() {
      return this.inherited.canRespond
    },
    gridGraphComponent() {
      return this.component.componentType === 'GridGraphQuestion'
        ? 'data-grid-with-graph'
        : 'data-grid'
    },
    defaultValue() {
      if (this.component.componentType === 'GridGraphQuestion') {
        return { ...defaultGridValue(), graphs: [defaultGraphValue()] }
      } else {
        return { ...defaultGridValue() }
      }
    },
    studentResponsePopoverMessage() {
      return this.inherited.studentResponsePopoverMessage
    }
  },
  mounted() {
    this.$emit('canSubmit', gridHasData(this.value))
  },
  methods: {
    closePopover() {
      this.dependencyVisible = false
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = false
      }
    },
    openPopover() {
      this.dependencyVisible = true
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = true
      }
    },
    onReset() {
      if (!this.canRespond || this.readonly) {
        return
      }

      /*
         when we replace the contents of the Y.Map, we're replacing all of the
         nested Y.Maps that our child components are bound to. if we let those
         same component instances stick around during the reset, they'll be
         listening for changes on Y.Maps that no longer exist. the easiest thing
         to do is force all those components to unmount (via the v-if above) and
         mount new instances once the value is fully constructed.
       */
      this.componentResponse.set('resetInProgress', true)

      this.$nextTick(() => {
        try {
          const value = this.component.defaultValue
            ? JSON.parse(this.component.defaultValue)
            : this.defaultValue

          this.componentResponse.doc.transact(() => {
            attachValueToYMap(this.componentResponse.get('value'), value)
            this.componentResponse.set('resetInProgress', false)
          })
        } catch (e) {
          this.componentResponse.set('resetInProgress', false)
          this.$error(e.message)
          throw e
        }
      })
    }
  },
  watch: {
    componentResponse: {
      immediate: true,
      handler(val) {
        this.unsubscribe?.()
        const onChange = () => {
          this.resetInProgress = !!val.get('resetInProgress')
          this.value = val.get('value')
          this.$emit('canSubmit', gridHasData(this.value))
        }
        onChange()
        val.observe(onChange)
        this.unsubscribe = () => val.unobserve(onChange)
      }
    }
  }
}
</script>
