<template>
  <modal @submit="submit">
    <modal-header>{{ title }}</modal-header>
    <modal-body>
      <div v-if="hasLibraries">
        <form-group>
          <form-label for="select-library"> Library </form-label>
          <div>
            <select-field
              id="select-library"
              v-model="library"
              label="library"
              rules="required"
            >
              <option
                v-for="{ id, name } in filteredLibraries"
                :key="id"
                :value="id"
              >
                {{ name }}
              </option>
            </select-field>
          </div>
        </form-group>
        <slot />
        <form-group v-if="isAdmin && library === 'pivot'">
          <checkbox id="update-deployDate" v-model="updateDeployDate">
            Update Deploy Date
          </checkbox>
        </form-group>
      </div>

      <div v-else class="no-libraries-msg">
        <p>
          There are currently no libraries to select as a destination. Please
          create a library before moving or copying an activity.
        </p>
      </div>
      <div v-if="hasViewerOnlyLibraries" class="no-libraries-msg">
        <p>
          If a library is missing from this list, it is because you don't have
          the proper permissions for that library.
        </p>
      </div>
    </modal-body>
    <modal-actions v-if="hasLibraries">
      <modal-button-submit data-focus>{{ submitLabel }}</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import { mapGetters, useStore } from 'vuex'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import client from 'src/shared/api-client.ts'

export default {
  name: 'SelectLibraryModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  emits: ['submit'],
  props: {
    title: {
      type: String,
      default: 'Select a library'
    },
    submitLabel: {
      type: String,
      default: 'Submit'
    },
    includePublic: {
      type: Boolean,
      default: false
    },
    hideArchived: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const userId = store.getters.userId
  },

  data() {
    return {
      library: null,
      loading: true,
      libraries: [],
      updateDeployDate: false
    }
  },
  computed: {
    ...mapGetters(['userId', 'isAdmin', 'isContentDeveloper']),
    filteredLibraries() {
      let libraries = this.libraries.filter(l =>
        l.members.find(m => m.id === this.userId && m.permissions !== 'viewer')
      )
      if (this.hideArchived) {
        libraries = libraries.filter(l => l.status !== 'archived')
      }

      if (this.includePublic) {
        libraries = [
          {
            id: 'pivot',
            name: 'Pivot Interactives Library'
          },
          {
            id: 'community',
            name: 'Community Library'
          },
          ...libraries
        ]
      } else if (this.isAdmin || this.isContentDeveloper) {
        libraries = [
          {
            id: 'pivot',
            name: 'Pivot Interactives Library'
          },
          ...libraries
        ]
      }
      return libraries
    },
    hasLibraries() {
      return !!this.filteredLibraries.length
    },
    hasViewerOnlyLibraries() {
      return this.filteredLibraries.length < this.libraries.length
    }
  },
  async created() {
    this.libraries = await client.libraries.list()
    this.library =
      this.filteredLibraries.length > 0 ? this.filteredLibraries[0].id : null
    this.loading = false
  },
  methods: {
    async submit({ done }) {
      this.$clearFlash()
      this.$emit('submit', {
        done,
        libraryId: this.library,
        updateDeployDate: this.updateDeployDate
      })
    }
  }
}
</script>
