<template>
  <view-container>
    <loading-container :loading="isLoading || step === 'redirect'">
      <connect-sso-account v-if="step === 'connect'" />
      <reset-password
        v-else
        :token="resetPasswordToken"
        :student="data?.role === 'student'"
        :sso-provider="data?.ssoProvider"
      />
    </loading-container>
  </view-container>
</template>

<script setup>
import { ref, onBeforeMount, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client'
import ConnectSsoAccount from 'src/modules/auth/components/ConnectSsoAccount.vue'
import ResetPassword from 'src/modules/auth/components/ResetPassword.vue'

const router = useRouter()
const flash = useFlash()
const props = defineProps({
  resetPasswordToken: {
    type: String,
    required: true
  },
  step: {
    type: String,
    default: undefined
  }
})

const data = ref(null)
const isLoading = ref(true)

onBeforeMount(async () => {
  try {
    data.value = await client.auth.getPasswordResetToken({
      token: props.resetPasswordToken
    })
    isLoading.value = false
  } catch (error) {
    if (
      error.body?.errors?.[0].message.includes(
        'Reset password token was not found or has expired.'
      )
    ) {
      flash.error(
        'This password reset has expired. Password resets are valid for 2 hours.'
      )
      await router.push({ name: 'forgot_password' })
    } else {
      flash.error(
        'An unknown error has occurred. Please try again or contact support.'
      )
      await router.push({ name: 'forgot_password' })
      throw error
    }
  }
})

watch(
  () => props.step,
  async step => {
    if (step === 'redirect') {
      window.location.assign('/')
    }
  }
)
</script>
