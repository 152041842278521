<template>
  <span v-if="canManageLibrary" class="title-bar edit-link">
    <form-button v-if="isArchived" link @click="unArchiveLibrary">
      Unarchive
    </form-button>
    <router-link
      v-else
      :to="{
        name: 'manage_library',
        params: { libraryId: libraryId }
      }"
      >Edit Library
    </router-link>
  </span>
</template>

<script>
import client from 'src/shared/api-client.ts'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
export default {
  name: 'ActivitiesHeaderActions',
  inject: ['browseActivities', '$modal'],

  computed: {
    library() {
      return this.browseActivities.library
    },
    isArchived() {
      return this.library.status && this.library.status === 'archived'
    },
    canManageLibraryActivities() {
      return this.browseActivities.canManageLibraryActivities
    },
    canManageLibrary() {
      return this.browseActivities.canManageLibrary
    },
    libraryId() {
      return this.browseActivities.query.library
    }
  },
  methods: {
    async archiveLibrary() {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: `Archiving the library, ${this.library.name}, will make it unavailable to be used and all activities within it to be unavailable.`,
        prompt: `Are you sure you want to archive ${this.library.name}?`
      })
      if (status === 'ok') {
        await client.libraries.setIsArchived(this.library.id)
        this.$success('The library has been archived')
        await this.browseActivities.loadLibraries()
      }
    },
    async unArchiveLibrary() {
      await client.libraries.setIsArchived(this.library.id, false)
      this.$success('The library has been unarchived')
      await this.browseActivities.loadLibraries()
    }
  }
}
</script>

<style lang="scss" scoped>
.title-bar {
  flex-shrink: 0;
  margin-left: 16px;
}
</style>
