<template>
  <div class="double-slider" role="group" aria-labelledby="double-label">
    <input
      type="range"
      min="0"
      max="100"
      step="10"
      :value="mutableState.left"
      :aria-label="`${ariaLabel} ${isLeftMin ? 'min' : 'max'}`"
      @input="onLeftInput"
      @change="onChange"
      ref="leftInput"
    />
    <input
      type="range"
      min="0"
      max="100"
      step="10"
      :value="mutableState.right"
      :aria-label="`${ariaLabel} ${isLeftMin ? 'max' : 'min'}`"
      @input="onRightInput"
      @change="onChange"
      ref="rightInput"
    />
    <span
      :style="{
        width: selectedWidth,
        'margin-left': selectedMarginLeft,
        'margin-right': selectedMarginRight
      }"
      class="range-selected"
    ></span>
    <span class="range-unselected"></span>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
  ariaLabel: {
    type: String,
    required: true
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  }
})
const leftInput = ref(null)
const rightInput = ref(null)
const focusLeft = ref(null)

const emit = defineEmits(['updated:min', 'updated:max', 'change'])

const mutableState = ref({ left: props.min, right: props.max })
const isLeftMin = ref(true)

const mutableMin = computed(() =>
  Math.min(mutableState.value.left, mutableState.value.right)
)
const mutableMax = computed(() =>
  Math.max(mutableState.value.left, mutableState.value.right)
)

// We need to keep track of which handle is the minimum and flip them when they cross.
watch(
  mutableState,
  ({ left, right }) => {
    if (left > right) {
      isLeftMin.value = false
    } else if (left < right) {
      isLeftMin.value = true
    }
  },
  { deep: true }
)
// When we update the internal state from props,
// we need to ensure that we don't flip the handles
// since that moves which element has focus.
watch(
  () => ({ min: props.min, max: props.max }),
  value => {
    if (isLeftMin.value) {
      mutableState.value = {
        left: value.min,
        right: value.max
      }
    } else {
      mutableState.value = {
        left: value.max,
        right: value.min
      }
    }
  }
)

const selectedWidth = computed(() => `${mutableMax.value - mutableMin.value}%`)
const selectedMarginLeft = computed(() => `${mutableMin.value}%`)
const selectedMarginRight = computed(() => `${mutableMax.value}%`)

function onChange() {
  emit('change', { min: mutableMin.value, max: mutableMax.value })
  if (focusLeft.value) {
    leftInput.value.focus()
  } else {
    rightInput.value.focus()
  }
}

// When the handles cross, we need to emit an event for both min and max since they are both changing.
function onLeftInput(e) {
  const left = parseFloat(e.target.value)
  if (left > mutableState.value.right) {
    emit('updated:max', left)
    if (props.min !== mutableState.value.right) {
      emit('updated:min', mutableState.value.right)
    }
    focusLeft.value = false
  } else {
    emit('updated:min', left)
    if (props.max !== mutableState.value.right) {
      emit('updated:max', mutableState.value.right)
    }
    focusLeft.value = true
  }
  mutableState.value.left = left
}

function onRightInput(e) {
  const right = parseFloat(e.target.value)
  if (right < mutableState.value.left) {
    emit('updated:min', right)
    if (props.max !== mutableState.value.left) {
      emit('updated:max', mutableState.value.left)
    }
    focusLeft.value = true
  } else {
    emit('updated:max', right)
    if (props.min !== mutableState.value.left) {
      emit('updated:min', mutableState.value.left)
    }
    focusLeft.value = false
  }
  mutableState.value.right = right
}
</script>

<style lang="scss" scoped>
@mixin track() {
  background: none; /* get rid of Firefox track background */
  height: 100%;
  width: 100%;
}

@mixin thumb() {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  pointer-events: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  // box-shadow: 0px 0px 3px black, inset 0px 0px 5px gray;
}

.range-selected {
  background-color: $teal;
  grid-column: 1;
  grid-row: 2;
  height: 6px;
  margin: auto 1px auto 1px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}
.range-unselected {
  grid-column: 1;
  grid-row: 2;
  height: 8px;
  margin: auto 0;
  position: relative;
  z-index: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.double-slider {
  display: grid;
  grid-template-rows: max-content 1.5em;
  width: 20em;
  overflow: hidden;
  position: relative;
  padding: 8px;
}

input[type='range'] {
  &::-webkit-slider-runnable-track,
  &::-webkit-slider-thumb,
  & {
    -webkit-appearance: none;
  }

  z-index: 2;
  grid-column: 1;
  grid-row: 2;
  margin: 0;
  background: none;
  color: #000;
  font: inherit;
  pointer-events: none;

  &::-webkit-slider-runnable-track {
    @include track;
  }
  &::-moz-range-track {
    @include track;
  }

  &::-webkit-slider-thumb {
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }

  &:focus {
    border: none;
    box-shadow: none;

    &::-webkit-slider-thumb {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
    &::-moz-range-thumb {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
