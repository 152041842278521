<template>
  <instance-upload
    v-if="isStudentInstanceComponent"
    v-model:config="value"
    :readonly="readonly || !canRespond"
    :limit-functionality="limitFunctionality"
    :no-upload="noUpload"
  />
</template>

<script>
import InstanceUpload from 'src/shared/components/InstanceUpload'

export default {
  name: 'ActivityStudentInstance',
  inject: ['inherited'],
  components: {
    InstanceUpload
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    limitFunctionality: {
      type: Boolean,
      default: false
    },
    noUpload: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    componentResponse() {
      return this.inherited.componentResponse
    },
    canRespond() {
      return this.inherited.canRespond
    },
    response() {
      return this.inherited.response
    },
    isStudentInstanceComponent() {
      return this.component.componentType === 'StudentInstance'
    },
    value: {
      get() {
        return this.componentResponse.value || {}
      },
      set(value) {
        if (!this.readonly) {
          this.inherited.updateResponse({ value })
        }
      }
    }
  }
}
</script>
