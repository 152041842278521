<template>
  <li class="sticky-header-library">
    <form-button
      link
      class="sticky-header-library__library"
      :class="{ 'sticky-header-library--active': isActive }"
      @click="changeLibrary"
    >
      <slot />
    </form-button>
  </li>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SidebarLibrary',
  inject: ['browseActivities'],
  props: {
    library: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    selectedLibrary() {
      return this.browseActivities.query.library
    },
    isActive() {
      return this.selectedLibrary === this.library
    }
  },
  methods: {
    changeLibrary() {
      if (this.selectedLibrary !== this.library) {
        this.browseActivities.updateQuery({
          page: 1,
          library: this.library,
          isArchived: undefined
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-header-library {
  position: relative;
  display: inline-block;

  &__library {
    color: inherit;
    font-weight: normal;

    &:focus,
    &:hover {
      text-decoration: none;
    }
    &:focus {
      text-decoration: underline;
    }
  }

  &--active {
    font-weight: bold;
  }
}
</style>
