<template>
  <view-container>
    <flash />
    <auth-card header="Reset Password">
      <p class="forgot-password__help-text">
        We will send you an email with instructions for how to reset your
        password.
      </p>
      <async-form class="form forgot-password__form" @submit="doSubmit">
        <form-group>
          <form-label for="email">Email</form-label>
          <text-input
            id="email"
            v-model="email"
            rules="required|email"
            type="email"
            label="email"
            autocomplete="email"
          />
        </form-group>
        <form-group class="forgot-password__actions">
          <form-button link @click="cancel"> Cancel </form-button>
          <submit-button>
            <template #default>Send Email</template>
            <template #submitting>Sending</template>
            <template #submitted>Sent</template>
          </submit-button>
        </form-group>
      </async-form>
    </auth-card>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'
import AuthCard from 'src/modules/auth/components/AuthCard.vue'
import Flash from 'src/shared/components/Flash.vue'

export default {
  name: 'ForgotPassword',
  components: { Flash, AuthCard },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    async doSubmit(e) {
      try {
        await client.auth.requestPasswordReset(this.email)

        // Redirect to previous route if there was one.
        if (this.redirectTo) {
          this.$router.push(this.redirectTo)
        } else {
          this.$router.push({ name: 'login' })
        }

        this.$success(
          'Please check your email for your password reset instructions.'
        )
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    cancel() {
      this.$router.back()
    }
  },
  // Save the previous route so we can navigate to it after submission.
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name !== 'change_password') {
        vm.redirectTo = from
      }
    })
  }
}
</script>

<style scoped lang="scss">
.forgot-password__help-text,
.forgot-password__form {
  width: 300px;
  margin: auto;
}

.forgot-password__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
