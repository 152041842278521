<template>
  <modal @submit="submit">
    <modal-header>Add Instructor</modal-header>
    <modal-body class="modal-body">
      <form-group>
        <div class="class-name-subgroup">
          <form-label for="assign-to">Role</form-label><br />
          <selector-input
            v-model="instructorRole"
            aria-labelledby="instructorRole"
            label="instructorRole"
            class="selector-input--horizontal"
          >
            <selector-option value="Instructor" title="Instructor" />
            <selector-option value="Grader" title="Grader" />
          </selector-input>
        </div>
      </form-group>
      <div v-if="instructorRole === 'Instructor'">
        Instructors can assign activities, grade assignments, manage due dates
        and add additional teachers or graders.
      </div>
      <div v-else>
        Graders can can assign activities, grade assignments and manage due
        dates.
      </div>
      <form-group>
        <form-label for="select-instructor"> Instructor </form-label>
        <autocomplete
          id="select-instructor"
          v-model="instructor"
          label="instructor"
          :options="instructorRole === 'Grader' ? instructors : instructorsOnly"
          value-key="id"
          label-key="name"
          aria-label="select an instructor"
          :multiple="true"
          input-type="textarea"
        />
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Add</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import client from 'src/shared/api-client'
import { onMounted, ref } from 'vue'
import { useFlash } from 'src/shared/hooks/flash'
import { ClassInstructor, InstructorList } from '@pi/api-types'
import Autocomplete from 'src/shared/components/Autocomplete.vue'

interface AddInstructorModalProps {
  classId: string
  licenseId: string
  existingInstructors: ClassInstructor[]
}

const flash = useFlash()
const props = defineProps<AddInstructorModalProps>()
const instructor = ref<string[]>()
const instructorRole = ref<string>('Grader')
const instructors = ref<InstructorList[]>([])
const instructorsOnly = ref<InstructorList[]>([])

const fetchInstructors = async () => {
  const result = await client.licenses.getInstructors(props.licenseId)
  instructors.value = result.instructors.filter(
    i => !props.existingInstructors.find(g => g._id === i.id)
  )
  instructorsOnly.value = result.instructors.filter(
    i =>
      !props.existingInstructors.find(g => g._id === i.id) &&
      i.role !== 'grader'
  )
}
const submit = async (e: any) => {
  if (!instructor.value) return
  try {
    await client.classes.addInstructor({
      classId: props.classId,
      instructorId: instructor.value,
      role: instructorRole.value
    })
    flash.success('Instructor added successfully!')
    e.done()
  } catch (error) {
    e.done(false)
    throw error
  }
}

onMounted(async () => {
  await fetchInstructors()
})
</script>
<style lang="scss" scoped>
.modal-body {
  min-height: 300px;
}
</style>
