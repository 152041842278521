import { format, isValid } from 'date-fns'

export default {
  computed: {
    $format() {
      return {
        currency(price, fallback) {
          if (!price) return fallback

          const value = Number.parseFloat(price)
            .toFixed(2)
            // commas between thousands
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

          return `$${value}`
        },
        date(date, fmt) {
          if (date && isValid(new Date(date))) {
            return format(new Date(date), fmt)
          } else {
            return ''
          }
        }
      }
    }
  }
}
