<template>
  <auth-card header="Sign in to Pivot Interactives">
    <div class="card-content">
      <oauth-selection
        @success="onOauthLogin"
        prefix="Login with"
        :allowed="allowedSso"
      ></oauth-selection>

      <p class="m-0">or</p>
      <button-link secondary class="button-content" :to="loginRoute">
        Sign in with Email and Password
      </button-link>
      <UIButton
        class="mt-4 text-xs"
        type="link"
        v-if="allowedSso.length === 1"
        @click="() => (allowAll = true)"
      >
        Not using {{ ssoConnection }}?</UIButton
      >
    </div>
  </auth-card>

  <div class="flex max-w-[300px] justify-between mx-auto my-5">
    <router-link :to="{ name: 'forgot_password' }">
      Forgot Login Details?
    </router-link>
    <router-link :to="signupRoute"> Create A New Account </router-link>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'
import AuthCard from './AuthCard.vue'
import OauthSelection from './OauthSelection.vue'
import { UIButton } from '@pi/design'
import { useRouter } from 'vue-router'
import client from 'src/shared/api-client'

const router = useRouter()

const props = defineProps({
  ssoConnection: {
    type: String,
    default: null
  },
  userType: {
    type: String
  }
})

const allowAll = ref(false)
const currentRoute = useRoute()
const signupRoute = computed(() => ({
  ...currentRoute,
  params: { ...currentRoute.params, step: 'sign-up' }
}))
const loginRoute = computed(() => ({
  ...currentRoute,
  params: { ...currentRoute.params, step: 'email' }
}))
const allowedSso = computed(() => {
  if (allowAll.value) {
    return ['google', 'clever', 'classLink', 'azure']
  }
  if (props.ssoConnection === 'Clever') {
    return ['clever']
  }
  if (props.ssoConnection === 'ClassLink') {
    return ['classLink']
  }
  return ['google', 'clever', 'classLink', 'azure']
})

async function onOauthLogin() {
  try {
    const response = await client.auth.loginOAuth({ userType: props.userType })

    if (response?.newTeacher) {
      router.push({
        name: 'instructor_rostering_signup'
      })
    } else if (response?.connect) {
      router.push({
        ...router.currentRoute.value,
        params: { ...router.currentRoute.value.params, step: 'connect' }
      })
    } else {
      router.push({
        ...router.currentRoute.value,
        params: { ...router.currentRoute.value.params, step: 'redirect' }
      })
    }
  } catch (error) {
    if (error.status === 401) {
      router.push({
        ...router.currentRoute.value,
        params: { ...router.currentRoute.value.params, step: 'sign-up' }
      })
    } else {
      throw error
    }
  }
}
</script>
<style lang="scss" scoped>
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.button-content {
  width: 300px;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  margin-right: 16px;
}

.button-icon {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  display: block;
}
.title {
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}
</style>
