<template>
  <collapse-panel
    :title="section.name"
    :collapsed="collapsedByDefault"
    show-on-print
  >
    <!-- Pass Slot for Total -->
    <template #header>
      <slot name="dirtyResponseFlag" />
      <div class="hidden-print panel-header">
        <p class="activity-score">
          <span class="score-prefix">Total Score: </span>
          <span>{{ score }} / {{ max }}</span>
        </p>
      </div>
    </template>
    <template v-if="section.isAvailable || override">
      <editor-content
        v-if="hasSummary"
        :text="section.summary"
        :variable-context="variableContext"
      />
      <slot />
    </template>
    <template v-else>
      Please complete and submit the previous section before continuing to this
      one.
    </template>
  </collapse-panel>
</template>

<script>
import * as Vue from 'vue'
import { hasContent } from 'src/shared/components/editor/utils.js'
import { getFlattenedComponentsForSection } from 'src/modules/assignments/utils'

export default {
  name: 'ActivitySectionWithScore',
  inject: ['inherited'],
  provide() {
    return {
      inherited: Vue.computed(() => ({
        ...this.inherited,
        section: this.section
      }))
    }
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    openByDefault: {
      type: Boolean,
      default: false
    },
    override: {
      type: Boolean,
      default: false
    },
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasSummary() {
      return !!this.section.summary && hasContent(this.section.summary)
    },
    collapsedByDefault() {
      if (this.openByDefault) {
        return false
      }

      return this.section.index !== 0
    },
    ignoreInstructorGradedPoints() {
      return this.inherited.response.assignment.ignoreInstructorGradedPoints
    },

    score() {
      let sumScore = 0
      if (this.ignoreInstructorGradedPoints) {
        getFlattenedComponentsForSection(this.section).forEach(component => {
          sumScore =
            sumScore +
            (this.inherited.response.responses || [])
              .filter(i => i.component === component._id && component.autograde)
              .reduce((total, response) => total + (response.score || 0), 0)
        })
      } else {
        getFlattenedComponentsForSection(this.section).forEach(component => {
          sumScore =
            sumScore +
            (this.inherited.response.responses || [])
              .filter(i => i.component === component._id)
              .reduce((total, response) => total + (response.score || 0), 0)
        })
      }

      return Math.floor(sumScore) === sumScore ? sumScore : sumScore.toFixed(2)
    },

    max() {
      return getFlattenedComponentsForSection(this.section).reduce(
        (sectionTotal, component) =>
          sectionTotal +
          (!this.ignoreInstructorGradedPoints || component.autograde
            ? component.pointValue ?? 0
            : 0),
        0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  margin-bottom: unset;
  border: 0px;
  border-top: 1px solid #ddd;
  border-radius: 0px;
  box-shadow: unset;
}
.panel-header {
  padding: 0 15px;
}
.score-prefix {
  margin-right: 10px;
}
</style>
