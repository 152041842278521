<template>
  <div v-if="showCorrectAnswer" class="grouped-graph">
    <div class="graph-data student-response">
      <label class="answer-heading">Student Response</label>
      <component-response
        :component="component"
        :response="response.value"
        :name="componentResponseName"
        :variable-context="variableContext"
      />
    </div>
    <div class="graph-data answer-key">
      <label class="answer-heading">Correct Answer</label>
      <component-response
        :component="component"
        :response="correctAnswer"
        :name="componentAnswerResponseName"
        :variable-context="variableContext"
      />
    </div>
  </div>
  <div v-else>
    <component-response
      :component="component"
      :response="response.value"
      :name="componentResponseName"
      :variable-context="variableContext"
    />
  </div>
</template>

<script>
import ComponentResponse from './ComponentResponse'

export default {
  name: 'GridGraphQuestionResponse',
  components: { ComponentResponse },
  props: {
    showCorrectAnswer: {
      type: Boolean,
      required: true
    },
    component: {
      required: true
    },
    response: {
      type: Object
    },
    gradableResponse: {
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    correctAnswer() {
      return this.component.answer
        ? JSON.parse(this.component.answer)
        : undefined
    },
    componentResponseName() {
      return `question-choice-${this.component._id}-${this.gradableResponse._id}`
    },
    componentAnswerResponseName() {
      return `answer-choice-${this.component._id}-${this.gradableResponse._id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.grouped-graph {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  @media (min-width: $screen-md) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    flex-direction: row;

    & .graph-data {
      width: 48%;
    }
  }

  & .answer-heading {
    font-size: 16px;
    font-weight: 600;
  }
}
.answer-key {
  background-color: #7651a61a;
  padding: 10px;
}

.student-response {
  padding: 10px;
}
</style>
