<template>
  <editor-modal
    class="image-menu__modal"
    v-if="isModalShown"
    name="image"
    title="Edit Image"
  >
    <async-form class="image-menu__form" @submit="updateImage">
      <div class="image-menu__form-columns">
        <div class="image-menu__form-column">
          <div>
            <form-label for="editor-file">{{ fileTypeText }}</form-label>
            <input
              type="file"
              id="editor-file"
              :accept="formatsAccepted"
              @change="onFileChange"
              data-focus
            />
          </div>
          <div v-if="isImage">
            <form-label for="editor-alt">Alt</form-label>
            <text-input id="editor-alt" v-model="image.alt" />
          </div>
        </div>
        <div class="image-menu__form-column">
          <form-label id="editor-formula-preview-label">Preview</form-label>
          <template v-if="previewUrl !== undefined">
            <img
              v-if="isImage"
              class="image-menu__preview"
              aria-labelledby="editor-formula-preview-label"
              :src="previewUrl"
              :alt="image.alt"
              ref="previewImage"
            />
            <video v-else class="image-menu__preview" :src="previewUrl" />
          </template>
        </div>
      </div>
      <div v-if="previewError" class="image-menu__error">
        {{ previewError }}
      </div>
      <template v-if="isImage">
        <div class="image-menu__form-columns">
          <div class="image-menu__form-column">
            <form-label for="height">Height (px)</form-label>
            <text-input
              id="height"
              :model-value="image.height"
              @change="updateHeight"
              class="image-menu__width-label"
            />
          </div>
          <div class="image-menu__form-column">
            <form-label for="label-text">Width (px)</form-label>
            <text-input
              id="label-text"
              :model-value="image.width"
              @change="updateWidth"
              class="image-menu__width-label"
            />
          </div>
        </div>
      </template>
      <div class="editor-modal__actions">
        <form-button :disabled="!image.src" type="submit">Update</form-button>
        <form-button tertiary @click="cancel">Cancel</form-button>
      </div>
    </async-form>
  </editor-modal>
</template>

<script>
import * as Vue from 'vue'
import EditorModal from '../components/EditorModal.vue'
import client from 'src/shared/api-client'
import { validateFile } from 'src/shared/components/editor/utils.js'

export default {
  name: 'ImageModal',
  components: { EditorModal },
  props: {
    editor: {
      type: Object,
      required: true
    },
    noupload: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Sync the focused node's href with the menu.
    const image = Vue.ref({})
    const previewImage = Vue.ref()
    const imageDimensions = Vue.computed(() => ({
      width: previewImage.value?.naturalWidth ?? 0,
      height: previewImage.value?.naturalHeight ?? 0
    }))
    const onSelectionUpdate = () => {
      const attrs = props.editor.getAttributes('image')
      image.value = { ...attrs }
    }
    props.editor.on('selectionUpdate', onSelectionUpdate)
    Vue.onUnmounted(() => {
      props.editor.off('selectionUpdate', onSelectionUpdate)
    })
    Vue.watch(previewImage, () => {
      if (
        previewImage.value &&
        image.value.width == 0 &&
        image.value.height == 0
      ) {
        setTimeout(() => {
          //adding a delay for the image to load before we try and get the width/height
          image.value.width = previewImage.value.naturalWidth
          image.value.height = previewImage.value.naturalHeight
        }, 1000)
      }
    })

    const isModalShown = Vue.ref(false)
    const openModal = () => {
      isModalShown.value = true
    }

    const allowVideo = Vue.computed(() => {
      const extension = props.editor.extensionManager.extensions.find(
        ext => ext.name === 'image'
      )
      return extension?.options.video ?? false
    })

    const fileTypeText = Vue.computed(() => {
      if (allowVideo.value) {
        return 'Image & Video File Selection'
      } else {
        return 'Image File Selection'
      }
    })

    const formatsAccepted = Vue.computed(() => {
      if (allowVideo.value) {
        return 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/svg+xml, video/mp4, video/webm, video/mov'
      } else {
        return 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/svg+xml'
      }
    })

    const updateImage = e => {
      const updatedImage = {
        ...image.value
      }
      props.editor.chain().focus().setImage(updatedImage).run()
      isModalShown.value = false
      e.done()
    }
    const cancel = e => {
      isModalShown.value = false
      props.editor.commands.focus()
    }

    const previewError = Vue.ref(null)

    const onFileChange = async e => {
      previewError.value = null
      try {
        let file = e.target.files[0]
        if (file && file.name.match(/\.(mov|mp4)$/i)) {
          try {
            await validateFile(e, file)
          } catch (error) {
            file = null
            e.target.value = null
            previewError.value = error.message
            return
          }
        }

        if (props.noupload) {
          image.value.src = await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
              resolve(reader.result)
            })
            reader.addEventListener('error', () => {
              reject(new Error(`Failed to read file: ${file.name}.`))
            })
            reader.addEventListener('abort', () => {
              reject(new Error(`Aborted reading file: ${file.name}.`))
            })
            reader.readAsDataURL(file)
          })
        } else {
          const ext = file.name.split('.').pop()
          const name = `${new Date().getTime()}.${ext}`
          const type = file.name.match(/\.(mov|mp4|webm)$/i) ? 'video' : 'image'
          image.value.src = await client.assets.upload({
            file,
            name,
            type
          })
        }
      } catch (error) {
        previewError.value = error.message
          ? error.message
          : error.bodyText.includes('exceeds the maximum allowed size')
          ? 'Your upload exceeds the maximum allowed size.'
          : 'An error occurred with your upload.  Please try another file or contact support.'
      }
    }

    // eslint-disable-next-line vue/no-mutating-props
    props.editor.storage.image.onEditImage = openModal

    const previewUrl = Vue.computed(() =>
      image.value.src && !image.value.src.startsWith('data:')
        ? `//${PI_API_HOST}${image.value.src}`
        : image.value.src
    )

    const isImage = Vue.computed(() => {
      if (previewUrl.value) {
        return (
          previewUrl.value.match(/\.(jpg|jpeg|png|gif|ico|svg|bmp)$/i) ||
          previewUrl.value.match(/data:image/i)
        )
      } else {
        return false
      }
    })

    const updateHeight = val => {
      image.value.height = parseFloat(val)
      const ratio = imageDimensions.value.width / imageDimensions.value.height
      image.value.width = Math.round(image.value.height * ratio)
    }
    const updateWidth = val => {
      image.value.width = parseFloat(val)
      const ratio = imageDimensions.value.height / imageDimensions.value.width
      image.value.height = Math.round(image.value.width * ratio)
    }

    return {
      isModalShown,
      updateImage,
      image,
      cancel,
      previewError,
      onFileChange,
      previewUrl,
      isImage,
      formatsAccepted,
      allowVideo,
      fileTypeText,
      updateWidth,
      updateHeight,
      previewImage
    }
  }
}
</script>

<style lang="scss" scoped>
.image-menu {
  &__form {
    margin: 0 !important;
  }

  &__form-columns {
    display: flex;
  }

  &__form-column {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  :deep() &__formula-input {
    width: 300px;
  }

  &__preview {
    max-width: 100%;
    max-height: 100px;
  }

  &__error {
    margin: 16px 0 0 0;
    color: $color-error;
  }
}
</style>
