<template>
  <view-container narrow>
    <view-header>
      <view-title> Class Not Connected </view-title>
      <view-subtitle>{{ klass?.name }}</view-subtitle>
    </view-header>

    <div class="connect-assignment__empty-msg">
      The class or section in the LMS is not connected to this Pivot class.
      Would you like to connect the two now?
    </div>
    <async-form @submit="connectClass">
      <div class="float-right">
        <form-button
          class="connect-assignment__cancel-btn"
          link
          @click="cancel"
        >
          Cancel
        </form-button>
        <submit-button>
          <template #default>Connect Class</template>
          <template #submitting>Connecting</template>
          <template #submitted>Success</template>
        </submit-button>
      </div>
      <div class="clear-both" />
    </async-form>
  </view-container>
</template>

<script>
import { mapGetters } from 'vuex'
import client from 'src/shared/api-client'
export default {
  name: 'LtiConnectExistingClassView',
  data() {
    return {
      klass: null,
      assignmentId: null
    }
  },
  computed: {
    ...mapGetters('lti', ['context'])
  },
  methods: {
    async loadSession() {
      let ltiSession
      ltiSession = await client.lti.getSession()

      if (ltiSession.state !== 'CONNECT_EXISTING_CLASS') {
        this.$router.push({ name: 'home' })
      } else {
        this.klass = ltiSession.context.class
      }
    },
    async connectClass(e) {
      try {
        await client.lti.connectClass()
        this.$success(
          `Your LMS section or course has been connected successfully`
        )
        this.$router.push({ name: 'lti' })
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    async cancel() {
      this.$router.push({ name: 'lti' })
    }
  },
  async created() {
    await this.loadSession()
  }
}
</script>

<style lang="scss" scoped>
.connect-assignment__empty-msg {
  margin-bottom: 64px;
}

.connect-assignment__cancel-btn {
  margin-right: 16px;
}
</style>
