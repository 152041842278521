import { ref } from 'vue'

class BluetoothDataStream {
  constructor(id = '', name = '', units = '') {
    this.id = id
    this.name = name
    this.units = units
    this._liveReading = ref(0)

    this._buffering = false
    this._sampleBuffer = []
    this._measurementPeriod = BluetoothDevice.IDLE_MEASUREMENT_PERIOD
    this._sampleTime = 0
    this._tareValue = ref(0)
  }

  get liveReading() {
    return this._liveReading.value
  }

  set liveReading(reading) {
    this._liveReading.value = reading
  }

  get tareValue() {
    return this._tareValue.value
  }

  set tareValue(value) {
    this._tareValue.value = value
  }

  get hasNewSamples() {
    return this._sampleBuffer.length > 0
  }

  readNewSamples() {
    const samples = this._sampleBuffer
    this._sampleBuffer = []
    return samples
  }

  getNextSampleTime() {
    const sampleTime = this._sampleTime
    this._sampleTime += this.measurementPeriod
    return sampleTime / 1000
  }

  async reset() {
    this._buffering = false
    this._sampleBuffer = []
    this._sampleTime = 0
    this.measurementPeriod = BluetoothDevice.IDLE_MEASUREMENT_PERIOD
  }

  get measurementPeriod() {
    return this._measurementPeriod
  }

  set measurementPeriod(measurementPeriod) {
    this._measurementPeriod = measurementPeriod
  }

  get isTared() {
    return this.tareValue !== 0
  }

  tare() {
    this.tareValue = this.liveReading
    this.liveReading = this.liveReading - this.tareValue
  }

  clearTare() {
    this.liveReading = this.liveReading + this.tareValue
    this.tareValue = 0
  }
}

class BluetoothDevice {
  constructor(nativeDevice) {
    this.dataStreams = {}
    this._error = ref(false)

    this._nativeDevice = nativeDevice

    this._nativeDevice.addEventListener(
      'gattserverdisconnected',
      this.onDeviceDisconnected.bind(this)
    )

    this.onDisconnect = () => {}
    this.onError = () => {}
  }

  get error() {
    return this._error.value
  }

  set error(error) {
    this._error.value = error
  }

  get name() {
    return this._nativeDevice?.name
  }

  get id() {
    return this._nativeDevice?.id
  }

  async init() {}

  async reset() {
    await Promise.all(
      Object.values(this.dataStreams).map(dataStream => dataStream.reset())
    )
  }

  async startCollectingSamples(measurementPeriod) {
    for (const dataStream of Object.values(this.dataStreams)) {
      dataStream.measurementPeriod = measurementPeriod
      dataStream._buffering = true
    }
  }

  async stopCollectingSamples() {}

  async disconnect() {
    this.onDisconnect = () => {}
  }

  onDeviceDisconnected() {
    this.onDisconnect(this)
  }
}

BluetoothDevice.namePrefixes = []
BluetoothDevice.optionalServices = []
BluetoothDevice.IDLE_MEASUREMENT_PERIOD = 1000

export default BluetoothDevice
export { BluetoothDataStream }
