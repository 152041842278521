<template>
  <view-container>
    <view-header>
      <view-title>Edit LTI Platform</view-title>
    </view-header>
    <async-form class="form-horizontal" @submit="save">
      <form-group>
        <form-label for="name" class="col-sm-2">Name</form-label>
        <div class="col-sm-10">
          <text-input
            id="name"
            v-model="platform.name"
            label="name"
            help-text="Users will see this name when connecting their Pivot account to your LTI platform."
            rules="required"
          />
        </div>
      </form-group>
      <form-group>
        <form-label for="issuer" class="col-sm-2">Issuer</form-label>
        <div class="col-sm-10">
          <text-input
            id="issuer"
            v-model="platform.issuer"
            label="issuer"
            help-text="The domain name and protocol of your authorization server without a path, hash, or query."
            :rules="{
              required: true,
              url: { require_protocol: true }
            }"
          />
        </div>
      </form-group>
      <form-group>
        <form-label for="client-id" class="col-sm-2">Client ID</form-label>
        <div class="col-sm-10">
          <text-input
            id="client-id"
            v-model="platform.clientId"
            label="client ID"
            help-text="The unique ID created by your LTI platform when you installed Pivot as an LTI tool."
            rules="required"
          />
        </div>
      </form-group>
      <form-group>
        <form-label for="oidc-url" class="col-sm-2"
          >OpenID Connect URL</form-label
        >
        <div class="col-sm-10">
          <text-input
            id="oidc-url"
            v-model="platform.oidcUrl"
            label="OIDC URL"
            :rules="{
              required: true,
              url: { require_protocol: true }
            }"
          />
        </div>
      </form-group>
      <form-group>
        <form-label for="oauth-url" class="col-sm-2"
          >OAuth Token URL</form-label
        >
        <div class="col-sm-10">
          <text-input
            id="oauth-url"
            v-model="platform.oauthUrl"
            label="OAuth URL"
            :rules="{
              required: true,
              url: { require_protocol: true }
            }"
          />
        </div>
      </form-group>
      <form-group>
        <form-label id="platform-key-type-label" class="col-sm-2">
          Key Type
        </form-label>
        <selector-input
          v-model="keyType"
          aria-labelledby="platform-key-type-label"
          label="platform key type"
          class="selector-input--horizontal col-sm-10"
        >
          <selector-option value="publicKey" :title="keyLabels['publicKey']" />
          <selector-option value="jwk" :title="keyLabels['jwk']" />
          <selector-option value="jwkUrl" :title="keyLabels['jwkUrl']" />
        </selector-input>
      </form-group>
      <form-group>
        <form-label :for="`platform-${keyType}`" class="col-sm-2">
          {{ keyLabels[keyType] }}
        </form-label>
        <div class="col-sm-10">
          <text-input
            v-if="keyType === 'jwkUrl'"
            id="platform-jwkUrl"
            v-model="platform.jwkUrl"
            label="JWK URL"
            :rules="{
              required: true,
              url: { require_protocol: true }
            }"
            placeholder="Example: https://platform.com/lti/jwks"
          />
          <multiline-text-input
            v-else-if="keyType === 'jwk'"
            id="platform-jwk"
            v-model="platform.jwk"
            label="JWK"
            rules="required"
            rows="6"
            wrap="off"
            :placeholder="exampleJwk"
            @blur="formatJwk"
          />
          <multiline-text-input
            v-else-if="keyType === 'publicKey'"
            id="platform-publicKey"
            v-model="platform.publicKey"
            label="public key"
            rules="required"
            rows="5"
            wrap="off"
            :placeholder="examplePublicKey"
            @blur="formatPublicKey"
          />
        </div>
      </form-group>
      <form-group>
        <div class="col-sm-offset-2 col-sm-10">
          <submit-button>
            <template #default>Save</template>
            <template #submitting>Saving</template>
          </submit-button>
        </div>
      </form-group>
    </async-form>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'

const KEY_LABELS = {
  publicKey: 'Public Key',
  jwk: 'JWK',
  jwkUrl: 'JWK URL'
}

export default {
  name: 'LtiEditPlatformView',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      keyType: 'publicKey',
      keyLabels: KEY_LABELS,
      platform: {
        id: '',
        name: '',
        issuer: '',
        clientId: '',
        oidcUrl: '',
        oauthUrl: ''
      }
    }
  },
  computed: {
    examplePublicKey() {
      return `Example:
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDNzEIs2zwRR9TjuHNpofGGGQUJ
0wCzME7yST7BUNUxRk8UZBJzia/E0akOv8qwfFswCYVMjgoTRtgh4UUCHstIsAWi
GEhEj3C4mohEidmSaXAo1WibDVrrx8tuMBDEvE4dAZuj7ixuaTNo0c0B6O9WoUnw
DKgfW0F8tBg23jxrTwIDAQAB`
    },
    exampleJwk() {
      return `Example: {
  "kty": "RSA",
  "e": "AQAB",
  "kid": "b6a3e1b7-4610-4b78-b0ff-12ab43b9ea89",
  "n": "zcxCLNs8EUfU47hzaaHxhhkFCdMAszBO8kk-wVDVMUZPFGQSc4mvxNGpDr_KsHxbMAmFTI4KE0bYIeFFAh7LSLAFohhIRI9wuJqIRInZkmlwKNVomw1a68fLbjAQxLxOHQGbo-4sbmkzaNHNAejvVqFJ8AyoH1tBfLQYNt48a08"
}`
    }
  },
  methods: {
    formatJwk() {
      try {
        this.platformData.jwk = JSON.stringify(
          JSON.parse(this.platformData.jwk),
          null,
          2
        )
      } catch {}
    },
    formatPublicKey() {
      try {
        this.platformData.publicKey = this.platformData.publicKey.match(
          /-+BEGIN.+KEY-+\n(.+)\n-+END.+KEY-+/s
        )[1]
      } catch {}
    },
    async loadPlatform() {
      const platform = await client.lti.getPlatform({ id: this.id })
      this.platform = {
        jwkUrl: '',
        jwk: '',
        publicKey: '',
        ...platform
      }
      if (this.platform.jwk) {
        this.keyType = 'jwk'
      } else if (this.platform.jwkUrl) {
        this.keyType = 'jwkUrl'
      } else if (this.platform.publicKey) {
        this.keyType = 'publicKey'
      }
    },
    async save(e) {
      try {
        const { jwkUrl, jwk, publicKey, ...data } = this.platform
        switch (this.keyType) {
          case 'publicKey':
            data.publicKey = publicKey
            break
          case 'jwk':
            data.jwk = jwk
            break
          case 'jwkUrl':
            data.jwkUrl = jwkUrl
            break
        }
        await client.lti.updatePlatform(data)
        this.$success(`${this.platform.name} was updated.`)
        this.$router.push({ name: 'lti_platforms' })
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    }
  },
  watch: {
    id: {
      handler: 'loadPlatform',
      immediate: true
    }
  }
}
</script>
