<template>
  <collapse-provider
    v-model:collapsed="collapsed"
    class="dragable-panel"
    data-testid="dragable-panel"
    role="region"
    :aria-label="ariaLabel"
  >
    <h4 class="panel-heading-title">
      <collapse-toggle class="panel-title">
        <collapse-icon class="title-icon" />
        <span>{{ name }}:</span>
        <span class="component-count"
          >{{ componentsCount }}
          {{ componentsCount > 1 ? 'Components' : 'Component' }}</span
        >
      </collapse-toggle>
    </h4>
    <collapse-content>
      <span class="activity-dragged-section" ref="root">
        <div
          class="components panel-group inner-panel"
          role="tablist"
          aria-multiselectable="true"
        >
          <draggable
            v-model="componentsList"
            ghost-class="moving-section"
            :animation="200"
            class="component-list-group"
            :class="
              componentDragging && components.length === 0
                ? 'empty-dropzone'
                : ''
            "
            group="components"
            handle=".component-drag-handle"
            :empty-insert-threshold="50"
            :scroll-sensitivity="200"
            :force-fallback="true"
            @end="handleEnd"
            @unchoose="() => (isChosen = false)"
            @start="emit('componentDragging', true)"
            @choose="() => (isChosen = true)"
            item-key="_id"
            @add="handleAdd"
          >
            <template #item="{ element: component, index }">
              <div>
                <activity-component
                  :class="{
                    'component-sortable': componentDragging
                  }"
                  :component="component"
                  :first="!allowMoveUp && index === 0"
                  :questionNumber="questionNumber"
                  :sectionName="name"
                  :variableContext="variableContext"
                  :sectionIndex="parentIndex"
                  nested
                  :last="!allowMoveDown && index === lastIndex"
                  @change="arg => updateComponent(arg, index)"
                  @move-panel="(...args) => emit('moveComponent', ...args)"
                  @panel-action="arg => panelAction(index, arg)"
                />
                <div v-if="componentsCount > 0 && isChosen === false">
                  <div
                    v-show="showAddComponentBox(index, lastIndex)"
                    class="add-component looped-components"
                  >
                    <add-activity-bar
                      :ref="el => setAddComponentRef(index, el)"
                      v-model="newComponentType"
                      humanized-component-name="Paste from Clipboard"
                      :isLastComponent="index === lastIndex"
                      @addComponent="addComponent(index)"
                      @pasteFromClipboard="pasteFromClipboard(index)"
                      @removeAddComponentBar="componentIndex = undefined"
                    />
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
        <div v-if="componentsCount === 0" class="add-component">
          <add-activity-bar
            v-model="newComponentType"
            humanized-component-name="Paste from Clipboard"
            @addComponent="addComponent(-1)"
            @pasteFromClipboard="pasteFromClipboard(-1)"
            :can-add-split="false"
          />
        </div>
      </span>
    </collapse-content>
  </collapse-provider>
</template>

<script setup lang="ts">
import {
  Ref,
  WritableComputedRef,
  computed,
  inject,
  nextTick,
  onMounted,
  ref
} from 'vue'
import { generateObjectId } from '../../../shared/utils/objectId'
import { InheritedActivity, DraggableComponent } from '../types'
import draggable from 'vuedraggable'
import AddActivityBar from './AddActivityBar.vue'
import ActivityComponent from './ActivityComponent.vue'
import { useFlash } from 'src/shared/hooks/flash'
import { humanizeComponentType } from 'src/setup/mixins/humanizeComponentType'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal.vue'
import useArrayRef from 'src/shared/hooks/array-ref'

const emit = defineEmits(['update', 'componentDragging', 'moveComponent'])
const props = defineProps({
  componentDragging: {
    type: Boolean,
    default: false
  },
  components: {
    type: Array,
    required: true
  },
  parentIndex: {
    type: Number,
    required: true
  },
  variableContext: {
    type: Object,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  ariaLabel: {
    type: String,
    default: 'Content'
  },
  allowMoveUp: {
    type: Boolean,
    default: false
  },
  allowMoveDown: {
    type: Boolean,
    default: false
  },
  questionNumber: Function
})
const inherited: Ref<InheritedActivity> | undefined = inject('inherited')
const flash = useFlash()
const modal: any = inject('$modal')

const collapsed = ref<boolean>(false)
const root = ref<HTMLElement | null>(null)
const isChosen = ref<boolean>(false)
const newComponentType = ref<string | undefined>(undefined)
const sourceActivity = ref<any>()
const componentIndex = ref<number>()

const componentsList: WritableComputedRef<DraggableComponent[]> = computed<
  DraggableComponent[]
>({
  get() {
    if (props.components.length > 0) {
      return props.components as DraggableComponent[]
    }
    // we need to send empty array like this, as vue-draggable library required this in order to shift component from a section into empty section
    return []
  },
  set(newValue) {
    contentChanged(newValue)
  }
})

const [addComponentRefs, setAddComponentRef] = useArrayRef<Ref<HTMLElement>>()

const componentsCount = computed(() => (props.components || []).length)
const lastIndex = computed(() => componentsCount.value - 1)

onMounted(() => {
  componentsList.value.forEach(component => (component.collapsed = true))

  let hashParams = window.location.hash
  hashParams = hashParams.replace('#component-', '')
  if (hashParams) {
    const component = componentsList.value.find(c => c._id === hashParams)
    if (component) {
      component.collapsed = false
      document.getElementById(`component-${hashParams}`)?.scrollIntoView()
    }
  }
})

const handleEnd = (event: any) => {
  emit('componentDragging', false)
  event.item.firstElementChild.focus()
}

const handleAdd = (event: any) => {
  nextTick(() => scrollToComponent(event.newIndex))
}

const scrollToComponent = (index: number) => {
  const components = root.value?.querySelectorAll<HTMLElement>(
    '[data-queryid="nested-activity-component"]'
  )
  const componentToFocus: HTMLElement | undefined = components?.[index]
  componentToFocus?.focus()
}

const panelAction = (index: number, args: any) => {
  if (args.action === 'delete') deleteComponent(index)
  if (args.action === 'add') focusOnAddComponentBox(index)
  if (args.action === 'duplicate') duplicateComponent(index)
  if (args.action === 'clipboard') saveToClipBoard(index)
  if (args.action === 'copyLinkToClipboard') copyLinkToClipboard(index)
  if (args.action === 'previewComponent') previewComponent(index)
  if (args.action === 'toggle-collapse') {
    const component = componentsList.value[index]
    updateComponent({ ...component, collapsed: !args.panel.collapsed }, index)
  }
}
const saveToClipBoard = async (index: number) => {
  const component = componentsList.value[index]
  try {
    const payload = {
      fromPivot: true,
      type: 'activity-component',
      data: {
        component,
        activityId: inherited?.value?.activity.id,
        variables: activityVariables()
      }
    }
    await navigator.clipboard.writeText(JSON.stringify(payload))
    flash.success(`Component has been copied to clipboard`)
  } catch (error) {
    flash.error('Something went wrong, please try again.')
  }
}
const activityVariables = () => {
  return props.variableContext.variables.map((variable: any) => {
    return {
      id: variable.id,
      name: variable.name
    }
  })
}
const copyLinkToClipboard = (index: number) => {
  const url = `${window.location.origin}/activities/${inherited?.value?.activity.id}/content/#component-${componentsList.value[index]._id}`
  navigator.clipboard.writeText(url)
  flash.success('Link copied to clipboard')
}

const duplicateComponent = async (index: number) => {
  const insertionIndex = index + 1
  const componentsCopy = componentsList.value.slice()

  const toBeDuplicate = {
    ...componentsList.value[index],
    _id: generateObjectId(),
    keyId: Math.floor(Math.random() * 100000),
    collapsed: false
  }

  componentsCopy.splice(insertionIndex, 0, toBeDuplicate)

  await contentChanged(componentsCopy)

  nextTick(() => {
    scrollToComponent(insertionIndex)
  })
}

const focusOnAddComponentBox = (index: number) => {
  componentIndex.value = index
  nextTick(() => addComponentRefs.value[index]?.value?.focus())
}
const previewComponent = (index: number) => {
  const url = `${window.location.origin}/activities/${inherited?.value?.activity.id}/preview/#component-${componentsList.value[index]._id}`
  window.open(url, '_blank', 'noopener')
}

const deleteComponent = async (componentIndex: number) => {
  const component = componentsList.value[componentIndex]
  // determine if the component being removed is being referenced as a student response variable anywhere
  const isComponentResponseReferenced = props.variableContext.variables.some(
    (variable: any) =>
      variable.variableType === 'studentResponse' &&
      variable.content === component._id
  )

  const { status } = await modal.show(ConfirmModal, {
    text: isComponentResponseReferenced
      ? 'The student response value for this component is being referenced in other components as a variable. Deleting this component will break other components that rely on this variable.'
      : '',
    prompt: `Are you sure you want to delete this component? This action cannot be undone.`
  })
  if (status === 'ok') {
    await contentChanged(
      componentsList.value.filter((_, i) => i !== componentIndex)
    )
  }
}

const addComponent = async (index: number) => {
  if (!newComponentType.value) return
  const insertionIndex = index + 1
  const newComponent: DraggableComponent = {
    _id: generateObjectId(),
    componentType: newComponentType.value,
    choices: [],
    answer: '',
    pointValue: [
      'MultipleChoiceQuestion',
      'DragDropQuestion',
      'NumericalQuestion'
    ].includes(newComponentType.value)
      ? 1
      : 0,
    collapsed: false,
    conditions: [],
    hideHint: true
  }
  componentIndex.value = undefined
  if (newComponent.componentType) {
    if (newComponent.componentType === 'PhetIOSim') {
      newComponent.config = '{"version":"2","urls":"","state":"{}"}'
    }
    if (newComponent.componentType === 'PhetIOSimulation') {
      newComponent.config =
        '{"simName": "", "simVersion": "", "wrapper": "", "values": []}'
    }
    if (
      ['MultipleChoiceQuestion', 'DragDropQuestion'].includes(
        newComponent.componentType
      )
    ) {
      newComponent.randomizedChoices = true
    }
    if (
      [
        'MultipleChoiceQuestion',
        'DragDropQuestion',
        'NumericalQuestion'
      ].includes(newComponent.componentType)
    ) {
      newComponent.autograde = true
    }
    if (newComponent.componentType === 'GridGraphQuestion') {
      newComponent.settings = {
        includeColumnStatistics: false,
        includeFormulaBar: false,
        allowUncertainty: false,
        legacyInterface: false,
        includeBluetooth: true,
        includeCsvDownload: true,
        curveFitTypes: {
          proportional: true,
          linear: true,
          squareLaw: true,
          squareRoot: true,
          quadratic: true,
          exponential: true,
          logarithmic: true,
          inverse: true,
          inverseSquare: true
        },
        graphTypes: {
          scatterGraphs: true,
          lineGraphs: true,
          barGraphs: true
        }
      }
    }
    if (newComponent.componentType === 'GridQuestion') {
      newComponent.settings = {
        includeColumnStatistics: false,
        includeFormulaBar: false,
        includeBluetooth: true,
        includeCsvDownload: true,
        allowUncertainty: true,
        legacyInterface: false,
        curveFitTypes: {
          proportional: true,
          linear: true,
          squareLaw: true,
          squareRoot: true,
          quadratic: true,
          exponential: true,
          logarithmic: true,
          inverse: true,
          inverseSquare: true
        },
        graphTypes: {
          scatterGraphs: true,
          lineGraphs: true,
          barGraphs: true
        }
      }
    }
    newComponent.keyId = Math.floor(Math.random() * 100000)

    const newComponents = [...componentsList.value]
    newComponents.splice(insertionIndex, 0, newComponent)
    await contentChanged(newComponents)
  }
  nextTick(() => {
    scrollToComponent(insertionIndex)
  })
  newComponentType.value = ''
}

const contentChanged = async (components: DraggableComponent[]) => {
  emit('update', components)
}

const showAddComponentBox = (index: number, lastComponent: number) => {
  return componentIndex.value === index || index === lastComponent
}
const pasteFromClipboard = async (index: number) => {
  const clipboardComponent = await getClipboardData()
  if (!clipboardComponent) {
    //show message
    flash.error('Clipboard is empty')
    return
  }
  if (clipboardComponent.componentType === 'SplitView') {
    flash.error(
      'Cannot paste SplitView component into another SplitView component'
    )
    return
  }
  const insertionIndex = index + 1
  const componentsCopy = componentsList.value.slice()
  const toBeCopy = {
    ...clipboardComponent,
    _id: '',
    keyId: Math.floor(Math.random() * 100000)
  }
  componentIndex.value = undefined
  parseComponent(toBeCopy)

  componentsCopy.splice(insertionIndex, 0, toBeCopy)
  await contentChanged(componentsCopy)
  nextTick(() => {
    scrollToComponent(insertionIndex)
  })
}

const parseComponent = (component: DraggableComponent) => {
  if (component.componentType === 'IFrame') {
    component.url = parseVariables(component.url ?? '')
  }
  if (component.componentType === 'NumericalQuestion') {
    // parse conditions
    component.conditions?.map(condition => {
      condition.condition = parseVariables(condition?.condition ?? '')
      condition.response = parseVariables(condition.response ?? '', true)
      return condition
    })
  }

  if (component.componentType === 'MultipleChoiceQuestion') {
    // parse conditions
    component.choices?.map(choice => {
      choice.text = parseVariables(choice.text, true)
      choice.response = parseVariables(choice?.response ?? '', true)
      return choice
    })
  }

  if (hasText(component)) {
    // parse text
    component.text = parseVariables(component.text ?? '', true)
  }
  if (isQuestion(component)) {
    // parse hint
    component.hint = parseVariables(component.hint ?? '', true)
  }
}
const sourceVariables = computed(() => {
  return sourceActivity.value?.variables
})
const parseVariables = (str: string, enCloseBrackets = false) => {
  if (sourceVariables.value === undefined || str === undefined) {
    return str
  }
  sourceVariables.value.forEach((variable: any) => {
    const indexOfVariable = str.indexOf(`${variable.id}`)
    if (indexOfVariable !== -1) {
      str = str.replace(
        new RegExp(`${variable.id}`, 'g'),
        `${variable.name}_UND`
      )
    }
  })
  return str
}

const getClipboardData = async () => {
  if (document.hasFocus()) {
    try {
      const payload = JSON.parse(await navigator.clipboard.readText())

      if (payload.fromPivot === true && payload.type === 'activity-component') {
        const { component, activityId, variables } = payload.data

        if (isValidComponent(component.componentType).length) {
          if (activityId === inherited?.value?.activity.id) {
            delete sourceActivity.value
          } else {
            sourceActivity.value = { variables }
          }

          return component
        }
      }
    } catch {
      delete sourceActivity.value
      return
    }
  }
}

const isValidComponent = (componentType: string): string => {
  return humanizeComponentType(componentType)
}
const isQuestion = (component: DraggableComponent): boolean => {
  return component.componentType.indexOf('Question') !== -1
}

const hasText = (component: DraggableComponent): boolean => {
  return !['EmbeddedInstance', 'IFrame', 'PhetIOSim'].includes(
    component.componentType
  )
}

const updateComponent = (fields: DraggableComponent, index: number) => {
  // Prop mutation is not ideal, but passing a new object up as an event has poor performance.
  const component = componentsList.value[index]
  for (const [key, value] of Object.entries(fields)) {
    if (value === undefined || value === null) {
      delete component[key as keyof DraggableComponent]
    } else {
      ;(component[key as keyof DraggableComponent] as any) = value
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-heading-title {
  display: flex;
  padding-top: 10px;
  & .title-icon {
    width: 18px;
    margin-right: 8px;
  }

  & .panel-title {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
  }

  & .panel-title:hover {
    color: $teal;
    text-decoration: underline;
  }

  .component-count {
    padding-left: 5px;
  }
}

.dragable-panel {
  margin-right: 20px;
}

.empty-dropzone {
  height: 100px;
}
</style>
