<template>
  <modal @submit="submit">
    <modal-header>Transfer Student</modal-header>
    <modal-body>
      <form-group>
        <form-label for="classKey">
          Enter the class key of the destination class
        </form-label>
        <div>
          <text-input
            id="classKey"
            ref="classKey"
            v-model="classKey"
            rules="required"
            label="class key"
            help-text="Examples: 'abcd1234' or 'ck-abcd1234'"
          />
        </div>
      </form-group>

      <div v-if="klass">
        <p>
          The following {{ noun }} will be transferred to the class
          <strong>{{ klass.name }} </strong
          ><template v-if="klass.instructors.length"
            ><span> taught by </span>
            <strong>
              {{ klass.instructors.map(i => i.lastName).join(', ') }}
            </strong></template
          >:
        </p>

        <ul>
          <li v-for="student in students" :key="student.id">
            {{ student.firstName }} {{ student.lastName }}
          </li>
        </ul>
        <p>
          {{ article }} {{ noun }} will be dropped from your class named
          <strong>{{ currentClassName }}</strong
          >. They will lose access to their work in this class, and it cannot be
          transferred. You will still be able to view this work by clicking the
          student's name in the <strong>Dropped Students</strong> section.
        </p>

        <p class="prompt">
          <strong
            >Are you sure you want to transfer {{ article.toLowerCase() }}
            {{ noun }}?</strong
          >
        </p>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit v-if="klass">Transfer</modal-button-submit>
      <modal-button-submit v-else>Continue</modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonCancel,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import client from 'src/shared/api-client'

export default {
  name: 'TransferStudentModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonCancel,
    ModalButtonSubmit
  },
  props: {
    students: {
      type: Array,
      default: () => []
    },
    currentClassName: {
      type: String,
      default: ''
    }
  },
  watch: {
    classKey() {
      this.klass = null
    }
  },

  data() {
    return {
      klass: null,
      classKey: ''
    }
  },
  computed: {
    noun() {
      return this.students.length === 1 ? 'student' : 'students'
    },
    article() {
      return this.students.length === 1 ? 'This' : 'These'
    }
  },

  methods: {
    async submit(e) {
      try {
        if (!this.klass) {
          await this.validate()
          e.done(false)
        } else if (this.klass) {
          e.done({
            classKey: this.classKey,
            className: this.klass.name
          })
        }
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    async validate() {
      try {
        const body = await client.classes.validate({ classKey: this.classKey })
        this.klass = body
      } catch (error) {
        if (error.status === 404) {
          this.$refs.classKey.applyError(
            'There is not a class with this class key.'
          )
        } else {
          throw error
        }
      }
    }
  }
}
</script>

<style scoped>
p.prompt {
  font-weight: bold;
  margin-bottom: 0;
}
</style>
