import { EventEmitter } from 'events'
import Sensor from './Sensor'

const SERVICE = '4a5c0000-0000-0000-0000-5c1e741f1c00'
const COMMAND_CHARACTERISTIC = '4a5c0000-0002-0000-0000-5c1e741f1c00'
const RESPONSE_CHARACTERISTIC = '4a5c0000-0003-0000-0000-5c1e741f1c00'

const NOOP = 0x00
const GET_FIRMWARE = 0x15
const START_SAMPLING = 0x06
const STOP_SAMPLING = 0x07
const BATTERY_STATUS = 0x85
const COMMAND = 0xc0

export default class Device extends EventEmitter {
  constructor(nativeDevice, interfaceData, sensorData) {
    super()

    this.nativeDevice = nativeDevice
    this.interfaceData = interfaceData
    this.sensorData = sensorData
    this.sensors = []
    this.opened = false

    this.deviceCommand = null
    this.deviceResponse = null

    this.batteryStatus = {
      mV: 0,
      percent: 0,
      charging: false,
      low: false,
      full: false
    }

    this.firmware = '0.0'
  }

  async open(autoStart = false) {
    if (this.opened) {
      throw new Error('Device cannot be opened because it is already open')
    }

    await this._connect()
    await this._init()
    await this._getFirmware()
    await this._initSensors()

    this._onOpened()

    if (autoStart) {
      await this.start()
    }
  }

  close() {
    return this.nativeDevice.gatt.disconnect()
  }

  async start(samplePeriodUs) {
    // Set each sensors sample period
    for (const sensor of this.sensors) {
      await sensor.setSamplePeriod(samplePeriodUs)
    }

    // Send command to start sending periodic samples
    await this._sendCommand(new Uint8Array([START_SAMPLING]))
  }

  async stop() {
    // Send command to stop sending periodic samples
    await this._sendCommand(new Uint8Array([STOP_SAMPLING]))
  }

  async _connect() {
    // Add listener for BLE disconnection
    this.nativeDevice.addEventListener('gattserverdisconnected', () =>
      this._onClosed()
    )

    const server = await this.nativeDevice.gatt.connect()
    const service = await server.getPrimaryService(SERVICE)
    const characteristics = await service.getCharacteristics()

    // Make sure the required characteristics exist
    characteristics.forEach(characteristic => {
      switch (characteristic.uuid) {
        case COMMAND_CHARACTERISTIC:
          this.deviceCommand = characteristic
          break
        case RESPONSE_CHARACTERISTIC:
          this.deviceResponse = characteristic
          this.deviceResponse.addEventListener(
            'characteristicvaluechanged',
            event => {
              const response = event.target.value
              this._handleResponse(response)
            }
          )
          this.deviceResponse.startNotifications()
          break
      }
    })

    if (!(this.deviceCommand && this.deviceResponse)) {
      throw new Error('Expected command and response characteristics not found')
    }
  }

  async _initSensors() {
    // Each sensor has it's own service which is the same UUID
    // with an incrementing first group digit.
    let serviceCounter = 1
    for (const channel of this.interfaceData.Channels) {
      const { SensorID } = channel
      const sensorData = this.sensorData[SensorID]
      const service = await this.nativeDevice.gatt.getPrimaryService(
        `4a5c000${serviceCounter++}-0000-0000-0000-5c1e741f1c00`
      )
      const sensor = new Sensor(sensorData, service)
      await sensor.init()
      this.sensors.push(sensor)
    }
  }

  async _sendCommand(command) {
    await this.deviceCommand.writeValue(command)
  }

  _handleResponse(notification) {
    const resType = notification.getUint8(0)
    switch (resType) {
      case BATTERY_STATUS:
        this._processBatteryStatus(notification)
        break
      case COMMAND:
        this._processCommandResponse(notification)
        break
    }
  }

  _processBatteryStatus(notification) {
    const resPacket = new DataView(notification.buffer, 1)
    const flags = resPacket.getUint8(4)

    this.batteryStatus = {
      mV: resPacket.getUint16(0, true),
      percent: resPacket.getUint16(2, true),
      charging: (flags & 1) !== 0,
      low: (flags & 2) !== 0,
      full: (flags & 4) !== 0
    }
  }

  _processCommandResponse(notification) {
    const resCommand = notification.getUint8(2)
    const resPacket = new DataView(notification.buffer, 3)

    // There are other command responses that we aren't using,
    // switch here is just for easier updating later on.
    switch (resCommand) {
      case GET_FIRMWARE:
        this.firmware = `${resPacket.getUint8(0)}.${resPacket.getUint8(1)}`
        break
    }
  }

  async _init() {
    // Sending this NOOP command just tells the device we're communicating
    await this._sendCommand(new Uint8Array([NOOP]))
  }

  async _getFirmware() {
    await this._sendCommand(new Uint8Array([GET_FIRMWARE]))
  }

  _onOpened() {
    this.opened = true
    this.emit('device-opened')
  }

  _onClosed() {
    this.opened = false
    this.emit('device-closed')
  }
}
