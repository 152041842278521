<template>
  <choice-input
    :rules="{ required: !isAdmin }"
    label="primary subjects"
    v-model="model"
  >
    <checkbox value="biology"> Biology </checkbox>
    <checkbox value="chemistry"> Chemistry </checkbox>
    <checkbox value="earth-science"> Earth Science </checkbox>
    <checkbox value="environmental-science"> Environmental Science </checkbox>
    <checkbox value="physics"> Physics </checkbox>
    <checkbox value="astronomy"> Astronomy </checkbox>
    <checkbox value="none"> None </checkbox>
  </choice-input>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PrimarySubjectsChoices',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),

    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        if (value.length > 1 && value[value.length - 1] === 'none') {
          value = ['none']
        } else if (
          value[value.length - 1] !== 'none' &&
          value.indexOf('none') !== -1
        ) {
          value.splice(value.indexOf('none'), 1)
        }
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
