<template>
  <submit-button class="modal-button-submit">
    <slot>Ok</slot>
  </submit-button>
</template>

<script>
export default {
  name: 'ModalButtonOk',
  inject: ['$_modal']
}
</script>

<style lang="scss">
.modal-button-submit {
  margin: 0 0 0 16px;
}
</style>
