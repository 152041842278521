<template>
  <modal class="activity-changelog-modal">
    <modal-header class="activity-changelog-modal__header"
      >Changelog
      <template #secondary
        ><form-button
          class="activity-changelog-modal__toggle-all"
          link
          @click="toggleLogs"
          >Toggle All Logs</form-button
        ></template
      >
    </modal-header>
    <modal-body v-if="props.changelogEntries">
      <changelog-entry
        v-for="entry in props.changelogEntries"
        :key="entry.id"
        :changelogEntry="entry"
        :collapsed="!!collapsedEntries[entry.id]"
        @update:collapsed="collapsed => toggleEntry(entry.id, collapsed)"
      />
    </modal-body>
    <modal-actions>
      <modal-button-cancel>Close</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import ChangelogEntry from '../../activity-editor/components/ChangelogEntry'
import { ref, computed, onMounted } from 'vue'

const props = defineProps({
  changelogEntries: {
    type: Array,
    required: true
  }
})

const collapsedEntries = ref({})
const areAllCollapsed = computed(() =>
  props.changelogEntries.every(entry => collapsedEntries.value[entry.id])
)

function toggleEntry(id, collapsed) {
  collapsedEntries.value[id] = collapsed
}

function toggleLogs() {
  if (!areAllCollapsed.value) {
    collapsedEntries.value = Object.fromEntries(
      props.changelogEntries.map(entry => [entry.id, true])
    )
  } else {
    collapsedEntries.value = {}
  }
}
onMounted(() => {
  collapsedEntries.value = Object.fromEntries(
    props.changelogEntries.map(entry => [entry.id, true])
  )
})
</script>

<style scoped>
pre {
  max-height: 400px;
  white-space: normal;
  word-break: normal;
  word-wrap: normal;
  overflow: scroll;
}

.activity-changelog-modal {
  width: 600px;
}
.activity-changelog-modal__header {
  margin-bottom: 30px;
}
.activity-changelog-modal__toggle-all {
  margin-left: 20px;
  font-size: 16px;
}
</style>
