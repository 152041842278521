<template>
  <span :class="chipClass">
    <span role="button" :title="`${truncate ? label : ''}`">
      {{ truncatedText }}
    </span>
    <span v-if="removeable" role="button" @click="removeChip()">
      <icon icon="times" />
      <span class="sr-only">Remove {{ label }}</span>
    </span>
  </span>
</template>
<script type="text/javscript">
export default {
  name: 'Chip',
  emits: ['remove'],
  props: {
    index: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    removeable: {
      type: Boolean,
      default: false
    },
    truncate: {
      type: [Boolean, Number],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    truncatedText() {
      if(!this.truncate) return this.label
      const charLimit = !isNaN(this.truncate) ? this.truncate : 10

      return this.label.length > charLimit
        ? this.label.slice(0, charLimit - 3) + '...'
        : this.label
    },
    chipClass() {
      return this.disabled || !this.removeable ? 'chip chip-disabled': 'chip'
    }
  },
  methods: {
    removeChip() {
      return this.$emit('remove', this.index)
    }
  }
}
</script>
<style lang="scss" scoped>
.chip {
  margin-right: 10px;
  margin-bottom: 2px;
  display: inline-block;
  background: transparent;

  & span {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: default;
    background-image: none;
    border: 1px solid transparent;
    padding: 0px 10px;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 15px;
    user-select: none;
    background-color: #6c757d;
    color: #fff;
  }

  & span:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & span:last-child {
    cursor: pointer;
    margin-left: -4px;
    border-left: 1px solid #ccc;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &.chip-disabled {
    & span {
      cursor: default;
      background-color: #979797;
      border-radius: 15px;
    }
  }
}
</style>
