<template>
  <section :aria-labelledby="`response-header-${response.id}`">
    <collapse-provider v-slot="{ collapsed }">
      <div
        class="student-panel-bar"
        :class="{
          unsaved: isDirty,
          saved: isSaved,
          graded: fullyGraded,
          collapsed: collapsed
        }"
      >
        <collapse-toggle>
          <h3 :id="`response-header-${response.id}`">
            <collapse-icon icon="caret" class="collapse-icon" />{{
              displayName
            }}
          </h3>
        </collapse-toggle>
        <div
          v-if="!collapsed && isGroupAssignment && !gradeAnonymously"
          class="students-list"
        >
          {{ studentsList }}
        </div>
        <span v-if="isDirty" class="pi-action-icon text-warning">
          <icon icon="triangle-exclamation" class="text-warning" />
          <strong>Unsaved Changes</strong>&nbsp;
        </span>
        <span v-else-if="isSaved" class="pi-action-icon bg-success">
          <icon icon="check" class="text-success" />
          <strong>Saved</strong>
        </span>
        <div v-if="!isDirty" class="grading-progress">
          <span v-if="fullyGraded">
            <icon icon="circle-check" />
            Graded
          </span>
          <span v-if="!fullyGraded && !missingResponses">
            Graded {{ gradedCount }} of {{ totalResponses }}
          </span>
          <span v-if="!fullyGraded && missingResponses">
            <icon icon="times" />
            Missing {{ missingResponses }} Answer{{
              missingResponses > 1 ? 's' : ''
            }}
          </span>
          <progress-circle
            v-if="!fullyGraded && !missingResponses"
            class="grading-progress__circle"
            :progress="gradingPercentage / 100"
            :completed="gradingPercentage === 100"
            :size="20"
          >
          </progress-circle>
        </div>
      </div>
      <collapse-content>
        <div class="panel-content__separator"></div>
        <div class="panel-content">
          <variable-provider
            v-slot="variableContext"
            :variables="variables || []"
            :values="response.variables"
          >
            <div>
              <div
                v-for="component in gradableComponents"
                :key="`${component._id}-${response.id}`"
              >
                <gradable-component-response
                  :component="component"
                  :assignment-response="response"
                  :show-correct-answer="showAnswer"
                  :variable-context="variableContext"
                  @grading-changed="
                    componentResponse =>
                      emit(
                        'update:response',
                        response,
                        component,
                        componentResponse
                      )
                  "
                />
                <hr />
              </div>
            </div>
          </variable-provider>

          <h4 v-if="responseGradingProgress == 'per_student'">
            Grading Progress
          </h4>
          <div class="status-and-buttons">
            <div v-if="responseGradingProgress == 'per_student'">
              <p v-if="!isModifiable" class="not-submitted-msg">
                This assignment has not been submitted for grading
              </p>
              <selector-input
                :id="`response-status-${response.id}-id`"
                :modelValue="response.gradingProgress"
                :label="`response-status-${response.id}`"
                @change="emit('update:progress', response, $event)"
              >
                <selector-option
                  :disabled="!isModifiable"
                  value="pending"
                  title="Pending"
                />
                <selector-option
                  :disabled="!isModifiable"
                  value="graded"
                  title="Final Grade"
                />
              </selector-input>
            </div>
            <div v-else></div>
            <div class="save-button-div">
              <form-button
                class="save-progress-button"
                secondary
                @click="emit('save:progress')"
              >
                Save Progress
              </form-button>
              <form-button @click="emit('save:grade', response)">
                Save {{ isGroupAssignment ? 'Group' : 'Student' }}
              </form-button>
            </div>
          </div>
        </div>
      </collapse-content>
    </collapse-provider>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import GradableComponentResponse from './GradableComponentResponse'
import VariableProvider from 'src/shared/components/VariableProvider'

const emit = defineEmits([
  'save:progress',
  'update:progress',
  'save:grade',
  'update:response',
  'update:is-collapsed'
])
const props = defineProps({
  response: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isGroupAssignment: {
    type: Boolean,
    required: true
  },
  gradeAnonymously: {
    type: Boolean,
    required: true
  },
  variables: {
    type: Array,
    required: true
  },
  gradableComponents: {
    type: Array,
    required: true
  },
  isDirty: {
    type: Boolean,
    required: false
  },
  isSaved: {
    type: Boolean,
    required: false
  },
  responseGradingProgress: {
    type: String,
    required: false
  },
  showAnswer: {
    type: Boolean,
    required: false
  }
})

const displayName = computed(() => {
  if (props.isGroupAssignment) {
    if (props.gradeAnonymously) {
      return `Group ${props.index + 1}`
    }
    return props.response.groupName
  }
  if (props.gradeAnonymously) {
    return `Student ${props.index + 1}`
  } else {
    return `${props.response.owner.firstName} ${props.response.owner.lastName}`
  }
})
const isModifiable = computed(
  () => props.response.gradingProgress !== 'not-ready'
)

const studentsList = computed(() => {
  return props.response.students
    ?.map(s => `${s.firstName} ${s.lastName}`)
    .join(', ')
})
const gradedCount = computed(() => {
  return props.response.responses.filter(
    r => typeof r.score === 'number' && r.score >= 0
  ).length
})

const totalResponses = computed(() => {
  return props.response.responses.length
})

const missingResponses = computed(() => {
  return props.response.responses.filter(r => !r.submissions && !r.value).length
})

const gradingPercentage = computed(() => {
  return props.response.gradingPercentage * 100
})
const fullyGraded = computed(() => {
  return props.response.gradingProgress === 'graded'
})
</script>

<style scoped lang="scss">
.students-list {
  padding-left: 15px;
  .student {
    margin-right: 10px;
  }
}
.save-button-div {
  align-self: flex-end;
}
.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  width: 40%;
}

.pi-action-icon {
  padding: 4px 8px;
  margin: 16px;
  border-radius: 4px;
  svg {
    padding-right: 6px;
  }
}
@media screen and (max-width: 800px) {
  .button-group {
    width: 60%;
  }
}

.panel .panel-heading h3 {
  padding: 10px 5px 10px 45px;
  font-size: 14px;
}

.student-grade-panel {
  .panel-heading {
    display: flex;
    align-items: center;

    h3 {
      flex-grow: 1;
    }

    .pi-action-icon {
      margin: 0 8px 0 0;
    }
  }
}

.save-all {
  margin: 0 0 0 16px;
}

p.not-submitted-msg {
  color: $color-error;
}

.panel-content {
  padding: 10px 5px 10px 23px;
}

.panel-default {
  border: none;
  border-bottom-color: #ddd;
}
.status-and-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.save-progress-button {
  margin-right: 10px;
}
.panel-content__separator {
  border-top: 1px solid #979797;
  padding: 0px 5px 10px 0px;
  margin-left: 23px;
}
.student-panel-bar {
  border-top: 4px solid rgba(52, 189, 189, 0.5);
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.graded {
    background-color: rgba(52, 189, 189, 0.1);

    .grading-progress {
      color: $teal;
    }
  }

  &.unsaved {
    background-color: rgba(204, 97, 20, 0.1);
  }

  :deep(h3) {
    color: $dark-grey;
    padding: 18px 0;
    margin: 0;
    font-size: 18px;
  }

  .flex-end {
    justify-self: flex-end;
  }
}

.grading-progress {
  width: 150px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  color: #666666;
  & :deep(.grading-progress__circle) {
    padding-left: 10px;
  }
}

.collapse-icon {
  margin-right: 10px;
}

:deep(.gradable-component-response) {
  h3 {
    margin-top: 0px;
  }
}
</style>
