import { Node, nodeInputRule } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import InlineLatex from './InlineLatex'
import { processLatex, getLatexForDisplay } from '../../../utils/latex-methods'
const LATEX_MATCH = /\$\$(.+)\$\$/

const Latex = Node.create({
  name: 'latex',
  inline: true,
  group: 'inline',
  atom: true,
  selectable: true,

  addOptions() {
    return {
      getLatexForDisplay: ({ variables, node }) => {
        return getLatexForDisplay({
          variables,
          formula: node.attrs.formula
        })
      }
    }
  },

  addStorage() {
    return {
      variableContext: null
    }
  },

  addAttributes() {
    return {
      formula: {
        default: '',
        parseHTML: element =>
          element.getAttribute('formula') ?? element.textContent.trim()
      }
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: text => {
          if (!this.storage.variableContext) return null
          const match = LATEX_MATCH.exec(text)

          if (match) {
            return {
              index: match.index,
              text: match[0],
              replaceWith: match[0],
              data: {
                formula: processLatex(
                  match[1],
                  this.storage.variableContext?.value.variables,
                  'name'
                )
              }
            }
          }
        },
        type: this.type,
        getAttributes: match => ({
          formula: match.data?.formula
        })
      })
    ]
  },

  addKeyboardShortcuts() {
    return {
      Enter({ editor }) {
        if (editor.isActive('latex')) {
          editor.commands.openLatexModal()
          return true
        }
      }
    }
  },

  addCommands() {
    return {
      setLatex:
        attributes =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: attributes
          })
        },
      openLatexModal: () => () => {
        this.storage.onEditLatex?.()
      }
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(InlineLatex)
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'inline-latex',
      { class: 'latex', formula: HTMLAttributes.formula },
      ['span', { class: 'sr-only' }],
      ['span', { class: 'katex-root' }]
    ]
  },

  parseHTML() {
    return [
      { tag: 'inline-latex' },
      // In order to parse HTML generated by quill.
      { tag: 'span.ql-formula' }
    ]
  },

  renderText({ node }) {
    return node.attrs.formula
  }
})

export default Latex
