<template>
  <div class="drag-drop-key__wrapper">
    <div class="drag-drop-key__header">
      <h4>
        {{
          correctConditions.length < 2
            ? 'Correct Answer'
            : `${correctConditions.length} Correct Conditions:`
        }}
      </h4>
      <select-field
        v-if="correctConditions.length > 1"
        v-model="selectedCondition"
        class="drag-drop-key__conditions-input"
      >
        <option
          v-for="(condition, index) in correctConditions"
          :key="index"
          :value="condition"
        >
          Condition {{ index + 1 }}
        </option>
      </select-field>
    </div>
    <DragDropArea
      :type="component.renderType"
      :variable-context="variableContext"
      :text="component.dropAreaText ?? ''"
      :choices="component.choices"
      :response="correctAnswer"
      :targets="[]"
      :target-states="{}"
    />
    <strong>Feedback:</strong>
    <EditorContent :text="feedback" :variable-context="variableContext" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue'
import DragDropArea from './DragDropArea.vue'
import { fillTargets } from '../correct-answers'

interface Condition {
  isCorrect: boolean
  response: string
  targets: { targetId: string; choices: 'all' | string[] }[]
}

interface Props {
  component: {
    renderType: 'latex' | 'rich-text'
    dropAreaText: string
    choices: any[]
    conditions: Condition[]
  }
  response?: Record<string, number>
  variableContext: any
}

const props = defineProps<Props>()

const correctConditions = computed(() =>
  props.component.conditions.filter(condition => condition.isCorrect)
)

// We default to the condition that has the most targets that match the student's response.
const selectedCondition = ref<Condition>()
watchEffect(() => {
  const { index } = correctConditions.value
    .map((condition, i) => ({
      index: i,
      count: condition.targets.filter(target => {
        const choice = props.response?.[target.targetId]
        return (
          target.choices === 'all' ||
          (target.choices.length === 0 && !choice) ||
          (choice && target.choices.includes(choice.toString()))
        )
      }).length
    }))
    .sort((a, b) => {
      const diff = b.count - a.count
      return diff === 0 ? a.index - b.index : diff
    })[0]
  selectedCondition.value = correctConditions.value[index]
})

const correctAnswer = computed(() => {
  if (selectedCondition.value) {
    return fillTargets({
      condition: selectedCondition.value,
      choices: props.component.choices
    })
  } else {
    return {}
  }
})
const feedback = computed(() => selectedCondition.value?.response)
</script>

<style scoped lang="scss">
.drag-drop-key__wrapper {
  background-color: #eee;
  padding: 8px 16px 16px 16px;
}

.drag-drop-key__header {
  display: flex;
}

.drag-drop-key__conditions-input {
  margin-left: 8px;
  flex-grow: 1;
}
</style>
