function flashStub() {
  throw new Error('flash mixin must be used within FlashProvider')
}

export default {
  inject: {
    _flash: {
      default: {
        messages: [],
        add: flashStub,
        remove: flashStub,
        clearFlash: flashStub
      }
    }
  },
  methods: {
    $error(text, options = {}) {
      this._flash.add({ ...options, level: 'error', text: text })
    },
    $warning(text, options = {}) {
      this._flash.add({ ...options, level: 'warning', text: text })
    },
    $notice(text, options = {}) {
      this._flash.add({ ...options, level: 'notice', text: text })
    },
    $success(text, options = {}) {
      this._flash.add({ ...options, level: 'success', text: text })
    },
    $clearFlash() {
      this._flash.clear()
    }
  }
}
