import { EditorState, Plugin, PluginKey } from '@tiptap/pm/state'
import { Editor, posToDOMRect } from '@tiptap/core'
import { EditorView } from '@tiptap/pm/view'
import { getAttributes } from '@tiptap/core'

interface VocabTooltipPluginProps {
  pluginKey: PluginKey | string
  editor: Editor
  onTipActivated: (tip: any) => void
}

export type VocabTooltipPluginViewProps = VocabTooltipPluginProps & {
  view: EditorView
}

export const VocabTooltipPlugin = (options: VocabTooltipPluginProps) => {
  return new Plugin({
    key:
      typeof options.pluginKey === 'string'
        ? new PluginKey(options.pluginKey)
        : options.pluginKey,
    props: {
      handleClick: (view, _pos, event) => {
        if (event.button !== 0) {
          return false
        }

        const a = event.target as HTMLElement
        const attrs = getAttributes(view.state, 'vocabTooltip')
        if (attrs.tip && options.onTipActivated) {
          options.onTipActivated(attrs.tip)
        }
        return
      }
    }
  })
}
