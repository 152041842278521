import type Client from './client'
import {
  CreateBookstorePurchaseRequestBody,
  CreateBookstorePurchaseResponseBody,
  SearchBookstorePurchasesRequest,
  SearchBookstorePurchasesResponseBody
} from '@pi/api-types'

export default class Bookstore {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async search(
    request: SearchBookstorePurchasesRequest
  ): Promise<SearchBookstorePurchasesResponseBody> {
    const params = new URLSearchParams({
      page: request.page ? request.page.toString() : '1',
      limit: request.limit ? request.limit.toString() : '1000'
    })
    const { body } =
      await this.client.get<SearchBookstorePurchasesResponseBody>({
        path: `/api/v2/bookstore-purchases?${params}`
      })
    return body
  }
  async create(
    request: CreateBookstorePurchaseRequestBody
  ): Promise<CreateBookstorePurchaseResponseBody> {
    const { body } =
      await this.client.post<CreateBookstorePurchaseResponseBody>({
        path: '/api/v2/bookstore-purchases',
        body: JSON.stringify(request)
      })
    return body
  }
}
