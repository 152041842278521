<template>
  <modal @submit="submit">
    <modal-header>Update Instructors</modal-header>
    <modal-body>
      <div class="form-container">
        <form-group>
          <form-label for="select-instructor"> Instructor </form-label>
          <autocomplete
            id="select-instructor"
            v-model="selectedInstructors"
            label="instructor"
            :options="instructorList"
            value-key="id"
            label-key="name"
            multiple
            aria-label="select instructors"
          />
        </form-group>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit data-focus>Save</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import { onMounted, ref } from 'vue'
import Autocomplete from 'src/shared/components/Autocomplete.vue'
import client from 'src/shared/api-client'
import { InstructorList, LicensedClass } from '@pi/api-types'

interface ChangeInstructorModalProps {
  licenseClass: LicensedClass
  licenseId: string
}
const props: ChangeInstructorModalProps =
  defineProps<ChangeInstructorModalProps>()

const instructorList = ref<InstructorList[]>([])
const selectedInstructors = ref<string[]>(
  props.licenseClass.instructors.map(i => i.id)
)
const fetchInstructors = async () => {
  const result = await client.licenses.getInstructors(props.licenseId)
  instructorList.value = result.instructors
}

onMounted(async () => {
  await fetchInstructors()
})

const submit = async (event: any) => {
  event.done({
    instructorIds: selectedInstructors.value
  })
}
</script>

<style scoped lang="scss">
.form-container {
  min-height: 200px;
}
</style>
