<template>
  <browse-quotes ref="browseQuotes" :query="computedQuery">
    <view-container>
      <view-header>
        <view-title>Quotes</view-title>
        <view-header-actions>
          <form-button class="new-quote-btn" right @click="newLicenseQuote">
            <icon icon="plus" />
          </form-button>
        </view-header-actions>
      </view-header>
      <browse-quotes-layout>
        <template #sidebar>
          <browse-sidebar>
            <sidebar-filter />
          </browse-sidebar>
        </template>
        <template #search>
          <quotes-search />
        </template>
        <template #summary>
          <browse-summary />
        </template>
        <template #quotes>
          <quotes-list />
        </template>
      </browse-quotes-layout>
    </view-container>
  </browse-quotes>
</template>

<script>
import BrowseQuotes from 'src/modules/purchases/components/BrowseQuotes'
import BrowseQuotesLayout from 'src/modules/purchases/components/BrowseQuotesLayout'
import QuotesSearch from 'src/modules/purchases/components/QuotesSearch'
import BrowseSidebar from 'src/shared/components/BrowseSidebar'
import BrowseSummary from 'src/modules/purchases/components/BrowseSummary'
import QuotesList from 'src/modules/purchases/components/QuotesList'

import SidebarFilter from 'src/modules/purchases/components/SidebarFilter'

export default {
  name: 'LicensesView',
  components: {
    BrowseQuotes,
    BrowseQuotesLayout,
    BrowseSidebar,
    BrowseSummary,
    QuotesSearch,
    QuotesList,
    SidebarFilter
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  computed: {
    computedQuery() {
      return {
        page: parseInt(this.query.page) || 1,
        sort: this.query.sort,
        dir: this.query.dir,
        q: this.query.q || '',
        types: Array.isArray(this.query.types || [])
          ? this.query.types || []
          : [this.query.types],
        status: this.query.status || 'quote'
      }
    }
  },
  methods: {
    newLicenseQuote() {
      this.$router.push({ name: 'new_license_session' })
    }
  }
}
</script>

<style scoped>
.new-quote-btn {
  margin-bottom: 15px;
}
</style>
