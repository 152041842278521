<template>
  <modal ref="modal" :closable="false" @submit="submit">
    <modal-header>Choose Login Method</modal-header>
    <modal-body class="choose-login-modal__body">
      <p class="choose-login-modal__prompt">
        We found multiple ways to log in to your account. Please choose how you
        would like to log in to Pivot Interactives. You will only be able to use
        this method for future logins.
      </p>
      <choice-input
        rules="required"
        label="login-method"
        v-model="loginMethodChosen"
      >
        <radio-button
          v-for="(method, index) in currentLoginMethods"
          :key="index"
          :value="method.value"
        >
          {{ method.display }}
        </radio-button>
      </choice-input>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Confirm</modal-button-submit>
    </modal-actions>
  </modal>
</template>

<script setup>
import { useStore } from 'vuex'
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import { ref, computed, inject } from 'vue'
import client from 'src/shared/api-client'

const $modal = inject('$modal')
const store = useStore()
const loginMethodChosen = ref(null)
const currentLoginMethods = computed(() => {
  return store.getters.loginMethods.map(method => {
    switch (method) {
      case 'password':
        return { display: 'Email and Password', value: 'password' }
      case 'google':
        return { display: 'Google', value: 'google' }
      case 'azure':
        return { display: 'Microsoft', value: 'azure' }
      case 'classLink':
        return { display: 'ClassLink', value: 'classLink' }
      case 'clever':
        return { display: 'Clever', value: 'clever' }
      default:
        return { display: 'N/A', value: 'n/a' }
    }
  })
})

const submit = async e => {
  try {
    await client.auth.chooseLoginProvider(loginMethodChosen.value)
    e.done()
  } catch (error) {
    e.done(false)
    throw error
  }
}
</script>

<style scoped>
.choose-login-modal__body {
  margin-top: 10px;
}
.choose-login-modal__prompt {
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
