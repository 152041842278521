<template>
  <div class="instance-settings-section">
    <template v-for="(setting, k) in instanceParameters" :key="k">
      <div class="setting-selection">
        <form-label :for="`instance-setting-${component}-${setting.name}`">
          {{ setting.label }}
        </form-label>
        <select-field
          :id="`instance-setting-${component}-${setting.name}`"
          :modelValue="getParam(setting.name).type"
          @update:modelValue="value => setParameterType(setting.name, value)"
        >
          <option key="student" value="student">Student Selected</option>
          <option key="variable" value="variable">Variable</option>
          <option key="preselected" value="preselected">Preselected</option>
        </select-field>
        <select-field
          v-if="getParam(setting.name).type === 'preselected'"
          :modelValue="getParam(setting.name).initial"
          aria-label="parameter value"
          class="setting-option"
          @update:modelValue="value => setParameterValue(setting.name, value)"
        >
          <option
            v-for="opt in setting.options"
            :key="opt"
            :value="opt"
            :disabled="!setting.validOptions.includes(opt)"
          >
            {{ opt }}
          </option>
        </select-field>
        <div
          class="setting-variable"
          v-if="getParam(setting.name).type === 'variable'"
        >
          <form-button
            link
            @click="
              () => changeVariable(setting.name, getParam(setting.name).initial)
            "
          >
            [{{ variableName(getParam(setting.name).initial) }}]
          </form-button>
        </div>
        <checkbox
          v-if="getParam(setting.name).type !== 'student'"
          :modelValue="getParam(setting.name).visible"
          @update:modelValue="
            value => setParameterVisibility(setting.name, value)
          "
          >Show student this value</checkbox
        >
      </div>
    </template>
  </div>
</template>

<script setup>
import { inject, computed } from 'vue'
import ActivityVariablesModal from 'src/shared/components/modals/ActivityVariablesModal'
const emit = defineEmits(['update:config'])
const modal = inject('$modal')
const props = defineProps({
  settings: {
    type: Object,
    default: () => ({})
  },
  variableContext: {
    type: Object,
    default: undefined
  },
  config: {
    required: true
  },
  component: {
    type: String,
    required: true
  }
})

function unique(list) {
  return Array.from(new Set(list))
}

const parameterKeys = computed(() =>
  props.settings && props.settings.selection
    ? Object.keys(props.settings.selection.parameters)
    : []
)

const instanceParameters = computed(() => {
  if (!props.settings || !props.settings.selection) {
    return []
  }
  return parameterKeys.value.map(key => {
    const { title } = props.settings.selection.parameters[key]
    const options = unique(
      props.settings.videos.map(video => video.parameters[key])
    ).sort((a, b) =>
      a.localeCompare(b, undefined, {
        numeric: true
      })
    )
    return {
      name: key,
      label: title || key,
      options,
      validOptions: getValidOptions(key, options)
    }
  })
})

const paramOptionExists = (paramName, key) => {
  return !!props.config?.parameters?.[paramName]?.[key]
}

const getParam = key => {
  const defaultParameter = {
    type: 'student'
  }
  return Object.prototype.hasOwnProperty.call(props.config, 'parameters')
    ? props.config.parameters[key] ?? defaultParameter
    : defaultParameter
}

const getValidOptions = (key, options) => {
  const videos = props.settings.videos
  // Parameters that have a value.
  const setParams = parameterKeys.value
    .filter(param => paramOptionExists(param, 'type'))
    .filter(
      k =>
        !paramOptionExists(k, 'initial') ||
        getParam(k).initial !== undefined ||
        getParam(k).type === 'student'
    )
    .map(k => ({ ...getParam(k), title: k }))

  return unique(
    // Get the value of the parameter from each video that matches all of the set parameters.
    videos
      .filter(video =>
        setParams.every(
          p =>
            p &&
            (p.title === key ||
              p.type === 'student' ||
              p.type === 'variable' ||
              p.initial === video.parameters[p.title])
        )
      )
      .map(video => video.parameters[key])
  )
}

const changeVariable = async (settingName, currentVariable) => {
  const newVariable = await chooseVariable(currentVariable)
  if (newVariable) {
    setParameterValue(settingName, newVariable)
  }
}

const chooseVariable = async (currentVariable = false) => {
  const { status, data } = await modal.show(ActivityVariablesModal, {
    variableContext: props.variableContext,
    allowInsert: true,
    allowResponseVariable: false,
    insertText: 'Choose Variable',
    ...(currentVariable ? { currentVariable } : {})
  })
  if (status === 'ok') {
    return data.id
  }
  return false
}

function updateParameter(key, fields) {
  const param = {
    ...getParam(key),
    ...fields
  }
  const currentParams = props.config.parameters
  emit('update:config', {
    parameters: {
      ...currentParams,
      [key]: param
    }
  })
}

const setParameterType = async (settingName, type) => {
  if (type === 'variable') {
    const newVariable = await chooseVariable()
    if (newVariable) {
      updateParameter(settingName, {
        type,
        initial: newVariable,
        visible: true,
        selectable: false
      })
    }
    return
  }
  updateParameter(settingName, {
    type,
    initial: undefined,
    visible: true,
    selectable: type === 'student'
  })
}

const setParameterValue = (settingName, initial) =>
  updateParameter(settingName, { initial })

const setParameterVisibility = (settingName, visible) =>
  updateParameter(settingName, { visible })

const variableName = value => {
  if (value.includes('.')) {
    const [colId, variable] = value.split('.')
    const collection = props.variableContext.variables.find(g => g.id === colId)
    const foundVariable = collection.variables.find(v => v.id === variable)
    return `$${collection.name}.${foundVariable.name}`
  }
  const foundVariable = props.variableContext.variables.find(
    variable => variable.id === value
  )

  if (foundVariable) {
    return `$${foundVariable.name}`
  }
  return value
}
</script>

<style lang="scss" scoped>
.instance-settings-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.setting-selection {
  width: 90%;
  margin-left: 0;
  margin-right: 0;
  max-width: 235px;
}

.setting-variable {
  margin-top: 6px;
}

.setting-option {
  margin-top: 6px;
}
</style>
