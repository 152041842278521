import { Mark, mergeAttributes } from '@tiptap/core'
import { VocabTooltipPlugin } from './VocabTooltipPlugin'

const randomId = () => Math.floor(Math.random() * 1000000).toString()

const tipStyle =
  'font-weight: 700; text-decoration: underline; text-decoration-style: dashed; cursor:pointer;'

const VocabTooltip = Mark.create({
  name: 'vocabTooltip',

  addOptions() {
    return {
      HTMLAttributes: {},
      onTipActivated: () => {}
    }
  },
  addStorage() {
    return {
      activeTip: null
    }
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: el => el.getAttribute('data-tip-id'),
        renderHTML: attrs => ({ 'data-tip-id': attrs.id })
      },
      tip: {
        default: '',
        parseHTML: el => JSON.parse(el.getAttribute('data-tip')),
        renderHTML: attrs => ({ 'data-tip': JSON.stringify(attrs.tip) })
      }
    }
  },

  addCommands() {
    return {
      setVocabTooltip:
        attributes =>
        ({ commands }) => {
          return commands.setMark(this.name, {
            ...attributes,
            id: attributes.id ?? randomId()
          })
        },
      openVocabTooltipModal: () => () => {
        this.storage.onEditVocabTooltip?.()
      },
      unsetVocabTooltip:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        }
    }
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'vocab-tooltip-link',
        style: tipStyle
      }),
      0
    ]
  },
  parseHTML() {
    return [
      {
        tag: 'a[data-tip-id]',
        getAttrs: el => !!el.getAttribute('data-tip-id')?.trim() && null
      }
    ]
  },
  addProseMirrorPlugins() {
    return [
      VocabTooltipPlugin({
        pluginKey: this.options.pluginKey,
        editor: this.editor,
        onTipActivated: this.options.onTipActivated
      })
    ]
  }
})

export default VocabTooltip
