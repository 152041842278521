<template>
  <ul class="nav navbar-nav navbar-right pi-menu">
    <nav-link :to="{ name: 'activities', query: { library: 'pivot' } }"
      >Activities</nav-link
    >
    <nav-link v-if="!isSalesAdmin" :to="{ name: 'lti_platforms' }">
      LTI
    </nav-link>
    <nav-dropdown title="Admin">
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_purchases' }"
      >
        Generate Codes
      </nav-dropdown-action>
      <nav-dropdown-action :to="{ name: 'admin_licenses' }">
        Licenses
      </nav-dropdown-action>
      <nav-dropdown-action :to="{ name: 'admin_manage_users' }">
        Users
      </nav-dropdown-action>
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_vet_instructors' }"
      >
        Vet Instructors
      </nav-dropdown-action>
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_manage_invitations' }"
      >
        Invitations
      </nav-dropdown-action>
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_bookstore_purchases' }"
      >
        Bookstore Purchases
      </nav-dropdown-action>
      <nav-dropdown-action
        v-if="!isSalesAdmin"
        :to="{ name: 'admin_search_quotes' }"
      >
        Quotes
      </nav-dropdown-action>
    </nav-dropdown>

    <nav-dropdown :title="fullName || 'Welcome back!'">
      <nav-dropdown-action :to="{ name: 'edit_profile' }">
        Profile
      </nav-dropdown-action>
      <nav-dropdown-action id="logout" :to="{ name: 'logout' }">
        Log out
      </nav-dropdown-action>
    </nav-dropdown>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import NavLink from 'src/shared/components/navigation/NavLink'
import NavDropdown from 'src/shared/components/navigation/NavDropdown'
import NavDropdownAction from 'src/shared/components/navigation/NavDropdownAction'
export default {
  name: 'AdminMenu',
  components: {
    NavLink,
    NavDropdown,
    NavDropdownAction
  },
  computed: {
    ...mapGetters(['fullName', 'isSalesAdmin'])
  }
}
</script>
