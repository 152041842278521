function gaussjordan(a, b) {
  const n = a.length
  const m = b[0].length

  const indxc = new Array(n)
  const indxr = new Array(n)
  const ipiv = new Array(n).fill(0)

  let row = 0
  let col = 0
  let pivinv = 0

  for (let i = 0; i < n; i++) {
    let big = 0

    for (let j = 0; j < n; j++) {
      if (ipiv[j] !== 1) {
        for (let k = 0; k < n; k++) {
          if (ipiv[k] === 0) {
            if (Math.abs(a[j][k]) >= big) {
              big = Math.abs(a[j][k])
              row = j
              col = k
            }
          }
        }
      }
    }

    ++ipiv[col]

    if (row !== col) {
      for (let l = 0; l < n; l++) {
        const rowl = a[row][l]
        a[row][l] = a[col][l]
        a[col][l] = rowl
      }
      for (let l = 0; l < m; l++) {
        const rowl = b[row][l]
        b[row][l] = b[col][l]
        b[col][l] = rowl
      }
    }

    indxr[i] = row
    indxc[i] = col

    if (a[col][col] === 0) return

    pivinv = 1 / a[col][col]
    a[col][col] = 1

    for (let l = 0; l < n; l++) {
      a[col][l] *= pivinv
    }

    for (let l = 0; l < m; l++) {
      b[col][l] *= pivinv
    }

    for (let ll = 0; ll < n; ll++) {
      let dum = 0

      if (ll !== col) {
        dum = a[ll][col]
        a[ll][col] = 0

        for (let l = 0; l < n; l++) {
          a[ll][l] -= a[col][l] * dum
        }

        for (let l = 0; l < m; l++) {
          b[ll][l] -= b[col][l] * dum
        }
      }
    }
  }

  for (let l = n - 1; l >= 0; l--) {
    if (indxr[l] !== indxc[l]) {
      for (let k = 0; k < n; k++) {
        const kl = a[k][indxr[l]]
        a[k][indxr[l]] = a[k][indxc[l]]
        a[k][indxc[l]] = kl
      }
    }
  }
}

function pcorr(x, y) {
  let sumX = 0
  let sumY = 0
  let sumXY = 0
  let sumX2 = 0
  let sumY2 = 0
  const minLength = (x.length = y.length = Math.min(x.length, y.length))
  const reduce = (xi, idx) => {
    const yi = y[idx]
    sumX += xi
    sumY += yi
    sumXY += xi * yi
    sumX2 += xi * xi
    sumY2 += yi * yi
  }
  x.forEach(reduce)
  return (
    (minLength * sumXY - sumX * sumY) /
    Math.sqrt(
      (minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY)
    )
  )
}

function fit(x, y, funcs) {
  // maximum number of `a` values to solve for
  const as = funcs(x[0]).length
  const covariance = new Array(as).fill(0).map(() => new Array(as).fill(0))
  let coefficients = new Array(as).fill(0).map(() => new Array(1).fill(0))

  for (let i = 0; i < x.length; i++) {
    const afunc = funcs(x[i])

    for (let j = 0, k = 0; k < as; k++) {
      for (let l = 0, m = 0; m <= k; m++) {
        covariance[j][l++] += afunc[k] * afunc[m]
      }

      coefficients[j++][0] += y[i] * afunc[k]
    }
  }

  for (let i = 1; i < as; i++) {
    for (let j = 0; j < i; j++) {
      covariance[j][i] = covariance[i][j]
    }
  }

  gaussjordan(covariance, coefficients)

  // convert matrix into single array
  coefficients = coefficients.map(v => v[0])

  // calculate chi squared
  let chisq = 0
  for (let i = 0; i < x.length; i++) {
    const afunc = funcs(x[i])
    let sum = 0

    for (let j = 0; j < as; j++) {
      sum += coefficients[j] * afunc[j]
    }

    chisq += Math.pow((y[i] - sum) / 1, 2)
  }

  // calculate rmse
  const rmse = Math.sqrt(chisq / (x.length - as))

  // calculate Pearson's r (Pearson's Correlation Coefficient)
  const correlationCoefficient = pcorr(x, y)

  return {
    correlationCoefficient,
    rmse,
    coefficients,
    uncertainties: covariance.map((v, i) => Math.sqrt(v[i]) * rmse)
  }
}

function format(number) {
  // number = Math.round(number * 1e10) / 1e10

  // return number.toPrecision(4)
  return number.toPrecision(3)
}

function ifIncludeUncertainties(str, includeUncertainties) {
  return includeUncertainties ? str : ''
}

export default {
  proportional: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse, correlationCoefficient } = fit(
      x,
      y,
      x => [x]
    )
    const predict = x => [x, coefficients[0] * x]

    return {
      coefficients,
      uncertainties,
      rmse,
      correlationCoefficient,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = A{xvar}\\\\
        A: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )} \\,{yunits \\over xunits}\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
        r: ${format(correlationCoefficient)}
      `,
      description: [
        'y = A x',
        `A = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`,
        `Correlation Coefficient = ${format(correlationCoefficient)}`
      ]
    }
  },
  linear: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse, correlationCoefficient } = fit(
      x,
      y,
      x => [x, 1]
    )
    const predict = x => [x, coefficients[0] * x + coefficients[1]]

    return {
      coefficients,
      uncertainties,
      rmse,
      correlationCoefficient,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = A{xvar} + B\\\\
        A: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )}\\,{yunits \\over xunits}\\\\
        B: ${format(coefficients[1])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[1])}`,
        includeUncertainties
      )}\\,yunits\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
        r: ${format(correlationCoefficient)}
      `,
      description: [
        'y = A x + B',
        `A = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `B = ${format(coefficients[1])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[1])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`,
        `Correlation Coefficient = ${format(correlationCoefficient)}`
      ]
    }
  },
  square: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse } = fit(x, y, x => [x * x])
    const predict = x => [x, coefficients[0] * Math.pow(x, 2)]

    return {
      coefficients,
      uncertainties,
      rmse,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = A{xvar}^2\\\\
        A: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )}\\,{yunits \\over \\left(xunits\\right)^2}\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
      `,
      description: [
        'y = A x ^ 2',
        `A = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`
      ]
    }
  },
  squareroot: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse } = fit(x, y, x => [Math.sqrt(x)])
    const predict = x => [x, coefficients[0] * Math.pow(x, 0.5)]

    return {
      coefficients,
      uncertainties,
      rmse,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = A\\sqrt{xvar}\\\\
        A: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )}\\,{yunits \\over \\sqrt xunits}\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
      `,
      description: [
        'y = A square root x',
        `A = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`
      ]
    }
  },
  quadratic: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse } = fit(x, y, x => [x * x, x, 1])
    const predict = x => [
      x,
      coefficients[0] * Math.pow(x, 2) + coefficients[1] * x + coefficients[2]
    ]

    return {
      coefficients,
      uncertainties,
      rmse,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = A{xvar}^2 + B{xvar} + C\\\\
        A: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )}\\,{yunits \\over \\left(xunits\\right)^2}\\\\
        B: ${format(coefficients[1])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[1])}`,
        includeUncertainties
      )}\\,{yunits \\over xunits}\\\\
        C: ${format(coefficients[2])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[2])}`,
        includeUncertainties
      )}\\,yunits\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
      `,
      description: [
        'y = A x ^ 2 + B x + C',
        `A = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `B = ${format(coefficients[1])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[1])}`,
          includeUncertainties
        )}`,
        `C = ${format(coefficients[2])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[2])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`
      ]
    }
  },
  exponential: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse, correlationCoefficient } = fit(
      x,
      y.map(y => Math.log(y)),
      x => [x, 1]
    )
    const predict = x => [
      x,
      Math.exp(coefficients[1]) * Math.exp(x * coefficients[0])
    ]

    return {
      coefficients,
      uncertainties,
      rmse,
      correlationCoefficient,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = Ae^{B{xvar}}\\\\
        A: ${format(Math.exp(coefficients[1]))} ${ifIncludeUncertainties(
        `\\pm ${format(Math.exp(uncertainties[1]))}`,
        includeUncertainties
      )}\\,yunits\\\\
        B: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )}\\,{1 \\over xunits}\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
        r: ${format(correlationCoefficient)}
      `,
      description: [
        'y = A e^{Bx}',
        `A = ${format(Math.exp(coefficients[1]))} ${ifIncludeUncertainties(
          `plus or minus ${format(Math.exp(uncertainties[1]))}`,
          includeUncertainties
        )}`,
        `B = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`,
        `Correlation Coefficient = ${format(correlationCoefficient)}`
      ]
    }
  },
  logarithmic: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse } = fit(x, y, x => [
      1,
      1 * Math.log(x)
    ])
    const predict = x => [x, coefficients[0] + coefficients[1] * Math.log(x)]

    return {
      coefficients,
      uncertainties,
      rmse,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = A + Bln({xvar})\\\\
        A: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )}\\\\
        B: ${format(coefficients[1])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[1])}`,
        includeUncertainties
      )}\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
      `,
      description: [
        'y = A + B ln(x)',
        `A = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `B = ${format(coefficients[1])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[1])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`
      ]
    }
  },
  inverse: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse } = fit(x, y, x => [1 / x])
    const predict = x => [x, coefficients[0] / x]

    return {
      coefficients,
      uncertainties,
      rmse,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = A / {xvar}\\\\
        A: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )}\\,yunits \\cdot xunits\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
      `,
      description: [
        `y = A / x`,
        `A = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`
      ]
    }
  },
  inverseSquare: (x, y, includeUncertainties) => {
    const { coefficients, uncertainties, rmse } = fit(x, y, x => [
      1 / Math.pow(x, 2)
    ])
    const predict = x => [x, coefficients[0] / Math.pow(x, 2)]

    return {
      coefficients,
      uncertainties,
      rmse,
      points: x.map(predict),
      predict,
      formula: `
        {yvar} = A / {xvar}^2\\\\
        A: ${format(coefficients[0])} ${ifIncludeUncertainties(
        `\\pm ${format(uncertainties[0])}`,
        includeUncertainties
      )}\\,yunits \\cdot xunits^2\\\\
        RMSE: ${format(rmse)} \\,yunits\\\\
      `,
      description: [
        'y = A / x ^ 2',
        `A = ${format(coefficients[0])} ${ifIncludeUncertainties(
          `plus or minus ${format(uncertainties[0])}`,
          includeUncertainties
        )}`,
        `RMSE = ${format(rmse)}`
      ]
    }
  }
}
