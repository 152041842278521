<template>
  <div>
    <template
      v-if="
        (!viewAsStudent && hasStudentResponseVariables) ||
        (!viewAsStudent && hasRandomVariables)
      "
    >
      <base-popover
        placement="top"
        :anchor-ref="popoverAnchor"
        :visible="popoverVisible"
      >
        {{ variablePopText }}
      </base-popover>
      <icon
        class="mr-2"
        :class="
          hasRandomVariables ? 'text-pivot-purple-600' : 'text-pivot-yellow-700'
        "
        :icon="hasRandomVariables ? 'shuffle' : 'circle-info'"
        ref="popoverAnchor"
        @mouseover="openPopover"
        @mouseout="closePopover"
      />
    </template>
    <LatexBlock class="drop-choice-content" :latex="latexForDisplay" />
  </div>
</template>

<script setup lang="ts">
import {
  getLatexForDisplay,
  getVariablesFromLatex
} from '../../../shared/utils/latex-methods'
import { ref, computed } from 'vue'

interface Props {
  text: string
  variableContext: any
  viewAsStudent?: boolean
}
const props = defineProps<Props>()

const latexForDisplay = computed(() =>
  getLatexForDisplay({
    variables: props.variableContext.variables,
    formula: props.text
  })
)

const popoverVisible = ref(false)
const popoverAnchor = ref<HTMLElement>()
const openPopover = () => {
  popoverVisible.value = true
}
const closePopover = () => {
  popoverVisible.value = false
}
const usedVariables = computed(() => {
  if (props.type === 'rich-text') return []
  return getVariablesFromLatex({
    formula: props.text,
    variables: props.variableContext?.variables
  })
})
const studentResponseVariables = computed(() =>
  usedVariables.value.filter((v: any) => v.variableType === 'studentResponse')
)
const hasStudentResponseVariables = computed(
  () => studentResponseVariables.value.length > 0
)
const hasRandomVariables = computed(
  () => usedVariables.value.length > 0 && !hasStudentResponseVariables.value
)
const variablePopText = computed(() => {
  if (hasStudentResponseVariables.value) {
    const dependentQuestions = studentResponseVariables.value
      .map((v: any) => `S${v.section}.Q${v.question}`)
      .join(', ')
    return `This value is derived from responses to the following questions: ${dependentQuestions}`
  } else if (hasRandomVariables.value) {
    return 'This value is random for each student.'
  }
  return ''
})
</script>

<style lang="scss" scoped>
.drop-choice-content {
  user-select: none;
}
</style>
