<template>
  <th scope="col" ref="th" :aria-readonly="readonly">
    <div class="column-header__wrapper">
      <template v-if="!allowText">
        <data-grid-input-field
          class="data-grid-header__units"
          :value="units.formula ?? units"
          :type="units.parser ?? 'text'"
          :placeholder="defaultColumnUnits"
          :readonly="readonly"
          :aria-label="`column ${columnIndex + 1} units`"
          @focusin="() => setFocus('units')"
          @input="
            value =>
              $gridActions.setValueAtColumnAndRow(columnIndex, 'units', value)
          "
        />
        <data-grid-input-field
          class="data-grid-header__variable"
          :value="variable.formula ?? variable"
          :type="variable.parser ?? 'text'"
          :placeholder="defaultColumnVariable"
          :readonly="readonly"
          :aria-label="`column ${columnIndex + 1} variable`"
          @focusin="() => setFocus('variable')"
          @input="
            value =>
              $gridActions.setValueAtColumnAndRow(
                columnIndex,
                'variable',
                value
              )
          "
        />
      </template>
    </div>
    <span class="resizer" @pointerdown="onMouseDown" />
  </th>
</template>

<script>
import * as Y from 'yjs'
import DataGridInputField from 'src/shared/components/grid-graph/DataGridInputField'
import {
  defaultColumnUnits,
  defaultColumnVariable
} from 'src/shared/components/grid-graph/utilities'

export default {
  name: 'DataGridColumnHeader',
  components: {
    DataGridInputField
  },
  emits: ['focus:cell'],
  inject: ['$gridActions'],
  props: {
    column: {
      type: Y.Map,
      required: true
    },
    columnIndex: Number,
    readonly: {
      type: Boolean,
      default: false
    },
    focusedCell: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      units: this.column.get('units'),
      allowText: this.column.get('allowText'),
      variable: this.column.get('variable'),
      defaultColumnUnits,
      defaultColumnVariable,
      moving: false,
      x: 0,
      w: 0
    }
  },
  methods: {
    onMouseDown(e) {
      this.moving = true
      this.x = e.clientX
      const styles = window.getComputedStyle(this.$refs.th)
      this.w = parseInt(styles.width, 10)
      document.addEventListener('pointermove', this.handleResize)
      document.addEventListener('pointerup', this.onMouseUp)
    },
    onMouseUp() {
      if (!this.moving) return
      this.moving = false
      document.removeEventListener('pointermove', this.handleResize)
      document.removeEventListener('pointerup', this.onMouseUp)
    },
    handleResize(e) {
      const dx = e.clientX - this.x
      // Update the width of column
      let w = this.w + dx
      if (w < 60) w = 60
      this.$gridActions.setColumnWidth(this.columnIndex, w)
    },
    setFocus(row) {
      if (row) {
        this.$emit('focus:cell', {
          column: this.columnIndex,
          row,
          value: {
            value: this[row].formula ? this[row].formula : this[row],
            dataType: this[row].parser ? this[row].parser : 'text'
          }
        })
      } else {
        this.$emit('focus:cell', { column: null, row: null, value: null })
      }
    }
  },
  watch: {
    column: {
      handler() {
        this.unsubscribe?.()
        const column = this.column
        const onChange = () => {
          this.units = column.get('units')
          this.allowText = column.get('allowText')
          this.variable = column.get('variable')
        }
        onChange()
        column.observe(onChange)
        this.unsubscribe = () => {
          column.unobserve(onChange)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
th {
  min-width: 60px;
}
.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  user-select: none;
}

.column-header__wrapper {
  display: flex;
  height: 100%;
}

.data-grid-header__units {
  border-right: 1px solid #979797;
}

.data-grid-header__units {
  width: 50%;
  flex: 1 1 50%;
}
.data-grid-header__variable {
  width: 50%;
  flex: 1 1 50%;
  font-style: italic;
}
</style>
