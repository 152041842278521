<template>
  <div v-bind="wrapperAttrs" class="search-input">
    <text-input
      v-bind="inputAttrs"
      ref="input"
      input-class="search-input__input"
    />
    <icon icon="search" class="search-input__icon" />
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  inheritAttrs: false,
  computed: {
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    inputAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    setFocus() {
      this.$refs.input.setFocus()
    }
  }
}
</script>

<style lang="scss">
.search-input {
  position: relative;

  &__input {
    padding-left: 32px;
  }

  &__icon {
    position: absolute;
    left: 11px;
    top: 11px;
  }
}
</style>
