<template>
  <slot v-bind="context" :variable-context="context" />
</template>

<script setup>
import { toRef, computed, reactive } from 'vue'

const emit = defineEmits(['update:variables'])
const props = defineProps({
  variables: {
    type: Array,
    required: true,
    default: () => []
  },
  activity: Object,
  values: {
    type: Array,
    default: () => []
  }
})

const variables = computed(() => {
  const formattedVariables = []
  props.variables.forEach(v => {
    const matched = props.values.find(val => val.id === v.id)

    const dependency =
      v.variableType === 'studentResponse'
        ? findSectionQuestion(v.content)
        : undefined

    if (matched) {
      if (typeof matched.value === 'object') {
        formattedVariables.push({
          ...v,
          value: matched.value,
          section: dependency?.sectionNumber,
          question: dependency?.questionNumber,
          variables: v.variables.map(v2 => ({
            ...v2,
            value: matched.value[v2.id]
          }))
        })
      } else {
        formattedVariables.push({
          ...v,
          section: dependency?.sectionNumber,
          question: dependency?.questionNumber,
          value: matched.value
        })
      }
    } else {
      formattedVariables.push({
        ...v,
        section: dependency?.sectionNumber,
        question: dependency?.questionNumber
      })
    }
  })
  return formattedVariables
})

function findSectionQuestion(componentValue) {
  if (!props.activity?.sections) return

  for (const section of props.activity.sections) {
    for (const component of section.components) {
      if (component._id === componentValue) {
        return {
          ...component,
          sectionNumber: section.sectionNumber
        }
      }
      if (component.componentType === 'SplitView') {
        for (const child of component.leftContent) {
          if (child._id === componentValue) {
            return {
              ...child,
              sectionNumber: section.sectionNumber
            }
          }
        }
        for (const child of component.rightContent) {
          if (child._id === componentValue) {
            return {
              ...child,
              sectionNumber: section.sectionNumber
            }
          }
        }
      }
    }
  }
}

function update(variables) {
  emit('update:variables', variables)
}

const context = reactive({
  variables,
  update,
  activity: toRef(props, 'activity')
})

defineExpose({ context })
</script>
