<template>
  <div class="browse-summary">
    <template v-if="!isLoading">
      Showing {{ licensePageSize }} of {{ licenseCount }} results
      <template v-if="search">for "{{ search }}"</template>
      <template v-if="filters.length > 0">
        filtered by {{ filters.join(', ') }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BrowseSummary',
  inject: ['browseLicenses'],
  computed: {
    licensePageSize() {
      return this.browseLicenses.licenses.length
    },
    licenseCount() {
      return this.browseLicenses.licenseCount
    },
    search() {
      return this.browseLicenses.query.q
    },
    isLoading() {
      return this.browseLicenses.isLoadingLicenses
    },
    filters() {
      const filters = [
        ...this.browseLicenses.query.types,
        ...this.browseLicenses.query.status
      ]
      if (this.browseLicenses.query.level !== 'all') {
        filters.push(this.browseLicenses.query.level)
      }
      return filters
    }
  }
}
</script>

<style lang="scss" scoped>
.browse-summary {
  margin: 8px 0 0 0;
  flex-shrink: 1;
  grid-area: summary;
}
</style>
