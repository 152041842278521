<template>
  <modal @submit="onSubmit">
    <modal-header>Drop Target</modal-header>
    <modal-body>
      <form-group>
        <form-label for="invalid-answer">Invalid Answer</form-label>
        <multiline-text-input
          id="invalid-answer"
          v-model="mutableData.invalidResponse"
        />
      </form-group>
      <div class="drop-target-menu__form-columns">
        <div class="drop-target-menu__form-column">
          <checkbox
            aria-label="Fill In the Blank"
            id="fillInTheBlank"
            v-model="mutableData.fillInTheBlank"
            @click.stop
            >Fill in the blank</checkbox
          >
        </div>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Save</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import { reactive } from 'vue'

interface Target {
  invalidResponse?: string
  targetType?: string
}

const props = defineProps<{ target: Target }>()

const mutableData = reactive({
  invalidResponse: props.target.invalidResponse,
  fillInTheBlank: props.target.targetType === 'dropdown'
})

function onSubmit(e: { done: (target: Target) => void }) {
  e.done({
    ...mutableData,
    targetType: mutableData.fillInTheBlank ? 'dropdown' : 'drag-drop'
  })
}
</script>
