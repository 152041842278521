import { GetActivityResponse, Component, ActivitySection } from '@pi/api-types'
import { ActivityComponentTypeValue } from '@pi/types'

export const getFlattenedComponents = (
  activity: GetActivityResponse
): Component[] => {
  return activity.sections.flatMap(section =>
    getFlattenedComponentsForSection(section)
  )
}

export const getFlattenedComponentsForSection = (
  section: ActivitySection
): Component[] => {
  return section.components.flatMap(component => {
    if (component.componentType === ActivityComponentTypeValue.SplitView) {
      return [
        ...(component.leftContent ?? []),
        ...(component.rightContent ?? [])
      ]
    }
    return component
  })
}
