<template>
  <li>
    <sidebar-collapse :key="`typeCollapse`">
      <template #header> License Type </template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('institution')"
              @update:modelValue="value => changeType('institution', value)"
              @click.stop
            >
              Institution
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('student')"
              @update:modelValue="value => changeType('student', value)"
              @click.stop
            >
              Student
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('trial')"
              @update:modelValue="value => changeType('trial', value)"
              @click.stop
            >
              Trial
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'TypeFilter',
  inject: ['browseUsers'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  computed: {
    types() {
      return this.browseUsers.query.licenseType
    }
  },
  methods: {
    isChecked(t) {
      return this.types.includes(t)
    },
    changeType(category, isChecked) {
      let types = [...this.types]
      if (!isChecked) {
        types = types.filter(t => t !== category)
      } else {
        types.push(category)
      }
      if (types.length) {
        return this.browseUsers.updateQuery({
          page: 1,
          licenseType: [...types]
        })
      }
      return this.browseUsers.updateQuery({
        page: 1,
        licenseType: undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
