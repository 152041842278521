<template>
  <th>
    <slot name="title">{{ title }}</slot>
    <!--
      @slot The content for each cell in this column.
      Recieves the `item` as a slot prop.
    -->
    <slot v-if="false" />
    <slot v-if="false" name="summary" />
  </th>
</template>

<script>
export default {
  name: 'ListColumn',
  props: {
    /**
     * The title of the column.
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * The property on the row item to show in the cell.
     * Ignored if the default slot is used.
     */
    property: {
      type: String,
      default: null
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
th {
  border-bottom: none !important;
  box-shadow: inset 0 -2px 0 0 #ddd;
}
</style>
