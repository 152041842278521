<template>
  <div v-bind="wrapperAttrs">
    <p
      v-bind="valueAttrs"
      class="form-control-static static-value__content"
      aria-describedby="helpId"
    >
      <slot />
    </p>
    <span v-if="helpText" :id="helpId" class="help-block">
      {{ helpText }}
    </span>
  </div>
</template>

<script>
let idCounter = 0

export default {
  name: 'StaticValue',
  inheritAttrs: false,
  data() {
    return {
      helpId: `static-help-${idCounter++}`
    }
  },
  props: {
    helpText: { type: String, default: null }
  },
  computed: {
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    valueAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  }
}
</script>

<style lang="scss">
.static-value__content {
  padding: 8px 0;
  height: 36px;
  font-size: 14px;
  line-height: 1.6;
}
</style>
