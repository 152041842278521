import { isRef, watch, ref, readonly, onBeforeUnmount } from 'vue'
import throttle from 'lodash/throttle'

export default function useElementSize(el) {
  const size = ref({ width: el.clientWidth, height: el.clientHeight })
  const observer = new ResizeObserver(
    throttle(([entry]) => {
      size.value = {
        width: entry.contentRect.width,
        height: entry.contentRect.height
      }
    }, 200)
  )

  if (isRef(el)) {
    watch(
      el,
      el => {
        observer.disconnect()
        if (el) {
          observer.observe(el)
        }
      },
      { immediate: true }
    )
  } else {
    if (el) {
      observer.observe(el)
    }
  }

  onBeforeUnmount(() => {
    observer.disconnect()
  })

  return readonly(size)
}
