import * as types from 'src/setup/store/mutation-types'
import client from '../../../shared/api-client'

const state = {
  version: PI_VERSION,
  shouldUpdate: false,
  canUpdate: true,
  isChecking: false
}

const getters = {
  shouldCheckVersion({ shouldUpdate, isChecking }) {
    return () => !isChecking || !shouldUpdate
  },
  piVersion: state => state.version
}

const mutations = {
  [types.SET_SHOULD_UPDATE](state, shouldUpdate) {
    state.shouldUpdate = !!shouldUpdate
  },
  [types.SET_IS_CHECKING](state, isChecking) {
    state.isChecking = !!isChecking
  },
  [types.SET_CAN_UPDATE](state, canUpdate) {
    state.canUpdate = !!canUpdate
  }
}

const actions = {
  async checkVersion({ state, commit, getters }) {
    if (!document.hidden && getters.shouldCheckVersion) {
      commit(types.SET_IS_CHECKING, true)
      try {
        const version = await client.getVersion()
        if (state.version !== version) {
          commit(types.SET_SHOULD_UPDATE, true)
        }
      } finally {
        commit(types.SET_IS_CHECKING, false)
      }
    }
  },
  setCanUpdate({ commit }, canUpdate) {
    commit(types.SET_CAN_UPDATE, canUpdate)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
