<template>
  <form class="license-search" @submit.prevent="search">
    <search-input
      v-model="text"
      aria-label="Search Licenses"
      class="license-search__input"
      placeholder="Search license name or Salesforce Id"
    />
    <form-button type="submit">Search</form-button>
  </form>
</template>

<script>
export default {
  name: 'LicenseSearch',
  inject: ['browseLicenses'],
  data() {
    return {
      text: ''
    }
  },
  computed: {
    value() {
      return this.browseLicenses.query.q
    }
  },
  methods: {
    search() {
      if (this.value !== this.text) {
        this.browseLicenses.updateQuery({
          page: '1',
          q: this.text || undefined
        })
      }
    }
  },
  watch: {
    value: {
      handler() {
        this.text = this.value
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.license-search {
  position: relative;
  display: flex;
  align-items: center;
  grid-area: search;

  &__icon {
    position: absolute;
    left: 12px;
    top: 11px;
  }

  &__input {
    flex-grow: 1;
    margin: 0 16px 0 0;
  }
}
</style>
