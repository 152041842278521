import CreateLicenseView from 'src/modules/licenses/views/CreateLicenseView'
import LicensesView from 'src/modules/licenses/views/LicensesView'
import ManageLicenseDashboardView from 'src/modules/licenses/views/ManageLicenseDashboardView'
export default store => [
  {
    name: 'admin_licenses',
    path: '/admin/licenses',
    component: LicensesView,
    props: to => ({ query: to.query }),
    meta: { title: 'Licenses', requiresAuth: true }
  },
  {
    name: 'admin_create_license',
    path: '/admin/licenses/create',
    component: CreateLicenseView,
    meta: { title: 'Create License', requiresAuth: true },
    props: { isCreate: true }
  },
  {
    name: 'admin_update_license',
    path: '/admin/licenses/:id/update',
    component: CreateLicenseView,
    props: true,
    meta: { title: 'Update License', requiresAuth: true }
  },
  {
    name: 'admin_manage_license',
    path: '/admin/licenses/:id/:tab?',
    component: ManageLicenseDashboardView,
    meta: to => ({
      title:
        !to.params.tab || to.params.tab === 'manage'
          ? 'Manage License'
          : `License ${
              to.params.tab.charAt(0).toUpperCase() + to.params.tab.slice(1)
            }`,
      requiresAuth: true
    }),
    props: route => ({
      id: route.params.id,
      tab: route.params.tab || 'manage'
    })
  },
  {
    name: 'license',
    path: '/license/:tab?',
    component: ManageLicenseDashboardView,
    meta: to => ({
      title:
        !to.params.tab || to.params.tab === 'manage'
          ? 'Manage License'
          : `License ${
              to.params.tab.charAt(0).toUpperCase() + to.params.tab.slice(1)
            }`,
      requiresAuth: true
    }),
    props: route => ({
      tab: route.params.tab || 'manage'
    })
  }
]
