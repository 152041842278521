<template>
  <span class="sticky-header-sub-title">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'StickyHeaderSubTitle',
  computed: {}
}
</script>

<style lang="scss" scoped>
.sticky-header-sub-title {
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: baseline;
  margin-bottom: -1px;
  .sticky-header--stuck & {
    font-size: 14px;
  }

  p {
    display: inline;
    margin-right: 10px;
  }
}
</style>
