<template>
  <section class="help-banner" v-if="!printing">
    <h4>
      Need help? Here are
      <a
        href="https://help.pivotinteractives.com/en/articles/3439783-purchasing-access-to-pivot-interactives"
        >detailed instructions on purchasing access to Pivot Interactives.
      </a>
    </h4>
    <p>
      <a href="#" @click.prevent="openChat">Chat</a> or email us at
      <a href="mailto:info@pivotinteractives.com" target="_blank"
        >info@pivotinteractives.com</a
      >
      if you need further assistance.
    </p>
  </section>
</template>

<script>
export default {
  name: 'HelpBanner',
  props: {
    printing: { default: false },
    licenseType: { required: true }
  },
  methods: {
    openChat() {
      if (window.Intercom) {
        window.Intercom('show')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.help-banner {
  margin: 0.8rem 0;
  padding: 2rem 6.4rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  @media print {
    display: none;
  }
}
</style>
