<template>
  <li>
    <hyper-link :to="to" class="dropdown-link__anchor">
      <!-- @slot The content of the dropdown item. -->
      <slot />
    </hyper-link>
  </li>
</template>

<script>
export default {
  name: 'DropdownLink',
  props: {
    /** Vue router object to link to. Passed directly to a `router-link`. */
    to: {
      type: [String, Object],
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-link__anchor {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 22.4px;
  color: #333333;
  white-space: nowrap; // prevent links from randomly breaking onto new lines

  &:hover,
  &:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
  }
  &:focus {
    background-color: #f5f5f5;
    text-decoration: underline;
  }
}
</style>
