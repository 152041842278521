<template>
  <modal @submit="submit">
    <modal-header>Report A Problem</modal-header>
    <modal-body>
      <p>
        We're sorry you've encountered a problem with grading or editing a
        question. Please provide us with some details about the problem you're
        experiencing.
      </p>
      <form-group>
        <form-label for="problemDescription">
          Description of problem
        </form-label>
        <multiline-text-input
          v-model="finalProblemDescription"
          label="problem description"
          rules="required"
        />
      </form-group>
      <p>
        Clicking <b>Submit</b> will forward this description along with some
        metadata to identify the activity and question you're working on in the
        chat.
      </p>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Submit</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

export default {
  name: 'ReportProblemModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  data() {
    return {
      finalProblemDescription: ''
    }
  },
  methods: {
    submit(e) {
      e.done(this.finalProblemDescription)
    }
  }
}
</script>

<style scoped></style>
