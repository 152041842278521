import { GetLtiSessionResponse, LtiConfigRequest } from '@pi/api-types'
import { LtiMessageTypeValue } from '@pi/types'
import { postRedirect } from 'src/setup/mixins/form-post'
import client from 'src/shared/api-client'

export interface UIStepItem {
  key: string
  name: string
  href?: string | object
}

export async function cancelLTIFlow(
  session: GetLtiSessionResponse | undefined
) {
  if (
    session &&
    session.context.messageType === LtiMessageTypeValue.DeepLinkingRequest
  ) {
    const response = await client.lti.cancelConnection()
    const { redirectUrl, openedInNewTab, token } = response

    if (openedInNewTab) {
      window.opener.postMessage(
        JSON.stringify({
          type: 'pivot:deep-link-response',
          redirectUrl,
          token
        }),
        `${window.location.protocol}//${window.PI_API_HOST}`
      )
      window.close()
    } else {
      postRedirect(redirectUrl, { JWT: token })
    }
  } else {
    window.close()
  }
}

export function getLtiSteps(
  ltiSession: GetLtiSessionResponse,
  additional?: {
    noClasses?: boolean
    noAssignment?: boolean
    noClassConnected?: boolean
  }
): UIStepItem[] {
  let savedSteps = sessionStorage.getItem('lti-steps')
  if (savedSteps) {
    savedSteps = JSON.parse(savedSteps)
  }

  const startOnSignin = ltiSession.state === 'CONNECT_ACCOUNT'
  const includeSignin =
    ltiSession.state === 'CONNECT_ACCOUNT' || savedSteps?.includes('signin')
  const includeAddClass =
    additional?.noClasses || savedSteps?.includes('addClass')
  const includeClassConnected =
    additional?.noClassConnected || savedSteps?.includes('connectClass')
  const includeAddAssignment =
    ltiSession.state === 'CONNECT_CLASS' ||
    additional?.noAssignment ||
    savedSteps?.includes('addAssignment')

  const inDeeplinkFlow =
    ltiSession.context.messageType === 'LtiDeepLinkingRequest'
  const isTeacher = ltiSession.context.role === 'teacher'

  const newSteps = [
    ...(includeSignin
      ? [
          {
            name: 'Sign In',
            key: 'signin'
          }
        ]
      : []),
    ...(isTeacher && (includeAddClass || startOnSignin)
      ? [
          {
            name: 'Add Pivot Class',
            key: 'addClass'
          }
        ]
      : []),
    ...(isTeacher && (includeClassConnected || startOnSignin)
      ? [
          {
            name: 'Connect Course to Pivot Class',
            key: 'connectClass'
          }
        ]
      : []),
    ...(isTeacher && inDeeplinkFlow && (includeAddAssignment || startOnSignin)
      ? [
          {
            name: 'Add Assignment to Pivot Class',
            key: 'addAssignment'
          }
        ]
      : []),
    ...(isTeacher && inDeeplinkFlow
      ? [
          {
            name: 'Connect Pivot Assignment',
            key: 'connectAssignment'
          }
        ]
      : []),
    ...(!inDeeplinkFlow
      ? [
          {
            name: 'Launch Assignment',
            key: 'launchAssignment'
          }
        ]
      : [])
  ]

  localStorage.setItem(
    'lti-steps',
    JSON.stringify(newSteps.map(step => step.key))
  )

  return newSteps
}
