<template>
  <div>
    <form-group class="groups-form">
      <div class="group-setup-label">
        <form-label for="numGroups">Co-Lab Groups</form-label>
      </div>
      <div class="break"></div>
      <div class="group-setup-row">
        <div class="group-setup-subgroup">
          <number-input
            id="numGroups"
            v-model="numGroups"
            :rules="`between:1,${maxGroups}`"
            :disabled="disabled"
            label="Number of Groups"
          />
        </div>
        <div v-if="!groupsDefined" class="group-setup-subgroup">
          <select-field
            id="select-type"
            v-model="groupType"
            aria-label="group type"
          >
            <option
              v-for="(option, index) in groupTypes"
              :key="index"
              :value="option"
            >
              {{ capitalize(option) }}
            </option>
          </select-field>
        </div>
        <div v-if="!disabled" class="group-setup-subgroup">
          <form-button v-if="!groupsDefined" secondary @click="createGroups">
            Create
          </form-button>
          <form-button v-if="groupsDefined" secondary @click="updateGroups">
            Update
          </form-button>
        </div>
        <div v-if="groupsDefined && !disabled" class="group-setup-subgroup">
          <form-button tertiary @click="clearGroups">
            Clear Groups
          </form-button>
        </div>
      </div>
    </form-group>
    <div v-if="groupsDefined">
      <collapse-provider collapsed>
        <div class="assigned-text">
          Students Assigned: {{ assignedUsers.length }} / {{ roster.length }}
          <collapse-toggle
            v-if="unassignedUsers.length"
            v-slot="{ collapsed }"
            link
          >
            {{ collapsed ? 'Show' : 'Hide' }} Unassigned
          </collapse-toggle>
        </div>
        <collapse-content v-if="unassignedUsers.length">
          <div
            class="unassigned-container"
            role="group"
            aria-label="unassigned"
          >
            <draggable
              v-if="!disabled"
              :modelValue="unassignedUsers"
              group="groups"
              item-key="_id"
            >
              <template #item="{ element, index }">
                <chip
                  :index="index"
                  :label="element.name"
                  :truncate="false"
                  :removeable="false"
                />
              </template>
            </draggable>
            <div v-if="disabled">
              <chip
                v-for="(student, index) in unassignedUsers"
                :key="index"
                :index="index"
                :label="student.name"
                :truncate="false"
                :removeable="false"
              />
            </div>
          </div>
        </collapse-content>
      </collapse-provider>
      <div class="groups-container">
        <activity-group
          v-for="(group, index) in groups"
          :key="index"
          :group="group"
          :disabled="disabled"
          :roster="unassignedUsers"
          @update="group => updateGroup(group, index)"
        />
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import Draggable from 'vuedraggable'
import ActivityGroup from './ActivityGroup'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'

export default {
  name: 'ActivityGroups',
  components: { Draggable, ActivityGroup },
  inject: ['$modal'],
  emits: ['change'],
  props: {
    roster: {
      type: Array,
      required: true
    },
    groups: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.numGroups = this.groups.length || 2
  },
  computed: {
    maxGroups() {
      // adding computed value so we can change this logic later.
      return this.roster.length
    },
    groupsDefined() {
      return this.groups && this.groups.length
    },
    assignedUsers() {
      return this.roster.filter(s => {
        // find if the user is in one of the groups
        const filtered = this.groups.filter(g =>
          g.students ? g.students.find(a => a._id === s._id) : false
        )
        return filtered.length !== 0
      })
    },
    unassignedUsers() {
      return this.roster.filter(s => {
        // find if the user is in one of the groups
        const filtered = this.groups.filter(g =>
          g.students ? g.students.find(a => a._id === s._id) : false
        )
        return filtered.length === 0
      })
    }
  },
  data() {
    return {
      groupType: 'alphabetical',
      numGroups: 2,
      groupTypes: ['random', 'alphabetical', 'manual']
    }
  },
  methods: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    createGroups() {
      if (this.groupType === 'alphabetical') {
        this.$emit('change', this.assignAlphabetically())
      } else if (this.groupType === 'manual') {
        this.$emit('change', this.assignManually())
      } else if (this.groupType === 'random') {
        this.$emit('change', this.assignRandomly())
      }
    },
    async updateGroups() {
      const delta = this.numGroups - this.groups.length
      if (delta > 0) {
        const groups = this.groups.slice()
        groups.push(
          ...Array.from({ length: delta }, (_, i) =>
            this.buildGroup([], this.groups.length + i)
          )
        )
        this.$emit('change', groups)
      } else if (delta < 0) {
        const { status } = await this.$modal.show(ConfirmModal, {
          text: `This will remove ${-delta} group(s). Students in those groups will be moved to unassigned.`,
          prompt: 'Remove groups and unassign students?'
        })
        if (status === 'ok') {
          this.$emit('change', this.groups.slice(0, delta))
        }
      }
    },
    async clearGroups() {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: `This will remove all groups. This cannot be undone.`,
        prompt: 'Are you sure you want to clear all groups?'
      })
      if (status !== 'ok') return
      this.$emit('change', [])
    },
    assignAlphabetically() {
      const users = [...this.roster].sort((u1, u2) => {
        const name1 = `${u1.lastName}, ${u1.firstName}`
        const name2 = `${u2.lastName}, ${u2.firstName}`
        return name1.localeCompare(name2)
      })
      return this.buildGroups(this.splitUsers(users, this.numGroups, true))
    },
    assignManually() {
      return this.buildGroups(Array(this.numGroups).fill([]))
    },
    assignRandomly() {
      const users = [...this.roster]
      // shuffle with Durstenfeld shuffle algorithm
      const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          const temp = array[i]
          array[i] = array[j]
          array[j] = temp
        }
        return array
      }
      return this.buildGroups(
        this.splitUsers(shuffleArray(users), this.numGroups, true)
      )
    },

    splitUsers(a, n, balanced) {
      if (n < 2) return [a]

      const len = a.length
      const out = []
      let i = 0
      let size

      if (len % n === 0) {
        size = Math.floor(len / n)
        while (i < len) {
          out.push(a.slice(i, (i += size)))
        }
      } else if (balanced) {
        while (i < len) {
          size = Math.ceil((len - i) / n--)
          out.push(a.slice(i, (i += size)))
        }
      } else {
        n--
        size = Math.floor(len / n)
        if (len % size === 0) size--
        while (i < size * n) {
          out.push(a.slice(i, (i += size)))
        }
        out.push(a.slice(size * n))
      }
      return out
    },
    buildGroups(chunks) {
      const groups = chunks.map((students, index) =>
        this.buildGroup(students, index)
      )
      return groups
    },
    buildGroup(students, index) {
      return {
        name: `Co-Lab Group ${index + 1}`,
        students
      }
    },
    updateGroup(group, index) {
      // When dragging and dropping both the source and target group emit an event in the same tick.
      // We need to cache the updated groups so that the second event updates the correct group state.
      const groups = this.editedGroups
      groups[index] = group
      this.editedGroups = groups
      this.$emit('change', groups)
    }
  },
  watch: {
    groups: {
      handler() {
        this.editedGroups = this.groups.slice()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.groups-form {
  flex-wrap: wrap;
}
.group-setup-label {
  flex-grow: 3;
}
.group-setup-row {
  display: flex;
  flex-direction: row;
}
.group-setup-subgroup {
  margin-right: 30px;
  margin-bottom: 10px;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.groups-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.unassigned-container {
  width: 100%;
  border: 1px solid $silver;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 15px;
}
.assigned-text {
  margin-bottom: 15px;
}
</style>
