import { Node, mergeAttributes, nodePasteRule } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import DropTargetEditableNodeView from './DropTargetEditableNodeView'
import DropTargetReadonlyNodeView from './DropTargetReadonlyNodeView'
import { ref } from 'vue'
import debounce from 'lodash/debounce'
import { Plugin, PluginKey } from 'prosemirror-state'
import { mapSlice } from 'src/shared/components/editor/utils'

export function findDropTargets(node, targets = []) {
  if (node.type.name === 'dropTarget') {
    targets.push({ id: node.attrs.id, label: node.attrs.label })
  } else {
    node.forEach(child => {
      findDropTargets(child, targets)
    })
  }
  return targets
}
export function findTextDropTargets(node, targets = []) {
  if (node.type === 'dropTarget') {
    targets.push(node.attrs)
  } else {
    if (node.content) {
      node.content.forEach(child => {
        findTextDropTargets(child, targets)
      })
    }
  }
  return targets
}

const randomId = () => Math.floor(Math.random() * 1000000).toString()

const DropTarget = Node.create({
  name: 'dropTarget',
  inline: true,
  group: 'inline',
  atom: true,
  priority: 1000,

  addStorage() {
    return {
      areas: ref([])
    }
  },

  onCreate() {
    this.storage.areas.value = findDropTargets(this.editor.state.doc).map(
      target => target.id
    )
  },
  onUpdate: debounce(function () {
    this.storage.areas.value = findDropTargets(this.editor.state.doc).map(
      target => target.id
    )
  }, 1000),

  addAttributes() {
    return {
      id: {
        default: ''
      },
      label: {
        default: null
      },
      invalidResponse: {
        default: null
      },
      width: {
        default: 40
      },
      height: {},
      display: {
        default: 'inline'
      },
      positionTop: {
        default: 0
      },
      positionRight: {
        default: 0
      },
      targetType: {
        default: 'drag-drop'
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter({ editor }) {
        if (editor.isActive('dropTarget')) {
          editor.commands.openDropTargetModal()
          return true
        }
      }
    }
  },

  addCommands() {
    return {
      openDropTargetModal: () => () => {
        this.storage.onInsertDropTarget?.()
      },
      setDropTarget:
        attributes =>
        ({ state, commands, editor }) => {
          const node = {
            type: this.name,
            attrs: {
              ...attributes,
              id: attributes.id ?? randomId()
            }
          }
          // If we are in a drop target context and not updating an existing target,
          // then we need to add it to the end of the context.
          if (
            editor.isActive('dropTargetContext') &&
            !editor.isActive('dropTarget')
          ) {
            const { selection } = state
            if (selection.node?.type?.name === 'dropTargetContext') {
              commands.insertContentAt(selection.to - 1, node)
            } else {
              commands.insertContentAt(
                selection.$anchor.end(selection.$anchor.depth),
                node
              )
            }
          } else {
            return commands.insertContent(node)
          }
        }
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(
      this.editor.isEditable
        ? DropTargetEditableNodeView
        : DropTargetReadonlyNodeView
    )
  },

  renderHTML({ HTMLAttributes }) {
    return ['dropTarget', mergeAttributes(HTMLAttributes)]
  },

  parseHTML() {
    return [{ tag: 'dropTarget' }]
  },

  renderText({ node }) {
    return `drop-target:${node.attrs.id}`
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('drop-target-paste'),
        props: {
          transformCopied: slice => {
            /* Add temporary dataForCopyPaste to live in the clipboard. */
            const addCopyPasteDataToDataFields = node => {
              if (node.type.name === 'dropTarget') {
                // do something with external context

                return node.type.create(
                  {
                    ...node.attrs,
                    id: null
                  },
                  node.content
                )
              }

              return node.copy(node.content)
            }

            return mapSlice(slice, addCopyPasteDataToDataFields)
          },
          transformPasted: slice => {
            // get dataForCopyPaste and do whatever with data + clean it up
            const fetchAndClearCopyPasteData = node => {
              if (node.type.name === 'dropTarget' && !node.attrs.id) {
                // do something with copyPasteData and clean it up
                return node.type.create(
                  { ...node.attrs, id: randomId() },
                  node.content
                )
              }
              return node.copy(node.content)
            }

            const newSlice = mapSlice(slice, fetchAndClearCopyPasteData)

            return newSlice
          }
        }
      })
    ]
  }
})

export default DropTarget
