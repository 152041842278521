<template>
  <bubble-menu class="bubble-menu" v-show="!isModalShown">
    <slot />
  </bubble-menu>
</template>

<script>
import * as Vue from 'vue'
import { BubbleMenu } from '@tiptap/vue-3'

export default {
  name: 'EditorBubbleMenu',
  components: { BubbleMenu },
  setup() {
    const editorModal = Vue.inject('editorModal')

    return {
      isModalShown: editorModal.value.isModalShown
    }
  }
}
</script>

<style lang="scss" scoped>
.bubble-menu {
  background: #fff;
  border-radius: $border-radius-base;
  border: 1px solid #ccc;
}
</style>
