<template>
  <div class="range-slider__container">
    <div class="range-slider">
      <input
        v-bind="inputAttrs"
        id="range-slider__range-line"
        class="range-slider__range"
        type="range"
        :name="name"
        :value="modelValue"
        :min="min"
        :max="max"
      />
    </div>
    <div class="range-slider__minmax" aria-hidden="true">
      <span v-for="i in range" :key="i">{{ i }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: 1
  },
  name: {
    type: String,
    // Names must be unique between different inputs.
    default: () => `range-slider-${Math.random() * 100}`
  },
  ariaDescribedby: {
    type: String,
    default: ''
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  }
})

const range = computed(() => {
  return Array(props.max - props.min + 1)
    .fill()
    .map((_, idx) => props.min + idx)
})
const inputAttrs = () => {
  const { style, class: _, ...attrs } = this.$attrs
  return attrs
}
</script>

<style lang="scss" scoped>
.range-slider__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.range-slider {
  width: 100%;
  padding-top: 10px;
}
.range-slider__minmax {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  span {
    display: inline-block;
    text-align: center;
    width: 20px;
  }
}
.range-slider__range {
  width: 100%;
  -webkit-appearance: none;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    &::-webkit-slider-thumb {
      outline: none;
      box-shadow: 0 0 5px rgb(0 0 0 / 50%);
    }
    &::-moz-range-thumb {
      outline: none;
      box-shadow: 0 0 5px rgb(0 0 0 / 50%);
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #ccc;
    border-radius: 0px;
    border: 1px solid #ccc;
  }
  &::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #ccc;
    border-radius: 0px;
    border: 1px solid #ccc;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 1px solid $teal;
    box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.25);
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
  }
  &::-moz-range-thumb {
    box-shadow: none;
    border: 1px solid $teal;
    box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.25);
    height: 20px;
    width: 10px;
    border-radius: 10px;
    background: $teal;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
  }
  &::-moz-focus-outer {
    border: 0;
  }
}
</style>
