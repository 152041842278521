<template>
  <div
    v-show="show"
    class="validation-flash bg-warning-orange-600 text-white validation-flash--inline"
    :class="{
      'validation-flash--stuck': isStuck
    }"
  >
    <div class="validation-flash__content w-full flex-wrap">
      <collapse-provider v-model:collapsed="collapsed" renderless>
        <collapse-toggle
          class="updated-changes__collapse-toggle relative w-full"
        >
          <collapse-icon class="updated-changes__collapse-icon" icon="caret" />
          <span class="updated-changes__heading">
            Some components require attention before the activity can be saved.
          </span>
          <span class="font-normal absolute right-0 underline">{{
            collapsed ? 'Show All' : 'Hide All'
          }}</span>
        </collapse-toggle>
        <collapse-content class="flex flex-1 w-full px-6">
          <div class="w-full">
            <div
              class="flex flex-row"
              v-for="failObj in failed"
              :key="failObj.key"
            >
              <span class="font-normal">
                {{
                  failObj.sectionNumber !== null
                    ? `Section ${failObj.sectionNumber}`
                    : ''
                }}{{
                  failObj.componentNumber ? ` ${failObj.componentNumber}` : ''
                }}.
              </span>
              <span class="font-bold px-2">
                {{ failObj.componentType ? failObj.componentType : '' }}
              </span>
              <span class="font-normal">
                {{ failObj.errorMessage ? failObj.errorMessage : '' }}
              </span>
              <span
                class="ml-4 text-whitefont-normal underline cursor-pointer"
                @click="() => scrollTo(failObj)"
              >
                Jump to this
                {{ failObj.componentNumber ? 'question' : 'section' }}
              </span>
            </div>
          </div>
        </collapse-content>
      </collapse-provider>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
import CollapseToggle from 'src/shared/components/global/CollapseToggle.vue'
import CollapseIcon from 'src/shared/components/global/CollapseIcon.vue'
import CollapseContent from 'src/shared/components/global/CollapseContent.vue'
import { GetActivityResponse, ActivitySection } from '@pi/api-types'

interface SectionWithKeyId extends ActivitySection {
  keyId: string
}
interface Activity extends Omit<GetActivityResponse, 'sections'> {
  sections: SectionWithKeyId[]
}

const isStuck = ref(false)
const observer = ref()
const collapsed = ref(true)

const SectionRegex = /sections\.s(\d*)\./
const componentRegex = /components\.c([a-zA-Z0-9]*)\./

const ActivityComponentTypeFriendlyNames: { [key: string]: string } = {
  GridGraphQuestion: 'Data Table & Graph Question',
  GridQuestion: 'Data Table Question',
  OpenEndedQuestion: 'Open Ended Question',
  MultipleChoiceQuestion: 'Multiple Choice Question',
  EmbeddedInstance: 'Video Instance',
  StudentInstance: 'Student Instance Upload',
  InstructorInstance: 'Instructor Instance Upload',
  GeneralNote: 'Announcment',
  GeneralInstruction: 'Numbered Instruction',
  IFrame: 'Embedded Web Content',
  PhetIOSim: 'Legacy PhET-iO Simulation',
  PhetIOSimulation: 'PhET-iO Simulation',
  NumericalQuestion: 'NumericalQuestion',
  DragDropQuestion: 'Drag & Drop Question',
  SplitView: 'Split View'
}

interface ValidationAlertProps {
  errors: any
  show: boolean
  activity: Activity
}
const props = defineProps<ValidationAlertProps>()

const getSection = (key: string) => {
  const sectionMatch = key.match(SectionRegex)
  const sectionKeyId = sectionMatch ? sectionMatch[1] : null
  const sectionIndex = props.activity.sections.findIndex(
    section => String((section as SectionWithKeyId).keyId) === sectionKeyId
  )
  const section = props.activity.sections[sectionIndex]
  return section
}

const getComponent = (key: string, section: SectionWithKeyId) => {
  const componentMatch = key.match(componentRegex)
  const componentId = componentMatch ? componentMatch[1] : null
  const numberedComponent = componentId
    ? section?.components.find(component => component._id === componentId)
    : null
  let componentType
  if (numberedComponent) {
    componentType = numberedComponent?.componentType
  }
  return {
    componentId,
    componentNumber: numberedComponent?.questionNumber,
    componentType: componentType
      ? ActivityComponentTypeFriendlyNames[componentType] ?? ''
      : ''
  }
}

interface FailObj {
  key: string
  sectionKey?: string | null
  sectionNumber?: number | null
  componentNumber?: number | null
  componentType: string
  componentId: string | null
  errorMessage: string
}

const failed = computed(() => {
  return (
    Object.keys(props.errors).map((key: string) => {
      const errorMessage = props.errors[key]

      const section = getSection(key)
      const component = section.components ? getComponent(key, section) : null

      return {
        key,
        sectionKey: section.keyId,
        sectionNumber: section.sectionNumber,
        componentNumber: component?.componentNumber,
        componentType: component ? component.componentType : '',
        componentId: component ? component.componentId : '',
        errorMessage
      }
    }) ?? []
  )
})

const openSectionAndScrollTo = (id: string, scroll = true) => {
  const section = document.getElementById(id)
  if (section) {
    const collapseToggles = section
      .getElementsByClassName('section-title-error')[0]
      .getElementsByClassName('panel-title__collapsed')
    if (collapseToggles.length > 0) {
      ;(collapseToggles[0] as HTMLButtonElement).click()
      if (scroll) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      }
    } else if (scroll) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

const openComponentAndScrollTo = (id: string) => {
  const component = document.getElementById(id)
  if (component) {
    const collapseToggles = component.getElementsByClassName(
      'panel-title__collapsed'
    )

    if (collapseToggles.length > 0) {
      ;(collapseToggles[0] as HTMLButtonElement).click()
      setTimeout(() => {
        component.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    } else {
      component.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

const scrollTo = (failObj: FailObj) => {
  if (failObj.componentId) {
    openSectionAndScrollTo(`sections.s${failObj.sectionKey}`, false)
    openComponentAndScrollTo(`components.c${failObj.componentId}`)
  } else {
    openSectionAndScrollTo(`sections.s${failObj.sectionKey}`)
  }
}
onMounted(() => {
  if (!window.IntersectionObserver) return

  // This is a transparent element 100px tall at the top of the view container.
  // See the ViewContainer for where this sentinel is defined.
  const scrollSentinel = document.querySelector('#scroll-sentinel')

  observer.value = new IntersectionObserver(
    ([e]) => {
      isStuck.value = e.intersectionRatio < 0.6
    },
    {
      // The #scroll-container element should be the ancestry of this component,
      // but in storybook it may not be, so we use the body.
      root:
        document.querySelector('#scroll-container') ||
        document.querySelector('body'),
      threshold: [0, 0.6]
    }
  )
  if (scrollSentinel) {
    observer.value.observe(scrollSentinel)
  }
})

onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect()
    observer.value = null
  }
})
</script>

<style lang="scss" scoped>
.validation-flash {
  position: relative;
  left: 0;
  z-index: 1002;
  padding: 12px 9999% 12px 9999%;
  margin: 0 -9999% 0 -9999%;

  &--inline {
    margin-top: 16px;
    margin-bottom: -16px;
  }
  &--stuck {
    position: sticky;
    top: 82px;
  }
  .validation-flash__content {
    width: 100%;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .last-updated {
    font-weight: 400;
    font-style: italic;
  }
}
</style>
