<template>
  <modal @submit="submit" class="replace-activity-modal">
    <modal-header
      >Replace prior activities with: {{ activityName }}</modal-header
    >
    <modal-body class="replace-activity-modal__body">
      <div>
        <div class="replace-activity-modal__step" v-if="currentStep === 1">
          <form-group>
            <p>Select existing activities to be replaced by this one:</p>
            <autocomplete
              v-model="selectedActivityIds"
              :options="activities"
              multiple
              placeholder="search by activity name or ID"
              label-key="name"
              value-key="id"
              ariaLabel="select activites to replace"
            />
          </form-group>
        </div>
        <div class="replace-activity-modal__step" v-if="currentStep === 2">
          <p>
            This activity is replacing
            <strong>{{ selectedActivityIds.length }}</strong> activities.
            <form-button link @click="goToStep1">Change</form-button>
          </p>
          <p
            class="replace-activity-modal__selected-activity"
            v-for="activity in selectedActivities"
            :key="activity.id"
          >
            {{ activity.name }}
          </p>
          <form-group>
            <label class="control-label" for="reason">
              Please provide an explanation for why these activities are being
              replaced.
            </label>

            <multiline-text-input
              id="reason"
              v-model="reason"
              ModalButtonSubmit
              resizable-both
              label="reason"
              ariaLabel="reason"
            />
          </form-group>
          <p class="warning">
            <strong>Warning:</strong> Replacing an activity will automatically
            archive the activity in the Pivot Library and prompt anyone with a
            copy of this activity to update it.
          </p>
          <checkbox v-model="understand" name="understand"
            >I understand
          </checkbox>
        </div>
      </div>
    </modal-body>
    <modal-actions>
      <form-button
        primary
        v-if="currentStep === 1"
        data-focus
        @click="goToReview"
        :disabled="!selectedActivityIds.length"
        >Select</form-button
      >
      <modal-button-submit v-if="currentStep === 2" :disabled="!canSubmit"
        >Replace</modal-button-submit
      >
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import Autocomplete from 'src/shared/components/Autocomplete.vue'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client'
import { ref, computed, onMounted } from 'vue'
import { SearchActivitiesRequest } from '@pi/api-types'

interface Props {
  activityId: string
  activityName: string
}
interface ActivityView {
  id: string
  name: string
  description?: string
  thumbnail?: string
  libraryId: string
  isArchived: boolean
  isFree: boolean
  isSharedWithCommunity: boolean
  updatedAt: Date
  autogradePercentage?: number
  questionCount?: number
  latestChangelogEntryDate?: Date
}

interface SubmitProps {
  done: () => void
}
const props = defineProps<Props>()

const activities = ref<ActivityView[]>([])
const selectedActivityIds = ref<string[]>([])
const reason = ref<string>('')
const currentStep = ref<number>(1)
const understand = ref<boolean>(false)
const flash = useFlash()

const selectedActivities = computed<ActivityView[]>(() => {
  return activities.value.filter(activity =>
    selectedActivityIds.value.includes(activity.id)
  )
})
const canSubmit = computed<boolean>(
  () =>
    !!(understand.value && reason.value && selectedActivityIds.value.length > 0)
)

const submit = async ({ done }: SubmitProps) => {
  await client.activities.replace({
    activityId: props.activityId,
    selectedActivities: selectedActivityIds.value,
    reason: reason.value
  })
  flash.success('Activities replaced')
  done()
}

const goToReview = () => {
  currentStep.value = 2
}

const goToStep1 = () => {
  currentStep.value = 1
}

onMounted(async () => {
  const searchRequest: SearchActivitiesRequest = {
    libraryId: 'pivot',
    page: 1,
    limit: 1000,
    isArchived: false
  } as SearchActivitiesRequest
  const activityResult = await client.libraries.searchActivities(searchRequest)
  activities.value = activityResult.page.filter(
    (a: ActivityView) => a.id !== props.activityId
  )
})
</script>
<style scoped>
.replace-activity-modal__body {
  min-height: 300px;
}
.replace-activity-modal__selected-activity {
  font-weight: bold;
  margin-bottom: 6px;
}
.warning {
  color: #cc6114;
}
</style>
