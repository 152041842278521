<template>
  <collapse-provider v-model:collapsed="collapsed">
    <collapse-toggle
      class="changelog-entry__collapse-toggle"
      data-testid="changelog-entry"
    >
      <collapse-icon class="changelog-entry__collapse-icon" />
      <h3 class="changelog-entry__heading">
        {{ format(new Date(changelogEntry.createdAt), 'MM/dd/yyyy HH:mm:ss') }}
        by
        {{ changelogEntry.createdBy.firstName }}
        {{ changelogEntry.createdBy.lastName }}
        <h4 class="changelog-entry__content-heading">
          {{ changelogEntry.title }}
        </h4>
      </h3>
    </collapse-toggle>
    <collapse-content>
      <editor-content
        class="changelog-entry__content"
        :text="changelogEntry.text"
      />
    </collapse-content>
  </collapse-provider>
</template>

<script setup>
import CollapseToggle from 'src/shared/components/global/CollapseToggle.vue'
import CollapseIcon from 'src/shared/components/global/CollapseIcon.vue'
import CollapseContent from 'src/shared/components/global/CollapseContent.vue'
import { format } from 'date-fns'
import { computed } from 'vue'

const emit = defineEmits(['update:collapsed'])
const props = defineProps({
  changelogEntry: {
    type: Object,
    required: true
  },
  collapsed: {
    type: Boolean,
    default: true
  }
})

const collapsed = computed({
  get: () => props.collapsed,
  set: value => emit('update:collapsed', value)
})
</script>

<style scoped lang="scss">
.changelog-entry__collapse-toggle {
  display: flex;
}
.changelog-entry__collapse-icon {
  margin-right: 5px;
  margin-top: 3px;
  font-size: 10px;
  color: #000000;
  text-align: center;
  align-self: flex-start;
}

h3 {
  padding: 0;
  margin: 0;
}
.changelog-entry__heading {
  color: #808080;
  font-size: 16px;
  font-style: italic;
  padding: 0;
  position: relative;
  display: inline-block;

  &:hover &:focus {
    cursor: pointer;
    color: $teal;
  }
}

.changelog-entry__content-heading {
  color: #3d3d3d;
  font-style: normal;
}

.changelog-entry__content {
  margin-left: 15px;
}
</style>
