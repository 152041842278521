<template>
  <div
    class="split-view-container"
    :class="
      viewMode === 'split' && !grading
        ? 'split-view-container__split'
        : 'split-view-container__inline'
    "
  >
    <div
      :class="{
        'split-view-content__left': viewMode == 'split' && !grading
      }"
    >
      <template
        v-for="(childComponent, index) in component.leftContent"
        :key="index"
      >
        <activity-section-component
          :class="{ 'split-view-grading': grading }"
          v-slot="{ onSubmit, onCanSubmitChange, canSubmit }"
          :ref="el => (childComponentRefs[childComponent._id] = el)"
          :component="childComponent"
          :variable-context="variableContext"
          :preview="preview"
          :overrideHiding="!viewAsStudent"
          @change="onChange"
          @submit="emit('submit', component)"
          :viewAsStudent="viewAsStudent"
          @needs-submission="e => emit('needs-submission', e, component._id)"
        >
          <activity-section-component-renderer
            :component="childComponent"
            :variable-context="variableContext"
            :answersShown="answersShown"
            :viewAsStudent="viewAsStudent"
            :preview="!!preview"
            :canSubmit="canSubmit"
            :grading="grading"
            @submit="onSubmit"
            @canSubmitChange="onCanSubmitChange"
            :unauthenticated="unauthenticated"
            :readonly="!isFreeActivity"
          />
        </activity-section-component>
      </template>
    </div>
    <div
      :class="{ 'split-view-content__right': viewMode === 'split' && !grading }"
    >
      <template
        v-for="(childComponent, index) in component.rightContent"
        :key="index"
      >
        <activity-section-component
          v-slot="{ onSubmit, onCanSubmitChange, canSubmit }"
          :ref="el => (childComponentRefs[childComponent._id] = el)"
          :component="childComponent"
          :variable-context="variableContext"
          :preview="preview"
          :overrideHiding="!viewAsStudent"
          @change="onChange"
          :viewAsStudent="viewAsStudent"
          @submit="emit('submit', component)"
          @needs-submission="e => emit('needs-submission', e, component._id)"
        >
          <activity-section-component-renderer
            :component="childComponent"
            :variable-context="variableContext"
            :answersShown="answersShown"
            :viewAsStudent="viewAsStudent"
            :preview="!!preview"
            :canSubmit="canSubmit"
            :grading="grading"
            @submit="onSubmit"
            @canSubmitChange="onCanSubmitChange"
            :unauthenticated="unauthenticated"
            :readonly="!isFreeActivity"
          />
        </activity-section-component>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ActivitySectionComponent from 'src/modules/activities/components/ActivitySectionComponent.vue'
import ActivitySectionComponentRenderer from '../components/ActivitySectionComponentRenderer.vue'

type SplitViewComponentProps = {
  component: any
  preview?: string
  variableContext: any
  overrideHiding?: boolean
  viewAsStudent: boolean
  answersShown: boolean
  componentRefs: any
  grading?: boolean
  unauthenticated?: boolean
  isFreeActivity?: boolean
  viewMode: string
}
const emit = defineEmits([
  'change',
  'updateComponentRefs',
  'submit',
  'needs-submission'
])

const childComponentRefs = computed({
  get: () => props.componentRefs,
  set: val => {
    emit('updateComponentRefs', val)
  }
})
const props = defineProps<SplitViewComponentProps>()

const onChange = (changes: any) => {
  emit('change', changes)
}
</script>

<style lang="scss" scoped>
.split-view-container__inline {
  display: block;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .split-view-container__split {
    display: flex;
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-right: -50vw;
    margin-left: -50vw;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: space-evenly;
  }
  .split-view-content__left {
    border: 1px solid #bcbcbc;
    min-width: 50%;
    position: sticky;
    top: 90px;
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
    padding: 0 12px;
  }

  .split-view-grading {
    width: 100%;
  }

  .split-view-content__right {
    max-width: 50%;
    min-width: 48%;
    margin-left: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .split-view-container__split {
    display: block;
    margin-left: 0;
    margin-right: 0;
    left: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    .split-view-content__left,
    .split-view-content__right {
      max-width: 100%;
    }
    .split-view-content__right {
      margin-left: 0;
    }
  }
}
</style>
