<template>
  <div
    class="sticky-header"
    :class="{ 'sticky-header--stuck': isStuck }"
    data-testId="sticky-header"
  >
    <div class="sticky-header__row-0">
      <slot name="above" />
    </div>
    <div class="sticky-header__row-1">
      <div class="sticky-header__primary-navigation">
        <slot name="primary-navigation" :is-stuck="isStuck" />
      </div>
      <div class="sticky-header__secondary-navigation">
        <slot name="secondary-navigation" :is-stuck="isStuck" />
      </div>
      <div class="sticky-header__page-action">
        <slot name="page-action" :is-stuck="isStuck" />
      </div>
    </div>
    <div class="sticky-header__row-2">
      <div class="sticky-header__title">
        <div class="sticky-header__title-block">
          <slot name="title" :is-stuck="isStuck" />
        </div>
      </div>
      <div class="sticky-header__subtitle">
        <slot name="sub-title" :is-stuck="isStuck" />
      </div>
      <div class="sticky-header__actions">
        <slot name="actions" :is-stuck="isStuck" />
      </div>
    </div>

    <slot name="alerts" />
    <flash class="sticky-header__flash" inline />
  </div>
  <slot name="steps" :is-stuck="isStuck" />
</template>

<script>
import Flash from 'src/shared/components/Flash.vue'

export default {
  name: 'StickyHeader',
  components: {
    Flash
  },
  data() {
    return {
      isStuck: false
    }
  },
  methods: {
    createObserver() {
      if (!window.IntersectionObserver) return

      this.observer = new IntersectionObserver(
        ([e]) => {
          // We have to provide different logic for when the header is stuck and not stuck
          // because when the header shrinks, the view can scroll up, making the sentinel visible again.
          // This would cause a flicker in the header styling.
          this.isStuck = this.isStuck
            ? e.intersectionRatio < 0.7 // When scrolling up, the header becomes unstuck when the top 30% of the sentinel is visible.
            : !e.isIntersecting // When scrolling down, the header becomes stuck when the sentinel is no longer visible.
        },
        {
          root: this.scrollContainer,
          threshold: [0, 0.7]
        }
      )
      if (this.scrollSentinel) {
        this.observer.observe(this.scrollSentinel)
      }
    },
    destroyObserver() {
      if (this.observer) {
        this.observer.disconnect()
        delete this.observer
      }
    }
  },
  mounted() {
    // The #scroll-container element should be the ancestry of this component,
    // but in storybook it may not be, so we use the body.
    this.scrollContainer =
      document.querySelector('#scroll-container') ||
      document.querySelector('body')

    // This is a transparent element 100px tall at the top of the view container.
    // See the ViewContainer for where this sentinel is defined.
    this.scrollSentinel = document.querySelector('#scroll-sentinel')

    this.createObserver()
  },
  beforeUnmount() {
    this.destroyObserver()
  }
}
</script>

<style lang="scss" scoped>
.sticky-header {
  padding: 8px 9999% 16px 9999%;
  margin: 0 -9999% 0 -9999%;
  background: #fff;
  font-size: 16px;
  border-bottom: 1px solid #ccc;

  &--stuck {
    z-index: 999;
    top: 0;
    position: sticky;
    padding-bottom: 3px;
    padding-top: 3px;
    font-size: 12px;
  }

  &__row-1 {
    margin: 0 0 8px 0;
    display: grid;
    grid-gap: 8px;
    grid: 'primary secondary  page' auto / auto auto auto;

    @media screen and (max-width: $screen-md) {
      grid: 'primary' 1fr / 1fr;
    }

    .sticky-header--stuck & {
      margin: 0 0 2px 0;
    }
  }

  &__primary-navigation {
    grid-area: primary;
  }

  &__secondary-navigation {
    grid-area: secondary;
    margin: 0 0 0 40px;
    justify-self: center;

    @media screen and (max-width: $screen-md) {
      display: none;
    }
  }

  &__page-action {
    grid-area: page;
    justify-self: end;

    @media screen and (max-width: $screen-md) {
      display: none;
    }
  }

  &__row-2 {
    display: grid;
    grid:
      'title actions' auto
      'subtitle actions' auto
      / 1fr auto;

    @media screen and (max-width: $screen-md) {
      grid:
        'title' auto
        'actions' auto
        'subtitle' auto
        / 1fr;
    }
  }

  &__title {
    grid-area: title;
    min-width: 0;
  }

  &__title-block {
    width: 100%;
    display: flex;
    align-items: baseline;
  }

  &__subtitle {
    margin: 4px 0 0 0;
    grid-area: subtitle;

    @media screen and (max-width: $screen-md) {
      margin: 8px 0 0 0;

      .sticky-header--stuck & {
        margin: 4px 0 0 0;
      }
    }
  }

  &__actions {
    grid-area: actions;
    align-self: flex-end;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: $screen-md) {
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-top: 12px;

      & > *:last-child {
        margin-left: 0;
      }

      .sticky-header--stuck & {
        margin-top: 4px;
      }
    }

    .sticky-header--stuck & :deep() {
      select {
        padding: 9px 14px;
      }

      .check-box {
        margin-top: 6px;
      }

      .checkbox-value {
        margin-top: 3px;
      }
    }

    & > :slotted(*) {
      margin-left: 8px;
    }

    :deep(select) {
      font-size: inherit;
      padding: 10px 14px;
      height: auto;
    }
  }
}

.sticky-header__flash {
  margin-top: 16px;

  .sticky-header--stuck & {
    margin-top: 3px;
  }
}
</style>
