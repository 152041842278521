<template>
  <div class="editor-modal__wrapper">
    <div
      v-bind="$attrs"
      ref="modal"
      class="editor-modal"
      role="dialog"
      aria-modal="true"
      :aria-label="title"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import * as Vue from 'vue'

export default {
  name: 'EditorModal',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const editorModal = Vue.inject('editorModal')
    editorModal.value.register(props.name)
    Vue.onUnmounted(() => editorModal.value.unregister())

    const modal = Vue.ref(null)
    Vue.onMounted(() => {
      const firstElement = modal.value?.querySelector('[data-focus]')

      if (firstElement) firstElement.focus()
      else {
        const el = modal.value?.querySelector(
          'button, input, select, textarea, a, [tabindex]'
        )
        el?.focus()
      }
    })

    return { modal }
  }
}
</script>

<style lang="scss" scoped>
.editor-modal {
  padding: 16px;
  background: #fff;
  border-radius: $border-radius-base;
  border: 1px solid #ccc;

  &__wrapper {
    border-radius: $border-radius-base;
    z-index: 10000;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}

:deep(.editor-modal__actions) {
  display: flex;
  flex-direction: row-reverse;
  margin: 16px 0 0 0;

  & > * {
    margin: 0 0 0 16px !important;
  }
}
</style>
