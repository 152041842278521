<template>
  <auth-card header="MFA Verification">
    <p class="mfa-verify__help-text">Please verify your MFA code.</p>
    <async-form @submit="onSubmit" class="mfa-verify__form">
      <form-group>
        <form-label for="token">Code</form-label>
        <text-input id="token" v-model="token" rules="required" label="Token" />
      </form-group>

      <form-group class="submit-group">
        <submit-button>Submit</submit-button>
      </form-group>
    </async-form>
  </auth-card>
</template>

<script setup>
import { ref } from 'vue'
import { useFlash } from 'src/shared/hooks/flash'
import { useRouter } from 'vue-router'
import AuthCard from './AuthCard.vue'
import client from 'src/shared/api-client'

const router = useRouter()
const flash = useFlash()

const token = ref('')

async function onSubmit(e) {
  try {
    await client.auth.verifyMFAToken(token.value)
    router.push({
      ...router.currentRoute.value,
      params: { ...router.currentRoute.value.params, step: 'redirect' }
    })
    e.done()
  } catch (error) {
    e.done(false)
    if (error.status === 401) {
      flash.error(error.body.errors[0].message)
    } else {
      throw error
    }
  }
}
</script>

<style scoped>
.submit-group {
  display: flex;
  justify-items: flex-end;
}

.mfa-verify__help-text,
.mfa-verify__form {
  width: 300px;
  margin: auto;
}
</style>
