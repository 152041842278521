<template>
  <th :class="headerClass">
    <div class="sortable-list-column__header-wrapper">
      <slot name="header" :on-click="onClick">
        <a
          @mouseenter="popoverVisible = true"
          @mouseleave="popoverVisible = false"
          ref="title"
          href="#"
          @click.prevent="onClick"
        >
          {{ title }}
        </a>
        <base-popover
          v-if="noIcon && (helpText || helpLink)"
          placement="bottom"
          :anchor-ref="$refs.title"
          :visible="popoverVisible"
        >
          <editor-content
            :text="helpText"
            :variable-context="variableContext"
            toolTip
          />
        </base-popover>
        <help-popover
          v-else-if="helpText || helpLink"
          :to="helpLink && { name: helpLink }"
        >
          <editor-content
            toolTip
            :text="helpText"
            :variable-context="variableContext"
          />
        </help-popover>
      </slot>
      <a
        href="#"
        class="sortable-list-column__icons"
        aria-hidden
        :tabindex="-1"
        @click.prevent="onClick"
      >
        <icon-layers>
          <icon
            icon="sort-up"
            class="sortable"
            :class="{ sorted: isAscending }"
            size="lg"
          />
          <icon
            icon="sort-down"
            class="sortable"
            :class="{ sorted: isDescending }"
            size="lg"
          />
        </icon-layers>
      </a>
    </div>

    <!--
      @slot The content for each cell in this column.
      Recieves the `item` as a slot prop.
    -->
    <slot v-if="false" />
    <slot v-if="false" name="summary" />
  </th>
</template>

<script>
export default {
  name: 'SortableListColumn',
  emits: ['change'],
  props: {
    /**
     * The title of the column.
     */
    title: {
      type: String,
      required: true
    },
    /**
     * The property on the row item to show in the cell.
     * Ignored if the default slot is used.
     */
    property: {
      type: String,
      default: null
    },
    /**
     * The sort direction of this column. Can be `'asc'`, `'desc'` or `false`.
     */
    sort: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false
    },
    helpText: {
      type: String,
      required: false,
      default: null
    },
    helpLink: {
      type: String,
      required: false,
      default: null
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    columnClass: {
      type: String,
      default: null,
      required: false
    },
    variableContext: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      method: this.sort,
      popoverVisible: false
    }
  },
  computed: {
    isAscending() {
      return this.method === 'asc'
    },
    isDescending() {
      return this.method === 'desc'
    },
    headerClass() {
      let headerClass = []
      if (this.sticky) {
        headerClass.push('sticky')
      }
      if (this.columnClass) {
        headerClass.push(this.columnClass)
      }

      return headerClass.join(' ')
    }
  },

  methods: {
    onClick() {
      switch (this.method) {
        case 'asc':
          this.method = 'desc'
          break
        case 'desc':
          this.method = ''
          break
        default:
          this.method = 'asc'
      }

      /**
       * Notifies the updated sort direction for this column.
       * @type {String}
       */
      this.$emit('change', this.method)
    }
  },
  watch: {
    sort(value) {
      this.method = value
    }
  }
}
</script>

<style lang="scss" scoped>
th {
  border-bottom: none !important;
  box-shadow: inset 0 -2px 0 0 #ddd;

  .sortable {
    color: lighten($gray-base, 75%);
  }
  .sorted {
    color: inherit;
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }

  &.sticky {
    position: sticky;
    top: 0;
    height: 40px;
    background: white;
    z-index: 2;
    border: none;
    box-shadow: inset 0 -2px 0 0 #ddd;
  }
}

.sortable-list-column__header-wrapper {
  display: flex;
  align-items: flex-end;
}
.sortable-list-column__help {
  max-width: 500px;
  text-wrap: wrap;
}

.sortable-list-column__icons {
  vertical-align: bottom;
  padding: 0 5px;
}
</style>
