<template>
  <div class="instance-upload" :class="errorMessage ? 'has-error' : ''">
    <div class="visible-print-block">
      Student uploaded video instance not printed.
    </div>

    <div class="hidden-print">
      <div class="instance-container">
        <pivot-instance
          ref="instance"
          :settings="settings"
          :limit-functionality="limitFunctionality"
          @calibration-change="calibrationChange"
        />

        <form-button class="show-settings" @click="toggleSettings">
          {{ showSettings ? 'Hide' : 'Show' }} Settings
        </form-button>

        <div
          :id="collapseId"
          class="instance-dropdown"
          :aria-labelledby="collapseId"
          :aria-expanded="settingsOpenDefault"
          :class="[{ in: settingsOpenDefault }]"
        >
          <div v-if="showSettings" class="instance-settings">
            <div v-if="!readonly" class="form-group row">
              <label class="col-sm-5 text-right">Select Video</label>
              <div class="col-sm-5">
                <input
                  ref="fileInput"
                  class="video-upload"
                  type="file"
                  accept="video/mp4, video/quicktime, video/webm"
                  @change="fileChange($event)"
                />
              </div>
            </div>
            <div v-if="!readonly" class="form-group row">
              <p class="col-sm-offset-5 col-sm-5">
                Only MP4 and MOV videos under {{ uploadLimit }} are allowed.
                Videos uploaded by students will be kept for up to 90 days.
              </p>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">Recorded Frame Rate</label>
              <div class="col-sm-5">
                <autocomplete
                  v-model="recordedFrameRate"
                  :options="recordedFrameRates"
                  override
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">Playback Frame Rate</label>
              <div class="col-sm-5">
                <autocomplete
                  v-model="playbackFrameRate"
                  :options="playbackFrameRates"
                  override
                />
              </div>
            </div>
            <div v-if="!readonly" class="form-group clear-both">
              <div class="col-sm-10 settings-actions">
                <span v-if="errorMessage" class="help-block">
                  {{ errorMessage }}
                </span>
                <form-button tertiary @click="cancel">Cancel</form-button>
                <loading-button
                  class="settings-action pi-btn-small"
                  :on-click="update"
                  >Update
                </loading-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef } from 'vue'
import PivotInstance from 'src/shared/components/PivotInstance'
import generateInstanceSettings from 'src/shared/components/config/instanceSettings'
import { useField } from 'vee-validate'
import Autocomplete from 'src/shared/components/Autocomplete'
import MP4Box from 'mp4box'
import client from 'src/shared/api-client'
import UnsupportedCodecModal from 'src/shared/components/modals/UnsupportedCodecModal'
import { validateFile } from 'src/shared/components/editor/utils.js'

const DEFAULT_PLAYBACK_FRAME_RATE = 29.97
const DEFAULT_RECORDED_FRAME_RATE = 30
let counter = 0

export default {
  name: 'InstanceUpload',
  components: { PivotInstance, Autocomplete },
  emits: ['update:config'],
  inject: ['$modal'],
  props: {
    label: {
      type: String,
      default: 'video upload'
    },
    name: {
      type: String,
      // Names must be unique between different inputs.
      default: () => `video-upload-${counter++}`
    },
    rules: {
      type: [Object, String]
    },
    limitFunctionality: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    config: {
      required: true
    },
    noUpload: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    function convertForValidation(config) {
      return Object.getOwnPropertyNames(config).length > 0 ? 'valid' : undefined
    }
    let abortController = new AbortController()

    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: convertForValidation(props.config),
      validateOnValueUpdate: false,
      syncVModel: false
    })

    return {
      abortController,
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField,
      convertForValidation
    }
  },
  data() {
    return {
      settingsOpenDefault: !this.config.src,
      showSettings: !this.config.src,
      file: null,
      request: null,
      recordedFrameRate: null,
      playbackFrameRate: null,
      recordedFrameRates: [30, 60, 120, 240, 480, 960].map(rate =>
        rate.toString()
      ),
      playbackFrameRates: [29.97, 59.94].map(rate => rate.toString()),
      toolColors: ['Red', 'Black', 'White'],
      uploadLimit: PI_ASSET_SIZE_LIMIT_VIDEO,
      calibration: this.config.calibration
    }
  },
  created() {
    this.reset()
  },
  computed: {
    collapseId() {
      return `video-upload-${Math.floor(Math.random() * 1000000)}`
    },
    settings() {
      let { playbackFrameRate, recordedFrameRate, src, calibration } =
        this.config
      if (this.readonly) {
        playbackFrameRate = parseFloat(this.playbackFrameRate)
        recordedFrameRate = parseFloat(this.recordedFrameRate)
      }
      return generateInstanceSettings(
        playbackFrameRate || 29.97,
        recordedFrameRate || 30,
        src,
        calibration
      )
    }
  },
  methods: {
    toggleSettings() {
      this.showSettings = !this.showSettings
    },
    async fileChange(event) {
      const file = event.target.files[0]
      if (file) {
        try {
          await validateFile(event, file)
          this.file = event.target.files[0]
        } catch (error) {
          await this.$modal.show(UnsupportedCodecModal)
          event.target.value = null
          this.file = null
        }
      } else {
        this.file = null
      }
    },
    calibrationChange(calibration) {
      this.calibration = calibration

      const newValue = {
        ...this.config,
        calibration
      }

      this.handleChange(this.convertForValidation(newValue), false)
      this.$emit('update:config', newValue)
    },

    async update() {
      let {
        file,
        playbackFrameRate,
        recordedFrameRate,
        toolColor,
        calibration
      } = this
      playbackFrameRate = parseFloat(playbackFrameRate)
      recordedFrameRate = parseFloat(recordedFrameRate)
      if (file) {
        let src
        if (this.noUpload) {
          const reader = new FileReader()
          src = await new Promise((resolve, reject) => {
            reader.addEventListener('load', () => {
              resolve(reader.result)
            })
            reader.addEventListener('error', () => {
              reject(new Error(`Failed to read file: ${file.name}.`))
            })
            reader.addEventListener('abort', () => {
              reject(new Error(`Aborted reading file: ${file.name}.`))
            })
            reader.readAsDataURL(file)
          })
        } else {
          const fileNameParts = file.name.split('.')
          const fileName = fileNameParts.slice(0, -1).join('.')
          const fileExtension =
            fileNameParts.length > 1
              ? fileNameParts[fileNameParts.length - 1]
              : 'mp4'

          src = await client.assets.upload(
            {
              file,
              name: `${fileName}-${Date.now()}.${fileExtension}`,
              type: 'video'
            },
            { signal: this.abortController.signal }
          )
        }
        const newValue = {
          src,
          playbackFrameRate,
          recordedFrameRate,
          toolColor,
          calibration: {}
        }
        this.handleChange(this.convertForValidation(newValue))
        this.$emit('update:config', newValue)
        this.file = null
        this.request = null
        // reload the instance manually when the src filename doesn't change
        if (src === (this.config || {}).src) {
          this.$refs.instance.load()
        }
      } else if (this.config.src) {
        const newValue = {
          ...this.config,
          playbackFrameRate,
          recordedFrameRate,
          toolColor,
          calibration
        }
        this.handleChange(this.convertForValidation(newValue))
        this.$emit('update:config', newValue)
      }
    },
    cancel() {
      this.abortController.abort()
      this.reset()
    },
    reset() {
      this.abortController = new AbortController()
      this.file = null
      if (this.$refs.fileInput) this.$refs.fileInput.value = null
      this.request = null
      this.recordedFrameRate = (
        this.config.recordedFrameRate || DEFAULT_RECORDED_FRAME_RATE
      ).toString()
      this.playbackFrameRate = (
        this.config.playbackFrameRate || DEFAULT_PLAYBACK_FRAME_RATE
      ).toString()
      this.resetField({ value: this.convertForValidation(this.config || {}) })
    }
  },
  watch: {
    config(newConfig = {}, oldConfig = {}) {
      if (
        newConfig.src !== oldConfig.src ||
        newConfig.playbackFrameRate !== oldConfig.playbackFrameRate ||
        newConfig.recordedFrameRate !== oldConfig.recordedFrameRate
      ) {
        this.reset()
        // Have to wait a tick for updated settings to propagate to PivotInstance component.
        this.$nextTick(() => {
          this.$refs.instance.load()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.instance-settings {
  padding: 16px 16px 0 16px;
  font-size: $font-size-base;
  line-height: 100%;
}

.settings-header {
  margin: 0 0 16px 0;
}

.settings-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}

.settings-action {
  margin: 0 8px;
}

.show-settings {
  font-size: $font-size-base;
  line-height: 100%;
  margin: 8px;
}

.help-block {
  text-align: right;
}
</style>
