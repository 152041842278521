<template>
  <slot v-if="renderless" :collapsed="state" />
  <div v-else v-bind="attrs">
    <slot :collapsed="state" />
  </div>
</template>

<script>
import * as Vue from 'vue'

/**
 * Coordinates the state of a collapsing element.
 */
export default {
  name: 'CollapseProvider',
  inheritAttrs: false,
  provide() {
    return {
      collapse: Vue.computed(() => ({
        state: this.state,
        setState: this.setState,
        contentId: this.contentId,
        registerContentId: this.registerContentId
      }))
    }
  },
  props: {
    /**
     * When controlled, determines the current collapse state.
     * When uncontrolled, provides the initial collapse state.
     */
    collapsed: {
      type: Boolean,
      default: false
    },

    /** Whether to render a wrapper div. Defaults to true. */
    renderless: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCollapsed: this.collapsed,
      contentId: null
    }
  },
  computed: {
    attrs() {
      const { onChange, 'onUpdate:collapsed': _, ...attrs } = this.$attrs
      return attrs
    },
    onChange() {
      return this.$attrs['onUpdate:collapsed'] ?? this.$attrs.onChange
    },
    isControlled() {
      return !!this.onChange
    },
    state() {
      return this.isControlled ? this.collapsed : this.localCollapsed
    }
  },
  methods: {
    setState(newState) {
      if (this.isControlled) {
        /** When controlled, emitted when descendent elements change the collapse state. */
        if (newState !== this.state) {
          this.onChange(newState)
        }
      } else {
        this.localCollapsed = newState
      }
    },
    registerContentId(id) {
      this.contentId = id
    }
  }
}
</script>

<style></style>
