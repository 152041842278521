<template>
  <div>
    <sidebar-header>Filter</sidebar-header>
    <sidebar-list>
      <type-filter />
      <status-filter />
    </sidebar-list>
  </div>
</template>

<script>
import SidebarHeader from 'src/shared/components/SidebarHeader'
import SidebarList from 'src/shared/components/SidebarList'
import TypeFilter from './TypeFilter'
import StatusFilter from './StatusFilter'

export default {
  name: 'SidebarFilter',
  components: {
    SidebarHeader,
    SidebarList,
    TypeFilter,
    StatusFilter
  }
}
</script>

<style></style>
