<template>
  <modal class="data-grid-column-formula-modal" @submit="submit">
    <modal-header>Column Formula</modal-header>
    <modal-body>
      <form-group>
        <form-label for="column-formula"> Formula </form-label>
        <div>
          <text-input
            id="column-formula"
            ref="input"
            label="Column Formula"
            :modelValue="formattedFormula"
            :rules="validateFormula"
            disabled
          />
        </div>
      </form-group>

      <div class="buttons-container">
        <div
          class="calculator"
          role="grid"
          aria-label="calculator buttons used to modify the column formula"
          @keydown.up.prevent="onCalcUpKey"
          @keydown.down.prevent="onCalcDownKey"
          @keydown.left.prevent="onCalcLeftKey"
          @keydown.right.prevent="onCalcRightKey"
          @keydown.home.prevent="onCalcHomeKey"
          @keydown.end.prevent="onCalcEndKey"
        >
          <div
            v-for="(row, rowIndex) in calculatorButtons"
            :key="rowIndex"
            role="row"
          >
            <button
              v-for="(button, buttonIndex) in row"
              :ref="
                el => setCalculatorButtonRef(rowIndex * 4 + buttonIndex, el)
              "
              :key="buttonIndex"
              type="button"
              role="gridcell"
              :class="{
                'width-2': button.width === 2
              }"
              :tabindex="rowIndex === 0 && buttonIndex === 0 ? 0 : -1"
              @click.prevent="onCalculatorButtonPress(button.value)"
            >
              <span aria-hidden="true">{{ button.label }}</span>
              <span class="sr-only">{{ button.name || button.label }}</span>
            </button>
          </div>
        </div>

        <div class="variables">
          <div
            class="variables-list"
            role="grid"
            aria-label="buttons used to insert column variables in the column formula"
            @keydown.up.prevent="onVarUpKey"
            @keydown.down.prevent="onVarDownKey"
            @keydown.left.prevent="onVarLeftKey"
            @keydown.right.prevent="onVarRightKey"
            @keydown.home.prevent="onVarHomeKey"
            @keydown.end.prevent="onVarEndKey"
          >
            <div v-for="(variable, index) in variables" :key="index" role="row">
              <button
                :ref="el => setVariableButtonRef(index, el)"
                type="button"
                role="gridcell"
                :tabindex="index === 0 ? 0 : -1"
                @click.prevent="onVariableButtonPress(variable.columnIndex)"
              >
                <span aria-hidden="true">{{ variable.label }}</span>
                <span class="sr-only"
                  >insert column variable {{ variable.label }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Submit</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import {
  columnDisplayName,
  columnDisplayVariable
} from 'src/shared/components/grid-graph/utilities'
import useArrayRef from 'src/shared/hooks/array-ref'
import math from 'src/setup/math'

const BUTTONS = [
  [
    { label: 'C', value: 'C', name: 'clear' },
    { label: '⌫', value: 'BS', name: 'backspace' },
    { label: '(', value: '(', name: 'left parenthesis' },
    { label: ')', value: ')', name: 'right parenthesis' }
  ],
  [
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '/', value: ' / ', name: 'divide' }
  ],
  [
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '*', value: ' * ', name: 'multiply' }
  ],
  [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '-', value: ' - ', name: 'subtract' }
  ],
  [
    { label: '0', value: '0' },
    { label: '.', value: '.', name: 'decimal' },
    { label: '(-)', value: '-', name: 'negate' },
    { label: '+', value: ' + ', name: 'add' }
  ],
  [
    { label: 'sin', value: 'sin(', name: 'sine' },
    { label: 'cos', value: 'cos(', name: 'cosine' },
    { label: 'tan', value: 'tan(', name: 'tangent' },
    { label: '√', value: 'sqrt(', name: 'square root' }
  ],
  [
    { label: 'ln', value: 'ln(', name: 'natural log' },
    { label: 'log', value: 'log(', name: 'log' },
    { label: 'exp', value: 'exp(', name: 'exponential' },
    { label: '^', value: '^', name: 'caret' }
  ],
  [
    { label: 'pi', value: 'PI', name: 'pi' },
    {
      label: 'Rate of Change',
      value: 'rateOfChange([y], [x])',
      name: 'rate of change',
      width: 2
    },
    { label: 'abs', value: 'abs(', name: 'absolute value' }
  ]
]

const TOKENS = BUTTONS.flat()
  .filter(
    button => !['C', 'BS', 'rateOfChange([y], [x])'].includes(button.value)
  )
  .map(button => button.value.trim())
  .sort((a, b) => b.length - a.length)

export default {
  name: 'DataGridColumnFormulaModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  props: {
    gridData: {
      type: Object,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    }
  },
  setup() {
    const [calculatorButtonRefs, setCalculatorButtonRef] = useArrayRef()
    const [variableButtonRefs, setVariableButtonRef] = useArrayRef()
    return {
      calculatorButtonRefs,
      setCalculatorButtonRef,
      variableButtonRefs,
      setVariableButtonRef
    }
  },
  data() {
    return {
      formula: this.gridData.columns[this.columnIndex].formula,
      calculatorButtons: BUTTONS,
      currentCalcCol: 0,
      currentCalcRow: 0,
      currentVariableRow: 0
    }
  },
  computed: {
    helpId() {
      return `${this.name}-help`
    },
    formattedFormula() {
      return this.formula.replace(/\(col(\d+)\)/g, (_, columnIndex) => {
        if (!this.gridData.columns[columnIndex].variable) {
          return columnDisplayName(this.gridData.columns[columnIndex])
        }

        return columnDisplayVariable(this.gridData.columns[columnIndex])
      })
    },
    variables() {
      return this.gridData.columns
        .slice(0, this.columnIndex)
        .reduce((acc, column, columnIndex) => {
          if (!column.allowText) {
            let label = columnDisplayName(column)

            if (column.variable) {
              label += ` (${columnDisplayVariable(column)})`
            }

            acc.push({
              label,
              columnIndex
            })
          }
          return acc
        }, [])
    }
  },
  methods: {
    validateFormula() {
      try {
        // Scope to test formula evaluates to a number has all columns
        // set to a value of 1.
        const scope = {}
        for (let i = 0; i < this.columnIndex; i++) {
          if (i !== this.columnIndex) {
            scope[`col${i}`] = math.bignumber(1)
          }
        }
        scope.rateOfChange = (y, x) => {
          const xIndex = parseInt((/(\d+)/.exec(y) ?? [])[1])
          const yIndex = parseInt((/(\d+)/.exec(x) ?? [])[1])
          return xIndex < this.columnIndex && yIndex < this.columnIndex
            ? math.bignumber(1)
            : NaN
        }
        return (
          typeof math.number(math.evaluate(this.formula, scope)) === 'number' ||
          'The formula is not valid.'
        )
      } catch (e) {
        return 'The formula is not valid.'
      }
    },
    onCalcUpKey() {
      this.moveCalcButtonFocus(0, -1)
    },
    onCalcDownKey() {
      this.moveCalcButtonFocus(0, 1)
    },
    onCalcLeftKey() {
      this.moveCalcButtonFocus(-1, 0)
    },
    onCalcRightKey() {
      this.moveCalcButtonFocus(1, 0)
    },
    onCalcHomeKey() {
      this.calculatorButtonRefs[0].focus()
      this.currentCalcCol = 0
      this.currentCalcRow = 0
    },
    onCalcEndKey() {
      const i = this.calculatorButtonRefs.length - 1
      this.calculatorButtonRefs[i].focus()
      this.currentCalcRow = Math.floor(i / 4)
      this.currentCalcCol = i - this.currentCalcRow * 4
    },
    moveCalcButtonFocus(cols = 0, rows = 0) {
      const newColumn = this.currentCalcCol + cols
      const newRow = this.currentCalcRow + rows
      const i = newRow * 4 + newColumn

      if (this.calculatorButtonRefs[i]) {
        this.calculatorButtonRefs[i].focus()

        this.currentCalcCol = newColumn
        this.currentCalcRow = newRow
      }
    },
    onCalculatorButtonPress(button) {
      if (button === 'C') {
        this.formula = ''

        return
      }
      if (button === 'BS') {
        this.formula = this.formula.trim()

        if (this.formula.match(/\(col\d+\)$/)) {
          this.formula = this.formula.replace(/\(col\d+\)$/, '')
          return
        } else if (
          this.formula.match(
            /rateOfChange\((?:(\[y\])|("\(col\d+\)")), (?:(\[x\])|("\(col\d+\)"))\)$/
          )
        ) {
          const matches = this.formula.match(
            /rateOfChange\((?:(\[y\])|("\(col\d+\)")), (?:(\[x\])|("\(col\d+\)"))\)$/
          )

          this.formula = this.formula.slice(0, -matches[0].length)

          if (matches[4]) {
            this.formula = this.formula + `rateOfChange(${matches[2]}, [x])`
            return
          } else if (matches[2]) {
            this.formula = this.formula + `rateOfChange([y], [x])`
            return
          } else {
            return
          }
        } else {
          TOKENS.every(token => {
            if (this.formula.endsWith(token)) {
              this.formula = this.formula.slice(0, -token.length)
              return false
            } else return true
          })
          return
        }
      }

      this.formula = this.formula + button
    },
    onVarUpKey() {
      this.moveVarButtonFocus(-1)
    },
    onVarDownKey() {
      this.moveVarButtonFocus(1)
    },
    onVarLeftKey() {
      this.moveVarButtonFocus(-1)
    },
    onVarRightKey() {
      this.moveVarButtonFocus(1)
    },
    onVarHomeKey() {
      this.variableButtonRefs[0].focus()
      this.currentVariableRow = 0
    },
    onVarEndKey() {
      const i = this.variableButtonRefs.length - 1
      this.variableButtonRefs[i].focus()
      this.currentVariableRow = i
    },
    moveVarButtonFocus(rows = 0) {
      const newRow = this.currentVariableRow + rows

      if (this.variableButtonRefs[newRow]) {
        this.variableButtonRefs[newRow].focus()
        this.currentVariableRow = newRow
      }
    },
    onVariableButtonPress(columnIndex) {
      const columnText = `(col${columnIndex})`
      const newFormula = this.formula.replace(/(\[\w+\])/, `"${columnText}"`)
      this.formula =
        this.formula === newFormula ? this.formula + columnText : newFormula
    },
    async submit(e) {
      e.done({ formula: this.formula })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-grid-column-formula-modal {
  .buttons-container {
    display: flex;

    button {
      display: flex;
      width: 44px;
      height: 44px;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 700;
      color: $dark-grey;
      border: 1px solid $dark-grey;
      border-radius: 6px;
      background-color: white;

      &.width-2 {
        width: 96px;
      }

      &:hover,
      &:focus {
        background-color: #f0f0f0;
      }

      &:focus {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .calculator {
    margin-right: 8px;

    & > div {
      display: flex;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    button {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .variables {
    flex: 1;
    position: relative;
    border: 1px solid $dark-grey;
    border-radius: 6px;
    background-color: white;
    overflow: scroll;

    .variables-list {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }

    button {
      width: 100%;
      height: 44px;
      justify-content: flex-start;
      border-radius: 0;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
