<template>
  <modal @submit="submit">
    <modal-header>Change Graph Title</modal-header>
    <modal-body>
      <form-group>
        <form-label for="graphTitle">Graph Title</form-label>
        <text-input data-focus id="graphTitle" v-model="graphTitle" />
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-cancel>Cancel</modal-button-cancel>
      <modal-button-submit>Update</modal-button-submit>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import ModalButtonCancel from './components/ModalButtonCancel.vue'

export default {
  name: 'DataGraphUpdateTitleModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      graphTitle: this.title
    }
  },
  methods: {
    async submit(e) {
      e.done({ title: this.graphTitle })
    }
  }
}
</script>
