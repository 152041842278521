<template>
  <li>
    <sidebar-collapse :key="`statusCollapse`">
      <template #header> Status </template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('active')"
              @update:modelValue="value => changeCategory('active', value)"
              @click.stop
            >
              Active
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('expiring')"
              @update:modelValue="value => changeCategory('expiring', value)"
              @click.stop
            >
              Expiring
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('inactive')"
              @update:modelValue="value => changeCategory('inactive', value)"
              @click.stop
            >
              Inactive
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('archived')"
              @update:modelValue="value => changeCategory('archived', value)"
              @click.stop
            >
              Archived
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'StatusFilter',
  inject: ['browseLicenses'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  data() {
    return {
      status: []
    }
  },
  created() {
    this.status = [...this.browseLicenses.query.status] || []
  },
  methods: {
    isChecked(t) {
      return this.status.includes(t)
    },
    changeCategory(category, isChecked) {
      if (!isChecked) {
        this.status = [...this.status].filter(t => t !== category)
      } else {
        this.status.push(category)
      }
      if (this.status.length) {
        return this.browseLicenses.updateQuery({
          page: 1,
          status: [...this.status]
        })
      }
      return this.browseLicenses.updateQuery({
        page: 1,
        status: undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
