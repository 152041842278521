<template>
  <button-dropdown
    tabindex="-1"
    unstyled
    left
    offset="{0}"
    :title="label"
    class="table-menu-button"
    :class="{
      'table-menu-button--active': active
    }"
  >
    <template #button>
      <Icon :icon="icon" />
    </template>
    <dropdown-action
      @click="
        editor
          .chain()
          .focus()
          .insertTable({ rows: 2, cols: 2, withHeaderRow: false })
          .run()
      "
    >
      <span class="table-dropdown__table-op-button"> Insert Table </span>
    </dropdown-action>

    <li>
      <collapse-provider collapsed renderless>
        <collapse-toggle
          :disabled="rowsDisabled"
          class="table-dropdown__collapse-toggle"
        >
          <template #default>
            <div class="table-dropdown__collapse-toggle-div">
              <span class="table-dropdown__collapse-toggle-name">Rows</span>
              <collapse-icon
                class="table-dropdown__collapse-toggle-icon"
                icon="caret"
              />
            </div>
          </template>
        </collapse-toggle>
        <collapse-content>
          <ul class="table-dropdown__sublist">
            <dropdown-action
              @click="editor.chain().focus().addRowBefore().run()"
              :disabled="!editor.can().addRowBefore()"
            >
              Insert Row Before
            </dropdown-action>
            <dropdown-action
              @click="editor.chain().focus().addRowAfter().run()"
              :disabled="!editor.can().addRowAfter()"
            >
              Insert Row After
            </dropdown-action>
            <dropdown-action
              @click="editor.chain().focus().deleteRow().run()"
              :disabled="!editor.can().deleteRow()"
            >
              <span class="table-dropdown__delete-button"> Delete Row </span>
            </dropdown-action>
          </ul>
        </collapse-content>
      </collapse-provider>
    </li>

    <li>
      <collapse-provider collapsed renderless>
        <collapse-toggle
          :disabled="columnsDisabled"
          class="table-dropdown__collapse-toggle"
        >
          <template #default>
            <div class="table-dropdown__collapse-toggle-div">
              <span class="table-dropdown__collapse-toggle-name">Columns</span>
              <collapse-icon
                class="table-dropdown__collapse-toggle-icon"
                icon="caret"
              />
            </div>
          </template>
        </collapse-toggle>
        <collapse-content>
          <ul class="table-dropdown__sublist">
            <dropdown-action
              @click="editor.chain().focus().addColumnBefore().run()"
              :disabled="!editor.can().addColumnBefore()"
            >
              Insert Column Left
            </dropdown-action>
            <dropdown-action
              @click="editor.chain().focus().addColumnAfter().run()"
              :disabled="!editor.can().addColumnAfter()"
            >
              Insert Column Right
            </dropdown-action>
            <dropdown-action
              @click="editor.chain().focus().deleteColumn().run()"
              :disabled="!editor.can().deleteColumn()"
            >
              <span class="table-dropdown__delete-button"> Delete Column </span>
            </dropdown-action>
          </ul>
        </collapse-content>
      </collapse-provider>
    </li>
    <li>
      <collapse-provider collapsed renderless>
        <collapse-toggle
          :disabled="headersDisabled"
          class="table-dropdown__collapse-toggle"
        >
          <template #default>
            <div class="table-dropdown__collapse-toggle-div">
              <span class="table-dropdown__collapse-toggle-name">Headers</span>
              <collapse-icon
                class="table-dropdown__collapse-toggle-icon"
                icon="caret"
              />
            </div>
          </template>
        </collapse-toggle>
        <collapse-content>
          <ul class="table-dropdown__sublist">
            <dropdown-action
              @click="editor.chain().focus().toggleHeaderColumn().run()"
              :disabled="!editor.can().toggleHeaderColumn()"
            >
              Toggle Header Column
            </dropdown-action>
            <dropdown-action
              @click="editor.chain().focus().toggleHeaderRow().run()"
              :disabled="!editor.can().toggleHeaderRow()"
            >
              Toggle Header Row
            </dropdown-action>
            <dropdown-action
              @click="editor.chain().focus().toggleHeaderCell().run()"
              :disabled="!editor.can().toggleHeaderCell()"
            >
              Toggle Header Cell
            </dropdown-action>
          </ul>
        </collapse-content>
      </collapse-provider>
    </li>
    <li>
      <collapse-provider collapsed renderless>
        <collapse-toggle
          :disabled="cellsDisabled"
          class="table-dropdown__collapse-toggle"
        >
          <template #default>
            <div class="table-dropdown__collapse-toggle-div">
              <span class="table-dropdown__collapse-toggle-name">Cells</span>
              <collapse-icon
                class="table-dropdown__collapse-toggle-icon"
                icon="caret"
              />
            </div>
          </template>
        </collapse-toggle>
        <collapse-content>
          <ul class="table-dropdown__sublist">
            <dropdown-action
              @click="editor.chain().focus().mergeCells().run()"
              :disabled="!editor.can().mergeCells()"
            >
              Merge Cells
            </dropdown-action>
            <dropdown-action
              @click="editor.chain().focus().splitCell().run()"
              :disabled="!editor.can().splitCell()"
            >
              Split Cell
            </dropdown-action>
          </ul>
        </collapse-content>
      </collapse-provider>
    </li>
    <dropdown-action
      @click="editor.chain().focus().deleteTable().run()"
      :disabled="!editor.can().deleteTable()"
      class="table-dropdown__delete-action"
    >
      <span
        class="table-dropdown__delete-button table-dropdown__table-op-button"
      >
        Delete Table
      </span>
    </dropdown-action>
  </button-dropdown>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  },
  active: {
    type: Boolean,
    default: false
  },
  tag: {
    type: String,
    default: 'button'
  },
  editor: {
    type: Object,
    required: true
  }
})

const rowsDisabled = computed(() => {
  return (
    !props.editor.can().addRowBefore() &&
    !props.editor.can().addRowAfter() &&
    !props.editor.can().deleteRow()
  )
})
const columnsDisabled = computed(() => {
  return (
    !props.editor.can().addColumnBefore() &&
    !props.editor.can().addColumnAfter() &&
    !props.editor.can().deleteColumn()
  )
})
const headersDisabled = computed(() => {
  return (
    !props.editor.can().toggleHeaderColumn() &&
    !props.editor.can().toggleHeaderRow() &&
    !props.editor.can().toggleHeaderCell()
  )
})
const cellsDisabled = computed(() => {
  return !props.editor.can().mergeCells() && !props.editor.can().splitCell()
})
</script>

<style lang="scss">
button.table-menu-button {
  border: 0px;
  background: transparent;
  width: 34px;
  height: 34px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: inherit;

  &:hover,
  &:focus {
    background: #dadada;
    color: inherit;
  }

  &:disabled {
    background: #e8e8e8;
    color: #777777;
  }

  &--active {
    background: $light-teal;
  }
}
</style>

<style lang="scss" scoped>
.table-dropdown__collapse-toggle {
  text-decoration: none;
  width: 100%;
  &:hover,
  &:focus {
    color: #262626;
    background-color: #f5f5f5;
    outline: none;
    text-decoration: none !important;
    :deep() {
      & span.table-dropdown__collapse-toggle-name {
        text-decoration: 1px solid underline;
      }
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: none;
    color: $silver;
  }
}
.table-dropdown__collapse-toggle-name {
  margin-left: 20px;
  font-weight: 700;
}
.table-dropdown__collapse-toggle-icon {
  text-decoration: none;
  margin-right: 10px;
}

.table-dropdown__collapse-toggle-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3px 0px;
}

.table-dropdown__sublist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #bbbbbb;

  li {
    font-size: 13px;
    font-weight: 400;
  }
}
.table-dropdown__table-op-button {
  font-weight: 700;
}
.table-dropdown__delete-button {
  color: $color-warning;
}

.table-dropdown__delete-action {
  :deep() {
    & button:disabled span {
      color: $silver;
    }
  }
}
</style>
