<template>
  <modal>
    <modal-header>Confirm</modal-header>
    <modal-body>
      <div v-if="html" v-html="sanitizedHtml" />
      <p>{{ text }}</p>
      <p class="prompt">
        <strong>{{ prompt }}</strong>
      </p>
    </modal-body>
    <modal-actions>
      <modal-button-ok>Yes</modal-button-ok>
      <modal-button-cancel>No</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonOk,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import DOMPurify from 'dompurify'
export default {
  name: 'ConfirmModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonOk,
    ModalButtonCancel
  },
  props: {
    html: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    prompt: {
      type: String,
      default: 'Are you sure?'
    }
  },
  computed: {
    sanitizedHtml() {
      return this.html && this.html !== '' ? DOMPurify.sanitize(this.html) : ''
    }
  }
}
</script>

<style scoped>
p.prompt {
  font-weight: bold;
  margin-bottom: 0;
}
</style>
