<template>
  <data-grid-with-graph
    :settings="settings"
    v-if="graph"
    v-bind="childProps"
    :edit-mode="editMode"
    @reset="$emit('reset')"
    :graphInstructions="graphInstructions"
    :variable-context="variableContext"
    :graphHint="graphHint"
    :graphHintPrompt="graphHintPrompt"
    :hideGraphHint="hideGraphHint"
    :view-as-student="viewAsStudent"
  />
  <data-grid
    :variable-context="variableContext"
    :settings="settings"
    :edit-mode="editMode"
    v-else
    v-bind="childProps"
    @reset="$emit('reset')"
  />
</template>

<script>
import * as Y from 'yjs'
import throttle from 'lodash/throttle'
import DataGrid from './DataGrid'
import DataGridWithGraph from './DataGridWithGraph'

export default {
  name: 'DataGridPojoAdapter',
  components: { DataGrid, DataGridWithGraph },
  emits: ['reset', 'update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    graph: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    componentId: {
      type: String
    },
    defaultValue: {
      type: Object,
      required: false
    },
    settings: {
      type: Object,
      required: true
    },
    graphInstructions: {
      type: Object,
      default: undefined,
      required: false
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    },
    graphHint: {
      type: String,
      default: undefined,
      required: false
    },
    graphHintPrompt: {
      type: String,
      default: undefined,
      required: false
    },
    hideGraphHint: {
      type: Boolean,
      default: false,
      required: false
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ydoc: new Y.Doc(),
      ymap: null,
      syncing: false
    }
  },
  mounted() {
    const onChange = throttle(() => {
      this.$emit('update:modelValue', this.ydoc.getMap('value').toJSON())
    })
    this.ydoc.on('update', onChange)
    this.unsubscribe = () => this.ydoc.off('update', onChange)
  },
  beforeUnmount() {
    this.unsubscribe?.()
  },
  computed: {
    childProps() {
      return {
        ...this.$attrs,
        ymap: this.ymap
      }
    }
  },
  methods: {
    buildYMap() {
      let map
      this.ydoc.transact(() => {
        map = this.ydoc.getMap('value')

        const firstColumnSize =
          this.getWithDefault('columns', [])[0]?.data?.length ?? 2
        const numRows = Math.max(
          this.getWithDefault('rows', 0),
          Math.max(1, firstColumnSize)
        )

        this.setIfChanged(map, 'rows', numRows)
        this.setIfChanged(map, 'format', 5)

        if (!map.get('columns')) {
          map.set('columns', new Y.Array())
        }
        const columns = map.get('columns')

        if (columns.length > this.modelValue.columns.length) {
          columns.delete(
            this.modelValue.columns.length,
            columns.length - this.modelValue.columns.length
          )
        }

        for (const [index, column] of this.getWithDefault(
          'columns',
          []
        ).entries()) {
          if (columns.length < index + 1) {
            columns.push([new Y.Map()])
          }
          const c = columns.get(index)

          this.setIfChanged(c, 'name', column.name)
          this.setIfChanged(c, 'units', column.units)
          this.setIfChanged(c, 'variable', column.variable)
          this.setIfChanged(c, 'formula', column.formula)
          this.setIfChanged(c, 'format', column.format)
          this.setIfChanged(c, 'decimalPlaces', column.decimalPlaces)
          this.setIfChanged(c, 'significantFigures', column.significantFigures)
          this.setIfChanged(c, 'allowText', column.allowText)
          this.setIfChanged(c, 'width', column.width)
          this.setIfChanged(c, 'deviceId', column.deviceId)
          this.setIfChanged(c, 'dataStreamId', column.dataStreamId)

          const oldData = column.data.map(val => val ?? '')
          const newData = new Array(numRows).fill('')
          newData.splice(0, oldData.length, ...oldData)
          if (
            JSON.stringify(c.get('data')?.toJSON()) !== JSON.stringify(newData)
          ) {
            c.set('data', Y.Array.from(newData))
          }

          const newCalculations = [...(column.calculations || [])].map(val => {
            if (typeof val === 'string') {
              return { type: val, precision: 3, format: 'sigfigs' }
            } else {
              return {
                type: val.type,
                precision: val.precision,
                format: val.format
              }
            }
          })

          if (
            JSON.stringify(c.get('calculations')?.toJSON()) !==
            JSON.stringify(newCalculations)
          ) {
            c.set(
              'calculations',
              Y.Array.from(
                newCalculations.map(calc => new Y.Map(Object.entries(calc)))
              )
            )
          }
        }

        const bluetooth = this.getOrCreateNestedType(map, 'bluetooth', Y.Map)
        this.setIfChanged(
          bluetooth,
          'sampleRate',
          this.getWithDefault(val => val?.bluetooth?.sampleRate, 1)
        )
        this.setIfChanged(
          bluetooth,
          'maxSamples',
          this.getWithDefault(val => val?.bluetooth?.maxSamples, 25)
        )

        // multiple graph setup
        if (!map.get('graphs')) {
          map.set('graphs', new Y.Array())
        }

        const graphs = map.get('graphs')

        for (const [index, graph] of this.getWithDefault(
          'graphs',
          []
        ).entries()) {
          if (graphs.length < index + 1) {
            graphs.push([new Y.Map()])
          }

          // g is the yDoc graph
          const g = graphs.get(index)

          this.setIfChanged(g, 'title', graph.title)
          this.setIfChanged(g, 'type', graph.type)
          this.setIfChanged(
            g,
            'curveFitUncertainties',
            graph.curveFitUncertainties
          )
          this.setIfChanged(g, 'includeOrigin', graph.includeOrigin)

          this.setIfChanged(g, 'hAxisTitle', graph.hAxisTitle ?? '')
          this.setIfChanged(g, 'vAxisTitle', graph.vAxisTitle ?? '')

          const oldXAxisRange = g.get('xAxisRange') ?? []
          const newXAxisRange = graph.xAxisRange ?? [null, null]
          if (
            oldXAxisRange[0] !== newXAxisRange[0] ||
            oldXAxisRange[1] !== newXAxisRange[1]
          ) {
            g.set('xAxisRange', newXAxisRange)
          }
          const oldYAxisRange = g.get('yAxisRange') ?? []
          const newYAxisRange = graph.yAxisRange ?? [null, null]
          if (
            oldYAxisRange[0] !== newYAxisRange[0] ||
            oldYAxisRange[1] !== newYAxisRange[1]
          ) {
            g.set('yAxisRange', newYAxisRange)
          }

          this.setIfChanged(g, 'showLegend', !!graph.showLegend)

          const oldIgnoredRows = g.get('curveFitIgnoredRows')
          const newIgnoredRows = graph.curveFitIgnoredRows ?? []

          if (
            !oldIgnoredRows ||
            oldIgnoredRows.length !== newIgnoredRows.length ||
            !oldIgnoredRows.every((val, i) => val === newIgnoredRows[i])
          ) {
            g.set('curveFitIgnoredRows', newIgnoredRows)
          }

          const hColumns = this.getOrCreateNestedType(g, 'hColumns', Y.Map)
          this.setIfChanged(
            hColumns,
            'data',
            this.getWithDefault(val => val?.graphs[index].hColumns?.data, -1)
          )

          this.setIfChanged(
            hColumns,
            'uncertainty',
            this.getWithDefault(
              val => val?.graphs[index].hColumns?.uncertainty,
              -1
            )
          )

          const newVColumns = this.getWithDefault(
            val => val?.graphs[index].vColumns,
            []
          )
          const vColumns = this.getOrCreateNestedType(g, 'vColumns', Y.Array)
          if (
            JSON.stringify(vColumns.toJSON()) !== JSON.stringify(newVColumns)
          ) {
            g.set(
              'vColumns',
              Y.Array.from(
                newVColumns.map(vColumn => {
                  const c = new Y.Map()
                  c.set('data', vColumn?.data ?? -1)
                  c.set('uncertainty', vColumn?.uncertainty ?? -1)
                  c.set('curve', vColumn?.curve ?? 'none')
                  return c
                })
              )
            )
          }
        }
      })

      return map
    },
    setIfChanged(ymap, key, value) {
      if (ymap.get(key) === value) {
        return
      }
      ymap.set(key, value)
    },
    getOrCreateNestedType(parent, key, YAbstractType = Y.Map) {
      if (parent.get(key) instanceof YAbstractType) {
        return parent.get(key)
      }
      parent.set(key, new YAbstractType())
      return parent.get(key)
    },
    getWithDefault(getter, fallback) {
      if (typeof getter === 'function') {
        return getter(this.modelValue) ?? getter(this.defaultValue) ?? fallback
      }

      return (
        this.modelValue?.[getter] ?? this.defaultValue?.[getter] ?? fallback
      )
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.ymap = this.buildYMap()
      },
      immediate: true
    }
  }
}
</script>
