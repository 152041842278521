<template>
  <form-button class="form-button--secondary" @click="onClick">
    <slot>Cancel</slot>
  </form-button>
</template>

<script>
export default {
  inject: ['$_modal'],
  name: 'ModalButtonCancel',
  emits: ['click'],
  methods: {
    onClick() {
      this.$_modal.close()
      this.$emit('click')
    }
  }
}
</script>
