<template>
  <div v-bind="wrapperAttrs" :class="errorMessage ? 'has-error' : ''">
    <textarea
      v-bind="inputAttrs"
      ref="input"
      :value="inputValue"
      class="pi-form-control form-control multiline-text-input__input"
      :class="{
        'resizable-horizontal': resizableHorizontal,
        'resizable-vertical': resizableVertical,
        'resizable-both': resizableBoth
      }"
      :aria-describedby="`${helpId} ${ariaDescribedby}`"
      @focus="onFocus"
      @input="onInput"
      @change="onChange"
      @blur="handleBlur"
    />
    <span v-if="errorMessage || helpText" :id="helpId" class="help-block">
      {{ errorMessage || helpText }}
    </span>
  </div>
  <div />
</template>

<script>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
let counter = 0

export default {
  name: 'MultilineTextInput',
  inheritAttrs: false,
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'field'
    },
    name: {
      type: String,
      // Names must be unique between different inputs.
      default: () => `multiline-text-input-${counter++}`
    },
    helpText: {
      type: String,
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: ''
    },
    rules: {
      type: [String, Object],
      default: null
    },
    resizableVertical: {
      type: Boolean,
      default: false
    },
    resizableHorizontal: {
      type: Boolean,
      default: false
    },
    resizableBoth: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: props.modelValue,
      validateOnValueUpdate: false,
      syncVModel: false
    })

    return {
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField
    }
  },
  computed: {
    helpId() {
      return `${this.name}-help`
    },
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    inputAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    onInput(e) {
      this.handleChange(e.target.value, !!this.errorMessage)
      this.$emit('update:modelValue', e.target.value)
    },
    onChange(e) {
      this.$emit('change', e.target.value)
    },
    applyError(message) {
      this.setValidationState({ errors: [message] })
    },
    select(...args) {
      return this.$refs.input.select(...args)
    },
    onFocus() {
      this.$refs.input.select()
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue !== this.inputValue) {
        this.resetField({ value: this.modelValue })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multiline-text-input__input {
  height: 100%;
}

.resizable-horizontal {
  resize: horizontal;
}
.resizable-vertical {
  resize: vertical;
}

.resizable-both {
  resize: both;
}
</style>
