<template>
  <modal @submit="register" style="width: 500px">
    <modal-header>
      Other LTI Registration
      <hyper-link :to="{ name: `lti_other_collection` }">
        <icon icon="question-circle" />
        <span class="sr-only">{{ platform }} Help</span>
      </hyper-link>
    </modal-header>
    <modal-body>
      <async-form @submit="register" persist>
        <form-group>
          <form-label for="platform-name">Name</form-label>
          <text-input
            id="platform-name"
            v-model="platformData.name"
            label="platform name"
            rules="required"
          />
        </form-group>
        <form-group>
          <form-label for="platform-issuer">Issuer</form-label>
          <text-input
            id="platform-issuer"
            v-model="platformData.issuer"
            label="platform issuer"
            :rules="{
              required: true,
              regex: /^https:\/\/((\w|\d|-)+\.)+(\w|\d|-)+(:\d+)?$/
            }"
            placeholder="Example: https://platform.com"
          />
        </form-group>
        <form-group>
          <form-label for="platform-clientid">Client ID</form-label>
          <text-input
            id="platform-clientid"
            v-model="platformData.clientId"
            label="platform client ID"
            rules="required"
            placeholder="Example: 100013"
          />
        </form-group>
        <form-group>
          <form-label for="platform-deploymentid">Deployment ID</form-label>
          <text-input
            id="platform-deploymentid"
            v-model="platformData.deploymentId"
            label="platform deployment ID"
            rules="required"
            placeholder="Example: 100013"
          />
        </form-group>
        <form-group>
          <form-label for="platform-oidc-url">
            OpenID Connect Authorization URL
          </form-label>
          <text-input
            id="platform-oidc-url"
            v-model="platformData.oidcUrl"
            label="platform OpenID Connect URL"
            :rules="{
              required: true,
              url: {
                require_protocol: true
              }
            }"
            placeholder="Example: https://platform.com/lti/authorize_redirect"
          />
        </form-group>
        <form-group>
          <form-label for="platform-oauth-url"> OAuth Token URL </form-label>
          <text-input
            id="platform-oauth-url"
            v-model="platformData.oauthUrl"
            label="platform OAuth token URL"
            :rules="{
              url: {
                require_protocol: true
              }
            }"
            placeholder="Example: https://platform.com/lti/oauth2/token"
          />
        </form-group>
        <form-group>
          <form-label for="platform-oauth-aud"> OAuth Audience </form-label>
          <text-input
            id="platform-oauth-aud"
            v-model="platformData.oauthAud"
            label="platform OAuth audience"
            :rules="{
              url: {
                require_protocol: true
              }
            }"
            placeholder="Example: https://platform.com/lti/oauth2/token"
            help-text="Optional - Your tool may not specify an audience"
          />
        </form-group>
        <form-group>
          <form-label id="platform-key-type-label">Key Type</form-label>
          <selector-input
            v-model="platformData.keyType"
            aria-labelledby="platform-key-type-label"
            rules="required"
            label="platform key type"
            class="selector-input--horizontal"
          >
            <selector-option
              value="publicKey"
              :title="keyLabels['publicKey']"
            />
            <selector-option value="jwk" :title="keyLabels['jwk']" />
            <selector-option value="jwkUrl" :title="keyLabels['jwkUrl']" />
          </selector-input>
        </form-group>
        <form-group>
          <form-label :for="`platform-${platformData.keyType}`">
            {{ keyLabels[platformData.keyType] }}
          </form-label>
          <text-input
            v-if="platformData.keyType === 'jwkUrl'"
            id="platform-jwkUrl"
            v-model="platformData.jwkUrl"
            label="JWK URL"
            :rules="{
              required: true,
              url: { require_protocol: true }
            }"
            placeholder="Example: https://platform.com/lti/jwks"
          />
          <multiline-text-input
            v-else-if="platformData.keyType === 'jwk'"
            id="platform-jwk"
            v-model="platformData.jwk"
            label="JWK"
            rules="required"
            rows="6"
            wrap="off"
            :placeholder="exampleJwk"
            @blur="formatJwk"
          />
          <multiline-text-input
            v-else-if="platformData.keyType === 'publicKey'"
            id="platform-publicKey"
            v-model="platformData.publicKey"
            label="public key"
            rules="required"
            rows="5"
            wrap="off"
            :placeholder="examplePublicKey"
            @blur="formatPublicKey"
          />
        </form-group>
      </async-form>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Register</modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import client from 'src/shared/api-client'
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

const KEY_LABELS = {
  publicKey: 'Public Key',
  jwk: 'JWK',
  jwkUrl: 'JWK URL'
}

export default {
  name: 'LtiPlatformRegistrationOtherView',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonCancel,
    ModalButtonSubmit
  },
  props: {
    licenseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      keyLabels: KEY_LABELS,
      config: {},
      loadingConfig: true,
      platformData: {
        name: '',
        issuer: '',
        deploymentId: '',
        clientId: '',
        oidcUrl: '',
        oauthUrl: '',
        keyType: 'publicKey',
        jwkUrl: '',
        jwk: '',
        publicKey: ''
      }
    }
  },
  computed: {
    examplePublicKey() {
      return `Example:
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDNzEIs2zwRR9TjuHNpofGGGQUJ
  0wCzME7yST7BUNUxRk8UZBJzia/E0akOv8qwfFswCYVMjgoTRtgh4UUCHstIsAWi
  GEhEj3C4mohEidmSaXAo1WibDVrrx8tuMBDEvE4dAZuj7ixuaTNo0c0B6O9WoUnw
  DKgfW0F8tBg23jxrTwIDAQAB`
    },
    exampleJwk() {
      return `Example: {
    "kty": "RSA",
    "e": "AQAB",
    "kid": "b6a3e1b7-4610-4b78-b0ff-12ab43b9ea89",
    "n": "zcxCLNs8EUfU47hzaaHxhhkFCdMAszBO8kk-wVDVMUZPFGQSc4mvxNGpDr_KsHxbMAmFTI4KE0bYIeFFAh7LSLAFohhIRI9wuJqIRInZkmlwKNVomw1a68fLbjAQxLxOHQGbo-4sbmkzaNHNAejvVqFJ8AyoH1tBfLQYNt48a08"
  }`
    }
  },
  methods: {
    formatJwk() {
      try {
        this.platformData.jwk = JSON.stringify(
          JSON.parse(this.platformData.jwk),
          null,
          2
        )
      } catch {}
    },
    formatPublicKey() {
      try {
        this.platformData.publicKey = this.platformData.publicKey.match(
          /-+BEGIN.+KEY-+\n(.+)\n-+END.+KEY-+/s
        )[1]
      } catch {}
    },
    async register(e) {
      try {
        const {
          name,
          issuer,
          clientId,
          deploymentId,
          oidcUrl,
          oauthUrl,
          oauthAud,
          keyType
        } = this.platformData
        await client.lti.connectLtiToLicense({
          type: 'Custom',
          licenseId: this.licenseId,
          name,
          issuer,
          clientId,
          deploymentId,
          oidcUrl,
          oauthUrl,
          oauthAud: oauthAud || oauthUrl,
          [keyType]: this.platformData[keyType]
        })
        this.$success(`${name} successfully configured in Pivot.`)
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    }
  }
}
</script>
