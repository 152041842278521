<template>
  <div>
    <sidebar-header>Filter</sidebar-header>
    <sidebar-list>
      <slot />
    </sidebar-list>
  </div>
</template>

<script>
import SidebarHeader from 'src/shared/components/SidebarHeader'
import SidebarList from 'src/shared/components/SidebarList'

export default {
  name: 'SidebarFilter',
  components: {
    SidebarHeader,
    SidebarList
  }
}
</script>

<style></style>
