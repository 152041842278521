<template>
  <collapse-provider
    v-if="panelObject"
    v-slot="{ collapsed }"
    class="dragable-panel"
    data-testid="dragable-panel"
    :collapsed="panelObject.collapsed"
    role="region"
    :aria-label="ariaLabel"
    @change="panelAction('toggle-collapse')"
  >
    <div>
      <div :class="`${dragHandleClass} drag-handle`">
        <icon icon="grip" />
        <span class="sr-only">Actions</span>
      </div>
      <div class="panel-header" @dragenter="componentDragging">
        <div class="panel-text">
          <h4
            class="panel-heading-title"
            :class="{
              'section-title-error text-warning-orange-600': highlightError
            }"
          >
            <collapse-toggle
              class="panel-title"
              :class="{ 'panel-title__collapsed': collapsed }"
            >
              <collapse-icon class="title-icon" />
              <icon
                v-if="highlightError"
                icon="triangle-exclamation"
                class="error-icon text-warning-orange-600"
              />
              <span>
                <slot name="title" />
              </span>
            </collapse-toggle>
          </h4>
          <span v-if="collapsed" class="collapsed-header">
            <slot name="collapsedHeader" :collapsed="collapsed"></slot>
          </span>
        </div>
        <div style="flex: 1" />
        <div class="moveable-panels-buttons">
          <icon-button
            :label="labelsTitle.up"
            icon="chevron-up"
            :disabled="first"
            :color="first ? 'move-disabled' : 'move-active'"
            @click.stop="movePanel('up')"
            data-queryid="move-up"
          />
          <icon-button
            :label="labelsTitle.down"
            icon="chevron-down"
            :disabled="last"
            :color="last ? 'move-disabled' : 'move-active'"
            @click.stop="movePanel('down')"
            data-queryid="move-down"
          />
          <span v-show="!collapsed">
            <icon-button
              v-if="labelsTitle.duplicate"
              :label="labelsTitle.duplicate"
              icon="copy"
              color="copy-icon"
              @click.stop="panelAction('duplicate')"
            />
            <icon-button
              v-if="labelsTitle.clipboard"
              :label="labelsTitle.clipboard"
              icon="clipboard"
              color="copy-icon"
              @click.stop="panelAction('clipboard')"
            />
            <icon-button
              :label="labelsTitle.delete"
              icon="trash"
              color="delete-icon"
              @click.stop="panelAction('delete')"
            />
            <icon-button
              v-if="labelsTitle.copyLink"
              :label="labelsTitle.copyLink"
              icon="link"
              color="add-icon"
              @click.stop="panelAction('copyLinkToClipboard')"
            />
            <icon-button
              v-if="labelsTitle.preview"
              :label="labelsTitle.preview"
              icon="magnifying-glass"
              color="add-icon"
              @click.stop="panelAction('previewComponent')"
            />
            <icon-button
              :label="labelsTitle.add"
              icon="plus"
              color="add-icon"
              @click.stop="panelAction('add')"
            />
          </span>
          <span v-show="collapsed">
            <button-dropdown
              class="action-menu"
              button-class="button-dropdown__button"
              right
            >
              <template #button>
                <icon icon="ellipsis-v" />
                <span class="sr-only">Actions</span>
              </template>
              <dropdown-action @click="panelAction('duplicate')">
                {{ labelsTitle.duplicate }}
              </dropdown-action>
              <dropdown-action @click="panelAction('delete')">
                {{ labelsTitle.delete }}
              </dropdown-action>
              <dropdown-action
                v-if="labelsTitle.clipboard"
                @click="panelAction('clipboard')"
              >
                {{ labelsTitle.clipboard }}
              </dropdown-action>
              <dropdown-action @click="panelAction('copyLinkToClipboard')">
                {{ labelsTitle.copyLink }}
              </dropdown-action>
              <dropdown-action @click="panelAction('previewComponent')">
                {{ labelsTitle.preview }}
              </dropdown-action>
              <dropdown-action @click="panelAction('add')">
                {{ labelsTitle.add }}
              </dropdown-action>
            </button-dropdown>
          </span>
        </div>
      </div>
    </div>
    <collapse-content>
      <div class="panel-body">
        <slot name="panel"></slot>
      </div>
    </collapse-content>
  </collapse-provider>
</template>

<script>
export default {
  name: 'DragPanel',
  emits: ['componentDrag', 'movePanel', 'panelAction'],
  props: {
    panelObject: {
      type: Object,
      required: true
    },
    first: {
      type: Boolean,
      required: true
    },
    last: {
      type: Boolean,
      required: true
    },
    labelsTitle: {
      type: Object,
      required: true
    },
    dragHandleClass: {
      type: String,
      default: 'section-drag-handle'
    },
    highlightError: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showText: false
    }
  },
  methods: {
    componentDragging(e) {
      this.$emit('componentDrag', e)
    },
    movePanel(action) {
      this.$emit('movePanel', { action: action, panel: this.panelObject })
    },
    panelAction(action) {
      this.$emit('panelAction', { action: action, panel: this.panelObject })
    },
    showDropDown() {
      this.showText = !this.showText
    },
    hideDropDownBox() {
      this.showText = false
    },
    closeOnOutSideClick(event) {
      const target = event.target.dataset.target
      if (target !== 'dropdown-action' && target !== 'drop-action') {
        this.hideDropDownBox()
      }
    }
  },
  created() {
    window.addEventListener('click', this.closeOnOutSideClick)
    if (this.panelObject.componentType === 'SplitView') {
      // eslint-disable-next-line vue/no-mutating-props
      this.panelObject.collapsed = false
    }
  },
  unmounted() {
    window.removeEventListener('click', this.closeOnOutSideClick)
  }
}
</script>

<style lang="scss" scoped>
.title-icon {
  margin-right: 4px;
}

.section-drop-down {
  position: absolute;
  top: 100%;
  right: 0;
  background: padding-box white;
  z-index: 10000;
  border: 1px solid #cccccc;
  min-width: 170px;
  font-size: 14px;
  list-style: none;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  padding: 0;

  & li {
    padding: 3px 5px;

    &:hover {
      background-color: #ccc;
    }
  }
}
.dragable-panel {
  padding-bottom: 22px;
  background-color: #fff;

  & .panel-header {
    padding: 0 6px;
    position: relative;
    display: flex;
    margin-bottom: 0;
    margin-top: 0;

    & .panel-heading-title {
      display: flex;

      & .title-icon {
        width: 18px;
        margin-right: 8px;
      }

      & .panel-title {
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
      }

      & .panel-title:hover {
        color: $teal;
        text-decoration: underline;
      }
    }
  }

  & .moveable-panels-buttons {
    z-index: 1;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;

    & span {
      display: flex;
      flex-direction: column;
    }
  }
}

.drag-handle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $teal;
  cursor: move;

  & span {
    line-height: 6px;
  }
}

.section-title-error {
  & .error-icon {
    font-size: 16px;
    margin-right: 5px;
  }
}

:deep(.button-dropdown__button) {
  all: revert;
  color: #000;
  background: none;
  border: none;
}
</style>
