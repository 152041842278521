<template>
  <div v-bind="wrapperAttrs" :class="errorMessage ? 'has-error' : ''">
    <select
      v-bind="inputAttrs"
      v-model="internalModelValue"
      class="pi-form-control form-control"
      :aria-describedby="`${helpId} ${ariaDescribedby}`"
      :name="name"
      @blur="handleBlur"
    >
      <slot />
    </select>
    <span v-if="errorMessage || helpText" :id="helpId" class="help-block">
      {{ errorMessage || helpText }}
    </span>
  </div>
</template>

<script>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
let counter = 0

export default {
  name: 'SelectField',
  inheritAttrs: false,
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: {},
    label: {
      name: String,
      default: 'field'
    },
    name: {
      type: String,
      // Names must be unique between different inputs.
      default: () => `select-field-${counter++}`
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    helpText: {
      type: String,
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: props.modelValue,
      validateOnValueUpdate: false,
      syncVModel: false
    })

    return {
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField
    }
  },
  computed: {
    helpId() {
      return `${this.name}-help`
    },
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    inputAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    },
    internalModelValue: {
      get() {
        return this.inputValue
      },
      set(value) {
        this.handleChange(value)
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue !== this.inputValue) {
        this.resetField({ value: this.modelValue })
      }
    }
  }
}
</script>
