import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'

import AsyncForm from 'src/shared/components/global/AsyncForm'
import BasePopover from 'src/shared/components/global/BasePopover'
import Breadcrumb from 'src/shared/components/global/Breadcrumb'
import BreadcrumbItem from 'src/shared/components/global/BreadcrumbItem'
import ButtonDropdown from 'src/shared/components/global/ButtonDropdown'
import ButtonLink from 'src/shared/components/global/ButtonLink'
import Card from 'src/shared/components/global/Card'
import CardSecondary from 'src/shared/components/global/CardSecondary'
import CardTitle from 'src/shared/components/global/CardTitle'
import Checkbox from 'src/shared/components/global/Checkbox'
import Chip from 'src/shared/components/global/Chip'
import ChoiceInput from 'src/shared/components/global/ChoiceInput'
import CollapseContent from 'src/shared/components/global/CollapseContent'
import CollapseIcon from 'src/shared/components/global/CollapseIcon'
import CollapsePanel from 'src/shared/components/global/CollapsePanel'
import CollapseProvider from 'src/shared/components/global/CollapseProvider'
import CollapseToggle from 'src/shared/components/global/CollapseToggle'
import DateInput from 'src/shared/components/global/DateInput'
import DoubleSlider from 'src/shared/components/global/DoubleSlider'
import DropdownAction from 'src/shared/components/global/DropdownAction'
import DropdownCopy from 'src/shared/components/global/DropdownCopy'
import DropdownLink from 'src/shared/components/global/DropdownLink'
import Editor from 'src/shared/components/editor/Editor'
import EditorContent from 'src/shared/components/editor/EditorContent'
import EmbeddedIFrame from 'src/shared/components/global/EmbeddedIFrame'
import FilePickerButton from 'src/shared/components/global/FilePickerButton'
import FormButton from 'src/shared/components/global/FormButton'
import FormGroup from 'src/shared/components/global/FormGroup'
import FormLabel from 'src/shared/components/global/FormLabel'
import HelpPopover from 'src/shared/components/global/HelpPopover'
import HyperLink from 'src/setup/router/HyperLink'
import IconButton from 'src/shared/components/global/IconButton'
import LatexBlock from 'src/shared/components/global/LatexBlock'
import List from 'src/shared/components/global/List'
import ListBox from 'src/shared/components/global/ListBox'
import ListColumn from 'src/shared/components/global/ListColumn'
import LoadingButton from 'src/shared/components/global/LoadingButton'
import LoadingContainer from 'src/shared/components/global/LoadingContainer'
import MultilineTextInput from 'src/shared/components/global/MultilineTextInput'
import NumberInput from 'src/shared/components/global/NumberInput'
import PaginatedList from 'src/shared/components/global/PaginatedList'
import PaginationContainer from 'src/shared/components/global/PaginationContainer'
import Playback from 'src/shared/components/global/Playback'
import ProgressCircle from 'src/shared/components/global/ProgressCircle'
import ProgressElement from 'src/shared/components/global/ProgressElement'
import ProgressProvider from 'src/shared/components/global/ProgressProvider'
import ProgressSummary from 'src/shared/components/global/ProgressSummary'
import RadioButton from 'src/shared/components/global/RadioButton'
import SearchInput from 'src/shared/components/global/SearchInput'
import SectionHeader from 'src/shared/components/global/SectionHeader'
import SectionTitle from 'src/shared/components/global/SectionTitle'
import SelectField from 'src/shared/components/global/SelectField'
import SelectorInput from 'src/shared/components/global/SelectorInput'
import SelectorOption from 'src/shared/components/global/SelectorOption'
import SortableListColumn from 'src/shared/components/global/SortableListColumn'
import SplitButtonDropdown from 'src/shared/components/global/SplitButtonDropdown'
import StaticValue from 'src/shared/components/global/StaticValue'
import StickyHeader from 'src/shared/components/global/StickyHeader'
import StickyHeaderSubTitle from 'src/shared/components/global/StickyHeaderSubTitle'
import StickyHeaderTitle from 'src/shared/components/global/StickyHeaderTitle'
import SubmitButton from 'src/shared/components/global/SubmitButton'
import TabButton from 'src/shared/components/global/TabButton'
import TabHeader from 'src/shared/components/global/TabHeader'
import TabHeaderButton from 'src/shared/components/global/TabHeaderButton'
import TabPanel from 'src/shared/components/global/TabPanel'
import TabProvider from 'src/shared/components/global/TabProvider'
import TableWrapper from 'src/shared/components/global/TableWrapper'
import TextInput from 'src/shared/components/global/TextInput'
import Toggle from 'src/shared/components/global/Toggle'
import ViewContainer from 'src/shared/components/global/ViewContainer'
import ViewHeader from 'src/shared/components/global/ViewHeader'
import ViewHeaderActions from 'src/shared/components/global/ViewHeaderActions'
import ViewSubHeader from 'src/shared/components/global/ViewSubHeader'
import ViewSubtitle from 'src/shared/components/global/ViewSubtitle'
import ViewTitle from 'src/shared/components/global/ViewTitle'

export default app => {
  Object.entries(components).forEach(([name, component]) =>
    app.component(name, component)
  )
}

export const components = {
  Icon: FontAwesomeIcon,
  IconLayers: FontAwesomeLayers,
  LatexBlock,
  DoubleSlider,
  TableWrapper,
  FormGroup: FormGroup,
  TextInput: TextInput,
  MultilineTextInput: MultilineTextInput,
  SearchInput: SearchInput,
  Checkbox: Checkbox,
  RadioButton: RadioButton,
  ChoiceInput: ChoiceInput,
  NumberInput: NumberInput,
  Editor: Editor,
  EditorContent: EditorContent,
  LoadingButton: LoadingButton,
  EmbeddedIframe: EmbeddedIFrame,
  FormLabel: FormLabel,
  FormButton: FormButton,
  LoadingContainer: LoadingContainer,
  PaginationContainer: PaginationContainer,
  List: List,
  PaginatedList: PaginatedList,
  ListColumn: ListColumn,
  SortableListColumn: SortableListColumn,
  SelectField: SelectField,
  AsyncForm: AsyncForm,
  SelectorInput: SelectorInput,
  SelectorOption: SelectorOption,
  StaticValue: StaticValue,
  SubmitButton: SubmitButton,
  CollapsePanel: CollapsePanel,
  ViewContainer: ViewContainer,
  ViewHeader: ViewHeader,
  ViewSubHeader: ViewSubHeader,
  StickyHeader: StickyHeader,
  ViewTitle: ViewTitle,
  ViewSubtitle: ViewSubtitle,
  ViewHeaderActions: ViewHeaderActions,
  Card: Card,
  CardTitle: CardTitle,
  CardSecondary: CardSecondary,
  ButtonDropdown: ButtonDropdown,
  DropdownLink: DropdownLink,
  DropdownAction: DropdownAction,
  DropdownCopy: DropdownCopy,
  SplitButtonDropdown: SplitButtonDropdown,
  BasePopover: BasePopover,
  ButtonLink: ButtonLink,
  Toggle: Toggle,
  SectionHeader: SectionHeader,
  SectionTitle: SectionTitle,
  StickyHeaderTitle: StickyHeaderTitle,
  StickyHeaderSubTitle: StickyHeaderSubTitle,
  IconButton: IconButton,
  HelpPopover: HelpPopover,
  Chip: Chip,
  TabProvider: TabProvider,
  TabHeader: TabHeader,
  TabPanel: TabPanel,
  TabButton: TabButton,
  TabHeaderButton,
  DateInput: DateInput,
  ProgressProvider: ProgressProvider,
  ProgressElement: ProgressElement,
  ProgressSummary: ProgressSummary,
  ProgressCircle: ProgressCircle,
  CollapseProvider: CollapseProvider,
  CollapseContent: CollapseContent,
  CollapseToggle: CollapseToggle,
  CollapseIcon: CollapseIcon,
  Breadcrumb: Breadcrumb,
  BreadcrumbItem: BreadcrumbItem,
  HyperLink: HyperLink,
  ListBox: ListBox,
  FilePickerButton,
  Playback
}
