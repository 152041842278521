<template>
  <li>
    <sidebar-collapse :key="`typeCollapse`">
      <template #header>Disabled Reason</template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('abuse')"
              @update:modelValue="value => changeType('abuse', value)"
              @click.stop
            >
              Abuse
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('invalid')"
              @update:modelValue="value => changeType('invalid', value)"
              @click.stop
            >
              Invalid
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('vetting-rejected')"
              @update:modelValue="
                value => changeType('vetting-rejected', value)
              "
              @click.stop
            >
              Vetting Rejected
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'DisableReasoFilter',
  inject: ['browseUsers'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  computed: {
    reasons() {
      return this.browseUsers.query.disabledReason
    }
  },
  methods: {
    isChecked(t) {
      return this.reasons ? this.reasons.includes(t) : false
    },
    changeType(status, isChecked) {
      let reasons = [...this.reasons]
      if (!isChecked) {
        reasons = reasons.filter(t => t !== status)
      } else {
        reasons.push(status)
      }
      if (reasons.length) {
        return this.browseUsers.updateQuery({
          page: 1,
          disabledReason: [...reasons]
        })
      }
      return this.browseUsers.updateQuery({
        page: 1,
        disabledReason: undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
