import { ref } from 'vue'

export interface LoadScriptOptions {
  url: string
  dataset?: Record<string, string>
  onBeforeLoad?: () => void
  onLoaded?: () => void
  onAlreadyLoaded?: () => void
}

export function loadScript({
  url,
  dataset = {},
  onBeforeLoad,
  onLoaded,
  onAlreadyLoaded
}: LoadScriptOptions) {
  const scripts = Array.from(document.querySelectorAll('script'))
  if (scripts.every(script => script.src !== url)) {
    onBeforeLoad?.()
    const script = document.createElement('script')
    if (url) {
      script.setAttribute('src', url)
    }
    script.addEventListener('load', () => {
      onLoaded?.()
    })
    Object.entries(dataset).forEach(([key, value]) => {
      script.dataset[key] = value
    })
    document.body.appendChild(script)
  } else {
    onAlreadyLoaded?.()
  }
}

export async function loadScriptAsync({
  url,
  dataset = {}
}: Pick<LoadScriptOptions, 'url' | 'dataset'>): Promise<void> {
  return new Promise((resolve, reject) => {
    const scripts = Array.from(document.querySelectorAll('script'))
    if (scripts.some(script => script.src === url)) {
      resolve()
    }

    const script = document.createElement('script')
    if (url) {
      script.setAttribute('src', url)
    }
    Object.entries(dataset).forEach(([key, value]) => {
      script.dataset[key] = value
    })

    script.addEventListener('load', () => resolve())
    script.addEventListener('error', () => reject())
    document.body.appendChild(script)
  })
}

export function isScriptLoaded(url: string) {
  return !!document.querySelector('script[src="' + url + '"]')
}

export default function useScript({
  onLoaded,
  onAlreadyLoaded,
  ...options
}: LoadScriptOptions) {
  const loaded = ref(false)
  loadScript({
    ...options,
    onLoaded() {
      loaded.value = true
      onLoaded?.()
    },
    onAlreadyLoaded() {
      loaded.value = true
      onAlreadyLoaded?.()
    }
  })
  return loaded
}
