<template>
  <collapse-provider :collapsed="isCollapsed" @change="doToggle">
    <collapse-toggle
      v-slot="toggleProps"
      data-testid="collapse-panel-header"
      class="collapse__header"
    >
      <slot name="header" :collapsed="toggleProps.collapsed" />
    </collapse-toggle>
    <collapse-content :print="showOnPrint">
      <slot name="body" />
    </collapse-content>
  </collapse-provider>
</template>

<script>
export default {
  name: 'Collapse',
  emits: ['toggle'],
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    showOnPrint: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isCollapsed: this.collapsed
    }
  },
  methods: {
    doToggle(state) {
      this.isCollapsed = state
      this.$emit('toggle', state)
    },
    openPanel() {
      if (this.isCollapsed === true) {
        this.$refs.headRef.click()
      }
    }
  },
  watch: {
    collapsed: function (newBool) {
      this.isCollapsed = newBool
    }
  }
}
</script>

<style lang="scss">
.collapse__header {
  text-align: inherit;
  width: 100%;
}

@media print {
  .collapse--show-print {
    // Styles to show panel.
    display: block !important;
    height: auto !important;
    overflow: visible !important;
    visibility: visible !important;
  }
}
</style>
