import godirect from '@vernier/godirect'
import BluetoothDevice, { BluetoothDataStream } from './BluetoothDevice'

class VernierBluetoothDataStream extends BluetoothDataStream {
  constructor(gdxSensor) {
    super(gdxSensor.number, gdxSensor.name, gdxSensor.unit)

    this._gdxSensor = gdxSensor

    this._gdxSensor.on('value-changed', this._onSensorValueChanged.bind(this))
  }

  get measurementPeriod() {
    return this._measurementPeriod
  }

  set measurementPeriod(measurementPeriod) {
    const { minPeriod, maxPeriod } = this._gdxSensor.specs.measurementInfo
    this._measurementPeriod = Math.min(
      Math.max(measurementPeriod, minPeriod),
      maxPeriod
    )
  }

  _onSensorValueChanged(gdxSensor) {
    if (this._buffering) {
      this._sampleBuffer.push(gdxSensor.value - this.tareValue)
    } else {
      this.liveReading = gdxSensor.value - this.tareValue
    }
  }
}

class VernierBluetoothDevice extends BluetoothDevice {
  get name() {
    return this._gdxDevice?.name
  }

  async init() {
    this._gdxDevice = await godirect.createDevice(this._nativeDevice, {
      startMeasurements: false
    })
    // We'll keep our own array of values
    this._gdxDevice.keepValues = false

    if (this._gdxDevice.orderCode === 'GDX-MD') {
      const sensor = this._gdxDevice.sensors[0]
      sensor.setEnabled(true)
      this.dataStreams[sensor.number] = new VernierBluetoothDataStream(sensor)
    } else {
      for (const sensor of this._gdxDevice.sensors) {
        sensor.setEnabled(true)
        this.dataStreams[sensor.number] = new VernierBluetoothDataStream(sensor)
      }
    }

    // Handle a few device specific overrides
    const { orderCode } = this._gdxDevice
    switch (orderCode) {
      case 'GDX-MD':
        if (this.dataStreams[5]) {
          this.dataStreams[5].name = 'Position'
        }
        break
      default:
        break
    }

    await this.reset()
  }

  async reset() {
    try {
      await super.reset()

      await this._gdxDevice.start(BluetoothDevice.IDLE_MEASUREMENT_PERIOD)
    } catch (error) {
      this.error = true
      this.onError(error, this)
    }
  }

  async startCollectingSamples(measurementPeriod) {
    try {
      await super.startCollectingSamples(measurementPeriod)

      await this._gdxDevice.start(measurementPeriod)
    } catch (error) {
      this.error = true
      this.onError(error, this)
    }
  }

  async stopCollectingSamples() {
    try {
      await this._gdxDevice.stop()
    } catch (error) {
      this.error = true
      this.onError(error, this)
    }
  }

  async disconnect() {
    await super.disconnect()
    await this._gdxDevice.close()
  }
}

VernierBluetoothDevice.namePrefixes = ['GDX']
VernierBluetoothDevice.optionalServices = [
  'd91714ef-28b9-4f91-ba16-f0d9a604f112'
]

export default VernierBluetoothDevice
