<template>
  <modal @submit="submit">
    <modal-header>Update Student Profile</modal-header>
    <modal-body>
      <form-group>
        <form-label for="firstName"> First Name </form-label>
        <div>
          <text-input
            id="firstName"
            v-model="firstName"
            label="first name"
            rules="required"
          />
        </div>
      </form-group>
      <form-group>
        <form-label for="lastName"> Last Name </form-label>
        <div>
          <text-input
            id="lastName"
            v-model="lastName"
            label="last name"
            rules="required"
          />
        </div>
      </form-group>
      <template v-if="student.canUpdateProfile">
        <form-group>
          <form-label for="email">
            Email Address<span id="required-asterisk">*</span>
          </form-label>
          <div>
            <text-input
              id="email"
              v-model="email"
              label="email"
              rules="required|email"
            />
          </div>
        </form-group>
        <form-group>
          <form-label for="password"> New Password </form-label>
          <div>
            <text-input
              id="password"
              v-model="password"
              type="password"
              label="password"
              :rules="{
                password: { isStudent: true }
              }"
            />
          </div>
        </form-group>
      </template>
      <p v-else>
        This student's account is configured with
        {{
          student.ssoProviders.length > 1
            ? student.ssoProviders.slice(0, -1).join(', ') +
              ', and ' +
              student.ssoProviders.at(-1)
            : student.ssoProviders[0]
        }}
        Single Sign On. They will need to login using
        {{
          student.ssoProviders.length > 1
            ? 'one of these single sign on providers'
            : 'this single sign on provider'
        }}
        to change their email or password.
      </p>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Update</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import client from 'src/shared/api-client.ts'
export default {
  name: 'UpdateSutdentProfileModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      firstName: this.student.firstName,
      lastName: this.student.lastName,
      email: this.student.email,
      password: ''
    }
  },
  methods: {
    async submit(e) {
      try {
        const data = {
          id: this.student._id,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        }
        if (this.student.canUpdateProfile) {
          data.email = this.email
          if (this.password) {
            data.password = this.password
          }
        }
        await client.users.updateUserProfile(data)
        this.$success('Student Profile updated successfully.')
        e.done({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        })
      } catch (error) {
        e.done(false)
        throw error
      }
    }
  }
}
</script>
