<template>
  <a
    v-if="isExternal"
    v-bind="$attrs"
    :target="$attrs.target || '_blank'"
    :href="link"
    rel="noopener"
    ><slot
  /></a>
  <router-link v-else v-bind="$attrs" :to="to"><slot /></router-link>
</template>

<script>
import {
  isExternalLink,
  linksList
} from 'src/setup/router/externalLinkUtils.js'
export default {
  name: 'HyperLink',
  props: {
    to: {
      type: [Object, String],
      required: true
    }
  },
  computed: {
    link() {
      return this.to.name
        ? typeof linksList[this.to.name] === 'string'
          ? linksList[this.to.name]
          : linksList[this.to.name](this.to.params)
        : ''
    },
    isExternal() {
      return this.to.name && isExternalLink(this.to.name)
    }
  }
}
</script>
