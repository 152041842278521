<template>
  <UICard class="class-selector-card relative overflow-clip">
    <div
      class="flex justify-between items-start"
      :class="{
        'mb-2': changing,
        'mb-3': !changing
      }"
    >
      <UICardHeading>
        {{ heading }}
      </UICardHeading>
      <UITextInput
        v-if="changing"
        v-model="classFilter"
        class="w-48 flex-shrink-0"
        name="class filter"
        placeholder="Filter Classes"
        icon="search"
        :controlled="false"
      />
    </div>
    <div v-if="!changing" class="flex justify-between items-start">
      <div>
        <div>
          Pivot Class:
          {{
            value === 'none' ? 'Do Not Connect To A Class' : selectedClass?.name
          }}
        </div>
        <div v-if="value">
          <template v-if="selectedClass?.createdAt">
            Created
            {{ formatDate(new Date(selectedClass.createdAt), 'MMM d, YYY') }}
          </template>
          <template v-if="selectedClass?.endDate">
            - Ends
            {{ formatDate(new Date(selectedClass.endDate), 'MMM d, YYY') }}
          </template>
        </div>
      </div>
      <UIButton @click="changing = true" type="outline" class="ml-auto">
        Change
      </UIButton>
    </div>
    <template v-else>
      <p class="mb-1">Select Pivot class to connect:</p>
      <p v-if="classes.length === 0">
        You have no existing classes in Pivot Interactives.
        <UIButton type="link" @click="createClass"> Connect Class </UIButton>
        to get started!
      </p>
      <p v-else-if="filteredClasses.length === 0">
        No classes match your filter.
      </p>
      <RadioGroup v-else v-model="value" class="flex flex-col gap-2">
        <RadioGroupLabel class="sr-only">selected class</RadioGroupLabel>
        <RadioGroupOption
          v-if="!required"
          value="none"
          class="block rounded-md border border-pivot-teal-700 px-3 py-2 cursor-pointer focus-within:shadow-glow outline-pivot-teal-700 outline-1 ui-checked:outline ui-checked:bg-pivot-teal-50"
        >
          <RadioGroupLabel
            class="font-bold whitespace-nowrap min-w-0 overflow-ellipsis overflow-hidden mr-2"
            >Do Not Connect To A Class</RadioGroupLabel
          >
        </RadioGroupOption>
        <RadioGroupOption
          v-for="klass in filteredClasses"
          :key="klass.id"
          :value="klass.id"
          class="block rounded-md border border-pivot-teal-700 px-3 py-2 cursor-pointer focus-within:shadow-glow outline-pivot-teal-700 outline-1 ui-checked:outline ui-checked:bg-pivot-teal-50"
        >
          <RadioGroupLabel
            class="font-bold whitespace-nowrap min-w-0 overflow-ellipsis overflow-hidden mr-2"
          >
            {{ klass.name }}
          </RadioGroupLabel>
          <RadioGroupDescription>
            Created
            {{ formatDate(new Date(klass.createdAt), 'MMM d, YYY') }}
            <template v-if="klass.endDate">
              - Ends
              {{ formatDate(new Date(klass.endDate), 'MMM d, YYY') }}
            </template>
          </RadioGroupDescription>
        </RadioGroupOption>
      </RadioGroup>
      <button
        v-if="!rostered"
        @click="createClass"
        class="flex items-center my-2 gap-2 rounded-md border border-dashed border-pivot-teal-700 px-3 py-4 cursor-pointer focus-within:shadow-glow w-full text-pivot-teal-700 font-bold"
      >
        Create Pivot Class
        <UIIcon icon="plus" />
      </button>
      <div
        v-else
        class="flex items-center justify-between my-2 gap-2 rounded-md border border-dashed border-pivot-teal-700 px-3 py-4 w-full font-bold"
      >
        <span>Add Class:</span>
        <div>
          <UIButton @click="createRosteredClass" type="outline">
            Create Class from Rostering Service
          </UIButton>
          <span class="px-2">or</span>
          <UIButton @click="createClass" type="outline">
            Create Class Manually
          </UIButton>
        </div>
      </div>
    </template>
    <slot name="actions" />
  </UICard>
</template>

<script setup lang="ts">
import { ref, computed, inject, watch } from 'vue'
import { useField } from 'vee-validate'
import { format as formatDate } from 'date-fns'
import { useStore } from 'vuex'
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'
import CreateClassModal from 'src/shared/components/modals/CreateClassModal.vue'
import RosterClassesModal from 'src/modules/classes/views/RosterClassesModal.vue'
import {
  UICard,
  UITextInput,
  UIIcon,
  UIButton,
  UICardHeading
} from '@pi/design'

const store = useStore()
const rostered = computed(() => store.state.auth.user.externalRoster)
const modal = inject<any>('$modal')

interface Class {
  id: string
  name: string
  createdAt: Date
  endDate?: Date
}

const emits = defineEmits(['refreshClassList'])
const props = withDefaults(
  defineProps<{
    platformName: string
    className?: string
    sectionName?: string
    path: string
    classes: Class[]
    required?: boolean
  }>(),
  {
    required: false
  }
)

const heading = computed(() => {
  let className = props.sectionName || props.className
  if (className) {
    return `${props.platformName}: ${className}`
  } else {
    return 'Connect Class'
  }
})

const { value } = useField<string>(props.path, 'required')
const selectedClass = computed(() =>
  props.classes.find(c => c.id === value.value)
)

const changing = ref(!value.value)
watch(value, value => {
  if (!value) {
    changing.value = true
  }
})

const classFilter = ref('')
const filteredClasses = computed(() => {
  if (!classFilter.value) return props.classes

  // Escape regex reserved characters.
  const matcher = new RegExp(
    classFilter.value
      .trim()
      .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      .replace(/-/g, '\\x2d'),
    'i'
  )
  return props.classes.filter(c => matcher.test(c.name))
})

const createClass = async () => {
  const { status } = await modal.show(CreateClassModal, {
    roster: rostered
  })
  if (status === 'ok') {
    emits('refreshClassList', true)
  }
}

const createRosteredClass = async () => {
  const { status } = await modal.show(RosterClassesModal, {})
  if (status === 'ok') {
    emits('refreshClassList', true)
  }
}
</script>
