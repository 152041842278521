<template>
  <view-container>
    <StickyHeader>
      <template #title>
        <StickyHeaderTitle class="inline-block">
          {{
            platform && className
              ? `${platform}: ${className}`
              : 'LTI Login for Pivot Interactives'
          }}
        </StickyHeaderTitle>
      </template>

      <template #actions>
        <UIButton
          type="outline"
          variant="destructive"
          size="md"
          @click="cancelLTIFlow(ltiSession)"
          >Cancel Connection
        </UIButton></template
      >

      <template #steps="{ isStuck }">
        <div
          class="bg-ui-gray-50 relative"
          style="padding: 12px 9999% 6px 9999%; margin: 0 -9999% 0 -9999%"
          :class="{
            'sticky top-12': isStuck
          }"
        >
          <UIStepList :steps="steps" currentStep="signin" />
        </div>
      </template>
    </StickyHeader>
    <loading-container :loading="step === 'redirect' || !loaded">
      <signup-start
        v-if="step === 'account-select'"
        :ssoConnection="ssoConnection"
        :userType="role"
      />
      <login-selection v-if="step === 'login-select'" />
      <create-account-selection
        v-if="step === 'create-account'"
        :student="role === 'student'"
      />
      <standard-login v-if="step === 'email'" />
      <profile-details-form
        v-if="step === 'sign-up'"
        :student="role === 'student'"
        :initial-field-values="formData"
      />
    </loading-container>
  </view-container>
</template>

<script setup>
import LoginSelection from 'src/modules/auth/components/LoginSelection.vue'
import SignupStart from 'src/modules/auth/components/SignupStart.vue'
import CreateAccountSelection from 'src/modules/auth/components/CreateAccountSelection.vue'
import ProfileDetailsForm from 'src/modules/auth/components/ProfileDetailsForm.vue'
import StandardLogin from 'src/modules/auth/components/StandardLogin.vue'
import { reactive, ref, onBeforeMount, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import client from 'src/shared/api-client'
import { UIButton, UIStepList } from '@pi/design'
import { cancelLTIFlow, getLtiSteps } from '../lti-utils'

const store = useStore()
const router = useRouter()
const props = defineProps({
  step: {
    type: String,
    required: true
  },
  classKey: {
    type: String,
    default: undefined
  },
  redirect: {
    type: String,
    default: 'lti_connect_account'
  }
})
const req = ref(null)
const formData = reactive({
  firstName: '',
  lastName: '',
  email: ''
})
const platform = ref(null)
const className = ref(null)
const ssoConnection = ref(null)
const role = ref(null)
const classFromRoster = ref(null)
const loaded = ref(false)
const ltiSession = ref()
const steps = ref([])

onBeforeMount(async () => {
  ltiSession.value = await client.lti.getSession()

  if (ltiSession.value.state !== 'CONNECT_ACCOUNT') {
    router.push({ name: 'home' })
  } else {
    req.value = ltiSession.value
    steps.value = getLtiSteps(ltiSession.value)
    formData.email = await req.value.context.user.email
    formData.confirmEmail = await req.value.context.user.email
    formData.firstName = req.value.context.user.firstName
    formData.lastName = req.value.context.user.lastName
    platform.value = await req.value.context.platform.name
    ssoConnection.value = await req.value.context.ssoConnection
    className.value = req.value.context.class?.name
    role.value = await req.value.context.role
    classFromRoster.value = req.value.context.class?.fromRoster ?? false

    loaded.value = true
  }
})

watchEffect(async () => {
  if (props.step === 'redirect') {
    window.location.assign(
      router.resolve({
        name: 'lti_connect_account'
      }).href
    )
  } else if (store.getters.isAuthenticated) {
    router.replace({ name: 'lti_connect_account' })
  } else if (
    props.step === 'account-select' &&
    (classFromRoster.value ||
      (role.value === 'teacher' && !req.value.context.license))
  ) {
    router.replace({
      ...router.currentRoute.value,
      params: { step: 'login-select' }
    })
  }
})
</script>
