<template>
  <Resizable
    :as="NodeViewWrapper"
    class="inline-drop-target"
    :class="{
      'inline-drop-target--block': attrs.display === 'inline-block',
      'inline-drop-target--inline': attrs.display === 'inline',
      'inline-drop-target--freeform': attrs.display === 'freeform',
      'inline-drop-target--full-width': isFullWidth,
      'inline-drop-target--invalid': targetState === 'invalid',
      'inline-drop-target--incorrect': targetState === 'incorrect',
      'inline-drop-target--correct': targetState === 'correct',
      'inline-drop-target--filled': choice,
      'inline-drop-target--dropdown': attrs.targetType === 'dropdown'
    }"
    :size="size"
    :focused="false"
    :fullWidth="isFullWidth"
    :display="attrs.display === 'inline' ? 'inline' : 'block'"
    :units="attrs.display === 'freeform' ? 'percent' : 'pixel'"
    :preserveAspectRatio="false"
    contenteditable="false"
    :style="styles"
    :data-target="attrs.id"
    :data-target-type="attrs.targetType"
    :tabindex="choice || attrs.targetType === 'dropdown' ? 0 : undefined"
    @pointerdown="handlePointerDown"
  >
    <template v-if="!choice">
      {{ node.attrs.label || areaLetter }}
    </template>
    <DragDropRichTextContent
      v-if="choice"
      :text="choice.text"
      :variableContext="variableContext"
      :view-as-student="viewAsStudent"
    />
  </Resizable>
</template>

<script setup>
import { computed } from 'vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import { indexToLetter } from 'src/shared/utils/index-to-letter.js'
import Resizable from '../components/Resizable.vue'
import DragDropRichTextContent from '../../../../modules/activities/components/DragDropRichTextContent.vue'
import useDragging from 'src/modules/activities/useDragging'

const variableContext = computed(() => props.extension.storage.variableContext)

const props = defineProps(nodeViewProps)

const viewAsStudent = computed(() => props.extension.storage.viewAsStudent)

const attrs = computed(() => ({
  ...props.node.attrs,
  display: props.node.attrs.display ?? 'inline'
}))
const areaLetter = computed(() =>
  indexToLetter(
    props.extension.storage.areas.findIndex(o => o === attrs.value.id)
  )
)
const isFullWidth = computed(
  () => attrs.value.width === '100%' && attrs.value.display !== 'freeform'
)

const choice = computed(() => {
  const targetValues = props.extension.storage.targetValues
  const choiceId = targetValues?.[attrs.value.id]
  return props.extension.storage.choices?.find(c => c.id === choiceId)
})

const targetState = computed(() => {
  return props.extension.storage.targetStates?.[attrs.value.id]
})
const size = computed({
  get() {
    return {
      width: parseFloat(attrs.value.width),
      height: parseFloat(attrs.value.height)
    }
  },
  set(size) {
    props.updateAttributes(size)
  }
})

const styles = computed(() => {
  switch (attrs.value.display) {
    case 'freeform': {
      return {
        top: `${attrs.value.positionTop ?? 50}%`,
        right: `${attrs.value.positionRight ?? 50}%`
      }
    }
    case 'inline-block': {
      return {
        ...(choice.value && { height: 'unset' })
      }
    }
    case 'inline':
    default: {
      return {
        display: choice.value && !isFullWidth.value ? 'inline' : 'inline-block'
      }
    }
  }
})

let draggedChoice

const { onPointerDown } = useDragging({
  onDragStart() {
    if (choice.value) {
      draggedChoice = choice.value.id
      props.extension.storage.setTargetValue(attrs.value.id)
      return true
    } else {
      return false
    }
  },
  onDragEnd(e, targetId) {
    if (targetId && draggedChoice) {
      props.extension.storage.setTargetValue(targetId, draggedChoice)
    }
  }
})

const handlePointerDown = e => {
  if (attrs.value.targetType !== 'dropdown') {
    onPointerDown(e)
  }
}
</script>

<style lang="scss" scoped>
.inline-drop-target {
  margin: 4px 0 4px 0;
  position: relative;
  border: 1px dashed #3d2c60;
  outline: 1px solid transparent;
  border-radius: 4px;
  padding: 1px 6px 1px 4px;
  display: inline-block;
  min-width: 25px;
  min-height: 30px;

  &.dropping {
    border: 1px solid #3d2c60;
    box-shadow: $focus-shadow;
  }
}

.inline-drop-target--dropdown {
  border: 1px solid;
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:after {
    content: url('data:image/svg+xml; utf8, <svg class="svg-inline--fa fa-caret-down library-filter-dropdown__icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path class="" fill="currentColor" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"></path></svg>');
    position: absolute;
    right: 5px;
    top: 0;
    width: 10px;
    height: 100%;
    line-height: 1.9;
  }

  &:focus {
    outline: 1px solid $teal;
  }
}

.inline-drop-target--freeform {
  min-width: 24px;
  min-height: 24px;
  display: block;
  transform: translate(50%, -50%);
  position: absolute;
  z-index: 10;
  background-color: #fff;
}

.inline-drop-target--filled {
  border: 1px solid gray;
  cursor: grab;
}

.inline-drop-target--full-width {
  width: calc(100% - 1px);
}

.inline-drop-target--block {
  display: inline-block;
  vertical-align: top;
}

.inline-drop-target--invalid,
.inline-drop-target--incorrect {
  border: 1px solid transparent;
  outline: 3px dashed $color-error;
  border-radius: 4px;
  background-color: rgb(255, 219, 181);

  .inline-drop-target__correct-icon {
    color: $color-error;
  }
}

.inline-drop-target--correct {
  border: 1px solid transparent;
  outline: 3px solid $teal;
  border-radius: 4px;
}
</style>
