<template>
  <collapse-panel
    :title="'Notes & Instructions'"
    :collapsed="false"
    show-on-print
  >
    <div class="activity-input-group">
      <label>Instructor Notes</label>
      <editor
        :text="activity.notes"
        :readonly="!isVetted"
        :help-text="
          !isVetted
            ? 'This field is disabled while your account is pending.'
            : ''
        "
        :variable-context="variableContext"
        @input="arg => (activity.notes = arg)"
      />
    </div>
  </collapse-panel>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ActivityNotesView',
  inject: ['inherited'],
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isVetted', 'canManageFreeActivities']),
    activity() {
      return this.inherited.activity
    }
  },
  methods: {}
}
</script>
