<template>
  <div class="activity-sort">
    <span class="activity-view__label">View as:</span>
    <form-button
      :secondary="browseActivities.viewSelected === listType.row"
      :aria-pressed="browseActivities.viewSelected === listType.card"
      @click="changeView('card')"
    >
      <icon icon="table-cells-large" />
      <span class="sr-only">Card View</span>
    </form-button>
    <form-button
      :secondary="browseActivities.viewSelected === listType.card"
      :aria-pressed="browseActivities.viewSelected === listType.row"
      @click="changeView('row')"
    >
      <icon icon="list" />
      <span class="sr-only">List View</span>
    </form-button>
  </div>
</template>

<script>
export default {
  name: 'ActivitySortAction',
  inject: ['browseActivities'],
  data() {
    return {
      listType: {
        card: 'card',
        row: 'row'
      }
    }
  },
  methods: {
    changeView(view) {
      this.browseActivities.updateViewSelected(view)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-sort {
  display: flex;
  height: 38px;
  align-items: baseline;
  margin: 16px 0;
  font-size: 16px;

  & :deep(select) {
    font-size: 16px;
  }

  & span {
    margin-right: 5px;
    white-space: nowrap;
  }
  & > :not(:last-child) {
    margin-right: 8px;
  }
}
.activity-view__label {
  display: flex;
  height: 38px;
  align-items: center;
  margin-left: 25px;
  font-size: 16px;
}
</style>
