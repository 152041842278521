<template>
  <dashboard-panel class="!pb-5 !mb-5 summary-panel">
    <dashboard-collapse-toggle
      >Performance By Student
    </dashboard-collapse-toggle>
    <collapse-content>
      <div class="assignment-summary__header lg:-mt-[50px]">
        <label for="unit-label">Unit</label>
        <select-field v-model="scoringType" id="unit-label">
          <option default value="Percent">Percent</option>
          <option value="Points">Points</option>
          <option value="Attempts">Attempts</option>
        </select-field>
      </div>
      <list
        :items="mappedResponses"
        :item-key="assignment.assignedTo === 'groups' ? 'studentId' : 'id'"
        row-class="summary-table-row"
        table-class="max-h-screen"
      >
        <sortable-list-column
          title="Student"
          :sort="propertySort('name')"
          @change="dir => updateSort('name', dir)"
          sticky
          column-class="min-w-64"
        >
          <template v-slot:default="response">
            <router-link
              v-if="response.id"
              :to="{
                name: 'grade_response',
                params: {
                  assignmentId: assignment.id,
                  responseId: response.id
                },
                query: responseSortQuery
              }"
            >
              {{ response.studentName }}
            </router-link>
            <template v-else>
              {{ response.studentName }}
            </template>
          </template>
          <template v-slot:summary> Average: </template>
        </sortable-list-column>
        <sortable-list-column
          title="Total"
          :sort="propertySort('total')"
          @change="dir => updateSort('total', dir)"
        >
          <template v-slot:default="response">
            {{ totalForResponse(response) }}
          </template>

          <template v-slot:summary>{{ averageFinalGrade }} </template>
        </sortable-list-column>

        <sortable-list-column
          v-for="(question, index) in activityComponents"
          :key="index"
          :title="`${question.sectionNumber}.${question.questionNumber}`"
          noIcon
          @change="dir => updateSort(question._id, dir)"
          :sort="propertySort(question._id)"
          :helpText="question.text"
          :variableContext="variableContext"
        >
          <template v-slot:summary>
            {{ getSummaryForComponent(index) }}
          </template>
          <template v-slot:default="response">
            <router-link
              v-if="question !== undefined && response !== undefined"
              :to="{
                name: 'grade_response',
                params: {
                  assignmentId: assignment.id,
                  responseId: response.id
                },
                query: responseSortQuery,
                hash: `#component-${question._id}`
              }"
            >
              {{ formatResponse(question, response) }}
            </router-link>
            <p v-else>-</p>
          </template>
        </sortable-list-column>
        <list-column style="width: 100%" />
      </list> </collapse-content
  ></dashboard-panel>
</template>

<script setup>
import { computed, watchEffect, ref } from 'vue'
import { useRouter } from 'vue-router'
import DashboardPanel from 'src/shared/components/DashboardPanel.vue'
import DashboardCollapseToggle from 'src/shared/components/DashboardCollapseToggle.vue'
const router = useRouter()
const emit = defineEmits(['refresh'])
const props = defineProps({
  assignment: {
    type: Object,
    required: true
  },
  activity: {
    type: Object,
    required: true
  },
  responses: {
    type: Object,
    required: true
  },
  klass: {
    type: Object,
    required: true
  },
  variableContext: {
    type: Object,
    required: true
  }
})

const scoringType = ref('Percent')

const totalPoints = computed(
  () => props.assignment.totalPointValue ?? props.activity.totalPointValue
)

const sort = computed(() => ({
  field: router.currentRoute.value.query['sort'] ?? 'name',
  direction: router.currentRoute.value.query['dir'] ?? 'asc'
}))
const responseSortQuery = computed(() =>
  sort.value.field
    ? {
        sort: sort.value.field,
        dir: sort.value.direction
      }
    : {}
)

function propertySort(key) {
  return sort.value.field === key ? sort.value.direction : false
}
function updateSort(field, direction) {
  router.replace({
    ...router.currentRoute.value,
    query: {
      ...router.currentRoute.value.query,
      sort: field,
      dir: direction || 'asc'
    }
  })
}

const activityComponents = computed(() =>
  (props.activity.sections ?? [])
    .flatMap(section =>
      section.components
        .flatMap(component =>
          component.componentType === 'SplitView'
            ? [...component.leftContent, ...component.rightContent]
            : component
        )
        .map(component => ({
          ...component,
          sectionNumber: section.sectionNumber
        }))
    )
    .filter(
      component => component.pointValue != undefined && component.pointValue > 0
    )
)

const mappedResponses = computed(() => {
  const responses = props.responses.map(response => {
    const studentName =
      props.assignment.assignedTo === 'groups'
        ? ''
        : `${response.owner.lastName}, ${response.owner.firstName}`

    return {
      ...response,
      studentName,
      studentId:
        props.assignment.assignedTo === 'groups' ? '' : response.ownerId,
      totalSubmissions: response.responses.reduce(
        (a, r) => a + (r.submissions ?? 0),
        0
      )
    }
  })

  if (props.assignment.assignedTo === 'groups') {
    return responses
      .map(response =>
        response.students.map((student, i) => ({
          ...response,
          studentName: `${student.lastName}, ${student.firstName}`,
          studentId: student._id,
          lastStudent: i === response.students.length - 1
        }))
      )
      .flat()
  }
  return responses
})

const average = array =>
  array.length ? array.reduce((a, b) => a + b) / array.length : 0
const formatPercentageForDisplay = (value, precision = 3) =>
  `${value.toPrecision(precision)}%`
const removeUnnecessaryZeroes = (value, decimals = 2) =>
  value % 1 === 0 ? value : value.toFixed(decimals)

const mappedAveragePercentages = computed(() => {
  let allAvg = []

  activityComponents.value.forEach(question => {
    const questionScores = mappedResponses.value
      .flatMap(response => response.responses)
      .filter(
        response => response.component.toString() === question._id.toString()
      )
      .map(response => response.score)
      .filter(score => score !== undefined)

    const questionAverageScore = questionScores.length
      ? Number(average(questionScores).toPrecision(2)).toFixed(2)
      : 0

    const percentageAvg = (questionAverageScore / question.pointValue) * 100
    const formattedAvg = questionScores.length
      ? formatPercentageForDisplay(percentageAvg)
      : '-'
    allAvg.push(formattedAvg)
  })
  return allAvg
})

const mappedAveragePoints = computed(() => {
  let allPoints = []

  activityComponents.value.forEach(question => {
    const questionScores = mappedResponses.value
      .flatMap(response => response.responses)
      .filter(
        response => response.component.toString() === question._id.toString()
      )
      .map(response => response.score)
      .filter(score => score !== undefined)

    const questionAverageScore = questionScores.length
      ? average(questionScores).toPrecision(2)
      : 0

    const formattedPoints = questionScores.length
      ? `${Number(questionAverageScore).toFixed(1)}/${Number(question.pointValue.toPrecision(2)).toFixed(1)}`
      : '-'
    allPoints.push(formattedPoints)
  })
  return allPoints
})

const mappedAverageAttempts = computed(() =>
  activityComponents.value.map(question => {
    if (!question.autograde) {
      return '-'
    }

    const questionAttempts = mappedResponses.value
      .flatMap(response => response.responses)
      .filter(response => response.component === question._id)
      .map(response => response.submissions)
      .filter(score => score !== undefined)

    const questionAverageAttempts = questionAttempts.length
      ? average(questionAttempts)
      : 0

    return questionAverageAttempts.toPrecision(2)
  })
)

const averageFinalGrade = computed(() => {
  if (scoringType.value === 'Attempts') {
    return average(
      mappedResponses.value.map(response => response.totalSubmissions)
    ).toFixed(1)
  } else {
    const scores = mappedResponses.value
      .filter(
        response => response.gradingPercentage || response.responsePercentage
      )
      .map(response => response.totalScore)
    const avg = average(scores)
    if (scores.length === 0) {
      return '-'
    } else if (scoringType.value === 'Percent') {
      return formatPercentageForDisplay((avg / totalPoints.value) * 100)
    } else {
      const score = removeUnnecessaryZeroes(avg)
      return `${score}/${totalPoints.value}`
    }
  }
})

const sortMethod = computed(() => {
  const directionMultiplier = sort.value.direction === 'desc' ? -1 : 1

  let getSortValue
  if (sort.value.field === 'total') {
    if (scoringType.value === 'Attempts') {
      getSortValue = item => item.totalSubmissions
    } else {
      getSortValue = item =>
        item.totalScore === 0 ? undefined : item.totalScore
    }
  } else if (
    activityComponents.value.find(question => question._id === sort.value.field)
  ) {
    if (scoringType.value === 'Attempts') {
      getSortValue = item =>
        item.responses.find(response => response.component === sort.value.field)
          ?.submissions ?? 0
    } else {
      getSortValue = item =>
        item.responses.find(response => response.component === sort.value.field)
          ?.score
    }
  } else {
    return () => 0
  }

  return (a, b) => {
    const aValue = getSortValue(a)
    const bValue = getSortValue(b)

    if (aValue === bValue) {
      return 0
    } else if (aValue === undefined) {
      return 1
    } else if (bValue === undefined) {
      return -1
    } else {
      return directionMultiplier * (aValue - bValue)
    }
  }
})

watchEffect(() => {
  const directionMultiplier = sort.value.direction === 'desc' ? -1 : 1
  const firstSortFn =
    props.assignment.assignedTo === 'groups'
      ? (a, b) =>
          directionMultiplier *
          a.groupName?.localeCompare(b.groupName, undefined, {
            numeric: true,
            sensitivity: 'base'
          })
      : (a, b) =>
          directionMultiplier * a.studentName?.localeCompare(b.studentName)
  mappedResponses.value
    // We sort everything by student/group name first
    // to ensure that every sort starts with the same array.
    .sort(firstSortFn)
    .sort(sortMethod.value)
})

function calculateGrade(totalScore) {
  return totalScore && totalPoints.value
    ? (totalScore / totalPoints.value) * 100
    : 0
}

function totalForResponse(response) {
  if (scoringType.value === 'Attempts') {
    return response.totalSubmissions
  } else if (!response.responsePercentage && !response.gradingPercentage) {
    return '-'
  } else if (scoringType.value === 'Percent') {
    return formatPercentageForDisplay(calculateGrade(response.totalScore))
  } else {
    const score = removeUnnecessaryZeroes(response.totalScore)
    return `${score}/${totalPoints.value}`
  }
}

function getSummaryForComponent(index) {
  if (scoringType.value === 'Points') return mappedAveragePoints.value[index]
  if (scoringType.value === 'Attempts')
    return mappedAverageAttempts.value[index]

  return mappedAveragePercentages.value[index]
}

function formatResponse(question, response) {
  const questionResponse = response.responses.find(
    response => response.component === question._id
  )

  if (scoringType.value === 'Attempts') {
    if (question.autograde) {
      return questionResponse?.submissions ?? '0'
    } else {
      return '-'
    }
  } else {
    if (question == undefined || response == undefined) {
      return '-'
    }
    const score = questionResponse?.score
    if (typeof score !== 'number') {
      return '-'
    } else if (scoringType.value === 'Percent') {
      const percent = (100 * score) / question.pointValue
      return `${Math.floor(percent)}%`
    } else {
      return `${score}/${question.pointValue}`
    }
  }
}
</script>

<style lang="scss">
.assignment-summary__header {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 10px;
  margin-right: 20px;
}
.visibility-warning {
  color: $color-warning;

  a {
    color: $color-warning;
    text-decoration: underline;

    &:hover {
      color: darken($color-warning, 10%);
    }
  }
}

.summary-table-row {
  td {
    padding: 0px 4px !important;
    line-height: 1.5 !important;
  }
}
.summary-row {
  padding: 0px 4px !important;
}
</style>
