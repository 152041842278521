<template>
  <slot />
</template>

<script>
export default {
  name: 'TabHeaderButton',
  props: {
    tab: {
      type: String,
      required: true
    }
  }
}
</script>
