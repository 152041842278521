<template>
  <loading-container :loading="isLoading">
    <dashboard-panel>
      <dashboard-collapse-toggle>Usage Summary</dashboard-collapse-toggle>
      <collapse-content>
        <div class="usage">
          <list :items="sortedItems">
            <sortable-list-column
              title="Teacher"
              :sort="sort['name']"
              @change="sort => changeSort('name', sort)"
              sticky
              v-slot="{ name, id }"
            >
              <router-link
                v-if="id"
                :to="{
                  name: 'manage_instructor',
                  params: { instructorId: id }
                }"
              >
                {{ name }}
              </router-link>
              <template v-if="!id">{{ name }}</template>
            </sortable-list-column>
            <sortable-list-column
              title="Last Action"
              :sort="sort['lastAction']"
              v-slot="{ lastAction }"
              @change="sort => changeSort('lastAction', sort)"
              class="status-column"
            >
              <div class="status-label">
                {{ lastActionDisplayMap[lastAction] ?? 'N/A' }}
              </div>
            </sortable-list-column>
            <sortable-list-column
              title="Last Action Date"
              property="lastActionDate"
              :sort="sort['lastActionDate']"
              @change="sort => changeSort('lastActionDate', sort)"
              v-slot="{ lastActionDate }"
            >
              {{ $format.date(lastActionDate, 'MM/dd/yyyy hh:mm a') }}
            </sortable-list-column>
            <sortable-list-column
              title="Recently Assigned"
              property="recentlyAssigned"
              :sort="sort['recentlyAssignedName']"
              @change="sort => changeSort('recentlyAssignedName', sort)"
              v-slot="{ recentlyAssigned }"
            >
              <template v-if="recentlyAssigned">
                <router-link
                  v-if="isAmdin"
                  :to="{
                    name: 'instructor_assignment',
                    params: { id: recentlyAssigned.id }
                  }"
                  >{{ recentlyAssigned.name }}</router-link
                >
                <template v-else>
                  {{ recentlyAssigned.name }}
                </template>
              </template>
            </sortable-list-column>
            <sortable-list-column
              title="Total Students"
              property="totalStudents"
              :sort="sort['totalStudents']"
              @change="sort => changeSort('totalStudents', sort)"
            />
          </list>
        </div>
      </collapse-content>
    </dashboard-panel>
  </loading-container>
</template>

<script setup>
import { ref, computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'

import DashboardPanel from 'src/shared/components//DashboardPanel.vue'
import DashboardCollapseToggle from 'src/shared/components//DashboardCollapseToggle.vue'
import CollapseContent from 'src/shared/components/global/CollapseContent.vue'
import client from 'src/shared/api-client.ts'

const store = useStore()
const isAdmin = store.getters.isAdmin

const props = defineProps({
  license: {
    type: Object,
    required: true
  }
})

const teachers = ref()
const sort = ref({ name: 'asc' })
const isLoading = computed(() => !teachers.value)

const getUsageData = async () => {
  if (!props.license) return
  const usage = await client.licenses.getUsage({ id: props.license.id })
  teachers.value = usage.teachers
  sortedItems.value = sortItems(teachers.value.slice(), sort.value)
}

const sortItems = (teachers, sort) => {
  const sortField = Object.keys(sort)[0]
  const sortDir = sort[sortField]
  const sortedItems = teachers
    .map(t => ({
      ...t,
      recentlyAssignedName: t.recentlyAssigned ? t.recentlyAssigned.name : ''
    }))
    .sort((a, b) => {
      if (!a[sortField] && !b[sortField]) return 0
      const isString =
        typeof a[sortField] === 'string' || typeof b[sortField] === 'string'

      if (sortDir === 'asc') {
        if (!a[sortField]) return -1
        if (!b[sortField]) return 1
        return isString
          ? a[sortField].localeCompare(b[sortField])
          : a[sortField] > b[sortField]
          ? 1
          : -1
      } else {
        if (!a[sortField]) return 1
        if (!b[sortField]) return -1
        return isString
          ? b[sortField].localeCompare(a[sortField])
          : a[sortField] < b[sortField]
          ? 1
          : -1
      }
    })
  return sortedItems
}

const sortedItems = computed(() =>
  teachers.value ? sortItems(teachers.value.slice(), sort.value) : []
)
const changeSort = newSort => {
  const dir = sort.value[newSort]
    ? sort.value[newSort] === 'asc'
      ? 'desc'
      : 'asc'
    : 'asc'
  sort.value = { [newSort]: dir }
}

const lastActionDisplayMap = {
  'accepted-invitation': 'Accepted Invite',
  'created-class': 'Created New Class',
  'graded-assignment': 'Graded Assignment',
  'assigned-activity': 'Assigned Activity',
  'created-activity': 'Created Activity',
  'added-activity-to-library': 'Added Activity to Library',
  'created-class-lti-connection': 'Created LTI Connection'
}

onBeforeMount(async () => {
  await getUsageData()
})

watch(
  () => props.license,
  () => {
    getUsageData()
  }
)
</script>

<style lang="scss" scoped>
.status-column {
  min-width: 200px;
}
.status-label {
  position: relative;
}

.status-complete {
  color: $color-success;
}
.status-2 {
  color: #e30e62;
}
.status-3 {
  color: #f1af51;
}
.status-4 {
  color: #5a3f85;
}
.status-5 {
  color: #16b8b7;
}

.status-6 {
  color: #b7b7b7;
}
</style>
