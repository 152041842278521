<template>
  <form v-bind="attrs" class="async-form" novalidate @submit="submit">
    <slot v-bind="meta" />
  </form>
</template>

<script>
import * as Vue from 'vue'
import { useForm } from 'vee-validate'

export default {
  name: 'AsyncForm',
  emits: ['submit'],
  inheritAttrs: false,
  props: {
    persist: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { values, handleSubmit, meta, isSubmitting, resetForm, errors } =
      useForm()

    return {
      errors,
      values,
      handleSubmit,
      meta,
      isSubmitting,
      resetForm
    }
  },
  provide() {
    return {
      form: Vue.computed(() => ({
        action: this.action,
        isSubmitting: this.isSubmitting,
        wasSuccessful: this.wasSuccessful,
        setAction: this.setAction
      }))
    }
  },
  data() {
    return {
      action: 'submit',
      wasSuccessful: false
    }
  },
  computed: {
    attrs() {
      const { onFail, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    reset() {
      this.resetForm()
    },
    async submit(e) {
      const validCallback = async (values, { resetForm }) => {
        const result = await new Promise(resolve => {
          this.$emit('submit', {
            values,
            action: this.action,
            done: (result = true) => resolve(result)
          })
        })
        if (result) {
          this.wasSuccessful = !!result
          if (!this.persist) {
            resetForm()
          }
        }
      }
      const invalidCallback = form => {
        if (this.$attrs.onFail) {
          this.$attrs.onFail(form)
        } else {
          this.$error('Please correct validation errors before continuing.')
        }
      }
      this.$clearFlash()
      if (e === true) {
        await validCallback(this.values, { resetForm: this.resetForm })
      } else {
        await this.handleSubmit(validCallback, invalidCallback)(e)
      }
    },
    setAction(action) {
      if (!this.isSubmitting) {
        this.action = action
        this.wasSuccessful = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.async-form {
  margin: 16px 0 0 0;
}
</style>
