<template>
  <browse-activities
    v-slot="{ library, updateQuery }"
    ref="browseActivities"
    :query="computedQuery"
  >
    <view-container>
      <sticky-header>
        <template #primary-navigation>
          <breadcrumb v-if="klass">
            <breadcrumb-item
              :to="{
                name: 'classes'
              }"
              >My Classes
            </breadcrumb-item>
            <breadcrumb-item
              :to="{
                name: 'existing_class',
                params: { id: klass.id }
              }"
              >{{ klass.name }}
            </breadcrumb-item>
          </breadcrumb>
        </template>
        <template #title>
          <sticky-header-title>
            {{
              library && library.name
                ? `Assign: ${library.name}`
                : 'Assign Activity'
            }}
          </sticky-header-title>
        </template>
        <template #sub-title>
          <sticky-header-sub-title>
            <sticky-header-libraries>
              <sticky-header-shared-libraries />
            </sticky-header-libraries>
          </sticky-header-sub-title>
        </template>
        <template #actions>
          <activity-search-action :query="computedQuery" />
        </template>
      </sticky-header>
      <library-description :library="library" />
      <library-filters
        :modelValue="computedQuery"
        @update:modelValue="updateQuery"
      />
      <filter-chips
        :modelValue="computedQuery"
        @update:modelValue="updateQuery"
      />
      <browse-summary />
      <div class="activity-view__settings">
        <activity-sort-action />
        <activity-search-view-settings />
      </div>
      <activity-list>
        <template #card="{ activity }">
          <activity-card :activity="activity">
            <activity-card-assign-actions @assign="assignActivity" />
          </activity-card>
        </template>
        <template #row="{ activity }">
          <activity-row :activity="activity">
            <activity-row-assign-actions @assign="assignActivity" />
          </activity-row>
        </template>
      </activity-list>
    </view-container>
  </browse-activities>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import client from 'src/shared/api-client'
import BrowseActivities from '../components/BrowseActivities'
import BrowseSummary from '../components/BrowseSummary'
import ActivityList from '../components/ActivityList'
import ActivityCard from '../components/ActivityCard'
import ActivityRow from '../components/ActivityRow.vue'
import StickyHeaderLibraries from '../components/StickyHeaderLibraries'
import StickyHeaderSharedLibraries from '../components/StickyHeaderSharedLibraries'
import ActivitySearchAction from '../components/ActivitySearchAction'
import ActivitySortAction from '../components/ActivitySortAction'
import LibraryDescription from '../components/LibraryDescription'
import LibraryFilters from '../components/filters/LibraryFilters'
import FilterChips from '../components/FilterChips'
import ActivitySearchViewSettings from '../components/ActivitySearchViewSettings.vue'
import ActivityCardAssignActions from '../components/ActivityCardAssignActions'
import ActivityRowAssignActions from '../components/ActivityRowAssignActions'

export default {
  name: 'AssignActivitiesView',
  inject: ['$modal'],
  components: {
    BrowseActivities,
    ActivitySearchViewSettings,
    BrowseSummary,
    ActivityList,
    ActivityCard,
    ActivityRow,
    StickyHeaderLibraries,
    StickyHeaderSharedLibraries,
    ActivitySearchAction,
    ActivitySortAction,
    LibraryFilters,
    LibraryDescription,
    FilterChips,
    ActivityCardAssignActions,
    ActivityRowAssignActions
  },
  props: {
    classId: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      klass: undefined,
      isLoading: false,
      showInputBox: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    computedQuery() {
      return {
        ...this.query,
        page: parseInt(this.query.page) || 1,
        sort: this.query.sort || 'updatedAt',
        dir: this.query.dir || 'desc',
        q: this.query.q || '',
        categories: Object.entries(this.query.categories || {}).reduce(
          (filters, [key, value]) => {
            if (value) {
              filters[key] = Array.isArray(value) ? value : [value]
            }
            return filters
          },
          {}
        )
      }
    }
  },
  methods: {
    async loadClass() {
      const body = await client.classes.get({ classId: this.classId })
      this.klass = body
    },
    async assignActivity(activityId) {
      this.$router.push({
        name: 'new_assignment',
        query: {
          classIds: this.klass?.id ? [this.klass.id] : [],
          activityIds: [activityId]
        }
      })
    }
  },
  watch: {
    id: {
      async handler() {
        this.isLoading = true
        this.loadClass()
        this.isLoading = false
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-view__settings {
  display: flex;
  gap: 30px;
  align-content: center;
  align-items: center;

  & form-buttom {
    height: 24px;
  }
}
.activity-row__actions {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
</style>
