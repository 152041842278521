<template>
  <div class="numerical-answer-container">
    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="flex">
            <number-input
              :aria-labelledby="`component-${component._id}-label`"
              ref="popoverAnchorRef"
              v-model="value"
              class="numerical-question-response"
              :readonly="!canRespond || readonly"
              @mouseover="openPopover"
              @mouseout="closePopover"
              @focusin="openPopover"
              @focusout="closePopover"
              @keydown.enter="
                () => {
                  if (canAutograde && canSubmit) {
                    $emit('submit')
                  }
                }
              "
              step="any"
              responseInput
            />
            <span class="units">{{ component.units }}</span>
          </div>
          <div v-if="hasDependency" class="text-sm mt-2">
            The answer to this question depends on your answers to previous
            questions.
          </div>
        </div>
        <numerical-answer-key
          :component="inherited.component"
          :response="inherited.componentResponse"
          class="numerical-answer-key"
          v-if="showCorrectAnswer"
          :variable-context="variableContext"
          :grading="grading"
        />
        <base-popover
          :anchor-ref="popoverAnchorRef"
          :visible="studentResponsePopoverMessage && popoverVisible"
          placement="bottom"
        >
          {{ studentResponsePopoverMessage }}
        </base-popover>

        <dependency-popover
          v-if="dependencyVisible"
          :anchor-ref="popoverAnchorRef"
          :grading="grading"
        >
        </dependency-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DependencyPopover from './DependencyPopover'
import NumericalAnswerKey from 'src/shared/components/NumericalAnswerKey.vue'
import debounce from 'lodash/debounce'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'

export default {
  name: 'ActivityResponseNumerical',
  inject: ['inherited'],
  emits: ['submit', 'canSubmit'],
  components: { DependencyPopover, NumericalAnswerKey },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    showCorrectAnswer: {
      type: Boolean,
      default: false
    },
    grading: {
      type: Boolean,
      default: false
    },
    variableContext: {
      type: Object,
      default: undefined
    },
    canSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverAnchorRef: null,
      popoverVisible: false,
      dependencyVisible: false
    }
  },
  computed: {
    ...mapGetters(['isStudent']),

    correctAnswer() {
      return this.component.answer ? parseInt(this.component.answer) : null
    },
    component() {
      return this.inherited.component
    },
    componentResponse() {
      return this.inherited.componentResponse
    },
    canRespond() {
      return this.inherited.canRespond
    },
    studentResponsePopoverMessage() {
      return this.inherited.studentResponsePopoverMessage
    },
    canAutograde() {
      return (
        !this.inherited.isGrading &&
        this.component.autograde &&
        this.canRespond &&
        !this.readonly &&
        this.inherited.response?.assignment?.studentFeedbackTiming ===
          AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
      )
    },
    hasValueToSubmit() {
      return this.value !== undefined && this.value !== null
    },
    hasDependency() {
      return (
        this.component.dependencies && this.component.dependencies.length > 0
      )
    },
    value: {
      get() {
        return this.componentResponse.value
      },
      set(value) {
        this.updateValue(value)
      }
    }
  },

  mounted() {
    this.popoverAnchorRef = this.$refs.popoverAnchorRef
  },

  methods: {
    updateValue: debounce(function (value) {
      this.inherited.updateResponse({ value })
    }, 50),
    closePopover() {
      this.dependencyVisible = false
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = false
      }
    },
    openPopover() {
      this.dependencyVisible = true
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = true
      }
    }
  },
  watch: {
    hasValueToSubmit: {
      handler(newValue) {
        this.$emit('canSubmit', newValue)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.numerical-answer-container {
  margin-bottom: 15px;
  & > div > div {
    display: flex;
    .numerical-answer-key {
      margin-left: auto;
    }
  }
}

.numerical-question-response {
  &--readonly {
    border: #ccc solid 1px;
    border-radius: 8px;
    padding: 10px 15px;
    width: 150px;
  }
}
.units {
  padding-left: 15px;
}

.numerical {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $screen-md) {
    flex-direction: row;
  }

  & .numerical-answer {
    flex-grow: 1;
    margin-bottom: 5px;

    @media (min-width: $screen-md) {
      max-width: 48%;
    }
  }

  & .answer-heading {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
