<template>
  <div class="correct-default-answer">
    <form-group class="section-insight">
      <template v-if="component.componentType === 'OpenEndedQuestion'">
        <label class="control-label" for="component.answer">
          Correct Answer
        </label>
        <editor
          :text="component.answer"
          :readonly="!isVetted"
          :help-text="
            !isVetted
              ? 'This field is disabled while your account is pending.'
              : ''
          "
          :variable-context="variableContext"
          :rules="{
            undefinedVariables: true
          }"
          variables
          :name="`${name}.answer`"
          @input="answer => emitChange({ answer })"
        />
      </template>
      <tab-provider v-else tab="activity-correct-answer" disable-scroll>
        <div class="activity_tab-spacer">
          <tab-header>
            <tab-header-button tab="activity-correct-answer">
              Correct Answer
            </tab-header-button>
            <tab-header-button tab="activity-default-answer">
              Pre-populated Values
            </tab-header-button>
          </tab-header>
          <form-button
            secondary
            class="activity_graph-settings"
            @click="openGraphSettingsModal"
          >
            <icon icon="gear" />
            {{ settingsButtonTitle }}
          </form-button>
        </div>
        <tab-panel>
          <!-- Correct Answer -->
          <template #activity-correct-answer>
            <div class="activity-correct-answer">
              <div>
                <data-grid-pojo-adapter
                  v-if="component.componentType.includes('Grid')"
                  v-model="graphAnswer"
                  :edit-mode="true"
                  :settings="component.settings"
                  :graph="component.componentType === 'GridGraphQuestion'"
                  :class="{
                    'data-grid': true,
                    'data-grid-graph':
                      component.componentType === 'GridGraphQuestion'
                  }"
                  :allow-reset="true"
                  :allow-resize="true"
                  :variable-context="variableContext"
                  @reset="onResetCorrectAnswer"
                />
              </div>
            </div>
          </template>
          <template #activity-default-answer>
            <!-- Default Value -->
            <div
              v-if="component.componentType.indexOf('Grid') !== -1"
              class="default-answer"
            >
              <data-grid-pojo-adapter
                v-if="component.componentType.includes('Grid')"
                v-model="graphDefaultValue"
                :edit-mode="true"
                :settings="component.settings"
                :graph="component.componentType === 'GridGraphQuestion'"
                :class="{
                  'data-grid': true,
                  'data-grid-graph':
                    component.componentType === 'GridGraphQuestion'
                }"
                :allow-reset="true"
                :allow-resize="true"
                :variable-context="variableContext"
                @reset="onResetDefaultValue"
              />
            </div>
          </template>
        </tab-panel>
      </tab-provider>
    </form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataGridPojoAdapter from 'src/shared/components/grid-graph/DataGridPojoAdapter'
import {
  defaultGridValue,
  defaultGraphValue,
  convertDataFormat
} from 'src/shared/components/grid-graph/utilities'
import GraphSettingsModal from 'src/shared/components/modals/GraphSettingsModal.vue'

export default {
  name: 'CorrectAndDefaultAnswer',
  inject: ['$modal'],
  components: {
    DataGridPojoAdapter
  },
  emits: ['change'],
  props: {
    component: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    variableContext: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isVetted']),
    settingsButtonTitle() {
      return this.component.componentType.includes('Graph')
        ? 'Table & Graph Settings'
        : 'Table Settings'
    },
    graphAnswer: {
      get() {
        try {
          const parsed = JSON.parse(this.component.answer)
          return convertDataFormat(parsed)
        } catch (e) {
          if (this.component.componentType === 'GridGraphQuestion') {
            return {
              ...defaultGridValue(),
              graphs: [{ ...defaultGraphValue() }]
            }
          }

          return { ...defaultGridValue() }
        }
      },
      set(value) {
        let answer
        try {
          answer = JSON.stringify(value)
        } catch (e) {
          answer = '{}'
        }
        this.emitChange({ answer })
      }
    },
    graphDefaultValue: {
      get() {
        try {
          const parsed = JSON.parse(this.component.defaultValue)

          return convertDataFormat(parsed)
        } catch (e) {
          if (this.component.componentType === 'GridGraphQuestion') {
            return {
              ...defaultGridValue(),
              graphs: [{ ...defaultGraphValue() }]
            }
          }

          return { ...defaultGridValue() }
        }
      },
      set(value) {
        let defaultValue
        try {
          defaultValue = JSON.stringify(value)
        } catch (e) {
          defaultValue = '{}'
        }
        this.emitChange({ defaultValue })
      }
    }
  },
  methods: {
    emitChange(fields) {
      this.$emit('change', fields)
    },
    onResetCorrectAnswer() {
      this.emitChange({ answer: '{}' })
    },
    onResetDefaultValue() {
      this.emitChange({ defaultValue: '{}' })
    },
    async openGraphSettingsModal() {
      const { data } = await this.$modal.show(GraphSettingsModal, {
        settings: this.component.settings,
        tableOnly: !this.component.componentType.includes('Graph')
      })
      if (data?.settings) this.emitChange({ settings: data.settings })
    }
  }
}
</script>

<style lang="scss" scoped>
$border: 1px solid $teal;

.activity_tab-spacer {
  display: flex;
  justify-content: space-between;
}
.correct-default-answer {
  margin: 10px;

  padding: 0 0 10px 0;

  & label {
    font-weight: 600;
  }

  .activity-correct-answer,
  .default-answer {
    padding: 30px;
    background-color: $extra-light-teal;
  }

  .section-insight {
    margin: 0;
  }
}

.activity-correct-answer__section {
  display: flex;
  flex-direction: column;
}
.activity-correct-answer__label-button {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 10px 0;
}
:deep(.tab-header .tab-header__tab--active) {
  background-color: $extra-light-teal;
  border: none;
}
</style>
