import VernierBluetoothDevice from './VernierBluetoothDevice'
import PascoBluetoothDevice from './PascoBluetoothDevice'
import { isBluetoothSupported } from 'src/shared/utils/system-compatibility'

const bluetoothScanFilters = [
  ...VernierBluetoothDevice.namePrefixes.map(namePrefix => ({ namePrefix })),
  ...PascoBluetoothDevice.namePrefixes.map(namePrefix => ({ namePrefix }))
]

const optionalServices = [
  ...VernierBluetoothDevice.optionalServices,
  ...PascoBluetoothDevice.optionalServices
]

const connectDevice = async () => {
  if (!isBluetoothSupported) {
    throw new DOMException(
      `Your web browser does not support the Web Bluetooth API`
    )
  }

  const nativeDevice = await window.navigator.bluetooth.requestDevice({
    filters: bluetoothScanFilters,
    optionalServices: optionalServices
  })

  if (!nativeDevice) throw new DOMException(`No device selected.`)

  const { name } = nativeDevice
  if (VernierBluetoothDevice.namePrefixes.some(np => name.startsWith(np))) {
    const vernierBluetoothDevice = new VernierBluetoothDevice(nativeDevice)
    await vernierBluetoothDevice.init()

    return vernierBluetoothDevice
  } else if (
    PascoBluetoothDevice.namePrefixes.some(np => name.startsWith(np))
  ) {
    const pascoBluetoothDevice = new PascoBluetoothDevice(nativeDevice)
    await pascoBluetoothDevice.init()

    return pascoBluetoothDevice
  }
}

export { connectDevice }
