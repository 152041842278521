<template>
  <div>
    <message-bar
      v-if="isImpersonating"
      :message="impersonatingMessage"
      message-name="Impersonating User"
      @close="closeImpersonatingMessage"
    />
    <message-bar
      v-if="!instructorStatusMessageClosed && instructorStatusMessage"
      :message="instructorStatusMessage"
      message-name="Instructor Status"
      data-testid="status-notification"
      @close="instructorStatusMessageClosed = true"
    />
    <slot />
  </div>
</template>

<script>
import MessageBar from 'src/shared/components/message-bar/MessageBar'
import { mapGetters, mapState, mapActions } from 'vuex'
import { isValidSystem } from 'src/shared/utils/system-compatibility'
import client from '../../api-client'

export default {
  name: 'MessageBars',
  components: { MessageBar },
  data() {
    return {
      instructorStatusMessageClosed: false,
      systemIncompatibleMessageClosed: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    ...mapGetters([
      'isAuthenticated',
      'hasPendingLicenseInvites',
      'isLicenseExpiring',
      'hasSiteLicense',
      'isLicenseActive',
      'isImpersonating'
    ]),
    instructorStatusMessage() {
      if (this.hasPendingLicenseInvites) {
        return {
          message:
            "You've been invited to a license. Click here to accept or decline",
          link: { name: 'edit_profile', hash: '#license' }
        }
      } else if (this.isLicenseExpiring) {
        return {
          message:
            'Your license is expiring soon. Click here for more information.',
          link: { name: 'teacher_dashboard' }
        }
      } else if (this.isAuthenticated && !this.isLicenseActive) {
        return {
          message: `Your license is no longer active. Click here for more information.`,
          link: { name: 'teacher_dashboard' }
        }
      }

      return null
    },
    systemIncompatibleMessage() {
      if (!isValidSystem()) {
        return {
          message:
            'You may not meet the minimum system requirements for Pivot Interactives.  Click here for more information.',
          link: {
            name: 'system_requirements'
          }
        }
      }

      return null
    },
    impersonatingMessage() {
      return {
        message:
          'You are impersonating this user. Close this message to stop impersonating and return to your account.'
      }
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    async closeImpersonatingMessage() {
      await client.users.stopImpersonating()
      await this.refreshAuth()
      const url = window.sessionStorage.getItem('previous_url')
      if (url) {
        window.location.href = url
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>
