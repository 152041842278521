import { createStore } from 'vuex'
import auth from './modules/auth'
import classes from './modules/classes'
import version from 'src/setup/store/modules/version'
import instances from 'src/setup/store/modules/instances'
import features from 'src/setup/store/modules/features'
import cloneDeep from 'lodash/cloneDeep'

export const createAppStore = () =>
  createStore(
    cloneDeep({
      modules: {
        auth,
        classes,
        version,
        instances,
        features
      },
      strict: PI_ENV !== 'production'
    })
  )
