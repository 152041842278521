<template>
  <div v-if="showPopover" ref="popoverRef" class="base-popover" role="tooltip">
    <div class="base-popover__arrow" ref="arrowRef" />
    <slot />
  </div>
</template>

<script setup>
import { computed, watchPostEffect, ref } from 'vue'
import {
  computePosition,
  autoUpdate,
  flip,
  shift,
  offset,
  arrow
} from '@floating-ui/dom'

const props = defineProps({
  anchorRef: {
    type: [HTMLElement, Object]
  },
  visible: {
    type: Boolean,
    default: false
  },
  placement: {
    type: String,
    default: 'bottom'
  },
  strategy: {
    type: String,
    default: 'absolute'
  }
})

const showPopover = computed(() => !!props.anchorRef && props.visible)

const popoverRef = ref()
const arrowRef = ref()

watchPostEffect(onCleanup => {
  if (props.anchorRef && popoverRef.value && arrowRef.value) {
    const cleanup = autoUpdate(
      props.anchorRef.$el ?? props.anchorRef,
      popoverRef.value,
      async () => {
        if (props.anchorRef && popoverRef.value) {
          const { x, y, placement, middlewareData } = await computePosition(
            props.anchorRef.$el ?? props.anchorRef,
            popoverRef.value,
            {
              placement: props.placement,
              middleware: [
                offset(10),
                flip(),
                shift(),
                arrow({ element: arrowRef.value })
              ]
            }
          )
          if (popoverRef.value) {
            Object.assign(popoverRef.value.style, {
              left: `${x}px`,
              top: `${y}px`
            })
          }

          if (arrowRef.value) {
            const { x: arrowX, y: arrowY } = middlewareData.arrow
            const staticSide = {
              top: 'bottom',
              right: 'left',
              bottom: 'top',
              left: 'right'
            }[placement.split('-')[0]]
            Object.assign(arrowRef.value.style, {
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
              [staticSide]: '-4px'
            })
          }
        }
      }
    )

    onCleanup(cleanup)
  }
})
</script>

<style lang="scss" scoped>
$background-color: #414141;

.base-popover {
  width: max-content;
  background-color: $background-color;
  color: white;
  position: absolute;
  z-index: 50;
  border-radius: 6px;
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 15px 35px 0px rgba(51, 64, 82, 0.15),
    0 5px 15px rgba(0, 0, 0, 0.1);
}

.base-popover__arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
  transform: rotate(45deg);
  background-color: $background-color;
}
</style>
