import Auth from './auth'
import Users from './users'
import License from './licenses'
import Classes from './classes'
import Invitations from './invitations'
import Libraries from './libraries'
import Bookstore from './bookstore'
import Activities from './activities'
import Assets from './assets'
import Assignments from './assignments'
import Instances from './instances'
import Purchases from './purchases'
import Lti from './lti'
export interface FeatureFlagConfig {
  enabled: string[]
  disabled: string[]
}
interface ClientConfig {
  baseUrl: string
  featureFlags: FeatureFlagConfig
}
interface RequestOptions {
  method: string
  path: string
  headers?: HeadersInit
  body?: BodyInit
  baseUrl?: string
}

type MethodRequestOptions = Omit<RequestOptions, 'method'>

interface ApiRequestErrorConfig {
  status: number
  body: unknown
  request: Request
}

export class ApiRequestError extends Error {
  public status: number
  public body: unknown
  public request: Request

  constructor(config: ApiRequestErrorConfig) {
    super(`Request failed with status ${config.status}`)
    this.name = 'ApiRequestError'
    this.status = config.status
    this.body = config.body
    this.request = config.request
  }
}

export default class Client {
  readonly auth: Auth
  readonly users: Users
  readonly licenses: License
  readonly classes: Classes
  readonly invitations: Invitations
  readonly libraries: Libraries
  readonly bookstore: Bookstore
  readonly instances: Instances
  readonly activities: Activities
  readonly assets: Assets
  readonly assignments: Assignments
  readonly purchases: Purchases
  readonly lti: Lti

  private baseUrl: string
  private featureFlags: FeatureFlagConfig

  constructor(config: ClientConfig) {
    this.baseUrl = config.baseUrl
    this.featureFlags = {
      enabled: config.featureFlags.enabled,
      disabled: config.featureFlags.disabled
    }
    this.auth = new Auth(this)
    this.users = new Users(this)
    this.licenses = new License(this)
    this.classes = new Classes(this)
    this.invitations = new Invitations(this)
    this.libraries = new Libraries(this)
    this.bookstore = new Bookstore(this)
    this.instances = new Instances(this)
    this.activities = new Activities(this)
    this.assets = new Assets(this)
    this.assignments = new Assignments(this)
    this.purchases = new Purchases(this)
    this.lti = new Lti(this)

    if (!this.baseUrl) {
      throw new Error('Base URL is required in the client configuration.')
    }
  }

  private async makeRequest<ResponseBody = void>(
    options: RequestOptions
  ): Promise<{ body: ResponseBody; headers: Headers; status: number }> {
    const url = new URL(options.path, options.baseUrl ?? this.baseUrl)

    if (this.featureFlags.enabled.length > 0) {
      const enabledFeatureFlags = this.featureFlags.enabled.join(',')
      url.searchParams.append('_ff', enabledFeatureFlags)
    }

    if (this.featureFlags.disabled.length > 0) {
      const disabledFeatureFlags = this.featureFlags.disabled.join(',')
      url.searchParams.append('_xff', disabledFeatureFlags)
    }

    const request = new Request(url, {
      method: options.method,
      headers: options.body
        ? {
            'Content-Type': 'application/json',
            ...options.headers
          }
        : options.headers,
      body: options.body,
      credentials: 'include'
    })

    let response
    try {
      response = await fetch(request)
    } catch (error) {
      if (error instanceof TypeError) {
        throw new ApiRequestError({
          status: 0,
          body: '',
          request
        })
      } else {
        throw error
      }
    }

    const contentType = response.headers.get('content-type') ?? ''
    let body
    if (contentType.includes('application/json')) {
      body = await response.json()
    } else if (contentType.includes('text/')) {
      body = await response.text()
    }

    if (response.ok) {
      return { body, headers: response.headers, status: response.status }
    } else {
      throw new ApiRequestError({
        status: response.status,
        body,
        request
      })
    }
  }

  async request<ResponseBody = void>(options: RequestOptions) {
    return this.makeRequest<ResponseBody>(options)
  }

  async get<ResponseBody = void>(options: MethodRequestOptions) {
    return this.makeRequest<ResponseBody>({
      method: 'GET',
      ...options
    })
  }

  async post<ResponseBody = void>(options: MethodRequestOptions) {
    return this.makeRequest<ResponseBody>({
      method: 'POST',
      ...options
    })
  }

  async patch(options: MethodRequestOptions) {
    return this.makeRequest({
      method: 'PATCH',
      ...options
    })
  }

  async delete(options: MethodRequestOptions) {
    return this.makeRequest({
      method: 'DELETE',
      ...options
    })
  }

  async put(options: MethodRequestOptions) {
    return this.makeRequest({
      method: 'PUT',
      ...options
    })
  }

  async getVersion(): Promise<string> {
    const { body } = await this.get<{ version: string }>({
      baseUrl: window.location.origin,
      path: '/version'
    })
    return body.version
  }
}
