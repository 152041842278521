<template>
  <collapse-provider
    role="region"
    class="welcome-panel"
    v-slot="{ collapsed }"
    :aria-labelledby="`${id}-header`"
  >
    <slot :collapsed="collapsed" />
  </collapse-provider>
</template>

<script setup>
import { provide } from 'vue'

const id = `dashboard-panel-${Math.floor(Math.random() * 1000000)}`
provide('dashboard-panel', { id })
</script>

<style scoped lang="scss">
.welcome-panel {
  margin-bottom: 22px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  padding-left: 15px;
  padding-bottom: 20px;
}
</style>
