<template>
  <modal class="changelog-entry-modal" @submit="submit">
    <modal-header>Changelog Entry</modal-header>
    <modal-body>
      <form-group>
        <form-label for="heading"> Heading </form-label>
        <div>
          <text-input
            id="title"
            v-model="changelogEntry.title"
            label="title"
            data-focus
          />
        </div>
      </form-group>
      <div>
        <form-group class="margin-r">
          <form-label for="action">Content</form-label>
          <div>
            <editor v-model:text="changelogEntry.text" prevent-stringify />
          </div>
        </form-group>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Save</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>
<script setup>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

import { ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const userId = store.getters.userId

const changelogEntry = ref({
  createdBy: userId,
  title: ''
})

const submit = e => {
  e.done(changelogEntry.value)
}
</script>
<style lang="scss" scoped>
.changelog-entry-modal {
  width: 650px;
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.margin-r {
  margin-right: 7px;
}
.margin-l {
  margin-left: 7px;
}
</style>
