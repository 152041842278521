<template>
  <modal @submit="submit">
    <modal-header>Add Members</modal-header>
    <modal-body>
      <template v-if="!useLink">
        <p>
          Enter one email address per line in the box below for each instructor
          you want to add to this library.
        </p>
        <form-group>
          <form-label id="permissions-label"> Permissions </form-label>
          <div>
            <selector-input
              v-model="permissions"
              label="permissions"
              aria-labelledby="permissions-label"
              rules="required"
            >
              <selector-option value="admin" title="Admin" />
              <selector-option value="editor" title="Editor" />
              <selector-option value="viewer" title="Viewer" />
            </selector-input>
          </div>
        </form-group>
        <form-group>
          <form-label for="emails">Email Addresses</form-label>
          <div>
            <multiline-text-input
              id="emails"
              :model-value="emails"
              label="emails"
              rows="4"
              rules="required|emailPerLine"
              @change="(input: any) => filterEmails(input)"
            />
          </div>
        </form-group>
      </template>
      <template v-else>
        <p>
          Copy the link below and share with each instructor you want to add to
          this library. Instructors will have view only access.
        </p>
        <form-group>
          <div>
            {{ link }}
            <icon-button
              label="Copy link to clipboard"
              icon="link"
              color="add-icon"
              @click.stop="copyLinkToClipboard"
            />
          </div>
        </form-group>
      </template>
      <form-group>
        <toggle :toggle="useLink" @toggle="toggleUseLink">
          <template #on>Invite by email</template>
          <template #off>Use link to invite members</template>
        </toggle>
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Add</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import { useFlash } from 'src/shared/hooks/flash'
import { addProtocolAndHost } from 'src/shared/utils/environment-tools'
import { computed, ref } from 'vue'

const props = defineProps({
  libraryId: {
    type: String,
    required: true
  }
})
const flash = useFlash()
const emails = ref('')
const permissions = ref('viewer')
const useLink = ref(false)

const link = computed(() => {
  return addProtocolAndHost(`join-library?library=${props.libraryId}`)
})

const toggleUseLink = () => {
  useLink.value = !useLink.value
}

const filterEmails = (input: string) => {
  const emailList = [...new Set(input.split('\n').map(email => email.trim()))]
    .map(email => email.trim())
    .filter(email => email)
    .join('\n')
  emails.value = emailList
}

const submit = async (e: any) => {
  try {
    e.done({
      emails: !useLink.value ? emails.value.trim().split('\n') : [],
      permissions: permissions.value
    })
  } catch (err) {
    e.done(false)
    throw err
  }
}

const copyLinkToClipboard = () => {
  navigator.clipboard.writeText(link.value)
  flash.success('Link copied to clipboard')
}
</script>
