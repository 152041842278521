<template>
  <view-container class="oauth-login-view" narrow>
    <loading-container :loading="loading">
      <view-header class="oauth-login-header">
        <view-title>Log In</view-title>
      </view-header>
      <div class="login-copy">
        <p>Please log in to your Pivot Interactives account to continue.</p>
      </div>
      <async-form @submit="onSubmit">
        <form-group>
          <form-label for="email"> Email address </form-label>
          <text-input
            id="email"
            v-model="email"
            rules="required|email"
            type="email"
            autocomplete="email"
            label="email"
          />
        </form-group>

        <form-group>
          <form-label for="password"> Password </form-label>
          <text-input
            id="password"
            v-model="password"
            rules="required"
            label="password"
            type="password"
            autocomplete="current-password"
          />
        </form-group>

        <form-group class="submit-group">
          <submit-button>
            <template #default>Log In</template>
            <template #submitting>Logging In</template>
            <template #submitted>Logged In</template>
          </submit-button>
          <span>
            <router-link :to="{ name: 'forgot_password' }">
              Forgot password
            </router-link>
          </span>
        </form-group>
      </async-form>
    </loading-container>
  </view-container>
</template>

<script>
/* eslint-disable vue/prop-name-casing */
import { mapMutations, mapGetters } from 'vuex'
import client from 'src/shared/api-client'
import { OAUTH_MODE } from 'src/setup/store/mutation-types'

export default {
  name: 'OAuthLogin',
  props: {
    response_type: {
      type: String,
      default: undefined
    },
    client_id: {
      type: String,
      default: undefined
    },
    redirect_uri: {
      type: String,
      default: undefined,
      required: true
    },
    state: {
      type: String,
      default: undefined
    },
    response_mode: {
      type: String,
      default: undefined
    },
    scope: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false
    }
  },
  created() {
    this.oauthMode()
  },
  mounted() {
    if (this.isAuthenticated()) {
      this.loading = true
      this.authorize()
    }
  },
  methods: {
    ...mapMutations({
      oauthMode: OAUTH_MODE
    }),
    ...mapGetters(['isAuthenticated']),
    async authorize() {
      const oauthResponse = await client.auth.oauthAuthorize({
        email: this.email,
        password: this.password,
        ...this.$props
      })
      if (oauthResponse.code && oauthResponse.redirect_uri) {
        this.loading = true
        window.location = oauthResponse.redirect_uri
      } else {
        this.loading = false
      }
    },
    async onSubmit(e) {
      try {
        await this.authorize()
        e.done()
      } catch (error) {
        this.loading = false
        this.handleLoginError(error)
        e.done(false)
      }
    },
    handleLoginError(error) {
      if (error.status === 401) {
        const httpError = error.body?.errors?.[0]?.message ?? ''
        const isSuspended = httpError.includes(
          'Your account has been suspended'
        )

        if (isSuspended) {
          this.$error(
            'Your account has been suspended. Please contact support for more information.'
          )
        } else {
          this.$error(httpError)
        }
      } else {
        throw error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.oauth-login-header {
  margin-bottom: 1rem;
}
.oauth-login-view {
  margin-top: 5rem;
}
.login-copy {
  margin-bottom: 3rem;
}
</style>
