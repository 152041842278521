<template>
  <modal class="data-grid-column-settings-modal" @submit="submit">
    <modal-header>Column Settings</modal-header>
    <modal-body>
      <div>
        <form-group v-if="!allowText">
          <form-label>Number Display Format</form-label>
          <select-field
            v-model="format"
            aria-describedby="select column data formatting option"
          >
            <option value="auto">No Formatting</option>
            <option value="scientific">Scientific Notation</option>
            <option value="decimals">Decimal Places</option>
            <option value="sigfigs">Significant Figures</option>
          </select-field>
        </form-group>
        <form-group v-if="!allowText && format === 'decimals'">
          <form-label>Number of Decimal Places</form-label>
          <select-field
            v-model="decimalPlaces"
            aria-describedby="select number of decimals places to format column data"
          >
            <option v-for="n in 11" :key="n" :value="n - 1">
              {{ n - 1 }}
            </option>
          </select-field>
        </form-group>
        <form-group v-if="!allowText && format === 'sigfigs'">
          <form-label>Number of Significant Figures</form-label>
          <select-field
            v-model="significantFigures"
            aria-describedby="select number of significant figures to format column data"
          >
            <option v-for="n in 10" :key="n" :value="n">
              {{ n }}
            </option>
          </select-field>
        </form-group>
        <form-group>
          <form-label>Allow Text Data</form-label>
          <p>
            Checking this box allows any text to be entered in a cell, but this
            column will be unavilable to use in column formulas and graph axes.
          </p>
          <checkbox v-model="allowText">Allow Text Data</checkbox>
        </form-group>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Done</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

export default {
  name: 'DataGridColumnSettingsModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  props: {
    column: Object
  },
  data() {
    const { format, decimalPlaces, significantFigures, allowText } = this.column

    return {
      format,
      decimalPlaces,
      significantFigures,
      allowText
    }
  },
  methods: {
    async submit(e) {
      const { format, decimalPlaces, significantFigures, allowText } = this

      e.done({
        format,
        decimalPlaces,
        significantFigures,
        allowText
      })
    }
  }
}
</script>
