<template>
  <modal>
    <modal-header>Confirm</modal-header>
    <modal-body>
      <p>
        You have unsubmitted work for the following question<span
          v-if="componentsNeedingSubmission.length > 1"
          >s</span
        >:
      </p>
      <ul class="confirm-modal__questions">
        <li
          :key="index"
          v-for="(component, index) in componentsNeedingSubmission"
        >
          <p class="confirm-modal__question">
            Section {{ component.sectionNumber }} Question
            {{ component.questionNumber }}
          </p>
        </li>
      </ul>
      <strong
        >If you submit your work, your unsaved responses will be lost. Do you
        wish to proceed?</strong
      >
    </modal-body>
    <modal-actions>
      <modal-button-ok>Yes</modal-button-ok>
      <modal-button-cancel>No</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonOk,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import { computed } from 'vue'

const props = defineProps({
  componentIdsNeedingSubmission: {
    type: Set,
    required: true
  },
  activity: {
    type: Object,
    required: true
  }
})

const componentsNeedingSubmission = computed(() => {
  return Array.from(props.componentIdsNeedingSubmission.entries()).map(
    ([componentId, _]) => {
      const { sectionNumber, questionNumber } = props.activity.sections.reduce(
        (acc, section, index) => {
          const component = section.components.find(comp => {
            return comp._id === componentId
          })
          if (component) {
            return {
              sectionNumber: section.sectionNumber,
              questionNumber: component.questionNumber
            }
          }
          return acc
        },
        { sectionNumber: 0, questionNumber: 0 }
      )
      return { sectionNumber, questionNumber }
    }
  )
})
</script>
<style scoped lang="scss">
.confirm-modal__questions {
  padding-left: 10px;
}
.confirm-modal__questions > li {
  list-style-type: none;
}

.confirm-modal__question {
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}
</style>
