<template>
  <div>
    <div class="max-w-96">
      <UILabel class="text-lg" for="selectedStudents"
        >Search for students to add to the assignment</UILabel
      >
      <div class="relative py-2.5 min-h-6">
        <div v-if="model?.length">
          {{ selectedCount }} students selected: {{ studentsList }}
        </div>
        <div v-else>No students selected</div>
      </div>
      <UICombobox
        multiple
        :options="rosterList"
        v-model="model"
        id="selectedStudents"
        name="selectedStudents"
        label="Selected Students"
        placeholder="Search students..."
      />
      <UIFieldError name="selectedStudents" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { UICombobox } from '@pi/design'

interface Props {
  roster: Array<{
    _id: string
    name: string
    [key: string]: any
  }>
  modelValue: Array<string>
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false
})

const emit = defineEmits<{
  'update:modelValue': [value: string[]]
}>()

const model = computed({
  get() {
    // Convert string IDs to full objects for the combobox
    return props.modelValue
      .map(id => rosterList.value.find(item => item.value === id))
      .filter(Boolean)
  },
  set(value) {
    // Convert full objects back to string IDs for the parent
    emit(
      'update:modelValue',
      value.map(v => v.value)
    )
  }
})

const rosterList = computed(() => {
  return props.roster.map(r => ({
    ...r,
    id: r._id,
    disabled: props.disabled,
    value: r._id,
    label: r.name
  }))
})

const studentsList = computed(() => {
  return model.value.map(student => student.label).join(', ')
})

const selectedCount = computed(() => {
  return model.value.length
})
</script>
