<template>
  <modal>
    <modal-header>Confirm</modal-header>
    <modal-body>
      <p>
        You have not <span v-if="!delayedFeedback">correctly </span>answered the
        following question(s):
      </p>
      <ul class="confirm-modal__questions" v-if="incorrectQuestions.length">
        <li :key="index" v-for="(question, index) in props.incorrectQuestions">
          <p class="confirm-modal__question">
            {{ question.questionNumber }}. {{ getText(question.text) }}
          </p>
        </li>
      </ul>
      <p>
        If you lock this section, you will not be able to go back and work on
        these questions.
      </p>
      <strong>Are you sure you want to lock the section and continue?</strong>
    </modal-body>
    <modal-actions>
      <modal-button-ok>Yes</modal-button-ok>
      <modal-button-cancel>No</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonOk,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import { getText } from 'src/shared/components/editor/utils'

const props = defineProps({
  incorrectQuestions: {
    type: Array,
    default: () => []
  },
  delayedFeedback: {
    type: Boolean,
    default: false
  }
})
</script>
<style scoped lang="scss">
.confirm-modal__questions {
  padding-left: 10px;
}
.confirm-modal__questions > li {
  list-style-type: none;
}

.confirm-modal__question {
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}
</style>
