<template>
  <modal>
    <modal-header>{{ title }}</modal-header>
    <modal-body v-if="html">
      <div v-html="sanitizedHtml" />
    </modal-body>
    <modal-body>{{ body }}</modal-body>
    <modal-actions><modal-button-ok /></modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonOk
} from 'src/shared/components/modals/components'
import DOMPurify from 'dompurify'
export default {
  name: 'AlertModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonOk
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: 'Are you sure?'
    },
    html: {
      type: String,
      default: ''
    }
  },
  computed: {
    sanitizedHtml() {
      return this.html && this.html !== '' ? DOMPurify.sanitize(this.html) : ''
    }
  }
}
</script>

<style scoped>
p.prompt {
  font-weight: bold;
  margin-bottom: 0;
}
</style>
