<template>
  <div v-if="showCorrectAnswer" class="grouped-open-ended">
    <div class="open-ended-answer">
      <span class="answer-heading">Student Response</span>
      <editor-content
        v-if="!canRespond || readonly"
        ref="popoverAnchorRef"
        :text="value"
        class="open-ended-response open-ended-response--readonly"
        @mouseover="openPopover"
        @mouseout="closePopover"
        @focusin="openPopover"
        @focusout="closePopover"
      />
      <editor
        v-else
        ref="popoverAnchorRef"
        v-model:text="value"
        class="open-ended-response"
        prevent-stringify
        :noupload="noupload"
        @mouseover="openPopover"
        @mouseout="closePopover"
        @focusin="openPopover"
        @focusout="closePopover"
      />
      <base-popover
        :anchor-ref="popoverAnchorRef"
        :visible="studentResponsePopoverMessage && popoverVisible"
        placement="bottom"
      >
        {{ studentResponsePopoverMessage }}
      </base-popover>
      <dependency-popover
        v-if="dependencyVisible"
        :anchor-ref="popoverAnchorRef"
        :grading="grading"
      >
      </dependency-popover>
    </div>
    <div class="open-ended-answer">
      <span class="answer-heading">Correct Answer</span>
      <editor
        v-model:text="correctAnswer"
        class="open-ended-response"
        :readonly="true"
        :variable-context="variableContext"
        prevent-stringify
      />
    </div>
  </div>
  <div v-else>
    <editor-content
      v-if="!canRespond || readonly"
      aria-label="student response"
      ref="popoverAnchorRef"
      :text="value"
      class="open-ended-response open-ended-response--readonly"
      @mouseover="openPopover"
      @mouseout="closePopover"
      @focusin="openPopover"
      @focusout="closePopover"
    />
    <editor
      v-else
      aria-label="student response"
      ref="popoverAnchorRef"
      v-model:text="value"
      class="open-ended-response"
      prevent-stringify
      :noupload="noupload"
      @mouseover="openPopover"
      @mouseout="closePopover"
      @focusin="openPopover"
      @focusout="closePopover"
    />
    <base-popover
      :anchor-ref="popoverAnchorRef"
      :visible="studentResponsePopoverMessage && popoverVisible"
      placement="bottom"
    >
      {{ studentResponsePopoverMessage }}
    </base-popover>
    <dependency-popover
      v-if="dependencyVisible"
      :anchor-ref="popoverAnchorRef"
      :grading="grading"
    >
    </dependency-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DependencyPopover from './DependencyPopover'
import debounce from 'lodash/debounce'

export default {
  name: 'ActivityResponseOpenEnded',
  inject: ['inherited'],
  components: { DependencyPopover },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    showCorrectAnswer: {
      type: Boolean,
      default: false,
      required: false
    },
    variableContext: {
      type: Object,
      required: true
    },
    grading: {
      type: Boolean,
      default: false
    },
    noupload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverAnchorRef: null,
      popoverVisible: false,
      dependencyVisible: false
    }
  },

  computed: {
    ...mapGetters(['isStudent']),
    correctAnswer() {
      return this.component.answer
    },
    component() {
      return this.inherited.component
    },
    componentResponse() {
      return this.inherited.componentResponse
    },
    canRespond() {
      return this.inherited.canRespond
    },
    studentResponsePopoverMessage() {
      return this.inherited.studentResponsePopoverMessage
    },
    canSubmit() {
      return !!this.componentResponse.value
    },
    value: {
      get() {
        if (this.componentResponse.value) {
          return this.componentResponse.value
        } else {
          return undefined
        }
      },
      set(value) {
        if (value) {
          this.updateValue(value)
        }
      }
    }
  },
  watch: {
    canSubmit: {
      immediate: true,
      handler(val) {
        this.$emit('canSubmit', val)
      }
    }
  },
  mounted() {
    this.popoverAnchorRef = this.$refs.popoverAnchorRef
  },

  methods: {
    updateValue: debounce(function (value) {
      this.inherited.updateResponse({ value })
    }, 50),
    closePopover() {
      this.dependencyVisible = false
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = false
      }
    },
    openPopover() {
      this.dependencyVisible = true
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.open-ended-response {
  width: 100%;

  &--readonly {
    border: #ccc solid 1px;
    border-radius: 8px;
    padding: 10px 15px;
  }
}

.grouped-open-ended {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $screen-md) {
    flex-direction: row;
  }

  & .open-ended-answer {
    flex-grow: 1;
    max-width: 100%;
    margin-bottom: 10px;

    @media (min-width: $screen-md) {
      max-width: 48%;
    }
  }

  & .answer-heading {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
