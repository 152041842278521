<template>
  <div v-if="component.isQuestion" class="question">
    <div class="question__body">
      <div class="prompt__container">
        <playback :element="question?.$el" class="playback_btn" />
        <div class="question__number">{{ component.questionNumber }}.</div>
        <template v-if="component.isRandomized && !viewAsStudent">
          <base-popover
            placement="top"
            :anchor-ref="popoverAnchorRef"
            :visible="popoverVisible"
            class="question-randomized--popover"
          >
            This question contains randomized values. Each student will be
            presented with different values for some of the numbers in the
            questions, which will result in different answers.
          </base-popover>
          <span class="pr-3" ref="popoverAnchorRef">
            <icon
              class="text-pivot-purple-600"
              icon="shuffle"
              @mouseover="openPopover"
              @mouseout="closePopover"
            />
          </span>
        </template>
        <editor-content
          :id="`component-${component._id}-label`"
          :text="component.text"
          :variable-context="variableContext"
          :view-as-student="viewAsStudent"
          class="question__prompt"
          ref="question"
        />
      </div>
      <div v-if="hasHint" class="hint-content">
        <form-button
          class="hint-toggle__btn"
          v-if="component.hideHint && !showHint"
          link
          @click="toggleHint"
        >
          {{ component.hintPrompt || 'I need a hint' }}
        </form-button>
        <form-button
          class="hint-toggle__btn"
          v-if="component.hideHint && showHint"
          link
          @click="toggleHint"
        >
          Hide Hint
        </form-button>
        <div v-show="showHint" class="prompt__container">
          <playback :element="hint" class="playback_btn" />
          <div ref="hint" class="question__hint-wrapper">
            <span class="question__hint-label">Hint:</span>
            <editor-content
              class="question__hint-body"
              :text="component.hint"
              :variable-context="variableContext"
              :view-as-student="viewAsStudent"
            />
          </div>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup>
import { hasContent } from 'src/shared/components/editor/utils.js'
import { ref, computed, inject } from 'vue'

const props = defineProps({
  variableContext: {
    type: Object,
    required: true
  },
  viewAsStudent: {
    type: Boolean,
    default: false
  }
})
const question = ref(null)
const hint = ref(null)
const popoverAnchorRef = ref(null)
const popoverVisible = ref(false)

const inherited = inject('inherited')
const component = computed(() => inherited.value.component)
const hasHint = computed(() => hasContent(component.value.hint))

const showHint = ref(!inherited.value.component.hideHint)

const toggleHint = () => {
  showHint.value = !showHint.value
}
const closePopover = () => {
  popoverVisible.value = false
}
const openPopover = () => {
  popoverVisible.value = true
}
</script>

<style lang="scss" scoped>
.question {
  margin-top: 16px;
}

.playback_btn {
  margin: 2px 8px 8px 0;
  flex-shrink: 0;
}

.prompt__container {
  display: flex;
  align-items: flex-start;
}
.question__number {
  margin: 0 8px 0 0;
  line-height: $line-height-base;
}

.question__body {
  width: 100%;
}

.question__prompt {
  min-width: 0;
}

.question__hint-wrapper {
  min-width: 0;
}

.question__hint-label {
  color: $pink;
  font-weight: bold;
  padding: 0;
  margin: 0 8px 0 0;
  float: left;
}

.question__hint-body {
  font-style: italic;
  overflow: hidden;
  padding-right: 5px;
}

.hint-content {
  padding-bottom: 10px;
  width: 100%;
  clear: both;
}

.hint-toggle__btn {
  display: inline-block;
}

.question__beta-flag {
  top: -16px;
}
</style>
