import datasheets from './datasheets.json'
import Device from './Device'

const { Interfaces, Sensors } = datasheets

// This is a confusing and specific thing that just is what
// it is :) PASCO had to create their own Base64 encoding because
// of some gotchya after going to production or something, so this
// is defining that encoding in an array. The 0-63 index converts an
// int to the correct character.
const Base64 = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '*',
  '#'
]

// Because with the Web Bluetooth API we only have access to the services
// we define in `optionalServices` during connection and each PASCO device
// can have a variable number of services, calculate the maximum number
// of virtual sensors to build `optionalServices` services list
const MAX_VIRTUAL_SENSORS = Object.keys(Interfaces).reduce((acc, key) => {
  const i = Interfaces[key]
  if (i.Channels.length > acc) {
    acc = i.Channels.length
  }
  return acc
}, 0)

const pasco = {
  // Used during BLE connection flow to include all PASCO devices
  // in the native device selection dialog.
  namePrefixes: Object.keys(Interfaces).map(
    key => Interfaces[key].AdvertisingName
  ),

  // Used during BLE connection flow to ensure we have access to
  // all services no matter which device is selected.
  optionalServices: Array(MAX_VIRTUAL_SENSORS + 1)
    .fill(null)
    .map((_, i) => `4a5c000${i}-0000-0000-0000-5c1e741f1c00`),

  // Create an instance of our `Device` wrapper from the nativeDevice
  // returned by the BLE connection flow.
  async createDevice(
    nativeDevice,
    { open = true, startMeasurments = false } = {}
  ) {
    // Parse Interface ID from device name.
    const interfaceId = `${
      Base64.indexOf(
        nativeDevice.name.substr(nativeDevice.name.indexOf('>') + 1, 1)
      ) + 1024
    }`

    // Lookup the Interface in the datasheets
    const interfaceData = Interfaces[interfaceId]

    if (!interfaceData) {
      throw new Error(`PASCO Interface not found in datasheets`)
    }

    // Lookup all the Sensors for the Interface in the datasheets
    const sensorData = interfaceData.Channels.reduce((acc, c) => {
      const s = Sensors[c.SensorID]
      if (s) {
        acc[s.ID] = s
      }
      return acc
    }, {})

    if (Object.keys(sensorData).length !== interfaceData.Channels.length) {
      throw new Error(`PASCO Sensor not found in datasheets`)
    }

    // Construct instance of `Device` wrapper
    const device = new Device(nativeDevice, interfaceData, sensorData)

    if (open) {
      try {
        await device.open(startMeasurments)
      } catch (error) {
        throw new Error(`Device Open Failed [${error}]`)
      }
    }

    return device
  }
}

export default pasco
