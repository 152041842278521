import { reactive, unref } from 'vue'
import { generateJSON } from '@tiptap/html'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import TextAlign from '@tiptap/extension-text-align'
import Latex from './latex/Latex'
import Image from './image/Image'
import DropTargetContext from './drop-target/DropTargetContext'
import Link from './link/Link'
import TableHeader from './table/TableHeader'
import TableCell from './table/TableCell'
import Table from './table/Table'
import TableRow from '@tiptap/extension-table-row'
import Focus from '@tiptap/extension-focus'
import { FileUpload } from './fileUpload'
import { Variable } from './variables'
import { Expression } from './expression'
import { Editor } from '@tiptap/core'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import Collaboration from '@tiptap/extension-collaboration'
import DropTarget from './drop-target/DropTarget'
import { InlineDocument } from './InlineDocument'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import { Slice, Fragment } from 'prosemirror-model'
import MP4Box from 'mp4box'
import { VocabTooltip } from './vocab-tooltip'
import { getVariablesFromLatex } from 'src/shared/utils/latex-methods'

export function getExtensions({
  includeHistory = false,
  openLinksOnClick,
  variableContext = null,
  fragment,
  includeDropTarget = false,
  noupload = false,
  inline = false,
  dropChoices,
  onTipActivated = null,
  viewAsStudent = false,
  toolTip = false
} = {}) {
  const latexExtension = Latex.configure()
  latexExtension.storage.viewAsStudent = viewAsStudent
  latexExtension.storage.toolTip = toolTip
  const nonInlineExtensions = [
    StarterKit.configure({
      history: includeHistory,
      blockquote: false,
      codeBlock: false,
      code: false,
      hardBreak: false,
      heading: false,
      horizontalRule: false,
      strike: false
    }),

    FileUpload.configure(),
    Link.configure({
      openOnClick: openLinksOnClick,
      autolink: false
    }),
    Table.configure({ resizable: true }),
    TableHeader,
    TableRow,
    TableCell,
    TextAlign.configure({
      types: ['tableCell', 'tableHeader']
    })
  ]
  const inlineExtensions = [InlineDocument, Text, Bold, Italic]
  const extensions = [
    Superscript,
    Subscript,
    Underline,
    Image.configure({ noupload }),
    Focus.configure({ className: 'focused-node' }),
    latexExtension,
    ...(!inline ? nonInlineExtensions : inlineExtensions),
    VocabTooltip.configure({ onTipActivated })
  ]

  if (fragment) {
    extensions.push(Collaboration.configure({ fragment }))
  }

  let dropTargetExtension
  if (includeDropTarget) {
    dropTargetExtension = DropTarget.configure()
    dropTargetExtension.storage.choices = reactive(dropChoices)
    dropTargetExtension.storage.targetPreviews = reactive({})
    dropTargetExtension.storage.viewAsStudent = viewAsStudent
    extensions.push(dropTargetExtension)
    extensions.push(DropTargetContext.configure())
  }

  if (unref(variableContext)) {
    const expressionExtension = Expression.configure()
    const variableExtension = Variable.configure()

    variableExtension.storage.variableContext = variableContext
    variableExtension.storage.viewAsStudent = viewAsStudent
    variableExtension.storage.toolTip = toolTip
    extensions.push(variableExtension)

    expressionExtension.storage.variableContext = variableContext
    expressionExtension.storage.viewAsStudent = viewAsStudent
    expressionExtension.storage.toolTip = toolTip
    extensions.push(expressionExtension)

    latexExtension.storage.variableContext = variableContext

    if (dropTargetExtension) {
      dropTargetExtension.storage.variableContext = variableContext
    }
  }

  return extensions
}

export function migrate(text, inlineDocument = false) {
  let json
  if (typeof text === 'string') {
    if (text.charAt(0) === '{') {
      json = JSON.parse(text)
    } else {
      const paragraphContent = []
      if (text !== '') {
        paragraphContent.push({ type: 'text', text })
      }
      return {
        type: 'doc',
        content: inlineDocument
          ? paragraphContent
          : [
              {
                type: 'paragraph',
                content: paragraphContent
              }
            ]
      }
    }
  } else {
    json = text
  }

  if (json?.ops) {
    const converter = new QuillDeltaToHtmlConverter(json.ops, {
      multiLineParagraph: false
    })
    converter.renderCustomWith(op => {
      if (op.insert.type === 'variable') {
        return `<span class="ql-var">${op.insert.value}</span>`
      } else {
        return ''
      }
    })
    const migrated = converter.convert()

    const jsonMigrated = generateJSON(
      migrated,
      getExtensions({ variableContext: { variables: [] } })
    )
    return jsonMigrated
  } else {
    return json
  }
}

export function getText(doc, variableContext = null, options = {}) {
  const editor = new Editor({
    content: migrate(doc, options.inline ?? false),
    extensions: getExtensions({
      variableContext,
      inline: options.inline ?? false
    })
  })
  return editor.getText()
}

export function hasContent(text) {
  const json = migrate(text)
  // In order to not be empty, at least one node in the document must have some content.
  return (
    json?.type === 'doc' &&
    (json.content ?? []).some(
      node => node.type !== 'paragraph' || node.content?.length > 0
    )
  )
}

export function isUsingVariable(text, variableId, isNode = true) {
  function processNode(node) {
    if (node.type === 'variable' && node.attrs.id === variableId) {
      return true
    } else if (Array.isArray(node.content)) {
      return (
        node.content.flatMap(node => processNode(node)).filter(Boolean).length >
        0
      )
    } else if (node.type === 'latex') {
      return (
        getVariablesFromLatex({
          formula: node.attrs.formula,
          variables: [{ id: variableId }]
        }).length > 0
      )
    }
    return false
  }
  if (!isNode) {
    if (!text) return false
    return text.includes(`$${variableId}`)
  }
  let responseJson = migrate(text)
  if (responseJson) {
    return processNode(responseJson)
  } else {
    return false
  }
}

export const mapFragment = (fragment, callback) =>
  Fragment.fromArray(
    fragment.content.map(node => {
      if (node.content.childCount > 0) {
        return node.type.create(node.attrs, mapFragment(node.content, callback))
      }

      return callback(node)
    })
  )

export const mapSlice = (slice, callback) => {
  const fragment = mapFragment(slice.content, callback)
  return new Slice(fragment, slice.openStart, slice.openEnd)
}

export const validateFile = (event, file) => {
  return new Promise((resolve, reject) => {
    const { name } = event.target.files[0]
    const extension = (
      name.substring(name.lastIndexOf('.') + 1, name.length) || name
    ).toLowerCase()

    if (extension !== 'mp4' && extension !== 'mov' && extension !== 'webm') {
      alert(
        'The file you selected is not the correct type. Please choose an MP4 or MOV video file.'
      )
      resolve(false)
    } else if (extension !== 'webm') {
      // Prevent uploading an H265-encoded video

      const fileReader = new FileReader()

      fileReader.readAsArrayBuffer(file)

      fileReader.addEventListener('load', e => {
        const buffer = fileReader.result

        buffer.fileStart = 0
        const mp4boxfile = MP4Box.createFile()

        mp4boxfile.onError = () => {
          reject(new Error('An error has occurred processing your video.'))
        }

        mp4boxfile.onReady = info => {
          const codecPrefixes = info.videoTracks.map(track => {
            return track.codec.slice(0, 3)
          })

          if (codecPrefixes.includes('hvc') || codecPrefixes.includes('hev')) {
            reject(
              new Error(
                'Video codec h265 is not supported. Please convert to h264 before uploading.'
              )
            )
          } else {
            resolve(true)
          }
        }
        mp4boxfile.appendBuffer(fileReader.result)
        mp4boxfile.flush()
      })
    } else {
      resolve(true)
    }
  })
}
