<template>
  <modal @submit="register">
    <modal-header>
      {{ platform }} LTI Registration
      <hyper-link :to="{ name: getHelpLink(this.platformData.type) }">
        <icon icon="question-circle" />
        <span class="sr-only">{{ platform }} Help</span>
      </hyper-link>
    </modal-header>
    <modal-body>
      <form-group v-if="showAdditionalFields">
        <form-label>Client Id</form-label>
        <text-input
          v-model="platformData.clientId"
          aria-label="Canvas Client ID"
          placeholder="Example: 10000000000014"
          label="client ID"
          rules="required"
        />
      </form-group>
      <form-group>
        <form-label>Deployment Id</form-label>
        <text-input
          v-model="platformData.deploymentId"
          aria-label="Canvas deployment ID"
          placeholder="Example: 10000000000014"
          label="deployment ID"
          rules="required"
        />
      </form-group>
      <form-group v-if="showAdditionalFields">
        <form-label>{{ platform }} Domain</form-label>
        <text-input
          v-model="platformData.domain"
          aria-label="Canvas Domain Name"
          placeholder="Example: canvas.instructure.com"
          label="domain name"
          :rules="{
            required: true,
            regex: /^((\w|\d|-)+\.)+(\w|\d|-)+(:\d+)?$/
          }"
        />
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Register</modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import client from 'src/shared/api-client'

export default {
  name: 'ltiModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonCancel,
    ModalButtonSubmit
  },
  props: {
    licenseId: {
      type: String,
      required: true
    },
    platform: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      platformData: {
        type: this.platform,
        clientId: '',
        deploymentId: '',
        domain: '',
        local: PI_IS_LOCAL
      }
    }
  },
  computed: {
    showAdditionalFields() {
      return !['Schoology', 'Blackboard'].includes(this.platform)
    }
  },
  methods: {
    async register(e) {
      try {
        await client.lti.connectLtiToLicense({
          licenseId: this.licenseId,
          ...this.platformData
        })
        this.$success(`${this.platform} successfully configured in Pivot.`)
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    getHelpLink(platformType) {
      switch (platformType) {
        case 'Canvas':
          return 'lti_canvas_collection'
        case 'D2L':
          return 'lti_d2l_collection'
        case 'Schoology':
          return 'lti_schoology_collection'
        case 'Blackboard':
          return 'lti_blackboard_collection'
        default:
          return 'help'
      }
    }
  }
}
</script>
