<template>
  <div v-if="showCorrectAnswer" class="grouped-multiple-choice">
    <div class="multiple-answer">
      <span class="answer-heading">Student Response</span>
      <div v-if="isSelectMany">Select all that apply:</div>
      <playback :element="$refs.popoverAnchorRef" class="playback_btn" />
      <ul
        ref="popoverAnchorRef"
        :class="`choice-response__choices choice-response__choices--${choicesPerRow}`"
        @mouseover="openPopover"
        @mouseout="closePopover"
        @focusin="openPopover"
        @focusout="closePopover"
      >
        <li v-for="choice in randomizedChoices" :key="choice.id">
          <component
            :is="inputComponent"
            v-model="value"
            :value="choice.id"
            :disabled="isDisabled"
            :name="inputName"
            class="choice-response__choice"
          >
            <editor-content
              :text="choice.text"
              :variable-context="variableContext"
              :view-as-student="viewAsStudent"
            />
          </component>
        </li>
      </ul>
      <base-popover
        :anchor-ref="popoverAnchorRef"
        :visible="studentResponsePopoverMessage && popoverVisible"
        placement="bottom"
      >
        {{ studentResponsePopoverMessage }}
      </base-popover>
      <dependency-popover
        v-if="dependencyVisible"
        :anchor-ref="popoverAnchorRef"
        :grading="grading"
      >
      </dependency-popover>
    </div>
    <div class="multiple-answer">
      <span class="answer-heading">Correct Answer</span>
      <div v-if="isSelectMany">Select all that apply:</div>
      <playback :element="$refs.choices" class="playback_btn" />
      <ul
        :class="`choice-response__choices choice-response__choices--${choicesPerRow}`"
        ref="choices"
      >
        <li v-for="choice in randomizedChoices" :key="choice.id + 4">
          <component
            :is="inputComponent"
            v-model="correctAnswer"
            :value="choice.id"
            :disabled="true"
            :name="inputAnswerName"
            class="choice-response__choice"
          >
            <editor-content
              :text="choice.text"
              :variable-context="variableContext"
            />
          </component>
          <div
            v-if="getFeedback(choice.id)"
            class="activity-question-comments--readonly"
            :class="isCorrectChoice(choice.id) ? 'correct' : 'incorrect'"
          >
            <icon
              v-if="isCorrectChoice(choice.id)"
              icon="check"
              style="color: green"
              class="icon"
            />
            <icon
              v-else-if="!isCorrectChoice(choice.id)"
              icon="times"
              style="color: red"
              class="icon"
            />
            <editor-content
              :text="getFeedback(choice.id).response"
              :aria-labelledby="`response-${choice.id}`"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-else>
    <div v-if="isSelectMany">Select all that apply:</div>
    <playback :element="$refs.popoverAnchorRef" class="playback_btn" />
    <ul
      ref="popoverAnchorRef"
      :class="`choice-response__choices choice-response__choices--${choicesPerRow}`"
      @mouseover="openPopover"
      @mouseout="closePopover"
      @focusin="openPopover"
      @focusout="closePopover"
    >
      <li v-for="choice in randomizedChoices" :key="choice.id">
        <component
          :is="inputComponent"
          v-model="value"
          :value="choice.id"
          :disabled="isDisabled"
          :name="inputName"
          class="choice-response__choice"
          :class="{
            'choice-response__choice--selected': value?.includes(
              choice.id.toString()
            ),
            'choice-response__has-feedback': getFeedback(choice.id)
          }"
        >
          <editor-content
            :text="choice.text"
            :variable-context="variableContext"
            :view-as-student="viewAsStudent"
          />
        </component>
        <div
          v-if="getFeedback(choice.id)"
          class="activity-question-comments--readonly"
        >
          <editor-content
            :text="getFeedback(choice.id).response"
            :aria-labelledby="`response-${choice.id}`"
          />
        </div>
      </li>
    </ul>
    <base-popover
      :anchor-ref="popoverAnchorRef"
      :visible="studentResponsePopoverMessage && popoverVisible"
      placement="bottom"
    >
      {{ studentResponsePopoverMessage }}
    </base-popover>
    <dependency-popover
      v-if="dependencyVisible"
      :anchor-ref="popoverAnchorRef"
      :grading="grading"
    >
    </dependency-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DependencyPopover from './DependencyPopover'
import { scramble } from 'src/shared/utils/array-randomizer.js'

export default {
  name: 'ActivityResponseChoice',
  components: { DependencyPopover },
  inject: ['inherited'],
  emits: ['submit', 'canSubmit'],
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    showCorrectAnswer: {
      type: Boolean,
      default: false,
      required: false
    },
    variableContext: {
      type: Object,
      required: true
    },
    grading: {
      type: Boolean,
      default: false
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverAnchorRef: null,
      popoverVisible: false,
      dependencyVisible: false,
      randomizedChoices: []
    }
  },
  computed: {
    ...mapGetters(['isStudent']),

    correctAnswer() {
      // As user can select multiple answer and can also single one
      const answer = this.component.choices
        .filter(ans => {
          return ans.answer
        })
        .filter(Boolean)
        .map(a => a.id)
        .toString()

      const choices = (answer || '').split(',').filter(v => v !== '')

      return this.isSelectMany || this.isPollQuestion ? choices : choices[0]
    },
    component() {
      return this.inherited.component
    },
    componentResponse() {
      return this.inherited.componentResponse
    },
    submittedResponse() {
      return this.inherited.prevResponse && this.inherited.prevResponse.value
        ? this.inherited.prevResponse.value.split(',')
        : []
    },
    choicesPerRow() {
      return this.component.choicesPerRow || 1
    },
    isSelectMany() {
      return (
        (this.component.choices || []).filter(({ answer }) => answer).length > 1
      )
    },
    isPollQuestion() {
      return (
        (this.component.choices || []).filter(({ answer }) => answer).length ===
        0
      )
    },
    canRespond() {
      return this.inherited.canRespond
    },
    canSubmit() {
      return this.isSelectMany || this.isPollQuestion ? true : !!this.value
    },
    isDisabled() {
      return !this.canRespond || this.readonly
    },
    studentResponsePopoverMessage() {
      return this.inherited.studentResponsePopoverMessage
    },
    value: {
      get() {
        const choices = (this.componentResponse.value || '')
          .split(',')
          .filter(v => v !== '')
        return this.isSelectMany || this.isPollQuestion ? choices : choices[0]
      },
      set(value) {
        this.inherited.updateResponse({
          value: Array.isArray(value) ? value.join(',') : value.toString()
        })
      }
    },
    inputName() {
      return `question-choice-${this.component._id}`
    },
    inputAnswerName() {
      return `answer-question-choice-${this.component._id}`
    },
    inputComponent() {
      return this.isSelectMany || this.isPollQuestion
        ? 'checkbox'
        : 'radio-button'
    }
  },
  mounted() {
    this.popoverAnchorRef = this.$refs.popoverAnchorRef
  },
  created() {
    if (this.inherited.component.randomizedChoices && !this.grading) {
      const responseId = this.inherited.response.id ?? Math.random()
      const seedValue = `${responseId}${this.component._id}`
      this.randomizedChoices = this.component.choices
        ? scramble(seedValue, this.component.choices)
        : []
    } else {
      this.randomizedChoices = this.component.choices
    }
  },
  methods: {
    closePopover() {
      this.dependencyVisible = false
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = false
      }
    },
    openPopover() {
      this.dependencyVisible = true
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = true
      }
    },
    getFeedback(id) {
      if (!this.inherited.response.assignment.showFeedback) return false
      if (!this.inherited.componentResponse?.feedback) return false
      if (
        this.submittedResponse?.includes(id.toString()) &&
        this.value?.includes(id.toString())
      ) {
        return this.inherited.componentResponse?.feedback.find(
          f => parseInt(f.id) === id
        )
      }
      return false
    },
    isCorrectChoice(id) {
      const choice = this.getFeedback(id)
      return choice.isCorrect
    }
  },
  watch: {
    canSubmit: {
      handler(newValue) {
        this.$emit('canSubmit', newValue)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.choice-response__choices {
  display: grid;
  padding-left: 0;
  list-style-type: none;

  &--1 {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(100% - 16px, max(100px, (100%/1))), 1fr)
    );
    column-gap: 16px;
  }
  &--2 {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(100% - 16px, max(100px, (100%/2 - 16px * (2 - 1)/2))), 1fr)
    );
    column-gap: 16px;
  }
  &--3 {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(100% - 16px, max(100px, (100%/3 - 16px * (3 - 1)/3))), 1fr)
    );
    column-gap: 16px;
  }
  &--4 {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(100% - 16px, max(100px, (100%/4 - 16px * (4 - 1)/4))), 1fr)
    );
    column-gap: 16px;
  }
}

.grouped-multiple-choice {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $screen-md) {
    flex-direction: row;
  }

  & .multiple-answer {
    flex-grow: 1;
    max-width: 100%;
    margin-bottom: 10px;

    @media (min-width: $screen-md) {
      max-width: 48%;
    }
  }

  & .answer-heading {
    font-size: 16px;
    font-weight: 600;
  }
}
.choice-response__choice :deep(label) {
  display: flex;
  align-items: flex-start;
  margin: 8px 0;
  padding: 8px;
  border: 1px solid #333333;
  border-radius: 5px;
}
.choice-response__choice--selected :deep(label) {
  box-shadow: teal 0px 0px 0px 1px;
  border-color: teal;
  border-radius: 5px;
}
.choice-response__choice--incorrect :deep(label) {
  box-shadow: $color-error 0px 0px 0px 1px;
  border-color: $color-error;
  border-radius: 5px;
}

.choice-response__has-feedback :deep(label) {
  margin-bottom: 0;
}

.playback_btn {
  float: left;
  margin-right: 8px;
  margin-top: 10px;
}

.activity-question-comments--readonly {
  display: flex;

  &.correct {
    color: $teal;
  }
  &.incorrect {
    color: $color-error;
  }

  .icon {
    padding-right: 10px;
    padding-top: 7px;
  }
}
</style>
