<template>
  <form class="instructor-search" @submit.prevent="search">
    <search-input
      v-model="text"
      aria-label="Search Instructors"
      class="instructor-search__input"
      placeholder="Search Instructor name, created time"
    />
    <form-button type="submit">Search</form-button>
  </form>
</template>

<script>
export default {
  name: 'InstructorsSearch',
  inject: ['browseInstructors'],
  data() {
    return {
      text: ''
    }
  },
  computed: {
    value() {
      return this.browseInstructors.query.q
    }
  },
  methods: {
    search() {
      if (this.value !== this.text) {
        this.browseInstructors.updateQuery({
          page: '1',
          q: this.text || undefined
        })
      }
    }
  },
  watch: {
    value: {
      handler() {
        this.text = this.value
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.instructor-search {
  position: relative;
  display: flex;
  align-items: center;
  grid-area: search;

  &__input {
    flex-grow: 1;
    margin: 0 16px 0 0;
  }
}
</style>
