<template>
  <form-group class="question-hint__section">
    <label :id="`${name}.hint.text-label`" class="question-hint__label"
      >Hint</label
    >
    <editor
      :aria-labelledby="`${name}.hint.text-label`"
      :text="hint.text"
      :name="`${name}.hint.text`"
      :variable-context="variableContext"
      label="Hint"
      :rules="{
        undefinedVariables: true
      }"
      @input="text => emitChange({ text })"
    />
  </form-group>
  <form-group class="question-hint__section">
    <label :for="`${name}.hint.prompt-label`" class="question-hint__label"
      >Hint Prompt</label
    >
    <text-input
      :id="`${name}.hint.prompt-label`"
      :name="`${name}.hint.prompt`"
      label="Hint Prompt"
      :model-value="hint.prompt"
      placeholder="I need a hint"
      @update:model-value="prompt => emitChange({ prompt })"
    />
    <div class="question-hint__hide-hint-toggle">
      <checkbox
        :name="`${name}.hint.hide`"
        :modelValue="hint.hide"
        @update:modelValue="hide => emitChange({ hide })"
      >
        Make hint initially hidden?
      </checkbox>
    </div>
  </form-group>
  <icon-button
    class="delete-icon-btn question-hint__action"
    label="Delete Hint"
    icon="trash"
    color="delete-icon"
    :tool-tip="false"
    @click="clearHint"
  />
</template>

<script setup>
const emit = defineEmits(['change', 'delete'])
const props = defineProps({
  hint: {
    type: Object,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  variableContext: {
    type: Object,
    required: true
  }
})
function emitChange(changes) {
  emit('change', { ...props.hint, ...changes })
}
function clearHint() {
  emit('delete')
}
</script>

<style lang="scss" scoped>
.question-hint__section {
  width: 50%;
  margin: 0;
  margin-left: 10px;
}
.question-hint__label {
  color: #3d3d3d;
  font-weight: 400;
  font-size: 16px;
}

.question-hint__hide-hint-toggle {
  width: 100%;
  display: flex;
}
.question-hint__action {
  position: absolute;
  right: 0;
}
.delete-icon-btn {
  display: inline-block;
  width: 20px;
}
</style>
