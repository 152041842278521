<template>
  <view-container wide>
    <view-header>
      <view-title>
        Student Assignments: {{ firstName }} {{ lastName }}
      </view-title>
    </view-header>
    <loading-container :loading="isLoading">
      <section>
        <list :items="studentAssignments">
          <list-column
            v-slot="{ activityName, assignmentId, responseId, groupName }"
            title="Activity Name"
            class="name-col"
          >
            <router-link
              :to="{
                name: 'grade_response',
                params: {
                  assignmentId,
                  responseId
                }
              }"
              >{{ activityName }} {{ groupName ? '(Co-lab)' : '' }}</router-link
            >
          </list-column>
          <list-column v-slot="{ score }" title="Score" class="score-col">
            {{ score.toFixed(2) }}%
          </list-column>
          <list-column
            v-slot="{ updatedAt }"
            title="Last Updated"
            class="updated-at-col"
          >
            {{ $format.date(updatedAt, 'MM/dd/yyyy hh:mm a') }}
          </list-column>
          <list-column title="Actions" v-slot="assignment">
            <button-dropdown link>
              <template #button>
                <icon icon="ellipsis-v" class="grade-menu-ellipsis" />
                <span class="sr-only">Student Assignment Actions</span>
              </template>
              <dropdown-action @click="grantExtension(assignment)">
                {{ assignment.extensionEndDate ? 'Edit' : 'Grant' }} Extension
              </dropdown-action>
            </button-dropdown>
          </list-column>
        </list>
      </section>
    </loading-container>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'
import ExtensionModal from 'src/modules/assignments/components/ExtensionModal.vue'

export default {
  name: 'TeacherClassView',
  props: {
    classId: {
      type: String,
      required: true
    },
    studentId: {
      type: String,
      required: true
    }
  },
  inject: ['$modal'],
  data() {
    return {
      studentAssignments: [],
      isLoading: true,
      firstName: undefined,
      lastName: undefined
    }
  },
  async created() {
    await Promise.all([this.loadStudentAssignments(), this.loadStudentInfo()])

    this.isLoading = false
  },
  methods: {
    async loadStudentAssignments() {
      const body = await client.classes.getStudentsCompletedAssignments({
        classId: this.classId,
        studentId: this.studentId
      })
      this.studentAssignments = body
    },

    async loadStudentInfo() {
      const { firstName, lastName } = await client.users.getUser(this.studentId)
      this.firstName = firstName
      this.lastName = lastName
    },

    async grantExtension(assignment) {
      await this.$modal.show(ExtensionModal, {
        assignment: {
          id: assignment.assignmentId,
          endDate: assignment.endDate
        },
        responses: [
          {
            id: assignment.responseId,
            name: `${this.firstName} ${this.lastName} ${
              assignment.groupName ? '(Co-lab)' : ''
            }`
          }
        ],
        selectedStudents: [assignment.responseId],
        isGroupAssignment: !!assignment.groupName,
        previousExtensionDate: assignment.extensionEndDate,
        isEdit: !!assignment.extensionEndDate
      })
      await this.loadStudentAssignments()
    }
  }
}
</script>
