import Bowser from 'bowser'

const isValidSystem = function () {
  const system = Bowser.getParser(window.navigator.userAgent)

  const validSystemCheck = system.satisfies({
    windows: {
      firefox: '>=73',
      chrome: '>=78'
    },
    macos: {
      chrome: '>=78',
      firefox: '>=73',
      safari: '>=12'
    },
    chromeos: {
      chrome: '>=78'
    },
    tablet: {
      safari: '>=12'
    },
    linux: {
      chrome: '>=78',
      firefox: '>=73'
    }
  })

  return validSystemCheck
}

const isBluetoothSupported = window.navigator && window.navigator.bluetooth

export { isValidSystem, isBluetoothSupported }
