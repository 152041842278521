<template>
  <div class="modal-actions"><slot /></div>
</template>

<script>
export default {
  name: 'ModalActions'
}
</script>

<style lang="scss">
.modal-actions {
  margin: 32px 0 0 0;
  display: flex;
  flex-direction: row-reverse;
}
</style>
