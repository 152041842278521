<template>
  <modal class="data-graph-axis-select-modal" @submit="submit">
    <modal-header>Configure {{ axisName }} Axis</modal-header>
    <modal-body>
      <div>
        <div class="axis-row">
          <form-group>
            <form-label>Data Column</form-label>
          </form-group>
          <form-group v-if="includeUncertainty">
            <form-label>Uncertainty Column</form-label>
          </form-group>
        </div>
        <div
          v-for="(selection, selectionIndex) in selections"
          :key="selectionIndex"
          class="axis-row"
        >
          <form-group>
            <select-field
              v-model="selection.data"
              :data-focus="selectionIndex === 0 ? true : null"
              :aria-label="`select data column to plot on ${axisName} axis number ${
                selectionIndex + 1
              }`"
            >
              <option :value="-1">Select Data Column</option>
              <option
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :value="columnIndex"
                :disabled="(!allowTextData && column.allowText) || null"
              >
                {{ toPlotlyString(columnDisplayName(column)) }}
              </option>
            </select-field>
          </form-group>
          <form-group v-if="includeUncertainty">
            <select-field
              v-model="selection.uncertainty"
              :aria-label="`select uncertainty column to plot on ${axisName} axis number ${
                selectionIndex + 1
              }`"
              :disabled="
                selection.data < 0 || columns[selection.data].allowText
              "
            >
              <option :value="-1">No Uncertainty</option>
              <option
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :value="columnIndex"
                :disabled="column.allowText || null"
              >
                {{ columnDisplayName(column) }}
              </option>
            </select-field>
          </form-group>
        </div>
      </div>
      <div class="actions">
        <a
          v-if="selections.length > 1"
          class="row-action"
          href="#"
          @click.prevent="removeColumns"
          >Remove Columns</a
        >
        <a
          v-if="selections.length < max"
          class="row-action"
          href="#"
          @click.prevent="addColumns"
          >Add Columns</a
        >
      </div>
      <form-group>
        <form-label for="axisTitle">Axis Title</form-label>
        <text-input
          id="axisTitle"
          v-model="title"
          :placeholder="defaultAxisTitle"
        />
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Done</modal-button-submit>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import {
  columnDisplayName,
  columnDisplayUnits,
  toPlotlyString
} from 'src/shared/components/grid-graph/utilities'

export default {
  name: 'DataGraphAxisSelectModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit
  },
  props: {
    axisName: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      default: 3
    },
    allowTextData: {
      type: Boolean,
      default: false
    },
    columns: Array,
    initialSelections: Array,
    defaultColumnsValue: Object,
    axisTitle: String,
    includeUncertainty: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selections: this.initialSelections.map(selection => ({ ...selection })),
      title: this.axisTitle
    }
  },
  computed: {
    defaultAxisTitle() {
      return this.selections
        .map(({ data }) => {
          if (data >= 0) {
            const column = this.columns[data]

            return `${column.name.formula ?? column.name}${
              column.units ? ` (${column.units.formula ?? column.units})` : ''
            }`
          }
        })
        .filter(x => !!x)
        .join(', ')
    }
  },

  methods: {
    addColumns() {
      this.selections.push({ ...this.defaultColumnsValue })
    },
    removeColumns(index) {
      this.selections.splice(-1, 1)
    },
    async submit(e) {
      e.done({ title: this.title, selections: this.selections })
    },
    columnDisplayName,
    columnDisplayUnits,
    toPlotlyString
  }
}
</script>

<style lang="scss" scoped>
.data-graph-axis-select-modal {
  .axis-row {
    margin-bottom: 8px;
    display: flex;

    &:first-child,
    &:last-child {
      margin-bottom: 0;
    }

    .form-group {
      flex: 1;
      margin: 0 8px 8px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    .row-action {
      margin-left: 8px;
    }
  }
}
</style>
