<template>
  <browse-activities
    ref="browseActivities"
    v-slot="{
      library,
      canManageLibraryActivities,
      isLibraryViewer,
      updateQuery
    }"
    :query="computedQuery"
  >
    <view-container>
      <sticky-header>
        <template #title>
          <sticky-header-title>
            <template v-if="library">
              Activities: {{ library.name }}
              <span v-if="library.status === 'archived'"> (Archived) </span>
            </template>
            <template v-else>Activities</template>
          </sticky-header-title>
          <activities-header-actions />
        </template>
        <template #sub-title>
          <sticky-header-sub-title>
            <div class="hidden-xs hidden-sm">
              <sticky-header-libraries>
                <sticky-header-shared-libraries />
                <sticky-header-library :main-list="true" library="pivot">
                  Pivot Interactives Library
                </sticky-header-library>
                <sticky-header-library :main-list="true" library="community">
                  Community Library
                </sticky-header-library>
              </sticky-header-libraries>
            </div>
            <div class="hidden-lg hidden-md">
              <div class="library-filter">
                <sticky-header-libraries>
                  <sticky-header-shared-libraries>
                    <template #other-libraries>
                      <library-dropdown-item library="pivot">
                        Pivot Interactives Library
                      </library-dropdown-item>
                      <library-dropdown-item library="community">
                        Community Library
                      </library-dropdown-item>
                    </template>
                  </sticky-header-shared-libraries>
                </sticky-header-libraries>
              </div>
            </div>
          </sticky-header-sub-title>
        </template>
        <template #actions>
          <activity-search-action />
          <UIButton
            v-if="
              canManageLibraryActivities &&
              library.status !== 'archived' &&
              isLicenseActive
            "
            :to="newActivityRoute"
          >
            Create New Activity
          </UIButton>
        </template>
      </sticky-header>
      <library-description :library="library" />
      <library-filters
        :modelValue="computedQuery"
        @update:modelValue="updateQuery"
        :show-archived-filter="showArchivedFilter"
      />
      <browse-summary />
      <filter-chips
        :modelValue="computedQuery"
        @update:modelValue="updateQuery"
      />
      <div class="activity-view__settings">
        <activity-sort-action />
        <activity-search-view-settings />
        <activity-bulk-actions
          v-if="isLicenseActive"
          :selected-activities="selectedActivities"
          @applyBulkAction="doBulkActions"
        />
      </div>
      <activity-list>
        <template #card="{ activity }">
          <activity-card :activity="activity">
            <checkbox
              v-model="selectedActivities"
              :value="activity.id"
              class="bulk-actions--checkbox"
            />
            <activity-card-private-actions
              v-if="canManageLibraryActivities || isLibraryViewer"
              @copy="copyActivity"
              @move="moveActivity"
              @share="shareActivity"
              @assign="assignActivity"
              @archive="archiveActivity"
              @unarchive="unArchiveActivity"
            />
            <activity-card-default-actions
              v-if="!canManageLibraryActivities && !isLibraryViewer"
              @copy="copyActivity"
            />
          </activity-card>
        </template>
        <template #row="{ activity }">
          <activity-row :activity="activity">
            <activity-row-private-actions
              v-if="canManageLibraryActivities"
              @copy="copyActivity"
              @move="moveActivity"
              @share="shareActivity"
              @assign="assignActivity"
              @archive="archiveActivity"
              @unarchive="unArchiveActivity"
            />
            <activity-row-default-actions
              v-if="!canManageLibraryActivities"
              @copy="copyActivity"
            />
          </activity-row>
        </template>
      </activity-list>
    </view-container>
  </browse-activities>
</template>

<script>
import MoveActivityBetweenLibrariesModal from 'src/shared/components/modals/MoveActivityBetweenLibrariesModal'
import CopyActivityToLibraryModal from 'src/shared/components/modals/CopyActivityToLibraryModal'
import BrowseActivities from '../components/BrowseActivities'
import ActivitySortAction from '../components/ActivitySortAction'
import BrowseSummary from '../components/BrowseSummary'
import ActivityList from '../components/ActivityList'
import ActivityCard from '../components/ActivityCard'
import ActivityRow from '../components/ActivityRow'
import ActivityCardPrivateActions from '../components/ActivityCardPrivateActions'
import ActivityCardDefaultActions from '../components/ActivityCardDefaultActions'
import ActivityRowPrivateActions from '../components/ActivityRowPrivateActions'
import ActivityRowDefaultActions from '../components/ActivityRowDefaultActions'
import StickyHeaderLibraries from '../components/StickyHeaderLibraries.vue'
import StickyHeaderSharedLibraries from '../components/StickyHeaderSharedLibraries.vue'
import StickyHeaderLibrary from '../components/StickyHeaderLibrary.vue'
import ActivitiesHeaderActions from '../components/ActivitiesHeaderActions.vue'
import ActivitySearchAction from '../components/ActivitySearchAction.vue'
import LibraryDescription from '../components/LibraryDescription'
import LibraryFilters from '../components/filters/LibraryFilters'
import LibraryDropdownItem from '../components/LibraryDropdownItem'
import FilterChips from '../components/FilterChips'
import ActivitySearchViewSettings from '../components/ActivitySearchViewSettings.vue'
import ActivityBulkActions from '../components/ActivityBulkActions.vue'
import { mapGetters } from 'vuex'
import client from 'src/shared/api-client'
import { UIButton } from '@pi/design'

export default {
  name: 'ActivitiesView',
  inject: ['$modal'],
  components: {
    BrowseActivities,
    ActivitySortAction,
    BrowseSummary,
    ActivityList,
    ActivityBulkActions,
    ActivityCard,
    ActivityCardPrivateActions,
    ActivityCardDefaultActions,
    ActivitySearchViewSettings,
    ActivityRow,
    ActivityRowPrivateActions,
    ActivityRowDefaultActions,
    StickyHeaderLibraries,
    StickyHeaderSharedLibraries,
    StickyHeaderLibrary,
    ActivitiesHeaderActions,
    ActivitySearchAction,
    LibraryDescription,
    LibraryFilters,
    LibraryDropdownItem,
    FilterChips,
    UIButton
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      selectedActivities: []
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isContentDeveloper', 'isLicenseActive']),
    computedQuery() {
      return {
        ...this.query,
        library: this.query.library || 'pivot',
        page: parseInt(this.query.page) || 1,
        sort: this.query.sort || (this.query.q ? 'relevance' : 'updatedAt'),
        dir: this.query.dir || 'desc',
        q: this.query.q || '',
        categories: Object.entries(this.query.categories || {}).reduce(
          (filters, [key, value]) => {
            if (value) {
              filters[key] = Array.isArray(value) ? value : [value]
            }
            return filters
          },
          {}
        )
      }
    },
    newActivityRoute() {
      return {
        name: 'new_library_activity',
        params: { libraryId: this.computedQuery.library }
      }
    },
    showArchivedFilter() {
      return (
        !['pivot', 'community'].includes(this.computedQuery.library) ||
        ((this.isAdmin || this.isContentDeveloper) &&
          this.computedQuery.library === 'pivot')
      )
    }
  },
  methods: {
    async refreshActivities() {
      this.selectedActivities = []
      await this.$refs.browseActivities.loadActivities()
    },
    async copyActivity(activity) {
      const { status, data } = await this.$modal.show(
        CopyActivityToLibraryModal,
        {
          activity: {
            id: activity._id,
            ...activity
          }
        }
      )
      if (status === 'ok' && data.libraryId === this.computedQuery.library) {
        await this.refreshActivities()
      }
    },
    async copyActivities() {
      const { status, data } = await this.$modal.show(
        CopyActivityToLibraryModal,
        {
          activities: this.selectedActivities
        }
      )
      if (status === 'ok' && data.libraryId === this.computedQuery.library) {
        await this.refreshActivities()
      } else if (status === 'ok') {
        this.selectedActivities = []
      }
    },
    async archiveActivity(activity) {
      await client.activities.archive({
        activityId: activity.id,
        isArchived: true
      })
      this.$success(`Activity ${activity.name} has been archived.`)
      this.refreshActivities()
    },
    async unArchiveActivity(activity) {
      await client.activities.archive({
        activityId: activity.id,
        isArchived: false
      })
      this.$success(`Activity ${activity.name} has been un-archived.`)
      await this.refreshActivities()
    },
    async moveActivity(activity) {
      const { status } = await this.$modal.show(
        MoveActivityBetweenLibrariesModal,
        {
          activity
        }
      )
      if (status === 'ok') {
        await this.refreshActivities()
      }
    },
    async moveActivities() {
      const { status } = await this.$modal.show(
        MoveActivityBetweenLibrariesModal,
        {
          activities: this.selectedActivities
        }
      )
      if (status === 'ok') {
        await this.refreshActivities()
      }
    },
    async shareActivity(activity) {
      const isSharedWithCommunity = !activity.isSharedWithCommunity
      const sharedText = isSharedWithCommunity
        ? 'shared to the community library.'
        : 'removed from the community library.'
      await client.activities.share({
        activityId: activity.id,
        isSharedWithCommunity
      })
      activity.isSharedWithCommunity = isSharedWithCommunity
      this.$success(`Activity ${activity.name} has been ${sharedText}`)
    },
    async shareActivities() {
      const sharedText = 'shared to the community library.'
      await client.activities.shareBulk({
        activities: this.selectedActivities,
        isSharedWithCommunity: true
      })
      this.$success(`Activities have been ${sharedText}`)
      await this.refreshActivities()
    },
    async archiveActivities() {
      await client.activities.archiveBulk({
        activities: this.selectedActivities,
        isArchived: true
      })
      this.$success(`Activities have been archived.`)
      await this.refreshActivities()
    },
    async assignActivity(activity) {
      this.$router.push({
        name: 'new_assignment',
        query: {
          activityIds: [activity.id]
        }
      })
    },
    async doBulkActions(action) {
      if (!this.selectedActivities.length) {
        return
      }
      switch (action) {
        case 'assign':
          this.$router.push({
            name: 'new_assignment',
            query: {
              activityIds: this.selectedActivities
            }
          })
          break
        case 'archive':
          return this.archiveActivities()
        case 'copy':
          return this.copyActivities()
        case 'move':
          return this.moveActivities()
        case 'share':
          return this.shareActivities()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.library-filter {
  display: flex;
  align-items: center;
}
.activity-view__settings {
  display: flex;
  gap: 30px;
  align-content: center;
  align-items: center;

  & form-buttom {
    height: 24px;
  }
}
.bulk-actions--checkbox {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
