<template>
  <th scope="row" class="row-header">
    <div class="row-header__wrapper">
      <span class="row-header__number">{{ rowIndex + 1 }}</span>
      <button-dropdown
        v-if="!readonly"
        ref="dropdown"
        side
        right
        button-class="row-header__menu-button"
        tabindex="-1"
        aria-label="row menu"
        data-tablenav
        @focus="() => $emit('focus:cell')"
      >
        <template #button>
          <icon icon="ellipsis-h" />
        </template>
        <template #default>
          <dropdown-action @click="$gridActions.insertRowAboveRow(rowIndex)">
            Insert Row Above
          </dropdown-action>
          <dropdown-action @click="$gridActions.insertRowBelowRow(rowIndex)">
            Insert Row Below
          </dropdown-action>
          <dropdown-action
            v-if="numRows > 1"
            @click="$gridActions.removeRow(rowIndex)"
          >
            Remove Row
          </dropdown-action>
        </template>
      </button-dropdown>
    </div>
  </th>
</template>

<script>
export default {
  name: 'DataGridRowHeader',
  inject: ['$gridActions'],
  emits: ['focus:cell'],
  props: {
    numRows: Number,
    rowIndex: Number,
    readonly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.row-header__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.row-header__number {
  flex: 1 1 0%;
  padding: 8px;
  font-weight: 400;

  color: #757575;
}

:deep(.row-header__menu-button) {
  width: 32px;
  height: $grid-row-default-height;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  flex-shrink: 0;
  margin-left: -16px;
}
</style>
