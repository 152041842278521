import { unref } from 'vue'
import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import InlineVariable from './InlineVariable'
import { findVariableById, findVariableByName } from '@pi/shared/variables'
const VARIABLE_MATCH = /\[\$([A-Za-z0-9_ℹ]+(?:\.[A-Za-z0-9_ℹ]+)?)\]/

const Variable = Node.create({
  name: 'variable',
  inline: true,
  group: 'inline',
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      id: {
        default: '',
        parseHTML: element =>
          element.getAttribute('id') ?? element.textContent.trim()
      }
    }
  },

  addStorage() {
    return {
      variableContext: null
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: text => {
          const match = VARIABLE_MATCH.exec(text)
          if (!match) return

          const variable = findVariableByName(
            match[1],
            this.storage.variableContext.value.variables
          )
          if (!variable) return

          return {
            index: match.index,
            text: match[0],
            replaceWith: match[0],
            data: { id: variable?.id ?? 'UNKNOWN' }
          }
        },
        type: this.type,
        getAttributes: match => ({ id: match.data?.id })
      })
    ]
  },

  addKeyboardShortcuts() {
    return {
      Enter({ editor }) {
        if (editor.isActive('variable')) {
          editor.commands.openVariableModal()
          return true
        }
      }
    }
  },

  addCommands() {
    return {
      setVariable:
        attributes =>
        ({ commands }) => {
          const { variable } = attributes
          return commands.insertContent({
            type: this.name,
            attrs: {
              id: variable.id
            }
          })
        },
      openVariableModal: attrs => () => {
        this.storage.onEditVariable?.(attrs?.id)
      }
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(InlineVariable)
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['variable', mergeAttributes(HTMLAttributes)]
  },

  renderText({ node }) {
    if (!node.attrs.id) {
      return '{$UNKNOWN}'
    }
    const variable = findVariableById(
      node.attrs.id,
      unref(this.storage.variableContext).variables
    )
    return `{$${variable?.name ?? 'UNKNOWN'}}`
  },

  parseHTML() {
    return [{ tag: 'inline-variable' }, { tag: 'span.ql-var' }]
  }
})

export default Variable
