<template>
  <div class="numerical-conditions">
    <collapse-panel
      :title="conditionTitle"
      :collapsed="false"
      icon-right="caret-up"
      icon-down="caret-down"
      show-on-print
    >
      <template v-if="conditions.length === 0" #header>
        <span class="no-conditions-message"
          >You haven't added any conditions yet</span
        >
      </template>
      <div>
        <response-condition
          v-for="(condition, index) in filteredConditions"
          :key="condition.keyId"
          :index="index"
          :condition="condition"
          :last-index="filteredConditions.length - 1"
          :variable-context="variableContext"
          :name="`${name}.conditions.c${condition.keyId}`"
          @edit="updateCondition"
          @delete="removeCondition"
          @insert="addConditionAtIndex"
          @moveUp="moveConditionUp"
          @moveDown="moveConditionDown"
        />
      </div>
      <div class="add-condition" @click="addCondition">
        <form-button link>
          <icon icon="plus" />
          Add Condition
        </form-button>
      </div>
    </collapse-panel>
    <div class="default-condition-layout">
      <label for="feedback">
        Default (optional)<br />
        Show if no expression is true
      </label>
      <div class="default-feedback">
        <form-group class="section-insight">
          <label for="feedback">Feedback</label>
          <editor
            :text="defaultCondition.response"
            :label="defaultCondition.response"
            :variable-context="variableContext"
            :rules="{
              undefinedVariables: true
            }"
            :name="`${name}.defaultResponse`"
            data-testid="text-input"
            @update:text="response => setDefaultResponse(response)"
            allow-response-variable
            video
          />
        </form-group>
      </div>
    </div>
  </div>
</template>

<script>
import ResponseCondition from './ResponseCondition'

export default {
  name: 'ResponseConditions',
  components: {
    ResponseCondition
  },
  inject: ['inherited'],
  emits: ['update'],
  props: {
    conditions: {
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    defaultCondition() {
      const defaultCondition = this.conditions.find(
        condition => condition.default
      )
      return defaultCondition || { response: '', default: true }
    },
    filteredConditions() {
      return this.conditions.filter(c => !c.default)
    },
    totalConditions() {
      return (this.filteredConditions || []).length
    },
    conditionTitle() {
      return this.totalConditions === 0
        ? 'Conditions'
        : `Conditions (${this.totalConditions})`
    }
  },
  methods: {
    addCondition: function () {
      const potentialDefault = this.conditions.slice(-1)[0]
      const newCondition = {
        text: '',
        isCorrect: false,
        response: '',
        pointValue: '',
        accuracy: 2,
        accuracyType: 'percent',
        default: false,
        keyId: Math.floor(Math.random() * 100000)
      }
      if (potentialDefault && potentialDefault.default) {
        this.$emit('update', {
          conditions: [
            ...this.conditions.slice(0, -1),
            newCondition,
            potentialDefault
          ]
        })
      } else {
        this.$emit('update', {
          conditions: [...this.conditions, newCondition]
        })
      }
    },
    addConditionAtIndex: function (index, condition) {
      const conditions = [...this.conditions]
      this.$emit('update', {
        conditions: [
          ...conditions.slice(0, index),
          { ...condition, keyId: Math.floor(Math.random() * 100000) },
          ...conditions.slice(index)
        ]
      })
    },
    removeCondition: function (index) {
      this.$emit('update', {
        conditions: this.conditions.filter((_, i) => i !== index)
      })
    },
    updateCondition: function (fields, index) {
      this.$emit('update', {
        conditions: [
          ...this.conditions.slice(0, index),
          {
            ...this.conditions[index],
            ...fields
          },
          ...this.conditions.slice(index + 1)
        ]
      })
    },
    setDefaultResponse: function (response) {
      const defaultIndex = this.conditions.findIndex(c => c.default)
      if (response) {
        if (defaultIndex !== -1) {
          this.updateCondition({ response }, defaultIndex)
        } else {
          this.addConditionAtIndex(this.conditions.length, {
            response,
            default: true
          })
        }
      } else {
        // remove default condition if it exists
        if (defaultIndex !== -1) {
          this.removeCondition(defaultIndex)
        }
      }
    },
    moveConditionUp(index, condition) {
      const mutableCondition = [...this.conditions]
      mutableCondition.splice(index, 1)
      mutableCondition.splice(index - 1, 0, condition)

      this.$emit('update', {
        conditions: mutableCondition
      })
      const focusEl = document.activeElement
      this.$nextTick(() => {
        focusEl.focus()
      })
    },
    moveConditionDown(index, condition) {
      const mutableCondition = [...this.conditions]
      mutableCondition.splice(index, 1)
      mutableCondition.splice(index + 1, 0, condition)

      this.$emit('update', {
        conditions: mutableCondition
      })
      const focusEl = document.activeElement

      this.$nextTick(() => {
        focusEl.focus()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
$border: 1px solid $teal;

.numerical-conditions {
  margin: 10px;

  :deep(.panel-body) {
    padding-top: 0;
  }

  & .no-conditions-message {
    color: $color-error;
    margin-left: 10px;
  }

  :deep(.collapse-panel__header) {
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  & .panel-default {
    background-color: $extra-light-teal;
    border: none;
  }

  & .add-condition {
    border-top: $border;
    padding-top: 15px;
  }

  & .section-insight {
    margin: 0;
  }
}

.default-condition-layout {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;

  & .default-feedback {
    flex-grow: 1;
    margin-left: 10px;

    & .section-insight {
      margin: 0;
    }
  }
}

.section-insight {
  margin: 0;
}
</style>
