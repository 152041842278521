<template>
  <li>
    <sidebar-collapse :key="`typeCollapse`">
      <template #header> Type </template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('license')"
              @update:modelValue="value => changeCategory('license', value)"
              @click.stop
            >
              Institution
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('bookstore-codes')"
              @update:modelValue="
                value => changeCategory('bookstore-codes', value)
              "
              @click.stop
            >
              Bookstore Codes
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('renewal')"
              @update:modelValue="value => changeCategory('renewal', value)"
              @click.stop
            >
              Renewal
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('added-seats')"
              @update:modelValue="value => changeCategory('added-seats', value)"
              @click.stop
            >
              Added Seats
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('upgrade')"
              @update:modelValue="value => changeCategory('upgrade', value)"
              @click.stop
            >
              Upgrade
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'TypeFilter',
  inject: ['browseQuotes'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  data() {
    return {
      type: '',
      types: []
    }
  },
  created() {
    this.types = this.browseQuotes.query.types
      ? [...this.browseQuotes.query.types]
      : ''
  },
  methods: {
    isChecked(t) {
      return this.types.includes(t)
    },
    changeCategory(category, isChecked) {
      if (!isChecked) {
        this.types = [...this.types].filter(t => t !== category)
      } else {
        this.types.push(category)
      }
      if (this.types.length) {
        return this.browseQuotes.updateQuery({
          page: 1,
          types: [...this.types]
        })
      }
      return this.browseQuotes.updateQuery({ page: 1, types: undefined })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
