<template>
  <div>
    <div v-for="(section, index) in sections" :key="index">
      <slot :section="section" :index="index" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivitySections',
  inject: ['inherited'],
  props: {
    isTeacherPreview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activity() {
      return this.inherited.activity
    },
    response() {
      return this.inherited.response
    },
    sections() {
      return (this.activity.sections || []).map((section, i) => {
        const previousSection = this.activity.sections[i - 1] || {}
        const isAvailable =
          this.isTeacherPreview ||
          i === 0 ||
          !previousSection.completeFirst ||
          this.response.lockedSections.includes(i - 1)
        const isLocked =
          !this.isTeacherPreview &&
          section.completeFirst &&
          this.response.lockedSections.includes(i)
        return {
          ...section,
          index: i,
          isAvailable,
          isLocked
        }
      })
    }
  }
}
</script>

<style></style>
