<template>
  <div class="unauthenticated__overlay">
    <span class="unauthenticated__overlay-text">
      Please
      <router-link :to="{ name: 'login', query: { redirect: route.path } }">
        Sign In
      </router-link>
      or
      <router-link
        :to="{
          name: 'new_license_session',
          params: { licenseType: 'trial' }
        }"
      >
        Request a Free Trial
      </router-link>
      to view this {{ humanize(componentType) }}.
    </span>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import humanize from 'src/shared/utils/humanize'

const route = useRoute()

const props = defineProps({
  componentType: {
    type: String,
    required: true
  }
})
</script>

<style scoped lang="scss">
.unauthenticated__overlay {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-weight: bold;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  width: 80%;
  height: 400px;
  margin: auto;
}
.unauthenticated__overlay-text {
  text-align: center;
}
</style>
