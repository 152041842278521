<template>
  <dashboard-panel class="license-info-widget">
    <dashboard-panel-header> My Dashboard </dashboard-panel-header>
    <p class="license-info-widget__intro-paragraph">
      Welcome to Pivot Interactives, where you can give your students 24/7
      access to interactive, real-world science.
    </p>
    <div class="license-info-widget__column">
      <div v-if="siteLicense">
        <h3>License Information</h3>

        <div>
          License Status:
          <span
            ><strong
              v-bind:class="{
                'license-info-widget__active ': siteLicense.status === 'active',
                'license-info-widget__inactive ':
                  siteLicense.status !== 'active'
              }"
              >{{ siteLicense.status?.toUpperCase() }}</strong
            >
          </span>
        </div>
        <div v-if="user.siteLicense">
          <div>{{ capitalize(user.siteLicense.type) }} License</div>
          <div v-if="siteLicense.endDate">
            Expires:
            <strong
              v-bind:class="{
                'license-info-widget__active ': siteLicense.status === 'active',
                'license-info-widget__inactive ':
                  siteLicense.status !== 'active'
              }"
              >{{ new Date(siteLicense.endDate).toLocaleDateString() }}
            </strong>
            <span v-if="user.siteLicense.renewalScheduled">
              (Renewal Scheduled)</span
            >
          </div>
          <div>
            <router-link
              v-if="isSiteLicenseAdmin"
              :to="{ name: 'license', params: { tab: 'manage' } }"
            >
              <a>Manage License</a>
            </router-link>
          </div>
        </div>
      </div>

      <div>
        <h3>Personal Information</h3>

        <div>{{ user.firstName }} {{ user.lastName }}</div>
        <div>{{ user.email }}</div>
        <div>
          {{
            user.vetStatus === 'vetted' ? 'Verified User' : 'Unverified User'
          }}
          <help-popover
            v-if="user.vetStatus !== 'vetted'"
            :to="{ name: 'account_suspended' }"
          >
            Why is my account unverified?
          </help-popover>
        </div>
        <div>
          <router-link :to="{ name: 'edit_profile' }">
            <a>Edit Profile</a>
          </router-link>
        </div>
      </div>

      <div v-if="user.siteLicense" v>
        <h3>School Information</h3>

        <div>School Name: {{ siteLicense?.institutionName }}</div>
        <div v-if="emailDetails">
          Admin email:
          <a :href="`mailto:${emailDetails}`">{{ emailDetails }}</a>
        </div>
        <div># of Admins: {{ adminDetails }}</div>
        <div>
          <router-link
            v-if="isSiteLicenseAdmin"
            :to="{ name: 'license', params: { tab: 'manage' } }"
          >
            View Information
          </router-link>
        </div>
      </div>
      <div v-if="!user.siteLicense">
        <h3>Upgrade to the latest license model</h3>
        <div>
          With your current legacy account, you are unable to
          <b>purchase new seats</b>, <b>share seats</b>, or
          <b>renew your account</b>. Message us in the chat now to roll your
          account into a new license model. There is <b>no fee</b> with this
          change - and it only takes a couple of minutes!
        </div>
      </div>
    </div>
  </dashboard-panel>
</template>

<script setup>
import DashboardPanel from 'src/shared/components/DashboardPanel.vue'
import DashboardPanelHeader from 'src/shared/components/DashboardPanelHeader.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const siteLicense = computed(() => store.state.auth.user.siteLicense)

const user = computed(() => store.state.auth.user)

const isSiteLicenseAdmin = siteLicense.value?.role === 'admin'

const emailDetails = computed(() => siteLicense.value?.firstAdminEmail)
const adminDetails = computed(() => siteLicense.value?.adminCount)
const statusDetails = computed(
  () =>
    siteLicense.value?.status === 'active' ||
    (siteLicense.value?.status === 'expiring' &&
      siteLicense.value?.renewalScheduled)
)

function capitalize(s = '') {
  return s && s[0].toUpperCase() + s.slice(1)
}
</script>
<style scoped>
.license-info-widget {
  padding: 0 15px;
  height: 100%;
  width: 100%;
}
.license-info-widget__column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
  gap: 20px;
}

.license-info-widget__intro-paragraph {
  font-size: 16px;
  line-height: normal;
}

.container > div {
  max-width: 300px;
  font-size: 16px;
}
.license-info-widget__active {
  color: #16b8b7;
}

.license-info-widget__inactive {
  color: #f1af51;
}
</style>
