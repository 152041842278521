<template>
  <div v-bind="wrapperAttrs" :class="errorMessage ? 'has-error' : ''">
    <div
      v-bind="inputAttrs"
      class="selector-input"
      :class="{
        'selector-input--vertical': direction === 'vertical',
        'selector-input--horizontal': direction === 'horizontal'
      }"
      role="group"
      :aria-describedby="`${helpId} ${ariaDescribedby}`"
    >
      <slot />
    </div>
    <span v-if="errorMessage || helpText" :id="helpId" class="help-block">
      {{ errorMessage || helpText }}
    </span>
  </div>
</template>

<script>
import { toRef } from 'vue'
import * as Vue from 'vue'
import { useField } from 'vee-validate'
let counter = 0

export default {
  name: 'SelectorInput',
  inheritAttrs: false,
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: {
      type: null,
      default: null
    },
    label: {
      type: String,
      default: 'field'
    },
    name: {
      type: String,
      default: () => `selector-input-${counter++}`
    },
    helpText: {
      type: String,
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: null
    },
    rules: {
      type: [Object, String],
      default: null
    },
    direction: {
      type: String,
      default: 'horizontal'
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField,
      meta
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: props.modelValue,
      validateOnValueUpdate: false,
      syncVModel: false
    })

    return {
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField,
      meta
    }
  },
  provide() {
    return {
      selectorInput: Vue.computed(() => ({
        name: this.name,
        value: this.inputValue,
        onChange: value => {
          this.handleChange(value)
          this.$emit('update:modelValue', value)
          this.$emit('change', value)
        },
        onBlur: this.handleBlur
      }))
    }
  },
  computed: {
    helpId() {
      return `${this.name}-help`
    },
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    inputAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue !== this.inputValue) {
        this.resetField({ value: this.modelValue })
      }
    }
  }
}
</script>

<style lang="scss">
.selector-input {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid $light-grey;

  &:focus-within {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .has-error & {
    border-color: #a94442;
  }
}

.selector-input--vertical {
  .selector-option__label {
    border-bottom-width: 1px;
    width: 100%;
  }
}

.selector-input--horizontal {
  &.selector-input {
    display: inline-flex;
    width: initial;
  }

  .selector-option__label {
    border-right-width: 1px;
  }
}

.selector-option__label {
  border: 0 $light-grey solid;
  font-weight: 700;
  color: $dark-grey;
  height: 36px;
  font-size: 14px;
  padding: 6px 14px;
  line-height: 1.6;
  margin: 0;
  text-align: center;

  .selector-option__input:checked + & {
    background-color: $teal;
    color: #ffffff;
  }

  .selector-option:last-of-type & {
    border-bottom-width: 0;
    border-right-width: 0;
  }
}

.selector-option__input {
  opacity: 0;
  position: absolute;
}
</style>
