import {
  SET_AUTH,
  ADD_SEATS,
  LTI_MODE,
  OAUTH_MODE,
  SET_HAS_MFA
} from 'src/setup/store/mutation-types'
import { initConsent } from '../../consent'
import client from 'src/shared/api-client.ts'
import * as Sentry from '@sentry/browser'
import { initAnalytics } from 'src/setup/analytics'

const MODE_NORMAL = 'normal'
const MODE_LTI = 'lti'
const MODE_OAUTH = 'oauth'

const state = {
  user: null,
  mode: MODE_NORMAL,
  hasAuth: false
}

const getters = {
  isLtiMode: ({ mode }) => mode === MODE_LTI,
  isNormalMode: ({ mode }) => mode === MODE_NORMAL,
  isOAuthMode: ({ mode }) => mode === MODE_OAUTH,
  isAuthenticated: ({ user }) => !!user,
  isAdmin: ({ user }) => !!user && user.role === 'admin',
  isStudent: ({ user }) => !!user && user.role === 'student',
  isTeacher: ({ user }) => !!user && user.role === 'teacher',
  isGrader: ({ user }) =>
    !!user && !!user.siteLicense && user.siteLicense.role === 'grader',
  isContentDeveloper: ({ user }) => !!user && user.role === 'content_developer',
  isSalesAdmin: ({ user }) => !!user && user.role === 'sales_admin',
  isSiteLicenseAdmin: ({ user }) =>
    !!user && !!user.siteLicense && user.siteLicense.role === 'admin',
  isTrialTeacher: ({ user }) =>
    !!user && !!user.siteLicense && user.siteLicense.type === 'trial',
  statusExpirationDays: ({ user }) => {
    if (user && user.siteLicense && user.siteLicense.type === 'trial') {
      return Math.max(
        0,
        Math.floor(
          (new Date(user.siteLicense.endDate).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        )
      )
    }
  },
  isLicenseExpiring: ({ user }) => {
    if (!user) return false
    if (user.siteLicense) {
      return user.siteLicense.status === 'expiring'
    }
    return false
  },
  isLicenseActive: (
    { user },
    { isAdmin, isContentDeveloper, isSalesAdmin, isStudent }
  ) => {
    if (!user) {
      return false
    } else if (isAdmin || isContentDeveloper || isSalesAdmin || isStudent) {
      return true
    } else if (user.siteLicense) {
      return (
        user.siteLicense.status === 'active' ||
        user.siteLicense.status === 'expiring'
      )
    } else {
      return false
    }
  },
  hasPendingLicenseInvites: ({ user }) => {
    return user && user.pendingInvites && user.pendingInvites.length
  },
  shouldHaveMessageBars: (
    _state,
    {
      isImpersonating,
      hasPendingLicenseInvites,
      isTrialExpiring,
      isLicenseActive,
      hasSiteLicense
    }
  ) => {
    if (hasSiteLicense) return false
    return (
      isImpersonating ||
      hasPendingLicenseInvites ||
      isTrialExpiring ||
      !isLicenseActive
    )
  },
  hasSiteLicense: ({ user }) => {
    return !!user?.siteLicense
  },
  isImpersonating: ({ user }) => {
    return user && user.impersonatingUser
  },
  isBetaUser: state =>
    !!state.user &&
    (['admin'].indexOf(state.user.role) > -1 || state.user.isBetaUser),
  canManageUsers: state =>
    !!state.user && ['admin'].indexOf(state.user.role) > -1,
  canManageActivities: state =>
    !!state.user &&
    ['teacher', 'content_developer', 'admin'].indexOf(state.user.role) > -1,
  canManageClasses: state =>
    !!state.user &&
    ['teacher', 'admin', 'content_developer'].indexOf(state.user.role) > -1,
  canManageResponses: state =>
    !!state.user &&
    ['teacher', 'admin', 'content_developer'].indexOf(state.user.role) > -1,
  canManageLibrary: state =>
    !!state.user &&
    ['content_developer', 'admin'].indexOf(state.user.role) > -1,
  canManageFreeActivities: state => !!state.user && state.user.role === 'admin',
  canDoAssignments: state =>
    !!state.user && ['student'].indexOf(state.user.role) > -1,
  fullName: state =>
    state.user ? `${state.user.firstName} ${state.user.lastName}` : '',
  firstName: state => (state.user ? `${state.user.firstName}` : ''),
  institutionRoles: state => (state.user ? state.user.institutionRoles : []),
  primarySubjects: state => (state.user ? state.user.primarySubjects : []),
  userId: state => state.user && state.user.id,
  vetStatus: ({ user }) => {
    if (user && user.vetStatus) {
      switch (user.vetStatus) {
        case 'vetted':
        case 'prevetted':
          return { value: 'Verified', icon: 'circle-check' }
        case 'unvetted':
          return { value: 'Pending', icon: 'hourglass-start' }
        case 'rejected':
          return { value: 'Rejected', icon: 'ban' }
        default:
          return { value: 'Unknown', icon: '' }
      }
    }
    return null
  },
  isVetted: ({ user }, { isAdmin, isContentDeveloper, isSalesAdmin }) => {
    return (
      isAdmin ||
      isContentDeveloper ||
      isSalesAdmin ||
      ['vetted', 'prevetted'].includes(user?.vetStatus)
    )
  },
  loginMethods: ({ user }) => {
    return user?.authType ?? []
  }
}

const mutations = {
  [SET_AUTH](state, user) {
    state.hasAuth = true
    if (user) {
      state.user = {
        ...user,
        // Convert statusExpiration to a Date object if it exists.
        ...(user.statusExpiration && {
          statusExpiration: new Date(user.statusExpiration)
        })
      }
    } else {
      state.user = null
    }
  },
  // Add seats to the user after activating from a purchase.
  [ADD_SEATS]({ user }, seats) {
    user.seats += seats
  },
  [LTI_MODE](state) {
    state.mode = MODE_LTI
  },
  [OAUTH_MODE](state) {
    state.mode = MODE_OAUTH
  },
  [SET_HAS_MFA](state, hasMFA) {
    if (state.user) {
      state.user.hasMFA = hasMFA
    }
  }
}

const actions = {
  async initAuth({ dispatch, commit }) {
    try {
      const user = await client.users.getMyself()
      commit(SET_AUTH, user)

      if (!user.impersonatingUser) {
        initAnalytics(user)
      }
      if (window.PI_SENTRY_DSN) {
        Sentry.setUser({ id: user.id })
      }
    } catch (error) {
      commit(SET_AUTH, null)
      initConsent()
      initAnalytics()
      if (window.PI_SENTRY_DSN) {
        Sentry.configureScope(scope => scope.clear())
      }
    }
  },

  async refreshAuth({ commit, state }) {
    try {
      const user = await client.users.getMyself()
      if (state.user.id !== user.id) {
        window.location.assign('/')
      } else {
        commit(SET_AUTH, user)
      }
    } catch (error) {
      window.location.assign('/')
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
