<template>
  <modal @submit="submit" :closable="!enforce">
    <modal-header>MFA Secret</modal-header>
    <modal-body v-if="!verifyingToken">
      <p v-show="message">{{ message }}</p>
      <p>
        Use the details below to configure your MFA device. You cannot access
        the details again once you close this modal!
      </p>
      <form-group>
        <form-label for="secret"> Secret </form-label>
        <sensitive-input
          :modelValue="secret"
          label="multi-factor secret"
          disabled
        />
      </form-group>
      <form-group>
        <form-label for="uri"> URI </form-label>
        <sensitive-input :modelValue="uri" label="multi-factor uri" disabled />
      </form-group>
      <div class="qr-wrapper">
        <img :src="qr" />
      </div>
    </modal-body>
    <modal-body v-else>
      <p>
        Use the MFA device you configured to generate and validate a token to
        complete MFA configuration for your account.
      </p>
      <form-group>
        <form-label for="token"> Token </form-label>
        <text-input
          v-model="token"
          rules="required"
          label="multi-factor token"
        />
      </form-group>
    </modal-body>
    <modal-actions v-if="!verifyingToken">
      <modal-button-action @click="verifyingToken = true"
        >Continue</modal-button-action
      >
      <modal-button-action v-if="enforce" @click="$router.push('/logout')">
        Log Out
      </modal-button-action>
      <modal-button-cancel v-else>Cancel</modal-button-cancel>
    </modal-actions>
    <modal-actions v-else>
      <modal-button-submit>Submit</modal-button-submit>
      <modal-button-action @click="verifyingToken = false">
        Back
      </modal-button-action>
    </modal-actions>
  </modal>
</template>

<script>
import client from 'src/shared/api-client'
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonCancel,
  ModalButtonSubmit,
  ModalButtonAction
} from 'src/shared/components/modals/components'
import SensitiveInput from 'src/shared/components/SensitiveInput'

export default {
  name: 'MFASecretModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonCancel,
    ModalButtonSubmit,
    ModalButtonAction,
    SensitiveInput
  },
  props: {
    secret: {
      type: String,
      required: true
    },
    uri: {
      type: String,
      required: true
    },
    qr: {
      type: String,
      requried: true
    },
    message: {
      type: String,
      required: false
    },
    enforce: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      token: '',
      verifyingToken: false
    }
  },
  methods: {
    async submit({ done }) {
      try {
        await client.auth.configureMFA({
          secret: this.secret,
          token: this.token
        })
        done()
      } catch (e) {
        done(false)
        throw e
      }
    }
  }
}
</script>

<style scoped>
.qr-wrapper {
  width: 100%;
}

.qr-wrapper img {
  display: block;
  width: 166px;
  height: auto;
  margin: auto;
}
</style>
