<template>
  <div class="answer-key">
    <h4 class="answer-key__heading">Correct Answers</h4>
    <div class="answer-key__table-padding">
      <p v-if="isAnswerRandomized" class="answer-key__subheading">
        This answer is based on values that are randomized.
      </p>
      <form-button v-if="isAnswerRandomized && !grading" link @click="showModal"
        >How can I see the answer for a specific student?</form-button
      >

      <table>
        <thead>
          <tr>
            <th>Answer</th>
            <th>Range</th>
            <th>Student Answer</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(condition, index) in correctAnswers" :key="index">
            <td>
              <span class="answer-key__display-expression">
                {{ condition.displayExpression }}</span
              >
              <span
                v-if="typeof condition.value === 'number' && condition.accuracy"
              >
                ±
                {{ condition.accuracy
                }}{{ condition.accuracyType === 'absolute' ? '' : '%' }}
              </span>
            </td>
            <td>
              <template v-if="condition.range">
                {{ condition.range.min }}
                -
                {{ condition.range.max }}
              </template>
            </td>
            <td>
              <div class="answer-key__student-answer">
                {{ studentAnswer }}
                <icon
                  v-if="condition.isStudentCorrect"
                  icon="circle-check"
                  class="correct-icon"
                />
                <span class="sr-only" v-if="condition.isStudentCorrect">
                  Correct
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs, inject } from 'vue'
import math from 'src/setup/math'
import {
  replaceVariablesWithNames,
  resolveExpressionVariables,
  simplifyExpression
} from '@pi/shared/variables'
import AlertModal from 'src/shared/components/modals/AlertModal'

const props = defineProps({
  variableContext: {
    type: Object,
    default: undefined
  },
  component: {
    type: Object,
    default: undefined
  },
  response: {
    type: Object,
    default: undefined
  },
  grading: {
    type: Boolean,
    defualt: false
  }
})
const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: 8 })
const { variableContext } = toRefs(props)
const $modal = inject('$modal')
const studentAnswer = computed(() => props.response.value)
const component = computed(() => props.component)

const randomVariableList = computed(() => {
  return variableContext.value.variables
    .filter(variable =>
      ['random', 'collection'].includes(variable.variableType)
    )
    .map(variable => variable.id)
})

const isAnswerRandomized = computed(() => {
  return correctConditions.value.some(condition => {
    return randomVariableList.value.some(variableId =>
      condition.condition.includes(variableId)
    )
  })
})

const correctConditions = computed(() =>
  component.value.conditions.filter(condition => condition.isCorrect)
)

// The variableScope gives mathjs all variables with known numeric values
const variableScope = computed(() => {
  return Object.fromEntries(
    variableContext.value.variables.map(variable => {
      if (variable.variableType === 'random') {
        return [[`$${variable.id}`], math.bignumber(variable.value)]
      }
      if (variable.variableType === 'studentResponse') {
        // Numbers can contain e for scientific notation, but not any other letter
        // We only use base 10 for answers as far as I know
        const isId = /[a-df-zA-DF-Z]/.test(variable.value)

        if (typeof variable.value === 'number') {
          return [[`$${variable.id}`], math.bignumber(variable.value)]
        }
        if (
          typeof variable.value === 'string' &&
          !isId &&
          !isNaN(parseFloat(variable.value))
        ) {
          return [
            [`$${variable.id}`],
            math.bignumber(parseFloat(variable.value))
          ]
        }
        return []
      }
      if (variable.variableType === 'collection') {
        return [
          [`$${variable.id}`],
          Object.fromEntries(
            variable.variables.map(v => {
              return typeof v.value === 'number'
                ? [`$${v.id}`, math.bignumber(v.value)]
                : []
            })
          )
        ]
      }
    })
  )
})

const correctAnswers = computed(() =>
  correctConditions.value
    .map(condition => {
      // First replace all known variables.

      const resolvedCondition = resolveExpressionVariables(
        condition.condition,
        variableScope.value,
        variableContext.value.variables
      )

      let simplifiedCondition = simplifyExpression(resolvedCondition)

      // If we don't process numbers when converting to string,
      // they could potentially have a signficant amount of digits.
      function handler(node) {
        if (
          node.isConstantNode &&
          (node.value.isBigNumber || typeof node.value === 'number')
        ) {
          return formatter.format(node.value)
        }
      }
      const displayExpression = replaceVariablesWithNames(
        (simplifiedCondition ?? resolvedCondition).toString({
          handler
        }),
        variableContext.value.variables
      )

      let value
      try {
        value = resolvedCondition.evaluate({
          ...(typeof studentAnswer.value === 'number' && {
            answer: math.bignumber(studentAnswer.value)
          })
        })
      } catch (error) {}

      let range,
        isStudentCorrect = false
      if (typeof value === 'boolean') {
        isStudentCorrect = value
      } else if (value?.isBigNumber || typeof value === 'number') {
        value = math.number(value)
        switch (condition.accuracyType) {
          case 'absolute': {
            range = {
              min: value - condition.accuracy,
              max: value + condition.accuracy
            }
            break
          }
          case 'percent':
          default: {
            const delta = (condition.accuracy / 100) * value
            range = { min: value - delta, max: value + delta }
            break
          }
        }

        isStudentCorrect =
          typeof studentAnswer.value === 'number' &&
          studentAnswer.value >= range.min &&
          studentAnswer.value <= range.max

        range.min = formatter.format(range.min)
        range.max = formatter.format(range.max)
      }

      return {
        displayExpression,
        value,
        isStudentCorrect,
        range,
        accuracy: condition.accuracy,
        accuracyType: condition.accuracyType
      }
    })
    .filter(
      condition =>
        typeof condition.value !== 'boolean' ||
        condition.displayExpression.includes('answer')
    )
)

const showModal = async () => {
  await $modal.show(AlertModal, {
    title: 'Randomized question',
    html: "<p> Each time you reload this preview, the randomized values and the correct answer to this question will change. </p><p>To see a correct answer for a specific student's randomization, go to:</p><p><b>My classes > Click the class > Click the assignment name > Grade Response</b> </p>",
    body: null
  })
}
</script>

<style scoped lang="scss">
table {
  width: 500px;
  table-layout: fixed;
  border-collapse: collapse;
}

thead {
  border-bottom: 1px solid rgb(207, 205, 205);
}

thead th:nth-child(1) {
  width: 40%;
}
thead th:nth-child(2) {
  width: 35%;
}
thead th:nth-child(3) {
  width: 25%;
}

tbody::before {
  height: 10px;
  display: table-row;
  content: '';
}

th {
  font-size: 16px;
  font-weight: 500;
}

.correct-icon {
  color: $color-success;
}
.answer-key {
  background-color: #7651a61a;
}

.answer-key__heading {
  padding: 10px 0px 0px 15px;
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
}
.answer-key__subheading {
  font-style: italic;
  margin-bottom: 0px;
}

.answer-key__table-padding {
  padding: 15px;
}

.answer-key__student-answer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer-key__display-expression {
  line-height: 1.2em;
  display: inline-block;
}
</style>
