<template>
  <activity-question
    :variable-context="variableContext"
    :view-as-student="viewAsStudent"
  >
    <activity-response-open-ended-realtime
      v-if="component.componentType === 'OpenEndedQuestion'"
      :component="component"
      :component-response="componentResponseMap"
      :awareness="yjsProvider.awareness"
    />
    <activity-response-numerical
      v-if="component.componentType === 'NumericalQuestion'"
      :canSubmit="canSubmit"
      @submit="emit('submit')"
    />
    <activity-response-choice
      v-if="component.componentType === 'MultipleChoiceQuestion'"
      :variable-context="variableContext"
      :view-as-student="viewAsStudent"
    />
    <activity-response-drag-drop
      v-if="component.componentType === 'DragDropQuestion'"
      :component-response="componentResponseMap"
      :variable-context="variableContext"
      @canSubmit="emit('canSubmitChange')"
      :view-as-student="viewAsStudent"
    />
    <activity-response-grid-graph-realtime
      v-if="
        component.componentType === 'GridGraphQuestion' ||
        component.componentType === 'GridQuestion'
      "
      :component="component"
      :component-response="componentResponseMap"
      allow-reset
      :view-as-student="viewAsStudent"
      :variable-context="variableContext"
    />
    <activity-question-autograde
      @submit="emit('submit')"
      :canSubmit="canSubmit"
    />
    <activity-question-feedback readonly>
      <template #score>
        <activity-question-score readonly />
      </template>

      <template #comments>
        <activity-question-comments-realtime
          :component="component"
          :component-response="componentResponseMap"
          readonly
          :grading="!viewAsStudent"
        />
      </template>
    </activity-question-feedback>
  </activity-question>
  <activity-instance :variable-context="variableContext" />
  <activity-instructor-instance />
  <activity-student-instance />
  <activity-frame :variable-context="variableContext" />
  <activity-legacy-phet-io-simulation :variable-context="variableContext" />
  <activity-phet-io-simulation :variable-context="variableContext" />
  <activity-general-note
    :variable-context="variableContext"
    :view-as-student="viewAsStudent"
  />
</template>

<script setup lang="ts">
import ActivityQuestionFeedback from 'src/modules/activities/components/ActivityQuestionFeedback.vue'
import ActivityFrame from 'src/modules/activities/components/ActivityFrame.vue'
import ActivityLegacyPhetIoSimulation from 'src/modules/activities/components/ActivityLegacyPhetIoSimulation.vue'
import ActivityPhetIoSimulation from 'src/modules/activities/components/ActivityPhetIoSimulation.vue'
import ActivityGeneralNote from 'src/modules/activities/components/ActivityGeneralNote.vue'
import ActivityQuestion from 'src/modules/activities/components/ActivityQuestion.vue'
import ActivityQuestionCommentsRealtime from 'src/modules/activities/components/ActivityQuestionCommentsRealtime.vue'
import ActivityQuestionScore from 'src/modules/activities/components/ActivityQuestionScore.vue'
import ActivityQuestionAutograde from 'src/modules/activities/components/ActivityQuestionAutograde.vue'
import ActivityResponseOpenEndedRealtime from 'src/modules/activities/components/ActivityResponseOpenEndedRealtime.vue'
import ActivityResponseNumerical from 'src/modules/activities/components/ActivityResponseNumerical.vue'
import ActivityResponseChoice from 'src/modules/activities/components/ActivityResponseChoice.vue'
import ActivityResponseDragDrop from 'src/modules/activities/components/ActivityResponseDragDrop.vue'
import ActivityResponseGridGraphRealtime from 'src/modules/activities/components/ActivityResponseGridGraphRealtime.vue'
import ActivityInstance from 'src/modules/activities/components/ActivityInstance.vue'
import ActivityInstructorInstance from 'src/modules/activities/components/ActivityInstructorInstance.vue'
import ActivityStudentInstance from 'src/modules/activities/components/ActivityStudentInstance.vue'
import * as Y from 'yjs'
import { HocuspocusProvider } from '@hocuspocus/provider'

type ActivitySectionComponentRenderProps = {
  variableContext: any
  canSubmit: boolean
  component: any
  componentResponseMap: Y.Map<unknown>
  yjsProvider: HocuspocusProvider
  viewAsStudent?: boolean
}

defineProps<ActivitySectionComponentRenderProps>()
const emit = defineEmits(['submit', 'canSubmitChange', 'complete'])
</script>
