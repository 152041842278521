import useScript from './script'
import { ref, watchEffect } from 'vue'

const MATHJAX_SRC = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js'

export default function useLatexToText() {
  const isReady = ref(false)
  useScript({
    url: MATHJAX_SRC,
    onBeforeLoad() {
      window.MathJaxLoader = new Promise(resolve => {
        window.MathJax = {
          loader: { load: ['a11y/semantic-enrich'] },
          skipStartupTypeset: true,
          tex: {
            inlineMath: []
          },
          startup: {
            ready: function () {
              window.MathJax.startup.defaultReady()
              // Initialise convert when MathJax/SRE is fully loaded.
              window.MathJax.startup.promise.then(() => {
                window.MathJax._.a11y.sre.Sre.setupEngine({
                  locale: 'en',
                  domain: 'mathspeak',
                  modality: 'speech',
                  pprint: true
                })
                window.SRE = window.MathJax._.a11y.sre.Sre
                window.SRE.sreReady().then(() => {
                  isReady.value = true
                  resolve(true)
                })
              })
            }
          }
        }
      })
    },
    onAlreadyLoaded() {
      if (window.SRE) {
        window.SRE.sreReady().then(() => {
          isReady.value = true
        })
      } else if (window.MathJaxLoader) {
        window.MathJaxLoader.then(() => {
          isReady.value = true
        })
      }
    }
  })
  const waiting = ref([])

  watchEffect(() => {
    if (isReady.value) {
      const toResolve = waiting.value.splice(0, waiting.value.length)
      toResolve.forEach(fn => fn())
    }
  })

  const toText = async code => {
    if (isReady.value) {
      const mml = await window.MathJax.tex2mmlPromise(code)
      return window.SRE.toSpeech(mml)
    } else {
      await new Promise(resolve => {
        waiting.value.push(resolve)
      })
      return toText(code)
    }
  }

  return {
    toText
  }
}
