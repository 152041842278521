<template>
  <view-container narrow>
    <view-header>
      <view-title>Class Payment</view-title>
    </view-header>
    <class-payment-form
      v-if="classKey"
      :class-key="classKey"
      @submit="onSubmit"
    />
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'
import ClassPaymentForm from '../../classes/components/ClassPaymentForm'

export default {
  name: 'LtiClassPaymentView',
  components: {
    ClassPaymentForm
  },
  data() {
    return {
      classKey: null
    }
  },
  methods: {
    onSubmit() {
      this.$router.push({ name: 'lti' })
    }
  },
  async mounted() {
    let ltiSession
    ltiSession = await client.lti.getSession()
    
    if (ltiSession.state !== 'CLASS_PAYMENT') {
      this.$router.push({ name: 'home' })
    } else {
      this.classKey = ltiSession.context.class.classKey
    }
  }
}
</script>
