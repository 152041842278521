<template>
  <form-button
    ref="button"
    class="submit-button"
    :class="{
      'submit-button--submitting': !hideState && isSubmitting && matchesAction,
      'submit-button--submitted': !hideState && showSuccess && matchesAction
    }"
    type="submit"
    :disabled="disabled || isSubmitting || showSuccess"
    @click="form.setAction(action)"
  >
    <template v-if="!hideState && isSubmitting && matchesAction">
      <span class="submit-button__loader" aria-hidden="true" />
      <slot name="submitting">Submitting</slot>
    </template>
    <template v-else-if="!hideState && showSuccess && matchesAction">
      <transition name="v-btn-label" mode="out-in">
        <icon icon="check" class="submit-button__submitted" />
      </transition>
      <slot name="submitted">Submitted</slot>
    </template>
    <slot v-else />
  </form-button>
</template>

<script>
export default {
  name: 'SubmitButton',
  inject: ['form'],
  props: {
    action: {
      type: String,
      default: 'submit'
    },
    hideState: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { showSuccess: false }
  },
  computed: {
    matchesAction() {
      return this.form.action === this.action
    },
    isSubmitting() {
      return this.form.isSubmitting
    }
  },
  watch: {
    'form.wasSuccessful'() {
      if (this.form.wasSuccessful) {
        this.showSuccess = true
        setTimeout(() => {
          this.showSuccess = false
        }, 1100)
      }
    }
  },
  methods: {
    focus() {
      this.$refs.button.focus()
    }
  }
}
</script>

<style lang="scss">
.submit-button--submitting {
  color: white;
  background-color: $teal;
  border: $teal solid 1px;
  display: inline-flex;
  align-items: center;

  &[disabled] {
    opacity: 1;
  }
}

.submit-button--submitted {
  color: white;
  pointer-events: none;
  background-color: $color-success;
  border: $color-success solid 1px;
  display: inline-flex;
  align-items: center;

  &[disabled] {
    opacity: 1;
  }

  &:hover,
  &:focus {
    background-color: $color-success;
    border: $color-success solid 1px;
  }
}

.submit-button__submitted {
  display: inline-block;
  margin: 0 4px 0 -4px;
  position: relative;
}

.submit-button__loader {
  margin: 0 6px 0 -8px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid white;
  animation: load 1.1s infinite linear;
}

.form-button--alpha .submit-button__loader {
  border-top: 3px solid rgba(60, 57, 58, 0.2);
  border-right: 3px solid rgba(60, 57, 58, 0.2);
  border-bottom: 3px solid rgba(60, 57, 58, 0.2);
  border-left: 3px solid #3c393a;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-btn-label-enter-active {
  animation: fadeIn ease 260ms;
}

.v-btn-label-leave-active {
  animation: fadeOut ease 260ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}
</style>
