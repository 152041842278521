<template>
  <modal class="replace-activity-modal">
    <modal-header>New version alert</modal-header>
    <modal-body class="replace-activity-modal__body">
      <div>
        <strong
          >Pivot Interactives has created an updated version of this activity in
          the Pivot Library</strong
        >
        <p v-if="libraryId !== 'pivot'" class="mt-4">
          To take advantage of this new version, select
          <span class="underline"
            >&ldquo;Archive & Replace Activity&rdquo;</span
          >
          to copy the new activity to your current library. Your old activity
          will be moved to your archive, and you can review the new activity
          before assigning.
          <br />To assign the current activity without the updates, select
          <span class="underline">&ldquo;Assign Anyway&rdquo;</span>
        </p>
        <p v-else>
          To see this new version, click on "Preview Updated Activity in New
          Tab" below. You can then copy this updated activity to a personal
          library to assign it.
        </p>
        <collapse-provider v-model:collapsed="collapsed">
          <collapse-toggle class="updated-version__collapse-toggle">
            <collapse-icon
              class="updated-version__collapse-icon"
              icon="caret"
            />
            <span class="updated-version__heading">
              Show Replacement Info
            </span>
          </collapse-toggle>
          <a
            v-if="libraryId !== 'pivot'"
            class="updated-version__link"
            target="_blank"
            :href="`/activities/${props.updatedActivity.id}/preview`"
            >Preview Updated Activity in New Tab</a
          >
          <collapse-content class="updated-version__content">
            <span class="updated-version__date"
              >{{
                format(
                  new Date(props.updatedActivity.createdAt),
                  'MM/dd/yyyy HH:mm:ss'
                )
              }}
              by {{ props.updatedActivity.createdBy }}</span
            ><br />
            <strong>Explanation of replaced activity</strong><br />
            <p>{{ props.updatedActivity.description }}</p>
          </collapse-content>
        </collapse-provider>
      </div>
    </modal-body>
    <modal-actions class="updated-version__modal-actions">
      <div v-if="libraryId !== 'pivot'">
        <form-button v-if="!props.hideAssign" secondary @click="assignAnyway">
          Assign Anyway
        </form-button>
        <modal-button-submit @click="archiveActivity"
          >Archive & Replace</modal-button-submit
        >
      </div>
      <div v-else>
        <UIButton
          :to="{
            name: 'preview_activity',
            params: { id: props.updatedActivity.id }
          }"
          openInNewTab
        >
          Preview Updated Activity
        </UIButton>
      </div>
      <form-button destructive class="flex-start" @click="closeModal">
        Cancel
      </form-button>
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import CollapseToggle from 'src/shared/components/global/CollapseToggle.vue'
import CollapseIcon from 'src/shared/components/global/CollapseIcon.vue'
import CollapseContent from 'src/shared/components/global/CollapseContent.vue'
import { useFlash } from 'src/shared/hooks/flash'
import { format } from 'date-fns'
import { ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import client from 'src/shared/api-client'
import { UIButton } from '@pi/design'

interface ActivityRelationshipView {
  id: string
  description: string
  createdBy: string
  createdAt: Date
}

interface Props {
  activityId: string
  activityName: string
  updatedActivity: ActivityRelationshipView
  libraryId: string
  hideAssign: boolean
}

interface SubmitProps {
  done: () => void
}
const props = defineProps<Props>()
const collapsed = ref(true)

const flash = useFlash()
const router = useRouter()
const $modal = inject<any>('$_modal')
const assignAnyway = () => {
  $modal.status('ok')
}
const closeModal = () => {
  $modal.close()
}

const archiveActivity = async () => {
  const newActivity = await client.activities.copy({
    activityId: props.updatedActivity.id,
    destinationLibraryId: props.libraryId
  })
  await client.activities.archive({
    activityId: props.activityId,
    isArchived: true
  })
  closeModal()
  flash.success('Activity has been archived and replaced')
  router.replace({ name: 'activity', params: { id: newActivity.activityId } })
}
</script>
<style scoped>
.replace-activity-modal {
  width: 600px;
}

.updated-version__heading {
  color: #3d2c60;
  font-size: 16px;
}
.updated-version__collapse-toggle {
  width: 100%;
}

.updated-version__link {
  float: right;
  margin-top: -23px;
  position: relative;
  z-index: 10;
}
.updated-version__content {
  font-size: 16px;
}
.updated-version__date {
  color: #808080;
}

.flex-start {
  align-self: flex-start;
}

.updated-version__modal-actions {
  justify-content: space-between;
}
</style>
