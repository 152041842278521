<template>
  <th ref="th" scope="col" :aria-readonly="readonly" :width="columnWidth">
    <div class="column-header__wrapper">
      <data-grid-input-field
        class="column-header__name"
        :title="name || defaultColumnName"
        :value="name.formula ?? name"
        :type="name.parser ?? 'text'"
        :placeholder="defaultColumnName"
        :readonly="readonly"
        :aria-label="`column ${columnIndex + 1} name`"
        @focusin="() => setFocus('name')"
        @input="
          value =>
            $gridActions.setValueAtColumnAndRow(columnIndex, 'name', value)
        "
      />
      <button-dropdown
        v-if="!readonly"
        ref="dropdown"
        tabindex="-1"
        :aria-label="`column menu`"
        @focusin="() => setFocus(false)"
        button-class="column-header__menu-button"
        data-tablenav
      >
        <template #button>
          <icon icon="ellipsis-h" />
        </template>
        <template #default>
          <dropdown-action
            @click="
              $gridActions.showColumnSettingsModalForColumn(
                columnIndex,
                $refs.dropdown
              )
            "
            >Column Settings</dropdown-action
          >
          <dropdown-action
            v-if="!allowText"
            :disabled="!!deviceId"
            @click="
              $gridActions.showColumnFormulaModalForColumn(
                columnIndex,
                $refs.dropdown
              )
            "
            >Change Column Formula</dropdown-action
          >
          <dropdown-action
            @click="$gridActions.insertColumnBeforeColumn(columnIndex)"
            >Insert Column Left</dropdown-action
          >
          <dropdown-action
            @click="$gridActions.insertColumnAfterColumn(columnIndex)"
            >Insert Column Right</dropdown-action
          >
          <dropdown-action
            v-if="canRemoveColumn"
            @click="$gridActions.removeColumn(columnIndex)"
            >Remove Column</dropdown-action
          >
        </template>
      </button-dropdown>
    </div>
    <span class="resizer" @pointerdown="onMouseDown" />
  </th>
</template>

<script>
import { ref } from 'vue'
import * as Y from 'yjs'
import DataGridInputField from 'src/shared/components/grid-graph/DataGridInputField'
import {
  defaultColumnName,
  columnDisplayWidth
} from 'src/shared/components/grid-graph/utilities'

export default {
  name: 'DataGridColumnHeader',
  components: {
    DataGridInputField
  },
  emits: ['focus:cell'],
  inject: ['$gridActions'],
  props: {
    column: {
      type: Y.Map,
      required: true
    },
    columnIndex: Number,
    canRemoveColumn: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    focusedCell: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      name: this.column.get('name'),
      allowText: this.column.get('allowText'),
      deviceId: this.column.get('deviceId'),
      defaultColumnName,
      moving: false,
      x: 0,
      w: 0
    }
  },
  computed: {
    columnWidth() {
      return columnDisplayWidth(this.column)
    }
  },
  methods: {
    onMouseDown(e) {
      this.moving = true
      this.x = e.clientX
      const styles = window.getComputedStyle(this.$refs.th)
      this.w = parseInt(styles.width, 10)
      document.addEventListener('pointermove', this.handleResize)
      document.addEventListener('pointerup', this.onMouseUp)
    },
    onMouseUp() {
      if (!this.moving) return
      this.moving = false
      document.removeEventListener('pointermove', this.handleResize)
      document.removeEventListener('pointerup', this.onMouseUp)
    },
    handleResize(e) {
      const dx = e.clientX - this.x
      // Update the width of column
      let w = this.w + dx
      if (w < 60) w = 60
      this.$gridActions.setColumnWidth(this.columnIndex, w)
    },
    setFocus(row) {
      if (row) {
        this.$emit('focus:cell', {
          column: this.columnIndex,
          row,
          value: {
            value: this[row].formula ? this[row].formula : this[row],
            dataType: this[row].parser ? this[row].parser : 'text'
          }
        })
      } else {
        this.$emit('focus:cell', { column: null, row: null, value: null })
      }
    }
  },
  watch: {
    column: {
      handler() {
        this.unsubscribe?.()
        const column = this.column
        const onChange = () => {
          this.name = column.get('name')
          this.allowText = column.get('allowText')
          this.deviceId = column.get('deviceId')
        }
        onChange()
        column.observe(onChange)
        this.unsubscribe = () => {
          column.unobserve(onChange)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
th {
  min-width: 60px;
  z-index: 6 !important;
}
.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  user-select: none;
}

.column-header__wrapper {
  display: flex;
  height: 100%;
}

.column-header__name {
  flex: 1 1 0%;
}

:deep(.column-header__menu-button) {
  width: 34px;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  flex-shrink: 0;
}
</style>
