<template>
  <modal class="settings-modal" @submit="onSubmit">
    <modal-header
      >{{ title }}
      <form-button link @click="toggleAllOn" class="text-2xl space-x-6"
        >Select All</form-button
      >
      <form-button link @click="toggleAllOff" class="text-2xl space-x-6"
        >Deselect All</form-button
      >
    </modal-header>
    <modal-body class="max-h-[calc(100vh-200px)]">
      <template v-if="!tableOnly">
        <form-group>
          <checkbox v-model="localSettings.legacyInterface"
            >Use legacy graph interface</checkbox
          >
        </form-group>
        <form-group>
          <form-label>Uncertainty</form-label>
          <checkbox v-model="localSettings.allowUncertainty"
            >Allow Uncertainty</checkbox
          >
        </form-group>
        <form-group>
          <form-label>Curve Fits</form-label>
          <div class="columns">
            <div class="column">
              <checkbox v-model="localSettings.curveFitTypes.proportional"
                >Proportional: y = Ax</checkbox
              >
              <checkbox v-model="localSettings.curveFitTypes.linear"
                >Linear: y = Ax + B</checkbox
              >
              <checkbox v-model="localSettings.curveFitTypes.squareLaw"
                >Square Law: y = Ax<sup>2</sup></checkbox
              >
              <checkbox v-model="localSettings.curveFitTypes.squareRoot"
                >Square Root: y = A&Sqrt;x</checkbox
              >
              <checkbox v-model="localSettings.curveFitTypes.quadratic"
                >Quadratic: y = Ax<sup>2</sup> + Bx + C</checkbox
              >
            </div>
            <div class="column">
              <checkbox v-model="localSettings.curveFitTypes.exponential"
                >Exponential: y = A e^(Bx)</checkbox
              >
              <checkbox v-model="localSettings.curveFitTypes.logarithmic"
                >Logarithmic: y = A + Bln(x)</checkbox
              >
              <checkbox v-model="localSettings.curveFitTypes.inverse"
                >Inverse: y = A / x</checkbox
              >
              <checkbox v-model="localSettings.curveFitTypes.inverseSquare"
                >Inverse Square: y = A / x<sup>2</sup></checkbox
              >
            </div>
          </div>
        </form-group>
        <form-group>
          <form-label>Types of Graphs</form-label>
          <div class="columns justify-between">
            <checkbox v-model="localSettings.graphTypes.scatterGraphs"
              >Scatter Graph</checkbox
            >
            <checkbox v-model="localSettings.graphTypes.lineGraphs"
              >Line Graph</checkbox
            >
            <checkbox v-model="localSettings.graphTypes.barGraphs"
              >Bar Graph</checkbox
            >
          </div>
        </form-group>
      </template>
      <form-group>
        <form-label>Table Options</form-label>
        <checkbox v-model="localSettings.hideTable">Hide Table</checkbox>
        <div class="columns justify-between">
          <checkbox v-model="localSettings.includeColumnStatistics"
            >Column Statistics</checkbox
          >
          <checkbox v-model="localSettings.includeFormulaBar"
            >Formula Bar</checkbox
          >
          <checkbox v-model="localSettings.includeBluetooth"
            >Bluetooth</checkbox
          >
          <checkbox v-model="localSettings.includeCsvDownload"
            >Download CSV</checkbox
          >
        </div>
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Confirm</modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import cloneDeep from 'lodash/cloneDeep'

import {
  DEFAULT_GRID_SETTINGS,
  DEFAULT_GRID_GRAPH_SETTINGS
} from 'src/shared/components/grid-graph/dtos'

const props = defineProps({
  settings: {
    type: Object,
    required: true
  },
  tableOnly: {
    type: Boolean,
    default: false
  }
})

const title = computed(() =>
  props.tableOnly ? 'Table Settings' : 'Table & Graph Settings'
)

const localSettings = ref(
  props.tableOnly ? DEFAULT_GRID_SETTINGS : DEFAULT_GRID_GRAPH_SETTINGS
)

const toggleAllOn = () => {
  const newSettings = {
    ...(props.tableOnly ? DEFAULT_GRID_SETTINGS : DEFAULT_GRID_GRAPH_SETTINGS)
  }
  Object.keys(newSettings).forEach(key => {
    if (typeof newSettings[key] === 'boolean') {
      newSettings[key] = true
    } else {
      Object.keys(newSettings[key]).forEach(
        subKey => (newSettings[key][subKey] = true)
      )
    }
  })
  localSettings.value = newSettings
}

const toggleAllOff = () => {
  const newSettings = {
    ...(props.tableOnly ? DEFAULT_GRID_SETTINGS : DEFAULT_GRID_GRAPH_SETTINGS)
  }
  Object.keys(newSettings).forEach(key => (newSettings[key] = false))
  localSettings.value = newSettings
}

function onSubmit(e) {
  e.done({ settings: localSettings.value })
}

onBeforeMount(async () => {
  localSettings.value = {
    ...localSettings.value,
    ...cloneDeep(props.settings)
  }
})
</script>
<style scoped lang="scss">
.settings-modal {
  max-width: 580px;
  width: 100%;
}
.columns {
  display: flex;
}
.column {
  width: 50%;
}

.justify-between {
  justify-content: space-between;
}

.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.space-x-6 {
  margin-left: 1.5rem;
}
</style>
