<template>
  <view-container normal>
    <flash />
    <loading-container :loading="isLoading">
      <help-banner :license-type="licenseType" />
      <div class="card">
        <h1 class="header">Configure a Plan</h1>
        <br />
        <h4 v-if="isStudentPaidLicense">
          <span class="callout">Are you a student?</span> You can join a class
          <router-link :to="{ name: 'join_class' }">here</router-link>.
        </h4>
        <h2 class="sub-header">Your Plan Type</h2>
        <div
          :class="[
            'flex',
            'plan-type',
            'flex-col',
            isTrialLicense ? 'trial-license' : ''
          ]"
        >
          <div class="space-between">
            <h2 class="sub-header license-type">{{ licenseTypeDisplay }}</h2>
          </div>
          <div v-if="isTrialLicense">
            Full access to Pivot for you and your students for 30 days.
          </div>
          <div v-else-if="isInstitutionLicense">
            Bulk seats are purchased by the institution.
          </div>
          <div v-else-if="isStudentPaidLicense">
            Instructors create courses that require students to pay when they
            enroll.
          </div>
        </div>
        <async-form class="form" @submit="onSubmit" @fail="onFail">
          <h2 class="">Education Level</h2>
          <div class="switch-field" v-if="!isStudentPaidLicense">
            <input
              checked
              id="switch-one"
              v-model="license.licenseLevel"
              type="radio"
              name="education-level"
              value="high-school"
              @change="computeCost"
            />
            <label id="switch-one" for="switch-one">Grades 6–12</label>
            <input
              id="switch-two"
              v-model="license.licenseLevel"
              type="radio"
              name="education-level"
              value="college"
              @change="computeCostAndResetDates"
            />
            <label id="switch-two" for="switch-two">College/University</label>
          </div>
          <div class="switch-field" v-else>
            <input
              id="switch-two"
              v-model="license.licenseLevel"
              type="radio"
              name="education-level"
              value="college"
              @change="computeCostAndResetDates"
              checked
            />
            <label id="switch-two" for="switch-two">College/University</label>
          </div>

          <div v-if="isInstitutionLicense">
            <h2 class="">Plan Size</h2>
            <p class="plain-text">
              Don't worry about being too exact. If you run out of seats on your
              license, you can add more later.
            </p>
            <h3>Projected Number of Seats</h3>
            <form-group class="input-40">
              <number-input
                id="seats"
                v-model="license.seats"
                :rules="{
                  required: true,
                  min_value: 10,
                  integer: true,
                  ...(isAdmin ? {} : { max_value: 500 })
                }"
                label="Projected Number of Seats"
                type="number"
                name="seats"
                min="0"
                step="1"
                :disabled="!canEdit"
                oninput="validity.valid||(value='')"
                aria-label="Projected Number of Seats"
                @change="computeCost"
              />
            </form-group>
            <table>
              <tr>
                <td>Cost Per Seat</td>
                <td>
                  <span v-if="isAdmin"
                    >$<number-input
                      v-model="license.pricePerSeat"
                      class="cost-per-seat-input"
                      aria-label="Cost Per Seat"
                      @change="overrideSeatPrice"
                    />
                  </span>
                  <span v-else>
                    {{ $format.currency(license.pricePerSeat, '--') }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>Total Cost</td>
                <td>
                  <span v-if="isAdmin"
                    >$<number-input
                      v-model="license.subtotal"
                      class="cost-per-seat-input"
                      aria-label="subtotal"
                      @change="overrideSubtotal"
                    />
                  </span>
                  <span v-else>
                    {{ $format.currency(license.subtotal, '--') }}</span
                  >
                </td>
              </tr>
            </table>
          </div>

          <div v-if="license.licenseType === 'student'">$10.00 Per Seat</div>

          <div v-if="isTrialLicense" class="trial-license-start">
            <h2>Trial License Start Date</h2>
            <h3>A Trial License will last for 30 days from the start date.</h3>
            <form-group class="input-30">
              <form-label for="end-date">End Date</form-label>
              <date-input
                id="end-date"
                v-model="license.endDate"
                disabled
                label="End Date"
              />
            </form-group>
          </div>
          <p class="advanced-features-text">
            For licenses over 500 seats and premium features such as LMS
            integration and rostering, standards alignment crosswalks, and
            customized professional development, please
            <hyper-link :to="{ name: 'de_sales_inquiry' }">
              connect with our sales team here</hyper-link
            >.
          </p>
          <div v-if="isInstitutionLicense">
            <h2>Student Access Dates</h2>
            <h3>
              An Institution License lasts for
              {{
                license.licenseLevel === 'high-school'
                  ? 'one year.'
                  : '1  term or semester'
              }}
            </h3>
            <p>Licenses start and end at midnight Greenwich Mean Time.</p>
            <div class="plain-text">
              {{
                license.licenseLevel === 'college'
                  ? 'Enter the first and last dates of class in a term. Instructors have access for 90 days before and after the term for preparation and grading.'
                  : ''
              }}
            </div>
            <div class="form__datetime-group">
              <form-group class="input-30">
                <div class="form__datewrapper">
                  <form-label for="start-date">Start Date</form-label>
                  <date-input
                    id="start-date"
                    v-model="startDate"
                    rules="required"
                    label="Start Date"
                    :disabled="!canEdit"
                    :clearable="false"
                    :min-date="isAdmin ? null : minStartDate"
                    @update:modelValue="handleStartDateChange"
                  />
                </div>
              </form-group>
              <div class="form__datewrapper">
                <div v-if="startDate">
                  {{ $format.date(startDate, 'p') }}
                </div>
              </div>
            </div>

            <div class="form__datetime-group">
              <form-group class="input-30">
                <div class="form__datewrapper">
                  <form-label for="end-date">End Date</form-label>
                  <date-input
                    id="end-date"
                    v-model="endDate"
                    :rules="
                      isAdmin
                        ? null
                        : {
                            required: true,
                            after: license.startDate,
                            before: maxEndDate
                          }
                    "
                    :disabled="!canSetEndDate"
                    :clearable="isAdmin ? false : true"
                    label="End Date"
                    :min-date="license.startDate"
                    :max-date="isAdmin ? null : maxEndDate"
                  />
                </div>
              </form-group>
              <div class="form__datewrapper">
                <div v-if="endDate">
                  {{ $format.date(endDate, 'p') }}
                </div>
              </div>
            </div>
          </div>

          <form-group class="button-group">
            <submit-button class="form-button--beta"> Continue </submit-button>
          </form-group>
        </async-form>
      </div>
    </loading-container>
  </view-container>
</template>

<script>
import HelpBanner from 'src/modules/purchases/components/HelpBanner'
import { getPurchaseData, mergePurchaseData } from 'src/shared/utils/purchases'
import { mapGetters, mapState } from 'vuex'
import { startOfToday, add, sub, endOfDay } from 'date-fns'
import Flash from 'src/shared/components//Flash'
import AlertModal from 'src/shared/components/modals/AlertModal'
import { linksList } from 'src/setup/router/externalLinkUtils'
import { getUTCMidnightOfDay } from 'src/shared/utils/dates'
import client from 'src/shared/api-client.ts'
import utils from '../pricing.ts'

export default {
  name: 'ConfigureLicense',
  components: { HelpBanner, Flash },
  inject: ['$modal'],
  props: ['licenseType', 'id'],
  data() {
    return {
      license: {},
      isLoading: true,
      quote: null
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState({
      siteLicense: state => state.auth.user?.siteLicense
    }),
    canEdit() {
      return this.isAdmin ? true : this.quote ? !this.quote.customPricing : true
    },
    minStartDate() {
      const today = startOfToday()
      return sub(today, { minutes: today.getTimezoneOffset() })
    },
    maxEndDate() {
      if (
        this.license.licenseLevel !== 'high-school' &&
        !!this.license.startDate
      ) {
        const maxDate = endOfDay(
          add(new Date(this.license.startDate), { days: 136 })
        )
        return sub(maxDate, { minutes: maxDate.getTimezoneOffset() })
      } else {
        return undefined
      }
    },
    canSetEndDate() {
      return (
        this.isAdmin ||
        (this.canEdit && this.license.licenseLevel === 'college')
      )
    },
    isStudentPaidLicense() {
      return this.license.licenseType === 'student'
    },
    isInstitutionLicense() {
      return this.license.licenseType === 'institution'
    },
    isTrialLicense() {
      return this.license.licenseType === 'trial'
    },
    licenseTypeDisplay() {
      const types = {
        student: 'Create Student-Pay Courses',
        institution: 'Institution License',
        trial: 'Trial License'
      }

      return types[this.license.licenseType]
    },
    otherRoleRule() {
      return this.license.role.includes('other') ? 'required' : ''
    },
    isUpdatingQuote() {
      return !!this.id
    },
    startDate: {
      get() {
        return this.license.startDate
      },
      set(date) {
        if (date) {
          date = getUTCMidnightOfDay(date)
          this.license.startDate = date
        } else {
          this.license.startDate = undefined
        }
      }
    },
    endDate: {
      get() {
        return this.license.endDate
      },
      set(date) {
        if (date) {
          date = endOfDay(date)
          date = sub(date, { minutes: date.getTimezoneOffset() })

          if (new Date().getTimezoneOffset() >= 0) {
            this.license.endDate = date
          } else {
            this.license.endDate = sub(date, { days: 1 })
          }
        } else {
          this.license.startDate = undefined
        }
      }
    }
  },
  methods: {
    async loadQuote() {
      let license = {
        licenseLevel: 'high-school',
        seats: 0,
        startDate: null,
        endDate: null,
        pricePerSeat: null,
        subtotal: null,
        // pricePerStudent: null,
        licenseType: this.licenseType
      }
      if (this.id) {
        const { quote } = await client.purchases.getQuote({ id: this.id })
        this.quote = quote || {}

        if (this.isAdmin) {
          this.license.customPricing = quote.customPricing
        }

        license.pricePerSeat = quote.pricePerSeat
        license.seats = quote.seats
        license.pricePerSeat = quote.pricePerSeat
        license.startDate = quote.startDate
        license.endDate = quote.endDate

        mergePurchaseData({
          purchaseType: 'license',
          configureLicenseForm: {
            ...license
          },
          educatorContactForm: {
            contactFirstName: quote.contactFirstName,
            contactLastName: quote.contactLastName,
            contactEmail: quote.contactEmail,
            contactRoles: quote.contactRoles,
            contactSubjects: quote.contactSubjects,
            institution: quote.institution,
            institutionCountry: quote.institutionCountry,
            institutionAddress: quote.institutionAddress,
            institutionAddress2: quote.institutionAddress2,
            institutionCity: quote.institutionCity,
            institutionState: quote.institutionState,
            institutionZipCode: quote.institutionZipCode,
            institutionWebsite: quote.institutionWebsite,
            salesforceId: quote.salesforceId
          },
          ...(this.id && { id: this.id })
        })
      } else {
        license = {
          ...license,
          ...getPurchaseData().configureLicenseForm
        }
      }

      license.startDate = license.startDate
        ? new Date(license.startDate)
        : license.startDate
      license.endDate = license.endDate
        ? new Date(license.endDate)
        : license.endDate
      this.license = license

      this.computeSubtotal()
    },
    computeSubtotal() {
      this.license.subtotal = this.license.pricePerSeat * this.license.seats
    },

    async computeCost() {
      if (!this.isAdmin && this.license.seats > 500) {
        this.license.pricePerSeat = null
        this.license.subtotal = null
        return
      }
      this.license.pricePerSeat = utils.determineLicensePriceCostPerSeat(
        this.license.licenseLevel
      )
      this.computeSubtotal()
      this.license.customPricing = false
    },
    async computeCostAndResetDates() {
      this.handleStartDateChange()
      await this.computeCost()
    },

    onSubmit({ done }) {
      if (this.license.licenseType === 'student') {
        this.license.licenseLevel = 'college'
      }
      mergePurchaseData({
        purchaseType: 'license',
        configureLicenseForm: this.license,
        ...(this.id && { id: this.id })
      })

      this.$router.push({ name: 'new_license_contact' })
      done()
    },
    async onFail({ values }) {
      if (values.seats >= 500) {
        await this.$modal.show(AlertModal, {
          title: 'Need more than 500 seats?',
          html: `<a href='${linksList.sales_inquiry}'>Contact us</a>
          for premium features and discounted pricing specifically for schools & districts.`,
          body: ''
        })
      } else {
        this.$error('Please correct validation errors before continuing.')
      }
    },
    checkForLicense() {
      if (this.siteLicense) {
        this.$router.push({ name: 'new_renewal_session' })
      }
    },
    handleStartDateChange(d) {
      // We have to delay updating the end date so that the start date form state has settled.
      setTimeout(() => {
        if (this.license.licenseLevel === 'high-school') {
          this.endDate = add(this.startDate, { years: 1 })
          return
        }
        if (this.isTrialLicense) {
          this.endDate = add(this.startDate, { days: 30 })
          return
        }
        this.license.endDate = undefined
      }, 50)
    },
    overrideSeatPrice() {
      this.license.customPricing = !!this.license.pricePerSeat
      this.computeSubtotal()
    },
    overrideSubtotal() {
      this.license.customPricing = true
      this.license.pricePerSeat = parseFloat(
        (this.license.subtotal / this.license.seats).toFixed(2)
      )
      this.computeSubtotal()
    }
  },
  async created() {
    this.checkForLicense()

    await this.loadQuote()

    this.isLoading = false

    if (this.license.licenseType === 'trial') {
      this.license.startDate = new Date()
      this.license.endDate = add(new Date(), { days: 30 })
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 60px 64px;
}
.container {
  width: 100%;
}
.overlay {
  // background-color: #d5d2d3;
  padding: 24px 0px;
  display: flex;
  justify-content: center;
}
.payment-view {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 48px 64px;
  width: 928px;
}
.license-type {
  margin-top: 0px;
}
.button-group {
  display: flex;
  flex-direction: row-reverse;
}
.plan-type {
  border: 2px solid #eeeded;
  border-radius: 6px;
  padding: 24px;
  flex-direction: column;
  display: flex;
  width: 462px;
}
.ref {
  color: #3d2c60;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}
#seats {
  text-align: right;
}
#startDate,
#endDate {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='141' height='146'%3E%3Cpath d='M13.3,126.4V37.4c0-2.4,.9-4.5,2.6-6.3c1.7-1.8,3.8-2.6%0A6.2-2.6h8.8v-6.7c0-3.1,1.1-5.7,3.2-7.9c2.2-2.2,4.7-3.3,7.8-3.3h4.4c3,0%0A5.6,1.1,7.8,3.3c2.2,2.2,3.2,4.8,3.2,7.9v6.7h26.4v-6.7c0-3.1,1.1-5.7%0A3.2-7.9c2.2-2.2,4.7-3.3,7.8-3.3h4.4c3,0,5.6,1.1,7.8,3.3c2.2,2.2,3.2%0A4.8,3.2,7.9v6.7h8.8c2.4,0,4.4,.9,6.2,2.6c1.7,1.8,2.6,3.8,2.6,6.3v88.9c0%0A2.4-.9,4.5-2.6,6.3c-1.7,1.8-3.8,2.6-6.2,2.6H22.1c-2.4,0-4.4-.9-6.2-2.6C14.2,130.8%0A13.3,128.8,13.3,126.4z M22.1,126.4h96.8V55.2H22.1V126.4z M39.7,41.9c0,.6,.2,1.2%0A.6,1.6c.4,.4,.9,.6,1.6,.6h4.4c.6,0,1.2-.2,1.6-.6c.4-.4%0A.6-.9,.6-1.6v-20c0-.6-.2-1.2-.6-1.6c-.4-.4-.9-.6-1.6-.6h-4.4c-.6,0-1.2,.2-1.6%0A.6c-.4,.4-.6,1-.6,1.6V41.9z%0AM92.5,41.9c0,.6,.2,1.2,.6,1.6c.4,.4,.9,.6%0A1.6,.6h4.4c.6,0,1.2-.2,1.6-.6c.4-.4%0A.6-.9,.6-1.6v-20c0-.6-.2-1.2-.6-1.6c-.4-.4-.9-.6-1.6-.6h-4.4c-.6%0A0-1.2,.2-1.6,.6c-.4,.4-.6,1-.6,1.6V41.9z'/%3E%3C/svg%3E");
  background-position: 5% 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 35px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header {
  font-size: 30px;
  line-height: 45px;
}
.sub-header {
  font-size: 20px;
  line-height: 30px;
}
.form-input {
  position: static;
  background-color: grey;
}
.input-75 {
  width: 75%;
}
.input-50 {
  width: 50%;
}
.input-40 {
  width: 38%;
}
.input-30 {
  width: 33%;
}
.input-25 {
  width: 25%;
}
.switch-field {
  display: flex;
  overflow: hidden;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field label {
  background-color: #ffffff;
  color: #3c393a;
  font-size: 20px;
  font-weight: normal;
  line-height: 150%;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid #d4d3d3;
  &:hover {
    cursor: pointer;
  }
}
#switch-one {
  border-radius: 3px 0px 0px 3px;
}
#switch-two {
  border-radius: 0px 3px 3px 0px;
}
.switch-field input:checked + label {
  background-color: #3d2c60;
  box-shadow: none;
  border: 1px solid #3d2c60;
  color: #ffffff;
}
.under13-checkbox {
  margin-bottom: 36px;
}
table {
  border: 1px solid #eeeded;
  border-radius: 0px 0px 3px 3px;
  width: 292px;
  color: #575153;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}
tr {
  border: 1px solid #eeeded;
}
td {
  padding: 12px 16px;
}
.trial-license {
  h2 {
    margin-bottom: 0;
  }
}
.plain-text {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.008em;
  text-align: left;
}
.callout {
  font-weight: 700;
}
.cost-per-seat-input {
  display: inline-block;
  width: 80px;
}
.advanced-features-text {
  margin-top: 8px;
}
.form__datetime-group {
  display: flex;
  align-items: baseline;
}
.form__datewrapper {
  display: inline-block;
}
</style>
x
