<template>
  <Resizable
    :as="NodeViewWrapper"
    class="inline-drop-target"
    :class="{
      'inline-drop-target--selected': selected,
      'inline-drop-target--full-width': isFullWidth,
      'inline-drop-target--preview': !!previewedChoice,
      'inline-drop-target--block': attrs.display === 'inline-block',
      'inline-drop-target--inline': attrs.display === 'inline',
      'inline-drop-target--freeform': attrs.display === 'freeform',
      'inline-drop-target--dropdown': attrs.targetType === 'dropdown'
    }"
    v-model:size="size"
    :focused="selected"
    :fullWidth="isFullWidth"
    :display="attrs.display === 'inline' ? 'inline' : 'block'"
    :units="attrs.display === 'freeform' ? 'percent' : 'pixel'"
    :preserveAspectRatio="false"
    contenteditable="false"
    :style="styles"
    @click="onClick"
  >
    <span
      v-if="attrs.display === 'freeform' && !previewedChoice"
      class="drag-handle"
      @pointerdown.stop.prevent="onPointerDown"
    >
      <Icon icon="grip-vertical" />
    </span>
    <div v-if="previewedChoice" class="inline-drop-target__preview">
      <DragDropRichTextContent
        :text="previewedChoice.text"
        :variableContext="{ variables: [] }"
      />
    </div>
    <template v-else>
      <template v-if="attrs.label">{{ attrs.label }}</template>
      <template v-else> {{ areaLetter }}</template>
    </template>
  </Resizable>
</template>

<script setup>
import { computed, ref } from 'vue'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import { indexToLetter } from 'src/shared/utils/index-to-letter.js'
import Resizable from '../components/Resizable.vue'
import DragDropRichTextContent from 'src/modules/activities/components/DragDropRichTextContent.vue'

const props = defineProps(nodeViewProps)
const isMoving = ref(false)

const attrs = computed(() => ({
  ...props.node.attrs,
  display: props.node.attrs.display ?? 'inline'
}))
const areaLetter = computed(() =>
  indexToLetter(
    props.extension.storage.areas.findIndex(o => o === attrs.value.id)
  )
)
const isFullWidth = computed(
  () => attrs.value.width === '100%' && attrs.value.display !== 'freeform'
)

const previewedChoice = computed(() => {
  const choiceId = props.extension.storage.targetPreviews?.[attrs.value.id]
  return props.extension.storage.choices?.find(c => c.id === choiceId)
})

const onClick = e => {
  if (isMoving.value) return
  e.preventDefault()
  e.stopPropagation()
  props.editor.chain().setNodeSelection(props.getPos()).run()
}

const size = computed({
  get() {
    return {
      width: parseFloat(attrs.value.width),
      height: parseFloat(attrs.value.height)
    }
  },
  set(size) {
    props.updateAttributes(size)
  }
})

const styles = computed(() => {
  switch (attrs.value.display) {
    case 'freeform': {
      return {
        top: `${attrs.value.positionTop ?? 50}%`,
        right: `${attrs.value.positionRight ?? 50}%`
      }
    }
    case 'inline-block': {
      return {}
    }
    case 'inline':
    default: {
      return {
        display:
          previewedChoice.value && !isFullWidth.value
            ? 'inline'
            : 'inline-block'
      }
    }
  }
})

function onPointerDown(e) {
  if (attrs.value.display !== 'freeform') return
  const context = e.currentTarget.closest('.target-context')
  const wrapperBox = context.getBoundingClientRect()
  e.stopPropagation()
  const handle = e.currentTarget
  const wrapper = handle.parentNode
  handle.setPointerCapture(e.pointerId)
  const startHeight = parseInt(window.getComputedStyle(wrapper).height)
  const startWidth = parseInt(window.getComputedStyle(wrapper).width)
  const targetPos = {
    top: attrs.value.positionTop ? parseInt(attrs.value.positionTop) : 50,
    right: attrs.value.positionRight ? parseInt(attrs.value.positionRight) : 50
  }

  const draggingState = {
    startTop:
      targetPos.top !== 0
        ? wrapperBox.height * (targetPos.top / 100)
        : wrapperBox.height / 2,
    startRight:
      targetPos.right !== 0
        ? wrapperBox.width * (targetPos.right / 100)
        : wrapperBox.width / 2,
    x: e.clientX + startWidth,
    y: e.clientY + startHeight,
    height: startHeight,
    width: startWidth
  }
  const bounds = {
    top: startHeight / 2,
    bottom: wrapperBox.height - startHeight / 2,
    left: wrapperBox.width - startWidth / 2,
    right: startWidth / 2
  }

  const onDrag = e => {
    e.stopPropagation()
    e.preventDefault()
    isMoving.value = true
    let newTop =
      draggingState.startTop +
      e.clientY +
      (draggingState.height - draggingState.y)
    let newRight =
      draggingState.startRight +
      draggingState.x -
      (e.clientX + draggingState.width)
    if (newTop < bounds.top) {
      newTop = bounds.top
    }
    if (newTop > bounds.bottom) {
      newTop = bounds.bottom
    }
    if (newRight < bounds.right) {
      newRight = bounds.right
    }
    if (newRight > bounds.left) {
      newRight = bounds.left
    }
    const xPerc = (newRight / wrapperBox.width) * 100
    const yPerc = (newTop / wrapperBox.height) * 100

    props.updateAttributes({
      positionTop: yPerc > 0 ? (yPerc < 100 ? yPerc : 100) : 0,
      positionRight: xPerc > 0 ? (xPerc < 100 ? xPerc : 100) : 0
    })
  }
  const onDragEnd = e => {
    e.preventDefault()
    e.stopPropagation()
    handle.removeEventListener('pointermove', onDrag)
    handle.removeEventListener('pointerup', onDragEnd)
    handle.removeEventListener('pointercancel', onDragEnd)
    setTimeout(() => {
      isMoving.value = false
    }, 1)
  }
  handle.addEventListener('pointermove', onDrag)
  handle.addEventListener('pointerup', onDragEnd)
  handle.addEventListener('pointercancel', onDragEnd)
}
</script>

<style lang="scss" scoped>
.inline-drop-target {
  border-radius: 4px;
  margin: 4px 0 4px 0;
  position: relative;
  font-style: italic;
  font-weight: 700;
  border: 1px dashed #3d2c60;
  outline: 1px solid transparent;
  border-radius: 4px;
  padding: 1px 6px 1px 4px;
  display: inline-block;
}

.inline-drop-target--dropdown {
  border: 1px solid;
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:after {
    content: url('data:image/svg+xml; utf8, <svg class="svg-inline--fa fa-caret-down library-filter-dropdown__icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path class="" fill="currentColor" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"></path></svg>');
    position: absolute;
    right: 5px;
    top: 0;
    width: 15px;
    height: 100%;
    line-height: 1.6;
  }
}
.inline-drop-target--selected {
  background-color: #7651a61a;
}

.inline-drop-target--preview {
  font-style: unset;
  font-weight: unset;
}

.inline-drop-target__preview {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.inline-drop-target--inline:not(.inline-drop-target--full-width) {
  &.inline-drop-target--preview,
  .inline-drop-target__preview {
    display: inline;
  }
}

.inline-drop-target--full-width {
  width: calc(100% - 1px);
}

.inline-drop-target--block {
  display: inline-block;
  vertical-align: top;
}
.inline-drop-target--freeform {
  min-width: 24px;
  min-height: 24px;
  display: block;
  transform: translate(50%, -50%);
  position: absolute;
  z-index: 10;
  background-color: #fff;

  .drag-handle {
    position: relative;
    cursor: move;
  }
}

.dropdown {
  width: 100%;
  position: relative;
  pointer-events: none;
  height: 100%;

  .truncate {
    width: 85%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.drop-arrow {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  pointer-events: none;
}
</style>
