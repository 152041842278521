<template>
  <li>
    <button-link class="nav-button" :disabled="disabled || null" :to="to">
      <slot />
    </button-link>
    <router-link
      class="nav-link"
      :class="{ disabled: disabled }"
      :to="disabled ? '' : to"
    >
      <slot />
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'NavButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: null,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-button {
  background-color: $teal;
  border: $teal solid 1px;
  color: #ffffff;
  border-radius: 6px;
  font-weight: 700;
  margin: 5px 8px 14px 8px;
  height: 33px;
  padding: 5px 16px;

  &:hover,
  &:focus {
    background-color: $darker-teal;
    border: $darker-teal solid 1px;
  }

  &:focus {
    box-shadow: $focus-shadow;
  }

  &:disabled {
    background-color: grey;
  }
}

.nav-link {
  font-size: ceil($font-size-base * 1.14);
  line-height: 33px;
  color: $plum;
  padding-top: 5px;
  display: none;
  position: relative;

  &:hover,
  &:focus {
    // Have to use important because of bootstrap.
    color: inherit !important;
    background-color: inherit !important;
  }
  &.disabled {
    color: grey !important;
  }
}

@media (max-width: $grid-float-breakpoint) {
  .nav-button {
    display: none;
  }

  .nav-link {
    display: block;
  }
}
</style>
