<template>
  <div class="loading-container" :class="{ overlay, loading }">
    <div v-if="loading" role="alert" aria-busy="true" aria-label="loading">
      <loading-spinner />
    </div>
    <div v-if="overlay || !loading">
      <slot />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from 'src/shared/components/LoadingSpinner'

export default {
  name: 'LoadingContainer',
  components: { LoadingSpinner },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    overlay: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.overlay {
  position: relative;
}
.overlay.loading > div:first-child {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: white;
}
.loading > div:first-child .loading-spinner {
  position: static;
  margin: auto;
}

.loading-container {
  width: 100%;
}
</style>
