<template>
  <template v-if="unauthenticated">
    <activity-question
      :variable-context="variableContext"
      :view-as-student="viewAsStudent"
    >
      <activity-response-open-ended
        v-if="component.componentType === 'OpenEndedQuestion'"
        :variable-context="variableContext"
        noupload
      />
      <activity-response-numerical
        v-if="component.componentType === 'NumericalQuestion'"
        :variable-context="variableContext"
        :canSubmit="canSubmit"
        @submit="emit('submit')"
        @canSubmit="emit('canSubmitChange')"
      />
      <activity-response-choice
        v-if="component.componentType === 'MultipleChoiceQuestion'"
        :variable-context="variableContext"
        :view-as-student="viewAsStudent"
      />
      <activity-response-grid-graph
        v-if="
          component.componentType === 'GridGraphQuestion' ||
          component.componentType === 'GridQuestion'
        "
        :variable-context="variableContext"
        :show-grid-actions-menu="false"
        :view-as-student="viewAsStudent"
      />
      <activity-response-drag-drop
        v-if="component.componentType === 'DragDropQuestion'"
        :variable-context="variableContext"
        :view-as-student="viewAsStudent"
        @canSubmit="emit('canSubmitChange')"
      />
      <activity-question-autograde
        :unauthenticated="unauthenticated"
        @submit="emit('submit')"
        :canSubmit="canSubmit"
      />
      <activity-question-feedback readonly>
        <template #score>
          <activity-question-score readonly />
        </template>
        <template #comments>
          <activity-question-comments readonly :grading="!viewAsStudent" />
        </template>
      </activity-question-feedback>
    </activity-question>

    <unauthenticated-placeholder
      v-if="restrictedComponentForUnauthenticated(component.componentType)"
      :component-type="component.componentType"
    />
    <template v-else>
      <activity-frame :variable-context="variableContext" />
      <activity-legacy-phet-io-simulation :variable-context="variableContext" />
      <activity-phet-io-simulation :variable-context="variableContext" />
      <activity-instance
        :limit-functionality="readonly"
        :variable-context="variableContext"
      />
      <activity-instructor-instance :limit-functionality="readonly" />
      <activity-student-instance :limit-functionality="readonly" no-upload />
    </template>
    <activity-general-note
      :variable-context="variableContext"
      :view-as-student="viewAsStudent"
    />
  </template>
  <template v-else>
    <activity-question
      :variable-context="variableContext"
      :view-as-student="viewAsStudent"
    >
      <activity-response-open-ended
        v-if="component.componentType === 'OpenEndedQuestion'"
        :variable-context="variableContext"
        :noupload="preview"
        :show-correct-answer="answersShown && !preview"
        :grading="grading"
        @canSubmit="onCanSubmitChange"
      />
      <activity-response-numerical
        v-if="component.componentType === 'NumericalQuestion'"
        :variable-context="variableContext"
        :show-correct-answer="answersShown && !viewAsStudent"
        :canSubmit="canSubmit"
        @canSubmit="onCanSubmitChange"
        @submit="emit('submit')"
        :grading="grading"
      />
      <activity-response-choice
        v-if="component.componentType === 'MultipleChoiceQuestion'"
        :variable-context="variableContext"
        :show-correct-answer="answersShown && !preview"
        :view-as-student="viewAsStudent"
        @canSubmit="onCanSubmitChange"
        :grading="grading"
      />
      <activity-response-drag-drop
        v-if="component.componentType === 'DragDropQuestion'"
        :variable-context="variableContext"
        :view-as-student="viewAsStudent"
        :show-correct-answer="answersShown && !preview"
        :grading="grading"
        @canSubmit="onCanSubmitChange"
      />
      <activity-response-grid-graph
        v-if="
          component.componentType === 'GridGraphQuestion' ||
          component.componentType === 'GridQuestion'
        "
        :variable-context="variableContext"
        :allow-reset="!preview && viewAsStudent"
        :allow-resize="!preview && viewAsStudent"
        :show-correct-answer="answersShown && !preview"
        :grading="grading"
        :view-as-student="viewAsStudent"
        @canSubmit="onCanSubmitChange"
      />
      <activity-question-autograde
        :readonly="grading"
        @submit="emit('submit')"
        :canSubmit="canSubmit"
        :grading="grading"
        :unauthenticated="unauthenticated"
      />
      <activity-question-feedback :readonly="!grading">
        <template #score>
          <activity-question-score :readonly="!grading" />
        </template>
        <template #comments>
          <activity-question-comments
            :readonly="!grading"
            :grading="!viewAsStudent"
          />
        </template>
      </activity-question-feedback>
    </activity-question>
    <activity-instance
      :variable-context="variableContext"
      :is-student="viewAsStudent" />
    <activity-instructor-instance />
    <activity-student-instance :no-upload="preview" />
    <activity-frame :variable-context="variableContext" />
    <activity-legacy-phet-io-simulation :variable-context="variableContext" />
    <activity-phet-io-simulation :variable-context="variableContext" />
    <activity-general-note
      :variable-context="variableContext"
      :view-as-student="viewAsStudent" />
    <activity-notes
      :show="answersShown && !viewAsStudent"
      :variable-context="variableContext"
  /></template>
</template>

<script setup lang="ts">
import ActivityQuestionFeedback from 'src/modules/activities/components/ActivityQuestionFeedback.vue'
import ActivityFrame from 'src/modules/activities/components/ActivityFrame.vue'
import ActivityLegacyPhetIoSimulation from 'src/modules/activities/components/ActivityLegacyPhetIoSimulation.vue'
import ActivityPhetIoSimulation from 'src/modules/activities/components/ActivityPhetIoSimulation.vue'
import ActivityGeneralNote from 'src/modules/activities/components/ActivityGeneralNote.vue'
import ActivityQuestion from 'src/modules/activities/components/ActivityQuestion.vue'
import ActivityQuestionComments from 'src/modules/activities/components/ActivityQuestionComments.vue'
import ActivityQuestionScore from 'src/modules/activities/components/ActivityQuestionScore.vue'
import ActivityQuestionAutograde from 'src/modules/activities/components/ActivityQuestionAutograde.vue'
import ActivityResponseOpenEnded from 'src/modules/activities/components/ActivityResponseOpenEnded.vue'
import ActivityResponseNumerical from 'src/modules/activities/components/ActivityResponseNumerical.vue'
import ActivityResponseChoice from 'src/modules/activities/components/ActivityResponseChoice.vue'
import ActivityResponseDragDrop from 'src/modules/activities/components/ActivityResponseDragDrop.vue'
import ActivityResponseGridGraph from 'src/modules/activities/components/ActivityResponseGridGraph.vue'
import ActivityInstance from 'src/modules/activities/components/ActivityInstance.vue'
import ActivityInstructorInstance from 'src/modules/activities/components/ActivityInstructorInstance.vue'
import ActivityStudentInstance from 'src/modules/activities/components/ActivityStudentInstance.vue'
import ActivityNotes from 'src/modules/activities/components/ActivityNotes.vue'
import UnauthenticatedPlaceholder from 'src/shared/components/UnauthenticatedPlaceholder.vue'

type ActivitySectionComponentRenderProps = {
  variableContext: any
  viewAsStudent: boolean
  answersShown: boolean
  canSubmit: boolean
  component: any
  preview?: boolean
  grading?: boolean
  unauthenticated?: boolean
  readonly?: boolean
}
const props = defineProps<ActivitySectionComponentRenderProps>()
const emit = defineEmits(['submit', 'canSubmitChange', 'complete'])
const restrictedComponentForUnauthenticated = (componentType: string) => {
  const restrictedComponents = [
    'IFrame',
    'EmbeddedInstance',
    'PhetIOSim',
    'PhetIOSimulation',
    'StudentInstance',
    'InstructorInstance'
  ]

  if (restrictedComponents.includes(componentType) && props.readonly) {
    return true
  }
  return false
}

function onCanSubmitChange(value: boolean): void {
  emit('canSubmitChange', value)
}
</script>
