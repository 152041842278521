<template>
  <div v-bind="wrapperAttrs">
    <label
      tag="label"
      class="radio-button response-style"
      :data-testid="dataTestid"
    >
      <input
        v-bind="inputAttrs"
        v-model="model"
        :id="inputId"
        type="radio"
        :value="value"
        :disabled="disabled || null"
        :name="computedName"
        :aria-describedby="`${helpId} ${ariaDescribedby}`"
      />
      <span v-if="!noIndicator" class="radio-indicator hidden-print" />
      <div class="radio-value">
        <slot />
      </div>
    </label>
    <div v-if="helpText" :id="helpId" class="radio-button__help-text">
      {{ helpText }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Radio',
  emits: ['update:modelValue'],
  inject: {
    choiceInput: { default: null }
  },
  props: {
    value: {},
    modelValue: {},
    disabled: {
      type: Boolean,
      default: false
    },
    autosave: {
      type: Boolean,
      default: false
    },
    componentId: {
      type: String
    },
    noIndicator: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    ariaDescribedby: {
      type: String,
      default: ''
    },
    dataTestid: {
      type: String,
      default: null
    },
    helpText: {
      type: String,
      default: null
    },
    // Deprecated in favor of choice-input
    name: {
      type: String,
      default: undefined
    }
  },
  computed: {
    computedName() {
      return this.choiceInput ? this.choiceInput.name : this.name
    },
    inputId() {
      return this.computedName
        ? `${this.computedName}-option-${this.value}`
        : undefined
    },
    helpId() {
      return `${this.computedName}-${this.value}-help`
    },
    model: {
      get() {
        return this.choiceInput ? this.choiceInput.value : this.modelValue
      },
      set(value) {
        if (this.choiceInput) {
          this.choiceInput.onChange(value)
        } else {
          this.$emit('update:modelValue', value)
        }
        if (this.autosave)
          this.autosaveStudentResponse({ componentId: this.componentId })
      }
    },
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    inputAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    ...mapActions(['autosaveStudentResponse'])
  }
}
</script>

<style lang="scss" scoped>
.radio-button {
  display: flex;
  align-items: flex-start;
  margin: 8px 0;
}

.inline {
  display: inline-flex;
}

input {
  opacity: -1;
  position: absolute;

  &:checked + .radio-indicator {
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      border-radius: 9999px;
      background-color: $plum;
    }
  }

  &:focus + .radio-indicator {
    box-shadow: 0 0 5px $silver;
  }
}

.radio-indicator {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #ffffff;
  border: $input-border solid 1px;
  display: inline-block;
  flex-shrink: 0;
}

.radio-value {
  font-weight: 400;
  flex-grow: 1;
  min-width: 0px;

  .radio-indicator + & {
    margin: 0 0 0 8px;
  }
}

/* styles for print preview page */
@media print {
  input[type='radio'] {
    opacity: initial;
    position: initial;
    margin: 0;
  }
}

.radio-button__help-text {
  margin: -4px 0 0 32px;
}
</style>
