<template>
  <div v-if="editor.options.editable" class="editor-toolbar">
    <div class="editor-toolbar__section">
      <editor-menu-button
        label="Bold"
        icon="bold"
        :active="editor.isActive('bold')"
        @click="editor.chain().focus().toggleBold().run()"
      />
      <editor-menu-button
        label="Italic"
        icon="italic"
        :active="editor.isActive('italic')"
        @click="editor.chain().focus().toggleItalic().run()"
      />
      <editor-menu-button
        label="Underline"
        icon="underline"
        :active="editor.isActive('underline')"
        @click="editor.chain().focus().toggleUnderline().run()"
      />
      <editor-menu-button
        label="Subscript"
        icon="subscript"
        :active="editor.isActive('subscript')"
        @click="editor.chain().focus().toggleSubscript().run()"
      />
      <editor-menu-button
        label="Superscript"
        icon="superscript"
        :active="editor.isActive('superscript')"
        @click="editor.chain().focus().toggleSuperscript().run()"
      />
      <editor-menu-dropdown
        label="Table Alignment Options"
        icon="bars"
        v-if="!inline"
        :disabled="!canUseTextAlign"
        :editor="editor"
      >
        <editor-menu-button
          label="Align Left"
          icon="align-left"
          :active="editor.isActive({ textAlign: 'left' })"
          @click="editor.chain().focus().setTextAlign('left').run()"
        />
        <editor-menu-button
          label="Align Center"
          icon="align-center"
          :active="editor.isActive({ textAlign: 'center' })"
          @click="editor.chain().focus().setTextAlign('center').run()"
        />
        <editor-menu-button
          label="Align Right"
          icon="align-right"
          :active="editor.isActive({ textAlign: 'right' })"
          @click="editor.chain().focus().setTextAlign('right').run()"
        />
      </editor-menu-dropdown>
    </div>

    <div class="editor-toolbar__section" v-if="!inline">
      <editor-menu-button
        label="Numbered List"
        icon="list-ol"
        :active="editor.isActive('orderedList')"
        @click="editor.chain().focus().toggleOrderedList().run()"
      />
      <editor-menu-button
        label="Bullet List"
        icon="list-ul"
        :active="editor.isActive('bulletList')"
        @click="editor.chain().focus().toggleBulletList().run()"
      />
      <editor-menu-button
        label="Indent"
        icon="indent"
        :disabled="!editor.can().sinkListItem('listItem')"
        @click="editor.chain().focus().sinkListItem('listItem').run()"
      />
      <editor-menu-button
        label="Outdent"
        icon="outdent"
        :disabled="!editor.can().liftListItem('listItem')"
        @click="editor.chain().focus().liftListItem('listItem').run()"
      />
    </div>
    <div class="editor-toolbar__section">
      <editor-menu-button
        v-if="!inline"
        :label="editor.isActive('link') ? 'Unlink' : 'Link'"
        :icon="editor.isActive('link') ? 'unlink' : 'link'"
        :disabled="
          !editor.isActive('link') && editor.view.state.selection.empty
        "
        @click="
          editor.isActive('link')
            ? editor.chain().focus().unsetLink().run()
            : editor.chain().openLinkModal().run()
        "
      />
      <editor-menu-button
        label="Formula"
        icon="square-root-variable"
        :active="editor.isActive('latex')"
        @click="editor.chain().openLatexModal().run()"
      />
      <editor-menu-button
        label="Image"
        icon="image"
        :active="editor.isActive('image')"
        @click="editor.chain().openImageModal().run()"
      />
      <editor-menu-button
        v-if="!noupload && !inline"
        label="File Upload"
        icon="file-pdf"
        @click="editor.chain().openFileModal().run()"
      />
      <editor-menu-button
        label="Variable"
        icon="dollar"
        v-if="allowVariables"
        :active="editor.isActive('variable')"
        @click="editor.chain().openVariableModal().run()"
      />
      <editor-menu-table-dropdown-button
        v-if="!inline"
        label="Insert Table"
        icon="table"
        :active="editor.isActive('table')"
        :editor="editor"
      />
      <editor-menu-button
        label="Drop Target Container"
        icon="vector-square"
        v-if="allowDropTargets"
        :editor="editor"
        :active="
          editor.isActive('dropTargetContext') && !editor.isActive('dropTarget')
        "
        @click="editor.chain().addDropTargetContext().run()"
      />

      <editor-menu-button
        label="Drop Target"
        icon="bullseye"
        v-if="allowDropTargets"
        :active="editor.isActive('dropTarget')"
        @click="editor.chain().openDropTargetModal().run()"
      />

      <editor-menu-button
        v-if="allowVocab"
        label="Vocab Tooltip"
        icon="circle-info"
        :active="editor.isActive('vocabTooltip')"
        :disabled="
          !editor.isActive('vocabTooltip') && editor.view.state.selection.empty
        "
        @click="editor.chain().openVocabTooltipModal().run()"
      />
    </div>
  </div>
</template>

<script>
import EditorMenuButton from '../components/EditorMenuButton.vue'
import EditorMenuTableDropdownButton from '../table/EditorMenuTableDropdownButton.vue'
import EditorMenuDropdown from '../components/EditorMenuDropdown.vue'

export default {
  name: 'EditorToolbar',
  components: {
    EditorMenuButton,
    EditorMenuTableDropdownButton,
    EditorMenuDropdown
  },
  props: {
    editor: {
      type: Object,
      required: true
    },
    noupload: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    disableVocab: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allowVariables() {
      return typeof this.editor.commands.openVariableModal !== 'undefined'
    },
    allowDropTargets() {
      return typeof this.editor.commands.setDropTarget !== 'undefined'
    },
    canUseTextAlign() {
      return !this.inline && this.editor.can().deleteTable()
    },
    allowVocab() {
      return !this.disableVocab
    
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-toolbar {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  flex-shrink: 0;

  &__section {
    flex-shrink: 1;
    margin: 0 16px 0 0;
  }

  .editor-menu-dropdown__action {
    width: 100%;
    padding: 5px;
    cursor: pointer;
    flex-direction: column;
  }
}
</style>
