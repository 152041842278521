<template>
  <editor-modal
    class="drop-target-menu__modal"
    v-if="isModalShown"
    name="drop area"
    title="Drop Target Area"
  >
    <async-form class="image-menu__form" @submit="updateImage">
      <div class="image-menu__form-columns">
        <div class="image-menu__form-column">
          <div>
            <form-label for="editor-file"
              >Drop Area Background Image</form-label
            >
            <input
              type="file"
              id="editor-file"
              :accept="formatsAccepted"
              @change="onFileChange"
              data-focus
            />
          </div>
          <div v-if="isImage">
            <form-label for="editor-alt">Alt</form-label>
            <text-input id="editor-alt" v-model="image.alt" />
          </div>
        </div>
        <div class="image-menu__form-column">
          <form-label id="editor-formula-preview-label">Preview</form-label>
          <template v-if="previewUrl !== undefined">
            <img
              class="image-menu__preview"
              aria-labelledby="editor-formula-preview-label"
              :src="previewUrl"
              :alt="image.alt"
              ref="previewImage"
              @load="updateImageDimenions"
            />
          </template>
        </div>
      </div>
      <div v-if="previewError" class="image-menu__error">
        {{ previewError }}
      </div>
      <div class="editor-modal__actions">
        <form-button :disabled="!image.src" type="submit">Update</form-button>
        <form-button tertiary @click="cancel">Cancel</form-button>
      </div>
    </async-form>
  </editor-modal>
</template>

<script setup>
import EditorModal from '../components/EditorModal'
import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
import client from 'src/shared/api-client'
import { validateFile } from 'src/shared/components/editor/utils.js'
const props = defineProps({
  editor: {
    type: Object,
    required: true
  },
  noupload: {
    type: Boolean,
    default: false
  }
})

const isModalShown = ref(false)
const image = ref({})
const previewImage = ref()

const updateImageDimenions = () => {
  if (previewImage.value) {
    //adding a delay for the image to load before we try and get the width/height
    image.value.width = previewImage.value.naturalWidth
    image.value.height = previewImage.value.naturalHeight
  }
}

const onSelectionUpdate = () => {
  const attrs = props.editor.getAttributes('image')
  image.value = { ...attrs }
}

onUnmounted(() => {
  props.editor.off('selectionUpdate', onSelectionUpdate)
})

const openModal = () => {
  const attributes = props.editor.getAttributes('dropTargetContext')
  if (attributes && attributes.src) {
    image.value = {
      ...attributes
    }
  }
  isModalShown.value = true
}
const formatsAccepted =
  'image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/svg+xml'

const updateImage = e => {
  const attributes = props.editor.getAttributes('dropTargetContext')
  const newAttributes = {
    ...attributes,
    ...image.value
  }
  props.editor.commands.updateAttributes('dropTargetContext', newAttributes)
  isModalShown.value = false
  e.done()
}
const cancel = e => {
  isModalShown.value = false
  props.editor.commands.focus()
}
const previewError = ref(null)

onMounted(() => {
  props.editor.on('selectionUpdate', onSelectionUpdate)
  if (props.editor.storage['dropTargetContext']) {
    // eslint-disable-next-line vue/no-mutating-props
    props.editor.storage['dropTargetContext'].onEditDropTargetContext =
      openModal
  }
})
const onFileChange = async e => {
  try {
    let file = e.target.files[0]

    if (file && file.name.match(/\.(mov|mp4)$/i)) {
      try {
        await validateFile(e, file)
      } catch (error) {
        file = null
        e.target.value = null
        previewError.value = error.message
        return
      }
    }

    if (props.noupload) {
      image.value.src = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          resolve(reader.result)
        })
        reader.addEventListener('error', () => {
          reject(new Error(`Failed to read file: ${file.name}.`))
        })
        reader.addEventListener('abort', () => {
          reject(new Error(`Aborted reading file: ${file.name}.`))
        })
        reader.readAsDataURL(file)
      })
    } else {
      const ext = file.name.split('.').pop()
      const name = `${new Date().getTime()}.${ext}`
      image.value.src = await client.assets.upload({ file, name })
    }
  } catch (error) {
    previewError.value = error.message
  }
}

const previewUrl = computed(() =>
  image.value.src && !image.value.src.startsWith('data:')
    ? `//${PI_API_HOST}${image.value.src}`
    : image.value.src
)

const isImage = computed(() => {
  if (previewUrl.value) {
    return previewUrl.value.match(/\.(jpg|jpeg|png|gif|ico|svg|bmp)$/i)
  } else {
    return false
  }
})
</script>

<style lang="scss" scoped>
.drop-target-menu {
  &__form {
    width: 500px;
    margin: 0 !important;
  }

  &__form-columns {
    display: flex;
    flex-wrap: wrap;
  }

  &__form-column {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  :deep() &__formula-input {
    width: 300px;
  }

  &__preview {
    max-width: 100%;
    max-height: 100px;
  }

  &__error {
    margin: 16px 0 0 0;
    color: $color-error;
  }

  &__modal-title {
    padding: 0;
    margin: 0 0 15px 0px;
  }

  &__modal-feedback {
    padding-top: 15px;
  }

  &__width {
    display: flex;
    padding-top: 35px;
    align-items: center;

    & > div {
      margin-left: 15px;
    }

    & > label {
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  &__width-label {
    &.disabled {
      :deep() .form-control {
        color: $light-grey;
      }
    }
  }
}
</style>
