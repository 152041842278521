<template>
  <div
    v-bind="$attrs"
    id="scroll-container"
    ref="app"
    class="modal-provider"
    :class="{ 'modal-provider--disabled': !!modalComponent }"
    :aria-hidden="modalComponent ? true : null"
  >
    <slot />
  </div>
  <transition name="modal-provider__overlay--t">
    <div v-if="modalComponent" class="modal-provider__overlay">
      <component v-bind="modalProps" ref="modal" :is="modalComponent" />
    </div>
  </transition>
</template>

<script>
import { markRaw } from 'vue'
import handler from 'src/shared/components/modals/handler'

export default {
  name: 'ModalProvider',
  provide() {
    return {
      $modal: {
        show: this.show,
        close: this.close
      },
      $_modal: {
        close: this.close,
        ok: this.ok,
        status: this.status
      }
    }
  },
  data() {
    return {
      modalComponent: null,
      resolve: null,
      modalProps: {}
    }
  },
  methods: {
    async show(component, props = {}) {
      const prevEl = document.activeElement
      const result = await new Promise(resolve => {
        this.resolve = resolve
        this.modalProps = props
        this.modalComponent = markRaw(component)
      })
      try {
        if (prevEl && prevEl.focus) prevEl.focus()
      } catch {}
      return result
    },
    close() {
      this.modalComponent = null
      this.modalProps = {}
      this.resolve && this.resolve({ status: 'cancel' })
      this.resolve = null
    },
    ok(data) {
      this.modalComponent = null
      this.modalProps = {}
      this.resolve && this.resolve({ status: 'ok', data })
      this.resolve = null
    },
    status(status, data) {
      this.modalComponent = null
      this.modalProps = {}
      this.resolve && this.resolve({ status, data })
      this.resolve = null
    }
  },
  created() {
    handler.register(this)
  }
}
</script>

<style lang="scss">
.modal-provider {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.modal-provider--disabled {
  overflow: hidden;
}

.modal-provider__overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  &--t-enter {
    background-color: rgba(0, 0, 0, 0);

    .modal {
      opacity: 0;
      transform: translateY(50px);
    }
  }

  &--t-leave-to {
    background-color: rgba(0, 0, 0, 0);

    .modal {
      opacity: 0;
      transform: translateY(-50px);
    }
  }

  &--t-leave-active,
  &--t-enter-active {
    transition: background-color 400ms;

    .modal {
      transition: transform 450ms, opacity 400ms;
    }
  }

  &--t-leave,
  &--t-enter-to {
    background-color: rgba(0, 0, 0, 0.5);

    .modal {
      opacity: 1;
    }
  }
}
</style>
