<template>
  <modal>
    <modal-header>Disable User</modal-header>
    <modal-body>
      <p class="prompt">
        <strong>Please choose a reason for disabling.</strong>
      </p>
      <selector-input
        v-model="reason"
        aria-labelledby="Reason for disabling"
        label="Reason"
        class="selector-input--horizontal"
        required
      >
        <selector-option value="abuse" title="Abuse" />
        <selector-option value="invalid" title="Invalid" />
        <selector-option value="vetting-rejected" title="Vetting Rejected" />
      </selector-input>
    </modal-body>
    <modal-actions>
      <modal-button-status :disabled="!reason" :status="reason"
        >Disable</modal-button-status
      >
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonStatus,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

export default {
  name: 'DisableReasonModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonStatus,
    ModalButtonCancel
  },
  data: () => ({
    reason: ''
  })
}
</script>

<style scoped>
p.prompt {
  font-weight: bold;
  margin-bottom: 0;
}
</style>
