const pivotUrl = 'https://www.pivotinteractives.com'
const helpUrl = 'https://help.pivotinteractives.com'
const discoveryUrl = 'https://www.discoveryeducation.com'

function pivotLink(path) {
  return `${pivotUrl}${path}`
}

function helpLink(path) {
  return `${helpUrl}${path}`
}

function discoveryLink(path) {
  return `${discoveryUrl}${path}`
}

export const linksList = {
  support: 'mailto:support@pivotinteractives.com',
  info: 'mailto:info@pivotinteractives.com',
  sales: 'mailto:sales@pivotinteractives.com',
  try_an_activity: pivotLink('/try-an-activity'),
  educators_and_students: pivotLink('/educators-and-students'),
  institutions: pivotLink('/institutions'),
  pricing: pivotLink('/pricing'),
  about: pivotLink('/about'),
  blog: pivotLink('/blog'),
  privacy: discoveryLink('/privacy-policy'),
  terms: discoveryLink('/terms-of-use'),
  de_sales_inquiry: discoveryLink('/help/contact-us/product-inquiry/'),
  what_is_pivot: pivotLink('/what-is-pivot-interactives'),
  help_teaching: pivotLink('/how-can-pivot-interactives-help'),
  teacher_voices: pivotLink('/teacher-voices'),
  whats_new: pivotLink('/whats-new'),
  our_team: pivotLink('/our-team'),
  jobs: pivotLink('/jobs'),
  system_requirements: helpLink(
    '/3833505-minimum-system-requirements-for-pivot-interactives'
  ),
  total_possible_points_help: helpLink(
    '/5544401-how-can-i-change-the-total-points-on-an-assignment'
  ),
  press: pivotLink('/press'),
  upgrade: 'https://www.vernier.com/pivot/#order',
  YouTube: 'https://www.youtube.com/channel/UChDAt7-MzwUq5c2lXj_JBsw',
  help: helpLink(''),
  instructor_quick_start: helpLink(
    '/articles/3389725-quick-start-for-instructors'
  ),
  student_quick_start: helpLink('/articles/3389724-student-instructions'),
  using_table_graph_legacy: helpLink(
    '/articles/3389718-using-the-data-table-and-graphing-tool'
  ),
  using_table_graph_new: helpLink(
    '/articles/8474212-using-the-new-data-table-and-graphing-tool'
  ),
  drop_students_help: helpLink(
    '/articles/3389728-making-a-class-inviting-students-and-managing-the-roster'
  ),
  archive_class_help: helpLink('/articles/3389726-archiving-classes'),
  activating_seats_help: helpLink('/articles/3440717-activating-seats'),
  unsupported_video_help: helpLink(
    '/articles/5715370-how-can-i-fix-a-video-unsupported-error'
  ),
  lti_blackboard_registration: helpLink(
    '/articles/4148046-configuring-pivot-interactives-in-blackboard'
  ),
  lti_schoology_registration_article: helpLink(
    '/articles/6370980-install-the-pivot-interactives-app-to-schoology#h_8061087e5c'
  ),
  lti_canvas_collection: helpLink(
    '/en/collections/2169165-using-canvas-with-pivot-interactives'
  ),
  lti_schoology_collection: helpLink(
    '/en/collections/3541425-using-schoology-with-pivot-interactives'
  ),
  lti_blackboard_collection: helpLink(
    '/en/collections/2403716-using-blackboard-lms-with-pivot-interactives'
  ),
  lti_d2l_collection: helpLink(
    '/en/collections/2411594-using-d2l-brightspace-with-pivot-interactives'
  ),
  lti_other_collection: helpLink(
    '/en/articles/8070068-using-other-lms-s-with-pivot-interactives'
  ),
  help_subscriptions: helpLink('/articles/3389716-managing-your-seats'),
  player_help: helpLink('/articles/3389717-using-pivot-video-player'),
  w9: helpLink(
    '/articles/4520308-where-can-i-find-a-w9-form-for-pivot-interactives'
  ),
  single_sole_source: helpLink(
    '/articles/4520547-is-vernier-the-single-sole-source-vendor-for-pivot-interactives'
  ),
  bluetooth_devices: helpLink('/articles/5163143-bluetooth-devices'),
  buy_seats: helpLink('/articles/5719159-how-can-i-purchase-individual-seats'),
  help_status: helpLink('/articles/4999127-assignment-status-and-grading'),
  help_NAG: helpLink('/articles/5300928-numerical-questions#h_783b545e64'),
  sales_dashboard_upgrade: 'https://www.pivotinteractives.com/lets-chat',
  sales_inquiry: 'https://www.pivotinteractives.com/lets-chat',
  account_suspended: helpLink(
    '/articles/5629447-faq-why-is-my-account-suspended'
  ),
  account_satus: helpLink('/articles/4132444-what-does-my-account-status-mean'),
  salesforce_account: params => {
    return `https://discoveryed.lightning.force.com/lightning/r/Account/${params.id}/view`
  },
  latex_help: helpLink(
    '/articles/3841834-using-latex-to-format-equations-and-text-in-pivot-interactives'
  ),
  latex_dnd_help: helpLink(
    '/articles/7855760-deep-dive-drag-and-drop#h_fba8ae8086'
  ),
  rich_text_dnd_help: helpLink('/articles/7855760-deep-dive-drag-and-drop'),
  assignment_bulk_actions_help: helpLink(
    '/articles/6515993-the-scores-page#h_fcf813aaf7'
  ),
  assignment_student_updates: helpLink(
    '/articles/6515993-the-scores-page#h_8eb49bbc7c'
  ),
  assignment_instructor_updates: helpLink(
    '/articles/6515993-the-scores-page#h_634b33bcb7'
  )
}

export function isExternalLink(linkName) {
  return Object.keys(linksList).includes(linkName)
}
