<template>
  <dashboard-panel>
    <dashboard-collapse-toggle> Onboarding Overview </dashboard-collapse-toggle>
    <collapse-content>
      <div class="overview">
        <div class="overview-left">
          <p class="overview-context">
            Your snapshot of how your teachers are using Pivot Interactives; see
            where your staff are in their onboarding progress here. To learn
            more about the individual onboarding progress of a particular
            teacher, head over to your
            <hyper-link
              :to="{ name: 'license', params: { tab: 'onboarding' } }"
            >
              Onboarding tab</hyper-link
            >.
          </p>
          <dashboard-circle
            :value="activeTeacherAccounts"
            label="Active Teacher Accounts"
            :description="`Total active teacher accounts of ${teachersInvited} invited.`"
            color="purple"
            class="pad-btm"
          />
          <dashboard-circle
            :value="activeAssignments"
            label="Active Assignments"
            :description="`total number of open assignments that are still accepting student responses.`"
            color="purple"
          />
        </div>
        <div class="pie-chart">
          <div
            ref="plotly"
            class="plotly-ref"
            role="img"
            :aria-label="`Onboarding Overview`"
            :aria-describedby="`Onboarding Overview`"
          />
        </div>
      </div>
    </collapse-content>
  </dashboard-panel>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  watch,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import Plotly from 'plotly.js-basic-dist'
import DashboardPanel from '../../../shared/components/DashboardPanel.vue'
import DashboardCollapseToggle from '../../../shared/components/DashboardCollapseToggle.vue'
import DashboardCircle from './DashboardCircle.vue'

import client from 'src/shared/api-client.ts'
const plotly = ref()

const onboardingDetails = ref({
  teachersInvited: 0,
  activeTeacherAccounts: 0,
  activeAssignments: 0,
  accepted: 0,
  created: 0,
  enrolled: 0,
  assigned: 0,
  graded: 0,
  complete: 0
})

const props = defineProps({
  licenseId: {
    type: String,
    required: true
  }
})

const store = useStore()

const teachersInvited = computed(() => onboardingDetails.value.teachersInvited)
const activeTeacherAccounts = computed(
  () => onboardingDetails.value.activeTeacherAccounts
)
const activeAssignments = computed(
  () => onboardingDetails.value.activeAssignments
)

const getOnboardingData = async () => {
  const onboardingStats = await client.licenses.getOnboardingStats({
    id: props.licenseId
  })
  onboardingDetails.value = onboardingStats
}

const plotData = computed(() => {
  const { accepted, created, enrolled, teachersInvited, complete } =
    onboardingDetails.value
  const remaining = teachersInvited - accepted - created - enrolled - complete
  return [
    {
      values: [remaining, accepted, created, enrolled, complete],
      labels: [
        '0. Not Yet Active',
        '1. Accepted Invitation',
        '2. Created First Class',
        '3. Enrolled Students',
        '4. Onboarding Complete'
      ],
      ids: [
        'remaining',
        'accepted',
        'created',
        'enrolled',
        'assigned',
        'complete'
      ],
      text: `${teachersInvited} teachers invited`,
      textposition: 'inside',
      domain: { column: 1 },
      name: '',
      hole: 0.7,
      type: 'pie',
      hoverinfo: 'none',
      textinfo: 'value',
      sort: false,
      marker: {
        colors: ['#b7b7b7', '#e30e62', '#f1af51', '#5a3f85', '#158484']
      },
      plot_bgcolor: '#b7b7b7',
      automargin: true,
      customdata: [{}, {}, {}, {}, { class: 'empty' }]
    }
  ]
})
const plotOptions = {
  displaylogo: false,
  displayModeBar: false,
  responsive: true,
  showTips: false,
  doubleClick: false,
  sort: false
}

const plotLayout = computed(() => {
  const { teachersInvited } = onboardingDetails.value
  return {
    title: {
      text: '<b>Onboarding Status</b>',
      x: 0.025,
      y: 0.9
    },
    plot_bgcolor: '#b7b7b7',
    annotations: [
      {
        font: {
          size: 16
        },
        showarrow: false,
        text: `<b>${teachersInvited}</b><br />Teachers Invited`,
        x: 0.89,
        y: 0.5
      }
    ],
    autosize: true,
    height: 200,
    width: 500,
    margin: {
      b: 0,
      t: 0,
      l: 0,
      r: 0
    },
    grid: { columns: 2, rows: 1 },
    showlegend: true,
    legend: {
      x: 0,
      y: 0.5,
      font: {
        size: 14
      }
    }
  }
})

watch(plotData, () => {
  nextTick(() => {
    Plotly.react(plotly.value, plotData.value, plotLayout.value, plotOptions)
  })
})

onBeforeMount(async () => {
  getOnboardingData()
})

onMounted(() => {
  nextTick(() => {
    Plotly.newPlot(plotly.value, plotData.value, plotLayout.value, plotOptions)
  })
})

onBeforeUnmount(() => {
  Plotly.purge(plotly.value)
})
</script>

<style lang="scss" scoped>
.overview {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.overview-left {
  width: 30%;
}

.overview-context {
  margin: 0;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 20px;
  width: 100%;
}
.context-message {
  margin: 0;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 20px;
}
.invitation-message {
  margin: 0;
  font-family: Lato;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: $color-success;
  padding-bottom: 20px;
  width: 65%;
}

.pad-btm {
  margin-bottom: 15px;
}

.pie-chart {
  width: 50%;
  display: flex;
}
.date-select {
  position: absolute;
  top: -50px;
  left: 260px;
}

.overview-context-emphasis {
  font-weight: bold;
  font-style: italic;
}
</style>
