<template>
  <view-container>
    <view-header>
      <view-title v-if="klass"
        >{{ klass.name }}
        <span v-if="klass.isArchived">(Archived)</span></view-title
      >
      <view-title v-else>Class</view-title>
    </view-header>
    <loading-container :loading="isLoading">
      <div class="form-two-column" v-if="klass">
        <form-group>
          <form-label for="name">Name</form-label>
          <static-value id="name">
            {{ klass.name }}
          </static-value>
        </form-group>

        <form-group>
          <form-label for="class-key"> Class Key </form-label>
          <static-value id="class-key">
            {{ klass.classKey }}
          </static-value>
        </form-group>

        <form-group>
          <form-label for="student-count"> # Students </form-label>
          <static-value id="student-count">
            {{ (klass.roster || []).length }}
          </static-value>
        </form-group>

        <form-group>
          <form-label for="assignments"> Assignments </form-label>
          <list
            v-if="(assignments || []).length > 0"
            id="assignments"
            :items="assignments"
          >
            <list-column v-slot="{ activity }" title="Name">
              {{ activity.name }}
            </list-column>
            <list-column v-slot="{ startDate }" title="Start Date">
              {{ $format.date(startDate, 'MM/dd/yyyy') }}
            </list-column>
            <list-column v-slot="{ endDate }" title="Due Date">
              {{ $format.date(endDate, 'MM/dd/yyyy') }}
            </list-column>
            <list-column v-slot="{ id }" title="Actions">
              <router-link
                :to="{
                  name: 'instructor_assignment',
                  params: { id: id }
                }"
              >
                View
              </router-link>
            </list-column>
          </list>
          <static-value v-else id="assignments">
            This class has no assignments.
          </static-value>
        </form-group>

        <form-group>
          <form-label for="students"> Students </form-label>
          <list
            v-if="(klass.roster || []).length > 0"
            id="students"
            :items="klass.roster"
          >
            <list-column v-slot="{ student }" title="Name">
              {{ student.firstName }} {{ student.lastName }}
            </list-column>
            <list-column v-slot="{ student }" title="Email">
              {{ student.email }}
            </list-column>
            <list-column
              v-if="klass.paymentSource === 'student'"
              v-slot="{ expirationDate }"
              title="Expiration"
            >
              {{ $format.date(expirationDate, 'MM/dd/yyyy') }}
            </list-column>
          </list>
          <static-value v-else id="students">
            No students have joined this class yet.
          </static-value>
        </form-group>

        <form-group>
          <form-label for="dropped-students"> Dropped Students </form-label>
          <list
            v-if="(klass.droppedStudents || []).length > 0"
            id="dropped-students"
            :items="klass.droppedStudents"
          >
            <list-column v-slot="{ student }" title="Name">
              {{ student.firstName }} {{ student.lastName }}
            </list-column>
            <list-column v-slot="{ student }" title="Email">
              {{ student.email }}
            </list-column>
          </list>
          <static-value v-else id="dropped-students">
            There are no dropped students in this class.
          </static-value>
        </form-group>

        <div class="graders-header">
          <h2 id="graders-header">Instructors</h2>
        </div>
        <p v-if="(klass.instructors || []).length === 0">
          No instructors have been added to this class yet.
        </p>
        <list v-else :items="klass.instructors" aria-label="Instructors">
          <list-column v-slot="{ firstName, lastName }" title="Name">
            <router-link
              :to="{
                name: 'manage_instructor',
                params: { instructorId: _id }
              }"
            >
              {{ firstName }} {{ lastName }}
            </router-link>
          </list-column>
          <list-column v-slot="{ email }" title="Email">
            {{ email }}
          </list-column>
          <list-column v-slot="{ role }" title="Role">
            {{ role }}
          </list-column>
        </list>
      </div>
    </loading-container>
  </view-container>
</template>

<script>
import { mapGetters } from 'vuex'
import client from 'src/shared/api-client'

export default {
  name: 'InstructorClassView',
  props: {
    classId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      klass: null,
      assignments: [],
      isLoading: true
    }
  },
  computed: {
    ...mapGetters(['isSiteLicenseAdmin']),
    instructors() {
      return (this.klass.instructors || []).filter(i => i.role === 'Instructor')
    }
  },
  methods: {
    async load() {
      await Promise.all([
        this.loadClass(this.classId),
        this.loadAssignments(this.classId)
      ])
      this.isLoading = false
    },
    async loadClass(classId) {
      this.klass = await client.classes.get({ classId })
    },
    async loadAssignments(classId) {
      this.assignments = await client.classes.getAssignments({
        classId: classId
      })
    }
  },
  watch: {
    classId: {
      handler() {
        return this.load()
      },
      immediate: true
    }
  }
}
</script>
