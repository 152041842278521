<template>
  <div
    class="pi-data-graph"
    :class="{ readonly, nopanel: !showSettingsPanel }"
    role="group"
    aria-label="data graph"
    @keydown.esc="hideSettings"
  >
    <div :id="`${uid}-graphTitleUpdate`" hidden>
      Use this button to change the graph title for {{ graphTitle }}
    </div>
    <collapse-provider v-slot="{ collapsed }">
      <div class="title-bar" :class="{ nopanel: !showSettingsPanel }">
        <collapse-toggle class="collapse-button">
          <collapse-icon aria-hidden="true" />
          <span v-if="showSettingsPanel" class="graph-title">{{
            graphTitle
          }}</span>
          <span v-if="!showSettingsPanel" class="sr-only">{{
            collapsed ? 'Show Graph' : 'Hide Graph'
          }}</span>
        </collapse-toggle>
        <template v-if="!showSettingsPanel">
          <button
            ref="graphTitleButton"
            type="button"
            class="graph-title-btn"
            :aria-describedby="`${uid}-graphTitleUpdate`"
            @click.prevent="updateGraphTitle()"
          >
            {{ graphTitle }}
          </button>
          <div class="graph-dropdown">
            <form-button
              v-if="graphDetails.curveFitIgnoredRows?.length"
              tertiary
              @click="clearSelectedPoints"
            >
              Select All Points
            </form-button>
            <button-dropdown ref="graphActionDropdownButton" secondary right>
              <template #button>
                <icon icon="gear" />
                <span class="sr-only">Graph Actions Menu</span>
              </template>
              <template v-if="!readonly">
                <dropdown-action
                  @click="changeGraphType('scatter')"
                  v-if="graphSettings.graphTypes?.scatterGraphs"
                >
                  <icon v-if="graphDetails.type === 'scatter'" icon="check" />
                  Scatter Graph
                </dropdown-action>
                <dropdown-action
                  @click="changeGraphType('line')"
                  v-if="graphSettings.graphTypes?.lineGraphs"
                >
                  <icon v-if="graphDetails.type === 'line'" icon="check" />
                  Line Graph
                </dropdown-action>
                <dropdown-action
                  @click="changeGraphType('bar')"
                  v-if="graphSettings.graphTypes?.barGraphs"
                >
                  <icon v-if="graphDetails.type === 'bar'" icon="check" />
                  Bar Graph
                </dropdown-action>
                <li role="separator" class="divider" />
                <dropdown-action
                  :disabled="graphDetails.type === 'bar'"
                  @click="configureCurveFits()"
                >
                  Curve Fits
                </dropdown-action>
                <dropdown-action @click="configureHorizontalAxis(true)">
                  Configure Horizontal Axis
                </dropdown-action>
                <dropdown-action @click="configureVerticalAxis(true)">
                  Configure Vertical Axis
                </dropdown-action>
                <li role="separator" class="divider" />
                <dropdown-action @click="autoscale()"
                  >Autoscale View</dropdown-action
                >
                <dropdown-action @click="toggleIncludeOrigin()">
                  {{
                    graphDetails.includeOrigin
                      ? `Don't Include Origin`
                      : 'Include Origin'
                  }}
                </dropdown-action>
                <dropdown-action @click="toggleShowLegend()">
                  {{ graphDetails.showLegend ? 'Hide' : 'Show' }} Legend
                </dropdown-action>
                <li role="separator" class="divider" />
                <dropdown-action @click="resetGraph(graphIndex)">
                  Reset Graph
                </dropdown-action>
                <dropdown-action
                  v-if="graphIndex > 0"
                  @click="$emit('deleteGraph', graphIndex)"
                >
                  Delete Graph
                </dropdown-action>
                <li role="separator" class="divider" />
              </template>
              <dropdown-link :to="{ name: 'using_table_graph_legacy' }">
                Data Table & Graph Help
              </dropdown-link>
            </button-dropdown>
          </div>
        </template>

        <div
          v-if="showSettingsPanel"
          class="graph-actions"
          :class="{ 'graph-actions--toggled': showSettings, hidden: collapsed }"
        >
          <form-button
            v-if="graphDetails.curveFitIgnoredRows?.length"
            tertiary
            @click="clearSelectedPoints"
            class="select-all-btn"
          >
            Select All Points
          </form-button>
          <button-link
            secondary
            :to="{ name: 'using_table_graph_new' }"
            class="help-btn"
            ><icon icon="question"></icon
          ></button-link>
          <form-button
            right
            label="Graph Actions Menu"
            @click.stop="toggleSettings"
            class="settings-btn"
            :class="{ 'settings-btn--toggled': showSettings }"
            ref="settingsToggle"
            unstyled
          >
            <icon v-if="!showSettings" icon="gear" />
            <icon v-if="showSettings" icon="arrow-right-to-bracket" />
            <span class="sr-only">Graph Actions Menu</span>
          </form-button>
        </div>
      </div>
      <data-graph-settings
        ref="settingsPanel"
        v-if="showSettingsPanel"
        :title="graphTitle"
        :settings="graphSettings"
        :showing="showSettings && !collapsed"
        :open-panel="openSettingsPanel"
        :columns="graphData.columns"
        :graph-details="graphDetails"
        :graph-map="graphMap"
        :curve-fit-disabled="hAxisText"
        :can-delete="canDelete"
        @update="computeGraphTitle"
        @reset="resetGraph"
        @clear:openPanel="clearOpenPanel"
        @delete="() => $emit('deleteGraph')"
      />
      <collapse-content>
        <div class="graph-content">
          <div class="graph-wrapper">
            <div class="axis vertical">
              <div :id="`${uid}-v-axis`" hidden>
                use this button to select data columns to plot along the
                vertical axis
              </div>
              <div class="vertical-axis-wrapper">
                <p v-if="readonly" :class="{ unset: !vColumnsSet }">
                  <template v-if="yAxis.title">{{ yAxis.title }}</template>
                  <template v-else-if="yAxis.columns.length > 0">
                    <template
                      v-for="(column, index) of yAxis.columns"
                      :key="column.index"
                    >
                      <template v-if="column.name">
                        <latex-block
                          v-if="column.name?.parser === 'latex'"
                          :latex="column.name.formula"
                        />
                        <template v-else>{{ column.name }}</template>
                        <template v-if="column.units">
                          (<latex-block
                            v-if="column.units.parser === 'latex'"
                            :latex="column.units.formula"
                          />
                          <template v-else>{{ column.units }}</template
                          >)</template
                        ><template v-if="index + 1 !== yAxis.columns.length"
                          >,
                        </template>
                      </template>
                    </template>
                  </template>
                  <template v-else>Vertical Axis</template>
                </p>
                <button
                  v-else
                  ref="configureVerticalAxisButton"
                  type="button"
                  href="#"
                  :class="{ unset: !vColumnsSet }"
                  :aria-describedby="`${uid}-v-axis`"
                  @click.prevent.stop="configureVerticalAxis()"
                >
                  <p>
                    <template v-if="yAxis.title">{{ yAxis.title }}</template>
                    <template
                      v-else-if="
                        yAxis.columns.filter(col => col.name).length > 0
                      "
                    >
                      <template
                        v-for="(column, index) of yAxis.columns"
                        :key="column.index"
                      >
                        <template v-if="column.name">
                          <latex-block
                            v-if="column.name?.parser === 'latex'"
                            :latex="column.name.formula"
                          />
                          <template v-else>{{ column.name }}</template>
                          <template v-if="column.units">
                            (<latex-block
                              v-if="column.units.parser === 'latex'"
                              :latex="column.units.formula"
                            />
                            <template v-else>{{ column.units }}</template
                            >)</template
                          ><template v-if="index + 1 !== yAxis.columns.length"
                            >,
                          </template>
                        </template>
                      </template>
                    </template>
                    <template v-else>Vertical Axis</template>
                  </p>
                </button>
              </div>
            </div>

            <div :id="`${uid}-graph-description`" hidden>
              {{ graphDescription }}
            </div>

            <div
              ref="plotly"
              class="plotly-ref"
              role="img"
              :aria-label="graphTitle"
              :aria-describedby="`${uid}-graph-description`"
            />
          </div>

          <div class="axis horizontal">
            <div :id="`${uid}-h-axis`" hidden>
              use this button to select data columns to plot along the
              horizontal axis
            </div>
            <p v-if="readonly">
              <template v-if="xAxis.title">{{ xAxis.title }}</template>
              <template v-else-if="xAxis.name">
                <latex-block
                  v-if="xAxis.name.parser === 'latex'"
                  :latex="xAxis.value"
                />
                <template v-else>{{ xAxis.name }}</template>
                <template v-if="xAxis.units">
                  (
                  <latex-block
                    v-if="xAxis.units.parser === 'latex'"
                    :latex="xAxis.units.formula"
                  />
                  <template v-else>{{ xAxis.units }}</template>
                  )
                </template>
              </template>
              <template v-else>Horizontal Axis</template>
            </p>
            <button
              v-else
              ref="configureHorizontalAxisButton"
              type="button"
              href="#"
              :class="{ unset: !hColumnsSet }"
              :aria-describedby="`${uid}-h-axis`"
              @click.prevent.stop="configureHorizontalAxis()"
            >
              <p>
                <template v-if="xAxis.title">{{ xAxis.title }}</template>
                <template v-else-if="xAxis.name">
                  <latex-block
                    v-if="xAxis.name.parser === 'latex'"
                    :latex="xAxis.name.formula"
                  />
                  <template v-else>{{ xAxis.name }}</template>
                  <template v-if="xAxis.units">
                    (<latex-block
                      v-if="xAxis.units.parser === 'latex'"
                      :latex="xAxis.units.formula"
                    />
                    <template v-else>{{ xAxis.units }}</template
                    >)
                  </template>
                </template>
                <template v-else>Horizontal Axis</template>
              </p>
            </button>
          </div>

          <div v-if="fittedVColumns.length > 0" class="curve-fits">
            <checkbox
              :modelValue="graphDetails.curveFitUncertainties"
              @update:modelValue="changeDisplayCurveFitUncertainties"
            >
              Display Curve Fit Uncertainties
            </checkbox>
            <div class="curve-fit-details">
              <data-graph-curve-fit-detail
                v-for="vColumnsIndex in fittedVColumns"
                :key="vColumnsIndex"
                :fit="curveFits[vColumnsIndex]"
                :h-column="graphData.columns[graphDetails.hColumns.data]"
                :v-column="
                  graphData.columns[graphDetails.vColumns[vColumnsIndex].data]
                "
              />
            </div>
          </div>
        </div>
      </collapse-content>
    </collapse-provider>
    <slot name="back-to-table" />
  </div>
</template>

<script>
import * as Y from 'yjs'
import Plotly from 'plotly.js-basic-dist'
import DataGraphCurveFitsModal from 'src/shared/components/modals/DataGraphCurveFitsModal'
import DataGraphUpdateTitleModal from 'src/shared/components/modals/DataGraphUpdateTitleModal'
import DataGraphAxisSelectModal from 'src/shared/components/modals/DataGraphAxisSelectModal'
import DataGraphCurveFitDetail from 'src/shared/components/grid-graph/DataGraphCurveFitDetail'
import cloneDeep from 'lodash/cloneDeep'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import DataGraphSettings from 'src/shared/components/grid-graph/DataGraphSettings'
import { evaluateExpression } from '@pi/shared/variables'
import {
  columnDisplayName,
  columnDisplayUnits,
  defaultGraphVAxisColumns
} from 'src/shared/components/grid-graph/utilities'
import fit from 'src/shared/components/grid-graph/curve-fit'

const plotOptions = {
  displaylogo: false,
  displayModeBar: false,
  responsive: true,
  showTips: false,
  doubleClick: false
}

const defaultAxisRange = [0, 10]
const markerSymbolOptions = [0, 1, 2, 3, 5, 13, 16, 17]
const graphColors = ['34BBBB', '674893', 'DF206C', '9B7000', 'F39049', '979797']

let instanceCount = 0

export default {
  name: 'DataGraph',
  inject: ['$modal'],
  components: {
    DataGraphCurveFitDetail,
    DataGraphSettings
  },
  emits: ['deleteGraph', 'resetGraph'],
  props: {
    ymap: {
      type: Y.Map,
      required: true
    },
    graphIndex: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      required: true
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    }
  },
  data() {
    return {
      graphData: this.ymap.toJSON(),
      graphDetails: this.ymap.get('graphs').get(this.graphIndex).toJSON(),
      graphMap: this.ymap.get('graphs').get(this.graphIndex),
      uid: 'data-graph-' + instanceCount++,
      showSettings: false,
      openSettingsPanel: null
    }
  },
  beforeUnmount() {
    this.unsubscribe?.()
    Plotly.purge(this.$refs.plotly)
  },
  computed: {
    evaluatedData() {
      const { columns } = this.graphData
      const variables = this.variableContext?.variables ?? []
      const evaluated = columns.map(column => {
        if (column.allowText) {
          return {
            ...column,
            data: column.data.map(v => {
              if (v.parser && v.parser === 'expression') {
                return evaluateExpression(v.id, variables).toString()
              }
              return v
            })
          }
        } else {
          return {
            ...column,
            data: column.data.map(v => {
              if (v.parser && v.parser === 'expression') {
                const res = evaluateExpression(v.id, variables)
                return isFinite(res) ? res : NaN
              }
              return isFinite(v) ? v : NaN
            })
          }
        }
      })
      return {
        ...this.graphData,
        columns: evaluated
      }
    },
    graphSettings() {
      const defaultSettings = {
        allowUncertainty: false,
        curveFitTypes: {
          proportional: true,
          linear: true,
          squareLaw: true,
          squareRoot: true,
          quadratic: true,
          exponential: true,
          logarithmic: true,
          inverse: true,
          inverseSquare: true
        },
        graphTypes: {
          scatterGraphs: true,
          lineGraphs: true,
          barGraphs: true
        }
      }
      const graphSettings = { ...this.settings }

      for (const key in defaultSettings) {
        if (!Object.prototype.hasOwnProperty.call(graphSettings, key)) {
          graphSettings[key] = defaultSettings[key]
        }
      }

      return graphSettings
    },
    showSettingsPanel() {
      return this.settings.legacyInterface === false
    },
    plotLayout() {
      return {
        showlegend: this.graphDetails.showLegend,
        legend: {
          x: 1,
          y: 1,
          xanchor: 'right',
          bgcolor: '#E2E2E2',
          bordercolor: '#FFFFFF',
          borderwidth: 2
        },
        height: 450,
        hovermode: 'x unified',
        dragmode: 'select',
        selectdirection: 'h',
        xaxis: {
          autorange: this.hAxisText,
          range: [...this.hAxisRange],
          type: this.hAxisText ? 'category' : 'linear',
          title: { text: '' },
          automargin: true
        },
        yaxis: {
          autorange: false,
          range: [...this.vAxisRange],
          type: 'linear',
          title: { text: '' },
          automargin: true
        },
        margin: { t: 0, l: 0, r: 0, b: 0 }
      }
    },
    plotData() {
      const plotData = []

      if (!this.hColumnsSet || !this.vColumnsSet) return plotData

      for (let i = 0; i < this.graphDetails.vColumns.length; i++) {
        const vColumns = this.graphDetails.vColumns[i]
        if (vColumns.data > -1) {
          const color = graphColors[i % 6]
          const symbol = markerSymbolOptions[i % markerSymbolOptions.length]

          plotData.push(
            this.makePlotData(
              vColumns,
              color,
              symbol,
              this.variableContext ? this.variableContext.variables : []
            )
          )

          if (this.curveFits[i]) {
            plotData.push(
              this.makeCurveFitPlotData(this.curveFits[i], vColumns, color)
            )
          }
        }
      }

      return plotData
    },
    hAxisText() {
      if (!this.hColumnsSet) return false

      const column = this.graphData.columns[this.graphDetails.hColumns.data]

      return column.allowText
    },
    hAxisRange() {
      if (!this.hColumnsSet || this.hAxisText) return [...defaultAxisRange]

      const uncertainty = this.graphSettings.allowUncertainty
        ? this.graphDetails.hColumns.uncertainty
        : -1
      const column = this.evaluatedData.columns[this.graphDetails.hColumns.data]
      let data = []

      if (uncertainty > -1) {
        data = column.data
          .flatMap((val, index) => {
            return [
              parseFloat(val) +
                parseFloat(this.graphData.columns[uncertainty].data[index]),
              parseFloat(val) -
                parseFloat(this.graphData.columns[uncertainty].data[index]),
              parseFloat(val)
            ]
          })
          .filter(d => d !== null && !isNaN(d))
      } else {
        data = [...column.data].filter(d => d !== null && !isNaN(d))
      }
      if (this.graphDetails.includeOrigin) {
        data.push(0)
      }

      const min = this.graphDetails.xAxisRange?.[0] ?? Math.min(...data)
      const max = this.graphDetails.xAxisRange?.[1] ?? Math.max(...data)
      const padding = (max - min) * 0.05
      return [min - padding, max + padding]
    },
    vAxisRange() {
      if (this.plotData.length < 1) return [...defaultAxisRange]
      const data = this.plotData
        .reduce((acc, plotData) => {
          if (plotData.error_y) {
            return acc
              .concat(
                plotData.y.map(
                  (value, index) =>
                    parseFloat(value) +
                    parseFloat(plotData.error_y.array[index] || 0)
                )
              )
              .concat(
                plotData.y.map(
                  (value, index) =>
                    parseFloat(value) -
                    parseFloat(plotData.error_y.array[index] || 0)
                )
              )
          } else {
            return acc.concat(plotData.y.map(parseFloat))
          }
        }, [])
        .filter(d => d !== null && !isNaN(d))

      if (this.graphDetails.includeOrigin) {
        data.push(0)
      }

      const min = this.graphDetails.yAxisRange?.[0] ?? Math.min(...data)
      const max = this.graphDetails.yAxisRange?.[1] ?? Math.max(...data)
      const padding = (max - min) * 0.05

      return [min - padding, max + padding]
    },
    curveFits() {
      if (!this.hColumnsSet || !this.vColumnsSet || this.hAxisText) return {}

      const columns = this.evaluatedData.columns
      const curveFitUncertainties = this.graphDetails.curveFitUncertainties

      const curveFits = this.graphDetails.vColumns.reduce(
        (acc, vColumns, index) => {
          if (
            !vColumns.curve ||
            vColumns.curve === 'none' ||
            vColumns.data === -1
          )
            return acc

          const column = columns[vColumns.data]
          const xy = columns[this.graphDetails.hColumns.data].data.reduce(
            (acc, x, i) => {
              if (!this.graphDetails.curveFitIgnoredRows?.includes(i)) {
                const acc0 = parseFloat(x)
                const acc1 = parseFloat(column.data[i])
                if (!isNaN(acc0) && !isNaN(acc1)) {
                  acc[0].push(acc0)
                  acc[1].push(acc1)
                }
              }
              return acc
            },
            [[], []]
          )

          if (xy.length > 1) {
            switch (vColumns.curve) {
              case 'linear':
                acc[index] = fit.linear(xy[0], xy[1], curveFitUncertainties)
                break
              case 'proportional':
                acc[index] = fit.proportional(
                  xy[0],
                  xy[1],
                  curveFitUncertainties
                )
                break
              case 'square':
                acc[index] = fit.square(xy[0], xy[1], curveFitUncertainties)
                break
              case 'squareroot':
                acc[index] = fit.squareroot(xy[0], xy[1], curveFitUncertainties)
                break
              case 'quadratic':
                acc[index] = fit.quadratic(xy[0], xy[1], curveFitUncertainties)
                break
              case 'exponential':
                acc[index] = fit.exponential(
                  xy[0],
                  xy[1],
                  curveFitUncertainties
                )
                break
              case 'logarithmic':
                acc[index] = fit.logarithmic(
                  xy[0],
                  xy[1],
                  curveFitUncertainties
                )
                break
              case 'inverse':
                acc[index] = fit.inverse(xy[0], xy[1], curveFitUncertainties)
                break
              case 'inverse-square':
                acc[index] = fit.inverseSquare(
                  xy[0],
                  xy[1],
                  curveFitUncertainties
                )
                break
              default:
                break
            }
          }

          return acc
        },
        {}
      )

      return curveFits
    },
    graphTitle() {
      return (
        this.graphDetails.title?.trim() || `Graph ${this.graphIndex + 1} Title`
      )
    },
    graphDescription() {
      if (!this.hColumnsSet || !this.vColumnsSet)
        return 'empty graph, no columns plotted'

      const vColumns = this.validVColumns
        .map(({ data }) => columnDisplayName(this.graphData.columns[data]))
        .join(', ')

      return `scatter plot graph with ${columnDisplayName(
        this.graphData.columns[this.graphDetails.hColumns.data] ?? ''
      )} on the horizontal axis and ${vColumns} on the vertical axis`
    },
    xAxis() {
      const dataIndex = this.graphDetails.hColumns.data
      const dataColumn = this.graphData.columns[dataIndex]
      return {
        title: this.graphDetails.hAxisTitle,
        name: dataColumn?.name,
        units: dataColumn?.units
      }
    },
    yAxis() {
      const columns = this.validVColumns.map(({ data }) => {
        const column = this.graphData.columns[data]

        return {
          index: data,
          name: column.name,
          units: column.units
        }
      })

      return {
        title: this.graphDetails.vAxisTitle,
        columns
      }
    },
    validVColumns() {
      return this.graphDetails.vColumns.filter(column => column.data > -1)
    },
    fittedVColumns() {
      if (!this.hColumnsSet || !this.vColumnsSet || this.hAxisText) {
        return []
      }

      return this.graphDetails.vColumns.reduce((acc, column, index) => {
        if (column.data > -1 && column.curve && column.curve !== 'none') {
          acc.push(index)
        }

        return acc
      }, [])
    },
    hColumnsSet() {
      return this.graphDetails.hColumns.data > -1
    },
    vColumnsSet() {
      return this.validVColumns.length > 0
    },
    markerOpacities() {
      return Array(this.graphData.rows)
        .fill(1)
        .map((_, rowIndex) => {
          return this.graphDetails.curveFitIgnoredRows?.includes(rowIndex)
            ? 0.5
            : 1
        })
    },
    hasCurve() {
      return this.graphDetails.vColumns.some(
        col => col.curve && col.curve !== 'none'
      )
    }
  },
  methods: {
    react() {
      if (this.$refs.plotly) {
        window.dispatchEvent(new Event('resize'))
        Plotly.react(
          this.$refs.plotly,
          this.plotData,
          this.plotLayout,
          plotOptions
        )
      }
    },
    autoscale() {
      this.graphMap.set('xAxisRange', [null, null])
      this.graphMap.set('yAxisRange', [null, null])
    },
    makePlotData(vColumns, color, symbol, variables) {
      const { columns } = this.evaluatedData
      const { hColumns, type } = this.graphDetails

      function cleanData(column) {
        if (column.allowText) {
          return column.data
        } else {
          return column.data.map(x => (isFinite(x) ? x : NaN))
        }
      }
      const allPoints = Array(this.graphData.rows)
        .fill(null)
        .map((_, rowIndex) => rowIndex)

      return {
        x: cleanData(columns[hColumns.data]),
        y: cleanData(columns[vColumns.data]),
        error_x: {
          array:
            this.graphSettings.allowUncertainty && hColumns.uncertainty > -1
              ? cleanData(columns[hColumns.uncertainty])
              : [],
          type: 'data',
          visible:
            this.graphSettings.allowUncertainty && hColumns.uncertainty > -1,
          width: 4,
          color
        },
        error_y: {
          array:
            this.graphSettings.allowUncertainty && vColumns.uncertainty > -1
              ? cleanData(columns[vColumns.uncertainty])
              : [],
          type: 'data',
          visible:
            this.graphSettings.allowUncertainty && vColumns.uncertainty > -1,
          width: 4,
          color: type === 'bar' ? 'black' : color
        },
        name: columnDisplayName(columns[vColumns.data]),
        type,
        mode: type === 'line' ? 'line' : 'markers',
        hovertemplate: `%{y} ${columnDisplayUnits(columns[vColumns.data])}`,
        marker: {
          color,
          size: 10,
          symbol: this.markerSymbols(symbol),
          opacity: this.markerOpacities
        }
      }
    },
    makeCurveFitPlotData(curveFit, vColumns, color) {
      const { columns } = this.graphData
      const { predict } = curveFit

      const hData = columns[this.graphDetails.hColumns.data].data
      const vData = columns[vColumns.data].data

      const quadrant = this.calcQuadrant(hData, vData)
      const numSteps = 50
      const step = (this.hAxisRange[1] - this.hAxisRange[0]) / numSteps
      let points = Array(numSteps)
        .fill(0)
        .map((x, index) => predict(this.hAxisRange[0] + step * index)) // calculate predicted points for all values in range
        .concat([predict(this.hAxisRange[1])]) // add end point
        .concat(
          hData
            .filter(d => d !== null && !isNaN(d))
            .map(d => predict(parseFloat(d))) // calculate predicted points for data points
        )
        .sort((a, b) => a[0] - b[0])

      if (vColumns.curve === 'inverse' || vColumns.curve === 'inverse-square') {
        points = points.filter(d => {
          switch (quadrant) {
            case 1:
              return d[0] > 0 && d[1] > 0
            case 2:
              return d[0] < 0 && d[1] > 0
            case 3:
              return d[0] < 0 && d[1] < 0
            case 4:
              return d[0] > 0 && d[1] < 0
            default:
              return true // include points on the axes
          }
        })
      }

      const x = points.map(p => p[0])
      const y = points.map(p => p[1])

      return {
        x,
        y,
        name: `${columnDisplayName(columns[vColumns.data])} ${
          vColumns.curve
        } fit`,
        type: 'scatter',
        mode: 'lines',
        hovertemplate: `%{y} ${columnDisplayUnits(columns[vColumns.data])}`,
        line: {
          shape: 'spline',
          width: 4,
          color
        }
      }
    },
    clearOpenPanel() {
      this.openSettingsPanel = null
    },
    async configureHorizontalAxis(fromCog = false) {
      if (this.showSettingsPanel) {
        this.openSettingsPanel = 'horizontalAxis'
        this.showSettings = true
      } else {
        const { status, data } = await this.$modal.show(
          DataGraphAxisSelectModal,
          {
            axisName: 'Horizontal',
            max: 1,
            allowTextData: true,
            columns: this.graphData.columns,
            initialSelections: [this.graphDetails.hColumns],
            axisTitle: this.graphDetails.hAxisTitle,
            includeUncertainty: this.graphSettings.allowUncertainty
          }
        )
        if (status === 'ok') {
          this.graphMap.doc.transact(() => {
            this.graphMap.set('hAxisTitle', data.title)
            const hColumns = new Y.Map()
            hColumns.set('data', data.selections[0].data)
            hColumns.set('uncertainty', data.selections[0].uncertainty)
            this.graphMap.set('hColumns', hColumns)
            this.computeGraphTitle()
          })
        }
        if (fromCog) {
          this.$refs.graphActionDropdownButton.focus({ preventScroll: true })
        } else {
          this.$refs.configureHorizontalAxisButton.focus({
            preventScroll: true
          })
        }
      }
    },

    async configureVerticalAxis(fromCog = false) {
      if (this.showSettingsPanel) {
        this.openSettingsPanel = 'verticalAxis'
        this.showSettings = true
      } else {
        const { status, data } = await this.$modal.show(
          DataGraphAxisSelectModal,
          {
            axisName: 'Vertical',
            max: 5,
            columns: this.graphData.columns,
            initialSelections: this.graphDetails.vColumns,
            defaultColumnsValue: defaultGraphVAxisColumns,
            axisTitle: this.graphDetails.vAxisTitle,
            includeUncertainty: this.graphSettings.allowUncertainty
          }
        )
        if (status === 'ok') {
          this.graphMap.doc.transact(() => {
            this.graphMap.set('vAxisTitle', data.title)
            this.graphMap.set(
              'vColumns',
              Y.Array.from(
                data.selections.map(column => {
                  const map = new Y.Map()
                  map.set('data', column.data ?? -1)
                  map.set('uncertainty', column.uncertainty ?? -1)
                  map.set('curve', column.curve ?? 'none')
                  return map
                })
              )
            )
            this.computeGraphTitle()
          })
        }
        if (fromCog) {
          this.$refs.graphActionDropdownButton.focus({ preventScroll: true })
        } else {
          this.$refs.configureHorizontalAxisButton.focus({
            preventScroll: true
          })
        }
      }
    },
    async configureCurveFits() {
      await this.$modal.show(DataGraphCurveFitsModal, {
        columns: this.graphData.columns,
        initialSelections: this.validVColumns,
        disabled: this.hAxisText,
        settings: this.graphSettings.curveFitTypes
      })
      this.graphMap.doc.transact(() => {
        this.graphMap.set(
          'vColumns',
          Y.Array.from(
            this.graphDetails.vColumns.map(column => {
              const map = new Y.Map()
              map.set('data', column.data ?? -1)
              map.set('uncertainty', column.uncertainty ?? -1)
              map.set('curve', column.curve ?? 'none')
              return map
            })
          )
        )
      })
      this.$refs.graphActionDropdownButton.focus({ preventScroll: true })
    },
    async updateCurveFits(vColumns) {
      this.graphMap.doc.transact(() => {
        this.graphMap.set(
          'vColumns',
          Y.Array.from(
            vColumns.map(column => {
              const map = new Y.Map()
              map.set('data', column.data ?? -1)
              map.set('uncertainty', column.uncertainty ?? -1)
              map.set('curve', column.curve ?? 'none')
              return map
            })
          )
        )
      })
    },
    async resetGraph(graphIndex) {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'This will reset your graph removing all data and curve fits. This cannot be undone.',
        prompt: 'Are you sure you want to reset this graph?'
      })

      if (status === 'ok') {
        this.$emit('resetGraph', graphIndex)
      }
    },
    async updateGraphTitle() {
      const { status, data } = await this.$modal.show(
        DataGraphUpdateTitleModal,
        {
          title: this.graphDetails.title
        }
      )
      if (status === 'ok' && data.title !== this.graphDetails.title) {
        this.setGraphTitle(data.title)
      }
    },
    changeGraphType(selection) {
      this.graphMap.doc.transact(() => {
        this.graphMap.set('type', selection)
        if (selection === 'bar') {
          this.graphMap
            .get('vColumns')
            .forEach(column => column.set('curve', 'none'))
        }
      })
    },
    toggleIncludeOrigin() {
      this.graphMap.set('includeOrigin', !this.graphDetails.includeOrigin)
    },
    toggleShowLegend() {
      this.graphMap.set('showLegend', !this.graphDetails.showLegend)
    },
    toggleSettings(event) {
      this.showSettings = !this.showSettings
    },
    hideSettings() {
      this.showSettings = false
      this.$refs.settingsToggle.focus()
    },
    changeDisplayCurveFitUncertainties(value) {
      this.graphMap.set('curveFitUncertainties', value)
    },
    markerSymbols(symbol = 0) {
      return Array(this.graphData.rows)
        .fill(symbol)
        .map((_, rowIndex) =>
          this.graphDetails.curveFitIgnoredRows?.includes(rowIndex) ? 4 : symbol
        )
    },
    computeGraphTitle() {
      if (this.hColumnsSet && this.vColumnsSet) {
        const vColumns = this.validVColumns
          .map(({ data }) => columnDisplayName(this.graphData.columns[data]))
          .join(', ')

        this.setGraphTitle(
          `${vColumns} vs ${columnDisplayName(
            this.graphData.columns[this.graphDetails.hColumns.data]
          )}`
        )
      }
    },
    setGraphTitle(title) {
      this.graphMap.set('title', title)
    },
    calcQuadrant(xColumn, yColumn) {
      const xAvg =
        xColumn
          .filter(d => d !== null && d !== '' && !isNaN(d))
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / xColumn.length
      const yAvg =
        yColumn
          .filter(d => d !== null && d !== '' && !isNaN(d))
          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / yColumn.length
      if (xAvg > 0) {
        if (yAvg > 0) {
          return 1
        } else return 4
      } else {
        if (yAvg > 0) {
          return 2
        } else return 3
      }
    },
    clearSelectedPoints() {
      this.graphMap.set('curveFitIgnoredRows', [])

      this.react()
    },
    onEscapeKey(event) {
      if (event.key === 'Escape') {
        this.hideSettings()
      }
    },
    handleClickOutsideSettings(event) {
      if (
        !this.$refs.settingsPanel?.$el.contains(event.target) &&
        this.showSettings
      ) {
        this.hideSettings()
      }
    }
  },
  mounted() {
    Plotly.newPlot(
      this.$refs.plotly,
      this.plotData,
      this.plotLayout,
      plotOptions
    )

    this.$refs.plotly.on('plotly_click', data => {
      const ignoredRows = this.graphDetails.curveFitIgnoredRows.slice()
      const onlyData = data.points.filter(
        point => point.data?.error_y?.type === 'data'
      )
      const index = ignoredRows.indexOf(onlyData[0].pointNumber)
      if (index > -1) {
        ignoredRows.splice(index, 1)
      } else {
        ignoredRows.push(onlyData[0].pointNumber)
      }
      this.graphMap.set('curveFitIgnoredRows', ignoredRows)
      this.react()
    })

    this.$refs.plotly.on('plotly_selected', eventData => {
      if (!eventData) return
      this.graphDetails.curveFitIgnoredRows = []
      const allPoints = Array(this.graphData.rows)
        .fill(null)
        .map((_, rowIndex) => rowIndex)
      const selectedPoints = eventData.points.map(point => point.pointNumber)
      this.graphDetails.curveFitIgnoredRows = allPoints.filter(
        p => !selectedPoints.includes(p)
      )

      this.graphMap.set(
        'curveFitIgnoredRows',
        this.graphDetails.curveFitIgnoredRows
      )
      this.react()
    })

    this.$refs.plotly.on('plotly_relayout', data => {
      const {
        'xaxis.range[0]': x0,
        'xaxis.range[1]': x1,
        'yaxis.range[0]': y0,
        'yaxis.range[1]': y1
      } = data

      if (typeof x0 === 'number' || typeof x1 === 'number') {
        this.graphMap.set('xAxisRange', [
          x0 ?? this.graphDetails.xAxisRange[0],
          x1 ?? this.graphDetails.xAxisRange[1]
        ])
        this.react()
      }
      if (typeof y0 === 'number' || typeof y1 === 'number') {
        this.graphMap.set('yAxisRange', [
          y0 ?? this.graphDetails.yAxisRange[0],
          y1 ?? this.graphDetails.yAxisRange[1]
        ])
        this.react()
      }
    })
  },

  watch: {
    ymap: {
      handler() {
        this.unsubscribe?.()
        const ymap = this.ymap
        const onChange = () => {
          // We have to wait a tick when the graph is deleted otherwise it is undefined for a second.
          this.$nextTick(() => {
            this.graphData = ymap.toJSON()
            this.graphMap = this.ymap.get('graphs').get(this.graphIndex)
            this.graphDetails = this.graphMap?.toJSON()
          })
        }
        this.graphData = ymap.toJSON()
        this.graphMap = this.ymap.get('graphs').get(this.graphIndex)
        this.graphDetails = this.graphMap?.toJSON()
        ymap.observeDeep(onChange)
        this.unsubscribe = () => ymap.unobserveDeep(onChange)
      },
      immediate: true
    },
    value(value) {
      this.graphData = cloneDeep(value)
    },
    graphData: {
      deep: true,
      handler() {
        this.react()
      }
    },
    'variableContext.variables': {
      deep: true,
      handler() {
        this.react()
      }
    },
    showSettings(newShowSettings, _, onCleanup) {
      if (newShowSettings) {
        window.addEventListener('click', this.handleClickOutsideSettings)
        onCleanup(() =>
          window.removeEventListener('click', this.handleClickOutsideSettings)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.graph-title-btn {
  background: transparent;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  &:hover {
    border-color: $dark-grey;
  }
}
.pi-data-graph {
  position: relative;
  overflow: hidden;
  padding: 10px 20px;

  &.nopanel {
    margin-top: 32px;
  }
  &.readonly {
    .axis p {
      color: $dark-grey;
    }
  }

  .graph-content {
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.2s linear;
  }

  .graph-wrapper {
    height: 450px;
    display: flex;

    .plotly-ref {
      flex: 1;
    }
  }

  .vertical-axis-wrapper {
    position: absolute;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: 400px;
  }

  .axis {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    &.horizontal {
      height: 41px;
    }

    &.vertical {
      width: 41px;
      position: relative;
      flex-shrink: 0;
    }

    p {
      font-size: 16px;
      font-weight: 700;
      padding: 0px 14px;
      margin: 0;
      color: $teal;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    button {
      display: block;
      padding: 0;
      width: auto;
      max-width: 100%;
      height: 41px;
      border: 1px solid transparent;
      background-color: transparent;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        border-color: $teal;
      }

      &:focus {
        border-color: $teal;
      }

      &:not(.unset) {
        p {
          color: $dark-grey;
        }

        &:hover {
          border-color: $dark-grey;
        }

        &:focus {
          border-color: $dark-grey;
        }
      }
    }
  }

  .title-bar {
    position: relative;
    display: flex;
    height: 42px;
    font-weight: bold;
    line-height: 30px;

    &.nopanel {
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 50px;
      line-height: inherit;
    }
    .graph-title {
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 15px;
    }

    .graph-actions {
      .select-all-btn {
        padding: 0 10px;
        margin-right: 5px;
      }
      .help-btn {
        padding: 0 10px;
        margin-right: 5px;
      }
      .settings-btn {
        background-color: $teal;
        border: $teal solid 1px;
        color: #ffffff;
        border-radius: 6px;
        padding: 0 8px;
        &:hover,
        &:focus {
          background-color: $darker-teal;
          border: $darker-teal solid 1px;
        }

        &:focus {
          box-shadow: $focus-shadow;
        }

        &--toggled {
          padding: 0 10px;
        }
      }
    }
  }

  .curve-fits {
    margin-top: 14px;
    padding: 0 50px;

    .curve-fit-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 14px;
    }
  }
}

:deep(.graph-actions) {
  position: absolute;
  top: 0;
  right: 0;
  transition: right 0.5s ease-out;
}
:deep(.graph-dropdown) {
  position: absolute;
  top: 0;
  right: 0;
}
:deep(.graph-actions--toggled) {
  right: 300px;
}
.collapse-button {
  padding: 0.5rem 1rem;
}
</style>
