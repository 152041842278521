<template>
  <modal class="wide-modal">
    <modal-header>{{ title }}</modal-header>
    <modal-body>
      <p v-if="date">{{ logDate }}</p>
      <pre>{{ text }}</pre>
    </modal-body>
    <modal-actions>
      <modal-button-cancel>Close</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import { format } from 'date-fns'

export default {
  name: 'TextLogModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonCancel
  },
  props: {
    title: {
      type: String,
      default: 'Log'
    },
    text: {
      type: String,
      default: ''
    },
    date: {
      type: Date
    }
  },
  computed: {
    logDate() {
      return format(new Date(this.date), 'MM-dd-yyyy hh:mm a')
    }
  }
}
</script>

<style scoped>
pre {
  max-height: 400px;
  white-space: normal;
  word-break: normal;
  word-wrap: normal;
  overflow: scroll;
}
</style>
