<template>
  <li>
    <sidebar-collapse :key="`statusCollapse`">
      <template #header> Status </template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('vetted')"
              @update:modelValue="value => changeCategory('vetted', value)"
              @click.stop
            >
              Vetted
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('unvetted')"
              @update:modelValue="value => changeCategory('unvetted', value)"
              @click.stop
            >
              Unvetted
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('prevetted')"
              @update:modelValue="value => changeCategory('prevetted', value)"
              @click.stop
            >
              Prevetted
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('rejected')"
              @update:modelValue="value => changeCategory('rejected', value)"
              @click.stop
            >
              Rejected
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'VetStatusFilter',
  inject: ['browseInstructors'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  data() {
    return {
      vetStatus: []
    }
  },
  created() {
    this.vetStatus = [...this.browseInstructors.query.vetStatus] || []
  },
  methods: {
    isChecked(t) {
      return this.vetStatus.includes(t)
    },
    changeCategory(category, isChecked) {
      if (!isChecked) {
        this.vetStatus = [...this.vetStatus].filter(t => t !== category)
      } else {
        this.vetStatus.push(category)
      }
      if (this.vetStatus.length) {
        return this.browseInstructors.updateQuery({
          page: 1,
          vetStatus: [...this.vetStatus]
        })
      }
      return this.browseInstructors.updateQuery({
        page: 1,
        vetStatus: 'all'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
