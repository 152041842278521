<template>
  <div>
    <div
      ref="input"
      class="credit-card-info pi-form-control form-control"
      :aria-describedby="`${helpId} ${ariaDescribedby}`"
    />
    <span v-if="errorMessage || helpText" :id="helpId" class="help-block">
      {{ errorMessage || helpText }}
    </span>
  </div>
</template>

<script>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
import stripe, { elements } from 'src/setup/stripe'

const counter = 0

export default {
  name: 'CreditCardInput',
  emits: ['blur', 'click', 'focus', 'ready', 'change'],
  props: {
    label: {
      type: String,
      default: 'credit-card'
    },
    name: {
      type: String,
      default: () => `credit-card-${counter}`
    },
    helpText: {
      type: String,
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField,
      setErrors
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: props.modelValue,
      validateOnValueUpdate: false,
      syncVModel: false
    })

    return {
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField,
      setErrors
    }
  },
  computed: {
    helpId() {
      return `${this.name}-help`
    }
  },
  mounted() {
    if (elements) {
      this.card = elements.create('card', { hidePostalCode: true })
      this.card.on('blur', e => {
        this.handleBlur()
        this.$emit('blur', e)
      })
      this.card.on('change', e => {
        if (e.error) {
          this.setErrors(e.error)
        } else {
          this.setErrors()
        }
        this.$emit('change', e)
      })
      this.card.on('click', e => this.$emit('click', e))
      this.card.on('focus', e => this.$emit('focus', e))
      this.card.on('ready', e => this.$emit('ready', e))
      this.card.mount(this.$refs.input)
    }
  },
  beforeUnmount() {
    if (this.card) {
      this.card.destroy()
    }
  },
  methods: {
    async createPaymentMethod(info = {}) {
      if (stripe) {
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          ...info
        })
        if (error) {
          this.setErrors(error)
        } else {
          return paymentMethod
        }
      } else {
        this.setErrors('Stripe is not enabled')
      }
    }
  }
}
</script>

<style lang="scss">
.credit-card-info {
  padding: 9px 12px;
}

.StripeElement--focus {
  border-color: #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
</style>
