<template>
  <a href="#" @click.prevent="doCopy">
    <template v-if="copySucceeded">Copied!</template>
    <slot v-else>{{ label }}</slot>
    <textarea :value="copyText" class="copy" readonly="false"> </textarea>
  </a>
</template>

<script>
export default {
  props: {
    copyText: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      copySucceeded: false
    }
  },
  computed: {
    canCopy() {
      return document.queryCommandSupported('copy')
    }
  },
  methods: {
    doCopy() {
      if (this.canCopy) {
        if (this.timeout) clearTimeout(this.timeout)
        window.getSelection().empty()
        this.$el.querySelector('.copy').select()
        this.copySucceeded = !!document.execCommand('copy')
        this.timeout = setTimeout(() => {
          this.copySucceeded = false
        }, 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  position: relative;
}
.copy {
  height: 1px;
  width: 1px;
  left: 0;
  top: 0;
  position: fixed;
  display: inline-block;
  background-color: transparent;
  border: none;
  resize: none;
  box-shadow: none;
  padding: 0px;
  &:active {
    box-shadow: none;
  }
}
</style>
