<template>
  <div class="activity-card-actions">
    <div class="activity-row__align-buttons">
      <button-dropdown v-if="licenseActive" secondary right up>
        <template #button>
          <icon icon="ellipsis-h" />
          <span class="sr-only">Activity Menu</span>
        </template>
        <dropdown-action @click="$emit('copy', activity)">
          Copy to Library
        </dropdown-action>
        <dropdown-action
          v-if="
            browseActivities.canManageLibraryActivities &&
            activity.status !== 'archived'
          "
          @click="$emit('move', activity)"
        >
          Move to Library
        </dropdown-action>
        <dropdown-action
          v-if="!activity.isArchived"
          @click="$emit('share', activity)"
        >
          <template v-if="!activity.isSharedWithCommunity">
            Share to Community Library
          </template>
          <template v-else> Remove from Community Library </template>
        </dropdown-action>
        <dropdown-action v-if="!activity.isArchived" @click="assign">
          Assign
        </dropdown-action>

        <dropdown-action
          v-if="!activity.isArchived"
          @click="$emit('archive', activity)"
        >
          Archive
        </dropdown-action>
        <dropdown-action v-else @click="$emit('unarchive', activity)">
          Unarchive
        </dropdown-action>
      </button-dropdown>
      <button-link
        :to="{ name: 'preview_activity', params: { id: activity.id } }"
        class="preview-btn"
        ref="popoverAnchorRef"
      >
        <template v-if="showVersionHistory">
          <base-popover
            :anchor-ref="popoverAnchorRef"
            :visible="popoverVisible"
            placement="top"
            >New Version Available
          </base-popover>
          <div
            class="version-icon"
            @mouseover="openPopover"
            @mouseout="closePopover"
            @focusin="openPopover"
            @focusout="closePopover"
          >
            <icon :icon="['far', 'lightbulb']" style="color: #000" />
          </div>
        </template>
        <template v-if="!showVersionHistory && showChanges">
          <base-popover
            :anchor-ref="popoverAnchorRef"
            :visible="popoverVisible"
            placement="top"
            >Updates Available
          </base-popover>
          <div
            class="version-icon"
            @mouseover="openPopover"
            @mouseout="closePopover"
            @focusin="openPopover"
            @focusout="closePopover"
          >
            <icon :icon="['far', 'lightbulb']" style="color: #000" />
          </div>
        </template>
        Preview
      </button-link>
    </div>

    <router-link
      v-if="canEdit"
      class="activity-card-actions__edit"
      :to="{ name: 'activity', params: { id: activity.id, tab: 'content' } }"
    >
      <icon icon="pencil" />
      Edit
    </router-link>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import ActivityVersionModal from 'src/modules/activities/components/ActivityVersionModal.vue'
import ActivityChangesModal from 'src/modules/activities/components/ActivityChangesModal.vue'

export default {
  name: 'ActivityRowEditAction',
  inject: ['browseActivities', 'activityRow', '$modal'],
  emits: ['copy', 'move', 'share', 'assign', 'archive', 'unarchive'],

  setup() {
    const store = useStore()
    const popoverAnchorRef = ref()
    const licenseActive = store.getters.isLicenseActive

    return {
      licenseActive,
      popoverAnchorRef
    }
  },
  data() {
    return {
      popoverVisible: false
    }
  },
  computed: {
    canEdit() {
      return this.licenseActive && !this.activity.isArchived
    },
    activity() {
      return this.activityRow.activity
    },
    showVersionHistory() {
      return this.activity.activityVersions?.length > 0
    },
    showChanges() {
      return this.activity.parentChanges?.length > 0
    }
  },
  methods: {
    async assign() {
      if (this.showVersionHistory) {
        const { status } = await this.$modal.show(ActivityVersionModal, {
          activityId: this.activity.id,
          activityName: this.activity.name,
          updatedActivity: this.activity.activityVersions[0],
          libraryId: this.activity.libraryId
        })
        if (status !== 'ok') {
          return
        }
      } else if (this.showChanges) {
        const { status } = await this.$modal.show(ActivityChangesModal, {
          activityId: this.activity.id,
          activityName: this.activity.name,
          changelogEntries: this.activity.parentChanges,
          libraryId: this.activity.libraryId,
          parentActivityId: this.activity.pivotParentId,
          hideAssign: false
        })
        if (status !== 'ok') {
          return
        }
      }
      this.$emit('assign', this.activity)
    },
    closePopover() {
      this.popoverVisible = false
    },
    openPopover() {
      this.popoverVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-card-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5px;

  & > :not(:first-child) {
    margin-left: 8px;
  }

  &__edit {
    padding: 4px 0 0 0;
  }

  &__spacer {
    flex-grow: 1;
    margin-right: 16px;
  }
}
.activity-row__align-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
}

.preview-btn {
  position: relative;
}
.version-icon {
  background: #efcc4f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
}
</style>
