<template>
  <li>
    <sidebar-collapse :key="`levelCollapse`">
      <template #header> Level </template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('high-school')"
              @update:modelValue="value => changeCategory('high-school', value)"
              @click.stop
            >
              6–12
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('college')"
              @update:modelValue="value => changeCategory('college', value)"
              @click.stop
            >
              College
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'LevelFilter',
  inject: ['browseLicenses'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  data() {
    return {
      level: 'all'
    }
  },
  created() {
    this.level = this.browseLicenses.query.level || 'all'
  },
  methods: {
    isChecked(t) {
      return this.level === t
    },
    changeCategory(category, isChecked) {
      if (!isChecked) {
        this.level = 'all'
        this.browseLicenses.updateQuery({ page: 1, level: 'all' })
        return
      }
      this.level = category
      this.browseLicenses.updateQuery({ page: 1, level: this.level })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
