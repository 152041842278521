<template>
  <div v-if="!isLoading" class="browse-summary">
    Showing {{ activityPageSize }} of {{ activityCount }} results
  </div>
</template>

<script>
export default {
  name: 'BrowseSummary',
  inject: ['browseActivities'],
  computed: {
    activityPageSize() {
      return this.browseActivities.activities.length
    },
    activityCount() {
      return this.browseActivities.activityCount
    },
    isLoading() {
      return this.browseActivities.isLoadingActivities
    }
  }
}
</script>

<style lang="scss" scoped>
.browse-summary {
  margin: 16px 0;
  font-weight: bold;
  font-size: 18px;
}
</style>
