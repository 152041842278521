import StudentResponseFeatureFlaggedView from 'src/modules/activities/views/StudentResponseFeatureFlaggedView'
import IntermediatePreviewView from 'src/modules/activities/views/IntermediatePreviewView'
import AssignmentPreviewView from 'src/modules/activities/views/AssignmentPreviewView'
import GradeByResponseView from 'src/modules/activities/views/GradeByResponseView'
function parseBoolean(value) {
  switch (value) {
    case true:
    case 'true':
      return true
    case false:
    case 'false':
      return false
  }
}

export default store => [
  {
    name: 'preview_activity',
    path: '/activities/:id/preview',
    component: IntermediatePreviewView,
    props: route => ({
      preview: route.meta.preview,
      id: route.params.id
    }),
    meta: { title: 'Preview Activity', preview: true }
  },
  {
    name: 'preview_assignment',
    path: '/assignments/:id/preview',
    component: AssignmentPreviewView,
    props: true,
    meta: { title: 'Preview Assignment', preview: true, requiresAuth: true }
  },
  {
    name: 'student_response',
    path: '/assignments/:id/responses',
    component: StudentResponseFeatureFlaggedView,
    props: to => ({
      id: to.params.id,
      query: {
        groups: parseBoolean(to.query.groups)
      }
    }),
    meta: { title: 'Student Response', requiresAuth: true }
  },
  {
    name: 'grade_response',
    path: '/assignments/:assignmentId/responses/:responseId',
    component: GradeByResponseView,
    props: route => ({
      ...route.params,
      ...(route.query['sort'] && {
        sort: {
          field: route.query['sort'],
          dir: route.query['dir']
        }
      })
    }),
    meta: { title: 'Grade Response', requiresAuth: true }
  }
]
