<template>
  <div class="button-container">
    <slot name="button" />
    <button-dropdown v-bind="$props" button-class="split">
      <template #button>
        <icon :icon="up ? 'caret-up' : 'caret-down'" />
        <span class="sr-only">Split Button Dropdown</span>
      </template>
      <slot name="actions" />
    </button-dropdown>
  </div>
</template>

<script>
export default {
  name: 'SplitButtonDropdown',
  props: {
    right: {
      type: Boolean,
      default: false
    },
    up: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
div.button-container {
  display: flex;
  flex-direction: row;
}
div.button-container > button.form-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
