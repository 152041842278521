<template>
  <div v-if="noFeedback">{{ showFeedbackStatus }}</div>
  <div v-if="showComments" class="question-comments">
    <label class="comments-label">Comments:</label>
    <div v-if="grading">{{ showFeedbackStatus }}</div>
    <editor-content
      v-if="readonly"
      class="activity-question-comments--readonly"
      :text="value"
      :view-as-student="viewAsStudent"
    />
    <editor v-else v-model:text="value" />
  </div>
</template>

<script>
import * as Y from 'yjs'
import { hasContent } from 'src/shared/components/editor/utils.js'

export default {
  name: 'ActivityQuestionCommentsRealtime',
  inject: ['inherited'],
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    grading: {
      type: Boolean,
      default: false
    },
    component: {
      type: Object,
      required: true
    },
    componentResponse: {
      type: Y.Map,
      required: true
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      comments: this.componentResponse.get('comments')
    }
  },
  beforeUnmount() {
    this.unsubscribe?.()
  },
  computed: {
    showComments() {
      const showFeedback =
        this.inherited?.response?.assignment?.showFeedback === undefined ||
        this.inherited?.response?.assignment?.showFeedback === true
      const isQuestion = this.component.componentType.includes('Question')
      const isGrading = !this.readonly
      const hasComments = !!this.comments && hasContent(this.comments)

      return isQuestion && (isGrading || (showFeedback && hasComments))
    },
    noFeedback() {
      return (
        this.grading &&
        this.inherited?.response?.assignment?.showFeedback === 'never'
      )
    },

    showFeedbackStatus() {
      switch (this.inherited?.response?.assignment?.showFeedback) {
        case 'never':
          return 'Because this assignment is set not to show student feedback, you cannot give feedback on this question.'
        case 'after-final-grade':
          return 'Because this assignment is set to not show feedback until after marked final grade, students will not see this feedback until after marked final grade.'
        case 'after-final-submission':
          return 'Because this assignment is set to not show feedback until the assignment is submitted, students will not see this feedback until after assignment is submitted.'
        default: // by default show no additional text because this was the previous default behavior
          return ''
      }
    },
    value: {
      get() {
        return this.comments
      },
      set(comments) {
        this.componentResponse.set('comments', comments)
      }
    }
  },
  watch: {
    componentResponse: {
      immediate: true,
      handler(val) {
        this.unsubscribe?.()

        const changeHandler = event => {
          this.comments = val.get('comments')
        }
        val.observe(changeHandler)
        changeHandler()

        this.unsubscribe = () => val.unobserve(changeHandler)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-question-comments {
  &--readonly {
    border: #ccc solid 1px;
    border-radius: 8px;
    padding: 10px 15px;
  }
}
</style>
