<template>
  <div v-if="showCorrectAnswer" class="grouped-graph">
    <div
      class="graph-data student-response"
      ref="popoverAnchorRef"
      @mouseover="openPopover"
      @mouseout="closePopover"
      @focusin="openPopover"
      @focusout="closePopover"
    >
      <span class="answer-heading">Student Response</span>
      <data-grid-pojo-adapter
        :settings="component.settings"
        :graphInstructions="component.graphInstructions"
        v-model="value"
        :graph="component.componentType === 'GridGraphQuestion'"
        :readonly="(!canRespond || readonly) && !grading"
        :show-grid-actions-menu="showGridActionsMenu"
        :allow-download-csv="allowDownloadCsv"
        :variable-context="variableContext"
        :allow-reset="allowReset"
        @reset="onReset"
        :view-as-student="viewAsStudent"
      />
    </div>
    <base-popover
      :anchor-ref="popoverAnchorRef"
      :visible="studentResponsePopoverMessage && popoverVisible"
      placement="bottom"
    >
      {{ studentResponsePopoverMessage }}
    </base-popover>

    <dependency-popover
      v-if="dependencyVisible"
      :anchor-ref="popoverAnchorRef"
      :grading="grading"
    >
    </dependency-popover>

    <div class="graph-data correct-answer">
      <span class="answer-heading">Correct Answer</span>
      <data-grid-pojo-adapter
        :settings="component.settings"
        :modelValue="correctAnswer"
        :graph="component.componentType === 'GridGraphQuestion'"
        :readonly="true"
        :show-grid-actions-menu="showGridActionsMenu"
        :variable-context="variableContext"
        :allow-download-csv="allowDownloadCsv"
        :graphInstructions="component.graphInstructions"
      />
    </div>
  </div>
  <div v-else>
    <div
      class="graph-data"
      ref="popoverAnchorRef"
      @mouseover="openPopover"
      @mouseout="closePopover"
      @focusin="openPopover"
      @focusout="closePopover"
    >
      <data-grid-pojo-adapter
        :settings="component.settings"
        v-model="value"
        :component-id="component._id"
        :graph="component.componentType === 'GridGraphQuestion'"
        :readonly="(!canRespond || readonly) && !grading"
        :show-grid-actions-menu="showGridActionsMenu"
        :allow-download-csv="allowDownloadCsv"
        :allow-reset="allowReset"
        @reset="onReset"
        :graphInstructions="component.graphInstructions"
        :variable-context="variableContext"
        :graphHint="component.graphHint"
        :graphHintPrompt="component.graphHintPrompt"
        :hideGraphHint="component.hideGraphHint"
        :view-as-student="viewAsStudent"
      />
    </div>

    <base-popover
      :anchor-ref="popoverAnchorRef"
      :visible="studentResponsePopoverMessage && popoverVisible"
      placement="bottom"
    >
      {{ studentResponsePopoverMessage }}
    </base-popover>

    <dependency-popover
      v-if="dependencyVisible"
      :anchor-ref="popoverAnchorRef"
      :grading="grading"
    >
    </dependency-popover>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapGetters } from 'vuex'
import DataGridPojoAdapter from 'src/shared/components/grid-graph/DataGridPojoAdapter'
import DependencyPopover from './DependencyPopover'
import {
  defaultGridValue,
  defaultGraphValue,
  convertDataFormat,
  gridHasData
} from 'src/shared/components/grid-graph/utilities'

export default {
  name: 'ActivityResponseGridGraph',
  inject: ['inherited'],
  components: {
    DataGridPojoAdapter,
    DependencyPopover
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    grading: {
      type: Boolean,
      default: false
    },
    showGridActionsMenu: {
      type: Boolean,
      default: true
    },
    allowDownloadCsv: {
      type: Boolean,
      default: true
    },
    allowReset: {
      type: Boolean,
      default: false
    },
    showCorrectAnswer: {
      type: Boolean,
      default: false,
      required: false
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    },
    graphHint: {
      type: String,
      default: undefined,
      required: false
    },
    graphHintPrompt: {
      type: String,
      default: undefined,
      required: false
    },
    hideGraphHint: {
      type: Boolean,
      default: false,
      required: false
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverVisible: false,
      dependencyVisible: false
    }
  },
  setup() {
    const popoverAnchorRef = ref()
    return { popoverAnchorRef }
  },
  computed: {
    ...mapGetters(['isStudent']),

    correctAnswer() {
      const answer = this.component.answer
        ? JSON.parse(this.component.answer)
        : this.defaultValue
      return convertDataFormat(answer)
    },
    component() {
      return this.inherited.component
    },
    componentResponse() {
      return this.inherited.componentResponse
    },
    canRespond() {
      return this.inherited.canRespond
    },
    studentResponsePopoverMessage() {
      return this.inherited.studentResponsePopoverMessage
    },
    defaultValue() {
      if (this.component.componentType === 'GridGraphQuestion') {
        return { ...defaultGridValue(), ...defaultGraphValue() }
      } else {
        return { ...defaultGridValue() }
      }
    },
    section() {
      return this.inherited.section
    },
    value: {
      get() {
        const value =
          this.componentResponse.value ||
          (this.component.defaultValue
            ? JSON.parse(this.component.defaultValue)
            : undefined) ||
          this.defaultValue
        return convertDataFormat(value)
      },
      set(value) {
        if (this.canRespond && !this.readonly) {
          this.inherited.updateResponse({ value })
          this.$emit('canSubmit', gridHasData(value))
        }
      }
    },
    gridGraphComponent() {
      return this.component.componentType === 'GridGraphQuestion'
        ? 'data-grid-with-graph'
        : 'data-grid'
    }
  },
  mounted() {
    this.$emit('canSubmit', gridHasData(this.value))
  },

  methods: {
    closePopover() {
      this.dependencyVisible = false
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = false
      }
    },
    openPopover() {
      this.dependencyVisible = true
      if (!this.canRespond && this.isStudent) {
        this.popoverVisible = true
      }
    },
    onReset() {
      if (this.canRespond && !this.readonly) {
        try {
          const value = this.component.defaultValue
            ? JSON.parse(this.component.defaultValue)
            : this.defaultValue

          this.inherited.updateResponse({ value })
        } catch (e) {
          this.$error(e.message)
          throw e
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grouped-graph {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  @media (min-width: $screen-md) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    flex-direction: row;

    & .graph-data {
      width: 48%;
    }
  }

  & .answer-heading {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
