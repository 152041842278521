<template>
  <div v-if="noFeedback">{{ showFeedbackStatus }}</div>
  <div v-if="showComments" class="question-comments">
    <label :id="`component-${component._id}`" class="comments-label"
      >Comments:</label
    >
    <div v-if="grading">{{ showFeedbackStatus }}</div>
    <playback v-if="readonly" :element="comments" class="playback_btn" />
    <div ref="comments">
      <editor-content
        v-if="readonly"
        class="activity-question-comments--readonly"
        :text="value"
        :aria-labelledby="`component-${component._id}`"
        :view-as-student="viewAsStudent"
      />

      <editor
        :aria-labelledby="`component-${component._id}`"
        v-else
        v-model:text="value"
      />
    </div>
  </div>
</template>

<script setup>
import { hasContent } from 'src/shared/components/editor/utils.js'
import { computed, ref, inject } from 'vue'
const props = defineProps({
  readonly: {
    type: Boolean,
    default: false
  },
  grading: {
    type: Boolean,
    default: false
  },
  viewAsStudent: {
    type: Boolean,
    default: false
  }
})
const comments = ref()
const inherited = inject('inherited')

const variables = computed(() => {
  return inherited.value.activity.variables
})
const component = computed(() => {
  return inherited.value.component
})
const componentResponse = computed(() => {
  return inherited.value.componentResponse
})

const showFeedback = computed(() => {
  if (
    inherited.value.response?.assignment?.showFeedback === true ||
    inherited.value.response?.assignment?.showFeedback === undefined
  ) {
    return true
  } else {
    return false
  }
})

const showComments = computed(() => {
  return (
    component.value &&
    component.value.componentType.includes('Question') &&
    (!props.readonly || hasContent(componentResponse.value.comments)) &&
    showFeedback.value
  )
})

const showFeedbackStatus = computed(() => {
  switch (inherited.value.response?.assignment?.studentFeedbackTiming) {
    case 'never':
      return 'Because this assignment is set not to show student feedback, you cannot give feedback on this question.'
    case 'after-final-grade':
      return 'Because this assignment is set to not show feedback until after marked final grade, students will not see this feedback until after marked final grade.'
    case 'after-final-submission':
      return 'Because this assignment is set to not show feedback until the assignment is submitted, students will not see this feedback until after assignment is submitted.'
    default: // by default show no additional text because this was the previous default behavior
      return ''
  }
})

const noFeedback = computed(() => {
  return (
    props.grading &&
    inherited.value.response?.assignment?.studentFeedbackTiming === 'never'
  )
})

const value = computed({
  get() {
    return componentResponse.value.comments
  },
  set(comments) {
    inherited.value.updateResponse({ comments })
  }
})
</script>

<style lang="scss" scoped>
.activity-question-comments {
  &--readonly {
    border: #ccc solid 1px;
    border-radius: 8px;
    padding: 10px 15px;
  }
}

.playback_btn {
  float: left;
  top: 44px;
  margin-left: -25px;
}
</style>
