<template>
  <base-popover
    :anchor-ref="anchorRef"
    :visible="canShowDependency"
    placement="bottom"
  >
    <b>
      You need to answer the following
      {{ dependencyCount === 1 ? 'question' : `${dependencyCount} questions` }}
      correctly before you can answer this one:
    </b>
    <ol class="dep-question">
      <li
        v-for="(dependency, index) in dependencies ?? []"
        :key="dependency?.id ?? index"
      >
        <template v-if="dependency">
          <b>Section: </b>
          {{ dependency.sectionName }}
          <b>Question: </b>
          {{ dependency.questionNumber }}
        </template>
        <template v-else>Unknown</template>
      </li>
    </ol>
  </base-popover>
</template>

<script>
import BasePopover from 'src/shared/components/global/BasePopover.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DependencyPopover',
  inject: ['inherited'],
  components: {
    BasePopover
  },
  props: {
    anchorRef: {
      type: [HTMLElement, Object]
    },
    grading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['isStudent']),
    activity() {
      return this.inherited.activity
    },
    component() {
      return this.inherited.component
    },
    componentResponse() {
      return this.inherited.componentResponse
    },
    response() {
      return this.inherited.response
    },
    section() {
      return this.inherited.section
    },
    isOverdue() {
      return this.inherited.isOverdue
    },

    isSubmitted() {
      return this.inherited.isSubmitted
    },
    canShowDependency() {
      return (
        !this.grading &&
        !this.isSubmitted &&
        !this.isOverdue &&
        !this.section.isLocked &&
        this.section.isAvailable &&
        !this.dependenciesSatisfied
      )
    },

    dependenciesSatisfied() {
      return (
        this.inherited.component.dependenciesSatisfied ||
        this.inherited.componentResponse.dependenciesSatisfied
      )
    },

    dependencies() {
      let dependencies =
        (this.inherited.component &&
          this.inherited.component.componentDependenciesRemaining) ??
        (this.inherited.componentResponse &&
          this.inherited.componentResponse.componentDependenciesRemaining) ??
        []

      let depswithSections = dependencies.map(dependency => {
        if (dependency.sectionName !== undefined) {
          return dependency
        }

        this.activity.sections.forEach((section, index) => {
          for (let component of section.components) {
            if (component._id === dependency.id) {
              dependency.sectionName = index + 1
              break
            }
          }
        })
        return dependency
      })
      return depswithSections
    },

    dependencyCount() {
      const dependencies =
        (this.inherited.component &&
          this.inherited.component.componentDependenciesRemaining) ??
        (this.inherited.componentResponse &&
          this.inherited.componentResponse.componentDependenciesRemaining) ??
        []
      return dependencies.length
    }
  },
  data: function () {
    return {
      showDeps: true
    }
  },
  methods: {
    popoverDependencyCheck() {
      this.showDeps = true
    }
  }
}
</script>
<style lang="scss" scoped>
.dep-question {
  display: inline-block;
  vertical-align: bottom;
  white-space: normal;
  padding-right: 5px;
}
</style>
