<template>
  <div class="activity-card-actions">
    <div class="activity-row__align-buttons">
      <form-button class="preview-btn" ref="popoverAnchorRef" @click="assign">
        <template v-if="showVersionHistory">
          <base-popover
            :anchor-ref="popoverAnchorRef"
            :visible="popoverVisible"
            placement="top"
            >New Version Available
          </base-popover>
          <div
            class="version-icon"
            @mouseover="openPopover"
            @mouseout="closePopover"
            @focusin="openPopover"
            @focusout="closePopover"
          >
            <icon :icon="['far', 'lightbulb']" style="color: #000" />
          </div>
        </template>
        <template v-if="!showVersionHistory && showChanges">
          <base-popover
            :anchor-ref="popoverAnchorRef"
            :visible="popoverVisible"
            placement="top"
            >Updates Available
          </base-popover>
          <div
            class="version-icon"
            @mouseover="openPopover"
            @mouseout="closePopover"
            @focusin="openPopover"
            @focusout="closePopover"
          >
            <icon :icon="['far', 'lightbulb']" style="color: #000" />
          </div>
        </template>
        <span v-if="copyFirst">Copy &amp; </span>Assign
      </form-button>
      <button-link
        :to="{ name: 'preview_activity', params: { id: activity?.id } }"
        v-if="!openInNewTab"
        secondary
      >
        Preview
      </button-link>
      <UIButton
        v-if="openInNewTab"
        type="link"
        @click="confirmPreview"
        class="w-24"
      >
        Preview
        <UIIcon icon="external-link" class="ml-1" />
      </UIButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue'
import ActivityVersionModal from 'src/modules/activities/components/ActivityVersionModal.vue'
import ActivityChangesModal from 'src/modules/activities/components/ActivityChangesModal.vue'
import PreviewInNewTabModal from 'src/modules/libraries/components/PreviewInNewTabModal.vue'
import CopyActivityToLibraryModal from 'src/shared/components/modals/CopyActivityToLibraryModal.vue'
import { LibraryActivityView } from '@pi/api-types'
import { UIButton, UIIcon } from '@pi/design'
import { useRouter } from 'vue-router'

interface ActivityRow {
  activity: LibraryActivityView
}
interface AssignActionProps {
  openInNewTab?: boolean
}
const router = useRouter()
const props = defineProps<AssignActionProps>()
const popoverAnchorRef = ref()
const emit = defineEmits(['assign'])
const modal = inject<any>('$modal')
const activityRow = inject<Ref<ActivityRow>>('activityRow')
const popoverVisible = ref<boolean>(false)
const activity = computed(() => activityRow?.value.activity)
const showVersionHistory = computed(
  () =>
    activity.value?.activityVersions &&
    activity.value?.activityVersions.length > 0
)
const showChanges = computed(
  () =>
    activity.value?.parentChanges && activity.value?.parentChanges.length > 0
)

const copyFirst = computed(() => activity.value?.libraryId === 'pivot')

const confirmPreview = async () => {
  const { status } = await modal.show(PreviewInNewTabModal, {
    activityId: activity.value?.id,
    activityName: activity.value?.name
  })
  if (status !== 'ok') return

  const routeData = router.resolve({
    name: 'preview_activity',
    params: { id: activity.value?.id }
  })
  window.open(routeData.href, '_blank')
}

const copyActivity = async () => {
  const { status, data } = await modal.show(CopyActivityToLibraryModal, {
    activity: {
      ...activity.value
    }
  })
  if (status === 'ok') {
    emit('assign', data.activityId)
  }
}
const assign = async () => {
  if (copyFirst.value) {
    return copyActivity()
  }
  if (showVersionHistory.value) {
    const { status } = await modal.show(ActivityVersionModal, {
      activityId: activity.value?.id,
      activityName: activity.value?.name,
      updatedActivity: activity.value?.activityVersions?.shift(),
      libraryId: activity.value?.libraryId
    })
    if (status !== 'ok') {
      return
    }
  } else if (showChanges.value) {
    const { status } = await modal.show(ActivityChangesModal, {
      activityId: activity.value?.id,
      activityName: activity.value?.name,
      changelogEntries: activity.value?.parentChanges,
      libraryId: activity.value?.libraryId,
      parentActivityId: activity.value?.pivotParentId,
      hideAssign: false
    })
    if (status !== 'ok') {
      return
    }
  }
  emit('assign', activity.value?.id)
}
const closePopover = () => {
  popoverVisible.value = false
}
const openPopover = () => {
  popoverVisible.value = true
}
</script>

<style lang="scss" scoped>
.activity-card-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5px;

  & > :not(:first-child) {
    margin-left: 8px;
  }

  &__edit {
    padding: 4px 0 0 0;
  }

  &__spacer {
    flex-grow: 1;
    margin-right: 16px;
  }
}
.activity-row__align-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.preview-btn {
  position: relative;
}
.version-icon {
  background: #efcc4f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
}
</style>
