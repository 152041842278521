<template>
  <span class="info-tab">
    <progress-element element-id="basic" title="Basic Info">
      <activity-basic-info-section />
    </progress-element>
    <progress-element element-id="tags" title="Tags & Categories">
      <activity-tags-section />
    </progress-element>
    <progress-element element-id="notes" title="Notes & Instructions">
      <activity-notes-section :variable-context="variableContext" />
    </progress-element>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import ActivityBasicInfoSection from './ActivityBasicInfoSection.vue'
import ActivityNotesSection from './ActivityNotesSection.vue'
import ActivityTagsSection from './ActivityTagsSection.vue'

export default {
  name: 'ActivityInfoTab',
  inject: ['inherited'],
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  components: {
    ActivityBasicInfoSection,
    ActivityTagsSection,
    ActivityNotesSection
  },
  computed: {
    ...mapGetters(['canManageLibrary', 'canManageFreeActivities']),
    activity() {
      return this.inherited.activity
    }
  }
}
</script>

<style lang="scss" scoped>
.info-tab {
  :deep(.activity-inner-panel) {
    display: flex;
    justify-content: space-between;

    & .left-panel-side {
      width: 50%;
      margin-right: 20px;

      & .activity-input-group {
        margin-bottom: 15px;
      }
    }

    & .right-panel-side {
      width: 50%;
      margin-left: 20px;
    }
  }
}
</style>
