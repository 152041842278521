import {
  createRouter,
  createWebHistory,
  isNavigationFailure,
  NavigationFailureType
} from 'vue-router'
import { trackPageChanges } from '../analytics'
import dashboardRoutes from 'src/modules/dashboard/routes'
import activityRoutes from 'src/modules/activities/routes'
import assignmentRoutes from 'src/modules/assignments/routes'
import activityEditorRoutes from 'src/modules/activity-editor/routes'
import libraryRoutes from 'src/modules/libraries/routes'
import adminRoutes from 'src/modules/admin/routes'
import ltiRoutes from 'src/modules/lti/routes'
import purchaseRoutes from 'src/modules/purchases/routes'
import authRoutes from 'src/modules/auth/routes'
import classRoutes from 'src/modules/classes/routes'
import licenseRoutes from 'src/modules/licenses/routes'

import NotFoundView from './NotFoundView'
import qs from 'qs'

const excludedRefreshRouteNames = [
  ...purchaseRoutes(null).map(obj => obj.name),
  'grade_response'
]

let router

function preserveFeatureFlagsQuery(to, from, next) {
  if (to.query.ff || !from.query.ff) {
    next()
    return
  }

  next({
    ...to,
    query: {
      ...to.query,
      ff: from.query.ff
    }
  })
}

function overrideNavigation(router, name) {
  const originalFn = router[name]
  router[name] = function push(arg, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalFn.call(this, arg, onResolve, onReject)
    }
    return originalFn.call(this, arg).catch(err => {
      if (err && !isNavigationFailure(err, NavigationFailureType.redirected)) {
        // If there really is an error, throw it
        return Promise.reject(err)
      }
      // Otherwise resolve to false to indicate the original push call didn't go to its original destination.
      return Promise.resolve(false)
    })
  }
}

export const createAppRouter = store => {
  const hasAuth = () => store.state.auth.hasAuth
  const isAuthenticated = () => store.getters.isAuthenticated
  const isLicenseActive = () => store.getters.isLicenseActive
  const matchesRole = roles => roles.includes(store.state.auth.user?.role)
  const isAdmin = () => store.getters.isAdmin
  const isSalesAdmin = () => store.getters.isSalesAdmin
  const isStudent = () => store.getters.isStudent
  const isGrader = () => store.getters.isGrader
  const isTeacher = () => store.getters.isTeacher
  const isContentDeveloper = () => store.getters.isContentDeveloper

  router = createRouter({
    history: createWebHistory(),
    parseQuery: qs.parse,
    stringifyQuery: obj => {
      return qs.stringify(obj, {
        encode: true,
        indices: false
      })
    },
    routes: [
      ...licenseRoutes(store),
      ...adminRoutes(store),
      ...ltiRoutes(store),
      ...purchaseRoutes(store),
      ...authRoutes(store),
      ...classRoutes(store),
      ...activityRoutes(store),
      ...activityEditorRoutes(store),
      ...libraryRoutes(store),
      ...assignmentRoutes(store),
      ...dashboardRoutes(store),
      {
        name: 'home',
        path: '/',
        beforeEnter: (to, from, next) => {
          const query = to.query.flash ? { flash: to.query.flash } : {}
          if (isAdmin() || isSalesAdmin()) {
            next({ name: 'admin_manage_users', query })
          } else if (isStudent()) {
            next({ name: 'classes', query })
          } else if (isGrader()) {
            next({ name: 'classes', query })
          } else if (isTeacher()) {
            next({ name: 'teacher_dashboard', query })
          } else if (isContentDeveloper() || isAdmin()) {
            next({ name: 'activities', query })
          } else if (PI_ENV === 'production') {
            location.href = 'https://www.pivotinteractives.com'
          } else {
            next({ name: 'login' })
          }
        }
      },
      {
        name: 'not_found',
        path: '/not-found',
        component: NotFoundView,
        meta: { title: 'Not found' }
      },
      {
        path: '/:pathMatch(.*)',
        redirect: { name: 'not_found' }
      }
    ]
  })

  overrideNavigation(router, 'push')
  overrideNavigation(router, 'replace')

  const requiresLicenseNextRoute = function (to, from, next) {
    if (
      isLicenseActive() ||
      isAdmin() ||
      isContentDeveloper() ||
      isSalesAdmin()
    ) {
      preserveFeatureFlagsQuery(to, from, next)
      next(
        to.meta.redirect ?? {
          name: 'login',
          query: {
            redirect: to.fullPath
          }
        }
      )
    } else {
      next(to.meta.redirect ?? { name: 'home' })
    }
  }

  const requiresAuthNextRoute = function (to, from, next) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isAuthenticated()) {
      if (to.meta.roles?.length > 0) {
        if (matchesRole(to.meta.roles)) {
          preserveFeatureFlagsQuery(to, from, next)
        } else {
          next(to.meta.redirect ?? { name: 'home' })
        }
      } else {
        preserveFeatureFlagsQuery(to, from, next)
      }
    } else {
      next(
        to.meta.redirect ?? {
          name: 'login',
          query: {
            redirect: to.fullPath
          }
        }
      )
    }
  }

  const checksAuthNextRoute = function (to, from, next) {
    // this route does not require auth, check if logged in
    // if so, redirect to home page.
    if (isAuthenticated()) {
      if (to.meta.redirect) {
        next(to.meta.redirect)
      } else {
        next({
          name: 'home',
          query: {
            ff: from.query.ff
          }
        })
      }
    } else {
      preserveFeatureFlagsQuery(to, from, next)
    }
  }

  router.beforeEach(async (to, from, next) => {
    if (!hasAuth()) {
      await store.dispatch('initAuth')
    }
    if (to.matched.some(record => typeof record.meta === 'function')) {
      const matched = to.matched.filter(
        record => typeof record.meta === 'function'
      )
      to.meta = evaluateMeta(to, matched[0].meta)
    }
    if (
      to.matched.some(record => evaluateMeta(to, record.meta).requiresLicense)
    ) {
      requiresLicenseNextRoute(to, from, next)
    }
    if (to.matched.some(record => evaluateMeta(to, record.meta).requiresAuth)) {
      requiresAuthNextRoute(to, from, next)
    } else if (
      to.matched.some(record => evaluateMeta(to, record.meta).checksAuth)
    ) {
      checksAuthNextRoute(to, from, next)
    } else {
      preserveFeatureFlagsQuery(to, from, next)
    }
  })

  router.afterEach(async (to, from) => {
    if (to.meta.title) {
      document.title = to.meta.title + ' | Pivot Interactives'
    }

    // Because all views are nested inside #scroll-container with overflow scrolling,
    // scrollBehavior on the router doesn't work. Scroll the container manually instead.
    const scrollContainer = document.getElementById('scroll-container')
    if (scrollContainer && scrollContainer.scrollTo) {
      scrollContainer.scrollTo(0, 0)
    }

    // if we are on any of the purchase routes, do not check version
    if (excludedRefreshRouteNames.includes(to.name)) {
      return
    }
    await store.dispatch('checkVersion')
  })

  trackPageChanges(router)

  return router
}

const evaluateMeta = (to, meta) => {
  if (typeof meta === 'function') {
    const metaObj = meta(to)
    return metaObj
  }
  return meta
}

export const getPathByRouteName = name => {
  const route = router.options.routes.find(route => route.name === name)
  const path = (route || {}).path

  if (router.mode === 'hash') {
    return '/#' + path
  }
  return path
}
