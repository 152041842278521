import ActivityView from './views/ActivityView'

export default store => [
  {
    name: 'new_library_activity',
    path: '/libraries/:libraryId/activities/new/:tab?',
    component: ActivityView,
    props: route => ({
      libraryId: route.params.libraryId,
      tab: route.params.tab || 'info'
    }),
    meta: { requiresAuth: true, requiresLicense: true }
  },
  {
    name: 'activity',
    path: '/activities/:id/:tab?',
    component: ActivityView,
    meta: { title: 'Activity', requiresAuth: true, requiresLicense: true },
    props: route => ({
      id: route.params.id,
      tab: route.params.tab || 'info'
    })
  }
]
