<template>
  <div>
    <router-view />
    <router-view v-if="isAuthenticated" name="authenticated" />
    <router-view v-else name="unauthenticated" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
}
</script>
