<template>
  <div class="activity-sort">
    <span>Sort By</span>
    <select-field
      :modelValue="sortOrder"
      aria-label="Sort Order"
      @update:modelValue="sort"
    >
      <option value="relevance">Relevance</option>
      <option value="recent">Newest to Oldest</option>
      <option value="old">Oldest to Newest</option>
      <option value="alpha_asc">Alphabetical A-Z</option>
      <option value="alpha_desc">Alphabetical Z-A</option>
    </select-field>
  </div>
</template>

<script>
export default {
  name: 'ActivitySortAction',
  inject: ['browseActivities'],
  computed: {
    prop() {
      return this.browseActivities.query.sort
    },
    dir() {
      return this.browseActivities.query.dir
    },
    sortOrder() {
      const sortObj = Object.entries(this.getSortObj())
      for (const [key, value] of sortObj) {
        if (value.prop === this.prop && value.dir === this.dir) {
          return key
        }
      }
      return 'recent'
    }
  },
  methods: {
    sort(newSortValue) {
      const { prop, dir } = this.getSortObj()[newSortValue]

      this.browseActivities.updateQuery({
        sort: prop,
        dir,
        page: 1
      })
    },

    getSortObj() {
      return {
        recent: { prop: 'updatedAt', dir: 'desc' },
        old: { prop: 'updatedAt', dir: 'asc' },
        alpha_asc: { prop: 'name', dir: 'asc' },
        alpha_desc: { prop: 'name', dir: 'desc' },
        relevance: { prop: 'relevance', dir: 'desc' }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-sort {
  display: flex;
  height: 38px;
  align-items: baseline;
  margin: 16px 0;
  font-size: 16px;

  & :deep(select) {
    font-size: 16px;
  }

  & span {
    margin-right: 5px;
    white-space: nowrap;
  }
  & > :not(:last-child) {
    margin-right: 8px;
  }
}
</style>
