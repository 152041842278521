<template>
  <collapse-provider class="activity-group" role="group" :aria-label="name">
    <form-group class="group-title-count">
      <span class="group-name">{{ name }}</span>
      <div class="group-count">
        <span class="sr-only">Group size: {{ group.students.length }}</span>
        <span aria-hidden="true">
          ({{ group.students.length }} {{ studentsLabel }})
        </span>
      </div>
      <collapse-toggle v-slot="{ collapsed }" link class="toggle-btn">
        {{ collapsed ? 'Show' : 'Hide' }}
      </collapse-toggle>
    </form-group>
    <div class="break" />
    <collapse-content>
      <div class="selected-groups">
        <draggable
          v-if="!disabled"
          v-model="students"
          group="groups"
          item-key="_id"
        >
          <template #item="{ element, index }">
            <chip
              :index="index"
              :label="`${element.firstName} ${element.lastName}`"
              :truncate="false"
              :removeable="true"
              @remove="removeStudent"
            />
          </template>
        </draggable>
        <div v-if="disabled">
          <chip
            v-for="(student, i) in students"
            :key="i"
            :index="i"
            :label="`${student.firstName} ${student.lastName}`"
            :truncate="false"
            :removeable="false"
            class="disabled-chip"
          />
        </div>
        <div v-if="!disabled" class="roster-search">
          <autocomplete
            ref="autocomplete"
            v-model="search"
            :options="roster"
            label-key="name"
            aria-label="search by student name"
            value-key="_id"
            placeholder="Type a student name to add to the list"
            @input="addUser"
          />
        </div>
      </div>
    </collapse-content>
  </collapse-provider>
</template>
<script type="text/javascript">
import Draggable from 'vuedraggable'
import Autocomplete from 'src/shared/components/Autocomplete'

export default {
  name: 'ActivityGroup',
  components: { Draggable, Autocomplete },
  emits: ['update'],
  props: {
    roster: {
      type: Array,
      required: true
    },
    group: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    name() {
      return this.group.name ?? ''
    },
    students: {
      get() {
        return this.group.students ?? []
      },
      set(students) {
        this.$emit('update', { ...this.group, students })
      }
    },
    studentsLabel() {
      return this.group.students.length === 1 ? 'student' : 'students'
    }
  },
  methods: {
    addUser(id) {
      if (id) {
        const students = this.students.slice()
        const student = this.roster.find(s => s._id === id)
        students.push(student)
        this.$refs.autocomplete.clear()
        this.students = students
      }
    },
    removeStudent(index) {
      const students = this.students.slice()
      students.splice(index, 1)
      this.students = students
    }
  }
}
</script>
<style lang="scss" scoped>
.collapse-enter-active,
.collapse-leave-active {
  transition: height 0.1s ease-out;
  -moz-transition: height 0.1s ease-out;
  overflow: hidden;
}
.collapse-leave-to {
  height: 0;
}

.activity-group {
  display: inline-block;
  flex: 1;
  flex-wrap: wrap;
  flex-basis: 50%;
}
.group-title-count {
  display: flex;
  flex-direction: row;
}
.group-name {
  margin-top: 7px;
  font-weight: 600;
}
.group-count {
  flex-grow: 1;
  margin-left: 10px;
  margin-top: 7px;
  font-weight: 300;
}
.toggle-btn {
  flex-grow: 1;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 7px;
  text-align: right;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.selected-groups {
  border: 1px solid $silver;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 30px;
  margin-right: 30px;
}
</style>
