<template>
  <div v-if="false" />
</template>

<script>
import * as Vue from 'vue'
import ActivityVariablesModal from 'src/shared/components/modals/ActivityVariablesModal'
import { inject } from 'vue'

export default {
  name: 'VariableMenu',
  props: {
    editor: {
      type: Object,
      required: true
    },
    variableContext: {
      type: Object,
      default: undefined
    },
    allowResponseVariable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Sync the focused node's href with the menu.
    const variable = Vue.ref(null)
    const modal = inject('$modal')
    const onSelectionUpdate = () => {
      variable.value = props.editor.getAttributes('variable').response
    }
    props.editor.on('selectionUpdate', onSelectionUpdate)
    Vue.onUnmounted(() => {
      props.editor.off('selectionUpdate', onSelectionUpdate)
    })

    const openModal = async currentVariable => {
      const { status, data } = await modal.show(ActivityVariablesModal, {
        variableContext: props.variableContext,
        allowInsert: true,
        allowResponseVariable: props.allowResponseVariable,
        currentVariable
      })
      if (status === 'ok') {
        props.editor.chain().focus().setVariable({ variable: data }).run()
      }
    }

    // eslint-disable-next-line vue/no-mutating-props
    props.editor.storage.variable.onEditVariable = openModal

    return {
      openModal,
      variable
    }
  }
}
</script>
