<template>
  <select
    ref="types"
    class="pi-form-control form-control"
    :disabled="disabled || null"
    :value="modelValue"
    data-testid="component-type-input"
    aria-label="Component Type"
    @change="e => $emit('update:modelValue', e.target.value)"
  >
    <option />
    <option value="GeneralNote">Announcement</option>
    <option value="GeneralInstruction">Numbered Instruction</option>
    <option value="GridGraphQuestion">Data Table & Graph</option>
    <option value="GridQuestion">Data Table</option>
    <option value="OpenEndedQuestion">Open Ended Question</option>
    <option value="MultipleChoiceQuestion">Multiple Choice Question</option>
    <option value="NumericalQuestion">Numerical Question</option>
    <option value="EmbeddedInstance">Video Instance</option>
    <option value="StudentInstance">Student Instance Upload</option>
    <option value="InstructorInstance">Instructor Instance Upload</option>
    <option value="IFrame">Embedded Web Content</option>
    <option value="DragDropQuestion">Drag & Drop</option>
    <option v-if="isAdmin || isContentDeveloper" value="PhetIOSim">
      Legacy PhET-iO Simulation
    </option>
    <option v-if="isAdmin || isContentDeveloper" value="PhetIOSimulation">
      PhET-iO Simulation
    </option>
  </select>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ComponentType',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'isContentDeveloper'])
  },

  methods: {
    setFocus() {
      this.$refs.types.focus()
    }
  }
}
</script>
