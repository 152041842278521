export default {
  provider: null,
  register(p) {
    this.provider = p
  },
  show(...args) {
    if (this.provider) {
      return this.provider.show(...args)
    } else {
      throw new Error(
        'The app must contain a ModalProvider component to display modals.'
      )
    }
  }
}
