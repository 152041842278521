<template>
  <section class="select-question-section">
    <collapse-provider
      :collapsed="isSectionCollapsed"
      @update:collapsed="
        collapsed => emit('update:is-section-collapsed', collapsed)
      "
    >
      <section-header :class="isSectionCollapsed ? 'collapsed' : ''">
        <collapse-toggle>
          <section-title
            ><collapse-icon icon="caret" class="collapse-icon" />{{
              section.name
            }}</section-title
          >
        </collapse-toggle>
        <span
          v-if="!isSectionCollapsed"
          class="section-header__toggle-sections"
        >
          <form-button link @click.stop="toggleAll">
            {{ areAllOpen ? 'Hide' : 'Show' }} All Questions in Section
          </form-button>
        </span>

        <div v-if="isSectionCollapsed" class="section-header__counts">
          <span>Components: {{ section.components.length }}</span>
          <span>
            Gradable Components:
            {{ gradableComponentsInSection.length }}
          </span>
          <span>
            Ungraded Components:
            <span
              :class="
                !ungradedComponentsInSection.length
                  ? 'gradable-section__zero-ungraded'
                  : ''
              "
              >{{ ungradedComponentsInSection.length }}</span
            >
          </span>
        </div>
      </section-header>
      <collapse-content>
        <div class="table-responsive section-components__table">
          <table class="table">
            <thead>
              <tr>
                <th class="col-sm-0">
                  <checkbox
                    v-if="visibleComponents.length > 0"
                    v-model="allComponentsSelected"
                    :indeterminate="someComponentsSelected"
                    :name="'all-section-' + section.id"
                    aria-label="Select All"
                  />
                </th>
                <th class="col-sm-7">Question</th>
                <th class="col-sm-2 text-center">Grading Status</th>
                <th class="col-sm-2 text-center">Default Score</th>
              </tr>
            </thead>
            <template
              v-for="component in visibleComponents"
              :key="component._id"
            >
              <gradable-component-list-item
                :gradable-components="gradableComponents"
                @update:gradableComponents="updateSelected"
                :component="component"
                :is-collapsed="collapsedComponents[component._id]"
                :variable-context="variableContext"
                @update:is-component-collapsed="
                  collapsed => toggleComponent(component._id, collapsed)
                "
              >
              </gradable-component-list-item>
            </template>
          </table>
        </div>
      </collapse-content>
    </collapse-provider>
  </section>
</template>

<script setup>
import GradableComponentListItem from './GradableComponentListItem'
import { ref, computed, watch } from 'vue'

const props = defineProps({
  section: {
    type: Object,
    required: true
  },
  isSectionCollapsed: {
    type: Boolean,
    default: false
  },
  componentsFilter: {
    type: String,
    required: true
  },
  gradableComponents: {
    default: []
  },
  variableContext: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['update:is-section-collapsed'])

const UNGRADABLE = [
  'GeneralNote',
  'GeneralInstruction',
  'EmbeddedInstance',
  'IFrame',
  'PhetIOSim',
  'PhetIOSimulation',
  'InstructorInstance',
  'StudentInstance'
]
const filterComponents = (components, filter) => {
  if (filter === 'only_ungraded') {
    return components.filter(
      component =>
        !UNGRADABLE.includes(component.componentType) &&
        !component.autograde &&
        component.gradingPct < 1
    )
  }
  if (filter === 'all_gradable') {
    return components.filter(
      component => !UNGRADABLE.includes(component.componentType)
    )
  }
  return components
}

const allComponents = computed(() =>
  props.section.components
    .map(component => {
      if (component.componentType === 'SplitView') {
        return [...component.leftContent, ...component.rightContent]
      }
      return component
    })
    .flat()
)

const gradableComponentsInSection = computed(() =>
  allComponents.value.filter(
    component => !UNGRADABLE.includes(component.componentType)
  )
)
const ungradedComponentsInSection = computed(() =>
  allComponents.value.filter(
    component =>
      !UNGRADABLE.includes(component.componentType) &&
      !component.autograde &&
      component.gradingPct < 1
  )
)

const visibleComponents = computed(() =>
  filterComponents(allComponents.value, props.componentsFilter)
)
const visibleGradableComponents = computed(() =>
  filterComponents(visibleComponents.value, 'all_gradable')
)

const selectedComponents = computed(() =>
  props.gradableComponents.filter(component =>
    allComponents.value.some(c => c._id === component.id)
  )
)
const updateSelected = newGradableComponents => {
  emit('update:gradableComponents', newGradableComponents)
}
const allComponentsSelected = computed({
  get() {
    return (
      selectedComponents.value.length &&
      selectedComponents.value.length === visibleGradableComponents.value.length
    )
  },
  set(isSelected) {
    if (isSelected) {
      emit('update:gradableComponents', [
        ...props.gradableComponents,
        ...visibleGradableComponents.value
          .filter(c => props.gradableComponents.every(gc => gc.id !== c._id))
          .map(c => ({
            id: c._id,
            defaultScore: c.pointValue
          }))
      ])
    } else {
      emit(
        'update:gradableComponents',
        props.gradableComponents.filter(
          c =>
            !props.section.components.some(component => component._id === c.id)
        )
      )
    }
  }
})
const someComponentsSelected = computed(
  () =>
    selectedComponents.value.length !== 0 &&
    selectedComponents.value.length !== visibleGradableComponents.value.length
)

const collapsedComponents = ref({})
const areAllCollapsed = computed(() =>
  visibleComponents.value.every(
    component => collapsedComponents.value[component._id]
  )
)
const areAllOpen = computed(() =>
  visibleComponents.value.every(
    component => !collapsedComponents.value[component._id]
  )
)
function toggleComponent(id, collapsed) {
  collapsedComponents.value[id] = collapsed
}
function toggleAll() {
  if (!areAllOpen.value && !areAllCollapsed.value) {
    collapsedComponents.value = {}
  } else if (!areAllCollapsed.value) {
    const collapsed = {}
    for (const c of visibleComponents.value) {
      collapsed[c._id] = true
    }
    collapsedComponents.value = collapsed
  } else {
    collapsedComponents.value = {}
  }
}
</script>

<style lang="scss" scoped>
$light-grey: #ccc;
$solid-gray: #c3c3c3;

$dashed-bottom-border: 1px dashed $light-grey;
$solid-bottom-border: 1px solid $solid-gray;
.section-header.collapsed {
  padding-top: 2px;
  .collapse-toggle {
    width: 100%;
  }
}

th.col-sm-0 {
  width: 5%;
}

.section-header__toggle-sections {
  height: 34px;
  line-height: 34px;
  position: absolute;
  right: 0;
}

.collapse-toggle {
  width: 49%;

  @media (min-width: $screen-sm) and (max-width: $screen-lg) {
    width: 73%;
  }
  @media (min-width: $screen-lg) {
    width: 80%;
  }
}
.section-components__table {
  margin-left: 24px;
}
.section-header__counts {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-left: 24px;

  span:not(:first-child) {
    padding-left: 10px;
  }
}
.section-header :deep() {
  position: relative;

  .collapse-icon {
    font-size: 16px;
    padding-right: 15px;
  }
  .section-header__title {
    width: 95%;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.section-header.collapsed :deep() {
  .section-header__title {
    width: 100%;
  }
}
.table {
  border-collapse: collapse;

  & thead > tr > th {
    vertical-align: middle;
    border-bottom: $solid-bottom-border;
  }
}

.gradable-section__zero-ungraded {
  color: #158484;
  font-weight: 800;
}
</style>
