<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { computed } from 'vue'
import { mapGetters, mapState } from 'vuex'
import client from 'src/shared/api-client'

export default {
  name: 'BrowseQuotes',
  provide() {
    return {
      browseQuotes: computed(() => ({
        query: this.query,
        quoteCount: this.quoteCount,
        quoteLimit: this.quoteLimit,
        quotes: this.quotes,
        isLoadingQuotes: this.isLoadingQuotes,
        updateQuery: this.updateQuery,
        statuses: this.statuses,
        types: this.types
      }))
    }
  },
  props: {
    query: {
      type: Object,
      required: true,
      validator(value) {
        return (
          typeof value.page === 'number' &&
          typeof value.sort === 'string' &&
          typeof value.dir === 'string' &&
          typeof value.q === 'string'
        )
      }
    }
  },
  data() {
    return {
      quotes: [],
      quoteCount: 0,
      quoteLimit: 18,
      isLoadingQuotes: false,
      statuses: {
        paid: 'Paid',
        fulfilled: 'Fulfilled',
        expired: 'Expired',
        quote: 'Open',
        pending: 'Pending'
      },
      types: {
        license: 'Institution',
        'bookstore-codes': 'Bookstore Codes',
        'added-seats': 'Added Seats',
        renewal: 'Renewal',
        upgrade: 'Upgrade'
      }
    }
  },
  computed: {
    ...mapGetters(['userId', 'isAdmin']),
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    async loadQuotes() {
      const response = await client.purchases.search({
        page: this.query.page,
        limit: this.quoteLimit,
        ...(!!this.query.q && { q: this.query.q }),
        ...(this.query.status !== 'undefined' && {
          status: this.query.status
        }),
        ...(this.query.types !== 'undefined' && {
          types: [...this.query.types]
        }),
        sort: this.query.sort ?? (this.query.q ? undefined : 'updatedAt'),
        dir: this.query.dir ?? (this.query.q ? undefined : 'desc')
      })
      this.quotes = response.page
      this.quoteCount = response.total
    },
    updateQuery(query) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          ...query
        }
      })
    }
  },
  watch: {
    query: {
      async handler() {
        this.isLoadingQuotes = true
        await this.loadQuotes()
        this.isLoadingQuotes = false
      },
      immediate: true
    }
  }
}
</script>
