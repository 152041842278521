<template>
  <label
    class="drag-drop-target-condition__dropdown-label"
    :id="`${name}-label`"
  >
    {{ dropTarget.label }}
  </label>
  <div :class="errorMessage ? 'has-error' : ''">
    <button-dropdown
      unstyled
      class="drag-drop-target-condition__dropdown-button"
      menu-class="drag-drop-target-condition__dropdown-menu"
      :aria-labelledby="`${name}-label`"
      rules="required"
      v-model="selectedChoices"
      :name="`${name}-label`"
      label="condition"
      @blur="handleBlur"
    >
      <template #button="{ isOpen }">
        <div class="drag-drop-target-condition__dropdown-display-div">
          <span class="drag-drop-target-condition__dropdown-display">
            {{ previewText }}
          </span>
          <icon :icon="isOpen ? 'caret-up' : 'caret-down'" />
        </div>
      </template>
      <template #default>
        <li class="-mt-2 px-3 pt-2 w-64 bg-gray-200 rounded" tabindex="-1">
          <checkbox
            v-if="allowEmptyTargets"
            v-model="selectedChoices"
            value="empty"
            >Empty</checkbox
          >
        </li>
        <li class="mb-1 px-3 pb-1 w-64 bg-gray-200" tabindex="-1">
          <checkbox v-model="selectedChoices" value="all">
            Any choice
          </checkbox>
        </li>
        <li
          class="px-3 w-64"
          v-for="choice in validChoices"
          :key="choice.id"
          tabindex="-1"
        >
          <checkbox
            :disabled="allSelected"
            v-model="selectedChoices"
            :value="choice.id.toString()"
          >
            {{ choice.index }}: {{ getChoiceText(choice.text) }}
          </checkbox>
        </li>
      </template>
    </button-dropdown>
    <span v-if="errorMessage" class="help-block">
      {{ errorMessage }}
    </span>
  </div>
</template>
<script setup>
import { computed, watch } from 'vue'
import { getText } from 'src/shared/components/editor/utils'
import { processLatex } from '../../../shared/utils/latex-methods'
import { useField } from 'vee-validate'

const emit = defineEmits(['change-target-condition'])
const props = defineProps({
  condition: {
    type: Object,
    required: true
  },
  dropTarget: {
    type: Object,
    required: true
  },
  choices: {
    type: Array,
    required: true
  },
  variableContext: {
    type: Object,
    required: true
  },
  renderType: {
    type: String,
    required: true
  },
  allowEmptyTargets: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  }
})

const {
  value: fieldValue,
  errorMessage,
  handleChange,
  handleBlur,
  resetField
} = useField(
  computed(() => props.name),
  'required',
  {
    label: 'condition',
    initialValue: props.condition.targets.find(
      c => c.targetId === props.dropTarget.id
    )?.choices,
    validateOnValueUpdate: false,
    syncVModel: false
  }
)

watch(
  () =>
    props.condition.targets.find(c => c.targetId === props.dropTarget.id)
      ?.choices,
  choices => {
    if (JSON.stringify(choices) !== JSON.stringify(fieldValue.value)) {
      resetField({ value: choices })
    }
  }
)

const validChoices = computed(() =>
  props.choices
    .map((choice, index) => ({ ...choice, index: index + 1 }))
    .filter(
      choice =>
        choice.validTargets.length === 0 ||
        choice.validTargets.includes(props.dropTarget.id)
    )
)

const selectedChoices = computed({
  get() {
    return Array.isArray(fieldValue.value)
      ? fieldValue.value
      : [fieldValue.value]
  },
  set(choices) {
    if (choices.includes('all')) {
      choices = choices.filter(choice => choice !== 'all' || choice !== 'empty')
    }
    handleChange(choices, !!errorMessage.value)
    emit('change-target-condition', choices)
  }
})

const allSelected = computed(() => selectedChoices.value.includes('all'))

const getChoiceText = text => {
  let parsedText
  if (props.renderType === 'latex') {
    parsedText = text
    return processLatex(text ?? '', props.variableContext.variables, 'id')
  } else {
    parsedText = getText(text, props.variableContext, {
      inline: true
    })
  }

  if (parsedText.length < 60) {
    return parsedText
  }
  return parsedText.slice(0, 60) + '...'
}

const previewText = computed(() => {
  const choices = selectedChoices.value

  const matchAll = choices.includes('all')
  const matchEmpty = choices.includes('empty')
  const matchChoices = choices.filter(
    choice => choice !== 'all' && choice != 'empty'
  )

  if (matchAll) {
    return matchEmpty ? 'All selected + Empty' : 'All selected'
  } else if (matchEmpty) {
    return matchChoices.length > 0
      ? `Empty + ${matchChoices.length} selected`
      : 'Empty'
  } else if (matchChoices.length === 1) {
    const choice = validChoices.value.find(
      c => c.id.toString() === matchChoices[0].toString()
    )
    return choice ? `${choice.index}: ${getChoiceText(choice.text)}` : 'Unknown'
  } else if (matchChoices.length > 1) {
    return `${matchChoices.length} selected`
  } else {
    return 'No selection'
  }
})
</script>

<style lang="scss">
.drag-drop-target-condition__dropdown-button {
  position: relative;
  background: #ffffff;
  width: 150px;
  color: #3d3d3d;
  font-weight: 700;
  font-size: 15px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 1;
  padding: 5px 10px 5px 5px;

  &:hover,
  &:focus {
    background: #ffffff;
    color: #3d3d3d;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

.drag-drop-target-condition__dropdown-label {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.drag-drop-target-condition__dropdown-menu {
  & li label {
    padding: 5px !important;
    margin: 0 0 0 0 !important;
  }
}

.drag-drop-target-condition__dropdown-display-div {
  display: flex;
  justify-content: space-between;
}
.drag-drop-target-condition__dropdown-display {
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.has-error {
  .drag-drop-target-condition__dropdown-button {
    border-color: $color-error;
  }
}
</style>
