<template>
  <td ref="td" :aria-readonly="inputReadonly">
    <div :class="{ readonly: inputReadonly }">
      <data-grid-input-field
        :class="{ invalid: isInvalid }"
        :value="value"
        :type="type"
        :format="format"
        :readonly="inputReadonly"
        :aria-label="`column ${columnIndex + 1} row ${rowIndex + 1} value`"
        :aria-invalid="isInvalid"
        :aria-errormessage="isInvalid ? 'number-cell-invalid-message' : null"
        :variable-context="variableContext"
        @focus="setFocus"
        @input="onInput"
        @paste="$gridActions.paste(columnIndex, rowIndex, $event)"
      />
      <div v-if="isInvalid && isFocused" class="invalid-message">
        {{ errorMessage }}
      </div>
    </div>
    <span class="resizer" @pointerdown="onMouseDown" />
  </td>
</template>

<script>
import * as Y from 'yjs'
import DataGridInputField from 'src/shared/components/grid-graph/DataGridInputField'
import { parseCellNumber } from './utilities'
import { validateExpression } from '@pi/shared/variables'

export default {
  name: 'DataGridCell',
  components: {
    DataGridInputField
  },
  inject: ['$gridActions'],
  emits: ['focus:cell'],
  props: {
    column: {
      type: Y.Map,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    variableContext: {
      type: Object,
      default: undefined,
      required: false
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnJson: this.column.toJSON(),
      moving: false,
      x: 0,
      w: 0
    }
  },
  beforeUnmount() {
    this.unsubscribe?.()
  },
  computed: {
    type() {
      if (this.columnJson.allowText) {
        return this.columnJson.data[this.rowIndex]?.parser === 'latex' ||
          this.columnJson.data[this.rowIndex]?.parser === 'expression'
          ? this.columnJson.data[this.rowIndex]?.parser
          : 'text'
      } else {
        return this.columnJson.data[this.rowIndex]?.parser === 'expression'
          ? this.columnJson.data[this.rowIndex]?.parser
          : 'number'
      }
    },
    value() {
      const cellData = this.columnJson.data[this.rowIndex]
      const value =
        this.type === 'expression'
          ? cellData?.id
          : cellData?.formula ?? cellData

      return value !== 'undefined' &&
        typeof value !== 'undefined' &&
        (typeof value !== 'number' || !isNaN(value))
        ? value.toString()
        : ''
    },
    errorMessage() {
      return this.type === 'expression'
        ? 'Invalid expression'
        : 'Must be a number'
    },
    format() {
      if (this.columnJson.format) {
        return {
          type: this.columnJson.format,
          decimalPlaces: this.columnJson.decimalPlaces,
          significantFigures: this.columnJson.significantFigures
        }
      } else {
        return undefined
      }
    },
    isInvalid() {
      if (this.columnJson.data[this.rowIndex]?.parser === 'expression') {
        return !validateExpression(
          this.value,
          this.variableContext.variables ?? []
        ).isValid
      }
      return (
        !this.columnJson.allowText &&
        !!this.value &&
        isNaN(parseCellNumber(this.value))
      )
    },
    inputReadonly() {
      return (
        this.readonly ||
        this.columnJson.formula !== '' ||
        (!this.editMode && this.type === 'expression')
      )
    }
  },
  methods: {
    setFocus(value) {
      if (!this.editMode && this.type === 'expression') return
      this.$emit('focus:cell', {
        column: this.columnIndex,
        row: this.rowIndex,
        value: {
          value: this.value,
          dataType: this.type
        },
        allowText: this.columnJson.allowText
      })
    },
    onInput(value) {
      if (this.type === 'latex' || this.type === 'expression') {
        value = {
          formula: value,
          parser: this.type
        }
      }
      this.$gridActions.setValueAtColumnAndRow(
        this.columnIndex,
        this.rowIndex,
        value
      )
    },
    onMouseDown(e) {
      this.moving = true
      this.x = e.clientX
      const table = this.$refs.td.closest('table')
      const th = table.querySelector('thead').querySelectorAll('th')[
        this.columnIndex
      ]
      const styles = window.getComputedStyle(th)
      this.w = parseInt(styles.width, 10)
      document.addEventListener('pointermove', this.handleResize)
      document.addEventListener('pointerup', this.onMouseUp)
    },
    onMouseUp() {
      if (!this.moving) return
      this.moving = false
      document.removeEventListener('pointermove', this.handleResize)
      document.removeEventListener('pointerup', this.onMouseUp)
    },
    handleResize(e) {
      const dx = e.clientX - this.x
      // Update the width of column
      const table = this.$refs.td.closest('table')
      let w = this.w + dx
      if (w < 60) w = 60
      this.$gridActions.setColumnWidth(this.columnIndex, w)
    }
  },
  watch: {
    column: {
      handler() {
        this.unsubscribe?.()
        const column = this.column
        const onChange = () => {
          this.columnJson = column.toJSON()
        }
        onChange()
        column.observeDeep(onChange)
        this.unsubscribe = () => {
          column.unobserveDeep(onChange)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
td {
  position: relative;
  height: 45px !important;

  & > div {
    position: relative;
    height: 100%;

    input {
      height: 100%;
    }

    &.readonly {
      background-color: $grid-cell-readonly-background-color;
    }

    .invalid-message {
      position: absolute;
      bottom: -22px;
      left: 0;
      width: 124px;
      height: 22px;
      line-height: 22px;
      background-color: $color-error;
      padding: 0 4px;
      font-weight: normal;
      z-index: 1;
    }
  }

  .resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    cursor: col-resize;
    user-select: none;
  }
}
</style>
