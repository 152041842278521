<template>
  <div class="sensitive-input-field">
    <text-input
      v-bind="{ rules, label, name }"
      v-model="inputValue"
      :type="inputType"
      :rules="rules"
      :name="name"
      :label="label"
      :disabled="disabled"
      class="sensitive-input-field__text"
    />
    <form-button class="sensitive-input-field__button" @click="toggleShown">
      <icon :icon="buttonIcon" />
      <span class="sr-only">{{ buttonText }}</span>
    </form-button>
  </div>
</template>

<script>
export default {
  name: 'SensitiveInput',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shown: false
    }
  },
  computed: {
    buttonIcon() {
      return this.shown ? 'eye-slash' : 'eye'
    },
    buttonText() {
      return this.shown ? 'Hide' : 'Show'
    },
    inputType() {
      return this.shown ? 'text' : 'password'
    },
    inputValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    toggleShown() {
      this.shown = !this.shown
    }
  }
}
</script>

<style lang="scss">
.sensitive-input-field {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.sensitive-input-field__button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sensitive-input-field__text {
  flex-grow: 1;

  input,
  input:hover,
  input:focus {
    height: 38px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
