<template>
  <view-container>
    <view-header>
      <view-title>My Classes</view-title>
    </view-header>
    <loading-container :loading="isLoading">
      <div v-if="classes.length == 0" class="row">
        <div
          class="col-sm-12 text-center no-classes-msg"
          data-testid="noClassesPrompt"
        >
          <p>
            It looks like you haven't joined any classes. Click below to join a
            class.
          </p>
          <button-link :to="{ name: 'join_class' }">
            <icon icon="plus" />
            Join Class
          </button-link>
        </div>
      </div>
      <pagination-container
        :page="page"
        :count="pageCount * 10"
        @loadpage="paginateCallback"
      >
        <div
          v-for="theClass in classes"
          :key="theClass._id"
          class="panel panel-default"
        >
          <div class="panel-heading">
            <div class="row">
              <div class="col-md-8">
                <span>{{ theClass.name }}</span>
                <span v-if="theClass.status !== 'active'" class="text-danger">
                  {{ theClass.status }}
                </span>
              </div>
            </div>
          </div>

          <class-assignments-list :class-id="theClass._id" />
        </div>
      </pagination-container>
    </loading-container>
  </view-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ClassAssignmentsList from 'src/modules/classes/components/ClassAssignmentsList'

export default {
  components: {
    ClassAssignmentsList
  },
  data() {
    return {
      isLoading: false,
      page: 1
    }
  },
  computed: {
    ...mapState({
      classes: state => state.classes.classes,
      pageCount: state => state.classes.pageCount
    })
  },
  methods: {
    ...mapActions(['loadClasses']),
    async paginateCallback({ page }) {
      this.page = page
    }
  },
  watch: {
    page: {
      async handler() {
        this.isLoading = true
        await this.loadClasses(this.page)
        window.scroll(0, 0)
        this.isLoading = false
      },
      immediate: true
    }
  }
}
</script>
