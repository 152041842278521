<template>
  <form-button v-bind="{ secondary, tertiary, link }" @click="onToggleClick">
    <slot v-if="toggle" name="on" />
    <slot v-else name="off" />
  </form-button>
</template>

<script>
export default {
  name: 'Toggle',
  emits: ['toggle'],
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onToggleClick() {
      this.$emit('toggle', !this.toggle)
    }
  }
}
</script>

<style lang="scss" scoped></style>
