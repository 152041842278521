<template>
  <ViewContainer>
    <LoadingContainer v-if="!ltiSession || !classes" loading />
    <template v-else>
      <StickyHeader>
        <template #primary-navigation>
          <Breadcrumb>
            <BreadcrumbItem>
              {{ ltiSession?.context.platform.name }}
            </BreadcrumbItem>
            <BreadcrumbItem v-if="ltiSession.context.context.name">
              {{ ltiSession.context.context.name }}
            </BreadcrumbItem>
          </Breadcrumb>
        </template>
        <template #title>
          <StickyHeaderTitle>Connect Class</StickyHeaderTitle>
        </template>
        <template
          v-if="managing && connectMode === 'sections'"
          #actions="{ isStuck }"
        >
          <UIButton
            v-if="managing && connectMode === 'sections'"
            type="outline"
            variant="destructive"
            :size="isStuck ? 'sm' : 'md'"
            @click="disconnectClasses"
          >
            Disconnect All
          </UIButton>
        </template>
        <template #steps="{ isStuck }">
          <div
            class="bg-ui-gray-50 relative"
            style="padding: 12px 9999% 6px 9999%; margin: 0 -9999% 0 -9999%"
            :class="{
              'sticky top-12': isStuck
            }"
          >
            <UIStepList
              :steps="steps"
              :currentStep="classes.length ? 'connectClass' : 'addClass'"
            />
          </div>
        </template>
      </StickyHeader>
      <ConnectContextForm
        v-if="connectMode === 'context'"
        :classes="classes"
        :className="ltiSession.context.context.name"
        :platformName="ltiSession.context.platform.name"
        :selectedClass="ltiSession.context.class?._id"
        :managing="managing"
        @refreshClassList="refreshList"
      />
      <ConnectSectionsForm
        v-else-if="connectMode === 'sections'"
        :classes="classes"
        :sections="ltiSession.context.sections"
        :platformName="ltiSession.context.platform.name"
        :managing="managing"
        @refreshClassList="refreshList"
      />
    </template>
  </ViewContainer>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, computed } from 'vue'
import { UIButton, UIStepList } from '@pi/design'
import { Class, GetLtiSessionResponse } from '@pi/api-types'
import client from 'src/shared/api-client'
import { useRouter } from 'vue-router'
import ConnectContextForm from '../components/ConnectContextForm.vue'
import ConnectSectionsForm from '../components/ConnectSectionsForm.vue'
import { getLtiSteps, UIStepItem } from '../lti-utils'

import { useFlash } from 'src/shared/hooks/flash'

const managing = computed(() => {
  return (
    !!ltiSession.value?.context.sections.some(section => section.class) ||
    !!ltiSession.value?.context.class
  )
})

const flash = useFlash()
const router = useRouter()

const connectMode = ref<'context' | 'sections'>()
const steps = ref<UIStepItem[]>()
const classes = ref<Class[]>()
const ltiSession = ref<GetLtiSessionResponse>()
async function load() {
  const [ltiSessionRequest, classesRequest] = await Promise.all([
    client.lti.getSession(),
    client.classes.search({ limit: 1000 })
  ])

  classes.value = classesRequest.classes
  ltiSession.value = ltiSessionRequest
  steps.value = getLtiSteps(ltiSession.value, {
    noClasses: !classes.value.length,
    noClassConnected: !managing.value
  })
}

async function refreshList() {
  await load()
}

async function disconnectClasses() {
  await client.lti.disconnectClass()
  flash.success('Classes disconnected successfully.')
  await load()
}

onBeforeMount(async () => {
  await load()

  if (ltiSession.value) {
    if (
      ltiSession.value.state !== 'CONNECT_CLASS' &&
      ltiSession.value.state !== 'CONNECT_ASSIGNMENT'
    ) {
      await router.push({ name: 'lti' })
    }
    if (ltiSession.value.context.sections.length === 0) {
      connectMode.value = 'context'
    } else {
      connectMode.value = 'sections'
    }
  }
})
</script>
