<template>
  <div
    class="split-view-container split-view-container__split"
    :class="
      viewMode === 'split'
        ? 'split-view-container__split'
        : 'split-view-container__inline'
    "
  >
    <div class="split-view-content__left">
      <template
        v-for="(childComponent, index) in component.leftContent"
        :key="index"
      >
        <activity-section-component-realtime
          v-slot="{ onSubmit, onCanSubmitChange, canSubmit }"
          :component="childComponent"
          :component-response-map="componentResponseMaps[childComponent._id]"
          @change="onChange"
          @submit="emit('submit', component)"
          @needs-submission="
            (e: any) => emit('needs-submission', e, component._id)
          "
        >
          <activity-section-component-realtime-renderer
            :component="childComponent"
            :variable-context="variableContext"
            :canSubmit="canSubmit"
            @submit="onSubmit"
            :viewAsStudent="viewAsStudent"
            @canSubmitChange="onCanSubmitChange"
            :component-response-map="componentResponseMaps[childComponent._id]"
            :yjsProvider="yjsProvider"
          />
        </activity-section-component-realtime>
      </template>
    </div>
    <div class="split-view-content__right">
      <template
        v-for="(childComponent, index) in component.rightContent"
        :key="index"
      >
        <activity-section-component-realtime
          v-slot="{ onSubmit, onCanSubmitChange, canSubmit }"
          :component="childComponent"
          :component-response-map="componentResponseMaps[childComponent._id]"
          @change="onChange"
          @submit="emit('submit', component)"
          @needs-submission="
            (e: any) => emit('needs-submission', e, component._id)
          "
        >
          <activity-section-component-realtime-renderer
            :component="childComponent"
            :variable-context="variableContext"
            :canSubmit="canSubmit"
            @submit="onSubmit"
            :viewAsStudent="viewAsStudent"
            @canSubmitChange="onCanSubmitChange"
            :component-response-map="componentResponseMaps[childComponent._id]"
            :yjsProvider="yjsProvider"
          />
        </activity-section-component-realtime>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import ActivitySectionComponentRealtime from 'src/modules/activities/components/ActivitySectionComponentRealtime.vue'
import ActivitySectionComponentRealtimeRenderer from '../components/ActivitySectionComponentRealtimeRenderer.vue'
import { HocuspocusProvider } from '@hocuspocus/provider'

type SplitViewComponentProps = {
  component: any
  variableContext: any
  componentResponseMaps: any
  yjsProvider: HocuspocusProvider
  viewAsStudent?: boolean
  viewMode: string
}
const emit = defineEmits(['change', 'submit', 'needs-submission'])

defineProps<SplitViewComponentProps>()

const onChange = (changes: any) => {
  emit('change', changes)
}
</script>

<style lang="scss" scoped>
.split-view-container__inline {
  display: block;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
@media screen and (min-width: 1025px) {
  .split-view-container__split {
    display: flex;
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-right: -50vw;
    margin-left: -50vw;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: space-evenly;

    .split-view-content__left {
      min-width: 50%;
      position: sticky;
      top: 8rem;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 9rem);
    }
    .split-view-content__right {
      max-width: 50%;
      min-width: 48%;
      margin-left: 20px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .split-view-container__split {
    display: block;
    margin-left: 0;
    margin-right: 0;
    left: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    .split-view-content__left,
    .split-view-content__right {
      max-width: 100%;
    }
    .split-view-content__right {
      margin-left: 0;
    }
  }
}
</style>
