<template>
  <div :id="id" ref="root">
    <transition
      mode="in-out"
      name="collapse2"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div
        v-if="!lazyLoad || hasOpened"
        v-show="!collapse.state"
        :class="{ 'collapse-content--print': print }"
      >
        <div>
          <!-- @slot The content that will collapse. -->
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * Renders content that will collapse when toggled.
 */
export default {
  name: 'CollapseContent',
  inject: ['collapse'],
  props: {
    /**
     * The ID to use for the root element.
     * The CollapseToggle uses this ID to explain to screen readers which element it controls.
     */
    id: {
      type: String,
      default: () => `collapse-content-${Math.floor(Math.random() * 100000)}`
    },
    /**
     * When true, open the collapsed content when printing page.
     */
    print: {
      type: Boolean,
      default: false
    },
    lazyLoad: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasOpened: false
    }
  },
  watch: {
    collapse(newCollapse) {
      // If it hasn't been opened before and it's not currently collapsed
      // Set hasOpened to true in order to keep the components rendered and not unmounted
      if (!this.hasOpened && !newCollapse.state) {
        this.hasOpened = true
      }
    }
  },
  methods: {
    getHeight() {
      return this.$refs.root.children[0].children[0].clientHeight
    },
    enter(el) {
      el.style.height = '0px'
      this.$nextTick(() => {
        el.style.height = `${this.getHeight()}px`
      })

      // for plotly to auto size graphs that are in collapsed containers on init
      window.dispatchEvent(new Event('resize'))
    },
    afterEnter(el) {
      el.style.height = null
    },
    beforeLeave(el) {
      el.style.height = `${this.getHeight()}px`
    },
    leave(el) {
      el.style.height = `${this.getHeight()}px`
      this.$nextTick(() => {
        el.style.height = '0px'
      })
    },
    afterLeave(el) {
      el.style.height = null
    }
  },
  mounted() {
    this.collapse.registerContentId(this.id)
  },
  beforeUnmount() {
    this.collapse.registerContentId(null)
  }
}
</script>

<style lang="scss" scoped>
.collapse2-enter-active,
.collapse2-leave-active {
  transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  overflow: hidden;
}

@media print {
  .collapse-content--print {
    display: block !important;
    height: auto !important;
    overflow: visible !important;
    visibility: visible !important;
  }
}
</style>
