<template>
  <card
    v-bind="$attrs"
    ref="root"
    :id="`component-${component._id}`"
    tabindex="-1"
    class="activity-panel-card border-2 border-transparent"
    :class="{
      'activity-panel-card-errors border-warning-orange-600': isFailed,
      'activity-panel-card-split-view': isSplitView
    }"
    :data-queryid="nested ? 'nested-activity-component' : 'activity-component'"
  >
    <drag-panel
      :aria-label="ariaLabel"
      class="truncate-title"
      :panel-object="component"
      drag-handle-class="component-drag-handle"
      :labels-title="{
        up: 'Move Component Up',
        down: 'Move Component Down',
        duplicate: 'Duplicate Component',
        clipboard: 'Copy Component To Clipboard',
        delete: 'Delete Component',
        copyLink: 'Copy Link To Component',
        preview: 'Preview Component in Activity',
        add: 'Add Component Below'
      }"
      :first="first"
      :last="last"
      @movePanel="arg => emit('move-panel', component._id, arg.action)"
      @panelAction="arg => emit('panel-action', arg)"
      :id="`components.c${component._id}`"
    >
      <template #title>
        <icon v-if="isFailed" icon="triangle-exclamation" class="error-icon" />
        <span>{{ numberAndType }}</span>
        <span class="componentText">{{ text }}</span>
      </template>
      <template #collapsedHeader>
        <collapsed-header-component
          :component="component"
          :variable-context="variableContext"
          @change="arg => emit('change', arg)"
          :name="`${sectionName}.components.c${component._id}`"
        />
      </template>
      <template #panel>
        <split-view-component
          v-if="isSplitView"
          :component="component"
          :variable-context="variableContext"
          :name="`${sectionName}.components.c${component._id}`"
          :sectionIndex="sectionIndex"
          :questionNumber="questionNumber"
          @change="arg => emit('change', arg)"
          @movePanel="(...args) => emit('move-panel', ...args)"
        />
        <edit-component
          v-if="component.componentType !== 'SplitView'"
          :component="component"
          :variable-context="variableContext"
          @change="arg => emit('change', arg)"
          :name="`${sectionName}.components.c${component._id}`"
          :can-hide-component="canHideComponent"
        />
      </template>
    </drag-panel>
  </card>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import { useFormErrors } from 'vee-validate'
import memo from 'src/shared/hooks/memo'
import { getText } from 'src/shared/components/editor/utils'
import DragPanel from './DraggablePanel'
import EditComponent from './Component'
import SplitViewComponent from './SplitViewComponent'
import CollapsedHeaderComponent from './CollapsedHeaderComponent'
import Card from 'src/shared/components/global/Card.vue'

const emit = defineEmits(['change', 'move-panel', 'panel-action'])
const props = defineProps({
  component: {
    type: Object,
    required: true
  },
  questionNumber: Function,
  sectionName: {
    type: String,
    required: true
  },
  sectionIndex: {
    type: Number,
    required: true
  },
  variableContext: {
    type: Object,
    required: true
  },
  first: Boolean,
  last: Boolean,
  nested: Boolean
})

const TYPES_MAP = {
  GeneralNote: 'Announcement',
  GeneralInstruction: 'Numbered Instruction',
  GridGraphQuestion: 'Data Table & Graph',
  GridQuestion: 'Data Table',
  OpenEndedQuestion: 'Open Ended Question',
  EmbeddedInstance: 'Video Instance',
  StudentInstance: 'Student Instance Upload',
  InstructorInstance: 'Instructor Instance Upload',
  MultipleChoiceQuestion: 'Multiple Choice Question',
  IFrame: 'Embedded Web Content',
  PhetIOSim: 'Legacy PhET-iO Simulation',
  PhetIOSimulation: 'PhET-iO Simulation',
  NumericalQuestion: 'Numerical Question',
  DragDropQuestion: 'Drag & Drop',
  SplitView: 'Split View'
}
const inherited = inject('inherited')

const numberAndType = computed(() => {
  const type = TYPES_MAP[props.component.componentType] ?? 'Unknown'
  const questionNumber = props.questionNumber
    ? props.questionNumber(props.component._id)
    : null
  return questionNumber && props.component.componentType !== 'SplitView'
    ? `${questionNumber}. ${type}:`
    : `${type}:`
})

const text = computed(() => {
  if (props.component.componentType === 'IFrame') {
    return props.component.url ? `${props.component.url}` : ''
  } else if (props.component.componentType === 'EmbeddedInstance') {
    return props.component.title ?? ''
  }
  return props.component.text
    ? getText(props.component.text, props.variableContext)
    : ''
})

const isSplitView = computed(
  () => props.component.componentType === 'SplitView'
)

const ariaLabel = computed(() => `${numberAndType.value} ${text.value}`)

const canHideComponent = computed(() => {
  if (!props.component || !inherited.value.prevSubmittableComponentMap)
    return false

  const previousQuestionId =
    inherited.value.prevSubmittableComponentMap[props.component._id]

  return !!previousQuestionId
})
const formErrors = useFormErrors()

const isFailed = memo(() => {
  return Object.keys(formErrors.value).some(key =>
    key.startsWith(`${props.sectionName}.components.c${props.component._id}`)
  )
})

const root = ref()
defineExpose({
  focus() {
    root.value?.focus()
  }
})
</script>

<style lang="scss" scoped>
.truncate-title :deep() {
  .panel-text {
    width: 100%;
  }

  .panel-title {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.componentText {
  font-weight: 400;
  margin-left: 5px;
}

.activity-panel-card {
  min-height: 110px;
  border: 2px solid transparent;
  &:hover {
    box-sizing: border-box;
    box-shadow:
      0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 0px 10px rgba(52, 189, 189, 0.5);
    border-radius: 5px;
  }

  &:focus-within {
    border: 2px solid #158484;
    box-sizing: border-box;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}

.activity-panel-card-errors {
  border: 2px solid #cc6114;
}

.activity-panel-card-split-view {
  background: rgba(118, 81, 166, 0.1);

  .dragable-panel {
    background: transparent;
  }
}

.activity-panel-card-errors :deep(.panel-heading-title) {
  color: #cc6114;

  & .error-icon {
    font-size: 16px;
    margin-right: 5px;
  }
}
</style>
