<template>
  <span>
    <activity-objective-summary-section :variable-context="variableContext" />
    <activity-panels-section :variable-context="variableContext" />
  </span>
</template>

<script>
import ActivityObjectiveSummarySection from './ActivityObjectiveSummarySection.vue'
import ActivityPanelsSection from './ActivityPanelsSection.vue'

export default {
  name: 'ActivityContentTab',
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  components: { ActivityObjectiveSummarySection, ActivityPanelsSection }
}
</script>
