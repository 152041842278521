<template>
  <modal class="search-modal" @submit="submit" aria-label="Activity Search">
    <modal-body>
      <div class="search-modal__search-input">
        <search-input
          ref="input"
          v-model="localQuery.q"
          @input="onSearchInput"
          class="search-modal__search-input-field"
          aria-label="Search Activities"
          placeholder="Search activity by name"
          data-focus
        />
        <modal-button-submit>Search</modal-button-submit>
      </div>
      <filter-chips
        :modelValue="localQuery"
        @update:modelValue="onChipRemoved"
        class="chips hidden-xs"
      />
      <div class="search-modal__results hidden-xs">
        <div class="search-modal__results-left">
          <span class="search-modal__results-header">Do you mean: </span>
          <p v-if="!localQuery.q" class="search-modal__placeholder">
            Begin typing to see suggestions
          </p>
          <p
            v-else-if="!suggestions || suggestions.length === 0"
            class="search-modal__placeholder"
          >
            No matching catgories found
          </p>
          <ul class="search-modal__suggestion-list">
            <li v-for="(suggestion, index) in suggestions" :key="index">
              <form-button
                class="search-modal__suggestion"
                :title="suggestion.name"
                link
                @click="() => addSuggestion(suggestion)"
                >{{ suggestion.name }}</form-button
              >
            </li>
          </ul>
        </div>

        <div class="search-modal__results-right">
          <div class="search-modal__columns">
            <div>
              <span class="search-modal__results-header hidden-xs"
                >{{
                  textSearched
                    ? `Top results for "${textSearched}"`
                    : 'Top results'
                }}
              </span>
              <modal-button-submit
                link
                v-if="textSearched"
                class="search-modal__total-button hidden-xs"
                >({{ activityCount }} total)</modal-button-submit
              >
            </div>
            <ul class="search-modal__card-list hidden-xs">
              <li v-for="activity in activities" :key="activity.id">
                <activity-search-modal-card
                  @click="onClick"
                  :activity="activity"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </modal-body>
  </modal>
</template>

<script>
import { ref, computed, inject, onBeforeMount } from 'vue'
import debounce from 'lodash/debounce'
import client from 'src/shared/api-client.ts'
import {
  Modal,
  ModalBody,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import ActivitySearchModalCard from './ActivitySearchModalCard'
import FilterChips from '../components/FilterChips'
import useCategoryFilters from './filters/use-category-filters'

export default {
  emits: ['submit'],
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { roots } = useCategoryFilters()
    const modal = inject('$modal')

    const localQuery = ref({
      ...props.query,
      categories: Object.fromEntries(
        Object.entries(props.query.categories).map(([key, value]) => [
          key,
          value.slice()
        ])
      )
    })

    const textSearched = computed(() => {
      return localQuery.value.q
    })

    const activities = ref([])

    const activityCount = ref(0)

    const suggestions = ref()

    let currentRequest

    const loadActivities = async () => {
      const request = client.libraries.searchActivities({
        libraryId: localQuery.value.library,
        page: 1,
        limit: 3,
        ...(!!localQuery.value.q && { text: localQuery.value.q }),
        ...(localQuery.value.isFree &&
          localQuery.value.isFree !== 'both' && {
            isFree: localQuery.value.isFree === 'true'
          }),
        ...(localQuery.value.isArchived !== 'both' && {
          isArchived: localQuery.value.isArchived === 'true'
        }),
        ...(localQuery.value.autograde && {
          minAutograde:
            parseFloat(localQuery.value.autograde.split('-')[0]) / 100,
          maxAutograde:
            parseFloat(localQuery.value.autograde.split('-')[1]) / 100
        }),
        sort: localQuery.value.q ? 'relevance' : 'updatedAt',
        dir: 'desc',
        ...Object.keys(localQuery.value.categories || {}).reduce(
          (filters, key) => {
            if (localQuery.value.categories[key]?.length > 0) {
              filters[key] = localQuery.value.categories[key]
            }
            return filters
          },
          {}
        )
      })
      currentRequest = request
      const response = await request
      // If loadActivities was called while this request was in progress,
      // currentRequest will be the later request, so we should ignore it.
      if (request === currentRequest) {
        activities.value = response.page
        activityCount.value = response.total
      }
    }
    const loadActivitiesDebounced = debounce(loadActivities, 1000)

    const loadSuggestions = async () => {
      if (localQuery.value.q) {
        const response = await client.libraries.searchAutocompleteFilters({
          text: localQuery.value.q,
          limit: 9
        })
        suggestions.value = response
      }
    }

    const updateSearch = newQuery => {
      localQuery.value = newQuery
      loadActivities()
    }

    const addSuggestion = suggestion => {
      let root = roots.value[suggestion.root]
      if (root === 'subjects' && suggestion.root !== suggestion.parent) {
        root = 'topics'
      }
      const currentList = localQuery.value.categories[root] ?? []
      if (!currentList.includes(suggestion.slug)) {
        localQuery.value.categories[root] = [
          ...currentList.filter(slug => !suggestion.ancestors.includes(slug)),
          suggestion.slug
        ]
      }

      localQuery.value.q = ''
      suggestions.value = null
      loadActivities()
    }

    function submit(e) {
      e.done({
        updatedQuery: {
          ...localQuery.value,
          sort: localQuery.value.q ? 'relevance' : 'updatedAt',
          dir: 'desc',
          page: 1
        }
      })
    }

    const onClick = () => {
      modal.close()
    }

    const onSearchInput = debounce(async () => {
      await loadActivitiesDebounced()
      if (localQuery.value.q) {
        await loadSuggestions()
      } else {
        suggestions.value = null
      }
    })

    const onChipRemoved = async updatedQuery => {
      localQuery.value = updatedQuery
      await loadActivitiesDebounced()
    }

    onBeforeMount(async () => {
      await loadActivities()
      await loadSuggestions()
    })

    return {
      localQuery,
      submit,
      loadActivities,
      activities,
      activityCount,
      textSearched,
      onClick,
      onSearchInput,
      suggestions,
      addSuggestion,
      updateSearch,
      onChipRemoved
    }
  },
  components: {
    Modal,
    ModalBody,
    ModalButtonSubmit,
    ActivitySearchModalCard,
    FilterChips
  }
}
</script>
<style lang="scss" scoped>
.search-modal__search-input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.search-modal__search-input .search-input {
  width: 100%;
}

.search-modal {
  width: 800px;
  height: 400px;
}
.search-modal__search-input-field {
  width: 100%;
}

.chips {
  margin-top: 5px;
  margin-bottom: 15px;
}

.search-modal__total-button {
  margin: 0;
}

.search-modal__card-list {
  display: flex;
  margin-top: 15px;
  padding: 0;
}

.search-modal__card-list li {
  list-style: none;
  margin: 0 10px 0 0;
  padding: 0;
  height: 175px;
  width: 170px;
}

.search-modal__results-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-right: 5px;
}

.search-modal__results {
  display: flex;
}

.search-modal__results-left {
  width: 30%;
}
.search-modal__results-right {
  width: 70%;
}

.search-modal__suggestion-list {
  list-style-type: none;
  padding: 0;
  width: 200px;
  margin-top: 10px;
}

.search-modal__suggestion {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  overflow: hidden;
}

.search-modal__placeholder {
  color: grey;
  margin-top: 10px;
}

@media (max-width: $screen-xs-max) {
  .search-modal {
    width: 80%;
    height: auto;
  }
}
</style>
