<template>
  <loading-container :loading="isLoading">
    <async-form style="margin-top: 0" @submit="submit" persist>
      <activity
        v-slot="{ variableContext }"
        :activity="activity"
        :response="response"
      >
        <sticky-header>
          <template #primary-navigation>
            <breadcrumb v-if="klass">
              <breadcrumb-item
                :to="{
                  name: 'classes'
                }"
                >My Classes
              </breadcrumb-item>
              <breadcrumb-item
                :to="{
                  name: 'existing_class',
                  params: { id: klass.classId }
                }"
                >{{ klass.name }}
              </breadcrumb-item>
              <breadcrumb-item
                :to="{
                  name: 'assignment_scores',
                  params: { assignmentId: assignmentId },
                  query: $route.query
                }"
                >Scores View
              </breadcrumb-item>
            </breadcrumb>
          </template>
          <template #secondary-navigation>
            <router-link
              v-if="cursorLinks?.prev"
              class="previous-button"
              :to="{
                name: 'grade_response',
                params: {
                  assignmentId,
                  responseId: cursorLinks.prev
                },
                query: $route.query
              }"
            >
              <icon icon="arrow-left" />
              Previous
            </router-link>
            <button-dropdown
              unstyled
              center
              class="directory_dropdown"
              menu-class="grade-response-view__student-menu"
            >
              <template #button="{ isOpen }">
                <div>
                  <span>
                    {{
                      isGroupAssignment
                        ? response.groupName
                        : response.owner.lastName +
                          ', ' +
                          response.owner.firstName
                    }}
                  </span>
                  <icon
                    class="dropdown-icon"
                    :icon="isOpen ? 'caret-up' : 'caret-down'"
                  />
                </div>
              </template>
              <dropdown-link
                :to="{
                  name: 'grade_response',
                  params: {
                    assignmentId,
                    responseId: entry.id
                  },
                  query: $route.query
                }"
                v-for="entry in otherResponses"
                :key="entry.id"
              >
                <template v-if="isGroupAssignment">
                  {{ entry.groupName }}
                </template>
                <template v-else>
                  {{ entry.owner.lastName }}, {{ entry.owner.firstName }}
                </template>
              </dropdown-link></button-dropdown
            >

            <router-link
              v-if="cursorLinks?.next"
              class="next-button"
              :to="{
                name: 'grade_response',
                params: {
                  assignmentId,
                  responseId: cursorLinks.next
                },
                query: $route.query
              }"
            >
              Next
              <icon icon="arrow-right" />
            </router-link>
          </template>
          <template #page-action>
            <toggle
              link
              :toggle="showAnswer"
              class="toggle-answer-key"
              @toggle="toggleAnswerKey"
            >
              <template #on>Hide Answer Key</template>
              <template #off>View Answer Key</template>
            </toggle>

            <toggle link :toggle="show" @toggle="onToggleClick">
              <template #on>Hide Full Notes</template>
              <template #off>View Full Notes</template>
            </toggle>
          </template>
          <template #title>
            <sticky-header-title>
              {{ activity?.name }}
            </sticky-header-title>
          </template>
          <template #sub-title="{ isStuck }">
            <sticky-header-sub-title>
              <template v-if="!isGroupAssignment"
                >Individual Assignment</template
              >
              <activity-group-name v-if="isGroupAssignment" />
              <activity-grading-progress />
              <activity-score
                :is-stuck="isStuck"
                grading
                @change="handleScoringChange"
              />
            </sticky-header-sub-title>
          </template>
          <template #actions>
            <button-dropdown right>
              <template #button>
                <icon icon="ellipsis-v" />
                <span class="sr-only">Actions</span>
              </template>
              <dropdown-action
                v-if="assignment.endDate && !response?.extensionEndDate"
                @click="grantExtension()"
              >
                Grant Extension
              </dropdown-action>
              <dropdown-action
                v-if="assignment.endDate && response?.extensionEndDate"
                @click="grantExtension(true)"
              >
                Edit Extension
              </dropdown-action>
              <dropdown-action
                v-if="assignment.endDate && response?.extensionEndDate"
                @click="cancelExtension(response)"
              >
                Revert to Original Due Date
              </dropdown-action>
              <dropdown-action
                v-if="response?.gradingProgress === 'not-ready'"
                @click="updateResponseStatus('pending')"
              >
                Submit Assignment
              </dropdown-action>

              <dropdown-action
                v-if="
                  (response?.gradingProgress === 'graded' ||
                    response?.gradingProgress === 'pending') &&
                  !response?.isPastDueDate
                "
                @click="updateResponseStatus('not-ready')"
              >
                Reopen Assignment
              </dropdown-action>
              <dropdown-action
                v-if="response?.gradingProgress !== 'graded'"
                @click="updateResponseStatus('graded')"
              >
                Mark As Final Grade
              </dropdown-action>
              <dropdown-action
                v-if="response?.gradingProgress === 'graded'"
                @click="updateResponseStatus('pending')"
              >
                Mark As Pending Grade
              </dropdown-action>
              <li class="divider" role="separator" />
              <dropdown-action
                @click="syncGrades()"
                v-if="
                  manualLtiSyncEnabled &&
                  response?.activityProgress === 'submitted'
                "
              >
                Re-Sync Grade to LMS
              </dropdown-action>
              <dropdown-action @click="regradeAutogradedQuestions()">
                Regrade Autograded Questions</dropdown-action
              >
              <dropdown-action
                v-if="activity?.sections.some(s => s.completeFirst)"
                @click="unlockLockedSections()"
              >
                Reopen Locked Sections</dropdown-action
              >
              <dropdown-action @click="resetAutogradedQuestionSubmissions()">
                Reset Autograded Question Submissions
              </dropdown-action>

              <dropdown-action @click="resetResponse()">
                Clear Student Response
              </dropdown-action>
              <dropdown-action
                class="hidden-lg hidden-md"
                @click="toggleAnswerKey"
              >
                <span v-if="showAnswer">Hide Answer Key</span>
                <span v-else>View Answer Key</span>
              </dropdown-action>
            </button-dropdown>

            <select-field
              v-if="canChangeStatus"
              :modelValue="response?.gradingProgress"
              aria-label="Assignment Status"
              @update:modelValue="updateResponseStatus"
            >
              <option value="pending">Pending Grade</option>
              <option value="graded">Final Grade</option>
            </select-field>

            <submit-button>
              <template #default>Save Progress</template>
              <template #submitting>Saving</template>
              <template #submitted>Saved</template>
            </submit-button>
          </template>
        </sticky-header>
        <activity-objectives :variable-context="variableContext" />
        <activity-notes :show="show" :variable-context="variableContext" />
        <activity-sections v-slot="{ section }">
          <activity-section-with-score
            :section="section"
            override
            open-by-default
            :variable-context="variableContext"
          >
            <template #dirtyResponseFlag>
              <span
                v-if="isResponseDirty"
                class="pi-action-icon bg-warning pull-right"
              >
                <icon icon="times" class="text-warning" />
                <strong>Unsaved Changes</strong>
              </span>
              <span
                v-else-if="isResponseSaved"
                class="pi-action-icon bg-success pull-right"
              >
                <icon icon="check" class="text-success" />
                <strong>Saved</strong>
              </span>
            </template>
            <activity-section-components v-slot="{ component }">
              <activity-split-view-component
                v-if="component.componentType === 'SplitView'"
                :ref="el => (componentRefs[component._id] = el)"
                :component="component"
                :componentRefs="componentRefs"
                :variable-context="variableContext"
                overrideHiding
                @change="updateResponse"
                @updateComponentRef="val => (componentRefs = val)"
                :viewAsStudent="false"
                :answersShown="showAnswer"
                grading
                viewMode="inline"
              />
              <activity-section-component
                v-if="component.componentType !== 'SplitView'"
                :component="component"
                :ref="el => (componentRefs[component._id] = el)"
                :variable-context="variableContext"
                overrideHiding
                @change="updateResponse"
              >
                <activity-section-component-renderer
                  :component="component"
                  :variable-context="variableContext"
                  :answersShown="showAnswer"
                  :viewAsStudent="false"
                  :canSubmit="false"
                  grading
                />
              </activity-section-component>
            </activity-section-components>
            <activity-section-lock-override
              @lock="lockSection"
              @unlock="unlockSection"
            />
          </activity-section-with-score>
        </activity-sections>
      </activity>
    </async-form>
  </loading-container>
</template>
<script setup lang="ts">
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  defineProps,
  defineEmits,
  inject
} from 'vue'
import Activity from 'src/modules/activities/components/Activity.vue'
import ActivityObjectives from 'src/modules/activities/components/ActivityObjectives.vue'
import ActivityNotes from 'src/modules/activities/components/ActivityNotes.vue'
import ActivitySections from 'src/modules/activities/components/ActivitySections.vue'
import ActivitySectionWithScore from 'src/modules/activities/components/ActivitySectionWithScore.vue'
import ActivitySectionComponents from 'src/modules/activities/components/ActivitySectionComponents.vue'
import ActivitySectionComponent from 'src/modules/activities/components/ActivitySectionComponent.vue'
import ActivitySectionLockOverride from 'src/modules/activities/components/ActivitySectionLockOverride.vue'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal.vue'
import extensionModal from 'src/modules/assignments/components/ExtensionModal.vue'
import { notConcurrent } from 'src/setup/async'
import ActivityScore from 'src/modules/activities/components/ActivityScore.vue'
import ActivityGroupName from 'src/modules/activities/components/ActivityGroupName.vue'
import ActivitySectionComponentRenderer from '../components/ActivitySectionComponentRenderer.vue'
import ActivitySplitViewComponent from 'src/modules/activities/components/ActivitySplitViewComponent.vue'
import ActivityGradingProgress from 'src/modules/activities/components/ActivityGradingProgress.vue'
import UnlockSectionsConfirmationModal from 'src/shared/components/modals/UnlockSectionsConfirmationModal.vue'
import UnsavedChangesModal from 'src/shared/components/modals/UnsavedChangesModal.vue'
import client from 'src/shared/api-client'
import {
  AssignmentsResponse,
  GetAssignmentActivityResponse,
  GetResponseResponse,
  StudentResponse
} from '@pi/api-types'
import { useFlash } from 'src/shared/hooks/flash'
import { useFeatureFlag } from 'src/shared/hooks/feature-flags'
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRouter } from 'vue-router'

const props = defineProps<{
  assignmentId: string
  responseId: string
  componentId?: string
  sort?: { field: string; dir?: string }
}>()

const manualLtiSyncEnabled = useFeatureFlag('manual_lti_sync')
const $router = useRouter()
const emit = defineEmits(['update:response'])
const $modal = inject<any>('$modal')
const flash = useFlash()
const componentRefs = ref<Record<string, any>>({})
const activity = ref<GetAssignmentActivityResponse | null>(null)
const assignment = ref<AssignmentsResponse | null>(null)
const response = ref<GetResponseResponse | null>(null)
const isLoadingAssignment = ref(true)
const isLoadingResponse = ref(true)
const cursorLinks = ref({ next: undefined, prev: undefined })
const show = ref(true)
const isResponseDirty = ref(false)
const isResponseSaved = ref(false)
const showAnswer = ref(false)
const klass = ref<{
  classId: string
  name: string
  fullClassName: string | null
} | null>(null)
const directory = ref<StudentResponse[]>([])

const canChangeStatus = computed(() => {
  return (
    response.value &&
    (response.value.gradingProgress === 'graded' ||
      response.value.gradingProgress === 'feedback' ||
      response.value.gradingProgress === 'pending')
  )
})

const isGroupAssignment = computed(() => {
  return (
    response.value &&
    response.value.assignment &&
    response.value.assignment.assignedTo === 'groups'
  )
})

const isLoading = computed(() => {
  return isLoadingAssignment.value || isLoadingResponse.value
})

const otherResponses = computed(() => {
  return directory.value.filter((entry: any) => entry.id !== response.value?.id)
})

const toggleAnswerKey = () => {
  showAnswer.value = !showAnswer.value
}

const beforeUnload = (e: BeforeUnloadEvent) => {
  const text = 'You have unsaved changes.'
  if (isResponseDirty.value) {
    e.returnValue = text
    return text
  }
  return
}

const onToggleClick = (value: boolean) => {
  show.value = value
}

const regradeAutogradedQuestions = async () => {
  if (!assignment.value || !response.value) return
  const { status } = await $modal.show(ConfirmModal, {
    text: 'Regrading autograded questions will overwrite any manual grading changes to autograded questions. This cannot be undone.',
    prompt: 'Are you sure you want to regrade autograded questions? '
  })

  if (status === 'ok') {
    await client.assignments.regrade({
      assignmentId: assignment.value.id,
      assignmentResponses: [response.value.id]
    })
    flash.success(`Autograded questions regraded.`)
  }
}

const resetAutogradedQuestionSubmissions = async () => {
  if (!assignment.value || !response.value) return
  await client.assignments.resetSubmissionsBulk({
    assignmentId: assignment.value.id,
    assignmentResponses: [response.value.id]
  })
  await loadResponse()
  flash.success(`Response submissions reset to zero.`)
}

const loadResponse = async (reloading = false) => {
  if (!reloading) isLoadingResponse.value = true
  const res = await client.assignments.getResponseById({
    assignmentId: props.assignmentId,
    responseId: props.responseId,
    sort: props.sort ?? { field: '', dir: '' }
  })
  const assignmentEndDate = res?.assignment?.endDate
    ? new Date(res.assignment.endDate)
    : undefined
  const extensionEndDate = res?.extensionEndDate
    ? new Date(res.extensionEndDate)
    : undefined

  const endDate =
    extensionEndDate &&
    (!assignmentEndDate || extensionEndDate > assignmentEndDate)
      ? extensionEndDate
      : assignmentEndDate
  const isPastDueDate =
    endDate && endDate < new Date() && res?.gradingProgress !== 'feedback'

  response.value = {
    ...res,
    isPastDueDate
  }
  if (!reloading) isLoadingResponse.value = false
}

const loadAssignment = async () => {
  isLoadingAssignment.value = true
  const [activityData, assignmentData, allResponses] = await Promise.all([
    client.assignments.getActivity({
      assignmentId: props.assignmentId
    }),
    client.assignments.get({ assignmentId: props.assignmentId }),
    client.assignments.getAllResponses({
      assignmentId: props.assignmentId
    })
  ])
  assignment.value = assignmentData as AssignmentsResponse
  activity.value = activityData
  directory.value = allResponses.page.sort((a: any, b: any) => {
    if (
      isGroupAssignment.value ||
      (Object.prototype.hasOwnProperty.call(a, 'groupName') &&
        Object.prototype.hasOwnProperty.call(b, 'groupName'))
    ) {
      return a.groupName.localeCompare(b.groupName)
    } else {
      const aName = a.owner.lastName + ',' + a.owner.firstName
      const bName = b.owner.lastName + ',' + b.owner.firstName
      return aName.localeCompare(bName)
    }
  })

  const body = await client.classes.get({
    classId: (assignmentData as AssignmentsResponse).class
  })

  const className =
    body.name.length < 15
      ? body.name
      : body.name.substring(0, 4) +
        '\u2026' +
        body.name.substring(body.name.length - 8)
  klass.value = {
    classId: body.id,
    name: className,
    fullClassName: body.name === className ? null : body.name
  }
  isLoadingAssignment.value = false
}

const save = notConcurrent(async () => {
  await client.assignments.gradeResponse({
    assignmentId: props.assignmentId,
    id: props.responseId,
    gradingProgress: response.value?.gradingProgress,
    lockedSections: response.value?.lockedSections,
    scoringType: response.value?.scoringType,
    customScore: response.value?.customScore,
    responses: response.value?.responses.map(
      ({ score, comments, ...res }: any) => ({
        ...res,
        // Filter out null for score and comments.
        ...(score === null ? {} : { score: score }),
        ...(comments === null ? {} : { comments: comments })
      })
    )
  })
  isResponseDirty.value = false
  isResponseSaved.value = true

  await loadResponse(true)
})

const reset = async () => {
  await client.assignments.resetResponse({
    assignmentId: props.assignmentId,
    responseId: props.responseId
  })
  await loadResponse()
  flash.success('Response saved successfully.')
}

const submit = async (e: any) => {
  try {
    await save()
    flash.success('Response saved successfully.')
    if (e.action === 'saveAndPrevious') {
      $router.push({
        name: 'grade_response',
        params: {
          assignmentId: props.assignmentId,
          responseId: response.value?.prev
        }
      })
    } else if (e.action === 'saveAndNext') {
      $router.push({
        name: 'grade_response',
        params: {
          assignmentId: props.assignmentId,
          responseId: response.value?.next
        }
      })
    }
    e.done()
  } catch (error) {
    e.done(false)
    throw error
  }
}

const updateResponse = ({
  response: res,
  isDirty
}: {
  response: any
  isDirty: boolean
}) => {
  if (!response.value) return
  isResponseDirty.value = isResponseDirty.value || isDirty
  const index = response.value?.responses.findIndex(
    (r: any) => r.component === res.component
  )
  if (index >= 0) {
    response.value?.responses.splice(index, 1, res)
  } else {
    response.value?.responses.push(res)
  }
}

const lockSection = async (index: number) => {
  if (!activity.value || !response.value) return
  if (activity.value?.sections[index].completeFirst) {
    response.value.lockedSections.push(index)
    await save()
  }
}

const unlockSection = async (index: number) => {
  if (!response.value) return
  if (activity.value?.sections[index].completeFirst) {
    response.value.lockedSections = response.value.lockedSections.filter(
      (i: number) => i !== index
    )
    await save()
  }
}

async function unlockLockedSections() {
  if (!assignment.value || !response.value) return
  const request = { lockedSections: [] }
  await client.assignments.gradeResponse({
    assignmentId: assignment.value.id,
    id: response.value.id,
    ...request
  })
  flash.success(`Sections unlocked`)
}

const syncGrades = async () => {
  if (!response.value || !assignment.value) return
  await client.assignments.resyncGradesBulk({
    assignmentId: assignment.value.id,
    assignmentResponses: [response.value.id]
  })
  flash.success('Grade resync initiated.')
}

const updateResponseStatus = async (newProgress: string) => {
  if (!response.value) return
  response.value.gradingProgress = newProgress

  isResponseDirty.value = true
  if (newProgress === 'feedback' && response.value.lockedSections.length) {
    const { status } = await $modal.show(UnlockSectionsConfirmationModal, {
      message:
        'Do you want to allow student to work on previously locked sections?'
    })
    if (status === 'yes') {
      response.value.lockedSections = []
    } else if (status === 'cancel') {
      return false
    }
  }
  await save()
  return
}

const resetResponse = async () => {
  const { status } = await $modal.show(ConfirmModal, {
    text: `Caution: This will permanently delete all responses for this assignment for this student. There is no 'undo' for this action.`,
    prompt:
      "Are you sure you want delete this student's responses to this assignment?"
  })

  if (status === 'ok') {
    await reset()
  }
}

const grantExtension = async (edit?: boolean) => {
  if (!response.value) return
  const student = [
    {
      id: response.value.id,
      studentId: isGroupAssignment.value
        ? undefined
        : response.value.owner?._id,
      name: isGroupAssignment.value
        ? response.value.groupName
        : `${response.value.owner?.lastName}, ${response.value.owner?.firstName}`
    }
  ]
  await $modal.show(extensionModal, {
    assignment: assignment.value,
    responses: student,
    isEdit: edit ?? false,
    selectedStudents: [response.value.id],
    isGroupAssignment: isGroupAssignment.value
  })
}

const cancelExtension = async (res: any) => {
  if (!assignment.value) return
  await client.assignments.cancelExtensions({
    assignmentId: assignment.value.id,
    assignmentResponses: [res.id]
  })
  flash.success(`Extension cancelled.`)
}

const handleScoringChange = ({
  scoringType,
  customScore
}: {
  scoringType: string
  customScore: number | null
}) => {
  if (!response.value) return
  if (scoringType) {
    response.value.scoringType = scoringType
  }

  if (customScore === null) {
    customScore = 0
  }

  if (response.value.scoringType === 'custom') {
    response.value.customScore = customScore
    isResponseDirty.value = true
  }
}

onBeforeRouteLeave(async (to, from, next) => {
  if (isResponseDirty.value) {
    const { status } = await $modal.show(UnsavedChangesModal)
    if (status === 'ok') {
      window.removeEventListener('beforeunload', beforeUnload)
      next()
    } else {
      next(false)
    }
  } else {
    window.removeEventListener('beforeunload', beforeUnload)
    next()
  }
})

onBeforeRouteUpdate(async (to, from, next) => {
  if (isResponseDirty.value) {
    const { status } = await $modal.show(UnsavedChangesModal)
    if (status === 'ok') {
      isResponseDirty.value = false
    } else {
      next(false)
    }
  }
  next()
})

watch(
  () => props.responseId,
  async () => {
    await loadResponse()
    if (response.value?.next || response.value?.prev) {
      cursorLinks.value = {
        next: response.value?.next,
        prev: response.value?.prev
      }
    }
  },
  { immediate: true }
)

watch(() => props.assignmentId, loadAssignment)

watch(
  () => props.componentId,
  async () => {
    await loadResponse()
  }
)

onMounted(async () => {
  await loadAssignment()

  window.addEventListener('beforeunload', beforeUnload)

  let hashParams = window.location.hash
  hashParams = hashParams.replace('#component-', '')
  if (hashParams) {
    const el = componentRefs.value[hashParams]
    if (el) {
      el.$el.scrollIntoView()
    }
  }
  if (props.responseId) {
    const el = componentRefs.value[props.componentId]
    if (el) {
      el.$el.scrollIntoView()
    }
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', beforeUnload)
})
</script>

<style lang="scss" scoped>
h4.display-status {
  font-style: italic;
}

div.bottom-score-name {
  display: flex;
  justify-content: space-between;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.previous-button {
  margin-right: auto;
}

.next-button {
  margin-left: 10px;
}

.pi-action-icon {
  padding: 4px 8px;
  margin: 16px;
  border-radius: 4px;
  svg {
    padding-right: 6px;
  }
}
.dropdown-icon {
  margin-left: 8px;
}

.toggle-answer-key {
  margin-right: 10px;
}
:deep(.directory_dropdown) {
  margin-left: 10px;
}

:deep(.grade-response-view__student-menu) {
  max-height: 400px;
  overflow-y: auto;
}
</style>
