<template>
  <view-container narrow>
    <view-header>
      <view-title>Join Class</view-title>
    </view-header>
    In order to join a class, your instructor needs to provide you with a join
    code or link. Instructors can find this link on their class roster page.
    <async-form class="form-two-column" @submit="onSubmit" :persist="!!klass">
      <form-group>
        <form-label for="classKey">Class Key</form-label>
        <text-input
          id="classKey"
          ref="classKey"
          v-model="classKey"
          rules="required"
          label="class key"
          help-text="Examples: 'abcd1234' or 'ck-abcd1234'"
        />
      </form-group>
      <template v-if="klass">
        <form-group>
          <form-label id="class-name-label">Class Name</form-label>
          <static-value aria-labelledby="class-name-label">
            {{ klass.name }}
          </static-value>
        </form-group>
        <form-group
          class="join-class-instructors"
          v-if="klass.instructors.length"
        >
          <form-label id="instructor-label">Instructor(s)</form-label>
          <static-value aria-labelledby="instructor-label">
            {{ klass.instructors.map(i => i.lastName).join(', ') }}
          </static-value>
        </form-group>
      </template>
      <form-group variant="right-aligned">
        <form-button
          submit
          class="join-class-view__confirm-button"
          :disabled="cannotJoin || endDateInPast"
        >
          {{ klass ? 'Confirm' : 'Continue' }}
        </form-button>
        <strong v-if="cannotJoin" class="text-danger error-message">
          This class is not accepting new students.
        </strong>
        <strong v-else-if="endDateInPast" class="text-danger error-message">
          The end date for this course has passed, new enrollments are not
          permitted. Please see your instructor for assistance.</strong
        >
      </form-group>
    </async-form>
  </view-container>
</template>

<script>
import { mapGetters } from 'vuex'
import client from 'src/shared/api-client'
import { endOfDay, isBefore } from 'date-fns'

export default {
  name: 'JoinClass',
  props: {
    joinClassKey: {
      type: String,
      default: ''
    },
    redirect: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      isLoading: false,
      classKey: this.joinClassKey || '',
      klass: null
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    endDateInPast() {
      return this.klass && this.klass.endDate
        ? isBefore(endOfDay(new Date(this.klass.endDate)), new Date())
        : false
    },
    cannotJoin() {
      return this.klass ? !this.klass.canJoin : false
    }
  },
  async created() {
    if (this.classKey) {
      await this.validate()
    }
  },
  methods: {
    async validate() {
      try {
        const body = await client.classes.validate({ classKey: this.classKey })
        this.klass = body
      } catch (error) {
        if (error.status === 404) {
          this.$refs.classKey.applyError(
            this.classKey.startsWith('bp-')
              ? "This is a bookstore code used to purchase access. You'll need additional information from your instructor to join your class."
              : 'There is not a class with this class key.'
          )
        } else {
          throw error
        }
      }
    },
    async onSubmit(e) {
      try {
        // Go to next step if class is valid.
        if (this.klass) {
          // If not logged in, login in or create account.
          if (!this.isAuthenticated) {
            this.$router.push({
              name: 'join_class_signup',
              params: { step: 'account-select' },
              query: { classKey: this.classKey, redirect: this.redirect }
            })
          }
          // Join the class if it is instructor pay.
          else if (this.klass.paymentSource === 'institution') {
            await client.classes.join({ classKey: this.classKey })
            this.$success(`You joined the class: ${this.klass.name}.`)
            this.$router.push(this.redirect || { name: 'classes' })
          }
          // Collect payment if class is student pay.
          else {
            this.$router.push({
              name: 'join_class_payment',
              query: { classKey: this.classKey, redirect: this.redirect }
            })
          }
          e.done()
        }
        // Otherwise validate the class.
        else {
          await this.validate()
          e.done(false)
        }
      } catch (error) {
        e.done(false)
        throw error
      }
    }
  },
  watch: {
    classKey() {
      this.klass = null
    }
  }
}
</script>

<style lang="scss" scoped>
.form-label {
  width: 82px;
}

.error-message {
  display: block;
  margin: 8px 16px 8px 0;
}

.join-class-view__confirm-button {
  align-self: center;
}

.join-class-instructors {
  font-size: 50px;
}
</style>
