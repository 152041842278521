<template>
  <select-library-modal
    ref="selectLibraryModal"
    title="Select a destination"
    :submit-label="bulkMode ? 'Move Activities' : 'Move Activity'"
    hide-archived
    @submit="submit"
  />
</template>

<script>
import client from 'src/shared/api-client.ts'
import SelectLibraryModal from './SelectLibraryModal'

export default {
  components: {
    SelectLibraryModal
  },
  props: {
    activity: {
      type: Object,
      required: false
    },
    activities: {
      type: Array,
      required: false
    }
  },
  computed: {
    bulkMode() {
      return !this.activity && this.activities && this.activities.length > 0
    }
  },
  methods: {
    async submit({ libraryId, updateDeployDate, done }) {
      if (this.bulkMode) {
        try {
          await client.activities.moveBulk({
            activities: this.activities,
            library: libraryId,
            updateDeployDate
          })
        } catch (error) {
          done(false)
          throw error
        }
      } else {
        try {
          await client.activities.move({
            activityId: this.activity.id,
            destinationLibraryId: libraryId,
            updateDeployDate
          })
        } catch (error) {
          done(false)
          throw error
        }
      }
      const library = await client.libraries.get(libraryId).catch(() => null)
      const successMessage = this.bulkMode
        ? library
          ? `Activities moved to library ${library.name}.`
          : 'Activities moved'
        : library
        ? `Activity ${this.activity.name} moved to library ${library.name}.`
        : `Activity ${this.activity.name} moved.`

      this.$refs.selectLibraryModal.$success(successMessage)
      done()
    }
  }
}
</script>
