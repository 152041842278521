import TeacherSignupView from './views/TeacherSignupView'
import LoginView from './views/LoginView'
import ForgotPasswordView from './views/ForgotPasswordView'
import ChangePasswordView from './views/ChangePasswordView'
import JoinClassSignupView from './views/JoinClassSignupView'
import OAuthRedirectView from './views/OAuthRedirectView'
import OAuthLogin from './views/OAuthLogin'
import UserProfileView from './views/UserProfileView'
import client from 'src/shared/api-client'

export default store => [
  {
    name: 'login',
    path: '/login/:step?',
    component: LoginView,
    props: route => ({
      redirect: route.query.redirect,
      step: route.params.step
    }),
    meta: { title: 'Log in' },
    beforeEnter(to, from, next) {
      if (!to.params.step) {
        next({
          ...to,
          params: { ...to.params, step: 'login-select' }
        })
      } else {
        next()
      }
    }
  },
  {
    name: 'logout',
    path: '/logout',
    async beforeEnter() {
      await client.auth.logout()
      window.location.assign('/login')
    }
  },
  {
    name: 'forgot_password',
    path: '/forgot-password',
    component: ForgotPasswordView,
    meta: { title: 'Forgot Password', checksAuth: true }
  },
  {
    name: 'change_password',
    path: '/change-password/:resetPasswordToken/:step?',
    component: ChangePasswordView,
    props: true,
    meta: { title: 'Change Password', checksAuth: true }
  },
  {
    name: 'instructor_signup',
    path: '/signup/:token/:step?',
    component: TeacherSignupView,
    props: route => ({
      session: route.query.session,
      step: route.params.step,
      token: route.params.token
    }),
    meta: { title: 'Sign Up' },
    beforeEnter(to, from, next) {
      if (!to.params.step) {
        next({
          ...to,
          params: { ...to.params, step: 'create-account' }
        })
      } else {
        next()
      }
    }
  },
  {
    name: 'instructor_rostering_signup',
    path: '/signup/oauth/:step?',
    component: TeacherSignupView,
    props: route => ({
      step: route.params.step || 'sign-up'
    }),
    meta: { title: 'Oauth - Sign Up' }
  },
  {
    name: 'join_class_signup',
    path: '/join-class/:step',
    component: JoinClassSignupView,
    props: route => ({
      classKey: route.query.classKey,
      redirect: route.query.redirect,
      step: route.params.step
    }),
    meta: { title: 'Join Class - Sign Up' }
  },
  {
    name: 'oauth_redirect',
    path: '/login/oauth/:provider(clever|clever-legacy|classLink|google|azure)',
    component: OAuthRedirectView,
    props: route => ({
      code: route.query.code,
      provider: route.params.provider
    }),
    meta: { title: 'OAuth Redirect' }
  },
  {
    name: 'oauth_authorize',
    path: '/oauth/authorize',
    component: OAuthLogin,
    props: route => ({
      response_type: route.query.response_type,
      client_id: route.query.client_id,
      redirect_uri: route.query.redirect_uri,
      state: route.query.state,
      response_mode: route.query.response_mode,
      scope: route.query.scope
    }),
    meta: { title: 'Authorize', requiresAuth: false }
  },
  {
    name: 'edit_profile',
    path: '/profile',
    component: UserProfileView,
    meta: { title: 'Profile', requiresAuth: true }
  },
  {
    name: 'admin_manage_user_profile',
    path: '/admin/manage-user/:id/profile',
    component: UserProfileView,
    props: true,
    meta: { title: 'User Profile', requiresAuth: true }
  }
]
