<template>
  <div class="mb-5">
    <UICard collapsible>
      <div class="w-full flex justify-between items-center">
        <UICardHeading>
          Assignment Dates<span v-if="name">: {{ name }}</span>
        </UICardHeading>
        <button
          v-if="allowRemove"
          @click.prevent="emit('remove')"
          class="new-assignment-card__close"
          type="button"
          aria-label="close"
        >
          <span aria-hidden="true"
            ><UIIcon icon="trash" class="w-4 h-4"></UIIcon
          ></span>
        </button>
      </div>
      <UICardContent>
        <div
          v-if="hasIris"
          class="font-light text-sm text-warning-orange-600 pb-6"
        >
          This activity uses our Iris Color Tool which is not compatible with
          iOS devices and desktop Safari.
        </div>
        <div class="flex justify-start">
          <div class="flex md:w-1/2">
            <form-group class="mr-10">
              <form-label> Start Date </form-label>
              <date-input
                v-model="startDate"
                time
                label="start date"
                help-text="Students can work on the assignment after this date."
              />
            </form-group>
            <form-group>
              <form-label> Due Date </form-label>
              <date-input
                v-model="endDate"
                :disabled="!startDate"
                :rules="{ after: startDate }"
                time
                label="due date"
                :min-date="startDate"
                help-text="Students cannot work on the assignment after this date, and all work will be automatically submitted."
              />
            </form-group>
          </div>
          <div class="md:w-1/2"></div>
        </div>
        <div class="w-full">
          <form-group v-if="visibilityDatesToggle">
            <UISwitch
              v-model="useVisibilityDates"
              :name="uniqueName"
              help-text="Enable Custom Assignment Visibility Dates"
              >Enable Custom Assignment Visibility Dates</UISwitch
            >
          </form-group>
          <div
            class="grid gap-6 grid-cols-2 md:grid-cols-2 lg:grid-cols-2 max-w-fit"
            v-if="useVisibilityDates || !visibilityDatesToggle"
          >
            <form-group class="max-w-s">
              <form-label for="first-visible-date">
                First Visible Date
              </form-label>
              <date-input
                v-model="firstVisibleDate"
                time
                label="first visible date"
                :rules="{ required: !!startDate, before: startDate }"
                :max-date="startDate ?? lastVisibleDate"
                help-text="After this date, students can see this assignment in their list, but cannot open it."
              />
            </form-group>
            <form-group>
              <form-label> Last Visible Date </form-label>
              <date-input
                v-model="lastVisibleDate"
                :disabled="!endDate"
                time
                label="last visible date"
                :rules="{ after: endDate }"
                :min-date="endDate"
                help-text="Students cannot see the assignment after this date."
              />
            </form-group>
          </div>
        </div>
      </UICardContent>
    </UICard>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, Ref, ref } from 'vue'
import { useStore } from 'vuex'
import {
  UISwitch,
  UICard,
  UICardContent,
  UICardHeading,
  UIIcon
} from '@pi/design'

export interface AssignmentDates {
  startDate?: Date
  endDate?: Date
  firstVisibleDate?: Date
  lastVisibleDate?: Date
  useVisibilityDates: boolean
  hasIris?: boolean
}
interface AssignmentDateProps extends AssignmentDates {
  name?: string
  allowRemove?: boolean
}

const props = defineProps<AssignmentDateProps>()
const emit = defineEmits(['update:dates', 'remove'])
const store = useStore()

const uniqueName = ref(
  `useVisiblityDatesToggle-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
)

const startDate: Ref<Date | undefined> = computed({
  get: () => props.startDate,
  set: (value: Date | undefined) =>
    emit('update:dates', { ...props, startDate: value })
})

const endDate: Ref<Date | undefined> = computed({
  get: () => props.endDate,
  set: (value: Date | undefined) =>
    emit('update:dates', { ...props, endDate: value })
})

const firstVisibleDate: Ref<Date | undefined> = computed({
  get: () => props.firstVisibleDate,
  set: (value: Date | undefined) =>
    emit('update:dates', { ...props, firstVisibleDate: value })
})

const lastVisibleDate: Ref<Date | undefined> = computed({
  get: () => props.lastVisibleDate,
  set: (value: Date | undefined) =>
    emit('update:dates', { ...props, lastVisibleDate: value })
})

const useVisibilityDates: Ref<boolean> = computed({
  get: () => props.useVisibilityDates,
  set: (value: boolean) =>
    emit('update:dates', {
      ...props,
      firstVisibleDate: value ? props.startDate : undefined,
      lastVisibleDate: value ? props.endDate : undefined,
      useVisibilityDates: value
    })
})

const visibilityDatesToggle = computed(() =>
  store.getters['features/isEnabled']('visibilityDates')
)

watch(
  () => startDate.value,
  (newStartDate: Date | undefined, oldStartDate: Date | undefined) => {
    if (
      useVisibilityDates.value &&
      (firstVisibleDate.value === oldStartDate ||
        (newStartDate &&
          firstVisibleDate.value &&
          newStartDate < firstVisibleDate.value))
    ) {
      firstVisibleDate.value = newStartDate
    }

    if (!useVisibilityDates.value) {
      firstVisibleDate.value = undefined
    }
  }
)

watch(
  () => endDate.value,
  (newEndDate: Date | undefined) => {
    if (
      useVisibilityDates.value &&
      newEndDate &&
      lastVisibleDate.value &&
      newEndDate > lastVisibleDate.value
    ) {
      lastVisibleDate.value = newEndDate
    }

    if (!newEndDate || !useVisibilityDates.value) {
      lastVisibleDate.value = undefined
    }
  }
)
</script>
