<template>
  <editor-content-base
    v-if="editor"
    class="editor-content"
    :class="{ 'editor-content--inline': inline }"
    :editor="editor"
  />
</template>

<script>
import * as Vue from 'vue'
import { EditorContent as EditorContentBase } from '@tiptap/vue-3'
import VocabDisplayModal from 'src/shared/components/editor/vocab-tooltip/VocabDisplayModal.vue'
import { inject } from 'vue'
import useEditor from './editor-hook'

export default {
  name: 'EditorContent',
  inject: ['$modal'],
  components: {
    EditorContentBase
  },
  emits: ['droptargetchange'],
  props: {
    text: {
      type: [String, Object],
      default: null
    },
    ytext: {
      type: null,
      default: undefined
    },
    variableContext: {
      type: Object,
      default: undefined
    },
    dropTargets: {
      type: [String, Object],
      default: null
    },
    choices: {
      type: [Object],
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    },
    targetStates: {
      type: Object,
      default: undefined
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    },
    toolTip: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const $modal = inject('$modal')
    const onTipActivated = tip => {
      $modal.show(VocabDisplayModal, {
        tip,
        variableContext: props.variableContext
      })
    }
    const editor = useEditor({
      text: Vue.toRef(props, 'text'),
      fragment: Vue.toRef(props, 'ytext'),
      readonly: true,
      variableContext: Vue.toRef(props, 'variableContext'),
      dropTargets: !!props.dropTargets,
      inline: Vue.toRef(props, 'inline'),
      viewAsStudent: Vue.toRef(props, 'viewAsStudent'),
      editorAttributes: {
        translate: 'yes'
      },
      onTipActivated,
      toolTip: Vue.toRef(props, 'toolTip')
    })

    if (props.dropTargets) {
      Vue.watch(editor, editor => {
        const storage = editor.storage['dropTarget']
        storage.setTargetValue = (targetId, choiceId) => {
          if (targetId) {
            emit('droptargetchange', targetId, choiceId)
          }
        }

        storage.targetValues = Vue.toRef(props, 'dropTargets')
        if (props.choices) {
          storage.choices = props.choices
        }
        storage.targetStates = Vue.toRef(props, 'targetStates')
      })
    }

    return {
      editor
    }
  }
}
</script>

<style lang="scss">
.editor-content {
  font-family: $font-family-sans-serif;
  font-size: $student-response-font-size-base;
  line-height: $line-height-base;
  margin-bottom: 5px;

  @media print {
    font-size: $print-font-size-base;
  }

  & > [contenteditable] {
    p {
      margin: 0;
    }

    ul,
    ol {
      padding-left: 32px;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }
  }
}

.editor-content {
  max-width: 800px;
  // This removes newlines that are added after fullwidth inline node views
  [data-node-view-wrapper] + .ProseMirror-separator,
  [data-node-view-wrapper]
    + .ProseMirror-separator
    + .ProseMirror-trailingBreak {
    display: none !important;
  }
}

.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    margin: 0;
    padding: 0;
    border-spacing: 0;
    overflow: visible;
    td,
    th {
      width: 150px;
      border: 1px solid #979797;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
      > p {
        border: 2px solid transparent;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}
.tableWrapper::-webkit-scrollbar {
  width: 12px;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.tableWrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
