import * as types from 'src/setup/store/mutation-types'

import client from 'src/shared/api-client'

const state = {
  classes: [],
  pageCount: 0,
  showArchivedClasses: false
}

export const getters = {
  showArchived({ classes }) {
    return index => {
      return classes[index].showArchived
    }
  }
}

export const mutations = {
  [types.SET_CLASSES](state, classes) {
    state.classes = classes.map(c => ({
      _id: c.id || '',
      name: c.name || '',
      assignments: [],
      classKey: c.classKey || '',
      status: c.status,
      isArchived: c.isArchived,
      showArchived: false,
      role: c.role
    }))
  },
  [types.SET_CLASSES_PAGECOUNT](state, pageCount) {
    state.pageCount = pageCount
  },
  [types.SET_CLASS_ASSIGNMENTS]({ classes }, { id, assignments = [] }) {
    const theClass = classes.find(({ _id }) => _id === id)
    if (theClass) {
      theClass.assignments = assignments.map(
        ({
          id = '',
          startDate,
          endDate,
          status,
          isArchived,
          isDeleted,
          activity = {},
          totalScore = 0,
          extensionEndDate,
          notCreated,
          assignedTo,
          scoreVisibilty,
          studentFeedbackTiming
        }) => ({
          _id: id,
          name: activity.name || '',
          startDate,
          endDate,
          status,
          isArchived,
          isDeleted,
          totalScore,
          extensionEndDate,
          notCreated,
          assignedTo,
          scoreVisibilty,
          studentFeedbackTiming
        })
      )
    }
  },
  [types.SET_CLASS_IS_ARCHIVED]({ classes }, { classId, isArchived }) {
    const theClass = classes.find(({ _id }) => _id === classId)
    if (theClass) {
      theClass.isArchived = isArchived
    }
  },
  [types.SET_SHOW_ARCHIVED_CLASSES](state, show) {
    state.showArchivedClasses = show
  },
  [types.SET_SHOW_ARCHIVED]({ classes }, { index, show }) {
    classes[index].showArchived = show
  },
  [types.SET_ASSIGNMENT_STARTDATE](
    { classes },
    { classId, assignmentId, startDate }
  ) {
    const theClass = classes.find(({ _id }) => _id === classId)
    if (theClass) {
      const assignment = theClass.assignments.find(
        ({ _id }) => _id === assignmentId
      )
      if (assignment) {
        if (startDate) {
          assignment.startDate = startDate
        } else {
          delete assignment.startDate
        }
      }
    }
  },
  [types.SET_ASSIGNMENT_ENDDATE](
    { classes },
    { classId, assignmentId, endDate }
  ) {
    const theClass = classes.find(({ _id }) => _id === classId)
    if (theClass) {
      const assignment = theClass.assignments.find(
        ({ _id }) => _id === assignmentId
      )
      if (assignment) {
        if (endDate) {
          assignment.endDate = endDate
        } else {
          delete assignment.endDate
        }
      }
    }
  },
  [types.SET_ASSIGNMENT_IS_ARCHIVED](
    { classes },
    { classId, assignmentId, isArchived }
  ) {
    const theClass = classes.find(({ _id }) => _id === classId)
    if (theClass) {
      const assignment = theClass.assignments.find(
        ({ _id }) => _id === assignmentId
      )
      if (assignment) {
        assignment.isArchived = isArchived
      }
    }
  },
  [types.SET_ASSIGNMENT_IS_DELETED](
    { classes },
    { classId, assignmentId, isDeleted }
  ) {
    const theClass = classes.find(({ _id }) => _id === classId)
    if (theClass) {
      const assignment = theClass.assignments.find(
        ({ _id }) => _id === assignmentId
      )
      if (assignment) {
        assignment.isDeleted = isDeleted
      }
    }
  }
}

export const actions = {
  loadClasses({ commit }, page) {
    return client.classes.search({ page: page }).then(response => {
      commit(types.SET_CLASSES, response.classes)
      commit(types.SET_CLASSES_PAGECOUNT, parseInt(response.total))
    })
  },
  async loadClassAssignments({ commit }, { classId, field, direction }) {
    const response = await client.classes.getAssignments({
      classId: classId,
      sort: field,
      dir: direction
    })

    commit(types.SET_CLASS_ASSIGNMENTS, {
      id: classId,
      assignments: response
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
