<template>
  <div
    class="activity-card"
    data-testid="activity-card"
    role="group"
    :aria-label="activity.name"
  >
    <div class="activity-card__thumbnail">
      <img role="presentation" :src="thumbnail" />
    </div>
    <p
      class="activity-card__date"
      v-if="dateText && activity.libraryId === 'pivot'"
    >
      {{ dateText }}
    </p>
    <span v-if="activity.isArchived" class="activity-card__archived">
      Archived
    </span>
    <span
      v-else-if="activity.isFree && showFreeTag"
      class="activity-card__free"
    >
      Free
    </span>
    <div class="activity-card__content">
      <p class="activity-card__name">{{ activity.name }}</p>

      <p class="activity-card__description">{{ activity.description }}</p>
      <div class="activity-card__chips">
        <span
          v-for="(category, index) in activity.flags"
          :key="index"
          class="activity-card__chip"
          >{{ category }}</span
        >
      </div>
      <div class="activity-card__autograde" v-if="activity.autogradePercentage">
        {{ questionCount }}
        <em>({{ autogradePercentage }}% Autograded)</em>
      </div>
      <div class="activity-card__actions">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from 'vue'
import defaultThumbnail from 'src/assets/img/activity-card/pivot-interactives-logo-color.png'
import { differenceInDays } from 'date-fns'

export default {
  name: 'ActivityCard',
  provide() {
    return {
      activityCard: Vue.computed(() => ({
        activity: this.activity
      }))
    }
  },
  props: {
    activity: {
      type: Object,
      required: true
    },
    showFreeTag: {
      type: Boolean
    }
  },
  computed: {
    thumbnail() {
      if (this.activity.thumbnail) {
        return this.activity.thumbnail
      }
      return defaultThumbnail
    },
    autogradePercentage() {
      return Math.round(this.activity.autogradePercentage * 100)
    },
    dateText() {
      if (
        differenceInDays(new Date(), new Date(this.activity.deployDate)) < 30
      ) {
        return this.activity.hasReplacementRelationship ? 'Revised' : 'New'
      } else if (
        this.activity.latestChangelogEntryDate &&
        differenceInDays(
          new Date(),
          new Date(this.activity.latestChangelogEntryDate)
        ) < 60
      ) {
        return 'Updated'
      } else {
        return undefined
      }
    },
    questionCount() {
      return this.activity.questionCount
        ? this.activity.questionCount == 1
          ? `${this.activity.questionCount} question`
          : `${this.activity.questionCount} questions`
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  border: 1px solid #ccc;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.activity-card__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 13px 13px 13px 13px;
}
.activity-card__thumbnail {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  background: #efefef;
  overflow: hidden;
  border-radius: 5px 5px 0 0;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

.activity-card__name {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 1px 0;
  overflow: hidden;
}

.activity-card__date {
  position: absolute;
  left: -4px;
  margin-top: 10px;
  padding-left: 5px;
  font-weight: bold;
  font-style: normal;
  background: $plum;
  padding: 0px 20px 0px 10px;
  color: #fff;
  height: 20px;
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
}
.activity-card__free {
  position: absolute;
  right: -4px;
  margin-top: 10px;
  padding-left: 5px;
  font-weight: bold;
  font-style: normal;
  background: $pink;
  padding: 0px 10px 0px 20px;
  color: #fff;
  height: 20px;
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%);
}

.activity-card__archived {
  position: absolute;
  right: -4px;
  margin-top: 10px;
  padding-left: 5px;
  font-weight: bold;
  font-style: normal;
  background: $color-warning;
  padding: 0px 10px 0px 20px;
  color: #fff;
  height: 20px;
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%);
}

.activity-card__description {
  margin: 0 0 16px 0;
  flex-grow: 1;
}

.activity-card__autograde {
  margin: 0 0 8px 0;
}

.activity-card__chips {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.activity-card__chip {
  background-color: #109cbf;
  color: #ffffff;
  border-radius: 20px;
  line-height: 100%;
  padding: 5px 10px 5px 10px;
}
</style>
