<template>
  <view-container normal>
    <view-header>
      <view-title>D2L Brightspace LTI Registration</view-title>
    </view-header>
    <loading-container :loading="loadingConfig">
      <section>
        <collapse-panel
          title="Step 1: Configure Pivot Interactives in Brightspace"
        >
          <form-group>
            <strong>
              This step registers Pivot as a tool in Brightspace. You must have
              permissions to manage LTI Advantage registrations to perform this
              step.
            </strong>
          </form-group>
          <ol>
            <li>
              <form-group>
                In Brightspace, select
                <strong>Manage Extensibility</strong> under the
                <strong>Admin</strong> menu.
              </form-group>
            </li>
            <li>
              <form-group>
                In the <strong>LTI Advantage</strong> tab, click
                <strong>Register Tool</strong>.
              </form-group>
            </li>
            <li>
              <form-group>
                Enter Pivot Interactives into the
                <strong>Name</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Tool Name"
                  :value="config.title"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the text below into the
                <strong>Description</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Description"
                  :value="config.description"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the text below into the
                <strong>Domain</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Domain"
                  :value="config.domain || ''"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the text below into the
                <strong>Redirect URLs</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Redirect URL"
                  :value="config.launchUrl || ''"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the text below into the
                <strong>OpenID Connect Login URL</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="OpenID Connect URL"
                  :value="config.oidcUrl || ''"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the text below into the
                <strong>Keyset URL</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Keyset URL"
                  :value="config.jwksUrl || ''"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                In the <strong>Extensions</strong> field select
                <strong>Assignment and Grade Services</strong> and
                <strong>Deep Linking</strong>. This allows instructors to create
                links to Pivot assignments and allows grades on the assignments
                to be sent back to Brightspace.
              </form-group>
            </li>
            <li>
              <form-group>
                Leave the <strong>Send Institution Role</strong> option
                unselected. Brightspace will send the role of the user in the
                course instead.
              </form-group>
            </li>
            <li>
              <form-group>Click <strong>Register</strong>.</form-group>
            </li>
            <li>
              <form-group>
                You should see a screen titled
                <strong>Brightspace Registration Details</strong>. You will need
                this information to configure Brightspace in Pivot.
              </form-group>
            </li>
          </ol>
        </collapse-panel>
      </section>
      <section>
        <collapse-panel
          title="Step 2: Configure Brightspace in Pivot Interactives"
        >
          <form-group>
            <strong>
              This step establishes the other half of the communication channel
              with Brightspace.
            </strong>
          </form-group>
          <async-form @submit="register" persist>
            <ol>
              <li>
                <form-group>
                  Copy and paste the <strong>Client id</strong> field from
                  Brightspace below.
                </form-group>
                <form-group>
                  <text-input
                    v-model="platformData.clientId"
                    aria-label="Brightspace Client ID"
                    placeholder="Example: 391bd3f6-664c-4b84-9095-c338e7ce7032"
                    label="client ID"
                    rules="required"
                  />
                </form-group>
              </li>
              <li>
                <form-group>
                  Enter the domain name you use to access Brightspace in the
                  field below. Most often, this will be something like
                  <strong>your-institution.brightspace.com</strong>.
                </form-group>
                <form-group>
                  <text-input
                    v-model="platformData.domain"
                    aria-label="Brightspace Domain Name"
                    placeholder="Example: school.brightspace.com"
                    label="domain name"
                    :rules="{
                      required: true,
                      regex: /^((\w|\d|-)+\.)+(\w|\d|-)+(:\d+)?$/
                    }"
                  />
                </form-group>
              </li>
              <li>
                <form-group>
                  When ready, click the button to finish registering
                  Brightspace.
                </form-group>
                <form-group>
                  <submit-button>
                    <template #default>Register</template>
                    <template #submitting>Configuring</template>
                    <template #submitted>Success</template>
                  </submit-button>
                </form-group>
              </li>
            </ol>
          </async-form>
        </collapse-panel>
      </section>
      <section>
        <collapse-panel title="Step 3: Create Deployment in Brightspace">
          <form-group>
            <strong>
              This step determines who in Brightspace has access to Pivot. You
              must have permissions to manage LTI Advantage deployments to
              perform this step.
            </strong>
          </form-group>
          <ol>
            <li>
              <form-group>
                In the tool page in Brightspace, click
                <strong>New Deployment</strong>
              </form-group>
            </li>
            <li>
              <form-group>
                This should take you to a new form. In the
                <strong>Tool</strong> field, select
                <strong>Pivot Interactives</strong>
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the value below into the
                <strong>Name</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Deployment Name"
                  :value="config.title || ''"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                In the <strong>Extensions</strong> field select
                <strong>Assignment and Grade Services</strong> and
                <strong>Deep Linking</strong>.
              </form-group>
            </li>
            <li>
              In the <strong>Security Settings</strong> field, select
              <strong>Org Unit Info</strong>. This allows us to connect your
              Pivot course with your Brightspace course.
            </li>
            <li>
              <form-group>
                You may also select
                <strong>First Name</strong>, <strong>Last Name</strong>, and
                <strong>Email</strong> to create a streamlined integration with
                Pivot. If these are not checked, we will ask the student for
                this information when they launch into Pivot for the first time.
              </form-group>
            </li>
            <li>
              <form-group>
                Select which <strong>Org Units</strong> should have access to
                Pivot.
              </form-group>
            </li>
          </ol>
        </collapse-panel>
      </section>
      <section>
        <collapse-panel title="Step 4: Enable Deep Linking in Brightspace">
          <form-group>
            <strong>
              This step adds Pivot to the assignment content editor so
              instructors can create links to Pivot assignments. You must have
              permissions to manage LTI Advantage deployments to perform this
              step.
            </strong>
          </form-group>
          <ol>
            <li>
              <form-group>
                On your deployment in Brightspace, near the bottom, click
                <strong>View Links</strong>.
              </form-group>
            </li>
            <li>
              <form-group>Then select <strong>New Link</strong>.</form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the text below into the
                <strong>Name</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Link Name"
                  :value="config.title || ''"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the text below into the
                <strong>URL</strong> field.
              </form-group>
              <form-group>
                <click-to-copy-field
                  aria-label="Link Name"
                  :value="config.launchUrl || ''"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                In the <strong>Type</strong> field, select
                <strong>Deep Linking Quicklink</strong>.
              </form-group>
            </li>
          </ol>
        </collapse-panel>
      </section>
    </loading-container>
  </view-container>
</template>

<script>
import ClickToCopyField from 'src/shared/components/ClickToCopyField'
import client from 'src/shared/api-client'

export default {
  name: 'LtiPlatformRegistrationBrightspaceView',
  components: { ClickToCopyField },
  data() {
    return {
      loadingConfig: true,
      config: {},
      platformData: {
        clientId: '',
        domain: ''
      }
    }
  },
  methods: {
    async register(e) {
      try {
        const protocol = PI_IS_LOCAL ? 'http://' : 'https://'
        const { clientId, domain } = this.platformData
        await client.lti.createPlatform({
          name: 'Brightspace',
          issuer: `${protocol}${domain}`,
          clientId,
          oidcUrl: `${protocol}${domain}/d2l/lti/authenticate`,
          oauthUrl: `${protocol}auth.brightspace.com/core/connect/token`,
          oauthAud: `${protocol}api.brightspace.com/auth/token`,
          jwkUrl: `${protocol}${domain}/d2l/.well-known/jwks`
        })
        this.$success('Brightspace successfully configured in Pivot.')
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    async loadConfig() {
      this.loadingConfig = true
      try {
        const config = await client.lti.getConfig({ platform: 'd2l' })
        this.config = config
      } finally {
        this.loadingConfig = false
      }
    }
  },
  async created() {
    await this.loadConfig()
  }
}
</script>
