<template>
  <modal>
    <modal-header>Unsaved changes</modal-header>
    <modal-body>
      You have unsaved student scores. If you leave, they will not be saved.
    </modal-body>
    <modal-actions>
      <modal-button-ok>Leave</modal-button-ok>
      <modal-button-cancel>Stay</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonOk,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

export default {
  name: 'UnsavedChangesModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonOk,
    ModalButtonCancel
  }
}
</script>

<style></style>
