<template>
  <div v-if="!dependenciesSatisfied && isPhetIOSimulation">
    <card>
      <card-title class="card-title"
        >Answer the questions below to launch the PhET-IO activity.</card-title
      >
      <hr />
      <div class="deps-container">
        <ol class="deps-list">
          <li
            v-for="(dependency, index) in dependencies ?? []"
            :key="dependency?.id ?? index"
          >
            <template v-if="dependency">
              <b>Section: </b>
              {{ dependency.sectionName }}
              <b>Question: </b>
              {{ dependency.questionNumber }}
            </template>
            <template v-else> </template>
          </li>
        </ol>
      </div>
    </card>
  </div>
  <template v-if="isPhetIOSimulation && dependenciesSatisfied">
    <phet-io-simulation
      :config="component.config"
      :variables="variableContext.variables"
  /></template>
</template>

<script>
import PhetIoSimulation from 'src/shared/components/PhetIoSimulation'

export default {
  name: 'ActivityPhetIoSimulation',
  inject: ['inherited'],
  components: {
    PhetIoSimulation
  },
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    isPhetIOSimulation() {
      return this.component.componentType === 'PhetIOSimulation'
    },
    dependenciesSatisfied() {
      return (
        this.inherited.component.dependenciesSatisfied ||
        this.inherited.componentResponse.dependenciesSatisfied
      )
    },
    dependencies() {
      let dependencies =
        (this.inherited.component &&
          this.inherited.component.componentDependenciesRemaining) ??
        (this.inherited.componentResponse &&
          this.inherited.componentResponse.componentDependenciesRemaining) ??
        []

      let depswithSections = dependencies.map(dependency => {
        if (dependency.sectionName !== undefined) {
          return dependency
        }

        this.activity.sections.forEach((section, index) => {
          for (let component of section.components) {
            if (component._id === dependency.id) {
              dependency.sectionName = index + 1
              break
            }
          }
        })
        return dependency
      })
      return depswithSections
    }
  }
}
</script>
<style lang="scss" scoped>
.card-title {
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}
.deps-container {
  text-align: center;
}
.deps-list {
  display: inline-block;
  padding: 20px;
  align-items: center;
  list-style-type: none;
  white-space: normal;
  background-color: $yellow;
  border-radius: 8px;
}
</style>
