<template>
  <p class="card__title" data-testid="card-title">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'CardTitle'
}
</script>

<style lang="scss">
.card__title {
  font-size: 16px;
  margin: 0;
}
</style>
