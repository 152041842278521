<template>
  <div class="browse-summary">
    <template v-if="!isLoading">
      Showing {{ instructorPageSize }} of {{ instructorCount }} results
      <template v-if="search">for "{{ search }}"</template>
      <template v-if="filters.length > 0">
        filtered by {{ filters.join(', ') }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BrowseSummary',
  inject: ['browseInstructors'],
  computed: {
    instructorPageSize() {
      return this.browseInstructors.instructors.length
    },
    instructorCount() {
      return this.browseInstructors.instructorCount
    },
    search() {
      return this.browseInstructors.query.q
    },
    isLoading() {
      return this.browseInstructors.isLoadingInstructors
    },
    filters() {
      const filters = this.browseInstructors.query.vetStatus
        ? [...this.browseInstructors.query.vetStatus]
        : []
      return filters
    }
  }
}
</script>

<style lang="scss" scoped>
.browse-summary {
  margin: 8px 0 0 0;
  flex-shrink: 1;
  grid-area: summary;
}
</style>
