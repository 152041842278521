<template>
  <div class="browse-instructors-layout">
    <div class="browse-instructors-layout__content">
      <div class="browse-instructors-layout__sidebar">
        <slot name="sidebar" />
      </div>
      <div class="browse-instructors-layout__results">
        <div class="browse-instructors-layout__search">
          <slot name="search" />
        </div>
        <div class="browse-instructors-layout__summary">
          <slot name="summary" />
        </div>
        <div class="browse-instructors-layout__instructors">
          <slot name="instructors" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrowseInstructorsLayout'
}
</script>

<style lang="scss" scoped>
.browse-instructors-layout {
  display: flex;
  flex-direction: column;

  &__sidebar {
    width: 200px;
  }

  &__content {
    display: flex;
    flex-direction: row;
  }

  &__results {
    flex: 1;
  }

  &__summary {
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
  }
}
</style>
