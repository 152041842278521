import ConfigureLicenseView from 'src/modules/purchases/views/ConfigureLicenseView'
import ConfigureRenewLicenseView from 'src/modules/purchases/views/ConfigureRenewLicenseView'
import ConfigureAddedSeatsView from 'src/modules/purchases/views/ConfigureAddedSeatsView'
import ConfigureAccessCodesView from 'src/modules/purchases/views/ConfigureAccessCodesView'
import EducatorContactView from 'src/modules/purchases/views/EducatorContactView'
import BillingView from 'src/modules/purchases/views/BillingView'
import TaxExemptionView from 'src/modules/purchases/views/TaxExemptionView'
import OrderSummaryView from 'src/modules/purchases/views/OrderSummaryView'
import ThankYouView from 'src/modules/purchases/views/ThankYouView'
import QuoteView from 'src/modules/purchases/views/QuoteView'
import QuotesView from 'src/modules/purchases/views/QuotesView'

import { clearPurchaseData } from 'src/shared/utils/purchases'

export default store => [
  {
    name: 'free_trial',
    path: '/request-trial/',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return {
        name: 'new_license_session',
        params: { licenseType: 'trial' },
        query: { referralCode: to.query.referralCode }
      }
    }
  },
  // Configure a new license purchase
  {
    name: 'new_license_session',
    path: '/purchase/license/:licenseType(institution|trial|student)?',
    redirect: to => {
      clearPurchaseData()
      return {
        name: 'new_license_configure',
        params: { licenseType: to.params.licenseType || 'institution' },
        query: to.query
      }
    }
  },
  {
    name: 'new_license_configure',
    path: '/purchase/license/:licenseType(institution|trial|student)/configure',
    component: ConfigureLicenseView,
    props: route => ({
      licenseType: route.params.licenseType,
      id: route.query.id
    }),
    meta: { title: 'Purchase License' },
    beforeEnter(to, from, next) {
      if (store.getters.isStudent) {
        next({ name: 'classes' })
      }
      next()
    }
  },
  {
    name: 'new_license_contact',
    path: '/purchase/license/contact',
    component: EducatorContactView,
    meta: { title: 'License Contact' },
    beforeEnter(to, from, next) {
      if (store.getters.isStudent) {
        next({ name: 'classes' })
      }
      next()
    }
  },

  // Configure a license upgrade/renewal
  {
    name: 'new_upgrade_session',
    path: '/purchase/upgrade',
    redirect: to => {
      clearPurchaseData()
      return {
        name: 'upgrade_license_configure',
        query: to.query
      }
    }
  },

  {
    name: 'upgrade_license_configure',
    path: '/purchase/upgrade',
    component: ConfigureRenewLicenseView,
    meta: { title: 'Purchase License Renewal' },
    props: route => {
      return {
        id: route.query.id
      }
    },
    beforeEnter(to, from, next) {
      if (store.getters.isStudent) {
        next({ name: 'classes' })
      }
      next()
    }
  },

  // Configure an existing license renewal purchase
  {
    name: 'new_renewal_session',
    path: '/purchase/renewal',
    redirect: to => {
      clearPurchaseData()
      return {
        name: 'renew_license_configure',
        query: to.query
      }
    }
  },

  {
    name: 'renew_license_configure',
    path: '/purchase/renewal',
    component: ConfigureRenewLicenseView,
    meta: { title: 'Purchase License Renewal' },
    props: route => {
      return {
        id: route.query.id,
        licenseId: route.query.licenseId
      }
    },
    beforeEnter(to, from, next) {
      if (store.getters.isStudent) {
        next({ name: 'classes' })
      }
      next()
    }
  },

  // Configure an existing license added seats purchase
  {
    name: 'new_add_seats_session',
    path: '/purchase/add-seats',
    redirect: to => {
      clearPurchaseData()
      return {
        name: 'added_seats_configure',
        query: to.query
      }
    }
  },

  {
    name: 'added_seats_configure',
    path: '/purchase/add-seats',
    component: ConfigureAddedSeatsView,
    meta: { title: 'Purchase Added Seats' },
    props: route => {
      return {
        id: route.query.id,
        licenseId: route.query.licenseId
      }
    },
    beforeEnter(to, from, next) {
      if (store.getters.isStudent) {
        next({ name: 'classes' })
      }
      next()
    }
  },

  // Configure access codes purchase
  {
    name: 'access_codes_configuration',
    path: '/purchase/bookstore-codes',
    component: ConfigureAccessCodesView,
    meta: { title: 'Purchase Access Codes' },
    props: route => {
      return {
        id: route.query.id
      }
    }
  },

  // Purchase billing information
  {
    name: 'purchase_billing_information',
    path: '/purchase/billing',
    component: BillingView,
    meta: { title: 'Billing' }
  },

  {
    name: 'tax_exemption',
    path: '/purchase/tax-exemption',
    component: TaxExemptionView,
    meta: { title: 'Tax Exemption' }
  },

  // View a quote for a purchase
  {
    name: 'purchase_quote',
    path: '/purchase/quote/:id',
    component: QuoteView,
    props: route => {
      return {
        id: route.params.id,
        quoteSent: route.query.quoteSent?.toString() === 'true'
      }
    },
    meta: { title: 'Quote' },
    beforeEnter(to, from, next) {
      if (store.getters.isStudent) {
        next({ name: 'classes' })
      }
      next()
    }
  },

  // Review purchase summary and complete
  {
    name: 'purchase_order_summary',
    path: '/purchase/summary',
    component: OrderSummaryView,
    meta: { title: 'Order Summary' }
  },

  // Purchase complete thank you
  {
    name: 'purchase_complete',
    path: '/purchase/thank-you',
    component: ThankYouView,
    meta: { title: 'Thank You!' }
  },
  {
    name: 'admin_search_quotes',
    path: '/admin/quotes',
    component: QuotesView,
    props: to => ({ query: to.query }),
    meta: { title: 'Search Quotes', requiresAuth: true }
  }
]
