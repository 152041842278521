<template>
  <modal @submit="submit">
    <modal-header>{{ isEdit ? 'Edit' : 'Add' }} Extension</modal-header>
    <modal-body v-if="!assignment.endDate">
      <p>
        This assignment has no due date. Please
        <router-link
          :to="{
            name: 'assignment_scores',
            params: { assignmentId: assignment.id, tab: 'details' }
          }"
          @click="closeModal"
          >set a due date</router-link
        >
        before granting an extension.
      </p></modal-body
    >
    <modal-body v-else>
      <p>
        Extending the due date will also extend the last visible date of the
        assignment, if applicable.
      </p>

      <span>
        <p v-if="isGroupAssignment" class="modal__label">Groups</p>
        <p v-else class="modal__label">Students</p>
        <autocomplete
          v-if="!isEdit"
          v-model="selectedStudentsforExtension"
          :options="responses"
          label-key="name"
          value-key="id"
          multiple
          :rules="{ required: true }"
          placeholder="Choose students"
          aria-label="Chosen students"
        />
        <span v-else>
          <chip
            v-for="(student, index) in joinedStudentNames"
            :key="index"
            :index="index"
            :label="student"
            :removeable="false"
            disabled
          />
        </span>
      </span>
      <span class="date-row">
        <span>
          <p class="modal__label">Due Date</p>
          {{ $format.date(dueDate, 'MM/dd/yyyy HH:mm a') }}
        </span>
        <span>
          <p class="modal__label">Extend To</p>

          <date-input
            data-testid="extension-date"
            time
            id="end-date"
            label="end date"
            v-model="extensionDate"
            :min-date="minDate"
            :rules="{ required: true }"
            :clearable="false"
          />
          <span v-if="showDefaultDateMessage" class="defaultExtensionMessage"
            >Default extension is 1 week</span
          >
        </span>
      </span>
      <span>
        <p class="modal__label">Options</p>

        <checkbox v-model="unlockSections" :disabled="resetResponses">
          Allow Students to Work on Previously Locked Sections
          <help-popover
            >The students can make edits to all content in every section,
            including ones that may have been previously locked. <br />Uncheck
            this box to prevent the students from editing previously locked
            sections.
          </help-popover></checkbox
        ></span
      >
      <checkbox v-model="resetQuestionSubmissions" :disabled="resetResponses">
        Reset Autograded Question Submissions
        <help-popover
          >This will reset the autograded question submissions for the selected
          students, giving students additional submissions for credit on all
          autograded questions in the acitivity.
        </help-popover>
      </checkbox>
      <modal-provider ref="modalProviderComponent">
        <checkbox v-model="resetResponses" @change="confirmResetResponses">
          Reset Student Responses
          <help-popover
            >This will reset the student responses for the selected students,
            completely removing any previous work. Use this only if you want
            students to start over completely on this assignment.
          </help-popover>
        </checkbox>
      </modal-provider>
    </modal-body>
    <modal-actions v-if="!assignment.endDate">
      <modal-button-ok>Ok</modal-button-ok>
    </modal-actions>
    <modal-actions v-else>
      <modal-button-submit
        data-click-name="Grant Extension Modal Submit Button"
        data-click-type="Grant Extension"
        :data-click-asset-id="`${assignment.id}`"
        >Extend</modal-button-submit
      >
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import { ref, computed, inject, watchEffect, watch } from 'vue'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonCancel,
  ModalButtonSubmit,
  ModalButtonOk
} from 'src/shared/components/modals/components'
import { useFlash } from 'src/shared/hooks/flash'
import Autocomplete from 'src/shared/components/Autocomplete.vue'
import ModalProvider from 'src/shared/components/modals/components/ModalProvider'
import client from 'src/shared/api-client'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal.vue'
import { add, isEqual } from 'date-fns'

const $modal = inject('$modal')

const props = defineProps({
  assignment: {
    type: Object,
    required: true
  },
  responses: {
    type: Array,
    required: true
  },
  selectedStudents: {
    type: Array,
    required: true
  },
  isGroupAssignment: {
    type: Boolean,
    required: false,
    default: false
  },
  isEdit: {
    type: Boolean,
    required: false,
    default: false
  },
  previousExtensionDate: {
    type: Date,
    required: false
  }
})
const showDefaultDateMessage = ref(false)
const extensionDate = ref()
const defaultExtensionDate = add(new Date(), { days: 7 })
const unlockSections = ref(true)
const resetQuestionSubmissions = ref(false)
const resetResponses = ref(false)
const modalProviderComponent = ref()

const flash = useFlash()
const selectedStudentsforExtension = ref(props.selectedStudents)

const selectedStudentsResponses = computed(() => {
  const filteredStudents = props.responses.filter(student => {
    return selectedStudentsforExtension.value.includes(student.id)
  })
  return filteredStudents.map(student => student.id.toString())
})

const closeModal = () => {
  $modal.close()
}

watchEffect(() => {
  if (props.previousExtensionDate) {
    extensionDate.value = props.previousExtensionDate
  } else {
    extensionDate.value = defaultExtensionDate
    showDefaultDateMessage.value = true
  }
})

watch(extensionDate, newValue => {
  showDefaultDateMessage.value = isEqual(newValue, defaultExtensionDate)
})

const joinedStudentNames = computed(() => {
  const filteredStudents = props.responses.filter(student => {
    return selectedStudentsforExtension.value.includes(student.id)
  })
  return filteredStudents.map(student => student.name)
})

const dueDate = computed(() => {
  return props.assignment.endDate ?? ''
})

const minDate = computed(() => {
  if (props.assignment.endDate) {
    return new Date(props.assignment.endDate)
  } else {
    let today = new Date().toLocaleDateString()
    return today
  }
})

const confirmResetResponses = async () => {
  if (resetResponses.value) {
    const { status } = await modalProviderComponent.value.show(ConfirmModal, {
      text: `This will reset the student responses for the selected students, completely removing any previous work. Use this only if you want students to start over completely on this assignment. `,
      prompt: 'Are you sure you want to reset student responses?'
    })
    if (status === 'ok') {
      unlockSections.value = false
      resetQuestionSubmissions.value = false
    }
    if (status == 'cancel') {
      resetResponses.value = false
    }
  }
}

const submit = async event => {
  try {
    await client.assignments.extendAssignment({
      assignmentId: props.assignment.id,
      assignmentResponses: selectedStudentsResponses.value,
      extensionDate: extensionDate.value,
      unlockSections: unlockSections.value,
      resetQuestionSubmissions: resetQuestionSubmissions.value,
      resetResponses: resetResponses.value
    })
    flash.success(`Extension granted.`)
    event.done()
  } catch (error) {
    flash.error(`Failed to grant extension.`)
    event.done(false)
  }
}
</script>
<style lang="scss" scoped>
.modal {
  width: 745px;
}
.modal__label {
  font-weight: 700;
}
.date-row {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
.student-names {
  font-weight: 400;
}
.defaultExtensionMessage {
  color: $color-muted;
  margin-top: 5px;
}
</style>
