<template>
  <view-container>
    <flash />
    <loading-container :loading="step === 'redirect'">
      <connect-sso-account v-if="step === 'connect'" />
      <multifactor-auth v-else-if="step === 'mfa'" />
      <standard-login v-else-if="step === 'email'" />
      <login-selection v-else-if="step === 'login-select'" />
    </loading-container>
  </view-container>
</template>

<script setup>
import Flash from 'src/shared/components/Flash'
import LoginSelection from 'src/modules/auth/components/LoginSelection.vue'
import StandardLogin from 'src/modules/auth/components/StandardLogin.vue'
import ConnectSsoAccount from 'src/modules/auth/components/ConnectSsoAccount.vue'
import MultifactorAuth from 'src/modules/auth/components/MultifactorAuth.vue'
import { useRouter } from 'vue-router'
import { watchEffect } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const props = defineProps({
  step: {
    type: String,
    required: false
  },
  redirect: {
    type: String,
    default: '/'
  }
})

watchEffect(async () => {
  if (props.step === 'redirect') {
    window.location.assign(router.resolve(props.redirect).href)
  } else if (store.getters.isAuthenticated) {
    router.replace(props.redirect)
  }
})
</script>
