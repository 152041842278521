<template>
  <p v-if="isCurrentlyHidden" class="visibility-warning">
    <icon icon="triangle-exclamation" class="warning-icon" /> This assignment is
    currently hidden from students. To change this,
    <router-link
      :to="{
        name: 'assignment_scores',
        params: { assignmentId: assignment.id, tab: 'details' }
      }"
      >edit the visiblity</router-link
    >
    of the assignment.
  </p>
  <AssignmentScoringSettingsCard
    class="mb-5"
    :assignment="assignment"
    :activity="activity"
    @updated="emit('refresh')"
  />
  <div class="assignment-scores__header">
    <div class="assignment-scores__header-sort">
      <select-field
        aria-label="Filter Responses by Status"
        class="assignment-scores__select-filter"
        v-model="filterInput"
      >
        <option value="">Show All Responses</option>
        <option value="initialized">Show Only Not Started</option>
        <option value="in-progress">Show Only In Progress</option>
        <option value="submitted">Show Only Submitted</option>
        <option value="graded">Show Only Final Grade</option>
      </select-field>
    </div>
    <div class="assignment-scores__header-actions">
      <label for="bulkActionSelect"
        >Bulk Actions
        <help-popover :to="{ name: 'assignment_bulk_actions_help' }"
          >Explanation of Actions</help-popover
        >
      </label>
      <select-field
        id="bulkActionSelect"
        v-model="bulkAction"
        :disabled="!allowBulkActions"
        class="assignment-scores__header-actions--select"
      >
        <option value="">Select Action</option>
        <template v-for="opt in bulkOptions" :key="opt.value">
          <hr v-if="opt.divider" />
          <option v-else :value="opt.value">
            {{ opt.label }}
          </option>
        </template>
      </select-field>
      <form-button
        secondary
        @click="bulkActionSubmit"
        :disabled="!allowBulkActions"
        >Apply</form-button
      >
    </div>
  </div>

  <p
    class="assignment-scores__no-responses"
    v-if="mappedResponses.length === 0"
  >
    There are no responses yet.
  </p>

  <p
    class="assignment-scores__no-responses"
    v-else-if="filteredResponses.length === 0"
  >
    There are no responses with the <strong>{{ filterInput }}</strong> status.
    <br />
    <form-button style="margin-top: 10px" value="" @click="filterInput = ''"
      >Show all responses</form-button
    >
  </p>

  <template v-else>
    <list
      :items="filteredResponses"
      :item-key="assignment.assignedTo === 'groups' ? 'studentId' : 'id'"
      :row-class="
        ({
          gradingProgress,
          instructorUpdatedAt,
          studentUpdatedAt,
          lastStudent
        }) => {
          const studentUpdatedDate = studentUpdatedAt
            ? new Date(studentUpdatedAt)
            : null

          const instructorUpdatedDate = instructorUpdatedAt
            ? new Date(instructorUpdatedAt)
            : null
          let baseClass = 'assignment-scores__row'
          if (sortingByGroup && lastStudent) {
            baseClass =
              'assignment-scores__row assignment-scores__row-group__last'
          }
          if (gradingProgress === 'graded') {
            return `${baseClass} assignment-scores__row--graded`
          } else if (studentUpdatedDate && !!instructorUpdatedDate) {
            return `${baseClass} assignment-scores__row--needs-attention`
          } else if (studentUpdatedDate > instructorUpdatedDate) {
            return `${baseClass} assignment-scores__row--needs-attention`
          }
          return baseClass
        }
      "
    >
      <list-column title="bulkActions">
        <template v-slot:title>
          <checkbox v-model="allSelected" :indeterminate="someSelected" />
        </template>
        <template v-slot="{ id }">
          <checkbox v-model="selectedResponses" :value="id" />
        </template>
      </list-column>
      <sortable-list-column
        title="Student"
        :sort="propertySort('name')"
        @change="dir => updateSort('name', dir)"
        v-slot="response"
      >
        <router-link
          v-if="response.id"
          :to="{
            name: 'grade_response',
            params: { assignmentId: assignment.id, responseId: response.id },
            query: responseSortQuery
          }"
        >
          {{ response.studentName }}
        </router-link>
        <template v-else>
          {{ response.studentName }}
        </template>
      </sortable-list-column>
      <sortable-list-column
        v-if="assignment.assignedTo === 'groups'"
        title="Co-Lab Group"
        :sort="propertySort('groupName')"
        @change="dir => updateSort('groupName', dir)"
        v-slot="response"
      >
        {{ response.groupName }}
      </sortable-list-column>

      <sortable-list-column
        title="Score"
        :sort="propertySort('score')"
        @change="dir => updateSort('score', dir)"
        v-slot="response"
      >
        {{ formatScore(response) }}
      </sortable-list-column>
      <sortable-list-column
        title="Last Change By"
        :sort="propertySort('updatedBy')"
        @change="dir => updateSort('updatedBy', dir)"
        v-slot="response"
      >
        <div
          class="assignment-scores__updated-by"
          :class="`assignment-scores__updated-by--${response.updatedBy}`"
        >
          <template v-if="response.updatedBy">
            by
            {{ response.updatedBy === 'instructor' ? 'Instructor' : 'Student' }}
          </template>
          <template v-else> No Change </template>
        </div>
      </sortable-list-column>
      <sortable-list-column
        title="Instructor Updates"
        :sort="propertySort('gradingProgress')"
        @change="dir => updateSort('gradingProgress', dir)"
        helpText="Explanation of Statuses"
        helpLink="assignment_instructor_updates"
        v-slot="response"
      >
        <span class="assignment-scores__progress">
          <progress-circle
            :progress="response.gradingPercentage"
            :completed="
              instructorUpdates(response.gradingProgress) === 'Final Grade'
            "
          >
          </progress-circle>
          <span class="assignment-scores__updates">
            {{ instructorUpdates(response) }}
            <br />
            <span class="text-sm font-normal">
              {{
                response.instructorUpdatedAt
                  ? formatDate(
                      new Date(response.instructorUpdatedAt),
                      'MM/dd/yy hh:mm a'
                    )
                  : ''
              }}</span
            >
          </span>
        </span>
      </sortable-list-column>
      <sortable-list-column
        title="Student Updates"
        :sort="propertySort('activityProgress')"
        @change="dir => updateSort('activityProgress', dir)"
        helpText="Explanation of Statuses"
        helpLink="assignment_student_updates"
        v-slot="response"
      >
        <span
          class="assignment-scores__progress"
          :ref="el => setProgressRef(el, response.studentId)"
        >
          <progress-circle
            :progress="response.responsePercentage"
            :completed="isStudentComplete(response.activityProgress)"
            @mouseover="hoveredResponse = response.studentId"
            @mouseout="hoveredResponse = undefined"
          >
            <template v-if="!studentUpdates(response)">Completed</template>
          </progress-circle>
          <base-popover
            v-if="response.extensionEndDate"
            placement="left"
            :anchor-ref="progressRefs[response.studentId]"
            :visible="hoveredResponse === response.studentId"
          >
            Due date extended to:
            {{
              $format.date(response.extensionEndDate, 'MM/dd/yyyy HH:mm a')
            }}</base-popover
          >
          <span class="assignment-scores__updates">
            {{ studentUpdates(response) }}
            <br v-if="studentUpdates(response) !== ''" />
            <span class="text-sm font-normal">
              {{
                response.studentUpdatedAt
                  ? formatDate(
                      new Date(response.studentUpdatedAt),
                      'MM/dd/yy hh:mm a'
                    )
                  : ''
              }}
            </span>
          </span>
        </span>
      </sortable-list-column>
      <sortable-list-column
        title="Due Dates"
        :sort="propertySort('extensionEndDate')"
        @change="dir => updateSort('extensionEndDate', dir)"
        v-slot="response"
      >
        <a
          v-if="response.extensionEndDate"
          @click="showExtensionModal(response, true)"
          href="#"
          class="cursor-pointer"
        >
          {{ $format.date(response.extensionEndDate, 'MM/dd/yyyy HH:mm a') }}
        </a>
        <span v-else>{{
          assignment.endDate
            ? formatDate(new Date(assignment.endDate), 'MM/dd/yy hh:mm a')
            : ''
        }}</span>
      </sortable-list-column>

      <list-column title="Actions">
        <template #default="response">
          <router-link
            class="grade-menu"
            v-if="response.id"
            :to="{
              name: 'grade_response',
              params: {
                assignmentId: assignment.id,
                responseId: response.id
              },
              query: responseSortQuery
            }"
            >Grade
          </router-link>
          <button-dropdown link>
            <template #button>
              <icon icon="ellipsis-v" class="grade-menu-ellipsis" />
              <span class="sr-only">Student Grading Actions</span>
            </template>
            <dropdown-action
              v-if="assignment.endDate && !response.extensionEndDate"
              @click="showExtensionModal(response)"
            >
              Grant Extension
            </dropdown-action>
            <dropdown-action
              v-if="assignment.endDate && response.extensionEndDate"
              @click="showExtensionModal(response, true)"
            >
              Edit Extension
            </dropdown-action>
            <dropdown-action
              v-if="assignment.endDate && response.extensionEndDate"
              @click="cancelExtension(response)"
            >
              Revert to Original Due Date
            </dropdown-action>
            <dropdown-action
              v-if="
                response.gradingProgress === 'not-ready' ||
                response.gradingProgress === 'reopened'
              "
              @click="updateResponseStatus(response, 'pending')"
            >
              Submit Assignment
            </dropdown-action>
            <dropdown-action
              v-if="
                (response.gradingProgress === 'graded' ||
                  response.gradingProgress === 'pending') &&
                !response.isPastDueDate
              "
              @click="updateResponseStatus(response, 'reopened')"
            >
              Reopen Assignment
            </dropdown-action>
            <dropdown-action
              v-if="response.gradingProgress !== 'graded'"
              @click="updateResponseStatus(response, 'graded')"
            >
              Mark As Final Grade
            </dropdown-action>
            <dropdown-action
              v-if="response.gradingProgress === 'graded'"
              @click="updateResponseStatus(response, 'pending')"
            >
              Mark As Pending Grade
            </dropdown-action>
            <li class="divider" role="separator" />
            <dropdown-action
              @click="syncGrades([response.id])"
              v-if="
                manualLtiSyncEnabled &&
                response.activityProgress === 'submitted'
              "
            >
              Re-Sync Grade to LMS
            </dropdown-action>
            <dropdown-action @click="regradeAutogradedQuestions([response.id])">
              Regrade Autograded Questions</dropdown-action
            >
            <dropdown-action
              v-if="activity.sections.some(s => s.completeFirst)"
              @click="unlockLockedSections(response)"
            >
              Reopen Locked Sections</dropdown-action
            >
            <dropdown-action
              @click="resetAutogradedQuestionSubmissions([response.id])"
            >
              Reset Autograded Question Submissions
            </dropdown-action>

            <dropdown-action @click="resetResponse(response.id)">
              Clear Student Response
            </dropdown-action>
          </button-dropdown>
        </template>
      </list-column>
    </list>
  </template>
</template>

<script setup>
import { computed, watchEffect, inject, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { format as formatDate } from 'date-fns'
import UnlockSectionsConfirmationModal from 'src/shared/components/modals/UnlockSectionsConfirmationModal'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal.vue'
import extensionModal from './ExtensionModal.vue'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client'
import { getFlattenedComponents } from 'src/modules/assignments/utils'
import AssignmentScoringSettingsCard from './AssignmentScoringSettingsCard.vue'
import { useFeatureFlag } from 'src/shared/hooks/feature-flags'

const $modal = inject('$modal')
const flash = useFlash()
const router = useRouter()
const emit = defineEmits(['refresh'])
const props = defineProps({
  assignment: {
    type: Object,
    required: true
  },
  activity: {
    type: Object,
    required: true
  },
  responses: {
    type: Object,
    required: true
  },
  klass: {
    type: Object,
    required: true
  }
})

const manualLtiSyncEnabled = useFeatureFlag('manual_lti_sync')

const progressRefs = {}
function setProgressRef(el, id) {
  if (progressRefs[id] !== el) {
    progressRefs[id] = el
  }
}

let hoveredResponse = ref('')
const filterInput = ref('')

const sort = computed(() => ({
  field: router.currentRoute.value.query['sort'] ?? 'activityProgress',
  direction: router.currentRoute.value.query['dir'] ?? 'desc'
}))
const responseSortQuery = computed(() =>
  sort.value.field
    ? {
        sort: sort.value.field,
        dir: sort.value.direction
      }
    : {}
)

const sortingByGroup = computed(() => sort.value.field === 'groupName')

const isCurrentlyHidden = computed(() => {
  return (
    (props.assignment.lastVisibleDate &&
      new Date(props.assignment.lastVisibleDate) < new Date()) ||
    (props.assignment.firstVisibleDate &&
      new Date(props.assignment.firstVisibleDate) > new Date())
  )
})
function propertySort(key) {
  return sort.value.field === key ? sort.value.direction : false
}
function updateSort(field, direction) {
  router.replace({
    ...router.currentRoute.value,
    query: {
      ...router.currentRoute.value.query,
      sort: field,
      dir: direction || 'asc'
    }
  })
}

const mappedResponses = computed(() => {
  const responses = props.responses.map(response => {
    const studentUpdatedAt = response.studentUpdatedAt
      ? new Date(response.studentUpdatedAt)
      : undefined
    const instructorUpdatedAt = response.instructorUpdatedAt
      ? new Date(response.instructorUpdatedAt)
      : undefined
    const updatedBy =
      !instructorUpdatedAt && !studentUpdatedAt
        ? undefined
        : (instructorUpdatedAt ?? 0) > (studentUpdatedAt ?? 0)
          ? 'instructor'
          : 'student'
    const lastUpdatedAt = {
      student: studentUpdatedAt,
      instructor: instructorUpdatedAt
    }[updatedBy]
    const studentName =
      props.assignment.assignedTo === 'groups'
        ? ''
        : `${response.owner.lastName}, ${response.owner.firstName}`

    const assignmentEndDate = response?.assignment?.endDate
      ? new Date(response.assignment.endDate)
      : undefined
    const extensionEndDate = response?.extensionEndDate
      ? new Date(response.extensionEndDate)
      : undefined

    const endDate =
      extensionEndDate &&
      (!assignmentEndDate || extensionEndDate > assignmentEndDate)
        ? extensionEndDate
        : assignmentEndDate
    const isPastDueDate =
      endDate &&
      endDate < new Date() &&
      response?.gradingProgress !== 'feedback'

    return {
      ...response,
      updatedBy,
      lastUpdatedAt,
      studentUpdatedAt,
      instructorUpdatedAt,
      studentName,
      studentId:
        props.assignment.assignedTo === 'groups' ? '' : response.owner._id,
      isPastDueDate
    }
  })
  if (props.assignment.assignedTo === 'groups') {
    return responses
      .map(response =>
        response.students.map((student, i) => ({
          ...response,
          studentName: `${student.lastName}, ${student.firstName}`,
          studentId: student._id,
          lastStudent: i === response.students.length - 1
        }))
      )
      .flat()
  }
  return responses
})

const filteredResponses = computed(() => {
  const filteredResponses = filterInput.value
    ? mappedResponses.value.filter(response => {
        if (['graded'].includes(filterInput.value)) {
          return response.gradingProgress === filterInput.value
        } else {
          return (
            response.activityProgress === filterInput.value &&
            !['graded'].includes(response.gradingProgress)
          )
        }
      })
    : mappedResponses.value
  return filteredResponses
})

const bulkAction = ref('')

let selectedResponses = ref([])
const allowBulkActions = computed(() => selectedResponses.value.length !== 0)
const allSelected = computed({
  get: () => {
    return selectedResponses.value.length === filteredResponses.value.length
  },
  set: isSelected => {
    if (isSelected) {
      selectedResponses.value = filteredResponses.value
        .map(item => item.id)
        .filter((value, index, array) => array.indexOf(value) === index)
    } else {
      selectedResponses.value = []
    }
  }
})
const someSelected = computed(
  () => selectedResponses.value.length > 0 && !allSelected.value
)

const regradeAutogradedQuestions = async ids => {
  const { status } = await $modal.show(ConfirmModal, {
    text: 'Regrading autograded questions will overwrite any manual grading changes to autograded questions. This cannot be undone.',
    prompt: 'Are you sure you want to regrade autograded questions? '
  })

  if (status === 'ok') {
    await client.assignments.regrade({
      assignmentId: props.assignment.id,
      assignmentResponses: ids
    })
    emit('refresh')
    flash.success(`Autograded questions regraded.`)
  }
  return
}

const resetAutogradedQuestionSubmissions = async ids => {
  await client.assignments.resetSubmissionsBulk({
    assignmentId: props.assignment.id,
    assignmentResponses: ids
  })
  emit('refresh')
  flash.success(`Response submissions reset to zero.`)
}

const bulkActionSubmit = async () => {
  if (bulkAction.value === '') return

  if (bulkAction.value === 'reset-submissions') {
    resetAutogradedQuestionSubmissions(selectedResponses.value)
    return
  }
  if (bulkAction.value === 'regrade') {
    regradeAutogradedQuestions(selectedResponses.value)
    return
  }
  if (bulkAction.value === 'extension') {
    showExtensionModal()
    return
  }
  if (bulkAction.value === 'editExtension') {
    showExtensionModal(undefined, true)
    return
  }
  if (bulkAction.value === 'resyncGrades') {
    syncGrades(selectedResponses.value)
  }
  if (bulkAction.value === 'cancel-extension') {
    await client.assignments.cancelExtensions({
      assignmentId: props.assignment.id,
      assignmentResponses: selectedResponses.value
    })
    emit('refresh')
    flash.success(`Extensions cancelled.`)
    return
  }
  if (bulkAction.value === 'clear-response') {
    //need bulk action to reset responses
    await client.assignments.bulkResetResponses({
      assignmentId: props.assignment.id,
      assignmentResponses: selectedResponses.value
    })
  }

  let newProgress = bulkAction.value

  if (bulkAction.value === 'unlock-sections') {
    const request = { lockedSections: [] }
    const assignmentResponses = selectedResponses.value.map(id => {
      const response = props.responses.find(r => r.id === id)
      return {
        id: response.id,
        ...request
      }
    })

    await client.assignments.saveResponsesBulk({
      assignmentId: props.assignment.id,
      assignmentResponses
    })
    emit('refresh')
    flash.success(`Sections unlocked for selected students.`)
    return
  }
  //lets actually do the bulk actions now.
  const request = { gradingProgress: newProgress }

  const assignmentResponses = selectedResponses.value.map(id => {
    const response = props.responses.find(r => r.id === id)
    return {
      id: response.id,
      ...request
    }
  })

  await client.assignments.saveResponsesBulk({
    assignmentId: props.assignment.id,
    assignmentResponses
  })
  emit('refresh')
  const label = {
    graded: 'Graded',
    pending: 'Submitted',
    reopened: 'Reopened',
    'not-ready': 'In Progress'
  }

  flash.success(`Responses marked as ${label[newProgress]}.`)
}

const students = computed(() =>
  props.responses.map(r => {
    if (r.groupName) {
      return {
        id: r.id,
        name: r.groupName
      }
    } else {
      return {
        id: r.id,
        studentId: r.owner._id,
        name: `${r.owner.firstName} ${r.owner.lastName}`
      }
    }
  })
)

const selectedStudents = computed(() =>
  selectedResponses.value.map(id => {
    const response = props.responses.find(r => r.id === id)
    if (response.groupName) {
      return {
        id: response.id,
        name: response.groupName
      }
    } else {
      return {
        id: response.id,
        studentId: response.owner._id,
        name: `${response.owner.firstName} ${response.owner.lastName}`
      }
    }
  })
)
const showExtensionModal = async (studentResponse, edit) => {
  let selectedStudentsforExtension = []
  if (studentResponse) {
    selectedStudentsforExtension.push(studentResponse.id)
  } else {
    selectedStudentsforExtension = selectedStudents.value.map(student => {
      return student.id
    })
  }
  await $modal.show(extensionModal, {
    assignment: props.assignment,
    responses: students,
    selectedStudents: selectedStudentsforExtension,
    isGroupAssignment: props.assignment.assignedTo === 'groups' ? true : false,
    isEdit: edit ?? false,
    previousExtensionDate: studentResponse?.extensionEndDate ?? undefined
  })
  emit('refresh')
}
const defaultBulkActions = [
  {
    value: 'extension',
    label: 'Grant Extension'
  },
  {
    value: 'editExtension',
    label: 'Edit Extension'
  },
  {
    value: 'cancel-extension',
    label: 'Revert to Original Due Date'
  },
  {
    value: 'pending',
    label: 'Submit Assignments'
  },
  {
    value: 'reopened',
    label: 'Reopen Assignments'
  },
  {
    value: 'graded',
    label: 'Mark as Final Grade'
  },
  {
    value: 'pending',
    label: 'Mark as Pending Grade'
  },
  {
    divider: true,
    label: ''
  },
  {
    value: 'resyncGrades',
    label: 'Re-Sync Grades with LMS'
  },
  {
    value: 'regrade',
    label: 'Regrade Autograded Questions'
  },
  {
    value: 'unlock-sections',
    label: 'Reopen Locked Sections'
  },
  {
    value: 'reset-submissions',
    label: 'Reset Autograded Question Submissions'
  },
  {
    value: 'clear-response',
    label: "Clear Student's response"
  }
  //
]
const bulkOptions = computed(() => {
  const selected = filteredResponses.value.filter(r =>
    selectedResponses.value.includes(r.id)
  )

  return defaultBulkActions.filter(action => {
    if (action.divider) return true
    if (action.value === 'graded') {
      return selected.every(r => r.gradingProgress !== 'graded')
    } else if (action.value === 'pending') {
      return selected.every(
        r =>
          r.gradingProgress === 'not-ready' || r.gradingProgress === 'feedback'
      )
    } else if (action.value === 'not-ready') {
      return selected.every(
        r => r.gradingProgress === 'graded' || r.gradingProgress === 'pending'
      )
    } else if (action.value === 'feedback') {
      return selected.every(
        r => r.gradingProgress === 'pending' || r.gradingProgress === 'graded'
      )
    } else if (action.value === 'reset-submissions') {
      return true
    } else if (action.value === 'extension') {
      return true
    } else if (action.value === 'cancel-extension') {
      return true
    } else if (action.value === 'editExtension') {
      return selected.every(r => r.extensionEndDate)
    } else if (action.value === 'unlock-sections') {
      return props.activity.sections.some(s => s.completeFirst)
    } else if (action.value === 'regrade') {
      return true
    } else if (action.value === 'resyncGrades') {
      return (
        manualLtiSyncEnabled.value &&
        selected.every(r => r.activityProgress === 'submitted')
      )
    } else if (action.value === 'submitted') {
      return selected.every(r => r.activityProgress === 'submitted')
    } else if (action.value === 'clear-response') {
      return selected.every(
        r =>
          r.activityProgress === 'in-progress' ||
          r.activityProgress === 'submitted'
      )
    } else if (action.value === 'reopened') {
      return selected.every(
        r =>
          (r.gradingProgress === 'graded' || r.gradingProgress === 'pending') &&
          !r.isPastDueDate
      )
    }
  })
})

const resetResponse = async responseId => {
  const { status } = await $modal.show(ConfirmModal, {
    text: `Caution: This will permanently delete all responses for this assignment for this student. There is no 'undo' for this action.`,
    prompt:
      "Are you sure you want delete this student's responses to this assignment?"
  })
  if (status === 'ok') {
    await client.assignments.resetResponse({
      assignmentId: props.assignment.id,
      responseId: responseId
    })
    emit('refresh')
    flash.success('Response saved successfully.')
  }
}

const syncGrades = async assignmentResponses => {
  await client.assignments.resyncGradesBulk({
    assignmentId: props.assignment.id,
    assignmentResponses
  })
  flash.success('Grade resync initiated.')
}

const sortMethod = computed(() => {
  const directionMultiplier = sort.value.direction === 'desc' ? -1 : 1
  switch (sort.value.field) {
    case 'score': {
      return (a, b) => directionMultiplier * (a.totalScore - b.totalScore)
    }
    case 'updatedBy': {
      const valueMap = {
        student: 1,
        undefined: 2,
        instructor: 3
      }
      return (a, b) => {
        const updatedByDiff = valueMap[a.updatedBy] - valueMap[b.updatedBy]
        if (updatedByDiff === 0) {
          // Secondary sort activities by updated date.
          // But the order of instructor changes should be backward so their most recent changes are at the bottom.
          const instructorMultiplier = a.updatedBy === 'instructor' ? 1 : -1
          const updatedAtDiff = (a.lastUpdatedAt ?? 0) - (b.lastUpdatedAt ?? 0)
          return directionMultiplier * instructorMultiplier * updatedAtDiff
        } else {
          return directionMultiplier * updatedByDiff
        }
      }
    }
    case 'activityProgress': {
      const activityMap = {
        submitted: 4,
        'in-progress': 3,
        started: 2,
        initialized: 1,
        unavailable: 0
      }
      const getProgressValue = response =>
        response.gradingProgress === 'graded'
          ? 5
          : activityMap[response.activityProgress]
      return (a, b) => {
        const diff = (getProgressValue(a) ?? 0) - (getProgressValue(b) ?? 0)
        if (diff === 0) {
          const updatedAtDiff =
            (b.studentUpdatedAt ?? 0) - (a.studentUpdatedAt ?? 0)
          return directionMultiplier * updatedAtDiff
        } else {
          return directionMultiplier * diff
        }
      }
    }
    case 'gradingProgress': {
      const activityMap = {
        pending: 2,
        feedback: 3,
        graded: 4,
        reopened: 5,
        'not-ready': 1
      }
      return (a, b) => {
        const diff =
          (activityMap[a.gradingProgress] ?? 0) -
          (activityMap[b.gradingProgress] ?? 0)
        if (diff === 0) {
          const updatedAtDiff =
            (b.instructorUpdatedAt ?? 0) - (a.instructorUpdatedAt ?? 0)
          return directionMultiplier * updatedAtDiff
        } else {
          return directionMultiplier * diff
        }
      }
    }
    default:
      return () => 0
  }
})
watchEffect(() => {
  const directionMultiplier = sort.value.direction === 'desc' ? -1 : 1
  const firstSortFn =
    props.assignment.assignedTo === 'groups'
      ? (a, b) =>
          directionMultiplier *
          a.groupName.localeCompare(b.groupName, undefined, {
            numeric: true,
            sensitivity: 'base'
          })
      : (a, b) =>
          directionMultiplier * a.studentName.localeCompare(b.studentName)
  filteredResponses.value
    // We sort everything by student/group name first
    // to ensure that every sort starts with the same array.
    .sort(firstSortFn)
    .sort(sortMethod.value)
})

async function unlockLockedSections(response) {
  const request = { lockedSections: [] }
  await client.assignments.gradeResponse({
    assignmentId: props.assignment.id,
    id: response.id,
    ...request
  })
  flash.success(`Sections unlocked for ${response.studentName}`)
}

async function updateResponseStatus(response, newProgress) {
  const request = { gradingProgress: newProgress }

  const hasLockedSections = props.activity.sections.some(s => s.completeFirst)
  if (newProgress === 'feedback' && hasLockedSections) {
    const { status } = await $modal.show(UnlockSectionsConfirmationModal, {
      message: 'Do you want to allow work on previously locked sections?'
    })
    if (status === 'yes') {
      request.lockedSections = []
    } else if (status === 'cancel') {
      return
    }
  }

  await client.assignments.gradeResponse({
    assignmentId: props.assignment.id,
    id: response.id,
    ...request
  })
  emit('refresh')
  const label = {
    graded: 'Graded',
    pending: 'Submitted',
    reopened: 'Reopened'
  }
  flash.success(`Response marked as ${label[newProgress]}.`)
}

async function cancelExtension(responese) {
  await client.assignments.cancelExtensions({
    assignmentId: props.assignment.id,
    assignmentResponses: [responese.id]
  })
  emit('refresh')
  flash.success(`Extension cancelled.`)
  return
}

function instructorUpdates(response) {
  if (
    response.activityProgress !== 'submitted' &&
    response.extensionEndDate &&
    (!response.instructorUpdatedAt ||
      new Date(response.instructorUpdatedAt) <
        new Date(response.extensionStartDate))
  ) {
    return 'Extension Added'
  }

  switch (response.gradingProgress) {
    case 'not-ready':
      return 'Assigned'
    case 'pending':
      return 'Grading'
    case 'graded':
      return 'Final Grade'
    case 'reopened':
      return 'Reopened'
  }

  return 'Unknown'
}

function isStudentComplete(activityProgress) {
  return activityProgress === 'submitted'
}

function studentUpdates(response) {
  const { activityProgress, activitySubmissionType } = response

  if (
    activityProgress !== 'submitted' &&
    response.extensionEndDate &&
    (!response.studentUpdatedAt ||
      new Date(response.studentUpdatedAt) <
        new Date(response.extensionStartDate))
  ) {
    return 'Extended'
  }

  switch (activityProgress) {
    case 'initialized':
      return 'Not Started'
    case 'in-progress':
      return 'In Progress'
    case 'submitted':
      return activitySubmissionType === 'manual' ? 'Submitted' : ''
  }

  return 'Unknown'
}
const getTotalAutogradedResponses = (activity, responses) => {
  const components = getFlattenedComponents(activity).filter(c => c.autograde)

  const componentIds = new Set(components.map(c => c._id))
  const totalAutogradeResponsePoints = responses.reduce((total, response) => {
    if (componentIds.has(response.component)) {
      return total + (response.score || 0)
    }
    return total
  }, 0)
  return totalAutogradeResponsePoints
}

function formatScore(response) {
  // We want to avoid adding unncessary zeros as decmials.
  const score =
    response.totalScore % 1 === 0
      ? response.totalScore
      : response.totalScore.toFixed(2)

  let grade = props.assignment.totalPointValue
    ? (
        (response.totalScore / props.assignment.totalPointValue) *
        100
      ).toPrecision(3)
    : 0

  return `${score}/${props.assignment.totalPointValue} (${grade}%)`
}

watch(filterInput, async filterInputValue => {
  if (filterInputValue) {
    allSelected.value = false
  }
})
</script>

<style lang="scss">
.assignment-scores__row {
  height: 55px;
}
.assignment-scores__row-group__last {
  border-bottom: 4px solid #ddd;
}
.assignment-scores__row--graded {
  background-color: rgba(52, 189, 189, 0.2);
}

.assignment-scores__row--needs-attention {
  background-color: rgba(239, 204, 79, 0.2);
}

.assignment-scores__no-responses {
  font-size: medium;
  padding-top: 100px;
  text-align: center;
  min-height: 100px;
}
.assignment-scores__updated-by {
  position: relative;
  padding-left: 14px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    transform: translateY(-50%);
    background-color: black;
  }

  &--student:before {
    background-color: $yellow;
  }
  &--instructor:before {
    background-color: $teal;
  }
}

.assignment-scores__students-list {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  flex-direction: row;
}
.assignment-scores__student-name {
  width: 50%;
}

.assignment-scores__progress {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
}

.assignment-scores__updates {
  font-weight: 700;
}

.assignment-scores__select-filter {
  max-width: 250px;
}

.grade-menu-ellipsis {
  padding-left: 6px;
}

.assignment-scores__header {
  display: flex;
  justify-content: space-between;
}

.assignment-scores__header-actions {
  width: 380px;
  label {
    font-weight: bold;
  }
  &--select {
    margin: 0 10px;
    display: inline-flex;
    width: 180px;
  }
}
.visibility-warning {
  color: $color-warning;

  a {
    color: $color-warning;
    text-decoration: underline;

    &:hover {
      color: darken($color-warning, 10%);
    }
  }
}
</style>
