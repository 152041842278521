<template>
  <auth-card header="Reset Password">
    <loading-container v-if="ssoProvider" :loading="oauthLoading">
      <div class="oauth-container">
        <p>Your account is configured with a single sign on service.</p>
        <oauth-selection
          :allowed="[ssoProvider]"
          prefix="Login with"
          @success="onOauthLogin"
        />
      </div>
    </loading-container>
    <async-form v-else @submit="onSubmit" class="reset-password__form">
      <form-group>
        <form-label for="password">Password</form-label>
        <text-input
          id="password"
          v-model="formData.password"
          :rules="{
            required: true,
            password: { isStudent: student }
          }"
          label="password"
          type="password"
          name="password"
          autocomplete="new-password"
        />
      </form-group>

      <form-group>
        <form-label for="confirm-password">Confirm Password</form-label>
        <text-input
          id="confirm-password"
          v-model="formData.confirmPassword"
          rules="required|confirmed:@password"
          label="password"
          type="password"
          autocomplete="new-password"
        />
      </form-group>

      <form-group class="submit-group">
        <submit-button name="log-in">
          <template #default>Reset</template>
          <template #submitting>Resetting</template>
          <template #submitted>Reset Complete</template>
        </submit-button>
      </form-group>
    </async-form>
  </auth-card>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useFlash } from 'src/shared/hooks/flash'
import { useRouter } from 'vue-router'
import AuthCard from './AuthCard.vue'
import client from 'src/shared/api-client'
import OauthSelection from './OauthSelection.vue'

const router = useRouter()
const flash = useFlash()

const props = defineProps({
  token: {
    type: String,
    required: true
  },
  student: {
    type: Boolean,
    default: false
  },
  ssoProvider: {
    type: String,
    default: undefined
  }
})

const formData = reactive({
  email: '',
  password: ''
})

const onSubmit = async e => {
  try {
    await client.auth.changePassword({ ...formData, token: props.token })
    flash.success(`Your password was changed successfully.`)
    router.push({
      ...router.currentRoute.value,
      params: { ...router.currentRoute.value.params, step: 'redirect' }
    })
    e.done()
  } catch (error) {
    e.done(false)
    throw error
  }
}

const oauthLoading = ref(false)
async function onOauthLogin() {
  try {
    oauthLoading.value = true
    const response = await client.auth.loginOAuth()

    if (response?.connect) {
      router.push({
        ...router.currentRoute.value,
        params: { step: 'connect' }
      })
    } else {
      router.push({
        ...router.currentRoute.value,
        params: { ...router.currentRoute.value.params, step: 'redirect' }
      })
    }
  } catch (error) {
    if (error.status === 401) {
      error.body.errors.map(error => flash.error(error.message))
    } else {
      throw error
    }
  } finally {
    oauthLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.oauth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reset-password__form {
  width: 300px;
  margin: auto;
}

.submit-group {
  display: flex;
  justify-content: flex-end;
}
</style>
