<template>
  <div class="oauth__layout">
    <form-button
      secondary
      class="oauth__sso"
      v-if="allowed.includes('google')"
      @click="onClick(googleUrl)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        class="mr-2"
      >
        <path
          d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          fill="#4285F4"
        />
        <path
          d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          fill="#34A853"
        />
        <path
          d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          fill="#FBBC05"
        />
        <path
          d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          fill="#EA4335"
        />
        <path d="M1 1h22v22H1z" fill="none" />
      </svg>
      {{ prefix }} Google</form-button
    >
    <form-button
      secondary
      class="oauth__sso"
      v-if="allowed.includes('azure')"
      @click="onClick(azureUrl)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        class="mr-2"
      >
        <title>MS-SymbolLockup</title>
        <rect x="1" y="1" width="9" height="9" fill="#f25022" />
        <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
        <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
        <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
      </svg>
      {{ prefix }} Microsoft</form-button
    >
    <form-button
      secondary
      class="oauth__sso"
      v-if="allowed.includes('clever')"
      @click="onClick(cleverUrl)"
    >
      <img
        class="mr-2"
        src="~assets/img/clever-blue.png"
        alt="Clever"
        width="24"
        height="24"
      />
      {{ prefix }} Clever</form-button
    >
    <form-button
      secondary
      class="oauth__sso"
      v-if="allowed.includes('classLink')"
      @click="onClick(classLinkUrl)"
    >
      <img
        class="mr-2"
        src="~assets/img/classlink-small.png"
        alt="ClassLink"
        width="24"
        height="24"
      />
      {{ prefix }} ClassLink</form-button
    >
  </div>
</template>

<script setup>
import { useFlash } from 'src/shared/hooks/flash'
import { onUnmounted } from 'vue'

const flash = useFlash()

const { protocol, host } = window.location

const props = defineProps({
  allowed: {
    type: Array,
    default: () => ['google', 'azure', 'clever', 'classLink']
  },
  prefix: {
    type: String
  }
})
const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=${protocol}//${host}/login/oauth/google&client_id=${window.PI_GOOGLE_CLIENT_ID}&scope=email profile`
const azureUrl = `${PI_API_URL}/v3/signin-oauth/azure`
const classLinkUrl = `https://launchpad.classlink.com/oauth2/v2/auth?response_type=code&scope=full&redirect_uri=${protocol}//${host}/login/oauth/classLink&client_id=${window.PI_CLASSLINK_CLIENT_ID}`
const cleverUrl = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${protocol}//${host}/login/oauth/clever&client_id=${window.PI_CLEVER_CLIENT_ID}`

const emit = defineEmits(['success'])
let previousUrl = null
let windowRef = null
function onClick(url) {
  window.addEventListener('message', onMessage)

  // Center the popup window
  const windowWidth = 600
  const windowHeight = 700
  const left = screen.width / 2 - windowWidth / 2
  const top = screen.height / 2 - windowHeight / 2
  const windowFeatures = `toolbar=no, menubar=no, width=${windowWidth}, height=${windowHeight}, top=${top}, left=${left}`

  if (windowRef === null || windowRef.closed) {
    windowRef = window.open(url, 'Pivot OAuth', windowFeatures)
  } else if (previousUrl !== url) {
    windowRef = window.open(url, 'Pivot OAuth', windowFeatures)
    windowRef.focus()
  } else {
    windowRef.focus()
  }

  previousUrl = url
}

function onMessage(event) {
  const { type, status, error } = event.data
  if (type !== 'pivot-oauth') return

  window.removeEventListener('message', onMessage)

  if (status === 'ok') {
    emit('success', { provider: event.data.provider })
  } else if (status === 'error') {
    if (error.body && error.body.errors) {
      flash.error(error.body.errors[0]?.message)
    } else if (error.message) {
      flash.error(error.message)
    } else {
      flash.error('Error signing in with OAuth provider.')
    }
  }
}

onUnmounted(() => {
  window.removeEventListener('message', onMessage)
  windowRef?.close()
})
</script>
<style lang="scss" scoped>
.oauth__layout {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 7px;
}
.oauth__sso {
  width: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
