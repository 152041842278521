<template>
  <div class="split-view-component">
    <div class="split-view-component__left-content" v-if="leftContent">
      <split-view-content
        aria-label="left content"
        name="Left Content"
        :components="leftContent"
        :parent-index="sectionIndex"
        :variable-context="variableContext"
        :component-dragging="isComponentDragging"
        @componentDragging="setComponentDragging"
        section-name="Split View"
        :questionNumber="questionNumber"
        @moveComponent="
          (componentId: string, dir: string) =>
            emit('movePanel', componentId, dir)
        "
        @update="
          (components: DraggableComponent[]) =>
            sideContentUpdated('leftContent', components)
        "
        :allowMoveDown="true"
        :allowMoveUp="!first"
        data-queryid="split-view-left-content"
      />
    </div>
    <div class="split-view-component__right-content" v-if="rightContent">
      <split-view-content
        aria-label="right content"
        name="Right Content"
        :components="rightContent"
        :parent-index="sectionIndex"
        :variable-context="variableContext"
        :component-dragging="isComponentDragging"
        @componentDragging="setComponentDragging"
        :questionNumber="questionNumber"
        section-name="Split View"
        :allowMoveUp="true"
        :allowMoveDown="!last"
        @moveComponent="
          (componentId: string, dir: string) =>
            emit('movePanel', componentId, dir)
        "
        @update="
          (components: DraggableComponent[]) =>
            sideContentUpdated('rightContent', components)
        "
        data-queryid="split-view-right-content"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import SplitViewContent from './SplitViewContent.vue'
import { DraggableComponent } from '../types'

const emit = defineEmits(['change', 'movePanel'])

const props = defineProps({
  component: {
    required: true,
    type: Object
  },
  sectionIndex: {
    required: true,
    type: Number
  },
  variableContext: {
    type: Object,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  canHideComponent: {
    type: Boolean,
    default: true
  },
  first: {
    type: Boolean,
    default: false
  },
  last: {
    type: Boolean,
    default: false
  },
  questionNumber: Function
})

const leftContent = computed(() => props.component?.leftContent)
const rightContent = computed(() => props.component?.rightContent)

const isComponentDragging = ref<boolean>(false)
const setComponentDragging = (dragging: boolean) => {
  isComponentDragging.value = dragging
}
const sideContentUpdated = (
  side: 'leftContent' | 'rightContent',
  components: DraggableComponent[]
) => {
  emit('change', {
    [side]: components
  })
}
</script>

<style lang="scss" scoped>
.split-view-component {
  background: transparent;

  :deep(.add-component) {
    margin: 10px 0;
    border: 1px solid $light-teal;
    padding: 10px;
    border-style: dotted;
    border-radius: 5px;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.split-view-component__left-content,
.split-view-component__right-content {
  background: transparent;
}
.split-view-component__left-content {
  border-bottom: 3px solid #fff;
}
.componentText {
  font-weight: 400;
  margin-left: 5px;
}
</style>
