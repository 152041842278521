import { ref, watchEffect } from 'vue'

/**
 * Like Vue.computed, but memoized. Equal primitive values will not trigger a rerender of the component.
 * This is most useful for values that are checked often, but change infrequently.
 */
export default function useTitle(fn) {
  const cache = ref(fn())
  document.title = `${cache.value} | Pivot Interactives`
  watchEffect(() => {
    const newValue = fn()
    if (!cache.value || newValue !== cache.value) {
      cache.value = newValue
      document.title = `${newValue} | Pivot Interactives`
    }
  })

  return
}
