<template>
  <div v-if="show && hasNotes" class="activity-notes">
    <h4>Instructor Notes</h4>
    <editor-content :text="text" :variable-context="variableContext" />
  </div>
</template>

<script>
import { hasContent } from 'src/shared/components/editor/utils.js'

export default {
  name: 'ActivityNotes',
  inject: ['inherited'],
  props: {
    show: {
      type: Boolean,
      default: true
    },
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    text() {
      return this.inherited.component
        ? this.inherited.component.notes
        : this.inherited.activity.notes
    },
    hasNotes() {
      return hasContent(this.text)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-notes {
  background: $light-grey;
  margin: 16px 0;
  padding: 16px;
  border-radius: 4px;
}
</style>
