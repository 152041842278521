<template>
  <editor-bubble-menu
    plugin-key="dropTargetContext"
    :editor="editor"
    :tippy-options="{ placement: 'bottom' }"
    :should-show="shouldShow"
  >
    <div>
      <editor-menu-button
        label="Edit"
        title="Edit"
        icon="pencil"
        @click="onClick"
      />
      <editor-menu-dropdown
        v-if="editor.isActive('dropTarget')"
        label="Preview Choice"
        title="Preview Choice"
        icon="magnifying-glass"
        menu-class="preview-menu"
        :editor="editor"
        list
      >
        <dropdown-action @click="() => setChoice()">Clear</dropdown-action>
        <dropdown-action
          v-for="choice in choices"
          :key="choice"
          @click="() => setChoice(choice.id)"
        >
          {{ getText(choice.text, { variables: [] }, { inline: true }) }}
        </dropdown-action>
      </editor-menu-dropdown>
    </div>
  </editor-bubble-menu>
</template>

<script setup>
import { computed } from 'vue'
import { isActive } from '@tiptap/core'
import EditorBubbleMenu from '../components/EditorBubbleMenu'
import EditorMenuButton from '../components/EditorMenuButton'
import EditorMenuDropdown from '../components/EditorMenuDropdown'
import { getText } from '../utils'

const props = defineProps({
  editor: {
    type: Object,
    required: true
  }
})

const choices = computed(
  () => props.editor.extensionStorage.dropTarget?.choices?.value ?? []
)
function setChoice(id) {
  const attrs = props.editor.getAttributes('dropTarget')
  if (attrs.id) {
    const targets = props.editor.extensionStorage.dropTarget?.targetPreviews
    if (targets) {
      targets[attrs.id] = id
    }
  }
}

function onClick() {
  if (props.editor.isActive('dropTarget')) {
    props.editor.commands.openDropTargetModal()
  } else {
    props.editor.commands.openDropTargetContextModal()
  }
}

const shouldShow = computed(
  () =>
    ({ state }) =>
      isActive(state, 'dropTargetContext') || isActive(state, 'dropTarget')
)
</script>

<style lang="scss" scoped>
:deep(.preview-menu) {
  max-width: 200px;
}
</style>
