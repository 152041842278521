<template>
  <h3 class="panel-heading" :id="`${id}-header`">
    <slot />
  </h3>
</template>

<script setup>
import { inject } from 'vue'

const { id } = inject('dashboard-panel')
</script>

<style scoped lang="scss">
.panel-heading {
  color: $plum;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  padding: 20px 15px 20px 0;
  position: relative;
  display: inline-block;

  &:hover &:focus {
    cursor: pointer;
    color: $teal;
  }
}
</style>
