<template>
  <view-container>
    <flash />
    <h1 style="font-size: 48px">Hello, {{ firstName }}!</h1>
    <div class="landing-page-grid">
      <license-information-widget class="landing-page-grid__license-info" />
      <onboarding-widget class="landing-page-grid__onboarding" />
      <div class="landing-page-grid__usage-overview"></div>
      <quick-links-widget class="landing-page-grid__quick-links" />
    </div>
    <footer-image />
  </view-container>
</template>

<script setup>
import FooterImage from 'src/shared/components/FooterImage'
import LicenseInformationWidget from '../components/LicenseInformationWidget'
import OnboardingWidget from '../components/OnboardingWidget'
import QuickLinksWidget from '../components/QuickLinksWidget'
import { useStore } from 'vuex'
import Flash from 'src/shared/components/Flash'

const store = useStore()
const firstName = store.getters.firstName
</script>

<style scoped lang="scss">
.landing-page-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  grid-auto-rows: minmax(100px, auto);
  width: 100%;
}
.landing-page-grid__license-info {
  grid-column: 1 / 3;
  grid-row: 1;
}
.landing-page-grid__onboarding {
  grid-column: 3 / 4;
  grid-row: 1;
}
.landing-page-grid__usage-overview {
  grid-column: 1 / 4;
  grid-row: 2;
}
.landing-page-grid__quick-links {
  grid-column: 3 / 4;
  grid-row: 2;
}
</style>
