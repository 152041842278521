<template>
  <LoadingContainer :loading="isLoading">
    <browse-activities
      v-slot="{ library, updateQuery }"
      ref="browseActivities"
      :query="computedQuery"
    >
      <tab-provider :tab="tab" @change="changeTab">
        <view-container>
          <StickyHeader>
            <template #primary-navigation>
              <Breadcrumb>
                <BreadcrumbItem>
                  {{ ltiSession?.context.platform.name }}:
                  {{ ltiSession?.context.context?.name || 'Class' }}
                </BreadcrumbItem>
                <BreadcrumbItem
                  :to="{
                    name: 'lti_connect_assignment'
                  }"
                >
                  {{ ltiSession?.context.class?.name }}
                </BreadcrumbItem>
              </Breadcrumb>
            </template>
            <template #title>
              <StickyHeaderTitle class="inline-block">
                {{
                  library && library.name
                    ? `Assign: ${library.name}`
                    : 'Assign Activity'
                }}
              </StickyHeaderTitle>
            </template>

            <template v-slot:sub-title="{ isStuck }">
              <StickyHeaderSubTitle
                :class="{
                  'mt-3': !isStuck
                }"
              >
                <tab-header :stuck="isStuck">
                  <tab-header-button tab="shared">
                    <sticky-header-libraries>
                      <sticky-header-shared-libraries />
                    </sticky-header-libraries>
                  </tab-header-button>
                  <tab-header-button tab="pivot"
                    >Pivot Interactives Library</tab-header-button
                  >
                </tab-header>
              </StickyHeaderSubTitle>
            </template>
            <template #actions>
              <UIButton
                type="outline"
                variant="destructive"
                size="md"
                @click="cancelLTIFlow(ltiSession)"
                >Cancel Connection
              </UIButton></template
            >
          </StickyHeader>
          <library-filters
            :modelValue="computedQuery"
            @update:modelValue="updateQuery"
            includeSearch
          />
          <div class="flex justify-between -mt-3">
            <browse-summary />
            <div class="activity-view__settings">
              <activity-sort-action />
              <activity-search-view-settings />
            </div>
          </div>
          <filter-chips
            :modelValue="computedQuery"
            @update:modelValue="updateQuery"
          />
          <activity-list>
            <template #card="{ activity }">
              <activity-card :activity="activity">
                <activity-card-assign-actions
                  @assign="assignActivity"
                  openInNewTab
                />
              </activity-card>
            </template>
            <template #row="{ activity }">
              <activity-row :activity="activity">
                <activity-row-assign-actions
                  @assign="assignActivity"
                  openInNewTab
                />
              </activity-row>
            </template>
          </activity-list>
        </view-container>
      </tab-provider>
    </browse-activities>
  </LoadingContainer>
</template>

<script setup lang="ts">
import client from 'src/shared/api-client'
import BrowseActivities from 'src/modules/libraries/components/BrowseActivities.vue'
import BrowseSummary from 'src/modules/libraries/components/BrowseSummary.vue'
import ActivityList from 'src/modules/libraries/components/ActivityList.vue'
import ActivityCard from 'src/modules/libraries/components/ActivityCard.vue'
import ActivityRow from 'src/modules/libraries/components/ActivityRow.vue'
import StickyHeaderLibraries from 'src/modules/libraries/components/StickyHeaderLibraries.vue'
import StickyHeaderSharedLibraries from 'src/modules/libraries/components/StickyHeaderSharedLibraries.vue'
import ActivitySortAction from 'src/modules/libraries/components/ActivitySortAction.vue'
import LibraryFilters from 'src/modules/libraries/components/filters/LibraryFilters.vue'
import FilterChips from 'src/modules/libraries/components/FilterChips.vue'
import ActivitySearchViewSettings from 'src/modules/libraries/components/ActivitySearchViewSettings.vue'
import ActivityCardAssignActions from 'src/modules/libraries/components/ActivityCardAssignActions.vue'
import ActivityRowAssignActions from 'src/modules/libraries/components/ActivityRowAssignActions.vue'
import { ref, computed, onBeforeMount } from 'vue'
import { GetLtiSessionResponse } from '@pi/api-types'
import { RouteRecordName, useRouter } from 'vue-router'
import { postRedirect } from 'src/setup/mixins/form-post'
import { useFlash } from 'src/shared/hooks/flash'
import { useStore } from 'vuex'
import { UIButton } from '@pi/design'
import { cancelLTIFlow } from '../lti-utils'
interface LtiAssignActivityProps {
  tab?: string
  query: {
    library?: string
    page?: string
    sort?: string
    dir?: string
    categories?: string
    q?: string
  }
}
const flash = useFlash()
const store = useStore()
const isV3Enabled = computed(() => store.getters['features/isEnabled']('v3lti'))
const router = useRouter()
const props = withDefaults(defineProps<LtiAssignActivityProps>(), {
  tab: 'shared'
})
const isLoading = ref(false)
const showInputBox = ref(false)

const tab = computed(() => {
  return props.query?.library
    ? props.query.library !== 'pivot'
      ? 'shared'
      : 'pivot'
    : 'shared'
})
const ltiSession = ref<GetLtiSessionResponse>()
async function loadLtiSession() {
  ltiSession.value = await client.lti.getSession()

  if (
    ltiSession.value.state !== 'CONNECT_ASSIGNMENT' &&
    ltiSession.value.state !== 'CONNECT_TEMPLATE'
  ) {
    await router.push({ name: 'lti' })
  }
}

onBeforeMount(async () => {
  await loadLtiSession()
  isLoading.value = false
})

const computedQuery = computed(() => {
  return {
    ...props.query,
    page: parseInt(props.query.page ?? '1') || 1,
    sort: props.query.sort || 'updatedAt',
    dir: props.query.dir || 'desc',
    q: props.query.q || '',
    categories: Object.entries(props.query.categories || {}).reduce(
      (filters, [key, value]) => {
        if (value) {
          filters[key] = Array.isArray(value) ? value : [value]
        }
        return filters
      },
      {} as { [key: string]: string | string[] }
    )
  }
})

const changeTab = (newTab: string) => {
  if (newTab !== 'pivot') return
  router.push({
    name: router.currentRoute.value.name as RouteRecordName,
    query: { library: 'pivot' }
  })
}

const assignActivity = async (activityId: string) => {
  if (ltiSession?.value?.state === 'CONNECT_TEMPLATE') {
    let response = await client.lti.createTemplate({ activityId: activityId })

    flash.success('Template connected successfully.')
    const { redirectUrl, token, openedInNewTab } = response
    if (openedInNewTab) {
      window.opener.postMessage(
        JSON.stringify({
          type: 'pivot:deep-link-response',
          redirectUrl,
          token
        }),
        `${window.location.protocol}//${window.PI_API_HOST}`
      )
      window.close()
    } else {
      postRedirect(redirectUrl, { JWT: token })
    }
  } else {
    router.push({
      name: 'lti_new_assignment',
      query: {
        classIds:
          ltiSession.value?.context?.sections &&
          ltiSession.value?.context?.sections.length
            ? ltiSession.value.context.sections
                .filter(s => !!s.class?.id)
                .map(s => s.class?.id)
            : [ltiSession.value?.context?.class?._id],
        activityIds: [activityId]
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.activity-view__settings {
  display: flex;
  gap: 30px;
  align-content: center;
  align-items: center;

  & form-buttom {
    height: 24px;
  }
}
.activity-row__actions {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
</style>
