<template>
  <view-container normal>
    <view-header>
      <view-title>Other LTI Registration</view-title>
    </view-header>
    <loading-container :loading="loadingConfig">
      <section>
        <collapse-panel
          title="Step 1: Configure Pivot Interactives in Your Platform"
        >
          <form-group>
            <strong>
              Your platform must support LTI 1.3 in order to integrate Pivot
              Interactives.
            </strong>
          </form-group>
          <ol>
            <li>
              <form-group>
                In your platform, find the page to configure a new LTI tool.
              </form-group>
            </li>
            <li>
              <form-group>
                Copy and paste the following values into fields provided by your
                platform. We may have different names, but every LTI platform
                should be expecting the same values.
              </form-group>
              <form-group>
                <form-label>Name</form-label>
                <click-to-copy-field
                  aria-label="Tool Name"
                  :value="config.title"
                />
              </form-group>
              <form-group>
                <form-label>Target Link URI</form-label>
                <click-to-copy-field
                  aria-label="Target Link URI"
                  :value="config.launchUrl"
                />
              </form-group>
              <form-group>
                <form-label>OpenID Connect Initiation URL</form-label>
                <click-to-copy-field
                  aria-label="OpenID Connect Initiation URL"
                  :value="config.oidcUrl"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Your platform should expect either a public key or a JWK. Copy
                and paste the appropriate value from below.
              </form-group>
              <form-group>
                <form-label>Public Key</form-label>
                <click-to-copy-field
                  aria-label="Public Key"
                  :value="(config.security || {}).key"
                  message="Copied public key to clipboard."
                  rows="6"
                />
              </form-group>
              <form-group>
                <form-label>JWK</form-label>
                <click-to-copy-field
                  aria-label="JWK"
                  :value="JSON.stringify((config.security || {}).jwk)"
                  message="Copied JWK to clipboard."
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Your platform must also have a way to configure LTI Deep
                Linking. Copy and paste the values below. Again, the names might
                be different, but LTI platforms should expect the same
                information.
              </form-group>
              <form-group>
                <form-label>Text</form-label>
                <click-to-copy-field
                  aria-label="Deep Link Text"
                  :value="(config.deepLinking || {}).text"
                />
              </form-group>
              <form-group>
                <form-label>Domain</form-label>
                <click-to-copy-field
                  aria-label="Deep Link Domain"
                  :value="(config.deepLinking || {}).domain"
                />
              </form-group>
              <form-group>
                <form-label>Target Link URI</form-label>
                <click-to-copy-field
                  aria-label="Deep Link Launch URI"
                  :value="(config.deepLinking || {}).launchUrl"
                />
              </form-group>
              <form-group>
                <form-label>Message Type</form-label>
                <click-to-copy-field
                  aria-label="Deep Link Message Type"
                  :value="(config.deepLinking || {}).messageType"
                />
              </form-group>
            </li>
            <li>
              <form-group>
                Set the privacy level to <strong>Public</strong>, if possible.
                This makes it easier for student's accounts to be linked to
                Pivot Interactives.
              </form-group>
            </li>
          </ol>
        </collapse-panel>
      </section>
      <section>
        <collapse-panel
          title="Step 2: Configure Your Platform In Pivot Interactives"
        >
          <async-form @submit="register" persist>
            <ol>
              <li>
                <form-group>
                  Your platform should provide the following information. The
                  names may be different but each LTI platform should provide
                  the same information.
                </form-group>
                <form-group>
                  <form-label for="platform-name">Name</form-label>
                  <text-input
                    id="platform-name"
                    v-model="platformData.name"
                    label="platform name"
                    rules="required"
                  />
                </form-group>
                <form-group>
                  <form-label for="platform-issuer">Issuer</form-label>
                  <text-input
                    id="platform-issuer"
                    v-model="platformData.issuer"
                    label="platform issuer"
                    :rules="{
                      required: true,
                      regex: /^https:\/\/((\w|\d|-)+\.)+(\w|\d|-)+(:\d+)?$/
                    }"
                    placeholder="Example: https://platform.com"
                  />
                </form-group>
                <form-group>
                  <form-label for="platform-clientid">Client ID</form-label>
                  <text-input
                    id="platform-clientid"
                    v-model="platformData.clientId"
                    label="platform client ID"
                    rules="required"
                    placeholder="Example: 100013"
                  />
                </form-group>
                <form-group>
                  <form-label for="platform-oidc-url">
                    OpenID Connect Authorization URL
                  </form-label>
                  <text-input
                    id="platform-oidc-url"
                    v-model="platformData.oidcUrl"
                    label="platform OpenID Connect URL"
                    :rules="{
                      required: true,
                      url: {
                        require_protocol: true
                      }
                    }"
                    placeholder="Example: https://platform.com/lti/authorize_redirect"
                  />
                </form-group>
                <form-group>
                  <form-label for="platform-oauth-url">
                    OAuth Token URL
                  </form-label>
                  <text-input
                    id="platform-oauth-url"
                    v-model="platformData.oauthUrl"
                    label="platform OAuth token URL"
                    :rules="{
                      url: {
                        require_protocol: true
                      }
                    }"
                    placeholder="Example: https://platform.com/lti/oauth2/token"
                  />
                </form-group>
                <form-group>
                  <form-label for="platform-oauth-aud">
                    OAuth Audience
                  </form-label>
                  <text-input
                    id="platform-oauth-aud"
                    v-model="platformData.oauthAud"
                    label="platform OAuth audience"
                    :rules="{
                      url: {
                        require_protocol: true
                      }
                    }"
                    placeholder="Example: https://platform.com/lti/oauth2/token"
                    help-text="Optional - Your tool may not specify an audience"
                  />
                </form-group>
              </li>
              <li>
                <form-group>
                  Your platform must have either a public key, a JWK, or a URL
                  to fetch JWKs from. Select which one and enter it below.
                </form-group>
                <form-group>
                  <form-label id="platform-key-type-label">Key Type</form-label>
                  <selector-input
                    v-model="platformData.keyType"
                    aria-labelledby="platform-key-type-label"
                    rules="required"
                    label="platform key type"
                    class="selector-input--horizontal"
                  >
                    <selector-option
                      value="publicKey"
                      :title="keyLabels['publicKey']"
                    />
                    <selector-option value="jwk" :title="keyLabels['jwk']" />
                    <selector-option
                      value="jwkUrl"
                      :title="keyLabels['jwkUrl']"
                    />
                  </selector-input>
                </form-group>
                <form-group>
                  <form-label :for="`platform-${platformData.keyType}`">
                    {{ keyLabels[platformData.keyType] }}
                  </form-label>
                  <text-input
                    v-if="platformData.keyType === 'jwkUrl'"
                    id="platform-jwkUrl"
                    v-model="platformData.jwkUrl"
                    label="JWK URL"
                    :rules="{
                      required: true,
                      url: { require_protocol: true }
                    }"
                    placeholder="Example: https://platform.com/lti/jwks"
                  />
                  <multiline-text-input
                    v-else-if="platformData.keyType === 'jwk'"
                    id="platform-jwk"
                    v-model="platformData.jwk"
                    label="JWK"
                    rules="required"
                    rows="6"
                    wrap="off"
                    :placeholder="exampleJwk"
                    @blur="formatJwk"
                  />
                  <multiline-text-input
                    v-else-if="platformData.keyType === 'publicKey'"
                    id="platform-publicKey"
                    v-model="platformData.publicKey"
                    label="public key"
                    rules="required"
                    rows="5"
                    wrap="off"
                    :placeholder="examplePublicKey"
                    @blur="formatPublicKey"
                  />
                </form-group>
              </li>
              <li>
                <form-group>
                  Press the button to register your platform in Pivot
                  Interactives. Congratulations! Pivot is now connected to your
                  platform. You may have other steps to enable Pivot for your
                  classes in your platform.
                </form-group>
                <form-group>
                  <submit-button>
                    <template #default>Register</template>
                    <template #submitting>Configuring</template>
                    <template #submitted>Success</template>
                  </submit-button>
                </form-group>
              </li>
            </ol>
          </async-form>
        </collapse-panel>
      </section>
    </loading-container>
  </view-container>
</template>

<script>
import ClickToCopyField from 'src/shared/components/ClickToCopyField'
import client from 'src/shared/api-client'

const KEY_LABELS = {
  publicKey: 'Public Key',
  jwk: 'JWK',
  jwkUrl: 'JWK URL'
}

export default {
  name: 'LtiPlatformRegistrationOtherView',
  components: { ClickToCopyField },
  data() {
    return {
      keyLabels: KEY_LABELS,
      config: {},
      loadingConfig: true,
      platformData: {
        name: '',
        issuer: '',
        clientId: '',
        oidcUrl: '',
        oauthUrl: '',
        keyType: 'publicKey',
        jwkUrl: '',
        jwk: '',
        publicKey: ''
      }
    }
  },
  computed: {
    examplePublicKey() {
      return `Example:
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDNzEIs2zwRR9TjuHNpofGGGQUJ
0wCzME7yST7BUNUxRk8UZBJzia/E0akOv8qwfFswCYVMjgoTRtgh4UUCHstIsAWi
GEhEj3C4mohEidmSaXAo1WibDVrrx8tuMBDEvE4dAZuj7ixuaTNo0c0B6O9WoUnw
DKgfW0F8tBg23jxrTwIDAQAB`
    },
    exampleJwk() {
      return `Example: {
  "kty": "RSA",
  "e": "AQAB",
  "kid": "b6a3e1b7-4610-4b78-b0ff-12ab43b9ea89",
  "n": "zcxCLNs8EUfU47hzaaHxhhkFCdMAszBO8kk-wVDVMUZPFGQSc4mvxNGpDr_KsHxbMAmFTI4KE0bYIeFFAh7LSLAFohhIRI9wuJqIRInZkmlwKNVomw1a68fLbjAQxLxOHQGbo-4sbmkzaNHNAejvVqFJ8AyoH1tBfLQYNt48a08"
}`
    }
  },
  methods: {
    formatJwk() {
      try {
        this.platformData.jwk = JSON.stringify(
          JSON.parse(this.platformData.jwk),
          null,
          2
        )
      } catch {}
    },
    formatPublicKey() {
      try {
        this.platformData.publicKey = this.platformData.publicKey.match(
          /-+BEGIN.+KEY-+\n(.+)\n-+END.+KEY-+/s
        )[1]
      } catch {}
    },
    async register(e) {
      try {
        const { name, issuer, clientId, oidcUrl, oauthUrl, oauthAud, keyType } =
          this.platformData
        await client.lti.createPlatform({
          name,
          issuer,
          clientId,
          oidcUrl,
          oauthUrl,
          oauthAud: oauthAud || oauthUrl,
          [keyType]: this.platformData[keyType]
        })
        this.$success(`${name} successfully configured in Pivot.`)
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    async loadConfig() {
      this.loadingConfig = true
      try {
        const config = await client.lti.getConfig({ platform: 'other' })
        this.config = config
      } finally {
        this.loadingConfig = false
      }
    }
  },
  async created() {
    await this.loadConfig()
  }
}
</script>
