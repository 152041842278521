<template>
  <icon :icon="resolvedIcon" />
</template>

<script>
/** Renders the appropriate icon based on the collapse state. */
export default {
  name: 'CollapseIcon',
  inject: ['collapse'],
  props: {
    /** The icon type to use. */
    icon: {
      type: String,
      default: 'chevron'
    }
  },
  computed: {
    resolvedIcon() {
      return this.collapse.state ? `${this.icon}-up` : `${this.icon}-down`
    }
  }
}
</script>
