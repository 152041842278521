<template>
  <view-container normal>
    <flash />
    <help-banner />
    <div class="card">
      <h1 class="header">Purchase Course Codes</h1>
      <h4>
        <span class="callout">Are you a student?</span> You can join a class
        <router-link :to="{ name: 'join_class' }">here</router-link>.
      </h4>
      <async-form class="form" @submit="onSubmit">
        <h2 class="">Education Level</h2>
        <div class="switch-field">
          <input
            id="switch-two"
            v-model="licenseLevel"
            type="radio"
            name="education-level"
            value="college"
          />
          <label for="switch-two">College/University</label>
        </div>
        <h2 id="seatCountLabel" class="subheader">Number of Codes</h2>
        <div class="plain-text spacing-bottom">
          {{ licenseTimeframe }}
        </div>
        <form-group class="input-30 seats-input">
          <number-input
            id="addedSeats"
            v-model="seats"
            aria-labelledby="seatCountLabel"
            rules="required"
            label="Seat Number"
            type="number"
            min="0"
            step="1"
            oninput="validity.valid||(value='')"
          />
        </form-group>
        <table>
          <tr>
            <td>Cost Per Seat</td>
            <td>
              {{ $format.currency(costPerSeat, '--') }}
            </td>
          </tr>
          <tr>
            <td>Total Cost</td>
            <td>
              {{ $format.currency(totalCost, '--') }}
            </td>
          </tr>
        </table>
        <div><hr /></div>
        <form-group>
          <form-label for="role">Your Role</form-label>
          <select-field
            id="role"
            rules="required"
            v-model="role"
            @update:modelValue="value => handleRoleChange(value)"
          >
            <option value="student">Student</option>
            <option value="instructor">Instructor</option>
            <option value="administrator">Administrator</option>
            <option value="bookstorePurchaser">Bookstore Purchaser</option>
            <option value="other">Other</option>
          </select-field>
        </form-group>

        <h1 class="header-institution">Institution Details</h1>
        <form-group class="input-50">
          <form-label for="institutionZip">Zip/Postal Code</form-label>
          <div class="input-flex">
            <text-input
              id="institutionZip"
              v-model="institutionZip"
              :rules="{
                required: !isAdmin,
                postalCode: { country: 'US' }
              }"
              label="zip code"
              autocomplete="postal-code"
              v-on:keydown.enter.prevent="queryLocation"
              @blur="queryLocation"
            />
          </div>
        </form-group>
        <form-group class="input-50">
          <form-label for="institutionName">Name of Institution</form-label>
          <select-field
            :disabled="!institutionEnabled"
            id="institutionName"
            :rules="required"
            label="institutionName"
            @update:modelValue="value => selectSchool(value)"
            :modelValue="initialSelection"
          >
            <option
              v-for="institution in queryData"
              :key="institution.AccountName"
              :value="institution"
            >
              {{ institution.AccountName }}
            </option>
          </select-field>
          <p v-if="institutionEnabled">
            Talk to
            <a href="mailto:sales@pivotinteractives.com"
              >sales@pivotinteractives.com</a
            >
            if your school is not available.
          </p>
        </form-group>

        <div><hr /></div>

        <form-group class="button-group">
          <submit-button class="form-button--beta">
            Continue to Payment
          </submit-button>
        </form-group>
      </async-form>
    </div>
  </view-container>
</template>

<script>
import { mapState } from 'vuex'
import { getPurchaseData, mergePurchaseData } from 'src/shared/utils/purchases'
import utils from '../pricing.ts'
import HelpBanner from 'src/modules/purchases/components/HelpBanner'
import Flash from 'src/shared/components//Flash'
import AlertModal from 'src/shared/components/modals/AlertModal'
import client from 'src/shared/api-client.ts'

export default {
  name: 'ConfigureCodes',
  props: ['id'],
  components: { HelpBanner, Flash },
  inject: ['$modal'],
  data() {
    return {
      licenseLevel: 'college',
      seats: 0,
      ...getPurchaseData().createCodesForm,
      institutionName: '',
      institutionZip: '',
      initialSelection: '',
      salesforceId: undefined,
      queryData: undefined,
      role: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user || {}
    }),
    institutionEnabled() {
      return this.queryData
    },
    siteLicenseId() {
      return this.user.siteLicense && this.user.siteLicense.id
    },
    licenseTimeframe() {
      return 'Each course code grants access for a single term.'
    },
    costPerSeat: function () {
      return utils.determineCodePurchasePriceCostPerSeat(this.licenseLevel)
    },
    totalCost: function () {
      return utils.determineCodePurchasePrice(this.licenseLevel, this.seats)
    },
    isUpdatingQuote() {
      return this.$route.query && this.$route.query.id
    }
  },
  methods: {
    async onSubmit({ done }) {
      try {
        mergePurchaseData({
          purchaseType: 'access-codes',
          createCodesForm: {
            licenseLevel: this.licenseLevel,
            seats: this.seats,
            institutionName: this.institutionName,
            salesforceId: this.salesforceId
          }
        })
        if (!this.id) {
          const { id } = await client.purchases.createAccessCodesQuote({
            licenseLevel: this.licenseLevel,
            seats: this.seats,
            institutionName: this.institutionName,
            salesforceId: this.salesforceId
          })

          mergePurchaseData({ id })
        } else {
          await client.purchases.updateAccessCodesQuote({
            id: this.id,
            licenseLevel: this.licenseLevel,
            seats: this.seats,
            institutionName: this.institutionName,
            salesforceId: this.salesforceId
          })
        }
        this.$router.push({ name: 'purchase_billing_information' })

        done()
      } catch (err) {
        done(false)
        throw err
      }
    },
    async queryLocation() {
      if (this.institutionZip) {
        const response = await fetch(
          `https://discoveryed.my.salesforce-sites.com/WebLead/services/apexrest/Account/?postalCode=${this.institutionZip}`
        )
        this.queryData = await response.json()

        for (const data in this.queryData) {
          if (this.salesforceId === this.queryData[data].Id) {
            this.initialSelection = this.queryData[data]
          }
        }
      }
    },
    selectSchool(value) {
      this.institutionName = value.AccountName
      this.salesforceId = value.Id
    },
    async handleRoleChange(value) {
      if (value === 'student') {
        await this.$modal.show(AlertModal, {
          title: `Students cannot purchase bookstore codes from this site`,
          body: 'You must purchase bookstore codes direct from your school bookstore. Or you can purchase access using a credit card when you join a class.'
        })
        this.$router.push({ name: 'login' })
      }
    }
  },
  async mounted() {
    if (this.isUpdatingQuote) {
      const { quote } = await client.purchases.getQuote({ id: this.id })
      this.quote = quote || {}
      this.licenseLevel = quote.licenseLevel
      this.seats = quote.seats
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 60px 64px;
}
.header {
  margin-top: 0;
}
.header-institution {
  margin-top: 0;
  text-align: left;
}
.subheader {
  margin-bottom: 16px;
}
.spacing-bottom {
  margin-bottom: 48px;
}
.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field label {
  background-color: #ffffff;
  color: #3c393a;
  font-size: 20px;
  font-weight: normal;
  line-height: 150%;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid #d4d3d3;
  &:hover {
    cursor: pointer;
  }
}
#switch-one {
  border-radius: 3px 0px 0px 3px;
}
#switch-two {
  border-radius: 0px 3px 3px 0px;
}
.switch-field input:checked + label {
  background-color: #3d2c60;
  box-shadow: none;
  border: 1px solid #3d2c60;
  color: #ffffff;
}
.payment-view {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 48px 64px;
}
.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field label {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    cursor: pointer;
  }
}
.switch-field input:checked + label {
  background-color: #3d2c60;
  box-shadow: none;
  border: 1px solid #3d2c60;
  color: #ffffff;
}
.label-1 {
  border-radius: 4px 0 0 4px;
}
.label-2 {
  border-radius: 0 4px 4px 0;
}
.input-30 {
  width: 33%;
}
.plain-text {
  font-size: 20px;
  line-height: 150%;
  font-weight: normal;
}
table {
  border: 1px solid #eeeded;
  border-radius: 0px 0px 3px 3px;
  width: 292px;
  color: #575153;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}
tr {
  border: 1px solid #eeeded;
}
td {
  padding: 12px 16px;
}
.button-group {
  display: flex;
  flex-direction: row-reverse;
}
.callout {
  font-weight: 700;
}
</style>
