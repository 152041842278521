<template>
  <modal ref="modal" :closable="false" @submit="submit">
    <modal-header>Your session has expired.</modal-header>
    <modal-body>
      <template v-if="step === 'login'">
        <p>Please log in again to continue where you left off.</p>
        <form-group v-if="user.authType.includes('password')">
          <form-label for="session-password">Password</form-label>
          <div>
            <text-input
              id="session-password"
              v-model="password"
              label="password"
              rules="required"
              type="password"
            />
          </div>
        </form-group>
        <div v-else>
          <oauth-selection
            class="session-expired__oauth"
            :allowed="[...user.authType]"
            @success="onOauthSuccess"
          />
        </div>
      </template>
      <template v-if="step === 'mfa'">
        <p>Please verify your MFA code.</p>
        <form-group>
          <form-label for="mfa-code">Code</form-label>
          <div>
            <text-input
              id="mfa-code"
              v-model="token"
              label="code"
              rules="required"
            />
          </div>
        </form-group>
      </template>
    </modal-body>
    <modal-actions v-if="user.authType.includes('password')">
      <modal-button-submit>Log In</modal-button-submit>
    </modal-actions>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'
import client from 'src/shared/api-client'
import OauthSelection from 'src/modules/auth/components/OauthSelection.vue'

export default {
  name: 'SessionExpiredModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    OauthSelection
  },
  data() {
    return {
      email: '',
      password: '',
      token: '',
      step: 'login'
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    async onOauthSuccess() {
      await client.auth.loginOAuth({ id: this.user?.id })
      this.$success(
        `You have successfully logged in and can continue where you left off.`
      )
      this.$refs.modal.close()
    },
    async submit(e) {
      try {
        if (this.step === 'login') {
          const response = await client.auth.login({
            email: this.user?.email,
            password: this.password
          })
          if (response?.mfa) {
            this.step = 'mfa'
            e.done(false)
            return
          }
        } else {
          await client.auth.verifyMFAToken(this.token)
        }
        this.$success(
          `You have successfully logged in and can continue where you left off.`
        )
        e.done()
      } catch (error) {
        e.done(false)
        if (error.status === 401) {
          const httpError = error.body?.errors?.[0]?.message ?? ''
          this.$error(httpError)
        } else {
          throw error
        }
      }
    }
  }
}
</script>

<style scoped>
.oauth-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 66px;
}
.oauth-buttons a {
  margin: 0px 8px 8px;
}

.session-expired__oauth {
  margin: auto;
  width: fit-content;
}
</style>
