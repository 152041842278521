<template>
  <footer class="hidden-print">
    <div class="container">
      <div class="navbar">
        <div class="row hidden-xs hidden-sm">
          <div class="col-md-5">
            © {{ new Date().getFullYear() }} Pivot Interactives, SBC
          </div>
          <div class="col-md-2 text-center">Version {{ piVersion }}</div>
          <div class="col-md-5 text-right">
            <hyper-link :to="{ name: 'privacy' }"> Privacy</hyper-link>
            |
            <hyper-link :to="{ name: 'terms' }">Terms</hyper-link>
            |
            <template v-if="showCookieSettings">
              <!-- OneTrust Cookies Settings button start -->

              <button
                class="ot-sdk-show-settings form-button form-button--link"
                style="text-decoration: none !important"
              >
                Cookie Settings
              </button>

              <!-- OneTrust Cookies Settings button end -->
              |
            </template>
            <hyper-link :to="{ name: 'help' }">Help</hyper-link>
            |
            <hyper-link :to="{ name: 'info' }">Contact Us</hyper-link>
          </div>
        </div>
        <div class="visible-xs-block visible-sm-block">
          <div class="row">
            <div class="col-sm-12 text-center">
              <hyper-link :to="{ name: 'privacy' }"> Privacy</hyper-link>
              |
              <hyper-link :to="{ name: 'terms' }">Terms</hyper-link>
              |
              <template v-if="showCookieSettings">
                <!-- OneTrust Cookies Settings button start -->

                <button
                  class="ot-sdk-show-settings form-button form-button--link"
                  style="text-decoration: none !important"
                >
                  Cookie Settings
                </button>

                <!-- OneTrust Cookies Settings button end -->
                |
              </template>
              <hyper-link :to="{ name: 'help' }">Help</hyper-link>
              |
              <hyper-link :to="{ name: 'info' }">Contact Us</hyper-link>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              © {{ new Date().getFullYear() }} Pivot Interactives, SBC
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">Version {{ piVersion }}</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FooterNavigation',
  computed: {
    ...mapGetters(['piVersion', 'isAuthenticated']),
    showCookieSettings() {
      return !!window.PI_ONE_TRUST_COOKIE_SCRIPT_ID && !this.isAuthenticated
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background-color: #ffffff;
  width: 100%;
}

.navbar {
  margin-bottom: 0;
}
</style>
