<template>
  <tr v-if="hasDataStreamColumns">
    <th>
      <div class="bluetooth-header__wrapper">
        <form-button
          class="bluetooth-header__control"
          :disabled="dataStreamSampleCollectionDisabled"
          @click="
            () =>
              $bluetooth.collectingSamples
                ? $gridActions.stopCollectingBluetoothSamples()
                : $gridActions.startCollectingBluetoothSamples()
          "
          tabindex="-1"
          data-tablenav
        >
          {{ $bluetooth.collectingSamples ? 'Stop' : 'Capture' }}
        </form-button>
      </div>
    </th>

    <data-grid-bluetooth-column-header
      v-for="(column, columnIndex) in columnsArray"
      :key="columnIndex"
      :column="column"
    />
  </tr>
</template>

<script>
import DataGridBluetoothColumnHeader from 'src/shared/components/grid-graph/DataGridBluetoothColumnHeader'

export default {
  name: 'DataGridBluetoothHeader',
  components: { DataGridBluetoothColumnHeader },
  inject: ['$gridActions', '$bluetooth'],
  props: {
    columnsArray: {
      type: Array,
      required: true
    }
  },
  computed: {
    dataStreamSampleCollectionDisabled() {
      return Object.values(this.$bluetooth.connectedDevices).length < 1
    },
    hasDataStreamColumns() {
      return this.columnsArray.some(
        column =>
          !!column.get('deviceId') && column.get('deviceId') !== 'SYSTEM'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.bluetooth-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.bluetooth-header__control {
  background-color: $teal !important;
  color: white !important;
  padding: 7px 0;
  width: 60px;

  &:focus,
  &:hover {
    background-color: $darker-teal !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
  }
}
</style>
