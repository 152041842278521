<template>
  <modal @submit="addMembers" class="add-members-modal">
    <modal-header> Invite Members</modal-header>
    <modal-body>
      <p>
        Enter one email address per line in the box below for each member you
        want to invite
      </p>
      <checkbox v-if="isAdmin" v-model="directAdd">
        Directly add users to license without sending notifications
      </checkbox>
      <form-group>
        <form-label for="license-role">Role</form-label>
        <select-field label="role" id="license-role" v-model="licenseRole">
          <option value="member">Member</option>
          <option value="admin">Admin</option>
          <option value="grader">Grader</option>
        </select-field>
      </form-group>
      <form-group>
        <form-label for="emails"> Email Addresses </form-label>
        <div>
          <multiline-text-input
            data-focus
            id="emails"
            :model-value="emails"
            label="emails"
            rows="4"
            rules="required|emailPerLine"
            @change="emails => (this.emails = filterEmails(emails))"
          />
        </div>
        <template v-if="inviteErrors.length">
          <hr />
          <div>
            <p>
              {{ emailArray.length - inviteErrors.length }} out of
              {{ emailArray.length }} invites were successful.
            </p>
            <p>
              <b>
                These accounts were unable to be added to the license. Please
                contact support if you need further assistance.</b
              >
            </p>

            <ul class="scroll">
              <li v-for="(error, index) in inviteErrors" :key="index">
                {{ error.email }} - {{ error.error }} <br />
              </li>
            </ul>
          </div>
        </template>
      </form-group>
    </modal-body>
    <modal-actions>
      <modal-button-submit>{{
        directAdd ? 'Add' : 'Invite'
      }}</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import client from 'src/shared/api-client'
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

export default {
  name: 'LicenseAddMembersModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  data() {
    return {
      emails: '',
      licenseRole: 'member',
      inviteErrors: [],
      emailArray: [],
      directAdd: false
    }
  },
  props: {
    license: {
      required: true,
      type: Object
    },
    isAdmin: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  inject: ['$modal'],
  methods: {
    filterEmails(emails) {
      const emailList = [
        ...new Set(emails.split('\n').map(email => email.trim()))
      ]
        .map(email => email.trim())
        .filter(email => email)
        .join('\n')
      return emailList
    },
    async addMembers(e) {
      this.emailArray = this.emails.split('\n')
      this.inviteErrors = []
      const { failures } = await client.licenses.inviteMembers({
        id: this.license.id,
        emails: this.emailArray,
        role: this.licenseRole,
        directAdd: this.directAdd
      })

      if (failures.length > 0) {
        this.inviteErrors = failures
        this.emails = this.inviteErrors
          .map(failedInvite => failedInvite.email)
          .join('\n')

        e.done(false)
      } else {
        e.done({
          success: true,
          inviteErrors: this.inviteErrors,
          emailArray: this.emailArray
        })
      }
    }
  }
}
</script>

<style scoped>
.add-members-modal {
  resize: both;
  overflow: hidden;
  min-width: 30%;
}
ul.scroll {
  max-height: 100px;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  margin: 0;
}
</style>
