<template>
  <div class="multiple-choice-template">
    <collapse-panel
      :title="choicesTitle"
      :collapsed="isCollapsed"
      icon-right="caret-up"
      icon-down="caret-down"
      show-on-print
      @change="doToggle"
      class="collapse-panel"
      :class="{ 'has-error': hasError }"
    >
      <template v-if="isVetted" #header>
        <span class="multiple-choice__header">
          <span v-if="isSelectMany">
            Because you have selected more than one correct answer, this is a
            <b>select all that apply</b> question
          </span>
          <span v-else-if="isPollQuestion && component.autograde">
            Because you have not marked any answers as correct,
            <b>any selected choice(s)</b> will be autograded as correct
          </span>
          <span v-else-if="isSelectOne">
            Because you have one correct answer selected, this is a
            <b>select one answer</b> question
          </span>
        </span>
      </template>

      <div class="multiple-choice__row-container">
        <span class="multiple-choice__row--slider-container">
          <form-label
            :for="`${component.id}choicesPerRow`"
            :id="`${component.id}choicesPerRow`"
            >Number of choices per row</form-label
          >
          <range-slider
            class="multiple-choice__row--slider"
            :name="`${component.id}choicesPerRow`"
            :min="1"
            :max="4"
            :modelValue="component.choicesPerRow"
            @change="
              e =>
                $emit('change', { choicesPerRow: parseFloat(e.target.value) })
            "
        /></span>

        <span>
          <checkbox
            @click.stop
            :modelValue="component.randomizedChoices"
            @update:modelValue="
              randomizedChoices => $emit('change', { randomizedChoices })
            "
            >Randomize Choice Order</checkbox
          >
        </span>
      </div>
      <div
        v-for="(choice, index) in choices"
        :key="choice.keyId"
        class="inner-panel"
      >
        <div class="text-input">
          <form-group class="section-insight">
            <label class="control-label">Choice Content</label>
            <editor
              label="choice text"
              :text="choice.text"
              :variable-context="variableContext"
              :name="`${name}.choices.c${choice.keyId}.text`"
              :rules="{
                required: true,
                undefinedVariables: true
              }"
              @input="text => update({ text }, index)"
              video
            />
          </form-group>
        </div>
        <div class="response">
          <form-group class="section-insight">
            <label class="control-label">Response</label>
            <editor
              :text="choice.response"
              :readonly="!isVetted"
              :variable-context="variableContext"
              :rules="{
                undefinedVariables: true
              }"
              :name="`${name}.choices.c${choice.keyId}.response`"
              :help-text="
                !isVetted
                  ? 'This field is disabled while your account is pending.'
                  : ''
              "
              data-testid="choice-response-input"
              @input="text => update({ response: text }, index)"
              video
            />
          </form-group>
        </div>
        <div class="mark-correct">
          <checkbox
            v-if="isVetted"
            :modelValue="choice.answer"
            @update:modelValue="answer => update({ answer }, index)"
          >
            Correct Answer
          </checkbox>
        </div>
        <div class="panel-icons">
          <icon-button
            label="Move Up"
            icon="chevron-up"
            :color="index === 0 ? 'move-disabled' : 'move-avtive'"
            @click="moveUp(index)"
          />
          <icon-button
            label="Move Down"
            icon="chevron-down"
            :color="
              index === choices.length - 1 ? 'move-disabled' : 'move-avtive'
            "
            @click="moveDown(index)"
          />
          <icon-button
            label="Duplicate Choice"
            icon="copy"
            color="copy-icon"
            @click="duplicate(index)"
          />
          <icon-button
            label="Delete Choice"
            icon="trash"
            color="delete-icon"
            @click="remove(index)"
          />
          <icon-button
            label="Add Choice Below"
            icon="plus"
            color="add-icon"
            @click="addAtIndex(index)"
          />
        </div>
      </div>
      <div class="add-choice-btn">
        <form-button link @click="add">
          <icon icon="plus" />
          Add Choice
        </form-button>
      </div>
    </collapse-panel>
    <template v-if="isCollapsed">
      <div class="multiple-choice-answer">
        <ol type="A">
          <li v-for="choice in choices" :key="choice.keyId">
            <span class="ellipsis-text">{{ getChoiceText(choice) }}</span>
            <icon
              v-if="choice.answer"
              icon="check"
              class="correct-answer-check"
            />
            <span v-if="choice.answer" class="correct-answer"
              >Correct Answer</span
            >
          </li>
        </ol>
      </div>
      <div v-if="choices.length === 0" class="add-choice-btn">
        <form-button link @click="add">
          <icon icon="plus" />
          Add Choice
        </form-button>
      </div>
    </template>
  </div>
</template>

<script setup>
import { mapGetters } from 'vuex'
import { getText } from 'src/shared/components/editor/utils.js'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import RangeSlider from 'src/shared/components/RangeSlider'
import { useFormErrors } from 'vee-validate'
import { computed, ref, inject, onMounted } from 'vue'

defineOptions({
  name: 'MultipleChoice'
})
const emit = defineEmits(['choiceActions', 'change'])

const inherited = inject('inherited')
const $modal = inject('$modal')

const props = defineProps({
  component: {
    type: Object,
    required: true
  },
  variableContext: {
    type: Object,
    required: true
  },
  name: {
    type: String,
    required: true
  }
})
const isCollapsed = ref(false)
const formErrors = useFormErrors()
const isVetted = computed(() => mapGetters(['isVetted']))
const choices = computed(() => props.component.choices || [])
const totalChoices = computed(() => (props.component.choices || []).length)
const choicesTitle = computed(() =>
  totalChoices.value === 0 ? 'Choices' : `Choices (${totalChoices.value})`
)
const isSelectMany = computed(
  () => choices.value.filter(({ answer }) => answer).length > 1
)
const isSelectOne = computed(
  () => choices.value.filter(({ answer }) => answer).length === 1
)
const isPollQuestion = computed(
  () => choices.value.filter(({ answer }) => answer).length === 0
)

const hasError = computed(() => {
  return Object.keys(formErrors).some(key =>
    key.includes(`components.c${props.component._id}`)
  )
})

const doToggle = state => {
  isCollapsed.value = state
}
const remove = async index => {
  const { status } = await $modal.show(ConfirmModal, {
    prompt: `Are you sure you want to delete this choice? This action cannot be undone.`
  })
  if (status === 'ok') {
    emit(
      'choiceActions',
      choices.value.filter((_, i) => i !== index)
    )
  }
}

const duplicate = index => {
  const { id, keyId, ...choice } = choices.value[index]
  const mutableChoices = choices.value.slice()
  mutableChoices.splice(index, 0, {
    ...choice,
    keyId: Math.floor(Math.random() * 100000)
  })
  emit('choiceActions', mutableChoices)
}
const moveUp = index => {
  const mutableChoices = choices.value.slice()
  const selectedChoice = {
    ...mutableChoices[index]
  }
  mutableChoices.splice(index, 1)
  mutableChoices.splice(index - 1, 0, selectedChoice)
  emit('choiceActions', mutableChoices)
}

const moveDown = index => {
  const mutableChoices = choices.value.slice()
  const selectedChoice = {
    ...mutableChoices[index]
  }
  mutableChoices.splice(index, 1)
  mutableChoices.splice(index + 1, 0, selectedChoice)
  emit('choiceActions', mutableChoices)
}

const addAtIndex = index => {
  const mutableChoices = choices.value.slice()
  mutableChoices.splice(index + 1, 0, {
    text: '',
    answer: false,
    response: '',
    keyId: Math.floor(Math.random() * 100000)
  })
  emit('choiceActions', mutableChoices)
}

const add = () => {
  emit('choiceActions', [
    ...choices.value,
    {
      text: '',
      answer: false,
      response: '',
      keyId: Math.floor(Math.random() * 100000)
    }
  ])
}
const update = (fields, index) => {
  emit('choiceActions', [
    ...choices.value.slice(0, index),
    {
      ...choices.value[index],
      ...fields
    },
    ...choices.value.slice(index + 1)
  ])
}

const addDefaultChoices = () => {
  emit('choiceActions', [
    ...choices.value,
    {
      text: '',
      answer: false,
      response: '',
      keyId: Math.floor(Math.random() * 100000)
    },
    {
      text: '',
      answer: false,
      response: '',
      keyId: Math.floor(Math.random() * 100000)
    }
  ])
}

const getChoiceText = choice => {
  return getText(choice.text, props.variableContext)
}

onMounted(() => {
  if (choices.value.length === 0) {
    addDefaultChoices()
  }
})
</script>

<style lang="scss" scoped>
$border: 1px solid $teal;

.collapse-panel {
  :deep(.collapse-panel__title) {
    font-size: 16px;
    margin-top: 10px;
    margin-left: 5px;
  }
}

.multiple-choice-template {
  border-radius: 6px;
  margin: 10px;

  background-color: $extra-light-teal;
  :deep(.collapse-panel__header) {
    font-size: 16px;
    padding: 10px;
  }

  & .panel-default {
    background-color: $extra-light-teal;
    border: none;
    box-shadow: none;
  }
  & .add-choice-btn {
    border-top: $border;
    padding-top: 15px;
  }
  & .inner-panel {
    display: flex;
    justify-content: space-between;
    border-top: $border;
    margin-bottom: 20px;
    & .text-input {
      margin-right: 5px;
      max-width: 38%;
      min-width: 38%;
      & .section-insight {
        margin: 0;
      }
    }
    & .response {
      margin-right: 5px;
      max-width: 38%;
      min-width: 38%;
      & .section-insight {
        margin: 0;
      }
    }
    & .mark-correct {
      margin-top: 30px;
    }
    & .panel-icons {
      margin-top: 15px;
      width: 27px;
    }
  }
}
.multiple-choice__header {
  display: inline-flex;
  align-content: flex-end;
  margin-left: 20px;
  font-size: 14px;
}
.multiple-choice__row-container {
  display: flex;
  justify-content: space-between;
}
.multiple-choice__row--slider-container {
  display: flex;
  gap: 20px;
  align-content: baseline;
}
.multiple-choice__row--slider {
  width: 200px;
}
.multiple-choice-answer {
  display: block;
  padding: 5px;
  .correct-answer-check {
    color: $teal;
    margin-right: 4px;
  }
  & ol {
    background-color: $neutral-grey;
    font-weight: 600;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0;
    & .correct-answer {
      font-weight: 500;
    }
    & li {
      & .ellipsis-text {
        display: block;
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
        margin-right: 10px;
      }
    }
  }
}
</style>
