<template>
  <div
    class="drag-drop-condition__parent-wrapper"
    role="region"
    aria-label="condition"
  >
    <div class="drag-drop-condition__actions">
      <icon-button
        label="Move Up"
        icon="chevron-up"
        :color="first ? 'move-disabled' : 'move-active'"
        @click="emit('moveup')"
      />
      <icon-button
        label="Move Down"
        icon="chevron-down"
        :color="last ? 'move-disabled' : 'move-active'"
        @click="emit('movedown')"
      />
      <icon-button
        label="Duplicate Condition"
        icon="copy"
        color="copy-icon"
        @click="emit('duplicate')"
      />
      <icon-button
        label="Delete Condition"
        icon="trash"
        color="delete-icon"
        @click="emit('delete')"
      />
      <icon-button
        label="Add Condition Below"
        icon="plus"
        color="add-icon"
        @click="emit('insert')"
      />
    </div>
    <div class="drag-drop-condition__dropdowns">
      <div
        class="drag-drop-condition__dropdown-components"
        v-for="(target, index) in dropTargets"
        :key="index"
      >
        <drag-and-drop-condition-target
          :drop-target="target"
          :choices="choices"
          :condition="condition"
          :name="`${componentName}.conditions.c${condition.id}.target.t${target.id}`"
          :variable-context="variableContext"
          @change-target-condition="
            choices => updateTargetCondition(target.id, choices)
          "
          :renderType="renderType"
          :allowEmptyTargets="allowEmptyTargets"
        />
      </div>
    </div>
    <div class="drag-drop-condition__answer-cell">
      <div class="drag-drop-condition__feedback">
        <form-group class="drag-drop-condition__insight">
          <label :id="`condition-${condition.id}-feedback-label`">
            Feedback
          </label>
          <editor
            :aria-labelledby="`condition-${condition.id}-feedback-label`"
            :text="condition.response"
            :label="condition.response"
            :variable-context="variableContext"
            :rules="{
              undefinedVariables: true
            }"
            :name="`${componentName}.conditions.c${condition.id}.response`"
            data-testid="text-input"
            @input="response => emitChange({ response })"
            allow-response-variable
          />
        </form-group>
      </div>
      <div class="drag-drop-condition__correct-answer">
        <checkbox
          :modelValue="condition.isCorrect"
          data-testid="condition-isCorrect-input"
          @update:modelValue="isCorrect => emitChange({ isCorrect })"
        >
          Correct Answer
        </checkbox>
      </div>
    </div>
  </div>
</template>
<script setup>
import DragAndDropConditionTarget from './DragAndDropConditionTarget'
const emit = defineEmits([
  'edit',
  'insert',
  'delete',
  'moveUp',
  'moveDown',
  'change-condition',
  'duplicate'
])
const props = defineProps({
  condition: {
    required: true
  },
  first: {
    type: Boolean,
    default: false
  },
  last: {
    type: Boolean,
    default: false
  },
  renderType: {
    type: String,
    required: true
  },
  variableContext: {
    type: Object,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  componentName: {
    type: String,
    required: true
  },
  dropTargets: {
    type: Array,
    default: () => []
  },
  choices: {
    type: Array,
    default: () => []
  },
  allowEmptyTargets: {
    type: Boolean,
    default: false
  }
})

const updateTargetCondition = (targetId, choices) => {
  const index = props.condition.targets.findIndex(
    condition => condition.targetId === targetId
  )
  emitChange({
    targets: [
      ...props.condition.targets.slice(0, index),
      {
        ...props.condition.targets[index],
        choices
      },
      ...props.condition.targets.slice(index + 1)
    ]
  })
}

const emitChange = fields => {
  emit('edit', {
    ...props.condition,
    ...fields
  })
}
</script>

<style lang="scss">
$border: 1px solid $teal;
.drag-drop-condition__parent-wrapper {
  padding: 0 16px 16px 16px;
  border-bottom: $border;
  &:focus-within {
    background-color: darken($extra-light-teal, 10%);
  }

  & .drag-drop-condition__answer-cell {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    label {
      font-weight: 400;
    }
    & .drag-drop-condition__feedback {
      margin-right: 10px;
      margin-bottom: 10px;
      width: 80%;
      & .drag-drop-condition__insight {
        margin: 0;
      }
    }
    & .drag-drop-condition__correct-answer {
      margin-top: 20px;
    }
  }
}
.drag-drop-condition__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.drag-drop-condition__dropdowns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.drag-drop-condition__dropdown-components {
  display: flex;
  flex-direction: column;
  margin: 0 20px 16px 0;
}
</style>
