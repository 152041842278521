<template>
  <ul class="nav navbar-nav navbar-right pi-menu">
    <nav-link :to="{ name: 'classes' }">My Classes</nav-link>
    <nav-link :to="{ name: 'join_class' }"> Join Class </nav-link>
    <nav-link :to="{ name: 'student_quick_start' }">Help Center</nav-link>

    <nav-dropdown :title="fullName || 'Welcome back!'">
      <nav-dropdown-action :to="{ name: 'edit_profile' }">
        Profile
      </nav-dropdown-action>
      <nav-dropdown-action id="logout" :to="{ name: 'logout' }">
        Log out
      </nav-dropdown-action>
    </nav-dropdown>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import NavLink from 'src/shared/components/navigation/NavLink'
import NavDropdown from 'src/shared/components/navigation/NavDropdown'
import NavDropdownAction from 'src/shared/components/navigation/NavDropdownAction'

export default {
  name: 'StudentMenu',
  components: { NavLink, NavDropdown, NavDropdownAction },
  computed: {
    ...mapGetters(['fullName'])
  }
}
</script>
