<template>
  <div v-if="showCorrectAnswer" class="grouped-multiple-choice">
    <div class="multiple-answer student-response">
      <label class="answer-heading">Student Response</label>
      <component-response
        :component="component"
        :response="response.value"
        :name="componentResponseName"
        :variable-context="variableContext"
      />
    </div>
    <div class="multiple-answer answer-key">
      <label class="answer-heading">Correct Answer</label>
      <component-response
        :component="component"
        :response="correctAnswer"
        :name="componentAnswerResponseName"
        :variable-context="variableContext"
      />
    </div>
  </div>
  <div v-else>
    <component-response
      :component="component"
      :response="response.value"
      :name="componentResponseName"
      :variable-context="variableContext"
    />
  </div>
</template>

<script>
import ComponentResponse from './ComponentResponse'

export default {
  name: 'ChoiceQuestionResponse',
  components: { ComponentResponse },
  props: {
    showCorrectAnswer: {
      type: Boolean,
      required: true
    },
    component: {
      required: true
    },
    response: {
      type: Object
    },
    gradableResponse: {
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    correctAnswer() {
      return this.component.choices
        .filter(ans => {
          return ans.answer
        })
        .filter(Boolean)
        .map(a => a.id)
        .toString()
    },
    componentResponseName() {
      return `question-choice-${this.component._id}-${this.gradableResponse.id}`
    },
    componentAnswerResponseName() {
      return `answer-choice-${this.component._id}-${this.gradableResponse.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.grouped-multiple-choice {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $screen-md) {
    flex-direction: row;
  }

  & .multiple-answer {
    flex-grow: 1;
    margin-bottom: 10px;

    @media (min-width: $screen-md) {
      max-width: 48%;
    }
  }

  & .answer-heading {
    font-size: 16px;
    font-weight: 600;
  }
}
.answer-key {
  background-color: #7651a61a;
  padding: 10px;
}

.student-response {
  padding: 10px;
}
</style>
