import { loadScript } from 'src/shared/hooks/script'

declare global {
  interface Window {
    PI_ONE_TRUST_COOKIE_SCRIPT_ID?: string
    OptanonActiveGroups?: string
    OptanonWrapper?(): void
  }
}

const oneTrustId = window.PI_ONE_TRUST_COOKIE_SCRIPT_ID

export async function initConsent() {
  await new Promise<void>(resolve => {
    if (oneTrustId) {
      loadScript({
        url: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        dataset: {
          domainScript: oneTrustId
        },
        onLoaded() {
          resolve()
        }
      })
    }
  })
}

const preferences = {
  get performance(): boolean {
    return window.PI_ONE_TRUST_COOKIE_SCRIPT_ID
      ? !!window.OptanonActiveGroups?.includes(',C0002,')
      : true
  },
  get functional(): boolean {
    return window.PI_ONE_TRUST_COOKIE_SCRIPT_ID
      ? !!window.OptanonActiveGroups?.includes(',C0003,')
      : true
  }
}

type ConsentCallback = (preferences: {
  performance: boolean
  functional: boolean
}) => void

const callbacks: Set<ConsentCallback> = new Set()
let consentLoaded = !oneTrustId

export function onConsentLoaded(fn: ConsentCallback) {
  if (consentLoaded) {
    fn(preferences)
  } else {
    callbacks.add(fn)
  }
}

let prevActiveGroups = window.OptanonActiveGroups
window.OptanonWrapper = function () {
  if (prevActiveGroups !== window.OptanonActiveGroups) {
    if (prevActiveGroups) {
      window.location.reload()
      return
    }
    prevActiveGroups = window.OptanonActiveGroups
    for (const fn of callbacks) {
      fn(preferences)
    }
    callbacks.clear()
    consentLoaded = true
  }
}
