import { featureFlags } from 'src/shared/hooks/feature-flags'

export default {
  namespaced: true,
  state: featureFlags,
  getters: {
    isEnabled: state => feature => {
      if (state.enabled.includes('*')) {
        return true
      }

      return state.enabled.includes(feature.toLowerCase())
    },
    enabled: state => {
      return state.enabled
    },
    disabled: state => {
      return state.disabled
    }
  }
}
