<template>
  <editor-modal
    class="latex-menu__modal"
    v-if="isModalShown"
    name="latex"
    title="Edit Formula"
  >
    <async-form class="latex-menu__form" @submit="updateFormula">
      <div class="latex-menu__form-columns">
        <div class="latex-menu__form-column">
          <form-label for="editor-formula">Formula</form-label
          ><help-popover :to="{ name: 'latex_help' }">
            LaTeX `Equation formatting help
          </help-popover>
          <multiline-text-input
            id="editor-formula"
            class="latex-menu__formula-input"
            label="formula"
            rules="required"
            rows="3"
            v-model="formula"
            resizable-vertical
            data-focus
          />
        </div>
        <div class="latex-menu__form-column">
          <form-label id="editor-formula-preview-label">Preview</form-label>
          <div
            class="latex-menu__preview"
            aria-labelledby="editor-formula-preview-label"
            ref="katexRoot"
          />
        </div>
      </div>
      <div v-if="previewError" class="latex-menu__error">
        {{ previewError }}
      </div>
      <div class="editor-modal__actions">
        <form-button type="submit">Update</form-button>
        <form-button tertiary @click="cancel">Cancel</form-button>
      </div>
    </async-form>
  </editor-modal>
</template>

<script>
import * as Vue from 'vue'
import EditorModal from '../components/EditorModal.vue'
import katex from 'katex'
import {
  processLatex,
  expressionToBoxedLatex
} from '../../../utils/latex-methods'

export default {
  name: 'LatexMenu',
  components: { EditorModal },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Sync the focused node's href with the menu.
    const formula = Vue.ref(null)
    const onSelectionUpdate = () => {
      formula.value = processLatex(
        props.editor.getAttributes('latex').formula,
        props.editor.storage.latex.variableContext?.value.variables,
        'id'
      )
    }
    props.editor.on('selectionUpdate', onSelectionUpdate)
    Vue.onUnmounted(() => {
      props.editor.off('selectionUpdate', onSelectionUpdate)
    })

    const isModalShown = Vue.ref(false)
    const openModal = () => (isModalShown.value = true)

    const updateFormula = e => {
      props.editor
        .chain()
        .focus()
        .setLatex({
          formula: processLatex(
            formula.value,
            props.editor.storage.latex.variableContext?.value.variables,
            'name'
          )
        })
        .run()
      isModalShown.value = false
      e.done()
    }
    const cancel = e => {
      isModalShown.value = false
      props.editor.commands.focus()
    }

    const previewError = Vue.ref(null)
    const katexRoot = Vue.ref(null)
    Vue.watchEffect(() => {
      if (!formula.value) {
        previewError.value = null
      } else if (katexRoot.value) {
        try {
          katex.render(expressionToBoxedLatex(formula.value), katexRoot.value, {
            throwOnError: true
          })
          previewError.value = null
        } catch (error) {
          previewError.value = error.message.startsWith('KaTeX parse error: ')
            ? error.message.slice(18)
            : error.message
        }
      }
    })

    // eslint-disable-next-line vue/no-mutating-props
    props.editor.storage.latex.onEditLatex = openModal

    return {
      isModalShown,
      updateFormula,
      formula,
      cancel,
      katexRoot,
      previewError
    }
  }
}
</script>

<style lang="scss" scoped>
.latex-menu {
  &__form {
    margin: 0 !important;
  }

  &__form-columns {
    display: flex;
  }

  &__form-column {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  :deep() &__formula-input {
    width: 300px;
  }

  &__preview {
    min-width: 100px;
  }

  &__error {
    margin: 16px 0 0 0;
    color: $color-error;
  }
  help-popover {
    border: none;
  }
}
</style>
