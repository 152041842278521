<template>
  <div class="breadcrumb-item">
    <router-link v-if="to" :to="to" class="router-link-style"
      ><slot
    /></router-link>
    <span v-else><slot /></span>
    <icon icon="chevron-right" class="breadcrumb--chevron" />
  </div>
</template>
<script>
export default {
  name: 'BreadcrumbItem',
  props: {
    to: {
      type: null,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb--chevron {
  margin-left: 0.5rem;
}
.breadcrumb-item {
  display: inline-block;
  margin-right: 0.5rem;
}
.router-link-style {
  &:focus {
    text-decoration: underline;
  }
}
</style>
