<template>
  <div class="click-copy-field">
    <form-button
      v-if="canCopy"
      class="click-copy-field__button"
      :aria-label="`Copy ${ariaLabel}`"
      @click="copy"
    >
      Copy
    </form-button>
    <multiline-text-input
      ref="text"
      class="click-copy-field__text"
      :class="{
        'click-copy-field__text--can-copy': canCopy,
        'click-copy-field__text--multiline': rows > 1
      }"
      readonly="true"
      :rows="rows"
      :modelValue="value"
      :aria-label="`Select ${ariaLabel}`"
      wrap="off"
      @click="select"
    />
  </div>
</template>

<script>
export default {
  name: 'ClickToCopyField',
  props: {
    value: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: null
    },
    ariaLabel: {
      type: String,
      default: 'Text'
    },
    rows: {
      type: String,
      default: '1'
    }
  },
  computed: {
    canCopy() {
      return document.queryCommandSupported('copy')
    }
  },
  methods: {
    copy() {
      this.select()
      if (
        document.queryCommandSupported('copy') &&
        document.execCommand('copy')
      ) {
        this.$success(
          this.message || `Copied '${this.value}' to the clipboard.`
        )
      }
    },
    select() {
      window.getSelection().empty()
      this.$refs.text.select()
    }
  }
}
</script>

<style lang="scss">
.click-copy-field {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.click-copy-field__button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 36px;
}

.click-copy-field__text {
  flex-grow: 1;

  textarea {
    height: 38px;
    overflow: hidden;
  }

  &--multiline {
    textarea {
      height: auto;
    }
  }
}

.click-copy-field__text--can-copy {
  textarea,
  textarea:hover,
  textarea:focus {
    border-top-left-radius: 0;
  }
}

.click-copy-field__text--can-copy:not(.click-copy-field__text--multiline) {
  textarea,
  textarea:hover,
  textarea:focus {
    border-bottom-left-radius: 0;
  }
}
</style>
