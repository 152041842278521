<template>
  <form-button v-bind="{ secondary, tertiary, link }" @click="changeTab">
    <slot />
  </form-button>
</template>

<script>
export default {
  name: 'TabButton',
  inject: ['tabsInfo'],
  props: {
    tab: {
      type: String,
      required: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeTab() {
      this.tabsInfo.changeTab(this.tab)
    }
  }
}
</script>
