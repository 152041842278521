<template>
  <fieldset
    :aria-describedby="`${helpId} ${ariaDescribedby}`"
    :class="{
      'has-error': errorMessage,
      horizontal: layout === 'horizontal'
    }"
  >
    <slot />
    <span v-if="errorMessage || helpText" :id="helpId" class="help-block">
      {{ errorMessage || helpText }}
    </span>
  </fieldset>
</template>

<script>
import { toRef } from 'vue'
import * as Vue from 'vue'
import { useField } from 'vee-validate'

let counter = 0

export default {
  name: 'ChoiceInput',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: null,
      required: true
    },
    label: {
      type: String,
      default: 'field'
    },
    name: {
      type: String,
      default: () => `choice-input-${counter++}`
    },
    rules: {
      type: [String, Object],
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: ''
    },
    helpText: {
      type: String,
      default: null
    },
    layout: {
      type: String,
      default: 'vertical'
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: props.modelValue,
      validateOnValueUpdate: false,
      syncVModel: false
    })

    return {
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField
    }
  },
  provide() {
    return {
      choiceInput: Vue.computed(() => ({
        name: this.name,
        value: this.modelValue,
        onChange: value => {
          this.handleChange(value)
          this.$emit('update:modelValue', value)
        },
        onBlur: this.handleBlur
      }))
    }
  },
  computed: {
    helpId() {
      return `${this.name}-help`
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue !== this.inputValue) {
        this.resetField({ value: this.modelValue })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horizontal {
  display: flex;
  flex-direction: row;

  :deep(label) {
    padding-right: 1rem;
  }
}
</style>
