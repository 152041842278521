<template>
  <view-container>
    <view-header>
      <view-title v-if="instructor"
        ><template v-if="instructor.firstName">
          {{ instructor.firstName }} {{ instructor.lastName }}
        </template>
        <template v-else> Instructor </template>
        <template v-if="instructor.email">
          -
          <a :href="`mailto:${instructor.email}`">{{ instructor.email }}</a>
        </template>
      </view-title>

      <view-title v-else>Instructor</view-title>

      <view-header-actions v-if="instructor">
        <form-button
          v-if="!isDisabled"
          class="disable-button"
          destructive
          @click="disable(instructor.id)"
          >Disable Account</form-button
        >
        <form-button
          v-if="isDisabled && allowReenable"
          class="disable-button"
          @click="reEnable(instructor.id)"
          >Re-Enable Account</form-button
        >
        <form-button
          class="reset-password-button"
          @click="resetPassword(instructor.email)"
          >Reset Password</form-button
        >
        <form-button
          class="impersonate-button"
          @click="impersonate(instructor.id)"
          >Impersonate</form-button
        >
      </view-header-actions>
    </view-header>
    <loading-container :loading="isLoading">
      <h4>
        Member since:
        {{ $format.date(instructor.createdAt, 'MM/dd/yyyy') }}
      </h4>
      <div class="form-group row">
        <div class="col-sm-6">
          Last login:
          {{
            instructor.lastLogin
              ? $format.date(instructor.lastLogin, 'MM/dd/yyyy  hh:mm a')
              : ''
          }}
        </div>
        <div v-if="instructor.ssoProviders.length > 0">
          <h4 class="fcol-md-6 col-sm-10">
            SSO Providers:
            <ul class="list-unstyled">
              <li v-for="sso in instructor.ssoProviders" :key="sso">
                <span>{{ sso }}</span>
                <icon-button
                  style="padding-left: 10px"
                  color="delete-icon"
                  icon="trash"
                  @click="removeSsoOption(sso)"
                  label="Remove"
                />
              </li>
            </ul>
          </h4>
        </div>
        <div v-if="instructor.lti" class="col-sm-6">
          <h4 class="fcol-md-6 col-sm-10">LTI: {{ instructor.lti.name }}</h4>
          <click-to-copy-field
            aria-label="Platform ID"
            :value="instructor.lti.id"
            message="The platform ID has been copied."
            name="platform-id"
          />
        </div>
      </div>
      <div class="row">
        <!-- Status -->
        <div class="col-sm-6">
          <h2>Account Status</h2>
          <form-label id="account-creation-label" v-if="!isDisabled">
            <strong>Creation Status</strong>
            <div>
              {{ setAccountCreationStatus(instructor) }}
            </div>
            <div v-if="setAccountCreationStatus(instructor) === 'Pending'">
              <form-button
                link
                @click="() => copyInvitationUrl(instructor.email)"
              >
                Copy Invitation URL
              </form-button>
            </div>
          </form-label>
          <div class="row form col-sm-12">
            <!-- Status Field -->
            <form-group>
              <form-label id="status-label"><strong>Status</strong></form-label>
              <div>
                <strong v-if="isDisabled">
                  Disabled ({{ disableReason }})</strong
                >
                <template v-else>
                  {{ instructor.status }}
                </template>
              </div>
            </form-group>

            <form-group v-if="instructor.siteLicense">
              <form-label id="site-license-label"
                ><strong>License</strong></form-label
              >
              <static-value aria-labelledby="site-license-label">
                <router-link
                  :to="{
                    name: 'admin_manage_license',
                    params: {
                      id: instructor.siteLicense.id
                    }
                  }"
                >
                  {{ instructor.siteLicense.name }}</router-link
                >
                ({{ instructor.siteLicense.role }})
              </static-value>
            </form-group>
            <form-group v-if="instructor.pendingInvites?.length > 0">
              <form-label id="invitations-label" class="invitations__label"
                ><strong>Invitations</strong></form-label
              >
              <ul class="invitations__list">
                <li
                  class="invitations__item"
                  v-for="invite in instructor.pendingInvites"
                  :key="invite.id"
                >
                  <router-link
                    :to="{
                      name: 'admin_manage_license',
                      params: {
                        id: invite.id
                      }
                    }"
                  >
                    {{ invite.name }}</router-link
                  >
                  ({{ invite.role }})
                </li>
              </ul>
            </form-group>
          </div>
        </div>

        <!-- Seats Summary -->
        <div class="col-sm-6">
          <h2>Seats Summary</h2>
          <div class="row form col-sm-12">
            <form-group>
              <form-label id="seats-used-label">Seats Used</form-label>
              <static-value aria-labelledby="seats-used-label">
                {{ instructor.seatsUsed }}
              </static-value>
            </form-group>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <h2 class="col-md-6 col-sm-10">Classes</h2>
      </div>
      <list
        :items="classes"
        :row-class="({ isArchived }) => (isArchived ? 'danger' : '')"
      >
        <list-column title="Name">
          <template #default="{ name, isArchived }">
            {{ name + (isArchived ? ' (archived)' : '') }}
          </template>
        </list-column>
        <list-column title="Class Key" property="classKey" />
        <list-column title="Payment" property="paymentSource" />
        <list-column title="Level" property="level" />
        <list-column v-slot="{ studentCount }" title="# Students">
          {{ studentCount }}
        </list-column>
        <list-column v-slot="{ id }" title="Actions">
          <router-link :to="{ name: 'admin_class', params: { id } }">
            View
          </router-link>
        </list-column>
      </list>

      <div class="form-group row">
        <h2 class="col-md-6 col-sm-10">Grading Classes</h2>
      </div>
      <list
        :items="gradingClasses"
        :row-class="({ isArchived }) => (isArchived ? 'danger' : '')"
      >
        <list-column title="Name">
          <template #default="{ name, isArchived }">
            {{ name + (isArchived ? ' (archived)' : '') }}
          </template>
        </list-column>
        <list-column title="Class Key" property="classKey" />
        <list-column title="Payment" property="paymentSource" />
        <list-column title="Level" property="level" />
        <list-column v-slot="{ studentCount }" title="# Students">
          {{ studentCount }}
        </list-column>
        <list-column v-slot="{ id }" title="Actions">
          <router-link :to="{ name: 'admin_class', params: { id } }">
            View
          </router-link>
        </list-column>
      </list>
    </loading-container>
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DisableReasonModal from 'src/shared/components/modals/DisableReasonModal'
import ClickToCopyField from 'src/shared/components/ClickToCopyField'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import client from 'src/shared/api-client.ts'

export default {
  name: 'AdminInstructorView',
  components: {
    ClickToCopyField
  },
  props: ['id'],
  inject: ['$modal'],
  data() {
    return {
      isLoading: true,
      instructor: null,
      classes: [],
      gradingClasses: []
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isDisabled() {
      return !this.isLoading && this.instructor
        ? typeof this.instructor.disabledReason !== 'undefined'
        : false
    },
    allowReenable() {
      return !this.loading && this.instructor
        ? this.instructor.disabledReason !== 'invalid'
        : false
    },
    disableReason() {
      if (!this.instructor) return ''
      switch (this.instructor.disabledReason) {
        case 'invalid':
          return 'Invalid'
        case 'abuse':
          return 'Abuse'
        case 'vetting-rejected':
          return 'Vetting Rejected'
        default:
          return 'Unknown'
      }
    },
    platformId() {
      return this.instructor.lti.id
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    async load() {
      await Promise.all([
        this.loadInstructor(),
        this.loadClasses(),
        this.loadGradingClasses()
      ])
      this.isLoading = false
    },
    async resetPassword(email) {
      await client.auth.requestPasswordReset(email)
      this.$success(`Password reset sent to ${email}`)
    },
    async copyInvitationUrl(email) {
      const { protocol, host } = window.location
      const response = await client.invitations.search({
        q: email,
        limit: 1
      })

      const invitation = response.items.filter(item => item.email === email)[0]

      if (invitation) {
        const token = invitation.token
        const signupUrl = `${protocol}//${host}/signup/${token}`
        window.navigator.clipboard.writeText(signupUrl)
        this.$success('Copied signup URL to clipboard')
      } else {
        this.$error('No invitation found for this email')
      }
    },
    setAccountCreationStatus(instructor) {
      if (instructor.isPendingSignup) {
        return 'Pending'
      }
      if (
        instructor.institutionRoles.length === 0 ||
        instructor.primarySubjects.length === 0
      ) {
        return 'Profile Incomplete'
      } else {
        return 'Complete'
      }
    },
    async loadInstructor() {
      const instructor = await client.users.getUser(this.id)
      this.instructor = instructor
    },
    async disable(id) {
      const { status: modalStatus } = await this.$modal.show(DisableReasonModal)
      if (modalStatus !== 'cancel') {
        await client.users.setUsersDisableStatus({
          users: [{ id, reason: modalStatus }]
        })
        this.$success('User has been disabled')
        this.instructor.disabledReason = modalStatus
      }
    },
    async reEnable(id) {
      await client.users.setUsersDisableStatus({ users: [{ id }] })
      this.$success('User has been re-enabled')
      this.instructor.disabledReason = undefined
    },
    async removeSsoOption(ssoOpt) {
      const { status: modalStatus } = await this.$modal.show(ConfirmModal, {
        text: `This will remove the saved ${ssoOpt} login method for the user to log in and will send a password reset email to the user.`,
        prompt: `Do you wish to remove ${ssoOpt}`
      })
      if (modalStatus === 'ok') {
        await client.users.removeSSO({ id: this.id, ssoOption: ssoOpt })
        this.$success(`${ssoOpt} as been removed`)
        await this.loadInstructor()
      }
    },
    async loadClasses() {
      this.classes = await client.classes.getClassesForUser({
        id: this.id,
        roles: ['instructor'],
        includeArchived: true
      })
    },
    async loadGradingClasses() {
      this.gradingClasses = await client.classes.getClassesForUser({
        id: this.id,
        roles: ['grader'],
        includeArchived: true
      })
    },
    async impersonate(userId) {
      window.sessionStorage.setItem('previous_url', window.location.href)
      await client.users.startImpersonating(userId)
      await this.refreshAuth()
      this.$router.push({ name: 'home' })
    }
  },
  watch: {
    id: {
      handler() {
        return this.load()
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.instructor-view__status-selector {
  width: 120px;
}
.reset-password-button {
  margin-right: 10px;
}
.disable-button {
  margin-right: 16px;
}
.invitations__label {
  margin-bottom: 0px;
}
.invitations__list {
  list-style: none;
  padding-left: 0px;
}
.invitations__item {
  margin: 0px;
}
.click-copy-field {
  width: 50%;
}
</style>
