<template>
  <nav id="main-navigation" class="navbar navbar-static-top">
    <div v-if="isLtiMode || isOAuthMode" class="container">
      <img
        class="pi-logo"
        src="~assets/img/RGB_pivot-logo.png"
        alt="Pivot Interactives Logo"
      />
    </div>
    <div v-else class="container">
      <div class="navbar-header">
        <button
          class="block lg:hidden relative float-right mb-3.5 mt-3.5 mr-6 pt-3.5 pb-3.5 pl-4 pr-4 text-pivot-purple-800"
          type="button"
          @click="toggleMenu"
          aria-expanded="false"
        >
          <span class="sr-only">Toggle navigation</span>
          <icon icon="bars" size="lg" />
        </button>
        <router-link
          class="pi-brand"
          :to="{ name: 'home' }"
          active-class="router-link-never-active"
        >
          <img
            class="pi-logo"
            src="~assets/img/RGB_pivot-logo.png"
            alt="Pivot Interactives Logo"
          />
        </router-link>
      </div>
      <TransitionRoot
        :show="menuOpen"
        id="pi-navbar-header"
        class="navbar-collapse transition-all ease duration-250"
        enterFrom="transform scale-95 opacity-0 max-h-0"
        enterTo="transform scale-100 opacity-100 max-h-96"
        leave="duration-150 ease-in"
        leaveFrom="transform scale-100 opacity-100 max-h-96"
        leaveTo="transform scale-95 opacity-0 max-h-0"
      >
        <menu-teacher v-if="isTeacher" />
        <menu-student v-else-if="isStudent" />
        <menu-admin v-else-if="isAdmin || isSalesAdmin" />
        <menu-content-developer v-else-if="isContentDeveloper" />
        <menu-guest v-else />
      </TransitionRoot>
    </div>
  </nav>
</template>

<script setup>
import { ref, provide, watch, computed } from 'vue'
import MenuGuest from 'src/shared/components/navigation/MenuGuest'
import MenuTeacher from 'src/shared/components/navigation/MenuTeacher'
import MenuStudent from 'src/shared/components/navigation/MenuStudent'
import MenuAdmin from 'src/shared/components/navigation/MenuAdmin'
import MenuContentDeveloper from 'src/shared/components/navigation/MenuContentDeveloper'
import { mapGetters } from 'src/shared/hooks/map-getters'
import { TransitionRoot } from '@headlessui/vue'
import useElementSize from 'src/shared/hooks/element-size'

const {
  isAdmin,
  isTeacher,
  isStudent,
  isContentDeveloper,
  isLtiMode,
  isSalesAdmin,
  isOAuthMode
} = mapGetters()

const menuOpen = ref(true)
const windowSize = useElementSize(document.body)
const isDesktop = computed(() => windowSize.value.width >= 992)

provide('closeMenu', () => {
  if (isDesktop.value) return
  menuOpen.value = false
})

watch(windowSize, () => {
  if (windowSize.value.width < 992 && menuOpen.value) menuOpen.value = false
  if (windowSize.value.width >= 992 && !menuOpen.value) menuOpen.value = true
})

const toggleMenu = () => {
  if (isDesktop.value) return
  menuOpen.value = !menuOpen.value
}
</script>
<style lang="scss">
.navbar {
  background-color: #ffffff;
  padding: 16px 0 28px 0;
  border: none;
  margin-bottom: 0;
}

.pi-brand {
  float: left;
}

.pi-logo {
  height: 52px;
}

// Collapse navbar header on portrait ipad or smaller
@media (max-width: $grid-float-breakpoint) {
  .pi-brand {
    margin-left: 15px;
  }
}
</style>
