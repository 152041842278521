<template>
  <modal class="image-modal" @submit="onSubmit" @close="onClose">
    <modal-header>Image Settings</modal-header>
    <modal-body>
      <div class="image-modal__columns">
        <div class="image-modal__column">
          <form-label>Preview</form-label>
          <div class="image-modal__preview">
            <img
              v-if="previewUrl"
              class="image-modal__preview-image"
              :src="previewUrl"
              :alt="localValue.alt"
            />
            <div v-else class="image-modal__preview-placeholder" />
          </div>
        </div>
        <div class="image-modal__column">
          <form-group>
            <form-label for="image-url">File</form-label>
            <div class="image-modal__url-group">
              <text-input
                class="image-modal__url-input"
                id="image-url"
                :modelValue="(localValue.url ?? '').split('/').slice(-1)[0]"
                readonly
                :title="localValue.url"
              />
              <file-picker-button
                class="image-modal__upload-button"
                secondary
                @change="uploadFile"
              >
                Upload
              </file-picker-button>
            </div>
          </form-group>
          <form-group>
            <form-label for="image-alt">Alt Text</form-label>
            <multiline-text-input id="image-alt" v-model="localValue.alt" />
          </form-group>
        </div>
      </div>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Save</modal-button-submit>
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import { inject, reactive, computed } from 'vue'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'

import client from 'src/shared/api-client'
const emit = defineEmits(['update:variable', 'close'])
const props = defineProps({
  value: {
    type: Object,
    required: true
  }
})

const $modal = inject('$_modal')
const onClose = () => {
  $modal.close()
}

const localValue = reactive({ ...props.value })
const previewUrl = computed(() =>
  localValue.url ? `//${PI_API_HOST}${localValue.url}` : ''
)

function onSubmit(e) {
  e.done(localValue)
}

async function uploadFile(e) {
  const file = e.target.files[0]
  const fileNameParts = file.name.split('.')
  const fileName = `${fileNameParts[0]}-${Math.floor(Date.now() / 1000)}`
  const ext = fileNameParts.slice(1).join('.')
  const name = `${fileName}.${ext}`
  localValue.url = await client.assets.upload({
    file: file,
    name: name
  })
}
</script>

<style scoped lang="scss">
.image-modal {
  width: 520px;
}

.image-modal__columns {
  display: flex;
}

.image-modal__column {
  flex: 1 1 0;

  &:not(:first-child) {
    margin-left: 16px;
  }
}

.image-modal__url-group {
  display: flex;
  align-items: center;
}

.image-modal__url-input {
  flex: 1 1;
}

:deep(.image-modal__upload-button) {
  flex: 0 0;
  margin-left: 8px;
}

.image-modal__preview {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  overflow: hidden;
}

.image-modal__preview-image {
  max-width: 100%;
  max-height: 300px;
}

.image-modal__preview-placeholder {
  padding-bottom: 100%;
}
</style>
