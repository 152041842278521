<template>
  <modal>
    <modal-header>This type of video upload is not supported.</modal-header>
    <modal-body
      >Please see our help article on
      <hyper-link :to="{ name: 'unsupported_video_help' }"
        >supported video formats
      </hyper-link>
      for details.
    </modal-body>

    <modal-actions><modal-button-ok /></modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonOk
} from 'src/shared/components/modals/components'

export default {
  name: 'UnsupportedCodecModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonOk
  }
}
</script>

<style scoped>
p.prompt {
  font-weight: bold;
  margin-bottom: 0;
}
.learn-more-button {
  margin-top: 5px;
}
.instruction {
  margin: 5px 0px;
}
</style>
