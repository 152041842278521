<template>
  <pagination-container
    v-slot="slot"
    :limit="limit"
    :page="page"
    :items="items"
    :count="count"
    @loadpage="$emit('loadpage', $event)"
  >
    <list :items="slot.items" :row-class="rowClass">
      <slot />
      <template v-if="hasDetailsTemplate" #details="props">
        <slot name="details" v-bind="props" />
      </template>
    </list>
  </pagination-container>
</template>

<script>
export default {
  name: 'PaginatedList',
  emits: ['loadpage'],
  props: {
    limit: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    count: {
      type: Number,
      default(props) {
        return props.items.length
      }
    },
    rowClass: {
      type: null,
      default: null
    }
  },
  computed: {
    hasDetailsTemplate() {
      return !!this.$slots.details
    }
  }
}
</script>

<style></style>
