<template>
  <div v-if="section.completeFirst && !disabled">
    <span v-if="section.isLocked" class="pull-right"> Section is Locked </span>
    <div class="section-lock" v-else>
      <span
        class="section-lock__info"
        v-if="incorrectQuestionsWithDependents.length && !isPreview"
        data-testid="lock-text"
      >
        <strong>
          You cannot lock this section until the following questions are
          correct:
        </strong>
        <ul class="section-lock__dependencies">
          <li
            :key="index"
            v-for="(question, index) in incorrectQuestionsWithDependents"
            class="section-lock__dependency"
          >
            #{{ question.questionNumber }}
          </li>
        </ul>
      </span>
      <form-button
        :disabled="incorrectQuestionsWithDependents.length && !isPreview"
        class="section-lock__button"
        @click="lock"
      >
        Lock Answer and Continue
      </form-button>
    </div>
  </div>
</template>
<script>
import LockSectionConfirmModal from 'src/shared/components/modals/LockSectionConfirmModal.vue'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'

export default {
  name: 'ActivitySectionLockOverride',
  inject: ['inherited', '$modal'],
  emits: ['lock'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    activityIndex: {
      type: Number,
      required: true
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    section() {
      return this.inherited.section
    },
    allowIncorrectResponses() {
      return (
        this.inherited.response.assignment.studentFeedbackTiming ===
          AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit ||
        this.inherited.response.assignment.studentFeedbackTiming ===
          AssignmentStudentFeedbackTimingValue.Never
      )
    },
    incorrectAutogradedQuestions() {
      return this.section.components.filter(
        component =>
          component.autograde &&
          !this.inherited.response.responses.some(response => {
            return (
              response.component === component._id &&
              ((response.correct ?? response.isCorrect) ||
                (this.allowIncorrectResponses && response.value))
            )
          })
      )
    },
    incorrectQuestionsWithDependents() {
      const otherSectionDependencies = Array.from(
        new Set(
          this.inherited.activity.sections
            .filter((section, index) => index !== this.activityIndex)
            .flatMap(section =>
              section.components.flatMap(component => component.dependencies)
            )
        )
      )

      return this.incorrectAutogradedQuestions.filter(component =>
        otherSectionDependencies.includes(component._id)
      )
    }
  },
  methods: {
    async lock() {
      if (
        (this.incorrectAutogradedQuestions.length === 0 &&
          this.incorrectQuestionsWithDependents.length === 0) ||
        this.isPreview
      ) {
        this.$emit('lock', this.section.index)
      } else if (this.incorrectAutogradedQuestions.length > 0) {
        const { status } = await this.$modal.show(LockSectionConfirmModal, {
          incorrectQuestions: this.incorrectAutogradedQuestions,
          delayedFeedback: this.allowIncorrectResponses
        })
        if (status === 'ok') {
          this.$emit('lock', this.section.index)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.section-lock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-lock__button {
  margin-left: auto;
  height: 42px;
  align-self: flex-end;
}
.section-lock__info {
  margin-bottom: -15px;
}

.section-lock__dependencies {
  padding-left: 10px;
}
.section-lock__dependencies > li {
  list-style-type: none;
  display: inline;
}
.section-lock__dependency:not(:last-child):after {
  content: ', ';
}
</style>
