<template>
  <async-form v-if="klass" class="form-two-column" @submit="onSubmit">
    <template v-if="klass && !order">
      <form-group>
        <div>
          You can join this class by paying with a credit card or by redeeming a
          bookstore code. Enter the information for one of the payment methods
          below.
        </div>
      </form-group>
      <form-group>
        <form-label id="cost">Cost</form-label>
        <static-value aria-labelledby="cost"
          >${{ klass.level == 'high-school' ? '5.50' : '10' }} plus applicable
          sales tax</static-value
        >
      </form-group>

      <form-group>
        <form-label id="card-label">Card</form-label>
        <credit-card-input
          ref="card"
          aria-labelledby="card-label"
          label="card"
        />
      </form-group>
      <form-group>
        <form-label for="country">Country</form-label>
        <select-field
          id="country"
          v-model="country"
          :rules="{ required: !bookstoreCode }"
        >
          <option
            v-for="iso3166Country in iso3166Countries"
            id="country"
            :key="iso3166Country['alpha-2']"
            :value="iso3166Country['alpha-2']"
          >
            {{ iso3166Country.name }}
          </option>
        </select-field>
      </form-group>
      <form-group v-if="requirePostalCode">
        <form-label for="postalCode">Postal Code</form-label>
        <div>
          <text-input
            id="postalCode"
            v-model="postalCode"
            label="postal code"
            :rules="
              bookstoreCode
                ? undefined
                : { required: true, postalCode: { country } }
            "
          />
        </div>
      </form-group>
      <form-group>
        <div class="form-offset text-right">or</div>
      </form-group>
      <form-group>
        <form-label for="bookstore-code">Bookstore Code</form-label>
        <div>
          <text-input
            id="bookstore-code"
            v-model="bookstoreCode"
            label="code"
          />
        </div>
      </form-group>
      <form-group class="over-13">
        <checkbox v-model="affirmedOver13">
          I affirm that I am at least 13 years of age
        </checkbox>
      </form-group>
      <div v-if="showAgeRestrictionWarningText" class="over-13-warning">
        You must affirm that you are over the age of 13 in order to complete
        this purchase by checking this checkbox
      </div>
      <form-group>
        <checkbox v-model="termsAccepted">
          I have read and understand Discovery Education’s
          <hyper-link :to="{ name: 'terms' }">Standard Terms</hyper-link>
        </checkbox>
      </form-group>
      <form-group>
        <checkbox v-model="privacyAccepted">
          I have reviewed
          <hyper-link :to="{ name: 'privacy' }">
            Discovery Education’s Privacy Policy</hyper-link
          >
          and acknowledge my information will be processed in accordance with
          this Privacy Policy.</checkbox
        >
      </form-group>

      <form-group variant="right-aligned">
        <submit-button
          action="order"
          :disabled="!termsAccepted || !privacyAccepted"
        >
          <template #default>Review Purchase</template>
          <template #submitting>Submitting</template>
          <template #submitted>Success</template>
        </submit-button>
      </form-group>
    </template>
    <template v-if="klass && order">
      <form-group>
        <div>
          Review the details of your payment below. If you need to make changes,
          you can go back to start over.
        </div>
      </form-group>
      <form-group>
        <form-label id="cost-label">Seat Cost</form-label>
        <static-value aria-labelledby="cost-label">
          ${{ order.subtotal.toFixed(2) }}
        </static-value>
      </form-group>
      <form-group>
        <form-label id="tax-label">Tax</form-label>
        <static-value aria-labelledby="tax-label">
          ${{ order.taxes.toFixed(2) }}
        </static-value>
      </form-group>
      <form-group>
        <form-label id="total-label">Total</form-label>
        <static-value aria-labelledby="total-label">
          ${{ order.total.toFixed(2) }}
        </static-value>
      </form-group>
      <form-group>
        <form-label id="payment-label">Payment</form-label>
        <static-value
          v-if="order.bookstoreCode"
          aria-labelledby="payment-label"
        >
          Bookstore Code: <strong>{{ order.bookstoreCode }}</strong>
        </static-value>
        <static-value v-else-if="paymentMethod" aria-labelledby="payment-label">
          {{ paymentMethod.card.brand.toUpperCase() }}
          {{ paymentMethod.card.last4 }}
        </static-value>
      </form-group>
      <form-group variant="right-aligned">
        <submit-button>
          <template #default>Pay & Join Class</template>
          <template #submitting>Joining</template>
          <template #submitted>Success</template>
        </submit-button>
        <submit-button action="back" secondary>Back</submit-button>
      </form-group>
    </template>
  </async-form>
</template>

<script>
import CreditCardInput from 'src/shared/components/CreditCardInput'
import client from 'src/shared/api-client'
import iso3166Countries from 'src/shared/utils/iso-3166-countries.json'
import { mapState } from 'vuex'

export default {
  name: 'ClassPaymentForm',
  components: { CreditCardInput },
  props: {
    classKey: {
      type: String,
      default: undefined
    }
  },
  emits: ['invalidorder', 'submit'],
  data() {
    return {
      order: null,
      klass: null,
      bookstoreCode: '',
      country: 'US',
      postalCode: '',
      paymentMethod: null,
      affirmedOver13: false,
      showAgeRestrictionWarningText: false,
      iso3166Countries,
      termsAccepted: false,
      privacyAccepted: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    requirePostalCode() {
      return this.country === 'US' || this.country === 'CA'
    }
  },
  methods: {
    async validate() {
      const { classKey } = this
      try {
        const body = await client.classes.validate({
          classKey: classKey,
          validateStudentPay: true
        })
        this.klass = body
        return
      } catch (error) {
        this.$emit('invalidorder')
        throw error
      }
    },
    async onSubmit({ action, done }) {
      if (action === 'back') {
        this.order = null
        return done()
      }

      if (action === 'order') {
        const { classKey, country, postalCode, bookstoreCode, affirmedOver13 } =
          this

        if (!affirmedOver13) {
          this.showAgeRestrictionWarningText = true
          return done(false)
        }

        if (!bookstoreCode) {
          const paymentMethod = await this.$refs.card.createPaymentMethod({
            billing_details: {
              name: `${this.user.firstName} ${this.user.lastName}`,
              email: this.user.email,
              address: {
                country,
                postal_code: postalCode
              }
            }
          })
          if (!paymentMethod) {
            return done(false)
          }

          this.paymentMethod = paymentMethod
        }

        try {
          const body = await client.classes.order({
            classKey,
            country,
            postalCode,
            paymentMethod: this.paymentMethod?.id,
            bookstoreCode,
            affirmedOver13
          })
          this.order = body.order
          this.klass = body.class
          done()
        } catch (error) {
          done(false)
          this.$emit('invalidorder')
          throw error
        }
      } else {
        try {
          const { classKey, order } = this
          await client.classes.pay({
            classKey,
            invoiceId: order.invoiceId
          })
          this.$success(`You joined the class: ${this.klass.name}.`)
          this.$emit('submit')
          done()
        } catch (error) {
          done(false)
          throw error
        }
      }
    }
  },
  created() {
    return this.validate()
  }
}
</script>

<style lang="scss" scoped>
.form-label,
.form-offset {
  width: 115px;
}

.over-13 {
  margin-bottom: 0;
}

.over-13-warning {
  color: $color-warning;
  margin-bottom: 0.5em;
}
</style>
