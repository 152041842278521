<template>
  <loading-container :loading="isLoading" class="license-list">
    <paginated-list
      v-bind="pagination"
      data-testid="license-list"
      @loadpage="loadPage"
    >
      <sortable-list-column
        title="Name"
        property="name"
        :sort="sort['name']"
        @change="sort => changeSort('name', sort)"
      />
      <sortable-list-column
        title="Domain"
        property="emailDomain"
        :sort="sort['emailDomain']"
        @change="sort => changeSort('emailDomain', sort)"
      />
      <sortable-list-column
        v-slot="{ maxSeats }"
        title="Seat Limit"
        :sort="sort['maxSeats']"
        @change="sort => changeSort('maxSeats', sort)"
      >
        {{ maxSeats }}
      </sortable-list-column>
      <sortable-list-column
        v-slot="{ startDate }"
        title="Start Date"
        :sort="sort['startDate']"
        @change="sort => changeSort('startDate', sort)"
      >
        {{ $format.date(startDate, 'MM/dd/yyyy') }}
      </sortable-list-column>
      <sortable-list-column
        v-slot="{ endDate }"
        title="End Date"
        :sort="sort['endDate']"
        @change="sort => changeSort('endDate', sort)"
      >
        <template v-if="endDate">
          {{ $format.date(endDate, 'MM/dd/yyyy') }}
        </template>
        <template v-else>-</template>
      </sortable-list-column>
      <list-column title="Type" property="type" />
      <list-column title="Level" property="level" />
      <list-column title="Status" property="status" />
      <list-column v-slot="{ id, status }" title="Actions">
        <router-link
          v-if="status !== 'archived'"
          :to="{ name: 'admin_manage_license', params: { id } }"
          >Manage</router-link
        >
      </list-column>
    </paginated-list>
  </loading-container>
</template>

<script>
export default {
  name: 'LicenseList',
  inject: ['browseLicenses', '$modal'],
  computed: {
    sort() {
      return this.browseLicenses.query.sort
        ? {
            [this.browseLicenses.query.sort]: this.browseLicenses.query.dir
          }
        : {}
    },
    empty() {
      return this.licenses.length === 0
    },
    licenses() {
      return this.browseLicenses.licenses
    },
    pagination() {
      return {
        page: this.browseLicenses.query.page,
        count: this.browseLicenses.licenseCount,
        limit: this.browseLicenses.licenseLimit,
        items: this.browseLicenses.licenses
      }
    },
    isLoading() {
      return this.browseLicenses.isLoadingLicenses
    }
  },
  methods: {
    loadPage({ page }) {
      this.browseLicenses.updateQuery({ page: page.toString() })
    },
    changeSort(property, dir) {
      if (dir) {
        this.browseLicenses.updateQuery({
          sort: property,
          dir
        })
      } else {
        this.browseLicenses.updateQuery({
          sort: undefined,
          dir: undefined
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.license-list {
  grid-area: results;

  &__list {
    list-style: none;
    display: grid;
    gap: 32px;
    grid-auto-flow: row;
    padding: 0;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }

  &__empty {
    margin-top: 1em;
    text-align: center;

    & > button {
      margin: 0 8px;
    }
  }
}
</style>
