<template>
  <div class="browse-summary">
    <template v-if="!isLoading">
      Showing {{ userPageSize }} of {{ userCount }} results
      <template v-if="search">for "{{ search }}"</template>
      <template v-if="filters.length > 0">
        filtered by {{ filters.join(', ') }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BrowseSummary',
  inject: ['browseUsers'],
  computed: {
    userPageSize() {
      return this.browseUsers.users.length
    },
    userCount() {
      return this.browseUsers.userCount
    },
    search() {
      return this.browseUsers.query.q
    },
    isLoading() {
      return this.browseUsers.isLoadingUsers
    },
    filters() {
      const filters = [
        ...(this.browseUsers.query.role || []),
        ...(this.browseUsers.query.licenseType || []),
        ...(this.browseUsers.query.disabledReason || [])
      ]

      return filters
    }
  }
}
</script>

<style lang="scss" scoped>
.browse-summary {
  margin: 8px 0 0 0;
  flex-shrink: 1;
  grid-area: summary;
}
</style>
