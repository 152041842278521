<template>
  <div
    v-if="
      component.componentType === 'DragDropQuestion' && !component.renderType
    "
    class="drag-drop-type-selector"
  >
    <p class="drag-drop-type__select-text">
      Select Drag & Drop Format (this cannot be changed)
    </p>
    <choice-input rules="required" v-model="renderChoice" layout="vertical">
      <radio-button
        value="rich-text"
        :class="
          renderChoice === 'rich-text'
            ? 'dndTypeChoice__selected'
            : 'dndTypeChoice'
        "
        checked="true"
      >
        <span class="dndChoice">Rich text</span>
        <span class="dndTypeChoice__description"
          ><b>Recommended:</b> For creating text, image, and custom drag & drop
          areas</span
        >
      </radio-button>
      <radio-button
        value="latex"
        name="latex"
        :class="
          renderChoice === 'latex' ? 'dndTypeChoice__selected' : 'dndTypeChoice'
        "
      >
        <span class="dndChoice">LaTeX</span>
        <span class="dndTypeChoice__description"
          >For inserting drop areas into a LaTeX formula</span
        >
      </radio-button>
    </choice-input>
    <form-button class="dndCreateButton" @click="submitRenderType">
      Create Drag & Drop Area
    </form-button>
  </div>
  <div v-else class="edit-activity-component">
    <div>
      <checkbox
        v-if="canHideComponent"
        class="allow-empty-targets__checkbox"
        :modelValue="component.hideContent"
        @update:modelValue="hideContent => emitChange({ hideContent })"
      >
        Hide content until previous question is answered correctly
      </checkbox>
    </div>
    <!-- Legacy PhET-iO Simulation -->
    <div v-if="component.componentType === 'PhetIOSim'" class="phet-iosim">
      <edit-legacy-phet-io-simulation
        :component="component"
        :variable-context="variableContext"
        @change="e => emitChange({ config: e.config })"
        :name="name"
      />
    </div>

    <!-- PhET-iO Simulation -->
    <div
      v-if="component.componentType === 'PhetIOSimulation'"
      class="phet-iosim"
    >
      <edit-phet-io-simulation
        :component="component"
        :variable-context="variableContext"
        @change="e => emitChange({ config: e.config })"
        :name="name"
      />
    </div>

    <!-- Embedded Instance -->
    <div
      v-if="component.componentType === 'EmbeddedInstance'"
      class="embedded-instance"
    >
      <label class="control-label title__label" for="component.title"
        >Title</label
      >
      <div class="embedded-instance__autocomplete_bar">
        <form-group class="section-insight">
          <text-input
            ref="sectionNameInput"
            :modelValue="component.title"
            :name="`${name}.name`"
            label="component.title"
            @update:modelValue="e => emitChange({ title: e })"
          />
        </form-group>
      </div>

      <label
        class="control-label embedded-instance__label"
        for="component.instance"
        >Instance</label
      >

      <div class="embedded-instance__autocomplete_bar">
        <form-group class="section-insight">
          <autocomplete
            v-model="instance"
            :options="instances"
            label="instance"
            :rules="{ required: true }"
            :name="`${name}.instance`"
            label-key="name"
            value-key="_id"
            @update:modelValue="() => resetVideoConfig()"
          />
        </form-group>
      </div>
      <embedded-instance-settings
        :settings="instanceSettings"
        :variable-context="variableContext"
        v-model:config="jsonConfig"
        :component="component.name"
      />
      <div v-if="showInstance">
        <pivot-instance :settings="instanceSettingsMetadata" />
      </div>
    </div>

    <!-- Student Instance -->
    <div v-if="component.componentType === 'StudentInstance'">
      <p>Videos will be kept for up to 90 days.</p>
    </div>

    <!-- Instructor Instance -->
    <div
      v-if="component.componentType === 'InstructorInstance'"
      class="form-group instructor-upload"
    >
      <form-group class="section-insight">
        <instance-upload
          v-model:config="jsonInstanceMetadata"
          rules="required"
          :name="`${name}.instanceMetadata`"
          label="Instructor Instance Config"
        />
      </form-group>
    </div>

    <!-- IFrame -->
    <edit-iframe
      v-if="component.componentType === 'IFrame'"
      :component="component"
      :variable-context="variableContext"
      @change="({ url, config }) => emitChange({ url, config })"
      :name="name"
    />

    <!-- Component Text -->
    <div class="component-data">
      <form-group
        v-if="hasText"
        :class="
          component.componentType === 'GeneralNote'
            ? 'component-full-width'
            : 'component-first-row'
        "
      >
        <label
          class="control-label"
          for="component.text"
          data-testid="text-label"
        >
          {{ textLabel }}
        </label>
        <div>
          <editor
            :text="component.text"
            :label="textLabel.toLowerCase()"
            :name="`${name}.text`"
            rules="undefinedVariables"
            data-testid="text-input"
            :variable-context="variableContext"
            @input="text => emitChange({ text })"
            video
          />
        </div>
      </form-group>
      <div v-if="hasNotes" class="component-first-row">
        <label class="control-label" for="component.notes">
          Instructor Notes
        </label>
        <div>
          <editor
            :text="component.notes"
            :name="`${name}.notes`"
            :readonly="!isVetted"
            :help-text="
              !isVetted
                ? 'This field is disabled while your account is pending.'
                : ''
            "
            data-testid="notes-input"
            :variable-context="variableContext"
            @input="notes => emitChange({ notes })"
            video
          />
        </div>
      </div>
    </div>

    <!-- Question Hint -->
    <div v-if="isQuestion || isInstruction" class="add-hint">
      <form-button
        link
        v-if="!showHint && !hasHint"
        for="component.hint"
        @click="showHint = true"
        class="add-hint__button"
      >
        <icon icon="plus" />
        Add Hint
      </form-button>
      <activity-question-hint
        v-else
        :hint="{
          hide: component.hideHint,
          text: component.hint,
          prompt: component.hintPrompt
        }"
        :name="`${name}[0]`"
        :variable-context="variableContext"
        @change="
          ({ hide, text, prompt }) =>
            emitChange({ hideHint: hide, hintPrompt: prompt, hint: text })
        "
        @delete="clearHint"
      />
    </div>

    <!-- Question Point Value -->
    <div class="point-grade-submission">
      <div v-if="isQuestion" class="point-value">
        <label for="component.pointValue"> Point Value </label>
        <number-input
          min="0"
          :modelValue="component.pointValue"
          data-testid="point-value-input"
          @input="pointValue => emitChange({ pointValue })"
          :name="`${name}.pointValue`"
        />
      </div>
      <div
        v-if="component.componentType == 'GridGraphQuestion'"
        class="graph_options"
      >
        <form-button link @click="toggleGraphInstructions">
          <span
            v-if="canHaveGraphInstructions || showGraphInstructions"
            class="remove-instructions__button"
          >
            Remove Graph Instructions
            <icon icon="trash" />
          </span>
          <span v-else class="add-instructions__button">
            <icon icon="plus" />
            Add Graph Instructions
          </span>
        </form-button>
      </div>

      <div v-if="canAutograde">
        <checkbox
          :modelValue="component.autograde"
          @update:modelValue="autograde => emitChange({ autograde })"
        >
          Autograde
        </checkbox>
      </div>

      <template v-if="component.autograde">
        <label class="submissions-label" for="component.autogradeLimit">
          Submissions
        </label>
        <number-input
          v-model="autogradeLimit"
          class="submissions-input"
          data-testid="autograde-limit-input"
          :name="`${name}.autogradeLimit`"
        />
      </template>
      <div v-if="component.componentType === 'DragDropQuestion'">
        <checkbox
          class="allow-empty-targets__checkbox"
          :modelValue="component.allowEmptyTargets"
          @update:modelValue="
            allowEmptyTargets => emitChange({ allowEmptyTargets })
          "
        >
          Allow submission with empty drop targets
        </checkbox>
      </div>
    </div>
    <div v-if="showGraphInstructions || canHaveGraphInstructions">
      <label
        :id="`${name}.graphInstructions-label`"
        class="graph-instructions__label"
        >Graph Instructions</label
      >
      <editor
        :aria-labelledby="`${name}.graphInstructions-label`"
        label="Graph Instructions"
        :text="component.graphInstructions"
        :name="`${name}.graphInstructions`"
        :variable-context="variableContext"
        class="graph-instructions__editor"
        @input="graphInstructions => emitChange({ graphInstructions })"
        prevent-stringify
        video
      />
    </div>
    <div v-if="component.componentType == 'GridGraphQuestion'" class="add-hint">
      <form-button
        link
        v-if="!showGraphHint && !hasGraphHint"
        for="component.graphHint"
        @click="showGraphHint = true"
        class="add-hint__button"
      >
        <icon icon="plus" />
        Add Graph Hint
      </form-button>
      <activity-question-hint
        v-else
        :hint="{
          hide: component.hideGraphHint,
          text: component.graphHint,
          prompt: component.graphHintPrompt
        }"
        :name="`${name}[0]graph`"
        :variable-context="variableContext"
        @change="
          ({ hide, text, prompt }) =>
            emitChange({
              hideGraphHint: hide,
              graphHintPrompt: prompt,
              graphHint: text
            })
        "
        @delete="clearGraphHint"
      />
    </div>
    <div
      v-if="component.componentType === 'NumericalQuestion'"
      class="numerical-units"
    >
      <label class="control-label" for="component.units"> Units </label>
      <text-input
        id="units"
        :modelValue="component.units"
        data-testid="units-input"
        @input="units => emitChange({ units })"
        :name="`${name}.units`"
      />
    </div>

    <correct-and-default-answer
      v-if="hasCorrectAnswer || component.componentType.indexOf('Grid') !== -1"
      :component="component"
      @change="emitChange"
      :name="name"
      :variable-context="variableContext"
    />

    <response-conditions
      v-if="component.componentType === 'NumericalQuestion'"
      :conditions="conditions"
      :variable-context="variableContext"
      @update="emitChange"
      :name="name"
    />

    <!-- Multiple Choice Question Choices -->
    <multiple-choice
      v-if="component.componentType === 'MultipleChoiceQuestion'"
      :component="component"
      :variable-context="variableContext"
      @change="emitChange"
      @choiceActions="choices => emitChange({ choices })"
      :name="name"
    />
    <drag-and-drop
      v-if="component.componentType === 'DragDropQuestion'"
      :component="component"
      @change="emitChange"
      :variable-context="variableContext"
      :name="name"
    />
  </div>
</template>

<script>
import InstanceUpload from 'src/shared/components/InstanceUpload'
import { mapState, mapGetters } from 'vuex'
import { hasContent } from 'src/shared/components/editor/utils.js'
import Autocomplete from 'src/shared/components/Autocomplete'
import EditLegacyPhetIoSimulation from './EditLegacyPhetIoSimulation'
import EditPhetIoSimulation from './EditPhetIoSimulation'
import EditIframe from './EditIFrame'
import ResponseConditions from './ResponseConditions'
import EmbeddedInstanceSettings from './EmbeddedInstanceSettings.vue'
import client from 'src/shared/api-client'

import PivotInstance from 'src/shared/components/PivotInstance'
import MultipleChoice from './MultipleChoice'
import CorrectAndDefaultAnswer from './CorrectAndDefaultAnswer'
import ActivityQuestionHint from './ActivtiyQuestionHint'
import DragAndDrop from './DragAndDrop'

export default {
  name: 'EditComponent',
  components: {
    InstanceUpload,
    Autocomplete,
    EditLegacyPhetIoSimulation,
    EditPhetIoSimulation,
    EditIframe,
    PivotInstance,
    ResponseConditions,
    MultipleChoice,
    CorrectAndDefaultAnswer,
    ActivityQuestionHint,
    EmbeddedInstanceSettings,
    DragAndDrop
  },
  inject: ['inherited'],
  emits: ['change'],
  props: {
    component: {
      required: true
    },
    variableContext: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    canHideComponent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      instanceSettings: {},
      showHint: false,
      showGraphInstructions: false,
      showGraphHint: false,
      renderChoice: 'rich-text'
    }
  },
  computed: {
    ...mapState({
      instances: state => state.instances.instances
    }),
    ...mapGetters(['isVetted']),
    showInstance() {
      return !!Object.keys(this.instanceSettings).length
    },
    canAutograde() {
      return [
        'MultipleChoiceQuestion',
        'NumericalQuestion',
        'DragDropQuestion'
      ].includes(this.component.componentType)
    },
    isQuestion() {
      return this.component.componentType.indexOf('Question') !== -1
    },
    hasCorrectAnswer() {
      return [
        'OpenEndedQuestion',
        'GridQuestion',
        'GridGraphQuestion'
      ].includes(this.component.componentType)
    },
    hasNotes() {
      return (
        this.isQuestion ||
        ['GeneralInstruction'].includes(this.component.componentType)
      )
    },
    canHaveGraphInstructions() {
      return this.component.graphInstructions !== undefined
    },
    hasText() {
      return ![
        'StudentInstance',
        'InstructorInstance',
        'EmbeddedInstance',
        'IFrame',
        'PhetIOSim',
        'PhetIOSimulation'
      ].includes(this.component.componentType)
    },
    isExpressionRequired() {
      return this.component.autograde
    },
    textLabel() {
      if (this.isQuestion) {
        return 'Prompt'
      } else if (this.component.componentType === 'GeneralNote') {
        return 'Note'
      } else if (this.component.componentType === 'GeneralInstruction') {
        return 'Instruction'
      } else {
        return 'Text'
      }
    },
    isInstruction() {
      return this.component.componentType === 'GeneralInstruction'
    },

    conditions() {
      return this.component.conditions || []
    },
    hasHint() {
      return hasContent(this.component.hint)
    },
    hasGraphHint() {
      return hasContent(this.component.graphHint)
    },
    hasInstructions() {
      return hasContent(this.component.instructions)
    },
    autogradeLimit: {
      get() {
        return this.component.autogradeLimit
      },
      set(autogradeLimit) {
        this.emitChange({ autogradeLimit })
      }
    },
    instance: {
      get() {
        const instance = this.component.instance
        return instance ? instance._id || instance : undefined
      },
      set(instance) {
        this.emitChange({ instance })
      }
    },
    jsonInstanceMetadata: {
      get() {
        try {
          return JSON.parse(this.component.instanceMetadata)
        } catch (e) {
          return {}
        }
      },
      set(instanceMetadata) {
        instanceMetadata = JSON.stringify(instanceMetadata)
        this.emitChange({ instanceMetadata })
      }
    },
    jsonConfig: {
      get() {
        try {
          return JSON.parse(this.component.config)
        } catch (e) {
          return {}
        }
      },
      set(config) {
        config = JSON.stringify(config)
        this.emitChange({ config })
      }
    },
    instanceSettingsMetadata() {
      const settings = this.instanceSettings
      if (!settings.selection || !this.jsonConfig.parameters) return settings
      const config = { ...settings.selection.parameters }

      Object.keys(this.jsonConfig.parameters).forEach(key => {
        const metadata = this.jsonConfig.parameters[key]
        config[key] = {
          ...config[key],
          visible: true,
          selectable: true,
          ...(metadata.type !== 'variable' && { initial: metadata.initial })
        }
      })
      settings.selection.parameters = config
      return settings
    }
  },
  methods: {
    async setVideoInstanceId(id) {
      try {
        this.instanceSettings = {}
        const instanceObj = await client.instances.get({ id: id })
        this.instanceSettings = instanceObj.settings
        if (!this.jsonConfig || !Object.keys(this.jsonConfig).length) {
          this.setDefaultVideoConfig()
        }
      } catch (error) {
        if (error.status === 500) {
          this.$error(`Failed to load instance player`)
        } else {
          throw error
        }
      }
    },
    submitRenderType() {
      this.emitChange({ renderType: this.renderChoice })
    },
    emitChange(fields) {
      this.$emit('change', fields)
    },
    clearHint() {
      this.showHint = false
      this.emitChange({ hint: '', hideHint: undefined, hintPrompt: undefined })
    },
    clearGraphHint() {
      this.showGraphHint = false
      this.emitChange({
        graphHint: '',
        hideGraphHint: undefined,
        graphHintPrompt: undefined
      })
    },
    resetVideoConfig() {
      this.jsonConfig = {}
    },
    setDefaultVideoConfig() {
      if (!this.instanceSettings.selection) return
      const defaultConfig = {}
      Object.keys(this.instanceSettings.selection.parameters).forEach(key => {
        defaultConfig[key] = {
          type: 'student',
          initial: undefined,
          visible: true,
          selectable: true
        }
      })
      this.jsonConfig = { parameters: defaultConfig }
    },
    toggleGraphInstructions() {
      if (this.showGraphInstructions) {
        this.emitChange({
          graphInstructions: undefined
        })
      }
      this.showGraphInstructions = !this.showGraphInstructions
    }
  },
  watch: {
    instance: {
      handler() {
        if (this.instance) {
          this.setVideoInstanceId(this.instance)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
$border: 1px solid $teal;

.choice-spacer {
  border-top: 1px solid #ddd;
}

.graph-instructions__label {
  margin-left: 15px;
  margin-bottom: 0px;
  color: #3d3d3d;
  font-weight: 400;
  font-size: 16px;
}

.submissions-label {
  margin: 0;
  font-weight: normal;
  margin-left: 24px;
}

.submissions-input {
  width: 80px;
  margin-left: 8px;
}

.autograde-container {
  display: flex;
  align-items: center;
}

.select-type-note {
  display: block;
  margin-top: 16px;
}

.new-flag-parent {
  position: relative;
}

.new-flag {
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  right: 16px;
  top: 30px;
  color: $pink;
}

.tooltip-trigger {
  background: inherit;
  color: #aaaaaa;
  border: 1px solid #aaaaaa;
  border-radius: 100%;
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
}

.tooltip-inner {
  width: 350px;
}

.component-data {
  display: flex;
  margin-bottom: 10px;
  width: 99%;
  justify-content: space-between;
  position: relative;

  & .component-first-row {
    width: 50%;
    margin: 0;
    margin-left: 10px;
  }

  & .component-full-width {
    width: 99%;
    margin: 0;
    margin-left: 10px;
  }
}

.add-hint {
  display: flex;
  margin-bottom: 10px;
  width: 99%;
  justify-content: space-between;
  position: relative;
}

.hint-section-action {
  display: inline-flex;
  position: absolute;
  right: 0;
  flex-direction: column;
}

.point-grade-submission {
  display: flex;
  margin: 10px;
  align-items: center;

  & .point-value {
    display: flex;
    align-items: center;
    margin-right: 20px;

    & label {
      margin-right: 10px;
    }
  }
}

.embedded-instance {
  &__label {
    font-weight: 600;
  }

  &__autocomplete_bar {
    width: 65%;

    & .section-insight {
      margin: 0;
    }
  }
}

.numerical-units {
  display: flex;
  align-items: center;
  margin: 10px;

  & label {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 700;
  }
}
.phet-iosim {
  width: 99%;

  & .form-group {
    margin: 0;
    margin-top: 15px;
  }
}

.instructor-upload {
  margin: 0;
  width: 98%;

  & .section-insight {
    margin: 0;
  }
}

.add-hint__button {
  margin-left: 10px;
}
.add-instructions__button {
  margin-left: 5px;
}
.remove-instructions__button {
  margin-left: 5px;
  color: $color-warning !important;
}
.graph-instructions__editor {
  margin: 10px;
}
.allow-empty-targets__checkbox {
  margin-left: 15px;
}
.drag-drop-type-selector {
  background-color: rgba(204, 97, 20, 0.08);
  width: 99%;
  padding: 14px 0px 14px 14px;
}
.dndTypeChoice {
  border: 1px solid $ui-grey;
  color: $text;
  width: 98%;
  border-radius: 5px;
  padding: 6px;
  margin-bottom: 5px;
  position: relative;
  > label {
    margin: 0 !important;
  }
}
.dndTypeChoice__selected {
  background-color: #ffffff;
  border: 1px solid $teal;
  outline: 1px solid $teal;
  width: 98%;
  border-radius: 5px;
  padding: 6px;
  margin-bottom: 5px;
  position: relative;
  > label {
    margin: 0 !important;
  }
}

.dndTypeChoice__description {
  position: absolute;
  left: 140px;
}
.drag-drop-type__select-text {
  margin-bottom: 14px;
}
.dndCreateButton {
  margin-top: 5px;
}
</style>
