import EventEmitter from 'events'
import math from 'src/setup/math'
import * as Sentry from '@sentry/browser'

const COMMAND_CHARACTERISTIC = '0002-0000-0000-5c1e741f1c00'
const SAMPLE_CHARACTERISTIC = '0004-0000-0000-5c1e741f1c00'
const SAMPLE_ACK_CHARACTERISTIC = '0005-0000-0000-5c1e741f1c00'

const SET_SAMPLE_PERIOD = 0x01

export default class Sensor extends EventEmitter {
  constructor(sensorData, service) {
    super()

    this.sensorData = sensorData
    this.service = service

    this.id = this.sensorData.ID

    // Process the different measurements of this sensor
    // by filtering out the measurement types we are ignoring,
    // and defining the dependencies of each measurement. Output
    // as an object with the measurement ID as key for faster lookup
    // when processing new sensor values.
    this.measurements = this.sensorData.Measurements.reduce(
      (acc, measurementData) => {
        const { ID, Type, Value, Inputs, Equation } = measurementData
        if (!['Derivative'].includes(Type)) {
          const dependencies = []
          Inputs && dependencies.push(...Inputs.split(','))
          Equation &&
            dependencies.push(
              ...[...Equation.matchAll(/\[(\d+)\]/g)].map(match => match[1])
            )

          if (Equation) {
            measurementData.Equation = Equation.replaceAll(
              /\[([^\]]+)\]/g,
              '$$$1'
            )
          }

          acc[ID] = {
            ...measurementData,
            dependencies,
            value: Type === 'Constant' ? parseFloat(Value || 0) : 0
          }
        }
        return acc
      },
      {}
    )

    // Raw values always need processing first, so filter them
    // into their own array
    this.rawMeasurements = Object.values(this.measurements).filter(
      m => m.Type === 'RawDigital'
    )

    // Any other measurments will be processed/calculated after the
    // raw values. Filter them into their own array and sort by
    // dependencies so we know the dependent values have been updated.
    this.calculatedMeasurments = Object.values(this.measurements)
      .filter(m => m.Type !== 'RawDigital')
      .sort((a, b) => {
        if (
          a.dependencies.includes(b.ID) ||
          (a.dependencies.length && !b.dependencies.length)
        ) {
          return 1
        } else if (
          b.dependencies.includes(a.ID) ||
          (b.dependencies.length && !a.dependencies.length)
        ) {
          return -1
        }

        return 0
      })

    this.sensorCommand = null
    this.sensorSampleResponse = null
    this.sensorSampleAck = null
  }

  async init() {
    const servicePrefix = this.service.uuid.split('-')[0]
    const characteristics = await this.service.getCharacteristics()

    // Make sure the required characteristics exist
    characteristics.forEach(characteristic => {
      switch (characteristic.uuid) {
        case `${servicePrefix}-${COMMAND_CHARACTERISTIC}`:
          this.sensorCommand = characteristic
          break
        case `${servicePrefix}-${SAMPLE_CHARACTERISTIC}`:
          this.sensorSampleResponse = characteristic
          this.sensorSampleResponse.addEventListener(
            'characteristicvaluechanged',
            event => {
              const response = event.target.value
              this._handleSampleResponse(response)
            }
          )
          this.sensorSampleResponse.startNotifications()
          break
        case `${servicePrefix}-${SAMPLE_ACK_CHARACTERISTIC}`:
          this.sensorSampleAck = characteristic
          break
      }
    })

    if (!(this.sensorCommand && this.sensorSampleResponse)) {
      throw new Error(
        'Expected command and sample response characteristics not found'
      )
    }
  }

  async setSamplePeriod(samplePeriodUs = 0) {
    // Build packet for command to set the sample period and send
    const packet = new DataView(new ArrayBuffer(7))
    packet.setUint8(0, SET_SAMPLE_PERIOD)
    packet.setUint32(1, samplePeriodUs, true)
    packet.setUint8(5, 0x00)
    packet.setUint8(6, 0x00)
    await this.sensorCommand.writeValue(new Uint8Array(packet.buffer))
  }

  async _sendSampleAck(sequence) {
    // Build packet for command to ack received samples and send
    const packet = new DataView(new ArrayBuffer(5))
    packet.setUint32(1, 0, true)
    packet.setUint8(4, sequence)
    await this.sensorSampleAck.writeValue(new Uint8Array(packet.buffer))
  }

  _handleSampleResponse(notification) {
    // Process a new sensor value notification. The first byte is
    // the `sequence byte` which tells us which value this is in the
    // sequence of sensor values. This byte is incrementing from 0 to 31
    // and then starts over.
    const sequenceByte = notification.getUint8(0)

    // Update the values of all the raw measurements of the sensor.
    // The `DataSize` prop of the measurement tells us how big the
    // value is in the packet and `CanChangeSign` tells us if the value
    // is signed or not. Each sensor has a different number of these and
    // the packets are different sizes to reflect that. Keeping track with
    // valueByteOffset allows that flexibility.
    let valueByteOffset = 1
    this.rawMeasurements.forEach(m => {
      try {
        const dataSize = +m.DataSize
        switch (dataSize) {
          case 1:
            m.value =
              m.CanChangeSign === '1'
                ? notification.getInt8(valueByteOffset, true)
                : notification.getUint8(valueByteOffset, true)
            break
          case 2:
            m.value =
              m.CanChangeSign === '1'
                ? notification.getInt16(valueByteOffset, true)
                : notification.getUint16(valueByteOffset, true)
            break
          case 4:
            m.value =
              m.CanChangeSign === '1'
                ? notification.getInt32(valueByteOffset, true)
                : notification.getUint32(valueByteOffset, true)
            break
        }
        valueByteOffset += dataSize
      } catch (error) {
        Sentry.withScope(scope => {
          scope.setExtra('pos', valueByteOffset)
          scope.setExtra(
            'data',
            Array.from(new Uint8Array(notification.buffer))
          )
          scope.setExtra('measurement', JSON.stringify(m))
          scope.setExtra('sensor', JSON.stringify(this.sensorData))
          Sentry.captureException(error)
        })
      }
    })

    // Update the values of all the other measurements of the sensor.
    // Each measurement may have different ways of calculating the final value,
    // determined by the Equation or Type prop of the measurment.
    this.calculatedMeasurments.forEach(m => {
      const { Equation, Type, Inputs, Params } = m

      // If the Equation prop exists, build a scope from the other measurement
      // values of the sensor and evaluate the equation with math.js. See
      // `src/setup/math.js` for some custom functions required by some measurments.
      if (Equation) {
        const scope = Object.values(this.measurements).reduce((acc, m) => {
          acc[`$${m.ID}`] = m.value
          return acc
        }, {})

        m.value = math.evaluate(Equation, scope)
      } else if (Type) {
        // If the Type prop exists, calculate the value based on the
        // predefined equation. (pulled from PASCO provided C++ files)
        switch (Type) {
          case 'FactoryCal':
          case 'UserCal':
            {
              const [x1, y1, x2, y2] = Params.split(',').map(parseFloat)
              m.value =
                ((y2 - y1) / (x2 - x1)) *
                  (this.measurements[Inputs].value - x1) +
                y1
            }
            break
          case 'LinearConv':
            {
              const [p0, p1] = Params.split(',').map(parseFloat)
              m.value = p0 * this.measurements[Inputs].value + p1
            }
            break
          case 'RotaryPos':
            {
              const [p0, p1] = Params.split(',').map(parseFloat)
              m.value += (this.measurements[Inputs].value * p0) / p1
            }
            break
          case 'ThreeInputVector':
            {
              const [x, y, z] = Inputs.split(',').map(
                ID => this.measurements[ID].value
              )
              m.value = Math.sqrt(x * x + y * y + z * z)
            }
            break
        }
      }
    })

    // Emit value-changed event once all measurments have been calculated.
    this.emit('value-changed', this)

    // We need to send a command telling the sensor we have
    // acknowledged the new values. We don't have to send it every
    // time, but it sounds like we need to send it at least once
    // before the sequence starts over, so here just sending it
    // every 10th notification in the sequence.
    if (sequenceByte % 10 === 0) {
      this._sendSampleAck(sequenceByte)
    }
  }
}
