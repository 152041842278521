<template>
  <div class="browse-summary">
    <template v-if="!isLoading">
      Showing {{ quotePageSize }} of {{ quoteCount }} results
      <template v-if="search">for "{{ search }}"</template>
      <template v-if="filters.length && filters.length > 0">
        filtered by {{ filters.join(', ') }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BrowseSummary',
  inject: ['browseQuotes'],
  computed: {
    quotePageSize() {
      return this.browseQuotes.quotes.length
    },
    quoteCount() {
      return this.browseQuotes.quoteCount
    },
    search() {
      return this.browseQuotes.query.q
    },
    isLoading() {
      return this.browseQuotes.isLoadingQuotes
    },
    filters() {
      const filters = [
        ...this.browseQuotes.query.types,
        ...(this.browseQuotes.query.status !== ''
          ? [this.browseQuotes.query.status]
          : [])
      ]
      return filters.map(filter => {
        if (this.browseQuotes.types[filter]) {
          return this.browseQuotes.types[filter]
        }
        return this.browseQuotes.statuses[filter]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.browse-summary {
  margin: 8px 0 0 0;
  flex-shrink: 1;
  grid-area: summary;
}
</style>
