<template>
  <th>
    <div class="bluetooth-header-column__container">
      <template v-if="deviceId && deviceId !== 'SYSTEM'">
        <span
          v-if="isConnected"
          class="data-stream-status connected"
          :class="{
            error: isError
          }"
          >{{ dataStreamStatus }}</span
        >
        <span v-else class="data-stream-status disconnected"
          ><form-button
            v-if="isBluetoothSupported"
            tabindex="-1"
            link
            @click="$gridActions.connectBluetoothDevice"
            data-tablenav
            >Disconnected</form-button
          ><span v-else>Disconnected</span></span
        >
        <template v-if="isConnected && !$bluetooth.collectingSamples">
          <form-button
            v-if="isTared"
            tabindex="-1"
            link
            @click="clearTare"
            data-tablenav
            >Reset</form-button
          >
          <form-button v-else tabindex="-1" link @click="tare" data-tablenav
            >Zero</form-button
          >
        </template>
      </template>
    </div>
  </th>
</template>

<script>
import { isBluetoothSupported } from 'src/shared/utils/system-compatibility'

export default {
  name: 'DataGridBluetoothColumnHeader',
  inject: ['$gridActions', '$bluetooth'],
  props: {
    column: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isBluetoothSupported
    }
  },
  computed: {
    deviceId() {
      return this.column.get('deviceId')
    },
    dataStreamId() {
      return this.column.get('dataStreamId')
    },
    dataStreamStatus() {
      if (this.$bluetooth.collectingSamples) {
        return 'Capturing'
      } else if (this.$bluetooth.connectedDevices[this.deviceId].error) {
        return this.$bluetooth.connectedDevices[this.deviceId].error
      }

      return this.$bluetooth.connectedDevices[this.deviceId].dataStreams[
        this.dataStreamId
      ].liveReading.toFixed(3)
    },
    isConnected() {
      return (
        !!this.$bluetooth.connectedDevices[this.deviceId] &&
        !!this.$bluetooth.connectedDevices[this.deviceId].dataStreams[
          this.dataStreamId
        ]
      )
    },
    isTared() {
      return this.$bluetooth.connectedDevices[this.deviceId].dataStreams[
        this.dataStreamId
      ].isTared
    },
    isError() {
      return !!this.$bluetooth.connectedDevices[this.deviceId].error
    }
  },
  methods: {
    tare() {
      this.$bluetooth.connectedDevices[this.deviceId].dataStreams[
        this.dataStreamId
      ].tare()
    },
    clearTare() {
      this.$bluetooth.connectedDevices[this.deviceId].dataStreams[
        this.dataStreamId
      ].clearTare()
    }
  }
}
</script>

<style lang="scss" scoped>
.bluetooth-header-column__container {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 8px;
  line-height: normal;

  span.data-stream-status {
    flex: 1;
    position: relative;
    padding-left: 14px;
    font-weight: normal;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      transform: translateY(-50%);
    }

    &.connected {
      &:before {
        background: $color-success;
      }
    }

    &.error {
      font-style: italic;

      &:before {
        background: $color-error;
      }
    }

    &.disconnected {
      font-style: italic;

      button {
        color: $dark-grey;
        font-weight: normal;
      }

      &:before {
        background: $color-muted;
      }
    }
  }
}
</style>
