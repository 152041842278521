<template>
  <modal>
    <modal-header>Questions</modal-header>
    <modal-body>
      <variable-provider v-slot="variableContext" :variables="variables">
        <div>
          <collapse-panel
            v-for="component in gradableComponents"
            :key="component._id"
            :title="`Section ${component.section}, Question ${component.questionNumber}`"
          >
            <h3>Prompt</h3>
            <editor-content
              :text="component.text"
              :variable-context="variableContext"
              variable-names
            />
            <h3>Answer</h3>
            <component-response
              :component="component"
              :response="componentAnswer(component)"
              :variable-context="variableContext"
              :name="`question-choice-${component._id}-answer`"
            />
            <h3>Instructor Notes</h3>
            <editor-content :text="component.notes" />
          </collapse-panel>
        </div>
      </variable-provider>
    </modal-body>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'src/shared/components/modals/components'
import CollapsePanel from 'src/shared/components/global/CollapsePanel.vue'
import ComponentResponse from './ComponentResponse.vue'
import VariableProvider from 'src/shared/components/VariableProvider.vue'

export default {
  name: 'ShowQuestionsModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    CollapsePanel,
    ComponentResponse,
    VariableProvider
  },
  props: {
    gradableComponents: {
      type: Array,
      required: true
    },
    variables: {
      type: Array,
      required: true
    }
  },

  methods: {
    componentAnswer(component) {
      if (component.componentType === 'MultipleChoiceQuestion') {
        return component.choices
          .map(({ answer }, index) => ({ answer, index }))
          .filter(({ answer }) => answer)
          .map(({ index }) => index)
          .join(',')
      } else {
        return component.answer
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  width: 745px;
}
</style>
