<template>
  <div class="section-header" :class="{ 'section-header--centered': centered }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    centered: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.section-header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 24px 0;
  min-height: 38px;
  align-items: center;
}

.section-header--centered {
  justify-content: center;
  text-align: center;
}
</style>
