import { computed, shallowRef, ref, onBeforeMount } from 'vue'
import client from 'src/shared/api-client.ts'

const filters = shallowRef({})
const roots = shallowRef({})
const isLoading = ref(false)
const isLoaded = ref(false)

export default function useCategoryFilters() {
  if (!isLoaded.value && !isLoading.value) {
    isLoading.value = true

    onBeforeMount(async () => {
      const data = await client.libraries.getFilters()
      roots.value = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [value.id, key])
      )
      data.topics = data.subjects.children
      data.subjects = data.subjects.children.map(
        ({ children, ...subject }) => subject
      )
      filters.value = data
      isLoaded.value = true
      isLoading.value = false
    })
  }

  const keys = computed(() => Object.keys(filters.value))

  return { isLoading, filters, roots, keys }
}
