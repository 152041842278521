<template>
  <choice-input
    :rules="{ required: !isAdmin }"
    label="institution roles"
    aria-labelledby="institution-roles-label"
    v-model="model"
  >
    <checkbox value="instructor">Instructor</checkbox>
    <checkbox value="department-head"> Department Head </checkbox>
    <checkbox value="curriculum-administrator">
      Curriculum Administrator
    </checkbox>
    <checkbox value="school-administrator"> School Administrator </checkbox>
    <checkbox value="it-administrator"> IT Administrator </checkbox>
    <checkbox value="teaching-assistant"> Teaching Assistant </checkbox>
    <checkbox value="purchasing-agent"> Purchasing Agent </checkbox>
    <checkbox value="other">Other</checkbox>
  </choice-input>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'InstitutionRolesChoices',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
