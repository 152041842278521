<template>
  <form-button v-if="hasPendo">
    <slot />
  </form-button>
</template>

<script setup>
import { ref } from 'vue'

const hasPendo = ref(!!window.PI_PENDO_KEY)
</script>
