<template>
  <select-library-modal
    ref="selectLibraryModal"
    title="Select a Library to Browse"
    submit-label="Browse Activities"
    hide-archived
    include-public
    @submit="submit"
  />
</template>

<script>
import SelectLibraryModal from './SelectLibraryModal'

export default {
  name: 'SelectLibraryToBrowseModal',
  components: {
    SelectLibraryModal
  },
  methods: {
    async submit({ libraryId, done }) {
      this.$router.push({
        name: 'activities',
        query: {
          library: libraryId
        }
      })
      done()
    }
  }
}
</script>
