export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE'
export const REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE'
export const CLEAR_FLASH_MESSAGES = 'CLEAR_FLASH_MESSAGES'
export const SET_FLASH_STICKY = 'SET_FLASH_STICKY'
export const SET_FLASH_SCROLLED = 'SET_FLASH_SCROLLED'

export const CLEAR_DIRTY_RESPONSES = 'CLEAR_DIRTY_RESPONSES'
export const CLEAR_SAVED_RESPONSES = 'CLEAR_SAVED_RESPONSES'
export const SET_SAVED_RESPONSE = 'SET_SAVED_RESPONSE'
export const CLEAR_DIRTY_STUDENT_RESPONSE = 'CLEAR_DIRTY_STUDENT_RESPONSE'

export const SET_CLASSES = 'SET_CLASSES'
export const SET_CLASSES_PAGECOUNT = 'SET_CLASSES_PAGECOUNT'
export const SET_CLASS_ASSIGNMENTS = 'SET_CLASS_ASSIGNMENTS'
export const SET_CLASS_IS_ARCHIVED = 'SET_CLASS_IS_ARCHIVED'
export const SET_SHOW_ARCHIVED_CLASSES = 'SET_SHOW_ARCHIVED_CLASSES'
export const SET_ACTIVITY_LIBRARY = 'SET_ACTIVITY_LIBRARY'
export const SET_SHOW_ARCHIVED = 'SET_SHOW_ARCHIVED'
export const SET_ASSIGNMENT_ENDDATE = 'SET_ASSIGNMENT_ENDDATE'
export const SET_ASSIGNMENT_STARTDATE = 'SET_ASSIGNMENT_STARTDATE'
export const SET_ASSIGNMENT_IS_ARCHIVED = 'SET_ASSIGNMENT_IS_ARCHIVED'
export const SET_ASSIGNMENT_IS_DELETED = 'SET_ASSIGNMENT_IS_DELETED'

export const SET_ASSIGNMENT = 'SET_ASSIGNMENT'
export const SET_RESPONSES = 'SET_RESPONSES'
export const SET_RESPONSE_SORTINGS = 'SET_RESPONSE_SORTINGS'

export const SET_GRADABLE_ASSIGNMENT = 'SET_GRADABLE_ASSIGNMENT'
export const SET_GRADABLE_RESPONSES = 'SET_GRADABLE_RESPONSES'
export const SET_GRADABLE_RESPONSE = 'SET_GRADABLE_RESPONSE'
export const RESET_GRADABLE_RESPONSES = 'RESET_GRADABLE_RESPONSES'
export const SET_GRADABLE_COMPONENTS = 'SET_GRADABLE_COMPONENTS'
export const SET_SCORE = 'SET_SCORE'
export const SET_COMMENTS = 'SET_COMMENTS'
export const SET_NEW_PROGRESS = 'SET_NEW_PROGRESS'
export const SET_TOTAL_PAGE_COUNT = 'SET_TOTAL_PAGE_COUNT'
export const SET_CURRENT_PAGE_NUMBER = 'SET_CURRENT_PAGE_NUMBER'
export const SET_GRADE_ANONYMOUSLY = 'SET_GRADE_ANONYMOUSLY'

export const SET_AUTH = 'UPDATE_AUTH'
export const ADD_SEATS = 'ADD_SEATS'
export const LTI_MODE = 'LTI_MODE'
export const OAUTH_MODE = 'OAUTH_MODE'
export const SET_HAS_MFA = 'SET_HAS_MFA'

export const SET_INSTRUCTOR_IS_SUSPENDED = 'SET_INSTRUCTOR_IS_SUSPENDED'

export const SORT_COLUMN = 'SORT_COLUMN'
export const SORT_COLUMN_DESCENDING = 'SORT_COLUMN_DESCENDING'
export const SORT_COLUMN_ASCENDING = 'SORT_COLUMN_ASCENDING'

export const SET_PAGECOUNT = 'SET_PAGECOUNT'
export const SET_PAGE = 'SET_PAGE'
export const SET_TAGS = 'SET_TAGS'
export const SET_PUBLIC_TAGS = 'SET_PUBLIC_TAGS'
export const SET_SELECTED_TAGS = 'SET_SELECTED_TAGS'

export const SET_IS_CHECKING = 'SET_IS_CHECKING'
export const SET_SHOULD_UPDATE = 'SET_SHOULD_UPDATE'
export const SET_CAN_UPDATE = 'SET_CAN_UPDATE'

export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_PAGE_COUNT = 'SET_PAGE_COUNT'
export const SET_SHOW_FILTER = 'SET_SHOW_FILTER'

export const SET_INSTRUCTORS = 'SET_INSTRUCTORS'
export const SET_INSTRUCTOR = 'SET_INSTRUCTOR'
export const EXTEND_TRIAL = 'EXTEND_TRIAL'
export const SAVE_INSTRUCTOR = 'SAVE_INSTRUCTOR'
export const SET_CLASS = 'SET_CLASS'

export const SET_INSTANCES = 'SET_INSTANCES'

export const SET_SAVED = 'SET_SAVED'
export const REMOVE_SAVED = 'REMOVE_SAVED'

export const SET_TOTAL_RESPONSE_COUNT = 'SET_TOTAL_RESPONSE_COUNT'
