<template>
  <div class="collapsed-data">
    <span
      v-if="component.componentType === 'EmbeddedInstance'"
      class="video-instance"
    >
      {{ videoInstanceName }}
    </span>
    <div v-if="component.componentType === 'SplitView'">
      Left Components: {{ component.leftContent.length }}
      <span class="rightComponentCount"
        >Right Components: {{ component.rightContent.length }}</span
      >
    </div>
    <div class="point-autograded">
      <div v-if="isQuestion" class="point-value" @click.stop="">
        <label :for="`component-header-${id}-point-input`"> Point Value</label>
        <number-input
          :id="`component-header-${id}-point-input`"
          class="score-input"
          min="0"
          :modelValue="component.pointValue"
          @input="pointValue => emitChange({ pointValue })"
          :name="`${name}.pointValue`"
        />
      </div>
      <span v-if="canAutograde" class="autograded">
        <checkbox
          :modelValue="component.autograde"
          @update:modelValue="autograde => emitChange({ autograde })"
          >Autograde
        </checkbox>
      </span>
      <template v-if="component.autograde">
        <label
          class="submissions-label"
          :for="`component-header-${id}-submissions-input`"
        >
          Submissions
        </label>
        <number-input
          :id="`component-header-${id}-submissions-input`"
          class="submissions-input"
          :modelValue="component.autogradeLimit"
          @input="autogradeLimit => emitChange({ autogradeLimit })"
        />
      </template>
    </div>
    <collapse
      v-if="component.componentType === 'MultipleChoiceQuestion'"
      :collapsed="false"
      class="multiple-choice-answer"
    >
      <template #header>
        <collapse-icon class="collapsed-header__choice-collapse-icon" />

        <span class="collapsed-header__choice-header-title">{{
          choicesTitle
        }}</span>
        <span v-if="isSelectMany">
          Because you have selected more than one correct answer, this is a
          <b>select all that apply</b> question
        </span>
        <span v-else-if="isPollQuestion && component.autograde">
          Because you have not marked any answers as correct,
          <b>any selected choice(s)</b> will be autograded as correct
        </span>
        <span v-else-if="isSelectOne">
          Because you have one correct answer selected, this is a
          <b>select one answer</b> question
        </span>
      </template>
      <template #body>
        <ol class="collapsed-header__conditions-list" type="A">
          <li v-for="choice in choices" :key="choice.keyId">
            <span class="ellipsis-text">{{ getChoiceText(choice) }}</span>
            <icon
              v-if="choice.answer"
              icon="check"
              class="correct-answer-check"
            />
            <span v-if="choice.answer" class="correct-answer"
              >Correct Answer</span
            >
          </li>
        </ol>
      </template>
    </collapse>
    <collapse
      v-if="component.componentType === 'NumericalQuestion'"
      :collapsed="false"
      class="multiple-choice-answer"
    >
      <template #header>
        <collapse-icon class="collapsed-header__choice-collapse-icon" />
        <span class="collapsed-header__choice-header-title">{{
          conditionTitle
        }}</span>
      </template>
      <template #body>
        <ol
          class="collapsed-header__conditions-list"
          v-if="conditions.length > 0"
          type="A"
        >
          <li v-for="condition in filteredConditions" :key="condition.keyId">
            {{ getConditionText(condition) }}
            <icon v-if="condition.isCorrect" icon="check" />
            <span v-if="condition.isCorrect" class="correct-answer"
              >Correct Answer</span
            >
          </li>
        </ol>
      </template>
    </collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { replaceVariablesWithNames } from '@pi/shared/variables'
import Collapse from 'src/shared/components/Collapse'
import { getText } from 'src/shared/components/editor/utils.js'

let idCounter = 1
export default {
  name: 'CollapsedHeaderComponent',
  components: { Collapse },
  inject: ['inherited'],
  emits: ['change'],
  props: {
    component: {
      required: true
    },
    variableContext: {
      type: Object,
      required: false
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: idCounter++
    }
  },
  computed: {
    ...mapState({
      instances: state => state.instances.instances
    }),
    totalChoices() {
      return (this.component.choices || []).length
    },
    choicesTitle() {
      return this.totalChoices === 0
        ? 'Choices'
        : `Choices (${this.totalChoices})`
    },
    isSelectMany() {
      return this.choices.filter(({ answer }) => answer).length > 1
    },
    isSelectOne() {
      return this.choices.filter(({ answer }) => answer).length === 1
    },
    isPollQuestion() {
      return this.choices.filter(({ answer }) => answer).length === 0
    },
    videoInstanceName() {
      const instanceObj = this.instances.find(
        i => i._id === this.component.instance
      )

      return instanceObj ? instanceObj.name : ''
    },

    isQuestion() {
      return this.component.componentType.indexOf('Question') !== -1
    },
    canAutograde() {
      return [
        'MultipleChoiceQuestion',
        'NumericalQuestion',
        'DragDropQuestion'
      ].includes(this.component.componentType)
    },
    choices() {
      return this.component.choices || []
    },
    conditions() {
      return this.component.conditions || []
    },
    filteredConditions() {
      return this.component.conditions.filter(c => !c.default)
    },
    activityVariables() {
      const hasResponse = this.inherited.activity.variables.find(
        v => v.id === 'response'
      )
      if (hasResponse) {
        return [...this.inherited.activity.variables]
      }
      return (
        [
          ...this.inherited.activity.variables,
          { id: 'response', name: 'response' }
        ] || []
      )
    },
    totalConditions() {
      return (this.filteredConditions || []).length
    },
    conditionTitle() {
      return this.totalConditions === 0
        ? 'Conditions'
        : `Conditions (${this.totalConditions})`
    }
  },
  methods: {
    emitChange(fields) {
      this.$emit('change', fields)
    },
    getChoiceText(choice) {
      return getText(choice.text, this.variableContext)
    },
    getConditionText(condition) {
      const expression = condition.condition || ''
      return replaceVariablesWithNames(
        expression,
        this.activityVariables || this.inherited.activity.variables
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.video-instance {
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-left: 30px;
}

.point-autograded {
  display: flex;
  align-items: center;

  & .point-value {
    display: flex;
    align-items: center;

    & label {
      margin-right: 10px;
    }

    .score-input {
      width: 50%;
    }
  }

  & .autograded {
    font-weight: 600;
  }

  & .autograded {
    font-weight: 600;
  }

  & .grid-graph-text {
    font-weight: 600;
    width: 50%;
  }
}

.multiple-choice-answer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: $neutral-grey;
  padding: 16px;

  .correct-answer-check {
    color: $teal;
    margin-right: 4px;
  }

  & ol {
    font-weight: 600;
    padding-left: 20px;
    margin-bottom: 0;

    & .correct-answer {
      font-weight: 500;
    }

    & li {
      & .ellipsis-text {
        display: block;
        max-width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
        margin-right: 10px;
      }
    }
    .score-input {
      width: 80%;
    }
  }
}

.submissions-label {
  margin: 0;
  font-weight: normal;
  margin-left: 24px;
}

.submissions-input {
  width: 80px;
  margin-left: 8px;
}
.collapsed-header__choice-header-title {
  font-weight: 700;
  margin-right: 32px;
}
.collapsed-header__choice-collapse-icon {
  margin-right: 4px;
}
.collapsed-header__conditions-list {
  padding-top: 8px;
}
.rightComponentCount {
  margin-left: 10px;
}
</style>
