<template>
  <button
    v-if="isSupported"
    ref="playBtn"
    class="play-btn"
    :class="{
      purple,
      active: state === 'playing'
    }"
    @click="togglePlay"
    type="button"
  >
    <span class="sr-only">Read this to me</span>
    <icon v-if="state === 'playing'" icon="pause" class="pause" />
    <icon v-else-if="state === 'paused'" icon="play" class="play" />
    <icon v-else icon="volume-high" size="sm" />
    <div :class="`pulsating-circle ${state === 'playing' ? 'play' : ''}`"></div>
  </button>
</template>

<script setup>
import { ref, onBeforeUnmount, computed } from 'vue'
const emit = defineEmits(['onPlayPause'])
const props = defineProps({
  text: {
    type: String
  },
  element: {
    type: Node
  },
  purple: {
    type: Boolean,
    default: false
  }
})
const isPlaying = ref(false)
const player = ref(null)

const state = ref('waiting')

const isSupported = computed(() => !!window.speechSynthesis)

if (isSupported.value) {
  onBeforeUnmount(() => {
    speechSynthesis.cancel()
    emit('onPlayPause', false)
  })
}

const getTextToRead = () => {
  if (props.text) {
    return props.text
  }
  if (props.element?.getElementsByClassName) {
    const cloned = props.element.cloneNode(true)
    var mathNodes = Array.from(cloned.getElementsByClassName('katex'))
    mathNodes.forEach(element => {
      element.remove()
    })
    return cloned.innerText
  }
  return
}
const togglePlay = e => {
  e.preventDefault()
  e.stopPropagation()
  if (state.value === 'playing') {
    speechSynthesis.pause()
    state.value = 'paused'
  } else if (state.value === 'paused') {
    speechSynthesis.resume()
    state.value = 'playing'
  } else {
    const textToRead = getTextToRead()
    if (!textToRead) return
    speechSynthesis.cancel()
    player.value = new SpeechSynthesisUtterance(textToRead)
    player.value.rate = 0.85
    player.value.onend = event => {
      isPlaying.value = false
      state.value = 'waiting'
      emit('onPlayPause', false)
    }
    state.value = 'playing'

    speechSynthesis.speak(player.value)
    emit('onPlayPause', state.value === 'playing')
  }
}
</script>

<style lang="scss" scoped>
.pause,
.play {
  font-size: 0.7em !important ;
}
.play-btn {
  margin: 0;
  padding: 0;
  position: relative;
  color: $teal;
  border: $teal solid 1px;
  background: #fff;
  border-radius: 50%;
  align-items: center;
  height: 1.5em;
  width: 1.5em;
  line-height: 0.5em;
  text-align: center;

  &.active {
    color: #fff;
    background: $teal;
  }
  &.purple {
    color: $plum;
    border: $plum solid 1px;
    &.active {
      background: $plum;
      color: #fff;
    }
    .pulsating-circle {
      &.play:before {
        background-color: $plum;
      }
    }
  }
  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}
.pulsating-circle {
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  &.play:before {
    content: '';
    position: relative;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 45px;
    background-color: $teal;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
</style>
