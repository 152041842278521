<template>
  <h1>
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'StickyHeaderTitle',
  computed: {}
}
</script>

<style lang="scss" scoped>
h1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;

  .sticky-header--stuck & {
    font-size: 14px;
  }
}
</style>
