<template>
  <loading-container :loading="isLoading" class="quote-list">
    <paginated-list
      v-bind="pagination"
      data-testid="quote-list"
      @loadpage="loadPage"
    >
      <list-column title="Quote Number" property="quoteNumber" />
      <sortable-list-column
        v-slot="{ productType }"
        title="Type"
        property="productType"
        :sort="sort['productType']"
        @change="sort => changeSort('productType', sort)"
      >
        {{ browseQuotes.types[productType] }}
      </sortable-list-column>
      <sortable-list-column
        v-slot="{ createdAt }"
        title="Date Created"
        :sort="sort['createdAt']"
        @change="sort => changeSort('createdAt', sort)"
      >
        {{ $format.date(createdAt, 'MM/dd/yyyy') }}
      </sortable-list-column>
      <list-column v-slot="{ status }" title="Status">{{
        browseQuotes.statuses[status]
      }}</list-column>
      <list-column title="Educator Name" property="customerName" />
      <list-column title="Educator Email" property="customerEmail" />
      <sortable-list-column
        v-slot="{ total }"
        title="Total"
        property="total"
        :sort="sort['total']"
        @change="sort => changeSort('total', sort)"
        >{{ $format.currency(total) }}</sortable-list-column
      >
      <list-column v-slot="{ id, productType }" title="Actions">
        <router-link :to="routeByType(id, productType)">Edit</router-link>
        |
        <router-link :to="{ name: 'purchase_quote', params: { id } }"
          >View</router-link
        >
      </list-column>
    </paginated-list>
  </loading-container>
</template>

<script>
export default {
  name: 'QuotesList',
  inject: ['browseQuotes', '$modal'],
  computed: {
    sort() {
      return this.browseQuotes.query.sort
        ? { [this.browseQuotes.query.sort]: this.browseQuotes.query.dir }
        : {}
    },
    empty() {
      return this.quotes.length === 0
    },
    quotes() {
      return this.browseQuotes.quotes
    },
    pagination() {
      return {
        page: this.browseQuotes.query.page,
        count: this.browseQuotes.quoteCount,
        limit: this.browseQuotes.quoteLimit,
        items: this.browseQuotes.quotes
      }
    },
    isLoading() {
      return this.browseQuotes.isLoadingQuotes
    }
  },
  methods: {
    loadPage({ page }) {
      this.browseQuotes.updateQuery({ page: page.toString() })
    },
    changeSort(property, dir) {
      if (dir) {
        this.browseQuotes.updateQuery({
          sort: property,
          dir
        })
      } else {
        this.browseQuotes.updateQuery({
          sort: undefined,
          dir: undefined
        })
      }
    },
    routeByType(id, purchaseType) {
      let routeName = { name: 'purchase_quote', params: { id } }
      switch (purchaseType) {
        case 'bookstore-codes':
          routeName = {
            name: 'access_codes_configuration',
            query: { id }
          }
          break
        case 'added-seats':
          routeName = { name: 'new_add_seats_session', query: { id } }
          break
        case 'renewal':
          routeName = { name: 'new_renewal_session', query: { id } }
          break
        case 'upgrade':
          routeName = { name: 'new_upgrade_session', query: { id } }
          break
      }

      return routeName
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-list {
  grid-area: results;

  &__list {
    list-style: none;
    display: grid;
    gap: 32px;
    grid-auto-flow: row;
    padding: 0;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }

  &__empty {
    margin-top: 1em;
    text-align: center;

    & > button {
      margin: 0 8px;
    }
  }
}
</style>
