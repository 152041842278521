<template>
  <ul class="sticky-header-list">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'StickyHeaderList'
}
</script>

<style lang="scss" scoped>
.sticky-header-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  padding: 0;

  .sticky-header-list {
    padding: 0 0 0 20px;
  }

  :slotted(li) {
    margin-right: 20px;
  }
}
</style>
