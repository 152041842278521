import {
  CreateLicenseQuoteRequest,
  CreateLicenseQuoteResponse,
  UpdateLicenseQuoteRequest,
  UploadFileRequest,
  UploadFileResponse,
  CreateRenewalQuoteRequest,
  CreateRenewalQuoteResponse,
  UpdateQuoteRequest,
  UpdateRenewalQuoteRequest,
  UpdateAddedSeatsQuoteRequest,
  CreateAccessCodesQuoteRequest,
  UpdateAccessCodesQuoteRequest,
  GetQuoteRequest,
  GetQuoteResponse,
  GetQuoteTaxExemptionRequest,
  GetQuoteTaxExemptionResponse,
  SetQuotePaymentMethodRequest,
  FulfillQuoteRequest,
  CompleteQuotePurchaseRequest,
  CreateTrialLicenseRequest,
  CreateStudentLicenseRequest,
  GetQuotePriceResponse,
  SendQuoteEmailRequest,
  SearchQuotesResponse,
  SearchQuotesRequest,
  GetQuotePriceRequest
} from '@pi/api-types'
import type Client from './client'
import { ApiRequestError } from './client'

export default class Purchase {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async createLicenseQuote(
    request: CreateLicenseQuoteRequest
  ): Promise<CreateLicenseQuoteResponse> {
    const { body } = await this.client.post<CreateLicenseQuoteResponse>({
      path: `/api/v3/quotes/license`,
      body: JSON.stringify(request)
    })

    return body
  }

  async updateLicenseQuote({
    id,
    ...request
  }: UpdateLicenseQuoteRequest): Promise<void> {
    await this.client.patch({
      path: `/api/v3/quotes/license/${id}`,
      body: JSON.stringify(request)
    })
  }

  async createRenewalQuote(
    request: CreateRenewalQuoteRequest
  ): Promise<CreateRenewalQuoteResponse> {
    const { body } = await this.client.post<CreateRenewalQuoteResponse>({
      path: `/api/v3/quotes/renewal`,
      body: JSON.stringify(request)
    })
    return body
  }

  async updateQuote({ id, ...request }: UpdateQuoteRequest) {
    await this.client.patch({
      path: `/api/v3/quotes/${id}`,
      body: JSON.stringify(request)
    })
  }

  async updateRenewalQuote({ id, ...request }: UpdateRenewalQuoteRequest) {
    await this.client.patch({
      path: `/api/v3/quotes/renewal/${id}`,
      body: JSON.stringify(request)
    })
  }

  async createAddedSeatsQuote(request: CreateLicenseQuoteRequest) {
    const { body } = await this.client.post<CreateLicenseQuoteResponse>({
      path: `/api/v3/quotes/added-seats`,
      body: JSON.stringify(request)
    })
    return body
  }

  async updateAddedSeatsQuote({
    id,
    ...request
  }: UpdateAddedSeatsQuoteRequest) {
    await this.client.patch({
      path: `/api/v3/quotes/added-seats/${id}`,
      body: JSON.stringify(request)
    })
  }

  async createAccessCodesQuote(request: CreateAccessCodesQuoteRequest) {
    const { body } = await this.client.post<CreateLicenseQuoteResponse>({
      path: `/api/v3/quotes/access-codes`,
      body: JSON.stringify(request)
    })
    return body
  }

  async updateAccessCodesQuote({
    id,
    ...request
  }: UpdateAccessCodesQuoteRequest) {
    await this.client.patch({
      path: `/api/v3/quotes/access-codes/${id}`,
      body: JSON.stringify(request)
    })
  }

  async getQuote({ id }: GetQuoteRequest): Promise<GetQuoteResponse> {
    const { body } = await this.client.get<GetQuoteResponse>({
      path: `/api/v3/quotes/${id}`
    })

    return body
  }

  async getQuoteTaxExemption({
    id
  }: GetQuoteTaxExemptionRequest): Promise<GetQuoteTaxExemptionResponse> {
    const { body } = await this.client.get<GetQuoteTaxExemptionResponse>({
      path: `/api/v3/quotes/${id}/tax-exemption`
    })

    return body
  }

  async setQuotePaymentMethod({
    id,
    ...request
  }: SetQuotePaymentMethodRequest) {
    await this.client.put({
      path: `/api/v3/quotes/${id}/payment-method`,
      body: JSON.stringify(request)
    })
  }

  async setQuotePurchaseOrder({
    id,
    ...request
  }: SetQuotePaymentMethodRequest) {
    await this.client.put({
      path: `/api/v3/quotes/${id}/purchase-order`,
      body: JSON.stringify(request)
    })
  }

  async fulfillQuote({ id }: FulfillQuoteRequest) {
    await this.client.patch({ path: `/api/v3/quotes/${id}/fulfill` })
  }

  async completeQuotePurchase({ id }: CompleteQuotePurchaseRequest) {
    await this.client.post({ path: `/api/v3/quotes/${id}/complete` })
  }

  async getQuotePrice(
    request: GetQuotePriceRequest
  ): Promise<GetQuotePriceResponse> {
    const { body } = await this.client.post<GetQuotePriceResponse>({
      path: `/api/v3/quotes/price`,
      body: JSON.stringify(request)
    })
    return body
  }

  async sendQuoteEmail({ id }: SendQuoteEmailRequest) {
    await this.client.post({ path: `/api/v3/quotes/${id}/email` })
  }

  async search(search: SearchQuotesRequest): Promise<SearchQuotesResponse> {
    const { ...query } = search
    const params = new URLSearchParams()

    Object.entries(query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(value => params.append(`${key}[]`, value.toString()))
      } else {
        params.append(key, value as string)
      }
    })

    const { body } = await this.client.get<SearchQuotesResponse>({
      path: `/api/v3/quotes?${params}`
    })
    return body
  }

  // Original comment:
  // This requests an AWS POST policy from the API in order to direct upload the
  // file to S3 from the browser. A signed policy is returned along with some
  // fields that have to be sent with the file and policy to S3.
  // Uploading from the browser reduces the load on the node API servers since
  // they don't have to process files.

  async upload(
    { fileName, id, type }: UploadFileRequest,
    file: File
  ): Promise<{ url: string }> {
    const requestBodyStringified = JSON.stringify({ fileName })

    const initialResponse = await this.client.post<UploadFileResponse>({
      path: `api/v3/quotes/${id}/file/${type}`,
      body: requestBodyStringified
    })

    const { body } = initialResponse

    if (!body) {
      throw new Error('Could not upload documents')
    }

    const { url, fields } = body
    const form = new FormData()
    Object.keys(fields).forEach(key => {
      form.append(key, fields[key])
    })
    form.append('Content-Type', file.type)
    form.append('file', file)

    const awsRequest = new Request(url, {
      method: 'POST',
      body: form
    })

    const awsResponse = await fetch(awsRequest)

    if (awsResponse.status === 204) {
      return {
        url: `${url}${fields['key']}`
      }
    } else {
      throw new ApiRequestError({
        status: awsResponse.status,
        body: await awsResponse.json(),
        request: awsRequest
      })
    }
  }
}
