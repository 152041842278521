<template>
  <browse-activities
    ref="browseActivities"
    v-slot="{ library, updateQuery }"
    :query="computedQuery"
    :shouldLoadLibraries="false"
  >
    <view-container>
      <sticky-header>
        <template #title>
          <sticky-header-title>Activities</sticky-header-title>
          <activities-header-actions />
        </template>
        <template #actions>
          <activity-search-action :query="computedQuery" />
        </template>
      </sticky-header>
      <library-description :library="library" />
      <library-filters
        :modelValue="computedQuery"
        @update:modelValue="updateQuery"
        show-free-filter
      />
      <filter-chips
        :modelValue="computedQuery"
        @update:modelValue="updateQuery"
      />
      <browse-summary />
      <div class="activity-view__settings">
        <activity-sort-action />
        <activity-search-view-settings />
      </div>
      <activity-list>
        <template #card="{ activity }">
          <activity-card :activity="activity" show-free-tag>
            <button-link
              :to="{ name: 'preview_activity', params: { id: activity.id } }"
            >
              Preview
            </button-link>
          </activity-card>
        </template>
        <template #row="{ activity }">
          <activity-row :activity="activity" show-free-tag>
            <div class="activity-row__actions">
              <button-link
                :to="{ name: 'preview_activity', params: { id: activity.id } }"
              >
                Preview
              </button-link>
            </div>
          </activity-row>
        </template>
      </activity-list>
    </view-container>
  </browse-activities>
</template>

<script>
import BrowseActivities from '../components/BrowseActivities'
import ActivitySortAction from '../components/ActivitySortAction'
import BrowseSummary from '../components/BrowseSummary'
import ActivityList from '../components/ActivityList'
import ActivityCard from '../components/ActivityCard'
import ActivityRow from '../components/ActivityRow'
import ActivitiesHeaderActions from '../components/ActivitiesHeaderActions.vue'
import ActivitySearchAction from '../components/ActivitySearchAction.vue'
import LibraryDescription from '../components/LibraryDescription'
import LibraryFilters from '../components/filters/LibraryFilters'
import FilterChips from '../components/FilterChips'
import ActivitySearchViewSettings from '../components/ActivitySearchViewSettings'

export default {
  name: 'ActivitiesView',
  inject: ['$modal'],
  components: {
    BrowseActivities,
    ActivitySortAction,
    BrowseSummary,
    ActivityList,
    ActivityCard,
    ActivityRow,
    ActivitiesHeaderActions,
    ActivitySearchAction,
    LibraryDescription,
    LibraryFilters,
    FilterChips,
    ActivitySearchViewSettings
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  computed: {
    computedQuery() {
      return {
        ...this.query,
        library: 'pivot',
        page: parseInt(this.query.page) || 1,
        sort: this.query.sort || (this.query.q ? 'relevance' : 'updatedAt'),
        dir: this.query.dir || 'desc',
        q: this.query.q || '',
        categories: Object.entries(this.query.categories || {}).reduce(
          (filters, [key, value]) => {
            if (value) {
              filters[key] = Array.isArray(value) ? value : [value]
            }
            return filters
          },
          {}
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-view__settings {
  display: flex;
  gap: 30px;
  align-content: center;
  align-items: center;

  & form-buttom {
    height: 24px;
  }
}
.activity-row__actions {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
</style>
