<template>
  <loading-container :loading="isLoading">
    <section>
      <div class="classes-header">
        <h2>Classes</h2>
      </div>
      <pagination-container v-bind="pagination" @loadpage="paginateCallback">
        <list :items="classes || []" aria-label="License Members">
          <list-column v-slot="{ name, id }" title="Class Name">
            <router-link
              :to="{
                name: 'existing_class',
                params: { id }
              }"
            >
              {{ name }}
            </router-link>
          </list-column>
          <list-column v-slot="{ instructors }" title="Instructor(s)">
            <template v-if="instructors.length > 0">
              <span v-for="instructor in instructors" :key="instructor.id">{{
                `${instructor.lastName}, ${instructor.firstName} `
              }}</span>
            </template>
            <template v-else><em>None Assigned</em></template>
          </list-column>
          <list-column v-slot="{ createdAt }" title="Created Date">
            {{ format(new Date(createdAt), 'MM/dd/yyyy') }}
          </list-column>
          <list-column v-slot="{ endDate }" title="End Date">
            {{ endDate ? format(new Date(endDate), 'MM/dd/yyyy') : '' }}
          </list-column>
          <list-column v-slot="{ activeStudents }" title="Active Students">
            {{ activeStudents }}
          </list-column>
          <list-column v-slot="{ droppedStudents }" title="Dropped Students">
            {{ droppedStudents }}
          </list-column>
          <list-column v-slot="{ id, name }" title="Actions">
            <button-dropdown tertiary right>
              <template #button>
                <icon icon="ellipsis-h" />
                <span class="sr-only">License Classes Actions Menu</span>
              </template>

              <dropdown-link
                :to="{ name: 'existing_class_roster', params: { id } }"
              >
                View Class Roster
              </dropdown-link>
              <dropdown-action @click="() => showChangeInstructorModal(id)">
                Update Instructor
              </dropdown-action>
              <dropdown-action @click="() => archiveClass(id, name)">
                Archive Class
              </dropdown-action>
            </button-dropdown>
          </list-column>
        </list>
      </pagination-container>
    </section>
  </loading-container>
</template>
<script setup lang="ts">
interface ClassesTabProps {
  licenseId: string
}

import { ref, computed, watch, inject, reactive } from 'vue'

import { format } from 'date-fns'
import ChangeInstructorModal from './ChangeInstructorModal.vue'
import client from 'src/shared/api-client'
import { useFlash } from 'src/shared/hooks/flash'
import { useRouter } from 'vue-router'
import { LicensedClass } from '@pi/api-types'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal.vue'

const props = defineProps<ClassesTabProps>()
const classes = ref<LicensedClass[]>([])
const router = useRouter()
const paginationresult = ref({})

const pagination = reactive({
  page: 1,
  count: 0,
  limit: 100
})
const modal = inject<any>('$modal')
const isLoading = computed(() => !classes.value)
const flash = useFlash()

const getClasses = async (page: number | undefined) => {
  const result = await client.licenses.getClasses({
    id: props.licenseId,
    page: page ?? 1
  })

  pagination.page = page ?? 1
  pagination.count = result.total
  pagination.limit = 100

  // mapping data so that it is what is expected from nested vue components
  classes.value = result.classes.map(classItem => ({
    ...classItem,
    id: classItem._id,
    _id: undefined,
    instructors: classItem.instructors.map(instructor => ({
      ...instructor.user,
      id: instructor.user._id,
      _id: undefined
    }))
  }))
}

watch(
  () => props.licenseId,
  async newLicenseId => {
    if (newLicenseId) {
      await getClasses()
    }
  },
  { immediate: true }
)

const showChangeInstructorModal = async (id: string) => {
  const licenseClass = classes.value.find((c: LicensedClass) => c.id === id)
  const { status, data } = await modal.show(ChangeInstructorModal, {
    licenseId: props.licenseId,
    licenseClass
  })
  if (status === 'ok' && data && data.instructorIds) {
    await client.classes.replaceInstructors({
      id,
      instructorIds: data.instructorIds
    })
    flash.success('Instructor updated')
    await getClasses()
  }
}

const archiveClass = async (id: string, name: string) => {
  const { status } = await modal.show(ConfirmModal, {
    text: 'Teachers on this license will no longer be able to assign activities to this class and students in this class will be unable to view their assignments. This will free up seats on your account, allowing you to invite new students to your classes.',
    prompt: `Are you sure you want to archive the class ${name}?`
  })
  if (status === 'ok') {
    await client.classes.archive({ classId: id })
    flash.success(`Archived class ${name} successfully.`)
    await getClasses()
  }
}

async function paginateCallback({ page }) {
  getClasses(page)
  window.scroll(0, 0)
}
</script>
