<template>
  <form-button class="form-button--secondary" @click="onClick">
    <slot>Cancel</slot>
  </form-button>
</template>

<script>
export default {
  name: 'ModalButtonAction',
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
