import AdminClassView from 'src/modules/classes/views/AdminClassView'
import ClassesView from 'src/modules/classes/views/ClassesRouterView'
import ClassRosterView from 'src/modules/classes/views/ClassRosterView'
import ClassView from 'src/modules/classes/views/ClassView'
import CreateClassView from 'src/modules/classes/views/CreateClassView'
import InstructorClassView from 'src/modules/classes/views/InstructorClassView.vue'
import JoinClassPaymentView from 'src/modules/classes/views/JoinClassPaymentView'
import JoinClassView from 'src/modules/classes/views/JoinClassView'
import StudentClassAssignmentsView from 'src/modules/classes/views/StudentClassAssignmentsView.vue'

export default store => [
  {
    name: 'admin_class',
    path: '/admin/classes/:id',
    component: AdminClassView,
    props: true,
    meta: { title: 'Class', requiresAuth: true }
  },
  {
    name: 'classes',
    path: '/classes',
    component: ClassesView,
    props: route => ({
      isArchived:
        typeof route.query.isArchived === 'string'
          ? route.query.isArchived.toLowerCase() === 'true'
          : route.query.isArchived,
      page: route.query.page ? parseInt(route.query.page) : 1
    }),
    meta: { title: 'Classes', requiresAuth: true }
  },
  {
    name: 'existing_class_roster',
    path: '/classes/:id/roster',
    component: ClassRosterView,
    props: true,
    meta: { title: 'Class Roster', requiresAuth: true }
  },
  {
    name: 'existing_class',
    path: '/classes/:id',
    component: ClassView,
    props: route => ({
      id: route.params.id,
      query: route.query
    }),
    meta: { requiresAuth: true }
  },
  {
    name: 'new_class',
    path: '/classes/new',
    component: CreateClassView,
    meta: { title: 'New Class', requiresAuth: true }
  },
  {
    name: 'instructor_class',
    path: '/instructors/classes/:classId',
    props: true,
    component: InstructorClassView,
    meta: { title: 'Class', requiresAuth: true }
  },
  {
    name: 'join_class_payment',
    path: '/join-class/payment',
    component: JoinClassPaymentView,

    props: route => ({
      classKey: route.query.classKey,
      redirect: route.query.redirect
    }),
    meta: { title: 'Join Class - Payment' }
  },
  {
    name: 'join_class',
    path: '/join-class',
    component: JoinClassView,
    props: route => ({
      joinClassKey: route.query.classKey,
      redirect: route.query.redirect
    }),
    meta: { title: 'Join Class' }
  },
  {
    name: 'student_class_assignments',
    path: '/classes/:classId/students/:studentId/assignments',
    component: StudentClassAssignmentsView,
    props: true,
    meta: { title: 'Student Assignments', requiresAuth: true }
  }
]
