<template>
  <embedded-iframe
    v-if="isIFrameComponent"
    class="activity-frame hidden-print"
    :src="parsedIFrameUrl"
    :config="component.config"
  />
</template>

<script>
import { findVariableById } from '@pi/shared/variables'

const VARIABLE_REGEX = /\[\$([^[\]]+)\]/g

export default {
  name: 'ActivityFrame',
  inject: ['inherited'],
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  computed: {
    component() {
      return this.inherited.component
    },
    isIFrameComponent() {
      return this.component.componentType === 'IFrame'
    },
    parsedIFrameUrl() {
      return (this.component.url || '').replaceAll(
        VARIABLE_REGEX,
        (_, expression) => {
          return (
            findVariableById(expression, this.variableContext.variables)
              ?.value ?? ''
          )
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-frame {
  margin: 16px 0;
}
</style>
