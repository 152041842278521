<template>
  <div v-if="focusState.anchor" ref="root" class="drop-choice__button-group">
    <button-dropdown
      tabindex="-1"
      menuClass="drop-choice__dropdown-item"
      class="drop-choice__move-button"
      :class="{ 'drop-choice__move-button--alone': !removable }"
      v-model:open="menuOpen"
      side
      right
      @click="() => focusState.anchor?.focus()"
    >
      <template #button>
        <icon icon="arrows-up-down-left-right" />
        <span class="sr-only">Move</span>
      </template>
      <dropdown-action
        v-for="(target, index) in filteredTargets"
        :key="target.id"
        :class="{
          'drop-choice__focused-target': focusedTarget === index
        }"
        tabindex="-1"
        @click="() => move(target.id)"
        >{{ target.label }}
      </dropdown-action>
    </button-dropdown>
    <form-button
      unstyled
      tabindex="-1"
      class="drop-choice__remove-button"
      v-if="removable"
      @click="() => move()"
    >
      <icon icon="times" />
      <span class="sr-only">Remove</span>
    </form-button>
  </div>
  <slot :onFocusin="onFocus" :onKeydown="onKeydown" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { computePosition, autoUpdate, offset } from '@floating-ui/dom'

interface Target {
  id: string
  letter: string
  label?: string
}

interface Response {
  [targetId: string]: number
}

interface Props {
  targets: Target[]
  response: Response
}

const emit = defineEmits<{
  move: [{ sourceTarget?: string; destTarget?: string; choice: number }]
}>()
const props = defineProps<Props>()

function move(targetId?: string) {
  if (focusState.value.choice) {
    emit('move', {
      destTarget: targetId,
      choice: focusState.value.choice,
      sourceTarget: focusState.value.target
    })
    if (focusState.value.target) {
      focusState.value = {}
    }
  }
}

const menuOpen = ref(false)
const focusState = ref<{
  anchor?: HTMLElement
  choice?: number
  target?: string
}>({})
const removable = computed(() => !!focusState.value.target)

function onFocus(e: FocusEvent) {
  if (e.target instanceof HTMLElement) {
    const { choice, ref, target, targetType } = e.target.dataset
    if (
      targetType === 'dropdown' ||
      e.target.classList.contains('drop-target--dropdown')
    ) {
      // let the drop down handle the keyboard stuff
      return
    }
    if (choice || ref || target) {
      menuOpen.value = false
      focusState.value.anchor = e.target
    }
    if (choice) {
      focusState.value.choice = parseInt(choice)
      focusState.value.target = undefined
    } else if (ref || target) {
      const targetId = (ref ?? target) as string
      focusState.value.target = targetId
      focusState.value.choice = props.response[targetId]
    }
  }
}

const filteredTargets = computed(() =>
  props.targets.filter(target => target.id !== focusState.value.target)
)
const focusedTarget = ref()

watch(menuOpen, open => {
  if (!open) {
    focusedTarget.value = undefined
  }
})

function onKeydown(e: KeyboardEvent) {
  switch (e.key) {
    case 'Tab': {
      focusState.value = {}
      return
    }
    case 'Enter': {
      e.preventDefault()
      if (menuOpen.value) {
        const target = filteredTargets.value[focusedTarget.value]
        if (target) {
          move(target.id)
          menuOpen.value = false
        }
      } else {
        menuOpen.value = true
        focusedTarget.value = 0
      }
      break
    }
    case 'Escape': {
      e.preventDefault()
      if (menuOpen.value) {
        menuOpen.value = false
      } else {
        move()
      }
      break
    }
    case 'ArrowDown': {
      if (menuOpen.value) {
        focusedTarget.value =
          typeof focusedTarget.value === 'number'
            ? (focusedTarget.value + 1) % filteredTargets.value.length
            : 0
      } else {
        menuOpen.value = true
        focusedTarget.value = 0
      }
      break
    }
    case 'ArrowUp': {
      if (menuOpen.value) {
        focusedTarget.value =
          typeof focusedTarget.value === 'number'
            ? (filteredTargets.value.length + focusedTarget.value - 1) %
              filteredTargets.value.length
            : filteredTargets.value.length - 1
      } else {
        menuOpen.value = true
        focusedTarget.value = filteredTargets.value.length - 1
      }
      break
    }
    default: {
      return
    }
  }
}

async function compute() {
  if (focusState.value.anchor && root.value) {
    const { x, y } = await computePosition(
      focusState.value.anchor,
      root.value,
      {
        placement: 'top-start',
        middleware: [offset(2)]
      }
    )
    if (root.value) {
      Object.assign(root.value.style, {
        left: `${x}px`,
        top: `${y}px`
      })
    }
  }
}

function onWindowClick(e: MouseEvent) {
  if (e.target instanceof HTMLElement) {
    const withinMenu =
      e.target instanceof HTMLElement &&
      (root.value?.contains(e.target) ||
        root.value === e.target ||
        focusState.value?.anchor?.contains(e.target) ||
        focusState.value?.anchor === e.target)
    if (!withinMenu) {
      focusState.value = {}
    }
  }
}

const root = ref<HTMLElement>()
watch(
  () => ({
    anchor: focusState.value.anchor,
    root: root.value,
    response: props.response
  }),
  ({ anchor, root }, _, onCleanup) => {
    let cleanupPopover: () => void
    if (anchor) {
      window.addEventListener('click', onWindowClick)
      if (root) {
        cleanupPopover = autoUpdate(anchor, root, compute)
      }
    }

    onCleanup(() => {
      cleanupPopover?.()
      window.removeEventListener('click', onWindowClick)
    })
  },
  {
    flush: 'post'
  }
)
</script>

<style lang="scss" scoped>
.drop-choice__button-group {
  position: absolute;
  display: flex;
  z-index: 999;
  pointer-events: auto;

  :deep(.drop-choice__move-button),
  .drop-choice__remove-button {
    z-index: 999;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  :deep(.drop-choice__move-button) {
    border-radius: 6px 0px 0px 6px;
  }
  :deep(.drop-choice__move-button--alone) {
    border-radius: 6px;
  }
  .drop-choice__remove-button {
    background-color: $color-warning;
    color: #ffffff;
    border: $color-warning solid 1px;
    border-radius: 0px 6px 6px 0px;
    padding: 0;
    &:focus,
    &:focus-within,
    &:hover,
    &:active {
      background-color: darken($color-warning, 10%);
      color: #ffffff;
      border: darken($color-warning, 10%) solid 2px;
    }
  }
}

.drop-choice__focused-target {
  color: #262626;
  outline: none;
  background-color: #f5f5f5;
  text-decoration: underline;
}
</style>
