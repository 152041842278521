<template>
  <editor-modal
    class="drop-target-menu__modal"
    v-if="isModalShown"
    name="drop area"
    title="Insert Drop Target"
  >
    <async-form class="drop-target-menu__form" @submit="insertDropTarget">
      <h3 class="drop-target-menu__modal-title">
        Drop Target {{ dropTargetLetter }}
      </h3>
      <div class="drop-target-menu__form-columns">
        <div
          class="drop-target-menu__form-column"
          v-if="attributes.display !== 'freeform'"
        >
          <choice-input
            rules="required"
            label="display"
            v-model="attributes.display"
            layout="horizontal"
          >
            <radio-button value="inline"> Inline </radio-button>
            <radio-button value="inline-block"> Block </radio-button>
          </choice-input>
        </div>
      </div>
      <div class="drop-target-menu__form-columns">
        <div class="drop-target-menu__form-column">
          <div>
            <form-label for="label-text">Label (optional)</form-label>
            <text-input id="label-text" v-model="attributes.label" />
          </div>
        </div>
      </div>
      <div class="drop-target-menu__form-columns">
        <div class="drop-target-menu__form-column">
          <form-label for="label-text">Width ({{ units }})</form-label>
          <number-input
            id="label-text"
            :modelValue="isFullWidth ? '' : attributes.width"
            @update:modelValue="width => (attributes.width = width)"
            class="drop-target-menu__width-label"
            :class="{ disabled: isFullWidth }"
            @focus="isFullWidth = false"
          />
        </div>
        <div
          v-if="attributes.display !== 'freeform'"
          class="drop-target-menu__form-column drop-target-menu__width"
        >
          <span>or</span>
          <checkbox
            aria-label="Full Width"
            id="fullWidth"
            v-model="isFullWidth"
            @click.stop
          />
          <label for="fullWidth">Full Width</label>
        </div>
      </div>
      <div class="drop-target-menu__form-columns">
        <div class="drop-target-menu__form-column">
          <checkbox
            aria-label="Fill In the Blank"
            id="fillInTheBlank"
            v-model="attributes.fillInTheBlank"
            @click.stop
            >Fill in the blank</checkbox
          >
        </div>
      </div>
      <div
        class="drop-target-menu__form-columns"
        v-if="
          attributes.display === 'inline-block' ||
          attributes.display === 'freeform'
        "
      >
        <div class="drop-target-menu__form-column">
          <form-label for="height">Height ({{ units }})</form-label>
          <number-input
            id="height"
            v-model="attributes.height"
            class="drop-target-menu__width-label"
          />
        </div>
      </div>
      <div class="drop-target-menu__modal-feedback">
        <form-label for="label-text">Invalid Answer</form-label>
        <multiline-text-input
          id="label-text"
          v-model="attributes.invalidResponse"
        />
      </div>

      <div class="editor-modal__actions">
        <form-button type="submit">Insert</form-button>
        <form-button link @click="cancel">Cancel</form-button>
        <span class="drop-target-menu__actions-spacer"></span>
        <form-button
          v-if="attributes.id"
          class="drop-target-menu__delete-button"
          destructive
          @click="deleteTarget"
          >Delete</form-button
        >
      </div>
    </async-form>
  </editor-modal>
</template>

<script setup>
import EditorModal from '../components/EditorModal'
import { ref, onMounted, computed, inject } from 'vue'
import { indexToLetter } from 'src/shared/utils/index-to-letter.js'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'

const props = defineProps({
  editor: {
    type: Object,
    required: true
  }
})
const $modal = inject('$modal')

const attributes = ref({})

const isModalShown = ref(false)

const isFullWidth = computed({
  get: () => attributes.value.width === '100%',
  set: val => {
    if (val !== isFullWidth.value) {
      attributes.value.width = val ? '100%' : 40
    }
  }
})

const units = computed(() =>
  attributes.value.display === 'freeform' ? '%' : 'px'
)

const openModal = () => {
  const attrs = props.editor.isActive('dropTarget')
    ? props.editor.getAttributes('dropTarget')
    : {}
  const display =
    attrs.display ??
    (props.editor.isActive('dropTargetContext') ? 'freeform' : 'inline')

  attributes.value = {
    ...attrs,
    ...(display === 'freeform' && {
      positionTop: attrs.positionTop ?? 50,
      positionRight: attrs.positionRight ?? 50
    }),
    display,
    fillInTheBlank: attrs.targetType === 'dropdown',
    width:
      attrs.width === '100%'
        ? attrs.width
        : parseFloat(attrs.width) || (display === 'freeform' ? 50 : 40),
    height: parseFloat(attrs.height) || (display == 'freeform' ? 50 : 40)
  }

  isModalShown.value = true
}

onMounted(() => {
  if (props.editor.storage['dropTarget']) {
    // eslint-disable-next-line vue/no-mutating-props
    props.editor.storage['dropTarget'].onInsertDropTarget = openModal
  }
})

const dropTargetLetter = computed(() => {
  let dropTargetIndex
  if (attributes.value.id) {
    dropTargetIndex = props.editor.storage['dropTarget'].areas?.value.findIndex(
      area => area === attributes.value.id
    )
  } else {
    dropTargetIndex = props.editor.storage['dropTarget'].areas?.value.length
  }

  return indexToLetter(dropTargetIndex)
})

const insertDropTarget = e => {
  props.editor.commands.setDropTarget({
    ...attributes.value,
    targetType: attributes.value.fillInTheBlank ? 'dropdown' : 'drag-drop',
    width: isFullWidth.value ? '100%' : attributes.value.width,
    height: attributes.value.height
  })
  isModalShown.value = false
  e.done()
}

const cancel = e => {
  isModalShown.value = false
  props.editor.commands.focus()
}

const deleteTarget = async e => {
  const { status } = await $modal.show(ConfirmModal, {
    text: ``,
    prompt: 'Are you sure you wish to deleted this drop target?'
  })
  if (status === 'ok') {
    props.editor.commands.deleteSelection('drop-target')
    isModalShown.value = false
  }
}
</script>

<style lang="scss" scoped>
.drop-target-menu {
  &__form {
    width: 500px;
    margin: 0 !important;
  }

  &__form-columns {
    display: flex;
    flex-wrap: wrap;
  }

  &__form-column {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  :deep() &__formula-input {
    width: 300px;
  }

  &__preview {
    max-width: 100%;
    max-height: 100px;
  }

  &__error {
    margin: 16px 0 0 0;
    color: $color-error;
  }

  &__modal-title {
    padding: 0;
    margin: 0 0 15px 0px;
  }

  &__modal-feedback {
    padding-top: 15px;
  }

  &__width {
    display: flex;
    padding-top: 35px;
    align-items: center;

    & > div {
      margin-left: 15px;
    }

    & > label {
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  &__width-label {
    &.disabled {
      :deep() .form-control {
        color: $light-grey;
      }
    }
  }

  &__delete-button {
    margin-left: 0;
  }

  &__actions-spacer {
    flex: 1;
  }
}
</style>
