<template>
  <div class="activity-row" data-testid="activity-row">
    <div>
      <div class="activity-row__thumbnail">
        <img role="presentation" :src="thumbnail" />
      </div>
    </div>
    <div class="activity-row__details">
      <span>
        <p class="activity-row__name">{{ activity.name }}</p>
        <p class="activity-row__update">
          Last Updated: {{ $format.date(activity.updatedAt, 'MM/dd/yyyy') }}
        </p>
      </span>
      <div class="activity-row__chip">
        <span
          class="activity-row__date"
          v-if="dateText && activity.libraryId === 'pivot'"
        >
          {{ dateText }}
        </span>
        <span
          v-for="(category, index) in activity.flags"
          :key="index"
          class="activity-row__flag-chip"
          >{{ category }}</span
        >
        <span v-if="activity.isArchived" class="activity-row__archived">
          Archived
        </span>
        <span
          v-else-if="activity.isFree && showFreeTag"
          class="activity-row__free"
        >
          Free
        </span>
      </div>
      <div class="activity-row__autograde" v-if="activity.autogradePercentage">
        {{ autogradePercentage }}% Autograded
      </div>
    </div>
    <div class="activity-row__actions">
      <slot />
    </div>
  </div>
</template>

<script>
import * as Vue from 'vue'
import defaultThumbnail from 'src/assets/img/activity-card/pivot-interactives-logo-color.png'
import { differenceInDays } from 'date-fns'
export default {
  name: 'ActivityRow',
  provide() {
    return {
      activityRow: Vue.computed(() => ({
        activity: this.activity
      }))
    }
  },
  props: {
    activity: {
      type: Object,
      required: true
    },
    showFreeTag: {
      type: Boolean
    }
  },
  computed: {
    thumbnail() {
      if (this.activity.thumbnail) {
        return this.activity.thumbnail
      }
      return defaultThumbnail
    },
    autogradePercentage() {
      return Math.round(this.activity.autogradePercentage * 100)
    },
    dateText() {
      if (
        differenceInDays(new Date(), new Date(this.activity.deployDate)) < 30
      ) {
        return this.activity.hasReplacementRelationship ? 'Revised' : 'New'
      } else if (
        this.activity.latestChangelogEntryDate &&
        differenceInDays(
          new Date(),
          new Date(this.activity.latestChangelogEntryDate)
        ) < 60
      ) {
        return 'Updated'
      } else {
        return undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding: 10px 0px;
  border-bottom: 2px solid #80808080;
}

.activity-row__details {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px;
  justify-content: space-between;
}
.activity-row__thumbnail {
  width: 200px;
  padding-bottom: 56.25%;
  justify-content: center;
  background: #efefef;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  img {
    position: absolute;
    height: 100%;
    margin: auto;
  }
}
.activity-row__name {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 1px 0;
  overflow: hidden;
  display: inline;
  vertical-align: baseline;
}

.activity-row__update {
  font-size: 12px;
  font-weight: normal;
  margin: 0 0 6px 0;
  color: $color-muted;
  font-style: italic;
  display: inline;
  vertical-align: baseline;
}

.activity-row__free {
  line-height: 100%;
  color: white;
  background-color: $pink;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
}

.activity-row__date {
  line-height: 100%;
  color: white;
  background-color: $plum;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
}

.activity-row__archived {
  line-height: 100%;
  color: white;
  background-color: $color-warning;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
}

.activity-row__autograde {
  margin: 0 0 8px 0;
}
.activity-row__actions {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
}
.activity-row__chip {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
}

.activity-row__flag-chip {
  background-color: #109cbf;
  color: #ffffff;
  border-radius: 20px;
  line-height: 100%;
  padding: 5px 10px 5px 10px;
}
</style>
