<template>
  <view-container>
    <view-header>
      <view-title>Assignment</view-title>
    </view-header>

    <div class="form-two-column" v-if="assignment">
      <form-group>
        <form-label for="name">Activity Name</form-label>
        <static-value id="name">
          {{ (assignment.activity || {}).name }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="startDate">Start Date</form-label>
        <static-value id="startDate">
          {{ $format.date(assignment.startDate, 'MM/dd/yyyy') }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="endDate">End Date</form-label>
        <static-value id="endDate">
          {{ $format.date(assignment.endDate, 'MM/dd/yyyy') }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="responses"> Responses </form-label>
        <list v-if="responses.length > 0" id="responses" :items="responses">
          <list-column
            :title="isGroupAssignment ? 'Group Name' : 'Student Name'"
            v-slot="{ owner, groupName }"
            >{{
              isGroupAssignment
                ? groupName
                : `${owner.firstName} ${owner.lastName}`
            }}</list-column
          >
          <list-column
            v-slot="{ studentUpdatedAt, instructorUpdatedAt }"
            title="Updated At"
          >
            {{
              $format.date(
                getUpdatedAt(studentUpdatedAt, instructorUpdatedAt),
                'MM/dd/yyyy'
              )
            }}
          </list-column>
          <list-column v-slot="{ totalScore }" title="Score">
            {{ formatPercent(grade(totalScore)) }}
          </list-column>
          <list-column title="Actions"></list-column>
        </list>
        <static-value v-else id="responses">
          This assignment has no responses yet.
        </static-value>
      </form-group>
    </div>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'
export default {
  name: 'AssignmentView',
  props: ['id'],
  data() {
    return {
      assignment: null,
      responses: []
    }
  },
  async created() {
    await this.load()
  },
  computed: {
    isGroupAssignment() {
      return this.assignment.assignedTo === 'groups'
    }
  },
  methods: {
    load() {
      return Promise.all([this.getAssignment(this.id), this.loadResponses()])
    },
    async getAssignment() {
      this.assignment = await client.assignments.get({
        assignmentId: this.id
      })
    },
    grade(score) {
      const total = this.assignment.activity.totalPointValue
      return total > 0 ? score / total : 0
    },
    formatPercent(value) {
      if (typeof value !== 'number') return value
      return `${(value * 100).toPrecision(4)} %`
    },
    getUpdatedAt(studentUpdatedAt, instructorUpdatedAt) {
      if (studentUpdatedAt && instructorUpdatedAt) {
        return studentUpdatedAt > instructorUpdatedAt
          ? studentUpdatedAt
          : instructorUpdatedAt
      } else {
        return instructorUpdatedAt ? instructorUpdatedAt : studentUpdatedAt
      }
    },
    async loadResponses() {
      const result = await client.assignments.getAllResponses({
        assignmentId: this.id
      })
      this.responses = result.page
    }
  },
  watch: {
    id: {
      handler() {
        return this.load()
      }
    }
  }
}
</script>

<style scoped>
.form-label {
  width: 140px;
}
</style>
