<template>
  <td :class="columnClass">
    <template v-if="isNumericColumn">
      <data-grid-calculation
        v-if="calculation"
        :calculation="calculation"
        :column="column"
        :readonly="readonly"
        :columnIndex="columnIndex"
        :index="rowIndex"
        :nav-row-index="navRowIndex"
        :variable-context="variableContext"
      />
      <div v-if="lastRow && !readonly">
        <button-dropdown
          link
          ref="dropdown"
          tabindex="-1"
          :aria-label="`row ${navRowIndex + 1} calculations menu`"
          data-tablenav
        >
          <template #button>
            <span>Calculate</span>&nbsp;
            <icon icon="plus" />
          </template>

          <template #default>
            <dropdown-action @click="addCalculation(columnIndex, 'total')">
              Total
            </dropdown-action>
            <dropdown-action @click="addCalculation(columnIndex, 'median')">
              Median
            </dropdown-action>
            <dropdown-action @click="addCalculation(columnIndex, 'stdev')">
              Standard Deviation
            </dropdown-action>
            <dropdown-action @click="addCalculation(columnIndex, 'mean')">
              Mean
            </dropdown-action>
          </template>
        </button-dropdown>
      </div>
    </template>
  </td>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue'
import * as Y from 'yjs'
import DataGridCalculation from './DataGridCalculation'

const gridActions = inject('$gridActions')
const dropdown = ref(null)
const props = defineProps({
  column: {
    type: Y.Map,
    required: true
  },
  columnIndex: {
    type: Number,
    required: true
  },
  rowIndex: {
    type: Number,
    required: true
  },
  navRowIndex: {
    type: Number,
    required: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  lastColumn: {
    type: Boolean,
    default: false
  },
  variableContext: {
    type: Object,
    default: undefined,
    required: false
  }
})

let maxRows = computed(() => props.column.get('calculations')?.length ?? 0)
let calculation = computed(() => {
  const calculations = props.column.get('calculations')?.toArray() ?? []
  return calculations[props.rowIndex]
})

const lastRow = computed(() => {
  return props.rowIndex === maxRows.value
})

const isNumericColumn = computed(() => {
  return !props.column.get('allowText')
})

const columnClass = computed(() => {
  let cls = 'data-grid__calculation-cell'
  if (props.columnIndex === 0) {
    cls += ' data-grid__calculation-cell--first'
  }
  if (props.lastColumn) {
    cls += ' data-grid__calculation-cell--last'
  }
  if ((!calculation.value && !lastRow.value) || !isNumericColumn.value) {
    cls += ' data-grid__calculation-cell--empty'
  }
  return cls
})

const addCalculation = (columnIndex, type) => {
  gridActions.insertColumnCalculation(columnIndex, type)
}
</script>

<style lang="scss" scoped>
.data-grid__calculation-cell {
  line-height: $grid-row-small-height;
  padding: 0 0 0 8px;

  &--empty {
    background-color: transparent;
    border-bottom: none;
    border-left: none;
    border-right: none;

    &.data-grid__calculation-cell--first {
      border-left: none;
    }

    &.data-grid__calculation-cell--last {
      border-right: none;
    }

    .data-grid__calculation-cell--empty ~ & {
      border-left: none;
    }
  }
}
</style>
