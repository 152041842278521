import { create, all } from 'mathjs'
import { AccuracyTypeValue } from '@pi/types'

const math = create(all)

math.config({
  number: 'BigNumber',
  precision: 64
})

type ReplacmentMathJs = {
  [n: string]: any
}
const replacements: ReplacmentMathJs = {}

// configure the default angles to degrees
const config = {
  angles: 'deg'
}

// convert the input angle from radians to degrees for trig functions
const fns1Dict = { sin: math.sin, cos: math.cos, tan: math.tan }
const fns1: (keyof typeof fns1Dict)[] = ['sin', 'cos', 'tan']
fns1.forEach(name => {
  const fn = fns1Dict[name]

  const fnNumber = function (x: number): any {
    if (config.angles === 'deg') {
      return fn(math.multiply(math.divide(x, 180), math.pi))
    }
    return x
  }

  // create a typed-function which checks the input types
  replacements[name] = math.typed(name, {
    BigNumber: fnNumber,
    number: fnNumber,
    'Array | Matrix': function (x) {
      return math.map(x, fnNumber)
    }
  })
})

// 'log' is used to evaluate the natural log (ln) in javascript/math.js - this evaluates 'ln' properly
replacements['ln'] = math.log

// 'log10' is used to evaluate log in javascript/math.js - this evaluates 'log' properly
replacements['log'] = math.log10

// import all replacements into math.js, override existing trig functions
math.import(replacements, { override: true })

export function isWithinTolerance(
  num1: number,
  range = 0,
  accuracyType: AccuracyTypeValue,
  num2: number
): boolean {
  if (range === 0) {
    return num1 === num2
  }
  let tolerance = 0
  switch (accuracyType) {
    default:
      throw new Error(`Unknown accuracy type: ${accuracyType}`)
    case AccuracyTypeValue.Percent: {
      const fraction = range / 100
      tolerance = fraction * Math.abs(num2)
      break
    }
    case AccuracyTypeValue.Absolute:
      tolerance = range
      break
    case AccuracyTypeValue.None:
      tolerance = 0
      break
  }
  const highRange = num2 + tolerance
  const lowRange = num2 - tolerance
  return lowRange <= num1 && num1 <= highRange
}

export default math
