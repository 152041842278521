<template>
  <auth-card header="Create Account">
    <div class="card-content">
      <button-link secondary class="mr-4 button-content" :to="createRoute">
        Create Account With Email and Password
      </button-link>
      <oauth-selection
        :allowed="['google', 'azure']"
        @success="onOauthSuccess"
        prefix="Sign up with"
      >
      </oauth-selection>
    </div>
  </auth-card>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import OauthSelection from './OauthSelection.vue'
import AuthCard from './AuthCard.vue'
import client from 'src/shared/api-client'

const router = useRouter()

const props = defineProps({
  student: {
    type: Boolean,
    default: false
  }
})
const createRoute = computed(() => ({
  ...router.currentRoute.value,
  params: { ...router.currentRoute.value.params, step: 'sign-up' }
}))

async function onOauthSuccess() {
  if (props.student) {
    await client.auth.studentSignup()
    router.push({
      ...router.currentRoute.value,
      params: { ...router.currentRoute.value.params, step: 'redirect' }
    })
  } else {
    router.push(createRoute.value)
  }
}
</script>
<style lang="scss" scoped>
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.button-content {
  width: 300px;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
}
.title {
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}
</style>
