import { ActivitySection, Component } from '@pi/api-types'

export interface NumberedComponent extends Component {
  number?: number
}
export type NumberedSection = Omit<ActivitySection, 'components'> & {
  components: NumberedComponent[]
}

const QUESTION_COMPONENTS = [
  'GridGraphQuestion',
  'GridQuestion',
  'OpenEndedQuestion',
  'MultipleChoiceQuestion',
  'GeneralInstruction',
  'NumericalQuestion',
  'DragDropQuestion'
]

export const getNumberedSection = (
  section: ActivitySection
): NumberedSection => {
  let n = 1
  const components = section.components.map(component => {
    if (
      QUESTION_COMPONENTS.includes(component.componentType) ||
      component.componentType === 'SplitView'
    ) {
      if (component.componentType === 'SplitView') {
        const leftContent = component.leftContent?.map(c => {
          if (QUESTION_COMPONENTS.includes(c.componentType)) {
            return {
              ...c,
              questionNumber: n++
            }
          }
          return c
        })
        const rightContent = component.rightContent?.map(c => {
          if (QUESTION_COMPONENTS.includes(c.componentType)) {
            return {
              ...c,
              questionNumber: n++
            }
          }
          return c
        })
        return {
          ...component,
          leftContent,
          rightContent
        }
      }

      return {
        ...component,
        questionNumber: n++
      }
    }
    return component
  })
  return {
    ...section,
    components
  }
}

export const getNumberedSections = (
  sections: ActivitySection[]
): NumberedSection[] => {
  return sections.map(getNumberedSection)
}
