<template>
  <browse-instructors ref="browseInstructors" :query="computedQuery">
    <view-container>
      <view-header>
        <view-title>Vet Instructors</view-title>
      </view-header>
      <browse-instructors-layout>
        <template #sidebar>
          <browse-sidebar>
            <sidebar-filter>
              <vet-status-filter />
            </sidebar-filter>
          </browse-sidebar>
        </template>
        <template #search>
          <instructors-search />
        </template>
        <template #summary>
          <browse-summary />
        </template>
        <template #instructors>
          <instructors-list />
        </template>
      </browse-instructors-layout>
    </view-container>
  </browse-instructors>
</template>

<script>
import BrowseInstructors from '../components/instructors/BrowseInstructors'
import BrowseInstructorsLayout from '../components/instructors/BrowseInstructorsLayout'
import InstructorsSearch from '../components/instructors/InstructorsSearch'
import BrowseSidebar from 'src/shared/components/BrowseSidebar'
import BrowseSummary from '../components/instructors/BrowseSummary'
import InstructorsList from '../components/instructors/InstructorsList'
import VetStatusFilter from '../components/instructors/VetStatusFilter'
import SidebarFilter from '../components/instructors/SidebarFilter'

export default {
  name: 'VetInstructorsView',
  components: {
    BrowseInstructors,
    BrowseInstructorsLayout,
    BrowseSidebar,
    BrowseSummary,
    InstructorsSearch,
    InstructorsList,
    VetStatusFilter,
    SidebarFilter
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  computed: {
    computedQuery() {
      return {
        page: parseInt(this.query.page) || 1,
        sort: this.query.sort || 'updatedAt',
        dir: this.query.dir || 'desc',
        q: this.query.q || '',
        vetStatus: Array.isArray(this.query.vetStatus)
          ? this.query.vetStatus
          : this.query.vetStatus !== 'all'
          ? ['unvetted', 'prevetted']
          : [],
        pending: true
      }
    }
  }
}
</script>
