import * as Vue from 'vue'
import { Editor } from '@tiptap/vue-3'
import { migrate, getExtensions } from './utils'

export default function useEditor(props) {
  const editor = Vue.shallowRef()
  const content = migrate(
    Vue.unref(props.text),
    props.inline ? Vue.unref(props.inline) : false
  )
  function initEditor() {
    editor.value?.destroy()
    editor.value = new Editor({
      content,
      editable: !Vue.unref(props.readonly),

      editorProps: {
        attributes: Vue.unref(props.editorAttributes)
      },
      extensions: getExtensions({
        includeHistory: !Vue.unref(props.fragment),
        openLinksOnClick: Vue.unref(props.readonly),
        variableContext: props.variableContext,
        fragment: Vue.unref(props.fragment),
        includeDropTarget: !!props.dropTargets,
        noupload: Vue.unref(props.noupload),
        inline: Vue.unref(props.inline) ?? false,
        video: Vue.unref(props.video) ?? false,
        dropChoices: props.dropChoices,
        onTipActivated: props.onTipActivated ?? null,
        viewAsStudent: props.viewAsStudent,
        toolTip: props.toolTip
      }),
      onUpdate(data) {
        const json = data.editor.getJSON()
        const jsonStr = JSON.stringify(json)
        if (jsonStr !== JSON.stringify(props.text)) {
          props.onUpdate?.(data)
        }
      },
      onCreate(data) {
        props.onCreate?.(data)
      }
    })
  }

  Vue.onMounted(() => {
    initEditor()
  })
  Vue.onBeforeUnmount(() => {
    editor.value?.destroy()
  })
  if (Vue.isRef(props.fragment)) {
    Vue.watch(props.fragment, fragment => {
      initEditor()
    })
  }

  if (Vue.isRef(props.text)) {
    Vue.watch(props.text, text => {
      const newContent = migrate(
        text,
        props.inline ? Vue.unref(props.inline) : false
      )
      if (
        JSON.stringify(newContent) !== JSON.stringify(editor.value.getJSON())
      ) {
        editor.value.commands.setContent(newContent, false)
        // Wait a tick for the internal state to update.
        Vue.nextTick(() => {
          props.onReset?.({ editor: editor.value })
        })
      }
    })
  }

  if (Vue.isRef(props.readonly)) {
    Vue.watch(props.readonly, readonly => {
      editor.value.setEditable(!readonly)
      // TODO change open on click in Link extension
    })
  }

  if (Vue.isRef(props.editorAttributes)) {
    Vue.watch(props.editorAttributes, attrs => {
      editor.value.setOptions({ editorProps: { attributes: attrs } })
    })
  }

  return editor
}
