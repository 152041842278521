<template>
  <view-container normal>
    <view-header>
      <view-title>LTI Platform Registration</view-title>
    </view-header>
    <h2>Select Your Platform</h2>
    <router-link :to="{ name: 'lti_canvas_registration' }">
      <card>
        <card-title>Canvas</card-title>
      </card>
    </router-link>
    <router-link :to="{ name: 'lti_schoology_registration' }">
      <card>
        <card-title>Schoology</card-title>
      </card>
    </router-link>
    <hyper-link :to="{ name: 'lti_blackboard_registration' }">
      <card>
        <card-title>Blackboard Learn</card-title>
      </card>
    </hyper-link>
    <router-link :to="{ name: 'lti_d2l_registration' }">
      <card>
        <card-title>D2L Brightspace</card-title>
      </card>
    </router-link>
    <router-link :to="{ name: 'lti_other_registration' }">
      <card>
        <card-title>Other Platform</card-title>
      </card>
    </router-link>
  </view-container>
</template>

<script>
export default {
  name: 'LtiPlatformRegistrationView'
}
</script>
