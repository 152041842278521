export default (Vue, store) => {
  Vue.http.options.credentials = true

  Vue.http.interceptors.push(request => {
    // Add feature flags to pivot requests.
    if (request.url.includes(PI_API_URL)) {
      const enabledFeatureFlags = store.getters['features/enabled'].join(',')
      const disabledFeatureFlags = store.getters['features/disabled'].join(',')
      request.params = {
        ...request.params,
        ...(enabledFeatureFlags.length ? { _ff: enabledFeatureFlags } : {}),
        ...(disabledFeatureFlags.length ? { _xff: disabledFeatureFlags } : {})
      }
    }

    return response => {
      // Save the request on failed responses for the error boundary.
      if (!response.ok) {
        response.request = request
      }
    }
  })
}
