<template>
  <div class="chips" v-if="query.q || computedCategories.length > 0">
    <chip
      v-for="(category, index) in computedCategories"
      :key="index"
      :index="index"
      :truncate="20"
      :label="`${category[0]}`"
      :removeable="true"
      @remove="removeCategory(category[1])"
    />
    <chip
      v-if="query.q"
      :key="computedCategories.length"
      :index="computedCategories.length"
      :label="searchChipLabelText"
      :truncate="20"
      :removeable="true"
      @remove="removeSearch"
    />
    <form-button tertiary @click="clearAll">Clear All</form-button>
  </div>
</template>
<script setup>
import { computed, toRefs } from 'vue'
import useCategoryFilters from './filters/use-category-filters'

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
})

const { modelValue: query } = toRefs(props)
const emit = defineEmits(['update:modelValue'])

const searchChipLabelText = computed(() => {
  return `Search "${query.value.q}"`
})
const searchTree = function (nodes, slugs) {
  const results = []
  for (const node of nodes) {
    if (slugs.includes(node.slug)) {
      results.push([node.name, node.slug])
    } else {
      const children = node.children ?? []
      if (children.length > 0) {
        results.push(...searchTree(node.children, slugs))
      }
    }
  }
  return results
}

const { filters } = useCategoryFilters()

const isArchivedMap = {
  true: [['Archived', 'archived']],
  false: [['Unarchived', 'unarchived']],
  both: [
    ['Archived', 'archived'],
    ['Unarchived', 'unarchived']
  ],
  none: []
}

const isFreeMap = {
  true: [['Free', 'free']],
  false: [['Paid', 'paid']],
  both: [
    ['Free', 'free'],
    ['Paid', 'paid']
  ],
  none: []
}

const computedCategories = computed(() => {
  return [
    ...Object.entries(query.value.categories ?? {}).flatMap(([slug, list]) => {
      const tree = Array.isArray(filters.value[slug])
        ? filters.value[slug]
        : filters.value[slug]?.children ?? []
      return searchTree(tree, list)
    }),
    query.value.autograde && [
      `Autograde ${query.value.autograde}%`,
      'autograde'
    ],
    ...isFreeMap[query.value.isFree ?? 'none'],
    ...isArchivedMap[query.value.isArchived ?? 'none']
  ].filter(Boolean)
})

const removeCategory = slug => {
  const updatedQuery = {
    ...props.modelValue,
    page: 1,
    categories: Object.fromEntries(
      Object.entries(props.modelValue.categories).map(([key, value]) => [
        key,
        value.slice()
      ])
    )
  }
  switch (slug) {
    case 'free': {
      updatedQuery.isFree = updatedQuery.isFree === 'both' ? 'false' : undefined
      break
    }
    case 'paid': {
      updatedQuery.isFree = updatedQuery.isFree === 'both' ? 'true' : undefined
      break
    }
    case 'archived': {
      updatedQuery.isArchived =
        updatedQuery.isArchived === 'both' ? 'false' : undefined
      break
    }
    case 'unarchived': {
      updatedQuery.isArchived =
        updatedQuery.isArchived === 'both' ? 'true' : undefined
      break
    }
    case 'autograde': {
      updatedQuery.autograde = undefined
      break
    }
    default:
      for (const list of Object.values(updatedQuery.categories)) {
        const index = list.findIndex(list => list === slug)
        if (index >= 0) {
          list.splice(index, 1)
        }
      }
      break
  }

  emit('update:modelValue', updatedQuery)
}
const removeSearch = () => {
  const updatedQuery = {
    ...props.modelValue,
    page: '1',
    q: undefined
  }
  emit('update:modelValue', updatedQuery)
}

const clearAll = () => {
  const updatedQuery = {
    ...props.modelValue,
    categories: Object.fromEntries(
      Object.entries(props.modelValue.categories).map(([key, value]) => [
        key,
        []
      ])
    ),
    page: '1',
    q: undefined,
    isFree: undefined,
    isArchived: undefined,
    autograde: undefined
  }
  emit('update:modelValue', updatedQuery)
}
</script>
<style lang="scss" scoped>
.chips ::v-deep(.chip span) {
  background-color: $teal;
}
</style>
