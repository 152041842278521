import { ActivitySection, GetActivityResponse } from '@pi/api-types'
import { generateObjectId } from 'src/shared/utils/objectId'

const LEFT_COMPONENT_TYPES = [
  'EmbeddedInstance',
  'StudentInstance',
  'InstructorInstance',
  'IFrame',
  'PhetIOSim',
  'PhetIOSimulation'
]

export const activityCanMigrateToSplitView = (
  activity: GetActivityResponse
): boolean => {
  return activity.sections.some(
    section =>
      section.components.some(c =>
        LEFT_COMPONENT_TYPES.includes(c.componentType)
      ) && !section.components.some(c => c.componentType === 'SplitView')
  )
}

export const migrateActivityToSplitView = async (
  activity: GetActivityResponse
): Promise<GetActivityResponse> => {
  return new Promise(resolve => {
    const updatedSections = activity.sections.map(section => {
      if (
        section.components.some(c =>
          LEFT_COMPONENT_TYPES.includes(c.componentType)
        )
      ) {
        return moveSectionIntoSplitView(section)
      }
      return section
    })
    activity.sections = updatedSections
    activity.splitViewComponentEnabled = true
    return resolve(activity)
  })
}

const moveSectionIntoSplitView = (
  section: ActivitySection
): ActivitySection => {
  const leftContent = section.components.filter(c =>
    LEFT_COMPONENT_TYPES.includes(c.componentType)
  )
  const rightContent = section.components.filter(
    c => !LEFT_COMPONENT_TYPES.includes(c.componentType)
  )
  const newComponents = [
    {
      _id: generateObjectId(),
      componentType: 'SplitView',
      leftContent,
      rightContent
    }
  ]
  return {
    ...section,
    components: newComponents
  }
}
