<template>
  <loading-container :loading="isLoading">
    <div v-if="hasData">
      <onboarding-overview :license-id="licenseId" />
      <dashboard-panel>
        <dashboard-collapse-toggle>Usage Overview </dashboard-collapse-toggle>
        <collapse-content>
          <div class="overview">
            <div class="overview-left">
              <p class="overview-context">
                Your snapshot to the educational growth of your students. Learn
                not just if - but HOW - your students are using Pivot
                Interactives. To learn more about the individual usage of a
                particular teacher, head over to your
                <hyper-link :to="{ name: 'license', params: { tab: 'usage' } }"
                  >Usage tab</hyper-link
                >.
              </p>
              <dashboard-circle
                :value="license?.seatsUsed ?? 0"
                label="Active Student Accounts"
                :description="`Total active student accounts of ${license.maxSeats} available.`"
                color="purple"
              />
            </div>
            <div class="overview-right">
              <dashboard-circle
                :value="stats?.assignments ?? 0"
                label="Total Assignments"
                :description="`Total active learning assignments students have completed this year.`"
                color="green"
                class="stat-circle"
              />
              <dashboard-circle
                :value="stats?.phenomena ?? 0"
                label="Phenomena-based Learning Opportunities"
                :description="`Total number of times students have interacted with phenomena-based learning opportunities.`"
                color="green"
              />
            </div>
            <div class="overview-right">
              <dashboard-circle
                :value="stats?.autograded ?? 0"
                label="Automatic Feedback"
                :description="`Total autograded questions students have completed with continuous personalized feedback.`"
                color="green"
              />
              <dashboard-circle
                :value="stats?.sciencePractices ?? 0"
                label="Embedded Science and Engineering Practices"
                :description="`Total number of times students have engaged with science and engineering practices in Pivot Interactives.`"
                color="green"
              />
            </div>
          </div>
        </collapse-content>
      </dashboard-panel>
    </div>
    <dashboard-panel v-else>
      <dashboard-panel-header
        >Welcome To Pivot Interactives!
      </dashboard-panel-header>
      <div>
        <p class="invitation-message">
          It looks like you haven't invited any teachers yet. Once you add
          teachers to the platform, you will be able to see more metrics about
          teacher and student performance.
        </p>
        <div class="invitation-buttons">
          <form-button class="send-invitations-button" @click="addMembers"
            >Send Invitations</form-button
          >
          <button-link :to="{ name: 'about' }" secondary>
            Learn More</button-link
          >
        </div>
      </div>
    </dashboard-panel>
  </loading-container>
</template>

<script setup>
import { ref, inject, computed, onBeforeMount } from 'vue'

import LicenseAddMembersModal from 'src/shared/components/modals/LicenseAddMembersModal'
import { useStore } from 'vuex'

import DashboardPanel from 'src/shared/components/DashboardPanel.vue'
import DashboardPanelHeader from 'src/shared/components/DashboardPanelHeader.vue'
import DashboardCollapseToggle from 'src/shared/components/DashboardCollapseToggle.vue'
import CollapseContent from 'src/shared/components/global/CollapseContent.vue'
import DashboardCircle from './DashboardCircle.vue'
import OnboardingOverview from './OnboardingOverview.vue'
import client from 'src/shared/api-client.ts'

const store = useStore()

const license = ref()
const stats = ref()
const $modal = inject('$modal')
const props = defineProps({
  id: {
    type: String,
    required: false
  }
})

const isLoading = computed(() => !license.value)
const isAdmin = computed(
  () => store.getters.isAdmin || store.getters.isSalesAdmin
)
const siteLicense = computed(() => store.state.auth.user.siteLicense)

const licenseId = computed(() => {
  return props.id || (siteLicense.value && siteLicense.value.id)
})

const hasData = computed(
  () =>
    isAdmin.value ||
    (license.value?.members.length ?? 0) +
      (license.value?.invitations.length ?? 0) >
      1
)

const getLicense = async () => {
  const licenseRequest = await client.licenses.get({ id: licenseId.value })
  license.value = licenseRequest
}

const getStudentStats = async () => {
  const studentStatsRequest = await client.licenses.getStudentOverviewStats({
    id: licenseId.value
  })
  stats.value = studentStatsRequest
}

const addMembers = async () => {
  try {
    await $modal.show(LicenseAddMembersModal, {
      license: license,
      isAdmin: isAdmin.value
    })
  } finally {
    await getLicense()
  }
}

onBeforeMount(async () => {
  await getLicense()
  await getStudentStats()
})
</script>

<style lang="scss" scoped>
.overview {
  display: flex;
  justify-content: space-between;
}
.overview-left {
  width: 30%;
}
.overview-right {
  width: 30%;
  padding-right: 15px;
}
.overview-context {
  margin: 0;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 20px;
  width: 100% !important;
}
.context-message {
  margin: 0;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 20px;
}
.invitation-message {
  margin: 0;
  font-family: Lato;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: $color-success;
  padding-bottom: 20px;
  width: 65%;
}

.send-invitations-button {
  margin-right: 10px;
}

.stat-circle {
  margin-bottom: 15px;
}

.overview-context-emphasis {
  font-weight: bold;
  font-style: italic;
}
</style>
