<template>
  <modal>
    <modal-header>Unlock Sections</modal-header>
    <modal-body>
      {{ message }}
    </modal-body>
    <modal-actions>
      <modal-button-status status="yes">Yes, Unlock</modal-button-status>
      <modal-button-status status="no">No</modal-button-status>
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonStatus
} from 'src/shared/components/modals/components'

export default {
  name: 'UnlockSectionsConfirmationModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonStatus
  },
  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>
