<template>
  <browse-users ref="browseUsers" :query="computedQuery">
    <view-container>
      <view-header>
        <view-title>Manage Users</view-title>
      </view-header>
      <browse-users-layout>
        <template #sidebar>
          <browse-sidebar>
            <sidebar-filter>
              <type-filter />
              <role-filter />
              <disable-reason-filter />
            </sidebar-filter>
          </browse-sidebar>
        </template>
        <template #search>
          <users-search />
        </template>
        <template #summary>
          <browse-summary />
        </template>
        <template #users>
          <users-list />
        </template>
      </browse-users-layout>
    </view-container>
  </browse-users>
</template>

<script>
import BrowseUsers from '../components/users/BrowseUsers'
import BrowseUsersLayout from '../components/users/BrowseUsersLayout'
import UsersSearch from '../components/users/UsersSearch'
import BrowseSidebar from 'src/shared/components/BrowseSidebar'
import BrowseSummary from '../components/users/BrowseSummary'
import UsersList from '../components/users/UsersList'
import SidebarFilter from '../components/instructors/SidebarFilter'
import TypeFilter from '../components/users/TypeFilter'
import RoleFilter from '../components/users/RoleFilter'
import DisableReasonFilter from '../components/users/DisableReasonFilter.vue'

export default {
  name: 'ManageUsersView',
  components: {
    BrowseUsers,
    BrowseUsersLayout,
    BrowseSidebar,
    BrowseSummary,
    UsersSearch,
    UsersList,
    SidebarFilter,
    TypeFilter,
    RoleFilter,
    DisableReasonFilter
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  computed: {
    computedQuery() {
      return {
        page: parseInt(this.query.page) || 1,
        q: this.query.q || '',
        licenseType: Array.isArray(this.query.licenseType)
          ? this.query.licenseType
          : this.query.licenseType
            ? [this.query.licenseType]
            : [],
        role: Array.isArray(this.query.role)
          ? this.query.role
          : this.query.role
            ? [this.query.role]
            : [],
        disabledReason: Array.isArray(this.query.disabledReason)
          ? this.query.disabledReason
          : this.query.disabledReason
            ? [this.query.disabledReason]
            : []
      }
    }
  }
}
</script>
