import { mergeAttributes } from '@tiptap/core'
import BaseImage from '@tiptap/extension-image'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import InlineImage from './InlineImage'
import { Plugin, PluginKey } from 'prosemirror-state'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client'
const Image = BaseImage.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      video: false,
      inline: true,
      noupload: false
    }
  },
  addStorage() {
    return {}
  },

  addCommands() {
    return {
      ...this.parent(),
      openImageModal: () => () => {
        this.storage.onEditImage?.()
      }
    }
  },

  addAttributes() {
    return {
      ...this.parent(),
      src: {
        default: null,
        parseHTML: element =>
          element.getAttribute('src').replace(`//${PI_API_HOST}`, ''),
        renderHTML: ({ src }) => ({
          src:
            src && !src.startsWith('data:') && !src.startsWith('http')
              ? `//${PI_API_HOST}${src}`
              : src
        })
      },
      width: {},
      height: {}
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter({ editor }) {
        if (editor.isActive('image')) {
          editor.commands.openImageModal()
          return true
        }
      }
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(InlineImage)
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(HTMLAttributes)]
  },

  parseHTML() {
    return [{ tag: 'img' }]
  },

  renderText({ node }) {
    return `[image:${node.attrs.alt ?? 'unknown'}]`
  },

  addProseMirrorPlugins() {
    const options = this.options
    const flash = useFlash()
    const uploadFile = async file => {
      if (!file) {
        throw new Error('You must select a file to insert')
      }
      if (options.noupload) {
        return new Promise(resolve => {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            resolve(reader.result)
          })
          reader.readAsDataURL(file)
        })
      } else {
        const ext = file.name.split('.').pop()
        const name = `${new Date().getTime()}.${ext}`
        const url = await client.assets.upload({
          file,
          name
        })
        return url
      }
    }
    const onPaste = (view, event, slice) => {
      let handled = false
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items

      const { schema } = view.state
      for (const item of items) {
        const image = item.getAsFile()
        // Return here, otherwise copying texts won't possible anymore
        if (!image) {
          return
        }
        event.preventDefault()
        uploadFile(image)
          .then(imageSrc => {
            const node = schema.nodes.image.create({
              src: imageSrc
            })
            const transaction = view.state.tr.replaceSelectionWith(node)
            view.dispatch(transaction)
          })
          .catch(() => {
            flash.error('Error pasting image')
          })
        handled = true
      }
      return handled
    }
    const onDrop = (view, event, slice) => {
      try {
        const hasFiles = event.dataTransfer.files.length > 0
        const image = slice.content?.content?.[0]

        if (!hasFiles && (!image || image?.type.name !== 'image')) {
          return
        }

        const { schema } = view.state
        const images = event.dataTransfer.files
        const coordinates = view.posAtCoords({
          left: event.clientX,
          top: event.clientY
        })
        if (!images.length && image) {
          event.preventDefault()
          let imageSrc
          if (image.attrs.src?.startsWith('data:')) {
            const type = image.attrs.src
              .split(';')[0]
              .split(':')[1]
              .split('/')[1]
            const filename = `${new Date().getTime()}.${type}`

            if (options.noupload) {
              imageSrc = image.attrs.src
            } else {
              fetch(image.attrs.src)
                .then(async response => {
                  const blob = await response.blob()
                  const file = new File([blob], filename, { type: blob.type })
                  imageSrc = await uploadFile(file)
                  const node = schema.nodes.image.create({
                    src: imageSrc
                  })
                  const transaction = view.state.tr.insert(
                    coordinates.pos,
                    node
                  )
                  view.dispatch(transaction)
                })
                .catch(() => {
                  flash.error(`Error dropping images`)
                })

              return true
            }
          } else if (image.attrs.src?.startsWith('http')) {
            imageSrc = image.attrs.src
          }
          const node = schema.nodes.image.create({
            src: imageSrc
          })
          const transaction = view.state.tr.insert(coordinates.pos, node)
          view.dispatch(transaction)
          return true
        } else {
          for (const image of images) {
            if (
              !['image/jpeg', 'image/png', 'image/gif'].includes(image.type)
            ) {
              return
            }
            event.preventDefault()
            uploadFile(image)
              .then(imageSrc => {
                const node = schema.nodes.image.create({
                  src: imageSrc
                })
                const transaction = view.state.tr.insert(coordinates.pos, node)
                view.dispatch(transaction)
              })
              .catch(() => {
                flash.error(`Error dropping images`)
              })
          }
          return true
        }
      } catch (error) {
        flash.error(`Error dropping images`)
        return true
      }
    }

    return [
      new Plugin({
        key: new PluginKey('image-paste'),
        props: {
          handlePaste: onPaste,
          handleDrop: onDrop
        }
      })
    ]
  }
})

export default Image
