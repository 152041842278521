<template>
  <view-container>
    <view-header>
      <view-title>Tax Exemption</view-title>
    </view-header>
    <loading-container :loading="isLoading">
      <cert-capture
        v-if="data"
        :region="data.region"
        :token="data.token"
        :customer="data.customer"
        @success="onSuccess"
      />
    </loading-container>
  </view-container>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import { getPurchaseData } from 'src/shared/utils/purchases'
import CertCapture from 'src/modules/purchases/components/CertCapture'
import { useRouter } from 'vue-router'
import client from 'src/shared/api-client.ts'
const router = useRouter()

const isLoading = ref(true)
const data = ref()

onBeforeMount(async () => {
  const { id } = getPurchaseData()

  if (id) {
    data.value = await client.purchases.getQuoteTaxExemption({ id })
    isLoading.value = false
  } else {
    router.push({
      name: 'new_license_session',
      params: { licenseType: 'institution' }
    })
  }
})

function onSuccess() {
  router.push({ name: 'purchase_order_summary' })
}
</script>
