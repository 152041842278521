export function postRedirect(route, data) {
  const form = document.createElement('form')
  form.action = route
  form.method = 'POST'
  form.style.visibility = 'invisible'

  // Add each property on data to the form.
  for (const key of Object.getOwnPropertyNames(data)) {
    const field = document.createElement('input')
    const value = data[key]

    // Set the type of input to match the type of the value.
    if (typeof value === 'number') {
      field.type = 'number'
    } else if (typeof value === 'boolean') {
      field.type = 'checkbox'
    }

    field.value = value
    field.name = key
    form.appendChild(field)
  }

  // The form has to be in the document in order to be submitted.
  // We also don't need to remove the form after submitting because
  // the browser will navigate away from this page.
  document.body.appendChild(form)
  form.submit()
}

export default {
  methods: { postRedirect }
}
