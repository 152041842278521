<template>
  <div v-if="canAutograde" class="question-autograde">
    <form-button
      v-if="canRespond && !readonly && showButton"
      class="question-autograde__submit"
      :disabled="isGrading || !canSubmit"
      @click="$emit('submit')"
    >
      <template v-if="isGrading">
        <span class="flex items-center">
          <span class="inline-block submit-button__loader" aria-hidden="true" />
          Submitting
        </span>
      </template>
      <template v-else> Submit Answer </template>
    </form-button>
    <span v-if="showIndicators" class="question-autograde__submissions">
      <icon v-if="isCorrect === true" icon="check" style="color: green" />
      <icon v-else-if="isCorrect === false" icon="times" style="color: red" />
    </span>
    <span class="question-autograde__submissions-used" v-if="showButton">
      {{ componentResponse.submissions ?? 0 }} /
      {{ component.autogradeLimit ?? '&infin;' }} submissions used
    </span>

    <p v-if="canSubmit && showButton" class="question-autograde__heads-up">
      <strong>Heads Up!</strong> You need to submit this answer for it to save
      and be graded.
    </p>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'

const emits = defineEmits(['submit'])
const props = defineProps({
  readonly: {
    type: Boolean,
    default: false
  },
  canSubmit: {
    type: Boolean,
    default: true
  },
  showButtonText: {
    type: Boolean,
    default: false
  },
  grading: {
    type: Boolean,
    default: false
  },
  unauthenticated: {
    type: Boolean,
    default: false
  }
})

const inherited = inject('inherited')

const isGrading = computed(() => {
  return inherited.value.isGrading
})
const component = computed(() => {
  return inherited.value.component
})
const componentResponse = computed(() => {
  return inherited.value.componentResponse
})
const canAutograde = computed(() => {
  return component.value?.autograde
})
const canRespond = computed(() => {
  return inherited.value.canRespond
})
const isCorrect = computed(() => {
  return componentResponse.value?.correct ?? componentResponse.value?.isCorrect
})
const showIndicators = computed(() => {
  return !!inherited.value.response.assignment?.showIndicators
})
const showButton = computed(() => {
  return (
    props.unauthenticated ||
    props.grading ||
    inherited.value.response.assignment?.studentFeedbackTiming ===
      AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
  )
})
</script>

<style lang="scss" scoped>
.question-autograde {
  &__submit {
    margin-right: 8px;
    transform: rotate(0deg);
    animation: 0.5s ease-in button-attention;

    &[disabled] {
      transform: rotate(0deg);
      animation: none;
    }
  }

  &__submissions {
    display: inline-block;
  }

  &__heads-up {
    margin-top: 8px;

    strong {
      color: $teal;
    }
  }

  @keyframes button-attention {
    0% {
      transform: rotate(-3deg);
    }
    20% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    60% {
      transform: rotate(5deg);
    }
    90% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  .question-autograde__submissions-used {
    margin-left: 5px;
  }

  .submissions {
    margin-left: 5px;
  }
}
</style>
