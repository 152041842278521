<template>
  <div v-bind="wrapperAttrs" :class="errorMessage ? 'has-error' : ''">
    <input
      v-bind="inputAttrs"
      ref="input"
      :value="inputValue"
      class="pi-form-control form-control"
      :class="inputClass"
      :type="type"
      :aria-describedby="`${helpId} ${ariaDescribedby}`"
      @input="onInput"
      @change="onChange"
      @blur="handleBlur"
    />
    <span v-if="errorMessage || helpText" :id="helpId" class="help-block">
      {{ errorMessage || helpText }}
    </span>
  </div>
</template>

<script>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
let counter = 0

export default {
  name: 'TextInput',
  inheritAttrs: false,
  emits: ['change', 'input', 'update:modelValue'],
  props: {
    inputClass: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: 'field'
    },
    name: {
      type: String,
      // Names must be unique between different inputs.
      default: () => `text-input-${counter++}`
    },
    helpText: {
      type: String,
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: ''
    },
    rules: {
      type: [String, Object, Function],
      default: null
    }
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      handleBlur,
      resetField,
      meta,
      setValidationState,
      setErrors
    } = useField(toRef(props, 'name'), toRef(props, 'rules'), {
      label: toRef(props, 'label'),
      initialValue: props.modelValue,
      validateOnValueUpdate: false,
      syncVModel: false
    })

    return {
      setErrors,
      errorMessage,
      inputValue,
      handleChange,
      handleBlur,
      resetField,
      meta,
      setValidationState
    }
  },
  computed: {
    helpId() {
      return `${this.name.replace(' ', '-')}-help`
    },
    wrapperAttrs() {
      const { style, class: klass } = this.$attrs
      return { style, class: klass }
    },
    inputAttrs() {
      const { style, class: _, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    onInput(e) {
      this.$emit('update:modelValue', e.target.value)
      this.$emit('input', e.target.value)
      this.handleChange(e.target.value, !!this.errorMessage)
    },
    onChange(e) {
      this.$emit('change', e.target.value)
      this.handleChange(e.target.value)
    },
    applyError(message) {
      this.setErrors(message)
    },
    select(...args) {
      return this.$refs.input.select(...args)
    },
    setFocus() {
      this.$refs.input.focus()
    }
  },
  watch: {
    modelValue(n, o) {
      if (this.modelValue !== this.inputValue) {
        this.resetField({ value: this.modelValue })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.help-block {
  max-width: 330px;
  display: block;
}
</style>
