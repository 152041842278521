<template>
  <div>
    <slot />
  </div>
</template>

<script>
import * as Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import client from 'src/shared/api-client.ts'

export default {
  name: 'BrowseInstructors',
  provide() {
    return {
      browseInstructors: Vue.computed(() => ({
        query: this.query,
        instructorCount: this.instructorCount,
        instructorLimit: this.instructorLimit,
        instructors: this.instructors,
        isLoadingInstructors: this.isLoadingInstructors,
        updateQuery: this.updateQuery,
        loadInstructors: this.loadInstructors,
        updateInstructor: this.updateInstructor
      }))
    }
  },
  props: {
    query: {
      type: Object,
      required: true,
      validator(value) {
        return (
          typeof value.page === 'number' &&
          typeof value.dir === 'string' &&
          typeof value.q === 'string'
        )
      }
    }
  },
  data() {
    return {
      instructors: [],
      instructorCount: 0,
      instructorLimit: 100,
      isLoadingInstructors: false
    }
  },
  computed: {
    ...mapGetters(['userId', 'isAdmin']),
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    async loadInstructors() {
      const request = client.users.search({
        page: this.query.page,
        limit: this.instructorLimit,
        ...(!!this.query.q && { q: this.query.q }),
        ...(this.query.vetStatus !== 'undefined' &&
          this.query.vetStatus !== 'all' && {
            vetStatus: [...this.query.vetStatus]
          }),
        ...(this.query.pending && { pending: true }),
        role: ['teacher'],
        sort: 'name',
        dir: 'asc'
      })
      this.request = request
      if (request === this.request) {
        const response = await request
        this.instructors = response.page
        this.instructorCount = response.total
      }
    },
    updateQuery(query) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          ...query
        }
      })
    },
    updateInstructor(id, status) {
      const newStatus = status ? 'vetted' : 'rejected'
      if (!this.query.vetStatus.includes(newStatus)) {
        this.instructors = this.instructors.filter(
          instructor => instructor.id !== id
        )
        this.instructorCount -= 1
      } else {
        const instructor = this.instructors.find(
          instructor => instructor.id === id
        )
        if (instructor) {
          instructor.vetStatus = newStatus
        }
      }
    }
  },
  async created() {
    this.isLoadingInstructors = true
    await this.loadInstructors()
    this.isLoadingInstructors = false
  },
  watch: {
    async query() {
      this.isLoadingInstructors = true
      await this.loadInstructors()
      this.isLoadingInstructors = false
    }
  }
}
</script>
