<template>
  <button :type="type" :disabled="disabled || isLoading || null" @click="click">
    <template v-if="isLoading">
      Loading...
      {{ progressText }}
    </template>
    <slot v-else />
  </button>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      default() {
        return () => {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    progress: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    progressText() {
      return this.progress > 0 && this.progress < 1
        ? Math.floor(this.progress * 100) + '%'
        : ''
    }
  },
  methods: {
    click() {
      if (this.onClick && !this.isLoading) {
        const result = this.onClick()
        let isComplete = false
        if (result && result.then) {
          result.finally(() => {
            isComplete = true
            this.isLoading = false
          })
          if (!isComplete) {
            this.isLoading = true
          }
        }
      }
    }
  }
}
</script>
