<template>
  <ul class="">
    <li
      v-for="section in mappedSections"
      :key="section.id"
      class="flex leading-4 text-base content-start text-pivot-teal-700 py-3 px-1"
      :class="{
        'bg-pivot-teal-50': visibleElementId === section.id
      }"
    >
      <span
        v-if="!section.mapped"
        class="border border-pivot-teal-700 rounded-full w-4 h-4 inline-flex mr-4"
      ></span>
      <icon
        :icon="section.icon"
        v-if="section.mapped"
        class="w-4 h-4 inline-flex mr-4"
      />
      <div>
        <a
          :href="`#section-${section.id}`"
          @click="e => scrollToSection(e, section.id)"
          >{{ section.name }}</a
        >
        <div class="pt-1">{{ section.class }}</div>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { type Class } from '@pi/api-types'

interface LtiSection {
  id: string
  name: string
}
const observer = ref<IntersectionObserver>()
const visibleElementId = ref<string>()

const props = defineProps<{
  sections: LtiSection[]
  values: {
    sections: Record<string, string>
  }
  classes: Class[]
}>()

const mappedSections = computed(() => {
  return props.sections.map(section => {
    const selectedClass =
      props.values.sections[section.id] &&
      props.values.sections[section.id] !== 'none'
        ? props.classes.find(c => c.id === props.values.sections[section.id])
        : undefined

    return {
      ...section,
      class: props.values.sections[section.id]
        ? props.values.sections[section.id] === 'none'
          ? 'Do Not Connect To A Class'
          : selectedClass?.name
        : 'Choose A Class',
      mapped: !!props.values.sections[section.id],
      icon: selectedClass
        ? 'circle-check'
        : props.values.sections[section.id] === 'none'
          ? 'circle-minus'
          : ''
    }
  })
})

const setupObserver = () => {
  observer.value = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          visibleElementId.value = entry.target.id.replace('section-', '')
          // You can also perform any action you want here when an element becomes visible
        }
      })
    },
    {
      root: document.getElementById('scroll-container'),
      threshold: 1,
      rootMargin: '0px 0px -50% 0px'
    }
  )

  const elements = document.querySelectorAll('.class-selector-card')

  elements.forEach(element => {
    observer.value?.observe(element)
  })
}

const scrollToSection = (e: PointerEvent | MouseEvent, id: string) => {
  e.preventDefault()
  document.getElementById(`section-${id}`)?.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })
}

onMounted(() => {
  setupObserver()
})
onBeforeUnmount(() => {
  observer.value?.disconnect()
})
</script>
