import { format } from 'mathjs'
import type { NestedVariable, Variable } from './types'

export function findVariableById(
  id: string,
  variables: Variable[]
): Variable | NestedVariable | undefined {
  const idParts = (id ?? '').split('.')

  const variable = variables.find(v => v.id === idParts[0])
  if (!variable) return

  if (idParts[1]) {
    if (variable.variableType !== 'collection') return

    const child = variable.variables?.find(v => v.id === idParts[1])
    if (!child) return

    return {
      ...child,
      id,
      name: `${variable.name}.${child.name}`
    }
  } else {
    return {
      ...variable,
      id,
      name: `${variable.name}`
    }
  }
}

export function findVariableByName(
  name: string,
  variables: Variable[]
): Variable | NestedVariable | undefined {
  const nameParts = (name ?? '').split('.')

  const variable = variables.find(v => v.name === nameParts[0])
  if (!variable) return

  if (nameParts[1]) {
    if (variable.variableType !== 'collection') return

    const child = variable.variables?.find(v => v.name === nameParts[1])
    if (!child) return

    return {
      ...child,
      name,
      id: `${variable.id}.${child.id}`
    }
  } else {
    return {
      ...variable,
      name,
      id: `${variable.id}`
    }
  }
}

const EXP_MATCH = /^(\d+(?:\.\d+)?)e([+-]\d+)$/

interface FormattedNumber {
  coefficient: string | number
  exponent?: string
}

export function formatNumber(
  value: number | string,
  variable: Variable | NestedVariable
): FormattedNumber {
  if (
    variable.variableType !== 'random' &&
    variable.variableType !== 'number' &&
    variable.variableType !== 'studentResponse'
  ) {
    throw new Error('cannot format variables that are not numeric')
  }

  const useScientificNotation =
    'useScientificNotation' in variable ? variable.useScientificNotation : false
  const precisionType =
    'precisionType' in variable ? variable.precisionType : 'decimal'
  const precision = 'precision' in variable ? variable.precision : undefined

  if (useScientificNotation) {
    const expStr = format(value, {
      notation: 'exponential',
      precision:
        typeof precision === 'number'
          ? precision + (precisionType === 'decimal' ? 1 : 0)
          : undefined
    })
    const match = EXP_MATCH.exec(expStr)
    if (match) {
      return {
        coefficient: match[1],
        exponent: parseInt(match[2]).toString()
      }
    } else {
      return { coefficient: value }
    }
  }
  if (typeof precision === 'number' && typeof value === 'number') {
    switch (precisionType) {
      default:
      case 'decimal':
        return {
          coefficient: new Intl.NumberFormat('en-US', {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision
          }).format(value)
        }
      case 'sigfigs':
        return {
          coefficient: new Intl.NumberFormat('en-US', {
            minimumSignificantDigits: precision,
            maximumSignificantDigits: precision
          }).format(value)
        }
    }
  }
  return {
    coefficient:
      typeof value === 'number'
        ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 8 }).format(
            value
          )
        : value
  }
}
