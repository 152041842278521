<template>
  <vc-donut
    v-bind="$attrs"
    background="white"
    foreground="gainsboro"
    :size="size"
    unit="px"
    :thickness="20"
    :sections="sections"
    :total="1"
    :start-angle="0"
    :auto-adjust-text-size="false"
    aria-hidden="true"
  >
    <icon
      v-if="completed === true || progress === 1"
      :style="{
        color:
          completed === true && progress === 1
            ? colors.complete
            : colors.progress
      }"
      icon="check"
    />
    <icon
      v-else
      :style="{
        color:
          completed === true && progress === 1
            ? colors.complete
            : colors.progress
      }"
      icon="hourglass-start"
    />
  </vc-donut>
  <span class="sr-only" :aria-hidden="ariaHidden">
    <slot>{{ (progress * 100).toFixed(0) }}%</slot>
  </span>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  progress: {
    type: Number,
    required: true
  },
  completed: {
    type: Boolean,
    default: false
  },
  total: {
    type: Number,
    default: 1
  },
  size: {
    type: Number,
    default: 30
  },
  ariaHidden: {
    type: String,
    default: undefined
  }
})

const colors = {
  complete: '#158484',
  progress: '#CC6114'
}

const progress = computed(() =>
  Math.max(0, Math.min(1, props.progress / props.total))
)

const sections = computed(() => [
  {
    value: props.progress,
    color:
      progress.value === 1 && props.completed
        ? colors.complete
        : colors.progress
  }
])
</script>
