<template>
  <h2 class="section-header__title"><slot /></h2>
</template>

<script>
export default {
  name: 'SectionTitle'
}
</script>

<style lang="scss" scoped>
.section-header__title {
  margin: 0;
  padding: 2px 0 0 0;
}
</style>
