<template>
  <div
    class="component-response"
    :class="
      ['OpenEndedQuestion', 'GridQuestion'].includes(component.componentType)
        ? 'open-ended-response'
        : ''
    "
  >
    <!-- Grid/Graph -->
    <grid-graph-response
      v-if="
        ['GridGraphQuestion', 'GridQuestion'].includes(component.componentType)
      "
      :variable-context="variableContext"
      :component="component"
      :response="response"
    />

    <!-- Multiple Choice -->
    <template v-else-if="component.componentType === 'MultipleChoiceQuestion'">
      <ul class="select-list">
        <template v-if="isSelectMany || isPoll">
          <li v-for="choice in component.choices" :key="choice.id">
            <checkbox
              v-model="selectAnyResponse"
              :value="choice.id"
              :disabled="true"
            >
              <editor-content
                :text="choice.text"
                :variable-context="variableContext"
              />
            </checkbox>
          </li>
        </template>
        <template v-else>
          <li v-for="choice in component.choices" :key="choice.id">
            <radio-button
              :modelValue="response"
              :value="choice.id"
              :disabled="true"
              :name="name"
            >
              <editor-content
                :text="choice.text"
                :variable-context="variableContext"
              />
            </radio-button>
          </li>
        </template>
      </ul>
    </template>

    <numerical-response
      v-else-if="component.componentType === 'NumericalQuestion'"
      :component="component"
      :response="response"
    />

    <!-- Open Ended Question -->
    <editor-content
      v-else
      :text="response"
      :variable-context="variableContext"
    />
  </div>
</template>

<script>
import GridGraphResponse from './GridGraphResponse'
import NumericalResponse from './NumericalResponse'

export default {
  name: 'ComponentResponse',
  components: { GridGraphResponse, NumericalResponse },
  props: {
    component: {
      required: true
    },
    response: [String, Object],
    name: {
      required: true,
      type: String
    },
    variableContext: {
      required: true,
      type: Object
    }
  },
  computed: {
    selectAnyResponse() {
      return (this.response || '')
        .split(',')
        .filter(item => item !== '')
        .map(item => parseInt(item))
    },
    isSelectMany() {
      return this.component.choices.filter(({ answer }) => answer).length > 1
    },
    isPoll() {
      return this.component.choices.filter(({ answer }) => answer).length === 0
    }
  }
}
</script>

<style lang="scss" scoped>
.select-list {
  list-style-type: none;
  padding-left: 0;
}
</style>
