<template>
  <div class="autograde-filter">
    <h4 class="autograde-filter__header">Autograde with Feedback Range</h4>
    <double-slider
      :min="range.min"
      :max="range.max"
      @change="onChange"
      aria-label="Autograde with Feedback Range"
    />

    <div class="autograde-filter__range">
      <number-input
        aria-label="Minimum autograde percent"
        class="autograde-filter__input"
        min="0"
        max="100"
        step="10"
        :modelValue="range.min"
        @change="value => onChange({ min: value, max: range.max })"
      />
      <number-input
        aria-label="Maximum autograde percent"
        class="autograde-filter__input"
        min="0"
        max="100"
        step="10"
        :modelValue="range.max"
        @change="value => onChange({ max: value, min: range.min })"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'LibraryFilterAutograde',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: '0-100'
    }
  },
  setup(props, { emit }) {
    const clamp = v =>
      Math.round(Math.round(Math.min(100, Math.max(0, v)) / 10) * 10)

    const range = computed(() => {
      try {
        const items = props.modelValue.split('-').map(v => clamp(parseFloat(v)))
        return {
          min: items[0],
          max: items[1]
        }
      } catch {
        return { min: 0, max: 100 }
      }
    })

    const onChange = ({ min, max }) => {
      min = clamp(min)
      max = clamp(max)
      if (min === 0 && max === 100) {
        emit('update:modelValue')
      } else {
        emit('update:modelValue', `${min}-${max}`)
      }
    }

    return { range, onChange }
  }
}
</script>

<style lang="scss" scoped>
.autograde-filter {
  font-size: 16px;
  padding: 0 0 8px 0;
}

.autograde-filter__header {
  font-size: 16px;
  font-weight: 700;
  margin: 8px 8px 12px 8px;
}

.autograde-filter__range {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
}
</style>
