<template>
  <auth-card header="Login">
    <UIForm class="mx-auto w-80" @submit="onSubmit">
      <div class="pb-4">
        <UILabel for="email"> Email address </UILabel>
        <UITextInput
          id="email"
          rules="required|email"
          type="email"
          autocomplete="email"
          label="Email"
          name="email"
        />
        <UIFieldError name="email" />
      </div>

      <div class="pb-4">
        <UILabel for="password"> Password </UILabel>
        <UITextInput
          id="password"
          rules="required"
          required
          label="Password"
          type="password"
          autocomplete="current-password"
          name="password"
        />
        <UIFieldError name="password" />
      </div>

      <div class="flex items-center justify-between">
        <UIButton type="link" :to="{ name: 'forgot_password' }">
          Forgot password
        </UIButton>
        <UIButton name="log-in" submit>Log In</UIButton>
      </div>
    </UIForm>
  </auth-card>
</template>

<script setup>
import { useFlash } from 'src/shared/hooks/flash'
import { useRouter } from 'vue-router'
import AuthCard from './AuthCard.vue'
import client from 'src/shared/api-client.ts'
import { useForm } from 'vee-validate'
import {
  UILabel,
  UITextInput,
  UIForm,
  UIButton,
  UIFieldError
} from '@pi/design'

const router = useRouter()
const flash = useFlash()

const { handleSubmit } = useForm({
  initialValues: {
    email: '',
    password: ''
  }
})

const onSubmit = handleSubmit(async values => {
  try {
    const body = await client.auth.login({
      email: values.email,
      password: values.password
    })

    if (body?.mfa) {
      router.push({
        ...router.currentRoute.value,
        params: { ...router.currentRoute.params, step: 'mfa' }
      })
    } else {
      router.push({
        ...router.currentRoute.value,
        params: { ...router.currentRoute.value.params, step: 'redirect' }
      })
    }
    return
  } catch (error) {
    if (error.status === 401) {
      error.body.errors.map(error => flash.error(`${error.message}`))
    } else {
      throw error
    }
  }
})
</script>
