<template>
  <loading-container :loading="gettingQuote">
    <view-container normal>
      <flash />
      <help-banner :printing="printView" :license-type="quote.licenseType" />
      <div v-if="quoteSent" class="email-sent">
        <div>Quote emailed to {{ quote.contact?.email }}</div>
        <form-button
          class="form-button form-button--beta"
          @click="copyQuoteLink"
        >
          Copy Quote Link
        </form-button>
      </div>
      <div class="card">
        <div class="header">
          <div>
            <img
              class="pi-logo grayscale"
              src="~assets/img/RGB_pivot-logo.png"
              alt="Pivot Interactives Logo"
            /><br />
            <address>
              4350 Congress Street, Suite 700<br />Charlotte, NC 28209
            </address>
          </div>
          <div class="right-grouping">
            <h2 class="header-right">Plan Quote {{ quote.quoteNumber }}</h2>
            <p>Valid for orders placed through Pivot Interactives only</p>

            <p v-if="quote.fulfilled" class="subheader-right">
              This purchase has been completed
            </p>
            <div v-else class="subheader-right">
              <div class="date-item">
                <label>Created</label>
                <span>{{
                  $format.date(quote.quoteCreated, 'd MMM yyyy')
                }}</span>
              </div>
              <div v-if="isUpdated" class="date-item">
                <label>Updated</label>
                <span>{{
                  $format.date(quote.quoteUpdated, 'd MMM yyyy')
                }}</span>
              </div>
              <div class="date-item">
                <label>Valid Until</label>
                <date-input
                  v-if="canUpdate && editDate"
                  v-model="quote.quoteExpires"
                />
                <span v-else>{{
                  $format.date(quote.quoteExpires, 'd MMM yyyy')
                }}</span>
              </div>
              <form-button
                v-if="canUpdate && !editDate"
                :class="printView ? 'print' : 'editButton'"
                tertiary
                @click="toggleEdit"
              >
                <icon icon="pencil" />
                <span class="sr-only">Edit {{ ariaLabel }}</span>
              </form-button>
              <form-button
                v-if="canUpdate && editDate"
                @click="toggleEdit"
                tertiary
              >
                <icon icon="check" />
                <span class="sr-only">Save</span>
              </form-button>
            </div>
          </div>
        </div>
        <section
          class="quote-section"
          aria-labelledby="plan-details-section-label"
          v-if="!isGeneric"
        >
          <h4
            id="plan-details-section-label"
            class="section-header bottom-spacing"
          >
            Plan Details
          </h4>
          <ul class="details">
            <li class="item">
              <p id="plan-type-label" class="item-label">Type</p>
              <p class="item-value" aria-labelledby="plan-type-label">
                {{ planType }}
              </p>
            </li>
            <li class="item">
              <p id="seats-label" class="item-label">Number of Seats</p>
              <p
                v-if="quote.seats"
                class="item-value"
                aria-labelledby="seats-label"
              >
                {{ quote.seats }} (<template v-if="canUpdate && !printView"
                  >$
                  <number-input
                    v-model="quote.pricePerSeat"
                    class="cost-per-seat-input"
                    aria-label="Cost Per Seat"
                    @change="overrideSeatPrice"
                  />
                </template>
                <template v-else>
                  {{ $format.currency(quote.pricePerSeat) }}
                </template>
                per seat)
                <form-button
                  link
                  v-if="canUpdate & this.quote.customPricing"
                  @click="resetPricing"
                  class="reset-pricing-button"
                  >Reset Price</form-button
                >
              </p>
            </li>
            <template v-if="hasDates">
              <li class="item">
                <label
                  id="start-date-label"
                  for="start-date"
                  class="item-label"
                >
                  Start Date
                </label>
                <p v-if="canUpdate && !printView" class="item-value">
                  <date-input
                    id="start-date"
                    v-model="startDate"
                    label="start date"
                    @update:modelValue="updateQuotePrice"
                  />
                </p>
                <p v-else class="item-value" aria-labelledby="start-date-label">
                  {{ $format.date(this.startDate, 'MMMM dd, yyyy') }}
                </p>
              </li>
              <li class="item">
                <label id="end-date-label" for="end-date" class="item-label">
                  End Date
                </label>
                <p v-if="canUpdate && !printView" class="item-value">
                  <date-input
                    id="end-date"
                    v-model="endDate"
                    :min-date="startDate"
                    label="end date"
                    @update:modelValue="updateQuotePrice"
                  />
                </p>
                <p v-else class="item-value" aria-labelledby="end-date-label">
                  {{ $format.date(this.endDate, 'MMMM dd, yyyy') }}
                </p>
              </li>
            </template>
          </ul>
        </section>
        <section class="quote-section" aria-labelledby="contact-section-label">
          <h4 id="contact-section-label" class="section-header bottom-spacing">
            Contact
          </h4>
          <ul class="details">
            <li class="item">
              <p id="name-label" class="item-label">Name</p>
              <p v-if="canUpdate && !printView" class="item-value contact-name">
                <text-input
                  v-model="quote.contact.name"
                  class="name-input"
                  aria-label="Name"
                />
              </p>
              <p v-else class="item-value" aria-labelledby="name-label">
                {{ quote.contact.name || '' }}
              </p>
            </li>
            <li class="item">
              <p id="email-label" class="item-label">Email</p>
              <p v-if="canUpdate && !printView" class="item-value">
                <text-input
                  class="email-input"
                  type="email"
                  v-model="quote.contact.email"
                  aria-label="Email"
                />
              </p>

              <p v-else class="item-value" aria-labelledby="email-label">
                {{ quote.contact.email }}
              </p>
            </li>
            <li class="item" v-if="isGeneric">
              <p id="institution-label" class="item-label">Institution</p>
              <p v-if="canUpdate && !printView" class="item-value">
                <text-input
                  class="institution-input"
                  type="text"
                  v-model="quote.contact.institution"
                  aria-label="Institution"
                />
              </p>

              <p v-else class="item-value" aria-labelledby="institution-label">
                {{ quote.contact.institution }}
              </p>
            </li>
          </ul>
        </section>
        <section
          class="quote-section"
          aria-labelledby="instituion-details-section-label"
          v-if="quote.purchaseType === 'license'"
        >
          <h4
            id="instituion-details-section-label"
            class="section-header bottom-spacing"
          >
            Institution Details
          </h4>
          <ul class="details">
            <li class="item">
              <p id="institution-label" class="item-label">Institution</p>
              <p class="item-value" aria-labelledby="institution-label">
                {{ quote.institution }}
              </p>
            </li>
            <li v-if="quote.institutionAddress !== ''" class="item">
              <p id="institution-address-label" class="item-label">Address</p>
              <p class="item-value" aria-labelledby="institution-address-label">
                <span>{{ quote.institutionAddress }}</span
                ><span v-if="quote.institutionAddress2">
                  {{ quote.institutionAddress2 }}</span
                >
                <br />
                <span
                  >{{ quote.institutionCity }}, {{ quote.institutionState }}
                  {{ quote.institutionZipCode }}</span
                >
              </p>
            </li>
          </ul>
        </section>
        <div :class="['charges-summary', gettingPrice ? 'loading' : '']">
          <div class="charges-summary">
            <order-banner
              :quote="quote"
              @refresh="refreshQuote"
              :printing="printView"
            />
            <div
              class="custom-price-disclaimer"
              v-if="quote.customPricing && !isAdmin"
            >
              <p>
                Your quote has a custom seat price. If you need to make any
                changes, please contact
                {{
                  quote.adminContact
                    ? quote.adminContact
                    : 'sales@pivotinteractives.com'
                }}
                to get an updated quote
              </p>
            </div>
          </div>
        </div>
        <section class="quote-notes" v-if="showNotes">
          <label for="notes">Notes</label>
          <multiline-text-input
            v-if="canUpdate && !printView"
            id="notes"
            v-model="quote.notes"
            class="notes-field"
            label="notes"
            @blur="saveNotes"
          ></multiline-text-input>
          <p v-else class="notes-field" label="notes">{{ quote.notes }}</p>
        </section>

        <async-form
          v-if="!quote.paid && !printView"
          class="form"
          @submit="onSubmit"
          persist
        >
          <form-group class="button-group">
            <submit-button
              primary
              v-if="isComplete"
              action="submit"
              class="form-button--beta"
            >
              Continue to Payment
            </submit-button>
            <submit-button
              primary
              v-else
              action="completePurchase"
              class="form-button--beta"
            >
              Complete Purchase
            </submit-button>
            <submit-button
              secondary
              name="button"
              v-if="canUpdate"
              action="save"
              :disabled="gettingPrice"
              class="form-button--alpha"
            >
              Save
            </submit-button>
            <submit-button
              secondary
              v-if="(isAdmin || !isExpired) && canEditQuote"
              action="edit"
              class="form-button--alpha"
            >
              Edit Quote
            </submit-button>

            <submit-button
              secondary
              v-if="isAdmin"
              action="resend"
              class="form-button--alpha"
            >
              Resend Quote Email
            </submit-button>
          </form-group>
        </async-form>
        <async-form v-if="isAdmin && quote.paid && !quote.fulfilled">
          <submit-button
            primary
            v-if="isAdmin && quote.paid && !quote.fulfilled"
            action="fulfill"
            class="form-button--beta"
          >
            Fulfill Quote
          </submit-button>
        </async-form>
        <section v-if="isAdmin && !printView && this.quote.licenseId">
          <h4 class="section-header bottom-spacing">License</h4>
          <router-link
            :to="{
              name: 'admin_manage_license',
              params: { id: this.quote.licenseId }
            }"
          >
            {{ licenseName ?? 'License' }}</router-link
          >
        </section>
        <section v-if="isAdmin && !printView" class="quote-history">
          <h4 id="history-section-label" class="section-header bottom-spacing">
            History
          </h4>
          <list :items="quote.history || []" aria-label="License Members">
            <list-column title="Action" property="action" />
            <list-column v-slot="{ actor }" title="User">{{
              actor ? `${actor.firstName} ${actor.lastName}` : 'Store'
            }}</list-column>
            <list-column v-slot="{ timestamp }" title="Date">
              {{ $format.date(timestamp, 'MM-dd-yyyy') }}
            </list-column>
          </list>
        </section>
      </div>
    </view-container>
  </loading-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import OrderBanner from 'src/modules/purchases/components/OrderBanner'
import {
  mergePurchaseData,
  clearPurchaseData
} from 'src/shared/utils/purchases'
import HelpBanner from 'src/modules/purchases/components/HelpBanner'
import Flash from 'src/shared/components//Flash'
import { isBefore, add, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import client from 'src/shared/api-client.ts'

export default {
  name: 'QuoteView',
  components: { OrderBanner, HelpBanner, Flash },
  inject: ['$modal'],
  props: ['id', 'quoteSent'],
  data() {
    return {
      quote: {
        contact: {
          name: '',
          institution: '',
          email: ''
        }
      },
      gettingQuote: true,
      gettingPrice: false,
      validDate: null,
      editDate: false,
      printView: false,
      expirationDateChagned: false,
      licenseName: ''
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState({
      user: state => state.auth.user
    }),
    hasDates() {
      return ['renewal', 'upgrade', 'license'].includes(this.quote.purchaseType)
    },
    contactRoles() {
      return (this.quote.contactRoles || []).join(', ')
    },
    isBookstorePurchase() {
      return this.quote.purchaseType === 'bookstore-purchase'
    },
    isLicensePurchase() {
      return this.quote.purchaseType === 'license'
    },
    isRenewalPurchase() {
      return this.quote.purchaseType === 'renewal'
    },
    isGeneric() {
      return !this.quote.purchaseType
    },
    planType() {
      let level =
        this.quote.licenseLevel === 'college' ? 'College' : 'Grades 6–12'
      if (this.quote.purchaseType === 'renewal') {
        level += ' Renewal'
      } else if (this.quote.purchaseType === 'added-seats') {
        level += ' Added Seats'
      } else if (this.quote.purchaseType === 'upgrade') {
        level += ' License Upgrade'
      } else if (this.quote.purchaseType === 'generic') {
        return 'General Quote'
      }
      return level
    },
    canEditQuote() {
      return (this.isAdmin || !this.quote.customPricing) && !this.isGeneric
    },
    canUpdate() {
      return (
        this.isAdmin &&
        !this.quote.paid &&
        !this.quote.fulfilled &&
        this.quote.purchaseType !== 'bookstore-codes' &&
        this.quote.licenseType !== 'student'
      )
    },
    isUpdated() {
      return (
        this.quote.quoteUpdated &&
        this.quote.quoteCreated !== this.quote.quoteUpdated
      )
    },
    isExpired() {
      return isBefore(new Date(this.quote.quoteExpires), new Date())
    },
    isComplete() {
      return (
        this.quote.purchaseType !== 'license' ||
        (this.quote.contactFirstName &&
          this.quote.contactLastName &&
          this.quote.contactEmail &&
          this.quote.contactRoles &&
          this.quote.contactSubjects &&
          this.quote.institution &&
          this.quote.institutionAddress &&
          this.quote.institutionCity &&
          this.quote.institutionState &&
          this.quote.institutionZipCode &&
          this.quote.institutionCountry)
      )
    },
    showNotes() {
      if (!this.isAdmin) {
        return this.quote.notes
      }
      return this.quote.notes || !this.printView
    },
    startDate: {
      get() {
        if (this.quote.startDate) {
          return utcToZonedTime(new Date(this.quote.startDate), 'Etc/UTC')
        } else {
          return undefined
        }
      },
      set(value) {
        if (value) {
          this.quote.startDate = value
          this.updateQuotePrice()
        } else {
          this.quote.startDate = undefined
        }
      }
    },
    endDate: {
      get() {
        if (this.quote.endDate) {
          return utcToZonedTime(new Date(this.quote.endDate), 'Etc/UTC')
        } else {
          return undefined
        }
      },
      set(value) {
        if (value) {
          this.quote.endDate = value
          this.updateQuotePrice()
        } else {
          this.updateQuotePrice()
        }
      }
    }
  },
  methods: {
    overrideSeatPrice() {
      this.quote.customPricing = !!this.quote.pricePerSeat
      this.updateQuotePrice()
    },
    enablePrintView() {
      this.printView = true
    },
    disablePrintView() {
      this.printView = false
    },
    toggleEdit() {
      this.editDate = !this.editDate
    },
    async resetPricing() {
      await this.saveQuote({
        id: this.id,
        licenseId: this.quote.licenseId,
        customPricing: false
      })
      this.$success(`Quote Saved`)
      const { quote } = await client.purchases.getQuote({ id: this.id })
      this.quote = quote || {}
    },
    copyQuoteLink() {
      navigator.clipboard.writeText(window.location.href)
      this.$success('Quote link copied to clipboard')
    },
    async updateQuotePrice() {
      if (this.gettingQuote || this.gettingPrice) return
      this.gettingPrice = true
      const { licenseLevel, seats, pricePerSeat } = this.quote

      const pricing = await client.purchases.getQuotePrice({
        id: this.quote.id,
        level: licenseLevel,
        seats,
        // Original comment:
        // Sending null forces the system to recalulate the price.
        // Once we know that you've changed it manually we need to keep sending that price.
        pricePerSeat: this.quote.customPricing ? pricePerSeat : null,
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.quote.pricePerSeat = pricing.pricePerSeat
      this.quote.startDate = this.startDate
      this.quote.endDate = this.endDate
      this.quote.productPrice = pricing.productPrice
      const lineItemSum = this.quote.lineItems.reduce(
        (sum, item) => sum + item.totalPrice,
        0
      )

      this.quote.subtotal = lineItemSum + this.quote.productPrice
      this.quote.taxes = this.quote.subtotal * (this.quote.taxRate / 100)
      this.quote.total = this.quote.subtotal + this.quote.taxes
      this.gettingPrice = false
    },
    async saveQuote(metadata) {
      switch (this.quote.purchaseType) {
        case 'license':
          await client.purchases.updateLicenseQuote({
            id: this.quote.id,
            ...metadata
          })
          break
        case 'bookstore-codes':
          await client.purchases.updateAccessCodesQuote({
            id: this.quote.id,
            ...metadata
          })
          break
        case 'added-seats':
          await client.purchases.updateAddedSeatsQuote({
            id: this.quote.id,
            ...metadata
          })
          break
        case 'upgrade':
        case 'renewal':
          await client.purchases.updateRenewalQuote({
            id: this.quote.id,
            ...metadata
          })
          break
        case 'fulfill':
          await client.purchases.fulfillQuote({ id: this.quote.id })
          break
        default:
          await client.purchases.updateQuote({
            id: this.quote.id,
            ...metadata
          })
      }
    },
    async saveContact() {
      await client.purchases.updateQuote({
        id: this.quote.id,
        contact: this.quote.contact
      })
    },
    async saveNotes() {
      await client.purchases.updateQuote({
        id: this.quote.id,
        notes: this.quote.notes
      })
    },
    async onSubmit({ done, action }) {
      if (action === 'submit') {
        mergePurchaseData({ id: this.id })
        this.$router.push({ name: 'purchase_billing_information' })
        done()
      } else if (action === 'resend') {
        try {
          await client.purchases.sendQuoteEmail({ id: this.id })
          this.$success(`Sent quote email to ${this.quote.contactEmail}`)
          done()
        } catch {
          done(false)
        }
      } else if (action === 'save') {
        const quoteMetadata = {
          licenseId: this.quote.licenseId,
          ...(this.quote.customPricing && {
            pricePerSeat: this.quote.pricePerSeat,
            customPricing: true
          }),
          sendMail: false,
          startDate: this.startDate,
          endDate: this.endDate,

          quoteExpires:
            this.isExpired && this.isAdmin
              ? add(new Date(), { month: 1 })
              : this.quote.quoteExpires
        }

        try {
          await this.saveQuote(quoteMetadata)
          await this.saveContact()
          this.$success(`Quote Saved`)
          await this.refreshQuote()
          done()
        } catch (e) {
          done(false)
          throw e
        }
      } else if (action === 'edit') {
        let routeName = ''
        if (this.quote.purchaseType === 'license') {
          this.$router.push({
            path: `/purchase/license/${this.quote.licenseType}`,
            query: { id: this.quote.id }
          })
          return
        }
        switch (this.quote.purchaseType) {
          case 'bookstore-codes':
            routeName = 'access_codes_configuration'
            break
          case 'added-seats':
            routeName = 'new_add-seats_session'
            break
          case 'renewal':
            routeName = 'new_renewal_session'
            break
          case 'upgrade':
            routeName = 'new_upgrade_session'
            break
        }

        this.$router.push({
          name: routeName,
          query: { id: this.quote.id }
        })
      } else if (action === 'completePurchase') {
        // create license object
        const licenseQuote = {
          licenseLevel: this.quote.licenseLevel,
          seats: this.quote.seats,
          startDate: this.quote.startDate,
          endDate: new Date(this.quote.endDate),
          pricePerSeat: this.quote.pricePerSeat,
          subtotal: this.quote.subtotal,
          licenseType: this.licenseType
        }

        mergePurchaseData({
          purchaseType: 'license',
          configureLicenseForm: licenseQuote,
          educatorContactForm: {
            contactFirstName: this.quote.contactFirstName,
            contactLastName: this.quote.contactLastName,
            contactEmail: this.quote.contactEmail,
            contactRoles: this.quote.contactRoles,
            contactSubjects: this.quote.contactSubjects,
            institution: this.quote.institution,
            institutionCountry: this.quote.institutionCountry,
            institutionAddress: this.quote.institutionAddress,
            institutionAddress2: this.quote.institutionAddress2,
            institutionCity: this.quote.institutionCity,
            institutionState: this.quote.institutionState,
            institutionZipCode: this.quote.institutionZipCode,
            institutionWebsite: this.quote.institutionWebsite
          },
          ...(this.id && { id: this.id })
        })

        this.$router.push({ name: 'new_license_contact' })
      }
    },
    async refreshQuote() {
      const { quote } = await client.purchases.getQuote({ id: this.id })
      this.quote = quote || {}
    },
    printDate(date) {
      return format(new Date(date), 'MMMM dd, yyyy')
    }
  },
  async mounted() {
    this.gettingQuote = true

    const { quote } = await client.purchases.getQuote({ id: this.id })
    this.quote = quote || {}
    this.startDate = quote.startDate ? new Date(quote.startDate) : undefined
    this.endDate = quote.endDate ? new Date(quote.endDate) : undefined

    if (this.isAdmin && this.quote?.licenseId) {
      const licenseRequest = await client.licenses.get({
        id: this.quote.licenseId
      })

      this.licenseName = licenseRequest.name
    }

    if (isBefore(new Date(this.quote.quoteExpires), new Date())) {
      this.$error('Sorry, this quote is expired')
      if (!this.isAdmin) {
        this.$router.push({
          name: 'new_license_session',
          params: { licenseType: this.quote.licenseType }
        })
      }
    }
    clearPurchaseData()
    mergePurchaseData({ ...quote })

    window.addEventListener('beforeprint', this.enablePrintView)
    window.addEventListener('afterprint', this.disablePrintView)
    this.gettingQuote = false
  },
  unmounted() {
    window.removeEventListener('beforeprint', this.enablePrintView)
    window.removeEventListener('afterprint', this.disablePrintView)
  }
}
</script>

<style lang="scss" scoped>
.contact-name {
  display: flex;
}

.email-sent {
  color: #fff;
  background-color: #3d2c60;
  border-radius: 6px;
  padding: 16px;
  padding-left: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-button--beta {
    border: 1px solid #fff;
    @media print {
      display: none;
    }
  }
}

.card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 60px 64px;
  @media print {
    border-radius: 0px;
    padding: 10px 64px;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media print {
    margin-bottom: 10px;
  }
}
.advancedFeatures--p {
  @media print {
    a[href]:after {
      display: none;
      visibility: hidden;
    }
  }
}

.right-grouping {
  text-align: right;
}
.header-right {
  margin-top: 0;
  font-size: 30px;
}
.button-group {
  display: flex;
  flex-direction: row-reverse;
  @media print {
    display: none;
  }
}
.subheader-right {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.002em;
  text-align: right;

  .date-item {
    display: inline-block;
    margin-left: 10px;
    label,
    span {
      display: block;
      margin-bottom: 0;
    }
  }
}
.grayscale {
  filter: grayscale(1);
}
.quote-section {
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 16px;
  margin-bottom: 8px;
  @media print {
    margin-bottom: 8px;
    padding-bottom: 0;
  }
}
.section-header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.016em;
  text-align: left;
  color: #221f20;
  @media print {
    font-size: 16px;
    line-height: normal;
    margin: 0px;
  }
}
.bottom-spacing {
  margin-bottom: 16px;
  @media print {
    margin-bottom: 0px;
  }
}
.details {
  list-style: none;
  padding-left: 20px;
  margin-top: 11px;
  margin-bottom: 11px;
  @media print {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.item {
  display: flex;
  align-items: center;
  margin: 0;
}
.item-label {
  width: 220px;
  font-weight: inherit;
  margin-bottom: 0px;
}
.item-value {
  margin-left: 40px;
  font-size: 16px;
  @media print {
    margin-bottom: 0px;
    font-size: 16px;
  }
}

.charges-summary {
  transition: opacity 0.1s linear;
  &.loading {
    opacity: 0.5;
    transition: opacity 0.3s linear;
  }
}

.cost-per-seat-input {
  display: inline-block;
  width: 80px;
}
.quote-notes {
  margin: 3rem 0 6rem 0;
  @media print {
    margin: 0;
  }
}
.reset-pricing-button {
  @media print {
    display: none;
  }
}
.notes-field {
  height: 12rem;
  @media print {
    height: auto;
    border: none !important;
  }
}
address {
  margin-top: 1rem;
  line-height: 20px;
  @media print {
    margin-bottom: 5px;
  }
}

.quote-history {
  margin-top: 50px;
}
.email-input {
  font-size: 14px;
  width: 300px;
}
.name-input,
.institution-input {
  font-size: 14px;
  width: 300px;
}

.custom-price-disclaimer {
  color: $plum;
}
.quote-view {
  @media print {
    padding: 0 !important;
    margin-bottom: 5px !important;
  }
}
h4 {
  @media print {
    margin-bottom: 8px;
  }
}
</style>
