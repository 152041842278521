<template>
  <div
    class="drag-drop"
    :class="{
      'drag-drop--split': showCorrectAnswer
    }"
  >
    <div class="drag-drop__response">
      <h4 v-if="showCorrectAnswer">Student Response</h4>
      <DragDropActionButtons
        ref="buttons"
        :targets="dropTargetsWithLabels"
        :response="responseDropTargets"
        @move="onDropTargetChange"
        v-slot="{ onFocusin, onFocusout, onKeydown }"
      >
        <div
          class="drag-drop-question"
          @focusin="onFocusin"
          @focusout="onFocusout"
          @keydown="onKeydown"
        >
          <DragDropArea
            :type="component.renderType"
            :variable-context="variableContext"
            :text="component.dropAreaText ?? ''"
            :choices="component.choices"
            :response="responseDropTargets"
            :target-states="targetStatuses"
            :targets="dropTargetsWithLabels"
            :view-as-student="viewAsStudent"
            @move="onDropTargetChange"
          />
          <p class="drag-drop__invalid-error" v-if="showErrorHelpText">
            Wrong answers indicated by dotted lines.
          </p>
          <p class="drag-drop__invalid-error" v-if="invalidState">
            {{ invalidState.message ?? 'Please select a valid drop area' }}
          </p>
          <div>
            <label v-if="draggableChoices.length > 0" for="choices"
              >Choices:</label
            >

            <FormButton
              v-if="Object.keys(responseDropTargets).length > 0"
              destructive
              link
              @click="resetDropTargets"
              class="drag-drop-reset-button"
            >
              Reset <Icon icon="rotate-left" />
            </FormButton>
          </div>

          <template v-if="draggableChoices.length > 0">
            <ul id="choices" class="drag-drop__choices">
              <li
                class="drag-drop__choice-wrapper"
                v-for="choice in availableChoices"
                :key="choice.id"
              >
                <DragDropChoice
                  :data-choice="choice.id"
                  class="drag-drop__choice"
                  :type="inherited.component.renderType"
                  :choice="choice"
                  :variable-context="variableContext"
                  :error="invalidState?.choice === choice.id"
                  :view-as-student="viewAsStudent"
                  @move="onDropTargetChange"
                />
              </li>
            </ul>
          </template>
        </div>
      </DragDropActionButtons>
    </div>
    <DragDropAnswerKey
      v-if="showCorrectAnswer"
      class="drag-drop__answer-key"
      :component="component"
      :response="responseDropTargets"
      :variable-context="variableContext"
    />
  </div>
</template>

<script setup>
import { ref, computed, inject, watch } from 'vue'
import { scramble } from 'src/shared/utils/array-randomizer.js'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import { indexToLetter } from 'src/shared/utils/index-to-letter.js'
import DragDropChoice from './DragDropChoice.vue'
import DragDropArea from './DragDropArea.vue'
import DragDropActionButtons from './DragDropActionButtons.vue'
import DragDropAnswerKey from './DragDropAnswerKey.vue'

const emit = defineEmits(['canSubmit'])
const props = defineProps({
  componentResponse: {
    type: Object,
    default: undefined
  },
  variableContext: {
    type: Object,
    required: true
  },
  showCorrectAnswer: {
    type: Boolean,
    default: false
  },
  grading: {
    type: Boolean,
    default: false
  },
  viewAsStudent: {
    type: Boolean,
    default: false
  }
})

const inherited = inject('inherited')
const modal = inject('$modal')

const dropTargetsWithLabels = computed(
  () =>
    inherited.value.component?.dropTargets?.map((target, index) => ({
      ...target,
      label: target.label ? target.label : indexToLetter(index)
    })) ?? []
)

const component = computed(() => inherited.value.component)
const responseDropTargets = computed(
  () =>
    (props.componentResponse
      ? props.componentResponse.get('value')?.toJSON()
      : inherited.value.componentResponse.value) ?? {}
)

const draggableChoices = computed(() => {
  const usedChoices = Object.values(responseDropTargets.value) ?? []
  const draggableTargets = dropTargetsWithLabels.value
    .filter(dt => dt.targetType !== 'dropdown')
    .map(dt => dt.id)

  // We don't need to bother with finding draggable choices if there aren't any draggable targets
  let choices
  if (draggableTargets.length === 0) {
    choices = []
  } else {
    choices = (component.value.choices ?? [])
      .filter(
        choice =>
          choice.validTargets.length === 0 ||
          choice.validTargets.some(id => draggableTargets.includes(id))
      )
      .map(choice => ({
        ...choice,
        remaining: choice.count
          ? choice.count - usedChoices.filter(id => id === choice.id).length
          : Infinity
      }))
  }

  const seed = `${inherited.value.response.id ?? Math.random()}${
    component.value._id
  }`
  return inherited.value.component.randomizedChoices
    ? scramble(seed, choices)
    : choices
})

const availableChoices = computed(() =>
  draggableChoices.value.filter(
    choice => choice.remaining === Infinity || choice.remaining > 0
  )
)

const emptyDropTargetCount = computed(
  () =>
    component.value.dropTargets.filter(
      dropTarget => !responseDropTargets.value[dropTarget.id]
    ).length
)

watch(
  () => emptyDropTargetCount.value === 0 || !!component.value.allowEmptyTargets,
  canSubmit => {
    emit('canSubmit', canSubmit)
  },
  {
    immediate: true
  }
)

const responseToCheck = computed(() => {
  return (
    (props.grading
      ? inherited.value.componentResponse?.value
      : inherited.value.prevResponse?.value) ?? {}
  )
})
const invalidState = ref()

function matchCondition(condition, response) {
  return condition.targets.map(target => {
    const selectedChoice = response[target.targetId]
    const matches = selectedChoice
      ? target.choices === 'all' ||
        target.choices.some(
          choice =>
            choice === 'all' || choice.toString() === selectedChoice.toString()
        )
      : target.choices.includes('empty')
    return { target: target.targetId, matches }
  })
}

const targetStatuses = computed(() => {
  const hasPreviousResponse =
    typeof inherited.value.prevResponse.correct === 'boolean' ||
    typeof inherited.value.prevResponse.isCorrect === 'boolean'
  const hasOneCorrectAnswer =
    component.value.conditions.filter(condition => condition.isCorrect)
      .length === 1

  const canShowIncorrectTargets =
    (props.grading || hasPreviousResponse) &&
    hasOneCorrectAnswer &&
    component.value.showIncorrectIndicators &&
    inherited.value.response.assignment?.showIndicators

  if (canShowIncorrectTargets) {
    const condition = component.value.conditions.find(
      condition => condition.isCorrect
    )
    if (condition) {
      return {
        ...Object.fromEntries(
          matchCondition(condition, responseToCheck.value)
            .map(({ target, matches }) => {
              if (
                responseToCheck.value[target] ===
                responseDropTargets.value[target]
              ) {
                return [target, matches ? 'correct' : 'incorrect']
              } else if (target === invalidState.value?.targetId) {
                return [target, 'invalid']
              }
            })
            .filter(x => !!x)
        )
      }
    }
  }

  return invalidState.value
    ? {
        [invalidState.value.targetId]: 'invalid'
      }
    : {}
})

const showErrorHelpText = computed(() => {
  return Object.values(targetStatuses.value).some(
    status => status === 'incorrect'
  )
})

function onDropTargetChange(options) {
  const choice = component.value.choices?.find(
    choice => choice.id === options.choice
  )

  if (
    choice &&
    options.destTarget &&
    choice.validTargets.length !== 0 &&
    !choice.validTargets.includes(options.destTarget)
  ) {
    const target = dropTargetsWithLabels.value.find(
      target => target.id === options.destTarget
    )
    invalidState.value = {
      targetId: options.destTarget,
      choice: choice.id,
      message: target?.invalidResponse
    }
  } else {
    invalidState.value = undefined
  }

  if (props.componentResponse) {
    const value = props.componentResponse.get('value')
    value.doc.transact(() => {
      if (options.destTarget) {
        if (!invalidState.value && options.choice) {
          value.set(options.destTarget, options.choice)
        } else {
          value.delete(options.destTarget)
        }
      }
      if (options.sourceTarget) {
        value.delete(options.sourceTarget)
      }
    })
  } else {
    inherited.value.updateResponse({
      value: {
        ...responseDropTargets.value,
        ...(options.destTarget && {
          [options.destTarget]: invalidState.value ? undefined : options.choice
        }),
        ...(options.sourceTarget && { [options.sourceTarget]: undefined })
      }
    })
  }
}

const resetDropTargets = async () => {
  const { status } = await modal.show(ConfirmModal, {
    prompt: `Are you sure you want to clear all your choices?`
  })
  if (status === 'ok') {
    if (props.componentResponse) {
      props.componentResponse.get('value').clear()
    } else {
      inherited.value.updateResponse({
        value: {}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.drag-drop {
  margin-bottom: 20px;
}

.drag-drop--split {
  display: flex;
  flex-wrap: wrap;

  .drag-drop__response {
    width: 50%;
    padding: 8px 16px 0 0;
  }

  .drag-drop__answer-key {
    width: 50%;
  }
}

.drag-drop__choices {
  max-width: 800px;
  background-color: #eee;
  padding: 10px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 0;
  touch-action: none;
}

.drag-drop__choice-wrapper {
  margin: 10px;
  position: relative;
}

.drag-drop__invalid-error {
  margin: 8px 0 8px 0;
  color: $color-error;
  font-weight: bold;
}
.drag-drop__answer-error {
  margin: 8px 0 8px 0;
  color: gray;
  font-weight: bold;
}
.drag-drop-reset-button {
  margin-left: 8px;
}
</style>
