<template>
  <collapse-panel
    :title="'Basic Info (Displays in activity library)'"
    :collapsed="false"
    show-on-print
  >
    <div class="activity-inner-panel">
      <div class="left-panel-side">
        <form-group class="form-group-section">
          <div class="activity-input-group">
            <label for="name" class="control-label">Name</label>
            <text-input
              id="name"
              v-model="activity.name"
              rules="required"
              data-testid="activity-name"
              label="Name"
            />
          </div>
        </form-group>
        <div class="activity-input-group">
          <label for="description">Short description</label>
          <text-input
            id="description"
            v-model="activity.description"
            rules="max:140"
            data-testid="activity-description"
            label="Short description"
            help-text="This will be displayed in the activity library."
          />
        </div>
        <div v-if="canManageFreeActivities" class="activity-input-group">
          <checkbox v-model="activity.isFree">
            Make freely available?
          </checkbox>
        </div>
        <checkbox v-model="activity.isSplitViewDefault">
          Default to split view?
        </checkbox>
      </div>
      <div class="right-panel-side">
        <div>
          <label for="thumbnail-image">Thumbnail Image</label>
          <div class="thumbnail-image-container">
            <div>
              <div
                class="thumbnail-image"
                :style="{ backgroundImage: `url(${thumbnailSrc})` }"
              />
              <loading-container
                class="uploading-container"
                :class="{ uploading }"
                :loading="uploading"
              />
            </div>
          </div>
          <input
            id="thumbnail-image"
            ref="fileInput"
            type="file"
            accept="image/*"
            aria-describedby="thumbnail-image-help"
            @change="fileChange($event)"
          />
          <span id="thumbnail-image-help" class="help-block"
            >This image will be displayed in the activity library.</span
          >
        </div>
      </div>
    </div>
  </collapse-panel>
</template>

<script>
import { mapGetters } from 'vuex'
import client from 'src/shared/api-client'
import defaultThumbnail from 'src/assets/img/activity-card/pivot-interactives-logo-color.png'

export default {
  name: 'ActivityBasicInfoSection',
  inject: ['inherited'],
  components: {},
  data() {
    return {
      uploading: false,
      thumbnailCacheBuster: Date.now()
    }
  },
  computed: {
    ...mapGetters(['canManageLibrary', 'canManageFreeActivities']),
    activity() {
      return this.inherited.activity
    },
    thumbnailSrc() {
      if (this.activity.thumbnail) {
        return `${this.activity.thumbnail}?rnd=${this.thumbnailCacheBuster}`
      }
      return defaultThumbnail
    },
    thumbNailHelp() {
      return `Example: //${PI_API_HOST}/api/instances/assets?path=june_activities/mag-ramp.jpg If no thumbnail is provided, a default image will be shown in the library.`
    }
  },
  methods: {
    async fileChange(event) {
      this.uploading = true

      try {
        const file = event.target.files[0]
        const { name } = file
        const extension = name.split('.').slice(-1)[0].toLowerCase()
        const fileName = this.activity.id
          ? `activity-${this.activity.id}-thumbnail.${extension}`
          : `${Date.now()}.${extension}`

        const src = await client.assets.upload({
          file,
          name: fileName,
          type: 'image'
        })

        this.activity.thumbnail = `//${PI_API_HOST}${src}`
      } finally {
        this.uploading = false
        if (this.$refs.fileInput) this.$refs.fileInput.value = null
        this.thumbnailCacheBuster = +new Date()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#thumbnail-image {
  margin-top: 16px;
}

.thumbnail-image-container {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  border: 1px solid #ccc;
  overflow: hidden;
}

.thumbnail-image-container > div {
  position: relative;
  height: 0px;
  padding-bottom: 56.25%;
}

.uploading-container,
.thumbnail-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
}

.uploading-container.uploading {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-group-section {
  margin: 0;
}
</style>
