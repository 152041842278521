<template>
  <modal @submit="submit" style="width: 600px">
    <modal-header>External Rostering</modal-header>
    <modal-body>
      <loading-container :loading="isLoading">
        <div>Select Classes to add from Rostering Service.</div>
        <div class="pi-classes row">
          <list :items="rosteredClasses" class="class-list col-xs-12">
            <list-column>
              <template #title>
                <checkbox v-model="allSelected" :indeterminate="someSelected">
                  <span class="sr-only">Select All</span>
                </checkbox>
              </template>
              <template v-slot="{ id }">
                <checkbox
                  v-if="!isClassAdded(id)"
                  v-model="selectedClasses"
                  :value="id"
                />
              </template>
            </list-column>
            <list-column v-slot="{ name }" title="Name">
              <p>{{ name }}</p>
            </list-column>
            <!--
              This column is temporarily removed until we have a better solution for loading the data.
              See the comment in the GetExternalClassesForInstructor route.
            -->
            <!--
            <list-column v-slot="{ students }" title="Rostered Students">
              <p>{{ students }}</p>
            </list-column>
            -->
            <list-column title="Status" v-slot="{ id }">
              <p v-if="isClassAdded(id)">Rostered</p>
              <p v-else>Available</p>
            </list-column>
            <list-column title="Term" v-slot="{ termName }">
              <p>{{ termName }}</p>
            </list-column>
          </list>
        </div>
      </loading-container>
    </modal-body>
    <modal-actions>
      <modal-button-submit>
        <icon icon="plus" /> Add Classes</modal-button-submit
      >
      <modal-button-cancel>Cancel</modal-button-cancel>
    </modal-actions>
  </modal>
</template>

<script setup>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonCancel,
  ModalButtonSubmit
} from 'src/shared/components/modals/components'

import client from 'src/shared/api-client'
import { useFlash } from 'src/shared/hooks/flash'
import { ref, computed, onBeforeMount } from 'vue'

const props = defineProps({
  instructorId: {
    type: String,
    default: undefined,
    required: true
  }
})

const flash = useFlash()
let isLoading = ref(true)
let rosteredClasses = ref([])
let currentClasses = ref([])
let selectedClasses = ref([])

const availableClasses = computed(() => {
  return rosteredClasses.value
    .map(item => item.id)
    .filter(
      selectedClass =>
        !currentClasses.value.some(
          currentClass =>
            selectedClass === currentClass.externalRoster?.externalId
        )
    )
})

const allSelected = computed({
  get: () => {
    return selectedClasses.value.length === availableClasses.value.length
  },

  set: isSelected => {
    if (isSelected) {
      selectedClasses.value = [...availableClasses.value]
    } else {
      selectedClasses.value = []
    }
  }
})
const someSelected = computed(
  () => selectedClasses.value.length > 0 && !allSelected.value
)

const getRosteredClasses = async () => {
  rosteredClasses.value = await client.classes.getExternal()
  isLoading.value = false
}

const getInstructorClasses = async () => {
  const { classes } = await client.classes.search({ limit: 1000 })
  currentClasses.value = classes.filter(e => e.isArchived == false)
}

const isClassAdded = id => {
  return currentClasses.value.some(e => e.externalRoster?.externalId == id)
}

onBeforeMount(async () => {
  await Promise.all([getInstructorClasses(), getRosteredClasses()])
})

async function submit(e) {
  let filteredClasses = rosteredClasses.value.filter(e =>
    selectedClasses.value.includes(e.id)
  )
  filteredClasses = filteredClasses.map(klass => klass.id)
  try {
    await client.classes.createBulk({ classes: filteredClasses })
    flash.success(`${filteredClasses.length} classes created`)
    e.done()
  } catch (error) {
    if (error.body?.operations) {
      error.body.operations.forEach(operation => {
        const name =
          filteredClasses.find(c => c.id == operation.id)?.name ??
          'Unknown Class'
        flash.error(`Could not import class ${name}: ${operation.error}`)
      })
      e.done(false)
    } else {
      e.done(false)
      throw error
    }
  }
}
</script>
