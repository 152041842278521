import { inject } from 'vue'

export function useFlash() {
  const flash = inject('_flash')

  return {
    error(text, options = {}) {
      flash.add({
        ...options,
        level: 'error',
        text
      })
    },
    success(text, options = {}) {
      flash.add({
        ...options,
        level: 'success',
        text
      })
    },
    clear() {
      flash.clear()
    }
  }
}
