<template>
  <li>
    <sidebar-collapse :key="`typeCollapse`">
      <template #header> Type </template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('institution')"
              @update:modelValue="value => changeCategory('institution', value)"
              @click.stop
            >
              Institution
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('student')"
              @update:modelValue="value => changeCategory('student', value)"
              @click.stop
            >
              Student
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('trial')"
              @update:modelValue="value => changeCategory('trial', value)"
              @click.stop
            >
              Trial
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'TypeFilter',
  inject: ['browseLicenses'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  data() {
    return {
      type: '',
      types: []
    }
  },
  created() {
    this.types = this.browseLicenses.query.types
      ? [...this.browseLicenses.query.types]
      : ''
  },
  methods: {
    isChecked(t) {
      return this.types.includes(t)
    },
    changeCategory(category, isChecked) {
      if (!isChecked) {
        this.types = [...this.types].filter(t => t !== category)
      } else {
        this.types.push(category)
      }
      if (this.types.length) {
        return this.browseLicenses.updateQuery({
          page: 1,
          types: [...this.types]
        })
      }
      return this.browseLicenses.updateQuery({
        page: 1,
        types: undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
