<template>
  <loading-container :loading="isLoading" class="users-list">
    <paginated-list
      v-bind="pagination"
      data-testid="users-list"
      @loadpage="loadPage"
    >
      <list-column
        v-slot="{ firstName, lastName }"
        title="Name"
        property="lastName"
      >
        <span v-if="lastName || firstName">
          {{ `${lastName}, ${firstName}` }}
        </span>
      </list-column>
      <list-column title="Signup" v-slot="{ isPendingSignup }"
        >{{ isPendingSignup ? 'Pending' : '' }}
      </list-column>
      <list-column title="Email" property="email" />
      <list-column title="Role" property="role" />
      <list-column title="License" v-slot="{ siteLicense }"
        >{{ siteLicense ? siteLicense.type : '' }}
      </list-column>
      <list-column v-slot="{ role, id }" title="Actions">
        <router-link
          :to="{
            name:
              role === 'teacher'
                ? 'admin_manage_instructor'
                : 'admin_manage_student',
            params: { id }
          }"
        >
          Manage
        </router-link>
        <span
          v-if="role === 'teacher' || role === 'student'"
          class="options-separator"
        >
          |
        </span>
        <router-link
          v-if="role === 'teacher' || role === 'student'"
          :to="{
            name: 'admin_manage_user_profile',
            params: { id }
          }"
        >
          Profile
        </router-link>
        <template v-if="role !== 'admin'">
          <span class="options-separator"> | </span>
          <form-button class="impersonate-button" link @click="impersonate(id)"
            >Impersonate</form-button
          >
        </template>
      </list-column>
    </paginated-list>
  </loading-container>
</template>

<script>
import client from '../../../../shared/api-client'
import { mapActions } from 'vuex'

export default {
  name: 'UsersList',
  inject: ['browseUsers', '$modal'],
  computed: {
    empty() {
      return this.users.length === 0
    },
    users() {
      return this.browseUsers.users
    },
    pagination() {
      return {
        page: this.browseUsers.query.page,
        count: this.browseUsers.userCount,
        limit: this.browseUsers.userLimit,
        items: this.browseUsers.users || []
      }
    },
    isLoading() {
      return this.browseUsers.isLoadingUsers
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    loadPage({ page }) {
      this.browseUsers.updateQuery({ page: page.toString() })
    },
    async impersonate(userId) {
      await client.users.startImpersonating(userId)
      await this.refreshAuth()
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
.users-list {
  grid-area: results;

  &__list {
    list-style: none;
    display: grid;
    gap: 32px;
    grid-auto-flow: row;
    padding: 0;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }

  &__empty {
    margin-top: 1em;
    text-align: center;

    & > button {
      margin: 0 8px;
    }
  }
}
</style>
