<template>
  <div
    class="section-components"
    :class="{
      'section-components--split':
        viewMode === 'split' &&
        !splitViewComponentEnabled &&
        leftComponents.length > 0,
      'split-components-view': splitViewComponentEnabled && viewMode === 'split'
    }"
  >
    <template
      v-if="
        viewMode === 'split' &&
        !splitViewComponentEnabled &&
        leftComponents.length > 0
      "
    >
      <div class="section-components__left">
        <div class="section-components__sticky">
          <div v-for="(component, index) in leftComponents" :key="index">
            <slot :component="component" />
          </div>
        </div>
      </div>
      <div class="section-components__right">
        <div v-for="(component, index) in rightComponents" :key="index">
          <slot :component="component" />
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="(component, index) in components" :key="index">
        <slot :component="component" />
      </div>
    </template>
  </div>
</template>

<script>
const LEFT_COMPONENT_TYPES = [
  'EmbeddedInstance',
  'StudentInstance',
  'InstructorInstance',
  'IFrame',
  'PhetIOSim',
  'PhetIOSimulation'
]

export default {
  name: 'ActivitySectionComponents',
  inject: ['inherited'],
  props: {
    viewMode: {
      type: String,
      default: 'inline'
    },
    splitViewComponentEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    section() {
      return this.inherited.section
    },

    components() {
      return this.section.components.map((component, index) => {
        if (component.componentType === 'SplitView') {
          return {
            ...component,
            leftContent: component.leftContent.map((leftComponent, i) => {
              return {
                ...leftComponent,
                isQuestion: this.isComponentQuestion(leftComponent)
              }
            }),
            rightContent: component.rightContent.map((rightComponent, i) => {
              return {
                ...rightComponent,
                isQuestion: this.isComponentQuestion(rightComponent)
              }
            })
          }
        }
        const isQuestion = this.isComponentQuestion(component)

        return {
          ...component,
          isQuestion
        }
      })
    },
    leftComponents() {
      return this.components
        .filter(
          component =>
            LEFT_COMPONENT_TYPES.includes(component.componentType) ||
            component.componentType === 'SplitView'
        )
        .map(component => {
          if (component.componentType !== 'SplitView') {
            return component
          }
          return [...component.leftContent]
        })
        .flat()
    },
    rightComponents() {
      return this.components
        .filter(
          component =>
            !LEFT_COMPONENT_TYPES.includes(component.componentType) ||
            component.componentType === 'SplitView'
        )
        .map(component => {
          if (component.componentType !== 'SplitView') {
            return component
          }
          return [...component.rightContent]
        })
        .flat()
    }
  },
  methods: {
    isComponentQuestion(component) {
      return (
        component.componentType.includes('Question') ||
        component.componentType === 'GeneralInstruction'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.section-components {
  &--split {
    display: flex;
    justify-content: space-evenly;
  }

  &__left {
    width: 100%;
    min-width: 50%;
  }

  &__sticky {
    position: sticky;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 90px;
    max-height: calc(100vh - 100px);
    border: 1px solid #bcbcbc !important;
    padding: 0 12px;
  }

  &__right {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    min-width: 48%;
  }
}
</style>
