<template>
  <tr>
    <td rowspan="2" class="data-grid-header__menu-cell" :width="65">
      <div class="data-grid-header__menu-wrapper">
        <button-dropdown
          v-if="showGridActionsMenu"
          ref="button"
          tabindex="-1"
          tertiary
          button-class="data-grid-header__menu-button"
          data-tablenav
          @focusin="
            () => $emit('focus:cell', { column: null, row: null, value: null })
          "
        >
          <template #button>
            <icon icon="gear" />
            <span class="sr-only">table actions menu</span>
          </template>

          <dropdown-action
            v-if="allowDownloadCsv"
            @click="$gridActions.downloadDataAsCSV"
          >
            Download Data as CSV
          </dropdown-action>
          <dropdown-action
            v-if="allowReset"
            :disabled="readonly"
            @click="$gridActions.reset"
          >
            Reset
          </dropdown-action>
          <li
            v-if="allowDownloadCsv || allowReset"
            role="separator"
            class="divider"
          />
          <template v-if="allowBluetooth">
            <dropdown-action
              :disabled="readonly"
              @click="$gridActions.configureBluetoothDevices"
              >Configure Bluetooth Devices</dropdown-action
            >
            <li role="separator" class="divider" />
          </template>
          <dropdown-link :to="{ name: 'using_table_graph_new' }">
            Data Table & Graph Help
          </dropdown-link>
        </button-dropdown>
      </div>
    </td>
    <data-grid-column-primary-header
      v-for="(column, columnIndex) in columnsArray"
      :key="columnIndex"
      :column="column"
      :column-index="columnIndex"
      :can-remove-column="canRemoveColumn"
      :readonly="readonly"
      :focused-cell="focusedCell"
      @focus:cell="props => $emit('focus:cell', props)"
    />
  </tr>
  <tr>
    <data-grid-column-secondary-header
      v-for="(column, columnIndex) in columnsArray"
      :key="columnIndex"
      :column="column"
      :column-index="columnIndex"
      :readonly="readonly"
      :focused-cell="focusedCell"
      @focus:cell="props => $emit('focus:cell', props)"
    />
  </tr>
  <data-grid-bluetooth-header :columns-array="columnsArray" />
</template>

<script>
import * as Y from 'yjs'
import DataGridColumnPrimaryHeader from 'src/shared/components/grid-graph/DataGridColumnPrimaryHeader'
import DataGridColumnSecondaryHeader from 'src/shared/components/grid-graph/DataGridColumnSecondaryHeader'
import DataGridBluetoothHeader from 'src/shared/components/grid-graph/DataGridBluetoothHeader'

export default {
  name: 'DataGridHeader',
  components: {
    DataGridColumnPrimaryHeader,
    DataGridColumnSecondaryHeader,
    DataGridBluetoothHeader
  },
  emits: ['focus:cell'],
  inject: ['$gridActions'],
  props: {
    columns: {
      type: Y.Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showGridActionsMenu: {
      type: Boolean,
      default: true
    },
    allowDownloadCsv: {
      type: Boolean,
      default: true
    },
    allowBluetooth: {
      type: Boolean,
      default: true
    },
    allowReset: {
      type: Boolean,
      default: false
    },
    focusedCell: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      columnsArray: this.columns.toArray()
    }
  },
  beforeUnmount() {
    this.unsubscribe?.()
  },
  computed: {
    canRemoveColumn() {
      return this.columnsArray.length > 1 // don't remove column if there is only one
    }
  },
  watch: {
    columns: {
      handler() {
        this.unsubscribe?.()
        const columns = this.columns
        const onChange = () => {
          this.columnsArray = columns.toArray()
        }
        onChange()
        columns.observeDeep(onChange)
        this.unsubscribe = () => columns.unobserveDeep(onChange)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.data-grid-header__menu-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-grid-header__menu-button {
  &:focus,
  &:hover {
    background-color: $teal;
    color: #fff;
    border: none;
  }
}
</style>

<style lang="scss" scoped>
.data-grid-header__menu-cell {
  width: $grid-first-column-default-width;
}
</style>
