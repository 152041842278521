<template>
  <li>
    <sidebar-collapse :key="`statusCollapse`">
      <template #header> Status </template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('paid')"
              @update:modelValue="value => changeCategory('paid', value)"
              @click.stop
            >
              Paid
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('fulfilled')"
              @update:modelValue="value => changeCategory('fulfilled', value)"
              @click.stop
            >
              Fulfilled
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('expired')"
              @update:modelValue="value => changeCategory('expired', value)"
              @click.stop
            >
              Expired
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('quote')"
              @update:modelValue="value => changeCategory('quote', value)"
              @click.stop
            >
              Open
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('pending')"
              @update:modelValue="value => changeCategory('pending', value)"
              @click.stop
            >
              Pending
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'StatusFilter',
  inject: ['browseQuotes'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  data() {
    return {
      status: ''
    }
  },
  created() {
    this.status = this.browseQuotes.query.status || ''
  },
  methods: {
    isChecked(t) {
      return this.status === t
    },
    changeCategory(category, isChecked) {
      if (!isChecked) {
        this.status = ''
      } else {
        this.status = category
      }
      if (this.status.length) {
        return this.browseQuotes.updateQuery({
          page: 1,
          status: this.status
        })
      }
      return this.browseQuotes.updateQuery({ page: 1, status: undefined })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
