<template>
  <div class="activity-card-actions">
    <button-link
      :to="{ name: 'preview_activity', params: { id: activity.id } }"
      class="preview-btn"
      ref="popoverAnchorRef"
    >
      <template v-if="showVersionHistory">
        <base-popover
          :anchor-ref="popoverAnchorRef"
          :visible="popoverVisible"
          placement="top"
          >New Version Available
        </base-popover>
        <div
          class="version-icon"
          @mouseover="openPopover"
          @mouseout="closePopover"
          @focusin="openPopover"
          @focusout="closePopover"
        >
          <icon :icon="['far', 'lightbulb']" style="color: #000" />
        </div>
      </template>
      <template v-if="!showVersionHistory && showChanges">
        <base-popover
          :anchor-ref="popoverAnchorRef"
          :visible="popoverVisible"
          placement="top"
          >Updates Available
        </base-popover>
        <div
          class="version-icon"
          @mouseover="openPopover"
          @mouseout="closePopover"
          @focusin="openPopover"
          @focusout="closePopover"
        >
          <icon :icon="['far', 'lightbulb']" style="color: #000" />
        </div>
      </template>
      Preview
    </button-link>
    <router-link
      v-if="canEdit"
      class="activity-card-actions__edit"
      :to="{ name: 'activity', params: { id: activity.id, tab: 'content' } }"
    >
      <icon icon="pencil" />
      Edit
    </router-link>
    <div class="activity-card-actions__spacer" />
    <button-dropdown v-if="licenseActive" secondary right up>
      <template #button>
        <icon icon="ellipsis-h" />
        <span class="sr-only">Activity Menu</span>
      </template>
      <dropdown-action @click="$emit('copy', activity)">
        Copy to Library
      </dropdown-action>
      <dropdown-action
        v-if="
          browseActivities.canManageLibraryActivities &&
          activity.status !== 'archived'
        "
        @click="$emit('move', activity)"
      >
        Move to Library
      </dropdown-action>
      <dropdown-action
        v-if="!activity.isArchived && !isLibraryViewer"
        @click="$emit('share', activity)"
      >
        <template v-if="!activity.isSharedWithCommunity">
          Share to Community Library
        </template>
        <template v-else> Remove from Community Library </template>
      </dropdown-action>
      <dropdown-action v-if="!activity.isArchived" @click="assign">
        Assign
      </dropdown-action>

      <dropdown-action
        v-if="!activity.isArchived && !isLibraryViewer"
        @click="archive(activity)"
      >
        Archive
      </dropdown-action>
      <dropdown-action
        v-else-if="!isLibraryViewer"
        @click="$emit('unarchive', activity)"
      >
        Unarchive
      </dropdown-action>
    </button-dropdown>
  </div>
</template>

<script>
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import { useStore } from 'vuex'
import { ref } from 'vue'
import ActivityVersionModal from 'src/modules/activities/components/ActivityVersionModal.vue'
import ActivityChangesModal from 'src/modules/activities/components/ActivityChangesModal.vue'

export default {
  name: 'ActivityCardEditAction',
  inject: ['browseActivities', 'activityCard', '$modal'],
  emits: ['copy', 'move', 'share', 'assign', 'archive', 'unarchive'],
  setup() {
    const store = useStore()
    const popoverAnchorRef = ref()
    const licenseActive = store.getters.isLicenseActive

    return {
      licenseActive,
      popoverAnchorRef
    }
  },
  data() {
    return {
      popoverVisible: false
    }
  },
  computed: {
    canEdit() {
      return (
        this.licenseActive && !this.activity.isArchived && !this.isLibraryViewer
      )
    },
    activity() {
      return this.activityCard.activity
    },
    showVersionHistory() {
      return this.activity.activityVersions?.length > 0
    },
    showChanges() {
      return this.activity.parentChanges?.length > 0
    },
    isLibraryViewer() {
      return this.browseActivities.isLibraryViewer
    }
  },
  methods: {
    async assign() {
      if (this.showVersionHistory) {
        const { status } = await this.$modal.show(ActivityVersionModal, {
          activityId: this.activity.id,
          activityName: this.activity.name,
          updatedActivity: this.activity.activityVersions[0],
          libraryId: this.activity.libraryId
        })
        if (status !== 'ok') {
          return
        }
      } else if (this.showChanges) {
        const { status } = await this.$modal.show(ActivityChangesModal, {
          activityId: this.activity.id,
          activityName: this.activity.name,
          changelogEntries: this.activity.parentChanges,
          libraryId: this.activity.libraryId,
          parentActivityId: this.activity.pivotParentId,
          hideAssign: false
        })
        if (status !== 'ok') {
          return
        }
      }
      this.$emit('assign', this.activity)
    },
    async archive(activity) {
      const { status } = await this.$modal.show(ConfirmModal, {
        prompt: `Are you sure you want to archive this activity?`
      })
      if (status === 'ok') {
        this.$emit('archive', activity)
      }
    },
    async showVersionModal() {
      await this.$modal.show(ActivityVersionModal, {
        activityId: this.activity.id,
        activityName: this.activity.name,
        updatedActivity: this.activity.revisedVersions[0],
        libraryId: this.activity.library.id,
        hideAssign: true
      })
    },
    async showChangesModal() {
      await this.$modal.show(ActivityChangesModal, {
        activityId: this.activity.id,
        activityName: this.activity.name,
        changelogEntries: this.activity.parentChanges,
        libraryId: this.activity.library.id,
        parentActivityId: this.activity.pivotParentId,
        hideAssign: true
      })
    },
    closePopover() {
      this.popoverVisible = false
    },
    openPopover() {
      this.popoverVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-card-actions {
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 8px;
  }

  &__edit {
    padding: 4px 0 0 0;
  }

  &__spacer {
    flex-grow: 1;
  }
}
.preview-btn {
  position: relative;
}
.version-icon {
  background: #efcc4f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
}
</style>
