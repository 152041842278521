<template>
  <div class="modal-body"><slot /></div>
</template>

<script>
export default {
  name: 'ModalBody'
}
</script>

.
<style lang="scss">
.modal-body {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1;
  margin: 0 -30px;
  padding: 0 30px;
}
</style>
