<template>
  <div class="variable-formatting">
    <div class="variable-formatting__precision">
      <number-input
        id="precision"
        class="variable-formatting__precision-number"
        v-model="precision"
        label="precision"
        aria-label="precision"
        data-focus
      />
      <select-field
        id="precisionType"
        class="variable-formatting__precision-type"
        v-model="precisionType"
        aria-label="Precision Type"
      >
        <option value="decimal">decimal</option>
        <option value="sigfigs">sig figs</option>
      </select-field>
    </div>
    <checkbox v-model="sciNotation">Scientific Notation</checkbox>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const emit = defineEmits([
  'update:precision',
  'update:precisionType',
  'update:sciNotation'
])
const props = defineProps({
  precision: Number,
  precisionType: String,
  sciNotation: Boolean
})

const precision = computed({
  get: () => props.precision,
  set: value => emit('update:precision', value)
})

const precisionType = computed({
  get: () => props.precisionType,
  set: value => emit('update:precisionType', value)
})

const sciNotation = computed({
  get: () => props.sciNotation,
  set: value => emit('update:sciNotation', value)
})
</script>

<style lang="scss" scoped>
.variable-formatting {
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
}

.variable-formatting__precision {
  display: flex;
}

.variable-formatting__precision-number {
  width: 50px;

  :deep(input),
  :deep(input:focus) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.variable-formatting__precision-type {
  :deep(select),
  :deep(select:focus) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}
</style>
