<template>
  <div>
    <slot />
  </div>
</template>

<script>
import * as Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import client from 'src/shared/api-client.ts'

export default {
  name: 'BrowseUsers',
  provide() {
    return {
      browseUsers: Vue.computed(() => ({
        query: this.query,
        userCount: this.userCount,
        userLimit: this.userLimit,
        users: this.users,
        isLoadingUsers: this.isLoadingUsers,
        updateQuery: this.updateQuery,
        loadUsers: this.loadUsers
      }))
    }
  },
  props: {
    query: {
      type: Object,
      required: true,
      validator(value) {
        return typeof value.page === 'number' && typeof value.q === 'string'
      }
    }
  },
  data() {
    return {
      users: [],
      userCount: 0,
      userLimit: 18,
      isLoadingUsers: false
    }
  },
  computed: {
    ...mapGetters(['userId', 'isAdmin']),
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    async loadUsers() {
      const request = client.users.search({
        page: this.query.page,
        limit: this.userLimit,
        ...(!!this.query.q && { q: this.query.q }),
        ...(this.query.role &&
          this.query.role !== 'undefined' &&
          this.query.role !== 'all' && {
            role: [...this.query.role]
          }),
        ...(this.query.licenseType &&
          this.query.licenseType !== 'undefined' &&
          this.query.licenseType !== 'all' && {
            licenseType: [...this.query.licenseType]
          }),
        ...(this.query.disabledReason &&
          this.query.disabledReason !== 'undefined' &&
          this.query.disabledReason !== 'all' && {
            disabledReason: [...this.query.disabledReason]
          }),
        sort: 'name',
        dir: 'asc',
        pending: true
      })
      this.request = request
      const response = await request
      if (request === this.request) {
        this.users = response.page
        this.userCount = response.total
      }
    },
    updateQuery(query) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          ...query
        }
      })
    }
  },
  async created() {
    this.isLoadingUsers = true
    await this.loadUsers()
    this.isLoadingUsers = false
  },
  watch: {
    async query() {
      this.isLoadingUsers = true
      await this.loadUsers()
      this.isLoadingUsers = false
    }
  }
}
</script>
