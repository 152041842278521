<template>
  <li>
    <sidebar-collapse :key="`typeCollapse`">
      <template #header> User Role </template>
      <template #body>
        <sidebar-list>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('admin')"
              @update:modelValue="value => changeRole('admin', value)"
              @click.stop
            >
              Admin
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('sales_admin')"
              @update:modelValue="value => changeRole('sales_admin', value)"
              @click.stop
            >
              Sales Admin
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('content_developer')"
              @update:modelValue="
                value => changeRole('content_developer', value)
              "
              @click.stop
            >
              Content Developer
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('teacher')"
              @update:modelValue="value => changeRole('teacher', value)"
              @click.stop
            >
              Instructor
            </checkbox>
          </li>
          <li class="filter-option">
            <checkbox
              :modelValue="isChecked('student')"
              @update:modelValue="value => changeRole('student', value)"
              @click.stop
            >
              Student
            </checkbox>
          </li>
        </sidebar-list>
      </template>
    </sidebar-collapse>
  </li>
</template>
<script>
import SidebarCollapse from 'src/shared/components/SidebarCollapse'
import SidebarList from 'src/shared/components/SidebarList'
export default {
  name: 'RoleFilter',
  inject: ['browseUsers'],
  components: {
    SidebarCollapse,
    SidebarList
  },
  computed: {
    roles() {
      return this.browseUsers.query.role
    }
  },
  methods: {
    isChecked(t) {
      return this.roles.includes(t)
    },
    changeRole(category, isChecked) {
      let roles = [...this.roles]
      if (!isChecked) {
        roles = [...roles].filter(t => t !== category)
      } else {
        roles.push(category)
      }
      if (roles.length) {
        return this.browseUsers.updateQuery({
          page: 1,
          role: [...roles]
        })
      }
      return this.browseUsers.updateQuery({
        page: 1,
        role: undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-option {
  display: flex;
}
</style>
