<template>
  <async-form @submit="onSubmit" persist>
    <AssignmentDatesCard
      :startDate="assignment.startDate"
      :endDate="assignment.endDate"
      :firstVisibleDate="assignment.firstVisibleDate"
      :lastVisibleDate="assignment.lastVisibleDate"
      :useVisibilityDates="assignment.useVisibilityDates"
      @update:dates="updateDates"
    />
    <div
      class="shadow-md border border-solid border-ui-gray-200 rounded-md p-6 my-10"
      :collapsed="assignment.assignedTo === 'everyone'"
      aria-label="Assign To"
    >
      <div class="font-bold text-black text-3xl mb-4">Assign To</div>
      <div class="assignment-settings__groups">
        <form-group>
          <div>
            <selector-input
              v-model="assignment.assignedTo"
              aria-labelledby="assign-to"
              label="assign-to"
              class="selector-input--horizontal"
            >
              <selector-option value="everyone" title="Everybody" disabled />
              <selector-option
                value="individuals"
                title="Individuals"
                disabled
              />
              <selector-option value="groups" title="Co-Lab Groups" disabled />
            </selector-input>
          </div>
        </form-group>
        <form-group>
          <form-button
            v-if="canAssignStudents"
            class="edit-group-btn"
            secondary
            @click="() => (isAssigningStudents = true)"
          >
            Edit Assigned
            {{ assignment.assignedTo === 'groups' ? 'Groups' : 'Individuals' }}
          </form-button>
          <form-button
            v-if="isAssigningStudents"
            class="edit-group-btn"
            secondary
            @click="revertAssignedStudents"
          >
            Revert Changes
          </form-button>
        </form-group>
        <template v-if="assignment.assignedTo === 'groups'">
          <p class="text-warning">
            All work stays with the existing group. If you move students to a
            different group, they will have access to the work done by the new
            group, not their old group. If all the students in a group are
            removed, this work will be archived; you will need to contact Pivot
            Support to get access to the work in this empty group.
          </p>

          <activity-groups
            ref="activityGroups"
            :roster="roster"
            :groups="groups"
            :disabled="!isAssigningStudents"
            @change="updateGroups"
          />
        </template>
        <activity-subsets
          v-else-if="
            assignment.assignedTo === 'individuals' && isAssigningStudents
          "
          :modelValue="individuals"
          :roster="roster"
          :disabled="!isAssigningStudents"
          @update:modelValue="updateIndividuals"
        />
      </div>
    </div>
    <AssignmentSettingsCard
      class="mb-10"
      v-model="assignment"
      :activities="[activity]"
      editing
    />
    <form-group class="button-group">
      <submit-button class="pull-left">
        <template #default>Save</template>
        <template #submitting>Updating</template>
        <template #submitted>Updated</template>
      </submit-button>
    </form-group>
  </async-form>
</template>

<script setup>
import { computed, watch, ref, toRef } from 'vue'
import { isEqual, isBefore, isAfter } from 'date-fns'
import ActivityGroups from './ActivityGroups'
import ActivitySubsets from './ActivitySubsets'
import { useFlash } from 'src/shared/hooks/flash'
import client from 'src/shared/api-client'
import AssignmentDatesCard from './AssignmentDatesCard.vue'
import AssignmentSettingsCard from './AssignmentSettingsCard.vue'

const flash = useFlash()
const emit = defineEmits(['refresh'])
const props = defineProps({
  assignment: {
    type: Object,
    required: true
  },
  responses: {
    type: Array,
    required: true
  },
  activity: {
    type: Object,
    required: true
  },
  klass: {
    type: Object,
    required: true
  }
})

function cloneAssignment(assignment) {
  return {
    ...assignment,
    startDate: assignment.startDate
      ? new Date(assignment.startDate)
      : undefined,
    endDate: assignment.endDate ? new Date(assignment.endDate) : undefined,
    firstVisibleDate: assignment.firstVisibleDate
      ? new Date(assignment.firstVisibleDate)
      : undefined,
    lastVisibleDate: assignment.lastVisibleDate
      ? new Date(assignment.lastVisibleDate)
      : undefined,
    useVisibilityDates: assignment.useVisibilityDates
  }
}
const assignment = ref(cloneAssignment(props.assignment))
watch(toRef(props, 'assignment'), _assignment => {
  assignment.value = cloneAssignment(_assignment)
})

const isPointTotalOverride = ref(false)

const roster = computed(() =>
  props.klass.roster.map(s => ({
    ...s.student,
    name: `${s.student.firstName} ${s.student.lastName}`
  }))
)

const hasGroupEdits = ref(false)
const groups = ref([])
function revertGroupEdits() {
  hasGroupEdits.value = false
  groups.value = props.responses.map(response => ({
    ...response,
    name: response.groupName
  }))
}
function updateGroups(_groups) {
  hasGroupEdits.value = true
  groups.value = _groups
}

const hasIndividualEdits = ref(false)
const individuals = ref([])
function revertIndividualEdits() {
  hasIndividualEdits.value = false
  individuals.value = props.responses.map(r => r.studentId)
}
function updateIndividuals(_individuals) {
  hasIndividualEdits.value = true
  individuals.value = _individuals
}

const updateDates = dates => {
  assignment.value = {
    ...assignment.value,
    ...dates
  }
}

watch(
  () => assignment.value.useVisibilityDates,
  newValue => {
    if (newValue === true) {
      assignment.value.firstVisibleDate = assignment.value.startDate
      assignment.value.lastVisibleDate = undefined
    }
  }
)
watch(
  () => ({ assignment: assignment.value, responses: props.responses }),
  ({ assignment }) => {
    individuals.value = []
    hasIndividualEdits.value = false
    groups.value = []
    hasGroupEdits.value = false
    isPointTotalOverride.value = false

    switch (assignment.assignedTo) {
      case 'groups':
        revertGroupEdits()
        break
      case 'individuals':
        revertIndividualEdits()
        break
    }
  },
  { immediate: true }
)

const isAssigningStudents = ref(false)
const canAssignStudents = computed(
  () =>
    ['groups', 'individuals'].includes(assignment.value.assignedTo) &&
    !isAssigningStudents.value
)
function revertAssignedStudents() {
  isAssigningStudents.value = false
  if (hasGroupEdits.value) {
    revertGroupEdits()
  }
  if (hasIndividualEdits.value) {
    revertIndividualEdits()
  }
}

async function onSubmit(e) {
  try {
    await client.assignments.update({
      assignmentId: assignment.value.id,
      startDate:
        assignment.value.startDate === undefined
          ? null
          : assignment.value.startDate,
      endDate:
        assignment.value.endDate === undefined
          ? null
          : assignment.value.endDate,
      firstVisibleDate: assignment.value.useVisibilityDates
        ? assignment.value.firstVisibleDate
        : undefined,
      lastVisibleDate: assignment.value.useVisibilityDates
        ? assignment.value.lastVisibleDate
        : undefined,
      attemptsOverride:
        assignment.value.attemptsOverride === undefined
          ? null
          : assignment.value.attemptsOverride,
      totalPointValueOverride:
        assignment.value.totalPointValueOverride === undefined
          ? null
          : assignment.value.totalPointValueOverride,
      studentFeedbackTiming: assignment.value.studentFeedbackTiming,
      scoreVisibility: assignment.value.scoreVisibility,
      additionalFeedback: assignment.value.additionalFeedback,
      noHints: assignment.value.noHints,
      ignoreInstructorGradedPoints:
        assignment.value.ignoreInstructorGradedPoints,
      progressiveDisclosure: assignment.value.progressiveDisclosure,
      useVisibilityDates: assignment.value.useVisibilityDates
    })

    if (hasGroupEdits.value) {
      await client.assignments.updateResponses({
        assignmentId: assignment.value.id,
        type: assignment.value.assignedTo,
        groups: groups.value.map(g => {
          return {
            id: g.id,
            name: g.groupName || g.name,
            students: g.students.map(s => s._id)
          }
        })
      })
    } else if (hasIndividualEdits.value) {
      await client.assignments.updateResponses({
        assignmentId: assignment.value.id,
        type: assignment.value.assignedTo,
        students: individuals.value
      })
    }

    isAssigningStudents.value = false
    flash.success('Updated assignment successfully.')
    e.done()
    emit('refresh')
  } catch (error) {
    e.done(false)
    if (error.body?.operations) {
      error.body.operations.forEach(operation => {
        flash.error(`${operation.id}: ${operation.error}`)
      })
    } else {
      throw error
    }
  }
}
</script>

<style lang="scss">
.assignment-settings__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
}
.assignment-settings__panel {
  box-shadow: 0px 10px 27px -11px rgba(0, 0, 0, 0.71) !important;
}

.assignment-settings__points {
  grid-area: points;
}

.assignment-settings__total-points {
  font-weight: bold;
  margin-right: 16px;
  display: inline-block;
}

.assignment-settings__point-splits {
  display: flex;

  & > :not(:first-child) {
    margin-left: 32px;
  }
}

.assignment-settings__groups {
  grid-area: assign;
}
</style>
