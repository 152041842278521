<template>
  <section>
    <section-title>LTI</section-title>
    <loading-container :loading="isLoading">
      <template v-if="ltiConfig">
        <p><b>Platform Name:</b> {{ ltiConfig.name }}</p>
        <p><b>Client Id:</b> {{ ltiConfig.clientId }}</p>
        <p>
          <b>Deployment Id:</b>
          {{ ltiConfig.deploymentId }}
        </p>
      </template>
      <template v-else>
        <p>Select an LMS to set up LTI for the license.</p>
        <div class="button-flex">
          <form-button class="lti-button" @click="showModal('Canvas')" secondary
            >Canvas</form-button
          >
          <form-button class="lti-button" @click="showModal('D2L')" secondary
            >D2L</form-button
          >
          <form-button
            class="lti-button"
            @click="showModal('Schoology')"
            secondary
            >Schoology</form-button
          >
          <form-button
            class="lti-button"
            @click="showModal('Blackboard')"
            secondary
            >Blackboard</form-button
          >
          <form-button
            class="lti-button"
            @click="showltiCustomModal()"
            secondary
            >Other</form-button
          >
        </div>
      </template>
    </loading-container>
  </section>
</template>

<script setup>
import client from 'src/shared/api-client'
import LtiModal from './LtiModal.vue'
import LtiCustomModal from './LtiCustomModal.vue'
import { ref, inject, onBeforeMount } from 'vue'

const props = defineProps({
  licenseId: {
    type: String,
    required: true
  }
})

const modal = inject('$modal')
const showModal = async platform => {
  const { status } = await modal.show(LtiModal, {
    licenseId: props.licenseId,
    platform
  })
  if (status === 'ok') {
    await loadLti()
  }
}

const showltiCustomModal = async () => {
  const { status } = await modal.show(LtiCustomModal, {
    licenseId: props.licenseId
  })
  if (status === 'ok') {
    await loadLti()
  }
}

const isLoading = ref(true)
const ltiConfig = ref(undefined)
async function loadLti() {
  isLoading.value = true
  try {
    const body = await client.lti.getLtiForLicense({
      licenseId: props.licenseId
    })
    if (body) {
      ltiConfig.value = body
    }
  } catch (error) {
    isLoading.value = false
    if (error.status !== 404) {
      throw error
    }
  }
  isLoading.value = false
}
onBeforeMount(async () => {
  await loadLti()
})
</script>

<style lang="scss" scoped>
.lti-title {
  margin-bottom: 10px;
}
.center-container {
  margin: auto;
  width: 50%;
  height: 50%;
  text-align: center;
}
.lti-button {
  width: 150px;
  margin: 0px;
}

.button-flex {
  flex-direction: column;
  display: flex;
  gap: 10px;
  width: 200px;
  margin: 0px;
  justify-content: flex-start;
}
</style>
