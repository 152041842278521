<template>
  <div class="data-grid__calculation-value">
    <div class="truncate" :title="tooltip">
      <span>{{ capitalize(calculationType) }}</span
      >: {{ value }}
    </div>
    <button-dropdown
      v-if="!readonly"
      :open="dropdownOpen"
      @update:open="openDropdown"
      ref="dropdown"
      tabindex="-1"
      right
      class="column-dropdown"
      data-tablenav
    >
      <template #button>
        <icon icon="ellipsis-h" />
        <span class="sr-only"
          >calculation {{ calculationType }} actions menu</span
        >
      </template>
      <template #default>
        <dropdown-action v-if="!changingType" @click="onEdit">
          Edit
        </dropdown-action>
        <dropdown-action
          v-if="!changingType"
          @click="gridActions.removeColumnCalculation(columnIndex, index)"
          >Clear</dropdown-action
        >
      </template>
    </button-dropdown>
  </div>
</template>

<script setup>
import * as Y from 'yjs'
import { computed, ref, inject } from 'vue'
import math from 'src/setup/math'
import DataGridEditModal from './DataGridEditModal.vue'
import { evaluateExpression } from '@pi/shared/variables'

const modal = inject('$modal')
const gridActions = inject('$gridActions')
const dropdownOpen = ref(false)
const changingType = ref(false)
const dropdown = ref(null)

const props = defineProps({
  calculation: {
    type: Y.Map,
    required: true
  },
  columnIndex: {
    type: Number,
    required: true
  },
  column: {
    type: Y.Map,
    required: true
  },
  navRowIndex: {
    type: Number,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  variableContext: {
    type: Object,
    default: undefined,
    required: false
  }
})

const calculationType = computed(() => props.calculation.get('type'))
const precision = computed(() => props.calculation.get('precision'))
const displayFormat = computed(() => props.calculation.get('format'))

const openDropdown = open => {
  if (!open && changingType.value) return
  dropdownOpen.value = open
}
const onEdit = async () => {
  const { data } = await modal.show(DataGridEditModal, {
    calculation: props.calculation
  })
  updateCalculation(data)
}

const value = computed(() => {
  const columnJson = props.column.toJSON()
  const data = columnJson.data
    .map(data => {
      if (data?.parser) {
        return evaluateExpression(data.id, props.variableContext.variables)
      }
      return parseFloat(data)
    })
    .filter(data => !isNaN(data))
  if (!data.length) return ''
  let calculationValue = 0
  switch (calculationType.value) {
    case 'total':
      calculationValue = math.sum(data)
      break
    case 'mean':
      calculationValue = math.mean(data)
      break
    case 'median':
      calculationValue = math.median(data)
      break
    case 'stdev':
      calculationValue = math.round(math.std(data))
      break
    default:
      throw new Error(
        `Data grid calculation type is not valid. Value is: ${calculationType.value}`
      )
  }

  switch (displayFormat.value) {
    case 'sigfigs':
      return calculationValue.toPrecision(precision.value)
    case 'scientific':
      return calculationValue.toExponential()
    case 'decimals':
      return calculationValue.toFixed(precision.value)
    default:
      throw new Error(
        `Data grid display value is not valid. Value is: ${displayFormat.value}`
      )
  }
})

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const tooltip = computed(() => {
  return `${capitalize(props.calculation)}: ${value.value}`
})

const updateCalculation = type => {
  gridActions.updateColumnCalculation(props.columnIndex, props.index, type)
  changingType.value = false
  dropdownOpen.value = false
}
</script>

<style lang="scss" scoped>
.data-grid__calculation-value {
  position: relative;
  span {
    font-weight: bold;
  }
  .truncate {
    width: calc(100% - $grid-row-small-height);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

:deep(.column-dropdown) {
  position: absolute;
  top: 0;
  right: 0;
  background-color: inherit;
  width: $grid-row-small-height;
  height: $grid-row-small-height;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: $teal;
  border-radius: 0;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: $darker-teal;
  }
}
</style>
