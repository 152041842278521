<template>
  <li class="filter-section">
    <h4 class="filter-section-header">{{ title }}</h4>
    <slot />
  </li>
</template>

<script>
export default {
  name: 'LibraryFilterSection',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-section {
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin-bottom: 12px;
  }
}

.filter-section-header {
  font-size: 16px;
  font-weight: 700;
  margin: 8px 8px 12px 8px;
}
</style>
