// This code is adapted from the source code of bson
// https://github.com/mongodb/js-bson/blob/eb98b8c39d6d5ba4ce7231ab9e0f29495d74b994/src/objectid.ts

const PROCESS_UNIQUE = [
  Math.floor(Math.random() * 0xff),
  Math.floor(Math.random() * 0xff),
  Math.floor(Math.random() * 0xff),
  Math.floor(Math.random() * 0xff),
  Math.floor(Math.random() * 0xff)
]

let index = Math.floor(Math.random() * 0xffffff)

export function generateObjectId() {
  const bytes = []

  const time = Math.floor(Date.now() / 1000)
  index = (index + 1) % 0xffffff

  bytes.push((time >> 24) & 0xff)
  bytes.push((time >> 16) & 0xff)
  bytes.push((time >> 8) & 0xff)
  bytes.push(time & 0xff)

  bytes.push(PROCESS_UNIQUE[0])
  bytes.push(PROCESS_UNIQUE[1])
  bytes.push(PROCESS_UNIQUE[2])
  bytes.push(PROCESS_UNIQUE[3])
  bytes.push(PROCESS_UNIQUE[4])

  bytes.push((index >> 16) & 0xff)
  bytes.push((index >> 8) & 0xff)
  bytes.push(index & 0xff)

  return bytes.map(byte => byte.toString(16).padStart(2, '0')).join('')
}
