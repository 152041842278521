<template>
  <modal @submit="submit">
    <modal-header>Configure Bluetooth Devices</modal-header>
    <modal-body>
      <template v-if="isBluetoothSupported">
        <form-group>
          <form-label for="bluetooth-sample-rate"
            >Samples per Second</form-label
          >
          <number-input
            id="bluetooth-sample-rate"
            v-model="sampleRate"
            min="0.00001"
            max="10"
            step="any"
            :rules="{
              required: true,
              min_value: 0.00001,
              max_value: 25
            }"
            label="sample rate"
          />
        </form-group>
        <form-group>
          <form-label for="bluetooth-max-samples">Number of Samples</form-label>
          <number-input
            id="bluetooth-max-samples"
            v-model="maxSamples"
            min="1"
            max="1000"
            step="1"
            :rules="{
              required: true,
              min_value: 1,
              max_value: 1000,
              integer: true
            }"
            label="number of samples"
          />
        </form-group>
        <form-group>
          <form-label>Connected Devices</form-label>
          <list :items="Object.values(connectedDevices)">
            <list-column
              title="Device Name"
              property="name"
              class="device-name-column"
            />
            <list-column v-slot="device" title="Actions">
              <form-button link @click="disconnectDevice(device)"
                >Disconnect</form-button
              >
            </list-column>
            <template #lastRow>
              <form-button link :disabled="connecting" @click="connectDevice">
                <icon icon="plus" />
                Connect Device
              </form-button>
            </template>
          </list>
        </form-group>
      </template>
      <p v-else>
        Bluetooth devices cannot be connected because your web browser does not
        support the Web Bluetooth API. Check out
        <hyper-link :to="{ name: 'bluetooth_devices' }"
          >this help center article</hyper-link
        >
        for more information.
      </p>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Ok</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import { markRaw } from 'vue'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import * as bluetooth from 'src/shared/components/grid-graph/bluetooth'
import { isBluetoothSupported } from 'src/shared/utils/system-compatibility'

export default {
  name: 'ConfigureBluetoothDevicesModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  props: {
    currentConnectedDevices: {
      type: Object,
      required: true
    },
    currentSampleRate: {
      type: Number,
      required: true
    },
    currentMaxSamples: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      connectedDevices: { ...this.currentConnectedDevices },
      sampleRate: this.currentSampleRate,
      maxSamples: this.currentMaxSamples,
      connecting: false,
      isBluetoothSupported
    }
  },
  methods: {
    async connectDevice() {
      this.connecting = true

      try {
        const device = await bluetooth.connectDevice()
        if (device) {
          this.connectedDevices[device.id] = markRaw(device)
        }
      } catch (error) {
        this.$error(`Error connecting device: ${error.message}`)
      } finally {
        this.connecting = false
      }
    },
    async disconnectDevice(device) {
      try {
        await device.disconnect()
      } catch (error) {
        this.$error(`Error disconnecting device: ${error.message}`)
      } finally {
        // Always remove the device as it could be in a bad state
        delete this.connectedDevices[device.id]
      }
    },
    async submit(e) {
      e.done({
        connectedDevices: this.connectedDevices,
        sampleRate: this.sampleRate,
        maxSamples: this.maxSamples
      })
    }
  }
}
</script>

<style scoped>
.device-name-column {
  width: 100%;
}
</style>
